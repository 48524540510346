import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchSimpleBrands } from 'api/simpleBrand';
import { fetchEoCampaigns } from 'api/eoCampaign';

import { optionsSelector } from 'selectors';
import { optionsList } from 'common/prop_types_shapes';

import EoToDosBulkActionsComponent from './EoToDosBulkActionsComponent';

const EoToDosBulkActionsContainer = ({
  brandsOptions,
  dispatch,
  eoCampaignsOptions,
  immutableUsersOptions,
}) => {
  React.useEffect(() => {
    fetchEoCampaigns(dispatch);
    fetchSimpleBrands(dispatch);
  }, [dispatch]);

  return (
    <EoToDosBulkActionsComponent
      immutableBrandsOptions={brandsOptions}
      immutableEoCampaignsOptions={eoCampaignsOptions}
      immutableUsersOptions={immutableUsersOptions}
    />
  );
};

EoToDosBulkActionsContainer.propTypes = {
  brandsOptions: optionsList,
  dispatch: PropTypes.func,
  eoCampaignsOptions: optionsList,
  immutableUsersOptions: optionsList,
};

function select(state) {
  const options = optionsSelector(state);
  const brandsOptions = options.get('brandsOptions');
  const eoCampaignsOptions = options.get('eoCampaignsOptions');

  return {
    brandsOptions,
    eoCampaignsOptions,
  };
}

export default ConnectStoreHOC(connect(select)(EoToDosBulkActionsContainer));
