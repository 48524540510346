import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import {
  eoBlacklistedDomainsSelector,
  eoBlacklistedDomainsPagySelector,
} from 'selectors/eoBlacklistedDomainsSelector';

import EoBlacklistedDomainsTableComponent from './EoBlacklistedDomainsTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { eoBlacklistedDomainsList } from 'common/propTypesShapes/eoBlacklistedDomains';
import { fetchEoBlacklistedDomains } from 'api/eoBlacklistedDomain';
import { resetSelectedIds, toggleSelectedIds } from 'actions/ui_actions';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import { uiSelector } from 'selectors';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const EoBlacklistedDomainsTableContainer = ({
  changeQueryParams,
  dispatch,
  eoBlacklistedDomains,
  eoBlacklistedDomainsPagy,
  queryParams,
  selectedIds,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const { pageNumber = 1, pagyItemsCount = 100, search = '', sorting = {} } = queryParams;

  React.useEffect(() => {
    async function fetchData() {
      const fetchingParams = {
        items: pagyItemsCount,
        page: pageNumber,
        search,
        sorting,
      };

      setIsFetching(true);
      await fetchEoBlacklistedDomains(dispatch, fetchingParams);
      setIsFetching(false);
    }

    fetchData();
  }, [dispatch, pageNumber, pagyItemsCount, search, sorting.sortBy, sorting.sortingOrder]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectedIds());
    };
  }, [dispatch]);

  function handleToggleSelectedRows(eoBlacklistedDomainsIds, isSelected) {
    dispatch(toggleSelectedIds(eoBlacklistedDomainsIds, isSelected));
  }

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  const eoBlacklistedDomainsCount = eoBlacklistedDomainsPagy.get('count');

  return (
    <>
      {eoBlacklistedDomains.size > 0 && (
        <EoBlacklistedDomainsTableComponent
          onTableHeaderClick={handleTableHeaderClick}
          onToggleSelectedRows={handleToggleSelectedRows}
          eoBlacklistedDomains={eoBlacklistedDomains}
          eoBlacklistedDomainsCount={eoBlacklistedDomainsCount}
          selectedIds={selectedIds}
          sorting={sorting}
        />
      )}
      {eoBlacklistedDomains.size === 0 && (
        <BlankStateCtaComponent isLoading={isFetching}>
          <TitleText>
            <strong>{translate('eoBlacklistedDomain.table.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('eoBlacklistedDomain.table.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

EoBlacklistedDomainsTableContainer.propTypes = {
  dispatch: PropTypes.func,
  eoBlacklistedDomains: eoBlacklistedDomainsList,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoBlacklistedDomains = eoBlacklistedDomainsSelector(state);
  const eoBlacklistedDomainsPagy = eoBlacklistedDomainsPagySelector(state);

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    eoBlacklistedDomains,
    eoBlacklistedDomainsPagy,
    selectedIds,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoBlacklistedDomainsTableContainer);
