import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';

import { currentIdSelector } from './index';

const eoInboxes = (state) => state.getIn(['eoInboxesSettings', 'eoInboxes']);
const eoInboxesSettings = (state) => state.getIn(['eoInboxesSettings', 'eoInboxesSettings']);

export const eoInboxesSettingsSelector = createSelector(
  [eoInboxesSettings],
  (eoInboxesSettings) => eoInboxesSettings || iMap(),
);

export const eoInboxesSelector = createSelector([eoInboxes], (eoInboxes) => eoInboxes || iList());

export const currentEoInboxSelector = createSelector(
  [eoInboxes, currentIdSelector],
  (eoInboxes, eoInboxId) =>
    eoInboxes.find((eoInbox) => String(eoInbox.get('id')) === eoInboxId) || iMap(),
);
