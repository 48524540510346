import React from 'react';
import PropTypes from 'prop-types';

import './progressBar.scss';

const ProgressBarComponent = ({ value, maxValue }) => {
  return (
    <div className="progress-bar-component">
      <progress
        className="progress-bar-component__bar"
        value={value}
        max={maxValue}
      />
      <span className="progress-bar-component__value">{value}%</span>
    </div>
  );
};

ProgressBarComponent.propTypes = {
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

ProgressBarComponent.defaultProps = {
  maxValue: 100,
  value: 0,
};

export default ProgressBarComponent;
