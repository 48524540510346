import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import Reaptcha from 'reaptcha';

import ErrorBoundary from 'decorators/error-boundary';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { optionsSelector } from 'selectors';

import { currentUserSelector, policiesSelector } from 'selectors/railsContextSelectors';

import { employeeFormRowSelector, employeesSelector } from 'selectors/employeeSelectors';

import { employeesMap, optionsMap, policiesShape, userShape } from 'common/prop_types_shapes';

import {
  createEmployee,
  deleteEmployees,
  fetchEmployees,
  inviteEmployees,
  updateEmployee,
} from 'api/employee';

import { verifyRecaptcha } from 'api/recaptcha';

import { updateEmployeeFormRow, updateEmployeeRows } from 'actions/employee_actions';

import Breadcrumbs from 'components/NewBreadcrumbs';

import EmployeesComponent from './EmployeesComponent';

import './employees.scss';

class EmployeesContainer extends Component {
  static propTypes = {
    currentUser: userShape,
    dispatch: PropTypes.func,
    employeeFormRow: userShape,
    employees: employeesMap,
    policies: policiesShape.isRequired,
    profileTypeOptions: optionsMap,
  };

  state = {
    token: null,
    handleAction: () => {},
    resolveCb: () => {},
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchEmployees(dispatch);
  }

  afterCaptchaChecked = (handleAction) => {
    const { token } = this.state;

    if (token) {
      return handleAction();
    }

    this.captcha.renderExplicitly();

    return new Promise((resolve) => {
      this.setState({ resolveCb: resolve, handleAction });
    });
  };

  handleVerify = async (token) => {
    const { resolveCb, handleAction } = this.state;
    this.setState({ token });

    const result = await verifyRecaptcha(this.props.dispatch, token);

    if (result) {
      resolveCb(handleAction());
    }
  };

  handleRemoveToken = () => this.setState({ token: null });

  handleCreateEmployee = (employee) =>
    this.afterCaptchaChecked(() => createEmployee(this.props.dispatch, employee));
  handleDeleteEmployees = (employeeIds) => deleteEmployees(this.props.dispatch, employeeIds);
  handleInviteEmployees = (employeeIds) =>
    this.afterCaptchaChecked(() => inviteEmployees(this.props.dispatch, employeeIds));
  handleUpdateEmployee = (employee) => updateEmployee(this.props.dispatch, employee);
  handleUpdateFormRow = (employee) => this.props.dispatch(updateEmployeeFormRow(employee));
  handleUpdateRows = (employees) => this.props.dispatch(updateEmployeeRows(employees));

  recaptcha = (
    <Reaptcha
      ref={(ref) => (this.captcha = ref)}
      sitekey={process.env.RECAPTCHA_SITE_KEY}
      onVerify={this.handleVerify}
      onExpire={this.handleRemoveToken}
      explicit
    />
  );

  render() {
    const { currentUser, employeeFormRow, employees, policies, profileTypeOptions } = this.props;

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.TextItem>Invite Team Members</Breadcrumbs.TextItem>
        </Breadcrumbs>
        <EmployeesComponent
          currentUser={currentUser}
          employees={employees.valueSeq().toJS()}
          onCreateEmployee={this.handleCreateEmployee}
          onDeleteEmployees={this.handleDeleteEmployees}
          onInviteEmployees={this.handleInviteEmployees}
          onUpdateEmployee={this.handleUpdateEmployee}
          onUpdateFormRow={this.handleUpdateFormRow}
          onUpdateRows={this.handleUpdateRows}
          employeeFormRow={employeeFormRow.toJS()}
          policies={policies}
          profileTypeOptions={profileTypeOptions}
          recaptcha={this.recaptcha}
        />
      </>
    );
  }
}

function select(state, ownProps) {
  const currentUser = currentUserSelector(state, ownProps);
  const employeeFormRow = employeeFormRowSelector(state, ownProps);
  const employees = employeesSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const profileTypeOptions = options.get('profileTypeOptions');

  return {
    currentUser,
    employeeFormRow,
    employees,
    policies,
    profileTypeOptions,
  };
}

export default compose(ConnectStoreHOC, connect(select), ErrorBoundary)(EmployeesContainer);
