import React from 'react';
import moment from 'moment';

import { translate } from 'common/i18n';

import ProgressBar from 'components_linkio/ProgressBar';

export default function buildLastCheck(lastCrawled, crawlingProgress) {
  const lastCrawledFormatted = lastCrawled ? moment(lastCrawled).format('MMM D, YYYY') : translate('notAvailable');

  switch (crawlingProgress) {
    case null: {
      return translate('notAvailable');
    }
    case 100: {
      return lastCrawledFormatted;
    }
    default: {
      return (
        <ProgressBar value={crawlingProgress} />
      );
    }
  }
}
