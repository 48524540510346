import React from 'react';
import { Map as iMap } from 'immutable';
import classnames from 'classnames';
import moment from 'moment';

import { subscriptionPlansMap, subscriptionShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import './subscriptionPlanUsageComponent.scss';

const WARNING_PERCENTAGE = 0.8; // 80%
const MONTHS_IN_YEAR = 12;

const counterClasses = (isWarning) =>
  classnames({
    'plan-usage-component__text_to-right': true,
    'plan-usage-component__text_blue': !isWarning,
    'plan-usage-component__text_red': isWarning,
  });

const isCounterHasWarning = (current, total) => current / total >= WARNING_PERCENTAGE;

const SubscriptionPlanUsageComponent = ({ currentSubscription, subscriptionPlans }) => {
  const backlinksMozImportsCount = currentSubscription.get('backlinksMozImportsCount', 0);
  const allowedBacklinksMozImportsCount = currentSubscription.get(
    'allowedBacklinksMozImportsCount',
    0,
  );
  const brandsCount = currentSubscription.get('brandsCount', 0);
  const trackedKeywordsCount = currentSubscription.get('trackedKeywordsCount', 0);
  const checkedKeywordsCount = currentSubscription.get('checkedKeywordsCount', 0);
  const eoInboxesCount = currentSubscription.get('eoInboxesCount', 0);
  const allowedBrandsCount = currentSubscription.get('allowedBrandsCount', 0);
  const allowedTrackedKeywordsCount = currentSubscription.get('allowedTrackedKeywordsCount', 0);
  const allowedCheckedKeywordsCount = currentSubscription.get('allowedCheckedKeywordsCount', 0);
  const emailsValidationCreditsCount = currentSubscription.get('emailsValidationCreditsCount', 0);
  const allowedEoInboxesCount = currentSubscription.get('allowedEoInboxesCount', 0);
  const crawlingsCount = currentSubscription.get('crawlingsCount', 0);
  const allowedCrawlingsCount = currentSubscription.get('allowedCrawlingsCount', 0);
  const currentSubscriptionPlanId = currentSubscription.get('subscriptionPlanId');
  const currentPlan = subscriptionPlans.get(String(currentSubscriptionPlanId), iMap());

  const subscriptionPlanTitle = currentPlan.get('title');
  const subscriptionPlanPrice = Number(currentPlan.get('price', 0));
  const annualMonthlyPrice = Number(subscriptionPlanPrice / MONTHS_IN_YEAR);
  const billingInterval = currentSubscription.get('billingInterval', 'monthly');
  const subscriptionDate = currentSubscription.get('startsAt');
  const brandsCountUsage = `${brandsCount}/${allowedBrandsCount}`;
  const trackedKeywordsCountUsage = `${trackedKeywordsCount}/${allowedTrackedKeywordsCount}`;
  const checkedKeywordsCountUsage = `${checkedKeywordsCount}/${allowedCheckedKeywordsCount}`;
  const eoInboxesCountUsage = `${eoInboxesCount}/${allowedEoInboxesCount}`;
  const isBrandsCountHasWarning = isCounterHasWarning(brandsCount, allowedBrandsCount);
  const isTrackedKeywordsHasWarning = isCounterHasWarning(
    trackedKeywordsCount,
    allowedTrackedKeywordsCount,
  );
  const isCheckedKeywordsHasWarning = isCounterHasWarning(
    checkedKeywordsCount,
    allowedCheckedKeywordsCount,
  );
  const isEoInboxesHasWarning = isCounterHasWarning(eoInboxesCount, allowedEoInboxesCount);
  const crawlingsCountUsage = `${crawlingsCount}/${allowedCrawlingsCount}`;
  const isCrawlingsCountHasWarning = isCounterHasWarning(crawlingsCount, allowedCrawlingsCount);
  const mozImportsUsage = `${backlinksMozImportsCount}/${allowedBacklinksMozImportsCount}`;
  const isMozImportsUsageHasWarning = isCounterHasWarning(
    backlinksMozImportsCount,
    allowedBacklinksMozImportsCount,
  );

  let priceString =
    billingInterval === 'yearly'
      ? `$${annualMonthlyPrice.toFixed(2)}/mo`
      : `$${subscriptionPlanPrice.toFixed(2)}/mo`;
  if (subscriptionPlanPrice === 0) {
    priceString = 'FREE';
  }

  const brandsCountClasses = counterClasses(isBrandsCountHasWarning);
  const trackedKeywordsCountClasses = counterClasses(isTrackedKeywordsHasWarning);
  const eoInboxesCountClasses = counterClasses(isEoInboxesHasWarning);
  const checkedKeywordsCountClasses = counterClasses(isCheckedKeywordsHasWarning);
  const crawlingsCountClasses = counterClasses(isCrawlingsCountHasWarning);
  const mozImportsUsageClasses = counterClasses(isMozImportsUsageHasWarning);

  return (
    <div className="plan-usage-component">
      <div
        className="plan-usage-component__title plan-usage-component__text
          plan-usage-component__text_bold plan-usage-component__text_blue"
      >
        {translate('subscriptionPlanUsageComponent.title')}
      </div>
      <div className="plan-usage-component__plan-usage-info">
        <div
          className="plan-usage-component__info-item plan-usage-component__info-item_first
            plan-usage-component__text plan-usage-component__text_bold plan-usage-component__text_blue"
        >
          {subscriptionPlanTitle}
          <span className="plan-usage-component__text_to-right">{priceString}</span>
        </div>
        <div className="plan-usage-component__text plan-usage-component__info-item plan-usage-component__info-item_second">
          {translate('subscriptionPlanUsageComponent.subscriptionDate')}
          <span className="plan-usage-component__text_blue">
            {moment(subscriptionDate).format('MMMM D, YYYY')}
          </span>
        </div>
        <div
          className="plan-usage-component__text plan-usage-component__info-item
            plan-usage-component__plan-usage-counters"
        >
          <div className="plan-usage-component__text_bold plan-usage-component__text_blue">
            {translate('subscriptionPlanUsageComponent.usage')}
          </div>
          <br />
          <div>
            {translate('subscriptionPlanUsageComponent.brandsWebsites')}
            <span className={brandsCountClasses}>{brandsCountUsage}</span>
          </div>
          <div>
            {translate('subscriptionPlanUsageComponent.backlinksChecked')}
            <span className={crawlingsCountClasses}>{crawlingsCountUsage}</span>
          </div>
          <div>
            {translate('subscriptionPlanUsageComponent.backlinksImportedFromMoz')}
            <span className={mozImportsUsageClasses}>{mozImportsUsage}</span>
          </div>
          <div>
            {translate('subscriptionPlanUsageComponent.trackedKeywords')}
            <span className={trackedKeywordsCountClasses}>{trackedKeywordsCountUsage}</span>
          </div>
          <div>
            {translate('subscriptionPlanUsageComponent.checkedKeywords')}
            <span className={checkedKeywordsCountClasses}>{checkedKeywordsCountUsage}</span>
          </div>
          <div>
            {translate('subscriptionPlanUsageComponent.eoInboxes')}
            <span className={eoInboxesCountClasses}>{eoInboxesCountUsage}</span>
          </div>
          <div>
            {translate('subscriptionPlanUsageComponent.emailsValidationCredits')}
            <span className="plan-usage-component__text_to-right plan-usage-component__text_blue">
              {emailsValidationCreditsCount}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

SubscriptionPlanUsageComponent.propTypes = {
  currentSubscription: subscriptionShape.isRequired,
  subscriptionPlans: subscriptionPlansMap,
};

export default SubscriptionPlanUsageComponent;
