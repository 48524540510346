import {
  backlinkCrawlingFailure,
  backlinkCrawlingStart,
  backlinkCrawlingSuccess,
} from 'actions/crawler_actions';

import updateBrandsAndBacklinks from 'api/users_channel/utils/updateBrandsAndBacklinks';

const handleBacklinkCrawlingStatus = (dispatch, data) => {
  const { destinationLinks, backlinkCrawlingStatus } = data;

  switch (backlinkCrawlingStatus) {
    case 'started':
      dispatch(backlinkCrawlingStart(destinationLinks));
      updateBrandsAndBacklinks(data, dispatch);

      break;
    case 'failure':
      dispatch(backlinkCrawlingFailure(destinationLinks));
      updateBrandsAndBacklinks(data, dispatch);

      break;
    case 'finished':
      dispatch(backlinkCrawlingSuccess(destinationLinks));
      updateBrandsAndBacklinks(data, dispatch);

      break;
  }
};

export default handleBacklinkCrawlingStatus;
