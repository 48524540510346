import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Link from 'components_linkio/link';

import { translate } from 'common/i18n';
import { insertNotAvailableIfNull } from 'common/utils';
import defaultMetricsParams from 'utils/routeUtils';
import { brandsMetricsArray } from 'common/propTypesShapes/metrics';

import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';
import MyBrandsDashboardMenu from '../MyBrandsDashboardMenu';

import './myBrandsDashboardTable.scss';

const metricCellClassNames = cx(
  'my-brands-dashboard-table__cell',
  'my-brands-dashboard-table__cell_value',
);

const headerCellWithLegendClassNames = cx(
  'my-brands-dashboard-table__cell',
  'my-brands-dashboard-table__cell_legend',
);

const renderDiff = (diff = 0) => {
  const diffClassNames = cx({
    'my-brands-dashboard-table__cell_value_decrease': diff < 0,
    'my-brands-dashboard-table__cell_value_increase': diff > 0,
  });

  return <span className={diffClassNames}>{Math.abs(diff) || ''}</span>;
};

const MyBrandsDashboardTableComponent = ({ brandsMetrics, canUseMetrics }) => {
  return (
    <table className="my-brands-dashboard-table">
      <thead>
        <tr className="my-brands-dashboard-table__row my-brands-dashboard-table__row_legend">
          <th className="my-brands-dashboard-table__cell" />
          <th
            className={`${headerCellWithLegendClassNames} my-brands-dashboard-table__avg-rank_header`}
          >
            {translate('brand.dashboard.myBrands.table.avgRank')}
            <ExplanatoryTooltipComponent
              text={translate('explanationMessages.myBrandsDashboardTable.avgRank')}
            />
          </th>
          <th
            className={`${headerCellWithLegendClassNames} my-brands-dashboard-table__ref-domains_header`}
          >
            {translate('brand.dashboard.myBrands.table.refDomains')}
            <ExplanatoryTooltipComponent
              text={translate('explanationMessages.myBrandsDashboardTable.refDomains')}
            />
          </th>
          <th className={`${headerCellWithLegendClassNames} my-brands-dashboard-table__atp-header`}>
            {translate('brand.dashboard.myBrands.table.atp')}
            <ExplanatoryTooltipComponent
              text={translate('explanationMessages.myBrandsDashboardTable.atp')}
            />
          </th>
          <th
            className={`${headerCellWithLegendClassNames} my-brands-dashboard-table__drip-lists_header`}
          >
            {translate('brand.dashboard.myBrands.table.dripLists')}
            <ExplanatoryTooltipComponent
              text={translate('explanationMessages.myBrandsDashboardTable.dripLists')}
            />
          </th>
          <th
            className={`${headerCellWithLegendClassNames} my-brands-dashboard-table__campaigns_header`}
          >
            {translate('brand.dashboard.myBrands.table.campaigns')}
            <ExplanatoryTooltipComponent
              text={translate('explanationMessages.myBrandsDashboardTable.campaigns')}
            />
          </th>
          <th className="my-brands-dashboard-table__cell my-brands-dashboard-table__cell_menu" />
        </tr>
      </thead>
      <tbody>
        {brandsMetrics.map((brand) => {
          const {
            id,
            name,
            averageRank,
            refDomains,
            atpBrandPagesCount,
            campaignsCount,
            dripListsCount,
          } = brand;

          return (
            <tr className="my-brands-dashboard-table__row" key={id}>
              <td className="my-brands-dashboard-table__cell my-brands-dashboard-table__cell_title">
                <Link
                  className="my-brands-dashboard-table__link"
                  to={Routes.dashboard_path(id, { format: null })}
                >
                  {name}
                </Link>
              </td>
              <td className={`${metricCellClassNames} capybara--brands-dashboard__avg-rank`}>
                <Link
                  className="my-brands-dashboard-table__link"
                  to={Routes.rank_tracker_root_path(id, { format: null })}
                >
                  {insertNotAvailableIfNull(Math.round(averageRank.value) || null)}
                </Link>
                {renderDiff(Math.round(averageRank.difference))}
              </td>
              <td className={`${metricCellClassNames} capybara--brands-dashboard__ref-domains`}>
                <Link
                  className="my-brands-dashboard-table__link"
                  to={Routes.backlink_path(id, {
                    ...defaultMetricsParams(canUseMetrics),
                  })}
                >
                  {insertNotAvailableIfNull(refDomains.value)}
                </Link>
                {renderDiff(refDomains.difference)}
              </td>
              <td className={`${metricCellClassNames} capybara--brands-dashboard__atp`}>
                <Link
                  className="my-brands-dashboard-table__link"
                  to={Routes.brand_brand_pages_path(id, { format: null })}
                >
                  {insertNotAvailableIfNull(atpBrandPagesCount)}
                </Link>
              </td>
              <td className={`${metricCellClassNames} my-brands-dashboard-table__drip-lists_cell`}>
                <Link
                  className="my-brands-dashboard-table__link"
                  to={Routes.eo_drip_lists_path({ filters: { brand_id_eq: id }, format: null })}
                >
                  {insertNotAvailableIfNull(dripListsCount)}
                </Link>
              </td>
              <td className={`${metricCellClassNames} my-brands-dashboard-table__campaigns_cell`}>
                <Link
                  className="my-brands-dashboard-table__link"
                  to={Routes.eo_campaigns_path({ filters: { brand_id_eq: id }, format: null })}
                >
                  {insertNotAvailableIfNull(campaignsCount)}
                </Link>
              </td>
              <td className="my-brands-dashboard-table__cell my-brands-dashboard-table__cell_menu">
                <MyBrandsDashboardMenu brand={brand} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

MyBrandsDashboardTableComponent.propTypes = {
  brandsMetrics: brandsMetricsArray,
  canUseMetrics: PropTypes.bool,
};

export default MyBrandsDashboardTableComponent;
