import React from 'react';
import PropTypes from 'prop-types';

import EoEmailTemplatesTable from './EoEmailTemplatesTable';
import EoEmailTemplatesBulkActions from './EoEmailTemplatesBulkActions';
import EoEmailTemplatesSidebar from './EoEmailTemplatesSidebar';
import EoCampaignsTabs from 'pages/EoCampaignsPage/EoCampaignsTabs';

import { pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';

import './EoEmailTemplatesPage.scss';

const EoEmailTemplatesComponent = ({
  eoEmailTemplatesPagy,
  onItemsSelectChange,
  onPagesPagerClick,
}) => {
  return (
    <div className="eo-email-templates">
      <EoCampaignsTabs />

      <div className="eo-email-templates__sidebar-and-table-wrapper">
        <EoEmailTemplatesSidebar />

        <div className="eo-email-templates__table-and-pager-wrapper">
          <EoEmailTemplatesBulkActions />

          <EoEmailTemplatesTable />

          <Pager
            handleItemsSelectChange={onItemsSelectChange}
            handlePagesControlClick={onPagesPagerClick}
            pagerType="rowsPager"
            pagy={eoEmailTemplatesPagy}
          />
        </div>
      </div>
    </div>
  );
};

EoEmailTemplatesComponent.propTypes = {
  eoEmailTemplatesPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoEmailTemplatesComponent;
