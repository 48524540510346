import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import RadioButton from 'components_linkio/RadioButton';
import Select from 'components_linkio/Select';

import './addEoBlacklistedDomainsPopup.scss';

const AddEoBlacklistedDomainsPopupComponent = ({ immutableBrandsOptions, onCancel, onSave }) => {
  const [domainsList, setDomainsList] = React.useState('');
  const [brandId, setBrandId] = React.useState(null);
  const [applyBlacklistedDomainsMode, setApplyBlacklistedDomainsMode] = React.useState(
    'applyToAllCampaigns',
  );

  const brandsOptions = immutableBrandsOptions.toJS();

  function onChangeDomainsList(event) {
    setDomainsList(event.target.value);
  }

  function handleChangeApplyBlacklistedDomainsMode(event) {
    const value = event.target.value;

    const needToDropBrandId = value === 'applyToAllCampaigns' && brandId;

    setApplyBlacklistedDomainsMode(value);
    needToDropBrandId && setBrandId(null);
  }

  function handleChangeBrandId(obj) {
    setBrandId(obj ? obj.value : null);
  }

  function handleSave(event) {
    event.preventDefault();

    onSave(domainsList, brandId);
  }

  return (
    <Modal show className="add-eo-blacklisted-domains-popup">
      <Modal.Header>
        <Modal.Title>
          {translate('eoBlacklistedDomain.addEoBlacklistedDomainsPopup.title')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form
          id="eo-blacklisted-domains-form"
          className="add-eo-blacklisted-domains-popup__form"
          noValidate
        >
          <InputComponent
            className="add-eo-blacklisted-domains-popup__textarea"
            label={translate(
              'eoBlacklistedDomain.addEoBlacklistedDomainsPopup.inputs.domain.label',
            )}
            message={{
              type: 'hint',
              text: translate(
                'eoBlacklistedDomain.addEoBlacklistedDomainsPopup.inputs.domain.hint',
              ),
            }}
            isMultiline
            onInputChange={onChangeDomainsList}
            value={domainsList}
          />

          <div className="add-eo-blacklisted-domains-popup__apply-blacklist-to">
            <p>
              {translate(
                'eoBlacklistedDomain.addEoBlacklistedDomainsPopup.applyBlacklistedDomainsMode.title',
              )}
            </p>

            <RadioButton
              checked={applyBlacklistedDomainsMode === 'applyToAllCampaigns'}
              onChange={handleChangeApplyBlacklistedDomainsMode}
              value="applyToAllCampaigns"
            >
              {translate(
                'eoBlacklistedDomain.addEoBlacklistedDomainsPopup.applyBlacklistedDomainsMode.applyToAllCampaigns',
              )}
            </RadioButton>

            <RadioButton
              checked={applyBlacklistedDomainsMode === 'applyToSpecificBrand'}
              onChange={handleChangeApplyBlacklistedDomainsMode}
              value="applyToSpecificBrand"
            >
              {translate(
                'eoBlacklistedDomain.addEoBlacklistedDomainsPopup.applyBlacklistedDomainsMode.applyToSpecificBrand',
              )}
            </RadioButton>

            {applyBlacklistedDomainsMode === 'applyToSpecificBrand' && (
              <Select
                isClearable
                label={translate('uiComponents.brandAssign.selectLabel')}
                blurInputOnSelect={false}
                placeholder={translate('uiComponents.brandAssign.noBrandAssigned')}
                triggerChangeOnBlur={false}
                onChange={handleChangeBrandId}
                options={brandsOptions}
                value={brandsOptions.find(({ value }) => value === brandId)}
              />
            )}
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="add-eo-blacklisted-domains-popup__btn-group">
          <div className="add-eo-blacklisted-domains-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('uiComponents.button.cancel')}
            </ButtonComponent>
          </div>

          <div className="add-eo-blacklisted-domains-popup__btn">
            <ButtonComponent isGreen onClick={handleSave} isDisabled={!domainsList}>
              {translate('uiComponents.button.add')}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AddEoBlacklistedDomainsPopupComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddEoBlacklistedDomainsPopupComponent;
