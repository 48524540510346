import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import DownArrowIcon from 'common/icons/down_arrow';

const SelectItemComponent = ({ children, onToggleMenu, menuIsOpen, select }) => {
  const classNames = cx('breadcrumbs__item', 'breadcrumbs__item_with_menu', {
    'breadcrumbs__item_with_open-menu': menuIsOpen,
  });

  return (
    <>
      {menuIsOpen && select}
      <div className={classNames} onClick={onToggleMenu}>
        {children}
      </div>
      <DownArrowIcon onClick={onToggleMenu} />
    </>
  );
};

SelectItemComponent.propTypes = {
  children: PropTypes.node,
  menuIsOpen: PropTypes.bool,
  onToggleMenu: PropTypes.func,
  select: PropTypes.node,
};

export default SelectItemComponent;
