import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap'; //TODO: Need to change to our component

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ClockIcon from 'common/icons/ClockIcon';
import MailIcon from 'common/icons/MailIcon';
import PlusInRoundIcon from 'common/icons/plus_in_round';

import './addEoCampaignStepDropdown.scss';

const AddEoCampaignStepDropdownComponent = ({ onAddEoCampaignStep }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);

  function handleOnBlur() {
    setDropdownIsOpen(false);
  }

  function handleOnToggle(isOpen) {
    setDropdownIsOpen(isOpen);
  }

  const handleAddEoCampaignStep = (eoCampaignStepActionType) => () => {
    onAddEoCampaignStep(eoCampaignStepActionType);
  };

  function buildActions() {
    return [
      {
        Icon: MailIcon,
        title: translate('eoCampaignStep.addEoCampaignStepMenu.EoCampaignStepEmail.title'),
        subtitle: translate('eoCampaignStep.addEoCampaignStepMenu.EoCampaignStepEmail.subtitle'),
        handleClick: handleAddEoCampaignStep('EoCampaignStepEmail'),
        className: 'add-eo-campaign-step-dropdown__email-step',
      },
      {
        Icon: ClockIcon,
        title: translate('eoCampaignStep.addEoCampaignStepMenu.EoCampaignStepWait.title'),
        subtitle: translate('eoCampaignStep.addEoCampaignStepMenu.EoCampaignStepWait.subtitle'),
        handleClick: handleAddEoCampaignStep('EoCampaignStepWait'),
        className: 'add-eo-campaign-step-dropdown__wait-step',
      },
    ];
  }

  return (
    <Dropdown
      className="add-eo-campaign-step-dropdown"
      id="add-eo-campaign-step-dropdown"
      onBlur={handleOnBlur}
      onToggle={handleOnToggle}
      open={dropdownIsOpen}
    >
      <CustomDropdownToggle bsRole="toggle" />
      <CustomDropdownMenu bsRole="menu">
        <div className="dropdown-menu dropdown-menu-left">
          <ul>
            {buildActions().map(({ Icon, title, subtitle, handleClick, className }) => {
              return (
                <li key={title} className={className} onMouseDown={handleClick}>
                  <Icon stroke="#777" />
                  <span className="add-eo-campaign-step-dropdown__item-title">{title}</span>
                  <span className="text_grey">{subtitle}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </CustomDropdownMenu>
    </Dropdown>
  );
};

AddEoCampaignStepDropdownComponent.propTypes = {
  onAddEoCampaignStep: PropTypes.func,
};

export default AddEoCampaignStepDropdownComponent;

// see https://react-bootstrap.github.io/components.html#btn-dropdowns-custom
// for more details about this crappy stuff
class CustomDropdownToggle extends Component {
  static propTypes = { onClick: PropTypes.func };
  shouldComponentUpdate() {
    return true;
  }
  render() {
    return (
      <ButtonComponent
        className="add-eo-campaign-step-dropdown__icon-wrapper"
        isWhite
        onClick={this.props.onClick}
      >
        <PlusInRoundIcon />

        <span className="text_grey add-eo-campaign-step-dropdown__label">
          {translate('eoCampaignStep.bulkActions.addEoCampaignStep')}
        </span>
      </ButtonComponent>
    );
  }
}

// see https://react-bootstrap.github.io/components.html#btn-dropdowns-custom
// for more details about this crappy stuff
class CustomDropdownMenu extends Component {
  static propTypes = { children: PropTypes.node };
  shouldComponentUpdate() {
    return true;
  }
  render() {
    return this.props.children;
  }
}
