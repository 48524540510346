import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoEmailTemplatesTableComponent from './EoEmailTemplatesTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { fetchEoEmailTemplatesByScope, fetchEoEmailTemplatesByFolder } from 'api/eoEmailTemplate';

import { eoEmailTemplatesList } from 'common/propTypesShapes/eoEmailTemplates';

import { eoEmailTemplatesSelector } from 'selectors/eoEmailTemplatesSelector';
import { currentIdSelector, uiSelector } from 'selectors';

import { resetSelectedIds, toggleSelectedIds } from 'actions/ui_actions';
import { userShape, selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const EoEmailTemplatesTableContainer = ({
  changeQueryParams,
  currentUser,
  dispatch,
  folderId,
  eoEmailTemplates,
  queryParams,
  selectedIds,
}) => {
  const {
    filters = {},
    pageNumber = 1,
    pagyItemsCount = 100,
    search = '',
    sorting = {},
    templateScope = '',
  } = queryParams;

  React.useEffect(() => {
    const templateScopeForApi = templateScope === 'all' ? '' : templateScope;
    const fetchingParams = {
      filters,
      items: pagyItemsCount,
      page: pageNumber,
      search,
      sorting,
      templateScope: templateScopeForApi,
    };

    if (folderId) {
      fetchEoEmailTemplatesByFolder(dispatch, folderId, fetchingParams);
    } else {
      fetchEoEmailTemplatesByScope(dispatch, fetchingParams);
    }
  }, [
    dispatch,
    JSON.stringify(filters),
    folderId,
    pageNumber,
    pagyItemsCount,
    search,
    sorting.sortBy,
    sorting.sortingOrder,
    templateScope,
  ]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectedIds());
    };
  }, [dispatch]);

  function handleToggleSelectedRows(eoEmailTemplatesIds, isSelected) {
    dispatch(toggleSelectedIds(eoEmailTemplatesIds, isSelected));
  }

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  return (
    <>
      {eoEmailTemplates.size > 0 && (
        <EoEmailTemplatesTableComponent
          currentUser={currentUser}
          onTableHeaderClick={handleTableHeaderClick}
          onToggleSelectedRows={handleToggleSelectedRows}
          eoEmailTemplates={eoEmailTemplates}
          selectedIds={selectedIds}
          sorting={sorting}
        />
      )}
      {eoEmailTemplates.size === 0 && (
        <BlankStateCtaComponent>
          <TitleText>
            <strong>{translate('eoEmailTemplate.table.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('eoEmailTemplate.table.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

EoEmailTemplatesTableContainer.propTypes = {
  currentUser: userShape,
  dispatch: PropTypes.func,
  eoEmailTemplates: eoEmailTemplatesList,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const folderId = currentIdSelector(state, ownProps);

  const eoEmailTemplates = eoEmailTemplatesSelector(state);

  const currentUser = state.getIn(['railsContext', 'cuser']);

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    currentUser,
    eoEmailTemplates,
    folderId,
    selectedIds,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoEmailTemplatesTableContainer);
