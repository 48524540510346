import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS, Map as iMap } from 'immutable';
import { keyBy, isEmpty } from 'lodash';

import { calculatePercentagesSum } from './utils';

import PercentagesFormPopupComponent from './PercentagesFormPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  createPercentageScheme,
  updatePercentageScheme,
  deletePercentageScheme,
} from 'api/percentage_scheme';

import { closePercentagesFormPopup } from 'actions/popup_actions';
import { fetchPagePercentage, updatePage } from 'api/brand_page';
import { optionsMap, percentageSchemesMap, policiesShape } from 'common/prop_types_shapes';
import { optionsSelector } from 'selectors';
import { policiesSelector } from 'selectors/railsContextSelectors';
import { startMouseFlowPageView, trackHelpcrunchEvent } from 'common/utils';
import { translate } from 'common/i18n';

class PercentagesFormPopupContainer extends React.Component {
  static propTypes = {
    anchorTypeOptions: optionsMap.isRequired,
    currentPercentageSchemesMap: percentageSchemesMap.isRequired,
    dispatch: PropTypes.func.isRequired,
    pageId: PropTypes.number,
    percentageSchemeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    policies: policiesShape.isRequired,
  };

  constructor(props) {
    super(props);
    const { currentPercentageSchemesMap, percentageSchemeId } = props;

    const currentPercentageScheme = currentPercentageSchemesMap.get(
      String(percentageSchemeId),
      iMap(),
    );

    this.state = {
      currentPercentageScheme,
      errors: {},
    };
  }

  componentDidMount() {
    startMouseFlowPageView(document.location.pathname);
  }

  handleClose = () => this.props.dispatch(closePercentagesFormPopup());

  handleDelete = () => {
    const { dispatch, percentageSchemeId } = this.props;

    deletePercentageScheme(dispatch, percentageSchemeId);
    this.handleClose();
  };

  handleSave = async (currentPercentageScheme) => {
    const { dispatch, pageId, percentageSchemeId } = this.props;

    const validationErrors = this.validateData(currentPercentageScheme);
    if (!isEmpty(validationErrors)) {
      return { errors: validationErrors };
    }

    const currentPercentageSchemeItems = currentPercentageScheme.get('percentageSchemeItems');
    const preparedPercentageScheme = currentPercentageScheme
      .set('percentageSchemeItems', currentPercentageSchemeItems.toList())
      .toJS();

    let resp = {};
    if (percentageSchemeId === 'newCustomScheme') {
      resp = await createPercentageScheme(dispatch, preparedPercentageScheme);
    } else {
      resp = await updatePercentageScheme(dispatch, preparedPercentageScheme);
    }

    if (!isEmpty(resp.errors)) {
      return resp;
    }

    if (percentageSchemeId === 'newCustomScheme') {
      trackHelpcrunchEvent('add.customPercentage');
    }

    if (pageId) {
      await updatePage(dispatch, pageId, { percentageSchemeId: resp.id });
      await fetchPagePercentage(dispatch, pageId);
    }

    return this.handleClose();
  };

  validateData = (currentPercentageScheme) => {
    const currentPercentageSchemeItems = currentPercentageScheme.get('percentageSchemeItems');
    const errors = {};

    if (!currentPercentageScheme.get('title')) {
      errors.title = translate('percentageSettings.savedPercentages.modal.errors.emptyTitle');
    }

    currentPercentageSchemeItems.forEach((item) => {
      const idealPercentageAsNumber = Number(item.get('idealPercentage'));

      const isIdealPercentageValid =
        !Number.isNaN(idealPercentageAsNumber) &&
        idealPercentageAsNumber >= 0 &&
        idealPercentageAsNumber <= 100;

      if (!isIdealPercentageValid) {
        errors.idealPercentage = translate(
          'percentageSettings.savedPercentages.modal.errors.notValidVIdealPercentage',
        );
      }
    });

    const percentageSummaryTotal = calculatePercentagesSum(currentPercentageSchemeItems);
    if (percentageSummaryTotal !== 100) {
      errors.percentageCounter = translate(
        'percentageSettings.savedPercentages.modal.errors.percentageNot100',
      );
    }

    return errors;
  };

  render() {
    const {
      anchorTypeOptions,
      currentPercentageSchemesMap,
      percentageSchemeId,
      policies,
    } = this.props;

    return (
      <PercentagesFormPopupComponent
        anchorTypeOptions={anchorTypeOptions}
        currentPercentageSchemesMap={currentPercentageSchemesMap}
        onCancel={this.handleClose}
        onDelete={this.handleDelete}
        onSave={this.handleSave}
        percentageSchemeId={percentageSchemeId}
        policies={policies}
      />
    );
  }
}

function select(state, ownProps) {
  const percentageSchemes = state.get('percentageSchemes');
  const policies = policiesSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);
  const anchorTypeOptions = options.get('anchorTypeOptions');
  // TODO: Move this to reselect
  let currentPercentageSchemesMap = percentageSchemes.filter((scheme) => {
    return scheme.get('id') === ownProps.percentageSchemeId;
  });

  if (ownProps.percentageSchemeId === 'newCustomScheme') {
    currentPercentageSchemesMap = newPercentageSchemesMap;
  }

  return { currentPercentageSchemesMap, policies, anchorTypeOptions };
}

const newPercentageSchemesMap = fromJS(
  keyBy(
    [
      {
        id: 'newCustomScheme',
        title: 'New',
        deletable: true,
        editable: true,
        percentageSchemeItems: {
          new_title_tag: {
            id: 'new_title_tag',
            anchorType: 'title_tag',
            idealPercentage: '0.0',
          },
          new_exact_keyword: {
            id: 'new_exact_keyword',
            anchorType: 'exact_keyword',
            idealPercentage: '0.0',
          },
          new_just_natural: {
            id: 'new_just_natural',
            anchorType: 'just_natural',
            idealPercentage: '0.0',
          },
          new_naked_uri: {
            id: 'new_naked_uri',
            anchorType: 'naked_uri',
            idealPercentage: '0.0',
          },
          new_keyword_plus_word: {
            id: 'new_keyword_plus_word',
            anchorType: 'keyword_plus_word',
            idealPercentage: '0.0',
          },
          new_only_part_of_keyword: {
            id: 'new_only_part_of_keyword',
            anchorType: 'only_part_of_keyword',
            idealPercentage: '0.0',
          },
          new_brand_and_keyword_together: {
            id: 'new_brand_and_keyword_together',
            anchorType: 'brand_and_keyword_together',
            idealPercentage: '0.0',
          },
          new_branded: {
            id: 'new_branded',
            anchorType: 'branded',
            idealPercentage: '0.0',
          },
          new_website_name_com: {
            id: 'new_website_name_com',
            anchorType: 'website_name_com',
            idealPercentage: '0.0',
          },
          new_no_text: {
            id: 'new_no_text',
            anchorType: 'no_text',
            idealPercentage: '0.0',
          },
          new_homepage_uri: {
            id: 'new_homepage_uri',
            anchorType: 'homepage_uri',
            idealPercentage: '0.0',
          },
          new_naked_uri_wo_http: {
            id: 'new_naked_uri_wo_http',
            anchorType: 'naked_uri_wo_http',
            idealPercentage: '0.0',
          },
          new_totally_random: {
            id: 'new_totally_random',
            anchorType: 'totally_random',
            idealPercentage: '0.0',
          },
        },
      },
    ],
    'id',
  ),
);

export default ConnectStoreHOC(connect(select)(PercentagesFormPopupContainer));
