import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';

import EoEmailTemplateFormPopup from '../EoEmailTemplatesBulkActions/EoEmailTemplateFormPopup';

import columnsGenerator from 'common/tables/columns_generator';

import { buildSelectedItems } from 'common/utils';
import { checkboxColumn, textColumn } from 'common/tables/columns';
import { eoEmailTemplatesList } from 'common/propTypesShapes/eoEmailTemplates';
import { userShape, selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';

import './eoEmailTemplatesTable.scss';

const EoEmailTemplatesTableComponent = ({
  currentUser,
  onTableHeaderClick,
  onToggleSelectedRows,
  eoEmailTemplates,
  selectedIds,
  sorting,
}) => {
  const processedEoEmailTemplates = buildSelectedItems(eoEmailTemplates.toJS(), selectedIds);

  const headerValueFormatter =
    (options = {}) =>
    (_value, extra) => {
      const { sortBy, sortingOrder } = sorting;
      const { withExplanationMessage } = options;
      const { property } = extra;

      const label = translate(`eoEmailTemplate.table.headers.${property}`);

      return (
        <HeaderFieldWithSorting
          appModule="eoEmailTemplates"
          label={label}
          onClick={onTableHeaderClick}
          property={property}
          sortBy={sortBy}
          sortingOrder={sortingOrder}
          withExplanationMessage={withExplanationMessage}
        />
      );
    };

  const handleOnToggleSelectRow = (eoEmailTemplateId) => (event) => {
    const isSelected = event.target.checked;

    onToggleSelectedRows([eoEmailTemplateId], isSelected);
  };

  function handleOnToggleSelectAll(event) {
    const isSelectedAll = event.target.checked;

    const eoEmailTemplatesIds = processedEoEmailTemplates
      .filter(({ userId }) => userId === currentUser.get('id'))
      .map((eoEmailTemplate) => eoEmailTemplate.id);

    onToggleSelectedRows(eoEmailTemplatesIds, isSelectedAll);
  }

  function isAllSelected() {
    const selectedRowsCount = processedEoEmailTemplates.filter(
      (eoEmailTemplate) => eoEmailTemplate.selected,
    ).length;
    const rowsCount = processedEoEmailTemplates.filter(
      ({ userId }) => userId === currentUser.get('id'),
    ).length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  }

  function cellCheckboxFormatters(_value, extra) {
    const {
      rowData: { selected, id, userId },
    } = extra;

    return (
      <>
        {userId === currentUser.get('id') && (
          <Checkbox
            checked={selected}
            className="eo-email-templates-table__checkbox"
            onChange={handleOnToggleSelectRow(id)}
          />
        )}
      </>
    );
  }

  function headerCheckboxFormatters() {
    return (
      <Checkbox
        checked={isAllSelected()}
        className="eo-email-templates-table__checkbox"
        onChange={handleOnToggleSelectAll}
      />
    );
  }

  function generateColumns() {
    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [headerCheckboxFormatters],
        cellFormatters: [cellCheckboxFormatters],
        className: 'eo-email-templates-table__checkbox-column',
      }),
      textColumn({
        name: 'title',
        headerValueFormatter: headerValueFormatter(),
        Popup: EoEmailTemplateFormPopup,
        className: 'eo-email-templates-table__title-column',
        isEditable: true,
      }),
      textColumn({
        name: 'folderTitle',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-email-templates-table__folder-column',
      }),
      textColumn({
        name: 'userName',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-email-templates-table__user-column',
      }),
      textColumn({
        name: 'brandName',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-email-templates-table__brand-column',
      }),
    ]);
  }

  const columns = generateColumns();

  return (
    <Table.Provider className="eo-email-templates-table" columns={columns}>
      <Table.Header />
      <Table.Body rowKey="id" rows={processedEoEmailTemplates} />
    </Table.Provider>
  );
};

EoEmailTemplatesTableComponent.propTypes = {
  currentUser: userShape,
  eoEmailTemplates: eoEmailTemplatesList,
  onTableHeaderClick: PropTypes.func,
  onToggleSelectedRows: PropTypes.func,
  selectedIds: selectedIdsList,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default EoEmailTemplatesTableComponent;
