import { handleAPIError, getFromAPI, postToAPI, processObjToSnakeCaseDeep } from './utils';

import { translate } from 'common/i18n';

import {
  fetchImpersonatesFailure,
  fetchImpersonatesStart,
  fetchImpersonatesSuccess,
  impersonateByUserIdFailure,
} from 'actions/impersonatesActions';

import { fetchImpersonatesPagySuccess } from 'actions/pagy_actions';

async function fetchImpersonates(dispatch, options = {}) {
  dispatch(fetchImpersonatesStart());

  const { pagyItemsCount: items, pageNumber: page, search } = options;
  const params = { items, page, search };

  try {
    const resp = await getFromAPI(Routes.impersonates_path(), processObjToSnakeCaseDeep(params));
    dispatch(fetchImpersonatesSuccess(resp.impersonates));
    dispatch(fetchImpersonatesPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchImpersonatesFailure,
      translate('errors.cantFetchImpersonates'),
    );
  }
}

async function impersonateByUserId(dispatch, id) {
  try {
    await postToAPI(Routes.impersonate_impersonate_path({ id }));
  } catch (err) {
    handleAPIError(dispatch, err, impersonateByUserIdFailure, translate('errors.cantSignIn'));
  }
}

export { fetchImpersonates, impersonateByUserId };
