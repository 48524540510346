import React from 'react';
import PropTypes from 'prop-types';

import { draftBrandPageShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import KeywordsManager from 'components_linkio/KeywordsManager';

import './editKeywordsPopup.scss';

const EditKeywordsPopupComponent = ({
  canSave,
  isUpdating,
  onCancelClick,
  onChangeKeywords,
  onSave,
  page,
  pageParentId,
  pageParentType,
  targetAppModule,
}) => {
  const {
    id,
    keywords = [],
    metaKeywords,
    title,
    errors: { keywords: keywordsError },
  } = page;

  return (
    <Modal show className="edit-keywords-popup__modal">
      <Modal.Header>
        <Modal.Title>{translate('bulkPagesSetup.editKeywordsPopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <KeywordsManager
          keywords={keywords}
          keywordsError={keywordsError}
          metaKeywords={metaKeywords}
          onChangeKeywords={onChangeKeywords}
          pageId={Number(id)}
          pageParentId={pageParentId}
          pageParentType={pageParentType}
          targetAppModule={targetAppModule}
          title={title}
        />
      </Modal.Body>

      <Modal.Footer>
        <div className="edit-keywords-popup__btn-group">
          <div className="edit-keywords-popup__btn">
            <ButtonComponent isWhite onClick={onCancelClick}>
              {translate('bulkPagesSetup.editKeywordsPopup.buttons.cancel')}
            </ButtonComponent>
          </div>
          <div className="edit-keywords-popup__btn">
            <ButtonComponent isLoading={isUpdating} isGreen onClick={onSave} isDisabled={!canSave}>
              {translate('bulkPagesSetup.editKeywordsPopup.buttons.save')}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EditKeywordsPopupComponent.propTypes = {
  canSave: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool,
  onCancelClick: PropTypes.func.isRequired,
  onChangeKeywords: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  page: draftBrandPageShape,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageParentType: PropTypes.oneOf(['brand_page', 'brand']),
  targetAppModule: PropTypes.oneOf(['atp', 'bulkCompetitorsSetup', 'bulkPagesSetup']),
};

export default EditKeywordsPopupComponent;
