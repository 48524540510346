import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EoDashboardChartsComponent from './EoDashboardChartsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchEoDashboardMetrics } from 'api/eoDashboard';
import { eoDashboardMetricsSelector } from 'selectors/eoDashboardSelector';
import { eoDashboardMetricsMap } from 'common/propTypesShapes/eoMetrics';

const EoDashboardChartsContainer = ({ dispatch, eoDashboardMetrics }) => {
  React.useEffect(() => {
    fetchEoDashboardMetrics(dispatch);
  }, [dispatch]);

  return <EoDashboardChartsComponent eoDashboardMetrics={eoDashboardMetrics} />;
};

EoDashboardChartsContainer.propTypes = {
  dispatch: PropTypes.func,
  eoDashboardMetrics: eoDashboardMetricsMap,
};

function select(state) {
  const eoDashboardMetrics = eoDashboardMetricsSelector(state);

  return {
    eoDashboardMetrics,
  };
}

export default ConnectStoreHOC(connect(select)(EoDashboardChartsContainer));
