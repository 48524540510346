import React from 'react';

const HumansIcon = (props) => (
  <svg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 0a1.867 1.867 0 100 3.733A1.867 1.867 0 008 0zm0 3.2A1.333 1.333 0 118 .533 1.333 1.333 0 018 3.2zM8.8 4H7.2a2.403 2.403 0 00-2.4 2.4v4.533h1.067V16H6.4V9.867h-.533v.533h-.534v-4A1.869 1.869 0 017.2 4.533h1.6A1.869 1.869 0 0110.667 6.4v4h-.534v-.533H9.6V16h.533v-5.067H11.2V6.4A2.403 2.403 0 008.8 4z" />
    <path d="M8.267 10.933h-.534V16h.534v-5.067zM13.333 2.667a1.6 1.6 0 100 3.2 1.6 1.6 0 000-3.2zm0 2.666a1.067 1.067 0 110-2.133 1.067 1.067 0 010 2.133zM14.133 6.133h-2.4v.534h2.4c.736 0 1.333.597 1.334 1.333v3.467h-.534v-.534H14.4V16h.533v-4H16V8a1.869 1.869 0 00-1.867-1.867zM11.733 10.933v.534h-1.066V12h1.066v4h.534v-5.067h-.534z" />
    <path d="M13.6 12h-.533v4h.533v-4zM2.667 2.667a1.6 1.6 0 100 3.2 1.6 1.6 0 000-3.2zm0 2.666a1.067 1.067 0 110-2.133 1.067 1.067 0 010 2.133zM1.867 6.133A1.869 1.869 0 000 8v4h1.067v4H1.6v-5.067h-.533v.534H.533V8c.001-.736.598-1.332 1.334-1.333h2.4v-.534h-2.4zM4.267 11.467v-.534h-.534V16h.534v-4h1.066v-.533H4.267z" />
    <path d="M2.933 12H2.4v4h.533v-4z" />
  </svg>
);

HumansIcon.defaultProps = {
  height: '16px',
  width: '16px',
};

export default HumansIcon;
