import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, isInteger, get, reduce, intersection, without } from 'lodash';
import { parse, format } from 'date-fns';

import BacklinksPageComponent from './BacklinksPageComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';

import { METRICS_FILTERS } from 'utils/constants';

import { pagyShape, withRouterPropTypes } from 'common/prop_types_shapes';

import { currentIdSelector } from 'selectors';

import { backlinksPagyByBrandIdSelector } from 'selectors/backlinkSelector';

import Breadcrumbs from 'components/NewBreadcrumbs';
import BreadcrumbsBrandsSelect from 'components/NewBreadcrumbs/BreadcrumbsBrandsSelect';
import ButtonComponent from 'components_linkio/button_component';

const DATE_RANGE_PARAMS = {
  new_links: {
    start: 'published_date_beginning_of_day_gteq',
    end: 'published_date_end_of_day_lteq',
  },
  lost_links: 'lost_during',
  re_discovered_links: 're_discovered_during',
};

const buildDateRangeProps = (queryParams, backlinksPageType = '') => {
  const takeFromQs = (paramName) => get(queryParams, ['filters', paramName], undefined);

  if (!DATE_RANGE_PARAMS[backlinksPageType]) {
    return {};
  }

  const dateStringRange =
    typeof DATE_RANGE_PARAMS[backlinksPageType] === 'string'
      ? takeFromQs(DATE_RANGE_PARAMS[backlinksPageType])
      : [
          takeFromQs(DATE_RANGE_PARAMS[backlinksPageType].start),
          takeFromQs(DATE_RANGE_PARAMS[backlinksPageType].end),
        ];

  const [dateStart, dateEnd] = dateStringRange.map((dateStr) =>
    parse(dateStr, 'yyyy-MM-dd', new Date()),
  );

  return {
    dateStart,
    dateEnd,
  };
};

const DATE_RANGE_PARAMS_LIST = reduce(
  DATE_RANGE_PARAMS,
  (acc, item) => (typeof item === 'string' ? [...acc, item] : [...acc, item.start, item.end]),
  [],
);

const hasDateRange = ({ filters = {} }) => {
  return intersection(Object.keys(filters), DATE_RANGE_PARAMS_LIST).length > 0;
};

const hasFilters = ({ filters = {} }) => {
  // 'not_a_double' is a predefined filter, which can't be changed by the user.
  // So don't count it like a user applied filter
  return without(Object.keys(filters), ...DATE_RANGE_PARAMS_LIST, 'not_a_double').length > 0;
};

class BacklinksPageContainer extends Component {
  static propTypes = {
    backlinksPagy: pagyShape,
    brandId: PropTypes.string,
    dispatch: PropTypes.func,
    ...withRouterPropTypes,
    ...withQueryParamsPropTypes,
  };

  handlePagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const { queryParams, changeQueryParams } = this.props;

      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  handleItemsSelectChange = ({ value }) => {
    const { queryParams, changeQueryParams } = this.props;

    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  };

  handleAddBacklinksClick = () =>
    this.props.history.push(Routes.import_backlink_path(this.props.brandId, { format: null }));

  changeDateRange = (dateStart, dateEnd) => {
    const {
      queryParams,
      changeQueryParams,
      match: {
        params: { backlinksPageType },
      },
    } = this.props;

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const dateRangeFilters =
      typeof DATE_RANGE_PARAMS[backlinksPageType] === 'string'
        ? {
            [DATE_RANGE_PARAMS[backlinksPageType]]: [formatDate(dateStart), formatDate(dateEnd)],
          }
        : {
            [DATE_RANGE_PARAMS[backlinksPageType].start]: formatDate(dateStart),
            [DATE_RANGE_PARAMS[backlinksPageType].end]: formatDate(dateEnd),
          };

    const newQueryParams = {
      ...queryParams,
      filters: {
        ...(queryParams.filters || {}),
        ...(METRICS_FILTERS[queryParams.metricsFilter] || {}),
        ...dateRangeFilters,
      },
    };

    changeQueryParams(newQueryParams);
  };

  rightSideBtn = () => {
    return (
      <ButtonComponent isGreen onClick={this.handleAddBacklinksClick}>
        {translate('backlinks.buttons.addBacklinks')}
      </ButtonComponent>
    );
  };

  buildPageTitle = () => {
    const { brandId, match } = this.props;

    switch (match.url) {
      case Routes.backlink_path(brandId, { format: null }):
        return translate('sidebar.backlinks.all.text');
      case Routes.new_links_backlink_path(brandId, { format: null }):
        return translate('sidebar.backlinks.new.text');
      case Routes.lost_links_backlink_path(brandId, { format: null }):
        return translate('sidebar.backlinks.lost.text');
      case Routes.re_discovered_links_backlink_path(brandId, { format: null }):
        return translate('sidebar.backlinks.reDiscovered.text');
      default:
        return void 0;
    }
  };

  buildTargetAppModule() {
    const {
      match: {
        params: { backlinksPageType = '' },
      },
    } = this.props;

    return backlinksPageType ? `backlinks_${backlinksPageType}` : 'backlinks';
  }

  render() {
    const {
      backlinksPagy,
      queryParams,
      match: {
        params: { backlinksPageType },
      },
    } = this.props;

    const title = this.buildPageTitle();

    return (
      <>
        <Breadcrumbs rightSideBtn={this.rightSideBtn()}>
          <Breadcrumbs.LinkItem to={Routes.root_path({ format: null })}>
            {translate('brand.list.title')}
          </Breadcrumbs.LinkItem>
          <Breadcrumbs.TextItem>{translate('backlinks.breadcrumbsTitle')}</Breadcrumbs.TextItem>
          <BreadcrumbsBrandsSelect targetAppModule={this.buildTargetAppModule()} />
        </Breadcrumbs>

        <div className="page__wrapper">
          <BacklinksPageComponent
            backlinksPageType={backlinksPageType}
            backlinksPagy={backlinksPagy}
            changeDateRange={this.changeDateRange}
            onItemsSelectChange={this.handleItemsSelectChange}
            onPagerClick={this.handlePagerClick}
            title={title}
            queryParams={queryParams}
            {...buildDateRangeProps(queryParams, backlinksPageType)}
            hasDateRange={hasDateRange(queryParams)}
            hasFilters={hasFilters(queryParams)}
          />
        </div>
      </>
    );
  }
}

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);

  const backlinksPagy = backlinksPagyByBrandIdSelector(state, brandId);

  return {
    backlinksPagy,
    brandId,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(BacklinksPageContainer);
