import pluralize from 'pluralize';

export default {
  competitorsPage: {
    title: 'Competitors',
    messages: {
      createSuccessfully: 'Successfully added a page.',
      deleteSuccessfully: 'Page was deleted successfully.',
      linksImportedFromAhrefsSuccessfully: (args) =>
        `Successfully imported ${pluralize(
          'anchor',
          args.uniqEntriesCount,
          true,
        )},
        ${pluralize('duplicate', args.dupeEntriesCount, true)} skipped.
        Linkio will now check backlinks and notify you once it calculates the percentages.`,
      linksImportedFromMozSuccessfully: (args) =>
        `Successfully imported ${pluralize(
          'anchor',
          args.uniqEntriesCount,
          true,
        )},
        ${pluralize('duplicate', args.dupeEntriesCount, true)} skipped.
        Linkio will now check backlinks and notify you once it calculates the percentages.`,
    },
    destinationLink: {
      buttons: {
        exportCSV: 'Export Backlinks',
      },
    },
    metrics: {
      countReferringDomains: {
        title: 'Referring Domains',
      },
      linkioScore: {
        title: 'Anchor Text Score',
      },
    },
    buttons: {
      actions: 'Actions',
      addPage: 'Add Competitors',
      addPageAndGenerateRoadmap: 'Add Page & Generate Roadmap',
      delete: 'Delete',
      edit: 'Edit',
    },
    keywords: {
      title: 'Keywords',
    },
    link: {
      title: 'Page URL',
    },
    pageTypeId: {
      title: 'Page Type',
    },
    priority: {
      title: 'Priority',
    },
    addPageModal: {
      title: 'Add Competitor Page',
      subtitle:
        "Add the competitor's page URL that ranks in Google for your target keyword",
      inputs: {
        brandHomepage: {
          label: 'Homepage',
        },
        brandOtherNames: {
          label: 'Brand Name',
        },
        pageUrl: {
          label: 'Page URL',
        },
        pageTypeId: {
          label: 'Page type',
        },
        keywords: {
          label: 'Keywords',
          hint: 'Please add one keyword per line',
        },
      },
      buttons: {
        cancel: 'Cancel',
        saveAndGenerate: 'Save & Generate',
      },
    },
    editPageModal: {
      title: 'Edit Page',
      subtitle: 'Edit the page for the selected brand',
      inputs: {
        brandHomepage: {
          label: 'Homepage',
        },
        brandOtherNames: {
          label: 'Brand Name',
        },
        pageUrl: {
          label: 'Page URL',
        },
        pageTypeId: {
          label: 'Page type',
        },
      },
      buttons: {
        cancel: 'Close',
        save: 'Save',
      },
    },
    blankState: {
      titleText: (url) =>
        `It looks like you don't have any competitors for the ${url} URL`,
      bodyText:
        'You can easily add new competitors by clicking on the "Add Competitors" button',
    },
    noDlsComponent: {
      tabs: {
        competitors: {
          title: 'Import Some Backlinks!',
        },
      },
    },
  },
};
