import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import DeleteEoSchedulersButtonComponent from './DeleteEoSchedulersButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { currentIdSelector, uiSelector } from 'selectors';
import { deleteEoSchedulers } from 'api/eoScheduler';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import confirmationDialogue from 'components/confirmation_dialogue';

const DeleteEoSchedulersButtonContainer = ({ dispatch, eoCampaignId, selectedIds }) => {
  function handleDelete() {
    confirmationDialogue({
      body: translate('confirmations.default.title'),
      onConfirm: async () => await deleteEoSchedulers(dispatch, eoCampaignId, selectedIds.toJS()),
    });
  }

  return <DeleteEoSchedulersButtonComponent onDelete={handleDelete} selectedIds={selectedIds} />;
};

DeleteEoSchedulersButtonContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    eoCampaignId,
    selectedIds,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(DeleteEoSchedulersButtonContainer);
