import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';

const eoEmailTemplatesData = (state) => state.get('eoEmailTemplatesData', iMap());
const eoEmailTemplatesPagy = (state) => state.getIn(['pagies', 'eoEmailTemplatesPagy'], iMap());

export const eoEmailTemplatesSelector = createSelector(
  [eoEmailTemplatesData],
  (eoEmailTemplatesData) => eoEmailTemplatesData.get('eoEmailTemplates', iList()),
);

export const eoEmailTemplatesCountSelector = createSelector(
  [eoEmailTemplatesData],
  (eoEmailTemplatesData) => eoEmailTemplatesData.get('templateCount', iMap()),
);

export const eoEmailTemplatesPagySelector = createSelector([eoEmailTemplatesPagy], (pagy) => pagy);
