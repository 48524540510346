import React, { Component } from 'react';

import RenderInBodyComponent from '../render_in_body_component';

import './loading_component.scss';

const ALLOWED_ACTION_LENGTH = 2500;


class LoadingComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOverlay: false,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(this.showOverlay, ALLOWED_ACTION_LENGTH);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  timeoutId;

  showOverlay = () => {
    this.setState({ showOverlay: true });
  }

  overlay = (
    <RenderInBodyComponent>
      <div className="loading-component__overlay" />
    </RenderInBodyComponent>
  );

  render() {
    const { showOverlay } = this.state;

    return (showOverlay && this.overlay);
  }
}

export default LoadingComponent;
