import React from 'react';

const DownArrowIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4">
      <path fillRule="evenodd" d="M3.5355 2.657L1.059.1803c-.2-.2-.5102-.1937-.7054.0016C.164.3714.1576.693.352.8874L3.1835 3.719c.0997.1.227.1483.3535.1476.125-.0036.2536-.0506.3505-.1475L6.719.8877c.2002-.2.1938-.5103-.0015-.7055-.1895-.1895-.511-.196-.7055-.0015L3.5355 2.657z" />
    </svg>
  );
};

DownArrowIcon.defaultProps = {
  fill: '#474a4d',
  width: '14px',
  height: '14px',
};

export default DownArrowIcon;
