import React from 'react';
import PropTypes from 'prop-types';

import ShowHideButtonsComponent from 'components_linkio/show_hide_buttons_component';

import './competitorAnalysisTableHeadline.scss';

const CompetitorAnalysisTableHeadlineComponent = ({
  showHideButtonsOptions,
  title,
  isPercentagesTable,
}) => {
  return (
    <div className="competitors-table-headline">
      <div className="competitors-table-headline__left-side">
        {isPercentagesTable && (
          <ShowHideButtonsComponent
            showIsDisabled={showHideButtonsOptions.showIsDisabled}
            onClickShow={showHideButtonsOptions.onClick(true)}
            hideIsDisabled={showHideButtonsOptions.hideIsDisabled}
            onClickHide={showHideButtonsOptions.onClick(false)}
          />
        )}
        <h3 className="competitors-table__title">{title}</h3>
      </div>
    </div>
  );
};

CompetitorAnalysisTableHeadlineComponent.propTypes = {
  isPercentagesTable: PropTypes.bool,
  showHideButtonsOptions: PropTypes.shape({
    showIsDisabled: PropTypes.bool.isRequired,
    hideIsDisabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  title: PropTypes.string.isRequired,
};

export default CompetitorAnalysisTableHeadlineComponent;
