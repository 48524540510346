import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';

import EoActiveHourFormPopup from '../EoActiveHoursBulkActions/EoActiveHourFormPopup';

import columnsGenerator from 'common/tables/columns_generator';

import { buildSelectedItems } from 'common/utils';
import { checkboxColumn, textColumn } from 'common/tables/columns';
import { eoActiveHoursList } from 'common/propTypesShapes/eoActiveHours';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';

import './eoActiveHoursTable.scss';

const EoActiveHoursTableComponent = ({
  onTableHeaderClick,
  onToggleSelectedRows,
  eoActiveHours,
  selectedIds,
  sorting,
}) => {
  const processedEoActiveHours = buildSelectedItems(eoActiveHours.toJS(), selectedIds);

  const headerValueFormatter = (options = {}) => (_value, extra) => {
    const { sortBy, sortingOrder } = sorting;
    const { withExplanationMessage } = options;
    const { property } = extra;

    const label = translate(`eoActiveHour.table.headers.${property}`);

    return (
      <HeaderFieldWithSorting
        appModule="eoActiveHours"
        label={label}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  };

  const handleOnToggleSelectRow = (eoActiveHourId) => (event) => {
    const isSelected = event.target.checked;

    onToggleSelectedRows([eoActiveHourId], isSelected);
  };

  function handleOnToggleSelectAll(event) {
    const isSelectedAll = event.target.checked;

    const eoActiveHoursIds = processedEoActiveHours.map((eoActiveHour) => eoActiveHour.id);

    onToggleSelectedRows(eoActiveHoursIds, isSelectedAll);
  }

  function isAllSelected() {
    const selectedRowsCount = processedEoActiveHours.filter((eoActiveHour) => eoActiveHour.selected)
      .length;
    const rowsCount = processedEoActiveHours.length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  }

  function cellCheckboxFormatters(_value, extra) {
    const {
      rowData: { selected, id },
    } = extra;

    return (
      <Checkbox
        checked={selected}
        className="eo-active-hours-table__checkbox"
        onChange={handleOnToggleSelectRow(id)}
      />
    );
  }

  function headerCheckboxFormatters() {
    return (
      <Checkbox
        checked={isAllSelected()}
        className="eo-active-hours-table__checkbox"
        onChange={handleOnToggleSelectAll}
      />
    );
  }

  function activeDaysCellValueFormatter(value) {
    return value
      .filter(({ enabled }) => enabled)
      .map(({ weekday }) => weekday)
      .join(', ');
  }

  function generateColumns() {
    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [headerCheckboxFormatters],
        cellFormatters: [cellCheckboxFormatters],
        className: 'eo-active-hours-table__checkbox-column',
      }),
      textColumn({
        name: 'name',
        headerValueFormatter: headerValueFormatter(),
        Popup: EoActiveHourFormPopup,
        className: 'eo-active-hours-table__name-column',
        isEditable: true,
      }),
      textColumn({
        name: 'eoActiveHoursSettings',
        headerLabel: translate('eoActiveHour.table.headers.eoActiveHoursSettings'),
        cellValueFormatter: activeDaysCellValueFormatter,
        className: 'eo-active-hours-table__active-days-column',
      }),
    ]);
  }

  const columns = generateColumns();

  return (
    <Table.Provider className="eo-active-hours-table" columns={columns}>
      <Table.Header />
      <Table.Body rowKey="id" rows={processedEoActiveHours} />
    </Table.Provider>
  );
};

EoActiveHoursTableComponent.propTypes = {
  eoActiveHours: eoActiveHoursList,
  onTableHeaderClick: PropTypes.func,
  onToggleSelectedRows: PropTypes.func,
  selectedIds: selectedIdsList,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default EoActiveHoursTableComponent;
