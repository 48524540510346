import React from 'react';

const MagnifierIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        fill="$charcoal-grey-two"
        fillRule="evenodd"
        d="M9.2222 3.0326c-1.7096-1.7095-4.48-1.7095-6.1896 0-1.7095 1.7096-1.7095 4.48 0 6.1896 1.7096 1.7095 4.48 1.7095 6.1896 0 1.7095-1.7096 1.7095-4.48 0-6.1896M13.774 13.774c-.3423.3413-.8964.3413-1.2386 0L9.779 11.0166c-2.4003 1.7945-5.8036 1.6247-7.984-.5567-2.3933-2.3926-2.3933-6.272 0-8.665 2.3922-2.3935 6.2726-2.3935 8.665 0 2.1813 2.1802 2.351 5.5835.5566 7.984l2.7574 2.757c.3413.3414.3413.8955 0 1.238"
      />
    </svg>
  );
};

MagnifierIcon.defaultProps = {
  fill: '#757575',
  width: '14px',
  height: '14px',
};

export default MagnifierIcon;
