import React from 'react';
import { proxiesMap } from '../../common/prop_types_shapes';

import './proxiesQuickStatusComponent.scss';


const ProxiesQuickStatusComponent = ({
  proxies,
}) => {
  const total = proxies.size;
  const aliveCount = proxies.filter((proxy) => proxy.get('alive')).size;
  const alivePercentage = Math.round(aliveCount / total * 100);

  return (
    <div className="proxies-quick-status-component">
      <h3>
        {`${aliveCount} of ${total} proxies are alive (${alivePercentage}%) `}
      </h3>
    </div>
  );
};

ProxiesQuickStatusComponent.propTypes = {
  proxies: proxiesMap.isRequired,
};

export default ProxiesQuickStatusComponent;
