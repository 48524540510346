import { isEmpty } from 'lodash';

import { fetchRailsContext } from './rails_context';

import {
  deleteToAPI,
  handleAPIError,
  getFromAPI,
  patchToAPI,
  postToAPI,
  processObjToSnakeCaseDeep,
} from './utils';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import { showSuccessMessage, showErrorMessage } from 'common/utils';
import { translate } from 'common/i18n';

import {
  createEoInboxFailure,
  createEoInboxStart,
  createEoInboxSuccess,
  connectGmailEoInboxFailure,
  deleteEoInboxFailure,
  deleteEoInboxStart,
  deleteEoInboxSuccess,
  fetchEoInboxesSettingsFailure,
  fetchEoInboxesSettingsStart,
  fetchEoInboxesSettingsSuccess,
  fetchEoInboxFailure,
  fetchEoInboxStart,
  fetchEoInboxSuccess,
  fetchEoInboxesFailure,
  fetchEoInboxesStart,
  fetchEoInboxesSuccess,
  updateEoInboxFailure,
  updateEoInboxStart,
  updateEoInboxSuccess,
  updateEoInboxesSettingsFailure,
} from 'actions/eoInboxesSettingsActions';

export async function createEoInbox(dispatch, eoInbox) {
  dispatch(createEoInboxStart());

  try {
    const resp = await postToAPI(
      Routes.eo_inboxes_path({ eo_inbox: processObjToSnakeCaseDeep(eoInbox) }),
    );

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(createEoInboxSuccess(resp));
      showSuccessMessage(dispatch, translate('eoInboxesSettings.messages.createEoInboxSuccess'));
      fetchRailsContext(dispatch);
    } else {
      dispatch(createEoInboxFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createEoInboxFailure,
      translate('eoInboxesSettings.messages.cantCreateEoInbox'),
    );
  }
}

export async function deleteEoInbox(dispatch, eoInboxId) {
  dispatch(deleteEoInboxStart());

  try {
    await deleteToAPI(Routes.eo_inbox_path(eoInboxId)).then(() => fetchRailsContext(dispatch));

    showSuccessMessage(dispatch, translate('eoInboxesSettings.messages.deleteEoInboxSuccess'));

    dispatch(deleteEoInboxSuccess(eoInboxId));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoInboxFailure,
      translate('eoInboxesSettings.messages.cantDeleteEoInbox'),
    );
  }
}

export async function fetchEoInboxes(dispatch) {
  dispatch(fetchEoInboxesStart());

  try {
    const resp = await getFromAPI(Routes.eo_inboxes_path());

    dispatch(fetchEoInboxesSuccess(resp.eo_inboxes));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoInboxesFailure,
      translate('eoInboxesSettings.messages.cantFetchEoInboxes'),
    );
  }
}

export async function fetchEoInbox(dispatch, eoInboxId) {
  dispatch(fetchEoInboxStart());

  try {
    const resp = await getFromAPI(Routes.eo_inbox_path(eoInboxId));

    dispatch(fetchEoInboxSuccess(resp));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoInboxFailure,
      translate('eoInboxesSettings.messages.cantFetchEoInbox'),
    );
  }
}

export async function fetchEoInboxesSettings(dispatch) {
  dispatch(fetchEoInboxesSettingsStart());

  try {
    const resp = await getFromAPI(Routes.show_eo_inboxes_settings_path());

    dispatch(fetchEoInboxesSettingsSuccess(resp));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoInboxesSettingsFailure,
      translate('eoInboxesSettings.messages.cantFetchEoInboxesSettings'),
    );
  }
}

export async function updateEoInbox(dispatch, eoInbox) {
  dispatch(updateEoInboxStart());

  try {
    const resp = await patchToAPI(Routes.eo_inbox_path({ id: eoInbox.id }), {
      eo_inbox: processObjToSnakeCaseDeep(eoInbox),
    });

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(updateEoInboxSuccess(resp));
      showSuccessMessage(dispatch, translate('eoInboxesSettings.messages.updateEoInboxSuccess'));
    } else {
      dispatch(updateEoInboxFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoInboxFailure,
      translate('eoInboxesSettings.messages.cantUpdateEoInbox'),
    );
  }
}

export async function updateEoInboxesSettings(dispatch, settings) {
  try {
    const resp = await postToAPI(Routes.save_eo_inboxes_settings_path(), {
      eo_inboxes_setting: processObjToSnakeCaseDeep(settings),
    });

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(fetchEoInboxesSettingsSuccess(resp));
      showSuccessMessage(
        dispatch,
        translate('eoInboxesSettings.messages.updateEoInboxesSettingsSuccess'),
      );
    } else {
      dispatch(updateEoInboxesSettingsFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoInboxesSettingsFailure,
      translate('eoInboxesSettings.messages.cantUpdateEoInboxesSettings'),
    );
  }
}

export async function verifyEoInboxAvailability(dispatch, eoInboxId) {
    try {
    const resp = await postToAPI(Routes.verify_availability_eo_inbox_path({ id: eoInboxId }));

    showSuccessMessage(dispatch, translate('eoInboxesSettings.messages.startVerifyAvailability'));
    dispatch(updateEoInboxSuccess(resp));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoInboxesSettingsFailure,
      translate('eoInboxesSettings.messages.cantVerifyAvailability'),
    );
  }
}

export async function eoInboxCheckReplies(dispatch, eoInboxId) {
  try {
    const resp = await postToAPI(Routes.check_replies_eo_inbox_path({ id: eoInboxId }));

    showSuccessMessage(dispatch, translate('eoInboxesSettings.messages.startEoInboxCheckReplies'));
    dispatch(updateEoInboxSuccess(resp));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoInboxesSettingsFailure,
      translate('eoInboxesSettings.messages.cantStartEoInboxCheckReplies'),
    );
  }
}

export async function connectGmailEoInbox(dispatch) {
  try {
    const resp = await getFromAPI(Routes.connect_gmail_inbox_eo_inboxes_path());

    const redirectUri = (resp || {}).redirect_uri;

    if (redirectUri) {
      window.location = redirectUri;
    }

    const errors = (resp || {}).errors;

    if (!isEmpty(errors)) {
      showErrorMessage(dispatch, translate('eoInboxesSettings.messages.gmailAccessDenied'), true);
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      connectGmailEoInboxFailure,
      translate('eoInboxesSettings.messages.cantConnectGmailInbox'),
    );
  }
}
