import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';
import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  brandPagesMap,
  matchShape,
  optionsMap,
  pagyShape,
  subscriptionPoliciesShape,
} from 'common/prop_types_shapes';

import Pager from 'components/Pager';
import PageDashboard from 'pages/Pages/PagesList/PageItem/PageDashboard';
import PagesListHeader from 'pages/Pages/PagesList/PagesListHeader';
import PageItem from 'pages/Pages/PagesList/PageItem';

import BrandPageContainer from 'components/atp/brand_page_container';

const BrandPagesListComponent = (props) => {
  const {
    brandPages,
    match,
    onClickUpgradeSubscription,
    onPagesPagerClick,
    onPagesPagerItemsSelectChange,
    pagesPagy,
    subscriptionPolicies,
    queryParams,
  } = props;

  const processBrandPages = () => {
    const showAtpSuggestions = (queryParams.pagesFilter || {}).showAtpSuggestions === 'true';
    const firstBrandPageId = brandPages.first().get('id');
    const canUseCrawlingInSubscription = subscriptionPolicies.getIn(['crawlers', 'canUse']);
    const visiblePageId = Number(match.params.pageId);
    let previousBrandPageId = void 0;

    return brandPages
      .map((brandPage, keyId) => {
        const keyIdNumber = Number(keyId);
        const isOpen = visiblePageId === keyIdNumber;
        const isFirst = keyIdNumber === firstBrandPageId;
        const isNextAfterOpen = !isFirst && !isOpen && previousBrandPageId === visiblePageId;

        previousBrandPageId = Number(brandPage.get('id', iMap()));

        return (
          <Fragment key={keyId}>
            {(isOpen || isFirst || isNextAfterOpen) && (
              <PagesListHeader pageChildType={'brandPage'} />
            )}
            <div className="brand-page-list__item">
              <PageItem
                pageParentType={'brand'}
                pageChildType={'brandPage'}
                page={brandPage}
                isOpen={isOpen}
              />
              {showAtpSuggestions && !isOpen && <PageDashboard brandPageId={keyId} />}
              {isOpen && (
                <BrandPageContainer
                  {...props}
                  brandPageId={keyId}
                  brandPage={brandPage}
                  canUseCrawlingInSubscription={canUseCrawlingInSubscription}
                  onClickUpgradeSubscription={onClickUpgradeSubscription}
                />
              )}
            </div>
          </Fragment>
        );
      })
      .valueSeq()
      .toArray();
  };

  return (
    <div className="brand-page-list">
      {processBrandPages()}

      <Pager
        handleItemsSelectChange={onPagesPagerItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="pagesPager"
        pagy={pagesPagy}
      />
    </div>
  );
};

BrandPagesListComponent.propTypes = {
  anchorTypeOptions: optionsMap,
  brandPages: brandPagesMap,
  dlSourcesOptions: optionsMap,
  match: matchShape,
  nofollowOptions: optionsMap,
  onClickUpgradeSubscription: PropTypes.func,
  onCreateNewBrandPage: PropTypes.func,
  onDeleteBrandPage: PropTypes.func,
  onImportCsvToBrandPage: PropTypes.func,
  onImportFromAhrefsToBrandPage: PropTypes.func,
  onImportPublishedLinks: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
  onPagesPagerItemsSelectChange: PropTypes.func,
  onRecalculatePercentage: PropTypes.func,
  onUpdateBrandPage: PropTypes.func,
  pageTypeOptions: optionsMap,
  pagesPagy: pagyShape,
  statusOptions: optionsMap,
  subscriptionPolicies: subscriptionPoliciesShape.isRequired,
};

export default ConnectStoreHOC(connect()(BrandPagesListComponent));
