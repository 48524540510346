import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, sortBy } from 'lodash';

import { eoCustomFieldsList } from 'common/propTypesShapes/eoCustomFields';
import { optionsList } from 'common/prop_types_shapes';
import { prospectShape } from 'common/propTypesShapes/prospects';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import Select from 'components_linkio/Select';
import SubmitButtonComponent from 'components/auth/submit_button_component';

import './prospectFormPopup.scss';

const initialState = {
  email: '',
  eoCustomFieldValues: [],
  firstName: '',
  lastName: '',
  organization: '',
  domain: '',
  position: '',
  role: '',
  phone: '',
  eoDripListIds: [],
  errors: {},
};

function reducer(state, action) {
  const { value, type } = action;

  const newState = cloneDeep(state);

  switch (type) {
    case 'setCustomFields': {
      const { eoCustomFieldName, inputValue } = value;

      newState.eoCustomFieldValues.find(
        ({ name }) => name === eoCustomFieldName,
      ).value = inputValue;
      break;
    }
    case 'setDripListIds': {
      newState.eoDripListIds = value.map(({ value }) => value);
      break;
    }
    default:
      newState[type] = value;
      break;
  }

  return newState;
}

const formInputs = [
  {
    name: 'firstName',
    type: 'text',
  },
  {
    name: 'lastName',
    type: 'text',
  },
  {
    name: 'email',
    type: 'email',
    isRequired: true,
  },
  {
    name: 'organization',
    type: 'text',
  },
  {
    name: 'domain',
    type: 'text',
  },
  {
    name: 'position',
    type: 'text',
  },
  {
    name: 'role',
    type: 'text',
  },
  {
    name: 'phone',
    type: 'text',
  },
];

const ProspectFormPopupComponent = ({
  immutableEoDripListsOptions,
  immutableEoCustomFields,
  isFetchingEoCustomFields,
  onCancel,
  onSave,
  prospect,
}) => {
  const [state, dispatch] = React.useReducer(reducer, prospect || initialState);

  const eoDripListsOptions = immutableEoDripListsOptions.toJS();
  const eoCustomFields = immutableEoCustomFields.toJS();

  const isExistingProspect = !!prospect;

  React.useEffect(() => {
    // If the user creates a new prospect then merge fetched custom fields to this prospect
    if (!isExistingProspect && !isFetchingEoCustomFields) {
      const processedEoCustomFields = eoCustomFields.map(({ id, name, defaultValue }) => ({
        eoCustomFieldId: id,
        name,
        value: '',
        defaultValue,
      }));

      dispatch({ type: 'eoCustomFieldValues', value: processedEoCustomFields });
    }
  }, [isFetchingEoCustomFields]);

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  function onChangeDripList(value) {
    dispatch({ type: 'setDripListIds', value: value || [] });
  }

  const setCustomFields = (eoCustomFieldName) => (event) => {
    dispatch({
      type: 'setCustomFields',
      value: { eoCustomFieldName, inputValue: event.target.value },
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const resp = await onSave(state);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      dispatch({ type: 'errors', value: errors });
    }
  }

  const { eoDripListIds, eoCustomFieldValues, errors } = state;

  const sortedEoCustomFields = sortBy(eoCustomFieldValues, 'name');

  return (
    <Modal show className="prospect-form-popup">
      <Modal.Header>
        <Modal.Title>
          {translate('prospects.prospectFormPopup.title')(isExistingProspect)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form id="prospect-form" className="prospect-form-popup__form" noValidate>
          <div className="prospect-form-popup__inputs-block">
            {formInputs.map((input) => {
              const { name, isRequired, type } = input;
              const value = state[name] || '';

              return (
                <InputComponent
                  isMandatory={isRequired}
                  key={name}
                  label={translate(`prospects.prospectFormPopup.inputs.${name}.label`)}
                  message={{ type: 'error', text: errors[name] }}
                  name={name}
                  onInputChange={setInputValue(name)}
                  type={type}
                  value={value}
                />
              );
            })}
          </div>

          <Select
            label={translate('prospects.prospectFormPopup.inputs.eoDripList.label')(
              isExistingProspect,
            )}
            blurInputOnSelect={false}
            triggerChangeOnBlur={false}
            closeMenuOnSelect={false}
            isClearable={false}
            isMulti
            onChange={onChangeDripList}
            options={eoDripListsOptions}
            value={eoDripListsOptions.filter(({ value }) => eoDripListIds.includes(value))}
          />

          <h4>{translate('prospects.prospectFormPopup.customFields.title')}:</h4>

          {sortedEoCustomFields.length > 0 && (
            <div className="prospect-form-popup__inputs-block">
              {sortedEoCustomFields.map(({ eoCustomFieldId, name, value, defaultValue }) => {
                return (
                  <InputComponent
                    key={eoCustomFieldId}
                    label={name}
                    message={{ type: 'error', text: errors[name] }}
                    onInputChange={setCustomFields(name)}
                    name={name}
                    placeholder={defaultValue}
                    type="text"
                    value={value || ''}
                  />
                );
              })}
            </div>
          )}

          {sortedEoCustomFields.length === 0 && (
            <p>
              <i>{translate('prospects.prospectFormPopup.customFields.blankState')}</i>
            </p>
          )}
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="prospect-form-popup__btn-group">
          <div className="prospect-form-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('prospects.prospectFormPopup.buttons.cancel')}
            </ButtonComponent>
          </div>

          <div className="prospect-form-popup__btn">
            <SubmitButtonComponent
              form="prospect-form"
              isGreen
              onClick={handleSubmit}
              text={translate('prospects.prospectFormPopup.buttons.submit')}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ProspectFormPopupComponent.propTypes = {
  immutableEoCustomFields: eoCustomFieldsList,
  immutableEoDripListsOptions: optionsList,
  isFetchingEoCustomFields: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  prospect: prospectShape,
};

export default ProspectFormPopupComponent;
