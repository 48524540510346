import { scrollToElement } from 'common/utils';

export default function scrollToOpenedCompetitorPage() {
  const openedCompetitorPageElement = document.querySelector(
    '.brand-page-list_competitors .brand-page-item-component_is-open',
  );

  const navbarHeight = 120; // subtract navbar's height
  scrollToElement(openedCompetitorPageElement, { minusValue: navbarHeight });
}
