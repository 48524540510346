const CHECK_PROXIES__FAILURE = 'CHECK_PROXIES__FAILURE';
const CHECK_PROXIES__FINISHED = 'CHECK_PROXIES__FINISHED';
const CHECK_PROXIES__START = 'CHECK_PROXIES__START';
const CREATE_PROXIES__FAILURE = 'CREATE_PROXIES__FAILURE';
const CREATE_PROXIES__START = 'CREATE_PROXIES__START';
const CREATE_PROXIES__SUCCESS = 'CREATE_PROXIES__SUCCESS';
const DELETE_PROXY__FAILURE = 'DELETE_PROXY__FAILURE';
const DELETE_PROXY__START = 'DELETE_PROXY__START';
const DELETE_PROXY__SUCCESS = 'DELETE_PROXY__SUCCESS';
const FETCH_PROXIES__FAILURE = 'FETCH_PROXIES__FAILURE';
const FETCH_PROXIES__START = 'FETCH_PROXIES__START';
const FETCH_PROXIES__SUCCESS = 'FETCH_PROXIES__SUCCESS';
const CHECK_SINGLE_PROXY__FAILURE = 'CHECK_SINGLE_PROXY__FAILURE';
const CHECK_SINGLE_PROXY__FINISHED = 'CHECK_SINGLE_PROXY__FINISHED';
const CHECK_SINGLE_PROXY__START = 'CHECK_SINGLE_PROXY__START';

function deleteProxyStart() {
  return { type: DELETE_PROXY__START };
}

function deleteProxySuccess(ids) {
  return { type: DELETE_PROXY__SUCCESS, ids };
}

function deleteProxyFailure(errors) {
  return { type: DELETE_PROXY__FAILURE, errors };
}

function fetchProxiesStart() {
  return { type: FETCH_PROXIES__START };
}

function fetchProxiesSuccess(proxies) {
  return { type: FETCH_PROXIES__SUCCESS, proxies };
}

function fetchProxiesFailure(errors) {
  return { type: FETCH_PROXIES__FAILURE, errors };
}

function checkProxiesStart() {
  return { type: CHECK_PROXIES__START };
}

function checkProxiesFailure() {
  return { type: CHECK_PROXIES__FAILURE };
}

function checkProxiesFinished() {
  return { type: CHECK_PROXIES__FINISHED };
}

function createProxiesStart() {
  return { type: CREATE_PROXIES__START };
}

function createProxiesSuccess(proxies) {
  return { type: CREATE_PROXIES__SUCCESS, proxies };
}

function createProxiesFailure() {
  return { type: CREATE_PROXIES__FAILURE };
}

function checkSingleProxyStart(proxy) {
  return { type: CHECK_SINGLE_PROXY__START, proxy };
}

function checkSingleProxyFinished(proxy) {
  return { type: CHECK_SINGLE_PROXY__FINISHED, proxy };
}

function checkSingleProxyFailure() {
  return { type: CHECK_SINGLE_PROXY__FAILURE };
}

export {
  CHECK_PROXIES__FAILURE,
  CHECK_PROXIES__FINISHED,
  CHECK_PROXIES__START,
  CREATE_PROXIES__FAILURE,
  CREATE_PROXIES__START,
  CREATE_PROXIES__SUCCESS,
  DELETE_PROXY__FAILURE,
  DELETE_PROXY__START,
  DELETE_PROXY__SUCCESS,
  FETCH_PROXIES__FAILURE,
  FETCH_PROXIES__START,
  FETCH_PROXIES__SUCCESS,
  CHECK_SINGLE_PROXY__FAILURE,
  CHECK_SINGLE_PROXY__FINISHED,
  CHECK_SINGLE_PROXY__START,
  checkProxiesFailure,
  checkProxiesFinished,
  checkProxiesStart,
  createProxiesFailure,
  createProxiesStart,
  createProxiesSuccess,
  deleteProxyFailure,
  deleteProxyStart,
  deleteProxySuccess,
  fetchProxiesFailure,
  fetchProxiesStart,
  fetchProxiesSuccess,
  checkSingleProxyFailure,
  checkSingleProxyFinished,
  checkSingleProxyStart,
};
