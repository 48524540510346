export const FETCH_BRANDS_METRICS__START = 'FETCH_BRANDS_METRICS__START';
export const FETCH_BRANDS_METRICS__SUCCESS = 'FETCH_BRANDS_METRICS__SUCCESS';
export const FETCH_BRANDS_METRICS__FAILURE = 'FETCH_BRANDS_METRICS__FAILURE';
export const PROCESS_DISAVOW__FAILURE = 'PROCESS_DISAVOW__FAILURE';
export const PROCESS_DISAVOW__FAILURE_MESSAGE = 'PROCESS_DISAVOW__FAILURE_MESSAGE';
export const PROCESS_DISAVOW__START = 'PROCESS_DISAVOW__START';
export const PROCESS_DISAVOW__SUCCESS = 'PROCESS_DISAVOW__SUCCESS';


export function fetchBrandsMetricsStart() {
  return { type: FETCH_BRANDS_METRICS__START };
}

export function fetchBrandsMetricsSuccess(brandsMetrics) {
  return { type: FETCH_BRANDS_METRICS__SUCCESS, brandsMetrics };
}

export function fetchBrandsMetricsFailure(errors) {
  return { type: FETCH_BRANDS_METRICS__FAILURE, errors };
}

export function processDisavowStart(brand) {
  return { type: PROCESS_DISAVOW__START, brand };
}

export function processDisavowSuccess(brand) {
  return { type: PROCESS_DISAVOW__SUCCESS, brand };
}

export function processDisavowFailure(brand) {
  return { type: PROCESS_DISAVOW__FAILURE, brand };
}

export function processDisavowFailureMessage(brand) {
  return { type: PROCESS_DISAVOW__FAILURE_MESSAGE, brand };
}
