import { cloneDeep, findIndex, trim } from 'lodash';
import * as edit from 'react-edit';

import { processValue } from './utils';

import { translate } from 'common/i18n';

import {
  addCSSClassToCellInTable,
  highlightTableRow,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
} from 'common/tables/utils';

const inCellEditHandlerService = (props, tableRef) => {
  const { destinationLinks, onShowErrorMessage, onUpdateDl, onUpdateRows, policies } = props;

  const turnOffEditingState = async (rowData) => {
    const cloneRowData = cloneDeep(rowData);

    Reflect.deleteProperty(cloneRowData, 'editing');
    await onUpdateRows([cloneRowData]);
  };

  const turnOnEditingState = async (destinationLinks, index, columnIndex) => {
    const cloneRows = cloneDeep(destinationLinks);
    const cloneRowData = cloneRows[index];

    cloneRowData.editing = columnIndex;
    await onUpdateRows([cloneRowData]);
  };

  function parsePasted(value) {
    if (value._isAMomentObject) {
      return [value._d];
    }

    const parsedValue = (trim(value) || '').split(/\r?\n/);
    if (parsedValue.length > 0) {
      return parsedValue;
    }

    return ['']; // we need empty value to be able to cleanup the cell
  }

  return edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowData }) => {
      const canUpdateAtp = policies.getIn(['atp', 'canUpdate']);

      if (!canUpdateAtp) {
        return;
      }

      const index = findIndex(destinationLinks, { id: rowData.id });

      addCSSClassToCellInTable(tableRef, index, columnIndex, TABLE_CELL_ACTIVE_CLASS);
      turnOnEditingState(destinationLinks, index, columnIndex);
    },

    onValue: async ({ value, rowData, property }) => {
      const index = findIndex(destinationLinks, { id: rowData.id });
      const cloneRows = cloneDeep(destinationLinks);
      const currentValue = rowData[property] || '';

      const newValue = processValue(value);

      if (currentValue !== newValue) {
        const valueRows = parsePasted(newValue);
        const valueLength = valueRows.length;
        const pastingTargetRowsLength = cloneRows.length - index;

        if (valueLength > pastingTargetRowsLength) {
          onShowErrorMessage(translate('errors.numberOfPastedMoreThanExistingRows'));
          turnOffEditingState(rowData);
          return;
        }

        if (valueLength > 1000) {
          onShowErrorMessage(translate('errors.limitedLinks')({ value: 1000, isAtOnce: true }));
          return;
        }

        const rowsData = [];
        for (let i = 0; i < valueRows.length; i++) {
          const rowIndex = index + i;
          const cloneRow = cloneRows[rowIndex];

          cloneRow[property] = valueRows[i];
          Reflect.deleteProperty(cloneRow, 'editing');
          highlightTableRow(tableRef, index);

          rowsData.push(cloneRow);
        }
        removeCSSClassFromTableCells(tableRef, TABLE_CELL_ACTIVE_CLASS);
        turnOffEditingState(rowData);

        await onUpdateDl(rowsData, property, newValue);
      } else {
        turnOffEditingState(rowData);
        removeCSSClassFromTableCells(tableRef, TABLE_CELL_ACTIVE_CLASS);
      }
    },
  });
};

export default inCellEditHandlerService;
