export const CREATE_EO_EMAIL_TEMPLATE__FAILURE = 'CREATE_EO_EMAIL_TEMPLATE__FAILURE';
export const CREATE_EO_EMAIL_TEMPLATE__START = 'CREATE_EO_EMAIL_TEMPLATE__START';
export const CREATE_EO_EMAIL_TEMPLATE__SUCCESS = 'CREATE_EO_EMAIL_TEMPLATE__SUCCESS';
export const DELETE_EO_EMAIL_TEMPLATES__FAILURE = 'DELETE_EO_EMAIL_TEMPLATES__FAILURE';
export const DELETE_EO_EMAIL_TEMPLATES__START = 'DELETE_EO_EMAIL_TEMPLATES__START';
export const DELETE_EO_EMAIL_TEMPLATES__SUCCESS = 'DELETE_EO_EMAIL_TEMPLATES__SUCCESS';
export const FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__FAILURE =
  'FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__FAILURE';
export const FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__FAILURE =
  'FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__FAILURE';
export const FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__START = 'FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__START';
export const FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__START =
  'FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__START';
export const FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__SUCCESS =
  'FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__SUCCESS';
export const FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__SUCCESS =
  'FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__SUCCESS';
export const FETCH_EO_EMAIL_TEMPLATES_COUNT__START = 'FETCH_EO_EMAIL_TEMPLATES_COUNT__START';
export const FETCH_EO_EMAIL_TEMPLATES_COUNT__SUCCESS = 'FETCH_EO_EMAIL_TEMPLATES_COUNT__SUCCESS';
export const FETCH_EO_EMAIL_TEMPLATES_COUNT__FAILURE = 'FETCH_EO_EMAIL_TEMPLATES_COUNT__FAILURE';
export const FETCH_EO_EMAIL_TEMPLATE__FAILURE = 'FETCH_EO_EMAIL_TEMPLATE__FAILURE';
export const FETCH_EO_EMAIL_TEMPLATE__START = 'FETCH_EO_EMAIL_TEMPLATE__START';
export const FETCH_EO_EMAIL_TEMPLATE__SUCCESS = 'FETCH_EO_EMAIL_TEMPLATE__SUCCESS';
export const UPDATE_EO_EMAIL_TEMPLATE__FAILURE = 'UPDATE_EO_EMAIL_TEMPLATE__FAILURE';
export const UPDATE_EO_EMAIL_TEMPLATE__START = 'UPDATE_EO_EMAIL_TEMPLATE__START';
export const UPDATE_EO_EMAIL_TEMPLATE__SUCCESS = 'UPDATE_EO_EMAIL_TEMPLATE__SUCCESS';

export function createEoEmailTemplateStart() {
  return { type: CREATE_EO_EMAIL_TEMPLATE__START };
}

export function createEoEmailTemplateSuccess(eoEmailTemplate) {
  return { type: CREATE_EO_EMAIL_TEMPLATE__SUCCESS, eoEmailTemplate };
}

export function createEoEmailTemplateFailure(errors) {
  return { type: CREATE_EO_EMAIL_TEMPLATE__FAILURE, errors };
}

export function deleteEoEmailTemplatesStart() {
  return { type: DELETE_EO_EMAIL_TEMPLATES__START };
}

export function deleteEoEmailTemplatesSuccess(ids) {
  return { type: DELETE_EO_EMAIL_TEMPLATES__SUCCESS, ids };
}

export function deleteEoEmailTemplatesFailure(errors) {
  return { type: DELETE_EO_EMAIL_TEMPLATES__FAILURE, errors };
}

export function fetchEoEmailTemplatesByScopeStart() {
  return { type: FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__START };
}

export function fetchEoEmailTemplatesByFolderStart() {
  return { type: FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__START };
}

export function fetchEoEmailTemplatesByScopeSuccess(eoEmailTemplates) {
  return { type: FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__SUCCESS, eoEmailTemplates };
}

export function fetchEoEmailTemplatesByFolderSuccess(eoEmailTemplates) {
  return { type: FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__SUCCESS, eoEmailTemplates };
}

export function fetchEoEmailTemplatesByScopeFailure(errors) {
  return { type: FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__FAILURE, errors };
}

export function fetchEoEmailTemplatesByFolderFailure(errors) {
  return { type: FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__FAILURE, errors };
}

export function fetchEoEmailTemplatesCountStart() {
  return { type: FETCH_EO_EMAIL_TEMPLATES_COUNT__START };
}

export function fetchEoEmailTemplatesCountSuccess(templateCount) {
  return { type: FETCH_EO_EMAIL_TEMPLATES_COUNT__SUCCESS, templateCount };
}

export function fetchEoEmailTemplatesCountFailure(errors) {
  return { type: FETCH_EO_EMAIL_TEMPLATES_COUNT__FAILURE, errors };
}

export function fetchEoEmailTemplateStart() {
  return { type: FETCH_EO_EMAIL_TEMPLATE__START };
}

export function fetchEoEmailTemplateSuccess(eoEmailTemplate) {
  return { type: FETCH_EO_EMAIL_TEMPLATE__SUCCESS, eoEmailTemplate };
}

export function fetchEoEmailTemplateFailure(errors) {
  return { type: FETCH_EO_EMAIL_TEMPLATE__FAILURE, errors };
}

export function updateEoEmailTemplateStart() {
  return { type: UPDATE_EO_EMAIL_TEMPLATE__START };
}

export function updateEoEmailTemplateSuccess(eoEmailTemplate) {
  return { type: UPDATE_EO_EMAIL_TEMPLATE__SUCCESS, eoEmailTemplate };
}

export function updateEoEmailTemplateFailure(errors) {
  return { type: UPDATE_EO_EMAIL_TEMPLATE__FAILURE, errors };
}
