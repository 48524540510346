import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map as iMap } from 'immutable';

import PageActionsComponent from './PageActionsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  openCategorizeDlsPopup,
  openImportDlsPopup,
  openPageEditPopup,
} from 'actions/popup_actions';

import { brandPageShape, policiesShape, subscriptionPoliciesShape } from 'common/prop_types_shapes';
import { deletePage, updatePage, enqueuePageGenerateMetric } from 'api/brand_page';
import { enqueuePageDlsCrawling } from 'api/crawler';
import { showSuccessMessageFromTranslation, trackHelpcrunchEvent } from 'common/utils';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';
import { translate } from 'common/i18n';

import confirmationDialogue from 'components/confirmation_dialogue';

const PageActionsContainer = ({
  dispatch,
  page,
  pageChildType,
  pageParentId,
  pageParentType,
  policies,
  subscriptionPolicies,
}) => {
  function handleCategorizeDlsClick() {
    const canCategorize = (subscriptionPolicies || iMap()).getIn(['categorizer', 'canUse'], false);

    if (canCategorize) {
      trackHelpcrunchEvent(`view.popup.categorizer.${pageChildType}`);
    }

    dispatch(
      openCategorizeDlsPopup({
        pageId: page.get('id'),
        pageChildType,
        pageParentId,
        pageParentType,
      }),
    );
  }

  function handleEditBrandPageClick() {
    dispatch(openPageEditPopup({ pageId: page.get('id'), pageChildType }));
  }

  function handleImportClick() {
    dispatch(openImportDlsPopup({ page, pageChildType }));
  }

  async function handlePageCrawl() {
    await enqueuePageDlsCrawling(dispatch, page.get('id'));

    trackHelpcrunchEvent(`click.crawl.${pageChildType}`);
  }


  async function onDeletePage() {
    await deletePage(dispatch, page.get('id'));

    const isCompetitorsPage = pageChildType === 'competitorsPage';

    if (isCompetitorsPage) {
      await updatePage(dispatch, pageParentId, { dlsRedistributionRequired: true });

      showSuccessMessageFromTranslation(
        dispatch,
        'brandPage.messages.competitorsAveragePercentageChangedSuccessfully',
      );
    }
  }

  function handleDeleteClick() {
    confirmationDialogue({
      body: translate('confirmations.deletePage.body'),
      onConfirm: onDeletePage,
    });
  }

  async function handleGenetricMetricClick(){
    await enqueuePageGenerateMetric(dispatch, page.get('id'));
  }
  return (
    <PageActionsComponent
      onCategorizeClick={handleCategorizeDlsClick}
      onDeletePageClick={handleDeleteClick}
      onEditPageClick={handleEditBrandPageClick}
      onImportClick={handleImportClick}
      onPageCrawlClick={handlePageCrawl}
      policies={policies}
      onPageMetricGenerateClick={handleGenetricMetricClick}
    />
  );
};

PageActionsContainer.propTypes = {
  dispatch: PropTypes.func,
  page: brandPageShape.isRequired,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  policies: policiesShape.isRequired,
  subscriptionPolicies: subscriptionPoliciesShape,
};

function select(state) {
  const subscriptionPolicies = subscriptionPoliciesSelector(state);

  return {
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(PageActionsContainer));
