import React from 'react';

import ImportProspectsButtonComponent from './ImportProspectsButtonComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

const ImportProspectsButtonContainer = ({ queryParams }) => {
  const { drive_access } = queryParams;

  return <ImportProspectsButtonComponent isGoogleDriveAccessed={drive_access === 'true'} />;
};

ImportProspectsButtonContainer.propTypes = {
  ...withQueryParamsPropTypes,
};

export default withQueryParams(ImportProspectsButtonContainer);
