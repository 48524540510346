import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import CancelFormSubmitDecorator from '../cancel_form_submit_decorator';

import InputComponent from '../../../components_linkio/input_component';

import AuthFormGlobalErrorComponent from '../auth_form_global_error_component';
import SubmitButtonComponent from '../submit_button_component';
import TabsNavigationComponent from '../tabs_navigation_component';

import { userShape } from '../../../common/prop_types_shapes';

import './edit_email_or_password_form_component.scss';

class EditEmailOrPasswordFormComponent extends Component {
  static propTypes = {
    currentUser: userShape,
    errors: PropTypes.shape({
      current_password: PropTypes.arrayOf(PropTypes.string),
      email: PropTypes.arrayOf(PropTypes.string),
      error: PropTypes.string,
      marketerTitle: PropTypes.arrayOf(PropTypes.string),
      password: PropTypes.arrayOf(PropTypes.string),
      password_confirmation: PropTypes.arrayOf(PropTypes.string),
    }),
    handleSubmitClickAction: PropTypes.func,
  };

  inputs = {};

  handleSubmitClick = async () => {
    const email = (this.inputs.email.value || '').toLowerCase();
    const currentPassword = this.inputs.currentPassword.value;
    const password = this.inputs.password.value;
    const passwordConfirmation = this.inputs.passwordConfirmation.value;

    await this.props.handleSubmitClickAction(
      email,
      currentPassword,
      password,
      passwordConfirmation,
    );
  };

  emailRef = (input) => (this.inputs.email = input);
  currentPasswordRef = (input) => (this.inputs.currentPassword = input);
  passwordRef = (input) => (this.inputs.password = input);
  passwordConfirmationRef = (input) => (this.inputs.passwordConfirmation = input);

  render() {
    const { currentUser, errors } = this.props;
    const { error: genericError } = errors;

    const currentPasswordMsg = (currentPasswordError) => {
      const hint = {
        type: 'hint',
        text: translate('authForms.editEmailOrPassword.inputs.currentPassword.hint'),
      };
      const error = { type: 'error', text: currentPasswordError };

      return currentPasswordError ? error : hint;
    };

    const passwordMsg = (passwordError) => {
      const hint = {
        type: 'hint',
        text: translate('authForms.editEmailOrPassword.inputs.password.hint'),
      };
      const error = { type: 'error', text: passwordError };

      return passwordError ? error : hint;
    };

    return (
      <div className="page__wrapper">
        <div className="edit-email-or-password-component">
          <div className="edit-email-or-password-component__tabs">
            <TabsNavigationComponent />
          </div>
          <div className="strip" />
          <form className="edit-email-or-password-component__form" noValidate>
            {genericError && <AuthFormGlobalErrorComponent message={genericError} />}
            <div>
              <div className="edit-email-or-password-component__input">
                <InputComponent
                  autoFocus
                  defaultValue={currentUser.get('email')}
                  inputRef={this.emailRef}
                  isMandatory
                  label={translate('authForms.editEmailOrPassword.inputs.email.label')}
                  message={{ type: 'error', text: errors.email }}
                  onSend={this.handleSubmitClick}
                  type="email"
                />
              </div>
              <div className="edit-email-or-password-component__input">
                <InputComponent
                  inputRef={this.currentPasswordRef}
                  isMandatory
                  label={translate('authForms.editEmailOrPassword.inputs.currentPassword.label')}
                  message={currentPasswordMsg(errors.current_password)}
                  onSend={this.handleSubmitClick}
                  type="password"
                />
              </div>
              <div className="edit-email-or-password-component__input">
                <InputComponent
                  autoComplete="off"
                  message={passwordMsg(errors.password)}
                  inputRef={this.passwordRef}
                  label={translate('authForms.editEmailOrPassword.inputs.password.label')}
                  type="password"
                  onSend={this.handleSubmitClick}
                />
              </div>
              <div className="edit-email-or-password-component__input">
                <InputComponent
                  inputRef={this.passwordConfirmationRef}
                  label={translate(
                    'authForms.editEmailOrPassword.inputs.passwordConfirmation.label',
                  )}
                  message={{ type: 'error', text: errors.password_confirmation }}
                  onSend={this.handleSubmitClick}
                  type="password"
                />
              </div>
              <SubmitButtonComponent
                isBlue
                onClick={this.handleSubmitClick}
                text={translate('authForms.editEmailOrPassword.buttons.submit')}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CancelFormSubmitDecorator(EditEmailOrPasswordFormComponent);
