import {
  updateCrawlerSettingsFailure,
  updateCrawlerSettingsStart,
  updateCrawlerSettingsSuccess,
} from '../actions/crawler_settings_actions';
import { translate } from '../common/i18n';

import {
  patchToAPI,
  handleAPIError,
} from './utils';

async function updateCrawlerSettings(dispatch, crawler_settings) {
  dispatch(updateCrawlerSettingsStart());

  try {
    const resp = await patchToAPI(
      Routes.update_crawler_settings_path(),
      crawler_settings
    );
    dispatch(updateCrawlerSettingsSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, updateCrawlerSettingsFailure, translate('errors.cantUpdateCrawlerSettings'));
  }
}

export { updateCrawlerSettings };
