import { createSelector } from 'reselect';
import { List as iList } from 'immutable';

const brandKeywords = (state) => state.getIn(['rankTrackerKeywords', 'brandKeywords']);

const brandKeywordsSelector = createSelector(
  [brandKeywords],
  (brandKeywords) => brandKeywords || iList()
);

export {
  brandKeywordsSelector,
};
