import { fromJS, Map as iMap } from 'immutable';

import {
  FETCH_PAGE_DASHBOARD_DLS__SUCCESS,
  UPDATE_PAGE_DASHBOARD_DLS__SUCCESS,
} from '../actions/destination_link_actions';

import {
  camelizeObjectDeep,
  updateBrandPageDls,
} from './utils';

const initialState = iMap();


export default function pageDashboardDlsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAGE_DASHBOARD_DLS__SUCCESS: {
      state = state.set(
        action.brandPageId,
        fromJS(action.dls.map(camelizeObjectDeep))
      );

      break;
    }
    case UPDATE_PAGE_DASHBOARD_DLS__SUCCESS: {
      state = state.set(
        action.brandPageId,
        updateBrandPageDls(state.get(action.brandPageId), action.dls, camelizeObjectDeep)
      );

      break;
    }
  }

  return state;
}
