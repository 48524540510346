import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import ImportCsvComponent from 'components_linkio/ImportCsv';

import './importFromGSCPopup.scss';

const ImportFromGSCPopupComponent = ({ onClickCancel, onClickSubmit }) => {
  const [csvFile, setCsvFile] = React.useState('');

  function handleCsvFileUpload(csvFile) {
    setCsvFile(csvFile);
  }

  function handleSubmit() {
    onClickSubmit(csvFile);
  }

  return (
    <Modal dialogClassName="import-from-gsc-popup" onEscapeKeyDown={onClickCancel} show>
      <Modal.Header>
        <Modal.Title>{translate('bulkPagesSetup.importFromGSCPopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{translate('bulkPagesSetup.importFromGSCPopup.subtitle')}</p>

        <ImportCsvComponent onFileUpload={handleCsvFileUpload} />
      </Modal.Body>

      <Modal.Footer>
        <div className="import-from-gsc-popup__btn-group">
          <div className="import-from-gsc-popup__btn">
            <ButtonComponent isWhite onClick={onClickCancel}>
              {translate('bulkPagesSetup.importFromGSCPopup.buttons.cancel')}
            </ButtonComponent>
          </div>
          <div className="import-from-gsc-popup__btn import-from-gsc-popup__btn_submit">
            <ButtonComponent isGreen onClick={handleSubmit} isDisabled={!csvFile}>
              {translate('bulkPagesSetup.importFromGSCPopup.buttons.import')}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ImportFromGSCPopupComponent.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
};

export default ImportFromGSCPopupComponent;
