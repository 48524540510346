import React from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../common/i18n';

import ButtonComponent from '../../components_linkio/button_component';

import './show_hide_buttons_component.scss';

const ShowHideButtonsComponent = ({ showIsDisabled, hideIsDisabled, onClickShow, onClickHide }) => {
  return (
    <div className="show-hide-buttons-component">
      <ButtonComponent
        isBlue
        isDisabled={hideIsDisabled}
        onClick={onClickHide}
        className="show-hide-buttons-component__hide-btn"
      >
        {translate('showHideButtons.hideAll')}
      </ButtonComponent>
      <ButtonComponent
        isBlue
        isDisabled={showIsDisabled}
        onClick={onClickShow}
        className="show-hide-buttons-component__show-btn"
      >
        {translate('showHideButtons.showAll')}
      </ButtonComponent>
    </div>
  );
};

ShowHideButtonsComponent.propTypes = {
  hideIsDisabled: PropTypes.bool.isRequired,
  onClickHide: PropTypes.func.isRequired,
  onClickShow: PropTypes.func.isRequired,
  showIsDisabled: PropTypes.bool.isRequired,
};

export default ShowHideButtonsComponent;
