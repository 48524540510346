import { updateEoProgressionSuccess } from 'actions/eoProgressionActions';

const handleEoProgressionStage = (dispatch, data) => {
  const { eoProgressionStage, eoCampaignId } = data;

  if (eoProgressionStage) {
    dispatch(updateEoProgressionSuccess(data, eoCampaignId));
  }
};

export default handleEoProgressionStage;
