import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { closeSubscriptionLimitReachedPopup } from 'actions/popup_actions';
import { currentSubscriptionSelector, policiesSelector } from 'selectors/railsContextSelectors';
import { fetchSubscriptionPlans } from 'api/subscription_plan';
import { filterSubscriptionPlans } from 'components/subscriptions/utils';
import { openSwitchSubscriptionPlanPopup } from 'actions/popup_actions';
import { policiesShape, subscriptionPlansMap, subscriptionShape } from 'common/prop_types_shapes';
import { somethingIsLoadingSelector, subscriptionPlansSelector } from 'selectors';
import { trackHelpcrunchEvent } from 'common/utils';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import StripePayBtn from 'components/subscriptions/StripePayBtn';
import SubscriptionPlansListComponent from 'components/subscriptions/subscription_plans_list_component';
import SwitchComponent from 'components_linkio/switch_component';

import './subscription_limit_reached_popup.scss';

const ALLOWED_ACTION_TYPES = ['changeSubscriptionPlan'];

const SubscriptionLimitReachedPopup = ({
  actionType = ALLOWED_ACTION_TYPES[0],
  currentSubscription,
  dispatch,
  policies,
  somethingIsLoading,
  subscriptionPlans,
}) => {
  React.useEffect(() => {
    fetchSubscriptionPlans(dispatch);
    trackHelpcrunchEvent('view.popup.upgradeYourPlan');
  }, []);

  const [selectedCostType, setSelectedCostType] = React.useState(
    currentSubscription.get('billingInterval', 'monthly'),
  );

  const [selectedPlanId, setSelectedPlanId] = React.useState(
    String(currentSubscription.get('subscriptionPlanId')),
  );

  function handleCancel() {
    dispatch(closeSubscriptionLimitReachedPopup());
  }

  function handlePlanSelect(planId) {
    setSelectedPlanId(planId);
  }

  function handleSwitchClick(item) {
    const newSelectedCostType = item === 'left' ? 'monthly' : 'yearly';
    setSelectedCostType(newSelectedCostType);
  }

  function buildModalHeaderTranslations() {
    const baseKey = `subscriptionLimitReachedPopup.${actionType}`;
    const title = translate(`${baseKey}.title`);
    const subTitle = translate(`${baseKey}.subTitle`);

    return { title, subTitle };
  }

  function handleDowngradeToFreePlan() {
    const freePlanId = subscriptionPlans
      .find((subscriptionPlan) => !subscriptionPlan.get('paid'))
      .get('id');

    dispatch(
      openSwitchSubscriptionPlanPopup({
        selectedPlanId: freePlanId,
        onClose: () => dispatch(closeSubscriptionLimitReachedPopup()),
      }),
    );
  }

  const canManageSubscriptions = policies.getIn(['subscription', 'canManage'], false);
  const { title, subTitle } = buildModalHeaderTranslations();
  const filteredSubscriptionPlans = filterSubscriptionPlans(subscriptionPlans, selectedCostType, {
    paidOnly: true,
  });
  const availability = currentSubscription.getIn([
    'subscriptionPlansAvailability',
    String(selectedPlanId),
  ]);
  const selectedPlan = subscriptionPlans.get(String(selectedPlanId));
  const switchInitialValue = selectedCostType === 'monthly' ? 'left' : 'right';

  const switchComponentClasses = classnames({
    'subscription-plan-list-component__switch': true,
    'subscription-plan-list-component__switch_billed-annually': selectedCostType === 'yearly',
  });

  const currentSubscriptionIsFreePlan = !currentSubscription.get('subscriptionPlanIsPaid');

  return (
    <Modal show className="subscription-limit-reached-popup">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {subTitle && <div className="subscription-limit-reached-popup__subtitle">{subTitle}</div>}

      <Modal.Body>
        <SwitchComponent
          className={switchComponentClasses}
          initialValue={switchInitialValue}
          left={translate('subscriptionLimitReachedPopup.switch.left')}
          onClick={handleSwitchClick}
          right={translate('subscriptionLimitReachedPopup.switch.right')}
        />
        {!somethingIsLoading && (
          <SubscriptionPlansListComponent
            currentSubscription={currentSubscription}
            onPlanSelect={handlePlanSelect}
            selectedCostType={selectedCostType}
            selectedPlanId={selectedPlanId}
            subscriptionPlans={filteredSubscriptionPlans}
            isClickable={canManageSubscriptions}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="subscription-limit-reached-popup__btn-group-wrapper">
          <div className="subscription-limit-reached-popup__btn-group">
            {canManageSubscriptions && (
              <>
                <ButtonComponent
                  isRed
                  onClick={handleDowngradeToFreePlan}
                  isDisabled={currentSubscriptionIsFreePlan}
                >
                  {translate('subscriptionLimitReachedPopup.buttons.downgradeToFree')}
                </ButtonComponent>

                {!currentSubscriptionIsFreePlan && (
                  <div className="subscription-limit-reached-popup__btn">
                    <StripePayBtn
                      availability={availability}
                      subscriptionPlan={selectedPlan}
                      withUpgradeText
                    />
                  </div>
                )}
              </>
            )}
          </div>

          <div className="subscription-limit-reached-popup__btn-group">
            <div className="subscription-limit-reached-popup__btn">
              <ButtonComponent isWhite onClick={handleCancel}>
                {translate('subscriptionLimitReachedPopup.buttons.cancel')}
              </ButtonComponent>
            </div>

            {canManageSubscriptions && !currentSubscriptionIsFreePlan && (
              <div className="subscription-limit-reached-popup__btn">
                <StripePayBtn
                  actionType={actionType}
                  availability={availability}
                  subscriptionPlan={selectedPlan}
                  isContinueTrial
                />
              </div>
            )}

            {currentSubscriptionIsFreePlan && (
              <div className="subscription-limit-reached-popup__btn">
                <StripePayBtn
                  availability={availability}
                  subscriptionPlan={selectedPlan}
                  withUpgradeText
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

SubscriptionLimitReachedPopup.propTypes = {
  actionType: PropTypes.oneOf(ALLOWED_ACTION_TYPES),
  currentSubscription: subscriptionShape.isRequired,
  dispatch: PropTypes.func,
  policies: policiesShape,
  somethingIsLoading: PropTypes.bool,
  subscriptionPlans: subscriptionPlansMap,
};

function select(state, ownProps) {
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);
  const somethingIsLoading = somethingIsLoadingSelector(state, ownProps);
  const subscriptionPlans = subscriptionPlansSelector(state, ownProps);

  return {
    currentSubscription,
    policies,
    somethingIsLoading,
    subscriptionPlans,
  };
}

export default ConnectStoreHOC(connect(select)(SubscriptionLimitReachedPopup));
