import React from 'react';


const WorldIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 356 356">
      <path d="M178 0C79.693 0 0 79.693 0 178s79.693 178 178 178 178-79.693 178-178C355.868 79.748 276.252.132 178 0zm-44 18a150.806 150.806 0 0 0-38.96 60.08 260.142 260.142 0 0 1-38.8-12.72A165.918 165.918 0 0 1 134 18zM48.08 74.8A267.46 267.46 0 0 0 91.12 90a287.032 287.032 0 0 0-13.2 82v-.24h-65.6A165.038 165.038 0 0 1 48.08 74.8zm0 205.76a165.038 165.038 0 0 1-35.76-96.96l65.6.16A287.045 287.045 0 0 0 91.12 266a267.52 267.52 0 0 0-43.04 14.56zm8.16 9.92V290a259.911 259.911 0 0 1 38.8-12.72A150.81 150.81 0 0 0 134 338a165.926 165.926 0 0 1-77.76-47.52zM172 343.44c-26.88-3.44-50.4-29.52-65.36-68.24a355.573 355.573 0 0 1 65.36-6.96v75.2zm0-87.68a366.765 366.765 0 0 0-69.36 7.52A279.437 279.437 0 0 1 90 184v-.24h82v72zm0-84H90a279.44 279.44 0 0 1 12.64-80 366.787 366.787 0 0 0 69.36 8v72zm0-84a355.797 355.797 0 0 1-65.36-6.88C121.6 42 145.12 16 172 12.56v75.2zm135.92-12.48a165.038 165.038 0 0 1 35.76 96.96h-65.6a287.039 287.039 0 0 0-13.2-82.4 267.52 267.52 0 0 0 43.04-14.56zm-8.24-9.76a260.142 260.142 0 0 1-38.8 12.72A150.804 150.804 0 0 0 222 18a165.917 165.917 0 0 1 77.68 47.52zM184 12.56c26.88 3.44 50.4 29.52 65.36 68.24A355.746 355.746 0 0 1 184 87.76v-75.2zm0 87.2a366.593 366.593 0 0 0 69.36-7.52 279.44 279.44 0 0 1 12.64 80h-82V99.76zm0 84.48h82a279.44 279.44 0 0 1-12.64 80 366.705 366.705 0 0 0-69.36-8v-72zm0 159.2v-75.2a355.623 355.623 0 0 1 65.36 6.88C234.4 314 210.88 340 184 343.44zm38-5.44a150.797 150.797 0 0 0 38.96-60.16 259.911 259.911 0 0 1 38.8 12.72A165.918 165.918 0 0 1 222 338zm85.92-56.8a267.329 267.329 0 0 0-43.04-15.2 287.032 287.032 0 0 0 13.2-82v.24h65.6a165.038 165.038 0 0 1-35.76 96.96z" />
    </svg>
  );
};

export default WorldIcon;
