import pluralize from 'pluralize';
import { buildWordInPastTenseWithPluralization } from 'common/utils';

export default {
  eoCustomField: {
    breadcrumbsTitle: 'Custom Fields',
    bulkActions: {
      addEoCustomField: 'Create New Custom Field',
      deleteConfirmation: 'Prospects that still use this custom field within a campaign will fail.',
    },
    messages: {
      cantFetchEoCustomFields: "Can't load custom fields",
      cantFetchEoCustomField: "Can't load custom field",
      cantCreateEoCustomField: "Can't create custom field",
      cantUpdateEoCustomField: "Can't update custom field",
      cantDeleteEoCustomFields: "Can't delete custom field(s)",
      createSuccess: 'Custom Field was created successfully.',
      deleteSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization('Custom Field', count)} deleted successfully.`;
      },
      updateSuccess: 'Custom Field was updated successfully.',
    },
    eoCustomFieldFormPopup: {
      buttons: {
        submit: (isExistingEoCustomField) => (isExistingEoCustomField ? 'Save' : 'Create Custom Field'),
      },
      inputs: {
        name: {
          label: 'Name of the custom field',
          hint: 'Alpha numeric characters and - or _ only',
        },
        defaultValue: {
          label: 'Default value (if value not specified)',
        },
      },
      title: (isExistingEoCustomField) =>
        `${isExistingEoCustomField ? 'Update' : 'Create'} custom field`,
    },
    table: {
      blankState: {
        body: 'You can manually add Custom Fields',
        title: "You don't have any Custom Fields yet",
      },
      headers: {
        name: 'Name',
        defaultValue: 'Default value',
      },
      eoCustomFieldsCount: (count) => `${pluralize('Custom field', count, true)}`,
    },
  },
};
