import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components_linkio/link';

const LinkItemComponent = ({ to, children }) => (
  <Link to={to} className="breadcrumbs__item breadcrumbs__item_link">
    {children}
  </Link>
);

LinkItemComponent.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

export default LinkItemComponent;
