export default {
  pageDashboard: {
    title: "Next 5 Suggestions",
    showAll: {
      prefix: " (",
      title: "Show All",
      postfix: ")",
    },
    status: {
      title: "Status:",
      all: "All",
    },
  },
};
