import React from 'react';
import PropTypes from 'prop-types';

import Calendar, { DaysOfWeek, Month } from 'components_linkio/Calendar';
import DateInput from 'components_linkio/DateInput';

import './calendarsSideBySide.scss';

const CalendarsSideBySideComponent = ({
  dateEnd,
  dateStart,
  leftMonth,
  onChangeDateEnd,
  onChangeDateStart,
  onClickDate,
  onClickNextMonth,
  onClickPrevMonth,
  rightMonth,
}) => (
  <div className="calendars-side-by-side">
    <div className="calendars-side-by-side__section calendars-side-by-side__section_input">
      <div className="calendars-side-by-side__input">
        <DateInput value={dateStart} onChange={onChangeDateStart} maxValue={dateEnd} />
      </div>
      <div className="calendars-side-by-side__input">
        <DateInput value={dateEnd} onChange={onChangeDateEnd} minValue={dateStart} />
      </div>
    </div>
    <div className="calendars-side-by-side__section calendars-side-by-side__section_month">
      <div className="calendars-side-by-side__col">
        <Month month={leftMonth} onClickPrevMonth={onClickPrevMonth} />
      </div>
      <div className="calendars-side-by-side__col">
        <Month month={rightMonth} onClickNextMonth={onClickNextMonth} />
      </div>
    </div>
    <div className="calendars-side-by-side__section calendars-side-by-side__section_calendar">
      <div className="calendars-side-by-side__col">
        <DaysOfWeek />
        <Calendar
          month={leftMonth}
          selectedDateRange={[dateStart, dateEnd]}
          onDateClick={onClickDate}
        />
      </div>
      <div className="calendars-side-by-side__month-delimiter" />
      <div className="calendars-side-by-side__col">
        <DaysOfWeek />
        <Calendar
          month={rightMonth}
          selectedDateRange={[dateStart, dateEnd]}
          onDateClick={onClickDate}
        />
      </div>
    </div>
  </div>
);

CalendarsSideBySideComponent.propTypes = {
  dateEnd: PropTypes.instanceOf(Date).isRequired,
  dateStart: PropTypes.instanceOf(Date).isRequired,
  leftMonth: PropTypes.instanceOf(Date).isRequired,
  onChangeDateEnd: PropTypes.func.isRequired,
  onChangeDateStart: PropTypes.func.isRequired,
  onClickDate: PropTypes.func.isRequired,
  onClickNextMonth: PropTypes.func.isRequired,
  onClickPrevMonth: PropTypes.func.isRequired,
  rightMonth: PropTypes.instanceOf(Date).isRequired,
};

export default CalendarsSideBySideComponent;
