import React from 'react';
import PropTypes from 'prop-types';

import { employeesArray, pagyShape } from 'common/prop_types_shapes';

import SearchInput from 'components/SearchInput';
import Pager from 'components/Pager';

import './impersonates.scss';

const ImpersonatesComponent = ({
  impersonates,
  impersonatesPagy,
  onItemsSelectChange,
  onPagerClick,
  onSignInAsClick,
  onSubmitSearch,
}) => {
  function buildImpersonatesList() {
    return (
      <ul>
        {impersonates.map((user) => {
          const { id, email, name } = user;

          return (
            <li key={id}>
              <span className="text_link" onClick={onSignInAsClick(id)}>
                {` ${email} ${name ? `(${name})` : ''}`}
              </span>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <>
      <div className="impersonates__actions">
        <SearchInput onSubmit={onSubmitSearch} />
      </div>
      <div className="impersonates__list">{buildImpersonatesList()}</div>
      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagerClick}
        pagerType="pagesPager"
        pagy={impersonatesPagy}
      />
    </>
  );
};

ImpersonatesComponent.propTypes = {
  impersonates: employeesArray,
  impersonatesPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagerClick: PropTypes.func,
  onSignInAsClick: PropTypes.func,
  onSubmitSearch: PropTypes.func,
};

export default ImpersonatesComponent;
