import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import CrossIcon from 'common/icons/cross';
import InputComponent from 'components_linkio/input_component';
import MagnifierIcon from 'common/icons/magnifier';

import './searchInput.scss';

const SearchInputComponent = ({ onInputChange, onReset, searchTerm }) => {
  return (
    <div className="search-input">
      <InputComponent
        className="search-input__input"
        value={searchTerm}
        icon={MagnifierIcon}
        isHaveRightIcon
        onInputChange={onInputChange}
        placeholder={translate('searchPlaceholder')}
      />
      {searchTerm && <CrossIcon className="search-input__cross-icon" onClick={onReset} />}
    </div>
  );
};

SearchInputComponent.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
};

export default SearchInputComponent;
