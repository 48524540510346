import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import BulkCompetitorsSetupTableComponent from './BulkCompetitorsSetupTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandPageMap, optionsMap, draftCompetitorPagesList } from 'common/prop_types_shapes';
import { bulkCompetitorPagesByBrandPageIdSelector } from 'selectors/bulkCompetitorPagesSelector';
import { fetchBulkCompetitorPages, updateDraftCompetitorPage } from 'api/bulkCompetitorsSetup';
import { fetchPageTypes } from 'api/page_type';
import { openEditKeywordsPopup } from 'actions/popup_actions';
import { optionsSelector, visibleBrandPageSelector } from 'selectors';
import { translate } from 'common/i18n';
import { updateDraftCompetitorPagesRows } from 'actions/bulkCompetitorsSetupActions';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';
import TableLoadingWrapper from 'components_linkio/TableLoadingWrapper';

const BulkCompetitorsSetupTableContainer = ({
  brandPage,
  brandPageId,
  dispatch,
  draftCompetitorPages,
  pageTypeOptions,
}) => {
  React.useEffect(() => {
    Promise.all([fetchBulkCompetitorPages(dispatch, brandPageId), fetchPageTypes(dispatch)]);
  }, []);

  function handleClickKeywordsCell(pageId) {
    dispatch(
      openEditKeywordsPopup({
        pageId,
        pageParentId: brandPageId,
        targetAppModule: 'bulkCompetitorsSetup',
      }),
    );
  }

  // Need to update state after selecting links
  function handleUpdateRows(draftCompetitorPages) {
    dispatch(updateDraftCompetitorPagesRows(draftCompetitorPages, brandPageId));
  }

  function handleUpdateDraftCompetitorPage(draftCompetitorPage) {
    updateDraftCompetitorPage(dispatch, draftCompetitorPage);
  }

  const autodiscoverCompetitorsInProgress = brandPage.get('autodiscoverCompetitorsInProgress');

  return (
    <>
      {draftCompetitorPages.size > 0 && (
        <BulkCompetitorsSetupTableComponent
          autodiscoverCompetitorsInProgress={autodiscoverCompetitorsInProgress}
          draftCompetitorPages={draftCompetitorPages.toJS()}
          onClickKeywordsCell={handleClickKeywordsCell}
          onUpdateDraftCompetitorPage={handleUpdateDraftCompetitorPage}
          onUpdateRows={handleUpdateRows}
          pageTypeOptions={pageTypeOptions.toList().toJS()}
        />
      )}
      {draftCompetitorPages.size === 0 && (
        <TableLoadingWrapper
          isLoading={autodiscoverCompetitorsInProgress}
          loadingMessage={translate('bulkCompetitorsSetup.table.dataNotReadyYet')}
        >
          <BlankStateCtaComponent>
            <TitleText>
              <strong>{translate('bulkCompetitorsSetup.table.blankState.title')}</strong>
            </TitleText>
            <BodyText>{translate('bulkCompetitorsSetup.table.blankState.body')}</BodyText>
          </BlankStateCtaComponent>
        </TableLoadingWrapper>
      )}
    </>
  );
};

function select(state, ownProps) {
  const brandPage = visibleBrandPageSelector(state, ownProps);
  const brandPageId = brandPage.get('id');

  const draftCompetitorPages = bulkCompetitorPagesByBrandPageIdSelector(state, brandPageId);

  const options = optionsSelector(state, ownProps);
  const pageTypeOptions = options.get('pageTypeOptions');

  return {
    brandPage,
    brandPageId,
    draftCompetitorPages,
    pageTypeOptions,
  };
}

BulkCompetitorsSetupTableContainer.propTypes = {
  brandPage: brandPageMap,
  brandPageId: PropTypes.number,
  dispatch: PropTypes.func,
  draftCompetitorPages: draftCompetitorPagesList,
  pageTypeOptions: optionsMap,
};

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(BulkCompetitorsSetupTableContainer);
