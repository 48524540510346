import React from 'react';
import PropTypes from 'prop-types';

import ProspectActionsPopupWithTable from './ProspectActionsPopupWithTable';

import { translate } from 'common/i18n';

import Select from 'components_linkio/Select';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import './prospectActionsSelect.scss';

const options = [
  { value: 'startEoCampaign', label: translate('prospects.bulkActions.startEoCampaign') },
  {
    value: 'validateEmailAddresses',
    label: translate('prospects.bulkActions.validateEmailAddresses'),
  },
  { value: 'addToEoDripList', label: translate('prospects.bulkActions.addToEoDripList') },
  { value: 'removeFromEoDripList', label: translate('prospects.bulkActions.removeFromEoDripList') },
];

const initialState = {
  showPopup: false,
  actionType: false,
};

const ProspectActionsSelectComponent = ({ isDisabled, onValidateEmails }) => {
  const [state, setState] = React.useState(initialState);

  function handleClosePopup() {
    setState(initialState);
  }

  function handleSelectAction({ value }) {
    if (value === 'validateEmailAddresses') {
      onValidateEmails();
      return;
    }

    setState({
      showPopup: true,
      actionType: value,
    });
  }

  const { actionType, showPopup } = state;

  return (
    <>
      <div className="prospect-actions-select">
        <Select
          blurInputOnSelect
          isClearable={false}
          isDisabled={isDisabled}
          onChange={handleSelectAction}
          options={options}
          triggerChangeOnBlur={false}
          value={{ label: translate('uiComponents.select.selectAction') }}
        />
      </div>

      <ToggleShowPopup
        Popup={<ProspectActionsPopupWithTable actionType={actionType} />}
        show={showPopup}
        onCloseCb={handleClosePopup}
      />
    </>
  );
};

ProspectActionsSelectComponent.propTypes = {
  isDisabled: PropTypes.bool,
  onValidateEmails: PropTypes.func,
};

export default ProspectActionsSelectComponent;
