import React from 'react';

import RankTrackerBulkActions from './RankTrackerBulkActions';
import RankTrackerCharts from './RankTrackerCharts';
import RankTrackerTable from './RankTrackerTable';

import './rankTracker.scss';

const RankTrackerComponent = () => {
  return (
    <div className="rank-tracker">
      <RankTrackerBulkActions />

      <RankTrackerTable />

      <RankTrackerCharts />
    </div>
  );
};

export default RankTrackerComponent;
