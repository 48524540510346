import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './tabs_component.scss';

const TabsComponent = ({ children, withRoundedTabs, className }) => {
  const tabsComponentClasses = classNames({
    'tabs-component': true,
    'tabs-component_with-rounded-tabs': withRoundedTabs,
    [className]: true,
  });
  return (
    <div className={tabsComponentClasses}>
      {children}
    </div>
  );
};

const TabItemComponent = ({ index, label, onClick, options = {} }) => {
  const tabClasses = classNames({
    'tabs-component__tab': true,
    'tabs-component__tab_active': options.active,
  });
  return (
    <div key={index} className={tabClasses} onClick={onClick}>
      <div>{label}</div>
      {options.counter && <div className="tabs-component__counter">{options.counter}</div>}
    </div>
  );
};

TabsComponent.propTypes = {
  children: PropTypes.node,
  withRoundedTabs: PropTypes.bool,
};

TabItemComponent.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.shape({
    active: PropTypes.bool,
    counter: PropTypes.number,
  }),
};

export default TabsComponent;
export { TabItemComponent };
