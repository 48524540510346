import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';
import { connect } from 'react-redux';

import CategorizeDlsButtonComponent from './CategorizeDlsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { openCategorizeDlsPopup } from 'actions/popup_actions';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';
import { subscriptionPoliciesShape } from 'common/prop_types_shapes';
import { trackHelpcrunchEvent } from 'common/utils';

class CategorizeDlsButtonContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    pageParentType: PropTypes.oneOf(['brand_page', 'brand']).isRequired,
    subscriptionPolicies: subscriptionPoliciesShape,
  };

  handleCategorizeDlsClick = () => {
    const {
      dispatch,
      pageChildType,
      pageParentId,
      pageParentType,
      subscriptionPolicies,
    } = this.props;
    const canCategorize = (subscriptionPolicies || iMap()).getIn(['categorizer', 'canUse'], false);

    if (canCategorize) {
      trackHelpcrunchEvent(`view.popup.categorizer.${pageChildType}`);
    }

    dispatch(openCategorizeDlsPopup({ pageChildType, pageParentId, pageParentType }));
  };

  render() {
    return <CategorizeDlsButtonComponent onCategorizeDlsClick={this.handleCategorizeDlsClick} />;
  }
}

function select(state, ownProps) {
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  return {
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(CategorizeDlsButtonContainer));
