import React from 'react';
import PropTypes from 'prop-types';

import RoboHeadIcon from 'common/icons/roboHead';

import './infoCard.scss';

const Body = ({ children }) => <div className="info-card__body">{children}</div>;
const BtnGroup = ({ children }) => <div className="info-card__btn-group">{children}</div>;
const Subtitle = ({ children }) => <h2 className="info-card__subtitle">{children}</h2>;
const Title = ({ children }) => <h1 className="info-card__title">{children}</h1>;

Body.propTypes = {
  children: PropTypes.node,
};

BtnGroup.propTypes = {
  children: PropTypes.node,
};
Subtitle.propTypes = {
  children: PropTypes.node,
};

Title.propTypes = {
  children: PropTypes.node,
};

function InfoCardComponent(props) {
  return (
    <div className="info-card">
      <RoboHeadIcon />
      {props.children}
    </div>
  );
}

InfoCardComponent.propTypes = {
  children: PropTypes.node,
};

InfoCardComponent.Body = Body;
InfoCardComponent.BtnGroup = BtnGroup;
InfoCardComponent.Subtitle = Subtitle;
InfoCardComponent.Title = Title;

export default InfoCardComponent;
