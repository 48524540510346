import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';

const brandDashboard = (state) => state.get('brandDashboard', iMap());

const brandDashboardDataSelector = createSelector(
  [brandDashboard],
  (brandDashboard) => brandDashboard || iMap()
);

export {
  brandDashboardDataSelector,
};
