import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import CancelFormSubmitDecorator from '../cancel_form_submit_decorator';

import InputComponent from '../../../components_linkio/input_component';

import AuthFormGlobalErrorComponent from '../auth_form_global_error_component';
import DeviseFooterComponent from '../devise_footer_component';
import DeviseLinksComponent from '../devise_links_component';
import SubmitButtonComponent from '../submit_button_component';

import './reset_password_form_component.scss';


class ResetPasswordFormComponent extends Component {
  static propTypes = {
    errors: PropTypes.shape({
      error: PropTypes.string,
      resetPasswordToken: PropTypes.arrayOf(PropTypes.string),
      password: PropTypes.arrayOf(PropTypes.string),
      passwordConfirmation: PropTypes.arrayOf(PropTypes.string),
    }),
    handleResetPasswordButtonClick: PropTypes.func,
  }

  inputs = {};

  handleSubmit = async () => {
    const urlObj = new URL(window.location);
    const resetPasswordToken = urlObj.searchParams.get("reset_password_token");
    const password = this.inputs.password.value;
    const passwordConfirmation = this.inputs.passwordConfirmation.value;

    await this.props.handleResetPasswordButtonClick(resetPasswordToken, password, passwordConfirmation);
  }

  invalidTokenMsg = (errors) => ((errors || {}).reset_password_token) && "Reset password token is invalid";

  passwordRef = (input) => this.inputs.password = input;
  passwordConfirmationRef = (input) => this.inputs.passwordConfirmation = input;

  render() {
    const { errors } = this.props;

    const globalErrorMsg = errors.error || this.invalidTokenMsg(errors);

    return (
      <div className="reset-password-component">
        <div className="reset-password-component__space" />
        <h2 className="reset-password-component__text reset-password-component__text_title">
          {translate('authForms.resetPassword.title')}
        </h2>
        <h4 className="reset-password-component__text reset-password-component__text_subtitle">
          {translate('authForms.resetPassword.subtitle')}
        </h4>
        <form className="reset-password-component__form" noValidate>
          <AuthFormGlobalErrorComponent message={globalErrorMsg} />
          <div className="reset-password-component__input">
            <InputComponent
              inputRef={this.passwordRef}
              isMandatory
              label={translate('authForms.resetPassword.inputs.password.label')}
              message={{ type: 'error', text: errors.password }}
              onSend={this.handleSubmit}
              type="password"
            />
          </div>
          <div className="reset-password-component__input">
            <InputComponent
              inputRef={this.passwordConfirmationRef}
              isMandatory
              label={translate('authForms.resetPassword.inputs.passwordConfirmation.label')}
              message={{ type: 'error', text: errors.password_confirmation }}
              onSend={this.handleSubmit}
              type="password"
            />
          </div>
          <SubmitButtonComponent
            isBlue
            onClick={this.handleSubmit}
            text={translate('authForms.resetPassword.buttons.submit')}
          />
          <DeviseLinksComponent />
          <DeviseFooterComponent />
        </form>
      </div>
    );
  }
}

export default CancelFormSubmitDecorator(ResetPasswordFormComponent);
