import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import BrandsBlankState from './BrandsBlankState';
import BrandsSteps from './BrandsSteps';
import EoDashboard from './EoDashboard';

import { translate } from 'common/i18n';

import EoOnboarding from 'pages/EoOnboarding';
import MyBrandsDashboard from 'components_linkio/MyBrandsDashboard';
import PageLayoutHeader from 'components_linkio/PageLayoutHeader';
import TutorialPopup from 'components_linkio/TutorialPopup';

import './accountDashboard.scss';

const AccountDashboardComponent = ({ showAtpOnboarding, showEoOnboarding, showBrandSteps }) => {
  const showAtLeastOneDashboard = !showAtpOnboarding || !showEoOnboarding;

  const accountDashboardClasses = cx('account-dashboard', {
    'account-dashboard_with-at-least-one-dashboard': showAtLeastOneDashboard,
  });

  return (
    <>
      {showBrandSteps && <BrandsSteps />}

      <div className={accountDashboardClasses}>
        {showAtLeastOneDashboard && (
          <div className="account-dashboard__header">
            <PageLayoutHeader
              title={translate('accountDashboard.title')}
              Popup={
                <TutorialPopup
                  title={translate('accountDashboard.title')}
                  videoSrc="https://www.youtube.com/embed/J_TVMfgyiZU"
                />
              }
            />
          </div>
        )}

        {showAtpOnboarding && showEoOnboarding && (
          <h1 className="account-dashboard__onboarding-title">
            {translate('accountDashboard.onboarding.title')}
          </h1>
        )}

        <div className="account-dashboard__body">
          <div className="account-dashboard__body-item account-dashboard__brands-content">
            {showAtpOnboarding ? <BrandsBlankState /> : <MyBrandsDashboard />}
          </div>

          {showAtLeastOneDashboard && (
            <div className="account-dashboard__body-item account-dashboard__separator" />
          )}

          <div className="account-dashboard__body-item account-dashboard__eo-content">
            <h2>{translate('prompts.eoOnboarding.title')}</h2>
            {showEoOnboarding ? <EoOnboarding /> : <EoDashboard />}
          </div>
        </div>
      </div>
    </>
  );
};

AccountDashboardComponent.propTypes = {
  showAtpOnboarding: PropTypes.bool.isRequired,
  showBrandSteps: PropTypes.bool.isRequired,
  showEoOnboarding: PropTypes.bool.isRequired,
};

export default AccountDashboardComponent;
