import React from 'react';
import pluralize from 'pluralize';

import { cloneDeep, uniqueId } from 'lodash';
import classnames from 'classnames';
import { translate } from 'common/i18n';
import { addMessage, removeMessage } from 'actions/messages_actions';

const showSuccessMessageFromTranslation = (dispatch, messageTranslationPath, args = {}) => {
  return showSuccessMessage(dispatch, translate(messageTranslationPath, args));
};

const showErrorMessageFromTranslation = (dispatch, messageTranslationPath, args = {}) => {
  return showErrorMessage(dispatch, translate(messageTranslationPath, args));
};

const formattedErrorsMessages = (errors, messageTranslationPath, args = {}) => {
  return (
    <span>
      <span>{translate(messageTranslationPath, args)}</span>
      <ul>
        {errors.map((error, key) => (
          <li key={key}>{error}</li>
        ))}
      </ul>
    </span>
  );
};

const showSuccessMessage = (dispatch, text, permanent = false) => {
  const timer = permanent ? void 0 : 5000;
  showMessage(dispatch, { type: 'success', message: text }, timer);
};

const showErrorMessage = (dispatch, text, permanent = false) => {
  const timer = permanent ? void 0 : 10000;
  showMessage(dispatch, { type: 'error', message: text }, timer);
};

const showInfoMessage = (dispatch, text, permanent = false) => {
  const timer = permanent ? void 0 : 3000;
  showMessage(dispatch, { type: 'warning', message: text }, timer);
}

const showMessage = (dispatch, messageObj, forTime) => {
  if (!dispatch || !messageObj) {
    return;
  }

  const id = uniqueId('message_');
  const handleClose = () => dispatch(removeMessage(id));

  dispatch(addMessage({ ...messageObj, id, onClose: handleClose }));
  typeof forTime === 'number' &&
    setTimeout(() => {
      dispatch(removeMessage(id));
    }, forTime);
};

const isValidEmail = (email) => /^[^@\s]+@[^@\s]+$/i.exec(email);

const validateEmail = ({ email }) => {
  const notValidEmail = translate('validations.email.invalidFormat');
  const emailEmpty = translate('validations.email.cantBeBlank');

  const errors = {};

  if (!isValidEmail(email)) {
    errors.email = [notValidEmail];
  }

  if (email === '') {
    errors.email = [emailEmpty];
  }

  return errors;
};

const validateMarketerTitle = ({ marketerTitle }) => {
  const marketerTitleEmpty = translate('validations.marketer.title.cantBeBlank');

  const errors = {};

  if (marketerTitle === '') {
    errors.marketerTitle = [marketerTitleEmpty];
  }

  return errors;
};

const validatePassword = ({ password, passwordConfirmation, withConfirmation }) => {
  const passwordNotMatch = translate('validations.password.confirmationMismatch');
  const passwordEmpty = translate('validations.password.cantBeBlank');
  const passwordIsTooShort = translate('validations.password.isTooShort');
  const passwordMinLength = 8;

  const errors = {};

  if (withConfirmation !== void 0) {
    if (password !== passwordConfirmation) {
      errors.password = [passwordNotMatch];
    }
  }

  if (password && password.length < passwordMinLength) {
    errors.password = [passwordIsTooShort];
  }

  if (password === '') {
    errors.password = [passwordEmpty];
  }

  return errors;
};

const validateSignUpForm = ({ email, marketerTitle, password, passwordConfirmation }) => {
  const emailErrors = { ...validateEmail({ email }) };
  const marketerTitleErrors = { ...validateMarketerTitle({ marketerTitle }) };
  const passwordErrors = {
    ...validatePassword({
      password,
      passwordConfirmation,
      withConfirmation: true,
    }),
  };

  return { ...emailErrors, ...marketerTitleErrors, ...passwordErrors };
};

const validateSignInForm = ({ email, password }) => {
  const emailErrors = { ...validateEmail({ email }) };
  const passwordErrors = { ...validatePassword({ password }) };

  return { ...emailErrors, ...passwordErrors };
};

const validateAcceptInvitationFrom = ({ password, passwordConfirmation }) => {
  return { ...validatePassword({ password, passwordConfirmation }) };
};

const addCookie = (key, value) => document && (document.cookie = `${key}=${value};path=/`);
const getCookie = (name, defaultValue) => {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`, //eslint-disable-line no-useless-escape
    ),
  );

  return matches ? decodeURIComponent(matches[1]) : defaultValue;
};

const clearErrors = (state) => ({ ...state, errors: {}, error: null });

const trackHelpcrunchEvent = async (translateKey, ...translateArgs) => {
  // Uncomment and finalize Helpcrunch implementation when events tracking is needed

  // await waitFor(() => window.Intercom);
  // const translateFromDictionary = translate(`helpcrunchEvents.${translateKey}`);
  // const eventName =
  //   typeof translateFromDictionary === 'string'
  //     ? translateFromDictionary
  //     : translateFromDictionary(translateArgs);
  // return window.Intercom('trackEvent', eventName);
};

const trackFirstPromoterSignup = (email) => window.$FPROM && window.$FPROM.trackSignup({ email });

const saveAs = (data, filename, type) => {
  const file = new Blob([data], { type });

  if (window.navigator.msSaveOrOpenBlob) {
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  } else {
    // Others
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);

    a.href = url;
    a.download = filename;

    document.body.appendChild(a);

    a.click();

    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

const waitFor = (conditionalFunc, timeoutms = 5000) => {
  const POLLING_PERIOD_MS = 1000;

  return new Promise((resolve, reject) => {
    const check = () => {
      if (conditionalFunc()) {
        resolve();
      } else if ((timeoutms -= POLLING_PERIOD_MS) < 0) {
        reject(translate('errors.timeout'));
      } else {
        setTimeout(check, POLLING_PERIOD_MS);
      }
    };
    setTimeout(check, POLLING_PERIOD_MS);
  });
};

const textTwoLinesWithEllipsis = (value, options = {}) => {
  const { breakAll } = options;

  const classes = classnames({
    'text_two-lines-with-ellipsis-break-all': breakAll,
    'text_two-lines-with-ellipsis-break-word': !breakAll,
  });

  return (
    <span title={value} className={classes}>
      {value}
    </span>
  );
};

const insertNotAvailableIfNull = (value) => {
  if (value === null) {
    return translate('notAvailable');
  }
  return value;
};

const insertZeroIfValueIsNegative = (value) => {
  if (Number(value) < 0) {
    return 0;
  }
  return value;
};

const scrollToElement = (elementNode, { plusValue = 0, minusValue = 0 }) => {
  window &&
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: elementNode && elementNode.offsetTop + plusValue - minusValue,
    });
};

// https://mouseflow.com/ - service for tracking users actions on page,
// based on recording all DOM mutations that happens on the page.
// It has 10.000 per page limit for DOM mutations, which we easily exceed.
// Workaround - manually creation of new page view.
// More can be find here https://mouseflow.groovehq.com/knowledge_base/topics/single-page-websites
const startMouseFlowPageView = (url) => {
  if (!window) {
    return;
  }

  window._mfq = window._mfq || [];
  window._mfq.push(['newPageView', url]);
};

const buildSelectedItems = (items, selectedIds) => {
  const processedItems = cloneDeep(items);

  processedItems.forEach((item) => {
    item.selected = selectedIds.includes(item.id);
  });

  return processedItems;
};

const fetchDataIfBrandIdHasChanged = (prevBrandId, currentBrandId, fetchFunc) => {
  if (prevBrandId !== currentBrandId) {
    fetchFunc();
  }
};

const buildWordInPastTenseWithPluralization = (word, count) => {
  return `${pluralize(word, count)} ${pluralize('was', count)}`;
};

const processAhrefsOrMozMetricValue = (value) => {
  if (value === null) {
    return translate('notAvailable');
  }
  if (value === 0) {
    return <span className="text_grey">{translate('noData')}</span>;
  }
  return value;
};

const stringifyOptions = (options) =>
  options.map(({ label, value }) => ({ label, value: value.toString() }));

export {
  addCookie,
  buildSelectedItems,
  buildWordInPastTenseWithPluralization,
  clearErrors,
  fetchDataIfBrandIdHasChanged,
  formattedErrorsMessages,
  getCookie,
  insertNotAvailableIfNull,
  insertZeroIfValueIsNegative,
  isValidEmail,
  processAhrefsOrMozMetricValue,
  saveAs,
  scrollToElement,
  showErrorMessage,
  showErrorMessageFromTranslation,
  showMessage,
  showSuccessMessage,
  showInfoMessage,
  showSuccessMessageFromTranslation,
  startMouseFlowPageView,
  stringifyOptions,
  textTwoLinesWithEllipsis,
  trackFirstPromoterSignup,
  trackHelpcrunchEvent,
  validateAcceptInvitationFrom,
  validateEmail,
  validateSignInForm,
  validateSignUpForm,
  waitFor,
};
