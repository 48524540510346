import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, keys, set } from 'lodash';

import EoEmailAttributesButton from './EoEmailAttributesButton';

import { eoCampaignStepShape } from 'common/propTypesShapes/eoCampaignSteps';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import Checkbox from 'components_linkio/Checkbox';
import ExternalLinkIcon from 'common/icons/external_link';
import InfoIcon from 'common/icons/info';
import InputComponent from 'components_linkio/input_component';
import QuillTextEditor from 'components_linkio/QuillTextEditor';
import EoEmailForm from 'components_linkio/EoEmailForm';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import Select from 'components_linkio/Select';

import SelectEoEmailTemplateButton from './SelectEoEmailTemplateButton';
import { optionsList } from 'common/prop_types_shapes';

import './eoCampaignStepEmail.scss';

import MessageComponent from 'components_linkio/message_component';

const initialState = {
  emailSubject: '',
  emailBody: '',
  includePreviousEmail: true,
  openTracking: false,
  clickTracking: false,
  createAsDraft: false,
  ccEmails: '',
  errors: {},
  userId: null,
};

function reducer(state, action) {
  const { value, type } = action;

  switch (type) {
    case 'setState':
      return value;
    default: {
      const newState = cloneDeep(state);

      set(newState, type, value);

      return newState;
    }
  }
}

const formCheckboxes = [
  { name: 'includePreviousEmail' },
  { name: 'createAsDraft' },
  // { name: 'openTracking' },
  // { name: 'clickTracking' },
  // { name: 'createAsDraft' },
];

function handleScrollToError(errors, emailBodyErrorRef, ccEmailsErrorRef) {
  const mappingByErrorToScroll = {
    eoCampaignStepActionEmailBody: () => emailBodyErrorRef.current.scrollIntoView(),
    eoCampaignStepActionCcEmails: () => ccEmailsErrorRef.current.scrollIntoView(),
  };

  const someError = keys(errors).find((key) => !!mappingByErrorToScroll[key]);

  someError && !errors.eoCampaignStepActionEmailSubject && mappingByErrorToScroll[someError]();
}

const EoCampaignStepEmailComponent = ({
  currentUserId,
  onCancel,
  onSave,
  eoCampaignStep,
  eoCampaignStepPosition,
  handleFetchUsersOptions,
  immutableUsersOptions,
}) => {
  const { eoCampaignStepAction } = eoCampaignStep;

  const assigneeOptions = immutableUsersOptions.toJS();

  const defaultState = eoCampaignStepAction
    ? {
        ...eoCampaignStepAction,
        ccEmails: (eoCampaignStepAction.ccEmails || []).join('\n'),
      }
    : initialState;

  const [state, dispatch] = React.useReducer(reducer, defaultState);

  // Set current user as a default assignee if the step is not already assigned
  const { userId } = state;

  React.useEffect(() => {
    !userId && currentUserId && dispatch({ type: 'userId', value: currentUserId });
  }, [currentUserId]);

  // Fetch users options for assigning a to-do only once
  const { createAsDraft } = state;

  React.useEffect(() => {
    !assigneeOptions.length && createAsDraft && handleFetchUsersOptions();
  }, [createAsDraft]);

  const reactQuillRef = React.useRef(null);
  const emailBodyErrorRef = React.useRef(null);
  const ccEmailsErrorRef = React.useRef(null);

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  const setCheckboxValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.checked });
  };

  const setEmailBodyValue = (value) => {
    dispatch({ type: 'emailBody', value });
  };
  const handleApplyEmailTemplate = ({ subject, body }) => {
    dispatch({ type: 'emailSubject', value: subject });
    dispatch({ type: 'emailBody', value: body });
  };
  function handleInsertAttributeToEmailSubject({ textToInsert, isHtml }) {
    const emailSubject = state.emailSubject || '';

    const inputNode = document.querySelector('input[name=emailSubject]');

    const cursorPositionIndex = inputNode.selectionStart;

    const processedTextToInsert = isHtml
      ? ReactDOMServer.renderToStaticMarkup(textToInsert)
      : textToInsert;

    const updatedText =
      emailSubject.slice(0, cursorPositionIndex) +
      processedTextToInsert +
      emailSubject.slice(cursorPositionIndex);

    dispatch({ type: 'emailSubject', value: updatedText });

    inputNode.focus();
  }

  function handleInsertAttributeToEmailBody({ textToInsert, isHtml }) {
    const editorIsAvailable = typeof reactQuillRef.current.getEditor === 'function';

    if (!editorIsAvailable) {
      return;
    }

    const editor = reactQuillRef.current.getEditor();
    const cursorPositionIndex = editor.selection.savedRange.index;

    if (isHtml) {
      const html = ReactDOMServer.renderToStaticMarkup(textToInsert);
      editor.clipboard.dangerouslyPasteHTML(cursorPositionIndex, html);
      reactQuillRef.current.focus();
      return;
    }

    editor.insertText(cursorPositionIndex, textToInsert);
    editor.setSelection(cursorPositionIndex + textToInsert.length);
    reactQuillRef.current.focus();
  }
    
  function handleSelectAssignee(option) {
    const value = option ? option.value : null;

    dispatch({ type: 'userId', value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const ccEmailsArray = state.ccEmails.trim().split(/\r?\n/);

    const data = {
      ...state,
      ccEmails: ccEmailsArray,
    };

    const resp = await onSave(data);

    const { errors } = resp;

    dispatch({ type: 'errors', value: errors });

    !isEmpty(errors) && handleScrollToError(errors, emailBodyErrorRef, ccEmailsErrorRef);
  }

  const { emailSubject, emailBody, ccEmails, errors = {} } = state;

  function buildCcEmailsMessage() {
    const {
      errors: { eoCampaignStepActionCcEmails },
    } = state;

    if (eoCampaignStepActionCcEmails) {
      return { type: 'error', text: eoCampaignStepActionCcEmails };
    }

    return {
      text: translate('eoCampaignStep.eoCampaignStepEmail.inputs.ccEmails.hint'),
      type: 'hint',
    };
  }

  const isAnyFallbackNotFilledInSubject =
    state.emailSubject && state.emailSubject.includes(':FALLBACK}}');
  const isAnyFallbackNotFilledInBody = state.emailBody.includes(':FALLBACK}}');

  return (
    <>
      <div className="eo-campaign-step__headline">
        <h3 className="eo-campaign-step__title">
          {`${eoCampaignStepPosition}. ${translate(
            'eoCampaignStep.actionTypes.EoCampaignStepEmail',
          )}`}
        </h3>

        <div className="eo-campaign-step-wait__btn-group">
          <div className="eo-campaign-step-email__btn">
            <SelectEoEmailTemplateButton onSubmitCb={handleApplyEmailTemplate} />
          </div>

          <div className="eo-campaign-step-wait__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('uiComponents.button.cancel')}
            </ButtonComponent>
          </div>

          <div className="eo-campaign-step-wait__btn">
            <SubmitButtonComponent
              form="eo-campaign-step-wait"
              isGreen
              onClick={handleSubmit}
              text={translate('eoCampaignStep.buttons.submit')(!!eoCampaignStepAction)}
            />
          </div>
        </div>
      </div>

      <div className="eo-campaign-step-email__templates-hint">
        <InfoIcon className="eo-campaign-step-email__templates-info-icon" />
        <span>
          {translate('eoCampaignStep.eoCampaignStepEmail.eoTemplatesHint.mainText')}{' '}
          <a
            className="text_link"
            href="https://www.linkio.com/email-outreach-templates/"
            target="_blank"
            rel="noreferrer"
          >
            {translate('eoCampaignStep.eoCampaignStepEmail.eoTemplatesHint.linkText')}.
            <ExternalLinkIcon className="eo-campaign-step-email__link-icon" />
          </a>
        </span>
      </div>

      <form id="eo-campaign-step-email-form" className="eo-campaign-step-email" noValidate>
        <div className="eo-campaign-step-email__email-subject-wrapper">
        <InputComponent
            label={translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailSubject.label')}
            name="emailSubject"
            onInputChange={setInputValue('emailSubject')}
            type="text"
            value={emailSubject || ''}
            message={{ type: 'error', text: errors.eoCampaignStepActionEmailSubject }}
          />
      
          {isAnyFallbackNotFilledInSubject && (
            <MessageComponent
              message={{
                type: 'infoBlock',
                text: translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailSubject.hint'),
              }}
            />
          )}

          <div className="eo-campaign-step-email__email-subject-attr-btn">
            <EoEmailAttributesButton onInsertAttribute={handleInsertAttributeToEmailSubject} />
          </div>
        </div>

        <div className="eo-campaign-step-email__email-body-wrapper">
          <QuillTextEditor
            isMandatory
            reactQuillRef={reactQuillRef}
            label={translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailBody.label')}
            value={emailBody}
            onChange={setEmailBodyValue}
            placeholder={translate(
              'eoCampaignStep.eoCampaignStepEmail.inputs.emailBody.placeholder',
            )}
            message={{ type: 'error', text: errors.eoCampaignStepActionEmailBody }}
          />
          <div ref={emailBodyErrorRef} />

          <div className="eo-campaign-step-email__email-body-attr-btn">
            <EoEmailAttributesButton onInsertAttribute={handleInsertAttributeToEmailBody} />
          </div>
        </div>

        {isAnyFallbackNotFilledInBody && (
          <MessageComponent
            message={{
              type: 'infoBlock',
              text: translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailBody.hint'),
            }}
          />
        )}

        {formCheckboxes.map((checkbox) => {
          const { name } = checkbox;
          const isChecked = state[name] || false;

          return (
            <Checkbox
              key={name}
              checked={isChecked}
              onChange={setCheckboxValue(name)}
              message={{
                type: 'hint',
                text: translate(`eoCampaignStep.eoCampaignStepEmail.inputs.${name}.hint`),
              }}
            >
              {translate(`eoCampaignStep.eoCampaignStepEmail.inputs.${name}.label`)}
            </Checkbox>
          );
        })}

        {createAsDraft && (
          <Select
            isClearable
            label={translate(`eoCampaignStep.eoCampaignStepEmail.draftAssigneeSelect`)}
            placeholder={translate(`eoToDo.emailArea.assignedToSelectDefaultValue`)}
            onChange={handleSelectAssignee}
            options={assigneeOptions}
            triggerChangeOnBlur={false}
            value={assigneeOptions.find(({ value }) => value === userId) || null}
            wrapperClassName="eo-campaign-step-email__assigned-to-select"
          />
        )}

        <InputComponent
          className="eo-campaign-step-email__textarea"
          label={translate('eoCampaignStep.eoCampaignStepEmail.inputs.ccEmails.label')}
          onInputChange={setInputValue('ccEmails')}
          isMultiline
          message={buildCcEmailsMessage()}
          value={ccEmails}
        />
        <div ref={ccEmailsErrorRef} />
      </form>
    </>
  );
};

EoCampaignStepEmailComponent.propTypes = {
  currentUserId: PropTypes.number,
  eoCampaignStep: eoCampaignStepShape,
  eoCampaignStepPosition: PropTypes.number,
  handleFetchUsersOptions: PropTypes.func,
  immutableUsersOptions: optionsList,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EoCampaignStepEmailComponent;
