import { translate } from '../../common/i18n';

import {
  formattedErrorsMessages,
  showSuccessMessage,
  showErrorMessage,
} from '../../common/utils';

import {
  updateBrandFailure,
  updateBrandSuccess,
} from '../../actions/brand_actions';

import {
  importBacklinksManuallyFailure,
  importBacklinksManuallyStart,
  importBacklinksManuallySuccess,
} from '../../actions/backlinkActions';

const handleImportBacklinksManuallyStatus = (dispatch, data) => {
  const {
    manuallyImportBacklinksStatus,
  } = data;

  switch (manuallyImportBacklinksStatus) {
    case 'started':
      dispatch(importBacklinksManuallyStart(data.id));
      dispatch(updateBrandSuccess(data));
      showSuccessMessage(dispatch, translate('backlinksSetup.messages.importBacklinksManuallyStarted'));
      break;
    case 'finished':
      dispatch(updateBrandSuccess(data));
      showSuccessMessage(dispatch, translate('backlinksSetup.messages.importBacklinksManuallySuccess'));
      dispatch(importBacklinksManuallySuccess(data.id));
      break;
    case 'failure':
      dispatch(updateBrandFailure(data.errors));
      showFailureNotification(dispatch, data);
      dispatch(importBacklinksManuallyFailure(data.id));
      break;
  }
};

const showFailureNotification = (dispatch, data) => {
  const errors = Object.values(data.errors).flat();

  return showErrorMessage(
    dispatch,
    formattedErrorsMessages(errors, 'backlinksSetup.messages.importBacklinksManuallyFailure'),
    true
  );
};

export default handleImportBacklinksManuallyStatus;
