import React from 'react';
import PropTypes from 'prop-types';

import BacklinksChangesHistoryTable from './BacklinksChangesHistoryTable';

import BrandCrawlingStatus from 'pages/BacklinksPage/BacklinksBulkActions/BrandCrawlingStatus';
import CrawlBrandBacklinksBtn from 'pages/BacklinksPage/BacklinksBulkActions/CrawlBrandBacklinksBtn';

import { pagyShape } from 'common/prop_types_shapes';

import PageLayoutHeader from 'components_linkio/PageLayoutHeader';
import SearchInput from 'components/SearchInput';
import Pager from 'components/Pager';

import './backlinksChangesHistoryPage.scss';

const BacklinksChangesHistoryPageComponent = ({
  changesHistoryPagy,
  onItemsSelectChange,
  onPagerClick,
  onSubmitSearch,
  title,
}) => {
  return (
    <div className="changes-history-page">
      <div className="changes-history-page__row">
        <PageLayoutHeader title={title} />
      </div>
      <div className="backlinks-bulk-actions">
        <div className="backlinks-bulk-actions__left-block">
          <div className="backlinks-bulk-actions__search-and-grouping">
            <SearchInput onSubmit={onSubmitSearch} />
          </div>
        </div>
        <div className="backlinks-bulk-actions__right-block">
          <div className="backlinks-bulk-actions__crawling-status-and-btn">
            <BrandCrawlingStatus />
          </div>
        </div>
      </div>

      <BacklinksChangesHistoryTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagerClick}
        pagerType="rowsPager"
        pagy={changesHistoryPagy}
      />
    </div>
  );
};

BacklinksChangesHistoryPageComponent.propTypes = {
  changesHistoryPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagerClick: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  title: PropTypes.string,
};

export default BacklinksChangesHistoryPageComponent;
