import React from 'react';
import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';

import EoActiveHoursPageComponent from './EoActiveHoursPageComponent';

import Breadcrumbs from 'components/NewBreadcrumbs';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { translate } from 'common/i18n';

import { pagyShape } from 'common/prop_types_shapes';
import { eoActiveHoursPagySelector } from 'selectors/eoActiveHoursSelector';

const EoActiveHoursPageContainer = ({ changeQueryParams, eoActiveHoursPagy, queryParams }) => {
  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('eoActiveHour.breadcrumbsTitle')}</Breadcrumbs.TextItem>
      </Breadcrumbs>
      <div className="page__wrapper">
        <EoActiveHoursPageComponent
          onItemsSelectChange={handleItemsSelectChange}
          onPagesPagerClick={handlePagesPagerClick}
          eoActiveHoursPagy={eoActiveHoursPagy}
        />
      </div>
    </>
  );
};

EoActiveHoursPageContainer.propTypes = {
  eoActiveHoursPagy: pagyShape,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoActiveHoursPagy = eoActiveHoursPagySelector(state);

  return {
    eoActiveHoursPagy,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoActiveHoursPageContainer);
