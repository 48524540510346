const ADD_COMPETITORS_MANUALLY__FAILURE = 'ADD_COMPETITORS_MANUALLY__FAILURE';
const ADD_COMPETITORS_MANUALLY__START = 'ADD_COMPETITORS_MANUALLY__START';
const ADD_COMPETITORS_MANUALLY__SUCCESS = 'ADD_COMPETITORS_MANUALLY__SUCCESS';
const ADD_COMPETITORS_TO_ATP__FAILURE = 'ADD_COMPETITORS_TO_ATP__FAILURE';
const ADD_COMPETITORS_TO_ATP__SUCCESS = 'ADD_COMPETITORS_TO_ATP__SUCCESS';
const AUTODISCOVER_COMPETITORS_BY_KEYWORD__FAILURE = 'AUTODISCOVER_COMPETITORS_BY_KEYWORD__FAILURE';
const AUTODISCOVER_COMPETITORS_BY_KEYWORD__START = 'AUTODISCOVER_COMPETITORS_BY_KEYWORD__START';
const AUTODISCOVER_COMPETITORS_BY_KEYWORD__SUCCESS = 'AUTODISCOVER_COMPETITORS_BY_KEYWORD__SUCCESS';
const FETCH_BULK_COMPETITOR_PAGES__FAILURE = 'FETCH_BULK_COMPETITOR_PAGES__FAILURE';
const FETCH_BULK_COMPETITOR_PAGES__START = 'FETCH_BULK_COMPETITOR_PAGES__START';
const FETCH_BULK_COMPETITOR_PAGES__SUCCESS = 'FETCH_BULK_COMPETITOR_PAGES__SUCCESS';
const UPDATE_DRAFT_COMPETITOR_PAGE__FAILURE = 'UPDATE_DRAFT_COMPETITOR_PAGE__FAILURE';
const UPDATE_DRAFT_COMPETITOR_PAGE__SUCCESS = 'UPDATE_DRAFT_COMPETITOR_PAGE__SUCCESS';
const UPDATE_DRAFT_COMPETITOR_PAGES_ROWS = 'UPDATE_DRAFT_COMPETITOR_PAGES_ROWS';

function addCompetitorsManuallyFailure(errors) {
  return { type: ADD_COMPETITORS_MANUALLY__FAILURE, errors };
}

function addCompetitorsManuallyStart() {
  return { type: ADD_COMPETITORS_MANUALLY__START };
}

function addCompetitorsManuallySuccess(pages, brandPageId) {
  return { type: ADD_COMPETITORS_MANUALLY__SUCCESS, pages, brandPageId };
}

function addCompetitorsToAtpFailure(pages, brandPageId) {
  return { type: ADD_COMPETITORS_TO_ATP__FAILURE, pages, brandPageId };
}

function addCompetitorsToAtpSuccess(pages, brandPageId) {
  return { type: ADD_COMPETITORS_TO_ATP__SUCCESS, pages, brandPageId };
}

function autodiscoverCompetitorsByKeywordFailure(payload) {
  return { type: AUTODISCOVER_COMPETITORS_BY_KEYWORD__FAILURE, payload };
}

function autodiscoverCompetitorsByKeywordStart(brandPageId) {
  return { type: AUTODISCOVER_COMPETITORS_BY_KEYWORD__START, brandPageId };
}

function autodiscoverCompetitorsByKeywordSuccess(payload) {
  return { type: AUTODISCOVER_COMPETITORS_BY_KEYWORD__SUCCESS, payload };
}

function fetchBulkCompetitorPagesFailure(errors) {
  return { type: FETCH_BULK_COMPETITOR_PAGES__FAILURE, errors };
}

function fetchBulkCompetitorPagesStart() {
  return { type: FETCH_BULK_COMPETITOR_PAGES__START };
}

function fetchBulkCompetitorPagesSuccess(pages, brandPageId) {
  return { type: FETCH_BULK_COMPETITOR_PAGES__SUCCESS, pages, brandPageId };
}

function updateDraftCompetitorPageSuccess(page) {
  return { type: UPDATE_DRAFT_COMPETITOR_PAGE__SUCCESS, page };
}

function updateDraftCompetitorPageFailure(errors) {
  return { type: UPDATE_DRAFT_COMPETITOR_PAGE__FAILURE, errors };
}

function updateDraftCompetitorPagesRows(pages, brandPageId) {
  return { type: UPDATE_DRAFT_COMPETITOR_PAGES_ROWS, pages, brandPageId };
}

export {
  ADD_COMPETITORS_MANUALLY__FAILURE,
  ADD_COMPETITORS_MANUALLY__START,
  ADD_COMPETITORS_MANUALLY__SUCCESS,
  ADD_COMPETITORS_TO_ATP__FAILURE,
  ADD_COMPETITORS_TO_ATP__SUCCESS,
  AUTODISCOVER_COMPETITORS_BY_KEYWORD__FAILURE,
  AUTODISCOVER_COMPETITORS_BY_KEYWORD__START,
  AUTODISCOVER_COMPETITORS_BY_KEYWORD__SUCCESS,
  FETCH_BULK_COMPETITOR_PAGES__FAILURE,
  FETCH_BULK_COMPETITOR_PAGES__START,
  FETCH_BULK_COMPETITOR_PAGES__SUCCESS,
  UPDATE_DRAFT_COMPETITOR_PAGE__FAILURE,
  UPDATE_DRAFT_COMPETITOR_PAGE__SUCCESS,
  UPDATE_DRAFT_COMPETITOR_PAGES_ROWS,
  addCompetitorsManuallyFailure,
  addCompetitorsManuallyStart,
  addCompetitorsManuallySuccess,
  addCompetitorsToAtpFailure,
  addCompetitorsToAtpSuccess,
  autodiscoverCompetitorsByKeywordFailure,
  autodiscoverCompetitorsByKeywordStart,
  autodiscoverCompetitorsByKeywordSuccess,
  fetchBulkCompetitorPagesFailure,
  fetchBulkCompetitorPagesStart,
  fetchBulkCompetitorPagesSuccess,
  updateDraftCompetitorPageSuccess,
  updateDraftCompetitorPageFailure,
  updateDraftCompetitorPagesRows,
};
