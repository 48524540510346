import { fromJS, Map as iMap } from 'immutable';
import { keyBy } from 'lodash';

import {
  CREATE_PROXIES__SUCCESS,
  DELETE_PROXY__SUCCESS,
  FETCH_PROXIES__SUCCESS,
  CHECK_SINGLE_PROXY__START,
  CHECK_SINGLE_PROXY__FINISHED,
} from '../actions/proxy_actions';

import {
  camelizeObjectDeep,
} from './utils';

const initialState = iMap();


export default function proxyReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PROXIES__SUCCESS:
    case FETCH_PROXIES__SUCCESS: {
      state = state
        .merge(state, fromJS(keyBy(action.proxies.map(camelizeObjectDeep), 'id')))
        .sortBy((proxy) => proxy.get('alive')).reverse();
      break;
    }
    case DELETE_PROXY__SUCCESS: {
      state = state.deleteAll(action.ids.map(String));

      break;
    }
    case CHECK_SINGLE_PROXY__START:
    case CHECK_SINGLE_PROXY__FINISHED: {
      state = state
        .merge(state, fromJS(keyBy([camelizeObjectDeep(action.proxy)], 'id')))
        .sortBy((proxy) => proxy.get('alive')).reverse();
      break;
    }
  }

  return state;
}
