import React from 'react';

const DownArrowIconV2 = (props) => {
  return (
    <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.634 5.537a.48.48 0 000 .666l4.149 4.256.003.004.39.4a.45.45 0 00.648 0l4.542-4.66a.48.48 0 000-.666l-.39-.399a.45.45 0 00-.648 0L7.5 9.065 3.672 5.138a.45.45 0 00-.648 0l-.39.4z"
        fill="#474a4d"
      />
    </svg>
  );
};

DownArrowIconV2.defaultProps = {
  width: '14px',
  height: '14px',
  cursor: 'pointer',
};

export default DownArrowIconV2;
