import { fromJS, List as iList } from 'immutable';
import { camelizeObjectDeep, mergeItemToList } from './utils';

import {
  CREATE_EO_CAMPAIGN_STEP__SUCCESS,
  DELETE_EO_CAMPAIGN_STEPS__SUCCESS,
  FETCH_EO_CAMPAIGN_STEP__SUCCESS,
  FETCH_EO_CAMPAIGN_STEPS__SUCCESS,
  UPDATE_EO_CAMPAIGN_STEP__SUCCESS,
  MOVE_EO_CAMPAIGN_STEP__SUCCESS,
} from 'actions/eoCampaignStepActions';

const initialState = iList();

export default function eoCampaignStepsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_CAMPAIGN_STEPS__SUCCESS:
    case MOVE_EO_CAMPAIGN_STEP__SUCCESS: {
      const { eoCampaignSteps } = action;

      state = fromJS(eoCampaignSteps.map(camelizeObjectDeep));

      break;
    }
    case CREATE_EO_CAMPAIGN_STEP__SUCCESS:
    case FETCH_EO_CAMPAIGN_STEP__SUCCESS:
    case UPDATE_EO_CAMPAIGN_STEP__SUCCESS: {
      const { eoCampaignStep } = action;

      state = mergeItemToList(state, eoCampaignStep, camelizeObjectDeep);

      break;
    }
    case DELETE_EO_CAMPAIGN_STEPS__SUCCESS: {
      const { ids } = action;

      state = state.filter((eoCampaignStep) => !ids.includes(eoCampaignStep.get('id')));

      break;
    }
  }

  return state;
}
