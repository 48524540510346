import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';
import { currentIdSelector } from './index';

const simpleBrands = (state) => state.get('simpleBrands', iMap());

export const simpleBrandsSelector = createSelector([simpleBrands], (simpleBrands) => simpleBrands);

export const currentSimpleBrandSelector = createSelector(
  [simpleBrands, currentIdSelector],
  (simpleBrands, currentBrandId) => simpleBrands.get(currentBrandId, iMap()),
);
