import { fromJS, Map as iMap } from 'immutable';

import { camelizeObjectDeep } from './utils';

import { FETCH_CHANGES_HISTORY__SUCCESS } from 'actions/changesHistoryActions';

const initialState = iMap();

export default function changesHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CHANGES_HISTORY__SUCCESS: {
      const { changesHistory, brandId } = action;
      state = state.set(String(brandId), fromJS(changesHistory.map(camelizeObjectDeep)));

      break;
    }
  }

  return state;
}
