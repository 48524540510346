import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';
import { get } from 'lodash';

import { currentIdSelector } from 'selectors';

const eoCampaigns = (state) => state.get('eoCampaigns', iList());
const eoCampaignsPagy = (state) => state.getIn(['pagies', 'eoCampaignsPagy'], iMap());

export const activeEoCampaignTabSelector = (_state, props) =>
  get(props, 'match.params.activeEoCampaignTab', null);

export const eoCampaignsSelector = createSelector([eoCampaigns], (eoCampaigns) => eoCampaigns);

export const eoCampaignSelector = createSelector(
  [eoCampaigns, currentIdSelector],
  (eoCampaigns, campaignId) =>
    eoCampaigns.find((eoCampaign) => String(eoCampaign.get('id')) === campaignId) || iMap(),
);

export const eoCampaignsPagySelector = createSelector([eoCampaignsPagy], (pagy) => pagy);
