import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';
import { withRouter } from 'react-router-dom';

import EoCampaignStepsPageComponent from './EoCampaignStepsPageComponent';

import { currentIdSelector } from 'selectors';
import { eoCampaignStepsList } from 'common/propTypesShapes/eoCampaignSteps';
import { eoCampaignStepsSelector } from 'selectors/eoCampaignStepsSelector';
import { fetchEoCampaignSteps } from 'api/eoCampaignStep';

import ConnectStoreHOC from 'startup/connect_store_hoc';

const EoCampaignStepsPageContainer = ({ dispatch, eoCampaignId, eoCampaignSteps }) => {
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      const fetchingParams = { eoCampaignId };

      setIsFetching(true);
      await fetchEoCampaignSteps(dispatch, fetchingParams);
      setIsFetching(false);
    }

    fetchData();
  }, [dispatch, eoCampaignId]);

  return (
    <>
      <EoCampaignStepsPageComponent
        immutableEoCampaignSteps={eoCampaignSteps}
        eoCampaignId={eoCampaignId}
        isFetching={isFetching}
      />
    </>
  );
};

EoCampaignStepsPageContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoCampaignSteps: eoCampaignStepsList,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoCampaignSteps = eoCampaignStepsSelector(state);

  return {
    eoCampaignId,
    eoCampaignSteps,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(EoCampaignStepsPageContainer);
