export default {
  eoToDo: {
    breadcrumbsTitle: 'To Do List',
    bulkActions: {
      filterByAssignees: 'Assigned to',
      filterByBrand: 'Filter by brand',
      filterByCampaign: 'Filter by campaign',
      filterByToDoType: 'To Do type',
    },
    details: {
      prospectInfoTitle: 'prospect information',
      campaignInfoTitle: 'campaign information',
      eoCustomFieldsInfoTitle: 'custom fields',
    },
    emailArea: {
      assignedToSelectDefaultValue: 'Unassigned',
      buttons: {
        sendEmail: 'Send Email',
        delete: 'Delete',
      },
    },
    messages: {
      cantDeleteEoToDos: "Can't delete the task.",
      cantFetchEoToDo: "Can't load the task.",
      cantFetchEoToDos: "Can't load tasks.",
      cantSendEoToDoEmail: "Can't send the email.",
      cantUpdateEoToDo: "Can't assign the task.",
      deleteSuccess: 'Task was deleted successfully.',
      sendEoToDoEmailSuccess: 'The email was sent successfully.',
      assignSuccess: 'The task was assigned successfully.',
      revokeAssignmentSuccess: 'Task assignment was revoked successfully.',
    },
    noTaskSelectedMessage: 'Select a task from the Tasks list.',
    tasks: {
      eoToDoCard: {
        stepPosition: 'Step #',
        noAssignee: 'Unassigned',
        noBrandAssigned: 'No brand association',
      },
      header: {
        title: 'Tasks',
      },
      sorting: {
        newest: 'Newest',
        oldest: 'Oldest',
      },
    },
  },
};
