import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';

const brandsMetrics = (state) => state.getIn(['accountDashboard', 'brandsMetrics'], iMap());

const brandsMetricsSelector = createSelector(
  [brandsMetrics],
  (brandsMetrics) => brandsMetrics.sortBy((brandMetrics) => brandMetrics.get('name').toLocaleLowerCase()) || iMap()
);

export {
  brandsMetricsSelector,
};
