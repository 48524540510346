const CATEGORIZE_DESTINATION_LINKS__FAILURE = 'CATEGORIZE_DESTINATION_LINKS__FAILURE';
const CATEGORIZE_DESTINATION_LINKS__START = 'CATEGORIZE_DESTINATION_LINKS__START';
const CATEGORIZE_DESTINATION_LINKS__SUCCESS = 'CATEGORIZE_DESTINATION_LINKS__SUCCESS';
const CREATE_DESTINATION_LINKS__FAILURE = 'CREATE_DESTINATION_LINKS__FAILURE';
const CREATE_DESTINATION_LINKS__START = 'CREATE_DESTINATION_LINKS__START';
const CREATE_DESTINATION_LINKS__SUCCESS = 'CREATE_DESTINATION_LINKS__SUCCESS';
const DELETE_DESTINATION_LINKS__FAILURE = 'DELETE_DESTINATION_LINKS__FAILURE';
const DELETE_DESTINATION_LINKS__START = 'DELETE_DESTINATION_LINKS__START';
const DELETE_DESTINATION_LINKS__SUCCESS = 'DELETE_DESTINATION_LINKS__SUCCESS';
const FETCH_BRAND_PAGE_DLS__SUCCESS = 'FETCH_BRAND_PAGE_DLS__SUCCESS';
const FETCH_DESTINATION_LINKS__FAILURE = 'FETCH_DESTINATION_LINKS__FAILURE';
const FETCH_DESTINATION_LINKS__START = 'FETCH_DESTINATION_LINKS__START';
const FETCH_PAGE_DASHBOARD_DLS__SUCCESS = 'FETCH_PAGE_DASHBOARD_DLS__SUCCESS';
const UPDATE_DESTINATION_LINKS__FAILURE = 'UPDATE_DESTINATION_LINKS__FAILURE';
const UPDATE_DESTINATION_LINKS__START = 'UPDATE_DESTINATION_LINKS__START';
const UPDATE_DESTINATION_LINKS__SUCCESS = 'UPDATE_DESTINATION_LINKS__SUCCESS';
const UPDATE_PAGE_DASHBOARD_DLS__SUCCESS = 'UPDATE_PAGE_DASHBOARD_DLS__SUCCESS';

function fetchBrandPageDlsSuccess(dls) {
  return { type: FETCH_BRAND_PAGE_DLS__SUCCESS, dls };
}

function fetchPageDashboardDlsSuccess(dls, brandPageId) {
  return { type: FETCH_PAGE_DASHBOARD_DLS__SUCCESS, dls, brandPageId };
}

function fetchDestinationLinksStart() {
  return { type: FETCH_DESTINATION_LINKS__START };
}

function fetchDestinationLinksFailure(errors) {
  return { type: FETCH_DESTINATION_LINKS__FAILURE, errors };
}

function createDestinationLinksStart() {
  return { type: CREATE_DESTINATION_LINKS__START };
}

function createDestinationLinksSuccess(dls) {
  return { type: CREATE_DESTINATION_LINKS__SUCCESS, dls };
}

function createDestinationLinksFailure(errors) {
  return { type: CREATE_DESTINATION_LINKS__FAILURE, errors };
}

function updateDestinationLinksStart() {
  return { type: UPDATE_DESTINATION_LINKS__START };
}

function updateDestinationLinksSuccess(dls) {
  return { type: UPDATE_DESTINATION_LINKS__SUCCESS, dls };
}

function updatePageDashboardDlsSuccess(dls, brandPageId) {
  return { type: UPDATE_PAGE_DASHBOARD_DLS__SUCCESS, dls, brandPageId };
}

function updateDestinationLinksFailure(errors) {
  return { type: UPDATE_DESTINATION_LINKS__FAILURE, errors };
}

function deleteDestinationLinksStart() {
  return { type: DELETE_DESTINATION_LINKS__START };
}

function deleteDestinationLinksSuccess(ids) {
  return { type: DELETE_DESTINATION_LINKS__SUCCESS, ids };
}

function deleteDestinationLinksFailure(errors) {
  return { type: DELETE_DESTINATION_LINKS__FAILURE, errors };
}

function categorizeDestinationLinksStart() {
  return { type: CATEGORIZE_DESTINATION_LINKS__START };
}

function categorizeDestinationLinksSuccess(dls) {
  return { type: CATEGORIZE_DESTINATION_LINKS__SUCCESS, dls };
}

function categorizeDestinationLinksFailure(errors) {
  return { type: CATEGORIZE_DESTINATION_LINKS__FAILURE, errors };
}

export {
  CATEGORIZE_DESTINATION_LINKS__FAILURE,
  CATEGORIZE_DESTINATION_LINKS__START,
  CATEGORIZE_DESTINATION_LINKS__SUCCESS,
  CREATE_DESTINATION_LINKS__FAILURE,
  CREATE_DESTINATION_LINKS__START,
  CREATE_DESTINATION_LINKS__SUCCESS,
  DELETE_DESTINATION_LINKS__FAILURE,
  DELETE_DESTINATION_LINKS__START,
  DELETE_DESTINATION_LINKS__SUCCESS,
  FETCH_BRAND_PAGE_DLS__SUCCESS,
  FETCH_DESTINATION_LINKS__FAILURE,
  FETCH_DESTINATION_LINKS__START,
  FETCH_PAGE_DASHBOARD_DLS__SUCCESS,
  UPDATE_DESTINATION_LINKS__FAILURE,
  UPDATE_DESTINATION_LINKS__START,
  UPDATE_DESTINATION_LINKS__SUCCESS,
  UPDATE_PAGE_DASHBOARD_DLS__SUCCESS,
  categorizeDestinationLinksFailure,
  categorizeDestinationLinksStart,
  categorizeDestinationLinksSuccess,
  createDestinationLinksFailure,
  createDestinationLinksStart,
  createDestinationLinksSuccess,
  deleteDestinationLinksFailure,
  deleteDestinationLinksStart,
  deleteDestinationLinksSuccess,
  fetchBrandPageDlsSuccess,
  fetchDestinationLinksFailure,
  fetchDestinationLinksStart,
  fetchPageDashboardDlsSuccess,
  updateDestinationLinksFailure,
  updateDestinationLinksStart,
  updateDestinationLinksSuccess,
  updatePageDashboardDlsSuccess,
};
