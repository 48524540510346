import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import DeleteEoEmailTemplatesButtonComponent from './DeleteEoEmailTemplatesButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { deleteEoEmailTemplates } from 'api/eoEmailTemplate';
import { selectedIdsList } from 'common/prop_types_shapes';
import { uiSelector } from 'selectors';

const DeleteEoEmailTemplatesButtonContainer = ({ dispatch, selectedIds }) => {
  function handleDelete() {
    deleteEoEmailTemplates(dispatch, selectedIds.toJS());
  }

  return (
    <DeleteEoEmailTemplatesButtonComponent
      onDelete={handleDelete}
      selectedIds={selectedIds}
    />
  );
};

DeleteEoEmailTemplatesButtonContainer.propTypes = {
  dispatch: PropTypes.func,
  selectedIds: selectedIdsList,
};

function select(state) {
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    selectedIds,
  };
}

export default compose(
  ConnectStoreHOC,
  connect(select)
)(DeleteEoEmailTemplatesButtonContainer);
