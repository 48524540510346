import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';

const brandChartMetricsByBrandId = (state, brandId) =>
  state.getIn(['brandsCharts', 'brandChartMetrics', String(brandId)], iMap());
  
export const brandChartMetricsByBrandIdSelector = createSelector(
  [brandChartMetricsByBrandId],
  (brandChartMetricsByBrandId) => brandChartMetricsByBrandId || iMap(),
);
