import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

import './brandsBlankState.scss';

const BrandsBlankStateComponent = ({ onClickAddBrand }) => {
  return (
    <div className="brands-blank-state">
      <h2>{translate('prompts.noBrands.title')}</h2>

      <div className="info-card brands-blank-state__card">
        <h2>{translate('prompts.noBrands.bodyText.title')}</h2>

        <p>{translate('prompts.noBrands.bodyText.subtitle')}</p>

        <ul>
          <li>- {translate('prompts.noBrands.bodyText.modulesList.rankTracker')}</li>
          <li>- {translate('prompts.noBrands.bodyText.modulesList.backlinks')}</li>
          <li>- {translate('prompts.noBrands.bodyText.modulesList.atp')}</li>
        </ul>

        <ButtonComponent
          className="brands-blank-state__add-brand-button"
          isBlue
          isInitial
          onClick={onClickAddBrand}
        >
          {translate('prompts.noBrands.buttons.addBrand')}
        </ButtonComponent>
      </div>
    </div>
  );
};

BrandsBlankStateComponent.propTypes = {
  onClickAddBrand: PropTypes.func,
};

export default BrandsBlankStateComponent;
