const categorizerPopup = (args) => {
  return {
    categorizerPopup: {
      title: 'Anchor Text Categorization Settings',
      labels: {
        all: 'All',
        alternativePageUrl: "Alternative page URL",
        brandName: 'Brand name',
        categorize: 'Categorize anchor text now',
        city: 'City',
        product: 'Product/Service',
        selected: 'Selected',
        state: 'State',
        stateAbbr: 'State abbreviation',
        titleTag: 'Title tag',
        uncategorized: 'Uncategorized',
        anchorSamples: 'Current Categorizations',
      },
      hints: {
        title: 'Please add one title per line',
      },
      buttons: {
        saveAndCategorize: saveAndCategorizeText(args),
        cancel: 'Close',
      },
    },
  };
};

const saveAndCategorizeText = (args) => {
  if (args.onlySave) {
    return 'Save';
  }
  if (args.anyChanges) {
    return 'Save and Categorize';
  }
  return 'Categorize';
};

export default categorizerPopup;
