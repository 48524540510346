import React from 'react';


const ExternalLinkIcon = (props) => {
  return (
    <svg {...props} viewBox="0.5849999785423279 1.11899995803833 98.2760009765625 98.61100006103516" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" stroke="#000" strokeWidth=".25" strokeMiterlimit="10" d="M57.5 11.75v5.75" />
      <path d="M98.57 4.784c-.024-.113-.047-.223-.074-.334a6.117 6.117 0 0 0-.117-.346c-.023-.062-.037-.125-.064-.187a1.368 1.368 0 0 0-.049-.092 2.644 2.644 0 0 0-.17-.336c-.05-.089-.095-.179-.152-.265-.062-.1-.139-.195-.209-.292-.065-.082-.124-.162-.19-.242-.081-.092-.172-.178-.258-.262-.07-.068-.139-.139-.212-.202a3.464 3.464 0 0 0-.3-.232c-.08-.058-.152-.114-.236-.163-.104-.069-.219-.128-.329-.186-.091-.046-.175-.093-.262-.132-.044-.019-.081-.043-.121-.061-.074-.03-.154-.046-.234-.072a4.598 4.598 0 0 0-.288-.092 4.89 4.89 0 0 0-.369-.083 2.952 2.952 0 0 0-.289-.046c-.131-.018-.263-.022-.393-.028-.064-.002-.12-.012-.186-.012l-30.298.186a4.58 4.58 0 0 0-4.554 4.605 4.582 4.582 0 0 0 4.604 4.546l19.258-.121-29.79 30.162a4.557 4.557 0 0 0-1.233 4.118 4.566 4.566 0 0 0 1.272 2.357 4.568 4.568 0 0 0 3.245 1.32 4.554 4.554 0 0 0 3.227-1.361l.002-.001 29.787-30.158.121 19.255a4.568 4.568 0 0 0 4.602 4.545 4.565 4.565 0 0 0 4.55-4.599l-.186-30.308c0-.063-.016-.124-.016-.189-.009-.123-.015-.25-.032-.375-.011-.109-.035-.211-.057-.317z" />
      <path d="M6.542 83.279c0 5.717 4.635 10.354 10.354 10.354l55.616.065c5.719 0 10.354-4.636 10.354-10.354l-.091-40.782h6.135v40.849c0 9.014-7.307 16.319-16.319 16.319l-56.36-.066C7.634 99.664.667 92.695.667 84.102L.585 27.821c0-9.014 7.306-16.32 16.32-16.32l41.138-.084v6.142l-41.063-.024c-5.718 0-10.354 4.635-10.354 10.354l-.084 55.39z" />
    </svg>
  );
};

export default ExternalLinkIcon;
