import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoSchedulersTableComponent from './EoSchedulersTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { fetchEoSchedulers } from 'api/eoScheduler';
import { eoSchedulersList } from 'common/propTypesShapes/eoSchedulers';

import { eoSchedulersSelector, eoSchedulersPagySelector } from 'selectors/eoSchedulersSelector';
import { currentIdSelector, uiSelector } from 'selectors';
import { policiesSelector } from 'selectors/railsContextSelectors';

import { resetSelectedIds, toggleSelectedIds } from 'actions/ui_actions';
import { pagyShape, selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const EoSchedulersTableContainer = ({
  canUpdate,
  changeQueryParams,
  dispatch,
  eoCampaignId,
  eoSchedulers,
  eoSchedulersPagy,
  queryParams,
  selectedIds,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const { pageNumber = 1, pagyItemsCount = 100, sorting = {} } = queryParams;

  React.useEffect(() => {
    async function fetchData() {
      const fetchingParams = {
        items: pagyItemsCount,
        page: pageNumber,
        eoCampaignId,
        sorting,
      };

      setIsFetching(true);
      await fetchEoSchedulers(dispatch, fetchingParams);
      setIsFetching(false);
    }

    fetchData();
  }, [dispatch, eoCampaignId, pageNumber, pagyItemsCount, sorting.sortBy, sorting.sortingOrder]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectedIds());
    };
  }, [dispatch, eoCampaignId]);

  function handleToggleSelectedRows(eoSchedulersIds, isSelected) {
    dispatch(toggleSelectedIds(eoSchedulersIds, isSelected));
  }

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  const eoSchedulersCount = eoSchedulersPagy.get('count');

  return (
    <>
      {eoSchedulers.size > 0 && (
        <EoSchedulersTableComponent
          canUpdate={canUpdate}
          onTableHeaderClick={handleTableHeaderClick}
          onToggleSelectedRows={handleToggleSelectedRows}
          eoSchedulers={eoSchedulers}
          eoSchedulersCount={eoSchedulersCount}
          selectedIds={selectedIds}
          sorting={sorting}
        />
      )}
      {eoSchedulers.size === 0 && (
        <BlankStateCtaComponent isLoading={isFetching}>
          <TitleText>
            <strong>{translate('eoScheduler.table.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('eoScheduler.table.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

EoSchedulersTableContainer.propTypes = {
  canUpdate: PropTypes.bool,
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoSchedulers: eoSchedulersList,
  eoSchedulersPagy: pagyShape,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoSchedulers = eoSchedulersSelector(state);
  const eoSchedulersPagy = eoSchedulersPagySelector(state);

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  const policies = policiesSelector(state);
  const canUpdate = policies.getIn(['emailOutreach', 'canUpdate']);

  return {
    canUpdate,
    eoCampaignId,
    eoSchedulers,
    eoSchedulersPagy,
    selectedIds,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoSchedulersTableContainer);
