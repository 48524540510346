import React from 'react';

import { updateBrandSuccess } from 'actions/brand_actions';
import { updatePageSuccess } from 'actions/brand_page_actions';
import { createDestinationLinksSuccess } from 'actions/destination_link_actions';
import {
  importDestinationLinksFromCSVFailure,
  importDestinationLinksFromCSVSuccess,
} from 'actions/destination_links_import_actions';

import { translate } from 'common/i18n';

import {
  showSuccessMessage,
  showErrorMessage,
  showSuccessMessageFromTranslation,
} from 'common/utils';

const handleImportFromCsvStatus = (dispatch, data) => {
  const {
    brand = {},
    brandPage = {},
    categorized,
    destinationLinks,
    dupeEntriesCount,
    importFromCsvStatus,
    uniqEntriesCount,
  } = data;

  switch (importFromCsvStatus) {
    case 'finished':
      dispatch(updateBrandSuccess(brand));
      dispatch(updatePageSuccess(brandPage));
      dispatch(createDestinationLinksSuccess(destinationLinks));
      dispatch(importDestinationLinksFromCSVSuccess(brandPage.id));
      handleImportCsvMessages(dispatch, brandPage, dupeEntriesCount, uniqEntriesCount);
      categorized && showSuccessCategorizeMessage(dispatch, destinationLinks.length);
      break;
    case 'failure':
      dispatch(importDestinationLinksFromCSVFailure(brandPage.id));
      handleImportCsvMessages(dispatch, brandPage);
      break;
  }
};

const handleImportCsvMessages = (dispatch, brandPage, dupeEntriesCount, uniqEntriesCount) => {
  const errors = (brandPage.errors || {}).base;
  const isCompetitorsPage = !!brandPage.brand_page_id;

  errors && errors.length > 0
    ? showErrorMessage(
        dispatch,
        importCSVMessageWithErrors(errors, dupeEntriesCount, uniqEntriesCount),
        true,
      ) // show permanent error
    : showSuccessMessage(
        dispatch,
        importCSVMessageWOErrors(dupeEntriesCount, uniqEntriesCount, isCompetitorsPage),
        false,
      );
};

const showSuccessCategorizeMessage = (dispatch, destinationLinksCount) => {
  showSuccessMessageFromTranslation(
    dispatch,
    'destinationLink.messages.linksCategorizedSuccessfully',
    {
      count: destinationLinksCount,
    },
  );
};

const importCSVMessageWithErrors = (errors, dupeEntriesCount, uniqEntriesCount) => {
  return (
    <span>
      <span>
        {translate('destinationLink.messages.csvImportFailure', {
          dupeEntriesCount,
          uniqEntriesCount,
        })}
      </span>
      <ul>
        {errors.map((error, key) => (
          <li key={key}>{error}</li>
        ))}
      </ul>
    </span>
  );
};

const importCSVMessageWOErrors = (dupeEntriesCount, uniqEntriesCount, isCompetitorsPage) => {
  return translate('destinationLink.messages.csvImportSuccess', {
    dupeEntriesCount,
    uniqEntriesCount,
    isCompetitorsPage,
  });
};

export default handleImportFromCsvStatus;
