import pluralize from 'pluralize';

import { buildWordInPastTenseWithPluralization } from 'common/utils';

export default {
  eoMessage: {
    breadcrumbsTitle: 'Replies',
    messages: {
      cantFetchEoMessages: "Can't load Replies",
      cantFetchEoMessage: "Can't load Reply",
      cantDeleteEoMessages: "Can't delete Replies",
      deleteSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization('Reply', count)} deleted successfully.`;
      },
    },
    table: {
      blankState: {
        body: '',
        title: "You don't have any Replies yet",
      },
      headers: {
        eoCampaignStepPosition: 'Step #',
        prospectEmail: 'Email Address',
        prospectPosition: 'Position',
        createdAt: 'Received',
      },
      eoMessagesCount: (count) => `${pluralize('Message', count, true)}`,
    },
    eoMessagePopup: {
      campaign: 'Campaign',
      from: 'From',
      prospect: 'Prospect',
      to: 'To',
      step: (position) => `Step #${position}`,
      title: 'Reply',
    },
  },
};
