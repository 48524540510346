import React from 'react';

import {
  importProspectsFailure,
  importProspectsSuccess,
  importProspectsStart,
  updateProspectsSuccess,
} from 'actions/prospectsActions';

import { translate } from 'common/i18n';

import { showSuccessMessage, showErrorMessage } from 'common/utils';

const handleImportProspectsFromCsvStatus = (dispatch, data) => {
  const {
    marketer,
    prospects,
    dupeEntriesCount,
    uniqEntriesCount,
    errors,
    importProspectsFromCsvStatus,
    notAddedToDripListCount,
  } = data;

  switch (importProspectsFromCsvStatus) {
    case 'started':
      dispatch(importProspectsStart(marketer));
      showSuccessMessage(dispatch, translate('prospects.messages.importStarted'));
      break;
    case 'finished':
      dispatch(importProspectsSuccess(marketer));
      dispatch(updateProspectsSuccess(prospects));
      handleImportCsvMessages(dispatch, errors, {
        dupeEntriesCount,
        uniqEntriesCount,
        notAddedToDripListCount,
      });
      break;
    case 'failure':
      dispatch(importProspectsFailure());
      handleImportCsvMessages(dispatch, errors);
      break;
  }
};

const handleImportCsvMessages = (dispatch, errors, entriesCount) => {
  errors && errors.length > 0
    ? showErrorMessage(dispatch, importCSVMessageWithErrors(errors, entriesCount), true) // show permanent error
    : showSuccessMessage(dispatch, importCSVMessageWOErrors(entriesCount), false);
};

const importCSVMessageWithErrors = (
  errors,
  { dupeEntriesCount, uniqEntriesCount, notAddedToDripListCount },
) => {
  return (
    <span>
      <span>
        {translate('prospects.messages.csvImportFailure')({
          dupeEntriesCount,
          uniqEntriesCount,
          notAddedToDripListCount,
        })}
      </span>
      <ul>
        {errors.map((error, key) => (
          <li key={key}>{error}</li>
        ))}
      </ul>
    </span>
  );
};

const importCSVMessageWOErrors = ({
  dupeEntriesCount,
  uniqEntriesCount,
  notAddedToDripListCount,
}) => {
  return translate('prospects.messages.csvImportSuccess')({
    dupeEntriesCount,
    uniqEntriesCount,
    notAddedToDripListCount,
  });
};

export default handleImportProspectsFromCsvStatus;
