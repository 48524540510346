import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../common/i18n';

import ButtonComponent from '../../components_linkio/button_component';

import './newProxiesInputComponent.scss';


class NewProxiesInputComponent extends Component {
  static propTypes = {
    onNewProxiesClick: PropTypes.func.isRequired,
  };

  proxiesInput = {};

  handleAddNewProxiesClick = () => {
    const proxies = this.proxiesInput.value.trim().split('\n');
    this.proxiesInput.value = '';
    this.props.onNewProxiesClick(proxies);
  }

  render() {
    return (
      <div className="new-proxies-input-component">
        <div className="new-proxies-input-component__row">
          <textarea
            className="new-proxies-input-component__textarea"
            ref={(input) => this.proxiesInput = input}
            placeholder={translate('proxy.newProxies.placeholder')}
          />
        </div>
        <div className="new-proxies-input-component__row">
          <ButtonComponent
            isGreen
            onClick={this.handleAddNewProxiesClick}
            className="new-proxies-input-component__add_new_proxies_btn"
          >
            {translate('proxy.newProxies.button')}
          </ButtonComponent>
        </div>
      </div>
    );
  }
}

export default NewProxiesInputComponent;
