export const CREATE_EO_ACTIVE_HOUR__FAILURE = 'CREATE_EO_ACTIVE_HOUR__FAILURE';
export const CREATE_EO_ACTIVE_HOUR__START = 'CREATE_EO_ACTIVE_HOUR__START';
export const CREATE_EO_ACTIVE_HOUR__SUCCESS = 'CREATE_EO_ACTIVE_HOUR__SUCCESS';
export const DELETE_EO_ACTIVE_HOURS__FAILURE = 'DELETE_EO_ACTIVE_HOURS__FAILURE';
export const DELETE_EO_ACTIVE_HOURS__START = 'DELETE_EO_ACTIVE_HOURS__START';
export const DELETE_EO_ACTIVE_HOURS__SUCCESS = 'DELETE_EO_ACTIVE_HOURS__SUCCESS';
export const FETCH_EO_ACTIVE_HOURS__FAILURE = 'FETCH_EO_ACTIVE_HOURS__FAILURE';
export const FETCH_EO_ACTIVE_HOURS__START = 'FETCH_EO_ACTIVE_HOURS__START';
export const FETCH_EO_ACTIVE_HOURS__SUCCESS = 'FETCH_EO_ACTIVE_HOURS__SUCCESS';
export const FETCH_EO_ACTIVE_HOUR__FAILURE = 'FETCH_EO_ACTIVE_HOUR__FAILURE';
export const FETCH_EO_ACTIVE_HOUR__START = 'FETCH_EO_ACTIVE_HOUR__START';
export const FETCH_EO_ACTIVE_HOUR__SUCCESS = 'FETCH_EO_ACTIVE_HOUR__SUCCESS';
export const UPDATE_EO_ACTIVE_HOUR__FAILURE = 'UPDATE_EO_ACTIVE_HOUR__FAILURE';
export const UPDATE_EO_ACTIVE_HOUR__START = 'UPDATE_EO_ACTIVE_HOUR__START';
export const UPDATE_EO_ACTIVE_HOUR__SUCCESS = 'UPDATE_EO_ACTIVE_HOUR__SUCCESS';
export const FETCH_EO_ACTIVE_HOURS_AS_OPTIONS__SUCCESS =
  'FETCH_EO_ACTIVE_HOURS_AS_OPTIONS__SUCCESS';

export function createEoActiveHourStart() {
  return { type: CREATE_EO_ACTIVE_HOUR__START };
}

export function createEoActiveHourSuccess(eoActiveHour) {
  return { type: CREATE_EO_ACTIVE_HOUR__SUCCESS, eoActiveHour };
}

export function createEoActiveHourFailure(errors) {
  return { type: CREATE_EO_ACTIVE_HOUR__FAILURE, errors };
}

export function deleteEoActiveHoursStart() {
  return { type: DELETE_EO_ACTIVE_HOURS__START };
}

export function deleteEoActiveHoursSuccess(ids) {
  return { type: DELETE_EO_ACTIVE_HOURS__SUCCESS, ids };
}

export function deleteEoActiveHoursFailure(errors) {
  return { type: DELETE_EO_ACTIVE_HOURS__FAILURE, errors };
}

export function fetchEoActiveHoursStart() {
  return { type: FETCH_EO_ACTIVE_HOURS__START };
}

export function fetchEoActiveHoursSuccess(eoActiveHours) {
  return { type: FETCH_EO_ACTIVE_HOURS__SUCCESS, eoActiveHours };
}

export function fetchEoActiveHoursFailure(errors) {
  return { type: FETCH_EO_ACTIVE_HOURS__FAILURE, errors };
}

export function fetchEoActiveHourStart() {
  return { type: FETCH_EO_ACTIVE_HOUR__START };
}

export function fetchEoActiveHourSuccess(eoActiveHour) {
  return { type: FETCH_EO_ACTIVE_HOUR__SUCCESS, eoActiveHour };
}

export function fetchEoActiveHourFailure(errors) {
  return { type: FETCH_EO_ACTIVE_HOUR__FAILURE, errors };
}

export function updateEoActiveHourStart() {
  return { type: UPDATE_EO_ACTIVE_HOUR__START };
}

export function updateEoActiveHourSuccess(eoActiveHour) {
  return { type: UPDATE_EO_ACTIVE_HOUR__SUCCESS, eoActiveHour };
}

export function updateEoActiveHourFailure(errors) {
  return { type: UPDATE_EO_ACTIVE_HOUR__FAILURE, errors };
}

export function fetchEoActiveHoursAsOptionsSuccess(eoActiveHours) {
  return { type: FETCH_EO_ACTIVE_HOURS_AS_OPTIONS__SUCCESS, eoActiveHours };
}
