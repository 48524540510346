import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import * as Table from 'reactabular-table';

import columnsGenerator from 'common/tables/columns_generator';

import { buildSelectedItems } from 'common/utils';
import { checkboxColumn, textColumn } from 'common/tables/columns';
import { eoBlacklistedDomainsList } from 'common/propTypesShapes/eoBlacklistedDomains';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';

import './eoBlacklistedDomainsTable.scss';

const EoBlacklistedDomainsTableComponent = ({
  onTableHeaderClick,
  onToggleSelectedRows,
  eoBlacklistedDomains,
  eoBlacklistedDomainsCount,
  selectedIds,
  sorting,
}) => {
  const processedEoBlacklistedDomains = buildSelectedItems(eoBlacklistedDomains.toJS(), selectedIds);

  const headerValueFormatter = (options = {}) => (_value, extra) => {
    const { sortBy, sortingOrder } = sorting;
    const { withExplanationMessage } = options;
    const { property } = extra;

    const label = translate(`eoBlacklistedDomain.table.headers.${property}`);

    return (
      <HeaderFieldWithSorting
        appModule="eoBlacklistedDomains"
        label={label}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  };

  const handleOnToggleSelectRow = (eoBlacklistedDomainId) => (event) => {
    const isSelected = event.target.checked;

    onToggleSelectedRows([eoBlacklistedDomainId], isSelected);
  };

  function handleOnToggleSelectAll(event) {
    const isSelectedAll = event.target.checked;

    const eoBlacklistedDomainsIds = processedEoBlacklistedDomains.map(
      (eoBlacklistedDomain) => eoBlacklistedDomain.id,
    );

    onToggleSelectedRows(eoBlacklistedDomainsIds, isSelectedAll);
  }

  function isAllSelected() {
    const selectedRowsCount = processedEoBlacklistedDomains.filter(
      (eoBlacklistedDomain) => eoBlacklistedDomain.selected,
    ).length;
    const rowsCount = processedEoBlacklistedDomains.length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  }

  function cellCheckboxFormatters(_value, extra) {
    const {
      rowData: { selected, id },
    } = extra;

    return (
      <Checkbox
        checked={selected}
        className="eo-blacklisted-domains-table__checkbox"
        onChange={handleOnToggleSelectRow(id)}
      />
    );
  }

  function headerCheckboxFormatters() {
    return (
      <Checkbox
        checked={isAllSelected()}
        className="eo-blacklisted-domains-table__checkbox"
        onChange={handleOnToggleSelectAll}
      />
    );
  }

  function dateValueFormatter(value) {
    return format(new Date(value), 'MMM d h:mm a');
  }

  function brandValueFormatter(value) {
    return value || translate('uiComponents.brandAssign.anyBrand');
  }

  function generateColumns() {
    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [headerCheckboxFormatters],
        cellFormatters: [cellCheckboxFormatters],
        className: 'eo-blacklisted-domains-table__checkbox-column',
      }),
      textColumn({
        name: 'domain',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-blacklisted-domains-table__domain-column',
      }),
      textColumn({
        name: 'brandName',
        // headerValueFormatter: headerValueFormatter(),
        headerLabel: translate('eoBlacklistedDomain.table.headers.brandName'),
        cellValueFormatter: brandValueFormatter,
        className: 'eo-campaigns-table__brand-name-column',
      }),
      textColumn({
        name: 'addedBy',
        headerValueFormatter: headerValueFormatter(),
      }),
      textColumn({
        name: 'createdAt',
        headerValueFormatter: headerValueFormatter(),
        cellValueFormatter: dateValueFormatter,
      }),
    ]);
  }

  const columns = generateColumns();

  return (
    <>
      {eoBlacklistedDomainsCount > 0 && (
        <p>
          {translate('eoBlacklistedDomain.table.eoBlacklistedDomainsCount')(eoBlacklistedDomainsCount)}
        </p>
      )}
      <Table.Provider className="eo-blacklisted-domains-table" columns={columns}>
        <Table.Header />
        <Table.Body rowKey="id" rows={processedEoBlacklistedDomains} />
      </Table.Provider>
    </>
  );
};

EoBlacklistedDomainsTableComponent.propTypes = {
  eoBlacklistedDomains: eoBlacklistedDomainsList,
  eoBlacklistedDomainsCount: PropTypes.number,
  onTableHeaderClick: PropTypes.func,
  onToggleSelectedRows: PropTypes.func,
  selectedIds: selectedIdsList,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default EoBlacklistedDomainsTableComponent;
