import React from 'react';

const AnchorIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        d="M 8.043 3.112 C 7.506 3.112 7.065 2.705 7.065 2.206 C 7.065 1.703 7.505 1.299 8.043 1.299 C 8.589 1.299 9.027 1.702 9.027 2.205 C 9.027 2.705 8.589 3.111 8.043 3.111 Z M 15.983 11.047 L 15.46 8.037 C 15.398 7.757 15.13 7.542 14.814 7.542 C 14.62 7.542 14.45 7.618 14.324 7.74 L 11.912 9.827 C 11.872 9.857 11.834 9.894 11.799 9.939 C 11.571 10.223 11.636 10.627 11.945 10.839 C 12.005 10.879 12.069 10.907 12.135 10.931 L 12.74 11.116 C 12.156 12.636 10.73 13.794 8.978 14.114 L 8.982 4.236 C 9.842 3.899 10.434 3.116 10.434 2.206 C 10.435 0.986 9.365 0 8.043 0 C 6.725 0 5.653 0.986 5.653 2.206 C 5.653 3.116 6.266 3.9 7.121 4.236 L 7.125 14.131 C 5.335 13.835 3.853 12.667 3.255 11.119 L 3.865 10.933 C 3.93 10.909 3.995 10.883 4.055 10.843 C 4.365 10.629 4.433 10.225 4.201 9.94 C 4.166 9.894 4.131 9.86 4.089 9.827 L 1.675 7.74 C 1.555 7.62 1.38 7.543 1.19 7.543 C 0.867 7.543 0.597 7.756 0.54 8.038 L 0.016 11.048 C 0.004 11.094 0 11.146 0 11.198 C 0 11.554 0.313 11.843 0.7 11.843 C 0.772 11.843 0.84 11.836 0.906 11.815 L 1.473 11.645 C 2.376 14.172 4.956 16 8.001 16 C 11.044 16 13.626 14.17 14.528 11.64 L 15.094 11.815 C 15.159 11.835 15.228 11.843 15.3 11.843 C 15.687 11.843 16 11.553 16 11.198 C 16 11.146 15.994 11.094 15.982 11.048 Z"
        fillRule="nonzero"
      />
    </svg>
  );
};

AnchorIcon.defaultProps = {
  fill: '#757575',
  width: '16px',
  height: '16px',
};

export default AnchorIcon;
