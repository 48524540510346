import React from 'react';

import { brandChartMetricsShape } from 'common/propTypesShapes/metrics';
import { translate } from 'common/i18n';

import ChartsDatapicker from 'components_linkio/ChartsDatapicker';
import LineChart from 'components_linkio/LineChart';

import buildChartDataWithMetrics from 'utils/buildChartDataWithMetrics';
import { METRICS_FOR_BACKLINK_CHART } from 'utils/constants';

import './brandCharts.scss';


const BrandChartsComponent = ({ brandChartMetrics }) => {
  const backlinksAndAverageRankData = buildChartDataWithMetrics(
    brandChartMetrics, ['backlinksCount', 'averageKeywordsRank']
  );
  const backlinkMetricsData = buildChartDataWithMetrics(
    brandChartMetrics, METRICS_FOR_BACKLINK_CHART
  );

  return (
    <div className="brand-charts">
      <ChartsDatapicker popupAlign="left" />

      <div className="brand-charts__charts-wrapper">
        <LineChart
          appModule="backlinks"
          chartData={backlinksAndAverageRankData}
          link={brandChartMetrics.link}
          rightYAxisReversed
          showRightYAxis
          title={translate('chart.titles.backlinksAndAverageRank')}
        />

        <LineChart
          appModule="backlinks"
          chartData={backlinkMetricsData}
          link={brandChartMetrics.link}
          title={translate('chart.titles.backlinkMetrics')}
        />
      </div>
    </div>
  );
};

BrandChartsComponent.propTypes = {
  brandChartMetrics: brandChartMetricsShape,
};

export default BrandChartsComponent;
