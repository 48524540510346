import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import ImportProspectsPopupComponent from './ImportProspectsPopupComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchGoogleDriveFiles, previewCsvImport, previewGoogleDriveImport } from 'api/prospects';

const ImportProspectsPopupContainer = ({ dispatch, onClose, queryParams, changeQueryParams }) => {
  const { drive_access } = queryParams;

  function googleDriveAccessedCb() {
    changeQueryParams({});
  }

  async function handleImportCsv(csvFile) {
    const resp = await previewCsvImport(dispatch, csvFile);

    return resp;
  }

  async function handleImportGoogleDriveFile(fileId) {
    const resp = await previewGoogleDriveImport(dispatch, fileId);

    return resp;
  }

  async function handleFetchGoogleDriveFiles() {
    const resp = await fetchGoogleDriveFiles(dispatch);
    return resp.filesList || [];
  }

  const isGoogleDriveAccessed = drive_access === 'true';

  return (
    <ImportProspectsPopupComponent
      googleDriveAccessedCb={googleDriveAccessedCb}
      isGoogleDriveAccessed={isGoogleDriveAccessed}
      onCancel={onClose}
      onFetchGoogleDriveFiles={handleFetchGoogleDriveFiles}
      onImportCsv={handleImportCsv}
      onImportGoogleDriveFile={handleImportGoogleDriveFile}
    />
  );
};

ImportProspectsPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  ...withQueryParamsPropTypes,
};

export default compose(withQueryParams, ConnectStoreHOC, connect())(ImportProspectsPopupContainer);
