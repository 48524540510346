import React from 'react';
import PropTypes from 'prop-types';

import SwitchComponent from 'components_linkio/switch_component';

const CrawlingEnabledSwitchComponent = ({ value, isDisabled, onClick }) => {
  const initialValue = value ? 'right' : 'left';

  return (
    <SwitchComponent
      isBoolean
      isDisabled={isDisabled}
      initialValue={initialValue}
      onClick={onClick}
    />
  );
};

CrawlingEnabledSwitchComponent.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

export default CrawlingEnabledSwitchComponent;
