export default {
  backlinksBulkActions: {
    buttons: {
      crawlBacklinks: 'Verify Backlinks',
    },
    brandCrawlingStatus: {
      lastCheck: 'Last Check',
      nextCheck: 'Next Check',
    },
  },
};
