export const FETCH_EO_CAMPAIGN_OPTIONS__FAILURE = 'FETCH_EO_CAMPAIGN_OPTIONS__FAILURE';
export const FETCH_EO_CAMPAIGN_OPTIONS__START = 'FETCH_EO_CAMPAIGN_OPTIONS__START';
export const FETCH_EO_CAMPAIGN_OPTIONS__SUCCESS = 'FETCH_EO_CAMPAIGN_OPTIONS__SUCCESS';
export const UPDATE_EO_CAMPAIGN_OPTIONS__FAILURE = 'UPDATE_EO_CAMPAIGN_OPTIONS__FAILURE';

export function fetchEoCampaignOptionsFailure(errors) {
  return { type: FETCH_EO_CAMPAIGN_OPTIONS__FAILURE, errors };
}

export function fetchEoCampaignOptionsStart() {
  return { type: FETCH_EO_CAMPAIGN_OPTIONS__START };
}

export function fetchEoCampaignOptionsSuccess(eoCampaignOptions) {
  return { type: FETCH_EO_CAMPAIGN_OPTIONS__SUCCESS, eoCampaignOptions };
}

export function updateEoCampaignOptionsFailure(errors) {
  return { type: UPDATE_EO_CAMPAIGN_OPTIONS__FAILURE, errors };
}
