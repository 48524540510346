import { translate } from '../common/i18n';

import {
  fetchAllNotificationsStarted,
  fetchAllNotificationsSuccess,
  fetchAllNotificationsFailure,
  fetchRecentNotificationsStarted,
  fetchRecentNotificationsSuccess,
  fetchRecentNotificationsFailure,
  fetchUnreadNotificationsCountFailure,
  fetchUnreadNotificationsCountSuccess,
  markAllNotificationsAsReadFailure,
  markAllNotificationsAsReadStarted,
  markAllNotificationsAsReadSuccess,
} from '../actions/notificationActions';

import {
  fetchNotificationsPagySuccess,
} from '../actions/pagy_actions';

import {
  getFromAPI,
  handleAPIError,
  patchToAPI,
  processObjToCamelCase,
  processObjToSnakeCaseDeep,
} from './utils';


async function fetchAllNotifications(dispatch, fetchingParams) {
  dispatch(fetchAllNotificationsStarted());

  try {
    const resp = await getFromAPI(
      Routes.notifications_path(),
      processObjToSnakeCaseDeep(fetchingParams),
    );

    dispatch(fetchAllNotificationsSuccess(processObjToCamelCase(resp)));
    dispatch(fetchNotificationsPagySuccess(resp.pagy));

    return;
  } catch (err) {
    handleAPIError(dispatch, err, fetchAllNotificationsFailure, translate('errors.cantFetchAllNotifications'));

    return;
  }
}

async function fetchRecentNotifications(dispatch) {
  dispatch(fetchRecentNotificationsStarted());

  try {
    const resp = await getFromAPI(Routes.recent_notifications_path());

    dispatch(fetchRecentNotificationsSuccess(processObjToCamelCase(resp)));

    return;
  } catch (err) {
    handleAPIError(dispatch, err, fetchRecentNotificationsFailure, translate('errors.cantFetchRecentNotifications'));

    return;
  }
}

async function fetchUnreadNotificationsCount(dispatch) {
  try {
    const resp = await getFromAPI(Routes.unread_count_notifications_path());

    dispatch(fetchUnreadNotificationsCountSuccess(processObjToCamelCase(resp)));

    return;
  } catch (err) {
    handleAPIError(dispatch, err, fetchUnreadNotificationsCountFailure, translate('errors.cantFetchUnreadNotificationsCount'));

    return;
  }
}

async function markAllNotificationsAsRead(dispatch) {
  dispatch(markAllNotificationsAsReadStarted());

  try {
    const resp = await patchToAPI(Routes.mark_all_as_read_notifications_path());

    dispatch(markAllNotificationsAsReadSuccess(processObjToCamelCase(resp)));

    return;
  } catch (err) {
    handleAPIError(dispatch, err, markAllNotificationsAsReadFailure, translate('errors.cantMarkAllNotificationsAsRead'));

    return;
  }
}


export {
  fetchAllNotifications,
  fetchRecentNotifications,
  fetchUnreadNotificationsCount,
  markAllNotificationsAsRead,
};
