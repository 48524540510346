import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import pluralize from 'pluralize';

import StripePayBtn from './StripePayBtn';

import { translate } from 'common/i18n';

import { subscriptionShape } from 'common/prop_types_shapes';
import { subscriptionPlanIsForbiddenToSwitchTo } from 'components/subscriptions/utils';
import ErrorBoundary from 'decorators/error-boundary';

import './subscription_plan_list_item_component.scss';

const MONTHS_IN_YEAR = 12;

function numberWithThousandSeparator(value) {
  return Number(value).toLocaleString('en-US').replace(',', ' ');
}

function buildPriceString(price, isBilledAnnually) {
  if (price === 0) {
    return 'FREE';
  }

  let processedPrice = isBilledAnnually ? price / MONTHS_IN_YEAR : price;

  if (!Number.isInteger(processedPrice)) {
    processedPrice = processedPrice.toFixed(2);
  }

  return processedPrice;
}

const SubscriptionPlanListItemComponent = ({
  availability,
  onPlanSelect,
  selectedCostType,
  selectedPlanId,
  subscriptionPlan,
  withPayButton,
  isClickable,
}) => {
  const id = subscriptionPlan.get('id');
  const slug = subscriptionPlan.get('slug');
  const title = subscriptionPlan.get('title');
  const brandsCount = Number(subscriptionPlan.get('brandsCount', 0));
  const price = Number(subscriptionPlan.get('price', 0));
  const crawlingsCount = Number(subscriptionPlan.get('crawlingsCount', 0));
  const trackedKeywordsCount = Number(subscriptionPlan.get('trackedKeywordsCount', 0));
  const eoInboxesCount = Number(subscriptionPlan.get('eoInboxesCount', 0));
  const isBilledAnnually = selectedCostType === 'yearly';

  const priceString = buildPriceString(price, isBilledAnnually);

  const crawlingsCountString = isBilledAnnually
    ? (crawlingsCount / MONTHS_IN_YEAR).toFixed(0)
    : crawlingsCount.toFixed(0);

  const brandsCountString = ['free_yearly', 'free_monthly'].includes(slug)
    ? '1+'
    : String(brandsCount);
  const isSelectedPlan = String(selectedPlanId) === String(id);
  const isForbidden = subscriptionPlanIsForbiddenToSwitchTo(availability);
  const isFreePlan = price === 0;

  const itemClasses = classnames({
    'subscription-plan-list-item': true,
    'subscription-plan-list-item_unavailable': !isClickable && isForbidden,
    'subscription-plan-list-item_clickable': isClickable,
    'subscription-plan-list-item_selected': isClickable && isSelectedPlan,
  });

  const billedAnnuallyHintClasses = classnames({
    'subscription-plan-list-item__billed-annually-hint': true,
    'subscription-plan-list-item__billed-annually-hint_hidden': !isBilledAnnually || isFreePlan,
  });

  const priceClasses = classnames({
    'subscription-plan-list-item__price': true,
    'subscription-plan-list-item__price_free': isFreePlan,
  });

  function handleSelectPlan() {
    onPlanSelect(id);
  }

  return (
    <div className={itemClasses} onClick={handleSelectPlan}>
      <div className="subscription-plan-list-item__title-wrapper">
        <h3 className="subscription-plan-list-item__title">{title}</h3>
      </div>

      <div className="subscription-plan-list-item__price-wrapper">
        <span className={priceClasses}>{priceString}</span>
        {!isFreePlan && (
          <div className="subscription-plan-list-item__price-tag">
            {translate('subscriptionLimitReachedPopup.switch.monthly')}
          </div>
        )}
      </div>

      <span className={billedAnnuallyHintClasses}>
        {translate('subscriptionLimitReachedPopup.billedAnnuallyHint')}
      </span>

      <ul className="subscription-plan-list-item__features-list">
        <div className="subscription-plan-list-item__feature-item">
          <b>{brandsCountString}</b>{' '}
          {translate('subscriptionLimitReachedPopup.subscriptionPlan.brandsCount')}
        </div>

        <div className="subscription-plan-list-item__feature-item">
          <b>{numberWithThousandSeparator(crawlingsCountString)}</b>
          <br />
          {translate('subscriptionLimitReachedPopup.subscriptionPlan.backlinksCrawlingsCount')}
        </div>

        <div className="subscription-plan-list-item__feature-item">
          <b>{trackedKeywordsCount}</b> <br />
          {translate('subscriptionLimitReachedPopup.subscriptionPlan.trackedKeywordsCount')}
        </div>

        <div className="subscription-plan-list-item__feature-item">
          {translate('subscriptionLimitReachedPopup.subscriptionPlan.users')}{' '}
          {translate('subscriptionLimitReachedPopup.subscriptionPlan.unlimited')}
        </div>

        <div className="subscription-plan-list-item__feature-item">
          <b>{eoInboxesCount}</b>
          <br />
          {pluralize(
            translate('subscriptionLimitReachedPopup.subscriptionPlan.eoInboxesCount'),
            eoInboxesCount,
          )}
        </div>
      </ul>

      {withPayButton && (
        <div className="subscription-plan-list-item__pay-button">
          <StripePayBtn availability={availability} subscriptionPlan={subscriptionPlan} />
        </div>
      )}
    </div>
  );
};

SubscriptionPlanListItemComponent.propTypes = {
  availability: PropTypes.shape({
    isAvailable: PropTypes.bool,
    rejectionReason: PropTypes.string,
  }),
  isClickable: PropTypes.bool,
  onPlanSelect: PropTypes.func.isRequired,
  selectedCostType: PropTypes.string,
  selectedPlanId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subscriptionPlan: subscriptionShape,
  withPayButton: PropTypes.bool,
};

export default ErrorBoundary(SubscriptionPlanListItemComponent);
