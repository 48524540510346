const FETCH_NEWSLETTER_SETTINGS__FAILURE = 'FETCH_NEWSLETTER_SETTINGS__FAILURE';
const FETCH_NEWSLETTER_SETTINGS__START = 'FETCH_NEWSLETTER_SETTINGS__START';
const FETCH_NEWSLETTER_SETTINGS__SUCCESS = 'FETCH_NEWSLETTER_SETTINGS__SUCCESS';
const UPDATE_NEWSLETTER_SETTINGS__FAILURE = 'UPDATE_NEWSLETTER_SETTINGS__FAILURE';

function fetchNewsletterSettingsFailure(errors) {
  return { type: FETCH_NEWSLETTER_SETTINGS__FAILURE, errors };
}

function fetchNewsletterSettingsStart() {
  return { type: FETCH_NEWSLETTER_SETTINGS__START };
}

function fetchNewsletterSettingsSuccess(newsletterSettings) {
  return { type: FETCH_NEWSLETTER_SETTINGS__SUCCESS, newsletterSettings };
}

function updateNewsletterSettingsFailure(errors) {
  return { type: UPDATE_NEWSLETTER_SETTINGS__FAILURE, errors };
}

export {
  FETCH_NEWSLETTER_SETTINGS__FAILURE,
  FETCH_NEWSLETTER_SETTINGS__START,
  FETCH_NEWSLETTER_SETTINGS__SUCCESS,
  UPDATE_NEWSLETTER_SETTINGS__FAILURE,
  fetchNewsletterSettingsFailure,
  fetchNewsletterSettingsStart,
  fetchNewsletterSettingsSuccess,
  updateNewsletterSettingsFailure,
};
