import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StripeChargesComponent from './StripeChargesComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { fetchCharges } from 'api/billing';
import { stripeChargesSelector } from 'selectors';
import { stripeChargesList } from 'common/prop_types_shapes';

class StripeChargesContainer extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    stripeCharges: stripeChargesList,
  };

  componentDidMount() {
    fetchCharges(this.props.dispatch);
  }

  render() {
    const mutableStripeCharges = this.props.stripeCharges.valueSeq().toJS();
    return <StripeChargesComponent stripeCharges={mutableStripeCharges} />;
  }
}

function select(state, ownProps) {
  const stripeCharges = stripeChargesSelector(state, ownProps);

  return { stripeCharges };
}

export default ConnectStoreHOC(connect(select)(StripeChargesContainer));
