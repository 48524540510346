import {
  CHECK_PROXIES__FAILURE,
  CHECK_PROXIES__FINISHED,
  CHECK_PROXIES__START,
} from '../../actions/proxy_actions';

const initialState = false;


export default function checkAllProxiesInProgressReducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_PROXIES__START: {
      state = true;

      break;
    }
    case CHECK_PROXIES__FAILURE:
    case CHECK_PROXIES__FINISHED: {
      state = false;

      break;
    }
  }

  return state;
}
