import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap'; //TODO: Need to change to our component

import { translate } from 'common/i18n';

import BinIcon from 'common/icons/bin';
import ButtonComponent from 'components_linkio/button_component';
import VerticalDotsIcon from 'common/icons/vertical_dots';

import './eoCampaignActions.scss';

const EoCampaignActionsComponent = ({ onDeleteCampaignClick, canCreate }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);

  function handleOnBlur() {
    setDropdownIsOpen(false);
  }

  function handleOnToggle(isOpen) {
    setDropdownIsOpen(isOpen);
  }

  function buildActions() {
    return [
      {
        Icon: BinIcon,
        title: translate('eoCampaignOptions.buttons.delete'),
        handleClick: onDeleteCampaignClick,
        canUse: canCreate,
        className: 'eo-campaign-actions__delete',
      },
    ];
  }

  const availableActions = buildActions().filter((action) => action.canUse);

  return (
    <Dropdown
      className="eo-campaign-actions"
      id="eo-campaign-actions"
      onBlur={handleOnBlur}
      onToggle={handleOnToggle}
      open={dropdownIsOpen}
    >
      <CustomDropdownToggle bsRole="toggle" />
      <CustomDropdownMenu bsRole="menu">
        <div className="dropdown-menu dropdown-menu-right eo-campaign-actions__dropdown-menu">
          <ul className="eo-campaign-actions__dropdown-items-list">
            {availableActions.map(({ Icon, title, handleClick, className }) => {
              return (
                <li
                  key={title}
                  className={`eo-campaign-actions__item ${className}`}
                  onMouseDown={handleClick}
                >
                  <Icon className="eo-campaign-actions__item-icon" />
                  <span>{title}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </CustomDropdownMenu>
    </Dropdown>
  );
};

EoCampaignActionsComponent.propTypes = {
  canCreate: PropTypes.bool,
  onDeleteCampaignClick: PropTypes.func,
};

export default EoCampaignActionsComponent;

// see https://react-bootstrap.github.io/components.html#btn-dropdowns-custom
// for more details about this crappy stuff
class CustomDropdownToggle extends Component {
  static propTypes = { onClick: PropTypes.func };
  shouldComponentUpdate() {
    return true;
  }
  render() {
    return (
      <ButtonComponent className="eo-campaign-actions__toggle" onClick={this.props.onClick}>
        <VerticalDotsIcon />
      </ButtonComponent>
    );
  }
}

// see https://react-bootstrap.github.io/components.html#btn-dropdowns-custom
// for more details about this crappy stuff
class CustomDropdownMenu extends Component {
  static propTypes = { children: PropTypes.node };
  shouldComponentUpdate() {
    return true;
  }
  render() {
    return this.props.children;
  }
}
