export const FETCH_EO_DASHBOARD_METRICS__FAILURE = 'FETCH_EO_DASHBOARD_METRICS__FAILURE';
export const FETCH_EO_DASHBOARD_METRICS__START = 'FETCH_EO_DASHBOARD_METRICS__START';
export const FETCH_EO_DASHBOARD_METRICS__SUCCESS = 'FETCH_EO_DASHBOARD_METRICS__SUCCESS';

export function fetchEoDashboardMetricsStart() {
  return { type: FETCH_EO_DASHBOARD_METRICS__START };
}

export function fetchEoDashboardMetricsSuccess(eoDashboardMetrics) {
  return { type: FETCH_EO_DASHBOARD_METRICS__SUCCESS, eoDashboardMetrics };
}

export function fetchEoDashboardMetricsFailure(errors) {
  return { type: FETCH_EO_DASHBOARD_METRICS__FAILURE, errors };
}
