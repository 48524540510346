import { translate } from '../../common/i18n';

import {
  checkSingleProxyFailure,
  checkSingleProxyFinished,
  checkSingleProxyStart,
} from '../../actions/proxy_actions';

import {
  handleAPIError,
} from './../utils';

const handleCheckSingleProxy = (dispatch, data) => {
  const {
    checkProxy,
  } = data;

  switch (checkProxy) {
    case 'started':
      dispatch(checkSingleProxyStart(data));
      break;
    case 'failure':
      dispatch(checkSingleProxyFailure());
      handleAPIError(dispatch, { message: void 0 }, checkSingleProxyFailure, translate('errors.cantCheckSingleProxy'));
      break;
    case 'finished':
      dispatch(checkSingleProxyFinished(data));
      break;
  }
};

export default handleCheckSingleProxy;
