import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isInteger } from 'lodash';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { notificationsList, pagyShape } from 'common/prop_types_shapes';

import { translate } from 'common/i18n';

import { allNotificationsSelector } from 'selectors/notificationSelectors';

import { notificationsPagySelector } from 'selectors';

import Breadcrumbs from 'components/NewBreadcrumbs';
import smartFetchNotificationsService from './services/smartFetchNotificationsService';
import NotificationsPageComponent from './NotificationsPageComponent';

class NotificationsPageContainer extends Component {
  static propTypes = {
    notifications: notificationsList,
    notificationsPagy: pagyShape,
  };

  componentDidMount() {
    smartFetchNotificationsService(this);
  }

  handleNotificationsPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      smartFetchNotificationsService(this, { pageNumber: value });
    }
  };

  handleNotificationsPagerItemsSelectChange = ({ value }) => {
    smartFetchNotificationsService(this, { pagyItemsCount: value });
  };

  render() {
    const { notifications, notificationsPagy } = this.props;

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.TextItem>{translate('sidebar.notifications')}</Breadcrumbs.TextItem>
        </Breadcrumbs>

        <div className="page__wrapper">
          <NotificationsPageComponent
            notifications={notifications}
            notificationsPagy={notificationsPagy}
            onNotificationsPagerClick={this.handleNotificationsPagerClick}
            onNotificationsPagerItemsSelectChange={this.handleNotificationsPagerItemsSelectChange}
          />
        </div>
      </>
    );
  }
}

function select(state) {
  const notifications = allNotificationsSelector(state);
  const notificationsPagy = notificationsPagySelector(state);

  return {
    notifications,
    notificationsPagy,
  };
}

export default ConnectStoreHOC(connect(select)(NotificationsPageContainer));
