import { translate } from 'common/i18n';

import {
  fetchCardInfoStart,
  fetchCardInfoSuccess,
  fetchCardInfoFailure,
  fetchChargesStart,
  fetchChargesSuccess,
  fetchChargesFailure,
} from 'actions/billing_actions';

import { getFromAPI, handleAPIError } from 'api/utils';

export async function fetchCardInfo(dispatch) {
  dispatch(fetchCardInfoStart());

  try {
    const resp = await getFromAPI(Routes.marketer_card_path());
    dispatch(fetchCardInfoSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchCardInfoFailure,
      translate('errors.cantFetchCardInfo'),
    );
  }
}

export async function fetchCharges(dispatch) {
  dispatch(fetchChargesStart());

  try {
    const resp = await getFromAPI(Routes.fetch_charges_path());
    dispatch(fetchChargesSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchChargesFailure,
      translate('errors.cantFetchCharges'),
    );
  }
}
