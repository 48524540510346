import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import SelectProspectPopupComponent from './SelectProspectPopupComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchProspectsSuccess } from 'actions/prospectsActions';

const SelectProspectPopupContainer = ({
  dispatch,
  onClose,
  onSelectProspect,
  changeQueryParams,
  queryParams,
}) => {
  React.useEffect(() => {
    changeQueryParams({
      ...queryParams,
      pagyItemsCount: 5, // show only the first 5 prospects
      pageNumber: 1,
      search: '',
    });

    // Need to drop data before fetching them again. Fetching and merging over old ones
    // leads to UI bug, when user clicks on one row but a wrong one is selected
    dispatch(fetchProspectsSuccess([]));

    return () => {
      changeQueryParams({});
    };
  }, [dispatch]);

  function handleChangeSearch(value) {
    const newQueryParams = {
      ...queryParams,
      pagyItemsCount: 5, // show only the first 5 prospects
      pageNumber: 1,
      search: value,
    };

    changeQueryParams(newQueryParams);
  }

  function handleSubmit(prospect) {
    onSelectProspect(prospect);
    onClose();
  }

  return (
    <SelectProspectPopupComponent
      onCancel={onClose}
      onChangeSearch={handleChangeSearch}
      onSubmit={handleSubmit}
    />
  );
};

SelectProspectPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  onSelectProspect: PropTypes.func,
  ...withQueryParamsPropTypes,
};

export default compose(withQueryParams, ConnectStoreHOC, connect())(SelectProspectPopupContainer);
