import React from 'react';

import EoCampaignTest from './EoCampaignTest';
import EoCampaignStepsPreview from './EoCampaignStepsPreview';

const EoCampaignPreviewPageComponent = () => {
  return (
    <div className="eo-campaign-preview-page">
      <EoCampaignTest />
      <EoCampaignStepsPreview />
    </div>
  );
};

export default EoCampaignPreviewPageComponent;
