import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { isInteger } from 'lodash';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { currentIdSelector } from 'selectors';

import { pagyShape, draftBrandPagesMap } from 'common/prop_types_shapes';

import { translate } from 'common/i18n';

import Breadcrumbs from 'components/NewBreadcrumbs';
import BreadcrumbsBrandsSelect from 'components/NewBreadcrumbs/BreadcrumbsBrandsSelect';

import {
  bulkSetupBrandPagesSelector,
  bulkPagesSetupPagyByBrandIdSelector,
} from 'selectors/bulkPagesSetupSelector';

import BulkPagesSetupComponent from './BulkPagesSetupComponent';

const BulkPagesSetupContainer = ({
  brandId,
  bulkPagesSetupPagy,
  changeQueryParams,
  draftBrandPages,
  queryParams,
}) => {
  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  const draftBrandPagesCount = draftBrandPages.size;

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('bulkPagesSetup.breadcrumbsTitle')}</Breadcrumbs.TextItem>
        <BreadcrumbsBrandsSelect targetAppModule="bulkPagesSetup" />
      </Breadcrumbs>

      <div className="page__wrapper">
        <BulkPagesSetupComponent
          brandId={brandId}
          bulkPagesSetupPagy={bulkPagesSetupPagy}
          draftBrandPagesCount={draftBrandPagesCount}
          onItemsSelectChange={handleItemsSelectChange}
          onPagesPagerClick={handlePagesPagerClick}
          isSimpleMode={queryParams.simpleMode === 'true'}
        />
      </div>
    </>
  );
};

BulkPagesSetupContainer.propTypes = {
  brandId: PropTypes.number,
  bulkPagesSetupPagy: pagyShape,
  draftBrandPages: draftBrandPagesMap,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const bulkPagesSetupPagy = bulkPagesSetupPagyByBrandIdSelector(state, brandId);
  const draftBrandPages = bulkSetupBrandPagesSelector(state, ownProps);

  return {
    brandId: parseInt(brandId, 10),
    bulkPagesSetupPagy,
    draftBrandPages,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(BulkPagesSetupContainer);
