import React from 'react';
import PropTypes from 'prop-types';

import { BRAND_PAGE_PRIORITY_LIST } from './constants';

import { translate } from 'common/i18n';

import Select from 'components_linkio/Select';

const BrandPagePriorityComponent = ({ pagePriority, canUpdateAtp, handlePagePriorityChange }) => {
  const componentStyles = {
    control: {
      border: 'none',
      paddingLeft: '0',
      height: 35,
      minHeight: 35,
    },
    dropdownIndicator: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    indicatorSeparator: {
      width: '0',
    },
    valueContainer: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    singleValue: {
      maxWidth: '100%',
    },
  };

  return (
    <div className="brand-page-item-component__priority-select-wrapper">
      <Select
        blurInputOnSelect
        componentStyles={componentStyles}
        isClearable={false}
        isDisabled={!canUpdateAtp}
        onChange={handlePagePriorityChange}
        options={BRAND_PAGE_PRIORITY_LIST}
        placeholder={translate('brandPage.priority.placeholder')}
        triggerChangeOnBlur={false}
        value={BRAND_PAGE_PRIORITY_LIST.find(
          (option) => Number(option.value) === Number(pagePriority),
        )}
      />
    </div>
  );
};

BrandPagePriorityComponent.propTypes = {
  canUpdateAtp: PropTypes.bool.isRequired,
  handlePagePriorityChange: PropTypes.func,
  pagePriority: PropTypes.string,
};

export default BrandPagePriorityComponent;
