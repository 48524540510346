import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse, stringify } from 'qs';

export const withQueryParamsPropTypes = {
  changeQueryParams: PropTypes.func,
  queryParams: PropTypes.shape(),
};

function withQueryParams(Component) {
  class WithQueryParams extends React.Component {
    static propTypes = {
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
    };

    changeQueryParams = (newQueryParams) => {
      const { history, location } = this.props;

      const queryString = stringify(newQueryParams, { addQueryPrefix: true });

      history.push({
        pathname: location.pathname,
        search: queryString,
      });
    };

    render() {
      const queryParams = parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });

      return (
        <Component
          {...this.props}
          queryParams={queryParams}
          changeQueryParams={this.changeQueryParams}
        />
      );
    }
  }

  return withRouter(WithQueryParams);
}

export default withQueryParams;
