const ADD_MESSAGE = 'ADD_MESSAGE';
const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

function addMessage(message) {
  return { type: ADD_MESSAGE, message };
}

function removeMessage(id) {
  return { type: REMOVE_MESSAGE, id };
}

export {
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  addMessage,
  removeMessage,
};
