import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List as iList } from 'immutable';
import ConnectStoreHOC from 'startup/connect_store_hoc';
import ErrorBoundary from 'decorators/error-boundary';

import { setCurrentAccount } from 'api/user';
import { currentUserSelector } from 'selectors/railsContextSelectors';
import { marketerShape, policiesShape, userShape } from 'common/prop_types_shapes';
import ChooseMarketerFormComponent from 'components/auth/choose_marketer_form_component';
import { fetchMarketers } from 'api/marketer';
import { fetchRailsContext } from 'api/rails_context';

class ChooseMarketerPage extends React.PureComponent {
  static propTypes = {
    currentUser: userShape,
    dispatch: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    marketers: PropTypes.arrayOf(marketerShape),
    policies: policiesShape.isRequired,
  }

  componentDidMount() {
    fetchMarketers(this.props.dispatch);
  }

  handleSelectMarketerAction = async (marketer) => {
    const { dispatch, history } = this.props;

    const res = await setCurrentAccount(dispatch, marketer.slug);

    if (res.type && res.type === 'error') {
      // TODO: think about possible errors
      return;
    }
    const context = await fetchRailsContext(dispatch);
    history.push(context.afterSignInPath || Routes.root_path({ format: null }));
  }

  render() {
    if (!this.props.marketers) {
      return null;
    }

    return (
      <ChooseMarketerFormComponent
        {...this.props}
        onMarketerClickAction={this.handleSelectMarketerAction}
      />
    );
  }
}

function select(state, ownProps) {
  const currentUser = currentUserSelector(state, ownProps);
  const policies = state.getIn(['railsContext', 'policies']);
  const marketers = state.getIn(['marketers', 'list'], iList()).toJS();

  return { policies, currentUser, marketers };
}

export default ConnectStoreHOC(connect(select)(ErrorBoundary(ChooseMarketerPage)));
