import { closeImportDlsPopup, openSubscriptionLimitReachedPopup } from 'actions/popup_actions';
import { importFromMoz } from 'api/destination_links_import';
import { trackHelpcrunchEvent } from 'common/utils';

const importFromMozService = async (context, pageId, categorizeBacklinks) => {
  await handleImportFromMozToBrandPage(context, pageId, categorizeBacklinks);

  closeMozImportRelatedModals(context.dispatch);
};

const handleImportFromMozToBrandPage = async (context, pageId, categorizeBacklinks) => {
  const { dispatch, pageChildType } = context;

  if (isGoingToOpenSubscriptionLimitReachedPopup(context, categorizeBacklinks)) {
    return void 0;
  }

  trackHelpcrunchEvent(`${pageChildType} Links imported from Moz`);

  return doImportFromMoz(dispatch, pageId, categorizeBacklinks)();
};

const isGoingToOpenSubscriptionLimitReachedPopup = (context, categorizeBacklinks) => {
  const { dispatch, subscriptionPolicies } = context;

  const canImport = true;
  const canCategorize = subscriptionPolicies.getIn(['categorizer', 'canUse'], false);

  if (!canImport || (categorizeBacklinks && !canCategorize)) {
    dispatch(openSubscriptionLimitReachedPopup({}));
  }

  return false;
};

const doImportFromMoz = (dispatch, pageId, categorizeBacklinks) => async () => {
  await importFromMoz(dispatch, pageId, categorizeBacklinks);
};

const closeMozImportRelatedModals = (dispatch) => {
  dispatch(closeImportDlsPopup());
};

export default importFromMozService;
