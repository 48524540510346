import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoInboxesPageComponent from './EoInboxesPageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector } from 'selectors';
import { eoInboxesList } from 'common/propTypesShapes/eoInboxesSettings';
import { eoCampaignStepsOptions } from 'common/propTypesShapes/eoCampaignSteps';
import { eoInboxesSelector } from 'selectors/eoInboxesSettingsSelector';
import { fetchEoCampaign } from 'api/eoCampaign';
import { fetchEoCampaignInboxes, addOrRemoveEoCampaignInbox } from 'api/eoInbox';
import { fetchEoCampaignStepsPreview } from 'api/eoCampaignPreview';
import { translate } from 'common/i18n';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';
import confirmationDialogue from 'components/confirmation_dialogue';

import { resetEoCampaignStepsPreviewOptions } from 'actions/eoCampaignPreviewActions';
import { eoCampaignStepsPreviewOptionsSelector } from 'selectors/eoCampaignStepsPreviewSelector';

const EoInboxesPageContainer = ({ dispatch, eoCampaignId, eoInboxes, history, eoCampaignStepsPreviewOptions }) => {
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      await fetchEoCampaignInboxes(dispatch, eoCampaignId);
      setIsFetching(false);
    }

    fetchData();
  }, [dispatch, eoCampaignId]);

  function handleAddOrRemoveEoInbox(eoInboxId, eoInboxAction) {
    async function addOrRemove(needToRefetchCampaign) {
      await addOrRemoveEoCampaignInbox(dispatch, { eoInboxId, eoCampaignId, eoInboxAction });
      // Re-fetch campaign if the user removed the last connected inbox
      // OR added the first inbox to the campaign
      needToRefetchCampaign && fetchEoCampaign(dispatch, eoCampaignId);
    }

    if (eoInboxAction === 'remove') {
      const isLastInboxRemoved =
        eoInboxes.filter((eoInbox) => eoInbox.get('belongsToCurrentEoCampaign')).size === 1;

      const { eoInboxId, prospect } = eoCampaignStepsPreviewOptions.toJS();

      const isEoCampaignStepsPreviewOptionsExist = !!eoInboxId && !!prospect;

      confirmationDialogue({
        body: translate('confirmations.default.title'),
        onConfirm: () => {
          addOrRemove(isLastInboxRemoved);
          if (isEoCampaignStepsPreviewOptionsExist) {
            dispatch(resetEoCampaignStepsPreviewOptions());
            fetchEoCampaignStepsPreview(dispatch, eoCampaignId, { eoInboxId: null, prospectId: null });
          }
        },
      });

      return;
    }

    const isFirstInboxAdded = !eoInboxes.some((eoInbox) =>
      eoInbox.get('belongsToCurrentEoCampaign'),
    );

    addOrRemove(isFirstInboxAdded);
  }

  function handleRedirectToInboxesPage() {
    history.push(Routes.eo_inboxes_settings_path({ format: null }));
  }

  const hasEoInboxes = eoInboxes.size > 0;

  return (
    <div className="eo-inboxes-page">
      {hasEoInboxes && (
        <EoInboxesPageComponent
          eoInboxes={eoInboxes}
          onAddOrRemoveEoInbox={handleAddOrRemoveEoInbox}
        />
      )}
      {!hasEoInboxes && (
        <BlankStateCtaComponent isLoading={isFetching}>
          <TitleText>
            <strong>{translate('eoInboxes.blankState.title')}</strong>
          </TitleText>
          <BodyText>
            {translate('eoInboxes.blankState.body.firstPart')}
            <span className="text text_link" onClick={handleRedirectToInboxesPage}>
              {translate('eoInboxes.blankState.body.secondPart')}
            </span>
            {'.'}
          </BodyText>
        </BlankStateCtaComponent>
      )}
    </div>
  );
};

EoInboxesPageContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoCampaignStepsPreviewOptions: eoCampaignStepsOptions,
  eoInboxes: eoInboxesList,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoInboxes = eoInboxesSelector(state);
  const eoCampaignStepsPreviewOptions = eoCampaignStepsPreviewOptionsSelector(state);

  return {
    eoCampaignId,
    eoInboxes,
    eoCampaignStepsPreviewOptions,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(EoInboxesPageContainer);
