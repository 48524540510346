import { fromJS } from 'immutable';
import { keyBy } from 'lodash';

import {
  FETCH_BRANDS_METRICS__SUCCESS,
  PROCESS_DISAVOW__FAILURE,
  PROCESS_DISAVOW__START,
  PROCESS_DISAVOW__SUCCESS,
} from 'actions/accountDashboardActions';

import { DELETE_BRANDS__SUCCESS } from 'actions/brand_actions';

import { camelizeObject, camelizeObjectDeep } from 'reducers/utils';

const initialState = fromJS({
  brandsMetrics: {},
});

export default function accountDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRANDS_METRICS__SUCCESS: {
      state = state.set(
        'brandsMetrics',
        fromJS(keyBy(camelizeObjectDeep(action.brandsMetrics), 'id')),
      );

      break;
    }
    case DELETE_BRANDS__SUCCESS: {
      const brandsMetricsWithoutDeletedBrands = state
        .get('brandsMetrics')
        .filter((brand) => !action.ids.includes(brand.get('id')));

      state = state.set('brandsMetrics', brandsMetricsWithoutDeletedBrands);

      break;
    }

    case PROCESS_DISAVOW__FAILURE:
    case PROCESS_DISAVOW__SUCCESS: {
      const { brand } = action;

      state = state.mergeIn(['brandsMetrics', String(brand.id)], camelizeObject(brand));

      break;
    }
    case PROCESS_DISAVOW__START: {
      const { brand } = action;

      state = state.setIn(['brandsMetrics', String(brand.id), 'disavowInProgress'], true);

      break;
    }
  }

  return state;
}
