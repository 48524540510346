export default {
  pager: {
    notificationsPager: {
      items10: '10 Notifications',
      items50: '50 Notifications',
      items100: '100 Notifications',
    },
    pagesPager: {
      items10: '10 Pages',
      items50: '50 Pages',
      items100: '100 Pages',
    },
    rowsPager: {
      items10: '10 Rows',
      items50: '50 Rows',
      items100: '100 Rows',
    },
    next: '>',
    prev: '<',
    gap: '...',
  },
};
