import React from 'react';
import PropTypes from 'prop-types';

import EoDripListFormPopup from '../EoDripListFormPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const AddEoDripListButtonComponent = ({ onSubmitCb, ...buttonProps }) => {
  return (
    <ToggleShowPopup Popup={<EoDripListFormPopup onSubmitCb={onSubmitCb} />}>
      <ButtonComponent
        className="eo-drip-lists-bulk-actions__add-eo-drip-list"
        isInitial
        {...buttonProps}
      >
        {translate('eoDripList.bulkActions.addEoDripList')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

AddEoDripListButtonComponent.propTypes = {
  onSubmitCb: PropTypes.func,
};

export default AddEoDripListButtonComponent;
