import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { isInteger } from 'lodash';
import { compose } from 'lodash/fp';

import ProspectsPageComponent from './ProspectsPageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { pagyShape } from 'common/prop_types_shapes';

import { prospectsPagySelector } from 'selectors/prospectsSelector';
import { policiesSelector } from 'selectors/railsContextSelectors';

import { showErrorMessage } from 'common/utils';
import { translate } from 'common/i18n';

import Breadcrumbs from 'components/NewBreadcrumbs';

const ProspectsPageContainer = ({
  dispatch,
  canCreate,
  changeQueryParams,
  prospectsPagy,
  queryParams,
}) => {
  const { drive_access } = queryParams;

  if (drive_access === 'false') {
    showErrorMessage(dispatch, translate('prospects.messages.googleDriveAccessDenied'), true);
    changeQueryParams({});
  }

  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('prospects.breadcrumbsTitle')}</Breadcrumbs.TextItem>
      </Breadcrumbs>

      <div className="page__wrapper">
        <ProspectsPageComponent
          canCreate={canCreate}
          onItemsSelectChange={handleItemsSelectChange}
          onPagesPagerClick={handlePagesPagerClick}
          prospectsPagy={prospectsPagy}
        />
      </div>
    </>
  );
};

ProspectsPageContainer.propTypes = {
  canCreate: PropTypes.bool,
  prospectsPagy: pagyShape,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const prospectsPagy = prospectsPagySelector(state);
  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);

  return {
    canCreate,
    prospectsPagy,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(ProspectsPageContainer);
