import { createSelector } from 'reselect';
import { Map as iMap, List as iList } from 'immutable';
import { camelCase, get } from 'lodash';

const billing = (state) => state.get('billing', iMap());
const brandPages = (state) => state.get('brandPages', iMap());
const brands = (state) => state.get('brands', iMap());
const crawlers = (state) => state.get('crawlers', iMap());
const destinationLinks = (state) => state.get('destinationLinks', iMap());
const dlDuplicates = (state) => state.get('dlDuplicates', iMap());
const loadingStates = (state) => state.getIn(['ui', 'loading'], iMap());
const options = (state) => state.get('options', iMap());
const pageDashboardDls = (state) => state.get('pageDashboardDls', iMap());
const pagies = (state) => state.get('pagies', iMap());
const percentages = (state) => state.get('percentages', iMap());
const subscriptionPlans = (state) => state.get('subscriptionPlans', iMap());
const ui = (state) => state.get('ui', iMap());

// return destination link iMap picked by id from props
const destinationLinkById = (state, props) => {
  const dls = state.get('destinationLinks', iList());
  return dls.find((link) => link.get('id') === props.destinationLinkId);
};

// return destination links iMap picked by brand page id from props
const destinationLinksByBrandPageId = (state, props) => {
  const brandPageId = props.brandPageId || visiblePageIdByParent(state, props);

  return (
    destinationLinks(state).filter((dl) => {
      return dl.get('brandPageId') === Number(brandPageId);
    }) || iMap()
  );
};

const pageDashboardDlsByBrandPageId = (state, props) => {
  return pageDashboardDls(state).get(props.brandPageId);
};

const atpPagyByBrandPageId = (state, props) => {
  const brandPageId = props.brandPageId || visiblePageIdByParent(state, props);
  return state.getIn(['pagies', 'atpPagy', String(brandPageId)], iMap());
};

const pagesPagyByBrandId = (state, brandId) => {
  return state.getIn(['pagies', 'pagesPagy', String(brandId)], iMap());
};

const notificationsPagySelector = createSelector([pagies], (pagies) =>
  pagies.get('notificationsPagy', iMap()),
);

// return percentage iList picked by brand page id from props
const percentageByBrandPageId = (state, props) => {
  const brandPageId = props.brandPageId || visiblePageIdByParent(state, props);
  return state.getIn(['percentages', String(brandPageId), 'percentage'], iList());
};

const uncategorizedAnchorsCountByBrandPageId = (state, props) => {
  const brandPageId = props.brandPageId || visiblePageIdByParent(state, props);
  return state.getIn(['percentages', String(brandPageId), 'uncategorizedAnchorsCount'], 0);
};

// return percentage scheme id iList picked by brand page id from props
const percentageSchemeIdByBrandPageId = (state, props) => {
  const brandPageId = props.brandPageId || visiblePageIdByParent(state, props);
  return brandPages(state).getIn([String(brandPageId), 'percentageSchemeId']);
};
// return brandPage iMap picked by id from props
const brandPageById = (state, props) => {
  return brandPages(state).get(String(props.brandPageId), iMap());
};
const brandPageByBrandPageIdSelector = createSelector(
  [brandPageById],
  (brandPage) => brandPage || iMap(),
);

// return pages iMap picked by parent id from props
const pagesByParentSelector = (state, props) => {
  const pageParentType = props.pageParentType || 'brand';
  const pageParentId = props.pageParentId || currentIdSelector(state, props);
  const selector = `${camelCase(pageParentType)}Id`;

  const filteredBrandPages =
    brandPages(state).filter((page) => {
      return page.get(selector) === Number(pageParentId);
    }) || iMap();

  if (pageParentType === 'brand') {
    return (
      filteredBrandPages.sort((a, b) => Number(b.get('priority')) - Number(a.get('priority'))) ||
      iMap()
    );
  }

  return filteredBrandPages;
};

const percentagesSelector = createSelector([percentages], (percentages) => percentages || iMap());

const destinationLinksSelector = createSelector(
  [destinationLinks],
  (destinationLinks) => destinationLinks || iMap(),
);

const destinationLinksByBrandPageIdSelector = createSelector(
  [destinationLinksByBrandPageId],
  (destinationLinks) => destinationLinks || iMap(),
);

const pageDashboardDlsByBrandPageIdSelector = createSelector(
  [pageDashboardDlsByBrandPageId],
  (pageDashboardDls) => pageDashboardDls || iMap(),
);

const atpPagyByBrandPageIdSelector = createSelector(
  [atpPagyByBrandPageId],
  (pagy) => pagy || iMap(),
);

const pagesPagyByBrandIdSelector = createSelector([pagesPagyByBrandId], (pagy) => pagy || iMap());

// return pages Pagy picked by parent id from props
const pagesPagyByParentSelector = (state, props) => {
  const pageParentType = props.pageParentType || 'brand';
  const pageParentId = props.pageParentId || currentIdSelector(state, props);

  if (pageParentType === 'brand') {
    return state.getIn(['pagies', 'pagesPagy', String(pageParentId)], iMap());
  }

  return iMap(); // CompetitorPages do not have pagination yet
};

const percentageByBrandPageIdSelector = createSelector(
  [percentageByBrandPageId],
  (percentage) => percentage || iList(),
);

const percentageSchemeIdByBrandPageIdSelector = createSelector(
  [percentageSchemeIdByBrandPageId],
  (schemeId) => schemeId,
);

const cardInfoSelector = createSelector([billing], (billing) => billing.get('cardInfo', iMap()));

const crawlersSelector = createSelector([crawlers], (crawlers) => crawlers || iMap());

const somethingIsLoadingSelector = createSelector(
  [loadingStates],
  (states) =>
    !!states.get('fetchAhrefsConnection') ||
    !!states.get('fetchPages') ||
    !!states.get('fetchBacklinks') ||
    !!states.get('fetchChangesHistory') ||
    !!states.get('fetchBrands') ||
    !!states.get('fetchBrandsMetrics') ||
    !!states.get('fetchBulkSetupBrandPages') ||
    !!states.get('fetchCrawlingResults') ||
    !!states.get('fetchDLs') ||
    !!states.get('fetchEmployees') ||
    !!states.get('fetchPageTypes') ||
    !!states.get('fetchPages') ||
    !!states.get('fetchPercentageSchemes') ||
    !!states.get('fetchSubscriptionPlans') ||
    !!states.get('createMarketer') ||
    !!states.get('fetchMarketers'),
);

const somethingIsChangingSelector = createSelector(
  [loadingStates],
  (states) =>
    !!states.get('changeBrand') ||
    !!states.get('changePage') ||
    !!states.get('changeDl') ||
    !!states.get('changeEmployee') ||
    !!states.get('changeUserPermissions') ||
    !!states.get('changePageTypes'),
);

export const currentIdSelector = (_state, props) => get(props, 'match.params.id', null);
export const visibleBrandPageIdSelector = (_state, props) => get(props, 'match.params.pageId', null);
export const visibleCompetitorPageIdSelector = (_state, props) => get(props, 'match.params.competitorId', null);
export const activeAtpTableTabSelector = (_state, props) => get(props, 'match.params.activeAtpTableTab', null);

export const visibleBrandPageSelector = createSelector(
  [brandPages, visibleBrandPageIdSelector],
  (brandPages, visibleBrandPageId) => brandPages.get(visibleBrandPageId, iMap()),
);

export const visibleCompetitorPageSelector = createSelector(
  [brandPages, visibleCompetitorPageIdSelector],
  (brandPages, visibleCompetitorPageId) => brandPages.get(visibleCompetitorPageId, iMap())
);

const visiblePageIdByParent = (state, props) => {
  const pageParentType = props.pageParentType || 'brand';

  if (pageParentType === 'brand') {
    return visibleBrandPageIdSelector(state, props);
  }

  return visibleCompetitorPageIdSelector(state, props);
};

export const visiblePageIdByParentSelector = createSelector(
  [visiblePageIdByParent],
  (visiblePageIdByParent) => visiblePageIdByParent || void 0
);

const visiblePageByParent = (state, props) => {
  const pageParentType = props.pageParentType || 'brand';

  if (pageParentType === 'brand') {
    return visibleBrandPageSelector(state, props);
  }

  return visibleCompetitorPageSelector(state, props);
};

export const visiblePageByParentSelector = createSelector(
  [visiblePageByParent],
  (visiblePageByParent) => visiblePageByParent || iMap()
);

const isBacklinksImportingByCurrentBrandIdSelector = createSelector(
  [loadingStates, currentIdSelector],
  (states, currentBrandId) =>
    !!states.getIn(['importBacklinksManuallyInProgress', currentBrandId]) ||
    !!states.getIn(['importBacklinksFromApiInProgress', currentBrandId]),
);

const optionsSelector = createSelector([options], (options) => options || iMap());

const dlDuplicatesSelector = createSelector(
  [dlDuplicates],
  (dlDuplicates) => dlDuplicates || iMap(),
);

const currentBrandSelector = createSelector([brands, currentIdSelector], (brands, currentBrandId) =>
  brands.get(currentBrandId, iMap()),
);

const stripeChargesSelector = createSelector(
  [billing],
  (billing) => billing.get('stripeCharges', iList())
);

const subscriptionPlansSelector = createSelector(
  [subscriptionPlans],
  (subscriptionPlans) => subscriptionPlans || iMap(),
);

const uncategorizedAnchorsCountByBrandPageIdSelector = createSelector(
  [uncategorizedAnchorsCountByBrandPageId],
  (uncategorizedAnchorsCount) => uncategorizedAnchorsCount || 0,
);

const destinationLinkByIdSelector = createSelector(
  [destinationLinkById],
  (destinationLink) => destinationLink || iMap(),
);

const uiSelector = createSelector([ui], (ui) => ui || iMap());

export {
  atpPagyByBrandPageIdSelector,
  brandPageByBrandPageIdSelector,
  cardInfoSelector,
  crawlersSelector,
  currentBrandSelector,
  destinationLinkByIdSelector,
  destinationLinksByBrandPageIdSelector,
  destinationLinksSelector,
  dlDuplicatesSelector,
  isBacklinksImportingByCurrentBrandIdSelector,
  notificationsPagySelector,
  optionsSelector,
  pageDashboardDlsByBrandPageIdSelector,
  pagesByParentSelector,
  pagesPagyByBrandIdSelector,
  pagesPagyByParentSelector,
  percentageByBrandPageIdSelector,
  percentageSchemeIdByBrandPageIdSelector,
  percentagesSelector,
  somethingIsChangingSelector,
  somethingIsLoadingSelector,
  stripeChargesSelector,
  subscriptionPlansSelector,
  uiSelector,
  uncategorizedAnchorsCountByBrandPageIdSelector,
};
