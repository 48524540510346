import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { pageItemMetricTitleField } from '../PageItem/pageItemMetricFields';

import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';

import { translate } from 'common/i18n';

import './pagesListHeader.scss';

const PagesListHeaderComponent = ({ canUseCrawlingInSubscription, pageChildType }) => {
  const isBrandPage = pageChildType === 'brandPage';
  const isWithMetrics = isBrandPage && canUseCrawlingInSubscription;

  const headerClasses = cx({
    'pages-list-header': true,
    'pages-list-header_with-metrics': isBrandPage && canUseCrawlingInSubscription,
    'pages-list-header_competitors': !isBrandPage,
    'pages-list-header_competitors-with-metrics': !isBrandPage && canUseCrawlingInSubscription,
  });

  return (
    <div className={headerClasses}>
      <div className="pages-list-header__item" />
      <div className="pages-list-header__item">{translate(`${pageChildType}.link.title`)}</div>
      <div className="pages-list-header__item">
        <span className="pages-list-header__title-text">
          {translate(`${pageChildType}.pageTypeId.title`)}
          <ExplanatoryTooltipComponent
            text={translate('explanationMessages.brandPagesList.pageType')}
          />
        </span>
      </div>
      {canUseCrawlingInSubscription && (
        <>
          {pageItemMetricTitleField('countReferringDomains', pageChildType)}
          {pageItemMetricTitleField('linkioScore', pageChildType)}
          {isWithMetrics && pageItemMetricTitleField('averageKeywordsRank', pageChildType)}
          {isWithMetrics && pageItemMetricTitleField('competitorsCount', pageChildType)}
        </>
      )}
      <div className="pages-list-header__item">
        {translate(`${pageChildType}.keywords.title`)}
        <ExplanatoryTooltipComponent
          text={translate('explanationMessages.brandPagesList.keywords')}
        />
      </div>
      {isBrandPage && (
        <div className="pages-list-header__item">
          {translate(`${pageChildType}.priority.title`)}
          <ExplanatoryTooltipComponent
            text={translate('explanationMessages.brandPagesList.priority')}
          />
        </div>
      )}
      <div className="pages-list-header__item pages-list-header__item_actions pages-list-header__title-text">
        {translate(`${pageChildType}.buttons.actions`)}
      </div>
    </div>
  );
};

PagesListHeaderComponent.propTypes = {
  canUseCrawlingInSubscription: PropTypes.bool.isRequired,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']),
};

export default PagesListHeaderComponent;
