const FETCH_EMPLOYEES__START = 'FETCH_EMPLOYEES__START';
const FETCH_EMPLOYEES__SUCCESS = 'FETCH_EMPLOYEES__SUCCESS';
const FETCH_EMPLOYEES__FAILURE = 'FETCH_EMPLOYEES__FAILURE';
const CREATE_EMPLOYEE__START = 'CREATE_EMPLOYEE__START';
const CREATE_EMPLOYEE__SUCCESS = 'CREATE_EMPLOYEE__SUCCESS';
const CREATE_EMPLOYEE__FAILURE = 'CREATE_EMPLOYEE__FAILURE';
const UPDATE_EMPLOYEE__START = 'UPDATE_EMPLOYEE__START';
const UPDATE_EMPLOYEE__SUCCESS = 'UPDATE_EMPLOYEE__SUCCESS';
const UPDATE_EMPLOYEE__FAILURE = 'UPDATE_EMPLOYEE__FAILURE';
const DELETE_EMPLOYEES__START = 'DELETE_EMPLOYEES__START';
const DELETE_EMPLOYEES__SUCCESS = 'DELETE_EMPLOYEES__SUCCESS';
const DELETE_EMPLOYEES__FAILURE = 'DELETE_EMPLOYEES__FAILURE';
const INVITE_EMPLOYEE__START = 'INVITE_EMPLOYEE__START';
const INVITE_EMPLOYEE__SUCCESS = 'INVITE_EMPLOYEE__SUCCESS';
const INVITE_EMPLOYEE__FAILURE = 'INVITE_EMPLOYEE__FAILURE';
const UPDATE_EMPLOYEE_ROWS = 'UPDATE_EMPLOYEE_ROWS';
const UPDATE_EMPLOYEE_FORM_ROW = 'UPDATE_EMPLOYEE_FORM_ROW';

function fetchEmployeesStart() {
  return { type: FETCH_EMPLOYEES__START };
}

function fetchEmployeesSuccess(employees) {
  return { type: FETCH_EMPLOYEES__SUCCESS, employees };
}

function fetchEmployeesFailure(errors) {
  return { type: FETCH_EMPLOYEES__SUCCESS, errors };
}

function createEmployeeStart() {
  return { type: CREATE_EMPLOYEE__START };
}

function createEmployeeSuccess(employee) {
  return { type: CREATE_EMPLOYEE__SUCCESS, employee };
}

function createEmployeeFailure(employee) {
  return { type: CREATE_EMPLOYEE__FAILURE, employee };
}

function updateEmployeeStart() {
  return { type: UPDATE_EMPLOYEE__START };
}

function updateEmployeeSuccess(employee) {
  return { type: UPDATE_EMPLOYEE__SUCCESS, employee };
}

function updateEmployeeFailure() {
  return { type: UPDATE_EMPLOYEE__FAILURE };
}

function deleteEmployeesStart() {
  return { type: DELETE_EMPLOYEES__START };
}

function deleteEmployeesSuccess(employeeIds) {
  return { type: DELETE_EMPLOYEES__SUCCESS, employeeIds };
}

function deleteEmployeesFailure() {
  return { type: DELETE_EMPLOYEES__FAILURE };
}

function inviteEmployeeStart() {
  return { type: INVITE_EMPLOYEE__START };
}

function inviteEmployeeSuccess(employees) {
  return { type: INVITE_EMPLOYEE__SUCCESS, employees };
}

function inviteEmployeeFailure() {
  return { type: INVITE_EMPLOYEE__FAILURE };
}

function updateEmployeeRows(employees) {
  return { type: UPDATE_EMPLOYEE_ROWS, employees };
}

function updateEmployeeFormRow(employee) {
  return { type: UPDATE_EMPLOYEE_FORM_ROW, employee };
}

export {
  CREATE_EMPLOYEE__FAILURE,
  CREATE_EMPLOYEE__START,
  CREATE_EMPLOYEE__SUCCESS,
  DELETE_EMPLOYEES__FAILURE,
  DELETE_EMPLOYEES__START,
  DELETE_EMPLOYEES__SUCCESS,
  FETCH_EMPLOYEES__FAILURE,
  FETCH_EMPLOYEES__START,
  FETCH_EMPLOYEES__SUCCESS,
  INVITE_EMPLOYEE__FAILURE,
  INVITE_EMPLOYEE__START,
  INVITE_EMPLOYEE__SUCCESS,
  UPDATE_EMPLOYEE_FORM_ROW,
  UPDATE_EMPLOYEE_ROWS,
  UPDATE_EMPLOYEE__FAILURE,
  UPDATE_EMPLOYEE__START,
  UPDATE_EMPLOYEE__SUCCESS,
  createEmployeeFailure,
  createEmployeeStart,
  createEmployeeSuccess,
  deleteEmployeesFailure,
  deleteEmployeesStart,
  deleteEmployeesSuccess,
  fetchEmployeesFailure,
  fetchEmployeesStart,
  fetchEmployeesSuccess,
  inviteEmployeeFailure,
  inviteEmployeeStart,
  inviteEmployeeSuccess,
  updateEmployeeFailure,
  updateEmployeeFormRow,
  updateEmployeeRows,
  updateEmployeeStart,
  updateEmployeeSuccess,
};
