import { fromJS, Map as iMap } from 'immutable';

import { CREATE_EO_CAMPAIGN__SUCCESS } from 'actions/eoCampaignActions';
import { CREATE_EO_DRIP_LIST__SUCCESS } from 'actions/eoDripListActions';
import { FETCH_BRAND_DASHBOARD_DATA__SUCCESS, ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_SUCCESS } from 'actions/brandDashboardActions';


import { camelizeObjectDeep } from './utils';

const initialState = iMap();

export default function brandDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRAND_DASHBOARD_DATA__SUCCESS: {
      state = fromJS(camelizeObjectDeep(action.data));

      break;
    }
    case CREATE_EO_DRIP_LIST__SUCCESS: {
      const { eoDripList } = action;

      if (state.has('id') && eoDripList.brand_id === state.get('id')) {
        state = state.set('eoDripListsCount', state.get('eoDripListsCount') + 1);
      }

      break;
    }
    case CREATE_EO_CAMPAIGN__SUCCESS: {
      const { eoCampaign } = action;

      if (state.has('id') && eoCampaign.brand_id === state.get('id')) {
        state = state.set('eoCampaignsCount', state.get('eoCampaignsCount') + 1);
      }

      break;
    }
    
  }

  return state;
}
