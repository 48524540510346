import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import ErrorBoundary from '../../../decorators/error-boundary';

import ConnectStoreHOC from '../../../startup/connect_store_hoc';

import { updateUserPermissions } from '../../../api/permissions';

import {
  optionsSelector,
  somethingIsChangingSelector,
} from '../../../selectors';

import {
  currentMarketerSelector,
  currentUserSelector,
} from '../../../selectors/railsContextSelectors';

import { optionsMap, userShape, permissionShape } from '../../../common/prop_types_shapes';

import UserPermissionsComponent from './UserPermissionsComponent';


class UserPermissionsContainer extends Component {
  static propTypes = {
    currentUser: userShape,
    dispatch: PropTypes.func,
    permissionOptions: optionsMap,
    somethingIsChanging: PropTypes.bool,
    userPermissions: permissionShape,
  }

  handleChangePermissions = (permissionsArray) => {
    const { userPermissions, dispatch } = this.props;
    const userId = userPermissions.get('userId');

    updateUserPermissions(dispatch, userId, permissionsArray);
  }

  render() {
    const {
      currentUser,
      permissionOptions,
      somethingIsChanging,
      userPermissions,
    } = this.props;

    return (
      <UserPermissionsComponent
        currentUser={currentUser}
        permissionOptions={permissionOptions}
        somethingIsChanging={somethingIsChanging}
        userPermissions={userPermissions}
        onChangePermissions={this.handleChangePermissions}
      />
    );
  }
}


function select(state, ownProps) {
  const currentUser = currentUserSelector(state, ownProps);
  const currentMarketer = currentMarketerSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const permissionOptions = options.get('permissionOptions');

  const somethingIsChanging = somethingIsChangingSelector(state, ownProps);


  return {
    currentMarketer,
    currentUser,
    permissionOptions,
    somethingIsChanging,
  };
}

export default compose(
  ConnectStoreHOC,
  connect(select),
  ErrorBoundary,
)(UserPermissionsContainer);
