import React from 'react';

import { cloneDeep, set, toPairs } from 'lodash';

import Select from 'components_linkio/Select';

import { translate } from 'common/i18n';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { processObjToCamelCase, processObjToSnakeCase } from 'api/utils';

import { optionsList } from 'common/prop_types_shapes';

import { stringifyOptions } from 'common/utils';

import './eoToDosBulkActions.scss';

const colors = {
  botticelli: '#c5d4df',
  charcoalGreyTwo: '#474a4d',
  mantis: '#70cb63',
  mineGray: '#333',
  paleGreySix: '#eff2f5',
  tango: '#ea712f',
  white: '#fff',
};

const toDosTypesOptions = [
  {
    value: 'reply',
    label: 'Reply',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

const initialState = {
  assignees: [],
  isUnassignedFilterOn: false,
  currentBrandId: null,
  currentCampaignId: null,
  toDosTypes: [],
  errors: {},
};

function reducer(state, action) {
  const { value, type } = action;

  switch (type) {
    case 'setState':
      return value;
    default: {
      const newState = cloneDeep(state);

      set(newState, type, value);

      return newState;
    }
  }
}

const initStateWithFiltersQueryParams = (filtersQueryParams) => {
  const initialStateWithFiltersQueryParams = cloneDeep(initialState);
  const filtersParamsPairs = toPairs(processObjToCamelCase(filtersQueryParams));

  const filtersKeysToStateProps = {
    eoCampaignBrandIdEq: 'currentBrandId',
    eoCampaignIdEq: 'currentCampaignId',
    toDosTypeEqAny: 'toDosTypes',
    userIdEqAny: 'assignees',
    userIdNull: 'isUnassignedFilterOn',
  };

  filtersParamsPairs.forEach(([filterKey, filterValue]) =>
    set(initialStateWithFiltersQueryParams, filtersKeysToStateProps[filterKey], filterValue),
  );

  return initialStateWithFiltersQueryParams;
};

function getAssigneeFilter(existingFiltersInSnakeCase, newFilterByUser, isUnassignedIncluded) {
  const existingFilters = processObjToCamelCase(existingFiltersInSnakeCase);

  const { userIdNull, ...existingFiltersWithoutUnassigned } = existingFilters;

  const unassignedFilter = isUnassignedIncluded ? { userIdNull: true } : {};

  const resultingFilter = {
    ...existingFiltersWithoutUnassigned,
    ...newFilterByUser,
    ...unassignedFilter,
  };

  return processObjToSnakeCase(resultingFilter);
}

function applyAssigneeFilterToQueryParams(
  queryValues,
  queryParams,
  changeQueryParams,
  isUnassignedIncluded,
) {
  const { filters: existingFilters } = queryParams;

  const newFilterByUser = { userIdEqAny: queryValues };

  const newFilter = getAssigneeFilter(existingFilters, newFilterByUser, isUnassignedIncluded);

  const newQueryParams = {
    ...queryParams,
    filters: newFilter,
  };

  changeQueryParams(newQueryParams);
}

function applyFilterToQueryParams(queryValues, predicate, queryParams, changeQueryParams) {
  const { filters: existingFilters } = queryParams;

  const newFilter = { [predicate]: queryValues };

  const newQueryParams = {
    ...queryParams,
    filters: { ...existingFilters, ...processObjToSnakeCase(newFilter) },
  };

  changeQueryParams(newQueryParams);
}

const EoToDosBulkActionsComponent = ({
  immutableBrandsOptions,
  immutableEoCampaignsOptions,
  immutableUsersOptions,
  changeQueryParams,
  queryParams,
}) => {
  const filtersQueryParams = queryParams.filters;

  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const newState = initStateWithFiltersQueryParams(filtersQueryParams);

    dispatch({ type: 'setState', value: newState });
  }, [JSON.stringify(filtersQueryParams)]);

  const brandsOptions = stringifyOptions(immutableBrandsOptions.toJS());
  const eoCampaignsOptions = stringifyOptions(immutableEoCampaignsOptions.toJS());

  const stringifiedUsersOptions = stringifyOptions(immutableUsersOptions.toJS());
  const assigneesOptions = [
    ...stringifiedUsersOptions,
    { label: 'Unassigned', value: 'unassigned' },
  ];

  const { assignees, isUnassignedFilterOn, currentBrandId, currentCampaignId, toDosTypes } = state;

  function handleSelectAssignees(options) {
    const usersOptionsValues = options
      .map(({ value }) => value)
      .filter((value) => value !== 'unassigned');
    const isUnassignedSelected = options.length > usersOptionsValues.length;

    applyAssigneeFilterToQueryParams(
      usersOptionsValues,
      queryParams,
      changeQueryParams,
      isUnassignedSelected,
    );
  }

  function handleSelectToDosTypes(options) {
    const optionsValues = options.map(({ value }) => value);

    applyFilterToQueryParams(optionsValues, 'toDosTypeEqAny', queryParams, changeQueryParams);
  }

  function handleChangeCurrentBrand(option) {
    const newBrandId = option ? option.value : null;

    applyFilterToQueryParams(newBrandId, 'eoCampaignBrandIdEq', queryParams, changeQueryParams);
  }

  function handleChangeCurrentCampaign(option) {
    const newCampaignId = option ? option.value : null;

    applyFilterToQueryParams(newCampaignId, 'eoCampaignIdEq', queryParams, changeQueryParams);
  }

  const componentStylesForSingleValueSelects = { option: { wordBreak: 'break-all' } };

  const assigneesComponentStyles = {
    multiValueCb: (state) => {
      const { value } = state.data;
      const isUnassignedBadge = value === 'unassigned';

      return {
        backgroundColor: isUnassignedBadge ? colors.paleGreySix : colors.botticelli,
        borderRadius: '3px',
        color: isUnassignedBadge ? colors.charcoalGreyTwo : colors.mineGray,
        fontSize: '12px',
        lineHeight: '1.5',
      };
    },
    multiValueLabelCb: (state) => {
      const { value } = state.data;
      const isUnassignedBadge = value === 'unassigned';

      return {
        color: isUnassignedBadge ? colors.charcoalGreyTwo : colors.mineGray,
      };
    },
  };

  const toDosTypesStyles = {
    multiValueCb: (state) => {
      const { value } = state.data;
      const isReplyBadge = value === 'reply';

      return {
        backgroundColor: isReplyBadge ? colors.mantis : colors.tango,
        borderRadius: '3px',
        color: colors.white,
        fontSize: '12px',
        lineHeight: '1.5',
      };
    },
    multiValueLabelCb: () => ({
      color: colors.white,
    }),
  };

  const assigneesFilterValues = assigneesOptions.filter((assigneeOption) => {
    if (assigneeOption.value === 'unassigned') {
      return isUnassignedFilterOn;
    }

    return assignees.some((assignee) => assigneeOption.value === assignee);
  });

  return (
    <div className="eo-to-dos-bulk-actions">
      <div className="eo-to-dos-bulk-actions__row">
        <div className="eo-to-dos-bulk-actions__row-item">
          <Select
            closeMenuOnSelect={false}
            componentStyles={assigneesComponentStyles}
            isDisabled={false}
            isMulti
            onChange={handleSelectAssignees}
            options={assigneesOptions}
            placeholder={translate(`eoToDo.bulkActions.filterByAssignees`)}
            triggerChangeOnBlur={false}
            value={assigneesFilterValues}
            wrapperClassName="eo-to-dos-bulk-actions__assignee-select"
          />

          <Select
            componentStyles={toDosTypesStyles}
            isDisabled={false}
            isMulti
            onChange={handleSelectToDosTypes}
            options={toDosTypesOptions}
            placeholder={translate(`eoToDo.bulkActions.filterByToDoType`)}
            triggerChangeOnBlur={false}
            value={toDosTypesOptions.filter(({ value: toDosTypesOptionValue }) =>
              toDosTypes.find((toDosType) => toDosTypesOptionValue === toDosType),
            )}
            wrapperClassName="eo-to-dos-bulk-actions__to-dos-types-select"
          />

          <Select
            blurInputOnSelect
            componentStyles={componentStylesForSingleValueSelects}
            isClearable
            placeholder={translate(`eoToDo.bulkActions.filterByBrand`)}
            onChange={handleChangeCurrentBrand}
            options={brandsOptions}
            triggerChangeOnBlur={false}
            value={brandsOptions.find(({ value }) => value === currentBrandId) ?? null}
            wrapperClassName="eo-to-dos-bulk-actions__filter-by-brand"
          />

          <Select
            blurInputOnSelect
            componentStyles={componentStylesForSingleValueSelects}
            isClearable
            placeholder={translate(`eoToDo.bulkActions.filterByCampaign`)}
            onChange={handleChangeCurrentCampaign}
            options={eoCampaignsOptions}
            triggerChangeOnBlur={false}
            value={eoCampaignsOptions.find(({ value }) => value === currentCampaignId) ?? null}
            wrapperClassName="eo-to-dos-bulk-actions__filter-by-campaign"
          />
        </div>
      </div>
    </div>
  );
};

EoToDosBulkActionsComponent.propTypes = {
  immutableBrandsOptions: optionsList,
  immutableEoCampaignsOptions: optionsList,
  immutableUsersOptions: optionsList,
  ...withQueryParamsPropTypes,
};

export default withQueryParams(EoToDosBulkActionsComponent);
