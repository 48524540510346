import React from 'react';

// Quick fix, before we complete transfer to client-side routing.
// All content rendered on server necessary had class 'ror-ssr-content'.

// TODO: remove it after we complete transfer to client-side routing.

const withRorSsrContent = (WrappedComponent) =>
  class RorSsrContent extends React.PureComponent {
    componentWillUnmount() {
      Reflect.apply(
        Array.prototype.forEach,
        document.querySelectorAll('.ror-ssr-content'),
        [(content) => content.remove()],
      );
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default withRorSsrContent;
