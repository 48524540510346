import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import OpenCloseIconComponent from './OpenCloseIconComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector, visibleBrandPageIdSelector } from 'selectors';
import { historyShape } from 'common/prop_types_shapes';
import { reDistributeDls } from 'api/brand_page';

const OpenCloseIconContainer = ({
  brandId,
  dispatch,
  history,
  isOpen,
  pageId,
  pageParentType,
  queryParams,
  visibleBrandPageId,
}) => {
  function updateCurrentUrl() {
    const isCompetitorPage = pageParentType === 'brand_page';

    const params = isOpen
      ? { pagesFilter: queryParams.pagesFilter, search: queryParams.search, format: null }
      : {
          ...queryParams,
          dlsGroupMode: isCompetitorPage ? 'all' : 'one_per_domain',
          format: null,
        };

    if (isCompetitorPage) {
      const competitorPageId = isOpen ? '' : pageId;

      const path = Routes.brand_page_tab_path(
        brandId,
        visibleBrandPageId,
        'competitors',
        competitorPageId,
        params,
      );

      history.push(path);
      return;
    }

    const brandPageId = isOpen ? '' : pageId;
    const atpTableTab = isOpen ? '' : 'backlinks';

    const path = Routes.brand_page_tab_path(brandId, brandPageId, atpTableTab, null, params);
    history.push(path);
  }

  function handleOpenCloseClick() {
    updateCurrentUrl();

    !isOpen && pageParentType === 'brand' && reDistributeDls(dispatch, pageId);
  }

  return (
    <OpenCloseIconComponent
      isOpen={isOpen}
      onOpenCloseClick={handleOpenCloseClick}
      pageId={pageId}
    />
  );
};

OpenCloseIconContainer.propTypes = {
  brandId: PropTypes.string,
  dispatch: PropTypes.func,
  history: historyShape,
  isOpen: PropTypes.bool,
  pageId: PropTypes.number,
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  visibleBrandPageId: PropTypes.string,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const visibleBrandPageId = visibleBrandPageIdSelector(state, ownProps);

  return {
    brandId,
    visibleBrandPageId,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(OpenCloseIconContainer);
