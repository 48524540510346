import React from 'react';
import PropTypes from 'prop-types';

import { percentageShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import OverlayWithTooltipComponent from 'components_linkio/overlay_with_tooltip_component';

const AnchorPercentageComponent = ({ anchorPercentage, currentPercentage }) => {
  const { anchorType, title, ideal } = anchorPercentage;

  const tooltipText = translate(`destinationLink.anchorType.${anchorType}`);
  return (
    <tr className="percentages__anchor-row percentages__anchor-row_text-light">
      <td className="percentages__icon">
        {/* we need this empty element to handle 'missing' icon cell */}
      </td>
      <OverlayWithTooltipComponent
        tooltip={{ text: tooltipText, placement: 'left', color: 'white' }}
      >
        <td className="percentages__anchor-data percentages__anchor-data_text-align-left percentages__anchor-data_with-right-border text">
          {title}
        </td>
      </OverlayWithTooltipComponent>
      <td className="percentages__anchor-data percentages__anchor-data_with-right-border">
        {Number(anchorPercentage[currentPercentage]).toFixed(2)}%
      </td>
      <td>{Number(ideal).toFixed(2)}%</td>
    </tr>
  );
};

AnchorPercentageComponent.propTypes = {
  anchorPercentage: percentageShape.isRequired,
  currentPercentage: PropTypes.string.isRequired,
};

export default AnchorPercentageComponent;
