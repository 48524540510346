import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';

import ExportToCsvButtonComponent from './ExportToCsvButtonComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandContextShape } from 'common/prop_types_shapes';
import { currentIdSelector } from 'selectors';
import { currentBrandContextSelector } from 'selectors/brandsContextSelector';
import { fetchRailsContext } from 'api/rails_context';
import { processObjToSnakeCase } from 'api/utils';

const ExportToCsvButtonContainer = ({ brand, brandId, dispatch, queryParams }) => {
  const sorting = queryParams.sorting || {};
  const filters = queryParams.filters || {};
  const { dateStart, dateEnd } = filters;

  async function handleClick() {
    // need context to trigger Auth failure if any
    const context = await fetchRailsContext(dispatch);
    if (context) {
      window.location.href = Routes.rank_tracker_export_csv_path({
        brand_id: brandId,
        format: 'csv',
        sorting: processObjToSnakeCase(sorting),
        start_date: dateStart,
        end_date: dateEnd,
      });
    }
  }

  const isDisabled = !brand.get('hasTrackedKeywords');

  return <ExportToCsvButtonComponent isDisabled={isDisabled} onClick={handleClick} />;
};

ExportToCsvButtonContainer.propTypes = {
  brand: brandContextShape,
  brandId: PropTypes.string,
  dispatch: PropTypes.func,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brand = currentBrandContextSelector(state, ownProps);
  const brandId = currentIdSelector(state, ownProps);

  return {
    brand,
    brandId,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(ExportToCsvButtonContainer);
