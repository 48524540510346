import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';
import uiComponentsTranslate from 'common/i18n/uiComponents';
import ChevronIcon from 'common/icons/chevron';

import './userTypeSelection.scss';

const {
  uiComponents: {
    userTypeSelection: { userTypes },
  },
} = uiComponentsTranslate;

const UserTypeSelectionComponent = ({ onClickUserType }) => (
  <div className="user-type-selection">
    <h1 className="user-type-selection__title">
      {translate('uiComponents.userTypeSelection.title')}
    </h1>
    <div className="user-type-selection__list">
      {userTypes.map(({ title, description }, idx) => (
        <div
          className="user-type-selection__type-button"
          key={idx}
          onClick={onClickUserType(title)}
        >
          <div className="user-type-selection__type-inner">
            <div className="user-type-selection__type-text">
              <b className="user-type-selection__type-title">{title}</b>
              {description && (
                <div className="user-type-selection__type-description">
                  {description}
                </div>
              )}
            </div>
            <ChevronIcon />
          </div>
        </div>
      ))}
    </div>
  </div>
);

UserTypeSelectionComponent.propTypes = {
  onClickUserType: PropTypes.func.isRequired,
};

export default UserTypeSelectionComponent;
