import { buildWordInPastTenseWithPluralization } from 'common/utils';

export default {
  eoCampaignStep: {
    bulkActions: {
      addEoCampaignStep: 'Add a step',
    },
    actionTypes: {
      EoCampaignStepEmail: 'Email',
      EoCampaignStepWait: 'Wait',
    },
    messages: {
      cantFetchEoCampaignSteps: "Can't load campaign steps",
      cantFetchEoCampaignStep: "Can't load campaign step",
      cantCreateEoCampaignStep: "Can't create campaign step",
      cantUpdateEoCampaignStep: "Can't update campaign step",
      cantDeleteEoCampaignSteps: "Can't delete campaign step(s)",
      cantMoveEoCampaignStep: "Can't move campaign step",
      createSuccess: 'Campaign Step was created successfully.',
      deleteSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization(
          'Campaign Step',
          count,
        )} deleted successfully.`;
      },
      updateSuccess: 'Campaign Step was updated successfully.',
    },
    addEoCampaignStepMenu: {
      EoCampaignStepEmail: {
        title: 'Email',
        subtitle: 'Create an Email',
      },
      EoCampaignStepWait: {
        title: 'Wait',
        subtitle: 'Add a delay',
      },
      title: 'Add Campaign Step',
    },
    eoCampaignStepPopup: {
      title: (isUpdating) => (isUpdating ? 'Update Campaign Step' : 'Create Campaign Step'),
      buttons: {
        chooseTemplate: 'Find Email Template',
        submit: (isUpdating) => (isUpdating ? 'Update Step' : 'Create Step'),
      },
    },
    buttons: {
      submit: (isUpdating) => (isUpdating ? 'Update Step' : 'Create Step'),
    },
    eoCampaignStepWait: {
      inputs: {
        waitTime: {
          label: 'Wait time:',
        },
        measure: {
          minutes: { label: 'Minutes' },
          hours: { label: 'Hours' },
          days: { label: 'Days' },
        },
        useTimeTable: {
          label: 'Set Active Hours',
          hint: 'Limit campaign activities to hours you specify. If nothing is specified there, \
            Saturday and Sunday will be non-operating days by default.',
        },
      },
      title: 'Delay before proceeding',
    },
    eoCampaignStepEmail: {
      draftAssigneeSelect: 'Assign the draft to',
      attrs: {
        buttonTitle: 'Fields',
        prospect: {
          attrTitle: 'Prospect',
          first_name: 'First name',
          last_name: 'Last name',
          email: 'Email Address',
          organization: 'Organization',
          domain: 'Domain Name',
          position: 'Position',
          role: 'Role',
          phone: 'Phone number',
        },
        inbox: {
          attrTitle: 'Inbox',
          email: 'Email',
          nickname: 'Nickname',
          from_name: 'From name',
          reply_to: 'Reply to',
          inbox_signature: 'Inbox signature',
        },
        precomputed: {
          attrTitle: 'Precomputed',
          textVariations: 'Text variations',
          unsubscribe_link: 'Unsubscribe',
          unsubscribeText: {
            mainText: 'If you do not want further emails from me, please click',
            linkText: 'here',
          },
        },
      },
      eoTemplatesHint: {
        mainText: 'Looking for inspiration? Have a look at our',
        linkText: 'collection of templates',
      },
      eoCampaignEmailTemplatesPopup: {
        buttons: {
          submit: 'Select this template',
          cancel: 'Cancel',
        },
        tabs: {
          all: 'All',
          default: 'Defaults',
          private: 'Private',
          shared: 'Shared',
        },
        createButton: '+ Create a new Template',
        textarea: {
          subject: 'Subject:',
        },
        title: 'Email templates',
      },
      inputs: {
        emailSubject: {
          label: 'Subject',
          hint: `Replace FALLBACK with a word or a line that will be used when the prospect's data
           is unavailable. Leave the variable with no characters after ':' to add nothing when
            there is no value: e. g. {{prospect.first_name:}} or if you don't want to use this 
            fallback feature, simply delete it, leaving only your variable: e.g.
             {{prospect.first_name}}`,
          noSubjectMessage: 'no subject set',
        },
        emailBody: {
          label: 'Body',
          placeholder: 'Write your email body here',
          hint: `Replace FALLBACK with a word or a line that will be used when the prospect's data
           is unavailable. Leave the variable with no characters after ':' to add nothing when
            there is no value: e. g. {{prospect.first_name:}} or if you don't want to use this 
            fallback feature, simply delete it, leaving only your variable: e.g.
             {{prospect.first_name}}`,
        },
        includePreviousEmail: {
          label: 'Keep Email Thread',
          hint: 'Check the box to reply in the same thread, with all the previous messages in view.',
        },
        openTracking: {
          label: 'Open/Read Tracking',
          hint: 'Track when a prospect opens or reads your email (may decrease deliverability).',
        },
        clickTracking: {
          label: 'Link Tracking',
          hint: 'Track when a prospect clicks a link in your email (may decrease deliverability).',
        },
        createAsDraft: {
          label: 'Edit Before Sending',
          hint: 'Save your message as a draft to give it a final edit before manual sending.',
        },
        ccEmails: {
          label: 'CC Recipients',
          hint: 'Please add one email per line. Note that this may interfere with open, \
            click and reply tracking statistics if any CCed person opens the email, \
            clicks on a link or replies to all.',
        },
      },
      title: 'Send an Email',
    },
    blankState: {
      body: "Add your first step by clicking 'Add a step' button",
      title: "You don't have any Steps yet",
    },
    eoCampaignStepActions: {
      moveUp: 'Move up',
      moveDown: 'Move down',
      deleteStep: 'Delete step',
    },
    title: 'Sequence',
  },
};
