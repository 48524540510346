import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';
import * as resolve from 'table-resolver';
import * as edit from 'react-edit';
import * as selectabular from 'selectabular';
import { cloneDeep, findIndex, get } from 'lodash';

import columnsGenerator, { columnSelect, columnTextArea } from 'common/tables/columns_generator';

import {
  addCSSClassToCellInTable,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
} from 'common/tables/utils';

import { checkboxColumn, reactSelectColumn, textColumn } from 'common/tables/columns';
import { processAhrefsOrMozMetricValue } from 'common/utils';
import { optionsArray, draftCompetitorPagesArray } from 'common/prop_types_shapes';
import { optionValueOrValue } from 'components_linkio/Select/utils';
import { translate } from 'common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import KeywordsValueFormatterComponent from 'common/tables/sharedComponents/KeywordsValueFormatter';
import OverlayWithTooltipComponent from 'components_linkio/overlay_with_tooltip_component';
import Spinner from 'components_linkio/Spinner';

import './bulkCompetitorsSetupTable.scss';

export default class BulkCompetitorsSetupTableComponent extends React.Component {
  static propTypes = {
    draftCompetitorPages: draftCompetitorPagesArray,
    onClickKeywordsCell: PropTypes.func,
    onUpdateDraftCompetitorPage: PropTypes.func,
    onUpdateRows: PropTypes.func,
    pageTypeOptions: optionsArray,
  };

  bulkCompetitorsSetupTableNode = () => document.querySelector('.bulk-competitors-setup-table');

  notInAtpCompetitorPages = () =>
    this.props.draftCompetitorPages.filter(
      (competitorPage) => competitorPage.status === 'not_in_atp',
    );

  isAllSelected = () => {
    const { draftCompetitorPages } = this.props;

    const rowsCount = this.notInAtpCompetitorPages().length;

    const selectedRowsCount = draftCompetitorPages.filter((page) => page.selected).length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  };

  handleOnToggleSelectAll = (event) => {
    const { onUpdateRows } = this.props;

    const rows = cloneDeep(this.notInAtpCompetitorPages());

    const newRows = event.target.checked ? selectabular.all(rows) : selectabular.none(rows);

    onUpdateRows(newRows);
  };

  handleOnToggleSelectRow = (rowId) => () => {
    const { draftCompetitorPages, onUpdateRows } = this.props;

    const selectedFilter = (row) => row.id === rowId;
    const rows = selectabular.toggle(selectedFilter)(draftCompetitorPages);

    onUpdateRows(rows);
  };

  headerCheckboxFormatters = () => {
    return <Checkbox checked={this.isAllSelected()} onChange={this.handleOnToggleSelectAll} />;
  };

  cellCheckboxFormatters = (value, extra) => {
    const {
      rowData: { selected, id, status },
    } = extra;

    return (
      <>
        {status === 'not_in_atp' && (
          <Checkbox checked={selected} onChange={this.handleOnToggleSelectRow(id)} />
        )}
      </>
    );
  };

  selectProps = {
    blurInputOnSelect: true,
    autoFocus: true,
    isClearable: true,
    openMenuOnFocus: true,
    skinOptions: {
      inTable: true,
    },
  };

  generateColumns = (withAbility, props) => {
    const { pageTypeOptions } = props;

    const columns = columnsGenerator([
      checkboxColumn({
        headerFormatters: [this.headerCheckboxFormatters],
        cellFormatters: [this.cellCheckboxFormatters],
        className: 'bulk-competitors-setup-table__checkbox-column',
      }),
      textColumn({
        name: 'link',
        headerLabel: translate('bulkCompetitorsSetup.table.headers.competitorPages'),
        isLink: true,
        className: 'bulk-competitors-setup-table__link-column',
      }),
      textColumn({
        name: 'keyword',
        headerLabel: translate('bulkCompetitorsSetup.table.headers.keyword'),
        cellValueFormatter: this.autodiscoverByKeywordValueFormatter,
        className: 'bulk-competitors-setup-table__keyword-column',
      }),
      textColumn({
        name: 'autodiscoverPosition',
        headerLabel: translate('bulkCompetitorsSetup.table.headers.position'),
        className: 'bulk-competitors-setup-table__position-column',
      }),
      textColumn({
        name: 'metrics.domainAhrefsRating',
        explanationMessage: translate(
          'explanationMessages.bulkCompetitorsSetupTable.domainAhrefsRating',
        ),
        headerLabel: translate('bulkCompetitorsSetup.table.headers.domainAhrefsRating'),
        cellValueFormatter: this.metricsValueFormatter('ahrefs'),
        className: 'bulk-competitors-setup-table__domain-rating-column',
      }),
      textColumn({
        name: 'metrics.uriRefdomainsCount',
        explanationMessage: translate(
          'explanationMessages.bulkCompetitorsSetupTable.uriRefdomainsCount',
        ),
        headerLabel: translate('bulkCompetitorsSetup.table.headers.uriRefdomainsCount'),
        cellValueFormatter: this.metricsValueFormatter('ahrefs'),
        className: 'bulk-competitors-setup-table__uri-refdomains-count-column',
      }),
      // textColumn({
      //   name: 'metrics.mozDomainAuthority',
      //   explanationMessage: translate(
      //     'explanationMessages.bulkCompetitorsSetupTable.mozDomainAuthority',
      //   ),
      //   headerLabel: translate('bulkCompetitorsSetup.table.headers.mozDomainAuthority'),
      //   cellValueFormatter: this.metricsValueFormatter('moz'),
      //   className: 'bulk-competitors-setup-table__moz-domain-authority-column',
      // }),
      reactSelectColumn({
        name: 'pageTypeId',
        headerLabel: translate('bulkCompetitorsSetup.table.headers.pageType'),
        cellValueFormatter: this.pageTypeValueFormatter,
        cellTransforms: [columnSelect(withAbility, pageTypeOptions, this.selectProps)],
        className: 'bulk-competitors-setup-table__page-type-column',
      }),
      textColumn({
        name: 'brandNames',
        explanationMessage: translate('explanationMessages.bulkCompetitorsSetupTable.brandName'),
        headerLabel: translate('bulkCompetitorsSetup.table.headers.brandName'),
        cellValueFormatter: this.brandNameValueFormatter,
        className: 'bulk-competitors-setup-table__brand-name-column',
        cellTransforms: [columnTextArea(withAbility)],
        showTooltip: true,
      }),
      textColumn({
        name: 'keywords',
        headerLabel: translate('bulkCompetitorsSetup.table.headers.keywords'),
        cellValueFormatter: this.keywordsValueFormatter,
        className: 'bulk-competitors-setup-table__keywords-column',
      }),
      textColumn({
        name: 'status',
        headerLabel: translate('bulkCompetitorsSetup.table.headers.status.title'),
        cellValueFormatter: this.statusValueFormatter,
        className: 'bulk-competitors-setup-table__status-column',
      }),
    ]);

    return columns;
  };

  autodiscoverByKeywordValueFormatter = (_value, extra) => {
    const { rowData } = extra;
    const keywordLabel = rowData.autodiscoverLabel;

    if (!keywordLabel) {
      return '';
    }

    return (
      <OverlayWithTooltipComponent isCopyable tooltip={{ text: keywordLabel, placement: 'top' }}>
        <span className="text_two-lines-with-ellipsis-break-word">{keywordLabel}</span>
      </OverlayWithTooltipComponent>
    );
  };

  keywordsValueFormatter = (value, extra) => {
    const { rowData } = extra;
    const keywords = value || [];

    const onClick = () => this.props.onClickKeywordsCell(rowData.id);

    return <KeywordsValueFormatterComponent keywords={keywords} onClick={onClick} />;
  };

  metricsValueFormatter = (metricsSource) => (value, extra) => {
    const metricsUploadingInProgress = get(
      extra,
      `rowData.${metricsSource}MetricsUploadingInProgress`,
      false,
    );

    if (metricsUploadingInProgress) {
      return <Spinner isBlack />;
    }

    return processAhrefsOrMozMetricValue(value);
  };

  pageTypeValueFormatter = (value) => {
    const { pageTypeOptions } = this.props;
    const option = pageTypeOptions.find((option) => option.value === String(value));
    const label = (option || {}).label;

    return (
      <OverlayWithTooltipComponent
        isCopyable
        tooltip={{ color: 'white', text: label, placement: 'top' }}
      >
        <span className="text_two-lines-with-ellipsis-break-word">{label}</span>
      </OverlayWithTooltipComponent>
    );
  };

  brandNameValueFormatter = (_value, extra) => {
    const { rowData } = extra;

    const brandNames = [rowData.brandName, ...rowData.brandOtherNames].join(', ');

    return (
      <OverlayWithTooltipComponent
        isCopyable
        tooltip={{ color: 'white', text: brandNames, placement: 'top' }}
      >
        <span className="text_two-lines-with-ellipsis-break-word">{brandNames}</span>
      </OverlayWithTooltipComponent>
    );
  };

  statusValueFormatter = (value) => translate(`bulkCompetitorsSetup.table.headers.status.${value}`);

  editable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowData, property }) => {
      const { draftCompetitorPages, onUpdateRows } = this.props;

      const index = findIndex(draftCompetitorPages, { id: rowData.id });
      const clonedRows = cloneDeep(draftCompetitorPages);
      clonedRows[index].editing = columnIndex;

      // Show all brand names during 'brand name' cell editing
      if (property === 'brandNames') {
        const brandNames = [rowData.brandName, ...rowData.brandOtherNames].join(', ');
        clonedRows[index].brandNames = brandNames;
      }

      addCSSClassToCellInTable(
        this.bulkCompetitorsSetupTableNode(),
        index,
        columnIndex,
        TABLE_CELL_ACTIVE_CLASS,
      );

      onUpdateRows(clonedRows);
    },

    onValue: async ({ value, rowData, property }) => {
      const { draftCompetitorPages, onUpdateDraftCompetitorPage, onUpdateRows } = this.props;

      const index = findIndex(draftCompetitorPages, { id: rowData.id });
      const currentValue = rowData[property] || '';
      const newValue = optionValueOrValue(value) || '';
      const clonedRow = cloneDeep(rowData);
      const clonedRows = cloneDeep(draftCompetitorPages);

      removeCSSClassFromTableCells(this.bulkCompetitorsSetupTableNode(), TABLE_CELL_ACTIVE_CLASS);

      const needToUpdatePage = String(currentValue) !== String(newValue);

      if (needToUpdatePage) {
        if (property === 'brandNames') {
          const brandNames = newValue.trim().split(/\s*,\s*/);
          clonedRow.brandName = brandNames[0];
          clonedRow.brandOtherNames = brandNames.slice(1);
        } else {
          clonedRow[property] = newValue;
        }

        await onUpdateDraftCompetitorPage(clonedRow);
      }

      Reflect.deleteProperty(clonedRow, 'editing');
      clonedRows[index] = clonedRow;

      onUpdateRows(clonedRows);
    },
  });

  render() {
    const { draftCompetitorPages } = this.props;
    const columns = this.generateColumns(this.editable, this.props);

    const resolvedRows = resolve.resolve({ columns, method: resolve.nested })(draftCompetitorPages);

    return (
      <div className="bulk-competitors-setup-table__wrapper">
        <Table.Provider className="bulk-competitors-setup-table" columns={columns}>
          <Table.Header />
          <Table.Body rowKey="id" rows={resolvedRows} />
        </Table.Provider>
      </div>
    );
  }
}
