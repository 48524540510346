import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { isString } from 'lodash';

import { optionsArray } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import Select from 'components_linkio/Select';

const MAIN_DEEP_SEA_BLUE = '#005689';
const MARINE_BLUE = '#00436b';
const WHITE = '#ffffff';

const componentStyles = {
  control: {
    backgroundColor: MAIN_DEEP_SEA_BLUE,
  },
  dropdownIndicator: {
    ':hover': WHITE,
    backgroundColor: MARINE_BLUE,
    color: WHITE,
    width: '30px',
  },
  indicatorSeparator: {
    width: '0',
  },
  singleValue: {
    color: WHITE,
  },
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <>
      <span className="percentages__label-prefix">
        {translate('brandPagePercentage.labelPrefix')}
      </span>
      {children}
    </>
  </components.SingleValue>
);

SingleValue.propTypes = {
  children: PropTypes.string,
};

const IdealSchemeSelectComponent = ({
  percentageSelectRef,
  onChange,
  percentageSchemeId,
  percentageSchemeOptions,
}) => {
  function filterOption(option, filter) {
    if (filter.length <= 0) {
      return true;
    }

    const label = isString(option.label) ? option.label : option.label.props.children;
    if (label === translate('brandPagePercentage.newPercentageLabel')) {
      return true;
    }

    return label.toLowerCase().substr(0, filter.length) === filter.toLowerCase();
  }

  function findValueInGrouped(options, simpleValue) {
    const flatOptions = options.map((group) => group.options).flat();

    return flatOptions.find((option) => option.value === String(simpleValue));
  }

  return (
    <Select
      blurOnSelect
      components={{ SingleValue }}
      componentStyles={componentStyles}
      filterOption={filterOption}
      forwardedRef={percentageSelectRef}
      isClearable={false}
      name="percentageSchemeId"
      onChange={onChange}
      openMenuOnFocus
      options={percentageSchemeOptions}
      triggerChangeOnBlur={false}
      value={findValueInGrouped(percentageSchemeOptions, percentageSchemeId)}
    />
  );
};

IdealSchemeSelectComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  percentageSchemeId: PropTypes.number.isRequired,
  percentageSchemeOptions: optionsArray.isRequired,
  percentageSelectRef: PropTypes.shape({ current: PropTypes.stateManager }).isRequired,
};

export default IdealSchemeSelectComponent;
