import pluralize from 'pluralize';
import { buildWordInPastTenseWithPluralization } from 'common/utils';

export default {
  eoProgression: {
    breadcrumbsTitle: 'Progressions',
    bulkActions: {
      cancelEoProgressions: 'Cancel',
      resumeEoProgressions: 'Resume',
      resumeEoProgressionsPopup: {
        anotherTime: 'Pick another time',
        dateTimeHint: 'Date format: "05/25/2020 9:00 AM" (MM/DD/YYYY). Time zone is local.',
        resumeImmediately: 'Resume immediately',
        submit: 'Resume',
        subtitle: 'Choose when to resume the progression(s)',
        title: 'Confirm resuming progression(s)',
      },
    },
    messages: {
      cancelEoProgressionsFailure: "Can't cancel Progression(s)",
      cancelEoProgressionsSuccess: 'Successfully canceled Progression(s)',
      cantFetchEoProgressions: "Can't load Progressions",
      cantDeleteEoProgressions: "Can't delete Progression(s)",
      deleteSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization(
          'Progression',
          count,
        )} deleted successfully.`;
      },
      resumeEoProgressionsFailure: "Can't resume Progression(s)",
      resumeEoProgressionsSuccess: 'Progression(s) set to be resumed',
      updateSuccess: 'Progression was updated successfully.',
    },
    table: {
      blankState: {
        body: 'Progressions are generated automatically.',
        title: "You don't have any Progressions yet.",
      },
      headers: {
        completedAt: 'Completed',
        createdAt: 'Created',
        currentEoCampaignStep: 'Step',
        nextEoCampaignStep: 'Next Step',
        prospectEmail: 'Email Address',
        prospectEoDomain: 'Domain Name',
        prospectName: 'Name',
        prospectPosition: 'Position',
        stage: 'Stage',
      },
      stage: {
        bounced: 'Bounced',
        canceled: 'Canceled',
        completed: 'Completed',
        has_error: 'Has Error',
        out_of_office: 'Out of Office',
        rejected: 'Rejected',
        replied: 'Replied',
        running: 'Running',
        draft: 'Draft',
        starting: 'Starting',
        unsubscribed: 'Unsubscribed',
        waiting: 'Waiting',
      },
      eoProgressionsCount: (count) => `${pluralize('Progression', count, true)}`,
    },
  },
};
