import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TrustSuggestionsPopupComponent from './TrustSuggestionsPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { closeTrustSuggestionsPopup } from 'actions/popup_actions';

class TrustSuggestionsPopupContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };

  handleCancel = () => this.props.dispatch(closeTrustSuggestionsPopup());

  render() {
    return <TrustSuggestionsPopupComponent onCancel={this.handleCancel} />;
  }
}

export default ConnectStoreHOC(connect()(TrustSuggestionsPopupContainer));
