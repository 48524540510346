import pluralize from 'pluralize';

export default {
  eoInboxesSettings: {
    breadcrumbsTitle: 'Settings',
    blankState: "You don't have any inboxes yet",
    buttons: {
      addCustomEoInbox: 'Add Custom Inbox',
      addEoInbox: 'Add Inbox',
      connectGmailEoInbox: 'Connect Google email account',
      update: 'Update',
    },
    availability: (isVerified) => {
      return `Deliverability: ${isVerified ? 'verified' : 'not verified'}`;
    },
    eoInboxTypeSelectPopup: {
      title: 'Add Inbox',
    },
    messages: {
      cantConnectGmailInbox: "Can't connect Gmail inbox",
      cantCreateEoInbox: "Can't create inbox",
      cantDeleteEoInbox: "Can't delete inbox",
      cantFetchEoInbox: "Can't fetch inbox",
      cantFetchEoInboxes: "Can't fetch inboxes",
      cantFetchEoInboxesSettings: "Can't fetch inboxes settings",
      cantStartEoInboxCheckReplies: "Can't start replies checking",
      cantVerifyAvailability: "Can't start verifying inbox deliverability",
      cantUpdateEoInbox: "Can't update inbox",
      cantUpdateEoInboxesSettings: "Can't update inboxes settings",
      createEoInboxSuccess: 'Successfully created an inbox',
      deleteEoInboxSuccess: 'Inbox was deleted successfully',
      failureVerifyAvailability: (error) => `Failed to verify deliverability. ${error}`,
      finishEoInboxCheckReplies: 'Finished replies checking',
      finishVerifyAvailability: 'Successfully verified inbox deliverability',
      gmailAccessDenied: "Can't connect Gmail Inbox. Access to Gmail was denied.",
      startEoInboxCheckReplies: 'Started replies checking',
      startVerifyAvailability: 'Started verifying inbox deliverability',
      updateEoInboxSuccess: 'Successfully updated an inbox',
      updateEoInboxesSettingsSuccess: 'Successfully updated inboxes settings',
    },
    title: 'Inboxes',
    inputs: {
      bccEmail: {
        label: 'BCC Email Address',
        hint:
          'You can choose to automatically copy all sent emails using the BCC field. \
          This can be used to keep track of all outgoing emails or to automatically copy \
          emails in an external tool.',
      },
      reportEmail: {
        label: 'Deliverability Report Email Address',
        hint:
          'We will send this email address a weekly report of deliverability test performed on inboxes of this account',
      },
    },
    inboxesLimitation: (count) =>
      `Your subscription plan allows for ${pluralize('inbox', count, true)}`,
    emailsSentToday: (emailsSentToday, emailsDailyQuota) =>
      `Emails sent today: ${emailsSentToday === null ? 0 : emailsSentToday} of ${emailsDailyQuota}`,
  },
};
