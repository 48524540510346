import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConnectStoreHOC from '../../../startup/connect_store_hoc';

import { trackHelpcrunchEvent, showErrorMessage, showInfoMessage} from '../../../common/utils';

import { currentIdSelector } from 'selectors';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';
import { 
    enqueueBrandMetricsGenerate,
 } from '../../../api/brandDashboard';
import GenerateMetricsBtnComponent from './GenerateMetricsBtnComponent';

import { fetchBrandsChartMetrics, fetchBrandsChartMetricsToday } from 'api/brandsCharts';
import { CHARTS_DEFAULT_DATE_RANGE } from 'utils/constants';
import { brandChartMetricsMap } from 'common/propTypesShapes/metrics';
import { brandMetricsByBrandIdSelector } from 'selectors/brandMetricsSelector';
import { brandChartMetricsByBrandIdSelector } from 'selectors/brandChartsSelector';
import { translate } from '../../../common/i18n';

class GenerateMetricsBtnContainer extends React.PureComponent {
  static propTypes = {
    brandId: PropTypes.string,
    canUseMetrics: PropTypes.bool,
    dispatch: PropTypes.func,
    brandChartsMetrics: brandChartMetricsMap,
    isLoading: PropTypes.bool

  };
  state = {
    loading : false
  }
  handleClick = () => {
    const dateStart = CHARTS_DEFAULT_DATE_RANGE.dateEnd;
    const dateEnd = CHARTS_DEFAULT_DATE_RANGE.dateEnd;
    const {
      brandId,
      dispatch,
      chartMetrics,
    } = this.props;
   const enq = enqueueBrandMetricsGenerate(dispatch, brandId);
   enq.then((data) => {
     if(data){
       this.setState({loading: true});
     }
   })
    const backLinksCount = chartMetrics.get('backlinksCount');
    if(backLinksCount == 0){
      showInfoMessage(dispatch, translate('brandOverviewApiCalls.messages.findBackLinksFirst'));
      return;
    }

    const intervalCall = () => {
        const fdata=  fetchBrandsChartMetricsToday(dispatch, [brandId], dateStart, dateEnd);
        fdata.then((response) => {
          const st = response;
          if(st == 'Complete'){
            this.setState({loading:false})
            clearTimeout(intervalCall)
          }else if(st == 'Processing'){
            setTimeout(intervalCall, 1000)
          }
        });
    }
    setTimeout(intervalCall, 1000);
    
    trackHelpcrunchEvent('click.crawl.brand');
  }
  defineIsLoading = (clicked = '') =>{
    const {
      chartMetrics,
    } = this.props
    const ojm = chartMetrics.get('status');
    const loadingState = (ojm == 'Processing' || clicked == true ? true : false);
    return loadingState;
  }
  
  render() {
    const {
        canUseMetrics,
        isLoading = this.defineIsLoading() || this.state.loading
    } = this.props;
    return (
      <GenerateMetricsBtnComponent
        canUseMetrics={canUseMetrics}
        onClick={this.handleClick}
        isLoading={isLoading}
      />
    );
  }
}

function select(state, ownProps) {
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);
  const canUseMetrics = subscriptionPolicies.getIn(['metrics', 'canUse']);
  const brandId = currentIdSelector(state, ownProps);
  const chartMetrics = brandMetricsByBrandIdSelector(state, brandId);
  const brandChartsMetrics = brandChartMetricsByBrandIdSelector(state, brandId);
  
  return {
    brandId,
    canUseMetrics,
    brandChartsMetrics,
    chartMetrics
  };
}



export default withRouter(ConnectStoreHOC(connect(select)(GenerateMetricsBtnContainer)));
