import React from 'react';
import PropTypes from 'prop-types';
import { Map as iMap, List as iList } from 'immutable';
import classnames from 'classnames';

import { crawlingInProgress } from '../../utils';

import PageActions from './PageActions';
import BrandPagePriority from './BrandPagePriority';
import OpenCloseIcon from './OpenCloseIcon';
import PageDls from './PageDls';
import { pageItemMetricValueField } from './pageItemMetricFields';

import KeywordsValueFormatterComponent from 'common/tables/sharedComponents/KeywordsValueFormatter';
import Link from 'components_linkio/link';

import {
  policiesShape,
  brandPageShape,
  optionsMap,
  subscriptionPoliciesShape,
} from 'common/prop_types_shapes';

import './pageItem.scss';

const PageItemComponent = ({
  isNextAfterOpen,
  isOpen,
  onClickKeywordsCell,
  onPageChangePriority,
  page,
  pageChildType,
  pageParentId,
  pageParentType,
  pageTypeOptions,
  policies,
  subscriptionPolicies,
}) => {
  const pageId = page.get('id');
  const pageLink = page.get('link', '');
  const pageKeywords = page.get('keywords', iList()).toJS();
  const pagePriority = page.get('priority', '');
  const pageType = (pageTypeOptions || iMap())
    .get(String(page.get('pageTypeId')), iMap())
    .get('label', '');

  const crawlingStatus = page.get('crawlingStatus');
  const importingInProgress = page.get('importingInProgress');
  const needToShowSpinner = crawlingInProgress(crawlingStatus) || importingInProgress;

  function handlePriorityChange({ value }) {
    onPageChangePriority(value);
  }

  const isBrandPage = pageParentType === 'brand';
  const canUseCategorizerInSubscription = subscriptionPolicies.getIn(['categorizer', 'canUse']);
  const isWithMetrics = isBrandPage && canUseCategorizerInSubscription;

  const pageItemClasses = classnames({
    'brand-page-item-component': true,
    'brand-page-item-component_is-open': isOpen,
    'brand-page-item-component_is-next': isNextAfterOpen,
    'brand-page-item-component_with-metrics': isWithMetrics,
    'brand-page-item-component_competitors': !isBrandPage,
    'brand-page-item-component_competitors-with-metrics':
      !isBrandPage && canUseCategorizerInSubscription,
  });

  const canUpdateAtp = policies.getIn(['atp', 'canUpdate']);

  return (
    <>
      <div className={pageItemClasses}>
        <div className="brand-page-item-component__item brand-page-item-component__item_center">
          <OpenCloseIcon isOpen={isOpen} pageId={Number(pageId)} pageParentType={pageParentType} />
        </div>
        <div className="brand-page-item-component__item brand-page-item-component__item_long-text">
          <Link
            className="text_two-lines-with-ellipsis-break-all brand-page-item-component__link"
            href={pageLink}
            rel="nofollow noopener noreferrer"
            target="_blank"
            title={pageLink}
          >
            {pageLink}
          </Link>
        </div>
        <div className="brand-page-item-component__item">{pageType}</div>
        {canUseCategorizerInSubscription && (
          <>
            {pageItemMetricValueField(page, 'countReferringDomains', needToShowSpinner)}
            {pageItemMetricValueField(page, 'linkioScore')}
            {isWithMetrics && pageItemMetricValueField(page, 'averageKeywordsRank')}
            {isWithMetrics && pageItemMetricValueField(page, 'competitorsCount')}
          </>
        )}
        <div className="brand-page-item-component__item brand-page-item-component__item_keywords">
          <KeywordsValueFormatterComponent
            isWithMetrics={isWithMetrics}
            keywords={pageKeywords}
            onClick={onClickKeywordsCell}
          />
        </div>
        {isBrandPage && (
          <div className="brand-page-item-component__item brand-page-item-component__item_priority">
            <BrandPagePriority
              pagePriority={pagePriority}
              canUpdateAtp={canUpdateAtp}
              handlePagePriorityChange={handlePriorityChange}
            />
          </div>
        )}
        <div className="brand-page-item-component__item brand-page-item-component__item_center">
          <PageActions
            page={page}
            pageChildType={pageChildType}
            pageParentId={pageParentId}
            pageParentType={pageParentType}
            policies={policies}
          />
        </div>
      </div>
      {isOpen && !isBrandPage && (
        <PageDls pageParentId={pageParentId} pageParentType={pageParentType} />
      )}
    </>
  );
};

PageItemComponent.propTypes = {
  isNextAfterOpen: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClickKeywordsCell: PropTypes.func,
  onPageChangePriority: PropTypes.func,
  page: brandPageShape,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  pageTypeOptions: optionsMap,
  policies: policiesShape,
  subscriptionPolicies: subscriptionPoliciesShape.isRequired,
};

export default PageItemComponent;
