import React from 'react';
import PropTypes from 'prop-types';

import {
  MAX_ALLOWED_FILTER_ROWS,
  getProperties,
  getPredicates,
  getValues,
  isNotBlankOrPresentFilter,
  isMultiSelect,
} from '../utils';

import FilterSelector from '../Select';

import { translate } from 'common/i18n';

import useClickOutsideCb from 'hooks/useClickOutsideCb';

import CrossIcon from 'common/icons/cross';

import './filters.scss';

const FiltersComponent = ({
  filters,
  filtersSpec,
  handleAddFilter,
  handleChangePredicate,
  handleChangeProperty,
  handleChangeValue,
  handleRemoveAllFilter,
  handleRemoveFilter,
  onClickOutside,
}) => {
  const ref = React.useRef(null);
  useClickOutsideCb(ref, onClickOutside);

  return (
    <div ref={ref} className="data-filter-filters">
      {filters.length === 0 && (
        <span className="data-filter-filters__empty-filter-text">
          {translate('uiComponents.dataFilters.emptyFilter')}
        </span>
      )}

      {filters.length > 0 &&
        filters.map((filter, filterIdx) => {
          const isPropertyPresent = filter.property;
          const isPropertyAndPredicatePresent = filter.property && filter.predicate;

          return (
            <div key={filterIdx} className="data-filter-filters__row capybara--row">
              <span
                className="data-filter-filters__cross-icon-wrapper"
                onClick={handleRemoveFilter(filterIdx)}
              >
                <CrossIcon />
              </span>
              <span className="data-filter-filters__queries-grouping">
                <span>
                  {translate(
                    `uiComponents.dataFilters.rowsGrouping.${
                      filterIdx === 0 ? 'where' : 'and'
                    }`,
                  )}
                </span>
              </span>

              {FilterSelector({
                className: 'capybara--property-name-selector',
                onChange: handleChangeProperty(filterIdx),
                options: getProperties(filtersSpec, filters),
                value: filter.property,
              })}

              {isPropertyPresent &&
                FilterSelector({
                  className: 'capybara--predicate-selector',
                  onChange: handleChangePredicate(filterIdx),
                  options: getPredicates(filtersSpec, filter),
                  value: filter.predicate,
                })}

              {isPropertyAndPredicatePresent &&
                isNotBlankOrPresentFilter(filter) &&
                FilterSelector({
                  className: 'capybara--value-selector',
                  isMultiSelect: isMultiSelect(filter),
                  onChange: handleChangeValue(filterIdx),
                  options: getValues(filtersSpec, filter),
                  value: filter.value,
                })}
            </div>
          );
        })}

      {filters.length < MAX_ALLOWED_FILTER_ROWS && (
        <button
          className="data-filter-filters__add-filter"
          onClick={handleAddFilter}
        >
          {translate('uiComponents.dataFilters.buttons.addFilter')}
        </button>
      )}

      {filters.length > 0 && (
        <button
          className="data-filter-filters__clear-all"
          onClick={handleRemoveAllFilter}
        >
          {translate('uiComponents.dataFilters.buttons.clearAll')}
        </button>
      )}
    </div>
  );
};

FiltersComponent.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape()),
  filtersSpec: PropTypes.shape(),
  handleAddFilter: PropTypes.func.isRequired,
  handleChangePredicate: PropTypes.func.isRequired,
  handleChangeProperty: PropTypes.func.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
  handleRemoveAllFilter: PropTypes.func.isRequired,
  handleRemoveFilter: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
};

export default FiltersComponent;
