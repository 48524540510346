export default () => {
  return {
    intersectAnalysis: {
      buttons: {
        runIntersectAnalysis: {
          label: 'Link Intersect Report',
          tooltip: 'See which referring domains your competitors have in common',
        },
      },
      csvFileName: 'link_intersect_report.csv',
    },
  };
};
