import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { eoCampaignStepsList } from 'common/propTypesShapes/eoCampaignSteps';

import Link from 'components_linkio/link';

import './eoCampaignStepsPreview.scss';

function buildEmailStepView({ htmlBody, subject, transformationError }) {
  return (
    <div className="email-step-preview">
      <span className="email-step-preview__title">
        {translate('eoCampaignStep.actionTypes.EoCampaignStepEmail')}
        {transformationError && (
          <span className="email-step-preview__transformation-error">{transformationError}</span>
        )}
      </span>
      <span className="email-step-preview__subject">“{subject}”</span>
      <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
    </div>
  );
}

function buildWaitStepView({ measure, value }) {
  return (
    <span className="wait-step-preview">
      {translate('eoCampaignPreview.stepsPreview.waitStepTitle')({ value, measure })}
    </span>
  );
}

const EoCampaignStepsPreviewComponent = ({ eoCampaignId, immutableEoCampaignStepsPreview }) => {
  const eoCampaignStepsPreview = immutableEoCampaignStepsPreview.toJS();

  return (
    <div className="eo-campaign-steps-preview">
      {eoCampaignStepsPreview.length > 0 && (
        <ul className="eo-campaign-steps-preview__steps-list">
          {eoCampaignStepsPreview.map((stepItem) => {
            const { actionType, position } = stepItem;

            return (
              <li key={stepItem.position} className="eo-campaign-steps-preview__steps-item">
                <span className="eo-campaign-steps-preview__step-position">
                  {translate('eoCampaignPreview.stepsPreview.stepPosition')(position)}
                </span>
                {actionType === 'EoCampaignStepEmail' && buildEmailStepView(stepItem)}
                {actionType === 'EoCampaignStepWait' && buildWaitStepView(stepItem)}
              </li>
            );
          })}
          <li className="eo-campaign-steps-preview__steps-item">
            <span className="eo-campaign-steps-preview__step-position">
              {translate('eoCampaignPreview.stepsPreview.stepEnd')}
            </span>
          </li>
        </ul>
      )}

      {eoCampaignStepsPreview.length === 0 && (
        <>
          <p className="eo-campaign-steps-preview__steps-blank-state">
            {translate('eoCampaignPreview.stepsPreview.blankState.text')}{' '}
            <Link
              className="text_link"
              to={Routes.eo_campaign_steps_path(eoCampaignId, { format: null })}
            >
              {translate('eoCampaignPreview.stepsPreview.blankState.link')}
            </Link>
            .
          </p>
        </>
      )}
    </div>
  );
};

EoCampaignStepsPreviewComponent.propTypes = {
  eoCampaignId: PropTypes.string,
  immutableEoCampaignStepsPreview: eoCampaignStepsList,
};

export default EoCampaignStepsPreviewComponent;
