import { translate } from '../common/i18n';

import {
  fetchUsersAsOptionsFailure,
  fetchUsersAsOptionsStart,
  fetchUsersAsOptionsSuccess,
  updateCurrentUserFailure,
  updateCurrentUserStart,
  updateCurrentUserSuccess,
} from '../actions/user_actions';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postFormDataToAPI,
  postToAPI,
  processError,
  processObjToCamelCase,
  putFormDataToAPI,
} from './utils';

async function signInAsUser(dispatch, formData) {
  try {
    const res = await postFormDataToAPI(Routes.user_session_path(), formData);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function signUpAsUser(dispatch, formData) {
  try {
    const res = await postFormDataToAPI(Routes.user_registration_path(), formData);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function setCurrentAccount(dispatch, marketerSlug) {
  try {
    const data = { marketer_slug: marketerSlug };
    const res = await postToAPI(Routes.users_set_current_account_path(), data);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function autoSetCurrentAccount(dispatch, marketerSlug) {
  try {
    const data = { marketer_slug: marketerSlug };
    const res = await postToAPI(Routes.users_auto_set_current_account_path(), data);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function forgotPassword(dispatch, formData) {
  try {
    const res = await postFormDataToAPI(Routes.user_password_path(), formData);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function resendConfirmation(dispatch, formData) {
  try {
    const res = await postFormDataToAPI(Routes.user_confirmation_path({ format: null }), formData);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function resendUnlock(dispatch, formData) {
  try {
    const res = await postFormDataToAPI(Routes.user_unlock_path({ format: null }), formData);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function resetPassword(dispatch, formData) {
  try {
    const res = await putFormDataToAPI(Routes.user_password_path(), formData);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function acceptInvitation(dispatch, formData) {
  try {
    const res = await putFormDataToAPI(Routes.users_invitation_path(), formData);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function updateEmailOrPassword(dispatch, formData) {
  try {
    const res = await putFormDataToAPI(Routes.user_registration_path(), formData);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function updateProfile(dispatch, formData) {
  try {
    const res = await putFormDataToAPI(Routes.users_update_profile_path(), formData);
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function deleteCurrentUserAccount(dispatch) {
  try {
    const res = await deleteToAPI(Routes.user_registration_path());
    return processObjToCamelCase(res);
  } catch (err) {
    return processError(err, undefined, dispatch);
  }
}

async function updateOnboarding(dispatch, module, state) {
  dispatch(updateCurrentUserStart());
  try {
    const res = await patchToAPI(Routes.onboarding_path(), { [module]: state });
    dispatch(updateCurrentUserSuccess(res));
    return res;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateCurrentUserFailure,
      translate('errors.cantUpdateCurrentUser'),
    );
  }
}

async function fetchUsersAsOptions(dispatch) {
  dispatch(fetchUsersAsOptionsStart());

  try {
    const resp = await getFromAPI(Routes.users_as_options_path());

    dispatch(fetchUsersAsOptionsSuccess(resp.users));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchUsersAsOptionsFailure,
      translate('errors.cantFetchUsersAsOptions'),
    );
  }
}

export {
  acceptInvitation,
  deleteCurrentUserAccount,
  fetchUsersAsOptions,
  forgotPassword,
  resendConfirmation,
  resendUnlock,
  resetPassword,
  setCurrentAccount,
  autoSetCurrentAccount,
  signInAsUser,
  signUpAsUser,
  updateEmailOrPassword,
  updateOnboarding,
  updateProfile,
};
