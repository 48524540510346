import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import cx from 'classnames';

import { eoDripListShape } from 'common/propTypesShapes/eoDripLists';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import Checkbox from 'components_linkio/Checkbox';
import InputComponent from 'components_linkio/input_component';
import RadioButton from 'components_linkio/RadioButton';
import Select from 'components_linkio/Select';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import BuyVerificationCreditsPopup from 'pages/EoEmailVerificationPage/BuyVerificationCreditsPopup';

import './eoDripListFormPopup.scss';

const initialState = {
  title: '',
  brandId: null,
  validateEmailAddresses: false,
  declineInvalidEmails: false,
  declineCatchAllEmails: false,
  declineUnknownEmails: false,
  declineProspectsBrandlyInvolved: false,
  declineProspectsAlreadyInvolved: false,
  errors: {},
};

function reducer(state, action) {
  const { value, type } = action;

  let newState = cloneDeep(state);

  switch (type) {
    case 'changeProspectInvolvedOption':
      newState = {
        ...newState,
        declineProspectsBrandlyInvolved: value === 'declineProspectsBrandlyInvolved',
        declineProspectsAlreadyInvolved: value === 'declineProspectsAlreadyInvolved',
      };
      break;
    case 'validateEmailAddresses': {
      const { declineInvalidEmails, declineCatchAllEmails, declineUnknownEmails } = newState;
      newState = {
        ...newState,
        validateEmailAddresses: value,
        declineInvalidEmails: !value ? false : declineInvalidEmails,
        declineCatchAllEmails: !value ? false : declineCatchAllEmails,
        declineUnknownEmails: !value ? false : declineUnknownEmails,
      };
      break;
    }
    default: {
      newState[type] = value;

      break;
    }
  }

  return newState;
}

const involvedProspectRadioButtons = [
  'declineProspectsBrandlyInvolved',
  'declineProspectsAlreadyInvolved',
];

const rejectEmailCheckboxes = [
  'declineInvalidEmails',
  'declineCatchAllEmails',
  'declineUnknownEmails',
];

const EoDripListFormPopupComponent = ({
  emailsValidationCreditsCount,
  onCancel,
  onSave,
  onSubmitCb,
  immutableBrandsOptions,
  eoDripList,
}) => {
  const [state, dispatch] = React.useReducer(reducer, eoDripList || initialState);

  const brandsOptions = immutableBrandsOptions.toJS();

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  function handleChangeProspectInvolvedOption(event) {
    dispatch({ type: 'changeProspectInvolvedOption', value: event.target.value });
  }

  const handleChangeSelect = (inputName) => (obj) => {
    dispatch({ type: inputName, value: obj ? obj.value : null });
  };

  const handleChangeCheckbox = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.checked });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const resp = await onSave(state);

    const { errors } = resp;

    dispatch({ type: 'errors', value: errors });

    if (isEmpty(resp.errors)) {
      onSubmitCb && onSubmitCb(resp);
      onCancel();
    }
  }

  const {
    title,
    brandId,
    declineProspectsBrandlyInvolved,
    declineProspectsAlreadyInvolved,
    validateEmailAddresses,
    errors,
  } = state;

  const rejectProspectsBasedOnValidationStyles = cx(
    'eo-drip-lists-form-popup__reject-prospects-by-validation',
    {
      'eo-drip-lists-form-popup__reject-prospects-by-validation_disabled': !validateEmailAddresses,
    },
  );

  return (
    <Modal show className="eo-drip-lists-form-popup">
      <Modal.Header>
        <Modal.Title>{translate('eoDripList.eoDripListFormPopup.title')(!!eoDripList)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form id="eo-drip-lists-form" className="eo-drip-lists-form-popup__form" noValidate>
          <div className="eo-drip-lists-form-popup__title-and-brand-wrapper">
            <InputComponent
              autoComplete="off"
              autoFocus
              isMandatory
              label={translate('eoDripList.eoDripListFormPopup.inputs.title.label')}
              message={{ type: 'error', text: errors.title }}
              onInputChange={setInputValue('title')}
              value={title}
            />

            <Select
              isClearable
              label={translate('uiComponents.brandAssign.selectLabel')}
              blurInputOnSelect={false}
              placeholder={translate('uiComponents.brandAssign.noBrandAssigned')}
              triggerChangeOnBlur={false}
              onChange={handleChangeSelect('brandId')}
              options={brandsOptions}
              value={brandsOptions.find(({ value }) => value === brandId)}
              message={{ type: 'error', text: errors.brandId }}
            />
          </div>

          <div className="eo-drip-lists-form-popup__email-verification-block">
            <Checkbox
              checked={validateEmailAddresses}
              onChange={handleChangeCheckbox('validateEmailAddresses')}
              message={{
                type: 'hint',
                text: translate(
                  'eoDripList.eoDripListFormPopup.checkboxes.validateEmailAddresses.hint',
                ),
              }}
            >
              {translate('eoDripList.eoDripListFormPopup.checkboxes.validateEmailAddresses.label')}
            </Checkbox>

            <div className="eo-drip-lists-form-popup__buy-credits">
              {translate('eoDripList.eoDripListFormPopup.buyCredits.emailsValidationCreditsCount')(
                emailsValidationCreditsCount,
              )}

              <ToggleShowPopup Popup={<BuyVerificationCreditsPopup />}>
                <span>{translate('eoDripList.eoDripListFormPopup.buyCredits.buyMoreCredits')}</span>
              </ToggleShowPopup>
            </div>

            <div className={rejectProspectsBasedOnValidationStyles}>
              <p>
                {translate(
                  'eoDripList.eoDripListFormPopup.rejectProspects.rejectProspectsBasedOnValidation',
                )}
              </p>

              <div className="eo-drip-lists-form-popup__checkboxes-wrapper">
                {rejectEmailCheckboxes.map((name) => (
                  <Checkbox
                    key={name}
                    disabled={!validateEmailAddresses}
                    checked={state[name]}
                    onChange={handleChangeCheckbox(name)}
                    message={{
                      type: 'hint',
                      text: translate(`eoDripList.eoDripListFormPopup.checkboxes.${name}.hint`),
                    }}
                  >
                    {translate(`eoDripList.eoDripListFormPopup.checkboxes.${name}.label`)}
                  </Checkbox>
                ))}
              </div>
            </div>
          </div>

          <p>{translate('eoDripList.eoDripListFormPopup.rejectProspects.rejectProspects')}</p>

          <div className="eo-drip-lists-form-popup__reject-prospect-options">
            {involvedProspectRadioButtons.map((name) => (
              <RadioButton
                key={name}
                checked={state[name]}
                value={name}
                onChange={handleChangeProspectInvolvedOption}
              >
                {translate(`eoDripList.eoDripListFormPopup.rejectProspects.${name}`)}
              </RadioButton>
            ))}

            <RadioButton
              checked={!declineProspectsBrandlyInvolved && !declineProspectsAlreadyInvolved}
              value={'n/a'}
              onChange={handleChangeProspectInvolvedOption}
            >
              {translate('notAvailable')}
            </RadioButton>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="eo-drip-lists-form-popup__btn-group">
          <div className="eo-drip-lists-form-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('eoDripList.eoDripListFormPopup.buttons.cancel')}
            </ButtonComponent>
          </div>

          <div className="eo-drip-lists-form-popup__btn">
            <SubmitButtonComponent
              form="eo-drip-lists-form"
              isGreen
              onClick={handleSubmit}
              text={translate('eoDripList.eoDripListFormPopup.buttons.submit')(!!eoDripList)}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EoDripListFormPopupComponent.propTypes = {
  eoDripList: eoDripListShape,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSubmitCb: PropTypes.func,
};

export default EoDripListFormPopupComponent;
