import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoCustomFieldsStart,
  fetchEoCustomFieldsSuccess,
  fetchEoCustomFieldsFailure,
  fetchEoCustomFieldStart,
  fetchEoCustomFieldSuccess,
  fetchEoCustomFieldFailure,
  createEoCustomFieldStart,
  createEoCustomFieldSuccess,
  createEoCustomFieldFailure,
  updateEoCustomFieldStart,
  updateEoCustomFieldSuccess,
  updateEoCustomFieldFailure,
  deleteEoCustomFieldsStart,
  deleteEoCustomFieldsSuccess,
  deleteEoCustomFieldsFailure,
} from 'actions/eoCustomFieldActions';

import { resetSelectedIds } from 'actions/ui_actions';
import { fetchEoCustomFieldsPagySuccess } from 'actions/pagy_actions';

import { translate } from 'common/i18n';

import { showSuccessMessage, showSuccessMessageFromTranslation } from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCase,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoCustomFields(dispatch, params) {
  dispatch(fetchEoCustomFieldsStart());

  try {
    const resp = await getFromAPI(Routes.eo_custom_fields_path(), processObjToSnakeCaseDeep(params));
    dispatch(fetchEoCustomFieldsSuccess(resp.eo_custom_fields));
    dispatch(fetchEoCustomFieldsPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoCustomFieldsFailure,
      translate('eoCustomField.messages.cantFetchEoCustomFields'),
    );
  }
}

export async function fetchEoCustomField(dispatch, eoCustomFieldId) {
  dispatch(fetchEoCustomFieldStart());

  try {
    const eoCustomField = await getFromAPI(Routes.eo_custom_field_path({ id: eoCustomFieldId }));
    dispatch(fetchEoCustomFieldSuccess(eoCustomField));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoCustomFieldFailure,
      translate('eoCustomField.messages.cantFetchEoCustomField'),
    );
  }
}

export async function createEoCustomField(dispatch, data) {
  dispatch(createEoCustomFieldStart());

  try {
    const resp = await postToAPI(Routes.eo_custom_fields_path(), eoCustomFieldObject(data));

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(createEoCustomFieldSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoCustomField.messages.createSuccess');
    } else {
      dispatch(createEoCustomFieldFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createEoCustomFieldFailure,
      translate('eoCustomField.messages.cantCreateEoCustomField'),
    );
  }
}

export async function updateEoCustomField(dispatch, data) {
  dispatch(updateEoCustomFieldStart());

  try {
    const resp = await patchToAPI(
      Routes.eo_custom_field_path({ id: data.id }),
      eoCustomFieldObject(data),
    );

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(updateEoCustomFieldSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoCustomField.messages.updateSuccess');
    } else {
      dispatch(updateEoCustomFieldFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoCustomFieldFailure,
      translate('eoCustomField.messages.cantUpdateEoCustomField'),
    );
  }
}

export async function deleteEoCustomFields(dispatch, ids) {
  dispatch(deleteEoCustomFieldsStart());

  try {
    await deleteToAPI(Routes.eo_custom_fields_path({ ids }));

    dispatch(resetSelectedIds());
    dispatch(deleteEoCustomFieldsSuccess(ids));

    showSuccessMessage(dispatch, translate('eoCustomField.messages.deleteSuccess')(ids.length));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoCustomFieldsFailure,
      translate('eoCustomField.messages.cantDeleteEoCustomFields'),
    );
  }
}

const eoCustomFieldObject = (data) => ({ eo_custom_field: processObjToSnakeCase(data) });
