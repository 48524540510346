const ADD_BACKLINKS__SUCCESS = 'ADD_BACKLINKS__SUCCESS';
const FETCH_BACKLINKS__FAILURE = 'FETCH_BACKLINKS__FAILURE';
const FETCH_BACKLINKS__START = 'FETCH_BACKLINKS__START';
const FETCH_BACKLINKS__SUCCESS = 'FETCH_BACKLINKS__SUCCESS';
const IMPORT_BACKLINKS_FROM_API__FAILURE = 'IMPORT_BACKLINKS_FROM_API__FAILURE';
const IMPORT_BACKLINKS_FROM_API__START = 'IMPORT_BACKLINKS_FROM_API__START';
const IMPORT_BACKLINKS_FROM_API__SUCCESS = 'IMPORT_BACKLINKS_FROM_API__SUCCESS';
const IMPORT_BACKLINKS_MANUALLY__FAILURE = 'IMPORT_BACKLINKS_MANUALLY__FAILURE';
const IMPORT_BACKLINKS_MANUALLY__START = 'IMPORT_BACKLINKS_MANUALLY__START';
const IMPORT_BACKLINKS_MANUALLY__SUCCESS = 'IMPORT_BACKLINKS_MANUALLY__SUCCESS';
const UPDATE_BACKLINK__FAILURE = 'UPDATE_BACKLINK__FAILURE';
const UPDATE_BACKLINK__SUCCESS = 'UPDATE_BACKLINK__SUCCESS';
const UPDATE_BACKLINKS__FAILURE = 'UPDATE_BACKLINKS__FAILURE';
const UPDATE_BACKLINKS__SUCCESS = 'UPDATE_BACKLINKS__SUCCESS';
const UPDATE_BACKLINKS_ROWS = 'UPDATE_BACKLINKS_ROWS';

function addBacklinksSuccess(brandId, backlinks) {
  return { type: ADD_BACKLINKS__SUCCESS, brandId, backlinks };
}

function fetchBacklinksStart() {
  return { type: FETCH_BACKLINKS__START };
}

function fetchBacklinksSuccess(brandId, backlinks) {
  return { type: FETCH_BACKLINKS__SUCCESS, brandId, backlinks };
}

function fetchBacklinksFailure(errors) {
  return { type: FETCH_BACKLINKS__FAILURE, errors };
}

function importBacklinksFromApiFailure(payload) {
  return { type: IMPORT_BACKLINKS_FROM_API__FAILURE, payload };
}

function importBacklinksFromApiStart(payload) {
  return { type: IMPORT_BACKLINKS_FROM_API__START, payload };
}

function importBacklinksFromApiSuccess(payload) {
  return { type: IMPORT_BACKLINKS_FROM_API__SUCCESS, payload };
}

function importBacklinksManuallyFailure(payload) {
  return { type: IMPORT_BACKLINKS_MANUALLY__FAILURE, payload };
}

function importBacklinksManuallyStart(payload) {
  return { type: IMPORT_BACKLINKS_MANUALLY__START, payload };
}

function importBacklinksManuallySuccess(payload) {
  return { type: IMPORT_BACKLINKS_MANUALLY__SUCCESS, payload };
}

function updateBacklinkFailure(errors) {
  return { type: UPDATE_BACKLINK__FAILURE, errors };
}

function updateBacklinkSuccess(brandId, backlink) {
  return { type: UPDATE_BACKLINK__SUCCESS, brandId, backlink };
}

function updateBacklinksFailure(errors) {
  return { type: UPDATE_BACKLINKS__FAILURE, errors };
}

function updateBacklinksSuccess(brandId, backlinks) {
  return { type: UPDATE_BACKLINKS__SUCCESS, brandId, backlinks };
}

function updateBacklinksRows(brandId, backlinks) {
  return { type: UPDATE_BACKLINKS_ROWS, brandId, backlinks };
}

export {
  ADD_BACKLINKS__SUCCESS,
  FETCH_BACKLINKS__FAILURE,
  FETCH_BACKLINKS__START,
  FETCH_BACKLINKS__SUCCESS,
  IMPORT_BACKLINKS_FROM_API__FAILURE,
  IMPORT_BACKLINKS_FROM_API__START,
  IMPORT_BACKLINKS_FROM_API__SUCCESS,
  IMPORT_BACKLINKS_MANUALLY__FAILURE,
  IMPORT_BACKLINKS_MANUALLY__START,
  IMPORT_BACKLINKS_MANUALLY__SUCCESS,
  UPDATE_BACKLINK__FAILURE,
  UPDATE_BACKLINK__SUCCESS,
  UPDATE_BACKLINKS__FAILURE,
  UPDATE_BACKLINKS__SUCCESS,
  UPDATE_BACKLINKS_ROWS,
  addBacklinksSuccess,
  fetchBacklinksFailure,
  fetchBacklinksStart,
  fetchBacklinksSuccess,
  importBacklinksFromApiFailure,
  importBacklinksFromApiStart,
  importBacklinksFromApiSuccess,
  importBacklinksManuallyFailure,
  importBacklinksManuallyStart,
  importBacklinksManuallySuccess,
  updateBacklinkFailure,
  updateBacklinkSuccess,
  updateBacklinksSuccess,
  updateBacklinksFailure,
  updateBacklinksRows,
};
