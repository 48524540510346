import React from 'react';

import EoCustomFieldFormPopup from '../EoCustomFieldFormPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const AddEoCustomFieldButtonComponent = () => {
  return (
    <ToggleShowPopup Popup={<EoCustomFieldFormPopup />}>
      <ButtonComponent className="eo-merge-tags-bulk-actions__add-eo_custom_field" isInitial isGreen>
        {translate('eoCustomField.bulkActions.addEoCustomField')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

export default AddEoCustomFieldButtonComponent;
