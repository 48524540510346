import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, Header, Body } from 'reactabular-table';
import classnames from 'classnames';
import { cloneDeep, isEqual } from 'lodash';

import BinIcon from '../../common/icons/bin';
import columnsGenerator from '../../common/tables/columns_generator';
import { policiesShape, proxiesMap } from '../../common/prop_types_shapes';
import { delayInWords } from '../../common/tables/utils';
import { checkboxColumn, textColumn } from '../../common/tables/columns';
import { translate } from '../../common/i18n';

import ButtonComponent from '../../components_linkio/button_component';
import Checkbox from '../../components_linkio/Checkbox';
import SpinnerComponent from '../../components_linkio/Spinner';
import confirmationDialogue from '../../components/confirmation_dialogue';

import './proxiesTableComponent.scss';


class ProxiesTableComponent extends Component {
  static propTypes = {
    checkAllProxiesInProgress: PropTypes.bool,
    onCheckNowClick: PropTypes.func.isRequired,
    onDeleteProxy: PropTypes.func.isRequired,
    policies: policiesShape.isRequired,
    proxies: proxiesMap.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      props,
      columns: this.generateColumns(),
      rows: props.proxies.valueSeq().toJS(),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const needToUpdState = !isEqual(nextProps, prevState.props);

    if (needToUpdState) {
      return {
        rows: nextProps.proxies.valueSeq().toJS(),
        props: nextProps,
      };
    }

    return null;
  }

  generateColumns = () => {
    const canDeleteProxy = this.props.policies.getIn(['proxy', 'canDestroy'], false);

    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [this.headerCheckboxFormatters],
        cellFormatters: [this.cellCheckboxFormatters],
        className: 'proxies-table-component__checkbox-column',
      }),
      textColumn({
        name: 'id',
        headerLabel: translate('proxy.id.title'),
        className: 'proxies-table-component__column',
      }),
      textColumn({
        name: 'fullProxy',
        headerLabel: translate('proxy.fullProxy.title'),
        cellValueFormatter: this.fullProxyFormatter,
        className: 'proxies-table-component__column',
      }),
      textColumn({
        name: 'useCounter',
        headerLabel: translate('proxy.useCounter.title'),
        className: 'proxies-table-component__column',
      }),
      textColumn({
        name: 'speed',
        headerLabel: translate('proxy.speed.title'),
        cellValueFormatter: this.speedToMs,
        className: 'proxies-table-component__column',
      }),
      textColumn({
        name: 'bannedByGoogle',
        headerLabel: translate('proxy.bannedByGoogle.title'),
        cellValueFormatter: this.boolToColouredString({ reverse: true }),
        className: 'proxies-table-component__column',
      }),
      textColumn({
        name: 'canVisitGoogle',
        headerLabel: translate('proxy.canVisitGoogle.title'),
        cellValueFormatter: this.boolToColouredString({ reverse: false }),
        className: 'proxies-table-component__column',
      }),
      textColumn({
        name: 'canVisitHttps',
        headerLabel: translate('proxy.canVisitHttps.title'),
        cellValueFormatter: this.boolToColouredString({ reverse: false }),
        className: 'proxies-table-component__column',
      }),
      textColumn({
        name: 'alive',
        headerLabel: translate('proxy.alive.title'),
        cellValueFormatter: this.aliveValueFormatter,
        className: 'proxies-table-component__column',
      }),
      textColumn({
        name: 'onlinePercentage',
        headerLabel: translate('proxy.onlinePercentage.title'),
        cellValueFormatter: this.calcOnlinePercentage,
        className: 'proxies-table-component__column',
      }),
      textColumn({
        name: 'delayInfo',
        headerLabel: translate('proxy.delayInfo.title'),
        cellValueFormatter: this.buildDelayInfo,
        className: 'proxies-table-component__column',
      }),
      textColumn({
        name: 'delete',
        headerLabel: translate('proxy.delete.title'),
        cellValueFormatter: canDeleteProxy ? this.deleteBtn : void 0,
        className: 'proxies-table-component__icon-column',
      }),
    ]);
  }

  headerCheckboxFormatters = () => {
    return (
      <Checkbox
        checked={this.isAllSelected()}
        onChange={this.handleOnToggleSelectAll}
      />
    );
  }

  cellCheckboxFormatters = (value, extra) => {
    const { isSelected, id } = extra.rowData;

    return (
      <Checkbox
        checked={isSelected}
        onChange={this.handleOnToggleSelectRow(id, isSelected)}
      />
    );
  }

  isAllSelected = () => {
    const { rows } = this.state;

    return rows.every((row) => row.isSelected);
  }

  handleOnToggleSelectAll = (event) => {
    const { rows } = this.state;

    const clonedRows = cloneDeep(rows);

    const headerCheckboxStatus = event.target.checked;

    clonedRows.forEach((row) => {
      if (row['isSelected'] !== headerCheckboxStatus) {
        row['isSelected'] = headerCheckboxStatus;
      }
    });

    this.setState({
      rows: clonedRows,
    });
  }

  handleOnToggleSelectRow = (rowId, isSelected) => () => {
    const { rows } = this.state;

    const clonedRows = cloneDeep(rows);

    clonedRows.forEach((row) => {
      if (row.id === rowId) {
        row['isSelected'] = !isSelected;
      }
    });

    this.setState({
      rows: clonedRows,
    });
  }

  fullProxyFormatter = (value, extra) => {
    const { protocol, credentials, ip } = extra.rowData;
    const isSmartProxy = ip === 'gate.smartproxy.com:7000';

    const textClassName = classnames({
      "proxies-table-component__cell-text": true,
      "proxies-table-component__cell-text_green": isSmartProxy,
    });

    return (
      <span className={textClassName}>
        {`${protocol}://${credentials}@${ip}`}
      </span>
    );
  }

  aliveValueFormatter = (value, extra) => {
    const { checkingInProgress } = extra.rowData;
    if (!checkingInProgress) {
      return this.boolToColouredString({ reverse: false })(value);
    }

    return (
      <div className="proxies-table-component__spinner_wrapper"><SpinnerComponent isBlack /></div>
    );
  }

  speedToMs = (value) => {
    if (!value) {
      return translate('proxy.speed.isOfflineStatus');
    }
    const speed = (Number.parseFloat(value) * 1000).toFixed(0);

    return `${speed}ms`;
  }

  boolToColouredString = ({ reverse }) => (value) => {
    const boolValue = !!value;

    // this allows to mark 'dangerous' (like `bannedByGoogle: true`) values with red colour
    const smartValue = reverse ? !boolValue : boolValue;

    const textClassName = classnames({
      "proxies-table-component__cell-text": true,
      "proxies-table-component__cell-text_red": !smartValue,
      "proxies-table-component__cell-text_green": smartValue,
    });

    return (
      <span className={textClassName}>
        {translate('proxy.boolToString')(value)}
      </span>
    );
  }

  calcOnlinePercentage = (_value, extra) => {
    const { rowData: { offlineCounter, checksCounter } } = extra;
    const offlineCounterInt = Number.parseInt(offlineCounter);
    const checksCounterInt = Number.parseInt(checksCounter);
    if (checksCounterInt === 0) {
      return translate('proxy.onlinePercentage.notCheckedYetStatus');
    }
    return `${(100 - ((offlineCounterInt / checksCounterInt) * 100)).toFixed(0)}%`;
  }

  buildDelayInfo = (_value, extra) => {
    const { rowData: { attemptNumber, delayedAt, delayedForInSeconds } } = extra;
    const delayInfoInWords = delayInWords(delayedAt, delayedForInSeconds);

    if (!delayInfoInWords) {
      return void 0;
    }

    return translate('proxy.delayInfo.nextTryDescription')(attemptNumber, delayInfoInWords);
  }

  deleteBtn = (_value, extra) => {
    const { rowData: { id } } = extra;

    return (
      <BinIcon className="proxies-table-component__bin-icon" onClick={this.handleDeleteProxy([id])} />
    );
  }

  handleDeleteProxy = (ids) => () => {
    confirmationDialogue({
      body: translate('confirmations.deleteProxy.body'),
      onConfirm: this.handleConfirmDeleteProxy(ids),
    });
  }

  handleConfirmDeleteProxy = (ids) => async () => this.props.onDeleteProxy(ids);

  handleCheckNowClick = () => this.props.onCheckNowClick();

  render() {
    const { checkAllProxiesInProgress, policies } = this.props;
    const { columns, rows } = this.state;

    const canCheckAll = policies.getIn(['proxy', 'canCheckAll'], false);

    const selectedProxiesIds = rows.filter((row) => row.isSelected).map((row) => row.id);
    const canUseBulkDelete = selectedProxiesIds.length > 0;

    return (
      <div className="proxies-table-component">
        <ButtonComponent
          className="proxies-table-component__bulk_delete_button"
          isDisabled={!canUseBulkDelete}
          isInitial
          isRed
          onClick={this.handleDeleteProxy(selectedProxiesIds)}
        >
          <BinIcon />
        </ButtonComponent>
        <Provider
          className="proxies-table-component__table"
          columns={columns}
        >
          <Header />
          <Body
            rows={rows}
            rowKey="id"
          />
        </Provider>
        {canCheckAll &&
          <ButtonComponent
            isGreen
            isLoading={checkAllProxiesInProgress}
            onClick={this.handleCheckNowClick}
            className="proxies-table-component__check_now_btn"
          >
            {translate('proxy.buttons.checkNow')}
          </ButtonComponent>
        }
      </div>
    );
  }
}

export default ProxiesTableComponent;
