export default {
  accountDashboard: {
    onboarding: {
      title: 'Welcome to your new account',
    },
    title: 'Dashboard',
    tutorialPopup: {
      title: 'First Time Setup Overview',
    },
  },
};
