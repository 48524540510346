import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  notificationsList,
  pagyShape,
} from '../../common/prop_types_shapes';

import Pager from '../../components/Pager';
import NotificationItem from './NotificationItem';
import NotificationsMarkAllAsRead from './NotificationsMarkAllAsRead';

import './notificationsPage.scss';


function createdAtToDate(createdAt) {
  return moment(new Date(createdAt).toISOString()).format('MMMM D, YYYY');
}

function groupNotificationsByDate(notifications) {
  return notifications.groupBy((item) => {
    const createdAt = item.get('createdAt');

    return createdAtToDate(createdAt);
  });
}

const NotificationsPageComponent = ({
  notifications,
  notificationsPagy,
  onNotificationsPagerClick,
  onNotificationsPagerItemsSelectChange,
}) => {
  const notificationsGroupedByDate = groupNotificationsByDate(notifications);

  return (
    <div className="notifications-page__notifications-wrapper">
      <NotificationsMarkAllAsRead />
      {
        notificationsGroupedByDate.keySeq().map((createdAt) => {
          return (
            <div key={createdAt} className="notifications-page__grouped-notifications-wrapper">
              <div className="notifications-page__date">
                {createdAtToDate(createdAt)}
              </div>
              {notificationsGroupedByDate.get(createdAt).map((notification) => {
                return (
                  <NotificationItem
                    key={notification.get('id')}
                    notification={notification}
                  />
                );
              })}
            </div>
          );
        })
      }
      <Pager
        handleItemsSelectChange={onNotificationsPagerItemsSelectChange}
        handlePagesControlClick={onNotificationsPagerClick}
        pagerType="notificationsPager"
        pagy={notificationsPagy}
      />
    </div>
  );
};

NotificationsPageComponent.propTypes = {
  notifications: notificationsList,
  notificationsPagy: pagyShape,
  onNotificationsPagerClick: PropTypes.func.isRequired,
  onNotificationsPagerItemsSelectChange: PropTypes.func.isRequired,
};


export default NotificationsPageComponent;
