import { handleAPIError } from '../utils';

import {
  pageAddHeadDataFailure,
  pageAddHeadDataSuccess,
  updateDraftBrandPageFailure,
  updateDraftBrandPageSuccess,
} from 'actions/bulkPagesSetupActions';

import {
  updateDraftCompetitorPageFailure,
  updateDraftCompetitorPageSuccess,
} from 'actions/bulkCompetitorsSetupActions';

import { translate } from 'common/i18n';

const handlePageAddHeadData = (dispatch, data) => {
  const { page, pageAddHeadData } = data;

  switch (pageAddHeadData) {
    case 'finished':
      handlePageAddHeadDataSuccess(dispatch, page);
      pageAddHeadDataSuccess(page.id);
      break;
    case 'failure':
      handlePageAddHeadDataFailure(dispatch, page);
      break;
  }
};

const handlePageAddHeadDataSuccess = (dispatch, page = {}) => {
  const isBrandPage = !page.brand_page_id;

  if (isBrandPage) {
    dispatch(updateDraftBrandPageSuccess(page));
  } else {
    dispatch(updateDraftCompetitorPageSuccess(page));
  }
};

const handlePageAddHeadDataFailure = (dispatch, page = {}) => {
  const error = ((page || {}).errors || {}).base;

  const isBrandPage = !page.brand_page_id;

  if (isBrandPage) {
    handleAPIError(
      dispatch,
      { message: error },
      updateDraftBrandPageFailure,
      translate('errors.cantUpdateDraftBrandPage'),
    );
  } else {
    handleAPIError(
      dispatch,
      { message: error },
      updateDraftCompetitorPageFailure,
      translate('errors.cantUpdateDraftCompetitorPage'),
    );
  }

  dispatch(pageAddHeadDataFailure(error, page.id));
};

export default handlePageAddHeadData;
