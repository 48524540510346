import { fromJS } from 'immutable';

// service returns object containing all parameters of destination link crawling status
// which we use in different front-end views
const crawlersStatusService = (destinationLink) => {
  const dl = fromJS(destinationLink);

  const status = dl.get('crawlingStatus');
  const backlinkCrawledAt = dl.get('backlinkCrawledAt');
  const googleIndexedCrawledAt = dl.get('googleIndexedCrawledAt');

  const crawledAtDates = [
    Date.parse(backlinkCrawledAt),
    Date.parse(googleIndexedCrawledAt),
  ];

  return {
    status,
    lastCheck: crawledAtDates.every((date) => date !== null) && Math.max(...crawledAtDates),
  };
};

export default crawlersStatusService;
