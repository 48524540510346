import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoProgressionsTableComponent from './EoProgressionsTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { currentIdSelector, uiSelector } from 'selectors';
import { eoProgressionsList } from 'common/propTypesShapes/eoProgressions';
import {
  eoProgressionsByCampaignIdSelector,
  eoProgressionsPagySelector,
} from 'selectors/eoProgressionsSelector';
import { fetchEoProgressions } from 'api/eoProgression';
import { resetSelectedIds, toggleSelectedIds } from 'actions/ui_actions';
import { pagyShape, selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';
import { policiesSelector } from 'selectors/railsContextSelectors';

const EoProgressionsTableContainer = ({
  canUpdate,
  changeQueryParams,
  dispatch,
  eoCampaignId,
  eoProgressions,
  eoProgressionsPagy,
  queryParams,
  selectedIds,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const { pageNumber = 1, pagyItemsCount = 100, sorting = {}, filters = {} } = queryParams;

  React.useEffect(() => {
    async function fetchData() {
      const fetchingParams = {
        items: pagyItemsCount,
        page: pageNumber,
        eoCampaignId,
        filters,
        sorting,
      };

      setIsFetching(true);
      await fetchEoProgressions(dispatch, fetchingParams);
      setIsFetching(false);
    }

    fetchData();
  }, [
    dispatch,
    eoCampaignId,
    pageNumber,
    pagyItemsCount,
    sorting.sortBy,
    sorting.sortingOrder,
    JSON.stringify(filters),
  ]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectedIds());
    };
  }, [dispatch, eoCampaignId]);

  function handleToggleSelectedRows(eoProgressionsIds, isSelected) {
    dispatch(toggleSelectedIds(eoProgressionsIds, isSelected));
  }

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  const eoProgressionsCount = eoProgressionsPagy.get('count');

  return (
    <>
      {eoProgressions.size > 0 && (
        <EoProgressionsTableComponent
          canUpdate={canUpdate}
          onTableHeaderClick={handleTableHeaderClick}
          onToggleSelectedRows={handleToggleSelectedRows}
          eoProgressions={eoProgressions}
          eoProgressionsCount={eoProgressionsCount}
          selectedIds={selectedIds}
          sorting={sorting}
        />
      )}
      {eoProgressions.size === 0 && (
        <BlankStateCtaComponent isLoading={isFetching}>
          <TitleText>
            <strong>{translate('eoProgression.table.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('eoProgression.table.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

EoProgressionsTableContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoProgressions: eoProgressionsList,
  eoProgressionsPagy: pagyShape,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoProgressions = eoProgressionsByCampaignIdSelector(state, eoCampaignId);
  const eoProgressionsPagy = eoProgressionsPagySelector(state);
  const policies = policiesSelector(state);
  const canUpdate = policies.getIn(['emailOutreach', 'canUpdate']);

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    canUpdate,
    eoCampaignId,
    eoProgressions,
    eoProgressionsPagy,
    selectedIds,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoProgressionsTableContainer);
