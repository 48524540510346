import React from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import MarketersTableComponent from '../marketers_table_component';
import { marketerShape, policiesShape, userShape } from '../../../common/prop_types_shapes';

import Link from 'components_linkio/link';

import './choose_marketer_form_component.scss';

const ChooseMarketerFormComponent = (props) => {
  const canCreateMarketer = props.policies.getIn(['marketer', 'canCreate']);

  return (
    <div className="page__wrapper">
      <div className="choose-marketer-component">
        <h2 className="choose-marketer-component__text choose-marketer-component__text_title">
          {translate('authForms.chooseMarketer.title')}
        </h2>
        <MarketersTableComponent {...props} />
        {canCreateMarketer &&
          <Link to={Routes.new_marketer_path({ format: null })}>
            {translate('authForms.chooseMarketer.buttons.createNewMarketer')}
          </Link>
        }
      </div>
    </div>
  );
};

ChooseMarketerFormComponent.propTypes = {
  currentUser: userShape,
  marketers: PropTypes.arrayOf(marketerShape),
  onMarketerClickAction: PropTypes.func,
  policies: policiesShape,
};

export default ChooseMarketerFormComponent;
