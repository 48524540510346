import { fromJS } from 'immutable';

import {
  FETCH_KEYWORDS_WITH_CHANGES__SUCCESS,
  FETCH_KEYWORDS_WITH_CHANGES_BY_URL__SUCCESS,
  RESET_RANK_TRACKER_FETCHING_STATES,
  SET_RANK_TRACKER_FILTERS,
} from '../actions/rankTrackerActions';

import {
  camelizeObject,
  camelizeObjectDeep,
} from './utils';

const initialState = fromJS({
  fetchingStates: {
    isKeywordsWithChangesByUrlFetched: false,
    isKeywordsWithChangesFetched: false,
  },
  filters: {
    groupByUrl: false,
    searchTerm: '',
  },
  keywordsWithChanges: {},
  keywordsWithChangesByUrl: {},
});

export default function rankTrackerReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_KEYWORDS_WITH_CHANGES__SUCCESS: {
      const { brandId, keywordsWithChanges } = action;

      state = state.setIn(['keywordsWithChanges', String(brandId)], fromJS(keywordsWithChanges.map(camelizeObject)));
      state = state.setIn(['fetchingStates', 'isKeywordsWithChangesFetched'], true);

      break;
    }
    case FETCH_KEYWORDS_WITH_CHANGES_BY_URL__SUCCESS: {
      const { brandId, keywordsWithChangesByUrl } = action;

      state = state.setIn(['keywordsWithChangesByUrl', String(brandId)], fromJS(keywordsWithChangesByUrl.map(camelizeObjectDeep)));
      state = state.setIn(['fetchingStates', 'isKeywordsWithChangesByUrlFetched'], true);

      break;
    }
    case RESET_RANK_TRACKER_FETCHING_STATES: {
      state = state.setIn(['fetchingStates', 'isKeywordsWithChangesByUrlFetched'], false);
      state = state.setIn(['fetchingStates', 'isKeywordsWithChangesFetched'], false);

      break;
    }
    case SET_RANK_TRACKER_FILTERS: {
      const { params } = action;

      state = state.mergeIn(['filters'], fromJS(params));

      break;
    }
  }

  return state;
}
