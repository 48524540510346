import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import RankTrackerChartsComponent from './RankTrackerChartsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { RANK_TRACKER_DEFAULT_DATE_RANGE } from 'utils/constants';
import { currentIdSelector } from 'selectors';
import { brandChartMetricsMap } from 'common/propTypesShapes/metrics';
import { brandChartMetricsByBrandIdSelector } from 'selectors/brandChartsSelector';
import { fetchBrandsChartMetrics } from 'api/brandsCharts';

const RankTrackerChartsContainer = ({
  brandChartMetrics,
  brandId,
  dispatch,
  queryParams: { filters = {} },
}) => {
  const dateStart = filters.dateStart || RANK_TRACKER_DEFAULT_DATE_RANGE.dateStart;
  const dateEnd = filters.dateEnd || RANK_TRACKER_DEFAULT_DATE_RANGE.dateEnd;

  React.useEffect(() => {
    fetchBrandsChartMetrics(dispatch, [brandId], dateStart, dateEnd);
  }, [brandId, dateStart, dateEnd]);

  return (
    <>
      {brandChartMetrics.get('brandId') && (
        <RankTrackerChartsComponent brandChartMetrics={brandChartMetrics.toJS()} />
      )}
    </>
  );
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);

  const brandChartMetrics = brandChartMetricsByBrandIdSelector(state, brandId);

  return {
    brandChartMetrics,
    brandId,
  };
}

RankTrackerChartsContainer.propTypes = {
  brandChartMetrics: brandChartMetricsMap,
  brandId: PropTypes.string,
  dispatch: PropTypes.func,
  ...withQueryParamsPropTypes,
};

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(RankTrackerChartsContainer);
