import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConnectStoreHOC from '../../../startup/connect_store_hoc';

import {
  uiSelector,
} from '../../../selectors';

import {
  unreadNotificationsCountSelector,
} from '../../../selectors/notificationSelectors';

import {
  fetchRecentNotifications,
  fetchUnreadNotificationsCount,
} from '../../../api/notification';

import { trackHelpcrunchEvent } from '../../../common/utils';

import {
  toggleNotificationsPopup,
} from '../../../actions/notificationActions';

import NotificationsButtonComponent from './NotificationsButtonComponent';


class NotificationsButtonContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    showNotificationsPopup: PropTypes.bool,
    unreadCount: PropTypes.number,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchUnreadNotificationsCount(dispatch);
  }

  handleToggleNotificationsButtonClick = () => {
    trackHelpcrunchEvent('click.btn.notifications');
    const { dispatch, showNotificationsPopup } = this.props;

    // start fetching of recent notifications as early as possible
    if (!showNotificationsPopup) {
      fetchRecentNotifications(dispatch);
    }

    dispatch(toggleNotificationsPopup());
  }

  render() {
    const { unreadCount } = this.props;

    return (
      <NotificationsButtonComponent
        onToggleNotificationsButtonClick={this.handleToggleNotificationsButtonClick}
        unreadCount={unreadCount}
      />
    );
  }
}

function select(state, ownProps) {
  const uiStates = uiSelector(state);
  const showNotificationsPopup = uiStates.getIn(['openClose', 'notifications', 'showNotificationsPopup']);
  const unreadCount = unreadNotificationsCountSelector(state, ownProps) || 0;

  return {
    showNotificationsPopup,
    unreadCount,
  };
}

export default ConnectStoreHOC(connect(select)(NotificationsButtonContainer));
