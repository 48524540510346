import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { subscriptionPlansSelector } from 'selectors';

import {
  currentMarketerSelector,
  currentSubscriptionSelector,
  currentUserSelector,
  stripeKeySelector,
} from 'selectors/railsContextSelectors';

import {
  marketerShape,
  subscriptionPlansMap,
  subscriptionShape,
  withRouterPropTypes,
} from 'common/prop_types_shapes';

import { changeSubscriptionPlan, createCheckoutSession } from 'api/subscription_plan';

import {
  openSwitchSubscriptionPlanPopup,
  closeSubscriptionLimitReachedPopup,
} from 'actions/popup_actions';

import { translate } from 'common/i18n';

import confirmationDialogue from 'components/confirmation_dialogue';
import ButtonComponent from 'components_linkio/button_component';

import { subscriptionPlanIsForbiddenToSwitchTo } from 'components/subscriptions/utils';

import './stripePayBtn.scss';

class StripePayBtnContainer extends React.PureComponent {
  static propTypes = {
    ...withRouterPropTypes,
    availability: PropTypes.shape({
      isAvailable: PropTypes.bool,
      rejectionReason: PropTypes.string,
    }).isRequired,
    cmarketer: marketerShape,
    dispatch: PropTypes.func.isRequired,
    stripeKey: PropTypes.string.isRequired,
    subscriptionPlan: subscriptionShape,
    subscriptionPlans: subscriptionPlansMap,
    withUpgradeText: PropTypes.bool,
  };

  doImmediateCheckout = async () => {
    const {
      cmarketer,
      dispatch,
      isContinueTrial,
      location: { pathname },
      stripeKey,
      subscriptionPlan,
    } = this.props;

    const selectedPlanId = subscriptionPlan.get('id');
    const hasCard = cmarketer.get('has_card', false);
    if (hasCard) {
      await changeSubscriptionPlan(dispatch, selectedPlanId, isContinueTrial);

      this.props.dispatch(closeSubscriptionLimitReachedPopup());

      return;
    }

    const checkoutSession = await createCheckoutSession(dispatch, pathname, selectedPlanId, false);
    // eslint-disable-next-line no-undef
    const stripe = Stripe(stripeKey);
    stripe.redirectToCheckout({ sessionId: checkoutSession.id });
  };

  doDelayedCheckout = () => {
    const { availability, subscriptionPlan, subscriptionPlans } = this.props;
    const selectedPlanId = subscriptionPlan.get('id');

    const isAvailable = availability.get('isAvailable', false);
    if (!isAvailable) {
      this.handleAttemptToSwitchToUnavailablePlan();
      return;
    }

    const isFreePlanSelected = subscriptionPlans.some((subscriptionPlan) => {
      return !subscriptionPlan.get('paid') && subscriptionPlan.get('id') === selectedPlanId;
    });

    if (isFreePlanSelected) {
      this.props.dispatch(openSwitchSubscriptionPlanPopup({ selectedPlanId }));
      return;
    }

    this.doImmediateCheckout();
  };

  handleAttemptToSwitchToUnavailablePlan = () => {
    const { availability, subscriptionPlan } = this.props;
    const rejectionReason = availability.get('rejectionReason');
    const allowedBrandsCount = subscriptionPlan.get('brandsCount');
    const allowedEoInboxesCount = subscriptionPlan.get('eoInboxesCount');
    const allowedTrackedKeywordsCount = subscriptionPlan.get('trackedKeywordsCount');
    const body = translate(`confirmations.attemptToChangeToUnavailablePlan.body`, {
      rejectionReason,
      allowedBrandsCount,
      allowedEoInboxesCount,
      allowedTrackedKeywordsCount,
    });
    confirmationDialogue({
      body,
      confirmLabel: translate('confirmations.attemptToChangeToUnavailablePlan.buttons.cancel'),
      hasCancelBtn: false,
      title: translate('confirmations.attemptToChangeToUnavailablePlan.title'),
    });
  };

  handleButtonClick = () => {
    const { availability, subscriptionPlan, currentSubscription } = this.props;

    const isAvailable =
      currentSubscription.get('status') === 'trialing' || availability.get('isAvailable');
    const isNotFreeSubscriptionPlan = !['free_monthly', 'free_yearly'].includes(
      subscriptionPlan.get('slug'),
    );

    const checkoutImmediately = isAvailable && isNotFreeSubscriptionPlan;

    checkoutImmediately ? this.doImmediateCheckout() : this.doDelayedCheckout();
  };

  buildButtonText = (isDisabled) => {
    const { withUpgradeText, isContinueTrial } = this.props;

    if (withUpgradeText) {
      return translate('payBtnComponent.withUpgradeText');
    }

    if (isContinueTrial) {
      return translate('payBtnComponent.updateTrial');
    }

    return isDisabled
      ? translate('payBtnComponent.myPlan')
      : translate('payBtnComponent.choosePlan');
  };

  buildProductDescription = () => {
    const { subscriptionPlan } = this.props;

    const price = subscriptionPlan.get('price');
    const billingInterval = subscriptionPlan.get('billingInterval');
    const title = subscriptionPlan.get('title');

    return translate('payBtnComponent.productDescription', {
      billingInterval,
      price,
      title,
    });
  };

  render() {
    const { availability, subscriptionPlan, currentSubscription } = this.props;
    const isTrialing = currentSubscription.get('status') === 'trialing';
    const isDisabled =
      !isTrialing && (subscriptionPlanIsForbiddenToSwitchTo(availability) || !subscriptionPlan);

    const isLoading = !subscriptionPlan;
    const buttonText = this.buildButtonText(isDisabled);

    return (
      <ButtonComponent
        className="stripe-pay-btn"
        isGreen
        isDisabled={isDisabled}
        onClick={this.handleButtonClick}
        isLoading={isLoading}
      >
        {buttonText}
      </ButtonComponent>
    );
  }
}

function select(state, ownProps) {
  const cmarketer = currentMarketerSelector(state, ownProps);
  const cuser = currentUserSelector(state, ownProps);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const stripeKey = stripeKeySelector(state, ownProps);
  const subscriptionPlans = subscriptionPlansSelector(state, ownProps);

  return {
    cmarketer,
    currentSubscription,
    cuser,
    stripeKey,
    subscriptionPlans,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(StripePayBtnContainer)));
