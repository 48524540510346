import { List as iList } from 'immutable';

import {
  RESET_SELECTED_IDS,
  TOGGLE_SELECTED_IDS,
} from '../../actions/ui_actions';

const initialState = iList();


export default function selectedIdsReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_SELECTED_IDS: {
      state = initialState;

      break;
    }
    case TOGGLE_SELECTED_IDS: {
      const { selectedIds, isSelected } = action;

      if (isSelected) {
        state = state.push(...selectedIds);
      } else {
        state = state.filter((id) => !selectedIds.includes(id));
      }

      break;
    }
  }

  return state;
}
