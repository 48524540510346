import {
  fetchAllNotifications,
} from '../../../api/notification';


const DEFAULT_PAGY_ITEMS_COUNT = 50;


function buildFetchingConfig(that, pagyValues) {
  const { notificationsPagy } = that.props;

  const pagyItemsCount = pagyValues.pagyItemsCount || notificationsPagy.get('items');
  const pageNumber = pagyValues.pageNumber || notificationsPagy.get('page');

  const fetchingConfig = {
    pagyItemsCount,
    pageNumber,
  };

  return fetchingConfig;
}

// Experimental.
// This service accepts 'that' agrument, which refers to 'this' in parent component.
// By doing so we make context passing less verbose (good) and less readable (bad).
function smartFetchNotificationsService(that, pagyValues = {}) {
  const { dispatch } = that.props;
  const {
    pageNumber,
    pagyItemsCount,
  } = buildFetchingConfig(that, pagyValues);

  const data = {
    items: pagyItemsCount || DEFAULT_PAGY_ITEMS_COUNT,
    page: pageNumber || 1,
  };

  fetchAllNotifications(dispatch, data);
}

export default smartFetchNotificationsService;
