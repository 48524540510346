import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

const NewsletterSettingsUnsubscribeComponent = ({
  isSubscribed,
  onCancel,
  onUnsubscribe,
}) => {
  if (!isSubscribed) {
    return (
      <div className="newsletter-settings-page page__wrapper">
        <div className="unsubscribe-page__no-active-subscriptions">
          {translate('newsletterSettings.unsubscribe.title.noSubscriptions')}
        </div>
      </div>
    );
  }

  return (
    <div className="newsletter-settings-page page__wrapper">
      <div className="unsubscribe-page">
        <h1>{translate('newsletterSettings.unsubscribe.title.clickToUnsubscribe')}</h1>

        <div className="unsubscribe-page__btn-group">
          <div className="unsubscribe-page__btn">
            <ButtonComponent isRed onClick={onUnsubscribe}>
              {translate('newsletterSettings.unsubscribe.buttons.unsubscribe')}
            </ButtonComponent>
          </div>

          <div className="unsubscribe-page__btn unsubscribe-page__btn_long">
            <ButtonComponent isGreen onClick={onCancel}>
              {translate('newsletterSettings.unsubscribe.buttons.cancel')}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

NewsletterSettingsUnsubscribeComponent.propTypes = {
  isSubscribed: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
};

export default NewsletterSettingsUnsubscribeComponent;
