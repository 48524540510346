import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EoCampaignStepsListComponent from './EoCampaignStepsPreviewComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector } from 'selectors';
import { sendTestEmail } from 'api/eoCampaignPreview';
import { eoCampaignStepsList } from 'common/propTypesShapes/eoCampaignSteps';
import { eoCampaignStepsPreviewSelector } from 'selectors/eoCampaignStepsPreviewSelector';

const EoCampaignStepsPreviewContainer = ({ dispatch, eoCampaignId, eoCampaignStepsPreview }) => {
  function onSendEmails(eoInboxId, prospectId) {
    sendTestEmail(dispatch, eoCampaignId, eoInboxId, prospectId);
  }

  return (
    <EoCampaignStepsListComponent
      eoCampaignId={eoCampaignId}
      immutableEoCampaignStepsPreview={eoCampaignStepsPreview}
      onSendEmails={onSendEmails}
    />
  );
};

EoCampaignStepsPreviewContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoCampaignStepsPreview: eoCampaignStepsList,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoCampaignStepsPreview = eoCampaignStepsPreviewSelector(state);

  return {
    eoCampaignId,
    eoCampaignStepsPreview,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(EoCampaignStepsPreviewContainer)));
