import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import RecheckRankingsButtonComponent from './RecheckRankingsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { recheckKeywordsRankings } from 'api/rankTracker';
import { fetchRailsContext } from 'api/rails_context';
import { currentIdSelector } from 'selectors';
import { currentBrandContextSelector } from 'selectors/brandsContextSelector';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';

import {
  keywordsWithChangesByUrlSelector,
  keywordsWithChangesSelector,
  rankTrackerFiltersSelector,
} from 'selectors/rankTrackerSelector';

import {
  brandContextShape,
  keywordsWithChangesByUrlList,
  keywordsWithChangesList,
  rankTrackerFiltersMap,
  subscriptionPoliciesShape,
} from 'common/prop_types_shapes';

const RecheckRankingsButtonContainer = ({
  brand,
  brandId,
  dispatch,
  keywordsWithChanges,
  keywordsWithChangesByUrl,
  rankTrackerFilters,
  subscriptionPolicies,
}) => {
  // componentDidMount analogue
  // we need railsContext here to get updated value of subscriptionPolicies
  useEffect(() => {
    fetchRailsContext(dispatch);
  }, []);

  const groupByUrl = rankTrackerFilters.get('groupByUrl');

  function handleClick() {
    recheckKeywordsRankings(dispatch, brandId);
  }

  function defineLoadingState() {
    const loadingState = (list) => list.some((keyword) => keyword.get('dataforseoRankInProgress'));

    if (groupByUrl) {
      return keywordsWithChangesByUrl.some((keywordWithChangesByUrl) => {
        return loadingState(keywordWithChangesByUrl.get('keywordsObjects'));
      });
    }

    return loadingState(keywordsWithChanges);
  }

  const isDisabledBySubscription = !subscriptionPolicies.getIn(
    ['checkedKeyword', 'canCheck'],
    false,
  );

  const isDisabledByKeywords = !brand.get('hasTrackedKeywords');

  const isLoading = defineLoadingState();

  return (
    <RecheckRankingsButtonComponent
      isDisabledByKeywords={isDisabledByKeywords}
      isDisabledBySubscription={isDisabledBySubscription}
      isLoading={isLoading}
      onClick={handleClick}
    />
  );
};

RecheckRankingsButtonContainer.propTypes = {
  brand: brandContextShape,
  brandId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  keywordsWithChanges: keywordsWithChangesList,
  keywordsWithChangesByUrl: keywordsWithChangesByUrlList,
  rankTrackerFilters: rankTrackerFiltersMap,
  subscriptionPolicies: subscriptionPoliciesShape,
};

function select(state, ownProps) {
  const brand = currentBrandContextSelector(state, ownProps);
  const brandId = currentIdSelector(state, ownProps);

  const keywordsWithChanges = keywordsWithChangesSelector(state, ownProps);
  const keywordsWithChangesByUrl = keywordsWithChangesByUrlSelector(state, ownProps);
  const rankTrackerFilters = rankTrackerFiltersSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  return {
    brand,
    brandId,
    keywordsWithChanges,
    keywordsWithChangesByUrl,
    rankTrackerFilters,
    subscriptionPolicies,
  };
}

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(RecheckRankingsButtonContainer);
