import React from 'react';
import PropTypes from 'prop-types';
import * as edit from 'react-edit';
import { upperCase, cloneDeep } from 'lodash';
import * as Table from 'reactabular-table';
import { fromJS, Map as iMap } from 'immutable';

import OverlayWithTooltipComponent from 'components_linkio/overlay_with_tooltip_component';

import {
  addCSSClassToCellInTable,
  generateDestinationLinkStatusForAtp,
  highlightTableRow,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
} from 'common/tables/utils';

import columnsGenerator, { columnSelect, columnTextArea } from 'common/tables/columns_generator';
import { destinationLinksMap, optionsMap, policiesShape } from 'common/prop_types_shapes';
import { optionValueOrValue } from 'components_linkio/Select/utils';
import { reactSelectColumn, textColumn } from 'common/tables/columns';
import { translate } from 'common/i18n';

import BadgeComponent from 'components_linkio/badge_component';
import Link from 'components_linkio/link';

import './duplicates_table_component.scss';

const selectProps = {
  autoFocus: true,
  blurInputOnSelect: false,
  openMenuOnFocus: true,
  triggerChangeOnBlur: true,
  isClearable: true,
  skinOptions: {
    inTable: true,
  },
};

const anchorTypeSelectProps = {
  ...selectProps,
  placeholder: translate('destinationLink.anchorType.placeholder'),
};

const includeByUserSelectProps = {
  ...selectProps,
  isClearable: false,
  placeholder: translate('destinationLink.includeByUser.placeholderShort'),
};

function reducer(state, action) {
  const { type, payload } = action;

  const newState = cloneDeep(state);

  switch (type) {
    case 'onActivate': {
      const { columnIndex, rowData, tableNode, policies } = payload;

      const canUpdateAtp = policies.getIn(['atp', 'canUpdate']);

      if (!canUpdateAtp) {
        break;
      }

      const updatedRows = newState.rows;

      const index = updatedRows.findIndex((dl) => dl.id === rowData.id);
      updatedRows[index].editing = columnIndex;

      addCSSClassToCellInTable(tableNode, index, columnIndex, TABLE_CELL_ACTIVE_CLASS);

      break;
    }
    case 'onValue': {
      const { value, rowData, property, tableNode, onUpdateDl } = payload;

      const updatedRows = newState.rows;

      const index = updatedRows.findIndex((dl) => dl.id === rowData.id);
      const newValue = optionValueOrValue(value) || '';
      const clonedRow = cloneDeep(rowData);

      removeCSSClassFromTableCells(tableNode, TABLE_CELL_ACTIVE_CLASS);

      const valueHasChanged = clonedRow[property] !== newValue;

      if (valueHasChanged) {
        clonedRow[property] = newValue;

        if (property === 'includeByUser') {
          clonedRow.included = newValue.includes('yes') ? 'true' : 'false';
        }

        highlightTableRow(tableNode, index);
        onUpdateDl(clonedRow);
      }

      Reflect.deleteProperty(clonedRow, 'editing');

      updatedRows[index] = clonedRow;
      break;
    }
    default:
      newState[type] = payload;
      break;
  }

  return newState;
}

const DuplicatesTable = ({
  anchorTypeOptions,
  canUseCrawlers,
  dlDuplicates,
  includeByUserOptions,
  includedOptions,
  onUpdateDl,
  policies,
}) => {
  const [state, dispatch] = React.useReducer(reducer, { rows: dlDuplicates.toList().toJS() });

  const tableRef = React.useRef(null);

  React.useEffect(() => {
    tableRef.current = document.querySelector('.duplicates-table-component__table');
  }, []);

  function buildPublishedLinkColumn() {
    const cellFormatters = [
      (value, extra) => {
        const { rowData } = extra;

        const dlStatus = generateDestinationLinkStatusForAtp(fromJS(rowData), canUseCrawlers).map(
          (row) => {
            const { status } = row;
            const text = upperCase(status);
            const color = {
              blue: status === 'processing',
              green: status === 'included',
              orange: status === 'unvalidated',
              red: status === 'excluded' || status === 'error',
            };
            return <BadgeComponent small {...color} key={text} text={text} />;
          },
        );

        return (
          <OverlayWithTooltipComponent tooltip={{ text: value, placement: 'top', color: 'white' }}>
            <div className="common-field">
              <Link
                href={value}
                target="_blank"
                className="text text_one-line"
                rel="noopener noreferrer"
              >
                {value}
              </Link>
              <div className="atp-table__link-status">{dlStatus}</div>
            </div>
          </OverlayWithTooltipComponent>
        );
      },
    ];

    return {
      cell: {
        formatters: cellFormatters,
      },
      header: {
        label: translate('destinationLink.publishedLink.title'),
      },
      property: 'publishedLink',
      props: {
        className: 'duplicates-table-component__published-link-column',
      },
    };
  }

  const editable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowData }) => {
      dispatch({
        type: 'onActivate',
        payload: { columnIndex, rowData, tableNode: tableRef.current, policies },
      });
    },

    onValue: ({ value, rowData, property }) => {
      dispatch({
        type: 'onValue',
        payload: { value, property, rowData, tableNode: tableRef.current, onUpdateDl },
      });
    },
  });

  function generateColumns() {
    const columns = columnsGenerator([
      reactSelectColumn({
        name: 'anchorType',
        headerLabel: translate('destinationLink.anchorType.title'),
        cellValueFormatter: anchorTypeValueFormatter,
        className: 'duplicates-table-component__anchor-type-column',
        cellTransforms: [
          columnSelect(editable, anchorTypeOptions.toList().toJS(), anchorTypeSelectProps),
        ],
      }),
      textColumn({
        name: 'anchorText',
        headerLabel: translate('destinationLink.anchorText.title'),
        cellTransforms: [columnTextArea(editable)],
        className: 'duplicates-table-component__anchor-text-column',
        showTooltip: true,
        isEditable: true,
      }),
      reactSelectColumn({
        name: 'includeByUser',
        headerLabel: translate('destinationLink.includeByUser.title'),
        className: 'duplicates-table-component__include-by-user-column',
        cellValueFormatter: includeByUserValueFormatter,
        cellTransforms: [
          columnSelect(editable, includeByUserOptions.toList().toJS(), includeByUserSelectProps),
        ],
      }),
    ]);
    // insert new column with index 1 in array
    columns.splice(0, 0, buildPublishedLinkColumn());

    return columns;
  }

  function anchorTypeValueFormatter(value) {
    const processedValue = optionValueOrValue(value);

    const option = (anchorTypeOptions || iMap()).get(String(processedValue), iMap()).toJS();

    return (option || {}).label;
  }

  function includeByUserValueFormatter(value, extra) {
    const {
      rowData: { included },
    } = extra;
    const processedValue = optionValueOrValue(value);

    // the code below aims to properly build 4 possible values of include status:
    // Yes (user), No (user), Yes (auto), No (auto)
    const includedOption = (includedOptions || iMap()).get(String(included), iMap()).toJS();
    const includedLabel = (includedOption || {}).label;
    const postfix = processedValue === 'auto' ? 'auto' : 'userDefined';
    const translatedPostfix = translate(`destinationLink.includeByUser.postfix.${postfix}`);
    return `${includedLabel}${translatedPostfix}`;
  }

  const { rows } = state;

  const columns = generateColumns();

  return (
    <div className="duplicates-table-component">
      <Table.Provider className="duplicates-table-component__table" columns={columns}>
        <Table.Header />
        <Table.Body rowKey="id" rows={rows} />
      </Table.Provider>
    </div>
  );
};

DuplicatesTable.propTypes = {
  anchorTypeOptions: optionsMap.isRequired,
  canUseCrawlers: PropTypes.bool.isRequired,
  dlDuplicates: destinationLinksMap.isRequired,
  includeByUserOptions: optionsMap.isRequired,
  includedOptions: optionsMap.isRequired,
  onUpdateDl: PropTypes.func,
  onUpdateRows: PropTypes.func,
  policies: policiesShape.isRequired,
};

export default DuplicatesTable;
