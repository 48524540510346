import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AnchorProgressPaneButtonComponent from './AnchorProgressPaneButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { toggleAnchorProgressPane } from 'actions/brand_page_actions';

const AnchorProgressPaneButtonContainer = ({ dispatch, pageParentType }) => {
  function handleTogglePane() {
    dispatch(toggleAnchorProgressPane(pageParentType));
  }

  return <AnchorProgressPaneButtonComponent onTogglePane={handleTogglePane} />;
};

AnchorProgressPaneButtonContainer.propTypes = {
  dispatch: PropTypes.func,
  pageParentType: PropTypes.oneOf(['brand_page', 'brand']).isRequired,
};

export default ConnectStoreHOC(connect()(AnchorProgressPaneButtonContainer));
