import {
  Map as iMap,
  List as iList,
  fromJS,
} from 'immutable';

import {
  CREATE_MARKETER__START,
  CREATE_MARKETER__SUCCESS,
  CREATE_MARKETER__FAILURE,
  FETCH_MARKETERS__START,
  FETCH_MARKETERS__SUCCESS,
  FETCH_MARKETERS__FAILURE,
} from '../actions/marketer_actions';

const initialState = iMap({
  list: iList(),
});

export default function marketersReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MARKETER__START:
    case CREATE_MARKETER__SUCCESS:
    case CREATE_MARKETER__FAILURE:
      break;

    case FETCH_MARKETERS__START:
      break;
    case FETCH_MARKETERS__SUCCESS: {
      return state.set('list', fromJS(action.result));
    }
    case FETCH_MARKETERS__FAILURE:
      break;
  }

  return state;
}
