import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';
import { format, isValid as isValidDate } from 'date-fns';

import ProspectFormPopup from '../../ProspectsPage/ProspectsBulkActions/ProspectFormPopup';
import columnsGenerator from 'common/tables/columns_generator';

import { buildSelectedItems } from 'common/utils';
import { checkboxColumn, textColumn } from 'common/tables/columns';
import { eoProgressionsList } from 'common/propTypesShapes/eoProgressions';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';
import OverlayWithTooltipComponent from 'components_linkio/overlay_with_tooltip_component';

import './eoProgressionsTable.scss';

const EoProgressionsTableComponent = ({
  canUpdate,
  onTableHeaderClick,
  onToggleSelectedRows,
  eoProgressions,
  eoProgressionsCount,
  selectedIds,
  sorting,
}) => {
  const processedEoProgressions = buildSelectedItems(eoProgressions.toJS(), selectedIds);

  const headerValueFormatter = (options = {}) => (_value, extra) => {
    const { sortBy, sortingOrder } = sorting;
    const { withExplanationMessage } = options;
    const { property } = extra;

    const label = translate(`eoProgression.table.headers.${property}`);

    return (
      <HeaderFieldWithSorting
        appModule="eoProgressions"
        label={label}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  };

  const handleOnToggleSelectRow = (eoProgressionId) => (event) => {
    const isSelected = event.target.checked;

    onToggleSelectedRows([eoProgressionId], isSelected);
  };

  function handleOnToggleSelectAll(event) {
    const isSelectedAll = event.target.checked;

    const eoProgressionsIds = processedEoProgressions.map((eoProgression) => eoProgression.id);

    onToggleSelectedRows(eoProgressionsIds, isSelectedAll);
  }

  function isAllSelected() {
    const selectedRowsCount = processedEoProgressions.filter(
      (eoProgression) => eoProgression.selected,
    ).length;
    const rowsCount = processedEoProgressions.length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  }

  function cellCheckboxFormatters(_value, extra) {
    const {
      rowData: { selected, id },
    } = extra;

    return (
      <Checkbox
        checked={selected}
        className="eo-progressions-table__checkbox"
        onChange={handleOnToggleSelectRow(id)}
      />
    );
  }

  function headerCheckboxFormatters() {
    return (
      <Checkbox
        checked={isAllSelected()}
        className="eo-progressions-table__checkbox"
        onChange={handleOnToggleSelectAll}
      />
    );
  }

  function dateValueFormatter(value) {
    if (!value) {
      return translate('notAvailable');
    }

    const date = new Date(value);

    return isValidDate(date) ? format(date, 'MMM d h:mm a') : value;
  }

  function stageValueFormatter(value, extra) {
    const {
      rowData: { currentError, stage },
    } = extra;

    const translatedStage = translate(`eoProgression.table.stage.${value}`);

    if (stage === 'has_error' && currentError) {
      return (
        <OverlayWithTooltipComponent isCopyable tooltip={{ text: currentError, placement: 'top' }}>
          <div>{translatedStage}</div>
        </OverlayWithTooltipComponent>
      );
    }

    if (stage === 'bounced') {
      return <span className="text_red">{translatedStage}</span>;
    }

    return translatedStage;
  }

  const emailValueFormatter = (value, extra) => {
    const {
      rowData: { stage },
    } = extra;

    if (stage !== 'bounced') {
      return value;
    }

    return (
      <span title={value} className="text_red text_two-lines-with-ellipsis-break-word">
        {value}
      </span>
    );
  };

  function currentEoCampaignStepFormatter(_value, extra) {
    const {
      rowData: { currentEoCampaignStepActionType, currentEoCampaignStepPosition },
    } = extra;

    const currentStepIsMissing = !currentEoCampaignStepActionType || !currentEoCampaignStepPosition;
    if (currentStepIsMissing) {
      return void 0;
    }

    const actionTypeText = translate(
      `eoCampaignStep.actionTypes.${currentEoCampaignStepActionType}`,
    );

    return `#${currentEoCampaignStepPosition} ${actionTypeText}`;
  }

  function generateColumns() {
    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [headerCheckboxFormatters],
        cellFormatters: [cellCheckboxFormatters],
        className: 'eo-progressions-table__checkbox-column',
      }),
      textColumn({
        name: 'stage',
        headerValueFormatter: headerValueFormatter(),
        cellValueFormatter: stageValueFormatter,
        className: 'eo-progressions-table__stage-column',
      }),
      textColumn({
        cellValueFormatter: currentEoCampaignStepFormatter,
        className: 'eo-progressions-table__current-eo-campaign-step-column',
        headerLabel: translate('eoProgression.table.headers.currentEoCampaignStep'),
        headerValueFormatter: headerValueFormatter(),
        name: 'currentEoCampaignStep',
      }),
      textColumn({
        cellValueFormatter: dateValueFormatter,
        className: 'eo-progressions-table__next-eo-campaign-step-column',
        headerLabel: translate('eoProgression.table.headers.nextEoCampaignStep'),
        headerValueFormatter: headerValueFormatter(),
        name: 'nextEoCampaignStep',
      }),
      textColumn({
        name: 'prospectEmail',
        headerValueFormatter: headerValueFormatter(),
        cellValueFormatter: emailValueFormatter,
        Popup: canUpdate && ProspectFormPopup,
        className: 'eo-progressions-table__prospect-email-column',
        isEditable: canUpdate,
      }),
      textColumn({
        name: 'prospectName',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-progressions-table__prospect-name-column',
      }),
      textColumn({
        name: 'prospectPosition',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-progressions-table__prospect-position-column',
        showTooltip: true,
      }),
      textColumn({
        name: 'prospectEoDomain',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-progressions-table__prospect-eo-domain-column',
      }),
      textColumn({
        name: 'createdAt',
        headerValueFormatter: headerValueFormatter(),
        cellValueFormatter: dateValueFormatter,
        className: 'eo-progressions-table__created_at-column',
      }),
      textColumn({
        name: 'completedAt',
        headerValueFormatter: headerValueFormatter(),
        cellValueFormatter: dateValueFormatter,
        className: 'eo-progressions-table__completed_at-column',
      }),
    ]);
  }

  const columns = generateColumns();

  return (
    <>
      {eoProgressionsCount > 0 && (
        <p>{translate('eoProgression.table.eoProgressionsCount')(eoProgressionsCount)}</p>
      )}
      <Table.Provider className="eo-progressions-table" columns={columns}>
        <Table.Header />
        <Table.Body rowKey="id" rows={processedEoProgressions} />
      </Table.Provider>
    </>
  );
};

EoProgressionsTableComponent.propTypes = {
  canUpdate: PropTypes.bool,
  eoProgressions: eoProgressionsList,
  eoProgressionsCount: PropTypes.number,
  onTableHeaderClick: PropTypes.func,
  onToggleSelectedRows: PropTypes.func,
  selectedIds: selectedIdsList,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default EoProgressionsTableComponent;
