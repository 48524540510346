import { translate } from '../common/i18n';

import {
  createDlSourceFailure,
  createDlSourceStart,
  createDlSourceSuccess,
  fetchDlSourcesFailure,
  fetchDlSourcesStart,
  fetchDlSourcesSuccess,
} from '../actions/dl_source_actions';

import {
  getFromAPI,
  handleAPIError,
  postToAPI,
  processObjToSnakeCase,
} from './utils';


async function fetchDlSources(dispatch) {
  dispatch(fetchDlSourcesStart());

  try {
    const resp = await getFromAPI(Routes.all_dl_sources_path());
    dispatch(fetchDlSourcesSuccess(resp.dl_sources));
    return resp.dl_sources;
  } catch (err) {
    return handleAPIError(dispatch, err, fetchDlSourcesFailure, translate('errors.cantFetchDlSources'));
  }
}

async function createDlSource(dispatch, data) {
  dispatch(createDlSourceStart());

  try {
    const resp = await postToAPI(
      Routes.dl_sources_path(),
      dlSourceObject(data)
    );
    dispatch(createDlSourceSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, createDlSourceFailure, translate('errors.cantCreateDlDource'));
  }
}

const dlSourceObject = (data) => ({ dl_source: processObjToSnakeCase(data) });

export { fetchDlSources, createDlSource };
