import React from 'react';
import { compose } from 'lodash';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import SearchInput from 'components/SearchInput';

const SearchEoDomainsInputContainer = ({ changeQueryParams, queryParams }) => {
  function handleSubmitSearch(value) {
    const newQueryParams = {
      ...queryParams,
      search: value,
      pageNumber: 1,
    };

    changeQueryParams(newQueryParams);
  }

  return <SearchInput onSubmit={handleSubmitSearch} />;
};

SearchEoDomainsInputContainer.propTypes = {
  ...withQueryParamsPropTypes,
};

export default compose(withQueryParams)(SearchEoDomainsInputContainer);
