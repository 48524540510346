import React from 'react';

import { eoCampaignMetricsMap } from 'common/propTypesShapes/eoMetrics';
import { translate } from 'common/i18n';

import LineChart from 'components_linkio/LineChart';
import PieChart from 'components_linkio/PieChart';

import './eoCampaignOverviewPage.scss';

const overallEoProgressionNames = ['started', 'active', 'pausedOrCompleted'];

const EoCampaignOverviewPageComponent = ({ eoCampaignMetrics }) => {
  const {
    overallEoProgressions = {},
    countByStages = [],
    metricsByDate = [],
  } = eoCampaignMetrics.toJS();

  return (
    <div className="eo-campaign-overview">
      <div className="eo-campaign-overview__progression-stats">
        {overallEoProgressionNames.map((name) => {
          return (
            <h3 key={name}>
              <span className="eo-campaign-overview__progressions-count">
                {overallEoProgressions[name]}
              </span>{' '}
              {translate(`chart.metrics.emailOutreach.eoProgressions.${name}`)}
            </h3>
          );
        })}
      </div>

      <PieChart appModule="emailOutreach" chartData={countByStages} />

      <LineChart
        appModule="emailOutreach"
        chartData={metricsByDate}
        title={translate('chart.titles.volumeStats')}
      />
    </div>
  );
};

EoCampaignOverviewPageComponent.propTypes = {
  eoCampaignMetrics: eoCampaignMetricsMap,
};

export default EoCampaignOverviewPageComponent;
