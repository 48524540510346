import pluralize from 'pluralize';

export default {
  bulkCompetitorsSetup: {
    addCompetitorsManuallyPopup: {
      buttons: {
        cancel: 'Cancel',
        save: 'Save',
      },
      hint: 'Please add one URL per line',
      subtitle: 'Add Multiple Competitor Pages',
      title: 'Add New Competitors',
    },
    autodiscoverCompetitorsPopup: {
      cancel: 'Cancel',
      chooseKeyword: 'Choose the keyword that you want to discover competitors for:',
      emptyStateText: {
        linkTextPre: 'Please ',
        linkText: 'add some keywords',
        linkTextAfter: ' to the parent Brand Page first.',
      },
      hint: 'The top 10 Google search results for your chosen keyword will be analyzed.',
      submit: 'Submit',
      title: 'Autodiscover Competitors',
    },
    bulkActions: {
      addToAtp: (competitorsCount) => {
        if (competitorsCount > 0) {
          return `Add ${pluralize('Competitor', competitorsCount, true)} to Anchor Text Planner`;
        }
        return 'Return to Competitors List';
      },
      autodiscoverCompetitors: 'Autodiscover competitors',
      cancel: 'Cancel',
      manuallyAddCompetitors: 'Manually add competitors',
      subtitle:
        "Autodiscover competitors from Google SERP or manually add the competitor URLs \
        and we'll import their backlinks, metrics, and anchor text percentages.",
      title: 'Which Competitor(s) Do You Want To Add For This Page?',
    },
    messages: {
      addCompetitorsSuccess: (uniqAndValidEntriesCount, dupeOrInvalidEntriesCount) => {
        const successMessage = `Import was completed successfully. ${pluralize(
          'competitor',
          uniqAndValidEntriesCount,
          true,
        )} added`;
        const skippedMessage = `${dupeOrInvalidEntriesCount} invalid or dupe ${pluralize(
          'competitor',
          dupeOrInvalidEntriesCount,
        )} skipped.`;

        return dupeOrInvalidEntriesCount > 0
          ? `${successMessage}, ${skippedMessage}`
          : successMessage;
      },
      addCompetitorsFailure: (label) => {
        return `No competitors were found for the keyword "${label}", maybe you misspelled it?`;
      },
      addToAtpSuccessfully: (competitorsCount) => {
        return `Successfully added ${pluralize(
          'competitor',
          competitorsCount,
          true,
        )} to Anchor Text Planner.`;
      },
    },
    table: {
      blankState: {
        body: 'You can autodiscover or manually add competitors',
        title: "You don't have any competitors yet",
      },
      dataNotReadyYet: 'Generating your results... (Can take up to a minute)',
      headers: {
        brandName: 'Brand Name',
        competitorPages: 'Competitor Pages',
        domainAhrefsRating: 'DR',
        keyword: 'Keyword',
        keywords: 'Keywords',
        // mozDomainAuthority: 'DA',
        pageType: 'Page Type',
        position: 'Position',
        status: {
          in_atp: 'Added',
          not_in_atp: 'Not Added',
          title: 'Status',
        },
        uriRefdomainsCount: 'RD',
      },
    },
  },
};
