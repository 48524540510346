import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isEmpty, cloneDeep } from 'lodash';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { resendConfirmation } from 'api/user';
import { clearErrors, validateEmail } from 'common/utils';

import ResendConfirmationFormComponent from 'components/auth/resend_confirmation_form_component';

class ResendConfirmationPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };
  constructor(props) {
    super(props);

    const processedProps = this.processProps();

    this.state = {
      error: null,
      errors: {},
      processedProps,
      showMessage: false,
    };
  }

  handleResendConfirmationAction = async (email) => {
    this.setState(clearErrors(this.state));
    const errors = validateEmail({ email });

    if (!isEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    const formData = new FormData();
    const { dispatch } = this.props;

    formData.append('user[email]', email);

    const res = await resendConfirmation(dispatch, formData);

    if (res.type && res.type === 'error') {
      this.setState(res.message);
    } else {
      this.setState({ showMessage: true });
    }
  }

  processProps = () => {
    const processedProps = cloneDeep(this.props);

    processedProps.handleResendConfirmationClick = this.handleResendConfirmationAction;

    return processedProps;
  }

  render() {
    const { processedProps, errors, error, showMessage } = this.state;

    return (
      <ResendConfirmationFormComponent
        {...processedProps}
        errors={errors}
        error={error}
        showMessage={showMessage}
      />
    );
  }
}

export default ConnectStoreHOC(connect()(ResendConfirmationPage));
