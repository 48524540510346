import { fromJS } from 'immutable';

import { FETCH_EO_DASHBOARD_METRICS__SUCCESS } from 'actions/eoDashboardActions';

import { camelizeObjectDeep } from 'reducers/utils';

const initialState = fromJS({
  eoDashboardMetrics: {},
});

export default function eoDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_DASHBOARD_METRICS__SUCCESS: {
      state = state.set(
        'eoDashboardMetrics',
        fromJS(camelizeObjectDeep(action.eoDashboardMetrics)),
      );

      break;
    }
  }

  return state;
}
