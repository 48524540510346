import { fromJS } from 'immutable';
import { keyBy } from 'lodash';

import { FETCH_BRANDS_CHART_METRICS__SUCCESS } from 'actions/brandsChartsActions';
import { DELETE_BRANDS__SUCCESS } from 'actions/brand_actions';

import { camelizeObjectDeep } from 'reducers/utils';

const initialState = fromJS({
  brandChartMetrics: {},
});

const processBrand = (brand) => {
  if (!brand) {
    return void 0;
  }

  return camelizeObjectDeep(brand);
};

export default function brandsChartsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRANDS_CHART_METRICS__SUCCESS: {
      const data = action.data || [];

      state = state.set('brandChartMetrics', fromJS(keyBy(data.map(processBrand), 'brandId')));

      break;
    }
    case DELETE_BRANDS__SUCCESS: {
      const brandChartMetricsWithoutDeletedBrands = state
        .get('brandChartMetrics')
        .filter((item) => !action.ids.includes(item.get('brandId')));

      state = state.set('brandChartMetrics', brandChartMetricsWithoutDeletedBrands);

      break;
    }
  }

  return state;
}
