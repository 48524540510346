import { fromJS } from 'immutable';

import {
  FETCH_EO_CAMPAIGN_STEPS_PREVIEW__SUCCESS,
  CHANGE_EO_CAMPAIGN_STEPS_PREVIEW_OPTIONS,
  RESET_EO_CAMPAIGN_STEPS_PREVIEW_OPTIONS,
} from 'actions/eoCampaignPreviewActions';

import { camelizeObjectDeep } from 'reducers/utils';

const initialEoCampaignStepsPreviewOptions = {
  eoInboxId: null,
  prospect: {},
};

const initialState = fromJS({
  eoCampaignStepsPreviewList: [],
  eoCampaignStepsPreviewOptions: initialEoCampaignStepsPreviewOptions,
});

export default function eoCampaignStepsPreviewReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_CAMPAIGN_STEPS_PREVIEW__SUCCESS: {
      const { eoCampaignStepsPreview } = action;

      state = state.set('eoCampaignStepsPreviewList', fromJS(eoCampaignStepsPreview.map(camelizeObjectDeep)));

      break;
    }
    case CHANGE_EO_CAMPAIGN_STEPS_PREVIEW_OPTIONS: {
      const { entityName, entity } = action;

      state = state.setIn(['eoCampaignStepsPreviewOptions', entityName], fromJS(entity));

      break;
    }
    case RESET_EO_CAMPAIGN_STEPS_PREVIEW_OPTIONS: {
      state = state.set('eoCampaignStepsPreviewOptions', fromJS(initialEoCampaignStepsPreviewOptions));

      break;
    }
  }

  return state;
}
