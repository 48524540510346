import { fromJS, Map as iMap } from 'immutable';
import { keyBy } from 'lodash';

import {
  FETCH_DL_SOURCES__START,
  FETCH_DL_SOURCES__SUCCESS,
  FETCH_DL_SOURCES__FAILURE,
  CREATE_DL_SOURCE__START,
  CREATE_DL_SOURCE__SUCCESS,
  CREATE_DL_SOURCE__FAILURE,
} from '../actions/dl_source_actions';

import {
  camelizeObjectDeep,
} from './utils';

const initialState = iMap();


export default function dlSourceReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DL_SOURCES__START:
    case CREATE_DL_SOURCE__START:
    case FETCH_DL_SOURCES__FAILURE:
    case CREATE_DL_SOURCE__FAILURE: {
      break;
    }
    case FETCH_DL_SOURCES__SUCCESS: {
      state = state.merge(state, fromJS(keyBy(action.dlSources.map(camelizeObjectDeep), 'id')));

      break;
    }
    case CREATE_DL_SOURCE__SUCCESS: {
      state = state.merge(state, fromJS(keyBy([camelizeObjectDeep(action.dlSource)], 'id')));

      break;
    }
  }

  return state;
}
