import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { optionsList } from 'common/prop_types_shapes';

import Select from 'components_linkio/Select';
import SearchEoDripListsInput from './SearchEoDripListsInput';
import DeleteEoDripListsButton from './DeleteEoDripListsButton';
import AddEoDripListButton from './AddEoDripListButton';

import './eoDripListsBulkActions.scss';

const EoDripListsBulkActionsComponent = ({
  canCreate,
  currentBrandId,
  immutableBrandsOptions,
  handleChangeCurrentBrand,
}) => {
  const brandsOptions = immutableBrandsOptions.toJS();
  const componentStylesPropForSelect = { option: { wordBreak: 'break-all' } };

  return (
    <div className="eo-drip-lists-bulk-actions">
      <div className="eo-drip-lists-bulk-actions__row">
        <div className="eo-drip-lists-bulk-actions__row-item">
          <SearchEoDripListsInput />

          <Select
            blurInputOnSelect
            componentStyles={componentStylesPropForSelect}
            wrapperClassName="eo-drip-lists-bulk-actions__filter_by-brand"
            isClearable
            placeholder={translate(`eoDripList.bulkActions.selectBrand`)}
            onChange={handleChangeCurrentBrand}
            options={brandsOptions}
            triggerChangeOnBlur={false}
            value={brandsOptions.find(({ value }) => value === currentBrandId) ?? null}
          />

          {canCreate && (
            <>
              <span className="vertical-separator" />
              <DeleteEoDripListsButton />
            </>
          )}
        </div>

        {canCreate && (
          <div className="eo-drip-lists-bulk-actions__row-item">
            <AddEoDripListButton isGreen />
          </div>
        )}
      </div>
    </div>
  );
};

EoDripListsBulkActionsComponent.propTypes = {
  canCreate: PropTypes.bool,
  currentBrandId: PropTypes.number,
  handleChangeCurrentBrand: PropTypes.func,
  immutableBrandsOptions: optionsList,
};

export default EoDripListsBulkActionsComponent;
