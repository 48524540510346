import React from 'react';
import PropTypes from 'prop-types';

import EoMessagesTable from './EoMessagesTable';
import EoMessagesBulkActions from './EoMessagesBulkActions';

import { pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';
const EoMessagesComponent = ({ onItemsSelectChange, onPagesPagerClick, eoMessagesPagy }) => {
  return (
    <div className="eo-messages">
      <EoMessagesBulkActions />

      <EoMessagesTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="rowsPager"
        pagy={eoMessagesPagy}
      />
    </div>
  );
};

EoMessagesComponent.propTypes = {
  eoMessagesPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoMessagesComponent;
