import React from 'react';
import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';

import EoEmailTemplatesPageComponent from './EoEmailTemplatesPageComponent';

import Breadcrumbs from 'components/NewBreadcrumbs';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { translate } from 'common/i18n';

import { pagyShape } from 'common/prop_types_shapes';
import { eoEmailTemplatesPagySelector } from 'selectors/eoEmailTemplatesSelector';

const EoEmailTemplatesPageContainer = ({
  changeQueryParams,
  eoEmailTemplatesPagy,
  queryParams,
}) => {
  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('eoEmailTemplate.breadcrumbsTitle')}</Breadcrumbs.TextItem>
      </Breadcrumbs>
      <div className="page__wrapper">
        <EoEmailTemplatesPageComponent
          onItemsSelectChange={handleItemsSelectChange}
          onPagesPagerClick={handlePagesPagerClick}
          eoEmailTemplatesPagy={eoEmailTemplatesPagy}
        />
      </div>
    </>
  );
};

EoEmailTemplatesPageContainer.propTypes = {
  eoEmailTemplatesPagy: pagyShape,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoEmailTemplatesPagy = eoEmailTemplatesPagySelector(state);

  return {
    eoEmailTemplatesPagy,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoEmailTemplatesPageContainer);
