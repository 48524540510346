import React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

export default (ComposedComponent) => {
  return function ConnectStoreHOC(props) {
    const appStore = ReactOnRails.getStore('ReduxStore');

    return (
      <Provider store={appStore}>
        <ComposedComponent {...props} />
      </Provider>
    );
  };
};
