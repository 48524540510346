const UPDATE_CRAWLER_SETTINGS__FAILURE = 'UPDATE_CRAWLER_SETTINGS__FAILURE';
const UPDATE_CRAWLER_SETTINGS__START = 'UPDATE_CRAWLER_SETTINGS__START';
const UPDATE_CRAWLER_SETTINGS__SUCCESS = 'UPDATE_CRAWLER_SETTINGS__SUCCESS';


function updateCrawlerSettingsFailure(errors) {
  return { type: UPDATE_CRAWLER_SETTINGS__FAILURE, errors };
}

function updateCrawlerSettingsStart() {
  return { type: UPDATE_CRAWLER_SETTINGS__START };
}

function updateCrawlerSettingsSuccess(marketer) {
  return { type: UPDATE_CRAWLER_SETTINGS__SUCCESS, marketer };
}


export {
  UPDATE_CRAWLER_SETTINGS__FAILURE,
  UPDATE_CRAWLER_SETTINGS__START,
  UPDATE_CRAWLER_SETTINGS__SUCCESS,
  updateCrawlerSettingsFailure,
  updateCrawlerSettingsStart,
  updateCrawlerSettingsSuccess,
};
