import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import EoEmailTemplatesTabsComponent from './EoEmailTemplatesTabsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchEoEmailTemplatesCount } from 'api/eoEmailTemplate';

import {
  eoEmailTemplatesCountSelector,
  eoEmailTemplatesSelector,
} from 'selectors/eoEmailTemplatesSelector';

import {
  eoEmailTemplatesCountMap,
  eoEmailTemplatesList,
} from 'common/propTypesShapes/eoEmailTemplates';

function getTemplatesCount(immutableTemplatesCountObject, scope) {
  const {
    allTemplateCount,
    privateTemplateCount,
    sharedTemplateCount,
    defaultTemplateCount,
  } = immutableTemplatesCountObject.toJS();

  const templatesCountByScope = {
    all: allTemplateCount,
    private: privateTemplateCount,
    shared: sharedTemplateCount,
    default: defaultTemplateCount,
  };

  return templatesCountByScope[scope];
}

const EoEmailTemplatesTabsContainer = ({
  changeQueryParams,
  dispatch,
  eoEmailTemplates,
  history,
  setCurrentEmailTemplateFolderId,
  templatesCountObject,
  queryParams,
}) => {
  const [activeTab, setActiveTab] = useState(queryParams.templateScope || 'all');

  React.useEffect(() => {
    fetchEoEmailTemplatesCount(dispatch);
  }, [dispatch, JSON.stringify(eoEmailTemplates)]);

  const handleTabSelect = (tab) => () => {
    const newQueryParams = {
      ...queryParams,
      templateScope: tab,
    };

    setActiveTab(tab);
    changeQueryParams(newQueryParams);
  };

  const handleDropSelectedFolder = () => {
    const path = Routes.eo_email_templates_all_path({ ...queryParams, format: null });

    history.push(path);
    setCurrentEmailTemplateFolderId(null);
  };

  return (
    <EoEmailTemplatesTabsComponent
      activeTab={activeTab}
      handleDropSelectedFolder={handleDropSelectedFolder}
      handleTabSelect={handleTabSelect}
      templatesCount={getTemplatesCount(templatesCountObject, activeTab)}
    />
  );
};

EoEmailTemplatesTabsContainer.propTypes = {
  dispatch: PropTypes.func,
  eoEmailTemplates: eoEmailTemplatesList,
  setCurrentEmailTemplateFolderId: PropTypes.func,
  templatesCountObject: eoEmailTemplatesCountMap,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const templatesCountObject = eoEmailTemplatesCountSelector(state);
  const eoEmailTemplates = eoEmailTemplatesSelector(state);

  return {
    eoEmailTemplates,
    templatesCountObject,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoEmailTemplatesTabsContainer);
