import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BrandsStepsComponent from './BrandsStepsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { updateOnboarding } from 'api/user';


const BrandsStepsContainer = ({ dispatch }) => {
  function handleCloseBrandsOnboarding() {
    updateOnboarding(dispatch, 'brands', false);
  }

  return (
    <BrandsStepsComponent onCloseBrandsOnboarding={handleCloseBrandsOnboarding} />
  );
};

BrandsStepsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default ConnectStoreHOC(connect()(BrandsStepsContainer));
