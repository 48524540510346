import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConnectStoreHOC from 'startup/connect_store_hoc';
import PropTypes from 'prop-types';
import {
  cloneDeep,
  isEmpty,
} from 'lodash';

import { resetPassword } from 'api/user';
import ResetPasswordFormComponent from 'components/auth/reset_password_form_component';


class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);

    const processedProps = this.processProps();

    this.state = {
      errors: {},
      processedProps,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func,
  };

  handleResetPasswordButtonAction = async (resetPasswordToken, password, passwordConfirmation) => {
    const formData = new FormData();
    const { dispatch } = this.props;

    formData.append('user[password]', password);
    formData.append('user[password_confirmation]', passwordConfirmation);
    formData.append('user[reset_password_token]', resetPasswordToken);

    const result = await resetPassword(dispatch, formData);
    const { type, errors, message } = result;

    if (type === 'error' || !isEmpty(errors)) {
      this.setState({ errors: message || errors });
    } else {
      ((window || {}).location || {}).href = Routes.root_url({ format: null });
    }
  }

  processProps = () => {
    const processedProps = cloneDeep(this.props);

    processedProps.handleResetPasswordButtonClick = this.handleResetPasswordButtonAction;

    return processedProps;
  }

  render() {
    const { processedProps, errors } = this.state;

    return (
      <ResetPasswordFormComponent {...processedProps} errors={errors} />
    );
  }
}

export default ConnectStoreHOC(connect()(ResetPasswordPage));
