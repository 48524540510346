import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import ProspectsBulkActionsComponent from './ProspectsBulkActionsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { prospectsSelector } from 'selectors/prospectsSelector';
import { prospectsList } from 'common/propTypesShapes/prospects';

import { fetchEoCampaigns } from 'api/eoCampaign';
import { eoCampaignsSelector } from 'selectors/eoCampaignsSelector';
import { eoCampaignsList } from 'common/propTypesShapes/eoCampaigns';

import { fetchEoDripListsAsOptions } from 'api/eoDripList';
import { optionsSelector } from 'selectors';
import { optionsList } from 'common/prop_types_shapes';

const ProspectsBulkActionsContainer = ({
  canCreate,
  dispatch,
  eoCampaigns,
  eoDripListsOptions,
  prospects,
  queryParams,
}) => {
  const [fetchingState, setFetchingState] = React.useState('notFetching');

  React.useEffect(() => {
    async function fetchFilterOptions() {
      await fetchEoCampaigns(dispatch, {});
      await fetchEoDripListsAsOptions(dispatch);

      setFetchingState('fetchingFinished');
    }

    setFetchingState('isFetching');

    fetchFilterOptions();
  }, [dispatch]);

  const hasProspects = prospects.size > 0;
  const isFetchingFilterOptions = fetchingState === 'isFetching';
  const hideDataFilter = (!queryParams.filters && !hasProspects) || isFetchingFilterOptions;

  const eoCampaignsArray = eoCampaigns.toJS();
  const eoCampaignsFilterOptions = eoCampaignsArray.map(({ title, id }) => ({
    label: title,
    value: id.toString(),
  }));

  const eoDriplistsOptionsWithIdAsNum = eoDripListsOptions.toJS();
  const eoDriplistsFilterOptions = eoDriplistsOptionsWithIdAsNum.map((option) => ({
    ...option,
    value: option.value.toString(),
  }));

  return (
    <ProspectsBulkActionsComponent
      canCreate={canCreate}
      eoCampaignsFilterOptions={eoCampaignsFilterOptions}
      eoDriplistsFilterOptions={eoDriplistsFilterOptions}
      hideDataFilter={hideDataFilter}
    />
  );
};

ProspectsBulkActionsContainer.propTypes = {
  canCreate: PropTypes.bool,
  eoCampaigns: eoCampaignsList,
  eoDripListsOptions: optionsList,
  prospects: prospectsList,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoCampaigns = eoCampaignsSelector(state);
  const prospects = prospectsSelector(state);

  const options = optionsSelector(state);
  const eoDripListsOptions = options.get('eoDripListsOptions');

  return {
    eoCampaigns,
    eoDripListsOptions,
    prospects,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(ProspectsBulkActionsContainer);
