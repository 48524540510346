import React from 'react';

import CopyrightComponent from '../copyright_component';

import './devise_footer_component.scss';


const DeviseFooterComponent = () => {
  return (
    <div className="devise-footer-component">
      <div className="devise-footer-component__copyright">
        <CopyrightComponent />
      </div>
    </div>
  );
};


export default DeviseFooterComponent;
