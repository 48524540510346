import { translate } from '../../common/i18n';

const DEFAULT_SE_SETTINGS = {
  DEFAULT_COUNTRY: 'United States',
  DEFAULT_ENGINE_NAME: 'google.com',
  DEFAULT_LANGUAGE: 'English',
};

const defaultSelectorProps = {
  blurInputOnSelect: false,
  isClearable: false,
  isCreatable: false,
  skinOptions: {
    inTable: false,
  },
  triggerChangeOnBlur: false,
};

const exactLocationSelectorProps = {
  ...defaultSelectorProps,
  components: {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  },
  placeholder: translate('rankTrackerSetup.selects.location.placeholder'),
};

export {
  DEFAULT_SE_SETTINGS,
  defaultSelectorProps,
  exactLocationSelectorProps,
};
