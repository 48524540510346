import {
  updatePageFailure,
  updatePageSuccess,
} from '../../actions/brand_page_actions';

import { translate } from '../../common/i18n';

import {
  handleAPIError,
} from './../utils';


const handlePageTitleFetching = (dispatch, data) => {
  const {
    brandPage,
    brandPageTitleFetching,
  } = data;

  switch (brandPageTitleFetching) {
    case 'finished':
      dispatch(updatePageSuccess(brandPage));

      break;
    case 'failure':
      handlePageTitleFetchingFailure(dispatch, brandPage);

      break;
  }
};

const handlePageTitleFetchingFailure = (dispatch, brandPage) => {
  const error = ((brandPage || {}).errors || {}).base;
  handleAPIError(dispatch, { message: error }, updatePageFailure, translate('errors.cantUpdatePage'));
};


export default handlePageTitleFetching;
