import React from 'react';

import StripeCharges from './StripeCharges';
import CardInfo from './CardInfo';

import { translate } from 'common/i18n';

import TabsNavigationComponent from 'components/auth/tabs_navigation_component';
import Breadcrumbs from 'components/NewBreadcrumbs';

import './billingPage.scss';

const BillingPageComponent = () => {
  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('authForms.breadcrumbsTitle')}</Breadcrumbs.TextItem>
      </Breadcrumbs>
      <div className="billing-component">
        <div className="strip" />
        <div className="billing-component__tabs">
          <TabsNavigationComponent />
        </div>
        <div className="strip" />
        <CardInfo />
        <StripeCharges />
      </div>
    </>
  );
};

export default BillingPageComponent;
