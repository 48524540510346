import {
  updateDestinationLinksSuccess,
} from '../../../../../../actions/destination_link_actions';

const refreshDlsService = (context, dls) => {
  const { dispatch } = context;

  dispatch(updateDestinationLinksSuccess(dls));
};


export default refreshDlsService;
