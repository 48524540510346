import { fromJS, List as iList, Map as iMap } from 'immutable';
import { camelizeObjectDeep, mergeArrayToList, mergeItemToList } from './utils';

import {
  CANCEL_EO_PROGRESSIONS__SUCCESS,
  DELETE_EO_PROGRESSIONS__SUCCESS,
  FETCH_EO_PROGRESSIONS__SUCCESS,
  RESUME_EO_PROGRESSIONS__SUCCESS,
  UPDATE_EO_PROGRESSION__SUCCESS,
} from 'actions/eoProgressionActions';

const initialState = iMap();

export default function eoProgressionsReducer(state = initialState, action) {
  switch (action.type) {
    case CANCEL_EO_PROGRESSIONS__SUCCESS:
    case RESUME_EO_PROGRESSIONS__SUCCESS: {
      const { eoProgressions, eoCampaignId } = action;

      state = state.set(
        String(eoCampaignId),
        mergeArrayToList(
          state.get(String(eoCampaignId), iList()),
          eoProgressions,
          camelizeObjectDeep,
        ),
      );

      break;
    }
    case FETCH_EO_PROGRESSIONS__SUCCESS: {
      const { eoProgressions, eoCampaignId } = action;

      state = state.set(String(eoCampaignId), fromJS(eoProgressions.map(camelizeObjectDeep)));

      break;
    }
    case DELETE_EO_PROGRESSIONS__SUCCESS: {
      const { ids, eoCampaignId } = action;

      const filteredEoProgressions = state
        .get(String(eoCampaignId))
        .filter((eoProgression) => !ids.includes(eoProgression.get('id')));

      state = state.set(String(eoCampaignId), filteredEoProgressions);

      break;
    }
    case UPDATE_EO_PROGRESSION__SUCCESS: {
      const { eoProgression, eoCampaignId } = action;

      state = state.set(
        String(eoCampaignId),
        mergeItemToList(
          state.get(String(eoCampaignId), iList()),
          eoProgression,
          camelizeObjectDeep,
        ),
      );

      break;
    }
  }

  return state;
}
