import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NewsletterSettingsComponent from './NewsletterSettingsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  newsletterSettingsMap,
  optionsList,
  subscriptionPoliciesShape,
  subscriptionShape,
  userShape,
} from 'common/prop_types_shapes';

import {
  currentSubscriptionSelector,
  currentUserSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import { fetchNewsletterSettings, updateNewsletterSettings } from 'api/newsletterSettings';
import { fetchSimpleBrands } from 'api/simpleBrand';

import { newsletterSettingsSelector } from 'selectors/newsletterSettingsSelector';
import { optionsSelector } from 'selectors';

import { translate } from 'common/i18n';

import clickUpgradeSubscriptionService from 'pages/Pages/services/clickUpgradeSubscriptionService';

import Breadcrumbs from 'components/NewBreadcrumbs';
import TabsNavigationComponent from 'components/auth/tabs_navigation_component';
import BrandsBlankState from 'pages/AccountDashboard/BrandsBlankState';

const NewsletterSettingsContainer = ({
  brandsOptions,
  dispatch,
  currentSubscription,
  currentUser,
  history,
  newsletterSettings,
  subscriptionPolicies,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      await Promise.all([fetchSimpleBrands(dispatch), fetchNewsletterSettings(dispatch)]);
      setIsFetching(false);
    }

    fetchData();
  }, [dispatch]);

  function onChangeEmailClick() {
    history.push(Routes.edit_user_registration_path({ format: null }));
  }

  function onUpdateSettings(settings) {
    updateNewsletterSettings(dispatch, settings);
  }

  function onClickUpgradeSubscription(event) {
    clickUpgradeSubscriptionService({ currentSubscription, dispatch }, event);
  }

  const userEmail = currentUser.get('email');

  const brandsCount = currentSubscription.get('brandsCount', 0);
  const canUseSettings = subscriptionPolicies.getIn(['metrics', 'canUse']);

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('authForms.breadcrumbsTitle')}</Breadcrumbs.TextItem>
      </Breadcrumbs>

      <div className="newsletter-settings-page page__wrapper">
        <TabsNavigationComponent />
        <div className="strip" />

        {!isFetching && (brandsCount > 0 || !canUseSettings) && (
          <NewsletterSettingsComponent
            brandsOptions={brandsOptions.toJS()}
            canUseSettings={canUseSettings}
            handleChangeEmailClick={onChangeEmailClick}
            handleClickUpgradeSubscription={onClickUpgradeSubscription}
            handleUpdateSettings={onUpdateSettings}
            newsletterSettings={newsletterSettings.toJS()}
            userEmail={userEmail}
          />
        )}

        {!isFetching && brandsCount === 0 && canUseSettings && <BrandsBlankState />}
      </div>
    </>
  );
};

NewsletterSettingsContainer.propTypes = {
  brandsOptions: optionsList,
  currentSubscription: subscriptionShape,
  currentUser: userShape,
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  newsletterSettings: newsletterSettingsMap,
  subscriptionPolicies: subscriptionPoliciesShape,
};

function select(state) {
  const currentSubscription = currentSubscriptionSelector(state);
  const subscriptionPolicies = subscriptionPoliciesSelector(state);
  const currentUser = currentUserSelector(state);
  const newsletterSettings = newsletterSettingsSelector(state);

  const options = optionsSelector(state);
  const brandsOptions = options.get('brandsOptions');

  return {
    brandsOptions,
    currentSubscription,
    currentUser,
    newsletterSettings,
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(NewsletterSettingsContainer));
