import { fromJS, List as iList } from 'immutable';
import { camelizeObjectDeep, mergeItemToList } from './utils';

import {
  CREATE_EO_CUSTOM_FIELD__SUCCESS,
  DELETE_EO_CUSTOM_FIELDS__SUCCESS,
  FETCH_EO_CUSTOM_FIELDS__SUCCESS,
  FETCH_EO_CUSTOM_FIELD__SUCCESS,
  UPDATE_EO_CUSTOM_FIELD__SUCCESS,
} from 'actions/eoCustomFieldActions';

const initialState = iList();

export default function eoCustomFieldsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_CUSTOM_FIELDS__SUCCESS: {
      const { eoCustomFields } = action;

      state = fromJS(eoCustomFields.map(camelizeObjectDeep));

      break;
    }
    case FETCH_EO_CUSTOM_FIELD__SUCCESS:
    case CREATE_EO_CUSTOM_FIELD__SUCCESS:
    case UPDATE_EO_CUSTOM_FIELD__SUCCESS: {
      const { eoCustomField } = action;
      state = mergeItemToList(state, eoCustomField, camelizeObjectDeep);

      break;
    }
    case DELETE_EO_CUSTOM_FIELDS__SUCCESS: {
      const { ids } = action;

      state = state.filter((eoCustomField) => !ids.includes(eoCustomField.get('id')));

      break;
    }
  }

  return state;
}
