import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './textarea_component.scss';


export default class TextAreaComponent extends Component {
  static propTypes = {
    extraParameters: PropTypes.shape({
      property: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      rowData: PropTypes.shape({
        errors: PropTypes.shape({
          email: PropTypes.string,
          failed: PropTypes.bool,
          profileType: PropTypes.string,
        }),
      }),
    }),
    onValue: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    extraParameters: {
      rowData: {},
    },
    onValue: () => {},
    value: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      rows: 2,
    };
  }

  componentDidMount() {
    this.setRows();
  }

  defaultHeight = 20;

  textarea = {};
  mirror = {};

  handleKeyDown = (event) => {
    const keys = ['Control', 'Meta', 'Shift', 'CapsLock', 'Alt'];

    if (keys.includes(event.key)) {
      return;
    }

    this.setRows();

    if (event.key === 'Enter') {
      this.sendData();
    }
  };

  handleBlur = () => this.sendData();

  setRows = () => this.setState({ rows: this.calculateRow() });

  sendData = () => this.props.onValue(this.textarea.value);

  calculateRow = () => {
    const html = `${String(this.textarea.value.substring(0, this.textarea.selectionStart)).replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;').replace(/'/g, '&#39;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      .replace(/\n/g, '<br />')} .<br/>.`;
    this.mirror.innerHTML = html;
    this.mirror.style.display = '';
    const currentHeight = this.mirror.clientHeight;
    this.mirror.style.display = 'none';

    return Math.round(currentHeight / (this.defaultHeight / 1.5));
  }

  render() {
    const { value, extraParameters: { property, rowData: { failed, errors } } } = this.props;
    const { rows } = this.state;

    return (
      <div className="textarea-component">
        <div
          ref={(mirror) => this.mirror = mirror}
          className="textarea-component__mirror"
        >{value}
        </div>
        <textarea
          ref={(textarea) => this.textarea = textarea}
          autoFocus
          rows={rows}
          defaultValue={value}
          className="textarea-component__input"
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
        />
        {failed && <span className="cell_error small">{(errors || {})[property]}</span>}
      </div>
    );
  }
}
