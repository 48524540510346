import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { translate } from 'common/i18n';
import ButtonComponent from 'components_linkio/button_component';
import CalendarsSideBySide from 'components_linkio/CalendarsSideBySide';

import './calendarPresetsPopup.scss';

const getButtonStyleProps = (preset, activePreset) => ({
  isWhite: preset !== activePreset,
  isBlue: preset === activePreset,
});

const CalendarPresetsPopupComponent = ({
  align,
  componentRef,
  dateEnd,
  dateStart,
  defaultPeriod,
  positionStyle,
  activePreset,
  applyPreset,
  setActivePresetCustom,
  setCalendarRange,
  onClickApply,
  onClickClear,
  onClickCancel,
}) => {
  const calendarPresetsPopupClasses = cx('calendar-presets-popup', {
    'calendar-presets-popup_arrow_left': Number.isFinite(align.left),
    'calendar-presets-popup_arrow_right': Number.isFinite(align.right),
  });

  return (
    <div className={calendarPresetsPopupClasses} ref={componentRef} style={positionStyle}>
      <div className="calendar-presets-popup__calendar">
        <CalendarsSideBySide
          dateEnd={dateEnd}
          dateStart={dateStart}
          onChangeRange={setCalendarRange}
        />
      </div>

      <div className="calendar-presets-popup__presets">
        <ButtonComponent
          className="calendar-presets-popup__button"
          {...getButtonStyleProps(defaultPeriod, activePreset)}
          onClick={applyPreset(defaultPeriod)}
        >
          {translate('uiComponents.calendarPresetsPopup.item.default')}
        </ButtonComponent>
        <ButtonComponent
          className="calendar-presets-popup__button"
          {...getButtonStyleProps('3 Months', activePreset)}
          onClick={applyPreset('3 Months')}
        >
          {translate('uiComponents.calendarPresetsPopup.item.months')(3)}
        </ButtonComponent>
        <ButtonComponent
          className="calendar-presets-popup__button"
          {...getButtonStyleProps('6 Months', activePreset)}
          onClick={applyPreset('6 Months')}
        >
          {translate('uiComponents.calendarPresetsPopup.item.months')(6)}
        </ButtonComponent>
        <ButtonComponent
          className="calendar-presets-popup__button"
          {...getButtonStyleProps('Year', activePreset)}
          onClick={applyPreset('Year')}
        >
          {translate('uiComponents.calendarPresetsPopup.item.year')}
        </ButtonComponent>
        <ButtonComponent
          className="calendar-presets-popup__button"
          {...getButtonStyleProps('Custom', activePreset)}
          onClick={setActivePresetCustom}
        >
          {translate('uiComponents.calendarPresetsPopup.item.custom')}
        </ButtonComponent>
      </div>

      <div className="calendar-presets-popup__footer">
        <ButtonComponent className="calendar-presets-popup__button" isWhite onClick={onClickCancel}>
          {translate('uiComponents.button.cancel')}
        </ButtonComponent>
        <ButtonComponent className="calendar-presets-popup__button" isWhite onClick={onClickClear}>
          {translate('uiComponents.button.clear')}
        </ButtonComponent>
        <ButtonComponent className="calendar-presets-popup__button" isGreen onClick={onClickApply}>
          {translate('uiComponents.button.apply')}
        </ButtonComponent>
      </div>
    </div>
  );
};

CalendarPresetsPopupComponent.propTypes = {
  activePreset: PropTypes.string,
  align: PropTypes.shape(),
  applyPreset: PropTypes.func.isRequired,
  componentRef: PropTypes.shape({ current: PropTypes.any }),
  dateEnd: PropTypes.instanceOf(Date),
  dateStart: PropTypes.instanceOf(Date),
  defaultPeriod: PropTypes.string,
  onClickApply: PropTypes.func,
  onClickCancel: PropTypes.func,
  onClickClear: PropTypes.func,
  positionStyle: PropTypes.shape(),
  setActivePresetCustom: PropTypes.func,
  setCalendarRange: PropTypes.func,
};

export default CalendarPresetsPopupComponent;
