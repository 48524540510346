import React from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import {
  notificationsList,
} from '../../../common/prop_types_shapes';

import NotificationItem from '../NotificationItem';

import './notificationsPopup.scss';


const NotificationsPopupComponent = ({ notifications, onViewAllClick }) => {
  return (
    <div className="notifications-popup">
      <h4 className="notifications-popup__title">{translate('notifications.title')}</h4>

      <div className="notifications-popup__notifications-wrapper">
        {notifications.map((notification) => {
          return (
            <NotificationItem
              key={notification.get('id')}
              notification={notification}
            />
          );
        })}
      </div>

      <div className="notifications-popup__view-all text_link" onClick={onViewAllClick}>
        {translate('notifications.viewAll')}
      </div>
    </div>
  );
};

NotificationsPopupComponent.propTypes = {
  notifications: notificationsList,
  onViewAllClick: PropTypes.func.isRequired,
};


export default NotificationsPopupComponent;
