import { translate } from '../common/i18n';

import {
  fetchOnboardingStatusFailure,
  fetchOnboardingStatusStart,
  fetchOnboardingStatusSuccess,
} from '../actions/onboardingStatusActions';

import { getFromAPI, handleAPIError } from './utils';

export async function fetchOnboardingStatus(dispatch) {
  dispatch(fetchOnboardingStatusStart());

  try {
    const res = await getFromAPI(Routes.onboarding_status_path());
    dispatch(fetchOnboardingStatusSuccess(res.onboarding_status));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchOnboardingStatusFailure,
      translate('prompts.messages.cantFetchOnboardingStatus'),
    );
  }
}
