import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

import './groupDlsButtons.scss';

const GroupDlsButtonsComponent = ({ dlsGroupMode, onChangeDlsGroupMode }) => {
  return (
    <div className="group-dls-buttons">
      <ButtonComponent
        isWhite={dlsGroupMode !== 'all'}
        onClick={onChangeDlsGroupMode('all')}
        isInitial
        className="group-dls-buttons__ungroup-button"
      >
        {translate('destinationLink.buttons.ungroup')}
      </ButtonComponent>
      <ButtonComponent
        isWhite={dlsGroupMode !== 'one_per_domain'}
        onClick={onChangeDlsGroupMode('one_per_domain')}
        isInitial
        className={'group-dls-buttons__group-per-domain-button'}
      >
        {translate('destinationLink.buttons.groupPerDomain')}
      </ButtonComponent>
    </div>
  );
};

GroupDlsButtonsComponent.propTypes = {
  dlsGroupMode: PropTypes.oneOf(['all', 'one_per_domain']),
  onChangeDlsGroupMode: PropTypes.func.isRequired,
};

export default GroupDlsButtonsComponent;
