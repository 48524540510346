import React from 'react';
import PropTypes from 'prop-types';

import BrandOverviewItem from './BrandOverviewItemComponent';

import { brandDashboardDataMap } from 'common/prop_types_shapes';
import defaultMetricsParams from 'utils/routeUtils';

import AddEoCampaignButton from 'pages/EoCampaignsPage/EoCampaignsBulkActions/AddEoCampaignButton';
import AddEoDripListButton from 'pages/EoDripListsPage/EoDripListsBulkActions/AddEoDripListButton';

import './brandOverview.scss';

const BrandOverviewComponent = ({ brandDashboardData, brandId, canUseMetrics, onVisitLink, onMetricsGenerate}) => {
  
  function buildAppModulesData() {
    return [
      {
        name: 'atp',
        anchorTextScoreLink: Routes.brand_page_tab_path(
          brandId,
          brandDashboardData.get('homepageId'),
          'backlinks',
          defaultMetricsParams(canUseMetrics),
        ),
        setupLink: Routes.bulk_pages_setup_path(brandId, { format: null, simpleMode: true }),
        viewMoreLink: Routes.brand_brand_pages_path(brandId, { format: null }),
        metrics: {
          pagesCount: brandDashboardData.get('pagesCount'),
          anchorTextScore: brandDashboardData.get('anchorTextScore'),
          isEmpty: !brandDashboardData.get('pagesCount'),
        },
      },
      {
        name: 'rankTracker',
        setupLink: Routes.edit_rank_tracker_keywords_path(brandId, { format: null }),
        viewMoreLink: Routes.rank_tracker_root_path(brandId, { format: null }),
        metrics: {
          keywordsCount: brandDashboardData.get('keywordsCount'),
          averageRanking: {
            count: brandDashboardData.getIn(['averageRank', 'value']),
            difference: brandDashboardData.getIn(['averageRank', 'difference']),
          },
          positionDifference: {
            up: brandDashboardData.getIn(['positionDifference', 'up']),
            down: brandDashboardData.getIn(['positionDifference', 'down']),
          },
          isEmpty: !brandDashboardData.get('keywordsCount'),
        },
      },
      {
        name: 'backlinks',
        setupLink: Routes.import_backlink_path(brandId, { format: null }),
        viewMoreLink: Routes.backlink_path(brandId, {
          ...defaultMetricsParams(canUseMetrics),
        }),
        metrics: {
          backlinks: {
            count: brandDashboardData.getIn(['backlinks', 'value']),
            difference: brandDashboardData.getIn(['backlinks', 'difference']),
          },
          refDomains: {
            count: brandDashboardData.getIn(['refDomains', 'value']),
            difference: brandDashboardData.getIn(['refDomains', 'difference']),
          },
          isEmpty: !brandDashboardData.getIn(['backlinks', 'value']),
        },
      },
      {
        name: 'eoDripLists',
        CreateButton: AddEoDripListButton,
        viewMoreLink: Routes.eo_drip_lists_path({ format: null }),
        setupLink: Routes.eo_drip_lists_path({ format: null }),
        metrics: {
          eoDripListsCount: brandDashboardData.get('eoDripListsCount'),
          isEmpty: !brandDashboardData.get('eoDripListsCount'),
        },
      },
      {
        name: 'eoCampaigns',
        CreateButton: AddEoCampaignButton,
        viewMoreLink: Routes.eo_campaigns_path({ format: null }),
        setupLink: Routes.eo_campaigns_path({ format: null }),
        metrics: {
          eoCampaignsCount: brandDashboardData.get('eoCampaignsCount'),
          isEmpty: !brandDashboardData.get('eoCampaignsCount'),
        },
      },
    ];
  }

  const appModulesData = buildAppModulesData();

  return (
    <div className="brand-overview">
      {appModulesData.map((appModule) => {
        return (
          <BrandOverviewItem onVisitLink={onVisitLink} key={appModule.name} appModule={appModule} />
        );
      })}
    </div>
  );
};

BrandOverviewComponent.propTypes = {
  brandDashboardData: brandDashboardDataMap,
  brandId: PropTypes.string,
  canUseMetrics: PropTypes.bool,
  onVisitLink: PropTypes.func,
};

export default BrandOverviewComponent;
