import React from 'react';

const BacklinkIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="M12.747 8.253l2.235-2.236A3.454 3.454 0 0 0 16 3.558c0-.928-.361-1.801-1.018-2.458a3.454 3.454 0 0 0-2.46-1.017c-.93 0-1.803.361-2.46 1.017L7.829 3.337a3.483 3.483 0 0 0-.729 3.844 3.468 3.468 0 0 0-1.386-.287c-.93 0-1.803.362-2.46 1.019l-2.235 2.236A3.455 3.455 0 0 0 0 12.607c0 .929.361 1.801 1.018 2.458a3.453 3.453 0 0 0 2.459 1.018c.93 0 1.804-.362 2.46-1.018l2.235-2.236a3.483 3.483 0 0 0 .729-3.845c.431.187.9.286 1.386.286.93 0 1.804-.361 2.46-1.017zm-5.142 4.014L5.37 14.503a2.658 2.658 0 0 1-1.893.783 2.657 2.657 0 0 1-1.892-.783A2.664 2.664 0 0 1 .8 12.607c0-.716.279-1.39.785-1.896L3.82 8.475a2.68 2.68 0 0 1 2.749-.644l-.473.473a2.09 2.09 0 0 0-1.87.58L1.99 11.122a2.089 2.089 0 0 0-.616 1.486c0 .561.219 1.09.616 1.486a2.09 2.09 0 0 0 1.487.616 2.09 2.09 0 0 0 1.488-.616l2.236-2.236a2.1 2.1 0 0 0 .578-1.87l.469-.469c.312.934.1 2.006-.643 2.749zM4.42 11.66c.225.225.525.35.843.35.318 0 .618-.125.843-.35l.823-.823a1.299 1.299 0 0 1-.295.456l-2.235 2.237a1.294 1.294 0 0 1-.922.38 1.298 1.298 0 0 1-1.303-1.305c0-.348.136-.676.383-.923l2.235-2.236a1.3 1.3 0 0 1 .457-.295l-.828.828a1.19 1.19 0 0 0 0 1.681zM5.54 11.1a.399.399 0 0 1-.552 0 .394.394 0 0 1 0-.557l5.474-5.475a.387.387 0 0 1 .552 0 .394.394 0 0 1 0 .556L5.539 11.1zm6.04-6.595a1.184 1.184 0 0 0-.842-.349c-.318 0-.617.124-.843.35l-.823.823c.064-.167.161-.323.295-.457l2.235-2.236c.246-.246.573-.381.921-.381a1.298 1.298 0 0 1 1.304 1.305c0 .349-.136.677-.382.923L11.208 6.72c-.131.131-.287.23-.456.294l.828-.828a1.191 1.191 0 0 0 0-1.68zM9.906 7.861a2.09 2.09 0 0 0 1.87-.58l2.236-2.236c.396-.398.615-.925.615-1.487 0-.56-.218-1.089-.615-1.486a2.09 2.09 0 0 0-1.489-.615 2.09 2.09 0 0 0-1.487.615L8.799 4.31a2.101 2.101 0 0 0-.578 1.87l-.469.468A2.682 2.682 0 0 1 8.395 3.9l2.235-2.236A2.659 2.659 0 0 1 12.523.88c.715 0 1.388.278 1.892.783.506.506.785 1.18.785 1.895 0 .717-.279 1.39-.785 1.896L12.18 7.691a2.687 2.687 0 0 1-2.748.644l.473-.474z" fillRule="nonzero" />
    </svg>
  );
};

BacklinkIcon.defaultProps = {
  fill: 'currentColor',
  height: '17px',
  width: '16px',
};

export default BacklinkIcon;
