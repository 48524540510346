import React from 'react';

const InfoIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M9.619 12.006H6.38c-.213 0-.385-.201-.385-.448s.172-.448.385-.448L7 11V8l-.62-.116c-.213 0-.385-.201-.385-.448s.172-.448.385-.448h2.514c.077 0 .102-.032.113-.058C9.004 6.945 9 6.959 9 7v4l.619.11c.213 0 .386.201.386.448s-.173.448-.386.448zM7 4h.98c.007 0 .013-.004.02-.004.007 0 .013.004.02.004H9v.94c.001.022.012.04.012.061 0 .022-.011.04-.012.061V6h-.967c-.012.001-.022.007-.033.007-.012 0-.022-.006-.033-.007H7v-.939c-.002-.021-.013-.038-.013-.06 0-.021.011-.039.013-.06V4zm1-4C3.581 0 0 3.582 0 8s3.581 8 8 8c4.418 0 8-3.582 8-8s-3.582-8-8-8z" />
    </svg>
  );
};

InfoIcon.defaultProps = {
  fill: '#8F9295',
  width: '14px',
  height: '14px',
};

export default InfoIcon;
