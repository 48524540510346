import React from 'react';
import PropTypes from 'prop-types';
import { upperCase, compact } from 'lodash';
import * as Table from 'reactabular-table';

import { translate } from 'common/i18n';
import { textTwoLinesWithEllipsis } from 'common/utils';

import { textColumn } from 'common/tables/columns';
import columnsGenerator from 'common/tables/columns_generator';

import BadgeComponent from 'components_linkio/badge_component';
import OverlayWithTooltipComponent from 'components/overlay_with_tooltip_component';
import MessageComponent from 'components_linkio/message_component';

import './status_table_component.scss';

const statusTableDescription = {
  type: 'hint',
  text: translate('dlDetailsPopup.tables.status.description'),
};

const StatusTableComponent = ({
  onClickUpgradeSubscription,
  onShowDuplicateLinksClick,
  rows,
  withDateColumn,
}) => {
  function statusCellFormatter(value) {
    const color = {
      blue: value === 'processing',
      green: value === 'included',
      orange: value === 'unvalidated',
      red: value === 'excluded',
      warning: ['error', 'unknown'].includes(value),
    };

    return <BadgeComponent {...color} text={upperCase(value)} />;
  }

  function descriptionCellFormatter(value, data) {
    const { rowData } = data;
    const UNVALIDATED_ROW_ID = 1;

    if (rowData.id === UNVALIDATED_ROW_ID) {
      return (
        <div className="common-field">
          <div className="common-field__text table__cell_one-line">
            <span className="status-table-component__unvalidated-description">
              {translate('dlDetailsPopup.tables.status.unvalidated.description.beforeLinkText')}
              <span className="text_link" onClick={onClickUpgradeSubscription}>
                {translate('dlDetailsPopup.tables.status.unvalidated.description.linkText')}
              </span>
              {translate('dlDetailsPopup.tables.status.unvalidated.description.afterLinkText')}
            </span>
          </div>
        </div>
      );
    }

    return value;
  }

  function detailsCellFormatter(value, data) {
    const { rowData } = data;
    const DUPLICATES_ROW_ID = 9;

    if (rowData.id === DUPLICATES_ROW_ID) {
      return (
        <div className="common-field">
          <div className="common-field__text table__cell_one-line">
            <div className="text_link" onClick={onShowDuplicateLinksClick}>
              {translate('dlDetailsPopup.tables.status.buttons.link')}
            </div>
          </div>
        </div>
      );
    }

    return (
      <OverlayWithTooltipComponent tooltip={{ text: value, placement: 'top', color: 'white' }}>
        {textTwoLinesWithEllipsis(value, { breakAll: false })}
      </OverlayWithTooltipComponent>
    );
  }

  function generateColumns() {
    return columnsGenerator(
      compact([
        withDateColumn &&
          textColumn({
            name: 'date',
            headerLabel: translate('dlDetailsPopup.tables.status.headerLabels.date'),
            className: 'status-table-component__column',
          }),
        textColumn({
          name: 'status',
          headerLabel: translate('dlDetailsPopup.tables.status.headerLabels.status'),
          cellFormatters: [statusCellFormatter],
          className: 'status-table-component__column',
        }),
        textColumn({
          name: 'description',
          headerLabel: translate('dlDetailsPopup.tables.status.headerLabels.description'),
          cellFormatters: [descriptionCellFormatter],
          className: 'status-table-component__column',
        }),
        textColumn({
          name: 'details',
          headerLabel: translate('dlDetailsPopup.tables.status.headerLabels.details'),
          cellFormatters: [detailsCellFormatter],
          className: 'status-table-component__column',
        }),
      ]),
    );
  }

  const columns = generateColumns();

  return (
    <div className="status-table-component">
      <Table.Provider className="status-table-component__table" columns={columns}>
        <Table.Header />
        <Table.Body rowKey="id" rows={rows} />
      </Table.Provider>

      <MessageComponent message={statusTableDescription} />
    </div>
  );
};

StatusTableComponent.propTypes = {
  onClickUpgradeSubscription: PropTypes.func,
  onShowDuplicateLinksClick: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      description: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,
  withDateColumn: PropTypes.bool,
};

export default StatusTableComponent;
