import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import EditIcon from 'common/icons/edit';

const CrawlingSettingsEditBtnComponent = ({ isDisabled, onClick }) => {
  const classes = classnames({
    'crawler-settings-table__action-icon': true,
    'crawler-settings-table__action-icon_disabled': isDisabled,
  });

  const eventHandler = !isDisabled ? onClick : null;

  return <EditIcon className={classes} onClick={eventHandler} />;
};

CrawlingSettingsEditBtnComponent.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default CrawlingSettingsEditBtnComponent;
