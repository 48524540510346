import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectStoreHOC from '../../../startup/connect_store_hoc';

import { sendFeedback } from '../../../api/helpcrunch';

import { translate } from '../../../common/i18n';

import { showSuccessMessageFromTranslation } from '../../../common/utils';

import { closeGetBacklinksSuggestionPopup } from '../../../actions/popup_actions';

import { Modal } from '../../../components_linkio/modal_component';
import TextAreaComponent from '../../../components_linkio/textarea_component';
import ButtonComponent from '../../../components_linkio/button_component';
import RadioButton from 'components_linkio/RadioButton';

import Link from 'components_linkio/link';

import './get_backlinks_suggestion_popup.scss';


class GetBacklinksSuggestionPopup extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    placeholder: PropTypes.string,
    title: PropTypes.string,
  }

  state = {
    selectedOpt: 'yes',
    suggestionText: void 0,
    message: void 0,
  }

  textArea = {};

  closePopup = () => this.props.dispatch(closeGetBacklinksSuggestionPopup());

  handleCancel = () => this.closePopup();

  handleSubmitSuggestion = () => {
    const { dispatch } = this.props;
    const { selectedOpt: contactMe, suggestionText } = this.state;

    if (!suggestionText) {
      return this.setState({
        message: { type: 'error', text: translate('getBacklinksSuggestionPopup.messages.noText') },
      });
    }

    sendFeedback(dispatch, { message: `${suggestionText} contact me: ${contactMe}` });

    showSuccessMessageFromTranslation(dispatch, 'getBacklinksSuggestionPopup.messages.thanksForSuggestion');
    this.closePopup();
  }

  handleOptsChange = (event) => this.setState({ selectedOpt: event.target.value, message: void 0 });
  handleSuggestionChange = (event) => this.setState({ suggestionText: event.target.value, message: void 0 });

  render() {
    const { title, placeholder } = this.props;
    const { selectedOpt, suggestionText, message } = this.state;

    return (
      <Modal show className="get-backlinks-suggestion-popup">
        <Modal.Header>
          <Modal.Title>{title || translate('getBacklinksSuggestionPopup.titles.modal')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="get-backlinks-suggestion-popup__ways-to-get-backlinks">
            {translate('getBacklinksSuggestionPopup.labels.waysToGetBacklins.beginning')}
            <Link
              href={translate('getBacklinksSuggestionPopup.labels.waysToGetBacklins.link.href')}
              rel="noopener noreferrer"
              target="_blank"
            >
              {translate('getBacklinksSuggestionPopup.labels.waysToGetBacklins.link.title')}
            </Link>
            {translate('getBacklinksSuggestionPopup.labels.waysToGetBacklins.ending')}
          </div>
          <TextAreaComponent
            label={translate('getBacklinksSuggestionPopup.labels.suggestion')}
            onChange={this.handleSuggestionChange}
            placeholder={placeholder || translate('getBacklinksSuggestionPopup.placeholders.suggestion')}
            value={suggestionText}
            message={message}
          />
          <div className="get-backlinks-suggestion-popup__selects">
            <div className="get-backlinks-suggestion-popup__title">
              {translate('getBacklinksSuggestionPopup.contactMe')}
            </div>
            <RadioButton
              checked={selectedOpt === 'yes'}
              onChange={this.handleOptsChange}
              value="yes"
            >
              {translate('yes')}
            </RadioButton>
            <RadioButton
              checked={selectedOpt === 'no'}
              onChange={this.handleOptsChange}
              value="no"
            >
              {translate('no')}
            </RadioButton>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="get-backlinks-suggestion-popup__btn-group">
            <div className="get-backlinks-suggestion-popup__btn">
              <ButtonComponent isWhite onClick={this.handleCancel}>
                {translate('getBacklinksSuggestionPopup.buttons.cancel')}
              </ButtonComponent>
            </div>
            <div className="get-backlinks-suggestion-popup__btn get-backlinks-suggestion-popup__btn_long">
              <ButtonComponent isGreen onClick={this.handleSubmitSuggestion}>
                {translate('getBacklinksSuggestionPopup.buttons.send')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConnectStoreHOC(connect()(GetBacklinksSuggestionPopup));
