import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { simpleBrandShape } from 'common/propTypesShapes/simpleBrands';

import { currentIdSelector } from 'selectors';
import { currentSimpleBrandSelector } from 'selectors/simpleBrandSelectors';

class CheckRedirectContainer extends React.PureComponent {
  static propTypes = {
    brand: simpleBrandShape,
    brandId: PropTypes.string,
    checkRedirect: PropTypes.bool,
    children: PropTypes.node,
  };

  render() {
    const { brand, brandId, checkRedirect, children } = this.props;

    if (!checkRedirect) {
      return children;
    }

    const hasNoSeSettings = brand.size > 0 && !brand.get('hasSeSettings');
    if (hasNoSeSettings) {
      return (
        <Redirect
          to={{ pathname: Routes.edit_rank_tracker_settings_path(brandId, { format: null }) }}
        />
      );
    }

    return children;
  }
}

function select(state, ownProps) {
  const brand = currentSimpleBrandSelector(state, ownProps);
  const brandId = currentIdSelector(state, ownProps);

  return {
    brand,
    brandId,
  };
}

export default ConnectStoreHOC(connect(select)(CheckRedirectContainer));
