import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import BulkCompetitorsSetupBulkActionsComponent from './BulkCompetitorsSetupBulkActionsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { addCompetitorsToAtp, fetchBulkCompetitorPages } from 'api/bulkCompetitorsSetup';
import { bulkCompetitorPagesByBrandPageIdSelector } from 'selectors/bulkCompetitorPagesSelector';
import { currentIdSelector, visibleBrandPageIdSelector } from 'selectors';
import { draftCompetitorPagesList } from 'common/prop_types_shapes';
import { fetchCompetitorPagesPercentages, updatePage } from 'api/brand_page';
import { translate } from 'common/i18n';

import {
  showSuccessMessageFromTranslation,
  showSuccessMessage,
  trackHelpcrunchEvent,
} from 'common/utils';

const BulkCompetitorsSetupBulkActionsContainer = ({
  brandId,
  brandPageId,
  dispatch,
  draftCompetitorPages,
  history,
  queryParams,
}) => {
  const [addingCompetitorsToAtp, setAddingCompetitorsToAtp] = React.useState(false);

  const selectedPagesIds = draftCompetitorPages
    .filter((page) => page.get('selected'))
    .map((page) => page.get('id'))
    .toJS();

  const selectedPagesCount = selectedPagesIds.length;

  async function handleAddCompetitorsToAtpClick() {
    if (selectedPagesCount === 0) {
      const path = Routes.brand_page_tab_path(brandId, brandPageId, 'competitors', {
        pagesFilter: queryParams.pagesFilter,
        format: null,
      });
      history.push(path);
      return;
    }

    setAddingCompetitorsToAtp(true);
    const resp = await addCompetitorsToAtp(dispatch, brandPageId, selectedPagesIds);
    setAddingCompetitorsToAtp(false);

    if (!resp.success) {
      return;
    }

    handleAddToAtpSuccess();
  }

  async function handleAddToAtpSuccess() {
    trackHelpcrunchEvent('add.competitorsPage');
    showSuccessMessage(
      dispatch,
      translate('bulkCompetitorsSetup.messages.addToAtpSuccessfully')(selectedPagesCount),
    );

    // Update competitors percentages and regenerate suggestions
    fetchCompetitorPagesPercentages(dispatch, brandPageId);

    await updatePage(dispatch, brandPageId, { dlsRedistributionRequired: true });

    showSuccessMessageFromTranslation(
      dispatch,
      'brandPage.messages.competitorsAveragePercentageChangedSuccessfully',
    );
  }

  async function onSubmitSearch(searchTerm) {
    await fetchBulkCompetitorPages(dispatch, brandPageId, searchTerm.trim());
  }

  return (
    <BulkCompetitorsSetupBulkActionsComponent
      addingCompetitorsToAtp={addingCompetitorsToAtp}
      handleSubmitSearch={onSubmitSearch}
      onAddCompetitorsToAtpClick={handleAddCompetitorsToAtpClick}
      selectedPagesCount={selectedPagesCount}
    />
  );
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const brandPageId = visibleBrandPageIdSelector(state, ownProps);

  const draftCompetitorPages = bulkCompetitorPagesByBrandPageIdSelector(state, brandPageId);

  return {
    brandId,
    brandPageId,
    draftCompetitorPages,
  };
}

BulkCompetitorsSetupBulkActionsContainer.propTypes = {
  brandId: PropTypes.string,
  brandPageId: PropTypes.string,
  dispatch: PropTypes.func,
  draftCompetitorPages: draftCompetitorPagesList,
  ...withQueryParamsPropTypes,
};

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(BulkCompetitorsSetupBulkActionsContainer);
