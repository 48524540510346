import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { compose } from 'lodash/fp';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import BrandFormComponent from './BrandFormComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { trackHelpcrunchEvent } from 'common/utils';
import { translate } from 'common/i18n';

import {
  ahrefsAbilitiesShape,
  brandShape,
  subscriptionPoliciesShape,
  subscriptionShape,
  optionsMap,
} from 'common/prop_types_shapes';

import { createBrand, fetchBrand, updateBrand } from 'api/brand';
import { fetchRailsContext } from 'api/rails_context';
import { importFromGsc } from 'api/destination_links_import';
import { fetchAhrefsAbilities } from 'api/ahrefs_connection';

import { currentBrandSelector, currentIdSelector, optionsSelector } from 'selectors';

import {
  currentMarketerSelector,
  currentSubscriptionSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import { openSubscriptionLimitReachedPopup } from 'actions/popup_actions';

import Breadcrumbs from 'components/NewBreadcrumbs';

import ModuleSetupSelection from 'pages/ModuleSetupSelection';

class BrandFormContainer extends Component {
  static propTypes = {
    ahrefsAbilities: ahrefsAbilitiesShape,
    brand: brandShape,
    brandId: PropTypes.string,
    currentSubscription: subscriptionShape,
    dispatch: PropTypes.func,
    hasAhrefsConnection: PropTypes.bool,
    history: PropTypes.shape({ push: PropTypes.func }),
    options: optionsMap,
    subscriptionPolicies: subscriptionPoliciesShape,
    ...withQueryParamsPropTypes,
  };

  componentDidMount() {
    const { brandId, dispatch } = this.props;
    const useLimit = false;

    fetchAhrefsAbilities(dispatch, useLimit);
    brandId && fetchBrand(dispatch, brandId);
  }

  processBrand = (brand) => {
    const processedBrand = {
      ...brand,
      otherNames: (brand.otherNames || '').trim().split(/\r?\n/),
    };

    return processedBrand;
  };

  handleBrandCreate = async (brand) => {
    const { dispatch, history, queryParams } = this.props;

    const processedBrand = this.processBrand(brand);

    const result = await createBrand(dispatch, processedBrand);
    const { type, errors } = result;

    if (type === 'error' || !isEmpty(errors)) {
      return result;
    }

    this.enqueueImportFromGsc(brand, result);

    const brandId = result.id;

    trackHelpcrunchEvent('add.brand');
    // Fetch rails context after the brand creation to keep fresh data in Redux store
    await fetchRailsContext(dispatch);

    if (queryParams.simpleMode === 'true') {
      history.push(
        Routes.edit_brand_path(brandId, { format: null, showModuleSetupSelection: true }),
      );
      return;
    }

    history.push(Routes.edit_rank_tracker_settings_path(brandId, { format: null }));

    return result;
  };

  handleBrandUpdate = async (brand) => {
    const { dispatch, brandId, history, queryParams } = this.props;

    const processedBrand = this.processBrand(brand);

    const result = await updateBrand(dispatch, processedBrand);
    const { type, errors } = result;

    if (type === 'error' || !isEmpty(errors)) {
      return result;
    }

    this.enqueueImportFromGsc(brand, result);

    if (queryParams.fromModuleSetupSelection === 'true') {
      history.push(Routes.bulk_pages_setup_path({ format: null, brand_id: brandId }));
      return;
    }

    history.goBack();

    return result;
  };

  enqueueImportFromGsc = async (brand, result) => {
    const { dispatch } = this.props;
    const { csvFile, importDlsFromGsc } = brand;

    if (!importDlsFromGsc) {
      return result;
    }

    if (importDlsFromGsc && csvFile) {
      trackHelpcrunchEvent('import.gsc');
    }

    const importResult = await importFromGsc(dispatch, result.id, csvFile);
    const { type, errors } = importResult;

    if (type === 'error' || !isEmpty(errors)) {
      return result;
    }

    return importResult;
  };

  handleClickUpgradeSubscription = (event) => {
    const { currentSubscription, dispatch } = this.props;
    const isTrial = currentSubscription.get('status') === 'trialing';
    const isFree = currentSubscription.get('subscriptionPlanTitle') === 'free';
    event && event.preventDefault();

    dispatch(openSubscriptionLimitReachedPopup({}));
  };

  handleCancelClick = () => this.props.history.push(Routes.root_path({ format: null }));

  render() {
    const {
      ahrefsAbilities,
      brand,
      brandId,
      hasAhrefsConnection,
      options,
      subscriptionPolicies,
      queryParams,
      //added to check subscription type
      currentSubscription,
    } = this.props;

    const websiteTypeOptions = options.get('websiteTypeOptions').toList().toJS();

    const kwDomainMatchOptions = options.get('kwDomainMatchOptions').toList().toJS();

    const canUseAhrefsInSubscription = subscriptionPolicies.getIn(
      ['ahrefsIntegration', 'canUse'],
      false,
    );

    const canUseMozInSubscription = subscriptionPolicies.getIn(['mozIntegration', 'canUse'], false);
    const isMozLimitReached = subscriptionPolicies.getIn(['mozIntegration', 'limitReached'], false);
    const isBrandFormSimpleMode = queryParams.simpleMode === 'true';
    const showModuleSetupSelection = queryParams.showModuleSetupSelection === 'true';
    const fromModuleSetupSelection = queryParams.fromModuleSetupSelection === 'true';

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.LinkItem to={Routes.root_path({ format: null })}>
            Brands
          </Breadcrumbs.LinkItem>
          <Breadcrumbs.TextItem>
            {translate('brand.form.breadcrumbsTitle')(brandId)}
          </Breadcrumbs.TextItem>
        </Breadcrumbs>

        <div className="page__wrapper">
          {!showModuleSetupSelection && (
            <BrandFormComponent
              ahrefsAbilities={ahrefsAbilities}
              brand={brand}
              canUseAhrefsInSubscription={canUseAhrefsInSubscription}
              canUseMozInSubscription={canUseMozInSubscription}
              fromModuleSetupSelection={fromModuleSetupSelection}
              hasAhrefsConnection={hasAhrefsConnection}
              isMozLimitReached={isMozLimitReached}
              isBrandFormSimpleMode={isBrandFormSimpleMode}
              key={brand.get('id')} // In order to re-render BrandComponent after brand fetching
              kwDomainMatchOptions={kwDomainMatchOptions}
              onBrandCreate={this.handleBrandCreate}
              onBrandUpdate={this.handleBrandUpdate}
              onCancelClick={this.handleCancelClick}
              onClickUpgradeSubscription={this.handleClickUpgradeSubscription}
              subscriptionPolicies={subscriptionPolicies}
              subscription={currentSubscription}
              websiteTypeOptions={websiteTypeOptions}
            />
          )}

          {showModuleSetupSelection && <ModuleSetupSelection />}
        </div>
      </>
    );
  }
}

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const brand = currentBrandSelector(state, ownProps);

  const currentMarketer = currentMarketerSelector(state, ownProps);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);

  const ahrefsAbilities = state.getIn(['ahrefsConnection', 'abilities']).toJS();
  const hasAhrefsConnection = currentMarketer.get('has_ahrefs_connection');

  return {
    ahrefsAbilities,
    brand,
    brandId,
    currentSubscription,
    hasAhrefsConnection,
    options,
    subscriptionPolicies,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(BrandFormContainer);
