import React from 'react';
import PropTypes from 'prop-types';

import CrawlerStatusBar from 'components_linkio/CrawlerStatusBar';

import SelectItem from 'components/NewBreadcrumbs/SelectItem';
import LinkItemComponent from 'components/NewBreadcrumbs/LinkItemComponent';
import TextItemComponent from 'components/NewBreadcrumbs/TextItemComponent';

import './breadcrumbs.scss';

class BreadcrumbsComponent extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    rightSideBtn: PropTypes.node,
  };
  static LinkItem = LinkItemComponent;
  static SelectItem = SelectItem;
  static TextItem = TextItemComponent;

  render() {
    const { children, rightSideBtn } = this.props;
    return (
      <div className="breadcrumbs">
        <div className="breadcrumbs__left-side-nav">{children}</div>
        <div className="breadcrumbs__right-side-nav">
          <CrawlerStatusBar />
          {rightSideBtn}
        </div>
      </div>
    );
  }
}

export default BreadcrumbsComponent;
