import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import Link from 'components_linkio/link';

import { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { eoEmailTemplateFoldersList } from 'common/propTypesShapes/eoEmailTemplates';

import './EoEmailTemplatesList.scss';

const EoEmailTemplatesListComponent = ({
  currentEmailTemplateFolderId,
  handleSelectDirectory,
  immutableEoEmailTemplateFolders,
  queryParams,
}) => {
  const eoEmailTemplateFolders = immutableEoEmailTemplateFolders.toJS();

  return (
    <div className="eo-email-templates-list">
      {eoEmailTemplateFolders.map(({ id, title, templateCount }) => {
        const templateClassNames = cx('eo-email-templates-list__entries', {
          'eo-email-templates-list__selected': id === currentEmailTemplateFolderId,
        });

        const path = Routes.eo_email_templates_path(id, { ...queryParams, format: null });

        return (
          <div className={templateClassNames} key={id} onClick={handleSelectDirectory(id)}>
            <Link to={path}>
              <div>{title}</div>
              <div>{templateCount}</div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

EoEmailTemplatesListComponent.propTypes = {
  currentEmailTemplateFolderId: PropTypes.number,
  handleSelectDirectory: PropTypes.func,
  immutableEoEmailTemplateFolders: eoEmailTemplateFoldersList,
  ...withQueryParamsPropTypes,
};

export default EoEmailTemplatesListComponent;
