import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProspectActionsSelectComponent from './ProspectActionsSelectComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { selectedIdsList } from 'common/prop_types_shapes';
import { uiSelector } from 'selectors';
import { validateProspectEmails } from 'api/prospects';

const ProspectActionsSelectContainer = ({ dispatch, selectedIds }) => {
  const isDisabled = selectedIds.size === 0;

  function handleValidateEmails() {
    validateProspectEmails(dispatch, selectedIds.toJS());
  }

  return (
    <ProspectActionsSelectComponent
      isDisabled={isDisabled}
      onValidateEmails={handleValidateEmails}
    />
  );
};

ProspectActionsSelectContainer.propTypes = {
  dispatch: PropTypes.func,
  selectedIds: selectedIdsList,
};

function select(state) {
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    selectedIds,
  };
}

export default ConnectStoreHOC(connect(select)(ProspectActionsSelectContainer));
