const FETCH_ANCHOR_TEXTS__FAILURE = 'FETCH_ANCHOR_TEXTS__FAILURE';
const FETCH_ANCHOR_TEXTS__START = 'FETCH_ANCHOR_TEXTS__START';
const FETCH_ANCHOR_TEXTS__SUCCESS = 'FETCH_ANCHOR_TEXTS__SUCCESS';

function fetchAnchorTextsFailure(errors) {
  return { type: FETCH_ANCHOR_TEXTS__FAILURE, errors };
}

function fetchAnchorTextsStart() {
  return { type: FETCH_ANCHOR_TEXTS__START };
}

function fetchAnchorTextsSuccess(anchorTexts) {
  return { type: FETCH_ANCHOR_TEXTS__SUCCESS, anchorTexts };
}


export {
  FETCH_ANCHOR_TEXTS__FAILURE,
  FETCH_ANCHOR_TEXTS__START,
  FETCH_ANCHOR_TEXTS__SUCCESS,
  fetchAnchorTextsFailure,
  fetchAnchorTextsStart,
  fetchAnchorTextsSuccess,
};
