import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import ImportFromGSCButtonComponent from './ImportFromGSCButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector } from 'selectors';
import { openImportFromGSCPopup } from 'actions/popup_actions';

const ImportFromGSCButtonContainer = ({ dispatch, isLoading }) => {
  function handleClick() {
    dispatch(openImportFromGSCPopup());
  }

  return <ImportFromGSCButtonComponent onClick={handleClick} isLoading={isLoading} />;
};

ImportFromGSCButtonContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const isLoading = state.getIn(['ui', 'loading', 'importFromGSC', brandId], false);

  return {
    isLoading,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(ImportFromGSCButtonContainer);
