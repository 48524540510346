import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';


const backlinksByBrandId = (state, brandId) => state.getIn(['backlinks', String(brandId)], iList());

const backlinksPagyByBrandId = (state, brandId) => {
  return state.getIn(['pagies', 'backlinksPagy', String(brandId)], iMap());
};


const backlinksPagyByBrandIdSelector = createSelector(
  [backlinksPagyByBrandId],
  (pagy) => pagy || iMap()
);

const backlinksByBrandIdSelector = createSelector(
  [backlinksByBrandId],
  (backlinks) => backlinks || iList()
);


export {
  backlinksPagyByBrandIdSelector,
  backlinksByBrandIdSelector,
};
