import React from 'react';
import PropTypes from 'prop-types';

import MyBrandsDashboardTable from './MyBrandsDashboardTable';

import { translate } from 'common/i18n';
import { brandsMetricsArray } from 'common/propTypesShapes/metrics';

import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import Link from 'components_linkio/link';

import MagnifierIcon from 'common/icons/magnifier';

import './myBrandsDashboard.scss';

const filterBrandsMetricsByName = (brandsMetrics, searchedName) => {
  if (searchedName === '') {
    return brandsMetrics;
  }

  return brandsMetrics.filter(({ name }) =>
    name.toLowerCase().includes(searchedName.toLowerCase()),
  );
};

const MyBrandsDashboardComponent = ({ brandsMetrics, canUseMetrics }) => {
  const [searchedName, setSearchedName] = React.useState('');
  const [filteredBrandsMetrics, setFilteredBrandsMetrics] = React.useState(brandsMetrics);

  React.useEffect(() => {
    setFilteredBrandsMetrics(filterBrandsMetricsByName(brandsMetrics, searchedName));
  }, [brandsMetrics, searchedName]);

  const handleChangeSearch = ({ target: { value } }) => setSearchedName(value);

  return (
    <div className="my-brands-dashboard">
      <h2>{translate('brand.dashboard.myBrands.title')}</h2>

      <div className="my-brands-dashboard__row">
        <InputComponent
          className="my-brands-dashboard__search"
          onInputChange={handleChangeSearch}
          icon={MagnifierIcon}
          isHaveRightIcon
        />
        <Link to={Routes.new_brand_path({ format: null })} className="my-brands-dashboard__button">
          <ButtonComponent isGreen>{translate('brand.list.buttons.add')}</ButtonComponent>
        </Link>
      </div>
      {filteredBrandsMetrics.length > 0 && (
        <MyBrandsDashboardTable
          brandsMetrics={filteredBrandsMetrics}
          canUseMetrics={canUseMetrics}
        />
      )}
    </div>
  );
};

MyBrandsDashboardComponent.propTypes = {
  brandsMetrics: brandsMetricsArray,
  canUseMetrics: PropTypes.bool,
};

MyBrandsDashboardComponent.defaultProps = {
  brandsMetrics: [],
};

export default MyBrandsDashboardComponent;
