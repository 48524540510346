import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CardInfoComponent from './CardInfoComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { fetchCardInfo } from 'api/billing';
import { createCheckoutSession } from 'api/subscription_plan';
import { cardInfoSelector } from 'selectors';
import { stripeKeySelector } from 'selectors/railsContextSelectors';
import { cardInfoShape, withRouterPropTypes } from 'common/prop_types_shapes';

class CardInfoContainer extends React.PureComponent {
  static propTypes = {
    ...withRouterPropTypes,
    cardInfo: cardInfoShape,
    dispatch: PropTypes.func,
    stripeKey: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchCardInfo(dispatch);
  }

  handleUpdateCardClick = async () => {
    const {
      dispatch,
      stripeKey,
      location: { pathname },
    } = this.props;

    const checkoutSession = await createCheckoutSession(dispatch, pathname);
    // eslint-disable-next-line no-undef
    const stripe = Stripe(stripeKey);
    stripe.redirectToCheckout({ sessionId: checkoutSession.id });
  };

  render() {
    const mutableCardInfo = this.props.cardInfo.toJS();

    return (
      <CardInfoComponent
        cardInfo={mutableCardInfo}
        onUpdateCardClick={this.handleUpdateCardClick}
      />
    );
  }
}

function select(state, ownProps) {
  const cardInfo = cardInfoSelector(state, ownProps);
  const stripeKey = stripeKeySelector(state, ownProps);

  return { cardInfo, stripeKey };
}

export default withRouter(ConnectStoreHOC(connect(select)(CardInfoContainer)));
