import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import DeleteEoProgressionsButtonComponent from './DeleteEoProgressionsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { deleteEoProgressions } from 'api/eoProgression';
import { selectedIdsList } from 'common/prop_types_shapes';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { currentIdSelector, uiSelector } from 'selectors';

const DeleteEoProgressionsButtonContainer = ({ dispatch, eoCampaignId, selectedIds }) => {
  function handleDelete() {
    deleteEoProgressions(dispatch, eoCampaignId, selectedIds.toJS());
  }

  return <DeleteEoProgressionsButtonComponent onDelete={handleDelete} selectedIds={selectedIds} />;
};

DeleteEoProgressionsButtonContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    eoCampaignId,
    selectedIds,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(DeleteEoProgressionsButtonContainer);
