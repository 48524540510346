import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';

import { eoCustomFieldShape } from 'common/propTypesShapes/eoCustomFields';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import SubmitButtonComponent from 'components/auth/submit_button_component';

import './eoCustomFieldFormPopup.scss';

function buildInitialState(predefinedEoCustomFieldName) {
  return {
    name: predefinedEoCustomFieldName || '',
    defaultValue: '',
    errors: {},
  };
}

function reducer(state, action) {
  const { value, type } = action;

  const newState = cloneDeep(state);

  newState[type] = value;

  return newState;
}

function buildFormInputs(isExistingEoCustomField) {
  return [
    {
      name: 'name',
      type: 'text',
      isRequired: true,
      isVisible: !isExistingEoCustomField,
    },
    {
      name: 'defaultValue',
      type: 'text',
      isVisible: true,
    },
  ].filter((input) => input.isVisible);
}

const EoCustomFieldFormPopupComponent = ({
  onCancel,
  onSave,
  onSubmitCb,
  eoCustomField,
  predefinedEoCustomFieldName,
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    eoCustomField || buildInitialState(predefinedEoCustomFieldName),
  );

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const resp = await onSave(state);

    dispatch({ type: 'errors', value: resp.errors });

    if (isEmpty(resp.errors)) {
      onSubmitCb && onSubmitCb(resp);
      onCancel();
    }
  }

  const { errors } = state;

  const isExistingEoCustomField = !!eoCustomField;

  const formInputs = buildFormInputs(isExistingEoCustomField);

  return (
    <Modal show className="eo-merge-tags-form-popup">
      <Modal.Header>
        <Modal.Title>
          {translate('eoCustomField.eoCustomFieldFormPopup.title')(isExistingEoCustomField)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form id="eo-merge-tags-form" className="eo-merge-tags-form-popup__form" noValidate>
          {formInputs.map((input) => {
            const { name, isRequired, type } = input;
            const value = state[name] || '';
            const message = errors[name]
              ? { type: 'error', text: errors[name] }
              : {
                  type: 'hint',
                  text: translate(`eoCustomField.eoCustomFieldFormPopup.inputs.${name}.hint`),
                };

            return (
              <InputComponent
                isMandatory={isRequired}
                key={name}
                label={translate(`eoCustomField.eoCustomFieldFormPopup.inputs.${name}.label`)}
                message={message}
                onInputChange={setInputValue(name)}
                type={type}
                value={value}
              />
            );
          })}
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="eo-merge-tags-form-popup__btn-group">
          <div className="eo-merge-tags-form-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('uiComponents.button.cancel')}
            </ButtonComponent>
          </div>

          <div className="eo-merge-tags-form-popup__btn">
            <SubmitButtonComponent
              form="eo-merge-tags-form"
              isGreen
              onClick={handleSubmit}
              text={translate('eoCustomField.eoCustomFieldFormPopup.buttons.submit')(
                isExistingEoCustomField,
              )}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EoCustomFieldFormPopupComponent.propTypes = {
  eoCustomField: eoCustomFieldShape,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSubmitCb: PropTypes.func,
  predefinedEoCustomFieldName: PropTypes.string,
};

export default EoCustomFieldFormPopupComponent;
