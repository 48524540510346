import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import { tooltipShape } from '../../common/prop_types_shapes';

import './overlay_with_tooltip_component.scss';


const OverlayWithTooltipComponent = ({ children, tooltip }) => {
  if (!tooltip) {
    return children;
  }

  const tooltipClasses = classnames('overlay-with-tooltip-component', {
    'overlay-with-tooltip-component_fit-content': tooltip.fitContent,
    [`overlay-with-tooltip-component_${tooltip.color}`]: tooltip.color,
    [`overlay-with-tooltip-component_${tooltip.placement}`]: tooltip.placement,
  });

  const tooltipComponent = (
    <Tooltip id="tooltip" className={tooltipClasses} >
      {tooltip.text}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement={tooltip.placement} overlay={tooltipComponent}>
      {children}
    </OverlayTrigger>
  );
};

OverlayWithTooltipComponent.propTypes = {
  children: PropTypes.node,
  tooltip: tooltipShape,
};

export default OverlayWithTooltipComponent;
