import React from 'react';

import SearchEoActiveHoursInput from './SearchEoActiveHoursInput';
import DeleteEoActiveHoursButton from './DeleteEoActiveHoursButton';
import AddEoActiveHourButton from './AddEoActiveHourButton';

import './eoActiveHoursBulkActions.scss';
const EoActiveHoursBulkActionsComponent = () => {
  return (
    <div className="eo-active-hours-bulk-actions">
      <div className="eo-active-hours-bulk-actions__row">
        <div className="eo-active-hours-bulk-actions__row-item">
          <SearchEoActiveHoursInput />
          <span className="vertical-separator" />
          <DeleteEoActiveHoursButton />
        </div>

        <div className="eo-active-hours-bulk-actions__row-item">
          <AddEoActiveHourButton />
        </div>
      </div>
    </div>
  );
};

export default EoActiveHoursBulkActionsComponent;
