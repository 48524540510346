import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

const RunIntersectAnalysButtonComponent = ({ onClick }) => {
  const tooltip = {
    text: translate('intersectAnalysis.buttons.runIntersectAnalysis.tooltip'),
    placement: 'top',
    color: 'black',
  };

  return (
    <ButtonComponent isInitial isGreen onClick={onClick} tooltip={tooltip}>
      {translate('intersectAnalysis.buttons.runIntersectAnalysis.label')}
    </ButtonComponent>
  );
};

RunIntersectAnalysButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RunIntersectAnalysButtonComponent;
