import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import Link from 'components_linkio/link';
import StepsComponent from 'components/steps_component';

const stepsItems = [
  {
    title: translate('stepsComponent.ahrefsStep.title'),
    body: (
      <>
        {translate('stepsComponent.ahrefsStep.bodyFirstPart')}
        <Link href={Routes.automation_settings_path({ format: null })}>
          {translate('stepsComponent.ahrefsStep.linkText')}
        </Link>
        {translate('stepsComponent.ahrefsStep.bodySecondPart')}
      </>
    ),
    optional: true,
  },
];

const BrandsStepsComponent = ({ onCloseBrandsOnboarding }) => {
  return (
    <StepsComponent items={stepsItems} onClose={onCloseBrandsOnboarding} />
  );
};

BrandsStepsComponent.propTypes = {
  onCloseBrandsOnboarding: PropTypes.func.isRequired,
};

export default BrandsStepsComponent;
