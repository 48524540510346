import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Map as iMap } from 'immutable';

import StatusTableComponent from './status_table_component';
import DuplicatesTableComponent from './duplicates_table_component';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { openSubscriptionLimitReachedPopup } from 'actions/popup_actions';
import { updateDl } from 'api/destination_links';
import { fetchDlDuplicates } from 'api/dl_duplicates';
import { enqueueDestinationLinkCrawling } from 'api/crawler';

import { translate } from 'common/i18n';

import { startMouseFlowPageView, trackHelpcrunchEvent } from 'common/utils';

import { generateDestinationLinkStatusRows } from 'common/tables/utils';

import {
  destinationLinkShape,
  destinationLinksMap,
  optionsMap,
  policiesShape,
  subscriptionPoliciesShape,
} from 'common/prop_types_shapes';

import { destinationLinkByIdSelector, dlDuplicatesSelector, optionsSelector } from 'selectors';

import { policiesSelector, subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';

import crawlersStatusService from 'pages/Pages/PagesList/PageItem/PageDls/services/crawlersStatusService';
import ExternalLinkIcon from 'common/icons/external_link';
import WorldIcon from 'common/icons/world_icon';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';

import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import { crawlingInProgress } from 'pages/Pages/utils';
import Link from 'components_linkio/link';

import './dl_details_popup_component.scss';

export const DlDetailsPopupComponent = ({
  anchorTypeOptions,
  destinationLink,
  destinationLinkId,
  dispatch,
  dlDuplicates,
  includeByUserOptions,
  includedOptions,
  onClose,
  onFetchCurrentSubscription,
  policies,
  subscriptionPolicies,
}) => {
  const [publishedLink, setPublishedLink] = React.useState(destinationLink.get('publishedLink'));
  const [showDuplicatesTable, setShowDuplicatesTable] = React.useState(false);

  React.useEffect(() => {
    onFetchCurrentSubscription();
    startMouseFlowPageView(document.location.pathname);
  }, []);

  const publishedLinkHasChanged = publishedLink !== destinationLink.get('publishedLink');

  function handleClickUpgradeSubscription(event) {
    event && event.preventDefault();

    dispatch(openSubscriptionLimitReachedPopup({}));
  }

  function handleCloseClick() {
    if (publishedLink === destinationLink.get('publishedLink')) {
      onClose();
      return;
    }

    const oldDl = destinationLink.set('publishedLink', publishedLink).toJS();
    const brandPageId = destinationLink.get('brandPageId');

    updateDl(dispatch, brandPageId, [oldDl]);

    onClose();
  }

  // WARN: CrawlNow button must be able to crawl excluded dls
  async function handleCrawlNowClick() {
    trackHelpcrunchEvent('click.crawl.now');

    // trigger saving of DL in order to avoid invalid links crawling
    if (publishedLinkHasChanged) {
      return handleSaveClick({ triggeredByCrawlNow: true });
    }

    const canUseCrawlingInSubscription = subscriptionPolicies.getIn(['crawlers', 'canUse']);

    if (canUseCrawlingInSubscription) {
      return await enqueueDestinationLinkCrawling(
        dispatch,
        destinationLink.get('brandPageId'),
        destinationLink.get('id'),
      );
    }

    return dispatch(openSubscriptionLimitReachedPopup({}));
  }

  async function handleSaveClick({ triggeredByCrawlNow = false }) {
    if (publishedLinkHasChanged) {
      const newDl = destinationLink.set('publishedLink', publishedLink).toJS();
      const brandPageId = destinationLink.get('brandPageId');
      const brandId = destinationLink.get('brandId');

      const resp = await updateDl(dispatch, brandPageId, [newDl], brandId);

      if ((resp || {}).type === 'error' || !isEmpty(resp[0].errors)) {
        return;
      }
    }

    // trigger crawling of DL in order to handle 'saved by clicking Crawl Now btn' use case
    if (triggeredByCrawlNow) {
      handleCrawlNowClick();
      return;
    }

    onClose();
  }

  function handlePublishedLinkChange(event) {
    setPublishedLink(((event || {}).target || {}).value);
  }

  function handleShowDuplicateLinksClick() {
    setShowDuplicatesTable((prevShowDuplicatesTable) => {
      if (!prevShowDuplicatesTable) {
        const brandPageId = destinationLink.get('brandPageId');

        fetchDlDuplicates(dispatch, brandPageId, destinationLinkId);
      }

      return !prevShowDuplicatesTable;
    });
  }

  async function handleUpdateDl(dl) {
    const { brandPageId } = dl;

    await updateDl(dispatch, brandPageId, [dl]);
  }

  const crawlersStatus = crawlersStatusService(destinationLink);
  const needToShowSpinner = crawlingInProgress(crawlersStatus.status);
  const lastCheck = crawlersStatus.lastCheck;

  const canUseCrawlers = subscriptionPolicies.getIn(['crawlers', 'canUse'], false);
  const dlStatusRows = generateDestinationLinkStatusRows(destinationLink, canUseCrawlers);
  const publishedLinkMessage =
    destinationLink.get('errors', iMap()).size > 0
      ? {
          text: destinationLink.getIn(['errors', 'publishedLink']),
          type: 'error',
        }
      : void 0;
  const hasPublishedLink = !!publishedLink;
  const destinationLinkHasErrors = destinationLink.get('errors', iMap()).size > 0;
  const crawlNowBtnIsDisabled = !canUseCrawlers || destinationLinkHasErrors || !hasPublishedLink;

  const publishedLinkRowClasses = classnames({
    'dl-details-popup-component__published-link': true,
    'dl-details-popup-component__published-link_with-error': publishedLinkMessage,
  });

  const externalLinkIconClasses = classnames({
    'dl-details-popup-component__external-link-icon': true,
    'dl-details-popup-component__external-link-icon_with-error': publishedLinkMessage,
  });

  const worldIconClasses = classnames({
    'dl-details-popup-component__world-icon': true,
    'dl-details-popup-component__world-icon_with-error': publishedLinkMessage,
  });

  const subscriptionLimitRibbonMessage = translate('subscriptionLimitRibbon.message')(
    translate('subscriptionLimitRibbon.limited.crawling'),
  );

  return (
    <Modal show className="dl-details-popup-component__modal">
      <Modal.Header>
        <Modal.Title>{translate('dlDetailsPopup.title')}</Modal.Title>
        <div className={publishedLinkRowClasses}>
          <span className={worldIconClasses}>
            <WorldIcon />
          </span>
          <InputComponent
            message={publishedLinkMessage}
            onInputChange={handlePublishedLinkChange}
            value={publishedLink}
          />
          <Link
            className={externalLinkIconClasses}
            href={destinationLink.get('publishedLink')}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <ExternalLinkIcon />
          </Link>
          <ButtonComponent
            isBlue
            onClick={handleCrawlNowClick}
            isDisabled={crawlNowBtnIsDisabled}
            isLoading={needToShowSpinner}
          >
            {translate('dlDetailsPopup.buttons.crawlNow')}
          </ButtonComponent>
        </div>
        <div className="dl-details-popup-component__status-report">
          {translate('dlDetailsPopup.statusReport')}
          <div className="dl-details-popup-component__last-check-text">
            {translate('dlDetailsPopup.lastCheck')}
            &nbsp;&nbsp;
            {lastCheck ? new Date(lastCheck).toLocaleString() : '-'}
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        {!canUseCrawlers && (
          <SubscriptionLimitRibbonComponent>
            {subscriptionLimitRibbonMessage}
            <UpgradeSubscriptionLink onClick={handleClickUpgradeSubscription} />
          </SubscriptionLimitRibbonComponent>
        )}
        <StatusTableComponent
          rows={dlStatusRows}
          onShowDuplicateLinksClick={handleShowDuplicateLinksClick}
          onClickUpgradeSubscription={handleClickUpgradeSubscription}
        />
        {showDuplicatesTable && (
          <div>
            <div className="dl-details-popup-component__duplicate-table-title">
              {translate('dlDetailsPopup.tables.duplicates.title')}
            </div>
            <DuplicatesTableComponent
              anchorTypeOptions={anchorTypeOptions}
              canUseCrawlers={canUseCrawlers}
              dlDuplicates={dlDuplicates}
              includeByUserOptions={includeByUserOptions}
              includedOptions={includedOptions}
              onUpdateDl={handleUpdateDl}
              policies={policies}
              key={JSON.stringify(dlDuplicates.size)}
            />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="dl-details-popup-component__button">
          <ButtonComponent isWhite onClick={handleCloseClick}>
            {translate('dlDetailsPopup.buttons.close')}
          </ButtonComponent>
        </div>
        <div className="dl-details-popup-component__button">
          <ButtonComponent isGreen onClick={handleSaveClick}>
            {translate('dlDetailsPopup.buttons.save')}
          </ButtonComponent>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

DlDetailsPopupComponent.propTypes = {
  anchorTypeOptions: optionsMap.isRequired,
  destinationLink: destinationLinkShape,
  destinationLinkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatch: PropTypes.func,
  dlDuplicates: destinationLinksMap,
  includeByUserOptions: optionsMap.isRequired,
  includedOptions: optionsMap.isRequired,
  onClose: PropTypes.func.isRequired,
  onFetchCurrentSubscription: PropTypes.func,
  policies: policiesShape.isRequired,
  subscriptionPolicies: subscriptionPoliciesShape,
};

function select(state, ownProps) {
  const destinationLink = destinationLinkByIdSelector(state, ownProps);
  const dlDuplicates = dlDuplicatesSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  const anchorTypeOptions = options.get('anchorTypeOptions');
  const includeByUserOptions = options.get('includeByUserOptions');
  const includedOptions = options.get('includedOptions');

  return {
    anchorTypeOptions,
    destinationLink,
    dlDuplicates,
    includeByUserOptions,
    includedOptions,
    policies,
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(DlDetailsPopupComponent));
