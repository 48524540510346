import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchSubscriptionPlans } from 'api/subscription_plan';

import { trackHelpcrunchEvent } from 'common/utils';
import { translate } from 'common/i18n';

import { somethingIsLoadingSelector, subscriptionPlansSelector } from 'selectors';

import {
  currentMarketerSelector,
  currentSubscriptionSelector,
  currentUserSelector,
  policiesSelector,
  stripeKeySelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import {
  userShape,
  marketerShape,
  policiesShape,
  subscriptionPlansMap,
  subscriptionShape,
  subscriptionPoliciesShape,
} from 'common/prop_types_shapes';

import SubscriptionPlansComponent from 'components/subscriptions/subscription_plans_component';
import Breadcrumbs from 'components/NewBreadcrumbs';

class SubscriptionPlansPage extends React.PureComponent {
  static propTypes = {
    cmarketer: marketerShape,
    currentSubscription: subscriptionShape.isRequired,
    cuser: userShape,
    dispatch: PropTypes.func,
    policies: policiesShape,
    somethingIsLoading: PropTypes.bool,
    stripeKey: PropTypes.string,
    subscriptionPlans: subscriptionPlansMap,
    subscriptionPolicies: subscriptionPoliciesShape,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchSubscriptionPlans(dispatch);
    trackHelpcrunchEvent('view.page.subscriptionPlans');
  }

  render() {
    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.TextItem>{translate('authForms.breadcrumbsTitle')}</Breadcrumbs.TextItem>
        </Breadcrumbs>
        <SubscriptionPlansComponent {...this.props} />
      </>
    );
  }
}

function select(state, ownProps) {
  const cmarketer = currentMarketerSelector(state, ownProps);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const cuser = currentUserSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);
  const somethingIsLoading = somethingIsLoadingSelector(state, ownProps);
  const stripeKey = stripeKeySelector(state, ownProps);
  const subscriptionPlans = subscriptionPlansSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  return {
    cmarketer,
    currentSubscription,
    cuser,
    policies,
    somethingIsLoading,
    stripeKey,
    subscriptionPlans,
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(SubscriptionPlansPage));
