import { translate } from 'common/i18n';

import AnchorTextSelectHeaderComponent from 'pages/Pages/PagesList/PageItem/PageDls/DlsTable/AnchorText/SelectHeader';
import AnchorTextSelectItemComponent from 'pages/Pages/PagesList/PageItem/PageDls/DlsTable/AnchorText/SelectItem';

const selectProps = {
  blurInputOnSelect: false,
  autoFocus: true,
  isClearable: true,
  isCreatable: false,
  menuIsOpen: true, // in order to get correctAtpWrapperBottomSpaceIfNeeded working
  openMenuOnFocus: true,
  placeholder: translate('destinationLink.defaultPlaceholder'),
  skinOptions: {
    inTable: true,
  },
  triggerChangeOnBlur: true,
};

const buildAnchorTextSelectOptions = (options) => {
  return [
    {
      label: AnchorTextSelectHeaderComponent(),
      options,
    },
  ];
};

const anchorTextSelectProps = {
  ...selectProps,
  cacheOptions: true,
  defaultOptions: true,
  isAsync: true,
  getOptionLabel: AnchorTextSelectItemComponent,
  placeholder: translate('destinationLink.anchorText.placeholder'),
};

const anchorTypeSelectProps = {
  ...selectProps,
  placeholder: translate('destinationLink.anchorType.placeholder'),
};

const statusSelectProps = {
  ...selectProps,
  placeholder: translate('destinationLink.status.placeholder'),
};

const sourceSelectProps = {
  ...selectProps,
  isCreatable: true,
  placeholder: translate('destinationLink.source.placeholder'),
};

export {
  anchorTextSelectProps,
  anchorTypeSelectProps,
  buildAnchorTextSelectOptions,
  sourceSelectProps,
  statusSelectProps,
};
