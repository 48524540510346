import { isEmpty } from 'lodash';

import {
  deleteToAPI,
  handleAPIError,
  getFromAPI,
  patchToAPI,
  postToAPI,
  processObjToSnakeCaseDeep,
} from './utils';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import { uploadTmpFileToS3 } from './s3';

import { translate } from 'common/i18n';
import { showSuccessMessage, showErrorMessage } from 'common/utils';

import {
  addProspectsToDripListFailure,
  addProspectsToDripListStart,
  addProspectsToDripListSuccess,
  createProspectFailure,
  createProspectStart,
  createProspectSuccess,
  deleteProspectsFailure,
  deleteProspectsFromDripListFailure,
  deleteProspectsFromDripListStart,
  deleteProspectsFromDripListSuccess,
  deleteProspectsStart,
  deleteProspectsSuccess,
  fetchProspectsFailure,
  fetchProspectFailure,
  fetchProspectsStart,
  fetchProspectStart,
  fetchProspectsSuccess,
  fetchProspectSuccess,
  importProspectsFailure,
  startCampaignForProspectsFailure,
  startCampaignForProspectsStart,
  startCampaignForProspectsSuccess,
  unsubscribeProspectFailure,
  updateProspectFailure,
  updateProspectStart,
  updateProspectSuccess,
  validateProspectEmailsFailure,
} from 'actions/prospectsActions';

import { resetSelectedIds } from 'actions/ui_actions';
import { fetchProspectsPagySuccess } from 'actions/pagy_actions';

export async function fetchProspects(dispatch, params) {
  dispatch(fetchProspectsStart());

  try {
    const resp = await getFromAPI(Routes.prospects_path(), processObjToSnakeCaseDeep(params));

    dispatch(fetchProspectsSuccess(resp.prospects));
    dispatch(fetchProspectsPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(dispatch, err, fetchProspectsFailure, translate('errors.cantFetchProspects'));
  }
}

export async function fetchProspect(dispatch, prospectId) {
  dispatch(fetchProspectStart());

  try {
    const resp = await getFromAPI(Routes.prospect_path(prospectId));

    dispatch(fetchProspectSuccess(resp));

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchProspectFailure,
      translate('errors.cantFetchProspect'),
    );
  }
}

export async function createProspect(dispatch, prospect) {
  dispatch(createProspectStart());

  try {
    const resp = await postToAPI(Routes.prospects_path(), {
      prospect: processObjToSnakeCaseDeep(prospect),
    });

    const { prospect: prospectFromResp, skipped_eo_drip_lists_count } = resp;

    if (isEmpty(prospectFromResp.errors)) {
      dispatch(createProspectSuccess(prospectFromResp));
      showSuccessMessage(
        dispatch,
        translate('prospects.messages.createSuccess')(skipped_eo_drip_lists_count),
      );
    } else {
      dispatch(createProspectFailure(prospectFromResp.errors));
    }

    return processObjToCamelCaseDeep(prospectFromResp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createProspectFailure,
      translate('errors.cantCreateProspect'),
    );
  }
}

export async function deleteProspects(dispatch, ids) {
  dispatch(deleteProspectsStart());

  try {
    await deleteToAPI(Routes.prospects_path({ ids }));

    showSuccessMessage(dispatch, translate('prospects.messages.deleteSuccess')(ids.length));

    dispatch(resetSelectedIds());
    dispatch(deleteProspectsSuccess(ids));
  } catch (err) {
    handleAPIError(dispatch, err, deleteProspectsFailure, translate('errors.cantDeleteProspects'));
  }
}

export async function deleteProspectsFromDripLists(dispatch, ids, params) {
  dispatch(deleteProspectsFromDripListStart());

  const { deleteFromAllDripLists, eoDripListIds } = params;

  try {
    const resp = await patchToAPI(
      Routes.delete_from_drip_lists_prospects_path({
        ids,
        eo_drip_list_ids: eoDripListIds,
        delete_from_all_drip_lists: deleteFromAllDripLists,
      }),
    );

    showSuccessMessage(
      dispatch,
      translate('prospects.messages.deleteProspectsFromDripListSuccess')(ids.length),
    );

    dispatch(resetSelectedIds());

    dispatch(deleteProspectsFromDripListSuccess(resp.prospects));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteProspectsFromDripListFailure,
      translate('prospects.messages.cantDeleteProspectsFromDripList'),
    );
  }
}

export async function addProspectsToDripList(dispatch, ids, eoDripListId) {
  dispatch(addProspectsToDripListStart());

  try {
    const resp = await patchToAPI(
      Routes.add_to_drip_list_prospects_path({ ids, eo_drip_list_id: eoDripListId }),
    );

    const { prospects, skipped_prospects_count, updated_prospects_count } = resp;

    showSuccessMessage(
      dispatch,
      translate('prospects.messages.addProspectsToDripListSuccess')(
        updated_prospects_count,
        skipped_prospects_count,
      ),
    );

    dispatch(resetSelectedIds());

    dispatch(addProspectsToDripListSuccess(prospects));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      addProspectsToDripListFailure,
      translate('prospects.messages.cantAddProspectsToDripList'),
    );
  }
}

export async function startCampaignForProspects(dispatch, ids, eoCampaignId) {
  dispatch(startCampaignForProspectsStart());

  try {
    const resp = await patchToAPI(
      Routes.start_eo_campaign_prospects_path({ ids, eo_campaign_id: eoCampaignId }),
    );

    const { prospects, added_prospects_count, skipped_prospects_count } = resp;

    showSuccessMessage(
      dispatch,
      translate('prospects.messages.startCampaignForProspectsSuccess')(
        added_prospects_count,
        skipped_prospects_count,
      ),
    );

    dispatch(resetSelectedIds());

    dispatch(startCampaignForProspectsSuccess(prospects));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      startCampaignForProspectsFailure,
      translate('prospects.messages.cantStartCampaignForProspects'),
    );
  }
}

export async function updateProspect(dispatch, prospect) {
  dispatch(updateProspectStart());

  try {
    const resp = await patchToAPI(Routes.prospect_path({ id: prospect.id }), {
      prospect: processObjToSnakeCaseDeep(prospect),
    });

    const { prospect: prospectFromResp, skipped_eo_drip_lists_count } = resp;

    if (isEmpty(prospectFromResp.errors)) {
      dispatch(updateProspectSuccess(prospectFromResp));
      showSuccessMessage(
        dispatch,
        translate('prospects.messages.updateSuccess')(skipped_eo_drip_lists_count),
      );
    } else {
      dispatch(updateProspectFailure(prospectFromResp.errors));
    }

    return processObjToCamelCaseDeep(prospectFromResp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateProspectFailure,
      translate('errors.cantUpdateProspect'),
    );
  }
}

export async function fetchGoogleDriveFiles(dispatch) {
  try {
    const resp = await getFromAPI(Routes.google_drive_import_prospects_path());

    const redirectUri = (resp || {}).redirect_uri;

    if (redirectUri) {
      window.location = redirectUri;
    }

    const errors = (resp || {}).errors;

    if (!isEmpty(errors)) {
      showErrorMessage(dispatch, translate('prospects.messages.googleDriveAccessDenied'), true);
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      importProspectsFailure,
      translate('errors.cantImportProspects'),
    );
  }
}

export async function previewGoogleDriveImport(dispatch, fileId) {
  try {
    const resp = await postToAPI(Routes.preview_import_prospects_path(), {
      file_drive_id: fileId,
      source: 'drive',
    });

    const redirectUri = (resp || {}).redirect_uri;

    if (redirectUri) {
      window.location = redirectUri;
    }

    const errors = (resp || {}).errors;
    if (!isEmpty(errors)) {
      showErrorMessage(
        dispatch,
        translate('prospects.messages.cantParseProspectsCsv')({ errors: errors.join('; ') }),
        true,
      );
    }

    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      importProspectsFailure,
      translate('errors.cantImportProspects'),
    );
  }
}

export async function previewCsvImport(dispatch, file) {
  // pre-flight check
  if (!file) {
    showErrorMessage(dispatch, translate('importCsv.messages.csvFileIsRequired'), true);
    return { type: 'error' };
  }

  try {
    const s3response = await uploadTmpFileToS3(file);

    if (!s3response.ok) {
      return handleAPIError(
        dispatch,
        s3response.error,
        importProspectsFailure,
        translate('errors.cantImportProspects'),
      );
    }

    const resp = await postToAPI(Routes.preview_import_prospects_path(), {
      file_s3_id: s3response.fileS3Id,
      source: 'csv',
    });

    const errors = (resp || {}).errors;
    if (!isEmpty(errors)) {
      showErrorMessage(
        dispatch,
        translate('prospects.messages.cantParseProspectsCsv')({ errors: errors.join('; ') }),
        true,
      );
    }

    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      importProspectsFailure,
      translate('errors.cantImportProspects'),
    );
  }
}

export async function importProspectsFromCsv(
  dispatch,
  fieldsToRemap,
  eoCustomFieldsToRemap,
  fileS3Id,
  eoDripListIds,
  duplicatesMode,
) {
  try {
    const params = {
      fields: fieldsToRemap,
      eoCustomFieldValues: eoCustomFieldsToRemap,
      duplicatesMode,
      eoDripListIds,
    };

    await postToAPI(Routes.import_prospects_path(), {
      file_s3_id: fileS3Id,
      ...processObjToSnakeCaseDeep(params),
    });
  } catch (err) {
    handleAPIError(dispatch, err, importProspectsFailure, translate('errors.cantImportProspects'));
  }
}

export async function unsubscribeProspect(dispatch, encodedProspectId) {
  try {
    await postToAPI(
      Routes.unsubscribe_result_prospects_path({ encoded_prospect_id: encodedProspectId }),
    );

    return { isSuccess: true };
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      unsubscribeProspectFailure,
      translate('errors.cantUnsubscribeProspect'),
    );
  }
}

export async function validateProspectEmails(dispatch, prospectIds) {
  try {
    await postToAPI(Routes.validate_emails_prospects_path({ ids: prospectIds }));
    showSuccessMessage(
      dispatch,
      translate('prospects.messages.prospectEmailsValidationStartSuccess'),
    );
    dispatch(resetSelectedIds());
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      validateProspectEmailsFailure,
      translate('prospects.messages.prospectEmailsValidationStartFailure'),
    );
  }
}
