import React from 'react';
import { compose } from 'lodash';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import SearchInput from 'components/SearchInput';

const SearchEoEmailTemplatesInputContainer = ({ changeQueryParams, queryParams }) => {
  function handleSubmitSearch(value) {
    const newQueryParams = {
      ...queryParams,
      search: value,
      pageNumber: 1,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <SearchInput withDefaultValue defaultValue={queryParams.search} onSubmit={handleSubmitSearch} />
  );
};

SearchEoEmailTemplatesInputContainer.propTypes = {
  ...withQueryParamsPropTypes,
};

export default compose(withQueryParams)(SearchEoEmailTemplatesInputContainer);
