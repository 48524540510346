import React from 'react';
import { Map as iMap } from 'immutable';
import { translate } from 'common/i18n';
import { insertNotAvailableIfNull } from 'common/utils';

import generateMetricsOverTime from 'utils/generateMetricsOverTime';

import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';
import SpinnerComponent from 'components_linkio/Spinner';

const pageItemMetricTitleField = (metricName, pageChildType) => {
  return (
    <div className="pages-list-header__item">
      <span className="pages-list-header__title-text">
        {translate(`${pageChildType}.metrics.${metricName}.title`)}
        <ExplanatoryTooltipComponent
          text={translate(`explanationMessages.brandPagesList.${metricName}`)}
        />
      </span>
    </div>
  );
};

const pageItemMetricValueField = (page, metricName, isLoading) => {
  const metrics = page.get('metrics', iMap());
  const overallMetricsOverTime = page.get('metricsOverTime', iMap());

  const isLinkioScore = metricName === 'linkioScore';
  const isAverageKeywordsRank = metricName === 'averageKeywordsRank';

  const metric =
    isLinkioScore || isAverageKeywordsRank
      ? insertNotAvailableIfNull(metrics.get(metricName))
      : metrics.get(metricName, 0);
  const metricOverTime = overallMetricsOverTime.get(metricName, iMap());

  const dateOptions = isLinkioScore ? { timePrefix: 'month', digits: 2 } : { timePrefix: 'month' };
  const metricsOverTime = generateMetricsOverTime(metricOverTime, dateOptions);

  return metricValueColumn(metric, metricsOverTime, isLoading);
};

const metricValueColumn = (metric, metricsOverTime, isLoading) => {
  return (
    <div className="brand-page-item-component__item brand-page-item-component__item_center">
      {isLoading && <SpinnerComponent isBlack />}
      {!isLoading && (
        <>
          {metric}
          <div className="brand-page-item-component__metric">
            <div className="brand-page-item-component__metric-plus">{metricsOverTime.plus}</div>
            <div className="brand-page-item-component__metric-minus">{metricsOverTime.minus}</div>
          </div>
        </>
      )}
    </div>
  );
};

export { pageItemMetricTitleField, pageItemMetricValueField };
