import React from 'react';

const WatchIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="#002F4A"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="4 0 24 24"
  >
    <circle cx="12" cy="12" r="7" />
    <path d="M12 9v3l1.5 1.5M16.51 17.35l-.35 3.83a2 2 0 01-2 1.82H9.83a2 2 0 01-2-1.82l-.35-3.83m.01-10.7l.35-3.83A2 2 0 019.83 1h4.35a2 2 0 012 1.82l.35 3.83" />
  </svg>
);

WatchIcon.defaultProps = {
  height: '24px',
  width: '24px',
};

export default WatchIcon;
