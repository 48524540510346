import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import { PageParentContext } from './context';
import PagesComponent from './PagesComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { currentIdSelector } from 'selectors';
import { fetchPages } from 'api/brand_page';
import { fetchPageTypes } from 'api/page_type';
import { policiesSelector } from 'selectors/railsContextSelectors';
import { policiesShape } from 'common/prop_types_shapes';

const PagesContainer = ({
  brandId,
  dispatch,
  history,
  pageChildType,
  pageParentId,
  pageParentType,
  policies,
  queryParams,
}) => {
  const { search = '' } = queryParams;

  React.useEffect(() => {
    fetchPageTypes(dispatch);
  }, []);

  React.useEffect(() => {
    const fetchingParams = {
      search,
    };

    fetchPages(dispatch, pageParentType, pageParentId, fetchingParams);
  }, [search]);

  function handleAddCompetitorsClick() {
    const path = Routes.brand_page_tab_path(brandId, pageParentId, 'bulk_competitors_setup', {
      pagesFilter: queryParams.pagesFilter,
      format: null,
    });

    history.push(path);
  }

  return (
    <PageParentContext.Provider value={{ pageChildType, pageParentType, pageParentId }}>
      <PagesComponent onAddCompetitorsClick={handleAddCompetitorsClick} policies={policies} />
    </PageParentContext.Provider>
  );
};

PagesContainer.propTypes = {
  brandId: PropTypes.string,
  dispatch: PropTypes.func,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageParentType: PropTypes.oneOf(['brand_page', 'brand']).isRequired,
  policies: policiesShape,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);

  return {
    brandId,
    policies,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(PagesContainer);
