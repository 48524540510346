export default function correctAtpWrapperBottomSpaceIfNeeded({ tableType }) {
  const tableWrapperClassName =
    tableType === 'competitors' ? '.competitors-atp-table__wrapper' : '.atp-table__wrapper';

  const atpTableWrapperNode = document.querySelector(
    `.brand-page-container ${tableWrapperClassName}`,
  );
  const selectDropdownNode = document.querySelector(
    `${tableWrapperClassName} .select__in-table div`,
  );

  const dropdownNode = atpTableWrapperNode
    ? selectDropdownNode || atpTableWrapperNode.querySelector('.date-time-picker')
    : null;

  // If dropdown was opened, then check whether this dropdown was overlayed by the bottom border. If so, then add
  // an extra space on the bottom.
  if (dropdownNode) {
    // Add an extra 50px in order to make a clear gap between the dropdown and bottom border.
    const bottomBorderPosition =
      document.querySelector('.container-with-scroll-component').getBoundingClientRect().bottom -
      50;

    // Find out the distance between the dropdown and bottom border
    let distance = dropdownNode.getBoundingClientRect().bottom - bottomBorderPosition;

    // Since anchor text select is working asynchronously, we can't count its dropdown height properly.
    // So let's assume it has 300px and add 300 to distance when anchor text select dropdown was opened.
    const anchorTextSelectNode = document.querySelector(
      '.atp-table__anchor-text-column .select__in-table',
    );

    if (anchorTextSelectNode && atpTableWrapperNode.style.paddingBottom === '0px') {
      distance = anchorTextSelectNode.getBoundingClientRect().bottom + 300 - bottomBorderPosition;
    }

    // Add a needed extra space.
    if (distance > 0) {
      atpTableWrapperNode.style.paddingBottom = `${distance}px`;
    }
  }

  // If dropdown was closed and we still have an extra space on the bottom,
  // remove this space since we don't need it anymore.
  if (atpTableWrapperNode && !dropdownNode && atpTableWrapperNode.style.paddingBottom !== '0px') {
    atpTableWrapperNode.style.paddingBottom = 0;
  }
}
