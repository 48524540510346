import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoCustomFieldsTableComponent from './EoCustomFieldsTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { eoCustomFieldsList } from 'common/propTypesShapes/eoCustomFields';
import { eoCustomFieldsSelector, eoCustomFieldsPagySelector } from 'selectors/eoCustomFieldsSelector';
import { fetchEoCustomFields } from 'api/eoCustomField';
import { resetSelectedIds, toggleSelectedIds } from 'actions/ui_actions';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import { uiSelector } from 'selectors';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const EoCustomFieldsTableContainer = ({
  changeQueryParams,
  dispatch,
  eoCustomFields,
  eoCustomFieldsPagy,
  queryParams,
  selectedIds,
}) => {
  const { pageNumber = 1, pagyItemsCount = 100, search = '', sorting = {} } = queryParams;

  React.useEffect(() => {
    const fetchingParams = {
      items: pagyItemsCount,
      page: pageNumber,
      search,
      sorting,
    };

    fetchEoCustomFields(dispatch, fetchingParams);
  }, [dispatch, pageNumber, pagyItemsCount, search, sorting.sortBy, sorting.sortingOrder]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectedIds());
    };
  }, [dispatch]);

  function handleToggleSelectedRows(eoCustomFieldsIds, isSelected) {
    dispatch(toggleSelectedIds(eoCustomFieldsIds, isSelected));
  }

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  const eoCustomFieldsCount = eoCustomFieldsPagy.get('count');

  return (
    <>
      {eoCustomFields.size > 0 && (
        <EoCustomFieldsTableComponent
          onTableHeaderClick={handleTableHeaderClick}
          onToggleSelectedRows={handleToggleSelectedRows}
          eoCustomFields={eoCustomFields}
          eoCustomFieldsCount={eoCustomFieldsCount}
          selectedIds={selectedIds}
          sorting={sorting}
        />
      )}
      {eoCustomFields.size === 0 && (
        <BlankStateCtaComponent>
          <TitleText>
            <strong>{translate('eoCustomField.table.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('eoCustomField.table.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

EoCustomFieldsTableContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCustomFields: eoCustomFieldsList,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoCustomFields = eoCustomFieldsSelector(state);
  const eoCustomFieldsPagy = eoCustomFieldsPagySelector(state);

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    eoCustomFields,
    eoCustomFieldsPagy,
    selectedIds,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoCustomFieldsTableContainer);
