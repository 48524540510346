import React from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import {
  policiesShape,
} from '../../../common/prop_types_shapes';

import ButtonComponent from '../../../components_linkio/button_component';

const PageCreateButton = ({ onClick, policies, pageChildType }) => {
  const canCreate = policies.getIn(['atp', 'canCreate']);

  return (
    canCreate &&
      <ButtonComponent isInitial isGreen onClick={onClick}>
        {translate(`${pageChildType}.buttons.addPage`)}
      </ButtonComponent>
  );
};

PageCreateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']),
  policies: policiesShape,
};

export default PageCreateButton;
