import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AhrefsSettingsEdit from './AhrefsSettingsEdit';
import AhrefsSettingsTable from './AhrefsSettingsTable';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';
import { trackHelpcrunchEvent } from 'common/utils';
import { optionsSelector } from 'selectors';

import {
  currentMarketerSelector,
  currentSubscriptionSelector,
} from 'selectors/railsContextSelectors';

import {
  ahrefsConnectionShape,
  marketerShape,
  optionsMap,
  subscriptionShape,
  withRouterPropTypes,
} from 'common/prop_types_shapes';

import {
  fetchConnectionInfo,
  revokeAhrefsAccess,
  updateConnectionSettings,
} from 'api/ahrefs_connection';

import {
  openGetBacklinksSuggestionPopup,
  openSubscriptionLimitReachedPopup,
} from 'actions/popup_actions';

import confirmationDialogue from 'components/confirmation_dialogue';

import './ahrefsSettings.scss';

class AhrefsSettingsContainer extends Component {
  static propTypes = {
    ahrefsConnectionInfo: ahrefsConnectionShape,
    currentMarketer: marketerShape,
    currentSubscription: subscriptionShape,
    dispatch: PropTypes.func,
    options: optionsMap,
    ...withRouterPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      settingsIsVisible: false,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      location: { search },
      history: { push },
      match: { path },
    } = this.props;

    if (search.indexOf('ahrefs_status=connected') !== -1) {
      trackHelpcrunchEvent('connect.ahrefs');
      push(path);
    }

    fetchConnectionInfo(dispatch);
  }

  handleAhrefsConnect = () =>
    (window.location = Routes.ahrefs_oauth_invitation_path({ format: null }));

  handleOpenGetBacklinksSuggestionPopup = (event) => {
    event && event.preventDefault();
    this.props.dispatch(openGetBacklinksSuggestionPopup());
  };

  handleClickUpgradeSubscription = (event) => {
    const { currentSubscription, dispatch } = this.props;

    event && event.preventDefault();

    dispatch(openSubscriptionLimitReachedPopup({}));
  };

  handleShowSettingsModal = () => this.setState({ settingsIsVisible: true });

  handleSubmitSettingsModal = async (value) => {
    const { dispatch } = this.props;

    await updateConnectionSettings(dispatch, value);
    this.closeSettingsModal();
  };

  handleCancelSettingsModal = () => this.closeSettingsModal();

  closeSettingsModal = () => this.setState({ settingsIsVisible: false });

  handleRevokeAccess = () => {
    confirmationDialogue({
      body: translate('confirmations.revokeAhrefsAccess.body'),
      onConfirm: this.handleRevokeAccessConfirm,
    });
  };

  handleRevokeAccessConfirm = async () => {
    const { ahrefsConnectionInfo, dispatch } = this.props;

    await revokeAhrefsAccess(dispatch, ahrefsConnectionInfo);
  };

  render() {
    const { settingsIsVisible } = this.state;
    const { ahrefsConnectionInfo, currentMarketer, currentSubscription, options } = this.props;

    return (
      <>
        <>
          <div className="ahrefs-connections-container__header">
            <span>{translate('ahrefsConnections.title')}</span>
            <a href="#" onClick={this.handleOpenGetBacklinksSuggestionPopup}>
              {translate('getBacklinksSuggestionOpener.title')}
            </a>
          </div>
          <div className="ahrefs-connections-container__description">
            {translate('ahrefsConnections.description')}
          </div>
          <AhrefsSettingsTable
            ahrefsConnectionInfo={ahrefsConnectionInfo}
            currentMarketer={currentMarketer}
            currentSubscription={currentSubscription}
            onClickUpgradeSubscription={this.handleClickUpgradeSubscription}
            onConnectClick={this.handleAhrefsConnect}
            onEditSettingsClick={this.handleShowSettingsModal}
            onRevokeAccessClick={this.handleRevokeAccess}
            options={options}
          />
        </>

        {settingsIsVisible && (
          <AhrefsSettingsEdit
            currentMarketer={currentMarketer}
            onCancel={this.handleCancelSettingsModal}
            onSubmit={this.handleSubmitSettingsModal}
          />
        )}
      </>
    );
  }
}

function select(state, ownProps) {
  const ahrefsConnectionInfo = state.get('ahrefsConnection');
  const currentMarketer = currentMarketerSelector(state, ownProps);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);

  return {
    ahrefsConnectionInfo,
    currentMarketer,
    currentSubscription,
    options,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(AhrefsSettingsContainer)));
