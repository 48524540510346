import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEqual } from 'lodash';

import { Provider, Header, Body } from 'reactabular-table';

import {
  ahrefsConnectionShape,
  marketerShape,
  optionsMap,
  subscriptionShape,
} from 'common/prop_types_shapes';

import { textColumn } from 'common/tables/columns';
import { translate } from 'common/i18n';

import BinIcon from 'common/icons/bin';
import columnsGenerator from 'common/tables/columns_generator';
import EditIcon from 'common/icons/edit';

import './ahrefsSettingsTable.scss';

class AhrefsSettingsTableComponent extends Component {
  static propTypes = {
    ahrefsConnectionInfo: ahrefsConnectionShape,
    currentMarketer: marketerShape,
    currentSubscription: subscriptionShape,
    onClickUpgradeSubscription: PropTypes.func,
    onConnectClick: PropTypes.func,
    onEditSettingsClick: PropTypes.func,
    onRevokeAccessClick: PropTypes.func,
    options: optionsMap,
  };

  constructor(props) {
    super(props);

    this.state = {
      props,
      columns: this.generateColumns(),
      rows: this.generateRows(this.props),
      generateRows: this.generateRows,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const needToUpdState = !isEqual(nextProps, prevState.props);

    if (needToUpdState) {
      const rows = prevState.generateRows(nextProps);

      return {
        rows,
        props: nextProps,
      };
    }

    return null;
  }

  generateRows = (props) => {
    const { ahrefsConnectionInfo, currentMarketer, currentSubscription } = props;

    const hasConnection = currentMarketer.get('has_ahrefs_connection');
    const syncInterval = currentMarketer.get('ahrefs_sync_interval');

    const rowsRequest = hasConnection
      ? `${ahrefsConnectionInfo.get('rowsLeft', 0)}/${ahrefsConnectionInfo.get('rowsLimit', 0)}`
      : translate('notAvailable');

    const canUseAhrefsInSubscription = currentSubscription.getIn([
      'subscriptionPolicies',
      'ahrefsIntegration',
      'canUse',
    ]);
    const linkText = canUseAhrefsInSubscription
      ? translate('ahrefsConnections.connectNew')
      : translate('ahrefsConnections.pleaseUpgrade');

    const subscription =
      hasConnection && canUseAhrefsInSubscription ? (
        ahrefsConnectionInfo.get('subscription')
      ) : (
        <a href="#" onClick={this.handleConnectClick(canUseAhrefsInSubscription)}>
          {linkText}
        </a>
      );

    const row = { rowsRequest, subscription, syncInterval };

    return [row];
  };

  generateColumns = () => {
    return columnsGenerator([
      textColumn({
        name: 'subscription',
        headerLabel: translate('ahrefsConnections.subscription.title'),
        className: 'ahrefs-connections-table__column',
      }),
      textColumn({
        name: 'rowsRequest',
        headerLabel: translate('ahrefsConnections.rowsRequest.title'),
        className: 'ahrefs-connections-table__column',
      }),
      textColumn({
        name: 'syncInterval',
        headerLabel: translate('ahrefsConnections.syncInterval.title'),
        cellValueFormatter: this.syncIntervalValueFormatter,
        className: 'ahrefs-connections-table__column',
      }),
      textColumn({
        name: 'editSettings',
        headerLabel: translate('ahrefsConnections.editSettings.title'),
        cellValueFormatter: this.editBtn,
        className: 'ahrefs-connections-table__column_action',
      }),
      textColumn({
        name: 'revokeAccess',
        headerLabel: translate('ahrefsConnections.revokeAccess.title'),
        cellValueFormatter: this.deleteBtn,
        className: 'ahrefs-connections-table__column_action',
      }),
    ]);
  };

  handleConnectClick = (canUseAhrefsInSubscription) => (event) => {
    const { onClickUpgradeSubscription, onConnectClick } = this.props;

    event && event.preventDefault();

    canUseAhrefsInSubscription ? onConnectClick() : onClickUpgradeSubscription();
  };

  syncIntervalValueFormatter = (value) => {
    const { currentMarketer, options } = this.props;
    const hasConnection = currentMarketer.get('has_ahrefs_connection');

    return hasConnection
      ? options.getIn(['ahrefsSyncIntervalOptions', value, 'label'])
      : translate('notAvailable');
  };

  deleteBtn = () => {
    const { currentMarketer, onRevokeAccessClick } = this.props;
    const hasConnection = currentMarketer.get('has_ahrefs_connection');

    return (
      hasConnection && (
        <BinIcon className="ahrefs-connections-table__action-icon" onClick={onRevokeAccessClick} />
      )
    );
  };

  editBtn = () => {
    const { currentMarketer, currentSubscription, onEditSettingsClick } = this.props;

    const hasConnection = currentMarketer.get('has_ahrefs_connection');
    const canUseCrawlerInSubscription = currentSubscription.getIn([
      'subscriptionPolicies',
      'crawlers',
      'canUse',
    ]);

    const classes = classnames({
      'ahrefs-connections-table__action-icon': true,
      'ahrefs-connections-table__action-icon_disabled': !canUseCrawlerInSubscription,
    });

    const eventHandler = canUseCrawlerInSubscription ? onEditSettingsClick : null;

    return hasConnection && <EditIcon className={classes} onClick={eventHandler} />;
  };

  render() {
    const { columns, rows } = this.state;

    return (
      <div>
        <div className="ahrefs-connections-table__wrapper">
          <Provider className="ahrefs-connections-table__all" columns={columns}>
            <Header />
            <Body rows={rows} rowKey="subscription" />
          </Provider>
        </div>
      </div>
    );
  }
}

export default AhrefsSettingsTableComponent;
