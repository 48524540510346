import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import RankTrackerBulkActionsComponent from './RankTrackerBulkActionsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { rankTrackerFetchingStatesMap, rankTrackerFiltersMap } from 'common/prop_types_shapes';

import { fetchKeywordsWithChanges } from 'api/rankTracker';

import { currentIdSelector } from 'selectors';
import {
  rankTrackerFetchingStatesSelector,
  rankTrackerFiltersSelector,
} from 'selectors/rankTrackerSelector';

import { setRankTrackerFilters } from 'actions/rankTrackerActions';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { RANK_TRACKER_DEFAULT_DATE_RANGE } from 'utils/constants';

class RankTrackerBulkActionsContainer extends React.PureComponent {
  static propTypes = {
    brandId: PropTypes.string,
    dispatch: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    rankTrackerFetchingStates: rankTrackerFetchingStatesMap,
    rankTrackerFilters: rankTrackerFiltersMap,
    ...withQueryParamsPropTypes,
  };

  handleChangeGroupByUrl = (value) => {
    const { dispatch, brandId, rankTrackerFetchingStates } = this.props;

    const {
      isKeywordsWithChangesByUrlFetched,
      isKeywordsWithChangesFetched,
    } = rankTrackerFetchingStates.toJS();

    const groupByUrl = value === 'right';
    const needToFetchKeywords = !isKeywordsWithChangesFetched || !isKeywordsWithChangesByUrlFetched;
    const filters = this.props.queryParams.filters || {};
    const { dateStart, dateEnd } = filters;

    dispatch(setRankTrackerFilters({ groupByUrl }));
    needToFetchKeywords &&
      fetchKeywordsWithChanges(dispatch, brandId, { groupByUrl, dateStart, dateEnd });
  };

  handleSubmitSearch = (value) => this.props.dispatch(setRankTrackerFilters({ searchTerm: value }));

  handleClickSettings = () => {
    this.props.history.push(
      Routes.edit_rank_tracker_settings_path(this.props.brandId, { format: null }),
    );
  };

  handleChangeDateRange = (dateStart, dateEnd) => {
    const { changeQueryParams, queryParams } = this.props;

    const filters = queryParams.filters || {};

    const newQueryParams = {
      ...queryParams,
      filters: {
        ...filters,
        dateStart: format(dateStart, 'yyyy-MM-dd'),
        dateEnd: format(dateEnd, 'yyyy-MM-dd'),
      },
      sorting: {},
    };

    changeQueryParams(newQueryParams);
  };

  handleResetClick = () => this.props.changeQueryParams({});

  render() {
    const { queryParams, rankTrackerFilters } = this.props;

    const filters = queryParams.filters || {};

    const dateStart = filters.dateStart || RANK_TRACKER_DEFAULT_DATE_RANGE.dateStart;
    const dateEnd = filters.dateEnd || RANK_TRACKER_DEFAULT_DATE_RANGE.dateEnd;

    const isDateFilterApplied = !!(filters.dateStart && filters.dateEnd);

    return (
      <RankTrackerBulkActionsComponent
        dateStart={dateStart}
        dateEnd={dateEnd}
        isDateFilterApplied={isDateFilterApplied}
        onChangeDateRange={this.handleChangeDateRange}
        onChangeGroupByUrl={this.handleChangeGroupByUrl}
        onClickSettings={this.handleClickSettings}
        onResetClick={this.handleResetClick}
        onSubmitSearch={this.handleSubmitSearch}
        rankTrackerFilters={rankTrackerFilters}
      />
    );
  }
}

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const rankTrackerFetchingStates = rankTrackerFetchingStatesSelector(state, ownProps);
  const rankTrackerFilters = rankTrackerFiltersSelector(state, ownProps);

  return {
    brandId,
    rankTrackerFetchingStates,
    rankTrackerFilters,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(RankTrackerBulkActionsContainer)));
