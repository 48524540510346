import { fromJS, List as iList } from 'immutable';
import { camelizeObjectDeep, mergeItemToList } from './utils';

import {
  CREATE_EO_DOMAIN__SUCCESS,
  DELETE_EO_DOMAINS__SUCCESS,
  FETCH_EO_DOMAINS__SUCCESS,
  FETCH_EO_DOMAIN__SUCCESS,
  UPDATE_EO_DOMAIN__SUCCESS,
} from 'actions/eoDomainActions';

const initialState = iList();

export default function eoDomainsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_DOMAINS__SUCCESS: {
      const { eoDomains } = action;

      state = fromJS(eoDomains.map(camelizeObjectDeep));

      break;
    }
    case FETCH_EO_DOMAIN__SUCCESS:
    case CREATE_EO_DOMAIN__SUCCESS:
    case UPDATE_EO_DOMAIN__SUCCESS: {
      const { eoDomain } = action;
      state = mergeItemToList(state, eoDomain, camelizeObjectDeep);

      break;
    }
    case DELETE_EO_DOMAINS__SUCCESS: {
      const { ids } = action;

      state = state.filter((eoDomain) => !ids.includes(eoDomain.get('id')));

      break;
    }
  }

  return state;
}
