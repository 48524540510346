import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import DeleteAccountPopupComponent from './DeleteAccountPopupComponent';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';

import {
  showSuccessMessageFromTranslation,
  startMouseFlowPageView,
} from 'common/utils';

import {
  subscriptionPlansMap,
  subscriptionShape,
} from 'common/prop_types_shapes';

import { deleteCurrentUserAccount } from 'api/user';
import { fetchSubscriptionPlans } from 'api/subscription_plan';
import { sendFeedback } from 'api/helpcrunch';

import {
  closeDeleteAccountPopup,
  openSwitchSubscriptionPlanPopup,
} from 'actions/popup_actions';

import { currentSubscriptionSelector } from 'selectors/railsContextSelectors';
import { subscriptionPlansSelector } from 'selectors';


class DeleteAccountPopupContainer extends React.Component {
  static propTypes = {
    currentSubscription: subscriptionShape,
    dispatch: PropTypes.func,
    subscriptionPlans: subscriptionPlansMap,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchSubscriptionPlans(dispatch);
    startMouseFlowPageView(document.location.pathname);
  }

  handleClickCancel = () => this.props.dispatch(closeDeleteAccountPopup());

  handleDeleteAccount = async (data) => {
    const { dispatch } = this.props;
    const { deletionReason, feedbackText, mayContactUser } = data;

    if (deletionReason) {
      const deletionReasonText = translate(
        `deleteAccountPopup.deletionReasons.${deletionReason}`,
      );

      // Need to send Feedback message before the actual deletion, cause mailer should know about
      // the current user and can't send message without it.
      await sendFeedback(dispatch, {
        message: `Deletion reason: ${deletionReasonText} - ${feedbackText} contact me: ${mayContactUser}`,
      });
    }

    const resp = await deleteCurrentUserAccount(dispatch);

    if ((resp || {}).type !== 'error' && get(window, 'location.href')) {
      showSuccessMessageFromTranslation(
        dispatch,
        'deleteAccountPopup.messages.thanksForFeedback',
      );

      this.handleClickCancel();
      window.location.href = Routes.root_path({ format: null });
    }
  };

  handleClickSwitchToFreeAccount = () => {
    const { dispatch, subscriptionPlans } = this.props;

    const freePlanId = subscriptionPlans
      .find((subscriptionPlan) => !subscriptionPlan.get('paid'))
      .get('id');

    dispatch(openSwitchSubscriptionPlanPopup({ selectedPlanId: freePlanId }));
  };

  render() {
    const { currentSubscription } = this.props;

    return (
      <DeleteAccountPopupComponent
        currentSubscription={currentSubscription}
        onClickCancel={this.handleClickCancel}
        onClickSwitchToFreeAccount={this.handleClickSwitchToFreeAccount}
        onDeleteAccount={this.handleDeleteAccount}
      />
    );
  }
}

function select(state, ownProps) {
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const subscriptionPlans = subscriptionPlansSelector(state, ownProps);

  return {
    currentSubscription,
    subscriptionPlans,
  };
}

export default ConnectStoreHOC(connect(select)(DeleteAccountPopupContainer));
