import React, { Component } from 'react';

import { translate } from '../../common/i18n';
import {
  optionsMap,
  policiesShape,
  percentageSchemesMap,
} from '../../common/prop_types_shapes';

import ButtonComponent from '../../components_linkio/button_component/';
import ContainerWithScrollComponent from '../container_with_scroll_component';
import ExplanatoryTooltipComponent from '../explanatory_tooltip_component';

import SavedPercentagesTableComponent from './saved_percentages_table_component';
import SavedPercentagesModalComponent from './saved_percentages_modal_component';

import './saved_percentages_component.scss';


class SavedPercentagesComponent extends Component {
  static propTypes = {
    anchorTypeOptions: optionsMap.isRequired,
    percentageSchemes: percentageSchemesMap.isRequired,
    policies: policiesShape.isRequired,
  }

  state = {
    showPercentagesModalFor: null,
  }

  handleAddPercentageClick = () => this.setState({ showPercentagesModalFor: 'newCustomScheme' });
  handleEditPercentageClick = (percentageId) => this.setState({ showPercentagesModalFor: percentageId });
  handleModalClose = () => this.setState({ showPercentagesModalFor: null });

  render() {
    const { anchorTypeOptions, percentageSchemes, policies } = this.props;
    const { showPercentagesModalFor } = this.state;

    return (
      <div className="saved-percentages-component">
        {showPercentagesModalFor &&
          <SavedPercentagesModalComponent
            percentageSchemeId={showPercentagesModalFor}
            onClose={this.handleModalClose}
          />
        }
        <div className="saved-percentages-component__row saved-percentages-component__row_header">
          <h3>
            {translate('percentageSettings.savedPercentages.title')}
            <ExplanatoryTooltipComponent text={translate('explanationMessages.percentageSettings.savedPercentages')} />
          </h3>
          <div className="saved-percentages-component__button saved-percentages-component__button_to-right">
            <ButtonComponent isGreen onClick={this.handleAddPercentageClick}>
              {translate('percentageSettings.savedPercentages.buttons.addPercentage')}
            </ButtonComponent>
          </div>
        </div>
        {percentageSchemes.size > 0 &&
          <div className="saved-percentages-component__row">
            <ContainerWithScrollComponent>
              <SavedPercentagesTableComponent
                anchorTypeOptions={anchorTypeOptions}
                onEditPercentageSchemeClick={this.handleEditPercentageClick}
                percentageSchemes={percentageSchemes}
                policies={policies}
              />
            </ContainerWithScrollComponent>
          </div>
        }
      </div>
    );
  }
}

export default SavedPercentagesComponent;
