import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import ImportFromGSCPopupComponent from './ImportFromGSCPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { closeImportFromGSCPopup } from 'actions/popup_actions';
import { currentIdSelector } from 'selectors';
import { importFromGsc } from 'api/destination_links_import';
import { trackHelpcrunchEvent } from 'common/utils';

const ImportFromGSCPopupContainer = ({ dispatch, brandId }) => {
  function handleClickCancel() {
    dispatch(closeImportFromGSCPopup());
  }

  async function handleClickSubmit(csvFile) {
    dispatch(closeImportFromGSCPopup());

    const importResult = await importFromGsc(dispatch, brandId, csvFile);
    const { type, errors } = importResult;

    if (type === 'error' || !isEmpty(errors)) {
      return importResult;
    }

    trackHelpcrunchEvent('Import from GSC');
    return importResult;
  }

  return (
    <ImportFromGSCPopupComponent
      onClickCancel={handleClickCancel}
      onClickSubmit={handleClickSubmit}
    />
  );
};

ImportFromGSCPopupContainer.propTypes = {
  brandId: PropTypes.string,
  dispatch: PropTypes.func,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);

  return {
    brandId,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(ImportFromGSCPopupContainer)));
