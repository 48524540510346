import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoMessagePopupComponent from './EoMessagePopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector } from 'selectors';
import { eoMessageShape } from 'common/propTypesShapes/eoMessages';
import { fetchEoMessage } from 'api/eoMessage';

const EoMessagePopupContainer = ({ dispatch, eoCampaignId, onClose, tableRowData: { id } }) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [eoMessage, setEoMessage] = React.useState({});

  React.useEffect(() => {
    setIsFetching(true);

    fetchEoMessage(dispatch, eoCampaignId, id).then((eoMessage) => {
      setEoMessage(eoMessage);
      setIsFetching(false);
    });
  }, [dispatch, eoCampaignId, id]);

  return (
    <EoMessagePopupComponent eoMessage={eoMessage} isFetching={isFetching} onCancel={onClose} />
  );
};

EoMessagePopupContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  onClose: PropTypes.func,
  tableRowData: eoMessageShape,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);

  return {
    eoCampaignId,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(EoMessagePopupContainer);
