import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import CancelFormSubmitDecorator from '../cancel_form_submit_decorator';

import InputComponent from '../../../components_linkio/input_component';

import AuthFormGlobalErrorComponent from '../auth_form_global_error_component';
import DeviseFooterComponent from '../devise_footer_component';
import DeviseHeaderComponent from '../devise_header_component';
import DeviseLinksComponent from '../devise_links_component';
import SubmitButtonComponent from '../submit_button_component';
import TermsAndPolicyLinksComponent from '../terms_and_policy_links_component';

import './sign_up_form_component.scss';


class SignUpFormComponent extends Component {
  static propTypes = {
    error: PropTypes.string,
    errors: PropTypes.shape({
      email: PropTypes.arrayOf(PropTypes.string),
      marketerTitle: PropTypes.arrayOf(PropTypes.string),
      password: PropTypes.arrayOf(PropTypes.string),
    }),
    handleSignUpClickAction: PropTypes.func,
    showMessage: PropTypes.bool,
  }

  state = {
    acceptedTermsAndConditions: false,
  }

  inputs = {};

  handleSignUpClick = async () => {
    const email = (this.inputs.email.value || '').toLowerCase();
    const marketerTitle = this.inputs.marketerTitle.value;
    const password = this.inputs.password.value;
    const passwordConfirmation = this.inputs.passwordConfirmation.value;
    const { acceptedTermsAndConditions } = this.state;

    await this.props.handleSignUpClickAction(email, marketerTitle, password, passwordConfirmation, acceptedTermsAndConditions);
  }

  handleToggleAcceptTermsAndConditions = () => {
    const { acceptedTermsAndConditions } = this.state;

    this.setState({ acceptedTermsAndConditions: !acceptedTermsAndConditions });
  }

  emailRef = (input) => this.inputs.email = input;
  marketerTitleRef = (input) => this.inputs.marketerTitle = input;
  passwordRef = (input) => this.inputs.password = input;
  passwordConfirmationRef = (input) => this.inputs.passwordConfirmation = input;

  render() {
    const { errors, showMessage, error } = this.props;
    const { acceptedTermsAndConditions } = this.state;

    const marketerMessage = errors.marketerTitle ?
      { type: 'error', text: errors.marketerTitle }
      :
      { type: 'hint', text: "Don't worry you can change it later" };

    return (
      <div className="signup-component">
        <DeviseHeaderComponent logIn />
        <form className="signup-component__form" noValidate>
          <h2 className="signup-component__text signup-component__text_title">
            {translate('authForms.signUp.title')}
          </h2>
          {showMessage &&
            <div className="signup-component__text">
              {translate('authForms.signUp.successMsg')}
            </div>
          }
          <AuthFormGlobalErrorComponent message={error} />
          {!showMessage &&
            <div>
              <div className="signup-component__input">
                <InputComponent
                  inputRef={this.emailRef}
                  isMandatory
                  label={translate('authForms.signUp.inputs.email.label')}
                  message={{ type: 'error', text: errors.email }}
                  onSend={this.handleSignUpClick}
                  autoComplete="true"
                  type="email"
                  name="email"
                />
              </div>
              <div className="signup-component__input">
                <InputComponent
                  inputRef={this.marketerTitleRef}
                  isMandatory
                  label={translate('authForms.signUp.inputs.businessName.label')}
                  message={marketerMessage}
                  onSend={this.handleSignUpClick}
                  autoComplete="true"
                  type="text"
                  name="organization"
                />
              </div>
              <div className="signup-component__input">
                <InputComponent
                  message={{ type: 'error', text: errors.password }}
                  inputRef={this.passwordRef}
                  isMandatory
                  label={translate('authForms.signUp.inputs.password.label')}
                  autoComplete="off"
                  type="password"
                  name="password"
                  onSend={this.handleSignUpClick}
                />
              </div>
              <div className="signup-component__input">
                <InputComponent
                  inputRef={this.passwordConfirmationRef}
                  isMandatory
                  label={translate('authForms.signUp.inputs.passwordConfirmation.label')}
                  message={{ type: 'error', text: errors.password }}
                  onSend={this.handleSignUpClick}
                  autoComplete="off"
                  type="password"
                  name="password"
                />
              </div>
              <TermsAndPolicyLinksComponent
                accepted={acceptedTermsAndConditions}
                onToggleAccept={this.handleToggleAcceptTermsAndConditions}
              />
              <SubmitButtonComponent
                isDisabled={!acceptedTermsAndConditions}
                onClick={this.handleSignUpClick}
                text={translate('authForms.signUp.buttons.submit')}
              />
              <DeviseLinksComponent />
              <DeviseFooterComponent />
            </div>
          }
        </form>
      </div>
    );
  }
}

export default CancelFormSubmitDecorator(SignUpFormComponent);
