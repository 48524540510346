import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Spinner from 'components_linkio/Spinner';

import './submit_button_component.scss';

function dummyFunction(event) {
  event.preventDefault();
  return void 0;
}

const SubmitButtonComponent = ({ form, text, isDisabled, isLoading, onClick, isBlue }) => {
  const btnIsDisabled = isDisabled || isLoading;

  const classes = classnames({
    'submit-button-component': true,
    'button-linkio': true,
    'button-linkio_is-green': !btnIsDisabled && !isBlue,
    'button-linkio_is-blue': !btnIsDisabled && isBlue,
    'button-linkio_is-disabled': btnIsDisabled,
  });

  const handleClick = btnIsDisabled ? dummyFunction : onClick;

  return (
    <span className="submit-button-component__wrapper">
      {isLoading && <Spinner />}

      <input
        className={classes}
        disabled={btnIsDisabled}
        form={form}
        onClick={handleClick}
        type="submit"
        value={isLoading ? '' : text}
      />
    </span>
  );
};

SubmitButtonComponent.propTypes = {
  form: PropTypes.string,
  isBlue: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default SubmitButtonComponent;
