export default {
  switchSubscriptionPlanPopup: {
    buttons: {
      cancel: "Cancel",
      downgrade: "Downgrade",
    },
    messages: {
      thanksForFeedback: "Thanks for sharing your feedback",
    },
    switchToFreeAccount: {
      feedbackQuestion: "Would you mind sharing with us the reason for the downgrade?",
      mainText: "Please confirm the downgrade to Free subscription. You wont be able to",
      pointsList: [
        "import backlinks from Ahrefs",
        "crawl the backlinks",
        "automatically categorize the anchor text",
        "track the rankings updates",
      ],
      title: "Switch to Free account",
    },
  },
};
