import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { messageIsEmpty } from '../message_utils';
import MessageComponent from '../message_component';

import './radioButton.scss';

const RadioButtonComponent = ({ children, className, message, ...inputProps }) => {
  const radioButtonStyles = cx('custom-radio-button', className);

  return (
    <label className={radioButtonStyles}>
      <span className="custom-radio-button__label-content">{children}</span>

      <input className="custom-radio-button__input" type="radio" {...inputProps} />

      <span className="custom-radio-button__checkmark" />
      {!messageIsEmpty(message) && <MessageComponent message={message} />}
    </label>
  );
};

RadioButtonComponent.propTypes = {
  checkboxProps: PropTypes.shape({
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
  ]),
  className: PropTypes.string,
  message: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    type: PropTypes.oneOf(['success', 'error', 'hint']),
  }),
};

export default RadioButtonComponent;
