import pluralize from 'pluralize';

export default {
  uiComponents: {
    dataFilters: {
      buttons: {
        filter: (numberOfFilterDlsQueries) => {
          if (numberOfFilterDlsQueries > 0) {
            return `${pluralize('filter', numberOfFilterDlsQueries, true)}`;
          }
          return 'Filter';
        },
        addFilter: '+ Add Filter',
        clearAll: 'Clear All',
      },
      emptyFilter: 'No filters applied to this view',
      predicates: {
        is: 'Is...',
        isNot: 'Is not...',
        isAnyOf: 'Is any of...',
        isNonOf: 'Is none of...',
        isEmpty: 'Is empty',
        isNotEmpty: 'Is not empty',
      },
      rowsGrouping: {
        and: 'And',
        or: 'Or',
        where: 'Where',
      },
      filter: {
        anchorType: 'Anchor Type',
        brandPage: 'Page',
        disavow: 'Disavow',
        eoEmailTemplates: {
          brand: 'Brand',
          user: 'Created by',
        },
        eoProgressionStage: 'Stage',
        included: 'Include',
        indexed: 'Indexed',
        isFound: 'Found',
        nofollow: 'DF/NF',
        prospect: {
          campaign: 'Campaign',
          dripLists: 'Drip List',
        },
        status: 'Status',
      },
      options: {
        DF: 'DF',
        NF: 'NF',
      },
    },
    calendarPresetsPopup: {
      item: {
        default: 'Default',
        months: (count) => `${count} Months`,
        year: 'Year',
        custom: 'Custom',
      },
    },
    button: {
      add: 'Add',
      cancel: 'Cancel',
      clear: 'Clear',
      apply: 'Apply',
      save: 'Save',
      undo: 'Undo',
    },
    userTypeSelection: {
      title:
        'Congrats, your account has been confirmed! Now, to help us serve you better please select which role describes you best.',
      userTypes: [
        {
          title: 'Marketing Agency',
          description: 'You work in an agency and you have multiple clients',
        },
        {
          title: 'Brand Marketer',
          description: 'You do digital marketing for a brand',
        },
        {
          title: 'Freelancer',
          description: 'You work solo for your clients',
        },
        {
          title: 'Other',
        },
      ],
      creditCardRequirement: {
        title: 'One last thing before we start',
        subtitle: 'To start your 14 days trial, we’ve got to confirm your billing details',
        hint: 'This is a security measure to prevent email abuse on our system. \
        You can cancel anytime. 60 day money back guarantee. You will receive an \
        email 24 hours before first billing.',
        stripeBtn: 'Continue with Stripe',
      },
    },
    moduleSetupSelection: {
      eoCampaigns: 'Email Outreach Campaign',
      atp: 'Anchor Text Planner',
      title: 'What would you like to setup first?',
    },
    noAccess: {
      button: 'Back to home',
      title: 'You do not have permissions to access this page!',
      subscriptionLimitTitle:
        'Sorry, your subscription plan does not allow you to access this page.',
    },
    select: {
      selectAction: 'Select action',
    },
    time: {
      am: 'AM',
      pm: 'PM',
    },
    weekdays: {
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      Sunday: 'Sunday',
    },
    timezone: {
      title: 'Timezone',
      accountTimezone: 'Use account time zone',
      currentTimezone: 'Use your current time zone',
      customTimezone: 'Choose a different time zone',
      timezoneSelect: {
        label: 'Timezone',
        placeholder: 'Select timezone...',
      },
    },
    brandAssign: {
      selectLabel: 'Brand',
      noBrandAssigned: '[no brand assigned]',
      anyBrand: '[any brand]',
      anyCampaign: 'Any campaign',
      brandCampaign: 'Brand campaign',
    },
  },
};
