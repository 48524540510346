import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Overlay } from 'react-overlays';

import { tooltipShape } from '../../common/prop_types_shapes';
// Overlay with Bootstrap as temporary solution to fix bug with React events
import OverlayWithBootstrap from '../../components_linkio/overlay_with_tooltip_component';

import Link from 'components_linkio/link';

import './overlay_with_tooltip_component.scss';


class OverlayWithTooltipComponent extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isCopyable: PropTypes.bool,
    tooltip: tooltipShape,
  };

  state = {
    show: false,
  }

  mouseOverTooltip = false;

  showOverlay = () => this.setState({ show: true });
  hideOverlay = () => this.setState({ show: false });

  handleMouseEnter = () => this.showOverlay();
  handleMouseOut = () => !this.mouseOverTooltip && this.handleMouseOutTooltip();

  handleMouseOverTooltip = () => {
    if (!this.props.isCopyable) {
      return;
    }
    this.mouseOverTooltip = true;
    this.setState({ show: true });
  }
  handleMouseOutTooltip = () => {
    this.mouseOverTooltip = false;
    this.setState({ show: false });
  }

  findTarget = () => ReactDOM.findDOMNode(this);

  render() {
    const { tooltip, children, className, isCopyable } = this.props;
    const { show } = this.state;

    if (!tooltip || !tooltip.text) {
      return children;
    }

    if (!isCopyable) {
      return (
        <OverlayWithBootstrap tooltip={tooltip}>
          {children}
        </OverlayWithBootstrap>
      );
    }

    const textNode = tooltip.text.startsWith('http') ?
      <Link href={tooltip.text} target="_blank" rel="nofollow noopener">{tooltip.text}{tooltip.icon}</Link>
      :
      <span>{tooltip.text}{tooltip.icon}</span>;

    const componentClasses = classnames({
      "overlay-with-tooltip-component": true,
      [className]: className,
    });

    return (
      <div
        className={componentClasses}
        onMouseEnter={this.handleMouseEnter}
        onMouseOut={this.handleMouseOut}
      >
        {children}
        <Overlay
          show={show}
          placement={tooltip.placement}
          target={this.findTarget}
          onHide={this.handleMouseOutTooltip}
        >
          <ToolTipComponent
            placement={tooltip.placement}
            color={tooltip.color}
            onMouseEnter={this.handleMouseOverTooltip}
            onMouseLeave={this.handleMouseOutTooltip}
          >
            {textNode}
          </ToolTipComponent>
        </Overlay>
      </div>
    );
  }
}

const ToolTipComponent = ({
  children,
  color,
  onMouseEnter,
  onMouseLeave,
  placement,
  positionLeft, // passed from Overlay component
  positionTop, // passed from Overlay component
}) => {
  const tooltipClasses = classnames({
    'overlay-with-tooltip-component__tooltip': true,
    [`overlay-with-tooltip-component__tooltip_${color}`]: color,
    [`overlay-with-tooltip-component__tooltip_${placement}`]: placement,
  });

  const tooltipArrowClasses = classnames({
    'overlay-with-tooltip-component__tooltip-arrow': true,
    [`overlay-with-tooltip-component__tooltip-arrow_${placement}`]: placement,
  });

  return (
    <div
      style={{ left: positionLeft, top: positionTop }}
      className={tooltipClasses}
      onMouseEnter={onMouseEnter}
    >
      <div className={tooltipArrowClasses} />
      <div
        className="overlay-with-tooltip-component__tooltip-inner"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
    </div>
  );
};

ToolTipComponent.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  placement: PropTypes.string,
  positionLeft: PropTypes.number,
  positionTop: PropTypes.number,
};

export default OverlayWithTooltipComponent;
