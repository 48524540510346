import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import CancelFormSubmitDecorator from '../cancel_form_submit_decorator';

import InputComponent from '../../../components_linkio/input_component';

import AuthFormGlobalErrorComponent from '../auth_form_global_error_component';
import DeviseFooterComponent from '../devise_footer_component';
import SubmitButtonComponent from '../submit_button_component';
import TermsAndPolicyLinksComponent from '../terms_and_policy_links_component';

import './accept_invitation_form_component.scss';


class AcceptInvitationFormComponent extends Component {
  static propTypes = {
    error: PropTypes.string,
    errors: PropTypes.shape({
      invitationToken: PropTypes.arrayOf(PropTypes.string),
      password: PropTypes.arrayOf(PropTypes.string),
      passwordConfirmation: PropTypes.arrayOf(PropTypes.string),
    }),
    handleSetPasswordButtonClick: PropTypes.func,
  }

  state = {
    acceptedTermsAndConditions: false,
  }

  inputs = {};

  handleSubmit = async () => {
    const urlObj = new URL(window.location);
    const invitationToken = urlObj.searchParams.get("invitation_token");
    const password = this.inputs.password.value;
    const passwordConfirmation = this.inputs.passwordConfirmation.value;

    await this.props.handleSetPasswordButtonClick(invitationToken, password, passwordConfirmation);
  }

  handleToggleAcceptTermsAndConditions = () => {
    const { acceptedTermsAndConditions } = this.state;

    this.setState({ acceptedTermsAndConditions: !acceptedTermsAndConditions });
  }

  passwordRef = (input) => this.inputs.password = input;
  passwordConfirmationRef = (input) => this.inputs.passwordConfirmation = input;

  render() {
    const { errors, error } = this.props;
    const { acceptedTermsAndConditions } = this.state;

    return (
      <div className="accept-invitation-component">
        <div className="reset-password-component__space" />
        <h2 className="accept-invitation-component__text accept-invitation-component__text_title">
          {translate('authForms.acceptInvitation.title')}
        </h2>
        <form className="signup-component__form" noValidate>
          <AuthFormGlobalErrorComponent message={error} />
          <div className="accept-invitation-component__input">
            <InputComponent
              inputRef={this.passwordRef}
              isMandatory
              label={translate('authForms.acceptInvitation.inputs.password.label')}
              message={{ type: 'error', text: errors.password }}
              onSend={this.handleSubmit}
              type="password"
            />
          </div>
          <div className="accept-invitation-component__input">
            <InputComponent
              inputRef={this.passwordConfirmationRef}
              isMandatory
              label={translate('authForms.acceptInvitation.inputs.passwordConfirmation.label')}
              message={{ type: 'error', text: errors.passwordConfirmation }}
              onSend={this.handleSubmit}
              type="password"
            />
          </div>
          <TermsAndPolicyLinksComponent
            accepted={acceptedTermsAndConditions}
            onToggleAccept={this.handleToggleAcceptTermsAndConditions}
          />
          <SubmitButtonComponent
            isBlue
            onClick={this.handleSubmit}
            text={translate('authForms.acceptInvitation.buttons.submit')}
            isDisabled={!acceptedTermsAndConditions}
          />
          <DeviseFooterComponent />
        </form>
      </div>
    );
  }
}

export default CancelFormSubmitDecorator(AcceptInvitationFormComponent);
