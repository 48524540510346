import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import TestimonialsItemComponent from './testimonials_item_component';
import TestimonialsButtonComponent from './testimonials_button_component';

import './testimonials_slider_component.scss';


export default class TestimonialsSliderComponent extends Component {
  static propTypes = {
    testimonials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      photoUrl: PropTypes.string,
      position: PropTypes.string,
      text: PropTypes.string,
    })),
  }

  constructor(props) {
    super(props);

    this.state = {
      currentPosition: 0,
      showItem: true,
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => this.moveToNextSlide(), 7000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  intervalId = void 0;

  moveToSlide = (number) => () => this.setState({ showItem: true, currentPosition: parseInt(number, 10) });

  hideSlide = () => this.setState({ showItem: false })

  moveToNextSlide = () => {
    const { testimonials } = this.props;
    const { currentPosition } = this.state;

    this.hideSlide();

    const nextPosition = currentPosition + 1;

    nextPosition === testimonials.length ? this.moveToSlide(0)() : this.moveToSlide(nextPosition)();
  }

  handleButtonClick = (number) => this.moveToSlide(number);

  render() {
    const { testimonials } = this.props;
    const { currentPosition, showItem } = this.state;

    return (
      <div className="testimonials-slider-component">
        <div className="testimonials-slider-component__items">
          {showItem && <TestimonialsItemComponent {...testimonials[currentPosition]} />}
        </div>
        <div className="testimonials-slider-component__buttons">
          {map(testimonials, (_, index) => {
            return (
              <TestimonialsButtonComponent
                active={currentPosition === index}
                key={index}
                position={index}
                onClick={this.handleButtonClick}
              />);
          })}
        </div>
      </div>
    );
  }
}
