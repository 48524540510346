import React from 'react';
import PropTypes from 'prop-types';

import AddEoInboxButton from './AddEoInboxButton';
import EoInboxesList from './EoInboxesList';
import EoInboxesSettings from './EoInboxesSettings';

import { translate } from 'common/i18n';

import DisabledSettings from 'common/img/disabled_eo_inboxes_settings.png';

import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import EoEmailVerificationPage from 'pages/EoEmailVerificationPage';

import './eoInboxesSettingsPage.scss';

const EoInboxesSettingsPageComponent = ({
  canCreate,
  canUpdate,
  canUseEoInboxesSettings,
  onClickUpgradeSubscription,
}) => {
  if (!canUseEoInboxesSettings) {
    return (
      <div className="eo-inboxes-settings">
        <img src={DisabledSettings} />
        <SubscriptionLimitRibbonComponent>
          {translate('subscriptionLimitRibbon.message')(
            translate('subscriptionLimitRibbon.limited.eoInboxesSettings'),
            true,
          )}
          <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
        </SubscriptionLimitRibbonComponent>
      </div>
    );
  }

  return (
    <div className="eo-inboxes-settings">
      <EoInboxesList canUpdate={canUpdate} />

      {canCreate && <AddEoInboxButton />}

      <EoInboxesSettings canUpdate={canUpdate} />

      <EoEmailVerificationPage />
    </div>
  );
};

EoInboxesSettingsPageComponent.propTypes = {
  canCreate: PropTypes.bool,
  canUpdate: PropTypes.bool,
  canUseEoInboxesSettings: PropTypes.bool,
  onClickUpgradeSubscription: PropTypes.func,
};

export default EoInboxesSettingsPageComponent;
