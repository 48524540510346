import React from 'react';

const CalendarIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M4 9h2V7H4v2zm0 3h2v-2H4v2zm3-3h2V7H7v2zm0 3h2v-2H7v2zm3-3h2V7h-2v2zm0 3h2v-2h-2v2zm4-6c0-.552-.448-1-1-1H3c-.552 0-1 .448-1 1v7c0 .552.448 1 1 1h10c.552 0 1-.448 1-1V6zm0 10H2c-1.104 0-2-.895-2-2V2C0 .895.896 0 2 0h2v1c0 .552.448 1 1 1s1-.448 1-1V0h4v1c0 .552.448 1 1 1s1-.448 1-1V0h2c1.105 0 2 .895 2 2v12c0 1.105-.895 2-2 2z" />
    </svg>
  );
};

CalendarIcon.defaultProps = {
  fill: '#E3E6E9',
  width: '14px',
  height: '14px',
};

export default CalendarIcon;
