import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';

import ExportToCsvButtonComponent from './ExportToCsvButtonComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandShape } from 'common/prop_types_shapes';
import { currentBrandSelector } from 'selectors';
import { fetchRailsContext } from 'api/rails_context';
import { METRICS_FILTERS } from 'utils/constants';
import { processObjToSnakeCaseDeep } from 'api/utils';

const ExportToCsvButtonContainer = ({ brand, dispatch, queryParams }) => {
  const { dlsGroupMode = '', filters, metricsFilter, search = '', sorting = {} } = queryParams;

  const params = {
    dlsGroupMode,
    filters: {
      ...(filters || {}),
      ...(METRICS_FILTERS[metricsFilter] || {}),
    },
    search,
    sorting,
  };

  async function handleClick() {
    // need context to trigger Auth failure if any
    const context = await fetchRailsContext(dispatch);
    if (context) {
      window.location.href = Routes.export_csv_backlink_path({
        brand_id: brand.get('id'),
        format: 'csv',
        ...processObjToSnakeCaseDeep(params),
      });
    }
  }

  const isDisabled = !brand.get('hasBacklinks');

  return <ExportToCsvButtonComponent isDisabled={isDisabled} onClick={handleClick} />;
};

ExportToCsvButtonContainer.propTypes = {
  brand: brandShape,
  dispatch: PropTypes.func,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brand = currentBrandSelector(state, ownProps);

  return {
    brand,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(ExportToCsvButtonContainer);
