export const ADD_EO_BLACKLISTED_DOMAINS__FAILURE = 'ADD_EO_BLACKLISTED_DOMAINS__FAILURE';
export const ADD_EO_BLACKLISTED_DOMAINS__START = 'ADD_EO_BLACKLISTED_DOMAINS__START';
export const ADD_EO_BLACKLISTED_DOMAINS__SUCCESS = 'ADD_EO_BLACKLISTED_DOMAINS__SUCCESS';
export const DELETE_EO_BLACKLISTED_DOMAINS__FAILURE = 'DELETE_EO_BLACKLISTED_DOMAINS__FAILURE';
export const DELETE_EO_BLACKLISTED_DOMAINS__START = 'DELETE_EO_BLACKLISTED_DOMAINS__START';
export const DELETE_EO_BLACKLISTED_DOMAINS__SUCCESS = 'DELETE_EO_BLACKLISTED_DOMAINS__SUCCESS';
export const FETCH_EO_BLACKLISTED_DOMAINS__FAILURE = 'FETCH_EO_BLACKLISTED_DOMAINS__FAILURE';
export const FETCH_EO_BLACKLISTED_DOMAINS__START = 'FETCH_EO_BLACKLISTED_DOMAINS__START';
export const FETCH_EO_BLACKLISTED_DOMAINS__SUCCESS = 'FETCH_EO_BLACKLISTED_DOMAINS__SUCCESS';

export function addEoBlacklistedDomainsStart() {
  return { type: ADD_EO_BLACKLISTED_DOMAINS__START };
}

export function addEoBlacklistedDomainsSuccess(eoBlacklistedDomains) {
  return { type: ADD_EO_BLACKLISTED_DOMAINS__SUCCESS, eoBlacklistedDomains };
}

export function addEoBlacklistedDomainsFailure(errors) {
  return { type: ADD_EO_BLACKLISTED_DOMAINS__FAILURE, errors };
}

export function deleteEoBlacklistedDomainsStart() {
  return { type: DELETE_EO_BLACKLISTED_DOMAINS__START };
}

export function deleteEoBlacklistedDomainsSuccess(ids) {
  return { type: DELETE_EO_BLACKLISTED_DOMAINS__SUCCESS, ids };
}

export function deleteEoBlacklistedDomainsFailure(errors) {
  return { type: DELETE_EO_BLACKLISTED_DOMAINS__FAILURE, errors };
}

export function fetchEoBlacklistedDomainsStart() {
  return { type: FETCH_EO_BLACKLISTED_DOMAINS__START };
}

export function fetchEoBlacklistedDomainsSuccess(eoBlacklistedDomains) {
  return { type: FETCH_EO_BLACKLISTED_DOMAINS__SUCCESS, eoBlacklistedDomains };
}

export function fetchEoBlacklistedDomainsFailure(errors) {
  return { type: FETCH_EO_BLACKLISTED_DOMAINS__FAILURE, errors };
}
