import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ClickOutsideComponentDecorator from 'components/click_outside_component_decorator';
import Link from 'components_linkio/link';

import { userShape, marketerShape, policiesShape } from 'common/prop_types_shapes';

import './navbarMenu.scss';

class NavbarMenuComponent extends React.Component {
  static propTypes = {
    currentMarketer: marketerShape.isRequired,
    currentUser: userShape.isRequired,
    onClickFeedback: PropTypes.func,
    onClickOutside: PropTypes.func.isRequired,
    policies: policiesShape,
  };

  onClickOutside = (e) => this.props.onClickOutside(e);

  render() {
    const { currentUser, currentMarketer, onClickFeedback, policies } = this.props;

    const editProfilePath = Routes.users_edit_profile_path({ format: null });
    const chooseAccountPath = Routes.users_choose_account_path({ format: null });
    const logoutPath = Routes.destroy_user_session_path({ format: null });
    const impersonatesPath = Routes.impersonates_path({ format: null });
    const affiliatePath = Routes.affiliate_path({ format: null });

    const canImpersonate = policies.getIn(['impersonate', 'canImpersonate']);

    return (
      <div className="navbar-menu">
        <div
          title={currentUser.get('displayName')}
          className="navbar-menu__item navbar-menu__item_not-clickable"
        >
          <span className="text_two-lines-with-ellipsis-break-word">
            {currentUser.get('displayName')}
          </span>
        </div>
        {currentMarketer.get('number') > 1 && (
          <div
            title={currentMarketer.get('title')}
            className="navbar-menu__item navbar-menu__item_not-clickable text_two-lines-with-ellipsis-break-word"
          >
            <span className="text_two-lines-with-ellipsis-break-word">
              {translate('topNavbar.accountTitle', { title: currentMarketer.get('title') })}
            </span>
          </div>
        )}
        <Link to={editProfilePath} className="navbar-menu__item">
          {translate('topNavbar.viewAccountDetails')}
        </Link>
        <Link to={chooseAccountPath} className="navbar-menu__item">
          {translate('topNavbar.switchAccount')}
        </Link>
        <Link href={affiliatePath} className="navbar-menu__item">
          {translate('topNavbar.affiliate')}
        </Link>
        <div className="navbar-menu__item" onClick={onClickFeedback}>
          {translate('topNavbar.feedback')}
        </div>
        <a href={logoutPath} data-method="delete" className="navbar-menu__item">
          {translate('topNavbar.logout')}
        </a>
        {canImpersonate && (
          <a href={impersonatesPath} className="navbar-menu__item">
            {translate('topNavbar.impersonate')}
          </a>
        )}
      </div>
    );
  }
}

export default ClickOutsideComponentDecorator(NavbarMenuComponent);
