import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import BuyVerificationCreditsButton from './BuyVerificationCreditsButton';
import InputComponent from 'components_linkio/input_component';

import './eoEmailVerificationPage.scss';

const EoEmailVerificationPageComponent = ({ onSubmit }) => {
  const [validDaysCount, setValidDaysCount] = React.useState('');

  function handleChangeInputValue(event) {
    setValidDaysCount(event.target.value);
  }

  return (
    <div className="eo-email-verification">
      <h3>{translate('eoEmailVerification.title')}</h3>

      <BuyVerificationCreditsButton />

      {/*Uncomment when the backend is ready

      <InputComponent
        label={translate('eoEmailVerification.inputs.validDays.label')}
        message={{ type: 'hint', text: translate('eoEmailVerification.inputs.validDays.hint') }}
        onInputChange={handleChangeInputValue}
        type="number"
        value={validDaysCount}
      />

      <ButtonComponent
        isInitial
        isGreen
        className="eo-email-verification__submit-valid-days"
        onClick={onSubmit}
      >
        {translate('uiComponents.button.save')}
      </ButtonComponent> */}
    </div>
  );
};

EoEmailVerificationPageComponent.propTypes = {
  onSubmit: PropTypes.func,
};

export default EoEmailVerificationPageComponent;
