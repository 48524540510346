import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AddEoBlacklistedDomainsPopupComponent from './AddEoBlacklistedDomainsPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { addEoBlacklistedDomains } from 'api/eoBlacklistedDomain';
import { fetchSimpleBrands } from 'api/simpleBrand';
import { optionsSelector } from 'selectors';

const AddEoBlacklistedDomainsPopupContainer = ({ dispatch, brandsOptions, onClose }) => {
  React.useEffect(() => {
    fetchSimpleBrands(dispatch);
  }, []);

  function handleSave(domainsList, brandId) {
    addEoBlacklistedDomains(dispatch, domainsList, brandId);

    onClose();
  }

  return (
    <AddEoBlacklistedDomainsPopupComponent
      immutableBrandsOptions={brandsOptions}
      onCancel={onClose}
      onSave={handleSave}
    />
  );
};

AddEoBlacklistedDomainsPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
};

function select(state) {
  const options = optionsSelector(state);
  const brandsOptions = options.get('brandsOptions');

  return {
    brandsOptions,
  };
}

export default ConnectStoreHOC(connect(select)(AddEoBlacklistedDomainsPopupContainer));
