import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { compose } from 'lodash/fp';
import { createCheckoutSession } from 'api/subscription_plan';
import { fetchSubscriptionPlans } from 'api/subscription_plan';
import { stripeKeySelector } from 'selectors/railsContextSelectors';
import { subscriptionPlansMap } from 'common/prop_types_shapes';
import { subscriptionPlansSelector } from 'selectors';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import CreditCardRequirementComponent from './CreditCardRequirementComponent';

const CreditCardRequirementContainer = ({ dispatch, stripeKey, subscriptionPlans }) => {
  React.useEffect(() => {
    fetchSubscriptionPlans(dispatch);
  }, [dispatch]);

  async function requireCreditCardAndSubscribeToTrialingSubscription() {
    const freeMonthlyPlanId = subscriptionPlans
      .find((subscriptionPlan) => subscriptionPlan.get('slug') === 'personal_monthly')
      .get('id');

    const startTrial = true;
    const successPathname = Routes.new_brand_path({ format: null, simpleMode: true });
    const checkoutSession = await createCheckoutSession(
      dispatch,
      successPathname,
      freeMonthlyPlanId,
      startTrial,
    );

    // eslint-disable-next-line no-undef
    const stripe = Stripe(stripeKey);
    stripe.redirectToCheckout({ sessionId: checkoutSession.id });
  }

  return (
    <CreditCardRequirementComponent
      onStripeBtnClick={requireCreditCardAndSubscribeToTrialingSubscription}
    />
  );
};

CreditCardRequirementContainer.propTypes = {
  dispatch: PropTypes.func,
  stripeKey: PropTypes.string,
  subscriptionPlans: subscriptionPlansMap,
};

function select(state, ownProps) {
  const stripeKey = stripeKeySelector(state, ownProps);
  const subscriptionPlans = subscriptionPlansSelector(state, ownProps);

  return {
    stripeKey,
    subscriptionPlans,
  };
}

export default compose(ConnectStoreHOC, connect(select))(CreditCardRequirementContainer);
