import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isEmpty, isEqual } from 'lodash';

import { translate } from '../../common/i18n';

import { optionsList, rankTrackerSettingsMap } from '../../common/prop_types_shapes';

import { valueFromImmutable } from '../../components_linkio/Select/utils';

import ButtonComponent from '../../components_linkio/button_component';
import MessageComponent from '../../components_linkio/message_component';
import Select from '../../components_linkio/Select';
import SwitchComponent from '../../components_linkio/switch_component';
import Checkbox from '../../components_linkio/Checkbox';

import { DEFAULT_SE_SETTINGS, defaultSelectorProps, exactLocationSelectorProps } from './utils';

import './rankTrackerSetup.scss';

class RankTrackerSetupComponent extends React.Component {
  static propTypes = {
    onChangeOptions: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onSaveRankTrackerSettings: PropTypes.func.isRequired,
    rankTrackerCountriesOptions: optionsList,
    rankTrackerLanguagesOptions: optionsList,
    rankTrackerLocationsOptions: optionsList,
    rankTrackerSettings: rankTrackerSettingsMap,
    searchEngineOptions: optionsList,
  };

  constructor(props) {
    super(props);

    const processedRankTrackerSettings = this.processRankTrackerSettings();

    this.state = {
      rankTrackerSettings: processedRankTrackerSettings,
      rankTrackerCountriesOptions: this.props.rankTrackerCountriesOptions,
      showDomainRelatedSettings: processedRankTrackerSettings.exactSearch,
      showSpecificLocationOptions: false, // show options only when user entered data into the input
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // If we get only one country after the search engine selection then apply this country automatically
    const isOnlyOneCountry =
      !isEqual(
        nextProps.rankTrackerCountriesOptions.toJS(),
        prevState.rankTrackerCountriesOptions.toJS(),
      ) && nextProps.rankTrackerCountriesOptions.size === 1;

    if (isOnlyOneCountry) {
      return {
        rankTrackerSettings: {
          ...prevState.rankTrackerSettings,
          country: nextProps.rankTrackerCountriesOptions.first().get('value'),
        },
        rankTrackerCountriesOptions: nextProps.rankTrackerCountriesOptions,
      };
    }

    return null;
  }

  processRankTrackerSettings = () => {
    const { rankTrackerSettings } = this.props;
    const { DEFAULT_COUNTRY, DEFAULT_ENGINE_NAME, DEFAULT_LANGUAGE } = DEFAULT_SE_SETTINGS;

    return {
      id: rankTrackerSettings.get('id'),
      engineName: rankTrackerSettings.get('engineName') || DEFAULT_ENGINE_NAME,
      errors: {},
      exactSearch: rankTrackerSettings.get('exactSearch'),
      exactLocation: rankTrackerSettings.get('exactLocation'),
      country: rankTrackerSettings.get('country') || DEFAULT_COUNTRY,
      language: rankTrackerSettings.get('language') || DEFAULT_LANGUAGE,
      locationName: rankTrackerSettings.get('locationName') || '',
      trackMode: rankTrackerSettings.get('trackMode'),
    };
  };

  handleChangeEngineName = (option) => {
    if (!option || this.state.rankTrackerSettings.engineName === option.value) {
      return;
    }

    this.setState({
      rankTrackerSettings: {
        ...this.state.rankTrackerSettings,
        engineName: option.value,
        country: '',
        language: '',
        locationName: '',
      },
    });

    this.props.onChangeOptions('engineName', { engineName: option.value });
  };

  handleChangeCountry = (option) => {
    const { rankTrackerSettings } = this.state;

    if (!option || rankTrackerSettings.country === option.value) {
      return;
    }

    this.setState({
      rankTrackerSettings: {
        ...rankTrackerSettings,
        country: option.value,
        language: '',
        locationName: '',
      },
    });

    this.props.onChangeOptions('country', {
      country: option.value,
      engineName: rankTrackerSettings.engineName,
    });
  };

  handleChangeLanguage = (option) => {
    if (!option || this.state.rankTrackerSettings.language === option.value) {
      return;
    }

    this.setState({
      rankTrackerSettings: {
        ...this.state.rankTrackerSettings,
        language: option.value,
      },
    });
  };

  handleChangeLocationName = (option) => {
    if (!option) {
      return;
    }

    this.setState({
      rankTrackerSettings: {
        ...this.state.rankTrackerSettings,
        locationName: option.value,
      },
    });
  };

  handleChangeSpecificLocationInput = (value) => {
    if (!value) {
      this.setState({ showSpecificLocationOptions: false });
      return;
    }

    const {
      showSpecificLocationOptions,
      rankTrackerSettings: { country },
    } = this.state;

    if (!showSpecificLocationOptions) {
      this.setState({ showSpecificLocationOptions: true });
    }

    this.props.onChangeOptions('locationName', { locationName: value, country });
  };

  handleClickTrackOptionsSwitch = (value) => {
    this.setState({
      rankTrackerSettings: {
        ...this.state.rankTrackerSettings,
        trackMode: value === 'right' ? 'mobile' : 'desktop',
      },
    });
  };

  handleClickExactSearchCheckbox = (event) => {
    this.setState({
      rankTrackerSettings: {
        ...this.state.rankTrackerSettings,
        exactSearch: event.target.checked,
      },
    });
  };

  handleClickSpecificLocationSwitch = (value) => {
    this.setState({
      rankTrackerSettings: {
        ...this.state.rankTrackerSettings,
        exactLocation: value === 'right',
        locationName: '',
      },
    });
  };

  handleClickDomainRelatedSettingsSwitch = (value) => {
    this.setState({ showDomainRelatedSettings: value === 'right' });

    if (value === 'left') {
      this.setState({
        rankTrackerSettings: {
          ...this.state.rankTrackerSettings,
          exactSearch: false,
        },
      });
    }
  };

  handleClickSubmit = async (event) => {
    event.preventDefault();

    const result = await this.props.onSaveRankTrackerSettings(this.state.rankTrackerSettings);
    const { errors } = result;

    if (!isEmpty(errors)) {
      this.setState({
        rankTrackerSettings: {
          ...this.state.rankTrackerSettings,
          errors,
        },
      });
    }
  };

  isRankTrackerSettingsChanged = () => {
    const prevRankTrackerSettings = this.processRankTrackerSettings();
    const currentRankTrackerSettings = this.state.rankTrackerSettings;

    return !isEqual(prevRankTrackerSettings, currentRankTrackerSettings);
  };

  buildLocationsNoOptionsMessage = (inputObject) => {
    // By returning 'undefined' we force react-select to use default 'No options' message
    return !inputObject.inputValue
      ? translate('rankTrackerSetup.selects.location.noOptionsMessage')
      : undefined;
  };

  render() {
    const {
      onClickCancel,
      rankTrackerCountriesOptions,
      rankTrackerLanguagesOptions,
      rankTrackerLocationsOptions,
      searchEngineOptions,
    } = this.props;

    const {
      rankTrackerSettings: {
        id,
        engineName,
        errors,
        exactSearch,
        exactLocation,
        country,
        language,
        locationName,
        trackMode,
      },
      showDomainRelatedSettings,
      showSpecificLocationOptions,
    } = this.state;

    const engineNameMessage = errors.engine_name ? { type: 'error', text: errors.engine_name } : {};
    const countryMessage = errors.country ? { type: 'error', text: errors.country } : {};
    const languageMessage = errors.language ? { type: 'error', text: errors.language } : {};
    const locationMessage = errors.location_name
      ? { type: 'error', text: errors.location_name }
      : {};

    const specificLocationsOptions = showSpecificLocationOptions
      ? rankTrackerLocationsOptions.toJS()
      : [];

    const rankTrackerSettingsWasChanged = this.isRankTrackerSettingsChanged();

    const submitButtonClasses = cx('button-linkio', {
      'button-linkio_is-green': rankTrackerSettingsWasChanged,
      'button-linkio_is-disabled': !rankTrackerSettingsWasChanged && !!id,
    });

    return (
      <div className="rank-tracker-setup">
        <h1>{translate('rankTrackerSetup.title')}</h1>

        <form className="rank-tracker-setup__form" noValidate>
          <Select
            className="capybara--rank-tracker-setup__engine-name-select"
            label={translate('rankTrackerSetup.selects.searchEngine.label')}
            message={engineNameMessage}
            onChange={this.handleChangeEngineName}
            options={searchEngineOptions.toJS()}
            value={valueFromImmutable(searchEngineOptions, engineName)}
            {...defaultSelectorProps}
          />

          <Select
            isDisabled={!engineName}
            label={translate('rankTrackerSetup.selects.country.label')}
            message={countryMessage}
            onChange={this.handleChangeCountry}
            options={rankTrackerCountriesOptions.toJS()}
            value={valueFromImmutable(rankTrackerCountriesOptions, country) || ''}
            {...defaultSelectorProps}
          />

          <Select
            className="capybara--rank-tracker-setup__language-select"
            isDisabled={!country}
            label={translate('rankTrackerSetup.selects.searchEngineLanguage.label')}
            message={languageMessage}
            onChange={this.handleChangeLanguage}
            options={rankTrackerLanguagesOptions.toJS()}
            value={valueFromImmutable(rankTrackerLanguagesOptions, language) || ''}
            {...defaultSelectorProps}
          />

          <div className="rank-tracker-setup__track-options">
            <SwitchComponent
              initialValue={trackMode === 'desktop' ? 'left' : 'right'}
              left={translate('rankTrackerSetup.switches.trackDesktop')}
              onClick={this.handleClickTrackOptionsSwitch}
              right={translate('rankTrackerSetup.switches.trackMobile')}
            />
          </div>

          <div className="rank-tracker-setup__location-options">
            <SwitchComponent
              isBoolean
              initialValue={exactLocation ? 'right' : 'left'}
              onClick={this.handleClickSpecificLocationSwitch}
              right={translate('rankTrackerSetup.switches.location')}
            />

            {exactLocation && (
              <Select
                className="capybara--rank-tracker-setup__location-select"
                label={translate('rankTrackerSetup.selects.location.label')}
                message={locationMessage}
                noOptionsMessage={this.buildLocationsNoOptionsMessage}
                onChange={this.handleChangeLocationName}
                onInputChange={this.handleChangeSpecificLocationInput}
                options={specificLocationsOptions}
                value={locationName ? { value: locationName, label: locationName } : ''}
                {...exactLocationSelectorProps}
              />
            )}
          </div>

          <div className="rank-tracker-setup__domain-options">
            <SwitchComponent
              isBoolean
              initialValue={showDomainRelatedSettings ? 'right' : 'left'}
              onClick={this.handleClickDomainRelatedSettingsSwitch}
              right={translate('rankTrackerSetup.switches.domain')}
            />

            {showDomainRelatedSettings && (
              <>
                <label className="rank-tracker-setup__checkbox-item">
                  <Checkbox checked={exactSearch} onChange={this.handleClickExactSearchCheckbox} />
                  {translate('rankTrackerSetup.checkboxes.domain.label')}
                </label>
                <MessageComponent
                  message={{
                    type: 'hint',
                    text: translate('rankTrackerSetup.checkboxes.domain.hint'),
                  }}
                />
              </>
            )}
          </div>

          <div className="rank-tracker-setup__form-buttons-wrapper">
            <ButtonComponent isWhite isInitial onClick={onClickCancel}>
              {translate('rankTrackerSetup.buttons.cancel')}
            </ButtonComponent>

            <input
              disabled={!rankTrackerSettingsWasChanged && !!id}
              type="submit"
              className={submitButtonClasses}
              onClick={this.handleClickSubmit}
              value={translate('rankTrackerSetup.buttons.submit')(id)}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default RankTrackerSetupComponent;
