const errors = {
  errors: {
    401: 'Unauthorized access error.',
    403: 'Authorization error.',
    404: 'Not found.',
    500: 'Internal server error.',
    503: 'Sorry! Our server is currently unavailable. Maybe, because it is overloaded. Please try again in a few minutes.',
    504: 'This is taking a little longer than usual but will be done soon. Refresh in a few minutes.',

    cantFetchAhrefsConnection: 'Cannot connect to Ahrefs.',
    cantRevokeAhrefsAccess: 'Cannot disconnect the Ahrefs account.',
    cantUpdateConnectionSettings: 'Cannot update Ahrefs connection settings.',
    cantUpdateCrawlerSettings: 'Cannot update crawler settings.',
    cantFindAhrefsBacklinksForThePage: 'Ahrefs did not return any new backlinks for this page',
    ahrefsIntegrationLimitExceeded:
      "You don't have any available rows in your Ahrefs plan. We cannot import the links.",
    dlsPerBrandPageLimitReached:
      "Can't import new backlinks, the maximum number allowed was reached.",

    cantCreateBrand: "Can't create brand",
    cantDeleteBrands: "Can't delete brands",
    cantFetchBrands: "Can't load brands",
    cantUpdateBrand: "Can't update brand",
    cantDisavowLinks: "Can't disavow links",

    cantUpdatePage: "Can't update the brand page",
    cantDeletePage: "Can't delete the brand page",
    cantFetchPages: "Can't fetch the brand pages",
    cantFetchPage: "Can't fetch the brand page",
    cantFetchPagePercentage: "Can't show the anchor text percentages for this brand page",
    cantFetchCompetitorPagesPercentages:
      "Can't show the anchor text percentages for the competitors of this brand page",
    cantImportDestinationLinksFromAhrefs: "Can't import the published links from Ahrefs",
    cantImportDestinationLinksFromMoz: "Can't import the published links from Moz",
    cantFetchAhrefsLimits: "Can't get the total number of the backlinks for this page",
    cantEnqueueAhrefsMetricsUploading: "Can't load missing metrics for this page",
    cantEnqueueMozMetricsUploading: "Can't load missing metrics for this page",

    cantCreateDestinationLinks: "Can't add the rows",
    cantFetchDestinationLinks: "Can't load the anchors for this page",
    cantFetchDlDuplicates: "Can't load the duplicates for this anchor",
    cantRedistributeDestinationLinks: "Can't redistribute the anchor types for this page",
    cantUpdateDestinationLinks: "Can't update the anchors",
    cantDeleteDestinationLinks: "Can't delete the anchors",
    cantImportDestinationLinks: "Can't import the anchors",

    cantImportDestinationLinksFromGsc: "Can't import backlinks from Google Search Console",

    cantCategorizeDestinationLinks: "Can't categorize destination links",

    cantCreateDlDource: "Can't create new source",
    cantFetchDlSources: "Can't load the sources",

    cantCreateEmployee: "Can't create the team member",
    cantDeleteEmployees: "Can't delete the team member",
    cantInviteEmployee: "Can't invite the team member",
    cantUpdateEmployee: "Can't update the team member",
    cantFetchEmployees: "Can't load team members",

    cantCreateMarketer: "Can't create the marketer",
    cantFetchMarketers: "Can't load marketers",

    cantFetchPermissions: "Can't load permissions",
    cantUpdateUserPermissions: "Can't update user permissions",

    cantFetchPageTypes: "Can't load page types",
    cantUpdatePageTypes: "Can't update default percentages",
    cantFetchPercentageSchemes: "Can't load percentages",
    cantReloadCompetitorsPercentageSchemes: "Can't reload competitors percentage schemes",

    cantEnqueuePageDlsCrawling: "Can't schedule crawling of your backlinks.",
    cantEnqueueDestinationLinkCrawling: "Can't schedule crawling of your backlink.",
    cantEnqueueBrandMetricsGeneration: "Can't schedule brand metrics generation",
    cantEnqueueBrandPageMetricsGeneration: "Can't schedule metrics calculation for this page",

    limitedLinks: ({ value, isAtOnce }) =>
      `We are sorry, you can add only ${value} links${isAtOnce ? ' at once.' : '.'} `,
    mustBePositive: `We are sorry, only positive number allowed.`,

    numberOfPastedMoreThanExistingRows:
      'The number of the rows pasted exceeds the number of the existing rows. \
    To add the new rows please paste the values to the bottom row.',

    cantDeleteProxy: "Can't delete proxy",
    cantFetchProxy: "Can't load proxies",
    cantCheckAllProxy: "Can't check all proxies",
    cantCheckSingleProxy: "Can't check one of the proxies",

    cantFetchSubscriptionPlans: "Can't load subscription plans",
    cantChangeSubscriptionPlan: "Can't change subscription plan",
    cantStartExtendedTrial: "Can't extend your trial",
    cantFetchCardInfo: "Can't load card info",
    cantFetchCharges: "Can't load payments data",
    cantLaunchCheckoutSession: "Can't launch Checkout",

    cantFetchCurrentSubscription: "Can't fetch current subscription",

    cantSendFeedback: "Can't send feedback",
    cantUpdateHelpcrunchCustomAttributes: "Can't update Helpcrunch attributes",

    cantFetchCrawlersStatus: "Can't get crawlers' status",

    timeout: 'Timeout connecting to the server',

    cantUpdateCurrentUser: "Can't update the settings",
    cantFetchUsersAsOptions: "Can't fetch users options",

    cantFetchAnchorTexts: "Can't fetch anchor texts",
    cantUpdateDestinationLinkAnchorText:
      "Can't update the anchor text for current destination link",

    cantFetchIntersectReport: "Can't fetch Intersect Report",

    cantFetchAllNotifications: "Can't fetch all notifications",
    cantFetchUnreadNotificationsCount: "Can't fetch unread notifications count",
    cantFetchRecentNotifications: "Can't fetch recent notifications",
    cantMarkAllNotificationsAsRead: "Can't mark all notifications as read",

    cantAddPagesToAtp: "Can't add pages to ATP",
    cantAutodiscoverPagesViaSitemap: "Can't autodiscover pages via sitemap",
    cantFetchBulkSetupBrandPages: "Can't fetch the brand pages for bulk setup",
    cantImportPagesManually: "Can't manually import pages",
    cantCreateDraftBrandPage: "Can't create brand page",
    invalidUrl: "URL doesn't match format https://www.your-url.com or original domain name",
    cantFetchTitleTags: "Can't get title tags for the URL",
    cantUpdateDraftBrandPage: "Can't update brand page",

    cantFetchBacklinks: "Can't fetch backlinks",
    cantUpdateBacklink: "Can't update backlink",
    cantUpdateBacklinks: "Can't update backlinks",

    cantFetchRailsContext: "Can't load account data",

    cantFetchBrandsChartMetrics: "Can't fetch brand chart metrics",
    cantFetchBrandDashboardData: "Can't fetch brand dashboard data",
    cantFetchBrandsMetrics: "Can't fetch brands metrics",

    cantFetchBrandKeywords: "Can't fetch brand's keywords",
    cantFetchKeywordsWithChanges: "Can't fetch keywords with changes",
    cantFetchRankTrackerCountries: "Can't fetch rank tracker countries",
    cantFetchRankTrackerEngines: "Can't fetch rank tracker engines",
    cantFetchRankTrackerLanguages: "Can't fetch rank tracker languages",
    cantFetchRankTrackerLocations: "Can't fetch rank tracker locations",
    cantFetchRankTrackerSettings: "Can't fetch rank tracker settings",
    cantRecheckKeywordsRankings: "Can't recheck keywords",
    cantSaveRankTrackerSettings: "Can't save rank tracker settings",
    cantUpdateBrandKeywords: "Can't update brand's keywords",
    mustBeFilled: 'must be filled',
    tooManyTrackedKeywords: (allowedTrackedKwsCount) =>
      `No more than ${allowedTrackedKwsCount} tracked keywords allowed`,
    cantVerifyRecaptcha: "Can't verify recaptcha",

    cantFetchCrawlerStatusData: "Can't fetch crawler status data",
    cantFetchBrandPagesForFilterByBrandId: "Can't fetch brand pages for filter",

    cantAddCompetitorsManually: "Can't add competitors manually",
    cantAddCompetitorsToAtp: "Can't add competitors to ATP",
    cantAutodiscoverCompetitorsByKeyword: "Can't autodiscover competitors by the keyword",
    cantFetchBulkCompetitorPages: "Can't fetch the competitor pages for bulk setup",
    cantUpdateDraftCompetitorPage: "Can't update competitor page",

    cantGetResultFromDataforseo: 'Uh oh. Something went wrong. Try again in a few minutes',

    cantSignIn: "Can't sign in",
    cantFetchImpersonates: "Can't fetch impersonates",

    cantFetchNewsletterSettings: "Can't fetch notifications settings",
    cantUpdateNewsletterSettings: "Can't update notifications settings",

    cantFetchAnchorSamples: "Can't fetch Anchor Samples",

    cantFetchChangesHistory: "Can't fetch changes history",

    cantCreateProspect: "Can't add a prospect",
    cantDeleteProspects: "Can't delete prospects",
    cantFetchProspects: "Can't fetch prospects",
    cantFetchProspect: "Can't fetch prospect",
    cantUpdateProspect: "Can't update prospect",
    cantImportProspects: "Can't import prospects",
  },
};

export default errors;
