import { Map as iMap, fromJS } from 'immutable';

import { FETCH_NEWSLETTER_SETTINGS__SUCCESS } from 'actions/newsletterSettingsActions';
import { NEWSLETTER_SETTINGS_UNSUBSCRIBE__SUCCESS } from 'actions/newsletterSettingsUnsubscribeActions';

const initialState = iMap();

export default function newsletterSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case NEWSLETTER_SETTINGS_UNSUBSCRIBE__SUCCESS:
    case FETCH_NEWSLETTER_SETTINGS__SUCCESS: {
      state = fromJS(action.newsletterSettings);

      break;
    }
  }

  return state;
}
