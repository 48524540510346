import { fromJS } from 'immutable';

import {
  CREATE_EMPLOYEE__SUCCESS,
  UPDATE_EMPLOYEE_FORM_ROW,
} from '../actions/employee_actions';

import { translate } from '../common/i18n';

import { camelizeObject } from './utils';


function processEmployee(employee) {
  return camelizeObject(employee);
}

const placeholder = {
  email: translate('employee.email.placeholder'),
  name: translate('employee.name.placeholder'),
  profileType: translate('employee.profileType.placeholder'),
  errors: {},
};


const initialState = fromJS(placeholder);

export default function employeeFormRowReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_EMPLOYEE_FORM_ROW: {
      state = fromJS(processEmployee(action.employee));

      break;
    }
    case CREATE_EMPLOYEE__SUCCESS: {
      state = initialState;

      break;
    }
  }

  return state;
}
