import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PageItemComponent from './PageItemComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  brandPageShape,
  optionsMap,
  policiesShape,
  subscriptionPoliciesShape,
} from 'common/prop_types_shapes';

import { openEditKeywordsPopup } from 'actions/popup_actions';
import { optionsSelector, pagesByParentSelector, visiblePageIdByParentSelector } from 'selectors';
import { policiesSelector, subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';
import { updatePagePriority } from 'api/brand_page';

const PageItemContainer = ({
  dispatch,
  page,
  pageChildType,
  pageParentId,
  pageParentType,
  pageTypeOptions,
  policies,
  subscriptionPolicies,
  visiblePageId,
}) => {
  async function handlePageChangePriority(value) {
    await updatePagePriority(dispatch, page.get('id'), value);
  }

  function handleClickKeywordsCell() {
    dispatch(
      openEditKeywordsPopup({
        pageId: page.get('id'),
        targetAppModule: 'atp',
        pageParentType,
        pageParentId,
      }),
    );
  }

  const isOpen = Number(visiblePageId) === page.get('id');

  return (
    <PageItemComponent
      isOpen={isOpen}
      onClickKeywordsCell={handleClickKeywordsCell}
      onPageChangePriority={handlePageChangePriority}
      page={page}
      pageChildType={pageChildType}
      pageParentId={pageParentId}
      pageParentType={pageParentType}
      pageTypeOptions={pageTypeOptions}
      policies={policies}
      subscriptionPolicies={subscriptionPolicies}
    />
  );
};

PageItemContainer.propTypes = {
  dispatch: PropTypes.func,
  page: brandPageShape,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  pageTypeOptions: optionsMap,
  policies: policiesShape,
  subscriptionPolicies: subscriptionPoliciesShape,
  visiblePageId: PropTypes.string,
};

function select(state, ownProps) {
  const pages = pagesByParentSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);
  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const pageTypeOptions = options.get('pageTypeOptions');
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  return {
    pages,
    pageTypeOptions,
    policies,
    subscriptionPolicies,
    visiblePageId,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(PageItemContainer)));
