import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EoEmailVerificationPageComponent from './EoEmailVerificationPageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

const EoEmailVerificationContainer = () => {
  function handleSaveVerificationOptions() {}

  return <EoEmailVerificationPageComponent onSubmit={handleSaveVerificationOptions} />;
};

EoEmailVerificationContainer.propTypes = {
  dispatch: PropTypes.func,
};

export default ConnectStoreHOC(connect()(EoEmailVerificationContainer));
