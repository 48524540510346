import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ButtonComponent from '../button_component';

class BackButtonComponent extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
    ]),
    history: PropTypes.shape({
      goBack: PropTypes.func,
    }),
  };

  handleClick = () => this.props.history.goBack()

  render() {
    return (
      <ButtonComponent
        {...this.props}
        onClick={this.handleClick}
      >
        {this.props.children}
      </ButtonComponent>
    );
  }
}

export default withRouter(BackButtonComponent);
