export const CREATE_EO_CUSTOM_FIELD__FAILURE = 'CREATE_EO_CUSTOM_FIELD__FAILURE';
export const CREATE_EO_CUSTOM_FIELD__START = 'CREATE_EO_CUSTOM_FIELD__START';
export const CREATE_EO_CUSTOM_FIELD__SUCCESS = 'CREATE_EO_CUSTOM_FIELD__SUCCESS';
export const DELETE_EO_CUSTOM_FIELDS__FAILURE = 'DELETE_EO_CUSTOM_FIELDS__FAILURE';
export const DELETE_EO_CUSTOM_FIELDS__START = 'DELETE_EO_CUSTOM_FIELDS__START';
export const DELETE_EO_CUSTOM_FIELDS__SUCCESS = 'DELETE_EO_CUSTOM_FIELDS__SUCCESS';
export const FETCH_EO_CUSTOM_FIELDS__FAILURE = 'FETCH_EO_CUSTOM_FIELDS__FAILURE';
export const FETCH_EO_CUSTOM_FIELDS__START = 'FETCH_EO_CUSTOM_FIELDS__START';
export const FETCH_EO_CUSTOM_FIELDS__SUCCESS = 'FETCH_EO_CUSTOM_FIELDS__SUCCESS';
export const FETCH_EO_CUSTOM_FIELD__FAILURE = 'FETCH_EO_CUSTOM_FIELD__FAILURE';
export const FETCH_EO_CUSTOM_FIELD__START = 'FETCH_EO_CUSTOM_FIELD__START';
export const FETCH_EO_CUSTOM_FIELD__SUCCESS = 'FETCH_EO_CUSTOM_FIELD__SUCCESS';
export const UPDATE_EO_CUSTOM_FIELD__FAILURE = 'UPDATE_EO_CUSTOM_FIELD__FAILURE';
export const UPDATE_EO_CUSTOM_FIELD__START = 'UPDATE_EO_CUSTOM_FIELD__START';
export const UPDATE_EO_CUSTOM_FIELD__SUCCESS = 'UPDATE_EO_CUSTOM_FIELD__SUCCESS';

export function createEoCustomFieldStart() {
  return { type: CREATE_EO_CUSTOM_FIELD__START };
}

export function createEoCustomFieldSuccess(eoCustomField) {
  return { type: CREATE_EO_CUSTOM_FIELD__SUCCESS, eoCustomField };
}

export function createEoCustomFieldFailure(errors) {
  return { type: CREATE_EO_CUSTOM_FIELD__FAILURE, errors };
}

export function deleteEoCustomFieldsStart() {
  return { type: DELETE_EO_CUSTOM_FIELDS__START };
}

export function deleteEoCustomFieldsSuccess(ids) {
  return { type: DELETE_EO_CUSTOM_FIELDS__SUCCESS, ids };
}

export function deleteEoCustomFieldsFailure(errors) {
  return { type: DELETE_EO_CUSTOM_FIELDS__FAILURE, errors };
}

export function fetchEoCustomFieldsStart() {
  return { type: FETCH_EO_CUSTOM_FIELDS__START };
}

export function fetchEoCustomFieldsSuccess(eoCustomFields) {
  return { type: FETCH_EO_CUSTOM_FIELDS__SUCCESS, eoCustomFields };
}

export function fetchEoCustomFieldsFailure(errors) {
  return { type: FETCH_EO_CUSTOM_FIELDS__FAILURE, errors };
}

export function fetchEoCustomFieldStart() {
  return { type: FETCH_EO_CUSTOM_FIELD__START };
}

export function fetchEoCustomFieldSuccess(eoCustomField) {
  return { type: FETCH_EO_CUSTOM_FIELD__SUCCESS, eoCustomField };
}

export function fetchEoCustomFieldFailure(errors) {
  return { type: FETCH_EO_CUSTOM_FIELD__FAILURE, errors };
}

export function updateEoCustomFieldStart() {
  return { type: UPDATE_EO_CUSTOM_FIELD__START };
}

export function updateEoCustomFieldSuccess(eoCustomField) {
  return { type: UPDATE_EO_CUSTOM_FIELD__SUCCESS, eoCustomField };
}

export function updateEoCustomFieldFailure(errors) {
  return { type: UPDATE_EO_CUSTOM_FIELD__FAILURE, errors };
}
