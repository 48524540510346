import React from 'react';
import PropTypes from 'prop-types';

import { pagyShape } from 'common/prop_types_shapes';
import Pager from 'components/Pager';

import ToggleShowPopup from 'components_linkio/ToggleShowPopup';
import BulkPagesSetupBulkActions from './BulkPagesSetupBulkActions';
import BulkPagesSetupTable from './BulkPagesSetupTable';

import AtpSimpleModePopup from './AtpSimpleModePopup';

const PAGES_COUNT_TO_SHOW_BOTTOM_BUTTONS = 10;

const BulkPagesSetupComponent = ({
  brandId,
  bulkPagesSetupPagy,
  draftBrandPagesCount,
  isSimpleMode,
  onItemsSelectChange,
  onPagesPagerClick,
}) => {
  return (
    <div className="bulk-pages-setup">
      <BulkPagesSetupBulkActions />

      <ToggleShowPopup key={brandId} show={isSimpleMode} Popup={<AtpSimpleModePopup />} />

      <BulkPagesSetupTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="pagesPager"
        pagy={bulkPagesSetupPagy}
      />

      {draftBrandPagesCount >= PAGES_COUNT_TO_SHOW_BOTTOM_BUTTONS && (
        <BulkPagesSetupBulkActions isBottom />
      )}
    </div>
  );
};

BulkPagesSetupComponent.propTypes = {
  brandId: PropTypes.number,
  bulkPagesSetupPagy: pagyShape,
  draftBrandPagesCount: PropTypes.number,
  isSimpleMode: PropTypes.bool,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func.isRequired,
};

export default BulkPagesSetupComponent;
