import React from 'react';
import PropTypes from 'prop-types';

import { eoInboxesSettingsShape } from 'common/propTypesShapes/eoInboxesSettings';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';

import './eoInboxesSettings.scss';

const EoInboxesSettingsComponent = ({ canUpdate, eoInboxesSettings, onUpdateSettings }) => {
  const {
    bccEmail: defaultBccEmail,
    // reportEmail: defaultReportEmail,
  } = eoInboxesSettings.toJS();

  const [bccEmail, setBccEmail] = React.useState(defaultBccEmail || '');
  // const [reportEmail, setReportEmail] = React.useState(defaultReportEmail || '');
  const [errors, setErrors] = React.useState({});

  function handleChangeBccEmail(event) {
    setBccEmail(event.target.value);
  }

  // function handleChangeReportEmail(event) {
  //   setReportEmail(event.target.value);
  // }

  async function handleUpdateSettings() {
    const resp = await onUpdateSettings({
      bccEmail,
      // reportEmail,
    });

    setErrors(resp.errors);
  }

  const bccEmailMessage = errors.bccEmail
    ? { type: 'error', text: errors.bccEmail }
    : { type: 'hint', text: translate('eoInboxesSettings.inputs.bccEmail.hint') };

  // const reportEmailMessage = errors.reportEmail
  //   ? { type: 'error', text: errors.reportEmail }
  //   : { type: 'hint', text: translate('eoInboxesSettings.inputs.reportEmail.hint') };

  return (
    <>
      <div className="eo-inboxes-settings__inputs">
        <InputComponent
          isDisabled={!canUpdate}
          label={translate('eoInboxesSettings.inputs.bccEmail.label')}
          message={bccEmailMessage}
          name="bcc-email"
          onInputChange={handleChangeBccEmail}
          type="email"
          value={bccEmail}
        />

        {/* <InputComponent
          isDisabled={!canUpdate}
          label={translate('eoInboxesSettings.inputs.reportEmail.label')}
          message={reportEmailMessage}
          name="report-email"
          onInputChange={handleChangeReportEmail}
          type="email"
          value={reportEmail}
        /> */}
      </div>

      {canUpdate && (
        <ButtonComponent isInitial isBlue onClick={handleUpdateSettings}>
          {translate('eoInboxesSettings.buttons.update')}
        </ButtonComponent>
      )}
    </>
  );
};

EoInboxesSettingsComponent.propTypes = {
  canUpdate: PropTypes.bool,
  eoInboxesSettings: eoInboxesSettingsShape,
  onUpdateSettings: PropTypes.func,
};

export default EoInboxesSettingsComponent;
