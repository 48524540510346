import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import LensIcon from 'common/icons/lens';

const CrawlPageDlsButtonComponent = ({ canUseCrawlers, onPageCrawl }) => {
  const explanationTooltip = () => ({
    text: translate('explanationMessages.atpTable.crawlButton'),
    placement: 'top',
    color: 'black',
  });

  const subscriptionLimitTooltip = () => {
    const subscriptionLimitMessageParts = `${translate('subscriptionLimitRibbon.message')(
      translate('subscriptionLimitRibbon.limited.crawling'),
    )} \
      ${translate('subscriptionLimitRibbon.upgradePopupLink.text')} \
      ${translate('subscriptionLimitRibbon.upgradePopupLink.link')}`;

    return {
      text: subscriptionLimitMessageParts,
      placement: 'top',
      color: 'red',
    };
  };

  const crawlButtonTooltip = canUseCrawlers ? explanationTooltip() : subscriptionLimitTooltip();

  return (
    <ButtonComponent
      isDisabled={!canUseCrawlers}
      isWhite
      onClick={onPageCrawl}
      tooltip={crawlButtonTooltip}
    >
      <span className="bulk-atp-actions-component__icon-wrapper">
        <LensIcon />
      </span>
      {translate('destinationLink.buttons.crawlNow')}
    </ButtonComponent>
  );
};

CrawlPageDlsButtonComponent.propTypes = {
  canUseCrawlers: PropTypes.bool,
  onPageCrawl: PropTypes.func.isRequired,
};

export default CrawlPageDlsButtonComponent;
