import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'lodash';

import BacklinksBulkActionsComponent from './BacklinksBulkActionsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchBrandPagesForFilterByBrandId } from 'api/brand_page';
import { isBacklinksImportingByCurrentBrandIdSelector, optionsSelector } from 'selectors';
import { withRouterPropTypes } from 'common/prop_types_shapes';

const BacklinksBulkActionsContainer = ({
  brandPagesOptions,
  changeDateRange,
  changeQueryParams,
  dateEnd,
  dateStart,
  dispatch,
  isBacklinksImporting,
  match: {
    params: { id, backlinksPageType },
  },
  queryParams,
}) => {
  const [isLoading, setLoadingState] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      setLoadingState(true);
      await fetchBrandPagesForFilterByBrandId(dispatch, id);
      setLoadingState(false);
    }

    fetchData();
  }, [id, isBacklinksImporting]);

  function handleSubmitSearch(value) {
    const newQueryParams = {
      ...queryParams,
      search: value,
      pageNumber: 1,
    };

    changeQueryParams(newQueryParams);
  }

  const searchTerm = queryParams.search || '';

  return (
    <BacklinksBulkActionsComponent
      backlinksPageType={backlinksPageType}
      brandPagesOptions={brandPagesOptions.toList().toJS()}
      changeDateRange={changeDateRange}
      dateEnd={dateEnd}
      dateStart={dateStart}
      isFiltersLoading={isLoading}
      onSubmitSearch={handleSubmitSearch}
      searchTerm={searchTerm}
    />
  );
};

BacklinksBulkActionsContainer.propTypes = {
  ...withRouterPropTypes,
  ...withQueryParamsPropTypes,
  isBacklinksImporting: PropTypes.bool,
};

const select = (state, ownProps) => {
  const isBacklinksImporting = isBacklinksImportingByCurrentBrandIdSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const brandPagesOptions = options.get('brandPagesOptions');

  return {
    brandPagesOptions,
    isBacklinksImporting,
  };
};

export default compose(
  withQueryParams,
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(BacklinksBulkActionsContainer);
