import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import DeleteEoBlacklistedDomainsButtonComponent from './DeleteEoBlacklistedDomainsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { deleteEoBlacklistedDomains } from 'api/eoBlacklistedDomain';
import { selectedIdsList } from 'common/prop_types_shapes';
import { uiSelector } from 'selectors';

const DeleteEoBlacklistedDomainsButtonContainer = ({ dispatch, selectedIds }) => {
  function handleDelete() {
    deleteEoBlacklistedDomains(dispatch, selectedIds.toJS());
  }

  return (
    <DeleteEoBlacklistedDomainsButtonComponent onDelete={handleDelete} selectedIds={selectedIds} />
  );
};

DeleteEoBlacklistedDomainsButtonContainer.propTypes = {
  dispatch: PropTypes.func,
  selectedIds: selectedIdsList,
};

function select(state) {
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    selectedIds,
  };
}

export default compose(ConnectStoreHOC, connect(select))(DeleteEoBlacklistedDomainsButtonContainer);
