export const DELETE_EO_MESSAGES__FAILURE = 'DELETE_EO_MESSAGES__FAILURE';
export const DELETE_EO_MESSAGES__START = 'DELETE_EO_MESSAGES__START';
export const DELETE_EO_MESSAGES__SUCCESS = 'DELETE_EO_MESSAGES__SUCCESS';
export const FETCH_EO_MESSAGES__FAILURE = 'FETCH_EO_MESSAGES__FAILURE';
export const FETCH_EO_MESSAGES__START = 'FETCH_EO_MESSAGES__START';
export const FETCH_EO_MESSAGES__SUCCESS = 'FETCH_EO_MESSAGES__SUCCESS';
export const FETCH_EO_MESSAGE__FAILURE = 'FETCH_EO_MESSAGE__FAILURE';
export const FETCH_EO_MESSAGE__START = 'FETCH_EO_MESSAGE__START';

export function deleteEoMessagesStart() {
  return { type: DELETE_EO_MESSAGES__START };
}

export function deleteEoMessagesSuccess(ids) {
  return { type: DELETE_EO_MESSAGES__SUCCESS, ids };
}

export function deleteEoMessagesFailure(errors) {
  return { type: DELETE_EO_MESSAGES__FAILURE, errors };
}

export function fetchEoMessagesStart() {
  return { type: FETCH_EO_MESSAGES__START };
}

export function fetchEoMessagesSuccess(eoMessages) {
  return { type: FETCH_EO_MESSAGES__SUCCESS, eoMessages };
}

export function fetchEoMessagesFailure(errors) {
  return { type: FETCH_EO_MESSAGES__FAILURE, errors };
}

export function fetchEoMessageStart() {
  return { type: FETCH_EO_MESSAGE__START };
}

export function fetchEoMessageFailure(errors) {
  return { type: FETCH_EO_MESSAGE__FAILURE, errors };
}
