import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  buildCompetitorsSchemeOptions,
  buildCustomSchemeOptions,
  buildDefaultSchemeOptions,
  defineSelectedSchemeType,
} from './utils';

import { translate } from 'common/i18n';
import { optionsMap } from 'common/prop_types_shapes';

import ButtonComponent from 'components_linkio/button_component';
import MessageComponent from 'components_linkio/message_component';
import RadioButton from 'components_linkio/RadioButton';
import RoboImage from 'common/img/linkio_robo.svg';
import Select from 'components_linkio/Select';
import { Modal } from 'components_linkio/modal_component';
import { valueFromImmutable } from 'components_linkio/Select/utils';

import './changePercentagesPopup.scss';

export default class ChangePercentagesPopupComponent extends Component {
  static propTypes = {
    isUpdating: PropTypes.bool,
    onAddCompetitors: PropTypes.func,
    onAddCustomPercentages: PropTypes.func,
    onApplyPercentages: PropTypes.func,
    onCancel: PropTypes.func,
    pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
    percentageSchemeId: PropTypes.number,
    percentageSchemeOptions: optionsMap,
    visiblePageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);

    // do not throw an error in case page is not loaded yet in parent component
    if (!props.percentageSchemeId) {
      this.state = {
        selectedSchemeType: 'default',
        selectedSchemeValue: {
          label: '',
          value: '',
        },
      };

      return;
    }

    const selectedSchemeValue = valueFromImmutable(
      props.percentageSchemeOptions,
      props.percentageSchemeId,
    );

    const selectedSchemeType = defineSelectedSchemeType(selectedSchemeValue);

    this.state = {
      selectedSchemeType,
      selectedSchemeValue,
    };
  }

  handleSelectedSchemeTypeChange = (event) => {
    this.setState({
      selectedSchemeType: event.target.value,
      selectedSchemeValue: {
        label: '',
        value: '',
      },
    });
  };

  handleSelectedSchemeValueChange = (object) => {
    this.setState({ selectedSchemeValue: object });
  };

  handleApplyPercentagesClick = () => {
    const { selectedSchemeValue } = this.state;

    this.props.onApplyPercentages(selectedSchemeValue);
  };

  buildPercentageMenuItemClasses(schemeType) {
    const { selectedSchemeType } = this.state;

    const percentageMenuItemClasses = classnames({
      'change-percentages-popup__percentages-menu-item': true,
      'change-percentages-popup__percentages-menu-item_active': selectedSchemeType === schemeType,
    });

    return percentageMenuItemClasses;
  }

  render() {
    const {
      isUpdating,
      onAddCompetitors,
      onAddCustomPercentages,
      onCancel,
      pageParentType,
      percentageSchemeId,
      percentageSchemeOptions,
      visiblePageId,
    } = this.props;

    const { selectedSchemeType, selectedSchemeValue } = this.state;

    const defaultSchemeOptions = buildDefaultSchemeOptions(percentageSchemeOptions);
    const customSchemeOptions = buildCustomSchemeOptions(percentageSchemeOptions);
    const competitorsSchemeOptions = buildCompetitorsSchemeOptions(
      percentageSchemeOptions,
      visiblePageId,
    );

    const isDefaultSchemeType = selectedSchemeType === 'default';
    const isCompetitorsSchemeType = selectedSchemeType === 'competitors';
    const isCustomSchemeType = selectedSchemeType === 'custom';

    const isBrandPage = pageParentType === 'brand';

    return (
      <Modal show className="change-percentages-popup">
        <Modal.Header>
          <Modal.Title>{translate('changePercentagesPopup.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="change-percentages-popup__wrapper">
            <div className="change-percentages-popup__schemes-select-block">
              <h3 className="change-percentages-popup__subtitle">
                {translate('changePercentagesPopup.subtitle')}
              </h3>

              <div className="change-percentages-popup__percentages-menu">
                <div className={this.buildPercentageMenuItemClasses('default')}>
                  <RadioButton
                    name="default"
                    onChange={this.handleSelectedSchemeTypeChange}
                    checked={isDefaultSchemeType}
                    value="default"
                  >
                    {translate('changePercentagesPopup.schemeTypes.defaultScheme.label')}
                  </RadioButton>
                  <Select
                    blurInputOnSelect
                    triggerChangeOnBlur={false}
                    defaultValue={isDefaultSchemeType && selectedSchemeValue}
                    value={isDefaultSchemeType && selectedSchemeValue}
                    name="defaultScheme"
                    onChange={this.handleSelectedSchemeValueChange}
                    options={defaultSchemeOptions}
                    isDisabled={!isDefaultSchemeType}
                  />
                  <MessageComponent
                    message={{
                      type: 'hint',
                      text: translate('changePercentagesPopup.schemeTypes.defaultScheme.hint'),
                    }}
                  />
                </div>

                {isBrandPage && (
                  <div className={this.buildPercentageMenuItemClasses('competitors')}>
                    <RadioButton
                      name="competitors"
                      onChange={this.handleSelectedSchemeTypeChange}
                      checked={isCompetitorsSchemeType}
                      value="competitors"
                    >
                      {translate('changePercentagesPopup.schemeTypes.competitorsScheme.label')}
                      <span className="change-percentages-popup__recommended">
                        {translate(
                          'changePercentagesPopup.schemeTypes.competitorsScheme.recommended',
                        )}
                      </span>
                    </RadioButton>
                    <div className="change-percentages-popup__select-with-button-wrapper">
                      <Select
                        blurInputOnSelect
                        triggerChangeOnBlur={false}
                        defaultValue={isCompetitorsSchemeType && selectedSchemeValue}
                        value={isCompetitorsSchemeType && selectedSchemeValue}
                        name="competitorsScheme"
                        onChange={this.handleSelectedSchemeValueChange}
                        options={competitorsSchemeOptions}
                        isDisabled={!isCompetitorsSchemeType}
                      />
                      <ButtonComponent
                        isDisabled={!isCompetitorsSchemeType}
                        isGreen
                        isInitial
                        onClick={onAddCompetitors}
                      >
                        {translate('changePercentagesPopup.buttons.addCompetitors')}
                      </ButtonComponent>
                    </div>
                    <MessageComponent
                      message={{
                        type: 'hint',
                        text: translate(
                          'changePercentagesPopup.schemeTypes.competitorsScheme.hint',
                        ),
                      }}
                    />
                  </div>
                )}

                <div className={this.buildPercentageMenuItemClasses('custom')}>
                  <RadioButton
                    name="custom"
                    onChange={this.handleSelectedSchemeTypeChange}
                    checked={isCustomSchemeType}
                    value="custom"
                  >
                    {translate('changePercentagesPopup.schemeTypes.customScheme.label')}
                  </RadioButton>
                  <div className="change-percentages-popup__select-with-button-wrapper">
                    <Select
                      blurInputOnSelect
                      triggerChangeOnBlur={false}
                      defaultValue={isCustomSchemeType && selectedSchemeValue}
                      value={isCustomSchemeType && selectedSchemeValue}
                      name="customScheme"
                      onChange={this.handleSelectedSchemeValueChange}
                      options={customSchemeOptions}
                      isDisabled={!isCustomSchemeType}
                    />
                    <ButtonComponent
                      className="change-percentages-popup__add-custom-percentages-button"
                      isDisabled={!isCustomSchemeType}
                      isGreen
                      isInitial
                      onClick={onAddCustomPercentages}
                    >
                      {translate('changePercentagesPopup.buttons.addCustomPercentages')}
                    </ButtonComponent>
                  </div>
                  <MessageComponent
                    message={{
                      type: 'hint',
                      text: translate('changePercentagesPopup.schemeTypes.customScheme.hint'),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="change-percentages-popup__percentage-scheme-description-wrapper">
              <div className="change-percentages-popup__percentage-scheme-description-header">
                <div className="change-percentages-popup__linkio">
                  <img src={RoboImage} />
                </div>
                <div className="change-percentages-popup__percentage-scheme-description-title">
                  <b>{translate('changePercentagesPopup.percentageSchemeDescription.about')}</b>
                  &nbsp;
                  {selectedSchemeValue.label}
                </div>
              </div>

              <div className="change-percentages-popup__percentage-scheme-description-text">
                {translate(
                  `changePercentagesPopup.percentageSchemeDescription.${selectedSchemeValue.label.replace(
                    /\s/g,
                    '',
                  )}`,
                )}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="change-percentages-popup__btn-group">
            <div className="change-percentages-popup__btn">
              <ButtonComponent isWhite onClick={onCancel}>
                {translate('changePercentagesPopup.buttons.cancel')}
              </ButtonComponent>
            </div>

            <div className="change-percentages-popup__btn">
              <ButtonComponent
                isDisabled={
                  String(percentageSchemeId) === selectedSchemeValue.value ||
                  !selectedSchemeValue.value
                }
                isLoading={isUpdating}
                isGreen
                onClick={this.handleApplyPercentagesClick}
              >
                {translate('changePercentagesPopup.buttons.apply')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
