export const FETCH_ONBOARDING_STATUS__FAILURE = 'FETCH_ONBOARDING_STATUS__FAILURE';
export const FETCH_ONBOARDING_STATUS__START = 'FETCH_ONBOARDING_STATUS__START';
export const FETCH_ONBOARDING_STATUS__SUCCESS = 'FETCH_ONBOARDING_STATUS__SUCCESS';

export function fetchOnboardingStatusStart() {
  return { type: FETCH_ONBOARDING_STATUS__START };
}

export function fetchOnboardingStatusSuccess(onboardingStatus) {
  return { type: FETCH_ONBOARDING_STATUS__SUCCESS, onboardingStatus };
}

export function fetchOnboardingStatusFailure(errors) {
  return { type: FETCH_ONBOARDING_STATUS__FAILURE, errors };
}
