import React from 'react';
import PropTypes from 'prop-types';

import PagerItemsSelectComponent from './PagerItemsSelectComponent';
import PagerPagesControlComponent from './PagerPagesControlComponent';

import { pagerTypeShape, pagyShape } from 'common/prop_types_shapes';

import './pager.scss';

const PagerComponent = ({ handleItemsSelectChange, handlePagesControlClick, pagerType, pagy }) => {
  return (
    <div className="pager__wrapper">
      <PagerItemsSelectComponent
        handleItemsSelectChange={handleItemsSelectChange}
        pagerType={pagerType}
        pagy={pagy}
      />

      {pagy.get('pages') > 1 && (
        <div className="pager-pages-control__wrapper">
          <PagerPagesControlComponent
            handlePagesControlClick={handlePagesControlClick}
            pagy={pagy}
          />
        </div>
      )}
    </div>
  );
};

PagerComponent.propTypes = {
  handleItemsSelectChange: PropTypes.func.isRequired,
  handlePagesControlClick: PropTypes.func.isRequired,
  pagerType: pagerTypeShape.isRequired,
  pagy: pagyShape,
};

export default PagerComponent;
