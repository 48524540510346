import { fromJS, Map as iMap } from 'immutable';

import { FETCH_BRANDS_CONTEXT__SUCCESS } from 'actions/brandsContextActions';

import { camelizeObject } from 'reducers/utils';

const initialState = iMap();

export default function brandsContextReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRANDS_CONTEXT__SUCCESS: {
      const { brandId, context } = action;

      state = state.mergeIn([String(brandId)], fromJS(camelizeObject(context)));

      break;
    }
  }

  return state;
}
