import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, isInteger, isEqual } from 'lodash';

import { ATP_TABLE_TABS_COLUMNS, ATP_TABLE_TABS_FILTERS } from '../AtpTableTabs/constants';
import AtpTable from '../atp_table';
import AtpTableTabs from '../AtpTableTabs';
import ContainerWithScrollComponent from '../../container_with_scroll_component';
import DlDetailsPopupComponent from '../dl_details_popup_component';
import NoDLsComponent from '../no_dls_component';
import Pager from '../../Pager';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { updateDestinationLinksSuccess } from 'actions/destination_link_actions';
import { createDl, fetchDlAnchorTexts, updateDl, updateDlAnchorText } from 'api/destination_links';
import { fetchPagePercentage } from 'api/brand_page';
import { createDlSource } from 'api/dl_source';

import {
  activeAtpTableTabSelector,
  atpPagyByBrandPageIdSelector,
  destinationLinksByBrandPageIdSelector,
  optionsSelector,
  uiSelector,
} from 'selectors';

import {
  currentSubscriptionSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import {
  brandPageShape,
  destinationLinksMap,
  optionsMap,
  pagyShape,
  policiesShape,
  subscriptionShape,
  subscriptionPoliciesShape,
} from 'common/prop_types_shapes';

import BulkCompetitorsSetup from 'pages/BulkCompetitorsSetup';
import { scrollToElement, showErrorMessage } from 'common/utils';
import { getSelected } from 'pages/Pages/utils';

import { smartFetchPageDlsService } from 'pages/Pages/PagesList/PageItem/PageDls/services/smartFetchPageDlsService';

import BulkDlsActions from 'pages/Pages/PagesList/PageItem/PageDls/BulkDlsActions';
import CompetitorPages from 'pages/CompetitorPages';
import DlsPercentage from 'pages/Pages/PagesList/PageItem/PageDls/DlsPercentage';
import DlsStatusBarComponent from 'pages/Pages/PagesList/PageItem/PageDls/DlsTable/DlsStatusBar';
import MetricsPanel from 'components_linkio/MetricsPanel';

import './brand_page_container.scss';

class BrandPageContainer extends React.PureComponent {
  static propTypes = {
    activeAtpTableTab: PropTypes.string,
    atpPagy: pagyShape,
    brandId: PropTypes.string,
    brandPage: brandPageShape,
    brandPageId: PropTypes.string,
    currentSubscription: subscriptionShape,
    destinationLinks: destinationLinksMap,
    dispatch: PropTypes.func,
    dlSourcesOptions: optionsMap,
    fetchingDls: PropTypes.bool,
    onClickMetrics: PropTypes.func,
    onClickUpgradeSubscription: PropTypes.func,
    onFetchCurrentSubscription: PropTypes.func,
    onRecalculatePercentage: PropTypes.func,
    policies: policiesShape,
    showAnchorProgressPane: PropTypes.bool,
    subscriptionPolicies: subscriptionPoliciesShape,
    ...withQueryParamsPropTypes,
  };

  state = {
    highlightRows: [],
    showDlDetailsPopupFor: null,
  };

  componentDidMount() {
    const { brandPageId, dispatch } = this.props;

    const openedBrandPageElement = document.querySelector('.brand-page-item-component_is-open');

    const navbarHeight = 120; // subtract navbar's height
    scrollToElement(openedBrandPageElement, { minusValue: navbarHeight });

    const dlsFetchingConfig = this.buildDlsFetchingConfig();

    Promise.all([
      smartFetchPageDlsService(dispatch, brandPageId, dlsFetchingConfig),
      fetchPagePercentage(dispatch, brandPageId),
    ]);
  }

  componentDidUpdate(prevProps) {
    const { activeAtpTableTab, queryParams } = this.props;

    const isCompetitorsTab = activeAtpTableTab === 'competitors';

    const needToRefetchPageDls =
      !isCompetitorsTab &&
      (activeAtpTableTab !== prevProps.activeAtpTableTab ||
        !isEqual(queryParams, prevProps.queryParams));

    if (needToRefetchPageDls) {
      const { brandPageId, dispatch } = this.props;

      const dlsFetchingConfig = this.buildDlsFetchingConfig();

      smartFetchPageDlsService(dispatch, brandPageId, dlsFetchingConfig);
    }
  }

  buildDlsFetchingConfig = () => {
    const { activeAtpTableTab, queryParams } = this.props;

    const {
      dlsGroupMode = '',
      filters = {},
      metricsFilter,
      pageNumber = 1,
      pagyItemsCount = 50,
      sorting = {},
    } = queryParams;

    const dlsFetchingConfig = {
      activeAtpTableTab,
      activeMetric: metricsFilter,
      dlsGroupMode,
      filters,
      pagyItemsCount,
      pageNumber,
      sorting,
    };

    return dlsFetchingConfig;
  };

  handleHighlightRows = (rowsIds) => {
    this.setState({ highlightRows: rowsIds }, this.setState({ highlightRows: [] }));
  };

  handleShowErrorMessage = (message) => showErrorMessage(this.props.dispatch, message);

  // Need to update state after selecting links
  handleUpdateRows = (dls) => this.props.dispatch(updateDestinationLinksSuccess(dls));

  handleUpdateDl = async (links = null) => {
    const { dispatch, brandPageId, destinationLinks } = this.props;

    const data = await updateDl(dispatch, brandPageId, links || destinationLinks.valueSeq().toJS());

    if (data.type && data.type === 'error') {
      // Error message will appear
    } else {
      this.handleHighlightRows(map(data, (row) => row.id));
    }
  };

  handleUpdateDlAnchorText = async (destinationLinkId) => {
    const { dispatch, brandPageId } = this.props;

    const data = await updateDlAnchorText(dispatch, brandPageId, destinationLinkId);

    if (data.type && data.type === 'error') {
      return;
    }

    this.handleHighlightRows(map(data, (row) => row.id));
  };

  handleFetchAnchorTextOptions = (destinationLinkId) => {
    const { dispatch, brandPageId } = this.props;

    return fetchDlAnchorTexts(dispatch, brandPageId, destinationLinkId);
  };

  handleCreateDl = async (newLink) => {
    const { brandPageId, dispatch, onRecalculatePercentage, activeAtpTableTab } = this.props;

    const linksCount = newLink.publishedLink.split(/\r?\n/).length;
    const tabStatus = ATP_TABLE_TABS_FILTERS[activeAtpTableTab]['statusEq'];
    const status = Array(linksCount).fill(tabStatus).join('\r\n');

    const newLinkData = { ...newLink, status };

    const data = await createDl(dispatch, brandPageId, newLinkData);

    if (data.type && data.type === 'error') {
      // Error message will appear
    } else {
      await onRecalculatePercentage(brandPageId);
    }
  };

  selectedRows = () => getSelected(this.props.destinationLinks);

  checkRecalculation = async (property, value = null) => {
    const { onRecalculatePercentage, brandPageId } = this.props;

    if (property === 'source') {
      this.handleDlSourcesChange(value);
    }

    switch (property) {
      case 'status':
      case 'anchorType': {
        await onRecalculatePercentage(brandPageId);
        break;
      }
    }
  };

  handleDlSourcesChange = async (newDlSource) => {
    if (!newDlSource) {
      return;
    }
    const { dispatch, dlSourcesOptions } = this.props;

    if (!dlSourcesOptions.get(newDlSource)) {
      const resp = await createDlSource(dispatch, { title: newDlSource });
      if ((resp || {}).type === 'error') {
        // error will appear
      }
    }
  };

  handleDlDetailsPopupClose = () => this.setState({ showDlDetailsPopupFor: null });
  handleShowDlDetailsPopup = (dlId) => this.setState({ showDlDetailsPopupFor: dlId });

  onAtpPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const { queryParams, changeQueryParams } = this.props;

      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  onAtpPagerItemsSelectChange = ({ value }) => {
    const { queryParams, changeQueryParams } = this.props;

    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  };

  handleReloadSuggestions = (event) => {
    const { brandPageId, dispatch } = this.props;

    event.preventDefault();

    const dlsFetchingConfig = this.buildDlsFetchingConfig();

    smartFetchPageDlsService(dispatch, brandPageId, dlsFetchingConfig);

    fetchPagePercentage(dispatch, brandPageId);
  };

  handleTableHeaderClick = (property) => () => {
    const { changeQueryParams, queryParams } = this.props;
    const { sortBy, sortingOrder } = queryParams.sorting || {};

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  render() {
    const {
      activeAtpTableTab,
      atpPagy,
      brandId,
      brandPage,
      brandPageId,
      destinationLinks,
      onFetchCurrentSubscription,
      policies,
      showAnchorProgressPane,
      subscriptionPolicies,
    } = this.props;

    const { highlightRows, showDlDetailsPopupFor } = this.state;

    const canUpdateAtp = policies.getIn(['atp', 'canUpdate']);
    const canUseCrawlers = subscriptionPolicies.getIn(['crawlers', 'canUse']);

    const dlsCount = atpPagy.get('count', 0);

    const redistributingDlsStatus = brandPage.get('redistributingDlsStatus', 'not_started');

    const atpTableColumns = ATP_TABLE_TABS_COLUMNS[activeAtpTableTab];

    const isBacklinksTab = activeAtpTableTab === 'backlinks';
    const isCompetitorsTab = activeAtpTableTab === 'competitors';
    const isBulkCompetitorsSetupTab = activeAtpTableTab === 'bulk_competitors_setup';

    const aggregatedProps = {
      ...this.props,
      canUseCrawlers,
      checkRecalculation: this.checkRecalculation,
      onCreateDl: this.handleCreateDl,
      onDlDetailsClick: this.handleShowDlDetailsPopup,
      onFetchAnchorTextOptions: this.handleFetchAnchorTextOptions,
      onShowErrorMessage: this.handleShowErrorMessage,
      onTableHeaderClick: this.handleTableHeaderClick,
      onUpdateDl: this.handleUpdateDl,
      onUpdateDlAnchorText: this.handleUpdateDlAnchorText,
      onUpdateRows: this.handleUpdateRows,
      selectedRows: this.selectedRows(),
    };

    return (
      <div className="brand-page-container__wrapper">
        <AtpTableTabs />
        <div className="brand-page-container">
          <div className="brand-page-container__main-content">
            {isCompetitorsTab && (
              <CompetitorPages
                pageChildType={'competitorsPage'}
                pageParentType={'brand_page'}
                pageParentId={Number(brandPageId)}
              />
            )}
            {isBulkCompetitorsSetupTab && <BulkCompetitorsSetup />}
            {!isCompetitorsTab && !isBulkCompetitorsSetupTab && (
              <>
                {isBacklinksTab && <MetricsPanel appModule="atp" pageParentType="brand" />}
                {canUpdateAtp && (
                  <BulkDlsActions
                    pageChildType="brandPage"
                    pageParentId={Number(brandId)}
                    pageParentType="brand"
                  />
                )}
                <div className="container-with-scroll-component__wrapper">
                  <DlsStatusBarComponent
                    dlsCount={dlsCount}
                    status={redistributingDlsStatus}
                    tab={activeAtpTableTab}
                    onReloadDls={this.handleReloadSuggestions}
                  />
                  <ContainerWithScrollComponent maxWidth={900}>
                    <AtpTable
                      {...aggregatedProps}
                      columns={atpTableColumns}
                      destinationLinks={destinationLinks.valueSeq().toJS()}
                      highlightRows={highlightRows}
                      tableType={activeAtpTableTab}
                    />
                    {dlsCount === 0 && (
                      <NoDLsComponent
                        activeAtpTableTab={activeAtpTableTab}
                        pageChildType={'brandPage'}
                      />
                    )}
                    <Pager
                      handleItemsSelectChange={this.onAtpPagerItemsSelectChange}
                      handlePagesControlClick={this.onAtpPagerClick}
                      pagerType="rowsPager"
                      pagy={atpPagy}
                    />
                  </ContainerWithScrollComponent>
                  {showAnchorProgressPane && (
                    <DlsPercentage
                      pageChildType={'brandPage'}
                      pageParentId={brandId}
                      pageParentType={'brand'}
                    />
                  )}
                </div>
                {canUpdateAtp && (
                  <BulkDlsActions
                    pageChildType="brandPage"
                    pageParentId={Number(brandId)}
                    pageParentType="brand"
                    positionBottom
                  />
                )}
              </>
            )}

            {showDlDetailsPopupFor && (
              <DlDetailsPopupComponent
                destinationLinkId={showDlDetailsPopupFor}
                onClose={this.handleDlDetailsPopupClose}
                onFetchCurrentSubscription={onFetchCurrentSubscription}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function select(state, ownProps) {
  const activeAtpTableTab = activeAtpTableTabSelector(state, ownProps);
  const atpPagy = atpPagyByBrandPageIdSelector(state, ownProps);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const destinationLinks = destinationLinksByBrandPageIdSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const anchorTextOptions = options.get('anchorTextOptions');

  const uiStates = uiSelector(state);
  const showAnchorProgressPane = uiStates.getIn([
    'openClose',
    'brand',
    'anchorProgressPaneIsVisible',
  ]);

  return {
    activeAtpTableTab,
    anchorTextOptions,
    atpPagy,
    currentSubscription,
    destinationLinks,
    showAnchorProgressPane,
    subscriptionPolicies,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(BrandPageContainer)));
