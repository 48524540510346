import React from 'react';
import PropTypes from 'prop-types';

import SelectProspectPopup from './SelectProspectPopup';

import { eoInboxesList } from 'common/propTypesShapes/eoInboxesSettings';
import { prospectShape } from 'common/propTypesShapes/prospects';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import Link from 'components_linkio/link';
import Select from 'components_linkio/Select';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import './eoCampaignTest.scss';

function buildSendEmailsBtnTooltip(selectedProspectId, hasTransformationError) {
  const needToShowTooltip = !selectedProspectId || hasTransformationError;

  if (needToShowTooltip) {
    const text = !selectedProspectId
      ? translate('eoCampaignPreview.test.sendTestEmails.disabledTooltip.noProspectSelected')
      : translate('eoCampaignPreview.test.sendTestEmails.disabledTooltip.hasTransformationError');

    return {
      placement: 'top',
      text,
    };
  }

  return {};
}

const EoCampaignTestComponent = ({
  selectedEoInboxId = null,
  selectedProspect = {},
  eoCampaignId,
  hasTransformationError,
  immutableEoInboxes,
  onChangePreviewOptions,
  onSendEmails,
  handleChangePreviewOptionsGlobal,
}) => {
  const selectedProspectId = selectedProspect.id;

  const eoInboxesBelongToCurrentCampaign = immutableEoInboxes
    .filter((eoInbox) => eoInbox.get('belongsToCurrentEoCampaign'))
    .toJS();

  function onSelectInbox({ value }) {
    if (selectedEoInboxId !== value) {
      onChangePreviewOptions(value, selectedProspectId);
      handleChangePreviewOptionsGlobal('eoInboxId', value);
    }
  }

  function onSelectProspect(prospect) {
    if (selectedProspectId !== prospect.id) {
      onChangePreviewOptions(selectedEoInboxId, prospect.id);
      handleChangePreviewOptionsGlobal('prospect', prospect);
    }
  }

  function handleSendEmails() {
    onSendEmails(selectedEoInboxId, selectedProspectId);
  }

  function buildProspectName() {
    if (!selectedProspectId) {
      return '';
    }

    const { firstName, lastName, email } = selectedProspect;
    return `${firstName || ''} ${lastName || ''} (${email})`;
  }

  const eoInboxesOptions = eoInboxesBelongToCurrentCampaign.map((eoInbox) => ({
    label: `${eoInbox.fromName} <${eoInbox.email}>`,
    value: eoInbox.id,
  }));

  const sendEmailsBtnTooltip = buildSendEmailsBtnTooltip(
    selectedProspectId,
    hasTransformationError,
  );

  return (
    <div className="eo-campaign-test">
      <h2>{translate('eoCampaignPreview.test.title')}</h2>

      <div className="eo-campaign-test__steps-wrapper">
        <div className="eo-campaign-test__step">
          <h3>{translate('eoCampaignPreview.test.chooseInbox.title')}</h3>
          {eoInboxesBelongToCurrentCampaign.length === 0 && (
            <>
              <p>{translate('eoCampaignPreview.test.chooseInbox.blankState.title')}</p>
              <p>
                {translate('eoCampaignPreview.test.chooseInbox.blankState.subtitle.text')}{' '}
                <Link
                  className="text_link"
                  to={Routes.eo_campaigns_eo_inboxes_path(eoCampaignId, { format: null })}
                >
                  {translate('eoCampaignPreview.test.chooseInbox.blankState.subtitle.link')}
                </Link>
                .
              </p>
            </>
          )}

          {eoInboxesBelongToCurrentCampaign.length > 0 && (
            <div className="eo-campaign-test__choose-inbox">
              <Select
                blurInputOnSelect
                isClearable={false}
                onChange={onSelectInbox}
                options={eoInboxesOptions}
                triggerChangeOnBlur={false}
                value={eoInboxesOptions.find(({ value }) => value === selectedEoInboxId) || null}
              />
            </div>
          )}
        </div>

        <div className="eo-campaign-test__step">
          <h3>{translate('eoCampaignPreview.test.selectProspect.title')}</h3>

          {selectedProspectId && (
            <h4 className="eo-campaign-test__prospect-name">{buildProspectName()}</h4>
          )}

          <ToggleShowPopup
            disabled={!selectedEoInboxId}
            Popup={<SelectProspectPopup onSelectProspect={onSelectProspect} />}
          >
            <ButtonComponent
              className="eo-campaign-test__select-prospect-btn"
              isInitial
              isBlue
              isDisabled={!selectedEoInboxId}
              tooltip={
                !selectedEoInboxId
                  ? {
                      placement: 'top',
                      text: translate('eoCampaignPreview.test.selectProspect.disabledTooltip'),
                    }
                  : {}
              }
            >
              {translate('eoCampaignPreview.test.selectProspect.btnTitle')}
            </ButtonComponent>
          </ToggleShowPopup>
        </div>

        <div className="eo-campaign-test__step">
          <h3>{translate('eoCampaignPreview.test.sendTestEmails.title')}</h3>

          <ButtonComponent
            className="eo-campaign-test__send-emails-btn"
            isInitial
            isBlue
            isDisabled={!selectedProspectId || hasTransformationError}
            onClick={handleSendEmails}
            tooltip={sendEmailsBtnTooltip}
          >
            {translate('eoCampaignPreview.test.sendTestEmails.btnTitle')}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

EoCampaignTestComponent.propTypes = {
  eoCampaignId: PropTypes.string,
  handleChangePreviewOptionsGlobal: PropTypes.func,
  hasTransformationError: PropTypes.bool,
  immutableEoInboxes: eoInboxesList,
  onChangePreviewOptions: PropTypes.func,
  onSendEmails: PropTypes.func,
  selectedEoInboxId: PropTypes.number,
  selectedProspect: prospectShape,
};

export default EoCampaignTestComponent;
