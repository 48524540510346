import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import AnchorIcon from 'common/icons/anchor';
import ButtonComponent from 'components_linkio/button_component';

const AnchorProgressPaneButtonComponent = ({ onTogglePane }) => {
  const tooltip = {
    text: translate('explanationMessages.atpBulkAction.anchorProgress'),
    placement: 'left',
    color: 'black',
  };

  return (
    <ButtonComponent isBlue onClick={onTogglePane} tooltip={tooltip}>
      <AnchorIcon className="bulk-atp-actions-component__icon" />
    </ButtonComponent>
  );
};

AnchorProgressPaneButtonComponent.propTypes = {
  onTogglePane: PropTypes.func.isRequired,
};

export default AnchorProgressPaneButtonComponent;
