import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';
import * as edit from 'react-edit';
import { isEqual, keyBy } from 'lodash';
import { fromJS } from 'immutable';

import ButtonComponent from '../../components_linkio/button_component';
import columnsGenerator, { columnSelect } from '../../common/tables/columns_generator';
import { policiesShape, optionsArray, optionsMap } from '../../common/prop_types_shapes';
import { reactSelectColumn, textColumn } from '../../common/tables/columns';
import { translate } from '../../common/i18n';

import {
  addCSSClassToCellInTable,
  highlightTableRow,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
} from '../../common/tables/utils';

import ExplanatoryTooltipComponent from '../explanatory_tooltip_component';
import { optionValueOrValue } from '../../components_linkio/Select/utils';

import './page_type_defaults_table_component.scss';

class PageTypeDefaultsTableComponent extends Component {
  static propTypes = {
    onSaveClick: PropTypes.func.isRequired,
    pageTypeOptions: optionsMap,
    percentageSchemeOptions: optionsArray,
    policies: policiesShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      props,
      saveButtonIsDisabled: true,
      columns: this.generateColumns(props),
      rows: props.pageTypeOptions.toList().toJS(),
      generateColumns: this.generateColumns,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const needToUpdState = !isEqual(nextProps, prevState.props);

    if (needToUpdState) {
      return {
        props: nextProps,
        saveButtonIsDisabled: true,
        columns: prevState.generateColumns(nextProps),
        rows: nextProps.pageTypeOptions.toList().toJS(),
      };
    }

    return null;
  }

  componentDidMount() {
    this.setTableRef();
  }

  tableRef = {};

  setTableRef = () => {
    const table = ReactDOM.findDOMNode(this).getElementsByTagName('table');
    this.tableRef = table[0];
  };

  generateColumns = (props) => {
    const { percentageSchemeOptions } = props;

    const columnClassName = (modificator) => {
      const baseClass = 'page-type-defaults-table-component__column';
      return `${baseClass} ${baseClass}_${modificator}`;
    };

    const defaultPercentageSelectProps = {
      autoFocus: true,
      blurInputOnSelect: false,
      isClearable: false,
      openMenuOnFocus: true,
      triggerChangeOnBlur: true,
      skinOptions: {
        inTable: true,
      },
    };

    const columns = [
      textColumn({
        className: columnClassName('page-type'),
        headerLabel: translate('percentageSettings.pageTypeDefaults.pageType.title'),
        name: 'label',
      }),
      reactSelectColumn({
        cellTransforms: [
          columnSelect(this.editable, percentageSchemeOptions, defaultPercentageSelectProps),
        ],
        cellValueFormatter: this.defaultPercentageSchemeIdValueFormatter,
        className: columnClassName('default-percentage'),
        headerLabel: translate(
          'percentageSettings.pageTypeDefaults.defaultPercentageSchemeId.title',
        ),
        name: 'defaultPercentageSchemeId',
      }),
    ];

    return columnsGenerator(columns);
  };

  defaultPercentageSchemeIdValueFormatter = (value) => {
    const { percentageSchemeOptions } = this.props;
    const processedValue = optionValueOrValue(value);

    const selectedOption = percentageSchemeOptions
      .map((group) => group.options)
      .flat()
      .find((option) => option.value === String(processedValue));

    return selectedOption.label;
  };

  editable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowData }) => {
      const canUpdate = this.props.policies.getIn(['percentageSetting', 'canUpdate']);

      if (!canUpdate) {
        return;
      }

      const pageTypeOptions = this.stateRowsToSortedImmutable(this.state.rows);

      const index = pageTypeOptions
        .toList()
        .findIndex((pageType) => pageType.get('value') === rowData.value);
      const editingPageType = pageTypeOptions
        .get(String(rowData.value))
        .set('editing', columnIndex);

      addCSSClassToCellInTable(this.tableRef, index, columnIndex, TABLE_CELL_ACTIVE_CLASS);

      this.updateStateRows(pageTypeOptions.set(String(rowData.value), editingPageType));
    },

    onValue: async ({ value, rowData, property }) => {
      const pageTypeOptions = this.stateRowsToSortedImmutable(this.state.rows);

      const index = pageTypeOptions
        .toList()
        .findIndex((pageType) => pageType.get('value') === rowData.value);
      removeCSSClassFromTableCells(this.tableRef, TABLE_CELL_ACTIVE_CLASS);

      const pageType = pageTypeOptions.get(String(rowData.value));
      const processedValue = optionValueOrValue(value);

      if (rowData[property] !== processedValue) {
        const updatedPageType = pageType.set(property, processedValue).set('editing', false);
        this.updateStateRows(pageTypeOptions.set(String(rowData.value), updatedPageType));
        highlightTableRow(this.tableRef, index);
      } else {
        const updatedPageType = pageType.set('editing', false);
        this.updateStateRows(pageTypeOptions.set(String(rowData.value), updatedPageType));
      }
    },
  });

  stateRowsToSortedImmutable = (rows) => {
    return fromJS(keyBy(rows, 'value')).sortBy((row) => parseInt(row.get('value')));
  };

  updateStateRows = (rows) => {
    const saveButtonIsDisabled = this.pageTypesDidNotChanged(rows);

    this.setState({ rows: rows.toList().toJS(), saveButtonIsDisabled });
  };

  pageTypesDidNotChanged = (rows) => {
    const buildComparisionFriendlyPercentageSchemes = (items) => {
      return items
        .toList()
        .map((item) => {
          return `${item.get('value')}${item.get('defaultPercentageSchemeId')}`;
        })
        .toJS()
        .sort();
    };

    const currentDefaultPercentageSchemes = buildComparisionFriendlyPercentageSchemes(rows);
    const initialDefaultPercentageSchemes = buildComparisionFriendlyPercentageSchemes(
      this.props.pageTypeOptions,
    );

    return isEqual(currentDefaultPercentageSchemes, initialDefaultPercentageSchemes);
  };

  handleCancelClick = () => {
    this.setState({ rows: this.props.pageTypeOptions.toList().toJS(), saveButtonIsDisabled: true });
  };

  handleSaveClick = () => {
    const { rows } = this.state;

    // remap rows to form-compatible array of pageType objects
    const pageTypes = rows.map((row) => {
      return {
        id: row.value,
        title: row.label,
        defaultPercentageSchemeId: row.defaultPercentageSchemeId,
      };
    });

    this.props.onSaveClick(pageTypes);
  };

  render() {
    const { columns, rows, saveButtonIsDisabled } = this.state;
    const canUpdate = this.props.policies.getIn(['percentageSetting', 'canUpdate']);

    return (
      <div className="page-type-defaults-table-component">
        <h3>
          {translate('percentageSettings.pageTypeDefaults.title')}
          <ExplanatoryTooltipComponent
            text={translate('explanationMessages.percentageSettings.pageTypeDefaults')}
          />
        </h3>
        <Table.Provider columns={columns} className="page-type-defaults-table-component__table">
          <Table.Header />
          <Table.Body rowKey="value" rows={rows} />
        </Table.Provider>
        {canUpdate && (
          <div className="page-type-defaults-table-component__btn-group">
            <div className="page-type-defaults-table-component__btn">
              <ButtonComponent
                isWhite
                onClick={this.handleCancelClick}
                isDisabled={saveButtonIsDisabled}
              >
                {translate('percentageSettings.pageTypeDefaults.buttons.cancel')}
              </ButtonComponent>
            </div>
            <div className="page-type-defaults-table-component__btn">
              <ButtonComponent
                isGreen
                onClick={this.handleSaveClick}
                isDisabled={saveButtonIsDisabled}
              >
                {translate('percentageSettings.pageTypeDefaults.buttons.save')}
              </ButtonComponent>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PageTypeDefaultsTableComponent;
