import React from 'react';
import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';

import EoCustomFieldsPageComponent from './EoCustomFieldsPageComponent';

import Breadcrumbs from 'components/NewBreadcrumbs';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { translate } from 'common/i18n';

import { pagyShape } from 'common/prop_types_shapes';
import { eoCustomFieldsPagySelector } from 'selectors/eoCustomFieldsSelector';

const EoCustomFieldsPageContainer = ({ changeQueryParams, eoCustomFieldsPagy, queryParams }) => {
  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('eoCustomField.breadcrumbsTitle')}</Breadcrumbs.TextItem>
      </Breadcrumbs>
      <div className="page__wrapper">
        <EoCustomFieldsPageComponent
          onItemsSelectChange={handleItemsSelectChange}
          onPagesPagerClick={handlePagesPagerClick}
          eoCustomFieldsPagy={eoCustomFieldsPagy}
        />
      </div>
    </>
  );
};

EoCustomFieldsPageContainer.propTypes = {
  eoCustomFieldsPagy: pagyShape,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoCustomFieldsPagy = eoCustomFieldsPagySelector(state);

  return {
    eoCustomFieldsPagy,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(EoCustomFieldsPageContainer);
