import React from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import Link from 'components_linkio/link';

import './terms_and_policy_component.scss';

const TermsAndPolicyLinksComponent = ({ accepted, onToggleAccept }) => {
  return (
    <div className="terms-and-policy-component">
      <Checkbox
        checked={accepted}
        onChange={onToggleAccept}
      >
        {translate('authForms.byClickingIAgree')}
        <Link href={Routes.terms_path({ format: null })} target="_blank" rel="noopener noreferrer">
          {translate('authForms.termsAndConditionsLinkText')}
        </Link>
        {' & '}
        <Link href={Routes.policy_path({ format: null })} target="_blank" rel="noopener noreferrer">
          {translate('authForms.privacyPolicyLinkText')}
        </Link>
      </Checkbox>
    </div>
  );
};

TermsAndPolicyLinksComponent.propTypes = {
  accepted: PropTypes.bool,
  onToggleAccept: PropTypes.func,
};

export default TermsAndPolicyLinksComponent;
