import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { crawlerConsts } from 'common/constants';
import { marketerShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import ButtonComponent from 'components_linkio/button_component';
import RadioButton from 'components_linkio/RadioButton';

import './crawlingSettingsEdit.scss';

class CrawlerSettingsEditComponent extends Component {
  static propTypes = {
    currentMarketer: marketerShape,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { currentMarketer } = this.props;
    const { defaultCrawlingInterval } = crawlerConsts;

    this.state = {
      selectedOpt: currentMarketer.get('scheduled_crawling_interval') || defaultCrawlingInterval,
    };
  }

  handleCancel = () => this.props.onCancel();
  handleSubmit = () => this.props.onSubmit({ scheduled_crawling_interval: this.state.selectedOpt });
  handleOptsChange = (event) => this.setState({ selectedOpt: event.target.value });

  radioBtn = (valueText) => {
    const { selectedOpt } = this.state;

    return (
      <RadioButton
        className="import-popup-component__radio-btn-item"
        checked={selectedOpt === valueText}
        onChange={this.handleOptsChange}
        value={valueText}
      >
        {translate(`options.crawlingInterval.${valueText}.label`)}
      </RadioButton>
    );
  };

  render() {
    return (
      <Modal show bsSize="small">
        <Modal.Header>
          <Modal.Title>{translate('editCrawlerSettings.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="crawler-settings-edit-component__form">
            <ul>
              <li>{this.radioBtn('weekly')}</li>
              <li>{this.radioBtn('monthly')}</li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="crawler-settings-edit-component__btn-group">
            <div className="crawler-settings-edit-component__btn">
              <ButtonComponent isWhite onClick={this.handleCancel}>
                {translate('editCrawlerSettings.buttons.cancel')}
              </ButtonComponent>
            </div>
            <div className="import-popup-component__btn">
              <ButtonComponent isGreen onClick={this.handleSubmit}>
                {translate('editCrawlerSettings.buttons.submit')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CrawlerSettingsEditComponent;
