import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PagesListComponent from './PagesListComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandPageShape, brandPagesMap } from 'common/prop_types_shapes';

import {
  visibleBrandPageSelector,
  pagesByParentSelector,
  visiblePageIdByParentSelector,
} from 'selectors';

const PagesListContainer = ({
  brandPage,
  pageChildType,
  pageParentId,
  pageParentType,
  pages,
  visiblePageId,
}) => {
  return (
    <PagesListComponent
      brandPage={brandPage}
      pageChildType={pageChildType}
      pageParentId={pageParentId}
      pageParentType={pageParentType}
      pages={pages}
      visiblePageId={Number(visiblePageId)}
    />
  );
};

PagesListContainer.propTypes = {
  brandPage: brandPageShape,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  pages: brandPagesMap,
  visiblePageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function select(state, ownProps) {
  const brandPage = visibleBrandPageSelector(state, ownProps);
  const pages = pagesByParentSelector(state, ownProps);
  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);

  return {
    brandPage,
    pages,
    visiblePageId,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(PagesListContainer)));
