import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { compose } from 'lodash/fp';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentMarketerSelector } from 'selectors/railsContextSelectors';

import { marketerShape } from 'common/prop_types_shapes';

import { updateHelpcrunchCustomAttributes } from 'api/helpcrunch';

import CreditCardRequirement from './CreditCardRequirement';
import UserTypeSelectionComponent from './UserTypeSelectionComponent';

const UserTypeSelectionContainer = ({ dispatch, cmarketer, history }) => {
  const [showCreditCardRequirement, setShowCreditCardRequirement] = React.useState(false);

  const hasCard = cmarketer.get('has_card', false);

  const handleClickUserType = (userType) => () => {
    updateHelpcrunchCustomAttributes(dispatch, { profile_type: userType });

    if (hasCard) {
      history.push(Routes.new_brand_path({ format: null, simpleMode: true }));
      return;
    }

    setShowCreditCardRequirement(true);
  };

  return (
    <>
      {!showCreditCardRequirement && (
        <UserTypeSelectionComponent
          onClickUserType={handleClickUserType}
          showCreditCardRequirement={showCreditCardRequirement}
        />
      )}

      {showCreditCardRequirement && <CreditCardRequirement />}
    </>
  );
};

UserTypeSelectionContainer.propTypes = {
  cmarketer: marketerShape,
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

function select(state) {
  const cmarketer = currentMarketerSelector(state);

  return {
    cmarketer,
  };
}

export default compose(ConnectStoreHOC, connect(select))(UserTypeSelectionContainer);
