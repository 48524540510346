import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutablejs-proptypes';
import { cloneDeep, keyBy, meanBy, filter, forEach } from 'lodash';
import { Map as iMap, fromJS } from 'immutable';
import { connect } from 'react-redux';

import CompetitorAnalysisTableHeadlineComponent from '../CompetitorAnalysisTableHeadline';
import PercentagesAnalysisTableComponent from './PercentagesAnalysisTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { showSuccessMessage } from 'common/utils';
import { brandPageShape, brandPagesMap, percentageShape } from 'common/prop_types_shapes';
import { reloadCompetitorsPercentageSchemes } from 'api/percentage_scheme';
import { translate } from 'common/i18n';
import updatePageService from 'pages/Pages/services/updatePageService';

const defaultPercentageGroupsStatus = {
  branded: { isOpen: false },
  keyword: { isOpen: false },
  hybrid: { isOpen: false },
  url: { isOpen: false },
  natural: { isOpen: false },
};

const PercentagesAnalysisContainer = ({
  competitorPages,
  dispatch,
  myBrandPage,
  onAddCompetitorsClick,
  percentages,
}) => {
  const [percentageGroupsStatus, setPercentageGroupsStatus] = React.useState(
    defaultPercentageGroupsStatus,
  );

  const percentageSelectRef = React.useRef(null);

  function handleEditClick() {
    percentageSelectRef.current.focus();
  }

  const handleChangeCountTowardAverages = (page) => async (value) => {
    const countTowardCompetitorPercentages = value === 'right';

    const pageData = {
      ...page.toJS(),
      countTowardCompetitorPercentages,
    };

    const contextData = {
      dispatch,
      myBrandPage,
      pages: competitorPages,
    };

    const parentPageData = {
      id: myBrandPage.get('id'),
      dlsRedistributionRequired: true,
    };

    await updatePageService(contextData, pageData, parentPageData);

    // It's easier to call reloading from here than if-else brand page updating
    reloadCompetitorsPercentageSchemes(dispatch, myBrandPage.get('id'));

    showSuccessMessage(
      dispatch,
      translate('brandPage.messages.competitorsAveragePercentageChangedSuccessfully'),
    );
  };

  function overallOfPercentageType(percentage, percentageType) {
    let overall = 0.0;

    percentage.forEach((anchor) => {
      overall += Number(anchor.get(percentageType));
    });

    return overall;
  }

  function buildPercentagesGroupedByType(percentage) {
    const getAnchorType = (item) => item.anchorType;

    return fromJS({
      branded: {
        title: translate('brandPagePercentage.percentageGroup.branded'),
        percentage: {
          ...keyBy(
            percentage.filter((item) => item.get('anchorGroup') === 'branded').toJS(),
            getAnchorType,
          ),
        },
      },
      keyword: {
        title: translate('brandPagePercentage.percentageGroup.keyword'),
        percentage: {
          ...keyBy(
            percentage.filter((item) => item.get('anchorGroup') === 'keyword').toJS(),
            getAnchorType,
          ),
        },
      },
      hybrid: {
        title: translate('brandPagePercentage.percentageGroup.hybrid'),
        percentage: {
          ...keyBy(
            percentage.filter((item) => item.get('anchorGroup') === 'hybrid').toJS(),
            getAnchorType,
          ),
        },
      },
      url: {
        title: translate('brandPagePercentage.percentageGroup.url'),
        percentage: {
          ...keyBy(
            percentage.filter((item) => item.get('anchorGroup') === 'url').toJS(),
            getAnchorType,
          ),
        },
      },
      natural: {
        title: translate('brandPagePercentage.percentageGroup.natural'),
        percentage: {
          ...keyBy(
            percentage.filter((item) => item.get('anchorGroup') === 'natural').toJS(),
            getAnchorType,
          ),
        },
      },
    });
  }

  function buildPercentagesGroupedByTypeWithOverall(percentage, isMyBrandPage) {
    const percentagesGroupedByType = buildPercentagesGroupedByType(percentage);
    const percentagesGroupedByTypeWithOverall = percentagesGroupedByType.map((type) => {
      if (isMyBrandPage) {
        return type.merge({
          publishedOverall: overallOfPercentageType(type.get('percentage'), 'published'),
          idealOverall: overallOfPercentageType(type.get('percentage'), 'ideal'),
        });
      }
      return type.set('overall', overallOfPercentageType(type.get('percentage'), 'published'));
    });

    return percentagesGroupedByTypeWithOverall;
  }

  function buildPagesWithPercentages() {
    const buildPageWithPercentages = (page) => {
      const id = page.get('id');
      const percentage = percentages.getIn([String(id), 'percentage'], iMap());
      const isMyBrandPage = myBrandPage.get('id') === id;

      const percentagesGroupedByTypeWithOverall = isMyBrandPage
        ? buildPercentagesGroupedByTypeWithOverall(percentage, isMyBrandPage)
        : buildPercentagesGroupedByTypeWithOverall(percentage);

      return page.set('percentages', percentagesGroupedByTypeWithOverall);
    };

    const competitorsWithPercentages = competitorPages
      .valueSeq()
      .map(buildPageWithPercentages)
      .toList();
    const myBrandPageWithPercentages = buildPageWithPercentages(myBrandPage);

    return { competitorsWithPercentages, myBrandPageWithPercentages };
  }

  function buildAveragePercentages() {
    const { competitorsWithPercentages } = buildPagesWithPercentages();

    const countTowardAveragesCompetitors = competitorsWithPercentages.filter((page) =>
      page.get('countTowardCompetitorPercentages', true),
    );

    if (countTowardAveragesCompetitors.size === 0) {
      return iMap();
    }
    const percentagesPerCompetitor = countTowardAveragesCompetitors.map((competitor) =>
      competitor.get('percentages'),
    );

    const percentagesPerCompetitorForAverages = percentagesPerCompetitor
      // exclude invalid (with zero percentages) competitors from average calculation
      .filter(
        (cp) =>
          cp
            .map((cpItem) => cpItem.get('overall'))
            .valueSeq()
            .toJS()
            .reduce((acc, overall) => acc + overall) !== 0,
      );

    const buildAverage = (anchorType, averageType) =>
      meanBy(percentagesPerCompetitorForAverages.valueSeq().toArray(), (item) => {
        if (averageType === 'overall') {
          return item.getIn([anchorType, 'overall']);
        }
        return item.getIn([anchorType, 'percentage', averageType, 'published']);
      });

    // let's take percentagesPerCompetitor's first object as a prototype of averagePercentages
    // and count overall average for every anchor type
    const averagePercentagesOnlyOverall = percentagesPerCompetitor
      .first()
      .map((type, anchorType) => {
        return type.set('overall', buildAverage(anchorType, 'overall'));
      });

    // count the remaining anchor sub-types, add them to averagePercentagesOnlyOverall
    // and rename it to averagePercentages as we have all average percentages now
    const averagePercentages = averagePercentagesOnlyOverall.map((type, anchorType) => {
      const averagePercentage = type.get('percentage').map((percentage, subType) => {
        return percentage.set('published', buildAverage(anchorType, subType));
      });

      return type.set('percentage', averagePercentage);
    });

    return iMap({ percentages: averagePercentages });
  }

  const handleTogglePercentageClick = (anchorType) => () => {
    const clonedPercentageGroupsStatus = cloneDeep(percentageGroupsStatus);
    clonedPercentageGroupsStatus[anchorType].isOpen = !percentageGroupsStatus[anchorType].isOpen;

    setPercentageGroupsStatus(clonedPercentageGroupsStatus);
  };

  const handleShowHideButtonsClick = (showOrHide) => () => {
    const clonedPercentageGroupsStatus = cloneDeep(percentageGroupsStatus);
    forEach(clonedPercentageGroupsStatus, (item) => {
      item.isOpen = showOrHide;
    });

    setPercentageGroupsStatus(clonedPercentageGroupsStatus);
  };

  function showHideButtonsOptions() {
    const showIsDisabled =
      filter(percentageGroupsStatus, (type) => type.isOpen === false).length === 0;
    const hideIsDisabled =
      filter(percentageGroupsStatus, (type) => type.isOpen === true).length === 0;

    return {
      showIsDisabled,
      hideIsDisabled,
      onClick: handleShowHideButtonsClick,
    };
  }

  const { competitorsWithPercentages, myBrandPageWithPercentages } = buildPagesWithPercentages();

  return (
    <>
      <CompetitorAnalysisTableHeadlineComponent
        isPercentagesTable
        showHideButtonsOptions={showHideButtonsOptions()}
        title={translate('percentagesAnalysis.table.title')}
      />
      <PercentagesAnalysisTableComponent
        averages={buildAveragePercentages()}
        competitors={competitorsWithPercentages}
        myBrandPage={myBrandPageWithPercentages}
        onAddCompetitorsClick={onAddCompetitorsClick}
        onChangeCountTowardAverages={handleChangeCountTowardAverages}
        onEditClick={handleEditClick}
        onTogglePercentageClick={handleTogglePercentageClick}
        percentageGroupsStatus={percentageGroupsStatus}
        percentageSelectRef={percentageSelectRef}
      />
    </>
  );
};

PercentagesAnalysisContainer.propTypes = {
  competitorPages: brandPagesMap,
  dispatch: PropTypes.func,
  myBrandPage: brandPageShape,
  onAddCompetitorsClick: PropTypes.func,
  percentages: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      percentage: ImmutablePropTypes.listOf(percentageShape).isRequired,
      uncategorizedAnchorsCount: PropTypes.number,
    }), //value
    PropTypes.string.isRequired, //key
  ),
};

export default ConnectStoreHOC(connect()(PercentagesAnalysisContainer));
