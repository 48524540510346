export default function convertTimeFrom24To12Format(time24) {
  if (!time24) {
    return '';
  }

  const [sHours, sMinutes] = time24.match(/([0-9]{1,2}):([0-9]{1,2})/).slice(1);
  const period = +sHours < 12 ? 'AM' : 'PM';
  const hours = +sHours % 12 || 12;
  const minutes = sMinutes.length === 1 ? `0${sMinutes}` : sMinutes;

  return `${hours}:${minutes} ${period}`;
}
