import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sortBy } from 'lodash';

import BreadcrumbsBrandsSelectComponent from './BreadcrumbsBrandsSelectComponent';
import { historyPusherService } from './historyPusherService';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchSimpleBrands } from 'api/simpleBrand';

import { breadcrumbsBrandsSelectToggle } from 'actions/ui_actions';

import { currentSimpleBrandSelector, simpleBrandsSelector } from 'selectors/simpleBrandSelectors';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';

import { simpleBrandsMap, simpleBrandShape } from 'common/propTypesShapes/simpleBrands';

const BreadcrumbsBrandsSelectContainer = ({
  canUseMetrics,
  currentSimpleBrand,
  dispatch,
  history,
  simpleBrands,
  targetAppModule,
}) => {
  React.useEffect(() => {
    fetchSimpleBrands(dispatch);
  }, []);

  function handleCloseMenu() {
    dispatch(breadcrumbsBrandsSelectToggle());
  }

  function handleSelectChange(option) {
    const brandId = option && option.value;

    if (!brandId) {
      return;
    }

    handleCloseMenu();

    historyPusherService(history, brandId, targetAppModule, canUseMetrics);
  }

  const brandWebsite = currentSimpleBrand.get('website');
  const brandsOptions = brandsAsOptions(simpleBrands);

  return (
    <BreadcrumbsBrandsSelectComponent
      brandWebsite={brandWebsite}
      brandsOptions={brandsOptions}
      onCloseMenu={handleCloseMenu}
      onSelectChange={handleSelectChange}
    />
  );
};

BreadcrumbsBrandsSelectContainer.propTypes = {
  canUseMetrics: PropTypes.bool,
  currentSimpleBrand: simpleBrandShape,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  simpleBrands: simpleBrandsMap,
  targetAppModule: PropTypes.oneOf([
    'atp',
    'backlinks',
    'backlinks_lost_links',
    'backlinks_new_links',
    'backlinks_re_discovered_links',
    'changesBacklink',
    'backlinksSetup',
    'brandDashboard',
    'bulkPagesSetup',
    'rankTracker',
    'rankTrackerKeywords',
    'rankTrackerSetup',
  ]),
};

function brandsAsOptions(brands) {
  const processedBrands = brands
    .filter((brand) => !brand.isEmpty())
    .map((brand) => {
      const regex = /^(?:https?:\/\/)?(?:www\.)?/i; // Remove http(s) and www parts from the brand's website
      const processedBrandWebsite = brand.get('website').replace(regex, '');

      return {
        label: processedBrandWebsite,
        value: brand.get('id'),
      };
    })
    .valueSeq()
    .toArray();

  const sortedBrands = sortBy(processedBrands, 'label');

  return sortedBrands;
}

const selector = (state, ownProps) => {
  const subscriptionPolicies = subscriptionPoliciesSelector(state);

  return {
    canUseMetrics: subscriptionPolicies.getIn(['metrics', 'canUse']),
    simpleBrands: simpleBrandsSelector(state, ownProps),
    currentSimpleBrand: currentSimpleBrandSelector(state, ownProps),
  };
};

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(selector),
)(BreadcrumbsBrandsSelectContainer);
