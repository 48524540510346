import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List as iList, Map as iMap } from 'immutable';
import { trim, isEmpty, compact } from 'lodash';

import CategorizeDlsPopupComponent from './CategorizeDlsPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { startMouseFlowPageView, trackHelpcrunchEvent } from 'common/utils';

import { fetchCurrentSubscription } from 'api/subscription';

import { closeCategorizeDlsPopup } from 'actions/popup_actions';

import { updatePage, fetchPagePercentage } from 'api/brand_page';

import { categorizeDl } from 'api/destination_links';

import { atpPagyByBrandPageIdSelector, visiblePageIdByParentSelector } from 'selectors';

import {
  currentSubscriptionSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import {
  brandPageShape,
  destinationLinksMap,
  pagyShape,
  subscriptionPoliciesShape,
  subscriptionShape,
} from 'common/prop_types_shapes';

import clickUpgradeSubscriptionService from 'pages/Pages/services/clickUpgradeSubscriptionService';

import { fetchAnchorSamples } from 'api/anchorSamples';

function getExistingKeywordsWithEditedValues(existingKeywords, newAndEditedKeywords) {
  const existingKeywordsWithEditedValues = existingKeywords.map((existingKeyword) => {
    const editedExistingKeyword = newAndEditedKeywords.find(({ id }) => id === existingKeyword.id);

    if (!editedExistingKeyword) {
      return existingKeyword;
    }

    const { label: newKeywordValue } = editedExistingKeyword;

    return { ...existingKeyword, label: newKeywordValue };
  });

  return existingKeywordsWithEditedValues;
}

class CategorizeDlsPopupContainer extends React.PureComponent {
  static propTypes = {
    currentSubscription: subscriptionShape,
    destinationLinks: destinationLinksMap,
    dispatch: PropTypes.func,
    page: brandPageShape,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pageParentType: PropTypes.oneOf(['brand_page', 'brand']),
    pagy: pagyShape,
    subscriptionPolicies: subscriptionPoliciesShape,
  };

  constructor(props) {
    super(props);

    const { page } = props;

    // TODO: move state to CategorizeDlsPopupComponent since most of state
    // changes are simple UI changes, not business logic
    this.state = {
      alternativeLinks: page.get('alternativeLinks', iList()).toJS(),
      brandIsFocused: false,
      brandIsHovered: false,
      brandName: page.get('brandName', ''),
      brandOtherNames: [...page.get('brandOtherNames', iList()).toJS()].join('\n'),
      isAnyChanges: false,
      isUpdating: false,
      keywords: [],
      metaKeywords: page.get('metaKeywords', iList()).toJS(),
      saveAndCategorize: true,
      selectedCheckbox: 'all',
      title: page.get('title', iList()).toJS(),
    };
  }

  componentDidMount() {
    fetchCurrentSubscription(this.props.dispatch);
    startMouseFlowPageView(document.location.pathname);
  }

  handleChangeState = (newState) => {
    this.setState(newState);
  };

  handleClickUpgradeSubscription = (event) => {
    const { currentSubscription, dispatch } = this.props;
    clickUpgradeSubscriptionService({ currentSubscription, dispatch }, event);
  };

  handleCancel = () => this.props.dispatch(closeCategorizeDlsPopup());

  handleCategorize = async () => {
    const { saveAndCategorize, selectedCheckbox } = this.state;

    this.setState({ isUpdating: true });

    const resp = await this.saveFormData();
    if (!isEmpty(resp)) {
      this.setState({ isUpdating: false });
      return void 0;
    }

    if (!saveAndCategorize) {
      return this.handleCancel();
    }

    const { destinationLinks, dispatch, pageId, pagy } = this.props;

    let dlsToCategorize = iMap();
    const brandPageDL = destinationLinks.filter((dl) => dl.get('brandPageId') === Number(pageId));

    switch (selectedCheckbox) {
      case 'all': {
        dlsToCategorize = brandPageDL;
        break;
      }
      case 'uncategorized': {
        dlsToCategorize = brandPageDL.filter((dl) => !dl.get('anchorType'));
        break;
      }
      case 'selected': {
        dlsToCategorize = brandPageDL.filter((dl) => dl.get('selected'));
        break;
      }
    }

    const ids = dlsToCategorize.map((dl) => dl.get('id')).toArray();
    const categorizationParams = { mode: selectedCheckbox, ids, page: pagy.get('page', 1) };
    await categorizeDl(dispatch, pageId, categorizationParams);

    await this.handleFinishCategorize(ids);

    fetchAnchorSamples(dispatch, pageId);

    this.setState({ isAnyChanges: false });
    this.setState({ isUpdating: false });
  };

  saveFormData = async () => {
    const { dispatch, page, pageId } = this.props;

    const { alternativeLinks, brandName, brandOtherNames, keywords, title } = this.state;

    const pageData = {
      ...page.toJS(),
      alternativeLinks: this.trimItems(alternativeLinks),
      brandName: (brandName || '').trim(),
      brandOtherNames: (brandOtherNames || '').trim().split(/\r?\n/),
      keywords,
      title: this.trimItems(title),
    };

    const newPage = {};

    const result = await updatePage(dispatch, pageId, pageData);
    newPage.errors = (result || {}).errors;

    return { ...(newPage || {}).errors };
  };

  handleFinishCategorize = async () => {
    const { dispatch, pageChildType, pageId } = this.props;

    const resp = await fetchPagePercentage(dispatch, pageId);

    if ((resp || {}).type === 'error') {
      return;
    }

    trackHelpcrunchEvent(`categorizationPerfomed.${pageChildType}`);
  };

  handleChangeKeywords = (keywords, hasDeletedKeyword) => {
    const { isAnyChanges: currentAnyChangesState } = this.state;

    const newAnyChangesValue = hasDeletedKeyword ? currentAnyChangesState : true;

    this.setState({ keywords, isAnyChanges: newAnyChangesValue });
  };

  trimItems = (items) => compact(items.map((item) => trim(item)));

  render() {
    const { page, pageId, pageParentId, pageParentType, subscriptionPolicies } = this.props;

    const {
      alternativeLinks,
      brandIsFocused,
      brandIsHovered,
      brandName,
      brandOtherNames,
      isAnyChanges,
      isUpdating,
      keywords: newAndEditedKeywords,
      metaKeywords,
      saveAndCategorize,
      selectedCheckbox,
      title,
    } = this.state;

    const pageErrors = page.get('errors', iMap()).valueSeq().toArray().join(', ');

    const canUseCategorizer = subscriptionPolicies.getIn(['categorizer', 'canUse']);
    const keywordsFromPage = page.get('keywords', iList()).toJS();

    const keywords = getExistingKeywordsWithEditedValues(keywordsFromPage, newAndEditedKeywords);

    return (
      <CategorizeDlsPopupComponent
        alternativeLinks={alternativeLinks}
        brandIsFocused={brandIsFocused}
        brandIsHovered={brandIsHovered}
        brandName={brandName}
        brandOtherNames={brandOtherNames}
        canUseCategorizer={canUseCategorizer}
        isAnyChanges={isAnyChanges}
        isUpdating={isUpdating}
        keywords={keywords}
        metaKeywords={metaKeywords}
        onCancel={this.handleCancel}
        onCategorize={this.handleCategorize}
        onChangeKeywords={this.handleChangeKeywords}
        onChangeState={this.handleChangeState}
        onClickUpgradeSubscription={this.handleClickUpgradeSubscription}
        pageErrors={pageErrors}
        pageId={pageId}
        pageParentId={pageParentId}
        pageParentType={pageParentType}
        saveAndCategorize={saveAndCategorize}
        selectedCheckbox={selectedCheckbox}
        title={title}
      />
    );
  }
}

function select(state, ownProps) {
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const destinationLinks = state.get('destinationLinks', iMap());
  const pagy = atpPagyByBrandPageIdSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);
  // If we have a page id passed to the component (a.k.a hidden page chosen by user), we use it,
  // otherwise we use the page, that was opened by user (visiblePage).
  const pageId = ownProps.pageId || visiblePageId;

  const brandPages = state.get('brandPages', iMap());
  const page = brandPages.get(String(pageId), iMap());

  return {
    currentSubscription,
    destinationLinks,
    page,
    pageId,
    pagy,
    subscriptionPolicies,
    visiblePageId,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(CategorizeDlsPopupContainer)));
