import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EoCampaignTabsComponent from './EoCampaignTabsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { activeEoCampaignTabString } from 'common/propTypesShapes/eoCampaigns';
import { activeEoCampaignTabSelector } from 'selectors/eoCampaignsSelector';
import { currentIdSelector } from 'selectors';

const EoCampaignTabsContainer = ({
  activeEoCampaignTab,
  eoCampaignId,
  history,
  changeQueryParams,
}) => {
  const handleTabClick = (tab) => () => {
    if (tab === activeEoCampaignTab) {
      return;
    }

    changeQueryParams({});

    let path = '';

    switch (tab) {
      case 'overview':
        path = Routes.overview_eo_campaign_path(eoCampaignId, { format: null });
        break;
      case 'prospects':
        path = Routes.prospects_eo_campaign_path(eoCampaignId, { format: null });
        break;
      case 'eo_progress':
        path = Routes.eo_progressions_path(eoCampaignId, { format: null });
        break;
      case 'eo_sequences':
        path = Routes.eo_campaign_steps_path(eoCampaignId, { format: null });
        break;
      case 'eo_inboxes':
        path = Routes.eo_campaigns_eo_inboxes_path(eoCampaignId, { format: null });
        break;
      case 'eo_scheduler':
        path = Routes.eo_schedulers_path(eoCampaignId, { format: null });
        break;
      case 'eo_messages':
        path = Routes.eo_messages_path(eoCampaignId, { format: null });
        break;
      case 'options':
        path = Routes.options_eo_campaign_path(eoCampaignId, { format: null });
        break;
      case 'eo_campaign_preview':
        path = Routes.eo_campaign_preview_index_path(eoCampaignId, { format: null });
        break;
    }

    history.push(path);
  };

  return (
    <EoCampaignTabsComponent
      activeEoCampaignTab={activeEoCampaignTab}
      onTabClick={handleTabClick}
    />
  );
};

EoCampaignTabsContainer.propTypes = {
  activeEoCampaignTab: activeEoCampaignTabString,
  eoCampaignId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const activeEoCampaignTab = activeEoCampaignTabSelector(state, ownProps);
  const eoCampaignId = currentIdSelector(state, ownProps);

  return {
    activeEoCampaignTab,
    eoCampaignId,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(EoCampaignTabsContainer)));
