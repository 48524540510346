import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import cx from 'classnames';

import LabelComponent from 'components_linkio/label_component';
import MessageComponent from 'components_linkio/message_component';

import './quillTextEditor.scss';

// By default Quill uses <p> tag as a Block-wrapper, which leads to unwanted additional
// space between the lines. Here we change default Block-wrapper tag to <div> to avoid
// extra space between the lines, it's a good solution for email sending purposes.
// More info: https://github.com/quilljs/quill/issues/1074#issuecomment-312594748,
// https://github.com/quilljs/quill/issues/861
const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

const defaultQuillToolbarModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }, { direction: 'rtl' }],
    ['link'], // add 'video' and 'image' when it's needed
    ['clean'],
  ],
};

const QuillTextEditorComponent = ({
  className,
  isMandatory,
  label,
  message = {},
  modules,
  reactQuillRef,
  ...quillProps
}) => {
  const { type: messageType, text: messageText } = message;

  const quillClassNames = cx('quill-text-editor', className, {
    'quill-text-editor_with-error': messageType === 'error' && messageText,
  });

  return (
    <div className={quillClassNames}>
      <LabelComponent isMandatory={isMandatory} text={label} />
      <ReactQuill
        ref={(el) => {
          reactQuillRef && (reactQuillRef.current = el);
        }}
        modules={modules || defaultQuillToolbarModules}
        {...quillProps}
      />
      {messageText && <MessageComponent message={{ type: messageType, text: messageText }} />}
    </div>
  );
};

QuillTextEditorComponent.propTypes = {
  className: PropTypes.string,
  isMandatory: PropTypes.bool,
  label: PropTypes.string,
};

export default QuillTextEditorComponent;
