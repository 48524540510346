import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { translate } from '../../common/i18n';

import { Modal } from '../../components_linkio/modal_component';
import ButtonComponent from '../../components_linkio/button_component';

import './confirmation_dialogue.scss';

const popup = document.createElement("div");

class ConfirmWindow extends React.Component {
  static defaultProps = {
    cancelLabel: translate('confirmations.default.buttons.cancel'),
    confirmLabel: translate('confirmations.default.buttons.confirm'),
    hasCancelBtn: true,
    hasConfirmBtn: true,
    onCancelInParent: () => {},
    onConfirmInParent: () => {},
    title: translate('confirmations.default.title'),
  }

  static propTypes = {
    body: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string,
    confirmLabel: PropTypes.string,
    hasCancelBtn: PropTypes.bool,
    hasConfirmBtn: PropTypes.bool,
    onCancelInParent: PropTypes.func,
    onConfirmInParent: PropTypes.func,
    title: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.state = {
      isVisible: true,
    };
  }

  handleCancel = () => {
    this.removeDialogue();
    this.props.onCancelInParent();
  }

  handleConfirm = () => {
    this.removeDialogue();
    this.props.onConfirmInParent();
  }

  removeDialogue() {
    this.setState({ isVisible: false });

    setTimeout( // to avoid "React DOM tree root should always have a node reference." error
      () => {
        ReactDOM.unmountComponentAtNode(popup);

        if (document.body.contains(popup)) { // helps to handle redirects
          document.body.removeChild(popup);
        }
      },
      500 // to keep 'on hide' animation
    );
  }

  render() {
    const {
      body,
      cancelLabel,
      confirmLabel,
      hasCancelBtn,
      hasConfirmBtn,
      title,
    } = this.props;

    return (
      <Modal
        show={this.state.isVisible}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{body}</Modal.Body>

        <Modal.Footer>
          <div className="confirm-window__btn-group">
            { hasCancelBtn &&
              <div className="confirm-window__btn">
                <ButtonComponent isWhite onClick={this.handleCancel}>{cancelLabel}</ButtonComponent>
              </div>
            }
            { hasConfirmBtn &&
              <div className="confirm-window__btn">
                <ButtonComponent isBlue onClick={this.handleConfirm} bsStyle="primary">
                  {confirmLabel}
                </ButtonComponent>
              </div>
            }
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

// Example of usage:
// handleDeleteEntry = (entry) => () => {
//   isOptionalFunction = () => {
//     // do something on 'Confirm' (remove entry, for example)
//   }
//
//   isOptionalFunction = () => {
//     // do something (or nothing) on 'Cancel'
//   }
//
//   confirmationDialogue({
//     body: "Is Required",
//     cancelLabel: "Optional",
//     confirmLabel: "Optional",
//     hasCancelBtn: false,
//     onCancel: isOptionalFunction(),
//     onConfirm: isOptionalFunction(),
//     title: "Optional",
//   });
// }
const confirmationDialogue = ({
  body,
  cancelLabel,
  confirmLabel,
  hasCancelBtn,
  hasConfirmBtn,
  onCancel,
  onConfirm,
  title,
}) => {
  const wrapper = document.body.appendChild(popup);
  const confirmWindow = (
    <ConfirmWindow
      body={body}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      hasCancelBtn={hasCancelBtn}
      hasConfirmBtn={hasConfirmBtn}
      onCancelInParent={onCancel}
      onConfirmInParent={onConfirm}
      title={title}
    />
  );
  ReactDOM.render(
    confirmWindow,
    wrapper
  );
};

export default confirmationDialogue;
