import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RoundSpinnerIcon from 'common/icons/RoundSpinnerIcon';

import './tableLoadingWrapper.scss';

const TableLoadingWrapperComponent = ({ children, className, isLoading, loadingMessage }) => {
  return (
    <>
      {isLoading && (
        <div className={cx('table-loading-wrapper__status', className)}>
          <RoundSpinnerIcon />
          <span className="table-loading-wrapper__message">{loadingMessage}</span>
        </div>
      )}
      {children}
    </>
  );
};

TableLoadingWrapperComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.string,
};

export default TableLoadingWrapperComponent;
