import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { fromPairs, toPairs } from 'lodash';

import DataFilterComponent from './DataFilterComponent';
import { getQueryDataFilterKeys, getQueryFiltersWithoutDataFilterKeys } from './utils';

import withErrorBoundary from 'decorators/error-boundary';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

function formatOptionsValuesToString(options) {
  if (!options.length || typeof options[0].value === 'string') {
    return options;
  }

  const formattedOptions = options.map((option) => {
    const { label, value } = option;
    const valueAsString = value.toString();

    return { label, value: valueAsString };
  });

  return formattedOptions;
}

function formatFiltersOptionsValuesToString(notFormattedFiltersSpec) {
  const notFormattedFilters = toPairs(notFormattedFiltersSpec);
  const formattedFilters = notFormattedFilters.map((notFormattedFilter) => {
    const [filterTitle, filterObject] = notFormattedFilter;
    const { options } = filterObject;

    if (!options) {
      return notFormattedFilter;
    }

    const formattedOptions = formatOptionsValuesToString(options);

    return [filterTitle, { ...filterObject, options: formattedOptions }];
  });

  return fromPairs(formattedFilters);
}

const DataFilterContainer = ({
  changeQueryParams,
  filtersSpec: notFormattedFiltersSpec,
  queryParams,
  onFilterQueryChangeCb,
  ...props
}) => {
  const filtersSpec = formatFiltersOptionsValuesToString(notFormattedFiltersSpec);

  const onFilterQueryChange = (newValue) => {
    changeQueryParams({
      ...queryParams,
      filters: {
        ...getQueryFiltersWithoutDataFilterKeys(filtersSpec, queryParams.filters),
        ...newValue,
      },
    });

    if (onFilterQueryChangeCb) {
      onFilterQueryChangeCb();
    }
  };

  const filters = queryParams.filters || {};

  return (
    <DataFilterComponent
      onFilterQueryChangeCb={onFilterQueryChange}
      filters={getQueryDataFilterKeys(filtersSpec, filters)}
      filtersSpec={filtersSpec}
      {...props}
    />
  );
};

DataFilterContainer.propTypes = {
  ...withQueryParamsPropTypes,
  onFilterQueryChangeCb: PropTypes.func,
};

export default compose(withErrorBoundary, withQueryParams)(DataFilterContainer);
