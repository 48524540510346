import { fromJS, List as iList } from 'immutable';
import { camelizeObjectDeep, mergeArrayToList } from './utils';

import {
  ADD_EO_BLACKLISTED_DOMAINS__SUCCESS,
  DELETE_EO_BLACKLISTED_DOMAINS__SUCCESS,
  FETCH_EO_BLACKLISTED_DOMAINS__SUCCESS,
} from 'actions/eoBlacklistedDomainActions';

const initialState = iList();

export default function eoBlacklistedDomainsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_EO_BLACKLISTED_DOMAINS__SUCCESS: {
      const { eoBlacklistedDomains } = action;

      state = mergeArrayToList(state, eoBlacklistedDomains, camelizeObjectDeep);

      break;
    }
    case FETCH_EO_BLACKLISTED_DOMAINS__SUCCESS: {
      const { eoBlacklistedDomains } = action;

      state = fromJS(eoBlacklistedDomains.map(camelizeObjectDeep));

      break;
    }
    case DELETE_EO_BLACKLISTED_DOMAINS__SUCCESS: {
      const { ids } = action;

      state = state.filter((eoBlacklistedDomain) => !ids.includes(eoBlacklistedDomain.get('id')));

      break;
    }
  }

  return state;
}
