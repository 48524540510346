import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import CategorizationIcon from 'common/icons/categorization';

const CategorizeDlsButtonComponent = ({ onCategorizeDlsClick }) => {
  const tooltip = {
    text: translate('explanationMessages.atpBulkAction.categorize'),
    color: 'black',
  };

  return (
    <div className="bulk-atp-actions-component__atp-button">
      <ButtonComponent isWhite onClick={onCategorizeDlsClick} tooltip={tooltip}>
        <span className="bulk-atp-actions-component__icon-wrapper">
          <CategorizationIcon />
        </span>
        {translate('destinationLink.buttons.categorize')}
      </ButtonComponent>
    </div>
  );
};

CategorizeDlsButtonComponent.propTypes = {
  onCategorizeDlsClick: PropTypes.func.isRequired,
};

export default CategorizeDlsButtonComponent;
