import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import ResumeEoProgressionsPopupComponent from './ResumeEoProgressionsPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { resumeEoProgressions } from 'api/eoProgression';
import { selectedIdsList } from 'common/prop_types_shapes';
import { currentIdSelector, uiSelector } from 'selectors';

const ResumeEoProgressionsPopupContainer = ({ eoCampaignId, dispatch, onClose, selectedIds }) => {
  async function handleResume(resumeAtDate) {
    const errors = await resumeEoProgressions(dispatch, eoCampaignId, selectedIds.toJS(), resumeAtDate);
    return errors;
  }

  return (
    <ResumeEoProgressionsPopupComponent
      onCancel={onClose}
      onResume={handleResume}
    />
  );
};

ResumeEoProgressionsPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  onClose: PropTypes.func,
  selectedIds: selectedIdsList,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    eoCampaignId,
    selectedIds,
  };
}

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(ResumeEoProgressionsPopupContainer);
