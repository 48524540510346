import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import DisavowPopupComponent from './DisavowPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { showErrorMessageFromTranslation, trackHelpcrunchEvent } from 'common/utils';

import { applyDisavowFile, deleteDisavowFile } from 'api/brand';

import { translate } from 'common/i18n';
import { brandMetricsShape } from 'common/propTypesShapes/metrics';

import confirmationDialogue from 'components/confirmation_dialogue';

class DisavowPopupContainer extends Component {
  static propTypes = {
    brand: brandMetricsShape,
    dispatch: PropTypes.func,
    onClose: PropTypes.func,
  };

  handleSubmit = (file) => async () => {
    const { dispatch, brand, onClose } = this.props;
    const fileIsChosen = file instanceof File;

    if (!fileIsChosen) {
      showErrorMessageFromTranslation(dispatch, 'disavowPopup.errors.fileIsNotChosen');
      return;
    }

    const result = await applyDisavowFile(dispatch, brand.id, file);

    trackHelpcrunchEvent('add.disavowFile');

    const { type, errors } = result;

    if (type === 'error' || !isEmpty(errors)) {
      return;
    }

    onClose();
  };

  handleDisavowDelete = async () => {
    const { dispatch, brand, onClose } = this.props;

    const result = await deleteDisavowFile(dispatch, brand.id);

    const { type, errors } = result;

    if (type === 'error' || !isEmpty(errors)) {
      return;
    }

    onClose();
  };

  handleDelete = () => {
    confirmationDialogue({
      body: translate('confirmations.deleteDisavow.body'),
      onConfirm: this.handleDisavowDelete,
    });
  };

  render() {
    const { brand, onClose } = this.props;

    return (
      <DisavowPopupComponent
        brand={brand}
        onClose={onClose}
        onDelete={this.handleDelete}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default ConnectStoreHOC(connect()(DisavowPopupContainer));
