export default {
  trustSuggestionsPopup: {
    buttons: {
      cancel: "Cancel",
    },
    description: {
      about: "How accurate are these suggestions?",
      intro: "The more data Linkio has to work with, the more accurate the suggestions will be.",
      section1: {
        title: "Did you import existing backlinks?",
        paragraph1: `If you have an Ahrefs account, your backlinks are imported automatically.
                     If you don't, be sure to least import your backlinks manually using the import
                     button on the Backlinks tab.`,
        paragraph2: `Linkio crawls and index checks each link to figure out which anchor texts
                     (only 1 per referring domain) should count to your percentages.
                     If you don't give us backlinks, we have no data to check!`,
      },
      section2: {
        title: "Did you fill out the brand name and keyword fields properly?",
        paragraph1: `Our anchor text categorizer algorithm does the heavy lifting for figuring out
                     which your anchor is branded, keyword, natural etc.
                     Make sure the fields in the Categorize button in the Backlinks tab have been
                     filled out properly. Maybe you forgot to include a variation of
                     your brand name or an important keyword.`,
        paragraph2: `This can throw off the percentages - and that's not good because
                     it’s the basis on which we make suggestions.`,
      },
      section3: {
        title: "Did you pick the right Ideal Target Percentages?",
        paragraph1: `By default, Linkio gives you percentages to shoot for based on your page type.
                     We came up with these percentages by studying top performing sites and
                     their anchor text ratios.`,
        paragraph2: `However, for the very best suggestions, you should use Linkio to calculate
                     ideal percentages based on the top ranking competitors for the keyword
                     you’re going after. You can do that in the Competitors tab.`,
        paragraph3: `Interestingly enough, Google tells you exactly what anchor text percentages
                     it wants for a particular keyword, and often times, the top results have
                     a similar breakdown.`,
        paragraph4: `Calculating this manually has been a pain in years past, but Linkio does it
                     all for you now and this gives you the best shot at making those links you're
                     building worthwhile!`,
      },
      whyIsThisImportant: "Why is this important?",
    },
    openLink: {
      text: 'How accurate are these suggestions?',
    },
    title: "Suggestions explanation",
  },
};
