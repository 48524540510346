import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';
import { Map as iMap } from 'immutable';

import ImpersonatesComponent from './ImpersonatesComponent';

import { translate } from 'common/i18n';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { employeesList, historyShape } from 'common/prop_types_shapes';
import { fetchImpersonates, impersonateByUserId } from 'api/impersonates';
import { impersonatesSelector } from 'selectors/impersonatesSelector';

import Breadcrumbs from 'components/NewBreadcrumbs';

const ImpersonatesContainer = ({
  changeQueryParams,
  dispatch,
  history,
  impersonates,
  impersonatesPagy,
  queryParams,
}) => {
  const { pageNumber = 1, pagyItemsCount = 50, search = '' } = queryParams;

  React.useEffect(() => {
    function fetchImpersonatesWithParams() {
      const fetchingParams = {
        pageNumber,
        pagyItemsCount,
        search,
      };

      fetchImpersonates(dispatch, fetchingParams);
    }

    fetchImpersonatesWithParams();
  }, [search, pageNumber, pagyItemsCount, dispatch]);

  const handleSignInAsClick = (userId) => async () => {
    await impersonateByUserId(dispatch, userId);

    history.push(Routes.root_path({ format: null }));
    const forceGet = false;
    window.location.reload(forceGet);
  };

  function handleSubmitSearch(value) {
    const newQueryParams = {
      ...queryParams,
      search: value,
      pageNumber: 1,
    };

    changeQueryParams(newQueryParams);
  }

  const handlePagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('impersonates.breadcrumbsTitle')}</Breadcrumbs.TextItem>
      </Breadcrumbs>
      <div className="page__wrapper">
        <ImpersonatesComponent
          impersonates={impersonates.toJS()}
          impersonatesPagy={impersonatesPagy}
          onItemsSelectChange={handleItemsSelectChange}
          onPagerClick={handlePagerClick}
          onSignInAsClick={handleSignInAsClick}
          onSubmitSearch={handleSubmitSearch}
        />
      </div>
    </>
  );
};

ImpersonatesContainer.propTypes = {
  ...withQueryParamsPropTypes,
  dispatch: PropTypes.func,
  history: historyShape,
  impersonates: employeesList,
};

function select(state) {
  const impersonates = impersonatesSelector(state);
  const impersonatesPagy = state.getIn(['pagies', 'impersonatesPagy', 'pagy'], iMap());

  return {
    impersonates,
    impersonatesPagy,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(ImpersonatesContainer);
