import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams from 'decorators/withQueryParams';
import { currentIdSelector } from 'selectors';

import { translate } from 'common/i18n';

import Link from 'components_linkio/link';

import './moduleSetupSelection.scss';

const ModuleSetupSelectionComponent = ({ brandId }) => (
  <div className="module-setup-selection">
    <h1>{translate('uiComponents.moduleSetupSelection.title')}</h1>

    <div className="module-setup-selection__items-wrapper">
      <Link
        to={Routes.eo_campaigns_path({ format: null })}
        className="module-setup-selection__item"
      >
        <h2>{translate('uiComponents.moduleSetupSelection.eoCampaigns')}</h2>
      </Link>

      <Link
        to={Routes.edit_rank_tracker_settings_path(brandId, {
          format: null,
          fromModuleSetupSelection: true,
        })}
        className="module-setup-selection__item"
      >
        <h2>{translate('uiComponents.moduleSetupSelection.atp')}</h2>
      </Link>
    </div>
  </div>
);

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);

  return {
    brandId,
  };
}

ModuleSetupSelectionComponent.propTypes = {
  brandId: PropTypes.string,
};

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(ModuleSetupSelectionComponent);
