import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map as iMap } from 'immutable';

import AtpTableTabsComponent from './AtpTableTabsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { activeAtpTableTabString, brandPageMap } from 'common/prop_types_shapes';

import { activeAtpTableTabSelector, currentIdSelector, visibleBrandPageSelector } from 'selectors';

import { fetchSinglePage, reDistributeDls } from 'api/brand_page';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';
import { trackHelpcrunchEvent } from 'common/utils';
import defaultMetricsParams from 'utils/routeUtils';

const AtpTableTabsContainer = ({
  activeAtpTableTab,
  brandId,
  brandPage,
  canUseMetrics,
  dispatch,
  history,
  queryParams,
}) => {
  const handleChangeTab = (tab) => async () => {
    if (tab === activeAtpTableTab) {
      return;
    }

    const brandPageId = brandPage.get('id');
    const params =
      tab === 'backlinks'
        ? {
          ...queryParams,
          ...defaultMetricsParams(canUseMetrics),
        }
        : { pagesFilter: queryParams.pagesFilter, format: null }; // we should reset opened page's query params on tab change

    const path = Routes.brand_page_tab_path(brandId, brandPageId, tab, params);

    history.push(path);

    trackHelpcrunchEvent(`click.tab.${tab}`);

    const isCompetitorsRelatedTab = tab.includes('competitor');

    if (isCompetitorsRelatedTab) {
      return;
    }

    if (tab === 'suggestions') {
      await reDistributeDls(dispatch, brandPageId);
      return;
    }

    fetchSinglePage(dispatch, brandPageId);
  };

  const destinationLinksCounts = brandPage.get('destinationLinksCounts', iMap()).toJS();

  return (
    <AtpTableTabsComponent
      activeAtpTableTab={activeAtpTableTab}
      destinationLinksCounts={destinationLinksCounts}
      onChangeTab={handleChangeTab}
    />
  );
};

AtpTableTabsContainer.propTypes = {
  activeAtpTableTab: activeAtpTableTabString,
  brandId: PropTypes.string,
  brandPage: brandPageMap,
  canUseMetrics: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const activeAtpTableTab = activeAtpTableTabSelector(state, ownProps);
  const brandId = currentIdSelector(state, ownProps);
  const brandPage = visibleBrandPageSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);
  const canUseMetrics = subscriptionPolicies.getIn(['metrics', 'canUse']);

  return {
    activeAtpTableTab,
    brandId,
    brandPage,
    canUseMetrics,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(AtpTableTabsContainer)));
