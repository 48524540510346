const FETCH_INTERSECT_REPORT__FAILURE = 'FETCH_INTERSECT_REPORT__FAILURE';
const FETCH_INTERSECT_REPORT__START = 'FETCH_INTERSECT_REPORT__START';
const FETCH_INTERSECT_REPORT__SUCCESS = 'FETCH_INTERSECT_REPORT__SUCCESS';


function fetchIntersectReportFailure(errors) {
  return { type: FETCH_INTERSECT_REPORT__FAILURE, errors };
}

function fetchIntersectReportStart() {
  return { type: FETCH_INTERSECT_REPORT__START };
}

function fetchIntersectReportSuccess(brandPageId, report) {
  return { type: FETCH_INTERSECT_REPORT__SUCCESS, brandPageId, report };
}


export {
  FETCH_INTERSECT_REPORT__FAILURE,
  FETCH_INTERSECT_REPORT__START,
  FETCH_INTERSECT_REPORT__SUCCESS,
  fetchIntersectReportFailure,
  fetchIntersectReportStart,
  fetchIntersectReportSuccess,
};
