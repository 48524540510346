import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import ManuallyImportPagesPopupComponent from './ManuallyImportPagesPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { closeManuallyImportPagesPopup } from 'actions/popup_actions';
import { currentIdSelector } from 'selectors';
import { importPagesManually } from 'api/bulkPagesSetup';

const ManuallyImportPagesPopupContainer = ({ dispatch, brandId }) => {
  const [pagesList, setPagesList] = React.useState('');

  async function handleAddPagesClick() {
    const pagesListArray = pagesList.trim().split(/\r?\n/);

    await importPagesManually(dispatch, brandId, pagesListArray);

    handleCancelClick();
  }

  function handleCancelClick() {
    dispatch(closeManuallyImportPagesPopup());
  }

  function handlePagesListChange(event) {
    setPagesList(event.target.value);
  }

  return (
    <ManuallyImportPagesPopupComponent
      onAddPagesClick={handleAddPagesClick}
      onCancelClick={handleCancelClick}
      onPagesListChange={handlePagesListChange}
      pagesList={pagesList}
    />
  );
};

ManuallyImportPagesPopupContainer.propTypes = {
  brandId: PropTypes.string,
  dispatch: PropTypes.func,
};

const select = (state, ownProps) => ({
  brandId: currentIdSelector(state, ownProps),
});

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(ManuallyImportPagesPopupContainer);
