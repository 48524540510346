import {
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCaseDeep,
} from './utils';

import { uploadTmpFileToS3 } from './s3';

import { translate } from 'common/i18n';

import { showErrorMessageFromTranslation } from 'common/utils';

import {
  fetchBacklinksStart,
  fetchBacklinksSuccess,
  fetchBacklinksFailure,
  updateBacklinkFailure,
  updateBacklinkSuccess,
  updateBacklinksFailure,
  updateBacklinksSuccess,
} from 'actions/backlinkActions';

import { fetchBacklinksPagySuccess } from 'actions/pagy_actions';

import {
  importDestinationLinksFromAhrefsFailure,
  importDestinationLinksFromCSVFailure,
  importDestinationLinksManuallyFailure,
} from 'actions/destination_links_import_actions';

async function fetchBacklinks(dispatch, brandId, options = {}) {
  dispatch(fetchBacklinksStart());

  const {
    pagyItemsCount: items,
    pageNumber: page,
    filters,
    sorting,
    search,
    dlsGroupMode,
  } = options;
  const params = { items, page, filters, sorting, search, dlsGroupMode };

  try {
    const resp = await getFromAPI(
      Routes.backlink_path({ brand_id: brandId }),
      processObjToSnakeCaseDeep(params),
    );
    dispatch(fetchBacklinksSuccess(brandId, resp.backlinks));
    dispatch(fetchBacklinksPagySuccess(brandId, resp.pagy));
  } catch (err) {
    handleAPIError(dispatch, err, fetchBacklinksFailure, translate('errors.cantFetchBacklinks'));
  }
}

async function updateBacklink(dispatch, backlink, brandId) {
  try {
    const resp = await patchToAPI(
      Routes.backlink_path({ brand_id: brandId, backlink_id: backlink.id }),
      { backlink: processObjToSnakeCaseDeep(backlink) },
    );
    dispatch(updateBacklinkSuccess(brandId, resp));
  } catch (err) {
    handleAPIError(dispatch, err, updateBacklinkFailure, translate('errors.cantUpdateBacklink'));
  }
}

async function updateBacklinks(dispatch, brandId, backlinksIds, propertyName, propertyValue) {
  try {
    const resp = await patchToAPI(
      Routes.bulk_update_backlink_path({ brand_id: brandId }),
      processObjToSnakeCaseDeep({ backlinksIds, propertyName, propertyValue }),
    );
    dispatch(updateBacklinksSuccess(brandId, resp.backlinks));
  } catch (err) {
    handleAPIError(dispatch, err, updateBacklinksFailure, translate('errors.cantUpdateBacklinks'));
  }
}

export async function importFromAPI(dispatch, brandId, backlinksProvider) {
  try {
    await postToAPI(Routes.import_backlink_path({ brand_id: brandId }), {
      import: {
        backlinks_provider: backlinksProvider,
        import_mode: 'via_api',
      },
    });
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      importDestinationLinksFromAhrefsFailure,
      translate('errors.cantImportDestinationLinksFromAhrefs'),
    );
  }
}

async function importFromCsv(dispatch, brandId, file, csvFormat) {
  // pre-flight check
  if (!file) {
    showErrorMessageFromTranslation(dispatch, 'importCsv.messages.csvFileIsRequired');
  }

  try {
    const s3response = await uploadTmpFileToS3(file);

    if (!s3response.ok) {
      handleAPIError(
        dispatch,
        s3response.error,
        importDestinationLinksFromCSVFailure,
        translate('errors.cantImportDestinationLinks'),
      );
    }

    await postToAPI(Routes.import_backlink_path({ brand_id: brandId }), {
      import: {
        csv_format: csvFormat,
        file_s3_id: s3response.fileS3Id,
        import_mode: 'from_csv',
      },
    });
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      importDestinationLinksFromCSVFailure,
      translate('errors.cantImportDestinationLinks'),
    );
  }
}

async function manualImport(dispatch, brandId, backinksArray) {
  try {
    await postToAPI(Routes.import_backlink_path({ brand_id: brandId }), {
      import: {
        backlink_uris: backinksArray,
        import_mode: 'manually',
      },
    });
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      importDestinationLinksManuallyFailure,
      translate('errors.cantImportDestinationLinks'),
    );
  }
}

export { fetchBacklinks, importFromCsv, manualImport, updateBacklink, updateBacklinks };
