import React from 'react';

import { translate } from 'common/i18n';

import './anchorTextSelectHeader.scss';

const AnchorTextSelectHeaderComponent = () => {
  return (
    <div className="anchor-text-select-header">
      <div>{translate('destinationLink.anchorText.optionsHeader.suggestions')}</div>
      <div className="anchor-text-select-header__count">
        {translate('destinationLink.anchorText.optionsHeader.repeated')}
      </div>
    </div>
  );
};

export default AnchorTextSelectHeaderComponent;
