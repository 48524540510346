import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EoInboxesSettingsComponent from './EoInboxesSettingsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { eoInboxesSettingsSelector } from 'selectors/eoInboxesSettingsSelector';
import { eoInboxesSettingsShape } from 'common/propTypesShapes/eoInboxesSettings';
import { fetchEoInboxesSettings, updateEoInboxesSettings } from 'api/eoInboxesSettings';

const EoInboxesSettingsContainer = ({ canUpdate, dispatch, eoInboxesSettings }) => {
  React.useEffect(() => {
    fetchEoInboxesSettings(dispatch);
  }, [dispatch]);

  async function handleUpdateSettings(settings) {
    const resp = await updateEoInboxesSettings(dispatch, settings);
    return resp;
  }

  return (
    <EoInboxesSettingsComponent
      canUpdate={canUpdate}
      key={eoInboxesSettings.get('id')}
      eoInboxesSettings={eoInboxesSettings}
      onUpdateSettings={handleUpdateSettings}
    />
  );
};

EoInboxesSettingsContainer.propTypes = {
  canUpdate: PropTypes.bool,
  dispatch: PropTypes.func,
  eoInboxesSettings: eoInboxesSettingsShape,
};

function select(state) {
  const eoInboxesSettings = eoInboxesSettingsSelector(state);

  return {
    eoInboxesSettings,
  };
}

export default ConnectStoreHOC(connect(select)(EoInboxesSettingsContainer));
