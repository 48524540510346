import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import EoInboxFormPageComponent from './EoInboxFormPageComponent';

import {
  createEoInbox,
  deleteEoInbox,
  eoInboxCheckReplies,
  fetchEoInbox,
  updateEoInbox,
  verifyEoInboxAvailability,
} from 'api/eoInboxesSettings';

import { eoInboxShape } from 'common/propTypesShapes/eoInboxesSettings';
import { translate } from 'common/i18n';
import { currentIdSelector } from 'selectors';
import { currentEoInboxSelector } from 'selectors/eoInboxesSettingsSelector';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import confirmationDialogue from 'components/confirmation_dialogue';

import Breadcrumbs from 'components/NewBreadcrumbs';

const EoInboxFormPageContainer = ({ dispatch, eoInboxId, eoInbox, history }) => {
  React.useEffect(() => {
    eoInboxId && fetchEoInbox(dispatch, eoInboxId);
  }, [dispatch]);

  async function handleSave(eoInboxData) {
    const resp = eoInboxId
      ? await updateEoInbox(dispatch, eoInboxData)
      : await createEoInbox(dispatch, eoInboxData);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      return resp;
    }

    history.push(Routes.eo_inboxes_settings_path({ format: null }));
    return resp;
  }

  function handleDelete() {
    confirmationDialogue({
      body: translate('confirmations.default.title'),
      onConfirm: async () =>
        await deleteEoInbox(dispatch, eoInboxId).then(() =>
          history.push(Routes.eo_inboxes_settings_path({ format: null })),
        ),
    });
  }

  async function handleVerifyAvailability() {
    await verifyEoInboxAvailability(dispatch, eoInboxId);
  }

  async function handleCheckReplies() {
    await eoInboxCheckReplies(dispatch, eoInboxId);
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.LinkItem to={Routes.eo_inboxes_settings_path({ format: null })}>
          {translate('eoInboxesSettings.breadcrumbsTitle')}
        </Breadcrumbs.LinkItem>
        <Breadcrumbs.TextItem>
          {translate(`eoInboxForm.breadcrumbsTitle.${eoInboxId ? 'editInbox' : 'addInbox'}`)}
        </Breadcrumbs.TextItem>
      </Breadcrumbs>

      <div className="page__wrapper">
        <EoInboxFormPageComponent
          key={eoInbox.get('id')}
          eoInboxImmutable={eoInbox}
          onDelete={handleDelete}
          onSave={handleSave}
          onVerifyAvailability={handleVerifyAvailability}
          onCheckReplies={handleCheckReplies}
        />
      </div>
    </>
  );
};

EoInboxFormPageContainer.propTypes = {
  dispatch: PropTypes.func,
  eoInbox: eoInboxShape,
  eoInboxId: PropTypes.string,
};

function select(state, ownProps) {
  const eoInboxId = currentIdSelector(state, ownProps);
  const eoInbox = currentEoInboxSelector(state, ownProps);

  return {
    eoInboxId,
    eoInbox,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(EoInboxFormPageContainer);
