import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, isEmpty } from 'lodash';

import EoSchedulerFormPopupComponent from './EoSchedulerFormPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { createEoScheduler, updateEoScheduler } from 'api/eoScheduler';
import { currentIdSelector, optionsSelector } from 'selectors';
import { currentUserSelector } from 'selectors/railsContextSelectors';
import { eoSchedulerShape } from 'common/propTypesShapes/eoSchedulers';
import { fetchEoDripListsAsOptions } from 'api/eoDripList';
import { userShape } from 'common/prop_types_shapes';

const EoSchedulerFormPopupContainer = ({
  dispatch,
  currentUser,
  eoDripListsOptions,
  eoCampaignId,
  onClose,
  tableRowData: eoScheduler,
  timezonesOptions,
}) => {
  React.useEffect(() => {
    fetchEoDripListsAsOptions(dispatch);
  }, [dispatch]);

  async function handleSave(eoSchedulerFormData) {
    const resp = eoScheduler
      ? await updateEoScheduler(dispatch, eoCampaignId, eoSchedulerFormData)
      : await createEoScheduler(dispatch, eoCampaignId, eoSchedulerFormData);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      return resp;
    }

    onClose();

    return resp;
  }

  const accountTimezone = currentUser.get('timezoneName', null);

  return (
    <EoSchedulerFormPopupComponent
      accountTimezone={accountTimezone}
      immutableEoDripListsOptions={eoDripListsOptions}
      eoScheduler={eoScheduler}
      onCancel={onClose}
      onSave={handleSave}
      immutableTimezonesOptions={timezonesOptions}
    />
  );
};

EoSchedulerFormPopupContainer.propTypes = {
  currentUser: userShape,
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  onClose: PropTypes.func,
  tableRowData: eoSchedulerShape,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const currentUser = currentUserSelector(state);
  const eoCampaignId = currentIdSelector(state, ownProps);

  const options = optionsSelector(state);
  const eoDripListsOptions = options.get('eoDripListsOptions');
  const timezonesOptions = options.get('timezonesOptions');

  return {
    currentUser,
    eoDripListsOptions,
    eoCampaignId,
    timezonesOptions,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoSchedulerFormPopupContainer);
