const BULK_UPDATE_PAGE_TYPES__FAILURE = 'BULK_UPDATE_PAGE_TYPES__FAILURE';
const BULK_UPDATE_PAGE_TYPES__START = 'BULK_UPDATE_PAGE_TYPES__START';
const BULK_UPDATE_PAGE_TYPES__SUCCESS = 'BULK_UPDATE_PAGE_TYPES__SUCCESS';
const FETCH_PAGE_TYPES__FAILURE = 'FETCH_PAGE_TYPES__FAILURE';
const FETCH_PAGE_TYPES__START = 'FETCH_PAGE_TYPES__START';
const FETCH_PAGE_TYPES__SUCCESS = 'FETCH_PAGE_TYPES__SUCCESS';

function bulkUpdatePageTypesFailure(errors) {
  return { type: BULK_UPDATE_PAGE_TYPES__FAILURE, errors };
}

function bulkUpdatePageTypesStart() {
  return { type: BULK_UPDATE_PAGE_TYPES__START };
}

function bulkUpdatePageTypesSuccess(pageTypes) {
  return { type: BULK_UPDATE_PAGE_TYPES__SUCCESS, pageTypes };
}

function fetchPageTypesFailure(errors) {
  return { type: FETCH_PAGE_TYPES__FAILURE, errors };
}

function fetchPageTypesStart() {
  return { type: FETCH_PAGE_TYPES__START };
}

function fetchPageTypesSuccess(pageTypes) {
  return { type: FETCH_PAGE_TYPES__SUCCESS, pageTypes };
}


export {
  BULK_UPDATE_PAGE_TYPES__FAILURE,
  BULK_UPDATE_PAGE_TYPES__START,
  BULK_UPDATE_PAGE_TYPES__SUCCESS,
  FETCH_PAGE_TYPES__FAILURE,
  FETCH_PAGE_TYPES__START,
  FETCH_PAGE_TYPES__SUCCESS,
  bulkUpdatePageTypesFailure,
  bulkUpdatePageTypesStart,
  bulkUpdatePageTypesSuccess,
  fetchPageTypesFailure,
  fetchPageTypesStart,
  fetchPageTypesSuccess,
};
