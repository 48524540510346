import React from 'react';

const BacklinksChangesIcon = (props) => (
  <svg {...props} viewBox="0 0 17 16">
    <g fillRule="evenodd">
      <path d="M12.747 8.753l2.235-2.236A3.455 3.455 0 0016 4.058c0-.928-.361-1.801-1.018-2.458a3.454 3.454 0 00-2.46-1.017c-.93 0-1.803.361-2.46 1.017L7.829 3.837A3.483 3.483 0 007.1 7.681a3.468 3.468 0 00-1.386-.287c-.93 0-1.803.362-2.46 1.019l-2.235 2.236A3.455 3.455 0 000 13.107c0 .929.361 1.801 1.018 2.458a3.454 3.454 0 002.459 1.018c.93 0 1.804-.362 2.46-1.018l2.235-2.236a3.483 3.483 0 00.729-3.845c.431.187.9.286 1.386.286.93 0 1.804-.361 2.46-1.017zm-5.142 4.014L5.37 15.003a2.657 2.657 0 01-1.893.783 2.657 2.657 0 01-1.892-.783A2.663 2.663 0 01.8 13.107c0-.716.279-1.39.785-1.896L3.82 8.975a2.68 2.68 0 012.749-.644l-.473.473a2.09 2.09 0 00-1.87.58L1.99 11.622a2.089 2.089 0 00-.616 1.486c0 .561.219 1.09.616 1.486a2.089 2.089 0 001.487.616 2.09 2.09 0 001.488-.616l2.236-2.236a2.1 2.1 0 00.578-1.87l.469-.469c.312.934.1 2.006-.643 2.749v-.001zM4.42 12.16a1.186 1.186 0 001.686 0l.823-.823a1.299 1.299 0 01-.295.456L4.399 14.03a1.293 1.293 0 01-.922.38 1.297 1.297 0 01-1.303-1.305c0-.348.136-.676.383-.923l2.235-2.236a1.3 1.3 0 01.457-.295l-.828.828a1.19 1.19 0 000 1.681H4.42zm1.12-.56a.399.399 0 01-.552 0 .394.394 0 010-.557l5.474-5.475a.387.387 0 01.552 0 .394.394 0 010 .556L5.539 11.6h.001zm6.04-6.595a1.183 1.183 0 00-.842-.349c-.318 0-.617.124-.843.35l-.823.823a1.3 1.3 0 01.295-.457l2.235-2.236c.246-.246.573-.381.921-.381a1.298 1.298 0 011.304 1.305c0 .349-.136.677-.382.923L11.208 7.22a1.29 1.29 0 01-.456.294l.828-.828a1.191 1.191 0 000-1.68v-.001zM9.906 8.361a2.09 2.09 0 001.87-.58l2.236-2.236c.396-.398.615-.925.615-1.487a2.09 2.09 0 00-.615-1.486 2.09 2.09 0 00-1.489-.615 2.09 2.09 0 00-1.487.615L8.799 4.81a2.1 2.1 0 00-.578 1.87l-.469.468A2.682 2.682 0 018.395 4.4l2.235-2.236a2.659 2.659 0 011.893-.784c.715 0 1.388.278 1.892.783.506.506.785 1.18.785 1.895 0 .717-.279 1.39-.785 1.896L12.18 8.191a2.687 2.687 0 01-2.748.644l.473-.474h.001z" />
      <path d="M11.666 13.26l.4.455.497-.569-.333-.38a1.874 1.874 0 00-1.41-.641H10v.75h.82c.324 0 .632.14.846.384z" />
      <path d="M14.875 15.125h-.07c-.324 0-.632-.14-.846-.384L13.31 14l.648-.74c.214-.245.522-.385.847-.385h.069v.75L16 12.5l-1.125-1.125v.75h-.07c-.54 0-1.054.233-1.41.64l-1.729 1.976a1.125 1.125 0 01-.847.384H10v.75h.82c.54 0 1.054-.233 1.41-.64l.582-.666.583.665c.356.408.87.641 1.41.641h.07v.75L16 15.5l-1.125-1.125v.75z" />
    </g>
  </svg>
);

BacklinksChangesIcon.defaultProps = {
  fill: 'currentColor',
  height: '17px',
  width: '16px',
};

export default BacklinksChangesIcon;
