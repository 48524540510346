import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import './unsubscribeProspectPage.scss';

const UnsubscribeProspectPageComponent = ({ unsubscribeStatus }) => (
  <div className="unsubscribe-prospect-page">
    {unsubscribeStatus === 'cancel' && (
      <>
        <h3>{translate('prospects.unsubscribe.cancel.title')}</h3>
      </>
    )}
    {unsubscribeStatus === 'unsubscribed' && (
      <>
        <h1>{translate('prospects.unsubscribe.success.title')}</h1>
        <p>{translate('prospects.unsubscribe.success.subtitle')}</p>
      </>
    )}
  </div>
);

UnsubscribeProspectPageComponent.propTypes = {
  unsubscribeStatus: PropTypes.string,
};

export default UnsubscribeProspectPageComponent;
