import React from 'react';
import PropTypes from 'prop-types';

import AddCustomEoInboxButton from 'pages/EoInboxesSettingsPage/AddCustomEoInboxButton';
import ButtonComponent from 'components_linkio/button_component';
import ConnectGmailEoInboxButton from 'pages/EoInboxesSettingsPage/ConnectGmailEoInboxButton';

import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';

import './eoInboxTypeSelectPopup.scss';

const EoInboxTypeSelectPopupComponent = ({ onClose }) => {
  return (
    <Modal show className="eo-inbox-type-select-popup">
      <Modal.Header>
        <Modal.Title>{translate('eoInboxesSettings.eoInboxTypeSelectPopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="eo-inbox-type-select-popup__btn-group">
          <ConnectGmailEoInboxButton />
          <AddCustomEoInboxButton />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <ButtonComponent isWhite isInitial onClick={onClose}>
          {translate('uiComponents.button.cancel')}
        </ButtonComponent>
      </Modal.Footer>
    </Modal>
  );
};

EoInboxTypeSelectPopupComponent.propTypes = {
  onClose: PropTypes.func,
};

export default EoInboxTypeSelectPopupComponent;
