import React from 'react';
import PropTypes from 'prop-types';

import DlsRedistributingStatusNotifier from '../DlsRedistributingStatusNotifier';
import ShowTrustSuggestionsPopupLink from '../../Suggestions/ShowTrustSuggestionsPopupLink';

import { translate } from 'common/i18n';

import './dlsStatusBar.scss';

const DlsStatusBarComponent = ({ dlsCount, status, tab, onReloadDls }) => {
  const isSuggestionsTab = tab === 'suggestions';

  return (
    <div className="atp-status-bar-component">
      <div className="atp-status-bar-component__item">
        {translate('dlsStatusBarComponent.linksCount')({ tab, dlsCount })}
      </div>
      {isSuggestionsTab && (
        <>
          <div className="atp-status-bar-component__item">
            <ShowTrustSuggestionsPopupLink />
          </div>
          <div className="atp-status-bar-component__item">
            <DlsRedistributingStatusNotifier status={status} onReloadDls={onReloadDls} />
          </div>
        </>
      )}
    </div>
  );
};

DlsStatusBarComponent.propTypes = {
  dlsCount: PropTypes.number.isRequired,
  onReloadDls: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default DlsStatusBarComponent;
