import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';

const eoProgressionsByCampaignId = (state, eoCampaignId) =>
  state.getIn(['eoProgressions', String(eoCampaignId)], iList());

const eoProgressionsPagy = (state) => state.getIn(['pagies', 'eoProgressionsPagy'], iMap());

export const eoProgressionsByCampaignIdSelector = createSelector(
  [eoProgressionsByCampaignId],
  (eoProgressions) => eoProgressions,
);

export const eoProgressionsPagySelector = createSelector([eoProgressionsPagy], (pagy) => pagy);
