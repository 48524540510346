import React from 'react';

const StackIcon = (props) => (
  <svg {...props} className="stack-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1.333L1.333 4.667 8 8l6.667-3.333L8 1.333zM1.333 11.333L8 14.667l6.667-3.334M1.333 8L8 11.333 14.667 8"
      strokeWidth="1.0"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

StackIcon.defaultProps = {
  height: '16px',
  width: '16px',
};

export default StackIcon;
