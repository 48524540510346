import React from 'react';

const CategorizationIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M4 5.333V8h4V6.667h4v4H8V9.333H4v4h4V12h4v4H8v-1.333H2.667V5.333H1.333A1.333 1.333 0 0 1 0 4V1.333C0 .597.597 0 1.333 0H4c.736 0 1.333.597 1.333 1.333V4c0 .736-.597 1.333-1.333 1.333zM9.333 8v1.333h1.334V8H9.333zm0 5.333v1.334h1.334v-1.334H9.333zm-8-12V4H4V1.333H1.333z" fillRule="nonzero" />
    </svg>
  );
};

CategorizationIcon.defaultProps = {
  fill: "#000",
  height: '16px',
  width: '12px',
};

export default CategorizationIcon;
