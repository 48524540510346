import moment from 'moment';

import { translate } from 'common/i18n';

export default function buildNextCheck(crawlingData) {
  const { canUseCrawlers, crawlingEnabled, lastCrawled, scheduledCrawlingInterval } = crawlingData;

  if (!crawlingEnabled) {
    return translate('brand.crawler.value.isOff');
  }

  if (!canUseCrawlers || !scheduledCrawlingInterval) {
    return translate('notAvailable');
  }

  const timeFrameToAdd = scheduledCrawlingInterval === 'monthly' ? 'month' : 'week';
  const nextCheckFormatted = lastCrawled && moment(lastCrawled).add(1, timeFrameToAdd).format('MMM D, YYYY');

  if (!nextCheckFormatted) {
    return translate('notAvailable');
  }

  return nextCheckFormatted;
}
