import { forOwn } from 'lodash';

import {
  getFromAPI,
} from './utils';

export async function uploadTmpFileToS3(file) {
  try {
    const presignData = await getFromAPI(Routes.s3_tmp_presign_path());

    const awsFormData = (file) => {
      const formData = new FormData();
      forOwn(presignData.fields, (value, key) => {
        formData.append(key, value);
      });
      formData.append('file', file);
      formData.append('Content-Type', file.type);
      return formData;
    };

    const s3response = await fetch(`${presignData.url}`, {
      method: 'POST',
      body: awsFormData(file),
      headers: { 'Access-Control-Allow-Origin': Routes.root_url({ format: null }) },
    });

    return { ok: s3response.ok, fileS3Id: presignData.fields.key };
  } catch (err) {
    return { error: err, ok: false };
  }
}
