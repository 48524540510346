import {
  fetchBrandsMetricsStart,
  fetchBrandsMetricsSuccess,
  fetchBrandsMetricsFailure,
} from '../actions/accountDashboardActions';

import { translate } from '../common/i18n';
import { showSuccessMessage } from '../common/utils';
import { getFromAPI, handleAPIError , patchToAPI} from './utils';

async function fetchBrandsMetrics(dispatch, startDate, endDate) {
  dispatch(fetchBrandsMetricsStart());

  try {
    const result = await getFromAPI(Routes.root_path({ start_date: startDate, end_date: endDate }));
    dispatch(fetchBrandsMetricsSuccess(result.brands));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchBrandsMetricsFailure,
      translate('errors.cantFetchBrandsMetrics'),
    );
  }
}



export { fetchBrandsMetrics};
