export const CREATE_EO_EMAIL_TEMPLATE_FOLDER__FAILURE = 'CREATE_EO_EMAIL_TEMPLATE_FOLDER__FAILURE';
export const CREATE_EO_EMAIL_TEMPLATE_FOLDER__START = 'CREATE_EO_EMAIL_TEMPLATE_FOLDER__START';
export const CREATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS = 'CREATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS';
export const DELETE_EO_EMAIL_TEMPLATE_FOLDERS__FAILURE =
  'DELETE_EO_EMAIL_TEMPLATE_FOLDERS__FAILURE';
export const DELETE_EO_EMAIL_TEMPLATE_FOLDERS__START = 'DELETE_EO_EMAIL_TEMPLATE_FOLDERS__START';
export const DELETE_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS =
  'DELETE_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS';
export const FETCH_EO_EMAIL_TEMPLATE_FOLDERS__FAILURE = 'FETCH_EO_EMAIL_TEMPLATE_FOLDERS__FAILURE';
export const FETCH_EO_EMAIL_TEMPLATE_FOLDERS__START = 'FETCH_EO_EMAIL_TEMPLATE_FOLDERS__START';
export const FETCH_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS = 'FETCH_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS';
export const FETCH_EO_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__FAILURE =
  'FETCH_EO_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__FAILURE';
export const FETCH_EO_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__START =
  'FETCH_EO_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__START';
export const FETCH_EO_CMARKETER_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__SUCCESS =
  'FETCH_EO_CMARKETER_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__SUCCESS';
export const FETCH_EO_EMAIL_TEMPLATE_FOLDER__FAILURE = 'FETCH_EO_EMAIL_TEMPLATE_FOLDER__FAILURE';
export const FETCH_EO_EMAIL_TEMPLATE_FOLDER__START = 'FETCH_EO_EMAIL_TEMPLATE_FOLDER__START';
export const FETCH_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS = 'FETCH_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS';
export const UPDATE_EO_EMAIL_TEMPLATE_FOLDER__FAILURE = 'UPDATE_EO_EMAIL_TEMPLATE_FOLDER__FAILURE';
export const UPDATE_EO_EMAIL_TEMPLATE_FOLDER__START = 'UPDATE_EO_EMAIL_TEMPLATE_FOLDER__START';
export const UPDATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS = 'UPDATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS';

export function createEoEmailTemplateFolderStart() {
  return { type: CREATE_EO_EMAIL_TEMPLATE_FOLDER__START };
}

export function createEoEmailTemplateFolderSuccess(eoEmailTemplateFolder) {
  return { type: CREATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS, eoEmailTemplateFolder };
}

export function createEoEmailTemplateFolderFailure(errors) {
  return { type: CREATE_EO_EMAIL_TEMPLATE_FOLDER__FAILURE, errors };
}

export function deleteEoEmailTemplateFoldersStart() {
  return { type: DELETE_EO_EMAIL_TEMPLATE_FOLDERS__START };
}

export function deleteEoEmailTemplateFoldersSuccess(ids) {
  return { type: DELETE_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS, ids };
}

export function deleteEoEmailTemplateFoldersFailure(errors) {
  return { type: DELETE_EO_EMAIL_TEMPLATE_FOLDERS__FAILURE, errors };
}

export function fetchEoEmailTemplateFoldersStart() {
  return { type: FETCH_EO_EMAIL_TEMPLATE_FOLDERS__START };
}

export function fetchEoEmailTemplateFoldersSuccess(eoEmailTemplateFolders) {
  return { type: FETCH_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS, eoEmailTemplateFolders };
}

export function fetchEoEmailTemplateFoldersFailure(errors) {
  return { type: FETCH_EO_EMAIL_TEMPLATE_FOLDERS__FAILURE, errors };
}

export function fetchCmarketerEoEmailTemplateFoldersAsOptionsStart() {
  return { type: FETCH_EO_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__START };
}

export function fetchCmarketerEoEmailTemplateFoldersAsOptionsSuccess(
  eoCmarketerEmailTemplateFolders,
) {
  return {
    type: FETCH_EO_CMARKETER_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__SUCCESS,
    eoCmarketerEmailTemplateFolders,
  };
}

export function fetchCmarketerEoEmailTemplateFoldersAsOptionsFailure(errors) {
  return { type: FETCH_EO_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__FAILURE, errors };
}

export function fetchEoEmailTemplateFolderStart() {
  return { type: FETCH_EO_EMAIL_TEMPLATE_FOLDER__START };
}

export function fetchEoEmailTemplateFolderSuccess(eoEmailTemplateFolder) {
  return { type: FETCH_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS, eoEmailTemplateFolder };
}

export function fetchEoEmailTemplateFolderFailure(errors) {
  return { type: FETCH_EO_EMAIL_TEMPLATE_FOLDER__FAILURE, errors };
}

export function updateEoEmailTemplateFolderStart() {
  return { type: UPDATE_EO_EMAIL_TEMPLATE_FOLDER__START };
}

export function updateEoEmailTemplateFolderSuccess(eoEmailTemplateFolder) {
  return { type: UPDATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS, eoEmailTemplateFolder };
}

export function updateEoEmailTemplateFolderFailure(errors) {
  return { type: UPDATE_EO_EMAIL_TEMPLATE_FOLDER__FAILURE, errors };
}
