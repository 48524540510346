import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RankTrackerComponent from './RankTrackerComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';
import { currentIdSelector } from 'selectors';

import Breadcrumbs from 'components/NewBreadcrumbs';
import BreadcrumbsBrandsSelect from 'components/NewBreadcrumbs/BreadcrumbsBrandsSelect';
import ButtonComponent from 'components_linkio/button_component';

class RankTrackerContainer extends React.Component {
  static propTypes = {
    brandId: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  handleClickAddKeywords = () => {
    this.props.history.push(
      Routes.edit_rank_tracker_keywords_path(this.props.brandId, { format: null }),
    );
  };

  rightSideBtn = () => {
    return (
      <ButtonComponent isGreen onClick={this.handleClickAddKeywords}>
        {translate('rankTracker.buttons.addKeywords')}
      </ButtonComponent>
    );
  };

  render() {
    return (
      <>
        <Breadcrumbs rightSideBtn={this.rightSideBtn()}>
          <Breadcrumbs.LinkItem to={Routes.root_path({ format: null })}>
            {translate('brand.list.title')}
          </Breadcrumbs.LinkItem>
          <BreadcrumbsBrandsSelect targetAppModule="rankTracker" />
        </Breadcrumbs>

        <div className="page__wrapper">
          <RankTrackerComponent />
        </div>
      </>
    );
  }
}

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);

  return {
    brandId,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(RankTrackerContainer)));
