import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';

import ClickOutsideDecorator from '../click_outside_component_decorator';

import './datepicker_component.scss';


class DatetimePickerComponent extends Component {
  static propTypes = {
    onValue: PropTypes.func,
    value: PropTypes.string,
  };

  buildDateString = () => {
    const { value } = this.props;

    if (value) {
      return new Date(this.props.value).toISOString();
    }

    return value;
  }

  onClickOutside = () => this.props.onValue(this.props.value)

  handleChange = (v) => this.props.onValue(v)

  render() {
    return (
      <Datetime
        className="date-time-picker date-time-picker_absolute"
        closeOnSelect
        disableOnClickOutside
        input={false}
        onChange={this.handleChange}
        timeFormat={false}
        value={moment(this.buildDateString())}
      />
    );
  }
}

export default ClickOutsideDecorator(DatetimePickerComponent);
