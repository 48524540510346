import React from 'react';
import PropTypes from 'prop-types';

import ButtonComponent from 'components_linkio/button_component';
import BinIcon from 'common/icons/bin';

const DeleteDlsButtonComponent = ({ isSelected, onDeleteDestinationLinks }) => {
  return (
    <div className="bulk-atp-actions-component__action">
      <div className="bulk-atp-actions-component__button bulk-atp-actions-component__delete-button">
        <ButtonComponent isDisabled={!isSelected} isRed onClick={onDeleteDestinationLinks}>
          <BinIcon className="bulk-atp-actions-component__icon" />
        </ButtonComponent>
      </div>
    </div>
  );
};

DeleteDlsButtonComponent.propTypes = {
  isSelected: PropTypes.bool,
  onDeleteDestinationLinks: PropTypes.func.isRequired,
};

export default DeleteDlsButtonComponent;
