export default {
  importCsv: {
    defaultFileName: 'No file selected.',
    buttons: {
      chooseFile: 'Choose File',
    },
    messages: {
      csvFileIsRequired: 'No file selected',
    },
  },
};
