import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { urlChangingHistoryShape } from 'common/prop_types_shapes';

import LinkIcon from 'common/icons/link';
import OverlayWithTooltipComponent from 'components_linkio/overlay_with_tooltip_component';

const ChangesCountValueComponent = ({
  changesCount,
  changesHistory,
  isActive,
  onChangesCountClick,
}) => {
  const isClickableValue = isActive && changesCount > 0;

  const changesCountClasses = cx('rank-tracker-table__changes-count', {
    'rank-tracker-table__changes-count_active': isClickableValue,
  });

  if (!isClickableValue) {
    return (
      <span className={changesCountClasses}><LinkIcon /></span>
    );
  }

  const tooltipText = (
    <div>
      {changesHistory.map((item, i) => <div key={i}>{item.link} {item.date}</div>)}
    </div>
  );

  return (
    <OverlayWithTooltipComponent
      isCopyable
      tooltip={{ text: tooltipText, placement: 'top', fitContent: true }}
    >
      <span className={changesCountClasses} onClick={onChangesCountClick}>
        {changesCount > 1 &&
          <span className="rank-tracker-table__changes-count-value">{changesCount}</span>
        }
        <LinkIcon />
      </span>
    </OverlayWithTooltipComponent>
  );
};

ChangesCountValueComponent.propTypes = {
  changesCount: PropTypes.number,
  changesHistory: PropTypes.arrayOf(urlChangingHistoryShape),
  isActive: PropTypes.bool,
  onChangesCountClick: PropTypes.func.isRequired,
};

export default ChangesCountValueComponent;
