import React from 'react';

import { translate } from 'common/i18n';
import { crawlerStatusDataShape } from 'common/prop_types_shapes';

import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';

import './crawlerStatusBar.scss';


const CrawlerStatusBarComponent = ({ crawlerStatusData }) => {
  return (
    <div className="crawler-status-bar">
      <div>{translate('crawlerStatusBar.crawlerQueue')(crawlerStatusData.jobsCount)}</div>

      <div>
        {translate('crawlerStatusBar.waitTime')(crawlerStatusData.waitTime)}
        <ExplanatoryTooltipComponent
          isWhite
          placement="bottom"
          text={translate('explanationMessages.crawlerStatusBar.waitTime')}
        />
      </div>
    </div>
  );
};

CrawlerStatusBarComponent.propTypes = {
  crawlerStatusData: crawlerStatusDataShape,
};

export default CrawlerStatusBarComponent;
