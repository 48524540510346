import { fromJS, Map as iMap } from 'immutable';
import { keyBy } from 'lodash';

import {
  CREATE_EMPLOYEE__SUCCESS,
  DELETE_EMPLOYEES__SUCCESS,
  FETCH_EMPLOYEES__SUCCESS,
  INVITE_EMPLOYEE__SUCCESS,
  UPDATE_EMPLOYEE_ROWS,
  UPDATE_EMPLOYEE__SUCCESS,
} from '../actions/employee_actions';

import {
  camelizeObject,
} from './utils';

const initialState = iMap();

const processEmployee = (employee) => {
  if (!employee) {
    return void 0;
  }
  return camelizeObject(employee);
};


export default function employeeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMPLOYEES__SUCCESS:
    case INVITE_EMPLOYEE__SUCCESS:
    case UPDATE_EMPLOYEE_ROWS: {
      state = state.merge(state, fromJS(keyBy(action.employees.map(processEmployee), 'id')));

      break;
    }
    case CREATE_EMPLOYEE__SUCCESS:
    case UPDATE_EMPLOYEE__SUCCESS: {
      state = state.merge(state, fromJS(keyBy([processEmployee(action.employee)], 'id')));

      break;
    }
    case DELETE_EMPLOYEES__SUCCESS: {
      state = state.deleteAll(action.employeeIds.map(String));

      break;
    }
  }

  return state;
}
