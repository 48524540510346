import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EoInboxesSettingsPageComponent from './EoInboxesSettingsPageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import clickUpgradeSubscriptionService from 'pages/Pages/services/clickUpgradeSubscriptionService';

import { subscriptionPoliciesShape, subscriptionShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import {
  currentSubscriptionSelector,
  policiesSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import Breadcrumbs from 'components/NewBreadcrumbs';

const EoInboxesSettingsPageContainer = ({
  canCreate,
  canUpdate,
  dispatch,
  currentSubscription,
  subscriptionPolicies,
}) => {
  const canUseEoInboxesSettings = subscriptionPolicies.getIn(['emailOutreach', 'canUse']);

  function handleClickUpgradeSubscription(event) {
    clickUpgradeSubscriptionService({ currentSubscription, dispatch }, event);
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>
          {translate('eoInboxesSettings.breadcrumbsTitle')}
        </Breadcrumbs.TextItem>
      </Breadcrumbs>

      <div className="eo-inboxes-settings-page page__wrapper">
        <EoInboxesSettingsPageComponent
          canCreate={canCreate}
          canUpdate={canUpdate}
          canUseEoInboxesSettings={canUseEoInboxesSettings}
          onClickUpgradeSubscription={handleClickUpgradeSubscription}
        />
      </div>
    </>
  );
};

EoInboxesSettingsPageContainer.propTypes = {
  canCreate: PropTypes.bool,
  canUpdate: PropTypes.bool,
  currentSubscription: subscriptionShape,
  dispatch: PropTypes.func,
  subscriptionPolicies: subscriptionPoliciesShape,
};

function select(state) {
  const currentSubscription = currentSubscriptionSelector(state);
  const subscriptionPolicies = subscriptionPoliciesSelector(state);

  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);
  const canUpdate = policies.getIn(['emailOutreach', 'canUpdate']);

  return {
    canCreate,
    canUpdate,
    currentSubscription,
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(EoInboxesSettingsPageContainer));
