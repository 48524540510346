const brands = {
  brand: {
    add: {
      title: "Let's start with adding your website.",
      subtitle:
        'Linkio monitors your keyword rankings, your backlinks and your anchor text profile \
        for each page on your website to give you insights on how to improve your rankings.',
    },
    form: {
      breadcrumbsTitle: (brandId) => (brandId ? 'Edit Brand' : 'Add Brand'),
      buttons: {
        save: (brandId) => (brandId ? 'Save' : 'Next'),
        cancel: 'Cancel',
      },
      connectAhrefsAccountLinkText: 'Connect your Ahrefs account',
      checkboxes: {
        importDlsFromGsc: {
          label: 'Import links from Google Search Console',
        },
      },
      inputs: {
        otherNames: {
          hint:
            'Be sure to include any additional variations, common misspellings and the name \
            without spaces',
          label: 'Alternative Brand Names',
        },
        brandName: {
          hint:
            'Be sure to include any additional variations, common misspellings and the name \
            without spaces',
          label: 'Brand Name',
        },
        importDlsFromGscFileName: {
          hint: {
            gscLink: {
              href: (brandLink) => {
                if (!brandLink) {
                  return 'https://search.google.com/search-console';
                }
                return `https://search.google.com/search-console/links?resource_id=${brandLink}`;
              },
              title: 'here',
            },
            firstPart: 'Check ',
            secondPart: ` to get the list of your external backlinks. \
            Please upload this file and we’ll automatically add your brand pages and their backlinks. \
            This may take a while.`,
          },
        },
        importBacklinksFromAhrefsCheckbox: {
          hint:
            'We integrate with the Ahrefs API to import your existing backlinks and find \
            new backlinks automatically.',
          label: 'Import backlinks from Ahrefs',
          warningFree:
            'You have a FREE Ahrefs account so API importing will not work. \
            Upgrade to a paid Ahrefs account to enable this feature.',
          warningLimit:
            "Automatic backlink import from Ahrefs temporarily won't work because \
            your monthly Ahrefs API credits are spent. Keep this box checked and we'll \
            perform this action when your credits renew",
          // added custom message for trail users this is test message can be removed or msg can be edited
          warningDefaultAhref:
            'For trail period and for paid subscription as an experimental feature Linkio provides you with limited Ahrefs API to import backlinks and find \
            new backlinks automatically, If you have your own Ahrefs API you can integrate by clicking the link next to it.',
        },
        importBacklinksFromMozCheckbox: {
          hint:
            'As an experimental feature Linkio provides you with 2,000 backlinks monthly. \
          Check the credits in your account settings.',
          label: 'Import backlinks from Moz',
          warningLimit:
            "Automatic backlink import from Moz temporarily won't work because \
            your monthly Moz API credits are spent. Keep this box checked and we'll \
            perform this action when your credits renew",
        },
        kwDomainMatch: {
          hint: {
            partOne: 'For example if your domain is ',
            partTwo: 'example.com',
            partThree: ' and your keyword is ',
            partFour: 'exampleone',
            partFive: ' the answer would be Partially.',
          },
          label: 'Does your domain name match your keyword exactly?',
        },
        website: {
          hint: 'Enter your domain, e.g. example.com',
          label: 'Website Address',
        },
        websiteType: {
          label: 'Website Type',
        },
      },
    },
    title: 'Brand',
    messages: {
      createSuccess: 'Brand was created successfully.',
      deleteFailure: 'Brand was not deleted.',
      deleteSuccess: 'Brand was deleted successfully.',
      updateSuccess: 'Brand was updated successfully.',
    },
    atpLink: {
      create: 'Create',
      title: 'Anchor Text Planner',
      view: 'View',
    },
    backlinksLink: {
      create: 'Create',
      title: 'Backlinks',
      view: 'View',
    },
    crawler: {
      title: 'Crawler',
      value: {
        lastCrawled: (value) => (value ? `Crawled ${value}` : ''),
        isOff: 'crawling is off',
      },
    },
    crawl: {
      title: 'Verify Backlinks',
    },
    generateMetris : {
      title: 'Check Metrics'
    },
    delete: {
      title: 'Delete',
    },
    disavowLinks: {
      title: 'Disavow',
    },
    edit: {
      title: 'Edit',
    },
    name: {
      placeholder: 'Name...',
      title: 'Name',
    },
    website: {
      placeholder: 'Website...',
      title: 'Website',
    },
    bulkActions: {
      buttons: {
        crawl: (count) => {
          switch (count) {
            case 0: {
              return 'Crawl';
            }
            case 1: {
              return 'Crawl 1 brand';
            }
            default: {
              return `Crawl ${count} brands`;
            }
          }
        },
      },
      warnings: {
        pleaseSelectBrands: 'Please select brand(s)',
      },
    },
    list: {
      title: 'Dashboard',
      buttons: {
        add: 'Add Brand',
      },
    },
    dashboard: {
      myBrands: {
        title: 'SEO Monitoring And Analysis',
        table: {
          atp: 'Anchor Text Planner',
          avgRank: 'Avg. Rank',
          campaigns: 'Outreach Campaigns',
          dripLists: 'Drip Lists',
          refDomains: 'Ref. Domains',
        },
      },
    },
  },
};

export default brands;
