import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import CrossIcon from '../../common/icons/cross';
import TickInCircleIcon from '../../common/icons/tick_in_circle';
import CrossInCircleIcon from '../../common/icons/cross_in_circle';

import './balloon_component.scss';

const BalloonComponent = ({ children, color, onClose }) => {
  let InfoIcon;
  const infoIconClasses = classnames({
    'balloon__icon': true,
    'balloon__icon_info': true,
  });

  switch (color) {
    case "green":
      InfoIcon = <TickInCircleIcon className={infoIconClasses} />;
      break;
    case "red":
      InfoIcon = <CrossInCircleIcon className={infoIconClasses} />;
      break;
    case "blue":
      InfoIcon = <CrossInCircleIcon className={infoIconClasses} />
    break
  }


  return (
    <div className={`balloon__wrapper-${color}`}>
      <div className="balloon__info-wrapper">
        {InfoIcon}
        <span className="balloon__text">{children}</span>
      </div>

      <span onClick={onClose}>
        <CrossIcon className="balloon__icon balloon__icon_close" />
      </span>
    </div>
  );
};

BalloonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['green', 'red', 'blue']),
  onClose: PropTypes.func.isRequired,
};

BalloonComponent.defaultProps = {
  color: 'green',
};

export default BalloonComponent;
