import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';

import { fromJS } from 'immutable';
import { upperCase } from 'lodash';
import moment from 'moment';

import ChangesHistoryByDate from './ChangesHistoryByDate';
import { changesHistoryList } from 'common/propTypesShapes/changesHistory';

import { textColumn } from 'common/tables/columns';
import columnsGenerator from 'common/tables/columns_generator';

import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';

import ToggleShowPopup from 'components_linkio/ToggleShowPopup';
import BacklinkPopup from 'pages/BacklinksPage/BacklinksTable/BacklinkPopup';

import { generateDestinationLinkStatusForAtp } from 'common/tables/utils';

import { translate } from 'common/i18n';

import { insertNotAvailableIfNull } from 'common/utils';

import BadgeComponent from 'components_linkio/badge_component';
import './backlinksChangesHistoryTable.scss';

const BacklinksChangesHistoryTableComponent = ({
  canUseCrawlers,
  changesHistory,
  changesHistoryCount,
  onFetchCurrentSubscription,
  onTableHeaderClick,
  sortBy,
  sortingOrder,
}) => {
  const headerValueFormatter = (options = {}) => (_value, extra) => {
    const { withExplanationMessage } = options;
    const { property } = extra;

    const label =
      property === 'firstSeen'
        ? buildDatesHeader()
        : translate(`changesHistory.table.headers.${property}`);

    return (
      <HeaderFieldWithSorting
        appModule="backlinks"
        label={label}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  };

  function publishedChangesCellValueFormatter(value, extra) {
    const { property, rowData } = extra;

    const { errors, publishedLinkPageTitle, id } = rowData;

    const dlStatus = generateDestinationLinkStatusForAtp(fromJS(rowData), canUseCrawlers).map(
      (row) => {
        const { status } = row;
        const text = upperCase(status);
        const color = {
          blue: status === 'processing',
          green: status === 'included',
          orange: status === 'unvalidated',
          red: status === 'excluded',
          warning: status === 'error',
        };
        return <BadgeComponent small {...color} key={text} text={text} />;
      },
    );

    const validationError = (errors || {})[property];

    return (
      <>
        <p>{publishedLinkPageTitle}</p>
        <ToggleShowPopup
          className="atp-table__link-status"
          Popup={
            <BacklinkPopup
              destinationLink={fromJS(rowData)}
              destinationLinkId={id}
              onFetchCurrentSubscription={onFetchCurrentSubscription}
            />
          }
        >
          {dlStatus}
        </ToggleShowPopup>
        <a className="text" href={value} target="_blank" rel="nofollow noopener noreferrer">
          {value}
        </a>
        {validationError && <span className="cell_error small">{validationError}</span>}
      </>
    );
  }

  function anchorTextCellValueFormatter(value, extra) {
    const { link, textPre, textPost } = extra.rowData;
    return (
      <>
        {renderAnchorText(value, textPre, textPost)}
        <a className="text" href={link} target="_blank" rel="nofollow noopener noreferrer">
          {link}
        </a>
      </>
    );
  }

  function renderAnchorText(anchorText, textPre, textPost) {
    if (anchorText === '') {
      return (
        <BadgeComponent small text={upperCase(translate('dlDetailsPopup.tables.status.noText'))} />
      );
    }

    if (!anchorText) {
      return (
        <BadgeComponent small text={upperCase(translate('dlDetailsPopup.tables.status.noData'))} />
      );
    }

    return (
      <p>
        <span className="changes-history-table__anchor-text_pre">{textPre} </span>
        <span className="changes-history-table__anchor-text_main">{anchorText}</span>
        <span className="changes-history-table__anchor-text_post"> {textPost}</span>
      </p>
    );
  }

  function changesHistoryCellValueFormatter(_value, extra) {
    const { changesHistory } = extra.rowData;

    const changesText = changesHistory.map((changesHistoryItem) => {
      return (
        <ChangesHistoryByDate
          key={changesHistoryItem.dateOfChange}
          changesHistoryItem={changesHistoryItem}
        />
      );
    });

    return <>{changesText}</>;
  }

  function datesCellValueFormatter(_value, extra) {
    const { dlCrawlingResult, publishedDate } = extra.rowData;

    const firstSeenDate = publishedDate
      ? moment(new Date(publishedDate).toISOString()).format('MM/DD/YYYY')
      : null;
    const lastCheckedDate = dlCrawlingResult ? moment(dlCrawlingResult.createdAt).fromNow() : null;

    return (
      <>
        <span>{insertNotAvailableIfNull(firstSeenDate)}</span>
        <span>{insertNotAvailableIfNull(lastCheckedDate)}</span>
      </>
    );
  }

  function buildDatesHeader() {
    return (
      <div>
        <div>{translate('changesHistory.table.headers.datesColumn.firstSeen')}</div>
        <div>{translate('changesHistory.table.headers.datesColumn.lastChecked')}</div>
      </div>
    );
  }

  function generateColumns() {
    return columnsGenerator([
      textColumn({
        name: 'firstSeen',
        headerValueFormatter: headerValueFormatter(),
        cellValueFormatter: datesCellValueFormatter,
        className: 'changes-history-table__dates-column',
      }),
      textColumn({
        name: 'publishedLink',
        headerValueFormatter: headerValueFormatter(),
        cellValueFormatter: publishedChangesCellValueFormatter,
        className: 'changes-history-table__published-link-column',
      }),
      textColumn({
        name: 'anchorText',
        headerValueFormatter: headerValueFormatter(),
        cellValueFormatter: anchorTextCellValueFormatter,
        className: 'changes-history-table__anchor-text-column',
      }),
      textColumn({
        name: 'changes',
        headerValueFormatter: headerValueFormatter(),
        cellValueFormatter: changesHistoryCellValueFormatter,
        className: 'changes-history-table__changes-column',
      }),
    ]);
  }

  const changesBacklinks = changesHistory.toJS();

  const columns = generateColumns();

  return (
    <>
      {changesHistoryCount > 0 && (
        <span>{translate('changesHistory.table.changesHistoryCount')(changesHistoryCount)}</span>
      )}
      <Table.Provider className="changes-history-table" columns={columns}>
        <Table.Header />
        <Table.Body rowKey="id" rows={changesBacklinks} />
      </Table.Provider>
    </>
  );
};

BacklinksChangesHistoryTableComponent.propTypes = {
  canUseCrawlers: PropTypes.bool,
  changesHistory: changesHistoryList,
  changesHistoryCount: PropTypes.number,
  onFetchCurrentSubscription: PropTypes.func.isRequired,
  onTableHeaderClick: PropTypes.func,
  sortBy: PropTypes.string,
  sortingOrder: PropTypes.oneOf(['asc', 'desc']),
};

export default BacklinksChangesHistoryTableComponent;
