import { translate } from '../../common/i18n';

import { formattedErrorsMessages, showSuccessMessage, showErrorMessage } from '../../common/utils';

import { updateBrandFailure, updateBrandSuccess } from '../../actions/brand_actions';

import {
  importBacklinksFromApiFailure,
  importBacklinksFromApiStart,
  importBacklinksFromApiSuccess,
} from '../../actions/backlinkActions';

const handleImportBacklinksFromApiStatus = (dispatch, data) => {
  const { importBacklinksFromAhrefsStatus, importBacklinksFromMozStatus } = data;

  switch (importBacklinksFromAhrefsStatus) {
    case 'started':
      dispatch(importBacklinksFromApiStart(data.id));
      dispatch(updateBrandSuccess(data));
      showSuccessMessage(
        dispatch,
        translate('backlinksSetup.messages.importBacklinksFromAhrefsStarted'),
      );
      break;
    case 'finished':
      dispatch(updateBrandSuccess(data));
      showSuccessMessage(
        dispatch,
        translate('backlinksSetup.messages.importBacklinksFromAhrefsSuccess'),
      );
      dispatch(importBacklinksFromApiSuccess(data.id));
      break;
    case 'failure':
      dispatch(updateBrandFailure(data.errors));
      showFailureNotification(dispatch, data, 'Ahrefs');
      dispatch(importBacklinksFromApiFailure(data.id));
      break;
  }

  switch (importBacklinksFromMozStatus) {
    case 'started':
      dispatch(importBacklinksFromApiStart(data.id));
      dispatch(updateBrandSuccess(data));
      showSuccessMessage(
        dispatch,
        translate('backlinksSetup.messages.importBacklinksFromMozStarted'),
      );
      break;
    case 'finished':
      dispatch(updateBrandSuccess(data));
      showSuccessMessage(
        dispatch,
        translate('backlinksSetup.messages.importBacklinksFromMozSuccess'),
      );
      dispatch(importBacklinksFromApiSuccess(data.id));
      break;
    case 'failure':
      dispatch(updateBrandFailure(data.errors));
      showFailureNotification(dispatch, data, 'Moz');
      dispatch(importBacklinksFromApiFailure(data.id));
      break;
  }
};

const showFailureNotification = (dispatch, data, apiName) => {
  const errors = Object.values(data.errors).flat();

  return showErrorMessage(
    dispatch,
    formattedErrorsMessages(errors, `backlinksSetup.messages.importBacklinksFrom${apiName}Failure`),
    true,
  );
};

export default handleImportBacklinksFromApiStatus;
