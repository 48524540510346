import React from 'react';

import { onboardingStatusShape } from 'common/propTypesShapes/onboardingStatus';
import { translate } from 'common/i18n';

import Link from 'components_linkio/link';
import Chevron from 'common/icons/chevron';
import CheckedIcon from 'common/icons/checked';

import './eoOnboarding.scss';

const sidebar = [
  {
    name: 'eoDashboard',
    link: Routes.eo_dashboard_index_path({ format: null }),
  },
  {
    name: 'prospects',
    link: Routes.prospects_path({ format: null }),
  },
  {
    name: 'eoCampaigns',
    link: Routes.eo_campaigns_path({ format: null }),
  },
  {
    name: 'eoDripLists',
    link: Routes.eo_drip_lists_path({ format: null }),
  },
  {
    name: 'eoInboxesSettings',
    link: Routes.eo_inboxes_settings_path({ format: null }),
  },
];

function buildTips({ hasEoInbox, hasDripList, hasProspectInDripList, hasStartedCampaign }) {
  return [
    {
      name: 'addInbox',
      link: Routes.eo_inboxes_settings_path({ format: null }),
      isFinished: hasEoInbox,
    },
    {
      name: 'createDripList',
      link: Routes.eo_drip_lists_path({ format: null }),
      isFinished: hasDripList,
    },
    {
      name: 'addProspectsToDripList',
      link: Routes.prospects_path({ format: null }),
      isFinished: hasProspectInDripList,
    },
    {
      name: 'launchCampaign',
      link: Routes.eo_campaigns_path({ format: null }),
      isFinished: hasStartedCampaign,
    },
  ];
}

function renderTips(onboardingStatus) {
  const tips = buildTips(onboardingStatus);

  return (
    <ul className="eo-onboarding__tips">
      {tips.map(({ name, link, isFinished }) => {
        return (
          <li key={name} className="eo-onboarding__tips-item">
            {isFinished ? (
              <p className="eo-onboarding__finished-tip">
                {translate(`prompts.eoOnboarding.bodyText.tips.${name}`)} <CheckedIcon />
              </p>
            ) : (
              <Link to={link}>
                {translate(`prompts.eoOnboarding.bodyText.tips.${name}`)}
                {<Chevron className="eo-onboarding__chevron" direction="right" />}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
}

const EoOnboardingComponent = ({ immutableOnboardingStatus }) => {
  const onboardingStatus = immutableOnboardingStatus.toJS();

  return (
    <div className="eo-onboarding">
      <div className="eo-onboarding__card info-card">
        <ul className="eo-onboarding__sidebar">
          {sidebar.map(({ name, link }) => (
            <li key={name}>
              <Link to={link}>{translate(`sidebar.emailOutreach.${name}.title`)}</Link>
            </li>
          ))}
        </ul>

        <div>
          <h3>{translate('prompts.eoOnboarding.bodyText.title')}</h3>
          {renderTips(onboardingStatus)}
        </div>
      </div>
    </div>
  );
};

EoOnboardingComponent.propTypes = {
  immutableOnboardingStatus: onboardingStatusShape,
};

export default EoOnboardingComponent;
