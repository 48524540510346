import React from 'react';

import { translate } from 'common/i18n';

import DeviseFooterComponent from 'components/auth/devise_footer_component';
import DeviseHeaderComponent from 'components/auth/devise_header_component';


const ForgotPasswordInstructionsSentComponent = () => {
  return (
    <div className="forgot-password-component">
      <DeviseHeaderComponent signUp logIn />
      <div className="forgot-password-component__space" />
      <h2 className="forgot-password-component__text forgot-password-component__text_title">
        {translate('authForms.forgotPassword.instructionsSent.title')}
      </h2>
      <h4 className="forgot-password-component__text forgot-password-component__text_subtitle">
        {translate('authForms.forgotPassword.instructionsSent.successMsg')}
      </h4>
      <form className="forgot-password-component__form" noValidate>
        <DeviseFooterComponent />
      </form>
    </div>
  );
};

export default ForgotPasswordInstructionsSentComponent;
