import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import { eoCampaignOptionsShape } from 'common/propTypesShapes/eoCampaignOptions';
import { optionsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import Checkbox from 'components_linkio/Checkbox';
import InputComponent from 'components_linkio/input_component';
import Select from 'components_linkio/Select';
import Link from 'components_linkio/link';

import './eoCampaignOptions.scss';

function reducer(state, action) {
  const { value, type } = action;

  const newState = cloneDeep(state);

  newState[type] = value;

  return newState;
}

const inputs = [
  {
    name: 'title',
    type: 'text',
    isRequired: true,
  },
  {
    name: 'summary',
    type: 'text',
  },
];

const checkboxes = [
  {
    name: 'prospectCanEnter',
  },
  {
    name: 'unsubscribeLink',
  },
  // {
  //   name: 'dontTriggerProspects',
  // },
  {
    name: 'keepCampaignGoingOnReply',
  },
];

const EoCampaignOptionsComponent = ({
  canCreate,
  canUpdate,
  eoCampaignOptions,
  immutableBrandsOptions,
  immutableEoActiveHourOptions,
  immutableUsersOptions,
  onDeleteCampaign,
  onUpdateSettings,
}) => {
  const [state, dispatch] = React.useReducer(reducer, eoCampaignOptions.toJS());

  const brandsOptions = immutableBrandsOptions.toJS();
  const eoActiveHourOptions = immutableEoActiveHourOptions.toJS();
  const assigneeOptions = immutableUsersOptions.toJS();

  const { ownerEmail, eoActiveHoursSetId, brandId, errors = {}, assigneeId } = state;

  const areThereAnyEoActiveHourOptions = !!eoActiveHourOptions.length;

  async function handleUpdateOptions() {
    const resp = await onUpdateSettings(state);

    dispatch({ type: 'errors', value: resp.errors });
  }

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  const setCheckboxValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.checked });
  };

  const setSelectValue = (inputName) => (obj) => {
    dispatch({ type: inputName, value: obj ? obj.value : null });
  };

  const pathToActiveHours = Routes.eo_active_hours_sets_path({ format: null });

  return (
    <div className="eo-campaign-options">
      <p className="eo-campaign-options__owner">
        <b>{translate('eoCampaignOptions.owner')}</b> {ownerEmail}
      </p>

      {inputs.map((input) => {
        const { name, type, isRequired } = input;
        const value = state[name] || '';

        return (
          <InputComponent
            isMandatory={isRequired}
            key={name}
            label={translate(`eoCampaignOptions.inputs.${name}.label`)}
            message={{ type: 'error', text: errors[name] }}
            name={name}
            onInputChange={setInputValue(name)}
            type={type}
            value={value}
          />
        );
      })}

      <Select
        className="options--eo-active-hours-selector"
        label={translate('eoCampaignOptions.inputs.activeHours.label')}
        blurInputOnSelect={false}
        triggerChangeOnBlur={false}
        isClearable
        onChange={setSelectValue('eoActiveHoursSetId')}
        options={eoActiveHourOptions}
        value={eoActiveHourOptions.find(({ value }) => value === eoActiveHoursSetId)}
        message={{
          type: 'hint',
          text: translate('eoCampaignOptions.inputs.activeHours.hint')(
            areThereAnyEoActiveHourOptions,
          ),
        }}
        wrapperClassName={
          areThereAnyEoActiveHourOptions
            ? 'eo-campaign-options__select_without-link-after'
            : 'eo-campaign-options__select_with-link-after'
        }
      />

      {!areThereAnyEoActiveHourOptions && (
        <Link className="eo-campaign-options__create-active-hours_link" to={pathToActiveHours}>
          {translate('eoCampaignOptions.links.createActiveHours')}
        </Link>
      )}

      <Select
        className="options--brand-selector"
        isClearable
        label={translate('uiComponents.brandAssign.selectLabel')}
        blurInputOnSelect={false}
        placeholder={translate('uiComponents.brandAssign.noBrandAssigned')}
        triggerChangeOnBlur={false}
        onChange={setSelectValue('brandId')}
        options={brandsOptions}
        value={brandsOptions.find(({ value }) => value === brandId)}
        wrapperClassName="eo-campaign-options__assign-brand-select"
      />

      <Select
        isClearable
        label={translate(`eoCampaignOptions.inputs.repliesAssigneeSelect`)}
        placeholder={translate(`eoToDo.emailArea.assignedToSelectDefaultValue`)}
        onChange={setSelectValue('assigneeId')}
        options={assigneeOptions}
        triggerChangeOnBlur={false}
        value={assigneeOptions.find(({ value }) => value === assigneeId) || null}
        wrapperClassName="eo-campaign-options__assign-reply-to-select"
      />

      {checkboxes.map((checkbox) => {
        const { name } = checkbox;
        const checked = state[name] || false;

        return (
          <Checkbox
            key={name}
            checked={checked}
            onChange={setCheckboxValue(name)}
            message={{
              type: 'hint',
              text: translate(`eoCampaignOptions.checkboxes.${name}.hint`),
            }}
          >
            {translate(`eoCampaignOptions.checkboxes.${name}.label`)}
          </Checkbox>
        );
      })}

      <div className="eo-campaign-options__buttons-row">
        {canUpdate && (
          <ButtonComponent
            className="eo-campaign-options__save-button"
            isGreen
            onClick={handleUpdateOptions}
          >
            {translate('uiComponents.button.save')}
          </ButtonComponent>
        )}

        {canCreate && (
          <ButtonComponent
            className="eo-campaign-options__delete-button"
            isInitial
            isRed
            onClick={onDeleteCampaign}
          >
            {translate('eoCampaignOptions.buttons.delete')}
          </ButtonComponent>
        )}
      </div>
    </div>
  );
};

EoCampaignOptionsComponent.propTypes = {
  canCreate: PropTypes.bool,
  canUpdate: PropTypes.bool,
  eoCampaignOptions: eoCampaignOptionsShape,
  immutableBrandsOptions: optionsList,
  immutableEoActiveHourOptions: optionsList,
  immutableUsersOptions: optionsList,
  onDeleteCampaign: PropTypes.func,
  onUpdateSettings: PropTypes.func,
};

export default EoCampaignOptionsComponent;
