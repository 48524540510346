import moment from 'moment';
import defaultMetricsParams from 'utils/routeUtils';

export function historyPusherService(history, brandId, targetAppModule, canUseMetrics) {
  switch (targetAppModule) {
    case 'atp':
      history.push(Routes.brand_brand_pages_path(brandId, { format: null }));
      break;
    case 'backlinks':
      history.push(
        Routes.backlink_path(brandId, {
          ...defaultMetricsParams(canUseMetrics),
        }),
      );
      break;
    case 'backlinks_new_links':
      history.push(
        Routes.new_links_backlink_path(brandId, {
          format: null,
          filters: {
            published_date_beginning_of_day_gteq: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            published_date_end_of_day_lteq: moment().format('YYYY-MM-DD'),
            not_a_double: true,
          },
          sorting: { sortBy: 'firstSeen', sortingOrder: 'desc' },
        }),
      );
      break;
    case 'backlinks_lost_links':
      history.push(
        Routes.lost_links_backlink_path(brandId, {
          format: null,
          filters: {
            lost_during: [
              moment().subtract(7, 'days').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
            ],
          },
        }),
      );
      break;
    case 'backlinks_re_discovered_links':
      history.push(
        Routes.re_discovered_links_backlink_path(brandId, {
          format: null,
          filters: {
            re_discovered_during: [
              moment().subtract(7, 'days').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
            ],
          },
        }),
      );
      break;
    case 'changesBacklink':
      history.push(Routes.changes_backlink_path(brandId, { format: null }));
      break;
    case 'backlinksSetup':
      history.push(Routes.import_backlink_path(brandId, { format: null }));
      break;
    case 'brandDashboard':
      history.push(Routes.dashboard_path(brandId, { format: null }));
      break;
    case 'bulkPagesSetup':
      history.push(Routes.bulk_pages_setup_path(brandId, { format: null, simpleMode: true }));
      break;
    case 'rankTracker':
      history.push(Routes.rank_tracker_root_path(brandId, { format: null }));
      break;
    case 'rankTrackerKeywords':
      history.push(Routes.edit_rank_tracker_keywords_path(brandId, { format: null }));
      break;
    case 'rankTrackerSetup':
      history.push(Routes.edit_rank_tracker_settings_path(brandId, { format: null }));
      break;
  }
}
