import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components_linkio/link';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

const AddCustomEoInboxButtonComponent = ({ allowedEoInboxesCount, canAddEoInboxes }) => {
  const explanationTooltip = {
    text: translate('eoInboxesSettings.inboxesLimitation')(allowedEoInboxesCount),
    placement: 'top',
    color: 'black',
  };

  return (
    <Link to={canAddEoInboxes && Routes.new_eo_inbox_path({ format: null })}>
      <ButtonComponent
        isDisabled={!canAddEoInboxes}
        isInitial
        isBlue
        className="add-custom-eo-inbox-button"
        tooltip={!canAddEoInboxes ? explanationTooltip : {}}
      >
        {translate('eoInboxesSettings.buttons.addCustomEoInbox')}
      </ButtonComponent>
    </Link>
  );
};

AddCustomEoInboxButtonComponent.propTypes = {
  allowedEoInboxesCount: PropTypes.number,
  canAddEoInboxes: PropTypes.bool,
};

export default AddCustomEoInboxButtonComponent;
