import { cloneDeep } from 'lodash';

import {
  addCSSClassToCellInTable,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
} from 'common/tables/utils';

import { optionValueOrValue } from 'components_linkio/Select/utils';

export function prospectsRemappingListReducer(state, action) {
  const { type, payload } = action;

  const newState = cloneDeep(state);

  switch (type) {
    case 'onActivate': {
      const { columnIndex, rowData, tableRef } = payload;

      const updatedRows = newState.rows;

      const index = updatedRows.findIndex(
        (row) => row.spreadsheetField === rowData.spreadsheetField,
      );

      addCSSClassToCellInTable(tableRef.current, index, columnIndex, TABLE_CELL_ACTIVE_CLASS);
      updatedRows[index].editing = columnIndex;

      break;
    }
    case 'onValue': {
      const { value, property, rowData, tableRef } = payload;
      const processedValue = optionValueOrValue(value);

      const updatedRows = newState.rows;

      const isAlreadySelected = updatedRows.some(
        ({ prospectAttr }) => prospectAttr === processedValue,
      );

      // If the prospect attribute is already selected, then drop it from the previous row
      // in order to not allow choose the same attribute twice
      if (isAlreadySelected) {
        const index = updatedRows.findIndex(({ prospectAttr }) => prospectAttr === processedValue);
        updatedRows[index].prospectAttr = null;
      }

      const attrHadError = !!newState.errors[processedValue];

      // If attribute had an error and now it is selected, then remove the error
      attrHadError && (newState.errors[processedValue] = '');

      removeCSSClassFromTableCells(tableRef.current, TABLE_CELL_ACTIVE_CLASS);

      const index = updatedRows.findIndex(
        (row) => row.spreadsheetField === rowData.spreadsheetField,
      );

      const clonedRow = cloneDeep(rowData);

      const valueHasChanged = clonedRow[property] !== processedValue;

      if (valueHasChanged) {
        clonedRow[property] = processedValue;
      }

      Reflect.deleteProperty(clonedRow, 'editing');

      updatedRows[index] = clonedRow;

      break;
    }
    case 'dropProspectAttr': {
      const { rowData } = payload;
      const clonedRow = cloneDeep(rowData);

      const updatedRows = newState.rows;

      const index = updatedRows.findIndex(
        (row) => row.spreadsheetField === rowData.spreadsheetField,
      );

      clonedRow.prospectAttr = null;
      updatedRows[index] = clonedRow;
      break;
    }
    case 'applyCreatedEoCustomField': {
      const { rowData, name } = payload;
      const clonedRow = cloneDeep(rowData);

      const updatedRows = newState.rows;

      const index = updatedRows.findIndex(
        (row) => row.spreadsheetField === rowData.spreadsheetField,
      );

      clonedRow.prospectAttr = name;
      updatedRows[index] = clonedRow;
      break;
    }
    case 'addError': {
      const { property, text } = payload;

      newState.errors[property] = text;
      break;
    }
    case 'setDuplicatesMode':
      newState.duplicatesMode = payload;
      break;
    default:
      newState[type] = payload;
      break;
  }

  return newState;
}

export function initState({ prospectAttrOptions = [], spreadsheetAttrs = {}, defaultEoDripListId }) {
  const unifyAttribute = (attr) => attr ? attr.replace(/[^A-Za-z0-9]+/g, '').toLowerCase() : '';

  const initialRows = Object.keys(spreadsheetAttrs).reduce((result, attribute) => {
    const processedSpreadsheetAttribute = unifyAttribute(attribute);

    const processedProspectAttr = (
      prospectAttrOptions
        .map(({ options }) => options)
        .flat()
        .find(({ value }) => {
          const processedValue = unifyAttribute(value);
          return (
            processedValue === processedSpreadsheetAttribute ||
            processedValue.includes(processedSpreadsheetAttribute) ||
            processedSpreadsheetAttribute.includes(processedValue)
          );
        }) || {}
    ).value;

    const prospectAttrIsAlreadyApplied = result.some(
      ({ prospectAttr }) => prospectAttr && prospectAttr === processedProspectAttr,
    );

    result.push({
      spreadsheetField: attribute,
      prospectAttr: prospectAttrIsAlreadyApplied ? null : processedProspectAttr, // Don't apply same prospect attribute twice
      spreadsheetFieldPreview: spreadsheetAttrs[attribute],
    });

    return result;
  }, []);

  return {
    rows: initialRows,
    eoDripListId: defaultEoDripListId,
    errors: {},
    isLoading: false,
    duplicatesMode: 'reject',
  };
}
