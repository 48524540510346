import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';
import { subscriptionShape } from 'common/prop_types_shapes';
import { currentSubscriptionSelector } from 'selectors/railsContextSelectors';
import { openSubscriptionLimitReachedPopup } from 'actions/popup_actions';

import RoboImage from 'common/img/linkio_robo.svg';

import './robo_subscription_status_component.scss';

const handleOpenPopupClick = (dispatch, options) => {
  return (event) => {
    event && event.preventDefault();
    dispatch(openSubscriptionLimitReachedPopup(options));
  };
};

const RoboSubscriptionStatusComponent = ({ currentSubscription, dispatch }) => {
  const subscriptionPlanTitle = currentSubscription.get('subscriptionPlanTitle');
  const subscriptionStatus = currentSubscription.get('status');
  const endsAt = currentSubscription.get('endsAt', '');
  const timeLeft = moment(endsAt).fromNow(true);
  const isTrial = subscriptionStatus === 'trialing';
  const isFree = subscriptionPlanTitle === 'Free';

  return (
    <div className="robo-subscription-status-component">
      {(isTrial || isFree) && (
        <div className="robo-subscription-status-component__container">
          <div className="robo-subscription-status-component__robo">
            <img src={RoboImage} />
          </div>
          <div className="robo-subscription-status-component__subscription-info">
            <a href="#" onClick={handleOpenPopupClick(dispatch, {})}>
              {translate('roboSubscriptionStatusComponent.ctaLinkText.upgradeSubscriptionPlan')}
            </a>
            <div className="robo-subscription-status-component__text-container">
              {translate('roboSubscriptionStatusComponent.subscription')}
              <div className="robo-subscription-status-component__text robo-subscription-status-component__text_bold">
                {translate('roboSubscriptionStatusComponent.subscriptionTitle')(
                  subscriptionPlanTitle,
                  isTrial,
                  isFree,
                )}
              </div>
              <div className="robo-subscription-status-component__text robo-subscription-status-component__text_bold">
                {isTrial && translate('roboSubscriptionStatusComponent.timeLeft')(timeLeft)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

RoboSubscriptionStatusComponent.propTypes = {
  currentSubscription: subscriptionShape.isRequired,
  dispatch: PropTypes.func,
};

function select(state, ownProps) {
  const currentSubscription = currentSubscriptionSelector(state, ownProps);

  return { currentSubscription };
}

export default ConnectStoreHOC(connect(select)(RoboSubscriptionStatusComponent));
