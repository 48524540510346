const FETCH_PERMISSIONS__START = 'FETCH_PERMISSIONS__START';
const FETCH_PERMISSIONS__SUCCESS = 'FETCH_PERMISSIONS__SUCCESS';
const FETCH_PERMISSIONS__FAILURE = 'FETCH_PERMISSIONS__FAILURE';
const UPDATE_USER_PERMISSIONS__START = 'UPDATE_USER_PERMISSIONS__START';
const UPDATE_USER_PERMISSIONS__SUCCESS = 'UPDATE_USER_PERMISSIONS__SUCCESS';
const UPDATE_USER_PERMISSIONS__FAILURE = 'UPDATE_USER_PERMISSIONS__FAILURE';


function fetchPermissionsStart() {
  return { type: FETCH_PERMISSIONS__START };
}

function fetchPermissionsSuccess(permissions) {
  return { type: FETCH_PERMISSIONS__SUCCESS, permissions };
}

function fetchPermissionsFailure(errors) {
  return { type: FETCH_PERMISSIONS__FAILURE, errors };
}

function updateUserPermissionsStart() {
  return { type: UPDATE_USER_PERMISSIONS__START };
}

function updateUserPermissionsSuccess(userPermissions) {
  return { type: UPDATE_USER_PERMISSIONS__SUCCESS, userPermissions };
}

function updateUserPermissionsFailure(errors) {
  return { type: UPDATE_USER_PERMISSIONS__FAILURE, errors };
}


export {
  FETCH_PERMISSIONS__START,
  FETCH_PERMISSIONS__SUCCESS,
  FETCH_PERMISSIONS__FAILURE,
  UPDATE_USER_PERMISSIONS__START,
  UPDATE_USER_PERMISSIONS__SUCCESS,
  UPDATE_USER_PERMISSIONS__FAILURE,
  fetchPermissionsStart,
  fetchPermissionsSuccess,
  fetchPermissionsFailure,
  updateUserPermissionsStart,
  updateUserPermissionsSuccess,
  updateUserPermissionsFailure,
};
