import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

/**
 * Decorator
 * Call React component "onClickOutside" method on click outside DOM element event
 * @param ComposedComponent
 * @returns {*}
 */
export default (ComposedComponent) => {
  return class ClickOutsideComponentDecorator extends Component {
    static propTypes = {
      isOnMousedown: PropTypes.bool,
    }

    componentDidMount() {
      // Some components need 'mousedown' listener in order to properly hide on outside clicking.
      // But react-select's Select working inproperly with 'mousedown', cause it closes before
      // the option was actually picked. So let's use 'mousedown' listener only when it's needed.
      this.props.isOnMousedown && document.addEventListener('mousedown', this.handleClickOutside);
      document.addEventListener('onclick', this.handleClickOutside);
      document.addEventListener('touchstart', this.handleClickOutside);
    }

    componentWillUnmount() {
      this.props.isOnMousedown && document.removeEventListener('mousedown', this.handleClickOutside);
      document.removeEventListener('onclick', this.handleClickOutside);
      document.removeEventListener('touchstart', this.handleClickOutside);
    }

    realComponent;

    handleClickOutside = (e) => {
      const component = this.realComponent;
      const el = ReactDOM.findDOMNode(component);
      const ours = component && component.coupled && component.coupled.length > 0 ? component.coupled.map((el) => ReactDOM.findDOMNode(el).contains(e.target)).reduce((prev, next) => prev || next) : false;
      if (el && !el.contains(e.target) && !ours) {
        component.onClickOutside && component.onClickOutside(e);
      }
    };

    render() {
      return <ComposedComponent ref={(el) => this.realComponent = el} {...this.props} />;
    }
  };
};
