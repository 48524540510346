import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EoCampaignPageComponent from './EoCampaignPageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { activeEoCampaignTabSelector, eoCampaignSelector } from 'selectors/eoCampaignsSelector';
import { activeEoCampaignTabString, eoCampaignShape } from 'common/propTypesShapes/eoCampaigns';
import { currentIdSelector } from 'selectors';
import { fetchEoCampaign } from 'api/eoCampaign';
import { fetchEoCampaignStepsPreview } from 'api/eoCampaignPreview';
import { fetchEoCampaignOptions } from 'api/eoCampaignOptions';
import { translate } from 'common/i18n';

import Breadcrumbs from 'components/NewBreadcrumbs';
import BreadcrumbsEoCampaignsSelect from 'components/NewBreadcrumbs/BreadcrumbsEoCampaignsSelect';

import { resetEoCampaignStepsPreviewOptions } from 'actions/eoCampaignPreviewActions';

const EoCampaignPageContainer = ({ dispatch, activeEoCampaignTab, eoCampaign, eoCampaignId }) => {
  React.useEffect(() => {
    fetchEoCampaign(dispatch, eoCampaignId);
    fetchEoCampaignOptions(dispatch, eoCampaignId);
    fetchEoCampaignStepsPreview(dispatch, eoCampaignId, { eoInboxId: null, prospectId: null });
  }, [dispatch, eoCampaignId]);

  React.useEffect(() => {
    return () => {
      dispatch(resetEoCampaignStepsPreviewOptions());
    };
  }, []);

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.LinkItem to={Routes.eo_campaigns_path({ format: null })}>
          {translate('eoCampaign.breadcrumbsTitle')}
        </Breadcrumbs.LinkItem>
        <BreadcrumbsEoCampaignsSelect activeEoCampaignTab={activeEoCampaignTab} />
      </Breadcrumbs>

      <EoCampaignPageComponent activeEoCampaignTab={activeEoCampaignTab} eoCampaign={eoCampaign} />
    </>
  );
};

EoCampaignPageContainer.propTypes = {
  activeEoCampaignTab: activeEoCampaignTabString,
  dispatch: PropTypes.func,
  eoCampaign: eoCampaignShape,
  eoCampaignId: PropTypes.string,
};

function select(state, ownProps) {
  const activeEoCampaignTab = activeEoCampaignTabSelector(state, ownProps);
  const eoCampaign = eoCampaignSelector(state, ownProps);
  const eoCampaignId = currentIdSelector(state, ownProps);

  return {
    activeEoCampaignTab,
    eoCampaign,
    eoCampaignId,
  };
}

export default ConnectStoreHOC(connect(select)(EoCampaignPageContainer));
