import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, cloneDeep } from 'lodash';

import ProspectFormPopupComponent from './ProspectFormPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { createProspect, updateProspect, fetchProspect } from 'api/prospects';
import { eoCustomFieldsList } from 'common/propTypesShapes/eoCustomFields';
import { eoCustomFieldsSelector } from 'selectors/eoCustomFieldsSelector';
import { fetchEoDripListsAsOptions } from 'api/eoDripList';
import { fetchEoCustomFields } from 'api/eoCustomField';
import { optionsList } from 'common/prop_types_shapes';
import { optionsSelector } from 'selectors';
import { prospectShape } from 'common/propTypesShapes/prospects';
import { updateEoProgressionSuccess } from 'actions/eoProgressionActions';
import { eoProgressionShape } from 'common/propTypesShapes/eoProgressions';

const ProspectFormPopupContainer = ({
  dispatch,
  eoDripListsOptions,
  eoCustomFields,
  eoProgression,
  onClose,
  onSubmit,
  prospectFromTable, // Comes from prospects tables
  prospectId, // Comes from other sources
}) => {
  const [isFetchingEoCustomFields, setIsFetchingEoCustomFields] = React.useState(false);
  const [prospect, setProspect] = React.useState(prospectFromTable);
  const isCreatingProspect = !prospect && !prospectId;

  React.useEffect(() => {
    async function fetchData() {
      fetchEoDripListsAsOptions(dispatch);

      if (isCreatingProspect) {
        setIsFetchingEoCustomFields(true);
        await fetchEoCustomFields(dispatch, {});
        setIsFetchingEoCustomFields(false);
      }

      prospectId && fetchProspect(dispatch, prospectId).then((resp) => setProspect(resp));
    }

    fetchData();
  }, [dispatch]);

  async function handleSave(prospectFormData) {
    const resp = isCreatingProspect
      ? await createProspect(dispatch, prospectFormData)
      : await updateProspect(dispatch, prospectFormData);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      return resp;
    }

    onSubmit();
    onClose();

    if (eoProgression) {
      const { firstName, lastName, email, domain, position } = resp;

      const updatedEoProgression = {
        ...cloneDeep(eoProgression),
        prospectName: `${firstName} ${lastName}`,
        prospectEmail: email,
        prospectEoDomain: domain,
        prospectPosition: position,
      };

      dispatch(updateEoProgressionSuccess(updatedEoProgression, eoProgression.eoCampaignId));
    }

    return resp;
  }

  const isFetchingProspect = prospectId && !prospect;

  return (
    <>
      {!isFetchingProspect && (
        <ProspectFormPopupComponent
          immutableEoDripListsOptions={eoDripListsOptions}
          prospect={prospect}
          onCancel={onClose}
          onSave={handleSave}
          immutableEoCustomFields={eoCustomFields}
          isFetchingEoCustomFields={isFetchingEoCustomFields}
        />
      )}
    </>
  );
};

ProspectFormPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCustomFields: eoCustomFieldsList,
  eoDripListsOptions: optionsList,
  eoProgression: eoProgressionShape,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  prospectFromTable: prospectShape,
  prospectId: PropTypes.number,
};

function select(state, ownProps) {
  const eoCustomFields = eoCustomFieldsSelector(state);

  const options = optionsSelector(state);
  const eoDripListsOptions = options.get('eoDripListsOptions');

  const tableRowData = ownProps.tableRowData;

  if (!tableRowData) {
    return {
      eoDripListsOptions,
      eoCustomFields,
    };
  }

  const appModule = tableRowData.stage ? 'progressions' : 'prospects';

  if (appModule === 'progressions') {
    return {
      eoDripListsOptions,
      eoCustomFields,
      prospectId: tableRowData.prospectId,
      eoProgression: tableRowData,
    };
  }

  return {
    eoDripListsOptions,
    eoCustomFields,
    prospectFromTable: tableRowData,
  };
}

export default ConnectStoreHOC(connect(select)(ProspectFormPopupContainer));
