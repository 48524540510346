import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PagesExportToCsvPopupComponent from './PagesExportToCsvPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { pagesByParentSelector, visibleBrandPageSelector } from 'selectors';
import { simpleBrandsSelector } from 'selectors/simpleBrandSelectors';
import {
  currentSubscriptionSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import {
  brandPageShape,
  brandPagesMap,
  subscriptionPoliciesShape,
  subscriptionShape,
} from 'common/prop_types_shapes';
import { simpleBrandsMap } from 'common/propTypesShapes/simpleBrands';

import { closePagesExportToCsvPopup } from 'actions/popup_actions';
import { fetchCurrentSubscription } from 'api/subscription';
import { startMouseFlowPageView, trackHelpcrunchEvent } from 'common/utils';

import exportPagesToCsvServise from 'pages/Pages/services/exportPagesToCsvService';

const PagesExportToCsvPopupContainer = ({
  brandPage,
  currentSubscription,
  dispatch,
  pageChildType,
  pageParentId,
  pageParentType,
  pages,
  simpleBrands,
  subscriptionPolicies,
}) => {
  React.useEffect(() => {
    fetchCurrentSubscription(dispatch);
    startMouseFlowPageView(document.location.pathname);
  }, []);

  function handleCancel() {
    dispatch(closePagesExportToCsvPopup());
  }

  function handleSubmit() {
    trackHelpcrunchEvent(`export.${pageChildType}.csv`);

    exportPagesToCsvServise(
      {
        currentSubscription,
        dispatch,
        pageChildType,
        pageParentType,
        subscriptionPolicies,
      },
      pages,
    );
  }

  function pageParentLink() {
    if (pageParentType === 'brand') {
      const brand = simpleBrands.get(String(pageParentId));
      return brand.get('website');
    }

    return brandPage.get('link');
  }

  const canExportCsvInSubscription = subscriptionPolicies.getIn(['exportCsv', 'canUse']);

  return (
    <PagesExportToCsvPopupComponent
      pageParentLink={pageParentLink()}
      pages={pages}
      canExportCsvInSubscription={canExportCsvInSubscription}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      pageParentId={pageParentId}
      pageParentType={pageParentType}
    />
  );
};

PagesExportToCsvPopupContainer.propTypes = {
  brandPage: brandPageShape,
  currentSubscription: subscriptionShape,
  dispatch: PropTypes.func,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageParentType: PropTypes.oneOf(['brand_page', 'brand']).isRequired,
  pages: brandPagesMap,
  simpleBrands: simpleBrandsMap,
  subscriptionPolicies: subscriptionPoliciesShape,
};

function select(state, ownProps) {
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const pages = pagesByParentSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);
  const simpleBrands = simpleBrandsSelector(state, ownProps);
  const brandPage = visibleBrandPageSelector(state, ownProps);

  return {
    currentSubscription,
    pages,
    subscriptionPolicies,
    simpleBrands,
    brandPage,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(PagesExportToCsvPopupContainer)));
