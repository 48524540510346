import React from 'react';
import PropTypes from 'prop-types';

import EoDomainsTable from './EoDomainsTable';
import EoDomainsBulkActions from './EoDomainsBulkActions';
import ProspectTabs from 'pages/ProspectsPage/ProspectTabs';

import { pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';
const EoDomainsComponent = ({
  canCreate,
  onItemsSelectChange,
  onPagesPagerClick,
  eoDomainsPagy,
}) => {
  return (
    <div className="eo-domains">
      <ProspectTabs />

      <EoDomainsBulkActions canCreate={canCreate} />

      <EoDomainsTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="rowsPager"
        pagy={eoDomainsPagy}
      />
    </div>
  );
};

EoDomainsComponent.propTypes = {
  canCreate: PropTypes.bool,
  eoDomainsPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoDomainsComponent;
