import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components_linkio/input_component';

import CalendarIcon from 'common/icons/calendar';


const DateInputComponent = ({ value, onChange, onDateChange, ...props }) => {
  return (
    <Input
      icon={CalendarIcon}
      onBlur={onDateChange}
      onSend={onDateChange}
      onInputChange={onChange}
      value={value}
      {...props}
    />
  );
};

DateInputComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default DateInputComponent;
