import React from 'react';
import { Map as iMap } from 'immutable';

import { messagesMap } from '../../common/prop_types_shapes';

import BalloonComponent from '../balloon_component';

import './notification_component.scss';

const NotificationComponent = ({ messages }) => {
  if (messages.size === 0) {
    return null;
  }

  return (
    <div className="notification-component">
      {messages.map((messageObj) => {
        const color = messageObj.get('type') === 'error' ? 'red' : (messageObj.get('type') === 'warning' ? 'blue' : 'green');
        const message = iMap.isMap(messageObj.get('message')) ?
          messageObj.get('message').toJS()
          :
          messageObj.get('message');
        return (
          <BalloonComponent
            key={messageObj.get('id')}
            color={color}
            onClose={messageObj.get('onClose')}
          >
            {message}
          </BalloonComponent>
        );
      }).valueSeq().toJS()}
    </div>
  );
};

NotificationComponent.propTypes = {
  messages: messagesMap,
};

NotificationComponent.defaultProps = {
  messages: iMap(),
};

export default NotificationComponent;
