import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './container_with_scroll.scss';

const ContainerWithScrollComponent = ({ children, isRelative }) => {
  const componentClasses = classnames({
    "container-with-scroll-component": true,
    "container-with-scroll-component_is-relative": isRelative,
  });

  return <div className={componentClasses}>{children}</div>;
};

ContainerWithScrollComponent.propTypes = {
  children: PropTypes.node,
  isRelative: PropTypes.bool,
};

ContainerWithScrollComponent.defaultProps = {
  isRelative: true,
};

export default ContainerWithScrollComponent;
