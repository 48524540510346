import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isInteger } from 'lodash';

import { smartFetchPageDlsService } from './services/smartFetchPageDlsService';

import PageDlsComponent from './PageDlsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import {
  activeAtpTableTabSelector,
  atpPagyByBrandPageIdSelector,
  destinationLinksByBrandPageIdSelector,
  uiSelector,
  visiblePageIdByParentSelector,
} from 'selectors';

import { destinationLinksMap, pagyShape, policiesShape } from 'common/prop_types_shapes';
import { fetchPagePercentage } from 'api/brand_page';
import { policiesSelector } from 'selectors/railsContextSelectors';

import scrollToOpenedCompetitorPage from 'pages/Pages/utils/scrollToOpenedCompetitorPage';

const PageDlsContainer = ({
  activeAtpTableTab,
  atpPagy,
  changeQueryParams,
  destinationLinks,
  dispatch,
  policies,
  queryParams,
  showAnchorProgressPane,
  visiblePageId,
}) => {
  const {
    dlsGroupMode = '',
    metricsFilter,
    pageNumber = 1,
    pagyItemsCount = 50,
    sorting = {},
  } = queryParams;

  React.useEffect(() => {
    scrollToOpenedCompetitorPage();

    fetchPagePercentage(dispatch, visiblePageId);
  }, []);

  React.useEffect(() => {
    function fetchPages() {
      const dlsFetchingConfig = {
        activeAtpTableTab,
        activeMetric: metricsFilter,
        dlsGroupMode,
        pageNumber,
        pagyItemsCount,
        sorting,
      };

      smartFetchPageDlsService(dispatch, visiblePageId, dlsFetchingConfig);
    }

    fetchPages();
  }, [JSON.stringify(queryParams)]);

  const handleAtpPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleAtpPagerItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <PageDlsComponent
      atpPagy={atpPagy}
      showAnchorProgressPane={showAnchorProgressPane}
      destinationLinks={destinationLinks}
      onAtpPagerClick={handleAtpPagerClick}
      onAtpPagerItemsSelectChange={handleAtpPagerItemsSelectChange}
      policies={policies}
    />
  );
};

PageDlsContainer.propTypes = {
  activeAtpTableTab: PropTypes.string,
  atpPagy: pagyShape,
  destinationLinks: destinationLinksMap,
  dispatch: PropTypes.func,
  policies: policiesShape,
  showAnchorProgressPane: PropTypes.bool,
  visiblePageId: PropTypes.string,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const activeAtpTableTab = activeAtpTableTabSelector(state, ownProps);
  const atpPagy = atpPagyByBrandPageIdSelector(state, ownProps);
  const destinationLinks = destinationLinksByBrandPageIdSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);
  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);

  const uiStates = uiSelector(state);
  const showAnchorProgressPane = uiStates.getIn([
    'openClose',
    'brand_page',
    'anchorProgressPaneIsVisible',
  ]);

  return {
    activeAtpTableTab,
    atpPagy,
    destinationLinks,
    policies,
    showAnchorProgressPane,
    visiblePageId,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(PageDlsContainer)));
