import React from 'react';
import PropTypes from 'prop-types';

import EoCampaignsTable from './EoCampaignsTable';
import EoCampaignsBulkActions from './EoCampaignsBulkActions';
import EoCampaignsTabs from './EoCampaignsTabs';

import { pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';

const EoCampaignsComponent = ({
  canCreate,
  onItemsSelectChange,
  onPagesPagerClick,
  eoCampaignsPagy,
}) => {
  return (
    <div className="eoCampaigns">
      <EoCampaignsTabs />

      <EoCampaignsBulkActions canCreate={canCreate} />

      <EoCampaignsTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="rowsPager"
        pagy={eoCampaignsPagy}
      />
    </div>
  );
};

EoCampaignsComponent.propTypes = {
  canCreate: PropTypes.bool,
  eoCampaignsPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoCampaignsComponent;
