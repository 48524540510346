import { fromJS, Map as iMap } from 'immutable';

import { FETCH_ONBOARDING_STATUS__SUCCESS } from '../actions/onboardingStatusActions';

import { camelizeObjectDeep } from './utils';

const initialState = iMap();

export default function onboardingStatusReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ONBOARDING_STATUS__SUCCESS: {
      state = fromJS(camelizeObjectDeep(action.onboardingStatus));

      break;
    }
  }

  return state;
}
