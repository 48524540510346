import {
  showErrorMessageFromTranslation,
} from '../../common/utils';

import {
  reDistributeDestinationLinksSuccess,
} from '../../actions/brand_page_actions';

const handleBrandPageDlsRedistributingStatus = (dispatch, data) => {
  const {
    brandPage,
    brandPageDlsRedistributingStatus,
  } = data;

  switch (brandPageDlsRedistributingStatus) {
    case 'finished':
      dispatch(reDistributeDestinationLinksSuccess(brandPage));
      break;
    case 'failure':
      showErrorMessageFromTranslation(
        dispatch,
        'errors.cantRedistributeDestinationLinks'
      );
      break;
  }
};

export default handleBrandPageDlsRedistributingStatus;
