import {
  handleAPIError,
  patchToAPI,
  getFromAPI,
  processObjToCamelCase,
  processObjToSnakeCase,
} from './utils';

import {
  fetchNewsletterSettingsFailure,
  fetchNewsletterSettingsStart,
  fetchNewsletterSettingsSuccess,
  updateNewsletterSettingsFailure,
} from 'actions/newsletterSettingsActions';

import {
  newsletterSettingsUnsubscribeFailure,
  newsletterSettingsUnsubscribeStart,
  newsletterSettingsUnsubscribeSuccess,
} from 'actions/newsletterSettingsUnsubscribeActions';

import { showSuccessMessage } from 'common/utils';
import { translate } from 'common/i18n';

async function fetchNewsletterSettings(dispatch, encodedUserId, encodedMarketerId) {
  dispatch(fetchNewsletterSettingsStart());
  try {
    const resp = await getFromAPI(Routes.newsletter_settings_path(), {
      encoded_user_id: encodedUserId,
      encoded_marketer_id: encodedMarketerId,
    });

    const newsletterSettings = processObjToCamelCase(resp);
    dispatch(fetchNewsletterSettingsSuccess(newsletterSettings));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchNewsletterSettingsFailure,
      translate('errors.cantFetchNewsletterSettings'),
    );
  }
}

async function updateNewsletterSettings(dispatch, settings) {
  try {
    const resp = await patchToAPI(Routes.newsletter_settings_path(), {
      newsletter_setting: processObjToSnakeCase(settings),
    });

    showSuccessMessage(dispatch, translate('newsletterSettings.messages.updateSuccess'));

    const newsletterSettings = processObjToCamelCase(resp);

    dispatch(fetchNewsletterSettingsSuccess(newsletterSettings));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      updateNewsletterSettingsFailure,
      translate('errors.cantUpdateNewsletterSettings'),
    );
  }
}

export async function newsletterSettingsUnsubscribe(dispatch, encodedUserId, encodedMarketerId) {
  dispatch(newsletterSettingsUnsubscribeStart());
  try {
    const resp = await patchToAPI(Routes.unsubscribe_result_newsletter_settings_path(), {
      encoded_user_id: encodedUserId,
      encoded_marketer_id: encodedMarketerId,
    });

    showSuccessMessage(dispatch, translate('newsletterSettings.unsubscribe.messages.unsubscribeSuccess'));

    const newsletterSettings = processObjToCamelCase(resp);
    dispatch(newsletterSettingsUnsubscribeSuccess(newsletterSettings));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      newsletterSettingsUnsubscribeFailure,
      translate('newsletterSettings.unsubscribe.messages.unsubscribeFailure'),
    );
  }
}

export { fetchNewsletterSettings, updateNewsletterSettings };
