import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import OverlayWithTooltipComponent from 'components_linkio/overlay_with_tooltip_component';

import './metricsPanelItemComponent.scss';

const MetricsPanelItemComponent = ({ item, isActive, isFetching }) => {
  const { onClick: handleClick } = item;

  const classes = cx({
    'metrics-panel-item': true,
    'metrics-panel-item_active': isActive,
    'metrics-panel-item_clickable': !!item.onClick,
    'metrics-panel-item_not-clickable': isFetching,
  });

  return (
    <div className={classes} onClick={handleClick}>
      <div className="metrics-panel-item__title">
        {item.tooltip ? (
          <OverlayWithTooltipComponent
            isCopyable
            tooltip={{ text: item.tooltip, placement: 'top' }}
          >
            <span>{item.title}</span>
          </OverlayWithTooltipComponent>
        ) : (
          item.title
        )}
      </div>
      <div className="metrics-panel-item__value">{item.value}</div>
      <div className="metrics-panel-item__plus-value">{item.plus}</div>
      <div className="metrics-panel-item__minus-value">{item.minus}</div>
    </div>
  );
};

MetricsPanelItemComponent.propTypes = {
  isActive: PropTypes.bool,
  isFetching: PropTypes.bool,
  item: PropTypes.shape({
    minus: PropTypes.string,
    onClick: PropTypes.func,
    plus: PropTypes.string,
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default MetricsPanelItemComponent;
