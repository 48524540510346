import React, { Component } from 'react';
import { isEmpty, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { signUpAsUser } from 'api/user';
import { clearErrors, validateSignUpForm, trackFirstPromoterSignup } from 'common/utils';
import { optionsList } from 'common/prop_types_shapes';
import { optionsSelector } from 'selectors';
import { fetchTimezonesAsOptions } from 'api/eoScheduler';

import SignUpFormComponent from 'components/auth/sign_up_form_component';

import { getCurrentTimezoneNameOrSimilarBySameOffset } from 'utils/timezoneHelpers';

class SignUpPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    timezonesOptions: optionsList,
  };
  constructor(props) {
    super(props);

    const processedProps = this.processProps();

    this.state = {
      error: null,
      errors: {},
      processedProps,
      showMessage: false,
    };
  }

  componentDidMount() {
    const { dispatch, timezonesOptions } = this.props;

    timezonesOptions.size === 0 && fetchTimezonesAsOptions(dispatch);
  }

  handleSignUpAction = async (
    email,
    marketerTitle,
    password,
    passwordConfirmation,
    acceptedTermsAndConditions,
  ) => {
    this.setState(clearErrors(this.state));
    const validationErrors = validateSignUpForm({
      email,
      marketerTitle,
      password,
      passwordConfirmation,
    });

    if (!isEmpty(validationErrors)) {
      this.setState({ errors: validationErrors });
      return;
    }

    const { dispatch, timezonesOptions } = this.props;
    const formData = new FormData();

    const timezone = getCurrentTimezoneNameOrSimilarBySameOffset(timezonesOptions.toJS());

    formData.append('user[email]', email);
    formData.append('user[marketer_title]', marketerTitle);
    formData.append('user[password]', password);
    formData.append('user[password_confirmation]', passwordConfirmation);
    formData.append('user[timezone]', timezone);
    formData.append('user[accepted]', acceptedTermsAndConditions);

    const resp = await signUpAsUser(dispatch, formData);
    const { type, errors, message } = resp;

    if (type === 'error' || !isEmpty(errors)) {
      this.setState({ error: message, errors });
    } else {
      trackFirstPromoterSignup(email);
      this.setState({ showMessage: true });
    }
  };

  processProps = () => {
    const processedProps = cloneDeep(this.props);

    processedProps.handleSignUpClickAction = this.handleSignUpAction;

    return processedProps;
  };

  render() {
    const { processedProps, errors, showMessage, error } = this.state;

    return (
      <SignUpFormComponent
        {...processedProps}
        errors={errors}
        error={error}
        showMessage={showMessage}
      />
    );
  }
}

function select(state) {
  const options = optionsSelector(state);
  const timezonesOptions = options.get('timezonesOptions');

  return {
    timezonesOptions,
  };
}

export default ConnectStoreHOC(connect(select)(SignUpPage));
