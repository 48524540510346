import React from 'react';
import { optionValueOrValue } from 'components_linkio/Select/utils';
import { translate } from 'common/i18n';

export const prospectAttrCellValueFormatter = (prospectAttrOptions) => (value) => {
  if (!value) {
    return (
      <span className="prospects-remapping-table__do-not-import">
        {translate('prospects.importProspectsPopup.remappingCsv.table.doNotImport')}
      </span>
    );
  }

  const processedValue = optionValueOrValue(value);
  const selectedOption = prospectAttrOptions
    .map(({ options }) => options)
    .flat()
    .find((option) => option.value === processedValue);
  const label = (selectedOption || {}).label;

  return label;
};
