import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import DlDetailsPopupComponent from './DlDetailsPopupComponent';


import ConnectStoreHOC from 'startup/connect_store_hoc';

import clickUpgradeSubscriptionService from 'pages/Pages/services/clickUpgradeSubscriptionService';
import { closeDlDetailsPopup } from 'actions/popup_actions';
import { fetchCurrentSubscription } from 'api/subscription';
import { updateDl } from 'api/destination_links';
import { fetchDlDuplicates } from 'api/dl_duplicates';
import { enqueueDestinationLinkCrawling } from 'api/crawler';
import { trackHelpcrunchEvent } from 'common/utils';

import {
  destinationLinkShape,
  subscriptionPoliciesShape,
  subscriptionShape,
} from 'common/prop_types_shapes';

import { destinationLinkByIdSelector } from 'selectors';

import {
  currentSubscriptionSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

class DlDetailsPopupContainer extends React.Component {
  static propTypes = {
    currentSubscription: subscriptionShape,
    destinationLink: destinationLinkShape,
    destinationLinkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    subscriptionPolicies: subscriptionPoliciesShape,
  };

  constructor(props) {
    super(props);
    const { destinationLink } = props;

    this.state = {
      publishedLink: destinationLink.get('publishedLink'),
      showDuplicatesTable: false,
    };
  }

  componentDidMount() {
    fetchCurrentSubscription(this.props.dispatch);
  }

  handleClickUpgradeSubscription = (event) => {
    const { currentSubscription, dispatch } = this.props;
    clickUpgradeSubscriptionService({ currentSubscription, dispatch }, event);
  };

  handleCloseClick = () => {
    const { dispatch, destinationLink } = this.props;
    const { publishedLink } = this.state;

    if (publishedLink === destinationLink.get('publishedLink')) {
      this.closePopup();
      return;
    }

    const oldDl = destinationLink.set('publishedLink', publishedLink).toJS();
    const brandPageId = destinationLink.get('brandPageId');

    updateDl(dispatch, brandPageId, [oldDl]);

    this.closePopup();
  };

  closePopup = () => this.props.dispatch(closeDlDetailsPopup());

  handleSaveClick = async ({ triggeredByCrawlNow = false }) => {
    const { dispatch, destinationLink } = this.props;
    const { publishedLink } = this.state;

    if (this.publishedLinkHasChanged()) {
      const newDl = destinationLink.set('publishedLink', publishedLink).toJS();
      const brandPageId = destinationLink.get('brandPageId');

      const resp = await updateDl(dispatch, brandPageId, [newDl]);

      if ((resp || {}).type === 'error' || !isEmpty(resp[0].errors)) {
        return;
      }
    }

    // trigger crawling of DL in order to handle 'saved by clicking Crawl Now btn' use case
    if (triggeredByCrawlNow) {
      this.handleCrawlNowClick();
      return;
    }

    this.closePopup();
  };

  handlePublishedLinkChange = (event) => {
    this.setState({ publishedLink: ((event || {}).target || {}).value });
  };

  // WARN: CrawlNow button must be able to crawl excluded dls
  handleCrawlNowClick = async () => {
    const {
      currentSubscription,
      destinationLink,
      dispatch,
      pageChildType,
      subscriptionPolicies,
    } = this.props;

    trackHelpcrunchEvent(`click.crawl.${pageChildType}`);

    // trigger saving of DL in order to avoid invalid links crawling
    if (this.publishedLinkHasChanged()) {
      return this.handleSaveClick({ triggeredByCrawlNow: true });
    }

    const canUseCrawlingInSubscription = subscriptionPolicies.getIn(['crawlers', 'canUse']);
    if (canUseCrawlingInSubscription) {
      return await enqueueDestinationLinkCrawling(
        dispatch,
        destinationLink.get('brandPageId'),
        destinationLink.get('id'),
      );
    }

    return clickUpgradeSubscriptionService({ currentSubscription, dispatch });
  };

  publishedLinkHasChanged = () => {
    const { destinationLink } = this.props;
    const { publishedLink } = this.state;

    return publishedLink !== destinationLink.get('publishedLink');
  };

  handleShowDuplicateLinksClick = () => {
    const { showDuplicatesTable } = this.state;

    if (!showDuplicatesTable) {
      const { dispatch, destinationLink, destinationLinkId } = this.props;
      const brandPageId = destinationLink.get('brandPageId');

      fetchDlDuplicates(dispatch, brandPageId, destinationLinkId);
    }

    this.setState({ showDuplicatesTable: !showDuplicatesTable });
  };

  handleUpdateDl = async (dl) => {
    const { dispatch } = this.props;
    const { brandPageId } = dl;

    await updateDl(dispatch, brandPageId, [dl]);
  };

  render() {
    const { destinationLink, subscriptionPolicies } = this.props;

    const { publishedLink, showDuplicatesTable } = this.state;

    return (
      <DlDetailsPopupComponent
        destinationLink={destinationLink}
        onClickUpgradeSubscription={this.handleClickUpgradeSubscription}
        onCloseClick={this.handleCloseClick}
        onCrawlNowClick={this.handleCrawlNowClick}
        onPublishedLinkChange={this.handlePublishedLinkChange}
        onSaveClick={this.handleSaveClick}
        onShowDuplicateLinksClick={this.handleShowDuplicateLinksClick}
        publishedLink={publishedLink}
        showDuplicatesTable={showDuplicatesTable}
        subscriptionPolicies={subscriptionPolicies}
      />
    );
  }
}

function select(state, ownProps) {
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const destinationLink = destinationLinkByIdSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  return {
    currentSubscription,
    destinationLink,
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(DlDetailsPopupContainer));
