import React from 'react';
import PropTypes from 'prop-types';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';
import BrandPagesListComponent from 'components/atp/brand_pages_list_component';
import Breadcrumbs from 'components/NewBreadcrumbs';
import BreadcrumbsBrandsSelect from 'components/NewBreadcrumbs/BreadcrumbsBrandsSelect';
import ShowAtpSuggestionsSwitcher from 'components/atp/show_atp_suggestions_switcher';
import StepsComponent from 'components/steps_component';

import {
  brandContextShape,
  brandPagesMap,
  historyShape,
  marketerShape,
  matchShape,
  optionsMap,
  policiesShape,
  subscriptionShape,
} from 'common/prop_types_shapes';

import { openChangePercentagesPopup } from 'actions/popup_actions';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import PageLayoutHeader from 'components_linkio/PageLayoutHeader';
import PagesBulkActions from 'pages/Pages/PagesBulkActions';
import TutorialPopup from 'components_linkio/TutorialPopup';

import './atp.scss';

const AtpContainer = (props) => {
  const {
    brand,
    brandId,
    brandPages,
    dispatch,
    history,
    isDataFetching,
    match,
    onCloseBrandInfoSteps,
    policies,
    queryParams,
    changeQueryParams,
  } = props;

  React.useEffect(() => {
    const { id, thingToOpen } = match.params;

    if (thingToOpen === 'choose_ideal_anchor_text_ratio') {
      dispatch(
        openChangePercentagesPopup({
          pageChildType: 'brandPage',
          pageParentId: Number(id),
          pageParentType: 'brand',
        }),
      );
    }
  }, []);

  function handleCreateNewClick() {
    history.push(Routes.bulk_pages_setup_path(props.brandId, { format: null, simpleMode: true }));
  }

  function handleClickShowAtpSuggestionsSwitcher(value) {
    changeQueryParams({
      ...queryParams,
      pagesFilter: {
        ...queryParams.pagesFilter,
        showAtpSuggestions: value === 'right',
      },
    });
  }

  function rightSideBtn({ withSwitcher }) {
    const showAtpSuggestions = (queryParams.pagesFilter || {}).showAtpSuggestions === 'true';
    const canCreate = policies.getIn(['atp', 'canCreate']);
    return (
      <>
        {withSwitcher && (
          <ShowAtpSuggestionsSwitcher
            onClick={handleClickShowAtpSuggestionsSwitcher}
            showAtpSuggestions={showAtpSuggestions}
          />
        )}
        {canCreate && (
          <ButtonComponent isGreen isInitial onClick={handleCreateNewClick}>
            {translate('brandPage.buttons.addPageAndGenerateRoadmap')}
          </ButtonComponent>
        )}
      </>
    );
  }

  const stepsItems = [
    {
      number: 1,
      title: translate('stepsComponent.importStep.title'),
      body: translate('stepsComponent.importStep.body'),
    },
    {
      number: 2,
      title: translate('stepsComponent.categorizeStep.title'),
      body: translate('stepsComponent.categorizeStep.body'),
    },
    {
      number: 3,
      title: translate('stepsComponent.percentagesStep.title'),
      body: translate('stepsComponent.percentagesStep.body'),
    },
    {
      number: 4,
      title: translate('stepsComponent.getSuggestionsStep.title'),
      body: translate('stepsComponent.getSuggestionsStep.body'),
    },
  ];

  const showInfoSteps = brand.get('showInfoSteps');

  const hasBrandPages = brandPages.size > 0;

  return (
    <>
      <Breadcrumbs rightSideBtn={rightSideBtn({ withSwitcher: true })}>
        <Breadcrumbs.TextItem>Anchor Text Planner</Breadcrumbs.TextItem>
        <BreadcrumbsBrandsSelect targetAppModule="atp" />
      </Breadcrumbs>
      <div className="atp page__wrapper">
        {hasBrandPages && showInfoSteps && (
          <StepsComponent items={stepsItems} onClose={onCloseBrandInfoSteps} />
        )}
        <PageLayoutHeader
          title={translate('anchorTextPlanner.titleLong')}
          Popup={
            <TutorialPopup
              title={translate('anchorTextPlanner.titleLong')}
              videoSrc="https://www.youtube.com/embed/8IB9fCWlK8Q"
            />
          }
        />
        <PagesBulkActions
          pageParentId={Number(brandId)}
          pageParentType={'brand'}
          pageChildType={'brandPage'}
        />
        {hasBrandPages && <BrandPagesListComponent {...props} />}
        {!isDataFetching && !hasBrandPages && (
          <BlankStateCtaComponent>
            <TitleText>{translate('brandPage.blankState.titleText')}</TitleText>
            <BodyText>
              {translate('brandPage.blankState.bodyText')}
              {rightSideBtn({})}
            </BodyText>
          </BlankStateCtaComponent>
        )}
      </div>
    </>
  );
};

AtpContainer.propTypes = {
  anchorTypeOptions: optionsMap,
  brand: brandContextShape,
  brandId: PropTypes.string,
  brandPages: brandPagesMap,
  currentMarketer: marketerShape,
  currentSubscription: subscriptionShape,
  dispatch: PropTypes.func,
  dlSourcesOptions: optionsMap,
  history: historyShape,
  includeByUserOptions: optionsMap,
  includedOptions: optionsMap,
  isDataFetching: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }),
  match: matchShape,
  nofollowOptions: optionsMap,
  onClickUpgradeSubscription: PropTypes.func,
  onCloseBrandInfoSteps: PropTypes.func,
  onCreateNewBrandPage: PropTypes.func,
  onFetchCurrentSubscription: PropTypes.func,
  onRecalculatePercentage: PropTypes.func,
  policies: policiesShape,
  statusOptions: optionsMap,
};

export default AtpContainer;
