import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import AutodiscoverPagesViaSitemapButton from './AutodiscoverPagesViaSitemapButton';
import ImportFromGSCButton from './ImportFromGSCButton';
import ManuallyImportPagesButton from './ManuallyImportPagesButton';
import { filtersSpec, defaultFilters } from './filtersSpec';

import { optionsArray } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import BackButton from 'components_linkio/BackButton';
import ButtonComponent from 'components_linkio/button_component';
import DataFilter from 'components_linkio/DataFilter';
import InfoCard from 'components_linkio/InfoCard';
import SearchInput from 'components/SearchInput';
import SpinnerComponent from 'components_linkio/Spinner';

import './bulkPagesSetupBulkActions.scss';

const BulkPagesSetupBulkActionsComponent = ({
  addingPagesToAtp,
  isBottom,
  onAddPagesToAtpClick,
  onSubmitSearch,
  pageStatusOptions,
  selectedPagesCount,
}) => {
  const buttonsPanelItemClasses = classnames({
    'bulk-pages-setup-bulk-actions__buttons-panel-item': true,
    'bulk-pages-setup-bulk-actions__buttons-panel-item_is-bottom': isBottom,
  });

  const btnGroupLeftClasses = classnames(
    'bulk-pages-setup-bulk-actions__btn-group',
    'bulk-pages-setup-bulk-actions__btn-group_left',
  );

  return (
    <div className="bulk-pages-setup-bulk-actions">
      {!isBottom && (
        <InfoCard>
          <InfoCard.Title>{translate('bulkPagesSetup.bulkActions.title')}</InfoCard.Title>
          {/* <InfoCard.Subtitle>{translate('bulkPagesSetup.bulkActions.subtitle')}</InfoCard.Subtitle> */}
          <InfoCard.BtnGroup>
            <ManuallyImportPagesButton />
            {/* <AutodiscoverPagesViaSitemapButton /> */}
            {/* <ImportFromGSCButton /> */}
          </InfoCard.BtnGroup>
        </InfoCard>
      )}

      <div className={buttonsPanelItemClasses}>
        {!isBottom && (
          <div className={buttonsPanelItemClasses}>
            <div className={btnGroupLeftClasses}>
              <SearchInput onSubmit={onSubmitSearch} />

              <DataFilter
                defaultFilters={defaultFilters({ pageStatusOptions })}
                filtersSpec={filtersSpec({ pageStatusOptions })}
              />
            </div>
          </div>
        )}

        <div className="bulk-pages-setup-bulk-actions__btn-group">
          <BackButton isWhite isInitial>
            {translate('bulkPagesSetup.bulkActions.buttons.cancel')}
          </BackButton>

          <ButtonComponent
            className="bulk-pages-setup-bulk-actions__add-to-atp-button"
            onClick={onAddPagesToAtpClick}
            isGreen
            isInitial
          >
            {addingPagesToAtp ? (
              <SpinnerComponent />
            ) : (
              translate('bulkPagesSetup.bulkActions.buttons.addToAtp')(selectedPagesCount)
            )}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

BulkPagesSetupBulkActionsComponent.propTypes = {
  addingPagesToAtp: PropTypes.bool,
  isBottom: PropTypes.bool,
  onAddPagesToAtpClick: PropTypes.func,
  onSubmitSearch: PropTypes.func.isRequired,
  pageStatusOptions: optionsArray,
  selectedPagesCount: PropTypes.number,
};

export default BulkPagesSetupBulkActionsComponent;
