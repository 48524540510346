import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Filters from './Filters';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

import FilterIcon from 'common/icons/filter_icon';

import './dataFilter.scss';

const DataFilterComponent = ({
  className,
  defaultFilters,
  eqIsDefaultPredicate,
  filters,
  filtersSpec,
  onButtonClickCb,
  onFilterQueryChangeCb,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <div className={className}>
        <ButtonComponent className={buttonClasses} isWhite isInitial isLoading />
      </div>
    );
  }

  const [isShowFilter, setShowFilter] = React.useState(false);
  const showFilter = () => setShowFilter(true);
  const hideFilter = () => setShowFilter(false);

  const onButtonClick = () => {
    showFilter();

    if (onButtonClickCb) {
      onButtonClickCb();
    }
  };

  const buttonClasses = cx('data-filter-button', {
    'data-filter-button_disabled': isShowFilter,
  });

  return (
    <div className={className}>
      <ButtonComponent className={buttonClasses} isWhite isInitial onClick={onButtonClick}>
        <span className="data-filter-button__icon-wrapper">
          <FilterIcon />
        </span>
        <span>
          {translate('uiComponents.dataFilters.buttons.filter')(Object.keys(filters).length)}
        </span>
      </ButtonComponent>

      {isShowFilter && filtersSpec && (
        <Filters
          defaultFilters={defaultFilters}
          eqIsDefaultPredicate={eqIsDefaultPredicate}
          onClickOutside={hideFilter}
          queryStringFilters={filters}
          filtersSpec={filtersSpec}
          onFilterQueryChange={onFilterQueryChangeCb}
        />
      )}
    </div>
  );
};

DataFilterComponent.propTypes = {
  className: PropTypes.string,
  defaultFilters: PropTypes.arrayOf(PropTypes.shape()),
  eqIsDefaultPredicate: PropTypes.bool,
  filters: PropTypes.shape(),
  filtersSpec: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool,
  onButtonClickCb: PropTypes.func,
  onFilterQueryChangeCb: PropTypes.func.isRequired,
};

export default DataFilterComponent;
