import { buildWordInPastTenseWithPluralization } from 'common/utils';

export default {
  eoActiveHour: {
    breadcrumbsTitle: 'Active Hours',
    bulkActions: {
      addEoActiveHour: 'Create New Active Hours',
    },
    messages: {
      cantFetchEoActiveHours: "Can't load Eo active hours",
      cantFetchEoActiveHour: "Can't load Eo active hour",
      cantCreateEoActiveHour: "Can't create Eo active hour",
      cantUpdateEoActiveHour: "Can't update Eo active hour",
      cantDeleteEoActiveHours: "Can't delete Eo active hour(s)",
      createSuccess: 'Active Hours were created successfully.',
      deleteSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization(
          'Active Hour',
          count,
        )} deleted successfully.`;
      },
      updateSuccess: 'Active Hours was updated successfully.',
    },
    eoActiveHourFormPopup: {
      buttons: {
        cancel: 'Cancel',
        submit: 'Save',
      },
      inputs: {
        name: {
          label: 'Active hours name',
        },
      },
      title: (isExistingEoActiveHour) =>
        isExistingEoActiveHour ? 'Update Active Hours' : 'Create Active Hours',
      sendingTimes: 'Sending times:',
    },
    table: {
      blankState: {
        body: 'You can manually add Active Hours',
        title: "You don't have any Active Hours yet",
      },
      headers: {
        name: 'Active hours name',
        eoActiveHoursSettings: 'Active days',
      },
    },
  },
};
