import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'lodash';

import EoCampaignStatusButtonComponent from './EoCampaignStatusButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector } from 'selectors';
import { eoCampaignOptionsSelector } from 'selectors/eoCampaignOptionsSelector';
import { eoCampaignSelector } from 'selectors/eoCampaignsSelector';
import { eoCampaignShape } from 'common/propTypesShapes/eoCampaigns';
import { translate } from 'common/i18n';
import { updateEoCampaignStatus } from 'api/eoCampaignOptions';

import confirmationDialogue from 'components/confirmation_dialogue';

const EoCampaignStatusButtonContainer = ({ dispatch, eoCampaignId, eoCampaign }) => {
  function handleUpdateCampaignStatus() {
    const status = eoCampaign.get('status');
    const updatedStatus = status === 'live' ? 'paused' : 'live';

    const updatedEoCampaign = eoCampaign.set('status', updatedStatus).toJS();

    function updateStatus() {
      updateEoCampaignStatus(dispatch, eoCampaignId, updatedStatus, updatedEoCampaign);
    }

    confirmationDialogue({
      body: translate('confirmations.default.title'),
      onConfirm: updateStatus,
    });
  }

  return (
    <EoCampaignStatusButtonComponent
      onUpdateCampaignStatus={handleUpdateCampaignStatus}
      immutableEoCampaign={eoCampaign}
    />
  );
};

EoCampaignStatusButtonContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaign: eoCampaignShape,
  eoCampaignId: PropTypes.string,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoCampaign = eoCampaignSelector(state, ownProps);
  const eoCampaignOptions = eoCampaignOptionsSelector(state);

  return {
    eoCampaign,
    eoCampaignId,
    eoCampaignOptions,
  };
}

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(EoCampaignStatusButtonContainer);
