import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';
import * as resolve from 'table-resolver';
import * as edit from 'react-edit';
import { cloneDeep, findIndex, get } from 'lodash';

import {
  buildSelectedItems,
  processAhrefsOrMozMetricValue,
  textTwoLinesWithEllipsis,
} from 'common/utils';
import { checkboxColumn, reactSelectColumn, textColumn } from 'common/tables/columns';
import { draftBrandPagesMap, optionsMap, selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import columnsGenerator, { columnSelect } from 'common/tables/columns_generator';

import { optionValueOrValue } from 'components_linkio/Select/utils';
import Checkbox from 'components_linkio/Checkbox';
import KeywordsValueFormatterComponent from 'common/tables/sharedComponents/KeywordsValueFormatter';
import Spinner from 'components_linkio/Spinner';
import TableLoadingWrapper from 'components_linkio/TableLoadingWrapper';

import './bulkPagesSetupTable.scss';

const BulkPagesSetupTableComponent = ({
  immutableDraftBrandPages,
  isLoading,
  onClickKeywordsCell,
  onToggleSelectedRows,
  onUpdateDraftBrandPage,
  onUpdateRows,
  pageTypeOptions,
  percentageSchemeOptions,
  selectedPagesIds,
}) => {
  const draftBrandPages = immutableDraftBrandPages.valueSeq().toJS();

  function notInAtpBrandPages() {
    return draftBrandPages.filter((brandPage) => brandPage.status === 'not_in_atp');
  }

  function isAllSelected() {
    const selectedRowsCount = draftBrandPages.filter((page) => page.selected).length;
    const rowsCount = notInAtpBrandPages().length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  }

  function handleOnToggleSelectAll(event) {
    const isSelectedAll = event.target.checked;

    const pagesIds = notInAtpBrandPages().map((page) => page.id);

    onToggleSelectedRows(pagesIds, isSelectedAll);
  }

  const handleOnToggleSelectRow = (pageId) => (event) => {
    const isSelected = event.target.checked;

    onToggleSelectedRows([pageId], isSelected);
  };

  function headerCheckboxFormatters() {
    return <Checkbox checked={isAllSelected()} onChange={handleOnToggleSelectAll} />;
  }

  function cellCheckboxFormatters(value, extra) {
    const {
      rowData: { selected, id, status },
    } = extra;

    return (
      <>
        {status === 'not_in_atp' && (
          <Checkbox checked={selected} onChange={handleOnToggleSelectRow(id)} />
        )}
      </>
    );
  }

  const selectProps = {
    blurInputOnSelect: true,
    autoFocus: true,
    isClearable: true,
    openMenuOnFocus: true,
    skinOptions: {
      inTable: true,
    },
  };

  function generateColumns(withAbility) {
    const columns = columnsGenerator([
      checkboxColumn({
        headerFormatters: [headerCheckboxFormatters],
        cellFormatters: [cellCheckboxFormatters],
        className: 'bulk-pages-setup-table__checkbox-column',
      }),
      textColumn({
        name: 'link',
        headerLabel: translate('bulkPagesSetup.table.columns.brandPages'),
        isLink: true,
        className: 'bulk-pages-setup-table__link-column',
      }),
      textColumn({
        explanationMessage: translate('explanationMessages.bulkPagesSetupTable.uriAhrefsRating'),
        name: 'metrics.uriAhrefsRating',
        headerLabel: translate('bulkPagesSetup.table.columns.metrics.uriAhrefsRating'),
        cellValueFormatter: metricsValueFormatter,
        showTooltip: true,
        className: 'bulk-pages-setup-table__metrics-uri-ahrefs-rating-column',
      }),
      textColumn({
        explanationMessage: translate('explanationMessages.bulkPagesSetupTable.uriRefdomainsCount'),
        name: 'metrics.uriRefdomainsCount',
        headerLabel: translate('bulkPagesSetup.table.columns.metrics.uriRefdomainsCount'),
        cellValueFormatter: metricsValueFormatter,
        showTooltip: true,
        className: 'bulk-pages-setup-table__metrics-domain-refdomains-count-column',
      }),
      reactSelectColumn({
        name: 'pageTypeId',
        headerLabel: translate('bulkPagesSetup.table.columns.pageType'),
        cellValueFormatter: pageTypeValueFormatter,
        cellTransforms: [columnSelect(withAbility, pageTypeOptions.toList().toJS(), selectProps)],
        className: 'bulk-pages-setup-table__page-type-column',
      }),
      textColumn({
        name: 'keywords',
        headerLabel: translate('bulkPagesSetup.table.columns.keywords'),
        cellValueFormatter: keywordsValueFormatter,
        className: 'bulk-pages-setup-table__keywords-column',
      }),
      reactSelectColumn({
        name: 'percentageSchemeId',
        headerLabel: translate('bulkPagesSetup.table.columns.idealPercentage'),
        cellValueFormatter: percentageSchemeValueFormatter,
        cellTransforms: [columnSelect(withAbility, percentageSchemeOptions, selectProps)],
        className: 'bulk-pages-setup-table__percentage-scheme-column',
        showTooltip: true,
      }),
      textColumn({
        name: 'status',
        headerLabel: translate('bulkPagesSetup.table.columns.status.title'),
        cellValueFormatter: statusValueFormatter,
        className: 'bulk-pages-setup-table__status-column',
      }),
    ]);

    return columns;
  }

  function keywordsValueFormatter(value, extra) {
    const { rowData } = extra;
    const keywords = value || [];

    const needToAutoSelectRow = !rowData.selected && rowData.status === 'not_in_atp';

    function onClick() {
      needToAutoSelectRow && onToggleSelectedRows([rowData.id], true);
      onClickKeywordsCell(rowData.id);
    }

    return <KeywordsValueFormatterComponent keywords={keywords} onClick={onClick} />;
  }

  function metricsValueFormatter(value, extra) {
    const ahrefsMetricsUploadingInProgress = get(
      extra,
      'rowData.ahrefsMetricsUploadingInProgress',
      false,
    );

    if (ahrefsMetricsUploadingInProgress) {
      return <Spinner isBlack />;
    }

    return processAhrefsOrMozMetricValue(value);
  }

  function pageTypeValueFormatter(_value, extra) {
    const {
      rowData: { pageTypeTitle },
    } = extra;

    return textTwoLinesWithEllipsis(pageTypeTitle);
  }

  function percentageSchemeValueFormatter(_value, extra) {
    const {
      rowData: { percentageSchemeTitle },
    } = extra;

    return textTwoLinesWithEllipsis(percentageSchemeTitle);
  }

  function statusValueFormatter(value) {
    const label = translate(`bulkPagesSetup.table.columns.status.${value}`);

    return textTwoLinesWithEllipsis(label);
  }

  const editable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowData }) => {
      const index = findIndex(draftBrandPages, { id: rowData.id });
      const clonedRows = cloneDeep(draftBrandPages);
      clonedRows[index].editing = columnIndex;

      onUpdateRows(clonedRows);
    },

    onValue: async ({ value, rowData, property }) => {
      const index = findIndex(draftBrandPages, { id: rowData.id });
      const currentValue = rowData[property] || '';
      const newValue = optionValueOrValue(value) || '';
      const clonedRow = cloneDeep(rowData);
      const clonedRows = cloneDeep(draftBrandPages);

      const keywordsAreEqual = property === 'keywords' && currentValue.join('\n') === newValue;
      const currentValueIsEqualNewValue =
        property !== 'keywords' && String(currentValue) === String(newValue);

      const needToUpdateDraftBrandPage = !keywordsAreEqual && !currentValueIsEqualNewValue;

      if (needToUpdateDraftBrandPage) {
        clonedRow[property] = newValue;

        // Auto-select row if it was edited and was not checked before
        const needToAutoSelectRow = !rowData.selected && rowData.status === 'not_in_atp';
        if (needToAutoSelectRow) {
          onToggleSelectedRows([rowData.id], true);
        }

        await onUpdateDraftBrandPage(clonedRow);
      } else {
        Reflect.deleteProperty(clonedRow, 'editing');
        clonedRows[index] = clonedRow;

        onUpdateRows(clonedRows);
      }
    },
  });

  const columns = generateColumns(editable);
  const rowsWithSelectedPages = buildSelectedItems(draftBrandPages, selectedPagesIds);
  const resolvedRows = resolve.resolve({ columns, method: resolve.nested })(rowsWithSelectedPages);

  return (
    <div className="bulk-pages-setup-table__wrapper">
      <TableLoadingWrapper
        isLoading={isLoading}
        loadingMessage={translate('bulkPagesSetup.table.dataNotReadyYet')}
      >
        <Table.Provider className="bulk-pages-setup-table__all" columns={columns}>
          <Table.Header />
          <Table.Body rowKey="id" rows={resolvedRows} />
        </Table.Provider>
      </TableLoadingWrapper>
    </div>
  );
};

BulkPagesSetupTableComponent.propTypes = {
  immutableDraftBrandPages: draftBrandPagesMap,
  isLoading: PropTypes.bool,
  onClickKeywordsCell: PropTypes.func,
  onToggleSelectedRows: PropTypes.func,
  onUpdateDraftBrandPage: PropTypes.func,
  onUpdateRows: PropTypes.func,
  pageTypeOptions: optionsMap,
  percentageSchemeOptions: optionsMap,
  selectedPagesIds: selectedIdsList,
};

export default BulkPagesSetupTableComponent;
