export const UI_SIDEBAR__TOGGLE = 'UI_SIDEBAR__TOGGLE';

export const RESET_LOADING_AND_CHANGING_STATUSES = 'RESET_LOADING_AND_CHANGING_STATUSES';
export const RESET_SELECTED_IDS = 'RESET_SELECTED_IDS';
export const TOGGLE_SELECTED_IDS = 'TOGGLE_SELECTED_IDS';
export const BREADCRUMBS_BRANDS_SELECT__TOGGLE = 'BREADCRUMBS_BRANDS_SELECT__TOGGLE';

export const uiSidebarToggle = () => ({ type: UI_SIDEBAR__TOGGLE });

export function toggleSelectedIds(selectedIds, isSelected) {
  return { type: TOGGLE_SELECTED_IDS, selectedIds, isSelected };
}

export function resetLoadingAndChangingStatuses() {
  return { type: RESET_LOADING_AND_CHANGING_STATUSES };
}

export function resetSelectedIds() {
  return { type: RESET_SELECTED_IDS };
}

export function breadcrumbsBrandsSelectToggle() {
  return { type: BREADCRUMBS_BRANDS_SELECT__TOGGLE };
}
