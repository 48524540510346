import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

const ShowTrustSuggestionsPopupLinkComponent = ({ onOpenPopupClick }) => {
  return (
    <a href="#" className="text_link" onClick={onOpenPopupClick}>
      {translate('trustSuggestionsPopup.openLink.text')}
    </a>
  );
};

ShowTrustSuggestionsPopupLinkComponent.propTypes = {
  onOpenPopupClick: PropTypes.func.isRequired,
};

export default ShowTrustSuggestionsPopupLinkComponent;
