import { chunk } from 'lodash';

import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
} from 'date-fns';

// Build days of month matrix for simplifying render.
// Each row contains 7 days.
// Each day represent by Date object.
const buildDaysOfMonthMatrix = (month) => {
  const startDate = startOfWeek(startOfMonth(month));
  const endDate = endOfWeek(endOfMonth(month));

  const days = eachDayOfInterval({ start: startDate, end: endDate });
  return chunk(days, 7);
};

export default buildDaysOfMonthMatrix;
