import React from 'react';
import PropTypes from 'prop-types';

import { PageParentContext } from './context';

import CompetitorAnalysis from './CompetitorAnalysis';
import PageCreateButton from './PageCreateButton';
import PagesBulkActions from './PagesBulkActions';
import PagesList from './PagesList';
import RunIntersectAnalysButton from './RunIntersectAnalysButton';

import { policiesShape } from 'common/prop_types_shapes';

import './pages.scss';

const PagesComponent = ({ onAddCompetitorsClick, policies }) => {
  return (
    <PageParentContext.Consumer>
      {({ pageParentId, pageParentType, pageChildType }) => (
        <>
          {pageChildType === 'competitorsPage' && (
            <>
              <span className="heading-action-buttons__wrapper">
                <div className="run-intersect-analys-button__wrapper">
                  <RunIntersectAnalysButton pageParentId={pageParentId} />
                </div>
                <div className="add-competitors-button__wrapper">
                  <PageCreateButton
                    onClick={onAddCompetitorsClick}
                    pageChildType={pageChildType}
                    policies={policies}
                  />
                </div>
              </span>

              <CompetitorAnalysis
                pageParentId={pageParentId}
                pageParentType={pageParentType}
                pageChildType={pageChildType}
              />
            </>
          )}

          <div className="page__wrapper page__wrapper_competitors">
            <PagesBulkActions
              pageParentId={pageParentId}
              pageParentType={pageParentType}
              pageChildType={pageChildType}
            />
            <PagesList
              pageParentId={pageParentId}
              pageParentType={pageParentType}
              pageChildType={pageChildType}
            />
          </div>
        </>
      )}
    </PageParentContext.Consumer>
  );
};

PagesComponent.propTypes = {
  onAddCompetitorsClick: PropTypes.func,
  policies: policiesShape,
};

export default PagesComponent;
