import {
  fetchIntersectReportFailure,
  fetchIntersectReportStart,
  fetchIntersectReportSuccess,
} from '../actions/competitorAnalysisActions';

import { translate } from '../common/i18n';

import {
  getFromAPI,
  handleAPIError,
} from './utils';

async function fetchIntersectReport(dispatch, brandPageId) {
  dispatch(fetchIntersectReportStart());

  try {
    const resp = await getFromAPI(
      Routes.intersect_report_competitors_analysis_index_path({ brand_page_id: brandPageId }),
    );
    dispatch(fetchIntersectReportSuccess(brandPageId, resp));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, fetchIntersectReportFailure, translate('errors.cantFetchIntersectReport'));
  }
}

export { fetchIntersectReport };
