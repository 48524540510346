const IMPERSONATE_BY_USER_ID__FAILURE = 'IMPERSONATE_BY_USER_ID__FAILURE';
const FETCH_IMPERSONATES__FAILURE = 'FETCH_IMPERSONATES__FAILURE';
const FETCH_IMPERSONATES__START = 'FETCH_IMPERSONATES__START';
const FETCH_IMPERSONATES__SUCCESS = 'FETCH_IMPERSONATES__SUCCESS';

function fetchImpersonatesFailure(errors) {
  return { type: FETCH_IMPERSONATES__FAILURE, errors };
}

function fetchImpersonatesStart() {
  return { type: FETCH_IMPERSONATES__START };
}

function fetchImpersonatesSuccess(impersonates) {
  return { type: FETCH_IMPERSONATES__SUCCESS, impersonates };
}

function impersonateByUserIdFailure(errors) {
  return { type: IMPERSONATE_BY_USER_ID__FAILURE, errors };
}

export {
  FETCH_IMPERSONATES__FAILURE,
  FETCH_IMPERSONATES__START,
  FETCH_IMPERSONATES__SUCCESS,
  IMPERSONATE_BY_USER_ID__FAILURE,
  fetchImpersonatesFailure,
  fetchImpersonatesStart,
  fetchImpersonatesSuccess,
  impersonateByUserIdFailure,
};
