import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoCampaignsTableComponent from './EoCampaignsTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { fetchEoCampaigns } from 'api/eoCampaign';
import { eoCampaignsList } from 'common/propTypesShapes/eoCampaigns';
import { eoCampaignsSelector } from 'selectors/eoCampaignsSelector';
import { translate } from 'common/i18n';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const EoCampaignsTableContainer = ({
  changeQueryParams,
  dispatch,
  eoCampaigns,
  history,
  isReadOnly,
  onRowClick: onRowSelect,
  queryParams,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const {
    filters = {},
    pageNumber = 1,
    pagyItemsCount = 10,
    search = '',
    sorting = {},
  } = queryParams;

  React.useEffect(() => {
    async function fetchData() {
      const fetchingParams = {
        filters,
        items: pagyItemsCount,
        page: pageNumber,
        search,
        sorting,
      };

      setIsFetching(true);
      await fetchEoCampaigns(dispatch, isReadOnly ? {} : fetchingParams);
      setIsFetching(false);
    }

    fetchData();
  }, [
    dispatch,
    JSON.stringify(filters),
    pageNumber,
    pagyItemsCount,
    search,
    sorting.sortBy,
    sorting.sortingOrder,
  ]);

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  function handleRowClick(eoCampaignId) {
    onRowSelect(eoCampaignId);
  }

  function handleRedirectToCampaign(eoCampaignId) {
    !isReadOnly && history.push(Routes.overview_eo_campaign_path(eoCampaignId, { format: null }));
  }

  return (
    <>
      {eoCampaigns.size > 0 && (
        <EoCampaignsTableComponent
          onRowClick={handleRowClick}
          onTableHeaderClick={handleTableHeaderClick}
          onRedirectToCampaign={handleRedirectToCampaign}
          eoCampaigns={eoCampaigns}
          isReadOnly={isReadOnly}
          sorting={sorting}
        />
      )}
      {eoCampaigns.size === 0 && (
        <BlankStateCtaComponent isLoading={isFetching}>
          <TitleText>
            <strong>{translate('eoCampaign.table.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('eoCampaign.table.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

EoCampaignsTableContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaigns: eoCampaignsList,
  isReadOnly: PropTypes.bool,
  onRowClick: PropTypes.func,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoCampaigns = eoCampaignsSelector(state);

  return {
    eoCampaigns,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoCampaignsTableContainer);
