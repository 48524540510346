import React from 'react';
import { List as iList } from 'immutable';

import ScrollToCompetitorLink from '../ScrollToCompetitorLink';

import SpinnerComponent from 'components_linkio/Spinner';

export default function buildBrandName({ page, needToShowSpinner, row = {}, tableSection = '' }) {
  const brandName = page.get('brandName') || page.get('brandOtherNames', iList()).first();

  // Show auto-scroll link only for competitor's brand name
  const isCompetitorBrandName =
    row.key === 'brandName' && tableSection.toLowerCase().includes('competitors');

  return (
    <span title={brandName} className="competitors-table__cell_data-with-spinner">
      <span className="text_two-lines-with-ellipsis-break-all">{brandName}</span>
      {isCompetitorBrandName && <ScrollToCompetitorLink competitorPageId={page.get('id')} />}
      {needToShowSpinner && <SpinnerComponent isBlack />}
    </span>
  );
}
