import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import ManuallyAddCompetitorsPopupComponent from './ManuallyAddCompetitorsPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { addCompetitorsManually } from 'api/bulkCompetitorsSetup';
import { visibleBrandPageIdSelector } from 'selectors';

const ManuallyAddCompetitorsPopupContainer = ({ dispatch, onClose, brandPageId }) => {
  async function handleSave(competitorUris) {
    const competitorUrisArray = competitorUris.trim().split(/\r?\n/);

    await addCompetitorsManually(dispatch, brandPageId, competitorUrisArray);

    onClose();
  }

  return <ManuallyAddCompetitorsPopupComponent onCancel={onClose} onSave={handleSave} />;
};

function select(state, ownProps) {
  const brandPageId = visibleBrandPageIdSelector(state, ownProps);

  return {
    brandPageId,
  };
}

ManuallyAddCompetitorsPopupContainer.propTypes = {
  brandPageId: PropTypes.string,
  dispatch: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(ManuallyAddCompetitorsPopupContainer);
