export default {
  helpcrunchEvents: {
    add: {
      brand: 'Add brand',
      brandPage: 'Add page',
      columns: 'Add/Edit columns',
      competitorsPage: 'competitorspage add page',
      customPercentage: 'Added custom percentage',
      disavowFile: 'Added disavow file',
      marketer: 'Create new marketer',
      template: 'Add Template',
    },
    categorizationPerfomed: {
      brandPage: 'Categorization performed',
      competitorsPage: 'competitorsPage Categorization performed',
    },
    change: {
      linkStatus: {
        started: 'changed link status to in progress',
        published: 'changed link status to published',
      },
    },
    click: {
      btn: {
        changePercentages: 'Change percentages button was clicked',
        notifications: 'Notifications button was clicked',
        showAllFromDashboardPage: 'Show all - clicked from page dashboard',
      },
      crawl: {
        now: 'Crawl Now clicked',
        brand: 'Verify Brand Backlinks button clicked',
        brandPage: 'brandPage Crawl Now clicked',
        competitorsPage: 'competitorsPage Crawl Now clicked',
      },
      tab: {
        backlinks: 'backlinks-clicked',
        bulk_competitors_setup: 'bulkCompetitorsSetup-clicked',
        competitors: 'competitors-clicked',
        in_progress: 'inProgress-clicked',
        suggestions: 'suggestions-clicked',
      },
    },
    connect: {
      ahrefs: 'Ahrefs account connected',
    },
    crawl: {
      brand: 'Crawl Brand',
      brandPage: 'brandPage Crawl Page',
      competitorsPage: 'competitorsPage Crawl Page',
    },
    export: {
      brandPage: {
        csv: 'brandPage Export CSV',
      },
      competitorsPage: {
        csv: 'competitorsPage Export CSV',
      },
    },
    import: {
      csv: 'Import CSV',
      gsc: 'Import from GSC',
      brandPage: {
        csv: 'ATP Import CSV',
      },
      competitorsPage: {
        csv: 'competitorsPage Import CSV',
      },
    },
    inviteUsers: 'Invite users',
    intersectReport: 'intersect-report',
    reDistribute: {
      brandPage: 'brandPage Re-distribute',
      competitorsPage: 'competitorsPage Re-distribute',
    },
    select: {
      competitorPercentage: 'competitor-percentage-selected',
      customPercentage: 'custom-percentage-selected',
      percentage: 'percentage-selected',
    },
    signIn: 'Sign In',
    suggestions: {
      edited: 'suggestion-edited',
      random: 'random-suggestion',
      selected: 'suggestion-selected',
    },
    view: {
      page: {
        subscriptionPlans: 'View "Subscription Plans" page',
      },
      popup: {
        categorizer: {
          brandPage: 'Categorizer popup opened',
          competitorsPage: 'competitorsPage Categorizer popup opened',
        },
        trustSuggestions: 'Trust suggestions link was clicked',
        upgradeYourPlan: 'View "upgrade your plan" popup',
      },
    },
  },
};
