import {
  fetchBrandsChartMetricsStart,
  fetchBrandsChartMetricsSuccess,
  fetchBrandsChartMetricsFailure,
} from 'actions/brandsChartsActions';

import { translate } from 'common/i18n';

import { postToAPI, handleAPIError } from 'api/utils';

async function fetchBrandsChartMetrics(dispatch, brandIds, startDate, endDate) {
  dispatch(fetchBrandsChartMetricsStart());
  try {
    const resp = await postToAPI(Routes.chart_data_dashboard_index_path(), {
      brand_ids: brandIds,
      start_date: startDate,
      end_date: endDate,
    });
    dispatch(fetchBrandsChartMetricsSuccess(resp.brands));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchBrandsChartMetricsFailure,
      translate('errors.cantFetchBrandsChartMetrics'),
    );
  }
}
async function fetchBrandsChartMetricsToday(dispatch, brandIds, startDate, endDate) {
  dispatch(fetchBrandsChartMetricsStart());
  try {
    const resp = await postToAPI(Routes.chart_data_dashboard_index_path(), {
      brand_ids: brandIds,
      start_date: startDate,
      end_date: endDate,
    });
    dispatch(fetchBrandsChartMetricsSuccess(resp.brands));
    return resp.brands[0].metrics[0].status;
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchBrandsChartMetricsFailure,
      translate('errors.cantFetchBrandsChartMetrics'),
    );
  }
}
export { fetchBrandsChartMetrics, fetchBrandsChartMetricsToday };
