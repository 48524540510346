export const CREATE_EO_CAMPAIGN__FAILURE = 'CREATE_EO_CAMPAIGN__FAILURE';
export const CREATE_EO_CAMPAIGN__START = 'CREATE_EO_CAMPAIGN__START';
export const CREATE_EO_CAMPAIGN__SUCCESS = 'CREATE_EO_CAMPAIGN__SUCCESS';
export const DELETE_EO_CAMPAIGNS__FAILURE = 'DELETE_EO_CAMPAIGNS__FAILURE';
export const DELETE_EO_CAMPAIGNS__START = 'DELETE_EO_CAMPAIGNS__START';
export const DELETE_EO_CAMPAIGNS__SUCCESS = 'DELETE_EO_CAMPAIGNS__SUCCESS';
export const FETCH_EO_CAMPAIGNS__FAILURE = 'FETCH_EO_CAMPAIGNS__FAILURE';
export const FETCH_EO_CAMPAIGNS__START = 'FETCH_EO_CAMPAIGNS__START';
export const FETCH_EO_CAMPAIGNS__SUCCESS = 'FETCH_EO_CAMPAIGNS__SUCCESS';
export const FETCH_EO_CAMPAIGN__FAILURE = 'FETCH_EO_CAMPAIGN__FAILURE';
export const FETCH_EO_CAMPAIGN__START = 'FETCH_EO_CAMPAIGN__START';
export const FETCH_EO_CAMPAIGN__SUCCESS = 'FETCH_EO_CAMPAIGN__SUCCESS';
export const FETCH_EO_CAMPAIGN_METRICS__FAILURE = 'FETCH_EO_CAMPAIGN_METRICS__FAILURE';
export const FETCH_EO_CAMPAIGN_METRICS__START = 'FETCH_EO_CAMPAIGN_METRICS__START';
export const FETCH_EO_CAMPAIGN_METRICS__SUCCESS = 'FETCH_EO_CAMPAIGN_METRICS__SUCCESS';
export const UPDATE_EO_CAMPAIGN__FAILURE = 'UPDATE_EO_CAMPAIGN__FAILURE';
export const UPDATE_EO_CAMPAIGN__START = 'UPDATE_EO_CAMPAIGN__START';
export const UPDATE_EO_CAMPAIGN__SUCCESS = 'UPDATE_EO_CAMPAIGN__SUCCESS';

export function createEoCampaignStart() {
  return { type: CREATE_EO_CAMPAIGN__START };
}

export function createEoCampaignSuccess(eoCampaign) {
  return { type: CREATE_EO_CAMPAIGN__SUCCESS, eoCampaign };
}

export function createEoCampaignFailure(errors) {
  return { type: CREATE_EO_CAMPAIGN__FAILURE, errors };
}

export function deleteEoCampaignsStart() {
  return { type: DELETE_EO_CAMPAIGNS__START };
}

export function deleteEoCampaignsSuccess(ids) {
  return { type: DELETE_EO_CAMPAIGNS__SUCCESS, ids };
}

export function deleteEoCampaignsFailure(errors) {
  return { type: DELETE_EO_CAMPAIGNS__FAILURE, errors };
}

export function fetchEoCampaignsStart() {
  return { type: FETCH_EO_CAMPAIGNS__START };
}

export function fetchEoCampaignsSuccess(eoCampaigns) {
  return { type: FETCH_EO_CAMPAIGNS__SUCCESS, eoCampaigns };
}

export function fetchEoCampaignsFailure(errors) {
  return { type: FETCH_EO_CAMPAIGNS__FAILURE, errors };
}

export function fetchEoCampaignStart() {
  return { type: FETCH_EO_CAMPAIGN__START };
}

export function fetchEoCampaignSuccess(eoCampaign) {
  return { type: FETCH_EO_CAMPAIGN__SUCCESS, eoCampaign };
}

export function fetchEoCampaignFailure(errors) {
  return { type: FETCH_EO_CAMPAIGN__FAILURE, errors };
}

export function fetchEoCampaignMetricsStart() {
  return { type: FETCH_EO_CAMPAIGN_METRICS__START };
}

export function fetchEoCampaignMetricsSuccess(eoCampaignMetrics) {
  return { type: FETCH_EO_CAMPAIGN_METRICS__SUCCESS, eoCampaignMetrics };
}

export function fetchEoCampaignMetricsFailure(errors) {
  return { type: FETCH_EO_CAMPAIGN_METRICS__FAILURE, errors };
}

export function updateEoCampaignStart() {
  return { type: UPDATE_EO_CAMPAIGN__START };
}

export function updateEoCampaignSuccess(eoCampaign) {
  return { type: UPDATE_EO_CAMPAIGN__SUCCESS, eoCampaign };
}

export function updateEoCampaignFailure(errors) {
  return { type: UPDATE_EO_CAMPAIGN__FAILURE, errors };
}
