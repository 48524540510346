import { fromJS, List as iList, Map as iMap } from 'immutable';

import {
  ADD_BACKLINKS__SUCCESS,
  FETCH_BACKLINKS__SUCCESS,
  UPDATE_BACKLINKS_ROWS,
  UPDATE_BACKLINK__SUCCESS,
  UPDATE_BACKLINKS__SUCCESS,
} from '../actions/backlinkActions';

import { camelizeObjectDeep, mergeArrayToList, mergeItemToList } from './utils';

const initialState = iMap();

export default function backlinkReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_BACKLINKS__SUCCESS:
    case UPDATE_BACKLINKS__SUCCESS:
    case UPDATE_BACKLINKS_ROWS: {
      const { backlinks, brandId } = action;

      state = state.set(
        String(brandId),
        mergeArrayToList(state.get(String(brandId), iList()), backlinks, camelizeObjectDeep),
      );

      break;
    }
    case FETCH_BACKLINKS__SUCCESS: {
      const { backlinks, brandId } = action;

      state = state.set(String(brandId), fromJS(backlinks.map(camelizeObjectDeep)));

      break;
    }
    case UPDATE_BACKLINK__SUCCESS: {
      const { backlink, brandId } = action;

      state = state.set(
        String(brandId),
        mergeItemToList(state.get(String(brandId), iList()), backlink, camelizeObjectDeep),
      );

      break;
    }
  }

  return state;
}
