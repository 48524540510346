import { fromJS, List as iList } from 'immutable';

import {
  ADD_PAGES_TO_ATP__FAILURE,
  ADD_PAGES_TO_ATP__SUCCESS,
  FETCH_BULK_SETUP_BRAND_PAGES__SUCCESS,
  IMPORT_PAGES_MANUALLY__SUCCESS,
  UPDATE_DRAFT_BRAND_PAGES_ROWS,
  UPDATE_DRAFT_BRAND_PAGE__SUCCESS,
} from '../actions/bulkPagesSetupActions';

import {
  camelizeObject,
  mergeArrayToList,
  mergeItemToList,
} from './utils';

const initialState = iList();

const processPage = (page, options = {}) => {
  const camelizedPage = camelizeObject(page);
  camelizedPage.errors = camelizeObject(page.errors);

  const processedPage = {
    ...camelizedPage,
    ...options,
  };

  return processedPage;
};

export default function bulkPagesSetupReducer(state = initialState, action) {
  switch (action.type) {
    case IMPORT_PAGES_MANUALLY__SUCCESS:
    case ADD_PAGES_TO_ATP__FAILURE:
    case ADD_PAGES_TO_ATP__SUCCESS:
    case UPDATE_DRAFT_BRAND_PAGES_ROWS: {
      state = mergeArrayToList(state, action.pages, processPage);

      break;
    }
    case FETCH_BULK_SETUP_BRAND_PAGES__SUCCESS: {
      state = fromJS(action.pages.map(processPage));

      break;
    }
    case UPDATE_DRAFT_BRAND_PAGE__SUCCESS: {
      state = mergeItemToList(state, action.page, processPage);

      break;
    }
  }

  return state;
}
