import React from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';

import PageItem from './PageItem';
import PagesListHeader from './PagesListHeader';

import { translate } from 'common/i18n';

import { brandPageShape, brandPagesMap } from 'common/prop_types_shapes';
import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const PagesListComponent = ({
  brandPage,
  pageChildType,
  pageParentId,
  pageParentType,
  pages,
  visiblePageId,
}) => {
  function processPages() {
    const firstPageId = (pages.first() || iMap()).get('id');
    let previousPageId = void 0;

    return pages
      .map((page, keyId) => {
        const keyIdNumber = Number(keyId);
        const isOpen = visiblePageId === keyIdNumber;
        const isFirst = keyIdNumber === firstPageId;
        const isNextAfterOpen = !isFirst && !isOpen && previousPageId === visiblePageId;

        previousPageId = Number(page.get('id'));

        return (
          <React.Fragment key={keyId}>
            {(isOpen || isFirst || isNextAfterOpen) && (
              <PagesListHeader pageChildType={pageChildType} />
            )}
            <div className="brand-page-list__item">
              <PageItem
                pageParentId={pageParentId}
                pageParentType={pageParentType}
                pageChildType={pageChildType}
                page={page}
              />
            </div>
          </React.Fragment>
        );
      })
      .valueSeq()
      .toArray();
  }

  const hasPages = pages.size > 0;

  return (
    <div className="brand-page-list brand-page-list_competitors">
      {processPages()}

      {!hasPages && (
        <BlankStateCtaComponent>
          <TitleText>
            <strong>{translate('prompts.hey')} </strong>
            {translate(`${pageChildType}.blankState.titleText`)(brandPage.get('link'))}
          </TitleText>
          <BodyText>{translate(`${pageChildType}.blankState.bodyText`)}</BodyText>
        </BlankStateCtaComponent>
      )}
    </div>
  );
};

PagesListComponent.propTypes = {
  brandPage: brandPageShape,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  pages: brandPagesMap,
  visiblePageId: PropTypes.number,
};

export default PagesListComponent;
