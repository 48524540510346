import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';

import ImportDlsPopupComponent from './ImportDlsPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { startMouseFlowPageView, trackHelpcrunchEvent } from 'common/utils';

import {
  ahrefsAbilitiesShape,
  brandPageShape,
  marketerShape,
  policiesShape,
  subscriptionPoliciesShape,
  subscriptionShape,
} from 'common/prop_types_shapes';

import {
  currentMarketerSelector,
  currentSubscriptionSelector,
  policiesSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import { closeImportDlsPopup } from 'actions/popup_actions';

import { fetchAhrefsAbilities } from 'api/ahrefs_connection';

import { fetchCurrentSubscription } from 'api/subscription';

import { importCSV, manualImport } from 'api/destination_links_import';

import importFromAhrefsService from 'pages/Pages/services/importFromAhrefsService';
import importFromMozService from 'pages/Pages/services/importFromMozService';

import clickUpgradeSubscriptionService from 'pages/Pages/services/clickUpgradeSubscriptionService';

const IMPORT_DL_MAX = 100;

class ImportDlsPopupContainer extends Component {
  static propTypes = {
    ahrefsAbilities: ahrefsAbilitiesShape,
    currentMarketer: marketerShape,
    currentSubscription: subscriptionShape,
    dispatch: PropTypes.func,
    page: brandPageShape,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    policies: policiesShape,
    subscriptionPolicies: subscriptionPoliciesShape,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchAhrefsAbilities(dispatch);
    startMouseFlowPageView(document.location.pathname);
  }

  handleCancel = () => {
    this.props.dispatch(closeImportDlsPopup());
  };

  handleClickUpgradeSubscription = (event) => {
    const { currentSubscription, dispatch } = this.props;
    clickUpgradeSubscriptionService({ currentSubscription, dispatch }, event);
  };

  handleFetchCurrentSubscription = async () => await fetchCurrentSubscription(this.props.dispatch);

  handleImportCsv = async (file, pageId, categorizeBacklinks) => {
    const { currentSubscription, dispatch, pageChildType, subscriptionPolicies } = this.props;

    const canCategorize = subscriptionPolicies.getIn(['categorizer', 'canUse'], false);
    if (categorizeBacklinks && !canCategorize) {
      clickUpgradeSubscriptionService({ currentSubscription, dispatch });
      return;
    }

    const { type } = await importCSV(dispatch, pageId, file, categorizeBacklinks);

    if (type === 'error') {
      return;
    }

    trackHelpcrunchEvent(`import.${pageChildType}.csv`);

    this.handleCancel();
  };

  handleImportFromAhrefs = (pageId, categorizeBacklinks) => {
    const { currentSubscription, dispatch, pageChildType, subscriptionPolicies } = this.props;

    importFromAhrefsService(
      {
        currentSubscription,
        dispatch,
        pageChildType,
        subscriptionPolicies,
      },
      pageId,
      categorizeBacklinks,
    );
  };

  handleImportFromMoz = (pageId, categorizeBacklinks) => {
    const { currentSubscription, dispatch, pageChildType, subscriptionPolicies } = this.props;

    importFromMozService(
      {
        currentSubscription,
        dispatch,
        pageChildType,
        subscriptionPolicies,
      },
      pageId,
      categorizeBacklinks,
    );
  };

  handleImportPublishedLinks = async (dls, pageId, categorizeBacklinks) => {
    const { currentSubscription, dispatch, subscriptionPolicies } = this.props;

    const canCategorize = subscriptionPolicies.getIn(['categorizer', 'canUse'], false);
    if (categorizeBacklinks && !canCategorize) {
      clickUpgradeSubscriptionService({ currentSubscription, dispatch });
      return;
    }

    const newDls = (dls.trim() || '').split(/\r?\n/).slice(0, IMPORT_DL_MAX);
    const status = map(newDls, () => 'published\n').join('');
    const data = {
      publishedLink: newDls.join('\n'),
      status,
    };

    await manualImport(dispatch, { brandPageId: pageId, data });

    this.handleCancel();
  };

  render() {
    const { ahrefsAbilities, currentMarketer, page, policies, subscriptionPolicies } = this.props;

    const hasAhrefsConnection = currentMarketer.get('has_ahrefs_connection');
    const canUseAhrefs = policies.getIn(['ahrefsConnection', 'canUse']);
    const canUseAhrefsInSubscription = subscriptionPolicies.getIn(['ahrefsIntegration', 'canUse']);

    const canUseCategorizerInSubscription = subscriptionPolicies.getIn(['categorizer', 'canUse']);
    const canUseMozInSubscription = subscriptionPolicies.getIn(['mozIntegration', 'canUse']);

    return (
      <ImportDlsPopupComponent
        ahrefsAbilities={ahrefsAbilities}
        canImportFromMoz={canUseMozInSubscription}
        canUseAhrefs={canUseAhrefs}
        canUseAhrefsInSubscription={canUseAhrefsInSubscription}
        canUseCategorizer={canUseCategorizerInSubscription}
        hasAhrefsConnection={hasAhrefsConnection}
        onCancel={this.handleCancel}
        onClickUpgradeSubscription={this.handleClickUpgradeSubscription}
        onFetchCurrentSubscription={this.handleFetchCurrentSubscription}
        onImportCsv={this.handleImportCsv}
        onImportFromAhrefs={this.handleImportFromAhrefs}
        onImportFromMoz={this.handleImportFromMoz}
        onImportPublishedLinks={this.handleImportPublishedLinks}
        page={page}
      />
    );
  }
}

function select(state, ownProps) {
  const currentMarketer = currentMarketerSelector(state, ownProps);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);

  const ahrefsAbilities = state.getIn(['ahrefsConnection', 'abilities']).toJS();
  const policies = policiesSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  return {
    ahrefsAbilities,
    currentMarketer,
    currentSubscription,
    policies,
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(ImportDlsPopupContainer));
