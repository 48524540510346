import React from 'react';

const KeywordIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.454.943C2.448.943 0 3.259 0 6.103c0 2.844 2.448 5.16 5.454 5.16 1.31 0 2.513-.435 3.454-1.166l1.375 1.306-.307.29a.502.502 0 0 0-.16.366c0 .137.058.269.16.366l2.239 2.118c.213.201.56.201.773 0a.497.497 0 0 0 0-.731l-1.853-1.753.614-.586 2.932 2.774-1 .951a.5.5 0 0 0-.168.511c.05.187.204.332.402.377a.565.565 0 0 0 .538-.162l1.387-1.312a.502.502 0 0 0 .16-.365.502.502 0 0 0-.16-.366l-3.705-3.499a.564.564 0 0 0-.772 0l-.307.29-1.38-1.306a4.98 4.98 0 0 0 1.232-3.263c0-2.844-2.448-5.16-5.454-5.16zm0 1.032c2.416 0 4.363 1.842 4.363 4.128s-1.947 4.129-4.363 4.129c-2.416 0-4.363-1.843-4.363-4.129 0-2.286 1.947-4.128 4.363-4.128zm-2 2.752C2.657 4.727 2 5.35 2 6.103c0 .754.657 1.376 1.454 1.376.606 0 1.13-.356 1.347-.86h1.017v.172c-.003.186.1.36.27.453.17.094.38.094.55 0a.511.511 0 0 0 .27-.453V6.62h.546v.172c-.003.186.1.36.27.453.17.094.38.094.55 0a.511.511 0 0 0 .27-.453v-.688c0-.285-.243-.516-.545-.516H4.801a1.468 1.468 0 0 0-1.347-.86zm0 1.032c.208 0 .364.148.364.344 0 .196-.156.344-.364.344-.207 0-.363-.148-.363-.344 0-.196.156-.344.363-.344z" fillRule="nonzero" />
    </svg>
  );
};

KeywordIcon.defaultProps = {
  fill: "#2A7FB3",
  height: '17px',
  width: '16px',
};

export default KeywordIcon;
