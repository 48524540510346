import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchInputComponent from './SearchInputComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector } from 'selectors';

const SearchInputContainer = ({
  brandId,
  defaultValue,
  location,
  onSubmit,
  queryParams,
  withDefaultValue,
}) => {
  const [searchTimeout, setSearchTimeout] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState('');

  const path = `${location.pathname}${location.search}`;

  React.useEffect(() => {
    setSearchTerm('');
  }, [brandId]);

  React.useEffect(() => {
    const defaultSearchTerm = withDefaultValue ? defaultValue : queryParams.search || '';

    setSearchTerm(defaultSearchTerm);
  }, [path]);

  function handleInputChange({ target: { value } }) {
    setSearchTerm(value);
    // Clear timeout and start a new one on every user input
    // in order to send request by the final term instead of on every single character
    clearTimeout(searchTimeout);

    setSearchTimeout(setTimeout(() => onSubmit(value), 400));
  }

  function handleReset() {
    setSearchTerm('');
    onSubmit('');
  }

  return (
    <SearchInputComponent
      onInputChange={handleInputChange}
      onReset={handleReset}
      searchTerm={searchTerm}
    />
  );
};

SearchInputContainer.propTypes = {
  brandId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);

  return {
    brandId,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(SearchInputContainer)));
