export const CREATE_EO_CAMPAIGN_STEP__FAILURE = 'CREATE_EO_CAMPAIGN_STEP__FAILURE';
export const CREATE_EO_CAMPAIGN_STEP__START = 'CREATE_EO_CAMPAIGN_STEP__START';
export const CREATE_EO_CAMPAIGN_STEP__SUCCESS = 'CREATE_EO_CAMPAIGN_STEP__SUCCESS';
export const DELETE_EO_CAMPAIGN_STEPS__FAILURE = 'DELETE_EO_CAMPAIGN_STEPS__FAILURE';
export const DELETE_EO_CAMPAIGN_STEPS__START = 'DELETE_EO_CAMPAIGN_STEPS__START';
export const DELETE_EO_CAMPAIGN_STEPS__SUCCESS = 'DELETE_EO_CAMPAIGN_STEPS__SUCCESS';
export const FETCH_EO_CAMPAIGN_STEPS__FAILURE = 'FETCH_EO_CAMPAIGN_STEPS__FAILURE';
export const FETCH_EO_CAMPAIGN_STEPS__START = 'FETCH_EO_CAMPAIGN_STEPS__START';
export const FETCH_EO_CAMPAIGN_STEPS__SUCCESS = 'FETCH_EO_CAMPAIGN_STEPS__SUCCESS';
export const FETCH_EO_CAMPAIGN_STEP__FAILURE = 'FETCH_EO_CAMPAIGN_STEP__FAILURE';
export const FETCH_EO_CAMPAIGN_STEP__START = 'FETCH_EO_CAMPAIGN_STEP__START';
export const FETCH_EO_CAMPAIGN_STEP__SUCCESS = 'FETCH_EO_CAMPAIGN_STEP__SUCCESS';
export const UPDATE_EO_CAMPAIGN_STEP__FAILURE = 'UPDATE_EO_CAMPAIGN_STEP__FAILURE';
export const UPDATE_EO_CAMPAIGN_STEP__START = 'UPDATE_EO_CAMPAIGN_STEP__START';
export const UPDATE_EO_CAMPAIGN_STEP__SUCCESS = 'UPDATE_EO_CAMPAIGN_STEP__SUCCESS';
export const MOVE_EO_CAMPAIGN_STEP__FAILURE = 'MOVE_EO_CAMPAIGN_STEP__FAILURE';
export const MOVE_EO_CAMPAIGN_STEP__START = 'MOVE_EO_CAMPAIGN_STEP__START';
export const MOVE_EO_CAMPAIGN_STEP__SUCCESS = 'MOVE_EO_CAMPAIGN_STEP__SUCCESS';

export function createEoCampaignStepStart() {
  return { type: CREATE_EO_CAMPAIGN_STEP__START };
}

export function createEoCampaignStepSuccess(eoCampaignStep) {
  return { type: CREATE_EO_CAMPAIGN_STEP__SUCCESS, eoCampaignStep };
}

export function createEoCampaignStepFailure(errors) {
  return { type: CREATE_EO_CAMPAIGN_STEP__FAILURE, errors };
}

export function deleteEoCampaignStepsStart() {
  return { type: DELETE_EO_CAMPAIGN_STEPS__START };
}

export function deleteEoCampaignStepsSuccess(eoCampaignId, ids) {
  return { type: DELETE_EO_CAMPAIGN_STEPS__SUCCESS, eoCampaignId, ids };
}

export function deleteEoCampaignStepsFailure(errors) {
  return { type: DELETE_EO_CAMPAIGN_STEPS__FAILURE, errors };
}

export function fetchEoCampaignStepsStart() {
  return { type: FETCH_EO_CAMPAIGN_STEPS__START };
}

export function fetchEoCampaignStepsSuccess(eoCampaignSteps) {
  return { type: FETCH_EO_CAMPAIGN_STEPS__SUCCESS, eoCampaignSteps };
}

export function fetchEoCampaignStepsFailure(errors) {
  return { type: FETCH_EO_CAMPAIGN_STEPS__FAILURE, errors };
}

export function fetchEoCampaignStepStart() {
  return { type: FETCH_EO_CAMPAIGN_STEP__START };
}

export function fetchEoCampaignStepSuccess(eoCampaignStep) {
  return { type: FETCH_EO_CAMPAIGN_STEP__SUCCESS, eoCampaignStep };
}

export function fetchEoCampaignStepFailure(errors) {
  return { type: FETCH_EO_CAMPAIGN_STEP__FAILURE, errors };
}

export function updateEoCampaignStepStart() {
  return { type: UPDATE_EO_CAMPAIGN_STEP__START };
}

export function updateEoCampaignStepSuccess(eoCampaignId, eoCampaignStep) {
  return { type: UPDATE_EO_CAMPAIGN_STEP__SUCCESS, eoCampaignId, eoCampaignStep };
}

export function updateEoCampaignStepFailure(errors) {
  return { type: UPDATE_EO_CAMPAIGN_STEP__FAILURE, errors };
}

export function moveEoCampaignStepStart() {
  return { type: MOVE_EO_CAMPAIGN_STEP__START };
}

export function moveEoCampaignStepSuccess(eoCampaignId, eoCampaignSteps) {
  return { type: MOVE_EO_CAMPAIGN_STEP__SUCCESS, eoCampaignId, eoCampaignSteps };
}

export function moveEoCampaignStepFailure(errors) {
  return { type: MOVE_EO_CAMPAIGN_STEP__FAILURE, errors };
}
