import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as edit from 'react-edit';
import { cloneDeep, find, findIndex, each, trim, upperCase, isEqual } from 'lodash';
import * as Table from 'reactabular-table';
import * as selectabular from 'selectabular';
import moment from 'moment';
import { fromJS, Map as iMap } from 'immutable';

import {
  anchorTextSelectProps,
  anchorTypeSelectProps,
  buildAnchorTextSelectOptions,
  sourceSelectProps,
  statusSelectProps,
} from './utils';

import { atpTableTrackHelpcrunchEventsService } from './services/atpTableTrackHelpcrunchEventsService';

import correctAtpWrapperBottomSpaceIfNeeded from 'utils/correctAtpWrapperBottomSpaceIfNeeded';
import { translate } from 'common/i18n';
import columnsGenerator, {
  columnSelect,
  columnSelectWithTextArea,
  columnTextArea,
  columnDate,
} from 'common/tables/columns_generator';
import { reactSelectColumn, checkboxColumn, textColumn } from 'common/tables/columns';
import {
  addCSSClassToCellInTable,
  generateDestinationLinkStatusForAtp,
  highlightTableRow,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
} from 'common/tables/utils';
import {
  destinationLinksArray,
  destinationLinksMap,
  optionsMap,
  policiesShape,
} from 'common/prop_types_shapes';

import { trackHelpcrunchEvent } from 'common/utils';

import DlCrawlingInProgressIndicator from 'pages/Pages/PagesList/PageItem/PageDls/DlsTable/DlCrawlingInProgressIndicator';

import BadgeComponent from 'components_linkio/badge_component';
import Checkbox from 'components_linkio/Checkbox';
import EditIcon from 'common/icons/edit';
import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';
import Link from 'components_linkio/link';

import './atp_table.scss';


export default class AtpTable extends React.Component {
  static propTypes = {
    anchorTextOptions: optionsMap,
    anchorTypeOptions: optionsMap.isRequired,
    canUseCrawlers: PropTypes.bool,
    checkRecalculation: PropTypes.func,
    destinationLinks: destinationLinksArray.isRequired,
    dlSourcesOptions: optionsMap.isRequired,
    onCreateDl: PropTypes.func,
    onDlDetailsClick: PropTypes.func,
    onFetchAnchorTextOptions: PropTypes.func,
    onShowErrorMessage: PropTypes.func,
    onTableHeaderClick: PropTypes.func,
    onUpdateDl: PropTypes.func,
    onUpdateDlAnchorText: PropTypes.func,
    onUpdateRows: PropTypes.func,
    policies: policiesShape.isRequired,
    selectedRows: destinationLinksMap,
    statusOptions: optionsMap.isRequired,
    tableType: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.state = {
      props,
      columns: this.generateColumns(props),
      formColumns: this.generateFormColumns(props),
      formRow: this.formRow(),
      generateColumns: this.generateColumns,
      generateFormColumns: this.generateFormColumns,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const needToUpdState = !isEqual(nextProps, prevState.props);

    if (needToUpdState) {
      return {
        columns: prevState.generateColumns(nextProps),
        formColumns: prevState.generateFormColumns(nextProps),
        props: nextProps,
      };
    }

    return null;
  }

  componentDidMount() {
    this.setTablesRef();
  }

  componentDidUpdate() {
    correctAtpWrapperBottomSpaceIfNeeded({});

    if (!this.tableFormRef && this.showAtpTableForm()) {
      const tables = ReactDOM.findDOMNode(this).getElementsByTagName('table');
      this.tableFormRef = tables[1];
    }
  }

  tableRef = {};
  tableFormRef = {};

  setTablesRef = () => {
    const tables = ReactDOM.findDOMNode(this).getElementsByTagName('table');
    this.tableRef = tables[0];
    this.tableFormRef = tables[1];
  }

  showAtpTableForm = () => (this.props.tableType !== 'suggestions' && this.props.tableType !== 'pageDashboard');

  removeCellActiveClassFromAllCells = () => {
    removeCSSClassFromTableCells(this.tableRef, TABLE_CELL_ACTIVE_CLASS);
    this.tableFormRef && removeCSSClassFromTableCells(this.tableFormRef, TABLE_CELL_ACTIVE_CLASS);
  }

  buildAnchorTypeColumn = (withAbility) => {
    const { anchorTypeOptions, tableType } = this.props;

    if (tableType !== 'suggestions') {
      return reactSelectColumn({
        name: 'anchorType',
        headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
        cellValueFormatter: this.anchorTypeValueFormatter,
        className: 'atp-table__anchor-type-column',
        cellTransforms: [
          columnSelect(withAbility, anchorTypeOptions.toList().toJS(), anchorTypeSelectProps),
        ],
      });
    }

    return textColumn({
      name: 'anchorType',
      headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
      cellValueFormatter: this.anchorTypeValueFormatter,
      className: 'atp-table__anchor-type-column',
    });
  }

  generateColumns = (props) => this.baseGenerateColumns(this.editable, props, { сheckbox: true });

  generateFormColumns = (props) => {
    const canUpdateAtp = props.policies.getIn(['atp', 'canUpdate']);

    if (!canUpdateAtp) {
      return [];
    }

    return this.baseGenerateColumns(this.creatable, props, { form: true });
  }

  baseGenerateColumns = (withAbility, props, options = {}) => {
    const {
      columns,
      dlSourcesOptions,
      statusOptions,
      tableType,
    } = props;
    const { сheckbox, form } = options;
    const headerValueFormatter = this.headerValueFormatter({ withExplanationMessage: true });
    const cellFormattersCheckbox = [];

    сheckbox && cellFormattersCheckbox.push(this.cellCheckboxFormatters);

    function buildAnchorTextColumn() {
      if (tableType !== 'backlinks') {
        return reactSelectColumn({
          name: 'anchorText',
          headerValueFormatter,
          cellTransforms: [
            form ? columnTextArea(withAbility) : columnSelectWithTextArea(withAbility, [], anchorTextSelectProps),
          ],
          className: 'atp-table__anchor-text-column',
          showTooltip: true,
          isRefreshable: !form,
          isEditable: true,
        });
      }

      return textColumn({
        name: 'anchorText',
        headerValueFormatter,
        cellTransforms: [columnTextArea(withAbility)],
        className: 'atp-table__anchor-text-column',
        showTooltip: true,
        isEditable: true,
      });
    }

    const anchorTypeColumn = this.buildAnchorTypeColumn(withAbility);

    const allAtpColumns = columnsGenerator([
      checkboxColumn({
        headerFormatters: [this.headerCheckboxFormatters],
        cellFormatters: cellFormattersCheckbox,
        className: 'atp-table__checkbox-column',
      }),
      anchorTypeColumn,
      buildAnchorTextColumn(),
      textColumn({
        name: 'targetWebsite',
        headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
        className: 'atp-table__target-website-column',
        cellTransforms: [columnTextArea(withAbility)],
        showTooltip: true,
        isEditable: true,
      }),
      reactSelectColumn({
        name: 'status',
        headerLabel: translate('destinationLink.status.title'),
        explanationMessage: translate('explanationMessages.atpTable.status'),
        cellValueFormatter: this.statusValueFormatter,
        className: 'atp-table__status-column',
        cellTransforms: [
          columnSelect(withAbility, statusOptions.toList().toJS(), statusSelectProps),
        ],
      }),
      reactSelectColumn({
        name: 'source',
        headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
        className: 'atp-table__source-column',
        cellValueFormatter: this.sourceValueFormatter,
        cellTransforms: [
          columnSelect(withAbility, dlSourcesOptions.toList().toJS(), sourceSelectProps),
        ],
      }),
      reactSelectColumn({
        name: 'publishedDate',
        headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
        cellValueFormatter: this.dateValueFormatter,
        cellTransforms: [columnDate(withAbility)],
        className: 'atp-table__date-column',
      }),
      textColumn({
        name: 'priority',
        headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
        className: 'atp-table__priority-column',
        showTooltip: true,
      }),
    ]);
    // insert new column with index 1 in array
    allAtpColumns.splice(1, 0, this.publishedLinkColumn(withAbility, form));

    return columns.map((column) => find(allAtpColumns, { property: column }));
  }

  handleDlDetailsClick = (dlId) => () => this.props.onDlDetailsClick(dlId);

  publishedLinkColumn = (withAbility, form) => {
    const { canUseCrawlers } = this.props;

    const cellFormatters = [(value, extra) => {
      if (value === translate('destinationLink.publishedLink.placeholder')) {
        return (
          <div className="common-field">
            <span>{value}</span>
            <span>
              <EditIcon className="common-field__edit-icon" />
            </span>
          </div>
        );
      }

      const { property, rowData } = extra;
      const {
        errors,
        id,
      } = rowData;

      const dlStatus = generateDestinationLinkStatusForAtp(fromJS(rowData), canUseCrawlers).map((row) => {
        const { status } = row;
        const text = upperCase(status);
        const color = {
          blue: status === 'processing',
          green: status === 'included',
          orange: status === 'unvalidated',
          red: status === 'excluded',
          warning: status === 'error',
        };
        return <BadgeComponent small {...color} key={text} text={text} />;
      });

      const commonFieldProps = {
        onClick: this.handleDlDetailsClick(id),
      };

      const validationError = (errors || {})[property];

      form && Reflect.deleteProperty(commonFieldProps, 'onClick');

      const cellProps = dlStatus.length > 0 ? {} : commonFieldProps;
      const badgesWrapperProps = dlStatus.length > 0 ? commonFieldProps : {};

      return (
        <div className="common-field" {...cellProps}>
          {value && (
            <Link
              className="text text_one-line atp-table__link"
              href={value}
              target="_blank"
              title={value}
            >
              {value}
            </Link>
          )}
          {!form && !validationError && (
            <span
              className="atp-table__link-status atp-table__link-status_no_stretch"
              {...badgesWrapperProps}
            >
              <DlCrawlingInProgressIndicator dl={rowData} />
              {dlStatus}
            </span>
          )}
          {validationError && (
            <span className="cell_error small">{validationError}</span>
          )}
        </div>
      );
    }];

    const cellTransforms = form ? [columnTextArea(withAbility)] : [];
    const headerFormatters = [this.headerValueFormatter({ withExplanationMessage: true })];

    return {
      cell: {
        formatters: cellFormatters,
        transforms: cellTransforms,
      },
      header: {
        label: translate('destinationLink.publishedLink.title'),
        formatters: headerFormatters,
      },
      property: 'publishedLink',
      props: {
        className: 'atp-table__published-link-column',
      },
    };
  };

  headerValueFormatter = ({ withExplanationMessage }) => (_value, extra) => {
    const { tableType } = this.props;
    const { property } = extra;

    if (tableType === 'pageDashboard') {
      return (
        <div className="atp-table__header-cell">
          {translate(`destinationLink.${property}.title`)}
          {withExplanationMessage && (
            <ExplanatoryTooltipComponent
              text={translate(`explanationMessages.atpTable.${property}`)}
            />
          )}
        </div>
      );
    }

    const { onTableHeaderClick, queryParams } = this.props;
    const { sortBy, sortingOrder } = (queryParams.sorting || {});

    return (
      <HeaderFieldWithSorting
        appModule="atp"
        label={translate(`destinationLink.${property}.title`)}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  }

  headerCheckboxFormatters = () => {
    const canUpdateAtp = this.props.policies.getIn(['atp', 'canUpdate']);

    return (
      <Checkbox
        checked={this.isAllSelected()}
        disabled={!canUpdateAtp}
        onChange={this.handleOnToggleSelectAll}
      />
    );
  }

  cellCheckboxFormatters = (value, extra) => {
    const canUpdateAtp = this.props.policies.getIn(['atp', 'canUpdate']);
    const { selected, id } = extra.rowData;

    return (
      <Checkbox
        checked={selected}
        disabled={!canUpdateAtp}
        onChange={this.handleOnToggleSelectRow(id)}
      />
    );
  }

  placeholders = {
    anchorText: translate('destinationLink.anchorText.placeholder'),
    anchorType: translate('destinationLink.anchorType.placeholder'),
    errors: {},
    failed: false,
    id: 'anything',
    priority: translate('destinationLink.priority.placeholder'),
    publishedDate: translate('destinationLink.publishedDate.placeholder'),
    publishedLink: translate('destinationLink.publishedLink.placeholder'),
    source: translate('destinationLink.source.placeholder'),
    status: translate('destinationLink.status.placeholder'),
    targetWebsite: translate('destinationLink.targetWebsite.placeholder'),
  };

  getEditingType = (property, event) => {
    if (property === 'anchorText') {
      return event.target.closest('.selector-field__down-arrow-icon') ? 'dropdown' : 'text';
    }

    return '';
  };

  refreshCellValue = (property, rowData) => {
    if (property === 'anchorText') {
      this.props.onUpdateDlAnchorText(rowData.id);
    }
  };

  anchorTextLoadOptions = (dlId) => (value, callback) => {
    const filteredOptions = (value, anchorTextOptions) => {
      if (!value) {
        return anchorTextOptions;
      }

      return anchorTextOptions.map((groupOptions) => {
        const options = value
          ? groupOptions.options.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
          : groupOptions.options;

        return {
          ...groupOptions,
          options,
        };
      });
    };

    // loadOptions function can't be async by react-select api design, have to use promise instead
    new Promise((resolve) => {
      resolve(this.props.onFetchAnchorTextOptions(dlId));
    }).then(() => {
      const anchorTextOptions = buildAnchorTextSelectOptions(this.props.anchorTextOptions.toList().toJS());
      callback(filteredOptions(value, anchorTextOptions));
    });
  };

  editable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowData, property, event }) => {
      // Refresh icon svg can be clicked on child node, i.e. path, etc
      // that's why use event.target.closest here.
      // We prevent standard react-edit behavour, when clicking on refresh icon.
      // In common case we can have refreshable cells in different columns,
      // that's why we pass property to refreshCellValue
      if (event.target.closest('.selector-field__refresh-icon')) {
        trackHelpcrunchEvent('suggestions.random');

        this.refreshCellValue(property, rowData);
        return;
      }

      const canUpdateAtp = this.props.policies.getIn(['atp', 'canUpdate']);

      if (!canUpdateAtp) {
        return;
      }

      const { onUpdateRows, destinationLinks } = this.props;

      const index = findIndex(destinationLinks, { id: rowData.id });
      const cloneRows = cloneDeep(destinationLinks);

      cloneRows[index].editing = columnIndex;

      // In case we want to get both text editing and select in the cell,
      // we need to define editingType, depending on place we click in the cell, i.e.
      // if user click on dropdown arrow, we set editingType as dropdown,
      // and show dropdown with options to user,
      // if user click somewhere else, we set editingType as text,
      // and allow user to change cell value just via typing there.
      //
      // LoadOptions - returns different options dending on the row of the clicked cell,
      // for anchorText case, we return different set of anchor text options,
      // depending on anchor type of current destination link.
      cloneRows[index].activeCell = {
        loadOptions: this.anchorTextLoadOptions(rowData.id),
        editingType: this.getEditingType(property, event),
      };

      addCSSClassToCellInTable(this.tableRef, index, columnIndex, TABLE_CELL_ACTIVE_CLASS);

      onUpdateRows(cloneRows);
    },

    onValue: async ({ value, rowData, property }) => {
      const {
        checkRecalculation,
        destinationLinks,
        onShowErrorMessage,
        onUpdateDl,
        tableType,
      } = this.props;

      const index = findIndex(destinationLinks, { id: rowData.id });
      const cloneRows = cloneDeep(destinationLinks);
      const currentValue = rowData[property] || '';
      const { activeCell: { editingType }, status } = rowData;

      const newValue = this.processValue(value);

      if (currentValue !== newValue) {
        atpTableTrackHelpcrunchEventsService({ property, editingType, status, newValue });

        const valueRows = this.parsePasted(newValue);
        const valueLength = valueRows.length;
        const pastingTargetRowsLength = cloneRows.length - index;

        if (valueLength > pastingTargetRowsLength) {
          onShowErrorMessage(translate('errors.numberOfPastedMoreThanExistingRows'));
          this.turnOffEditingState(rowData);
          return;
        }

        if (valueLength > 1000) {
          onShowErrorMessage(translate('errors.limitedLinks')({ value: 1000, isAtOnce: true }));
          return;
        }

        const rowsData = [];
        for (let i = 0; i < valueRows.length; i++) {
          const rowIndex = index + i;
          const cloneRow = cloneRows[rowIndex];

          cloneRow[property] = valueRows[i];
          Reflect.deleteProperty(cloneRow, 'editing');

          highlightTableRow(this.tableRef, index);

          rowsData.push(cloneRow);
        }

        this.removeCellActiveClassFromAllCells();
        this.turnOffEditingState(rowData);

        await onUpdateDl(rowsData);

        // We don't need to update percentages on page dashboard
        if (tableType !== 'pageDashboard') {
          await checkRecalculation(property, newValue);
        }
      } else {
        this.removeCellActiveClassFromAllCells();
        this.turnOffEditingState(rowData);
      }
    },
  });

  processValue = (value) => {
    let newValue = value || '';

    if (typeof value === 'object') {
      newValue = (value || {}).value || '';
    }

    if ((value || {})._isAMomentObject) {
      newValue = value._d;
    }

    return String(newValue).replace("Create option ", '').replace(/"/g, '');
  }

  turnOffEditingState = async (rowData) => {
    const cloneRowData = cloneDeep(rowData);

    Reflect.deleteProperty(cloneRowData, 'editing');
    await this.props.onUpdateRows([cloneRowData]);
  }

  parsePasted = (value) => {
    if (value._isAMomentObject) {
      return [value._d];
    }

    const parsedValue = (trim(value) || '').split(/\r?\n/);
    if (parsedValue.length > 0) {
      return parsedValue;
    }

    return ['']; // we need empty value to be able to cleanup the cell
  }

  creatable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex }) => {
      const index = 0;
      const { formColumns, formRow } = this.state;

      const property = formColumns[columnIndex].property;
      const cloneFormRow = cloneDeep(formRow);

      cloneFormRow[index].editing = columnIndex;
      if (cloneFormRow[index][property] === this.placeholders[property]) {
        cloneFormRow[index][property] = '';
      }

      addCSSClassToCellInTable(this.tableFormRef, index, columnIndex, TABLE_CELL_ACTIVE_CLASS);

      this.setState({ formRow: cloneFormRow });
    },

    onValue: async ({ value, rowData, property }) => {
      const index = 0;
      const { formRow } = this.state;
      const { onCreateDl, checkRecalculation } = this.props;

      const cloneFormRow = cloneDeep(formRow);
      const cloneRowData = cloneDeep(rowData);

      this.removeCellActiveClassFromAllCells();

      const newValue = this.processValue(value);

      if (newValue === '') {
        cloneFormRow[index] = {
          ...cloneFormRow[index],
          [property]: this.placeholders[property],
        };

        Reflect.deleteProperty(cloneFormRow[index], 'editing');

        return this.setState({ formRow: cloneFormRow });
      }

      const linkData = { ...cloneRowData, [property]: newValue };

      // we should not send placeholders values to the server
      each(this.placeholders, (newValue, key) => {
        if (linkData[key] === newValue) {
          linkData[key] = '';
        }
      });

      await onCreateDl(linkData);
      await checkRecalculation(property, newValue);

      cloneFormRow[index] = { ...cloneFormRow[index], ...this.placeholders };
      Reflect.deleteProperty(cloneFormRow[index], 'editing');

      highlightTableRow(this.tableFormRef, index);
      highlightTableRow(this.tableRef, -1);

      return this.setState({ formRow: cloneFormRow });
    },
  });

  formRow = () => [this.placeholders]

  anchorTypeValueFormatter = (value) => {
    const { anchorTypeOptions } = this.props;

    const option = (anchorTypeOptions || iMap()).get(String(value), iMap()).toJS();

    return (option || {}).label;
  }

  statusValueFormatter = (value) => {
    const { statusOptions } = this.props;

    const option = (statusOptions || iMap()).get(String(value), iMap()).toJS();

    return (option || {}).label;
  }

  dateValueFormatter = (value) => {
    const { publishedDate } = this.placeholders;

    if (value && value !== publishedDate) {
      const date = new Date(value).toISOString();
      return moment(date).format('MM/DD/YYYY');
    }

    return value;
  }

  sourceValueFormatter = (value) => value;

  handleOnToggleSelectAll = (event) => {
    const { onUpdateRows, destinationLinks } = this.props;

    const rows = cloneDeep(destinationLinks);

    const newRows = event.target.checked ? selectabular.all(rows) : selectabular.none(rows);

    onUpdateRows(newRows);
  }

  isAllSelected = () => {
    const { selectedRows, destinationLinks } = this.props;

    const rowsCount = destinationLinks.length;

    return rowsCount > 0 ? (rowsCount === selectedRows.size) : false;
  }

  handleOnToggleSelectRow = (rowId) => () => {
    const { onUpdateRows, destinationLinks } = this.props;

    const selectedFilter = (row) => row.id === rowId;
    const rows = selectabular.toggle(selectedFilter)(destinationLinks);

    onUpdateRows(rows);
  }

  render() {
    const { columns, formColumns, formRow } = this.state;
    const { destinationLinks, tableType } = this.props;

    const componentClasses = classnames({
      'atp-table__wrapper': true,
      'atp-table__wrapper-backlinks': tableType === 'backlinks',
      'atp-table__wrapper-suggestions': tableType === 'suggestions',
      'atp-table__wrapper-in-progress': tableType === 'in_progress',
      'atp-table__wrapper_page-dashboard': tableType === 'pageDashboard',
    });

    const showAtpTableForm = this.showAtpTableForm();

    return (
      <div className={componentClasses}>
        <Table.Provider
          className="atp-table__all"
          columns={columns}
        >
          <Table.Header />
          <Table.Body
            rowKey="id"
            rows={destinationLinks}
          />
        </Table.Provider>

        {showAtpTableForm &&
          <Table.Provider
            className="atp-table__form"
            columns={formColumns}
          >
            <Table.Body
              rowKey="id"
              rows={formRow}
            />
          </Table.Provider>
        }
      </div>
    );
  }
}
