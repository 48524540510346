import React from 'react';
import PropTypes from 'prop-types';

import buildLastCheck from './utils/buildLastCheck';
import buildNextCheck from './utils/buildNextCheck';

import { translate } from 'common/i18n';
import { brandShape } from 'common/prop_types_shapes';

import './brandCrawlingStatus.scss';

const BrandCrawlingStatusComponent = ({
  brand,
  canUseCrawlers,
  crawlingEnabled,
  scheduledCrawlingInterval,
}) => {
  const crawlingProgress = brand.get('crawlingProgress');
  const lastCrawled = brand.get('lastCrawled');

  const lastCheck = buildLastCheck(lastCrawled, crawlingProgress);
  const nextCheck = buildNextCheck({
    canUseCrawlers,
    crawlingEnabled,
    lastCrawled,
    scheduledCrawlingInterval,
  });

  return (
    <div className="brand-crawling-status">
      <span className="brand-crawling-status__item-wrapper">
        <span className="brand-crawling-status__label">
          {`${translate('backlinksBulkActions.brandCrawlingStatus.lastCheck')}: ${lastCheck}`}
        </span>
      </span>
      <span className="brand-crawling-status__item-wrapper">
        <span className="brand-crawling-status__label">
          {`${translate('backlinksBulkActions.brandCrawlingStatus.nextCheck')}: ${nextCheck}`}
        </span>
      </span>
    </div>
  );
};

BrandCrawlingStatusComponent.propTypes = {
  brand: brandShape,
  canUseCrawlers: PropTypes.bool,
  crawlingEnabled: PropTypes.bool,
  scheduledCrawlingInterval: PropTypes.string,
};

export default BrandCrawlingStatusComponent;
