import pluralize from 'pluralize';
import { buildWordInPastTenseWithPluralization } from 'common/utils';

export default {
  eoBlacklistedDomain: {
    breadcrumbsTitle: 'Blacklisted domains',
    bulkActions: {
      addEoBlacklistedDomain: 'Add Domains',
    },
    messages: {
      addEoBlacklistedDomainsSuccess: (uniqAndValidEntries, dupeOrInvalidEntries) => {
        const successMessage = `Successfully added domain(s). ${pluralize(
          'domain',
          uniqAndValidEntries,
          true,
        )} added`;
        const skippedMessage = `${dupeOrInvalidEntries} invalid or dupe ${pluralize(
          'domain',
          dupeOrInvalidEntries,
        )} skipped.`;

        return dupeOrInvalidEntries > 0 ? `${successMessage}, ${skippedMessage}` : successMessage;
      },
      cantAddEoBlacklistedDomains: "Can't add blacklisted domains",
      cantFetchEoBlacklistedDomains: "Can't load blacklisted domains",
      cantDeleteEoBlacklistedDomains: "Can't delete blacklisted domain(s)",
      deleteSuccess: (count) => {
        return `Blacklisted ${buildWordInPastTenseWithPluralization(
          'domain',
          count,
        )} deleted successfully.`;
      },
    },
    addEoBlacklistedDomainsPopup: {
      inputs: {
        domain: {
          label: 'Blacklisted domain names:',
          hint: 'Please add one domain name per line',
        },
      },
      title: 'Add domain names to blacklist from outreach',
      applyBlacklistedDomainsMode: {
        title: 'Which campaigns should we apply this blacklist to?',
        applyToAllCampaigns: 'All campaigns',
        applyToSpecificBrand: 'For a specific brand',
      },
    },
    table: {
      blankState: {
        body: 'You can manually add blacklisted domains',
        title: "You don't have any blacklisted domains yet",
      },
      headers: {
        domain: 'Domain Name',
        brandName: 'Associated brand',
        addedBy: 'Added by',
        createdAt: 'Created',
      },
      eoBlacklistedDomainsCount: (count) => `${pluralize('domain', count, true)}`,
    },
  },
};
