import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import ChartsDatapickerComponent from './ChartsDatapickerComponent';

import { CHARTS_DEFAULT_DATE_RANGE } from 'utils/constants';

import withQueryParams, {
  withQueryParamsPropTypes,
} from 'decorators/withQueryParams';


const ChartsDatapickerContainer = ({
  changeQueryParams,
  popupAlign,
  queryParams,
}) => {
  const filters = queryParams.filters || {};
  const formatDate = (date) => format(date, 'yyyy-MM-dd');

  function handleChangeDateRange(dateStart, dateEnd) {
    const newQueryParams = {
      ...queryParams,
      filters: {
        ...filters,
        dateStart: formatDate(dateStart),
        dateEnd: formatDate(dateEnd),
      },
    };

    changeQueryParams(newQueryParams);
  }

  const dateStart = filters.dateStart || CHARTS_DEFAULT_DATE_RANGE.dateStart;
  const dateEnd = filters.dateEnd || CHARTS_DEFAULT_DATE_RANGE.dateEnd;

  return (
    <ChartsDatapickerComponent
      dateEnd={dateEnd}
      dateStart={dateStart}
      onChangeDateRange={handleChangeDateRange}
      popupAlign={popupAlign}
    />
  );
};

ChartsDatapickerContainer.propTypes = {
  changeQueryParams: PropTypes.func.isRequired,
  popupAlign: PropTypes.oneOf(['left', 'right']),
  ...withQueryParamsPropTypes,
};

export default withQueryParams(ChartsDatapickerContainer);
