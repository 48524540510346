import React from 'react';
import { DEFAULT_PROSPECT_ATTRIBUTES } from '../constants/defaultProspectAttributes';
import { translate } from 'common/i18n';

export default function buildProspectAttrOptions(eoCustomFields) {
  const defaultProspectAttrOptions = DEFAULT_PROSPECT_ATTRIBUTES.map((field) => {
    const label =
      field === 'email' ? (
        <span className="prospects-remapping-table__required-wrapper">
          {translate('prospects.prospectFormPopup.inputs.email.label')}{' '}
          <span className="label-component__required" title={translate('warnings.fieldIsRequired')}>
            *
          </span>
        </span>
      ) : (
        translate(`prospects.prospectFormPopup.inputs.${field}.label`)
      );

    return {
      value: field,
      label,
    };
  });

  const eoCustomFieldOptions = eoCustomFields.map(({ name }) => ({
    value: name,
    label: name,
  }));

  return [
    {
      label: translate('prospects.importProspectsPopup.remappingCsv.prospectAttrOptions.default'),
      options: defaultProspectAttrOptions,
    },
    {
      label: translate(
        'prospects.importProspectsPopup.remappingCsv.prospectAttrOptions.customFields',
      ),
      options: eoCustomFieldOptions,
    },
  ];
}
