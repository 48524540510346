import { processDisavowFailure, processDisavowSuccess } from 'actions/accountDashboardActions';

const handleDisavowStatus = (dispatch, data) => {
  const { processDisavowStatus } = data;

  switch (processDisavowStatus) {
    case 'failure':
      dispatch(processDisavowFailure(data));
      break;
    case 'finished':
      dispatch(processDisavowSuccess(data));
      break;
  }
};

export default handleDisavowStatus;
