import React from 'react';
import PropTypes from 'prop-types';

import Pages from '../Pages';


const CompetitorPagesContainer = ({ pageChildType, pageParentType, pageParentId }) => {
  return (
    <Pages
      pageParentId={pageParentId}
      pageParentType={pageParentType}
      pageChildType={pageChildType}
    />
  );
};

CompetitorPagesContainer.propTypes = {
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageParentType: PropTypes.oneOf(['brand_page', 'brand']).isRequired,
};

export default CompetitorPagesContainer;
