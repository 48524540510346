export const FETCH_CARD_INFO__FAILURE = 'FETCH_CARD_INFO__FAILURE';
export const FETCH_CARD_INFO__START = 'FETCH_CARD_INFO__START';
export const FETCH_CARD_INFO__SUCCESS = 'FETCH_CARD_INFO__SUCCESS';
export const FETCH_CHARGES__FAILURE = 'FETCH_CHARGES__FAILURE';
export const FETCH_CHARGES__START = 'FETCH_CHARGES__START';
export const FETCH_CHARGES__SUCCESS = 'FETCH_CHARGES__SUCCESS';

export function fetchCardInfoStart() {
  return { type: FETCH_CARD_INFO__START };
}

export function fetchCardInfoSuccess(cardInfo) {
  return { type: FETCH_CARD_INFO__SUCCESS, cardInfo };
}

export function fetchCardInfoFailure(errors) {
  return { type: FETCH_CARD_INFO__FAILURE, errors };
}

export function fetchChargesStart() {
  return { type: FETCH_CHARGES__START };
}

export function fetchChargesSuccess(charges) {
  return { type: FETCH_CHARGES__SUCCESS, charges };
}

export function fetchChargesFailure(errors) {
  return { type: FETCH_CHARGES__FAILURE, errors };
}
