import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoDripListFormPopupComponent from './EoDripListFormPopupComponent';

import { createEoDripList, updateEoDripList } from 'api/eoDripList';
import { currentSubscriptionSelector } from 'selectors/railsContextSelectors';
import { fetchSimpleBrands } from 'api/simpleBrand';
import { eoDripListShape } from 'common/propTypesShapes/eoDripLists';
import { optionsSelector } from 'selectors';
import ConnectStoreHOC from 'startup/connect_store_hoc';

const EoDripListFormPopupContainer = ({
  brandsOptions,
  currentSubscription,
  dispatch,
  onSubmitCb,
  onClose,
  tableRowData: eoDripList,
}) => {
  React.useEffect(() => {
    fetchSimpleBrands(dispatch);
  }, []);

  async function handleSave(eoDripListFormData) {
    const resp = eoDripList
      ? await updateEoDripList(dispatch, eoDripListFormData)
      : await createEoDripList(dispatch, eoDripListFormData);

    return resp;
  }

  const emailsValidationCreditsCount = currentSubscription.get('emailsValidationCreditsCount', 0);

  return (
    <EoDripListFormPopupComponent
      emailsValidationCreditsCount={emailsValidationCreditsCount}
      immutableBrandsOptions={brandsOptions}
      eoDripList={eoDripList}
      onCancel={onClose}
      onSave={handleSave}
      onSubmitCb={onSubmitCb}
    />
  );
};

EoDripListFormPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  onSubmitCb: PropTypes.func,
  tableRowData: eoDripListShape,
};

function select(state) {
  const currentSubscription = currentSubscriptionSelector(state);
  const options = optionsSelector(state);
  const brandsOptions = options.get('brandsOptions');

  return {
    brandsOptions,
    currentSubscription,
  };
}

export default compose(ConnectStoreHOC, connect(select))(EoDripListFormPopupContainer);
