import { fromJS, Map as iMap } from 'immutable';

import { camelizeObjectDeep } from './utils';

import { FETCH_EO_CAMPAIGN_METRICS__SUCCESS } from 'actions/eoCampaignActions';

const initialState = iMap();

export default function eoCampaignMetricsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_CAMPAIGN_METRICS__SUCCESS: {
      const { eoCampaignMetrics } = action;

      state = fromJS(camelizeObjectDeep(eoCampaignMetrics));

      break;
    }
  }

  return state;
}
