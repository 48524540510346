import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import { isInteger } from 'lodash';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  brandContextShape,
  brandPagesMap,
  marketerShape,
  optionsMap,
  pagyShape,
  policiesShape,
  subscriptionPoliciesShape,
  subscriptionShape,
} from 'common/prop_types_shapes';

import { updateBrand } from 'api/brand';
import { fetchCurrentSubscription } from 'api/subscription';
import { fetchPagePercentage } from 'api/brand_page';
import { fetchDlSources } from 'api/dl_source';
import { fetchPageTypes } from 'api/page_type';
import { fetchPercentageSchemes } from 'api/percentage_scheme';

import { openSubscriptionLimitReachedPopup } from 'actions/popup_actions';
import { fetchBrandsContextSuccess } from 'actions/brandsContextActions';

import {
  currentIdSelector,
  optionsSelector,
  pagesByParentSelector,
  pagesPagyByBrandIdSelector,
} from 'selectors';
import { currentBrandContextSelector } from 'selectors/brandsContextSelector';

import smartFetchPagesService from 'pages/Pages/services/smartFetchPagesService';

import {
  currentSubscriptionSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import AtpContainer from 'components/atp/atp_container';

const ATPPage = (props) => {
  const { brandId, dispatch, queryParams, changeQueryParams } = props;

  const [isDataFetching, setIsDataFetching] = React.useState(true);

  async function smartFetchPages() {
    const { pagyItemsCount, pageNumber, search } = queryParams.pagesFilter || {};

    const fetchingParams = {
      pagyItemsCount,
      pageNumber,
      search,
    };

    await smartFetchPagesService(dispatch, 'brand', brandId, fetchingParams);
  }

  React.useEffect(() => {
    const fetchData = () => {
      setIsDataFetching(true);

      Promise.all([
        fetchPageTypes(dispatch),
        fetchPercentageSchemes(dispatch),
        fetchDlSources(dispatch),
      ]).then(() => setIsDataFetching(false));
    };

    fetchData();
  }, [brandId]);

  React.useEffect(() => {
    smartFetchPages();
  }, [brandId, JSON.stringify(queryParams.pagesFilter)]);

  function handleClickUpgradeSubscription(event) {
    event && event.preventDefault();

    dispatch(openSubscriptionLimitReachedPopup({}));
  }

  const handleFetchCurrentSubscription = async () => await fetchCurrentSubscription(dispatch);

  async function handleRecalculatePercentage(brandPageId) {
    await fetchPagePercentage(dispatch, brandPageId);
  }

  async function handleCloseBrandInfoSteps() {
    const data = { id: brandId, showInfoSteps: false };
    const showMessage = false;

    await updateBrand(dispatch, data, showMessage);
    dispatch(fetchBrandsContextSuccess(brandId, { showInfoSteps: false }));
  }

  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newPagesFilterParams = {
        ...queryParams.pagesFilter,
        pageNumber: value,
      };

      changeQueryParams({
        ...queryParams,
        pagesFilter: newPagesFilterParams,
      });
    }
  };

  function handlePagesPagerItemsSelectChange({ value }) {
    const newPagesFilterParams = {
      ...queryParams.pagesFilter,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams({
      ...queryParams,
      pagesFilter: newPagesFilterParams,
    });
  }

  const funcProps = {
    onPagesPagerClick: handlePagesPagerClick,
    onPagesPagerItemsSelectChange: handlePagesPagerItemsSelectChange,
    onClickUpgradeSubscription: handleClickUpgradeSubscription,
    onCloseBrandInfoSteps: handleCloseBrandInfoSteps,
    onFetchCurrentSubscription: handleFetchCurrentSubscription,
    onRecalculatePercentage: handleRecalculatePercentage,
  };

  return <AtpContainer {...props} {...funcProps} isDataFetching={isDataFetching} />;
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const brand = currentBrandContextSelector(state, ownProps);
  const brandPages = pagesByParentSelector(state, ownProps).filter(
    (item) => item.get('status', 'not_in_atp') === 'in_atp',
  );
  const currentMarketer = state.getIn(['railsContext', 'cmarketer']);
  const options = optionsSelector(state, ownProps);
  const anchorTypeOptions = options.get('anchorTypeOptions');
  const dlSourcesOptions = options.get('dlSourcesOptions');
  const nofollowOptions = options.get('nofollowOptions');
  const pageTypeOptions = options.get('pageTypeOptions');
  const statusOptions = options.get('linkStatusOptions');
  const pagesPagy = pagesPagyByBrandIdSelector(state, brandId);
  const policies = state.getIn(['railsContext', 'policies']);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  return {
    anchorTypeOptions,
    brand,
    brandId,
    brandPages,
    currentMarketer,
    currentSubscription,
    dlSourcesOptions,
    nofollowOptions,
    pageParentId: brandId,
    pageParentType: 'brand',
    pageTypeOptions,
    pagesPagy,
    policies,
    statusOptions,
    subscriptionPolicies,
  };
}

ATPPage.propTypes = {
  anchorTypeOptions: optionsMap,
  brand: brandContextShape,
  brandId: PropTypes.string,
  brandPages: brandPagesMap,
  currentMarketer: marketerShape,
  currentSubscription: subscriptionShape,
  dispatch: PropTypes.func,
  dlSourcesOptions: optionsMap,
  nofollowOptions: optionsMap,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  pageTypeOptions: optionsMap,
  pagesPagy: pagyShape,
  policies: policiesShape,
  somethingIsChanging: PropTypes.bool,
  somethingIsLoading: PropTypes.bool,
  statusOptions: optionsMap,
  subscriptionPolicies: subscriptionPoliciesShape,
  ...withQueryParamsPropTypes,
};

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(ATPPage);
