export default {
  notifications: {
    title: "Notifications center",
    viewAll: "View all notifications",
    unreadCounter: {
      today: "Today",
      yesterday: "Yesterday",
      days: "d",
    },
    actions: {
      markAllAsRead: 'Mark All as Read',
    },
  },
};
