import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';
import * as edit from 'react-edit';
import { cloneDeep, findIndex, get, upperCase } from 'lodash';
import { fromJS } from 'immutable';
import moment from 'moment';

import BacklinkPopup from './BacklinkPopup';

import {
  insertNotAvailableIfNull,
  buildSelectedItems,
  processAhrefsOrMozMetricValue,
} from 'common/utils';

import {
  addCSSClassToCellInTable,
  generateDestinationLinkStatusForAtp,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
} from 'common/tables/utils';

import { checkboxColumn, reactSelectColumn, textColumn } from 'common/tables/columns';
import columnsGenerator, { columnSelect } from 'common/tables/columns_generator';
import { optionValueOrValue } from 'components_linkio/Select/utils';
import { backlinksArray, optionsArray } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import BadgeComponent from 'components_linkio/badge_component';
import Checkbox from 'components_linkio/Checkbox';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';
import Spinner from 'components_linkio/Spinner';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import './backlinksTable.scss';

const selectProps = {
  blurInputOnSelect: true,
  autoFocus: true,
  isClearable: true,
  openMenuOnFocus: true,
  skinOptions: {
    inTable: true,
  },
  isCreatable: true,
  placeholder: translate('destinationLink.source.placeholder'),
};

export default class BacklinksTableComponent extends React.PureComponent {
  static propTypes = {
    backlinks: backlinksArray,
    backlinksCount: PropTypes.number,
    canUseCrawlers: PropTypes.bool,
    dlSourcesOptions: optionsArray,
    onFetchCurrentSubscription: PropTypes.func.isRequired,
    onTableHeaderClick: PropTypes.func,
    onToggleSelectedRows: PropTypes.func,
    onUpdateBacklink: PropTypes.func.isRequired,
    onUpdateRows: PropTypes.func.isRequired,
    selectedBacklinksIds: PropTypes.arrayOf(PropTypes.number),
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.oneOf(['asc', 'desc']),
  };

  backlinksTableNode = () => document.querySelector('.backlinks-table');

  headerValueFormatter =
    (options = {}) =>
    (_value, extra) => {
      const { onTableHeaderClick, sortBy, sortingOrder } = this.props;
      const { withExplanationMessage } = options;
      const { property } = extra;

      const label =
        property === 'firstSeen'
          ? this.buildDatesHeader()
          : translate(`backlinks.table.headers.${property}`);

      return (
        <HeaderFieldWithSorting
          appModule="backlinks"
          label={label}
          onClick={onTableHeaderClick}
          property={property}
          sortBy={sortBy}
          sortingOrder={sortingOrder}
          withExplanationMessage={withExplanationMessage}
        />
      );
    };

  buildDatesHeader = () => {
    return (
      <div>
        <div>{translate('backlinks.table.headers.datesColumn.firstSeen')}</div>
        <div>{translate('backlinks.table.headers.datesColumn.lastChecked')}</div>
      </div>
    );
  };

  buildRowsWithSelectedBacklinks = () => {
    const { backlinks, selectedBacklinksIds } = this.props;
    return buildSelectedItems(backlinks, selectedBacklinksIds);
  };

  isAllSelected = () => {
    const { backlinks } = this.props;

    const rowsWithSelectedBacklinks = this.buildRowsWithSelectedBacklinks();

    const selectedRowsCount = rowsWithSelectedBacklinks.filter(
      (backlink) => backlink.selected,
    ).length;
    const rowsCount = backlinks.length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  };

  handleOnToggleSelectRow = (backlinkId) => (event) => {
    const isSelected = event.target.checked;

    this.props.onToggleSelectedRows([backlinkId], isSelected);
  };

  handleOnToggleSelectAll = (event) => {
    const isSelectedAll = event.target.checked;

    const backlinksIds = this.props.backlinks.map((backlink) => backlink.id);

    this.props.onToggleSelectedRows(backlinksIds, isSelectedAll);
  };

  headerCheckboxFormatters = () => {
    return (
      <Checkbox
        checked={this.isAllSelected()}
        className="backlinks-table__checkbox"
        onChange={this.handleOnToggleSelectAll}
      />
    );
  };

  cellCheckboxFormatters = (_value, extra) => {
    const {
      rowData: { selected, id },
    } = extra;

    return (
      <Checkbox
        checked={selected}
        className="backlinks-table__checkbox"
        onChange={this.handleOnToggleSelectRow(id)}
      />
    );
  };

  generateColumns = (withAbility) => {
    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [this.headerCheckboxFormatters],
        cellFormatters: [this.cellCheckboxFormatters],
        className: 'backlinks-table__checkbox-column',
      }),
      textColumn({
        name: 'publishedLink',
        headerValueFormatter: this.headerValueFormatter(),
        cellValueFormatter: this.publishedLinkCellValueFormatter,
        className: 'backlinks-table__published-link-column',
      }),
      textColumn({
        name: 'ahrefsRank',
        headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
        cellValueFormatter: this.ahrefsMetricsValueFormatter,
        className: 'backlinks-table__ahrefs-rank-column',
      }),
      textColumn({
        name: 'ahrefsDomainRating',
        headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
        cellValueFormatter: this.ahrefsMetricsValueFormatter,
        className: 'backlinks-table__ahrefs-domain-rating-column',
      }),
      // textColumn({
      //   name: 'mozDomainAuthority',
      //   headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
      //   cellValueFormatter: this.mozMetricsValueFormatter,
      //   className: 'backlinks-table__moz-domain-authority',
      // }),
      // textColumn({
      //   name: 'mozPageAuthority',
      //   headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
      //   cellValueFormatter: this.mozMetricsValueFormatter,
      //   className: 'backlinks-table__moz-page-authority',
      // }),
      // textColumn({
      //   name: 'mozSubdomainSpamScore',
      //   headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
      //   cellValueFormatter: this.mozMetricsValueFormatter,
      //   className: 'backlinks-table__moz-subdomain-spam-score',
      // }),
      textColumn({
        name: 'anchorText',
        headerValueFormatter: this.headerValueFormatter(),
        cellValueFormatter: this.anchorTextCellValueFormatter,
        className: 'backlinks-table__anchor-text-column',
      }),
      textColumn({
        name: 'firstSeen',
        headerValueFormatter: this.headerValueFormatter(),
        cellValueFormatter: this.datesCellValueFormatter,
        className: 'backlinks-table__dates-column',
      }),
      reactSelectColumn({
        name: 'source',
        headerValueFormatter: this.headerValueFormatter(),
        className: 'backlinks-table__source-column',
        cellTransforms: [columnSelect(withAbility, this.props.dlSourcesOptions, selectProps)],
      }),
    ]);
  };

  editable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowData }) => {
      const { backlinks, onUpdateRows } = this.props;

      const index = findIndex(backlinks, { id: rowData.id });
      const clonedRows = cloneDeep(backlinks);
      clonedRows[index].editing = columnIndex;

      addCSSClassToCellInTable(
        this.backlinksTableNode(),
        index,
        columnIndex,
        TABLE_CELL_ACTIVE_CLASS,
      );

      onUpdateRows(clonedRows);
    },

    onValue: async ({ value, rowData, property }) => {
      const { backlinks, onUpdateBacklink, onUpdateRows } = this.props;

      const index = findIndex(backlinks, { id: rowData.id });
      const currentValue = rowData[property] || '';
      const newValue = optionValueOrValue(value) || '';
      const clonedRow = cloneDeep(rowData);
      const clonedRows = cloneDeep(backlinks);

      removeCSSClassFromTableCells(this.backlinksTableNode(), TABLE_CELL_ACTIVE_CLASS);

      const needToUpdateBacklink = String(currentValue) !== String(newValue);

      if (needToUpdateBacklink) {
        clonedRow[property] = newValue;

        await onUpdateBacklink(clonedRow, property, newValue);
      }

      Reflect.deleteProperty(clonedRow, 'editing');
      clonedRows[index] = clonedRow;

      onUpdateRows(clonedRows);
    },
  });

  ahrefsMetricsValueFormatter = (value, extra) => {
    const ahrefsMetricsUploadingInProgress = get(
      extra,
      'rowData.ahrefsMetricsUploadingInProgress',
      false,
    );

    if (ahrefsMetricsUploadingInProgress) {
      return <Spinner isBlack />;
    }

    return processAhrefsOrMozMetricValue(value);
  };

  // mozMetricsValueFormatter = (value, extra) => {
  //   const mozMetricsUploadingInProgress = get(
  //     extra,
  //     'rowData.mozMetricsUploadingInProgress',
  //     false,
  //   );

  //   if (mozMetricsUploadingInProgress) {
  //     return <Spinner isBlack />;
  //   }

  //   return processAhrefsOrMozMetricValue(value);
  // };

  publishedLinkCellValueFormatter = (value, extra) => {
    const { canUseCrawlers, onFetchCurrentSubscription } = this.props;
    const { property, rowData } = extra;

    const { crawlingInProgress, errors, googleIndexedInProgress, publishedLinkPageTitle, id } =
      rowData;

    const dlStatus = generateDestinationLinkStatusForAtp(fromJS(rowData), canUseCrawlers).map(
      (row) => {
        const { status } = row;
        const text = upperCase(status);
        const color = {
          blue: status === 'processing',
          green: status === 'included',
          orange: status === 'unvalidated',
          red: status === 'excluded',
          warning: status === 'error',
        };
        return <BadgeComponent small {...color} key={text} text={text} />;
      },
    );

    const validationError = (errors || {})[property];

    return (
      <>
        <p>{publishedLinkPageTitle}</p>
        {!validationError && (
          <ToggleShowPopup
            className="atp-table__link-status"
            Popup={
              <BacklinkPopup
                destinationLink={fromJS(rowData)}
                destinationLinkId={id}
                onFetchCurrentSubscription={onFetchCurrentSubscription}
              />
            }
          >
            {(crawlingInProgress || googleIndexedInProgress) && (
              <div className="atp-table__spinner_wrapper">
                <Spinner isBlack />
              </div>
            )}
            {dlStatus}
          </ToggleShowPopup>
        )}
        <a className="text" href={value} target="_blank" rel="nofollow noopener noreferrer">
          {value}
        </a>
        {validationError && <span className="cell_error small">{validationError}</span>}
      </>
    );
  };

  anchorTextCellValueFormatter = (value, extra) => {
    const { link, textPre, textPost } = extra.rowData;

    return (
      <>
        {this.renderAnchorText(value, textPre, textPost)}
        <a className="text" href={link} target="_blank" rel="nofollow noopener noreferrer">
          {link}
        </a>
      </>
    );
  };

  renderAnchorText = (anchorText, textPre, textPost) => {
    if (anchorText === '') {
      return (
        <BadgeComponent small text={upperCase(translate('dlDetailsPopup.tables.status.noText'))} />
      );
    }

    if (!anchorText) {
      return (
        <BadgeComponent small text={upperCase(translate('dlDetailsPopup.tables.status.noData'))} />
      );
    }

    return (
      <p>
        <span className="backlinks-table__anchor-text_pre">{textPre} </span>
        <span className="backlinks-table__anchor-text_main">{anchorText}</span>
        <span className="backlinks-table__anchor-text_post"> {textPost}</span>
      </p>
    );
  };

  datesCellValueFormatter = (_value, extra) => {
    const { dlCrawlingResult, publishedDate } = extra.rowData;

    const firstSeenDate = publishedDate
      ? moment(new Date(publishedDate).toISOString()).format('MM/DD/YYYY')
      : null;
    const lastCheckedDate = dlCrawlingResult ? moment(dlCrawlingResult.createdAt).fromNow() : null;

    return (
      <>
        <span>{insertNotAvailableIfNull(firstSeenDate)}</span>
        <span>{insertNotAvailableIfNull(lastCheckedDate)}</span>
      </>
    );
  };

  render() {
    const { backlinksCount } = this.props;

    const columns = this.generateColumns(this.editable);
    const rowsWithSelectedBacklinks = this.buildRowsWithSelectedBacklinks();

    return (
      <>
        {backlinksCount > 0 && (
          <span>{translate('backlinks.table.backlinksCount')(backlinksCount)}</span>
        )}
        <Table.Provider className="backlinks-table" columns={columns}>
          <Table.Header />
          <Table.Body rowKey="id" rows={rowsWithSelectedBacklinks} />
        </Table.Provider>
      </>
    );
  }
}
