import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { bulkUpdatePageTypes, fetchPageTypes } from 'api/page_type';
import { fetchPercentageSchemes } from 'api/percentage_scheme';
import { translate } from 'common/i18n';

import { optionsSelector, somethingIsLoadingSelector } from 'selectors';

import { policiesSelector } from 'selectors/railsContextSelectors';

import { optionsMap, percentageSchemesMap, policiesShape } from 'common/prop_types_shapes';

import Breadcrumbs from 'components/NewBreadcrumbs';
import PageTypeDefaultsTableComponent from 'components/percentage_settings/page_type_defaults_table_component';
import SavedPercentagesComponent from 'components/percentage_settings/saved_percentages_component';
import buildGroupedPercentageSchemeOptionsService from '../Pages/PagesList/PageItem/PageDls/DlsPercentage/services/buildGroupedPercentageSchemeOptionsService';

class PercentageSettingsPage extends Component {
  static propTypes = {
    anchorTypeOptions: optionsMap,
    dispatch: PropTypes.func,
    pageTypeOptions: optionsMap,
    percentageSchemeOptions: optionsMap,
    percentageSchemes: percentageSchemesMap,
    policies: policiesShape.isRequired,
    somethingIsLoading: PropTypes.bool,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    Promise.all([fetchPageTypes(dispatch), fetchPercentageSchemes(dispatch)]);
  }

  handleSavePageTypeDefaultsClick = async (pageTypes) => {
    const { dispatch } = this.props;

    const { type } = await bulkUpdatePageTypes(dispatch, pageTypes);

    if (type === 'error') {
      return;
    }

    fetchPercentageSchemes(dispatch);
  };

  groupedAndSortedPercentageSchemeOptions = () => {
    const { percentageSchemeOptions } = this.props;
    const groupedOptions = buildGroupedPercentageSchemeOptionsService({
      competitorsGroupInvisible: true,
      hideActions: true,
      percentageSchemeOptions,
    });
    const sortedOptions = groupedOptions.map((group) => {
      const sortedOptions = sortBy(group.options, (option) => option.label);
      return {
        label: group.label,
        options: sortedOptions,
      };
    });

    return sortedOptions;
  };

  render() {
    const {
      anchorTypeOptions,
      pageTypeOptions,
      percentageSchemes,
      policies,
      somethingIsLoading,
    } = this.props;

    const nonCompetitorsPercentageSchemes = percentageSchemes.filter(
      (scheme) => !scheme.get('brandPageId'),
    );

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.TextItem>{translate('sidebar.percentageSettings')}</Breadcrumbs.TextItem>
        </Breadcrumbs>
        {!somethingIsLoading && (
          <div className="page__wrapper">
            <PageTypeDefaultsTableComponent
              onSaveClick={this.handleSavePageTypeDefaultsClick}
              pageTypeOptions={pageTypeOptions}
              percentageSchemeOptions={this.groupedAndSortedPercentageSchemeOptions()}
              policies={policies}
            />
            <SavedPercentagesComponent
              anchorTypeOptions={anchorTypeOptions}
              percentageSchemes={nonCompetitorsPercentageSchemes}
              policies={policies}
            />
          </div>
        )}
      </>
    );
  }
}

function select(state, ownProps) {
  const policies = policiesSelector(state, ownProps);
  const somethingIsLoading = somethingIsLoadingSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);
  const percentageSchemeOptions = options.get('percentageSchemeOptions');
  const anchorTypeOptions = options.get('anchorTypeOptions');
  const pageTypeOptions = options.get('pageTypeOptions');
  const percentageSchemes = state.get('percentageSchemes');

  return {
    anchorTypeOptions,
    pageTypeOptions,
    percentageSchemeOptions,
    percentageSchemes,
    policies,
    somethingIsLoading,
  };
}

export default ConnectStoreHOC(connect(select)(PercentageSettingsPage));
