export default {
  getBacklinksSuggestionPopup: {
    titles: {
      modal: "Don't Have Ahrefs Account?",
    },
    contactMe: 'May we contact you to follow up on your feedback?',
    buttons: {
      cancel: 'Cancel',
      send: 'Send',
    },
    labels: {
      suggestion: "What's your favorite way to get the backlinks?",
      waysToGetBacklins: {
        beginning: 'Please check ',
        ending: ' to quickly find your links for free',
        link: {
          title: 'a couple of ways',
          href: 'https://www.linkio.com/help-center/how-to-find-and-import-links-if-you-dont-have-ahrefs/',
        },
      },
    },
    placeholders: {
      suggestion: 'Tell us how you check your backlinks data so that we could integrate your favorite source!',
    },
    messages: {
      thanksForSuggestion: 'Thanks for sharing your thoughts',
      noText: 'Please tell us something',
    },
  },
};
