import React from 'react';

import { eoDashboardMetricsMap } from 'common/propTypesShapes/eoMetrics';
import { translate } from 'common/i18n';

import LineChart from 'components_linkio/LineChart';

const EoDashboardChartsComponent = ({ eoDashboardMetrics }) => {
  const { metricsByDate = [] } = eoDashboardMetrics.toJS();

  return (
    <div className="eo-dashboard-charts">
      <LineChart
        appModule="emailOutreach"
        chartData={metricsByDate}
        title={translate('chart.titles.volumeStats')}
      />
    </div>
  );
};

EoDashboardChartsComponent.propTypes = {
  eoDashboardMetrics: eoDashboardMetricsMap,
};

export default EoDashboardChartsComponent;
