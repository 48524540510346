import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { format } from 'date-fns';

import CalendarIcon from 'common/icons/calendar';
import DownArrowIcon from 'common/icons/down_arrow';
import './dateRangeInput.scss';

const formatedDate = (date) => format(date, 'MMM dd, yyyy');

const DateRangeInputComponent = (
  {
    componentRef,
    dateEnd,
    dateStart,
    isShow,
    show,
  }
) => {
  const dateRangeInputClasses = cx('date-range-input', {
    'date-range-input_editable': show,
    'date-range-input_disable': isShow,
  });
  return (
    <div
      className={dateRangeInputClasses}
      ref={componentRef}
      onClick={show}
    >
      <CalendarIcon className="date-range-input__icon_calendar" />
      {formatedDate(dateStart)} - {formatedDate(dateEnd)}
      {show && <DownArrowIcon className="date-range-input__icon_chevron" />}
    </div>
  );
};

DateRangeInputComponent.propTypes = {
  componentRef: PropTypes.shape({ current: PropTypes.any }),
  dateEnd: PropTypes.instanceOf(Date),
  dateStart: PropTypes.instanceOf(Date),
  isShow: PropTypes.bool,
  show: PropTypes.func,
};

export default DateRangeInputComponent;
