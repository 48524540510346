import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import { translate } from '../../../common/i18n';

import ButtonComponent from '../../../components_linkio/button_component';
import LinkioLogo from '../../../common/icons/linkio_logo.svg';

import './dont_have_account_component.scss';

const labelText = (logIn) => {
  const translatePostfix = logIn ? 'alreadyHave' : 'dontHave';
  return translate(`authForms.dontHaveAccount.${translatePostfix}`);
};

const DontHaveAccountComponent = ({ logIn, signUp, history }) => {
  const handleSignUpClick = () => history.push(Routes.new_user_registration_path({ format: null }));

  const handleLoginClick = (event) => {
    event && event.preventDefault();
    history.push(Routes.new_user_session_path({ format: null }));
  };

  const labelClasses = classnames({
    'dont-have-account-component__label': true,
    'dont-have-account-component__label_is-hidden': logIn && signUp,
  });

  return (
    <div className="dont-have-account-component">
      <a
        className="dont-have-account-component__logo"
        href={translate('projectHomeURL')}
        title={translate('projectTitle')}
      >
        <img src={LinkioLogo} alt={translate('projectTitle')} />
      </a>
      <div className={labelClasses}>
        {labelText(logIn)}
      </div>
      {signUp &&
        <div className="dont-have-account-component__button">
          <ButtonComponent isGreen onClick={handleSignUpClick}>
            <span className="dont-have-account-component__button-text">
              {translate('authForms.dontHaveAccount.signUp')}
            </span>
          </ButtonComponent>
        </div>
      }
      {logIn &&
        <div className="dont-have-account-component__button">
          <ButtonComponent isBlue onClick={handleLoginClick}>
            <span className="dont-have-account-component__button-text">
              {translate('authForms.dontHaveAccount.signIn')}
            </span>
          </ButtonComponent>
        </div>
      }
    </div>
  );
};

DontHaveAccountComponent.propTypes = {
  logIn: PropTypes.bool,
  signUp: PropTypes.bool,
};

export default withRouter(DontHaveAccountComponent);
