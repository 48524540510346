import React from 'react';
import PropTypes from 'prop-types';

import AnchorPercentageComponent from './AnchorPercentageComponent';

import { percentageShape } from 'common/prop_types_shapes';

import MinusInRoundIcon from 'common/icons/minus_in_round';
import PlusInRoundIcon from 'common/icons/plus_in_round';

const sumGroupedPercentage = (percentage, currentPercentage) => {
  return percentage.reduce((sum, anchor) => {
    return Number(sum) + Number(currentPercentage ? anchor[currentPercentage] : anchor.ideal);
  }, 0.0);
};

const GroupedPercentageComponent = ({ groupedPercentage, isOpen, onClick, currentPercentage }) => {
  const icon = isOpen ? (
    <MinusInRoundIcon className="percentages__icon_grey" />
  ) : (
    <PlusInRoundIcon className="percentages__icon_grey" />
  );
  const groupActualPercentage = sumGroupedPercentage(
    groupedPercentage.percentage,
    currentPercentage,
  );
  const groupIdealPercentage = sumGroupedPercentage(groupedPercentage.percentage);

  return (
    <>
      <tr className={`percentages__anchor-row percentages__anchor-row_text-bold`}>
        <td className="percentages__icon" onClick={onClick}>
          {icon}
        </td>
        <td className="percentages__anchor-data percentages__anchor-data_text-align-left percentages__anchor-data_with-right-border">
          <span>{groupedPercentage.title}</span>
        </td>
        <td className="percentages__anchor-data percentages__anchor-data_with-right-border">
          <span>{Number(groupActualPercentage).toFixed(2)}%</span>
        </td>
        <td className="percentages__anchor-data">
          <span>{Number(groupIdealPercentage).toFixed(2)}%</span>
        </td>
      </tr>

      {isOpen &&
        groupedPercentage.percentage.map((anchorPercentage) => (
          <AnchorPercentageComponent
            key={anchorPercentage.anchorType}
            anchorPercentage={anchorPercentage}
            currentPercentage={currentPercentage}
          />
        ))}
    </>
  );
};

GroupedPercentageComponent.propTypes = {
  currentPercentage: PropTypes.string.isRequired,
  groupedPercentage: PropTypes.shape({
    title: PropTypes.string,
    percentage: PropTypes.arrayOf(percentageShape),
  }).isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default GroupedPercentageComponent;
