import React from 'react';

const DotsIcon = ({ isHorizontal, ...props }) => {
  return (
    <svg
      {...props}
      transform={isHorizontal && 'rotate(90)'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.583 45.583"
    >
      <path
        d="M22.793 12.196c-3.361 0-6.078-2.729-6.078-6.099C16.715 2.73 19.432 0 22.793 0c3.353 0 6.073 2.729 6.073
        6.097 0 3.369-2.721 6.099-6.073 6.099zm.001 16.693c-3.361 0-6.079-2.729-6.079-6.099 0-3.366 2.717-6.099
        6.078-6.099 3.353 0 6.073 2.732 6.075 6.099-.002 3.372-2.724 6.099-6.074 6.099zm0 16.694c-3.361
        0-6.079-2.729-6.079-6.099s2.717-6.098 6.078-6.098c3.353-.002 6.073 2.729 6.073 6.098s-2.722 6.099-6.072 6.099z"
      />
    </svg>
  );
};

DotsIcon.defaultProps = {
  cursor: 'pointer',
  fill: '#D6DADC',
  height: '16px',
  width: '16px',
};

export default DotsIcon;
