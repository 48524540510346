import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

const ImportFromGSCButtonComponent = ({ isLoading, onClick }) => (
  <ButtonComponent
    className="import-from-gsc-button"
    isSecondary
    isInitial
    isLoading={isLoading}
    onClick={onClick}
  >
    {translate('bulkPagesSetup.bulkActions.buttons.importFromGSC')}
  </ButtonComponent>
);

ImportFromGSCButtonComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ImportFromGSCButtonComponent;
