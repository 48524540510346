import React from 'react';
import cx from 'classnames';

export default function buildCampaignStatusBadge(status) {
  const statusClassNames = cx('eo-campaign-page__status-badge', {
    'eo-campaign-page__status-badge_blue': status === 'live',
    'eo-campaign-page__status-badge_orange': status === 'paused',
  });

  return <span className={statusClassNames}>{status.toUpperCase()}</span>;
}
