import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import * as Table from 'reactabular-table';
import cx from 'classnames';

import ProspectFormPopup from '../ProspectsBulkActions/ProspectFormPopup';

import columnsGenerator from 'common/tables/columns_generator';
import { addCSSClassToRowInTable, TABLE_ROW_ACTIVE_CLASS } from 'common/tables/utils';

import { buildSelectedItems } from 'common/utils';
import { checkboxColumn, textColumn } from 'common/tables/columns';
import { prospectsList } from 'common/propTypesShapes/prospects';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';

import { buildEmailWithValidityStatus } from './utils';

import './prospectsTable.scss';

const ProspectsTableComponent = ({
  canUpdate,
  isReadOnly,
  onRowClick,
  onTableHeaderClick,
  onToggleSelectedRows,
  prospects,
  prospectsCount,
  selectedIds,
  sorting,
}) => {
  const processedProspects = buildSelectedItems(prospects.toJS(), selectedIds);

  const headerValueFormatter = (options = {}) => (_value, extra) => {
    const { sortBy, sortingOrder } = sorting;
    const { withExplanationMessage } = options;
    const { property } = extra;

    const label = translate(`prospects.table.headers.${property}`);

    if (isReadOnly) {
      return label;
    }

    return (
      <HeaderFieldWithSorting
        appModule="prospects"
        label={label}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  };

  const nameValueFormatter = (value, extra) => {
    const {
      rowData: { firstName },
    } = extra;

    return `${firstName || ''} ${value || ''}`;
  };

  const emailValueFormatter = (_value, { rowData: prospect }) => {
    return buildEmailWithValidityStatus(prospect);
  };

  const dateValueFormatter = (value) => {
    return format(new Date(value), 'MMM d h:mm a');
  };

  const handleOnToggleSelectRow = (prospectId) => (event) => {
    const isSelected = event.target.checked;

    onToggleSelectedRows([prospectId], isSelected);
  };

  function handleOnToggleSelectAll(event) {
    const isSelectedAll = event.target.checked;

    const prospectsIds = processedProspects.map((prospect) => prospect.id);

    onToggleSelectedRows(prospectsIds, isSelectedAll);
  }

  function isAllSelected() {
    const selectedRowsCount = processedProspects.filter((prospect) => prospect.selected).length;
    const rowsCount = processedProspects.length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  }

  function cellCheckboxFormatters(_value, extra) {
    const {
      rowData: { selected, id },
    } = extra;

    return (
      <Checkbox
        checked={selected}
        className="prospects-table__checkbox"
        onChange={handleOnToggleSelectRow(id)}
      />
    );
  }

  function headerCheckboxFormatters() {
    return (
      <Checkbox
        checked={isAllSelected()}
        className="prospects-table__checkbox"
        onChange={handleOnToggleSelectAll}
      />
    );
  }

  function generateColumns() {
    return columnsGenerator(
      [
        !isReadOnly &&
          checkboxColumn({
            headerFormatters: [headerCheckboxFormatters],
            cellFormatters: [cellCheckboxFormatters],
            className: 'prospects-table__checkbox-column',
          }),
        textColumn({
          name: 'email',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: emailValueFormatter,
          Popup: canUpdate && !isReadOnly && ProspectFormPopup,
          className: 'prospects-table__email-column',
          isEditable: canUpdate && !isReadOnly,
        }),
        textColumn({
          name: 'lastName',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: nameValueFormatter,
        }),
        textColumn({
          name: 'organization',
          headerValueFormatter: headerValueFormatter(),
        }),
        textColumn({
          name: 'position',
          headerValueFormatter: headerValueFormatter(),
        }),
        textColumn({
          name: 'domain',
          headerValueFormatter: headerValueFormatter(),
        }),
        textColumn({
          name: 'eoDripListTitles',
          headerLabel: translate('prospects.table.headers.eoDripListTitle'),
        }),
        textColumn({
          name: 'lastEoCampaignTitle',
          headerLabel: translate('prospects.table.headers.lastEoCampaignTitle'),
        }),
        textColumn({
          name: 'updatedAt',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: dateValueFormatter,
        }),
      ].filter(Boolean),
    );
  }

  function handleRowClick(row, rowIndex) {
    const tableNode = document.querySelector('.prospects-table_read-only');
    const activeRow = tableNode.querySelector(`.${TABLE_ROW_ACTIVE_CLASS}`);

    activeRow && activeRow.classList.remove(TABLE_ROW_ACTIVE_CLASS);

    addCSSClassToRowInTable(tableNode, rowIndex, TABLE_ROW_ACTIVE_CLASS);
    
    onRowClick(row);
  }

  function onBodyRow(row, { rowIndex }) {
    if (!isReadOnly) {
      return void 0;
    }

    return {
      onClick: () => handleRowClick(row, rowIndex),
    };
  }

  const columns = generateColumns();

  const tableClassNames = cx('prospects-table', {
    'prospects-table_read-only': isReadOnly,
    'table-with-row-selection': isReadOnly,
  });

  return (
    <>
      {prospectsCount > 0 && !isReadOnly && (
        <p>{translate('prospects.table.prospectsCount')(prospectsCount)}</p>
      )}
      {isReadOnly && <p>{translate('prospects.table.readOnlyHint')}</p>}
      <Table.Provider className={tableClassNames} columns={columns}>
        <Table.Header />
        <Table.Body rowKey="id" rows={processedProspects} onRow={onBodyRow} />
      </Table.Provider>
    </>
  );
};

ProspectsTableComponent.propTypes = {
  canUpdate: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onRowClick: PropTypes.func,
  onTableHeaderClick: PropTypes.func,
  onToggleSelectedRows: PropTypes.func,
  prospects: prospectsList,
  prospectsCount: PropTypes.number,
  selectedIds: selectedIdsList,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default ProspectsTableComponent;
