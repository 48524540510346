export const ADD_PROSPECTS_TO_DRIP_LIST__FAILURE = 'ADD_PROSPECTS_TO_DRIP_LIST__FAILURE';
export const ADD_PROSPECTS_TO_DRIP_LIST__START = 'ADD_PROSPECTS_TO_DRIP_LIST__START';
export const ADD_PROSPECTS_TO_DRIP_LIST__SUCCESS = 'ADD_PROSPECTS_TO_DRIP_LIST__SUCCESS';
export const CREATE_PROSPECT__FAILURE = 'CREATE_PROSPECT__FAILURE';
export const CREATE_PROSPECT__START = 'CREATE_PROSPECT__START';
export const CREATE_PROSPECT__SUCCESS = 'CREATE_PROSPECT__SUCCESS';
export const DELETE_PROSPECTS_FROM_DRIP_LIST__FAILURE = 'DELETE_PROSPECTS_FROM_DRIP_LIST__FAILURE';
export const DELETE_PROSPECTS_FROM_DRIP_LIST__START = 'DELETE_PROSPECTS_FROM_DRIP_LIST__START';
export const DELETE_PROSPECTS_FROM_DRIP_LIST__SUCCESS = 'DELETE_PROSPECTS_FROM_DRIP_LIST__SUCCESS';
export const DELETE_PROSPECTS__FAILURE = 'DELETE_PROSPECTS__FAILURE';
export const DELETE_PROSPECTS__START = 'DELETE_PROSPECTS__START';
export const DELETE_PROSPECTS__SUCCESS = 'DELETE_PROSPECTS__SUCCESS';
export const FETCH_PROSPECTS__FAILURE = 'FETCH_PROSPECTS__FAILURE';
export const FETCH_PROSPECT__FAILURE = 'FETCH_PROSPECT__FAILURE';
export const FETCH_PROSPECTS__START = 'FETCH_PROSPECTS__START';
export const FETCH_PROSPECT__START = 'FETCH_PROSPECT__START';
export const FETCH_PROSPECTS__SUCCESS = 'FETCH_PROSPECTS__SUCCESS';
export const FETCH_PROSPECT__SUCCESS = 'FETCH_PROSPECT__SUCCESS';
export const START_CAMPAIGN_FOR_PROSPECTS__FAILURE = 'START_CAMPAIGN_FOR_PROSPECTS__FAILURE';
export const START_CAMPAIGN_FOR_PROSPECTS__START = 'START_CAMPAIGN_FOR_PROSPECTS__START';
export const START_CAMPAIGN_FOR_PROSPECTS__SUCCESS = 'START_CAMPAIGN_FOR_PROSPECTS__SUCCESS';
export const UNSUBSCRIBE_PROSPECT__FAILURE = 'UNSUBSCRIBE_PROSPECT__FAILURE';
export const UPDATE_PROSPECT__FAILURE = 'UPDATE_PROSPECT__FAILURE';
export const UPDATE_PROSPECT__START = 'UPDATE_PROSPECT__START';
export const UPDATE_PROSPECT__SUCCESS = 'UPDATE_PROSPECT__SUCCESS';
export const UPDATE_PROSPECTS__SUCCESS = 'UPDATE_PROSPECTS__SUCCESS';
export const IMPORT_PROSPECTS__FAILURE = 'IMPORT_PROSPECTS__FAILURE';
export const IMPORT_PROSPECTS__START = 'IMPORT_PROSPECTS__START';
export const IMPORT_PROSPECTS__SUCCESS = 'IMPORT_PROSPECTS__SUCCESS';
export const VALIDATE_PROSPECT_EMAILS__FAILURE = 'VALIDATE_PROSPECT_EMAILS__FAILURE';

export function addProspectsToDripListFailure(errors) {
  return { type: ADD_PROSPECTS_TO_DRIP_LIST__FAILURE, errors };
}

export function addProspectsToDripListStart() {
  return { type: ADD_PROSPECTS_TO_DRIP_LIST__START };
}

export function addProspectsToDripListSuccess(prospects) {
  return { type: ADD_PROSPECTS_TO_DRIP_LIST__SUCCESS, prospects };
}

export function startCampaignForProspectsFailure(errors) {
  return { type: START_CAMPAIGN_FOR_PROSPECTS__FAILURE, errors };
}

export function startCampaignForProspectsStart() {
  return { type: START_CAMPAIGN_FOR_PROSPECTS__START };
}

export function startCampaignForProspectsSuccess(prospects) {
  return { type: START_CAMPAIGN_FOR_PROSPECTS__SUCCESS, prospects };
}

export function createProspectFailure(errors) {
  return { type: CREATE_PROSPECT__FAILURE, errors };
}

export function createProspectStart() {
  return { type: CREATE_PROSPECT__START };
}

export function createProspectSuccess(prospect) {
  return { type: CREATE_PROSPECT__SUCCESS, prospect };
}

export function deleteProspectsFromDripListFailure(errors) {
  return { type: DELETE_PROSPECTS_FROM_DRIP_LIST__FAILURE, errors };
}

export function deleteProspectsFromDripListStart() {
  return { type: DELETE_PROSPECTS_FROM_DRIP_LIST__START };
}

export function deleteProspectsFromDripListSuccess(prospects) {
  return { type: DELETE_PROSPECTS_FROM_DRIP_LIST__SUCCESS, prospects };
}

export function deleteProspectsFailure(errors) {
  return { type: DELETE_PROSPECTS__FAILURE, errors };
}

export function deleteProspectsStart() {
  return { type: DELETE_PROSPECTS__START };
}

export function deleteProspectsSuccess(ids) {
  return { type: DELETE_PROSPECTS__SUCCESS, ids };
}

export function fetchProspectsFailure(errors) {
  return { type: FETCH_PROSPECTS__FAILURE, errors };
}

export function fetchProspectsStart() {
  return { type: FETCH_PROSPECTS__START };
}

export function fetchProspectsSuccess(prospects) {
  return { type: FETCH_PROSPECTS__SUCCESS, prospects };
}

export function unsubscribeProspectFailure(errors) {
  return { type: UNSUBSCRIBE_PROSPECT__FAILURE, errors };
}

export function updateProspectFailure(errors) {
  return { type: UPDATE_PROSPECT__FAILURE, errors };
}

export function updateProspectStart() {
  return { type: UPDATE_PROSPECT__START };
}

export function updateProspectSuccess(prospect) {
  return { type: UPDATE_PROSPECT__SUCCESS, prospect };
}

export function updateProspectsSuccess(prospects) {
  return { type: UPDATE_PROSPECTS__SUCCESS, prospects };
}

export function importProspectsFailure() {
  return { type: IMPORT_PROSPECTS__FAILURE };
}

export function importProspectsStart(marketer) {
  return { type: IMPORT_PROSPECTS__START, marketer };
}

export function importProspectsSuccess(marketer) {
  return { type: IMPORT_PROSPECTS__SUCCESS, marketer };
}

export function fetchProspectStart() {
  return { type: FETCH_PROSPECT__START };
}

export function fetchProspectFailure(errors) {
  return { type: FETCH_PROSPECT__FAILURE, errors };
}

export function fetchProspectSuccess(prospect) {
  return { type: FETCH_PROSPECT__SUCCESS, prospect };
}

export function validateProspectEmailsFailure(errors) {
  return { type: VALIDATE_PROSPECT_EMAILS__FAILURE, errors };
}
