import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import {
  notificationShape,
} from '../../../common/prop_types_shapes';

import './notificationItem.scss';


const NotificationItemComponent = ({ notification, onItemClick }) => {
  const body = notification.get('body');
  const createdAt = notification.get('createdAt');
  const link = notification.get('link');
  const title = notification.get('title');

  const notificationItemClasses = classnames({
    'notification-item': true,
    'notification-item_clickable': link,
  });

  const dateDiffString = moment(createdAt).fromNow();

  return (
    <div className={notificationItemClasses} onClick={onItemClick}>
      <span className="notification-item__title">{title}</span>
      <p className="notification-item__body">{body}</p>
      {dateDiffString &&
        <div className="notification-item__date">
          <span>{dateDiffString}</span>
        </div>
      }
    </div>
  );
};

NotificationItemComponent.propTypes = ({
  notification: notificationShape,
  onItemClick: PropTypes.func.isRequired,
});


export default NotificationItemComponent;
