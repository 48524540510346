import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import SearchProspectsInputComponent from './SearchProspectsInputComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

const SearchProspectsInputContainer = ({ changeQueryParams, queryParams }) => {
  function handleSubmitSearch(value) {
    const newQueryParams = {
      ...queryParams,
      search: value,
      pageNumber: 1,
    };

    changeQueryParams(newQueryParams);
  }

  return <SearchProspectsInputComponent onSubmitSearch={handleSubmitSearch} />;
};

SearchProspectsInputContainer.propTypes = {
  ...withQueryParamsPropTypes,
};

export default compose(withQueryParams, ConnectStoreHOC, connect())(SearchProspectsInputContainer);
