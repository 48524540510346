import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoEmailTemplateFoldersStart,
  fetchEoEmailTemplateFoldersSuccess,
  fetchEoEmailTemplateFoldersFailure,
  fetchCmarketerEoEmailTemplateFoldersAsOptionsStart,
  fetchCmarketerEoEmailTemplateFoldersAsOptionsSuccess,
  fetchCmarketerEoEmailTemplateFoldersAsOptionsFailure,
  fetchEoEmailTemplateFolderStart,
  fetchEoEmailTemplateFolderSuccess,
  fetchEoEmailTemplateFolderFailure,
  createEoEmailTemplateFolderStart,
  createEoEmailTemplateFolderSuccess,
  createEoEmailTemplateFolderFailure,
  updateEoEmailTemplateFolderStart,
  updateEoEmailTemplateFolderSuccess,
  updateEoEmailTemplateFolderFailure,
  deleteEoEmailTemplateFoldersStart,
  deleteEoEmailTemplateFoldersSuccess,
  deleteEoEmailTemplateFoldersFailure,
} from 'actions/eoEmailTemplateFolderActions';

import { resetSelectedIds } from 'actions/ui_actions';

import { translate } from 'common/i18n';

import { showSuccessMessage, showSuccessMessageFromTranslation } from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCase,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoEmailTemplateFolders(dispatch, params) {
  dispatch(fetchEoEmailTemplateFoldersStart());

  try {
    const resp = await getFromAPI(
      Routes.eo_email_template_folders_path(processObjToSnakeCaseDeep(params)),
    );
    dispatch(fetchEoEmailTemplateFoldersSuccess(resp.eo_email_template_folders));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoEmailTemplateFoldersFailure,
      translate('eoEmailTemplate.messages.cantFetchEoEmailTemplateFolders'),
    );
  }
}

// restrict folders with ones belong to current marketer
export async function fetchCmarketerEoEmailTemplateFoldersAsOptions(dispatch) {
  dispatch(fetchCmarketerEoEmailTemplateFoldersAsOptionsStart());

  try {
    const resp = await getFromAPI(Routes.eo_email_template_folders_as_options_path());
    dispatch(fetchCmarketerEoEmailTemplateFoldersAsOptionsSuccess(resp.eo_email_template_folders));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchCmarketerEoEmailTemplateFoldersAsOptionsFailure,
      translate('eoEmailTemplate.messages.cantFetchCmarketerEoEmailTemplateFoldersAsOptions'),
    );
  }
}

export async function fetchEoEmailTemplateFolder(dispatch, eoEmailTemplateId) {
  dispatch(fetchEoEmailTemplateFolderStart());

  try {
    const eoEmailTemplateFolder = await getFromAPI(
      Routes.eo_email_template_folder_path({ id: eoEmailTemplateId }),
    );
    dispatch(fetchEoEmailTemplateFolderSuccess(eoEmailTemplateFolder));

    return eoEmailTemplateFolder;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchEoEmailTemplateFolderFailure,
      translate('eoEmailTemplate.messages.cantFetchEoEmailTemplateFolder'),
    );
  }
}

export async function createEoEmailTemplateFolder(dispatch, data) {
  dispatch(createEoEmailTemplateFolderStart());

  try {
    const resp = await postToAPI(
      Routes.eo_email_template_folders_path(eoEmailTemplateObject(data)),
    );
    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(createEoEmailTemplateFolderSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoEmailTemplate.messages.createFolderSuccess');
    } else {
      dispatch(createEoEmailTemplateFolderFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createEoEmailTemplateFolderFailure,
      translate('eoEmailTemplate.messages.cantCreateEoEmailTemplateFolder'),
    );
  }
}

export async function updateEoEmailTemplateFolder(dispatch, data) {
  dispatch(updateEoEmailTemplateFolderStart());

  try {
    const resp = await patchToAPI(
      Routes.eo_email_template_folders_path({ id: data.id }),
      eoEmailTemplateObject(data),
    );

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(updateEoEmailTemplateFolderSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoEmailTemplate.messages.updateSuccess');
    } else {
      dispatch(updateEoEmailTemplateFolderFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoEmailTemplateFolderFailure,
      translate('eoEmailTemplate.messages.cantUpdateEoEmailTemplateFolder'),
    );
  }
}

export async function deleteEoEmailTemplateFolders(dispatch, ids) {
  dispatch(deleteEoEmailTemplateFoldersStart());

  try {
    await deleteToAPI(Routes.eo_email_template_folders_path({ ids }));

    dispatch(resetSelectedIds());
    dispatch(deleteEoEmailTemplateFoldersSuccess(ids));

    showSuccessMessage(
      dispatch,
      translate('eoEmailTemplate.messages.deleteFoldersSuccess')(ids.length),
    );
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoEmailTemplateFoldersFailure,
      translate('eoEmailTemplate.messages.cantDeleteEoEmailTemplateFolders'),
    );
  }
}

const eoEmailTemplateObject = (data) => ({ eo_email_template_folder: processObjToSnakeCase(data) });
