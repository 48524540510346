import React from 'react';
import PropTypes from 'prop-types';

import SearchEoBlacklistedDomainsInput from './SearchEoBlacklistedDomainsInput';
import DeleteEoBlacklistedDomainsButton from './DeleteEoBlacklistedDomainsButton';
import AddEoBlacklistedDomainButton from './AddEoBlacklistedDomainButton';

import './eoBlacklistedDomainsBulkActions.scss';
const EoBlacklistedDomainsBulkActionsComponent = ({ canCreate }) => {
  return (
    <div className="eo-blacklisted-domains-bulk-actions">
      <div className="eo-blacklisted-domains-bulk-actions__row">
        <div className="eo-blacklisted-domains-bulk-actions__row-item">
          <SearchEoBlacklistedDomainsInput />
          {canCreate && (
            <>
              <span className="vertical-separator" />
              <DeleteEoBlacklistedDomainsButton />
            </>
          )}
        </div>

        {canCreate && (
          <div className="eo-blacklisted-domains-bulk-actions__row-item">
            <AddEoBlacklistedDomainButton />
          </div>
        )}
      </div>
    </div>
  );
};

EoBlacklistedDomainsBulkActionsComponent.propTypes = {
  canCreate: PropTypes.bool,
};

export default EoBlacklistedDomainsBulkActionsComponent;
