import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoDomainsTableComponent from './EoDomainsTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { fetchEoDomains } from 'api/eoDomain';
import { eoDomainsList } from 'common/propTypesShapes/eoDomains';

import { eoDomainsSelector, eoDomainsPagySelector } from 'selectors/eoDomainsSelector';
import { uiSelector } from 'selectors';
import { policiesSelector } from 'selectors/railsContextSelectors';

import { resetSelectedIds, toggleSelectedIds } from 'actions/ui_actions';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const EoDomainsTableContainer = ({
  canUpdate,
  changeQueryParams,
  dispatch,
  eoDomains,
  eoDomainsPagy,
  queryParams,
  selectedIds,
}) => {
  const { pageNumber = 1, pagyItemsCount = 100, search = '', sorting = {} } = queryParams;

  React.useEffect(() => {
    const fetchingParams = {
      items: pagyItemsCount,
      page: pageNumber,
      search,
      sorting,
    };

    fetchEoDomains(dispatch, fetchingParams);
  }, [dispatch, pageNumber, pagyItemsCount, search, sorting.sortBy, sorting.sortingOrder]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectedIds());
    };
  }, [dispatch]);

  function handleToggleSelectedRows(eoDomainsIds, isSelected) {
    dispatch(toggleSelectedIds(eoDomainsIds, isSelected));
  }

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  const eoDomainsCount = eoDomainsPagy.get('count');

  return (
    <>
      {eoDomains.size > 0 && (
        <EoDomainsTableComponent
          canUpdate={canUpdate}
          onTableHeaderClick={handleTableHeaderClick}
          onToggleSelectedRows={handleToggleSelectedRows}
          eoDomains={eoDomains}
          eoDomainsCount={eoDomainsCount}
          selectedIds={selectedIds}
          sorting={sorting}
        />
      )}
      {eoDomains.size === 0 && (
        <BlankStateCtaComponent>
          <TitleText>
            <strong>{translate('eoDomain.table.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('eoDomain.table.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

EoDomainsTableContainer.propTypes = {
  canUpdate: PropTypes.bool,
  dispatch: PropTypes.func,
  eoDomains: eoDomainsList,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoDomains = eoDomainsSelector(state);
  const eoDomainsPagy = eoDomainsPagySelector(state);

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  const policies = policiesSelector(state);
  const canUpdate = policies.getIn(['emailOutreach', 'canUpdate']);

  return {
    canUpdate,
    eoDomains,
    eoDomainsPagy,
    selectedIds,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(EoDomainsTableContainer);
