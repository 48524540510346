import React from 'react';

const SpeakerWithWorldIcon = (props) => (
  <svg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.25.5A.25.25 0 005 .25H4a.25.25 0 00-.25.25v.369a3.52 3.52 0 01-2 .631h-1a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h.07l.443 1.329A.25.25 0 001.5 5h1a.25.25 0 00.237-.329l-.371-1.114c.495.09.97.286 1.384.574V4.5c0 .138.112.25.25.25h1a.25.25 0 00.25-.25v-4zM.75 2h1c.167 0 .334-.014.5-.035v1.07A3.978 3.978 0 001.75 3h-1V2zm1.403 2.5H1.68l-.333-1h.403c.024 0 .047.002.07.003l.333.997zm.597-1.37V1.87c.35-.09.687-.23 1-.41v2.08c-.313-.18-.65-.32-1-.41zm2 1.12h-.5V.75h.5v3.5zM9.823 10.073l-.5.5a.25.25 0 00-.073.177v.5c0 .138.112.25.25.25h.896l.427.427A.25.25 0 0011 12h.75a.25.25 0 00.177-.073l.75-.75A.25.25 0 0012.75 11v-.75a.25.25 0 00-.073-.177l-.5-.5A.25.25 0 0012 9.5h-.75a.25.25 0 00-.177.073l-.427.427H10a.25.25 0 00-.177.073zm.927.427a.25.25 0 00.177-.073l.427-.427h.543l.353.354v.543l-.604.603h-.543l-.426-.427A.25.25 0 0010.5 11h-.75v-.146l.354-.354h.646z" />
    <path d="M10.5 5.25a5.256 5.256 0 00-5.25 5.25 5.256 5.256 0 005.25 5.25 5.256 5.256 0 005.25-5.25 5.256 5.256 0 00-5.25-5.25zm4.75 5.25c0 .256-.026.505-.065.75H15a.25.25 0 00-.177.073l-.427.427h-.646a.25.25 0 00-.177.073l-.75.75a.25.25 0 00-.073.177v.75h-.5a.25.25 0 00-.177.073l-.427.427H11a.25.25 0 00-.177.073l-.5.5a.25.25 0 00-.073.177v.487a4.71 4.71 0 01-1.75-.435v-.948l.677-.677A.25.25 0 009.25 13v-.75a.25.25 0 00-.073-.177l-.75-.75a.25.25 0 00-.177-.073h-.646l-.677-.677a.25.25 0 00-.177-.073h-1c0-.28.03-.553.076-.82l.247.247A.25.25 0 006.25 10h1a.25.25 0 00.177-.073l.677-.677H9a.25.25 0 00.177-.073l.75-.75A.249.249 0 0010 8.25V7.5a.249.249 0 00-.073-.177l-.677-.677v-.723c.399-.11.817-.173 1.25-.173 2.62 0 4.75 2.13 4.75 4.75zm-6.5-4.412v.662c0 .066.026.13.073.177l.677.677v.542l-.604.604H8a.25.25 0 00-.177.073l-.677.677h-.792l-.394-.394a4.77 4.77 0 012.79-3.018zM5.777 11h.87l.676.677a.25.25 0 00.177.073h.646l.604.604v.543l-.677.676A.25.25 0 008 13.75v.781A4.752 4.752 0 015.777 11zm4.973 4.237v-.383l.354-.354h.646a.25.25 0 00.177-.073l.427-.427H13a.25.25 0 00.25-.25v-.896l.604-.604h.646a.25.25 0 00.177-.073l.392-.392a4.752 4.752 0 01-4.319 3.452zM4.5 11.5h.25v.5H4.5v-.5zM2.7 11.5h.45v.5H2.7v-.5zM3.6 11.5h.45v.5H3.6v-.5zM1.75 11.75c0 .138.112.25.25.25h.25v-.5h-.5v.25zM1.75 10.455h.5v.522h-.5v-.522zM1.75 9.41h.5v.522h-.5V9.41zM1.75 6.273h.5v.522h-.5v-.522zM1.75 7.318h.5v.523h-.5v-.523zM1.75 8.364h.5v.523h-.5v-.523zM1.75 5.5h.5v.25h-.5V5.5zM5.75 2H6v.5h-.25V2zM9.341 2h.477v.5h-.477V2zM10.296 2h.477v.5h-.477V2zM6.477 2h.478v.5h-.478V2zM8.386 2h.477v.5h-.477V2zM7.432 2h.477v.5h-.477V2zM11.75 2.25A.25.25 0 0011.5 2h-.25v.5h.5v-.25zM11.25 2.9h.5v.4h-.5v-.4zM11.25 3.7h.5v.4h-.5v-.4zM11.25 4.5h.5v.25h-.5V4.5z" />
  </svg>
);

SpeakerWithWorldIcon.defaultProps = {
  height: '16px',
  width: '16px',
};

export default SpeakerWithWorldIcon;
