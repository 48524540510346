import { fromJS, List as iList } from 'immutable';

import { camelizeObjectDeep, mergeItemToList, mergeArrayToList } from './utils';

import {
  ADD_PROSPECTS_TO_DRIP_LIST__SUCCESS,
  CREATE_PROSPECT__SUCCESS,
  DELETE_PROSPECTS_FROM_DRIP_LIST__SUCCESS,
  DELETE_PROSPECTS__SUCCESS,
  FETCH_PROSPECTS__SUCCESS,
  FETCH_PROSPECT__SUCCESS,
  START_CAMPAIGN_FOR_PROSPECTS__SUCCESS,
  UPDATE_PROSPECTS__SUCCESS,
  UPDATE_PROSPECT__SUCCESS,
} from 'actions/prospectsActions';

const initialState = iList();

export default function prospectsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PROSPECT__SUCCESS:
    case UPDATE_PROSPECT__SUCCESS:
    case FETCH_PROSPECT__SUCCESS: {
      const { prospect } = action;

      state = mergeItemToList(state, prospect, camelizeObjectDeep);

      break;
    }
    case DELETE_PROSPECTS__SUCCESS: {
      const { ids } = action;

      state = state.filter((prospect) => !ids.includes(prospect.get('id')));

      break;
    }
    case FETCH_PROSPECTS__SUCCESS: {
      const { prospects } = action;

      state = fromJS(prospects.map(camelizeObjectDeep));

      break;
    }
    case ADD_PROSPECTS_TO_DRIP_LIST__SUCCESS:
    case DELETE_PROSPECTS_FROM_DRIP_LIST__SUCCESS:
    case START_CAMPAIGN_FOR_PROSPECTS__SUCCESS:
    case UPDATE_PROSPECTS__SUCCESS: {
      const { prospects = [] } = action;

      state = mergeArrayToList(state, prospects, camelizeObjectDeep);

      break;
    }
  }

  return state;
}
