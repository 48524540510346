import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectStoreHOC from '../../startup/connect_store_hoc';

import { sendFeedback } from '../../api/helpcrunch';

import { translate } from '../../common/i18n';

import { showSuccessMessageFromTranslation, startMouseFlowPageView } from '../../common/utils';

import { closeFeedbackPopup } from '../../actions/popup_actions';

import { Modal } from '../../components_linkio/modal_component';
import TextAreaComponent from '../../components_linkio/textarea_component';
import ButtonComponent from '../../components_linkio/button_component';
import ContactUserOnFeedbackComponent from './ContactUserOnFeedbackComponent';

import Link from 'components_linkio/link';

import './feedback_popup.scss';


class FeedbackPopup extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    placeholder: PropTypes.string,
    title: PropTypes.string,
    videoAnnotation: PropTypes.string,
    withVideo: PropTypes.string,
  }

  state = {
    feedbackText: void 0,
    mayContactUser: 'yes',
    message: void 0,
  }

  componentDidMount() {
    startMouseFlowPageView(document.location.pathname);
  }

  textArea = {};

  closePopup = () => this.props.dispatch(closeFeedbackPopup());

  handleCancel = () => this.closePopup()

  handleSubmitFeedback = () => {
    const { dispatch } = this.props;
    const { mayContactUser, feedbackText } = this.state;

    if (!feedbackText) {
      this.setState({
        message: { type: 'error', text: translate('feedbackPopup.messages.noText') },
      });
      return;
    }

    sendFeedback(dispatch, { message: `${feedbackText} contact me: ${mayContactUser}` });

    showSuccessMessageFromTranslation(dispatch, 'feedbackPopup.messages.thanksForFeedback');
    this.closePopup();
  }

  handleMayContactUserChange = (event) => this.setState({ mayContactUser: event.target.value, message: void 0 });
  handleFeedbackChange = (event) => this.setState({ feedbackText: event.target.value, message: void 0 });

  render() {
    const { title, videoAnnotation, withVideo, placeholder } = this.props;
    const { mayContactUser, feedbackText, message } = this.state;

    return (
      <Modal show className="feedback-popup">
        <Modal.Header>
          <Modal.Title>{title || translate('feedbackPopup.titles.feedback')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {withVideo &&
            <div className="feedback-popup__video">
              {videoAnnotation}
              <iframe
                allowFullScreen
                frameBorder="0"
                src={withVideo}
                title="main-video"
              />
            </div>
          }
          <TextAreaComponent
            label={translate('feedbackPopup.labels.feedback')}
            onChange={this.handleFeedbackChange}
            placeholder={placeholder || translate('feedbackPopup.placeholders.feedback')}
            value={feedbackText}
            message={message}
          />
          <p className="feedback-popup__survey-request">
            {translate('feedbackPopup.survey.request')}
          </p>
          <Link
            className="feedback-popup__survey-link"
            rel="noopener noreferrer"
            href="https://outreachmama.typeform.com/to/Z9kST1"
            target="_blank"
          >
            {translate('feedbackPopup.survey.linkText')}
          </Link>

          <ContactUserOnFeedbackComponent
            mayContactUser={mayContactUser}
            onMayContactUserChange={this.handleMayContactUserChange}
          />
        </Modal.Body>

        <Modal.Footer>
          <div className="feedback-popup__btn-group">
            <div className="feedback-popup__btn">
              <ButtonComponent isWhite onClick={this.handleCancel}>
                {translate('feedbackPopup.buttons.cancel')}
              </ButtonComponent>
            </div>
            <div className="feedback-popup__btn feedback-popup__btn_long">
              <ButtonComponent isGreen onClick={this.handleSubmitFeedback}>
                {translate('feedbackPopup.buttons.send')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConnectStoreHOC(connect()(FeedbackPopup));
