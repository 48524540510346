import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap'; //TODO: Need to change to our component

import { translate } from 'common/i18n';

import ArrowVectorIcon from 'common/icons/ArrowVectorIcon';
import BinIcon from 'common/icons/bin';
import ButtonComponent from 'components_linkio/button_component';
import DotsIcon from 'common/icons/DotsIcon';

import './eoCampaignStepActionsDropdown.scss';

const EoCampaignStepActionsDropdownComponent = ({
  onMoveEoCampaignStep,
  onDeleteEoCampaignStep,
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);

  function handleOnBlur() {
    setDropdownIsOpen(false);
  }

  function handleOnToggle(isOpen) {
    setDropdownIsOpen(isOpen);
  }

  const handleMoveEoCampaignStep = (delta) => () => {
    onMoveEoCampaignStep(delta);
  };

  function buildActions() {
    return [
      {
        Icon: <ArrowVectorIcon direction="up" />,
        title: translate('eoCampaignStep.eoCampaignStepActions.moveUp'),
        handleClick: handleMoveEoCampaignStep('-1'),
        className: 'eo-campaign-step-actions-dropdown__move-up',
      },
      {
        Icon: <ArrowVectorIcon direction="down" />,
        title: translate('eoCampaignStep.eoCampaignStepActions.moveDown'),
        handleClick: handleMoveEoCampaignStep('1'),
        className: 'eo-campaign-step-actions-dropdown__move-down',
      },
      {
        Icon: <BinIcon />,
        title: translate('eoCampaignStep.eoCampaignStepActions.deleteStep'),
        handleClick: onDeleteEoCampaignStep,
        className: 'eo-campaign-step-actions-dropdown__delete-step',
      },
    ];
  }

  return (
    <Dropdown
      className="eo-campaign-step-actions-dropdown"
      id="eo-campaign-step-actions-dropdown"
      onBlur={handleOnBlur}
      onToggle={handleOnToggle}
      open={dropdownIsOpen}
    >
      <CustomDropdownToggle bsRole="toggle" />
      <CustomDropdownMenu bsRole="menu">
        <div className="dropdown-menu dropdown-menu-right">
          <ul>
            {buildActions().map(({ Icon, title, handleClick, className }) => {
              return (
                <li key={title} className={className} onMouseDown={handleClick}>
                  {Icon}
                  <span>{title}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </CustomDropdownMenu>
    </Dropdown>
  );
};

EoCampaignStepActionsDropdownComponent.propTypes = {
  onDeleteEoCampaignStep: PropTypes.func,
  onMoveEoCampaignStep: PropTypes.func,
};

export default EoCampaignStepActionsDropdownComponent;

// see https://react-bootstrap.github.io/components.html#btn-dropdowns-custom
// for more details about this crappy stuff
class CustomDropdownToggle extends Component {
  static propTypes = { onClick: PropTypes.func };
  shouldComponentUpdate() {
    return true;
  }
  render() {
    return (
      <ButtonComponent
        className="eo-campaign-step-actions-dropdown__icon-wrapper"
        onClick={this.props.onClick}
      >
        <DotsIcon isHorizontal />
      </ButtonComponent>
    );
  }
}

// see https://react-bootstrap.github.io/components.html#btn-dropdowns-custom
// for more details about this crappy stuff
class CustomDropdownMenu extends Component {
  static propTypes = { children: PropTypes.node };
  shouldComponentUpdate() {
    return true;
  }
  render() {
    return this.props.children;
  }
}
