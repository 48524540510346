import {
  fetchUnreadNotificationsCountSuccess,
} from '../../actions/notificationActions';

const handleNotificationStatus = (dispatch, data) => {
  const {
    notificationStatus,
  } = data;

  switch (notificationStatus) {
    case 'arrived':
      dispatch(fetchUnreadNotificationsCountSuccess(data));
      break;
  }
};


export default handleNotificationStatus;
