import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { isNumber } from 'lodash';

import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { translate } from 'common/i18n';
import { chartMetricsArray } from 'common/propTypesShapes/metrics';

import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import DisabledChartImg from 'common/img/disabled_chart.png';

import './lineChart.scss';

const LINE_COLORS = [
  '#005689',
  '#1ABC9C',
  '#EB7A26',
  '#E3204B',
  '#00A0FF',
  '#795548',
  '#FFD4B5',
  '#FFB0C2',
];
const chartMargin = { top: 30, right: -15, left: -15, bottom: 20 };
const legendWrapperStyle = { width: 'initial', right: 45, left: 45, bottom: 8 };
const tickStyle = { fontSize: 11 };

export default class LineChartComponent extends React.PureComponent {
  static propTypes = {
    appModule: PropTypes.oneOf(['backlinks', 'emailOutreach']),
    canUseCharts: PropTypes.bool,
    chartData: chartMetricsArray,
    className: PropTypes.string,
    leftYAxisReversed: PropTypes.bool,
    link: PropTypes.string,
    onClickUpgradeSubscription: PropTypes.func,
    rightYAxisReversed: PropTypes.bool,
    showRightYAxis: PropTypes.bool,
    title: PropTypes.string,
  };

  tickFormatter = (value) => {
    return value <= 0 ? '' : value;
  };

  tooltipValueFormatter = (value) => {
    return Number.isInteger(value) ? value : value.toFixed(2);
  };

  renderLegend = (props) => {
    const { payload } = props;
    const { link, appModule } = this.props;

    return (
      <ul className="line-chart-component__legend">
        {payload.map((item) => (
          <li key={item.dataKey} className="line-chart-legend-item__wrapper">
            <div className="line-chart-legend-item" style={{ borderColor: item.color }}>
              <p className="line-chart-legend-item__title">
                {translate(`chart.metrics.${appModule}.${item.dataKey}`)}
              </p>
              <p className="line-chart-legend-item__link">{link}</p>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const {
      appModule,
      canUseCharts,
      chartData,
      className,
      leftYAxisReversed,
      onClickUpgradeSubscription,
      rightYAxisReversed,
      showRightYAxis,
      title,
    } = this.props;

    if (!canUseCharts) {
      return (
        <div className="line-chart-component__disabled-chart-wrapper">
          <img className="line-chart-component__disabled-chart-img" src={DisabledChartImg} />
          <div className="line-chart-component__subscription-ribbon">
            <SubscriptionLimitRibbonComponent>
              {translate('subscriptionLimitRibbon.message')(
                translate('subscriptionLimitRibbon.limited.charts'),
                true,
              )}
              <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
            </SubscriptionLimitRibbonComponent>
          </div>
        </div>
      );
    }

    const isEmptyChart =
      chartData.filter((metricsObject) => {
        return Object.values(metricsObject).some(
          (metricValue) => isNumber(metricValue) && metricValue > 0,
        );
      }).length === 0;

    const lineChartClasses = cx('chart-wrapper', 'line-chart-component', className, {
      'line-chart-component__no-data': isEmptyChart,
    });

    if (isEmptyChart) {
      return <div className={lineChartClasses}>{translate('chart.noData')}</div>;
    }

    // 'date' is using in several chart components, so it's easier to pre-format it
    // rather than attach formatter function for every chart component
    const processedChartData = chartData.map((item) => ({
      ...item,
      date: moment(item.date).format('MMM D'),
    }));

    // Build line keys for the chart. We should leave only YAxis related data as the lines
    const chartLines = Object.keys(processedChartData[0]).filter((item) => item !== 'date');

    // Calculate the offset in order to show the tooltip on the top of the chart.
    // The more metrics the higher the tooltip.
    const tooltipOffset = 20 + chartLines.length * 30;

    return (
      <div className={lineChartClasses}>
        <h4>{title}</h4>

        <ResponsiveContainer height={400} width="100%">
          <LineChart data={processedChartData} margin={chartMargin}>
            <CartesianGrid strokeDasharray="4" vertical={false} />
            <XAxis dataKey="date" tickLine={false} tick={tickStyle} tickMargin={10} />
            <YAxis
              domain={['auto', 'auto']}
              tickFormatter={this.tickFormatter}
              tickLine={false}
              tick={tickStyle}
              yAxisId="left"
              reversed={leftYAxisReversed}
              allowDecimals={false}
            />
            <YAxis
              tickFormatter={this.tickFormatter}
              tickLine={false}
              tick={tickStyle}
              yAxisId="right"
              axisLine={showRightYAxis}
              reversed={rightYAxisReversed}
              orientation="right"
              allowDecimals={false}
            />
            <Tooltip position={{ y: -tooltipOffset }} formatter={this.tooltipValueFormatter} />
            <Brush dataKey="date" height={20} travellerWidth={16} stroke="#2A7FB3" />
            <Legend
              content={this.renderLegend}
              align="left"
              iconSize={0}
              wrapperStyle={legendWrapperStyle}
            />
            {chartLines.map((lineKey, i) => {
              const yAxisId =
                showRightYAxis && lineKey === 'averageKeywordsRank' ? 'right' : 'left';
              return (
                <Line
                  key={lineKey}
                  yAxisId={yAxisId}
                  name={translate(`chart.metrics.${appModule}.${lineKey}`)}
                  dataKey={lineKey}
                  stroke={LINE_COLORS[i]}
                  strokeWidth={2}
                  dot={false}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
