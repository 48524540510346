import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { clone } from 'lodash';

import ChangesCountValue from './ChangesCountValue';

import {
  buildCellClasses,
  buildRankChanges,
  buildUrlToggleIcon,
  getValue,
  getLink,
  todayRankValueFormatter,
} from './utils';

import { searchVolumeValueFormatter } from './utils/searchVolumeValueFormatter';

import { translate } from 'common/i18n';

import {
  rankTrackerFiltersMap,
  keywordsWithChangesByUrlList,
  keywordsWithChangesList,
} from 'common/prop_types_shapes';

import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';

import './rankTrackerTable.scss';

const RankTrackerTableComponent = ({
  dateStart,
  dateEnd,
  keywordsWithChanges,
  keywordsWithChangesByUrl,
  onTableHeaderClick,
  rankTrackerFilters,
  sorting,
}) => {
  const [openedUrlIds, setOpenedUrlIds] = React.useState([]);

  const onToggleUrlClick = (pageId) => () => {
    let clonedOpenedUrlIds = clone(openedUrlIds);

    if (openedUrlIds.includes(pageId)) {
      clonedOpenedUrlIds = openedUrlIds.filter((id) => id !== pageId);
    } else {
      clonedOpenedUrlIds.push(pageId);
    }

    setOpenedUrlIds(clonedOpenedUrlIds);
  };

  const groupByUrl = rankTrackerFilters.get('groupByUrl');
  const searchTerm = rankTrackerFilters.get('searchTerm');

  const dateStartMoment = moment(dateStart);
  const dateEndMoment = moment(dateEnd);

  const comparedMonthsCount = dateEndMoment.diff(dateStartMoment, 'months', true);
  const isDateEndToday = dateEnd === moment().format('YYYY-MM-DD');

  const isPresetDateSelected = [3, 6, 12].includes(comparedMonthsCount) && isDateEndToday;
  const isSingleDateCompare = dateStart && dateEnd && dateStart === dateEnd;

  const isDateEndColumnVisible = dateEnd && !isPresetDateSelected && !isSingleDateCompare;
  const isDateStartColumnVisible =
    dateStart || (dateStart && (isPresetDateSelected || isSingleDateCompare));

  const isDefaultColumnsVisible =
    (!dateStart && !dateEnd) ||
    (dateStart && dateEnd && !isSingleDateCompare && isPresetDateSelected);

  const buildDateStartColumnTitle = () => {
    if (isPresetDateSelected) {
      return translate('rankTracker.table.headers.months')(comparedMonthsCount);
    }

    if (dateStart) {
      return moment(dateStart).format('MMM DD, YYYY');
    }

    return '';
  };

  const dateEndColumnTitle = dateEnd && moment(dateEnd).format('MMM DD, YYYY');

  function buildColumns({ isLinkRow }) {
    const columns = [
      {
        key: 'link',
        title: translate('rankTracker.table.headers.url'),
        cellValueFormatter: getLink,
        isVisible: groupByUrl && isLinkRow,
        withSorting: true,
      },
      {
        key: 'label',
        title: translate('rankTracker.table.headers.keywords'),
        isVisible: !isLinkRow,
        withSorting: true,
      },
      {
        key: 'keywordsCount',
        title: translate('rankTracker.table.headers.keywordsCount'),
        isVisible: groupByUrl,
        withSorting: true,
      },
      {
        key: 'changesCount',
        title: '',
        cellValueFormatter: buildChangesCountValue,
        isVisible: true,
        isActive: !isLinkRow,
        withSorting: false,
      },
      {
        key: 'todayRank',
        withExplanationMessage: true,
        title: translate('rankTracker.table.headers.todayRank'),
        cellValueFormatter: todayRankValueFormatter,
        isVisible: true,
        withSorting: true,
      },
      {
        key: 'yesterdayRank',
        title: translate('rankTracker.table.headers.yesterday'),
        cellValueFormatter: buildRankChanges,
        isVisible: isDefaultColumnsVisible,
        withSorting: true,
      },
      {
        key: 'weekAgoRank',
        title: translate('rankTracker.table.headers.week'),
        cellValueFormatter: buildRankChanges,
        isVisible: isDefaultColumnsVisible,
        withSorting: true,
      },
      {
        key: 'monthAgoRank',
        title: translate('rankTracker.table.headers.months')(1),
        cellValueFormatter: buildRankChanges,
        isVisible: isDefaultColumnsVisible,
        withSorting: true,
      },
      {
        key: 'endDateRank',
        title: dateEndColumnTitle,
        cellValueFormatter: buildRankChanges,
        isVisible: isDateEndColumnVisible,
        withSorting: true,
      },
      {
        key: 'startDateRank',
        title: buildDateStartColumnTitle(),
        cellValueFormatter: buildRankChanges,
        isVisible: isDateStartColumnVisible,
        withSorting: true,
      },
      {
        key: 'dfsSearchVolumeLocal',
        title: translate('rankTracker.table.headers.dfsSearchVolumeLocal'),
        cellValueFormatter: searchVolumeValueFormatter,
        isVisible: true,
        withSorting: true,
      },
      {
        key: 'dfsSearchVolumeGlobal',
        title: translate('rankTracker.table.headers.dfsSearchVolumeGlobal'),
        cellValueFormatter: searchVolumeValueFormatter,
        isVisible: true,
        withSorting: true,
      },
    ];

    return columns.filter((column) => column.isVisible);
  }

  function buildChangesCountValue(keyword, brandPageId, isActive) {
    return <ChangesCountValue brandPageId={brandPageId} isActive={isActive} keyword={keyword} />;
  }

  function buildKeywordRow(keyword, options = {}) {
    const { brandPageId } = options;

    function buildRowClasses() {
      return cx('rank-tracker-table__keywords-row', {
        'rank-tracker-table__keywords-row_green-left-border': groupByUrl,
        'rank-tracker-table__keywords-row_hide': groupByUrl && !openedUrlIds.includes(brandPageId),
      });
    }

    const columns = buildColumns({ isLinkRow: false });

    return (
      <tr key={keyword.get('id')} className={buildRowClasses()}>
        {columns.map((column) => {
          return (
            <td key={column.key} className={buildCellClasses(column, groupByUrl)}>
              {getValue(column, keyword, brandPageId)}
            </td>
          );
        })}
      </tr>
    );
  }

  function buildKeywordsByUrlRows(brandPage) {
    const columns = buildColumns({ isLinkRow: true });
    const brandPageId = brandPage.get('id');

    return (
      <React.Fragment key={brandPageId}>
        <tr className="capybara--rank-tracker-table__link-row">
          {columns.map((column) => {
            const titleTooltip =
              column.key === 'todayRank'
                ? translate('explanationMessages.rankTrackerTable.averageTodayRank')
                : '';

            return (
              <td
                title={titleTooltip}
                key={column.key}
                className={buildCellClasses(column, groupByUrl)}
              >
                {column.key === 'link' && (
                  <span onClick={onToggleUrlClick(brandPageId)}>
                    {buildUrlToggleIcon(openedUrlIds.includes(brandPageId))}
                  </span>
                )}
                {getValue(column, brandPage)}
              </td>
            );
          })}
        </tr>
        {brandPage.get('keywordsObjects').map((keyword) => {
          return buildKeywordRow(keyword, { brandPageId });
        })}
      </React.Fragment>
    );
  }

  function buildTableHeaderRow() {
    const headerColumns = buildColumns({ isLinkRow: groupByUrl });

    return (
      <tr>
        {headerColumns.map(({ key, title, withExplanationMessage, withSorting }) => (
          <th key={key}>
            {withSorting && (
              <HeaderFieldWithSorting
                appModule="rankTracker"
                label={title}
                onClick={onTableHeaderClick}
                property={key}
                sortBy={sorting.sortBy}
                sortingOrder={sorting.sortingOrder}
                withExplanationMessage={withExplanationMessage}
              />
            )}
            {!withSorting && title}
          </th>
        ))}
      </tr>
    );
  }

  function buildTableBodyRows() {
    function buildFilteredBySearchKeywords(keywords) {
      return keywords.filter((keyword) => keyword.get('label').includes(searchTerm));
    }

    const filteredKeywords = buildFilteredBySearchKeywords(keywordsWithChanges);

    // Leave only those pages that have any keyword left after the filtering by search term
    const filteredKeywordsByUrl = keywordsWithChangesByUrl.map((page) => {
      const filteredKeywords = buildFilteredBySearchKeywords(page.get('keywordsObjects'));
      return page.set('keywordsObjects', filteredKeywords);
    });
    const filteredPages = filteredKeywordsByUrl.filter(
      (page) => page.get('keywordsObjects').size > 0,
    );

    if (!groupByUrl && filteredKeywords.size > 0) {
      return filteredKeywords.map((keyword) => buildKeywordRow(keyword));
    }

    if (groupByUrl && filteredPages.size > 0) {
      return filteredPages.map((brandPage) => buildKeywordsByUrlRows(brandPage));
    }

    return void 0;
  }

  const tableHeaderRow = buildTableHeaderRow();
  const tableBodyRows = buildTableBodyRows();

  const visibleColumnsCount = buildColumns({ isLinkRow: groupByUrl }).length;

  const rankTrackerTableClasses = cx(
    'rank-tracker-table',
    `rank-tracker-table_${visibleColumnsCount}-columns`,
    {
      [`rank-tracker-table__group-by-url_${visibleColumnsCount}-columns`]: groupByUrl,
    },
  );

  return (
    <>
      <div className="rank-tracker-table__wrapper">
        <table className={rankTrackerTableClasses}>
          <thead>{tableHeaderRow}</thead>
          <tbody>{tableBodyRows}</tbody>
        </table>
      </div>
    </>
  );
};

RankTrackerTableComponent.propTypes = {
  dateEnd: PropTypes.string,
  dateStart: PropTypes.string,
  keywordsWithChanges: keywordsWithChangesList,
  keywordsWithChangesByUrl: keywordsWithChangesByUrlList,
  onTableHeaderClick: PropTypes.func,
  rankTrackerFilters: rankTrackerFiltersMap.isRequired,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default RankTrackerTableComponent;
