import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoActiveHourFormPopupComponent from './EoActiveHourFormPopupComponent';

import { createEoActiveHour, updateEoActiveHour } from 'api/eoActiveHour';
import { currentUserSelector } from 'selectors/railsContextSelectors';
import { eoActiveHourShape } from 'common/propTypesShapes/eoActiveHours';
import { optionsList, userShape } from 'common/prop_types_shapes';
import { optionsSelector } from 'selectors';

import ConnectStoreHOC from 'startup/connect_store_hoc';

const EoActiveHourFormPopupContainer = ({
  dispatch,
  onClose,
  currentUser,
  tableRowData: eoActiveHour,
  timezonesOptions,
}) => {
  async function handleSave(eoActiveHourFormData) {
    const resp = eoActiveHour
      ? await updateEoActiveHour(dispatch, eoActiveHourFormData)
      : await createEoActiveHour(dispatch, eoActiveHourFormData);

    return resp;
  }

  const accountTimezone = currentUser.get('timezoneName', null);

  return (
    <EoActiveHourFormPopupComponent
      accountTimezone={accountTimezone}
      eoActiveHour={eoActiveHour}
      onCancel={onClose}
      onSave={handleSave}
      immutableTimezonesOptions={timezonesOptions}
    />
  );
};

function select(state) {
  const currentUser = currentUserSelector(state);

  const options = optionsSelector(state);
  const timezonesOptions = options.get('timezonesOptions');

  return {
    currentUser,
    timezonesOptions,
  };
}

EoActiveHourFormPopupContainer.propTypes = {
  currentUser: userShape,
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  tableRowData: eoActiveHourShape,
  timezonesOptions: optionsList,
};

export default compose(ConnectStoreHOC, connect(select))(EoActiveHourFormPopupContainer);
