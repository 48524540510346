import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoSchedulersBulkActionsComponent from './EoSchedulersBulkActionsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { eoSchedulersSelector } from 'selectors/eoSchedulersSelector';
import { eoSchedulersList } from 'common/propTypesShapes/eoSchedulers';

const EoSchedulersBulkActionsContainer = ({ eoSchedulers }) => {
  const hasEoSchedulers = eoSchedulers.size > 0;

  return <EoSchedulersBulkActionsComponent hasEoSchedulers={hasEoSchedulers} />;
};

EoSchedulersBulkActionsContainer.propTypes = {
  eoSchedulers: eoSchedulersList,
};

function select(state) {
  const eoSchedulers = eoSchedulersSelector(state);

  return {
    eoSchedulers,
  };
}

export default compose(ConnectStoreHOC, connect(select))(EoSchedulersBulkActionsContainer);
