import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoActiveHoursTableComponent from './EoActiveHoursTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { fetchEoActiveHours } from 'api/eoActiveHour';
import { eoActiveHoursList } from 'common/propTypesShapes/eoActiveHours';

import { eoActiveHoursSelector } from 'selectors/eoActiveHoursSelector';
import { uiSelector } from 'selectors';

import { resetSelectedIds, toggleSelectedIds } from 'actions/ui_actions';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const EoActiveHoursTableContainer = ({
  changeQueryParams,
  dispatch,
  eoActiveHours,
  queryParams,
  selectedIds,
}) => {
  const { pageNumber = 1, pagyItemsCount = 100, search = '', sorting = {} } = queryParams;

  React.useEffect(() => {
    const fetchingParams = {
      items: pagyItemsCount,
      page: pageNumber,
      search,
      sorting,
    };

    fetchEoActiveHours(dispatch, fetchingParams);
  }, [dispatch, pageNumber, pagyItemsCount, search, sorting.sortBy, sorting.sortingOrder]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectedIds());
    };
  }, [dispatch]);

  function handleToggleSelectedRows(eoActiveHoursIds, isSelected) {
    dispatch(toggleSelectedIds(eoActiveHoursIds, isSelected));
  }

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  return (
    <>
      {eoActiveHours.size > 0 && (
        <EoActiveHoursTableComponent
          onTableHeaderClick={handleTableHeaderClick}
          onToggleSelectedRows={handleToggleSelectedRows}
          eoActiveHours={eoActiveHours}
          selectedIds={selectedIds}
          sorting={sorting}
        />
      )}
      {eoActiveHours.size === 0 && (
        <BlankStateCtaComponent>
          <TitleText>
            <strong>{translate('eoActiveHour.table.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('eoActiveHour.table.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

EoActiveHoursTableContainer.propTypes = {
  dispatch: PropTypes.func,
  eoActiveHours: eoActiveHoursList,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoActiveHours = eoActiveHoursSelector(state);

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    eoActiveHours,
    selectedIds,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoActiveHoursTableContainer);
