import React from 'react';
import { isEmpty } from 'lodash';

import {
  updateBrandFailure,
  updateBrandSuccess,
} from '../../actions/brand_actions';

import {
  importFromGSCFailure,
  importFromGSCStart,
  importFromGSCSuccess,
} from '../../actions/bulkPagesSetupActions';

import { translate } from '../../common/i18n';

import { showSuccessMessage, showErrorMessage } from '../../common/utils';

const handleImportFromGscStatus = (dispatch, data) => {
  const {
    gscBacklinksImportStatus,
    id,
  } = data;

  switch (gscBacklinksImportStatus) {
    case 'started':
      dispatch(importFromGSCStart(id));
      dispatch(updateBrandSuccess(data));
      showStartedNotification(dispatch, data.errors);
      break;
    case 'finished':
      dispatch(updateBrandSuccess(data));
      showFinishedNotification(dispatch);
      dispatch(importFromGSCSuccess(id));
      break;
    case 'failure':
      dispatch(updateBrandFailure(data.errors));
      showFailureNotification(dispatch, data.errors);
      dispatch(importFromGSCFailure(id));
      break;
  }
};

const showStartedNotification = (dispatch, errors) => {
  if (isEmpty(errors)) {
    return showSuccessMessage(
      dispatch,
      translate('destinationLink.messages.gscImportStart'),
      false
    );
  }

  return showErrorMessage(
    dispatch,
    formattedErrorsMessage(translate('destinationLink.messages.gscImportWarnings'), errors),
    false
  );
};

const showFinishedNotification = (dispatch) => {
  return showSuccessMessage(
    dispatch,
    translate('destinationLink.messages.gscImportSuccess'),
    false
  );
};

const showFailureNotification = (dispatch, errors) => {
  return showErrorMessage(
    dispatch,
    formattedErrorsMessage(translate('destinationLink.messages.gscImportFailure'), errors),
    true
  );
};

const formattedErrorsMessage = (title, errors) => {
  return (
    <span>
      <span>{title}</span>
      <ul>
        {
          Object.values(errors).flat().map((error, key) => (
            <li key={key}>{error}</li>
          ))
        }
      </ul>
    </span>
  );
};

export default handleImportFromGscStatus;
