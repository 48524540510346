import React from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../common/i18n';

import ExplanatoryTooltipComponent from '../../components/explanatory_tooltip_component';

import './label_component.scss';


const LabelComponent = ({ text, children, isMandatory, explanationMessage }) => {
  if (!text) {
    return children;
  }

  return (
    <label className="label-component">
      <span className="label-component__text">{text}</span>
      {explanationMessage && <ExplanatoryTooltipComponent text={explanationMessage} />}
      {isMandatory &&
        <span
          className="label-component__required"
          title={translate('warnings.fieldIsRequired')}
        >
          *
        </span>
      }
      {children}
    </label>
  );
};

LabelComponent.propTypes = {
  children: PropTypes.node,
  explanationMessage: PropTypes.string,
  isMandatory: PropTypes.bool,
  text: PropTypes.string,
};

export default LabelComponent;
