export default {
  dlDetailsPopup: {
    title: 'Link Status Details',
    statusReport: 'Crawler Status Report',
    lastCheck: 'Last check:',
    buttons: {
      close: 'Close',
      save: 'Save',
      crawlNow: 'Crawl Now',
    },
    tables: {
      status: {
        details: {
          publishedHostBlacklisted: 'Published host is blacklisted',
        },
        description: `By default we consider that the backlinks that are NF, not indexed or are disavowed \
          do not count towards anchor text percentages. Same applies to the referring pages that are not \
          live and the backlinks that can't be found. Also if you have several backlinks from the same \
          domain pointing to your website page we count only one of them and mark the rest as duplicates.`,
        headerLabels: {
          date: 'Date',
          description: 'Description',
          details: 'Details',
          status: 'Status',
        },
        buttons: {
          link: 'Show/Hide Links',
        },
        auto: 'Auto-defined.',
        df: 'This is a dofollow link.',
        disavow: 'The backlink is found in the disavow file you uploaded.',
        duplicates: 'Number of backlinks from this domain.',
        duplicatesShort: 'Dupe',
        enqueued: 'Enqueued. Check Crawler queue for time estimate',
        errorCrawler: 'Could not crawl the link.',
        errorGoogleIndex: 'Could not check Google indexed status.',
        excluded: 'The backlink does not count towards anchor text percentages. ',
        foundBacklink: 'The backlink to your website page was found.',
        foundBacklinkWithRedirect: 'The anchor was found with a redirect.',
        foundPublishedUri: 'The referring page is live.',
        included: 'The backlink counts towards anchor text percentages. ',
        indexed: 'The backlink is indexed by Google.',
        indexedDetails: (recheckEnd) => {
          return `Indexed status will be rechecked weekly until ${recheckEnd}`;
        },
        manual: 'Manually defined.',
        nextTryDescription: (attemptNumber, timeBeforeNextTry) => {
          if (attemptNumber === 5 && !timeBeforeNextTry) {
            return 'Max number of attempts reached.';
          }
          return `Attempt ${attemptNumber} of 5. Next try: ${timeBeforeNextTry}.`;
        },
        nf: 'This is a nofollow link.',
        noData: 'No Data',
        noText: 'No Text',
        notDisavow: 'The backlink is not in disavow file, if you uploaded one.',
        notFound500PublishedUri: 'The published link was not found because of server error.',
        notFoundBacklink: 'The backlink to your website page was not found at this referring page.',
        notFoundPublishedUri: "The referring page can't be reached.",
        notIndexed: 'The backlink is not indexed by Google.',
        okPublishedUri: 'The referring page is live.',
        processing: {
          title: 'processing',
          description:
            'The link is being checked by Linkio to see if it’s live, dofollow and indexed.',
        },
        unvalidated: {
          title: 'unvalidated',
          description: {
            beforeLinkText: 'The link was not checked by Linkio. Please ',
            linkText: 'upgrade',
            afterLinkText: ' to check whether the link is live, dofollow and indexed.',
          },
        },
      },
      duplicates: {
        title: 'Links From This Domain',
      },
      history: {
        title: 'Published URL History',
      },
    },
  },
};
