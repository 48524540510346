import {
  googleIndexedCrawlingFailure,
  googleIndexedCrawlingStart,
  googleIndexedCrawlingSuccess,
} from 'actions/crawler_actions';

import updateBrandsAndBacklinks from 'api/users_channel/utils/updateBrandsAndBacklinks';

const handleGoogleIndexedCrawlingStatus = (dispatch, data) => {
  const { destinationLinks, googleIndexedCrawlingStatus } = data;

  switch (googleIndexedCrawlingStatus) {
    case 'started':
      dispatch(googleIndexedCrawlingStart(destinationLinks));
      updateBrandsAndBacklinks(data, dispatch);

      break;
    case 'failure':
      dispatch(googleIndexedCrawlingFailure(destinationLinks));
      updateBrandsAndBacklinks(data, dispatch);

      break;
    case 'finished':
      dispatch(googleIndexedCrawlingSuccess(destinationLinks));
      updateBrandsAndBacklinks(data, dispatch);

      break;
  }
};

export default handleGoogleIndexedCrawlingStatus;
