import React from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import { List as iList } from 'immutable';

import { pagyShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import ArrowIcon from 'common/icons/ArrowIcon';

const PagerPagesControlComponent = ({ handlePagesControlClick, pagy }) => {
  const next = pagy.get('next');
  const prev = pagy.get('prev');
  const series = pagy.get('series', iList());
  const randomKey = () => [...Array(5)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
  const seriesLength = series.get(-1);
  const currentItem = series.filter((item) => isString(item) && item !== 'gap').first();

  const seriesPart = () => {
    return series.map((item) => {
      // avoid 'gap' keys collision in case both possible 'gap's are present in given series
      // [1, :gap, 7, 8, "9", 10, 11, :gap, 36]
      const theKey = item === 'gap' ? randomKey() : item;

      return <SeriesItem key={theKey} handleClick={handlePagesControlClick} item={item} />;
    });
  };

  return (
    <nav className="pager-pages-control" role="navigation">
      <ul className="pager-pages-control__list">
        <Prev handleClick={handlePagesControlClick} prev={prev} />
        {seriesPart()}
        <Next handleClick={handlePagesControlClick} next={next} />
      </ul>
      <div className="pager-pages-control__info">{`Page ${currentItem} of ${seriesLength}`}</div>
    </nav>
  );
};

PagerPagesControlComponent.propTypes = {
  handlePagesControlClick: PropTypes.func,
  pagy: pagyShape,
};

PagerPagesControlComponent.defaultProps = {
  handleClick: (pageNumber) => (event) => {
    event.preventDefault();
    pageNumber;
  },
  next: 2,
  prev: null,
  series: [],
};

export default PagerPagesControlComponent;

const Prev = ({ handleClick, prev }) => {
  const prevText = <ArrowIcon orientation="left" height="10px" />;

  if (prev) {
    return (
      <li className="pager-pages-control__item pager-pages-control__item_prev">
        {pagyLink(handleClick, prev, prevText)}
      </li>
    );
  }

  return (
    <li className="pager-pages-control__item pager-pages-control__item_prev">
      <span
        className="pager-pages-control__link pager-pages-control__link_disabled"
        onClick={handleClick(void 0)}
      >
        {prevText}
      </span>
    </li>
  );
};
Prev.propTypes = {
  handleClick: PropTypes.func,
  prev: PropTypes.number,
};

const Next = ({ handleClick, next }) => {
  const nextText = <ArrowIcon orientation="right" height="10px" />;

  if (next) {
    return (
      <li className="pager-pages-control__item pager-pages-control__item_next">
        {pagyLink(handleClick, next, nextText)}
      </li>
    );
  }

  return (
    <li className="pager-pages-control__item pager-pages-control__item_next">
      <span
        className="pager-pages-control__link pager-pages-control__link_disabled"
        onClick={handleClick(void 0)}
      >
        {nextText}
      </span>
    </li>
  );
};
Next.propTypes = {
  handleClick: PropTypes.func,
  next: PropTypes.number,
};

const SeriesItem = ({ handleClick, item }) => {
  const gapText = translate('pager.gap');

  if (item === 'gap') {
    // gap
    return (
      <li className="pager-pages-control__item gap">
        <span
          className="pager-pages-control__link pager-pages-control__link_disabled"
          onClick={handleClick(void 0)}
        >
          {gapText}
        </span>
      </li>
    );
  }

  if (isString(item)) {
    // active page
    return (
      <li className="pager-pages-control__item pager-pages-control__item_active">
        {pagyLink(handleClick, item)}
      </li>
    );
  }

  // page link
  return <li className="pager-pages-control__item">{pagyLink(handleClick, item)}</li>;
};
SeriesItem.propTypes = {
  handleClick: PropTypes.func,
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const pagyLink = (handleClick, n, text = n) => {
  return (
    <span className="pager-pages-control__link" onClick={handleClick(n)}>
      {text}
    </span>
  );
};
