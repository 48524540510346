function buildCompetitorsSchemeOptions(percentageSchemeOptions, pageId) {
  const options = percentageSchemeOptions.filter((scheme) => {
    return isCompetitorsScheme(scheme) && schemeBelongsToCurrentPage(scheme, pageId) && !scheme.get('isZero');
  }).toList().sortBy((option) => option.get('label')).toJS();

  return options;
}

function buildCustomSchemeOptions(percentageSchemeOptions) {
  const options = percentageSchemeOptions.filter((scheme) => {
    return isCustomScheme(scheme) && !isCompetitorsScheme(scheme);
  }).toList().toJS();

  return options;
}

function buildDefaultSchemeOptions(percentageSchemeOptions) {
  const options = percentageSchemeOptions.filter((scheme) => {
    return !isCustomScheme(scheme) && !isCompetitorsScheme(scheme);
  }).toList().toJS();

  return options;
}

function isCustomScheme(scheme) {
  return scheme.get('isCustom');
}

function isCompetitorsScheme(scheme) {
  return scheme.get('isCompetitors');
}

function schemeBelongsToCurrentPage(scheme, pageId) {
  return Number(scheme.get('brandPageId')) === Number(pageId);
}

function defineSelectedSchemeType(scheme) {
  if (scheme.isCustom) {
    return 'custom';
  }
  if (scheme.isCompetitors) {
    return 'competitors';
  }

  return 'default';
}

export {
  buildCompetitorsSchemeOptions,
  buildCustomSchemeOptions,
  buildDefaultSchemeOptions,
  defineSelectedSchemeType,
};
