import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';

import { sendFeedback } from 'api/helpcrunch';
import { changeSubscriptionPlan } from 'api/subscription_plan';

import { showSuccessMessageFromTranslation, startMouseFlowPageView } from 'common/utils';

import { closeDeleteAccountPopup, closeSwitchSubscriptionPlanPopup } from 'actions/popup_actions';
import { Modal } from 'components_linkio/modal_component';
import TextAreaComponent from 'components_linkio/textarea_component';
import ButtonComponent from 'components_linkio/button_component';
import ContactUserOnFeedbackComponent from 'components/feedback_popup/ContactUserOnFeedbackComponent';

import './switchSubscriptionPlanPopup.scss';

class SwitchSubscriptionPlanPopupComponent extends React.Component {
  static propTypes = {
    deleteAccountPopup: PropTypes.shape({ isOpen: PropTypes.bool }),
    dispatch: PropTypes.func,
    onClose: PropTypes.func,
    selectedPlanId: PropTypes.number,
  };

  state = {
    feedbackText: '',
    mayContactUser: 'yes',
  };

  componentDidMount() {
    startMouseFlowPageView(document.location.pathname);
  }

  handleCancel = () => this.props.dispatch(closeSwitchSubscriptionPlanPopup());

  handleSubmit = () => {
    const { deleteAccountPopup, dispatch, selectedPlanId, onClose } = this.props;
    const { mayContactUser, feedbackText } = this.state;

    changeSubscriptionPlan(this.props.dispatch, selectedPlanId);

    if (feedbackText) {
      sendFeedback(dispatch, {
        message: `${feedbackText} contact me: ${mayContactUser}`,
      });
      showSuccessMessageFromTranslation(
        dispatch,
        'switchSubscriptionPlanPopup.messages.thanksForFeedback',
      );
    }

    this.handleCancel();
    deleteAccountPopup.isOpen && dispatch(closeDeleteAccountPopup());
    onClose && onClose();
  };

  handleMayContactUserChange = (event) => this.setState({ mayContactUser: event.target.value });
  handleFeedbackChange = (event) => this.setState({ feedbackText: event.target.value });

  render() {
    const { feedbackText, mayContactUser } = this.state;

    return (
      <Modal show className="switch-subscription-plan-popup">
        <Modal.Header>
          <Modal.Title>
            {translate('switchSubscriptionPlanPopup.switchToFreeAccount.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{translate('switchSubscriptionPlanPopup.switchToFreeAccount.mainText')}:</p>
          <ul>
            {translate('switchSubscriptionPlanPopup.switchToFreeAccount.pointsList').map(
              (point, i) => {
                return <li key={i}>- {point};</li>;
              },
            )}
          </ul>

          <TextAreaComponent
            label={translate('switchSubscriptionPlanPopup.switchToFreeAccount.feedbackQuestion')}
            onChange={this.handleFeedbackChange}
            value={feedbackText}
          />

          <ContactUserOnFeedbackComponent
            mayContactUser={mayContactUser}
            onMayContactUserChange={this.handleMayContactUserChange}
          />
        </Modal.Body>

        <Modal.Footer>
          <div className="switch-subscription-plan-popup__btn-group">
            <div className="switch-subscription-plan-popup__btn">
              <ButtonComponent isWhite onClick={this.handleCancel}>
                {translate('switchSubscriptionPlanPopup.buttons.cancel')}
              </ButtonComponent>
            </div>

            <div className="switch-subscription-plan-popup__btn switch-subscription-plan-popup__btn_long">
              <ButtonComponent isGreen onClick={this.handleSubmit}>
                {translate('switchSubscriptionPlanPopup.buttons.downgrade')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

function select(state) {
  const deleteAccountPopup = state.getIn(['ui', 'popups', 'deleteAccountPopup']);

  return {
    deleteAccountPopup,
  };
}

export default ConnectStoreHOC(connect(select)(SwitchSubscriptionPlanPopupComponent));
