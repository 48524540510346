import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import ConnectStoreHOC from '../../../startup/connect_store_hoc';

import {
  notificationsList,
} from '../../../common/prop_types_shapes';

import {
  uiSelector,
} from '../../../selectors';

import {
  recentNotificationsSelector,
} from '../../../selectors/notificationSelectors';

import {
  toggleNotificationsPopup,
} from 'actions/notificationActions';

import NotificationsPopupComponent from './NotificationsPopupComponent';


class NotificationsPopupContainer extends Component {
  static propTypes = {
    notifications: notificationsList,
    showNotificationsPopup: PropTypes.bool.isRequired,
  };

  handleViewAllClick = () => {
    const {
      history,
      dispatch,
    } = this.props;

    dispatch(toggleNotificationsPopup());
    history.push(Routes.notifications_path({ format: null }));
  }

  render() {
    const {
      notifications,
      showNotificationsPopup,
    } = this.props;

    return (
      <>
        {showNotificationsPopup &&
          <NotificationsPopupComponent
            notifications={notifications}
            onViewAllClick={this.handleViewAllClick}
          />
        }
      </>
    );
  }
}

function select(state) {
  const uiStates = uiSelector(state);
  const showNotificationsPopup = uiStates.getIn(['openClose', 'notifications', 'showNotificationsPopup']);
  const notifications = recentNotificationsSelector(state);

  return {
    notifications,
    showNotificationsPopup,
  };
}

export default compose(
  ConnectStoreHOC,
  connect(select),
  withRouter,
)(NotificationsPopupContainer);
