import pluralize from 'pluralize';

const confirmations = (args) => {
  return {
    confirmations: {
      default: {
        title: 'Are you sure?',
        buttons: {
          cancel: 'Cancel',
          confirm: 'OK',
        },
      },
      attemptToChangeToUnavailablePlan: {
        body: attemptToChangeToUnavailablePlan(args),
        title: 'Oops',
        buttons: {
          cancel: 'Close',
        },
      },
      deletePage: {
        body: 'This will remove all the keywords being tracked.',
      },
      deleteBrand: {
        body: 'This will remove all the related backlinks.',
      },
      deleteDestinationLink: {
        body: `This will delete ${args.count} ${args.title}.`,
      },
      deleteDisavow: {
        body: 'This will delete disavow data',
      },
      deleteDraftCompetitors: {
        body:
          'This will delete all competitor pages from the table. Added competitor \
          pages will not be deleted from the "Analyze Competitors" module.',
      },
      deleteEmployee: {
        body: `This will delete ${args.count} team ${pluralize('member', args.count)}.`,
      },
      deleteKeywords: {
        body: `This will delete keywords being tracked. All collected data on these keywords will be removed.`,
      },
      deletePercentageScheme: {
        body:
          'These percentages are actively associated with pages in your account. \
          Deleting it will reset those pages back to system defaults.',
      },
      importFromAhrefs: {
        body: importFromAhrefsConfirmationBody(args),
      },
      inviteEmployee: {
        body: `This will invite ${args.count} team ${pluralize('member', args.count)}.`,
      },
      revokeAhrefsAccess: {
        body: 'This will disconnect your Ahrefs account from Linkio',
      },
      deleteProxy: {
        body: 'This will delete the proxy.',
      },
    },
  };
};

const attemptToChangeToUnavailablePlan = (args) => {
  const {
    allowedBrandsCount,
    allowedEoInboxesCount,
    allowedTrackedKeywordsCount,
    rejectionReason,
  } = args;

  switch (rejectionReason) {
    case 'the_same_plan':
      return 'Sorry, this plan is not available. Please contact info@linkio.com should you have any questions.';
    case 'too_many_brands':
      return `This plan allows only ${allowedBrandsCount} brands, please delete the extra ones.`;
    case 'too_many_eo_inboxes':
      return `This plan allows only ${allowedEoInboxesCount} Inboxes, please delete the extra ones.`;
    case 'too_many_tracked_kws':
      return `This plan allows only ${allowedTrackedKeywordsCount} tracked keywords, please delete the extra ones.`;
    default:
      return '';
  }
};

const importFromAhrefsConfirmationBody = (ahrefsLimits) => {
  const { backlinksCount, dlsLeft, possibleBacklinksCount, rowsLeft, rowsUsage } = ahrefsLimits;

  const localizedBacklinksCount = (backlinksCount || 0).toLocaleString();
  const localizedDlsLeft = (dlsLeft || 0).toLocaleString();
  const localizedRowsUsage = (rowsUsage || 0).toLocaleString();
  const localizedPossibleBacklinksCount = (possibleBacklinksCount || 0).toLocaleString();

  if (backlinksCount > dlsLeft) {
    return `We've found ${localizedBacklinksCount} backlinks for this page.
    Due to the technical limitations we’re going to import ${localizedDlsLeft} backlinks for your page
    which will use ${localizedRowsUsage} rows of your Ahrefs plan. \n Shall we proceed?`;
  }

  if (rowsLeft < rowsUsage) {
    return `We are going to import ${localizedPossibleBacklinksCount} of ${localizedBacklinksCount} backlinks,
     not enough rows in the Ahrefs plan to import the rest. \n
    Shall we proceed?`;
  }

  return `We're going to import ${localizedBacklinksCount} backlinks for your page which will use ${localizedRowsUsage} rows of your Ahrefs plan.
  Shall we proceed?`;
};

export default confirmations;
