import React from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';
import { find, isEmpty } from 'lodash';

import BinIcon from 'common/icons/bin';
import { translate } from 'common/i18n';

import { optionsMap, percentageSchemesMap, policiesShape } from 'common/prop_types_shapes';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import SavedPercentagesTableComponent from 'components/percentage_settings/saved_percentages_table_component';

import './percentagesFormPopup.scss';

class PercentagesFormPopupComponent extends React.Component {
  static propTypes = {
    anchorTypeOptions: optionsMap.isRequired,
    currentPercentageSchemesMap: percentageSchemesMap.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    percentageSchemeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    policies: policiesShape.isRequired,
  };

  constructor(props) {
    super(props);
    const { currentPercentageSchemesMap, percentageSchemeId } = props;

    const currentPercentageScheme = currentPercentageSchemesMap.get(
      String(percentageSchemeId),
      iMap(),
    );

    this.state = {
      currentPercentageScheme,
      errors: {},
    };
  }

  handleSave = async () => {
    const resp = await this.props.onSave(this.state.currentPercentageScheme);
    const { errors } = resp;

    if (!isEmpty(errors)) {
      this.setState({ errors });
    }
  };

  handlePercentageSchemeChange = (changedData) => {
    const { percentageSchemeId } = this.props;
    const { currentPercentageScheme } = this.state;

    const currentPercentageSchemeItems = currentPercentageScheme.get('percentageSchemeItems');

    const changedPercentageSchemeItems = currentPercentageSchemeItems.map((item) => {
      const newIdealPercentage = find(changedData, (row) => {
        return row.anchorType === item.get('anchorType');
      })[percentageSchemeId];

      return item.set('idealPercentage', newIdealPercentage);
    });

    const changedPercentageScheme = currentPercentageScheme.set(
      'percentageSchemeItems',
      changedPercentageSchemeItems,
    );

    this.setState({ currentPercentageScheme: changedPercentageScheme });
  };

  handleTitleChange = (event) => {
    const { currentPercentageScheme } = this.state;
    const updatedCurrentPercentageScheme = currentPercentageScheme.set('title', event.target.value);

    this.setState({ currentPercentageScheme: updatedCurrentPercentageScheme });
  };

  deleteIconIsVisible = () => {
    const { policies, percentageSchemeId } = this.props;
    const { currentPercentageScheme } = this.state;

    const canDelete = policies.getIn(['percentageScheme', 'canDestroy']);
    const isDeletableScheme = currentPercentageScheme.get('deletable');
    const isExistingScheme = percentageSchemeId !== 'newCustomScheme';

    return canDelete && isDeletableScheme && isExistingScheme;
  };

  render() {
    const { anchorTypeOptions, onCancel, onDelete, policies } = this.props;
    const { currentPercentageScheme, errors } = this.state;

    const currentPercentageSchemesMap = iMap({
      [String(currentPercentageScheme.get('id'))]: currentPercentageScheme,
    });
    const currentPercentageSchemeTitle = currentPercentageScheme.get('title');

    return (
      <Modal show className="saved-percentages-modal-component">
        <Modal.Header>
          <Modal.Title>{translate('percentageSettings.savedPercentages.modal.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SavedPercentagesTableComponent
            anchorTypeOptions={anchorTypeOptions}
            editMode
            onPercentageChange={this.handlePercentageSchemeChange}
            percentageSchemes={currentPercentageSchemesMap}
            policies={policies}
          />
          <div className="saved-percentages-modal-component__input">
            <InputComponent
              label={translate('percentageSettings.savedPercentages.modal.titleInput')}
              message={{ text: Object.values(errors), type: 'error' }}
              onInputChange={this.handleTitleChange}
              value={currentPercentageSchemeTitle}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="saved-percentages-modal-component__btn-group">
            <div className="saved-percentages-modal-component__btn">
              <ButtonComponent isWhite onClick={onCancel}>
                {translate('percentageSettings.savedPercentages.buttons.cancel')}
              </ButtonComponent>
            </div>
            {this.deleteIconIsVisible() && (
              <div className="saved-percentages-modal-component__btn">
                <ButtonComponent isRed onClick={onDelete}>
                  <BinIcon />
                </ButtonComponent>
              </div>
            )}
            <div className="saved-percentages-modal-component__btn">
              <ButtonComponent isGreen onClick={this.handleSave}>
                {translate('percentageSettings.savedPercentages.buttons.save')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PercentagesFormPopupComponent;
