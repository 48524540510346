import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import moment from 'moment';
import { parse } from 'qs';

import { ATP_TABLE_TABS } from 'components/atp/AtpTableTabs/constants';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import ErrorBoundary from 'decorators/error-boundary';

import { translate } from 'common/i18n';
import defaultMetricsParams from 'utils/routeUtils';

import RouteAppLayout from 'components_linkio/route_app_layout';

// auth pages
import AcceptInvitationPage from 'pages/auth/accept_invitation_page';
import EditEmailOrPasswordPage from 'pages/auth/edit_email_or_password_page';
import EditProfilePage from 'pages/auth/edit_profile_page';
import ChooseMarketerPage from 'pages/auth/choose_marketer_page';
import ForgotPasswordPage from 'pages/auth/forgot_password_page';
import ForgotPasswordInstructionsSentPage from 'pages/auth/ForgotPasswordInstructionsSentPage';
import NewMarketerPage from 'pages/auth/new_marketer_page';
import ResendConfirmationPage from 'pages/auth/resend_confirmation_page';
import ResendUnlockPage from 'pages/auth/resend_unlock_page';
import ResetPasswordPage from 'pages/auth/reset_password_page';
import SignInPage from 'pages/auth/SignInPage';
import SignUpPage from 'pages/auth/sign_up_page';

// EO pages
import EoActiveHoursPage from 'pages/EoActiveHoursPage';
import EoBlacklistedDomainsPage from 'pages/EoBlacklistedDomainsPage';
import EoDashboardPage from 'pages/EoDashboardPage';
import EoDripListsPage from 'pages/EoDripListsPage';
import EoCampaignPage from 'pages/EoCampaignPage';
import EoCampaignsPage from 'pages/EoCampaignsPage';
import EoDomainsPage from 'pages/EoDomainsPage';
import EoEmailTemplatesPage from 'pages/EoEmailTemplatesPage';
import EoInboxesSettingsPage from 'pages/EoInboxesSettingsPage';
import EoInboxFormPage from 'pages/EoInboxFormPage';
import EoCustomFieldsPage from 'pages/EoCustomFieldsPage';
import ProspectsPage from 'pages/ProspectsPage';
import UnsubscribeProspectPage from 'pages/UnsubscribeProspectPage';
import EoToDosPage from 'pages/EoToDosPage';

// settings pages
import BillingPage from 'pages/BillingPage';
import EmployeesPage from 'pages/EmployeesPage';
import NewsletterSettings from 'pages/NewsletterSettings';
import NewsletterSettingsUnsubscribe from 'pages/NewsletterSettings/NewsletterSettingsUnsubscribe';
import PercentageSettingsPage from 'pages/percentage_settings_page';
import ProxiesPage from 'pages/ProxiesPage';
import SubscriptionPlansPage from 'pages/subscription_plans_page';
import UserTypeSelection from 'pages/UserTypeSelection';

// brands pages
import AccountDashboard from 'pages/AccountDashboard';
import AtpPage from 'pages/atp';
import AutomationSettingsPage from 'pages/AutomationSettingsPage';
import BrandForm from 'pages/BrandForm';
import BacklinksPage from 'pages/BacklinksPage';
import BacklinkChanges from 'pages/BacklinksChangesHistory';
import BacklinksSetup from 'pages/BacklinksSetup';
import BrandDashboard from 'pages/BrandDashboard';
import BulkPagesSetup from 'pages/BulkPagesSetup';
import PermissionsPage from 'pages/PermissionsPage';
import RankTracker from 'pages/RankTracker';
import RankTrackerKeywords from 'pages/RankTrackerKeywords';
import RankTrackerSetup from 'pages/RankTrackerSetup';

import Impersonates from 'pages/Impersonates';
import NotificationsPage from 'pages/NotificationsPage';
import PricingForAgenciesPage from 'pages/pricing_for_agencies';
import PricingForClientsPage from 'pages/pricing_for_clients';

import NoAccessPage from 'pages/NoAccess';
import SubscriptionLimitPage from 'pages/SubscriptionLimit';
import StyleGuidePage from 'pages/style_guide_page';
import AdminReportsPage from 'pages/AdminReportsPage';

import AnchorIcon from 'common/icons/anchor';
import BacklinkAddIcon from 'common/icons/backlink_add';
import BacklinkIcon from 'common/icons/backlink';
import BacklinksImportIcon from 'common/icons/BacklinksImportIcon';
import BacklinksLostIcon from 'common/icons/BacklinksLostIcon';
import BacklinksRediscoveredIcon from 'common/icons/BacklinksRediscoveredIcon';
import BacklinksChangesIcon from 'common/icons/BacklinksChangesIcon';
import BulbIcon from 'common/icons/bulb';
import DashboardIcon from 'common/icons/dashboard';
import DashboardSecondIcon from 'common/icons/DashboardSecondIcon';
import EmailSettingsIcon from 'common/icons/EmailSettingsIcon';
import HumansIcon from 'common/icons/HumansIcon';
import KeywordIcon from 'common/icons/keyword';
import ListIcon from 'common/icons/list';
import SpeakerWithWorldIcon from 'common/icons/SpeakerWithWorldIcon';
import StackIcon from 'common/icons/StackIcon';

const buildQueryParams = (location) =>
  parse(location.search, {
    ignoreQueryPrefix: true,
  });

const formatRoRPath = (path) => path.toString().replace('(.:format)', ''); // TODO: remove when delete js-routes gem
const buildFormattedRoRPathWithReplacedBrandId = (path) =>
  formatRoRPath(path).replace(':brand_id', ':id');

const formattedBrandDashboardPath = buildFormattedRoRPathWithReplacedBrandId(Routes.dashboard_path);
const formattedBulkPagesSetupPath = buildFormattedRoRPathWithReplacedBrandId(
  Routes.bulk_pages_setup_path,
);
const formattedImportBacklinksPath = buildFormattedRoRPathWithReplacedBrandId(
  Routes.import_backlink_path,
);
const formattedRankTrackerKeywordsPath = buildFormattedRoRPathWithReplacedBrandId(
  Routes.edit_rank_tracker_keywords_path,
);
const formattedRankTrackerPath = buildFormattedRoRPathWithReplacedBrandId(
  Routes.rank_tracker_root_path,
);
const formattedRankTrackerSetupPath = buildFormattedRoRPathWithReplacedBrandId(
  Routes.edit_rank_tracker_settings_path,
);
const formattedBacklinksChangesPath = buildFormattedRoRPathWithReplacedBrandId(
  Routes.changes_backlink_path,
);

const atpTableTabs = ATP_TABLE_TABS.join('|');
const thingsToOpen = 'choose_ideal_anchor_text_ratio';

// React router v4 can not extract params from search query
// https://github.com/ReactTraining/react-router/issues/4410
const ATP_PAGE_PATHS = [
  `/brands/:id/brand_pages/:pageId?/:activeAtpTableTab(${atpTableTabs})?/:competitorId?/:thingToOpen(${thingsToOpen})?`,
];

const BACKLINKS_PAGE_PATHS = [
  '/backlinks/:id',
  '/backlinks/:id/:backlinksPageType(new_links|lost_links|re_discovered_links)',
];

const EO_CAMPAIGN_PAGE_PATHS = [
  '/eo_campaigns/:id/:activeEoCampaignTab(overview|prospects|eo_progress|eo_sequences|eo_inboxes|eo_scheduler|eo_messages|options|eo_campaign_preview)',
];

const EO_EMAIL_TEMPLATE_FOLDERS_PATHS = [
  '/eo_email_templates/all_records',
  '/eo_email_template_folders/:id/eo_email_template',
];

const PROSPECTS_PAGE_PATHS = [
  '/prospects',
  '/eo_domains',
  '/eo_custom_fields',
  '/eo_blacklisted_domains',
];
const EO_CAMPAIGNS_PAGE_PATHS = [
  '/eo_campaigns',
  '/eo_drip_lists',
  '/eo_active_hours_sets',
  '/eo_email_template_folders',
];

const EO_INBOXES_SETTINGS_PAGE_PATHS = [
  '/eo_inboxes_settings',
  '/eo_inboxes/new',
  '/eo_inboxes/:id/edit',
];

const UNSUBSCRIBE_PROSPECT_PATHS = [
  Routes.unsubscribe_prospects_path({ format: null }),
  '/eo_campaigns/:id/{{precomputed.unsubscribe_link}}',
];

const atpPageSidebarItems = [
  {
    styleAs: 'title',
    icon: <StackIcon />,
    type: 'link',
    text: translate('sidebar.brandDashboard'),
    title: translate('sidebar.brandDashboard'),
    to: (matchProps) => Routes.dashboard_path(matchProps.match.params.id, { format: null }),
    exact: true,
  },
  {
    type: 'title',
    title: translate('sidebar.atp'),
  },
  {
    type: 'button',
    icon: <AnchorIcon />,
    text: translate('sidebar.pageList'),
    title: translate('sidebar.pageList'),
    isActive: ({ match, location }) => {
      const queryParams = buildQueryParams(location);
      const showAtpSuggestions = (queryParams.pagesFilter || {}).showAtpSuggestions;
      return ATP_PAGE_PATHS.some((i) => i === match.path) && showAtpSuggestions !== 'true';
    },
    onClick:
      ({ match, history }) =>
      () => {
        history.push(Routes.brand_brand_pages_path(match.params.id, { format: null }));
      },
  },
  {
    type: 'button',
    icon: <BulbIcon />,
    text: translate('sidebar.suggestionsDashboard'),
    title: translate('sidebar.suggestionsDashboard'),
    isActive: ({ match, location }) => {
      const queryParams = buildQueryParams(location);
      const showAtpSuggestions = (queryParams.pagesFilter || {}).showAtpSuggestions;
      return ATP_PAGE_PATHS.some((i) => i === match.path) && showAtpSuggestions === 'true';
    },
    onClick:
      ({ match, history }) =>
      () => {
        history.push(
          Routes.brand_brand_pages_path(match.params.id, {
            format: null,
            pagesFilter: { showAtpSuggestions: true },
          }),
        );
      },
  },
  {
    type: 'link',
    icon: <ListIcon />,
    text: translate('sidebar.bulkPagesSetup'),
    title: translate('sidebar.bulkPagesSetup'),
    to: ({ history, location, match }) => {
      const isCameFromBulkPagesSetup = history.location.pathname.includes('bulk_pages_setup');
      const queryParams = isCameFromBulkPagesSetup ? buildQueryParams(location) : {};
      const path = Routes.bulk_pages_setup_path(match.params.id, {
        ...queryParams,
        format: null,
        simpleMode: true,
      });

      return path;
    },
  },
  {
    type: 'title',
    title: translate('sidebar.backlinks.title'),
  },
  {
    type: 'link',
    icon: <BacklinkIcon />,
    text: translate('sidebar.backlinks.all.text'),
    title: translate('sidebar.backlinks.all.title'),
    to: (matchProps, canUseMetrics) =>
      Routes.backlink_path(matchProps.match.params.id, {
        ...defaultMetricsParams(canUseMetrics),
      }),
    exact: true,
  },
  {
    type: 'link',
    icon: <BacklinkAddIcon />,
    text: translate('sidebar.backlinks.new.text'),
    title: translate('sidebar.backlinks.new.title'),
    to: (matchProps) =>
      Routes.new_links_backlink_path(matchProps.match.params.id, {
        format: null,
        filters: {
          published_date_beginning_of_day_gteq: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          published_date_end_of_day_lteq: moment().format('YYYY-MM-DD'),
          not_a_double: true,
        },
        sorting: { sortBy: 'firstSeen', sortingOrder: 'desc' },
      }),
    isActive: (matchProps) => {
      return (
        matchProps.match.url ===
        Routes.new_links_backlink_path(matchProps.match.params.id, { format: null })
      );
    },
    exact: true,
  },
  {
    type: 'link',
    icon: <BacklinksLostIcon />,
    text: translate('sidebar.backlinks.lost.text'),
    title: translate('sidebar.backlinks.lost.title'),
    to: (matchProps) =>
      Routes.lost_links_backlink_path(matchProps.match.params.id, {
        format: null,
        filters: {
          lost_during: [
            moment().subtract(7, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
          ],
        },
      }),
    isActive: (matchProps) => {
      return (
        matchProps.match.url ===
        Routes.lost_links_backlink_path(matchProps.match.params.id, { format: null })
      );
    },
    exact: true,
  },
  {
    type: 'link',
    icon: <BacklinksRediscoveredIcon />,
    text: translate('sidebar.backlinks.reDiscovered.text'),
    title: translate('sidebar.backlinks.reDiscovered.title'),
    to: (matchProps) =>
      Routes.re_discovered_links_backlink_path(matchProps.match.params.id, {
        format: null,
        filters: {
          re_discovered_during: [
            moment().subtract(7, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
          ],
        },
      }),
    isActive: (matchProps) => {
      return (
        matchProps.match.url ===
        Routes.re_discovered_links_backlink_path(matchProps.match.params.id, { format: null })
      );
    },
    exact: true,
  },
  {
    type: 'link',
    icon: <BacklinksChangesIcon />,
    text: translate('sidebar.backlinks.changes.text'),
    title: translate('sidebar.backlinks.changes.title'),
    to: (matchProps) => Routes.changes_backlink_path(matchProps.match.params.id, { format: null }),
    isActive: (matchProps) => {
      return (
        matchProps.match.url ===
        Routes.changes_backlink_path(matchProps.match.params.id, { format: null })
      );
    },
    exact: true,
  },
  {
    type: 'link',
    icon: <BacklinksImportIcon />,
    text: translate('sidebar.backlinks.import.text'),
    title: translate('sidebar.backlinks.import.title'),
    to: (matchProps) => Routes.import_backlink_path(matchProps.match.params.id, { format: null }),
    exact: true,
  },
  {
    type: 'title',
    title: translate('sidebar.rankTracker.title'),
  },
  {
    type: 'link',
    icon: <KeywordIcon />,
    text: translate('sidebar.rankTracker.overview'),
    to: (matchProps) => Routes.rank_tracker_root_path(matchProps.match.params.id, { format: null }),
    exact: true,
  },
  {
    type: 'separator',
  },
  {
    type: 'title',
    title: translate('sidebar.emailOutreach.title'),
  },
  {
    type: 'link',
    icon: <DashboardSecondIcon />,
    text: translate('sidebar.emailOutreach.eoDashboard.text'),
    title: translate('sidebar.emailOutreach.eoDashboard.title'),
    to: () => Routes.eo_dashboard_index_path({ format: null }),
  },
  {
    type: 'link',
    icon: <HumansIcon />,
    text: translate('sidebar.emailOutreach.prospects.text'),
    title: translate('sidebar.emailOutreach.prospects.title'),
    to: () => Routes.prospects_path({ format: null }),
  },
  {
    type: 'link',
    icon: <ListIcon />,
    text: translate('sidebar.emailOutreach.eoDripLists.text'),
    title: translate('sidebar.emailOutreach.eoDripLists.title'),
    to: () => Routes.eo_drip_lists_path({ format: null }),
  },
  {
    type: 'link',
    icon: <SpeakerWithWorldIcon />,
    text: translate('sidebar.emailOutreach.eoCampaigns.text'),
    title: translate('sidebar.emailOutreach.eoCampaigns.title'),
    to: () => Routes.eo_campaigns_path({ format: null }),
  },
  {
    type: 'link',
    icon: <DashboardSecondIcon />, // - need new icon
    text: translate('sidebar.emailOutreach.eoToDos.text'),
    title: translate('sidebar.emailOutreach.eoToDos.title'),
    to: () => Routes.eo_to_dos_items_path({ format: null }),
  },
  {
    type: 'link',
    icon: <EmailSettingsIcon fill="currentColor" stroke="currentColor" />,
    text: translate('sidebar.emailOutreach.eoInboxesSettings.text'),
    title: translate('sidebar.emailOutreach.eoInboxesSettings.title'),
    to: () => Routes.eo_inboxes_settings_path({ format: null }),
  },
];

const emailOutreachSidebarItems = [
  {
    type: 'title',
    title: translate('sidebar.emailOutreach.title'),
  },
  {
    type: 'link',
    icon: <DashboardSecondIcon />,
    text: translate('sidebar.emailOutreach.eoDashboard.text'),
    title: translate('sidebar.emailOutreach.eoDashboard.title'),
    to: () => Routes.eo_dashboard_index_path({ format: null }),
  },
  {
    type: 'link',
    icon: <HumansIcon />,
    text: translate('sidebar.emailOutreach.prospects.text'),
    title: translate('sidebar.emailOutreach.prospects.title'),
    to: () => Routes.prospects_path({ format: null }),
    isActive: ({ match }) => {
      return PROSPECTS_PAGE_PATHS.some((i) => i === match.path);
    },
  },
  {
    type: 'link',
    icon: <SpeakerWithWorldIcon />,
    text: translate('sidebar.emailOutreach.eoCampaigns.text'),
    title: translate('sidebar.emailOutreach.eoCampaigns.title'),
    to: () => Routes.eo_campaigns_path({ format: null }),
    isActive: ({ match }) => {
      return EO_CAMPAIGNS_PAGE_PATHS.some((i) => i === match.path);
    },
  },
  {
    type: 'link',
    icon: <DashboardSecondIcon />, // - need new icon
    text: translate('sidebar.emailOutreach.eoToDos.text'),
    title: translate('sidebar.emailOutreach.eoToDos.title'),
    to: () => Routes.eo_to_dos_items_path({ format: null }),
  },
  {
    type: 'link',
    icon: <EmailSettingsIcon />,
    text: translate('sidebar.emailOutreach.eoInboxesSettings.text'),
    title: translate('sidebar.emailOutreach.eoInboxesSettings.title'),
    to: () => Routes.eo_inboxes_settings_path({ format: null }),
    isActive: ({ match }) => {
      return EO_INBOXES_SETTINGS_PAGE_PATHS.some((i) => i === match.path);
    },
  },
  {
    type: 'separator',
  },
  {
    type: 'title',
    title: translate('sidebar.emailOutreach.seoTools.title'),
  },
  {
    type: 'link',
    icon: <DashboardIcon />,
    text: translate('sidebar.dashboard'),
    title: translate('sidebar.dashboard'),
    to: () => Routes.root_path({ format: null }),
    exact: true,
  },
];

const App = ({ layout }) => {
  return (
    <Router>
      <AppContainerWithListener>
        {layout === 'devise' && (
          <>
            <Route
              path={Routes.new_user_session_path({ format: null })}
              exact
              component={SignInPage}
            />
            <Route
              path={Routes.new_user_registration_path({ format: null })}
              exact
              component={SignUpPage}
            />
            <Route
              path={Routes.edit_user_password_path({ format: null })}
              component={ResetPasswordPage}
            />
            <Route
              path={Routes.new_user_password_path({ format: null })}
              exact
              component={ForgotPasswordPage}
            />
            <Route
              path={Routes.new_user_confirmation_path({ format: null })}
              exact
              component={ResendConfirmationPage}
            />
            <Route
              path={Routes.new_user_unlock_path({ format: null })}
              exact
              component={ResendUnlockPage}
            />
            <Route
              path={Routes.accept_user_invitation_path({ format: null })}
              component={AcceptInvitationPage}
            />
            <Route
              path={Routes.users_passwords_instructions_sent_path({ format: null })}
              component={ForgotPasswordInstructionsSentPage}
            />
          </>
        )}
        {layout === 'app' && (
          <Switch>
            {/* User type selection after registration */}
            <RouteAppLayout
              path={Routes.user_type_selection_index_path({ format: null })}
              exact
              component={UserTypeSelection}
            />
            {/* AccountDashboard */}
            <RouteAppLayout
              path={Routes.root_path({ format: null })}
              exact
              component={AccountDashboard}
            />
            <RouteAppLayout
              path={[Routes.edit_brand_path, Routes.new_brand_path].map(formatRoRPath)}
              exact
              component={BrandForm}
            />

            {/* ATP */}
            <RouteAppLayout
              path={ATP_PAGE_PATHS}
              component={AtpPage}
              sidebarItems={atpPageSidebarItems}
              checkRedirect
            />

            {/* Backlinks */}
            <RouteAppLayout
              checkRedirect
              path={BACKLINKS_PAGE_PATHS}
              exact
              component={BacklinksPage}
              sidebarItems={atpPageSidebarItems}
            />
            <RouteAppLayout
              checkRedirect
              component={BacklinksSetup}
              exact
              path={formattedImportBacklinksPath}
              sidebarItems={atpPageSidebarItems}
            />
            {/* BacklinksChanges */}
            <RouteAppLayout
              checkRedirect
              path={formattedBacklinksChangesPath}
              exact
              component={BacklinkChanges}
              sidebarItems={atpPageSidebarItems}
            />

            {/* Brand dashboard */}
            <RouteAppLayout
              checkRedirect
              component={BrandDashboard}
              exact
              path={formattedBrandDashboardPath}
              sidebarItems={atpPageSidebarItems}
            />

            {/* Bulk Pages Setup */}
            <RouteAppLayout
              checkRedirect
              component={BulkPagesSetup}
              path={formattedBulkPagesSetupPath}
              sidebarItems={atpPageSidebarItems}
            />

            {/* Rank tracker */}
            <RouteAppLayout
              checkRedirect
              component={RankTracker}
              exact
              path={formattedRankTrackerPath}
              sidebarItems={atpPageSidebarItems}
            />
            <RouteAppLayout
              component={RankTrackerKeywords}
              exact
              path={formattedRankTrackerKeywordsPath}
              sidebarItems={atpPageSidebarItems}
            />
            <RouteAppLayout
              component={RankTrackerSetup}
              exact
              path={formattedRankTrackerSetupPath}
              sidebarItems={atpPageSidebarItems}
            />

            {/* Employees settings */}
            <RouteAppLayout
              path={Routes.employees_permissions_path({ format: null })}
              component={PermissionsPage}
            />
            <RouteAppLayout
              path={Routes.employees_path({ format: null })}
              component={EmployeesPage}
            />

            {/* Settings */}
            <RouteAppLayout
              path={Routes.percentage_settings_path({ format: null })}
              component={PercentageSettingsPage}
            />
            <RouteAppLayout
              path={Routes.automation_settings_path({ format: null })}
              component={AutomationSettingsPage}
            />

            {/* User settings */}
            <RouteAppLayout
              path={Routes.users_edit_profile_path({ format: null })}
              exact
              component={EditProfilePage}
            />
            <RouteAppLayout
              path={Routes.users_choose_account_path({ format: null })}
              component={ChooseMarketerPage}
            />
            <RouteAppLayout
              path={Routes.edit_user_registration_path({ format: null })}
              exact
              component={EditEmailOrPasswordPage}
            />

            {/* Notifications */}
            <RouteAppLayout
              path={Routes.notifications_path({ format: null })}
              exact
              component={NotificationsPage}
            />

            {/* Marketer */}
            <RouteAppLayout
              path={Routes.new_marketer_path({ format: null })}
              exact
              component={NewMarketerPage}
            />
            <RouteAppLayout
              path={Routes.billing_marketers_path({ format: null })}
              exact
              component={BillingPage}
            />

            {/* Subscription */}
            <RouteAppLayout
              path={Routes.subscription_plans_path({ format: null })}
              exact
              component={SubscriptionPlansPage}
            />

            <RouteAppLayout
              path={Routes.newsletter_settings_path({ format: null })}
              exact
              component={NewsletterSettings}
            />

            <RouteAppLayout
              path={Routes.unsubscribe_newsletter_settings_path({ format: null })}
              exact
              component={NewsletterSettingsUnsubscribe}
            />

            <RouteAppLayout
              path={Routes.vendor_outreachmama_pricing_path({ format: null })}
              exact
              component={PricingForClientsPage}
            />

            <RouteAppLayout
              path={Routes.proxies_path({ format: null })}
              exact
              component={ProxiesPage}
            />

            <RouteAppLayout
              path={Routes.impersonates_path({ format: null })}
              exact
              component={Impersonates}
            />

            {/* Email Outreach */}
            <RouteAppLayout
              path={Routes.prospects_path({ format: null })}
              exact
              component={ProspectsPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout path={UNSUBSCRIBE_PROSPECT_PATHS} component={UnsubscribeProspectPage} />

            <RouteAppLayout
              path={Routes.eo_blacklisted_domains_path({ format: null })}
              exact
              component={EoBlacklistedDomainsPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout
              path={Routes.eo_domains_path({ format: null })}
              exact
              component={EoDomainsPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout
              path={Routes.eo_campaigns_path({ format: null })}
              exact
              component={EoCampaignsPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout
              path={EO_CAMPAIGN_PAGE_PATHS}
              exact
              component={EoCampaignPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout
              path={Routes.eo_drip_lists_path({ format: null })}
              exact
              component={EoDripListsPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout
              path={Routes.eo_inboxes_settings_path({ format: null })}
              exact
              component={EoInboxesSettingsPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout
              path={[Routes.edit_eo_inbox_path, Routes.new_eo_inbox_path].map(formatRoRPath)}
              exact
              component={EoInboxFormPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout
              path={Routes.eo_dashboard_index_path({ format: null })}
              exact
              component={EoDashboardPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout
              path={Routes.eo_custom_fields_path({ format: null })}
              exact
              component={EoCustomFieldsPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout
              path={Routes.eo_active_hours_sets_path({ format: null })}
              exact
              component={EoActiveHoursPage}
              sidebarItems={emailOutreachSidebarItems}
            />

            <RouteAppLayout
              path={Routes.eo_to_dos_items_path({ format: null })}
              exact
              component={EoToDosPage}
              sidebarItems={emailOutreachSidebarItems}
            />
            
            <RouteAppLayout
              path={EO_EMAIL_TEMPLATE_FOLDERS_PATHS}
              exact
              component={EoEmailTemplatesPage}
              sidebarItems={emailOutreachSidebarItems}
            />
          </Switch>
        )}

        <RouteAppLayout
          path={Routes.style_guide_path({ format: null })}
          exact
          component={StyleGuidePage}
        />
        <RouteAppLayout
          path={Routes.no_access_path({ format: null })}
          exact
          component={NoAccessPage}
        />
        <RouteAppLayout
          path={Routes.admin_reports_path({ format: null })}
          exact
          component={AdminReportsPage}
        />
        <RouteAppLayout
          path={Routes.subscription_limit_path({ format: null })}
          exact
          component={SubscriptionLimitPage}
        />
      </AppContainerWithListener>
    </Router>
  );
};

class AppContainer extends React.PureComponent {
  componentDidMount() {
    window._mfq = window._mfq || [];
    this.unlisten = this.props.history.listen(({ pathname }) =>
      window._mfq.push(['newPageView', pathname]),
    );
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return <>{this.props.children}</>;
  }
}

const AppContainerWithListener = compose(ConnectStoreHOC, connect(), withRouter)(AppContainer);

export default compose(ErrorBoundary)(App);