const DELETE_PAGE__FAILURE = 'DELETE_PAGE__FAILURE';
const DELETE_PAGE__START = 'DELETE_PAGE__START';
const DELETE_PAGE__SUCCESS = 'DELETE_PAGE__SUCCESS';
const ENQUEUE_AHREFS_METRICS_UPLOADING__FAILURE = 'ENQUEUE_AHREFS_METRICS_UPLOADING__FAILURE';
const ENQUEUE_AHREFS_METRICS_UPLOADING__START = 'ENQUEUE_AHREFS_METRICS_UPLOADING__START';
const ENQUEUE_AHREFS_METRICS_UPLOADING__SUCCESS = 'ENQUEUE_AHREFS_METRICS_UPLOADING__SUCCESS';
const ENQUEUE_MOZ_METRICS_UPLOADING__FAILURE = 'ENQUEUE_MOZ_METRICS_UPLOADING__FAILURE';
const ENQUEUE_MOZ_METRICS_UPLOADING__START = 'ENQUEUE_MOZ_METRICS_UPLOADING__START';
const ENQUEUE_MOZ_METRICS_UPLOADING__SUCCESS = 'ENQUEUE_MOZ_METRICS_UPLOADING__SUCCESS';
const FETCH_BACKLINKS_COUNT_FROM_AHREFS__FAILURE = 'FETCH_BACKLINKS_COUNT_FROM_AHREFS__FAILURE';
const FETCH_BACKLINKS_COUNT_FROM_AHREFS__START = 'FETCH_BACKLINKS_COUNT_FROM_AHREFS__START';
const FETCH_BACKLINKS_COUNT_FROM_AHREFS__SUCCESS = 'FETCH_BACKLINKS_COUNT_FROM_AHREFS__SUCCESS';
const FETCH_BRAND_PAGES_FOR_FILTER_BY_BRAND_ID__FAILURE =
  'FETCH_BRAND_PAGES_FOR_FILTER_BY_BRAND_ID__FAILURE';
const FETCH_BRAND_PAGES_FOR_FILTER_BY_BRAND_ID__SUCCESS =
  'FETCH_BRAND_PAGES_FOR_FILTER_BY_BRAND_ID__SUCCESS';
const FETCH_COMPETITOR_PAGES_PERCENTAGES__FAILURE = 'FETCH_COMPETITOR_PAGES_PERCENTAGES__FAILURE';
const FETCH_COMPETITOR_PAGES_PERCENTAGES__START = 'FETCH_COMPETITOR_PAGES_PERCENTAGES__START';
const FETCH_COMPETITOR_PAGES_PERCENTAGES__SUCCESS = 'FETCH_COMPETITOR_PAGES_PERCENTAGES__SUCCESS';
const FETCH_PAGES__FAILURE = 'FETCH_PAGES__FAILURE';
const FETCH_PAGES__START = 'FETCH_PAGES__START';
const FETCH_PAGES__SUCCESS = 'FETCH_PAGES__SUCCESS';
const FETCH_PAGE_PERCENTAGE__FAILURE = 'FETCH_PAGE_PERCENTAGE__FAILURE';
const FETCH_PAGE_PERCENTAGE__START = 'FETCH_PAGE_PERCENTAGE__START';
const FETCH_PAGE_PERCENTAGE__SUCCESS = 'FETCH_PAGE_PERCENTAGE__SUCCESS';
const FETCH_SINGLE_PAGE__FAILURE = 'FETCH_SINGLE_PAGE__FAILURE';
const FETCH_SINGLE_PAGE__START = 'FETCH_SINGLE_PAGE__START';
const FETCH_SINGLE_PAGE__SUCCESS = 'FETCH_SINGLE_PAGE__SUCCESS';
const REDISTRIBUTE_DESTINATION_LINKS__FAILURE = 'REDISTRIBUTE_DESTINATION_LINKS__FAILURE';
const REDISTRIBUTE_DESTINATION_LINKS__START = 'REDISTRIBUTE_DESTINATION_LINKS__START';
const REDISTRIBUTE_DESTINATION_LINKS__SUCCESS = 'REDISTRIBUTE_DESTINATION_LINKS__SUCCESS';
const TOGGLE_ANCHOR_PROGRESS_PANE = 'TOGGLE_ANCHOR_PROGRESS_PANE';
const UPDATE_PAGE__FAILURE = 'UPDATE_PAGE__FAILURE';
const UPDATE_PAGE__START = 'UPDATE_PAGE__START';
const UPDATE_PAGE__SUCCESS = 'UPDATE_PAGE__SUCCESS';
const ENQUE_GENERATE_BRAND_PAGE_METRIC_START = 'ENQUE_GENERATE_BRAND_PAGE_METRIC_START';
const ENQUE_GENERATE_BRAND_PAGE_METRIC_SUCCESS = 'ENQUE_GENERATE_BRAND_PAGE_METRIC_SUCCESS';
const ENQUE_GENERATE_BRAND_PAGE_METRIC_FAILURE = 'ENQUE_GENERATE_BRAND_PAGE_METRIC_FAILURE';

function deletePageFailure(errors) {
  return { type: DELETE_PAGE__FAILURE, errors };
}

function enqueueMozMetricsUploadingStart() {
  return { type: ENQUEUE_MOZ_METRICS_UPLOADING__START };
}

function enqueueMozMetricsUploadingSuccess() {
  return { type: ENQUEUE_MOZ_METRICS_UPLOADING__SUCCESS };
}

function enqueueMozMetricsUploadingFailure(errors) {
  return { type: ENQUEUE_MOZ_METRICS_UPLOADING__FAILURE, errors };
}

function deletePageStart() {
  return { type: DELETE_PAGE__START };
}

function deletePageSuccess(page) {
  return { type: DELETE_PAGE__SUCCESS, page };
}

function enqueueAhrefsMetricsUploadingFailure(errors) {
  return { type: ENQUEUE_AHREFS_METRICS_UPLOADING__FAILURE, errors };
}

function enqueueAhrefsMetricsUploadingStart() {
  return { type: ENQUEUE_AHREFS_METRICS_UPLOADING__START };
}

function enqueueAhrefsMetricsUploadingSuccess() {
  return { type: ENQUEUE_AHREFS_METRICS_UPLOADING__SUCCESS };
}

function fetchAhrefsLimitsFailure(errors) {
  return { type: FETCH_BACKLINKS_COUNT_FROM_AHREFS__FAILURE, errors };
}

function fetchAhrefsLimitsStart() {
  return { type: FETCH_BACKLINKS_COUNT_FROM_AHREFS__START };
}

function fetchAhrefsLimitsSuccess(result) {
  return { type: FETCH_BACKLINKS_COUNT_FROM_AHREFS__SUCCESS, result };
}

function fetchBrandPagesForFilterByBrandIdFailure() {
  return { type: FETCH_BRAND_PAGES_FOR_FILTER_BY_BRAND_ID__FAILURE };
}

function fetchBrandPagesForFilterByBrandIdSuccess(brandPages) {
  return { type: FETCH_BRAND_PAGES_FOR_FILTER_BY_BRAND_ID__SUCCESS, brandPages };
}

function fetchCompetitorPagesPercentagesFailure(errors) {
  return { type: FETCH_COMPETITOR_PAGES_PERCENTAGES__FAILURE, errors };
}

function fetchCompetitorPagesPercentagesStart() {
  return { type: FETCH_COMPETITOR_PAGES_PERCENTAGES__START };
}

function fetchCompetitorPagesPercentagesSuccess(competitorPagesPercentages) {
  return { type: FETCH_COMPETITOR_PAGES_PERCENTAGES__SUCCESS, competitorPagesPercentages };
}

function fetchPagePercentageFailure(errors) {
  return { type: FETCH_PAGE_PERCENTAGE__FAILURE, errors };
}

function fetchPagePercentageStart() {
  return { type: FETCH_PAGE_PERCENTAGE__START };
}

function fetchPagePercentageSuccess(pageId, percentagesObject) {
  return { type: FETCH_PAGE_PERCENTAGE__SUCCESS, pageId, percentagesObject };
}

function fetchPagesFailure(errors) {
  return { type: FETCH_PAGES__FAILURE, errors };
}

function fetchPagesStart() {
  return { type: FETCH_PAGES__START };
}

function fetchPagesSuccess(pages, pageParentType) {
  return { type: FETCH_PAGES__SUCCESS, pages, pageParentType };
}

function fetchSinglePageFailure(errors) {
  return { type: FETCH_SINGLE_PAGE__FAILURE, errors };
}

function fetchSinglePageStart() {
  return { type: FETCH_SINGLE_PAGE__START };
}

function fetchSinglePageSuccess(page) {
  return { type: FETCH_SINGLE_PAGE__SUCCESS, page };
}

function reDistributeDestinationLinksFailure(errors) {
  return { type: REDISTRIBUTE_DESTINATION_LINKS__FAILURE, errors };
}

function reDistributeDestinationLinksStart() {
  return { type: REDISTRIBUTE_DESTINATION_LINKS__START };
}

function reDistributeDestinationLinksSuccess(page) {
  return { type: REDISTRIBUTE_DESTINATION_LINKS__SUCCESS, page };
}

function toggleAnchorProgressPane(pageParentType) {
  return { type: TOGGLE_ANCHOR_PROGRESS_PANE, pageParentType };
}

function updatePageFailure(errors) {
  return { type: UPDATE_PAGE__FAILURE, errors };
}

function updatePageStart() {
  return { type: UPDATE_PAGE__START };
}

function updatePageSuccess(page) {
  return { type: UPDATE_PAGE__SUCCESS, page };
}
function enqueGenerateBrandPageMetricStart(page){
  return { type: ENQUE_GENERATE_BRAND_PAGE_METRIC_START, page }
}
function enqueGenerateBrandPageMetricSuccess(page){
  return { type: ENQUE_GENERATE_BRAND_PAGE_METRIC_SUCCESS, page}
}
function enqueGenerateBrandPageMetricFailure(errors){
  return { type: ENQUE_GENERATE_BRAND_PAGE_METRIC_FAILURE, errors }
}
export {
  DELETE_PAGE__FAILURE,
  DELETE_PAGE__START,
  DELETE_PAGE__SUCCESS,
  ENQUEUE_AHREFS_METRICS_UPLOADING__FAILURE,
  ENQUEUE_AHREFS_METRICS_UPLOADING__START,
  ENQUEUE_AHREFS_METRICS_UPLOADING__SUCCESS,
  ENQUEUE_MOZ_METRICS_UPLOADING__FAILURE,
  ENQUEUE_MOZ_METRICS_UPLOADING__START,
  ENQUEUE_MOZ_METRICS_UPLOADING__SUCCESS,
  FETCH_BACKLINKS_COUNT_FROM_AHREFS__FAILURE,
  FETCH_BACKLINKS_COUNT_FROM_AHREFS__START,
  FETCH_BACKLINKS_COUNT_FROM_AHREFS__SUCCESS,
  FETCH_BRAND_PAGES_FOR_FILTER_BY_BRAND_ID__FAILURE,
  FETCH_BRAND_PAGES_FOR_FILTER_BY_BRAND_ID__SUCCESS,
  FETCH_COMPETITOR_PAGES_PERCENTAGES__FAILURE,
  FETCH_COMPETITOR_PAGES_PERCENTAGES__START,
  FETCH_COMPETITOR_PAGES_PERCENTAGES__SUCCESS,
  FETCH_PAGES__FAILURE,
  FETCH_PAGES__START,
  FETCH_PAGES__SUCCESS,
  FETCH_PAGE_PERCENTAGE__FAILURE,
  FETCH_PAGE_PERCENTAGE__START,
  FETCH_PAGE_PERCENTAGE__SUCCESS,
  FETCH_SINGLE_PAGE__FAILURE,
  FETCH_SINGLE_PAGE__START,
  FETCH_SINGLE_PAGE__SUCCESS,
  REDISTRIBUTE_DESTINATION_LINKS__FAILURE,
  REDISTRIBUTE_DESTINATION_LINKS__START,
  REDISTRIBUTE_DESTINATION_LINKS__SUCCESS,
  TOGGLE_ANCHOR_PROGRESS_PANE,
  UPDATE_PAGE__FAILURE,
  UPDATE_PAGE__START,
  UPDATE_PAGE__SUCCESS,
  ENQUE_GENERATE_BRAND_PAGE_METRIC_START,
  ENQUE_GENERATE_BRAND_PAGE_METRIC_SUCCESS,
  ENQUE_GENERATE_BRAND_PAGE_METRIC_FAILURE,
  deletePageFailure,
  deletePageStart,
  deletePageSuccess,
  enqueueAhrefsMetricsUploadingFailure,
  enqueueAhrefsMetricsUploadingStart,
  enqueueAhrefsMetricsUploadingSuccess,
  enqueueMozMetricsUploadingStart,
  enqueueMozMetricsUploadingSuccess,
  enqueueMozMetricsUploadingFailure,
  fetchAhrefsLimitsFailure,
  fetchAhrefsLimitsStart,
  fetchAhrefsLimitsSuccess,
  fetchBrandPagesForFilterByBrandIdFailure,
  fetchBrandPagesForFilterByBrandIdSuccess,
  fetchCompetitorPagesPercentagesFailure,
  fetchCompetitorPagesPercentagesStart,
  fetchCompetitorPagesPercentagesSuccess,
  fetchPagePercentageFailure,
  fetchPagePercentageStart,
  fetchPagePercentageSuccess,
  fetchPagesFailure,
  fetchPagesStart,
  fetchPagesSuccess,
  fetchSinglePageFailure,
  fetchSinglePageStart,
  fetchSinglePageSuccess,
  reDistributeDestinationLinksFailure,
  reDistributeDestinationLinksStart,
  reDistributeDestinationLinksSuccess,
  toggleAnchorProgressPane,
  updatePageFailure,
  updatePageStart,
  updatePageSuccess,
  enqueGenerateBrandPageMetricStart,
  enqueGenerateBrandPageMetricSuccess,
  enqueGenerateBrandPageMetricFailure
};
