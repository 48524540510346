import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Dropdown } from 'react-bootstrap';

import { brandMetricsShape } from 'common/propTypesShapes/metrics';
import { translate } from 'common/i18n';
import BinIcon from 'common/icons/bin';
import BacklinkIcon from 'common/icons/backlink';
import BulbIcon from 'common/icons/bulb';
import CloudIcon from 'common/icons/CloudIcon';
import DisavowFileIcon from 'common/icons/DisavowFileIcon';
import EditIcon from 'common/icons/edit';
import LensIcon from 'common/icons/lens';
import DotsIcon from 'common/icons/DotsIcon';
import defaultMetricsParams from 'utils/routeUtils';

import DisavowPopup from 'components/DisavowPopup';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import './myBrandsDashboardMenu.scss';
import generateMetricsOverTime from 'utils/generateMetricsOverTime';

// We need that by following documentation react-bootstrap@0.32.4 for creating custom dropdown.
// See https://5c507d49471426000887a6a7--react-bootstrap.netlify.com/components/dropdowns/#btn-dropdowns-custom
// for more detail.
// eslint-disable-next-line react/prefer-stateless-function
class DropdownToggle extends React.PureComponent {
  static propTypes = { onClick: PropTypes.func };

  render() {
    return (
      <button className="my-brands-menu__toggle" onClick={this.props.onClick}>
        <DotsIcon className="my-brands-menu__icon" />
      </button>
    );
  }
}

// We need that by following documentation react-bootstrap@0.32.4 for creating custom dropdown.
// See https://5c507d49471426000887a6a7--react-bootstrap.netlify.com/components/dropdowns/#btn-dropdowns-custom
// for more detail.
// eslint-disable-next-line react/prefer-stateless-function
class DropdownMenu extends React.PureComponent {
  static propTypes = { children: PropTypes.node };

  render() {
    return this.props.children;
  }
}

const buildMenuItems = ({ brand, canUseMetrics, crawlBrand, deleteBrand, goTo}) => [
  {
    icon: BulbIcon,
    text: translate('brand.atpLink.title'),
    action: goTo(Routes.brand_brand_pages_path),
    className: 'capybara--my-brands-menu__atp',
  },
  {
    icon: BacklinkIcon,
    text: translate('brand.backlinksLink.title'),
    action: brand.hasBacklinks
      ? goTo(Routes.backlink_path, defaultMetricsParams(canUseMetrics))
      : goTo(Routes.import_backlink_path),
    className: 'capybara--my-brands-menu__backlinks',
  },
  {
    isDisavowOption: true,
    action: void 0,
    className: 'capybara--my-brands-menu__disavow',
  },
  {
    icon: LensIcon,
    text: translate('brand.crawl.title'),
    action: crawlBrand,
    className: 'capybara--my-brands-menu__verify-backlinks',
  },
 
  {
    icon: EditIcon,
    text: translate('brand.edit.title'),
    action: goTo(Routes.edit_brand_path),
    className: 'capybara--my-brands-menu__edit',
  },
  {
    icon: BinIcon,
    text: translate('brand.delete.title'),
    action: deleteBrand,
    warning: true,
    className: 'capybara--my-brands-menu__delete',
  },
];

class MyBrandsDashboardMenuComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.menuItems = buildMenuItems({
      brand: props.brand,
      canUseMetrics: props.canUseMetrics,
      crawlBrand: props.onCrawlBrand,
      generateMetrics: props.onGenerateMetrics,
      deleteBrand: props.onDeleteBrand,
      goTo: props.goTo,
    });
  }

  buildDisavowOption = () => {
    const { brand } = this.props;

    const DisavowIcon = brand.disavowFilename ? DisavowFileIcon : CloudIcon;

    return (
      <ToggleShowPopup
        className="my-brands-menu__disavow-option-wrapper"
        Popup={<DisavowPopup brand={brand} />}
      >
        <span className="my-brands-menu__wrapper-item-icon">
          <DisavowIcon className="my-brands-menu__item-icon" />
        </span>
        <span>{translate('brand.disavowLinks.title')}</span>
      </ToggleShowPopup>
    );
  };

  handleOnBlur = () => {
    this.setState({ isOpen: false });
  };

  handleOnToggle = (isOpen) => {
    this.setState({ isOpen });
  };

  renderMenuItem = ({ icon: Icon, text, action, warning, isDisavowOption, className }, key) => {
    return (
      <li
        className={cx('my-brands-menu__item', className, {
          'my-brands-menu__item_warning': warning,
        })}
        key={key}
        onMouseDown={action}
      >
        {isDisavowOption ? (
          this.buildDisavowOption()
        ) : (
          <>
            <span className="my-brands-menu__wrapper-item-icon">
              <Icon className="my-brands-menu__item-icon" />
            </span>
            <span>{text}</span>
          </>
        )}
      </li>
    );
  };

  render() {
    const { brand } = this.props;
    const { isOpen } = this.state;

    return (
      <Dropdown
        className="my-brands-menu"
        id={`my-brand-menu-${brand.id}`}
        onBlur={this.handleOnBlur}
        onToggle={this.handleOnToggle}
        open={isOpen}
      >
        <DropdownToggle bsRole="toggle" />
        <DropdownMenu bsRole="menu">
          <div
            className="
            dropdown-menu
            dropdown-menu-right
            my-brands-menu__menu"
          >
            <ul className="my-brands-menu__items-list">
              {this.menuItems.map(this.renderMenuItem)}
            </ul>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

MyBrandsDashboardMenuComponent.propTypes = {
  brand: brandMetricsShape,
  canUseMetrics: PropTypes.bool,
  goTo: PropTypes.func.isRequired,
  onCrawlBrand: PropTypes.func.isRequired,
  onGenerateMetrics: PropTypes.func.isRequired,
  onDeleteBrand: PropTypes.func.isRequired,
};

export default MyBrandsDashboardMenuComponent;
