import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';

const rankTrackerSettings = (state) => state.get('rankTrackerSettings');

const rankTrackerSettingsSelector = createSelector(
  [rankTrackerSettings],
  (rankTrackerSettings) => rankTrackerSettings || iMap()
);

export {
  rankTrackerSettingsSelector,
};
