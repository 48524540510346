import { fromJS, Map as iMap } from 'immutable';
import { keyBy } from 'lodash';

import { FETCH_SIMPLE_BRANDS__SUCCESS } from 'actions/simpleBrandActions';

import {
  CREATE_BRAND__SUCCESS,
  DELETE_BRANDS__SUCCESS,
  UPDATE_BRAND__SUCCESS,
} from 'actions/brand_actions';

import { camelizeObject } from 'reducers/utils';

const initialState = iMap();

const processBrand = (brand) => {
  if (!brand) {
    return void 0;
  }

  return camelizeObject(brand);
};

export default function simpleBrandReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SIMPLE_BRANDS__SUCCESS: {
      const brands = action.brands || [];
      state = initialState.merge(fromJS(keyBy(brands.map(processBrand), 'id')));

      break;
    }
    case CREATE_BRAND__SUCCESS:
    case UPDATE_BRAND__SUCCESS: {
      state = state.merge(state, fromJS(keyBy([processBrand(action.brand)], 'id')));

      break;
    }
    case DELETE_BRANDS__SUCCESS: {
      state = state.deleteAll(action.ids.map(String));

      break;
    }
  }

  return state;
}
