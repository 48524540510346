import React from 'react';
import PropTypes from 'prop-types';

const downPath = (
  <path d="M 3.328 2.624 L 0.852 0.147 C 0.652 -0.053 0.342 -0.046 0.145 0.149 C -0.045 0.339 -0.051 0.659 0.143 0.854 L 2.976 3.687 C 3.076 3.787 3.202 3.834 3.329 3.834 C 3.454 3.83 3.583 3.784 3.679 3.686 L 6.512 0.853 C 6.712 0.653 6.705 0.343 6.51 0.148 C 6.32 -0.042 5.998 -0.048 5.804 0.146 L 3.328 2.624 Z" fillRule="evenodd" />
);

const upPath = (
  <path d="M 3.328 1.21 L 0.852 3.687 C 0.652 3.887 0.342 3.88 0.145 3.685 C -0.045 3.495 -0.051 3.175 0.143 2.98 L 2.976 0.147 C 3.076 0.047 3.202 0 3.329 0 C 3.454 0.004 3.583 0.05 3.679 0.148 L 6.512 2.981 C 6.712 3.181 6.705 3.491 6.51 3.686 C 6.32 3.876 5.998 3.882 5.804 3.688 L 3.328 1.21 Z" fillRule="evenodd" />
);

const rightPath = (
  <path d="M 1.915 2.623 L -0.561 5.1 C -0.761 5.3 -1.071 5.293 -1.268 5.098 C -1.458 4.908 -1.464 4.588 -1.27 4.393 L 1.563 1.56 C 1.663 1.46 1.789 1.413 1.916 1.413 C 2.041 1.417 2.17 1.463 2.266 1.561 L 5.099 4.394 C 5.299 4.594 5.292 4.904 5.097 5.099 C 4.907 5.289 4.585 5.295 4.391 5.101 L 1.915 2.623 Z" fillRule="evenodd" transform="matrix(0, 1, -1, 0, 5.246636, 1.413508)" />
);

const leftPath = (
  <path d="M 1.915 4.037 L -0.561 1.56 C -0.761 1.36 -1.071 1.367 -1.268 1.562 C -1.458 1.752 -1.464 2.072 -1.27 2.267 L 1.563 5.1 C 1.663 5.2 1.789 5.247 1.916 5.247 C 2.041 5.243 2.17 5.197 2.266 5.099 L 5.099 2.266 C 5.299 2.066 5.292 1.756 5.097 1.561 C 4.907 1.371 4.585 1.365 4.391 1.559 L 1.915 4.037 Z" fillRule="evenodd" transform="matrix(0, 1, -1, 0, 5.246492, 1.413364)" />
);

const path = (direction) => {
  let path;
  switch (direction) {
    case 'down':
      path = downPath;
      break;
    case 'right':
      path = rightPath;
      break;
    case 'left':
      path = leftPath;
      break;
    case 'up':
      path = upPath;
      break;
  }
  return path;
};

const viewBox = (direction) => {
  let viewBox;
  switch (direction) {
    case 'up':
    case 'down': {
      viewBox = "0 0 6.658999919891357 3.8340001106262207";
      break;
    }
    case 'right':
    case 'left': {
      viewBox = "0 0 3.8340001106262207 6.658999919891357";
      break;
    }
  }
  return viewBox;
};

const Chevron = (props) => {
  const { direction, ...svgProps } = props;

  return (
    <svg {...svgProps} viewBox={viewBox(direction)} xmlns="http://www.w3.org/2000/svg">
      {path(direction)}
    </svg>
  );
};

Chevron.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['down', 'right', 'left', 'up']),
  width: PropTypes.string
};

Chevron.defaultProps = {
  className: '',
  direction: 'right',
  fill: '#1abc9c',
  width: '14px',
};

export default Chevron;
