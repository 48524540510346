import { updateBacklinksSuccess } from 'actions/backlinkActions';
import { updateBrandSuccess } from 'actions/brand_actions';

function updateBrandsAndBacklinks(data, dispatch) {
  const { brands, destinationLinks } = data;
  // workaround for brands == [null] case
  if (!brands[0]) {
    return;
  }

  brands.forEach((brand) => dispatch(updateBrandSuccess(brand)));
  dispatch(updateBacklinksSuccess(brands[0].id, destinationLinks));
}

export default updateBrandsAndBacklinks;
