import {  fromJS, Map as iMap } from 'immutable';
import { camelCase } from 'lodash';

import {
  FETCH_COMPETITOR_PAGES_PERCENTAGES__SUCCESS,
  FETCH_PAGE_PERCENTAGE__SUCCESS,
} from '../actions/brand_page_actions';

import {
  camelizeObject,
} from './utils';

const initialState = iMap();

const processPercentage = (anchor) => {
  const processedPercentage = camelizeObject(anchor);
  processedPercentage.anchorType = camelCase(processedPercentage.anchorType);

  return processedPercentage;
};

const orderPercentage = (percentage) => {
  const orderedPercentage = [];

  percentageOrderList.forEach((anchorType) => {
    percentage.forEach((item) => {
      if (item.anchorType === anchorType) {
        orderedPercentage.push(item);
      }
    });
  });

  return orderedPercentage;
};

const percentageOrderList = [
  'branded',
  'websiteNameCom',
  'exactKeyword',
  'onlyPartOfKeyword',
  'keywordPlusWord',
  'titleTag',
  'brandAndKeywordTogether',
  'nakedUri',
  'nakedUriWoHttp',
  'homepageUri',
  'justNatural',
  'noText',
  'totallyRandom',
];

export default function percentageReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAGE_PERCENTAGE__SUCCESS: {
      const { pageId } = action;
      const { percentage, uncategorized_anchors_count } = action.percentagesObject;

      state = state.setIn(
        [String(pageId), 'uncategorizedAnchorsCount'], uncategorized_anchors_count
      );

      const processedPercentage = percentage.map(processPercentage);
      const orderedPercentage = orderPercentage(processedPercentage);

      state = state.setIn(
        [String(pageId), 'percentage'],
        fromJS(orderedPercentage)
      );

      break;
    }

    case FETCH_COMPETITOR_PAGES_PERCENTAGES__SUCCESS: {
      const { competitors_percentages } = action.competitorPagesPercentages;

      const competitorPagesPercentages = {};
      competitors_percentages.map((percentageItem) => {
        const { id, percentage } = percentageItem;
        const competitorPagePercentage = competitorPagesPercentages[String(id)] = {};

        const processedPercentage = percentage.map(processPercentage);
        const orderedPercentage = orderPercentage(processedPercentage);

        competitorPagePercentage['percentage'] = orderedPercentage;
      });

      state = state.merge(state, fromJS(competitorPagesPercentages));

      break;
    }
  }

  return state;
}
