import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoToDosStart,
  fetchEoToDosSuccess,
  fetchEoToDosFailure,
  fetchEoToDoStart,
  fetchEoToDoSuccess,
  fetchEoToDoFailure,
  sendEoToDoEmailStart,
  sendEoToDoEmailSuccess,
  sendEoToDoEmailFailure,
  updateEoToDoStart,
  updateEoToDoSuccess,
  updateEoToDoFailure,
  deleteEoToDosStart,
  deleteEoToDosSuccess,
  deleteEoToDosFailure,
} from 'actions/eoToDoActions';

import { fetchEoToDosPagySuccess } from 'actions/pagy_actions';

import { translate } from 'common/i18n';

import { showSuccessMessage, showSuccessMessageFromTranslation } from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCase,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoToDos(dispatch, params) {
  dispatch(fetchEoToDosStart());

  try {
    const resp = await getFromAPI(Routes.eo_to_dos_items_path(), processObjToSnakeCaseDeep(params));

    dispatch(fetchEoToDosSuccess(resp.eo_to_dos_items));
    dispatch(fetchEoToDosPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoToDosFailure,
      translate('eoToDo.messages.cantFetchEoToDos'),
    );
  }
}

export async function fetchEoToDo(dispatch, eoToDoId) {
  dispatch(fetchEoToDoStart());

  try {
    const resp = await getFromAPI(Routes.eo_to_dos_item_path({ id: eoToDoId }));

    const eoToDo = processObjToCamelCaseDeep(resp);

    dispatch(fetchEoToDoSuccess(eoToDo));

    return eoToDo;
  } catch (err) {
    handleAPIError(dispatch, err, fetchEoToDoFailure, translate('eoToDo.messages.cantFetchEoToDo'));
  }
}

export async function sendEoToDoEmail(dispatch, id, email) {
  dispatch(sendEoToDoEmailStart());

  try {
    const resp = await postToAPI(Routes.send_email_eo_to_dos_item_path(id), eoMessageObject(email));

    dispatch(sendEoToDoEmailSuccess([id]));
    showSuccessMessageFromTranslation(dispatch, 'eoToDo.messages.sendEoToDoEmailSuccess');

    return resp;
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      sendEoToDoEmailFailure,
      translate('eoToDo.messages.cantSendEoToDoEmail'),
    );
  }
}

export async function updateEoToDo(dispatch, params) {
  const { id, userId } = params;

  dispatch(updateEoToDoStart());

  try {
    const resp = await patchToAPI(
      Routes.eo_to_dos_item_path({ id }),
      processObjToSnakeCase(params),
    );

    const userAssignMessage = userId
      ? 'eoToDo.messages.assignSuccess'
      : 'eoToDo.messages.revokeAssignmentSuccess';

    dispatch(updateEoToDoSuccess(resp));
    showSuccessMessageFromTranslation(dispatch, userAssignMessage);

    return resp;
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      updateEoToDoFailure,
      translate('eoToDo.messages.cantUpdateEoToDo'),
    );
  }
}

export async function deleteEoToDos(dispatch, ids) {
  dispatch(deleteEoToDosStart());

  try {
    const resp = await deleteToAPI(Routes.eo_to_dos_items_path({ ids }));

    dispatch(deleteEoToDosSuccess(ids));
    showSuccessMessage(dispatch, translate('eoToDo.messages.deleteSuccess'));

    return resp;
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoToDosFailure,
      translate('eoToDo.messages.cantDeleteEoToDos'),
    );
  }
}

const eoMessageObject = (data) => ({ eo_message: processObjToSnakeCase(data) });
