import React from 'react';

import { translate } from '../../../common/i18n';

import LinkioLogo from '../../../common/icons/linkio_logo_white.svg';
import TestimonialsComponent from '../../../components_linkio/testimonials_component';
import CopyrightComponent from '../copyright_component';

import './left_column_login_signup_component.scss';


const LeftColumnSignInUpComponent = () => {
  return (
    <div className="left-column-login-signup-component">
      <a
        className="left-column-login-signup-component__logo"
        href={translate('projectHomeURL')}
        title={translate('projectTitle')}
      >
        <img src={LinkioLogo} alt="Linkio" />
      </a>
      <h2 className="left-column-login-signup-component__text">
        {translate('authForms.leftColumnSlogan')}
      </h2>
      <TestimonialsComponent />
      <div className="left-column-login-signup-component__copyright">
        <CopyrightComponent />
      </div>
    </div>
  );
};

export default LeftColumnSignInUpComponent;
