import { isEmpty } from 'lodash';

import { translate } from '../common/i18n';
import { showSuccessMessage } from '../common/utils';


import {
  createEmployeeFailure,
  createEmployeeStart,
  createEmployeeSuccess,
  deleteEmployeesFailure,
  deleteEmployeesStart,
  deleteEmployeesSuccess,
  fetchEmployeesStart,
  fetchEmployeesSuccess,
  fetchEmployeesFailure,
  inviteEmployeeFailure,
  inviteEmployeeStart,
  inviteEmployeeSuccess,
  updateEmployeeFailure,
  updateEmployeeStart,
  updateEmployeeSuccess,
} from '../actions/employee_actions';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToCamelCase,
  processObjToSnakeCase,
} from './utils';


export async function fetchEmployees(dispatch) {
  dispatch(fetchEmployeesStart());
  try {
    const resp = await getFromAPI(Routes.employees_path());
    dispatch(fetchEmployeesSuccess(resp.employees));
  } catch (err) {
    handleAPIError(dispatch, err, fetchEmployeesFailure, translate('errors.cantFetchEmployees'));
  }
}

export async function updateEmployee(dispatch, data) {
  dispatch(updateEmployeeStart());
  try {
    const resp = await patchToAPI(
      Routes.employee_path({ id: data.id }),
      employeeObject(data)
    );
    dispatch(updateEmployeeSuccess(processResponce(resp)));

    return processResponce(resp);
  } catch (err) {
    return handleAPIError(dispatch, err, updateEmployeeFailure, translate('errors.cantUpdateEmployee'));
  }
}

export async function createEmployee(dispatch, data) {
  dispatch(createEmployeeStart());
  try {
    const resp = await postToAPI(Routes.employees_path(), employeeObject(data));
    if (isEmpty(resp.errors)) {
      dispatch(createEmployeeSuccess(resp));
    } else {
      dispatch(createEmployeeFailure(resp));
    }


    return processResponce(resp);
  } catch (err) {
    return handleAPIError(dispatch, err, createEmployeeFailure, translate('errors.cantCreateEmployee'));
  }
}

export async function deleteEmployees(dispatch, employeeIds = []) {
  dispatch(deleteEmployeesStart());
  try {
    await deleteToAPI(Routes.bulk_destroy_employees_path(), { ids: employeeIds });
    showSuccessMessage(dispatch, translate('employee.messages.deleteSuccess', { count: employeeIds.length }));
    dispatch(deleteEmployeesSuccess(employeeIds));
  } catch (err) {
    handleAPIError(dispatch, err, deleteEmployeesFailure, translate('errors.cantDeleteEmployees'));
  }
}

export async function inviteEmployees(dispatch, employeesIds = []) {
  dispatch(inviteEmployeeStart());
  try {
    const resp = await postToAPI(
      Routes.users_invitation_invite_employees_path(),
      { ids: employeesIds }
    );
    showSuccessMessage(dispatch, translate('employee.messages.inviteSuccess', { count: employeesIds.length }));
    dispatch(inviteEmployeeSuccess(resp.employees));

    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, inviteEmployeeFailure, translate('errors.cantInviteEmployee'));
  }
}

const employeeObject = (data) => ({ employee: processObjToSnakeCase(data) });

const processResponce = (resp) => ({ ...processObjToCamelCase(resp), errors: processObjToCamelCase(resp.errors) });
