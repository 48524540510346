import { fromJS } from 'immutable';

import {
  FETCH_BRAND_KEYWORDS__SUCCESS,
  UPDATE_BRAND_KEYWORDS__SUCCESS,
} from '../actions/rankTrackerKeywordsActions';

const initialState = fromJS({
  brandKeywords: [],
});

export default function rankTrackerKeywordsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRAND_KEYWORDS__SUCCESS:
    case UPDATE_BRAND_KEYWORDS__SUCCESS: {
      const { keywords } = action;

      state = state.set('brandKeywords', fromJS(keywords));

      break;
    }
  }

  return state;
}
