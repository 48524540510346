import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ChangesCountValueComponent from './ChangesCountValueComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  keywordsWithChangesShape,
  keywordsWithChangesByUrlShape,
} from 'common/prop_types_shapes';

import { openUrlChangingHistoryPopup } from 'actions/popup_actions';


const ChangesCountValueContainer = ({ dispatch, brandPageId, isActive, keyword }) => {
  const { id: keywordId, changesCount = 0, changesHistory } = keyword.toJS();

  function handleChangesCountClick() {
    dispatch(openUrlChangingHistoryPopup(keywordId, brandPageId));
  }

  return (
    <ChangesCountValueComponent
      changesCount={changesCount}
      changesHistory={changesHistory}
      isActive={isActive}
      onChangesCountClick={handleChangesCountClick}
    />
  );
};

ChangesCountValueContainer.propTypes = {
  brandPageId: PropTypes.number,
  dispatch: PropTypes.func,
  isActive: PropTypes.bool,
  keyword: PropTypes.oneOfType([keywordsWithChangesShape, keywordsWithChangesByUrlShape]),
};

export default ConnectStoreHOC(connect()(ChangesCountValueContainer));
