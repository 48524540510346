import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import BulkPagesSetupBulkActionsComponent from './BulkPagesSetupBulkActionsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { selectedIdsList } from 'common/prop_types_shapes';

import { currentIdSelector, optionsSelector, uiSelector } from 'selectors';
import { currentBrandContextSelector } from 'selectors/brandsContextSelector';

import {
  currentMarketerSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import { addPagesToAtp } from 'api/bulkPagesSetup';
import { bulkPagesSetupPagyByBrandIdSelector } from 'selectors/bulkPagesSetupSelector';
import { fetchAhrefsAbilities } from 'api/ahrefs_connection';
import { resetSelectedIds } from 'actions/ui_actions';
import { showSuccessMessage, showErrorMessage, trackHelpcrunchEvent } from 'common/utils';
import { translate } from 'common/i18n';

const BulkPagesSetupBulkActionsContainer = ({
  ahrefsAbilities: {
    freeAhrefsSubscription: hasFreeAhrefsSubscription,
    limitReached: isAhrefsLimitReached,
  },
  brand,
  brandId,
  canUseAhrefsInSubscription,
  changeQueryParams,
  dispatch,
  hasAhrefsConnection,
  history,
  isBottom,
  pageStatusOptions,
  queryParams,
  selectedIds,
}) => {
  React.useEffect(() => {
    fetchAhrefsAbilities(dispatch);
  }, []);

  const [addingPagesToAtp, setAddingPagesToAtp] = React.useState(false);

  async function handleAddPagesToAtpClick() {
    const selectedPagesIds = selectedIds.toJS();

    // If nothing is selected navigate the user to ATP
    if (selectedPagesIds.length === 0) {
      history.push(Routes.brand_brand_pages_path(brandId, { format: null }));
      return;
    }

    setAddingPagesToAtp(true);
    const resp = await addPagesToAtp(dispatch, brandId, selectedPagesIds);
    setAddingPagesToAtp(false);

    if (!resp.success) {
      return;
    }

    handleAddToAtpSuccess(selectedPagesIds);
  }

  function handleAddToAtpSuccess(pageIds) {
    trackHelpcrunchEvent('add.brandPage');
    showSuccessMessage(
      dispatch,
      translate('brandPage.messages.createSuccessfully')(pageIds.length),
    );

    if (
      hasAhrefsConnection &&
      canUseAhrefsInSubscription &&
      brand.get('importBacklinksFromAhrefs')
    ) {
      if (isAhrefsLimitReached) {
        showErrorMessage(dispatch, translate('brandPage.messages.ahrefsLimitReached'), true);
      }
      if (hasFreeAhrefsSubscription) {
        showErrorMessage(dispatch, translate('brandPage.messages.ahrefsFreeAccount'), true);
      }
    }

    // Reset selected ids after successful adding to ATP
    dispatch(resetSelectedIds());
  }

  function handleSubmitSearch(value) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      search: value,
    };

    changeQueryParams(newQueryParams);
  }

  const selectedPagesCount = selectedIds.size;

  return (
    <BulkPagesSetupBulkActionsComponent
      addingPagesToAtp={addingPagesToAtp}
      isBottom={isBottom}
      onAddPagesToAtpClick={handleAddPagesToAtpClick}
      onSubmitSearch={handleSubmitSearch}
      pageStatusOptions={pageStatusOptions.toList().toJS()}
      selectedPagesCount={selectedPagesCount}
    />
  );
};

BulkPagesSetupBulkActionsContainer.propTypes = {
  brandId: PropTypes.string,
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isBottom: PropTypes.bool,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const brand = currentBrandContextSelector(state, ownProps);

  const bulkPagesSetupPagy = bulkPagesSetupPagyByBrandIdSelector(state, brandId);

  const options = optionsSelector(state, ownProps);
  const pageStatusOptions = options.get('pageStatusOptions');

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  const currentMarketer = currentMarketerSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);
  const ahrefsAbilities = state.getIn(['ahrefsConnection', 'abilities']).toJS();
  const hasAhrefsConnection = currentMarketer.get('has_ahrefs_connection');
  const canUseAhrefsInSubscription = subscriptionPolicies.getIn(
    ['ahrefsIntegration', 'canUse'],
    false,
  );

  return {
    ahrefsAbilities,
    brand,
    brandId,
    bulkPagesSetupPagy,
    canUseAhrefsInSubscription,
    hasAhrefsConnection,
    pageStatusOptions,
    selectedIds,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(BulkPagesSetupBulkActionsContainer);
