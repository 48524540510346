import React from 'react';
import PropTypes from 'prop-types';

import './auth_form_global_error_component.scss';

const AuthFormGlobalErrorComponent = ({ message }) => {
  return (
    <div className="auth-form-global-error-component__message">
      <div className="auth-form-global-error-component__message-text">
        {message}
      </div>
    </div>
  );
};

AuthFormGlobalErrorComponent.propTypes = {
  message: PropTypes.string,
};

export default AuthFormGlobalErrorComponent;
