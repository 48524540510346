import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import BrandDashboardComponent from './BrandDashboardComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';
import { policiesSelector } from 'selectors/railsContextSelectors';

import { policiesShape } from 'common/prop_types_shapes';
import { simpleBrandShape } from 'common/propTypesShapes/simpleBrands';

import { currentIdSelector } from 'selectors';
import { currentSimpleBrandSelector } from 'selectors/simpleBrandSelectors';

import Breadcrumbs from 'components/NewBreadcrumbs';
import BreadcrumbsBrandsSelect from 'components/NewBreadcrumbs/BreadcrumbsBrandsSelect';
import ButtonComponent from 'components_linkio/button_component';
import FolderIcon from 'common/icons/folder';
import Link from "components_linkio/link";

class BrandDashboardContainer extends React.Component {
  static propTypes = {
    brandId: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    policies: policiesShape.isRequired,
    simpleBrand: simpleBrandShape,
  };

  handleVisitLink = (link) => () => this.props.history.push(link);

  rightSideBtn = () => {
    const canCreate = this.props.policies.getIn(['brand', 'canCreate']);
    return (
      canCreate && (
        <Link to={Routes.new_brand_path({ format: null })} className="new-brands-link">
          <ButtonComponent className="add-brand-button" isGreen>
            <FolderIcon /> {translate('brand.list.buttons.add')}
          </ButtonComponent>
        </Link>
      )
    );
  };

  render() {
    const { simpleBrand, brandId } = this.props;

    return (
      <>
        {brandId && (
          <>
            <Breadcrumbs rightSideBtn={this.rightSideBtn()}>
              <Breadcrumbs.TextItem>{translate('brandDashboard.title')}</Breadcrumbs.TextItem>
              <BreadcrumbsBrandsSelect targetAppModule="brandDashboard" />
            </Breadcrumbs>
          </>
        )}
        {!simpleBrand.isEmpty() && (
          <div className="page__wrapper">
            <BrandDashboardComponent brand={simpleBrand} onVisitLink={this.handleVisitLink} />
          </div>
        )}
      </>
    );
  }
}

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const simpleBrand = currentSimpleBrandSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);

  return {
    simpleBrand,
    brandId,
    policies,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(BrandDashboardContainer);
