import { translate } from '../../common/i18n';

export const processEmployee = (employee) => {
  if (!employee) {
    return {};
  }

  function handlePlaceholdedAttr(attr) {
    if (employee[attr] === '_clear_') {
      return '';
    }

    return employee[attr] || placeholderTextObject[attr];
  }

  return {
    id: employee.id || 'new',
    avatar: employee.avatar || '',
    name: handlePlaceholdedAttr('name'),
    email: handlePlaceholdedAttr('email'),
    status: employee.status || '',
    profileType: handlePlaceholdedAttr('profileType'),
    selected: employee.selected || false,
    editing: employee.editing,
    errors: employee.errors || {},
  };
};

export const placeholderTextObject = {
  email: translate('employee.email.placeholder'),
  name: translate('employee.name.placeholder'),
  profileType: translate('employee.profileType.placeholder'),
};

export const isEditing = ({ columnIndex, rowData }) => columnIndex === rowData.editing;

export const profileSelectProps = {
  blurInputOnSelect: true,
  autoFocus: true,
  isClearable: false,
  openMenuOnFocus: true,
  placeholder: translate('employee.profileType.placeholder'),
  skinOptions: {
    inTable: true,
  },
  triggerChangeOnBlur: false,
};
