import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { optionsArray } from 'common/prop_types_shapes';

import ButtonComponent from 'components_linkio/button_component';
import Select from 'components_linkio/Select';

import './bulkSetValueInputs.scss';

const selectorTooltip = {
  text: translate('explanationMessages.atpBulkAction.selector'),
  placement: 'top',
  color: 'black',
};


const BulkSetValueInputsComponent = ({
  dlSourcesOptions,
  isSelected,
  onChangeSource,
  onSetProperties,
}) => {
  const [selectedProperty, setSelectedProperty] =
    React.useState({ value: 'source', label: translate('destinationLink.source.title') });
  const [selectedValue, setSelectedValue] = React.useState(null);

  function onSetSelectedProperty(object) {
    setSelectedProperty(object);
    setSelectedValue(null);
  }

  async function onSourceInputChange(object) {
    await onChangeSource((object || {}).value);
    setSelectedValue(object);
  }

  async function onSetButtonClick() {
    await onSetProperties(selectedProperty.value, selectedValue.value);
    setSelectedValue(null);
  }

  function generateSelector(props) {
    const defaultSelectorProps = {
      blurInputOnSelect: false,
      isClearable: false,
      isCreatable: false,
      menuPlacement: "bottom",
      placeholder: translate('destinationLink.bulkActions.column.placeholder'),
      skinOptions: {
        inTable: false,
      },
    };

    return (
      <Select
        {...defaultSelectorProps}
        {...props}
      />
    );
  }

  const propertySelectorInput = (disabled) => {
    const propertySelectorOptions = [
      { value: 'source', label: translate('destinationLink.source.title') },
    ];

    return generateSelector({
      isDisabled: disabled,
      name: "propertySelector",
      onChange: onSetSelectedProperty,
      options: propertySelectorOptions,
      placeholder: translate('destinationLink.bulkActions.column.placeholder'),
      tooltip: selectorTooltip,
      value: disabled ? null : selectedProperty,
    });
  };

  const sourceInput = (disabled) => {
    const placeholder = disabled
      ? translate('destinationLink.bulkActions.value.placeholder')
      : translate('destinationLink.source.placeholder');

    return generateSelector({
      isClearable: true,
      isCreatable: true,
      isDisabled: disabled,
      name: "source",
      onChange: onSourceInputChange,
      options: dlSourcesOptions,
      placeholder,
      tooltip: selectorTooltip,
      value: disabled ? null : selectedValue,
    });
  };

  const inputs = {
    propertySelector: propertySelectorInput,
    source: sourceInput,
  };

  const setButtonIsEnabled = isSelected && selectedValue;

  return (
    <div className="backlinks-bulk-set-value__wrapper">
      <div className="backlinks-bulk-set-value__select">
        {inputs['propertySelector'](!isSelected)}
      </div>
      <div className="backlinks-bulk-set-value__select capybara--backlinks-bulk-set-property">
        {inputs[selectedProperty.value](!isSelected)}
      </div>
      <div className="backlinks-bulk-set-value__button">
        <ButtonComponent
          isDisabled={!setButtonIsEnabled}
          isGreen
          onClick={onSetButtonClick}
          tooltip={selectorTooltip}
        >
          {translate('destinationLink.buttons.set')}
        </ButtonComponent>
      </div>
    </div>
  );
};

BulkSetValueInputsComponent.propTypes = {
  dlSourcesOptions: optionsArray,
  isSelected: PropTypes.bool.isRequired,
  onChangeSource: PropTypes.func.isRequired,
  onSetProperties: PropTypes.func.isRequired,
};

export default BulkSetValueInputsComponent;
