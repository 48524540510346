import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';

const eoBlacklistedDomains = (state) => state.get('eoBlacklistedDomains', iList());
const eoBlacklistedDomainsPagy = (state) => state.getIn(['pagies', 'eoBlacklistedDomainsPagy'], iMap());

export const eoBlacklistedDomainsSelector = createSelector(
  [eoBlacklistedDomains],
  (eoBlacklistedDomains) => eoBlacklistedDomains
);

export const eoBlacklistedDomainsPagySelector = createSelector(
  [eoBlacklistedDomainsPagy],
  (pagy) => pagy
);
