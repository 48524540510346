import React from 'react';

import EoSchedulerFormPopup from '../EoSchedulerFormPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const AddEoSchedulerButtonComponent = () => {
  return (
    <ToggleShowPopup Popup={<EoSchedulerFormPopup />}>
      <ButtonComponent className="eo-schedulers-bulk-actions__add-eo_scheduler" isInitial isGreen>
        {translate('eoScheduler.bulkActions.addEoScheduler')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

export default AddEoSchedulerButtonComponent;
