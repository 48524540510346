import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EoInboxesListComponent from './EoInboxesListComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { eoInboxesList } from 'common/propTypesShapes/eoInboxesSettings';
import { eoInboxesSelector } from 'selectors/eoInboxesSettingsSelector';
import { fetchEoInboxes } from 'api/eoInboxesSettings';
import { translate } from 'common/i18n';

import Spinner from 'components_linkio/Spinner';

const EoInboxesListContainer = ({ canUpdate, dispatch, eoInboxes }) => {
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      await fetchEoInboxes(dispatch);
      setIsFetching(false);
    }

    fetchData();
  }, [dispatch]);

  return (
    <div className="eo-inboxes-list">
      <h3>{translate('eoInboxesSettings.title')}</h3>

      {isFetching && <Spinner isBlack />}
      {!isFetching && <EoInboxesListComponent canUpdate={canUpdate} eoInboxes={eoInboxes} />}
    </div>
  );
};

EoInboxesListContainer.propTypes = {
  canUpdate: PropTypes.bool,
  dispatch: PropTypes.func,
  eoInboxes: eoInboxesList,
};

function select(state) {
  const eoInboxes = eoInboxesSelector(state);

  return {
    eoInboxes,
  };
}

export default ConnectStoreHOC(connect(select)(EoInboxesListContainer));
