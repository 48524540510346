import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import BacklinksChangesHistoryTableComponent from './BacklinksChangesHistoryTableComponent';
import { changesHistoryList } from 'common/propTypesShapes/changesHistory';
import { fetchChangesHistory } from 'api/changesHistory';
import { fetchCurrentSubscription } from 'api/subscription';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  changesHistoryByBrandIdSelector,
  changesHistoryPagyByBrandIdSelector,
} from 'selectors/changesHistorySelector';
import { currentIdSelector, uiSelector } from 'selectors';
import { canUseCrawlersSelector } from 'selectors/railsContextSelectors';

import { translate } from 'common/i18n';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

import HandOnHipRobotImage from 'common/img/hand_on_hip_robot.svg';

const BacklinksChangesHistoryTableContainer = ({
  brandId,
  canUseCrawlers,
  changesHistory,
  changesHistoryPagy,
  changeQueryParams,
  dispatch,
  isFetchingChangesHistory,
  queryParams,
}) => {
  const {
    filters,
    pageNumber = 1,
    pagyItemsCount = 50,
    search = '',
    sorting = {},
  } = queryParams;

  React.useEffect(() => {
    function fetchChangesHistoryWithParams() {
      const fetchingParams = {
        pageNumber,
        pagyItemsCount,
        sorting,
        search,
      };

      fetchChangesHistory(dispatch, brandId, fetchingParams);
    }

    fetchChangesHistoryWithParams();
  }, [
    brandId,
    JSON.stringify(filters),
    search,
    pageNumber,
    pagyItemsCount,
    sorting.sortBy,
    sorting.sortingOrder,
  ]);

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  const handleFetchCurrentSubscription = async () => await fetchCurrentSubscription(dispatch);

  function renderBlankState() {
    return (
      <div className="no-dls-component">
        <BlankStateCtaComponent customIcon={<img src={HandOnHipRobotImage} />}>
          <TitleText>
            <strong>{translate(`changesHistory.table.blankState.noChangesBackLinks.title`)}</strong>
          </TitleText>
          <BodyText>
            {translate(`changesHistory.table.blankState.noChangesBackLinks.body`)}
          </BodyText>
        </BlankStateCtaComponent>
      </div>
    );
  }

  const { sortBy, sortingOrder } = sorting;
  const changesHistoryCount = changesHistoryPagy.get('count');

  return (
    <>
      {changesHistory.size > 0 && (
        <BacklinksChangesHistoryTableComponent
          changesHistory={changesHistory}
          changesHistoryCount={changesHistoryCount}
          brandId={brandId}
          canUseCrawlers={canUseCrawlers}
          onFetchCurrentSubscription={handleFetchCurrentSubscription}
          onTableHeaderClick={handleTableHeaderClick}
          sortBy={sortBy}
          sortingOrder={sortingOrder}
        />
      )}

      {!isFetchingChangesHistory && changesHistory.size === 0 && renderBlankState()}
    </>
  );
};

BacklinksChangesHistoryTableContainer.propTypes = {
  brandId: PropTypes.string,
  canUseCrawlers: PropTypes.bool,
  changesHistory: changesHistoryList,
  dispatch: PropTypes.func,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const canUseCrawlers = canUseCrawlersSelector(state, ownProps);
  const changesHistory = changesHistoryByBrandIdSelector(state, brandId);
  const changesHistoryPagy = changesHistoryPagyByBrandIdSelector(state, brandId);

  const uiStates = uiSelector(state);
  const isFetchingChangesHistory = uiStates.getIn(['loading', 'fetchChangesHistory'], false);

  return {
    brandId,
    canUseCrawlers,
    changesHistory,
    changesHistoryPagy,
    isFetchingChangesHistory,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(BacklinksChangesHistoryTableContainer);
