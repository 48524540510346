import React from 'react';

// The hook which allowing gets a variable value from the previous render.
function useGetPreviousValue(val, initialValue = null) {
  const ref = React.useRef(initialValue);
  React.useEffect(() => {
    ref.current = val;
  });
  return ref.current;
}

export default useGetPreviousValue;
