import pluralize from 'pluralize';
import { buildWordInPastTenseWithPluralization } from 'common/utils';

export default {
  eoDomain: {
    breadcrumbsTitle: 'Domains',
    bulkActions: {
      addEoDomain: 'Add Domain',
    },
    messages: {
      cantFetchEoDomains: "Can't load domains",
      cantFetchEoDomain: "Can't load domain",
      cantCreateEoDomain: "Can't create domain",
      cantUpdateEoDomain: "Can't update domain",
      cantDeleteEoDomains: "Can't delete domain(s)",
      createSuccess: 'Domain was created successfully.',
      deleteSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization('Domain', count)} deleted successfully.`;
      },
      updateSuccess: 'Domain was updated successfully.',
    },
    eoDomainFormPopup: {
      buttons: {
        cancel: 'Cancel',
        submit: 'Save',
      },
      inputs: {
        domain: {
          label: 'Domain Name',
        },
      },
      title: (isExistingEoDomain) => (isExistingEoDomain ? 'Update Domain' : 'Add Domain'),
    },
    table: {
      blankState: {
        body: 'You can manually add domains',
        title: "You don't have any domains yet",
      },
      headers: {
        domain: 'Domain Name',
        prospects: 'Prospects',
      },
      eoDomainsCount: (count) => `${pluralize('Domain', count, true)}`,
    },
  },
};
