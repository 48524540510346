export default {
  eoCampaign: {
    breadcrumbsTitle: 'Campaigns',
    bulkActions: {
      addEoCampaign: 'Create New Campaign',
      selectBrand: 'Filter by brand',
    },
    buttons: {
      status: {
        label: (status) => {
          return status === 'live' ? 'Pause' : 'Start';
        },
        hasNoEmailStep: 'Please add at least one email Step.',
        hasNoInbox: 'Please add an inbox to the campaign.',
      },
    },
    messages: {
      cantFetchEoCampaigns: "Can't load campaigns",
      cantFetchEoCampaign: "Can't load campaign",
      cantFetchEoCampaignMetrics: "Can't load campaign metrics",
      cantCreateEoCampaign: "Can't create campaign",
      cantUpdateEoCampaign: "Can't update campaign",
      cantDeleteEoCampaign: "Can't delete campaign",
      createSuccess: 'Campaign was created successfully.',
      deleteSuccess: 'Campaign deleted successfully.',
      updateSuccess: 'Campaign was updated successfully.',
    },
    eoCampaignFormPopup: {
      buttons: {
        cancel: 'Cancel',
        submit: 'Save',
      },
      inputs: {
        title: {
          label: 'Title',
        },
      },
      title: 'Create New Campaign',
    },
    table: {
      blankState: {
        body: 'You can manually add Campaigns',
        title: "You don't have any Campaigns yet",
      },
      headers: {
        title: 'Campaign',
        status: 'Status',
        brandName: 'Associated brand',
        uniqSentCount: 'Sent',
        uniqOpensCount: 'Opens',
        uniqClicksCount: 'Clicks',
        uniqRepliesCount: 'Replies',
        uniqBouncesCount: 'Bounces',
        uniqPendingCount: 'Pending',
        lastEmailSent: 'Last email sent',
        eoCampaignActions: 'Actions',
      },
      campaignInfo: {
        ownerEmail: 'Owner:',
        createdAt: 'Created:',
      },
      noEmailsSent: 'No emails sent',
    },
    tabs: {
      overview: 'Overview',
      prospects: 'Prospects',
      eo_sequences: 'Sequences',
      eo_progress: 'Progress',
      eo_scheduler: 'Scheduler',
      eo_messages: 'Replies',
      eo_inboxes: 'Inboxes',
      options: 'Options',
      eo_campaigns: 'Campaigns',
      eo_drip_lists: 'Drip Lists',
      eo_campaign_preview: 'Preview',
      eo_active_hours_sets: 'Active Hours',
      eo_email_templates: 'Email Templates',
    },
  },
};
