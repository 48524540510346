import { closeImportDlsPopup, openSubscriptionLimitReachedPopup } from 'actions/popup_actions';
import { fetchAhrefsLimits } from 'api/brand_page';
import { importFromAhrefs } from 'api/destination_links_import';
import { translate } from 'common/i18n';
import { showErrorMessage } from 'common/utils';
import confirmationDialogue from 'components/confirmation_dialogue';

const importFromAhrefsService = async (context, pageId, categorizeBacklinks) => {
  await handleImportFromAhrefsToBrandPage(context, pageId, categorizeBacklinks);

  closeAhrefsImportRelatedModals(context.dispatch);
};

const handleImportFromAhrefsToBrandPage = async (context, pageId, categorizeBacklinks) => {
  const { dispatch } = context;

  if (isGoingToOpenSubscriptionLimitReachedPopup(context, categorizeBacklinks)) {
    return void 0;
  }

  const ahrefsLimitsResponse = await fetchAhrefsLimits(dispatch, pageId);

  const {
    canProceedWOConfirmation,
    dlsLeft,
    hasBacklinksToImport,
    hasSomeRowsForImport,
    type,
  } = ahrefsLimitsResponse;

  if (type === 'error') {
    return ahrefsLimitsResponse;
  }

  if (dlsLeft <= 0) {
    return showErrorMessage(dispatch, translate('errors.dlsPerBrandPageLimitReached'), true);
  }

  if (!hasBacklinksToImport) {
    return showErrorMessage(dispatch, translate('errors.cantFindAhrefsBacklinksForThePage'), true);
  }

  if (!hasSomeRowsForImport) {
    return showErrorMessage(dispatch, translate('errors.ahrefsIntegrationLimitExceeded'), true);
  }

  if (canProceedWOConfirmation) {
    return doImportFromAhrefs(dispatch, pageId, categorizeBacklinks)();
  }

  return confirmImportFromAhrefs(dispatch, categorizeBacklinks, pageId, ahrefsLimitsResponse);
};

const isGoingToOpenSubscriptionLimitReachedPopup = (context, categorizeBacklinks) => {
  const { dispatch, subscriptionPolicies } = context;

  const canImport = subscriptionPolicies.getIn(['ahrefsIntegration', 'canUse'], false);
  const canCategorize = subscriptionPolicies.getIn(['categorizer', 'canUse'], false);

  if (!canImport || (categorizeBacklinks && !canCategorize)) {
    dispatch(openSubscriptionLimitReachedPopup({}));
  }

  return false;
};

const confirmImportFromAhrefs = (dispatch, categorizeBacklinks, pageId, ahrefsLimits) => {
  confirmationDialogue({
    body: translate('confirmations.importFromAhrefs.body', ahrefsLimits),
    onConfirm: doImportFromAhrefs(dispatch, pageId, categorizeBacklinks),
    onCancel: closeAhrefsImportRelatedModals(dispatch),
  });
};

const doImportFromAhrefs = (dispatch, pageId, categorizeBacklinks) => async () => {
  await importFromAhrefs(dispatch, pageId, categorizeBacklinks);
};

const closeAhrefsImportRelatedModals = (dispatch) => {
  dispatch(closeImportDlsPopup());
};

export default importFromAhrefsService;
