import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DeleteProspectsButtonComponent from './DeleteProspectsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { deleteProspects } from 'api/prospects';
import { selectedIdsList } from 'common/prop_types_shapes';
import { uiSelector } from 'selectors';

const DeleteProspectsButtonContainer = ({ dispatch, selectedIds }) => {
  function handleDelete() {
    deleteProspects(dispatch, selectedIds.toJS());
  }

  return <DeleteProspectsButtonComponent onDelete={handleDelete} selectedIds={selectedIds} />;
};

DeleteProspectsButtonContainer.propTypes = {
  dispatch: PropTypes.func,
  selectedIds: selectedIdsList,
};

function select(state) {
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    selectedIds,
  };
}

export default ConnectStoreHOC(connect(select)(DeleteProspectsButtonContainer));
