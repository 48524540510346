import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

import cx from 'classnames';

import EoEmailAttributesButton from './EoEmailAttributesButton';
import QuillTextEditor from 'components_linkio/QuillTextEditor';
import InputComponent from 'components_linkio/input_component';
import MessageComponent from 'components_linkio/message_component';

import { translate } from 'common/i18n';

import './eoEmailForm.scss';

const EoEmailFormComponent = ({
  className,
  emailBody,
  emailSubject,
  errors = {},
  isDisabled,
  setEmailBodyValue,
  setEmailSubjectValue,
}) => {
  const reactQuillRef = React.useRef(null);
  const quillEditor = reactQuillRef.current;

  isDisabled && quillEditor && quillEditor.getEditor().disable();

  const eoEmailFormClassNames = cx('eo-email-form', {
    [className]: className,
  });

  function handleInsertAttributeToEmailSubject({ textToInsert, isHtml }) {
    const inputNode = document.querySelector('.eo-email-form__subject-input input');

    const cursorPositionIndex = inputNode.selectionStart;

    const processedTextToInsert = isHtml
      ? ReactDOMServer.renderToStaticMarkup(textToInsert)
      : textToInsert;

    const updatedText =
      emailSubject.slice(0, cursorPositionIndex) +
      processedTextToInsert +
      emailSubject.slice(cursorPositionIndex);
    setEmailSubjectValue({ target: { value: updatedText } });

    inputNode.focus();
  }

  function handleInsertAttributeToEmailBody({ textToInsert, isHtml }) {
    const editorIsAvailable = typeof reactQuillRef.current.getEditor === 'function';

    if (!editorIsAvailable) {
      return;
    }

    const editor = reactQuillRef.current.getEditor();
    const cursorPositionIndex = editor.selection.savedRange.index;

    if (isHtml) {
      const html = ReactDOMServer.renderToStaticMarkup(textToInsert);
      editor.clipboard.dangerouslyPasteHTML(cursorPositionIndex, html);
      reactQuillRef.current.focus();
      return;
    }

    editor.insertText(cursorPositionIndex, textToInsert);
    editor.setSelection(cursorPositionIndex + textToInsert.length);
    reactQuillRef.current.focus();
  }

  const isAnyFallbackNotFilledInSubject = emailSubject && emailSubject.includes(':FALLBACK}}');
  const isAnyFallbackNotFilledInBody = emailBody && emailBody.includes(':FALLBACK}}');

  return (
    <div className={eoEmailFormClassNames}>
      <div className="eo-email-form__subject-wrapper">
        <InputComponent
          className="eo-email-form__subject-input"
          isDisabled={isDisabled}
          label={translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailSubject.label')}
          onInputChange={setEmailSubjectValue}
          type="text"
          value={emailSubject ?? ''}
          message={{
            type: 'error',
            text: errors.eoCampaignStepActionEmailSubject || errors.subject,
          }}
        />

        {isAnyFallbackNotFilledInSubject && (
          <MessageComponent
            message={{
              type: 'infoBlock',
              text: translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailSubject.hint'),
            }}
          />
        )}

        {!isDisabled && (
          <div className="eo-email-form__subject-attr-btn">
            <EoEmailAttributesButton onInsertAttribute={handleInsertAttributeToEmailSubject} />
          </div>
        )}
      </div>

      <div className="eo-email-form__body-wrapper">
        <QuillTextEditor
          isMandatory={!isDisabled}
          reactQuillRef={reactQuillRef}
          label={translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailBody.label')}
          value={emailBody}
          onChange={setEmailBodyValue}
          placeholder={translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailBody.placeholder')}
          message={{ type: 'error', text: errors.eoCampaignStepActionEmailBody || errors.body }}
        />

        {!isDisabled && (
          <div className="eo-email-form__body-attr-btn">
            <EoEmailAttributesButton onInsertAttribute={handleInsertAttributeToEmailBody} />
          </div>
        )}
      </div>

      {isAnyFallbackNotFilledInBody && (
        <MessageComponent
          message={{
            type: 'infoBlock',
            text: translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailBody.hint'),
          }}
        />
      )}
    </div>
  );
};

EoEmailFormComponent.propTypes = {
  className: PropTypes.string,
  emailBody: PropTypes.string,
  emailSubject: PropTypes.string,
  isDisabled: PropTypes.bool,
  setEmailBodyValue: PropTypes.func,
  setEmailSubjectValue: PropTypes.func,
};

export default EoEmailFormComponent;
