import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import BrandCrawlingStatusComponent from './BrandCrawlingStatusComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandShape, marketerShape } from 'common/prop_types_shapes';

import { currentBrandSelector } from 'selectors';

import { currentMarketerSelector, canUseCrawlersSelector } from 'selectors/railsContextSelectors';

const BrandCrawlingStatusContainer = ({ brand, cmarketer, canUseCrawlers }) => {
  const scheduledCrawlingInterval = cmarketer.get('scheduled_crawling_interval');
  const crawlingEnabled = cmarketer.get('crawling_enabled');

  return (
    <BrandCrawlingStatusComponent
      brand={brand}
      canUseCrawlers={canUseCrawlers}
      crawlingEnabled={crawlingEnabled}
      scheduledCrawlingInterval={scheduledCrawlingInterval}
    />
  );
};

BrandCrawlingStatusContainer.propTypes = {
  brand: brandShape,
  canUseCrawlers: PropTypes.bool,
  cmarketer: marketerShape,
};

function select(state, ownProps) {
  const brand = currentBrandSelector(state, ownProps);
  const cmarketer = currentMarketerSelector(state, ownProps);
  const canUseCrawlers = canUseCrawlersSelector(state, ownProps);

  return {
    brand,
    canUseCrawlers,
    cmarketer,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(BrandCrawlingStatusContainer)));
