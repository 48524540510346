import { fromJS, List as iList } from 'immutable';

import {
  CATEGORIZE_DESTINATION_LINKS__SUCCESS,
  CREATE_DESTINATION_LINKS__SUCCESS,
  DELETE_DESTINATION_LINKS__SUCCESS,
  FETCH_BRAND_PAGE_DLS__SUCCESS,
  UPDATE_DESTINATION_LINKS__SUCCESS,
} from '../actions/destination_link_actions';

import {
  BACKLINK_CRAWLING__FAILURE,
  BACKLINK_CRAWLING__START,
  BACKLINK_CRAWLING__SUCCESS,
  GOOGLE_INDEXED_CRAWLING__FAILURE,
  GOOGLE_INDEXED_CRAWLING__START,
  GOOGLE_INDEXED_CRAWLING__SUCCESS,
} from '../actions/crawler_actions';

import {
  camelizeObjectDeep,
  itemIndex,
  mergeArrayToList,
  updateBrandPageDls,
} from './utils';

const initialState = iList();

const cleanupSelected = (state) => (dl) => {
  const index = itemIndex(dl, state);
  dl.selected = state.getIn([index, 'selected'], false);
  return camelizeObjectDeep(dl);
};


export default function destinationLinkReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_DESTINATION_LINKS__SUCCESS: {
      state = mergeArrayToList(state, action.dls, camelizeObjectDeep);

      break;
    }
    case BACKLINK_CRAWLING__START:
    case GOOGLE_INDEXED_CRAWLING__START:
    case CATEGORIZE_DESTINATION_LINKS__SUCCESS:
    case UPDATE_DESTINATION_LINKS__SUCCESS: {
      state = updateBrandPageDls(state, action.dls, camelizeObjectDeep);

      break;
    }
    case BACKLINK_CRAWLING__FAILURE:
    case BACKLINK_CRAWLING__SUCCESS:
    case GOOGLE_INDEXED_CRAWLING__FAILURE:
    case GOOGLE_INDEXED_CRAWLING__SUCCESS: {
      state = updateBrandPageDls(state, action.dls, cleanupSelected(state));

      break;
    }
    case FETCH_BRAND_PAGE_DLS__SUCCESS: {
      state = fromJS(action.dls.map(camelizeObjectDeep));

      break;
    }
    case DELETE_DESTINATION_LINKS__SUCCESS: {
      action.ids.forEach((id) => {
        const index = state.findIndex((listItem) => {
          return listItem.get('id') === id;
        });

        if (index > -1) {
          state = state.delete(index);
        }
      });

      break;
    }
  }

  return state;
}
