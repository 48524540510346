import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'lodash';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import EoToDosTasksComponent from './EoToDosTasksComponent';

import { eoToDosList } from 'common/propTypesShapes/eoToDos';

const EoToDosTasksContainer = ({
  changeQueryParams,
  immutableEoToDos,
  onClickEoToDo,
  selectedEoToDoId,
  queryParams,
}) => {
  const isThereSortingParamsExist = !!queryParams.sorting;
  const isShowNewTasksFirst = isThereSortingParamsExist
    ? queryParams.sorting.sortingOrder === 'desc'
    : true;

  function handleSwitchSortingOrder() {
    const sortingOrder = isShowNewTasksFirst ? 'asc' : 'desc';

    const newQueryParams = {
      ...queryParams,
      sorting: {
        sortBy: 'createdAt',
        sortingOrder,
      },
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <EoToDosTasksComponent
      handleSwitchSortingOrder={handleSwitchSortingOrder}
      immutableEoToDos={immutableEoToDos}
      isShowNewTasksFirst={isShowNewTasksFirst}
      onClickEoToDo={onClickEoToDo}
      selectedEoToDoId={selectedEoToDoId}
    />
  );
};

EoToDosTasksContainer.propTypes = {
  immutableEoToDos: eoToDosList,
  onClickEoToDo: PropTypes.func,
  selectedEoToDoId: PropTypes.number,
  ...withQueryParamsPropTypes,
};

export default compose(withQueryParams)(EoToDosTasksContainer);
