export default {
  brandDashboard: {
    editBrandHint: 'Edit brand',
    title: 'Brand Overview',
    brandOverview: {
      title: 'Brand Details Overview',
      atp: {
        title: 'Anchor Text Planner',
        metrics: {
          pagesCount: 'Pages Added',
          anchorTextScore: 'Homepage Anchor Text Score',
        },
        buttons: {
          add: 'Add Pages',
          setup: 'Setup Anchor Text Planner',
        },
      },
      rankTracker: {
        title: 'Rank Tracker',
        metrics: {
          keywordsCount: 'Keywords Monitored',
          averageRanking: 'Av.Ranking',
          upOrDown: 'Up / Down',
        },
        buttons: {
          add: 'Add Keywords',
          setup: 'Setup Keyword Rank Tracker',
        },
      },
      backlinks: {
        title: 'Backlinks',
        metrics: {
          backlinksCount: 'Backlinks',
          refDomains: 'Referring Domains',
        },
        buttons: {
          add: 'Import Backlinks',
          setup: 'Setup Backlinks Monitoring',
        },
      },
      eoDripLists: {
        title: 'Drip Lists',
        metrics: {
          eoDripListsCount: 'Drip Lists Assigned',
        },
        buttons: {
          setup: 'Setup Drip Lists',
        },
      },
      eoCampaigns: {
        title: 'Email Outreach Campaigns',
        metrics: {
          eoCampaignsCount: 'Campaigns Assigned',
        },
        buttons: {
          setup: 'Setup Campaigns',
        },
      },
      videoTutorial: 'Video Tutorial',
      viewMore: 'View More',
      
    },
    brandCharts: {
      title: 'Brand Charts',
    },
    generateMetrics: {
      buttons: {
        generate: "Check Brand Metrics"
      }
    }
    
  },
};
