import { translate } from '../common/i18n';

import {
  fetchPermissionsFailure,
  fetchPermissionsStart,
  fetchPermissionsSuccess,
  updateUserPermissionsFailure,
  updateUserPermissionsStart,
  updateUserPermissionsSuccess,
} from '../actions/permissionActions';

import {
  getFromAPI,
  handleAPIError,
  patchToAPI,
} from './utils';


export async function fetchPermissions(dispatch) {
  dispatch(fetchPermissionsStart());
  try {
    const resp = await getFromAPI(Routes.employees_permissions_path());

    dispatch(fetchPermissionsSuccess(resp.permissions));
  } catch (err) {
    handleAPIError(dispatch, err, fetchPermissionsFailure, translate('errors.cantFetchPermissions'));
  }
}

export async function updateUserPermissions(dispatch, userId, permissions) {
  dispatch(updateUserPermissionsStart());
  try {
    const resp = await patchToAPI(Routes.update_employee_permission_path(userId), { permissions });

    dispatch(updateUserPermissionsSuccess(resp));
  } catch (err) {
    handleAPIError(dispatch, err, updateUserPermissionsFailure, translate('errors.cantUpdateUserPermissions'));
  }
}
