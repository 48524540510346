import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import CrawlerStatusBarComponent from './CrawlerStatusBarComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { crawlerStatusDataMap } from 'common/prop_types_shapes';
import { crawlerStatusDataSelector } from 'selectors/railsContextSelectors';
import { fetchCrawlerStatusData } from 'api/crawler';


const CrawlerStatusBarContainer = ({ dispatch, crawlerStatusData }) => {
  React.useEffect(() => {
    fetchCrawlerStatusData(dispatch);
  }, []);

  return <CrawlerStatusBarComponent crawlerStatusData={crawlerStatusData.toJS()} />;
};

CrawlerStatusBarContainer.propTypes = {
  crawlerStatusData: crawlerStatusDataMap,
  dispatch: PropTypes.func.isRequired,
};

const selector = (state, ownProps) => ({
  crawlerStatusData: crawlerStatusDataSelector(state, ownProps),
});

export default compose(
  ConnectStoreHOC,
  connect(selector),
)(CrawlerStatusBarContainer);
