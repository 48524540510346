import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

const ManuallyImportPagesButtonComponent = ({ onClick }) => {
  return (
    <ButtonComponent className="manually-import-pages-button" isInitial isBlue onClick={onClick}>
      {translate('bulkPagesSetup.bulkActions.buttons.manuallyImportPages')}
    </ButtonComponent>
  );
};

ManuallyImportPagesButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ManuallyImportPagesButtonComponent;
