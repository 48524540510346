import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import AutodiscoverCompetitorsButtonComponent from './AutodiscoverCompetitorsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandPageMap, subscriptionShape } from 'common/prop_types_shapes';
import { currentSubscriptionSelector } from 'selectors/railsContextSelectors';
import { visibleBrandPageSelector } from 'selectors';

const AutodiscoverCompetitorsButtonContainer = ({ brandPage, currentSubscription }) => {
  const isFreeSubscription = !currentSubscription.get('subscriptionPlanIsPaid');
  const isTrial = currentSubscription.get('status') === 'trialing';

  const autodiscoverIsAvailable = !isFreeSubscription || isTrial;
  const isLoading = brandPage.get('autodiscoverCompetitorsInProgress');

  return (
    <AutodiscoverCompetitorsButtonComponent
      autodiscoverIsAvailable={autodiscoverIsAvailable}
      isLoading={isLoading}
    />
  );
};

function select(state, ownProps) {
  const brandPage = visibleBrandPageSelector(state, ownProps);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);

  return {
    brandPage,
    currentSubscription,
  };
}

AutodiscoverCompetitorsButtonContainer.propTypes = {
  brandPage: brandPageMap,
  currentSubscription: subscriptionShape,
};

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(AutodiscoverCompetitorsButtonContainer);
