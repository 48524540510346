import React from 'react';
import PropTypes from 'prop-types';

import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import PlayIcon from 'common/icons/play';

import './pageLayoutHeader.scss';

const PageLayoutHeaderComponent = ({ title, Popup }) => (
  <div className="page-layout-header">
    <h1 className="page-layout-header__title">{title}</h1>
    {Popup && (
      <ToggleShowPopup className="page-layout-header__button" Popup={Popup}>
        <PlayIcon />
        <div className="page-layout-header__button-text">Video Tutorial</div>
      </ToggleShowPopup>
    )}
  </div>
);

PageLayoutHeaderComponent.propTypes = {
  Popup: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.string.isRequired,
};

export default PageLayoutHeaderComponent;
