import React from 'react';
import PropTypes from 'prop-types';

import EoActiveHoursTable from './EoActiveHoursTable';
import EoActiveHoursBulkActions from './EoActiveHoursBulkActions';
import EoCampaignsTabs from 'pages/EoCampaignsPage/EoCampaignsTabs';
import Pager from 'components/Pager';

import { pagyShape } from 'common/prop_types_shapes';

import './eoActiveHoursPage.scss';

const EoActiveHoursComponent = ({ onItemsSelectChange, onPagesPagerClick, eoActiveHoursPagy }) => {
  return (
    <div className="eo-active-hours">
      <EoCampaignsTabs />

      <div className="eo-active-hours__main-content">
        <EoActiveHoursBulkActions />

        <EoActiveHoursTable />

        <Pager
          handleItemsSelectChange={onItemsSelectChange}
          handlePagesControlClick={onPagesPagerClick}
          pagerType="rowsPager"
          pagy={eoActiveHoursPagy}
        />
      </div>
    </div>
  );
};

EoActiveHoursComponent.propTypes = {
  eoActiveHoursPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoActiveHoursComponent;
