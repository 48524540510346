import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoActiveHoursStart,
  fetchEoActiveHoursSuccess,
  fetchEoActiveHoursFailure,
  fetchEoActiveHourStart,
  fetchEoActiveHourSuccess,
  fetchEoActiveHourFailure,
  fetchEoActiveHoursAsOptionsSuccess,
  createEoActiveHourStart,
  createEoActiveHourSuccess,
  createEoActiveHourFailure,
  updateEoActiveHourStart,
  updateEoActiveHourSuccess,
  updateEoActiveHourFailure,
  deleteEoActiveHoursStart,
  deleteEoActiveHoursSuccess,
  deleteEoActiveHoursFailure,
} from 'actions/eoActiveHourActions';

import { resetSelectedIds } from 'actions/ui_actions';
import { fetchEoActiveHoursPagySuccess } from 'actions/pagy_actions';

import { translate } from 'common/i18n';

import { showSuccessMessage, showSuccessMessageFromTranslation } from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoActiveHours(dispatch, params) {
  dispatch(fetchEoActiveHoursStart());

  try {
    const resp = await getFromAPI(
      Routes.eo_active_hours_sets_path(),
      processObjToSnakeCaseDeep(params),
    );
    dispatch(fetchEoActiveHoursSuccess(resp.eo_active_hours_sets));
    dispatch(fetchEoActiveHoursPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoActiveHoursFailure,
      translate('eoActiveHour.messages.cantFetchEoActiveHours'),
    );
  }
}

export async function fetchEoActiveHour(dispatch, eoActiveHourId) {
  dispatch(fetchEoActiveHourStart());

  try {
    const eoActiveHour = await getFromAPI(Routes.eo_active_hours_set_path({ id: eoActiveHourId }));
    dispatch(fetchEoActiveHourSuccess(eoActiveHour));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoActiveHourFailure,
      translate('eoActiveHour.messages.cantFetchEoActiveHour'),
    );
  }
}

export async function createEoActiveHour(dispatch, data) {
  dispatch(createEoActiveHourStart());

  try {
    const resp = await postToAPI(Routes.eo_active_hours_sets_path(), eoActiveHourObject(data));

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(createEoActiveHourSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoActiveHour.messages.createSuccess');
    } else {
      dispatch(createEoActiveHourFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createEoActiveHourFailure,
      translate('eoActiveHour.messages.cantCreateEoActiveHour'),
    );
  }
}

export async function updateEoActiveHour(dispatch, data) {
  dispatch(updateEoActiveHourStart());

  try {
    const resp = await patchToAPI(
      Routes.eo_active_hours_set_path({ id: data.id }),
      eoActiveHourObject(data),
    );

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(updateEoActiveHourSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoActiveHour.messages.updateSuccess');
    } else {
      dispatch(updateEoActiveHourFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoActiveHourFailure,
      translate('eoActiveHour.messages.cantUpdateEoActiveHour'),
    );
  }
}

export async function deleteEoActiveHours(dispatch, ids) {
  dispatch(deleteEoActiveHoursStart());

  try {
    await deleteToAPI(Routes.eo_active_hours_sets_path({ ids }));

    dispatch(resetSelectedIds());
    dispatch(deleteEoActiveHoursSuccess(ids));

    showSuccessMessage(dispatch, translate('eoActiveHour.messages.deleteSuccess')(ids.length));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoActiveHoursFailure,
      translate('eoActiveHour.messages.cantDeleteEoActiveHours'),
    );
  }
}

export async function fetchEoActiveHoursAsOptions(dispatch) {
  try {
    const resp = await getFromAPI(Routes.eo_active_hours_sets_as_options_path());
    dispatch(fetchEoActiveHoursAsOptionsSuccess(resp.eo_active_hours_sets));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoActiveHoursFailure,
      translate('eoActiveHour.messages.cantFetchEoActiveHours'),
    );
  }
}

const eoActiveHourObject = (data) => ({ eo_active_hours_set: processObjToSnakeCaseDeep(data) });
