import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, set } from 'lodash';

import { eoCampaignStepShape } from 'common/propTypesShapes/eoCampaignSteps';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import RadioButton from 'components_linkio/RadioButton';
import Checkbox from 'components_linkio/Checkbox';

import './eoCampaignStepWait.scss';

const initialState = {
  measure: 'days',
  value: 3,
  useTimeTable: true,
  errors: {},
};

const radioButtons = ['minutes', 'hours', 'days'];

function reducer(state, action) {
  const { value, type } = action;

  const newState = cloneDeep(state);

  set(newState, type, value);

  return newState;
}

const EoCampaignStepWaitComponent = ({
  eoCampaignStep,
  eoCampaignStepPosition,
  onCancel,
  onSave,
}) => {
  const { eoCampaignStepAction } = eoCampaignStep;

  const [state, dispatch] = React.useReducer(reducer, eoCampaignStepAction || initialState);

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  const setCheckboxValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.checked });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const resp = await onSave(state);

    const { errors } = resp;

    dispatch({ type: 'errors', value: errors });
  }

  const { measure, value, useTimeTable, errors = {} } = state;

  return (
    <>
      <div className="eo-campaign-step__headline">
        <h3 className="eo-campaign-step__title">
          {`${eoCampaignStepPosition}. ${translate(
            'eoCampaignStep.actionTypes.EoCampaignStepWait',
          )}`}
        </h3>

        <div className="eo-campaign-step-wait__btn-group">
          <div className="eo-campaign-step-wait__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('uiComponents.button.cancel')}
            </ButtonComponent>
          </div>

          <div className="eo-campaign-step-wait__btn">
            <SubmitButtonComponent
              form="eo-campaign-step-wait"
              isGreen
              onClick={handleSubmit}
              text={translate('eoCampaignStep.buttons.submit')(!!eoCampaignStepAction)}
            />
          </div>
        </div>
      </div>

      <form id="eo-campaign-step-wait" className="eo-campaign-step-wait" noValidate>
        <div className="eo-campaign-step-wait__wait-time">
          <InputComponent
            label={translate('eoCampaignStep.eoCampaignStepWait.inputs.waitTime.label')}
            message={{ type: 'error', text: errors.eoCampaignStepActionValue }}
            name="EoCampaignStepWait"
            onInputChange={setInputValue('value')}
            type="number"
            value={value}
          />

          {radioButtons.map((name) => {
            return (
              <RadioButton
                key={name}
                checked={name === measure}
                onChange={setInputValue('measure')}
                value={name}
              >
                {translate(`eoCampaignStep.eoCampaignStepWait.inputs.measure.${name}.label`)}
              </RadioButton>
            );
          })}
        </div>

        <Checkbox
          checked={useTimeTable}
          onChange={setCheckboxValue('useTimeTable')}
          message={{
            type: 'hint',
            text: translate('eoCampaignStep.eoCampaignStepWait.inputs.useTimeTable.hint'),
          }}
        >
          {translate('eoCampaignStep.eoCampaignStepWait.inputs.useTimeTable.label')}
        </Checkbox>
      </form>
    </>
  );
};

EoCampaignStepWaitComponent.propTypes = {
  eoCampaignStep: eoCampaignStepShape,
  eoCampaignStepPosition: PropTypes.number,
  onCancel: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};

export default EoCampaignStepWaitComponent;
