import { fromJS, List as iList } from 'immutable';
import { camelizeObjectDeep, mergeItemToList } from './utils';

import {
  CREATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS,
  DELETE_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS,
  FETCH_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS,
  FETCH_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS,
  UPDATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS,
} from 'actions/eoEmailTemplateFolderActions';

const initialState = iList();

export default function eoEmailTemplateFoldersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS: {
      const { eoEmailTemplateFolders } = action;

      state = fromJS(eoEmailTemplateFolders.map(camelizeObjectDeep));

      break;
    }
    case FETCH_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS:
    case CREATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS:
    case UPDATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS: {
      const { eoEmailTemplateFolder } = action;
      state = mergeItemToList(state, eoEmailTemplateFolder, camelizeObjectDeep);

      break;
    }
    case DELETE_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS: {
      const { ids } = action;

      state = state.filter(
        (eoEmailTemplateFolder) => !ids.includes(eoEmailTemplateFolder.get('id')),
      );

      break;
    }
  }

  return state;
}
