import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PagesListHeaderComponent from './PagesListHeaderComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';
import { subscriptionPoliciesShape } from 'common/prop_types_shapes';

const PagesListHeaderContainer = ({ pageChildType, subscriptionPolicies }) => {
  const canUseCrawlingInSubscription = subscriptionPolicies.getIn(['crawlers', 'canUse']);

  return (
    <PagesListHeaderComponent
      canUseCrawlingInSubscription={canUseCrawlingInSubscription}
      pageChildType={pageChildType}
    />
  );
};

PagesListHeaderContainer.propTypes = {
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']),
  subscriptionPolicies: subscriptionPoliciesShape,
};

function select(state, ownProps) {
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  return {
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(PagesListHeaderContainer));
