import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { textTwoLinesWithEllipsis } from '../utils';

import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';
import OverlayWithTooltipComponent from 'components/overlay_with_tooltip_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import CrossIcon from 'common/icons/cross';
import DownArrowIcon from 'common/icons/down_arrow';
import EditIcon from 'common/icons/edit';
import RefreshIcon from 'common/icons/refresh';

import './fields_components.scss';

function processLongText(text) {
  const textIncludesLink = text.includes('http');

  return textTwoLinesWithEllipsis(text, { breakAll: textIncludesLink });
}

export const HeaderField = ({ explanationMessage, text }) => {
  return (
    <>
      {text}
      {explanationMessage && <ExplanatoryTooltipComponent text={explanationMessage} />}
    </>
  );
};

HeaderField.propTypes = {
  explanationMessage: PropTypes.string,
  text: PropTypes.node,
};

export const CommonField = ({
  Popup,
  errorText,
  Icon,
  isEditable,
  isLink,
  rowData,
  text,
  textIsLong,
  tooltipText,
  onClick,
}) => {
  const processedText = textIsLong ? processLongText(text) : text;

  const Component = (
    <OverlayWithTooltipComponent tooltip={{ text: tooltipText, placement: 'top', color: 'white' }}>
      <div className="common-field" onClick={onClick}>
        <div className="common-field__container">
          {isLink && (
            <a className="text" href={text} target="_blank" rel="nofollow noopener noreferrer">
              {processedText}
              {errorText && <span className="cell_error small">{errorText}</span>}
            </a>
          )}
          {!isLink && (
            <div className="common-field__text">
              {processedText}
              {errorText && <span className="cell_error small">{errorText}</span>}
            </div>
          )}
          {Icon && (
            <span>
              <Icon className="common-field__icon" />
            </span>
          )}
          {isEditable && !Icon && (
            <span>
              <EditIcon className="common-field__icon" />
            </span>
          )}
        </div>
      </div>
    </OverlayWithTooltipComponent>
  );

  if (Popup) {
    return (
      <ToggleShowPopup className="common-field" Popup={<Popup tableRowData={rowData} />}>
        {Component}
      </ToggleShowPopup>
    );
  }

  return Component;
};

CommonField.propTypes = {
  errorText: PropTypes.string,
  isEditable: PropTypes.bool,
  isLink: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.node]),
  textIsLong: PropTypes.bool,
  tooltipText: PropTypes.string,
};

CommonField.displayName = 'CommonField';

export const SelectorField = ({
  errorText,
  hideArrow,
  hideCrossIcon,
  isEditable,
  isRefreshable,
  isClearable,
  text,
  tooltipText,
}) => {
  const processedText = tooltipText ? processLongText(text) : text;
  const crossIconStyles = cx('selector-field__cross-icon', {
    'selector-field__cross-icon_hide': isClearable && hideCrossIcon,
  });
  return (
    <div title={tooltipText} className="selector-field">
      <div className="selector-field__container">
        <div className="selector-field__text">{processedText}</div>
        {isEditable && (
          <span>
            <EditIcon className="common-field__edit-icon" />
          </span>
        )}
        {isRefreshable && (
          <div>
            <RefreshIcon className="selector-field__refresh-icon" />
          </div>
        )}
        {!hideArrow && (
          <>
            <div>
              <DownArrowIcon className="selector-field__down-arrow-icon" />
            </div>
          </>
        )}
        {isClearable && (
          <span className={crossIconStyles}>
            <CrossIcon />
          </span>
        )}
      </div>
      <span className="selector-field__error cell_error small">{errorText}</span>
    </div>
  );
};

SelectorField.propTypes = {
  errorText: PropTypes.string,
  hideArrow: PropTypes.bool,
  hideCrossIcon: PropTypes.bool,
  isClearable: PropTypes.bool,
  isEditable: PropTypes.bool,
  isRefreshable: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.node]),
  tooltipText: PropTypes.string,
};

SelectorField.displayName = 'CommonField';

export const NameField = ({ text, avatarImgSrc, errorText }) => {
  return (
    <div title={text} className="name-field">
      <div className="name-field__avatar">
        <img src={avatarImgSrc} />
      </div>
      <div className="name-field__name">
        <div>{text}</div>
        <span className="cell_error small">{errorText}</span>
      </div>
    </div>
  );
};

NameField.propTypes = {
  avatarImgSrc: PropTypes.string,
  errorText: PropTypes.string,
  text: PropTypes.string,
};

NameField.displayName = 'NameField';
