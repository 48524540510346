import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';

import EoSchedulersPageComponent from './EoSchedulersPageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { pagyShape } from 'common/prop_types_shapes';
import { eoSchedulersPagySelector } from 'selectors/eoSchedulersSelector';
import { policiesSelector } from 'selectors/railsContextSelectors';

const EoSchedulersPageContainer = ({
  canCreate,
  changeQueryParams,
  eoSchedulersPagy,
  queryParams,
}) => {
  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <EoSchedulersPageComponent
        canCreate={canCreate}
        onItemsSelectChange={handleItemsSelectChange}
        onPagesPagerClick={handlePagesPagerClick}
        eoSchedulersPagy={eoSchedulersPagy}
      />
    </>
  );
};

EoSchedulersPageContainer.propTypes = {
  canCreate: PropTypes.bool,
  eoSchedulersPagy: pagyShape,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoSchedulersPagy = eoSchedulersPagySelector(state);
  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);

  return {
    canCreate,
    eoSchedulersPagy,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(EoSchedulersPageContainer);
