export default (args) => {
  return {
    metricsAnalysis: {
      table: {
        title: 'Competitors Metrics Comparision',
        rows: {
          pageMetrics: {
            title: 'Page Related Metrics',
          },
          liveReferringDomains: {
            title: 'Live Referring Domains',
          },
          dfAndIndexedReferringDomains: {
            title: 'DF and Indexed Referring Domains',
          },
          nfReferringDomains: {
            title: 'NF Referring Domains',
          },
          nfAndIndexedReferringDomains: {
            title: 'NF and Indexed Referring Domains',
          },
          notIndexedReferringDomains: {
            title: 'Not Indexed Referring Domains',
          },
          uriAhrefsRating: {
            title: 'URL Rank',
          },
          linkioScore: {
            title: 'Anchor Text Score',
          },
          domainMetrics: {
            title: 'Domain Related Metrics',
          },
          domainRefdomainsCount: {
            title: 'Overall Referring Domains',
          },
          homepageRefdomainsCount: {
            title: 'Homepage Referring Domains',
          },
          domainAhrefsRating: {
            title: 'Domain Rank',
          },
        },
        columns: {
          competitors: {
            title: 'Competitors',
          },
          average: {
            title: 'Average',
          },
          myBrand: {
            title: 'My Brand',
          },
        },
      },
    },
  };
};
