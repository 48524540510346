export default {
  dlsRedistributingStatusNotifierComponent: {
    newSuggestionsMessage: "You've got new suggestions. ",
    suggestionsAreGenerated: 'Suggestions are being generated. ',
    suggestionsUpdateInvitation: {
      beginning: 'Please ',
      ending: ' to load the update.',
      linkText: 'click here',
    },
  },
};
