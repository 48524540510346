import accountDashboardReducer from './accountDashboardReducer';
import ahrefsConnectionReducer from './ahrefs_connection_reducer';
import anchorSamplesReducer from './anchorSamplesReducer';
import backlinkReducer from './backlinkReducer';
import billingReducer from './billing_reducer';
import brandsChartsReducer from './brandsChartsReducer';
import brandDashboardReducer from './brandDashboardReducer';
import brandPageReducer from './brand_page_reducer';
import brandReducer from './brand_reducer';
import brandsContextReducer from './brandsContextReducer';
import bulkCompetitorPagesReducer from './bulkCompetitorPagesReducer';
import bulkPagesSetupReducer from './bulkPagesSetupReducer';
import changesHistoryReducer from './changesHistoryReducer';
import crawlersReducer from './crawlers_reducer';
import destinationLinkReducer from './destination_link_reducer';
import dlDuplicateReducer from './dl_duplicate_reducer';
import dlSourceReducer from './dl_source_reducer';
import eoBlacklistedDomainsReducer from './eoBlacklistedDomainsReducer';
import employeeFormRowReducer from './employeeFormRowReducer';
import employeeReducer from './employeeReducer';
import eoDashboardReducer from './eoDashboardReducer';
import eoDripListsReducer from './eoDripListsReducer';
import eoCampaignMetricsReducer from './eoCampaignMetricsReducer';
import eoCampaignsReducer from './eoCampaignsReducer';
import eoCampaignOptionsReducer from './eoCampaignOptionsReducer';
import eoCampaignStepsPreviewReducer from './eoCampaignStepsPreviewReducer';
import eoCampaignStepsReducer from './eoCampaignStepsReducer';
import eoDomainsReducer from './eoDomainsReducer';
import eoInboxesSettingsReducer from './eoInboxesSettingsReducer';
import eoProgressionsReducer from './eoProgressionsReducer';
import eoSchedulersReducer from './eoSchedulersReducer';
import impersonatesReducer from './impersonatesReducer';
import marketersReducer from './marketers_reducer';
import messagesReducer from './messages_reducer';
import newsletterSettingsReducer from './newsletterSettingsReducer';
import notificationReducer from './notificationReducer';
import onboardingStatusReducer from './onboardingStatusReducer';
import optionsReducer from './options_reducer';
import pageDashboardDlsReducer from './page_dashboard_reducer';
import pagyReducer from './pagy_reducer';
import percentageReducer from './percentage_reducer';
import percentageSchemeReducer from './percentage_scheme_reducer';
import permissionReducer from './permissionReducer';
import prospectsReducer from './prospectsReducer';
import proxyReducer from './proxy_reducer';
import railsContextReducer from './rails_context_reducer';
import rankTrackerKeywordsReducer from './rankTrackerKeywords';
import rankTrackerReducer from './rankTrackerReducer';
import rankTrackerSettingsReducer from './rankTrackerSettingsReducer';
import simpleBrandReducer from './simpleBrandReducer';
import subscriptionPlansReducer from './subscription_plans_reducer';
import uiReducer from './ui_reducer';
import eoMessagesReducer from './eoMessagesReducer';
import eoCustomFieldsReducer from './eoCustomFieldsReducer';
import eoActiveHoursReducer from './eoActiveHoursReducer';
import eoEmailTemplatesReducer from './eoEmailTemplatesReducer';
import eoEmailTemplateFoldersReducer from './eoEmailTemplateFoldersReducer';
import eoToDosReducer from './eoToDosReducer';

export default {
  eoToDos: eoToDosReducer,
  eoActiveHours: eoActiveHoursReducer,
  eoEmailTemplatesData: eoEmailTemplatesReducer,
  eoEmailTemplateFolders: eoEmailTemplateFoldersReducer,
  eoCustomFields: eoCustomFieldsReducer,
  eoMessages: eoMessagesReducer,
  accountDashboard: accountDashboardReducer,
  ahrefsConnection: ahrefsConnectionReducer,
  anchorSamples: anchorSamplesReducer,
  backlinks: backlinkReducer,
  billing: billingReducer,
  brandsCharts: brandsChartsReducer,
  brandDashboard: brandDashboardReducer,
  brandPages: brandPageReducer,
  brands: brandReducer,
  brandsContext: brandsContextReducer,
  bulkCompetitorPages: bulkCompetitorPagesReducer,
  bulkSetupBrandPages: bulkPagesSetupReducer,
  changesHistory: changesHistoryReducer,
  crawlers: crawlersReducer,
  destinationLinks: destinationLinkReducer,
  dlDuplicates: dlDuplicateReducer,
  dlSources: dlSourceReducer,
  eoBlacklistedDomains: eoBlacklistedDomainsReducer,
  employeeFormRow: employeeFormRowReducer,
  employees: employeeReducer,
  eoDashboard: eoDashboardReducer,
  eoDripLists: eoDripListsReducer,
  eoDomains: eoDomainsReducer,
  eoCampaignMetrics: eoCampaignMetricsReducer,
  eoCampaigns: eoCampaignsReducer,
  eoCampaignOptions: eoCampaignOptionsReducer,
  eoCampaignSteps: eoCampaignStepsReducer,
  eoCampaignStepsPreview: eoCampaignStepsPreviewReducer,
  eoInboxesSettings: eoInboxesSettingsReducer,
  eoProgressions: eoProgressionsReducer,
  eoSchedulers: eoSchedulersReducer,
  impersonates: impersonatesReducer,
  marketers: marketersReducer,
  messages: messagesReducer,
  newsletterSettings: newsletterSettingsReducer,
  notifications: notificationReducer,
  onboardingStatus: onboardingStatusReducer,
  options: optionsReducer,
  pageDashboardDls: pageDashboardDlsReducer,
  pagies: pagyReducer,
  percentageSchemes: percentageSchemeReducer,
  percentages: percentageReducer,
  permissions: permissionReducer,
  prospects: prospectsReducer,
  proxies: proxyReducer,
  railsContext: railsContextReducer,
  rankTracker: rankTrackerReducer,
  rankTrackerKeywords: rankTrackerKeywordsReducer,
  rankTrackerSettings: rankTrackerSettingsReducer,
  simpleBrands: simpleBrandReducer,
  subscriptionPlans: subscriptionPlansReducer,
  ui: uiReducer,
};
