import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';
import * as edit from 'react-edit';

import buildProspectAttrOptions from './utils/buildProspectAttrOptions';
import { DEFAULT_PROSPECT_ATTRIBUTES } from './constants/defaultProspectAttributes';
import { initState, prospectsRemappingListReducer } from './utils/prospectsRemappingListReducer';
import { prospectAttrCellValueFormatter } from './utils/prospectAttrCellValueFormatter';

import { reactSelectColumn, textColumn } from 'common/tables/columns';
import columnsGenerator, { columnSelect } from 'common/tables/columns_generator';

import { eoCustomFieldsList } from 'common/propTypesShapes/eoCustomFields';
import { optionsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import AddEoDripListButton from 'pages/EoDripListsPage/EoDripListsBulkActions/AddEoDripListButton';
import ButtonComponent from 'components_linkio/button_component';
import EoCustomFieldFormPopup from 'pages/EoCustomFieldsPage/EoCustomFieldsBulkActions/EoCustomFieldFormPopup';
import MessageComponent from 'components_linkio/message_component';
import RadioButton from 'components_linkio/RadioButton';
import Select from 'components_linkio/Select';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import './prospectsRemappingList.scss';

const prospectAttrsSelectProps = {
  autoFocus: true,
  blurInputOnSelect: false,
  openMenuOnFocus: true,
  triggerChangeOnBlur: true,
  isClearable: true,
  skinOptions: {
    inTable: true,
  },
};

const DUPLICATE_MODES = ['reject', 'upload', 'update_existing']; // snake_case because we send it to server

const ProspectsRemappingListComponent = ({
  defaultEoDripListId,
  immutableEoCustomFields,
  immutableEoDripListsOptions,
  onCancel,
  onImportProspects,
  spreadsheetAttrs,
}) => {
  const eoDripListsOptions = React.useMemo(
    () => immutableEoDripListsOptions.toList().toJS(),
    [JSON.stringify(immutableEoDripListsOptions)],
  );

  const prospectAttrOptions = React.useMemo(
    () => buildProspectAttrOptions(immutableEoCustomFields.toList().toJS()),
    [JSON.stringify(immutableEoCustomFields)],
  );

  const tableRef = React.useRef(null);

  React.useEffect(() => {
    tableRef.current = document.querySelector('.prospects-remapping-table');
  }, []);

  const [state, dispatch] = React.useReducer(
    prospectsRemappingListReducer,
    { prospectAttrOptions, spreadsheetAttrs, defaultEoDripListId },
    initState,
  );

  const { eoDripListId, isLoading, rows, duplicatesMode, errors } = state;

  async function handleImportProspects() {
    const isEmailSelected = rows.some(({ prospectAttr }) => prospectAttr === 'email');

    // Email attribute must be selected
    if (!isEmailSelected) {
      dispatch({
        type: 'addError',
        payload: {
          property: 'email',
          text: translate('prospects.importProspectsPopup.remappingCsv.table.errors.email'),
        },
      });
      return;
    }

    const fieldsToRemap = {};
    const eoCustomFieldsToRemap = {};

    rows.forEach((row) => {
      const { spreadsheetField, prospectAttr } = row;

      if (!prospectAttr) {
        return;
      }

      const isDefaultProspectAttr = DEFAULT_PROSPECT_ATTRIBUTES.includes(prospectAttr);

      // Need to separate default prospect attributes and custom fields (backend requirement)
      if (isDefaultProspectAttr) {
        fieldsToRemap[prospectAttr] = spreadsheetField;
      } else {
        const eoCustomFieldId = immutableEoCustomFields
          .find((eoCustomField) => eoCustomField.get('name') === prospectAttr)
          .get('id');

        eoCustomFieldsToRemap[eoCustomFieldId] = spreadsheetField;
      }
    });

    dispatch({ type: 'isLoading', payload: true });

    await onImportProspects({ fieldsToRemap, eoCustomFieldsToRemap, eoDripListId, duplicatesMode });

    dispatch({ type: 'isLoading', payload: false });
    onCancel();
  }

  const setSelectValue = (property) => (obj) => {
    dispatch({ type: property, payload: obj ? obj.value : null });
  };

  function handleChangeDuplicatesMode(event) {
    dispatch({ type: 'setDuplicatesMode', payload: event.target.value });
  }

  const handleCreateEoCustomFieldCb = (rowData) => (payload) => {
    const { prospectAttr } = rowData;
    const { name } = payload;

    // Apply newly created Custom Field automatically if no attribute was selected before
    !prospectAttr && dispatch({ type: 'applyCreatedEoCustomField', payload: { rowData, name } });
  };

  function handleCreateEoDripListCb(payload) {
    // Apply newly created Drip List automatically if no Drip List was selected before
    !eoDripListId && dispatch({ type: 'eoDripListId', payload: payload.id });
  }

  function customFieldValueFormatter(_value, { rowData }) {
    const predefinedEoCustomFieldName = rowData.spreadsheetField
      .replace(/[^A-Za-z0-9]+/g, '_')
      .toLowerCase();

    return (
      <ToggleShowPopup
        Popup={
          <EoCustomFieldFormPopup
            predefinedEoCustomFieldName={predefinedEoCustomFieldName}
            onSubmitCb={handleCreateEoCustomFieldCb(rowData)}
          />
        }
      >
        <span className="prospects-remapping-table__create-custom-field-btn">
          <div>
            {translate('prospects.importProspectsPopup.remappingCsv.table.createEoCustomField')}
          </div>
          <span> +</span>
        </span>
      </ToggleShowPopup>
    );
  }

  const editable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowData, event }) => {
      // Remove prospect attribute if cross icon is clicked
      if (event.target.closest('.selector-field__cross-icon')) {
        dispatch({ type: 'dropProspectAttr', payload: { rowData } });
        return;
      }

      dispatch({ type: 'onActivate', payload: { columnIndex, rowData, event, tableRef } });
    },

    onValue: async ({ value, rowData, property }) => {
      dispatch({ type: 'onValue', payload: { value, property, rowData, tableRef } });
    },
  });

  function generateColumns() {
    const columns = [
      textColumn({
        className: 'prospects-remapping-table__spreadsheet-field-column',
        headerLabel: translate(
          'prospects.importProspectsPopup.remappingCsv.table.headers.spreadsheetColumn',
        ),
        name: 'spreadsheetField',
      }),
      reactSelectColumn({
        className: 'prospects-remapping-table__prospect-attribute-column',
        cellTransforms: [columnSelect(editable, prospectAttrOptions, prospectAttrsSelectProps)],
        cellValueFormatter: prospectAttrCellValueFormatter(prospectAttrOptions),
        headerLabel: translate(
          'prospects.importProspectsPopup.remappingCsv.table.headers.prospectAttribute',
        ),
        name: 'prospectAttr',
        isClearable: true,
      }),
      textColumn({
        cellValueFormatter: customFieldValueFormatter,
        className: 'prospects-remapping-table__custom-field-column',
      }),
      textColumn({
        className: 'prospects-remapping-table__spreadsheet-field-preview-column',
        headerLabel: translate(
          'prospects.importProspectsPopup.remappingCsv.table.headers.spreadsheetPreviewColumn',
        ),
        name: 'spreadsheetFieldPreview',
      }),
    ];

    return columnsGenerator(columns);
  }

  const columns = generateColumns();

  return (
    <div className="prospects-remapping">
      <h3>{translate('prospects.importProspectsPopup.remappingCsv.table.title')}</h3>

      <div className="prospects-remapping__table-wrapper">
        <Table.Provider columns={columns} className="prospects-remapping-table">
          <Table.Header />
          <Table.Body rowKey="spreadsheetField" rows={rows} />
        </Table.Provider>
        <MessageComponent message={{ type: 'error', text: errors.email }} />
      </div>

      <h3>{translate('prospects.importProspectsPopup.remappingCsv.dripList.title')}</h3>

      <div className="prospects-remapping__eo-drip-list">
        <Select
          isClearable
          label={translate('prospects.importProspectsPopup.remappingCsv.dripList.label')}
          blurInputOnSelect={false}
          triggerChangeOnBlur={false}
          onChange={setSelectValue('eoDripListId')}
          options={eoDripListsOptions}
          value={eoDripListsOptions.find(({ value }) => value === eoDripListId)}
        />

        <AddEoDripListButton isBlue onSubmitCb={handleCreateEoDripListCb} />
      </div>

      <hr />

      <h3>
        {translate('prospects.importProspectsPopup.remappingCsv.prospectAlreadyExists.title')}
      </h3>

      <div className="prospects-remapping__duplicate-modes">
        {DUPLICATE_MODES.map((mode) => (
          <RadioButton
            key={mode}
            checked={duplicatesMode === mode}
            value={mode}
            onChange={handleChangeDuplicatesMode}
          >
            {translate(
              `prospects.importProspectsPopup.remappingCsv.prospectAlreadyExists.duplicateModes.${mode}`,
            )}
          </RadioButton>
        ))}
      </div>

      <hr className="import-prospects-popup__modal-divider" />

      <div className="import-prospects-popup__btn-group">
        <div className="import-prospects-popup__btn">
          <ButtonComponent isWhite onClick={onCancel}>
            {translate('prospects.importProspectsPopup.buttons.cancel')}
          </ButtonComponent>
        </div>

        <div className="import-prospects-popup__btn">
          <ButtonComponent isGreen onClick={handleImportProspects} isLoading={isLoading}>
            {translate('prospects.importProspectsPopup.buttons.importProspects')}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

ProspectsRemappingListComponent.propTypes = {
  defaultEoDripListId: PropTypes.number,
  immutableEoCustomFields: eoCustomFieldsList,
  immutableEoDripListsOptions: optionsList,
  onCancel: PropTypes.func,
  onImportProspects: PropTypes.func,
  spreadsheetAttrs: PropTypes.object,
};

export default ProspectsRemappingListComponent;
