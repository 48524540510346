import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import PagesTable from './PagesTable';

import { brandPagesArray } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ExternalLinkIcon from 'common/icons/external_link';
import WorldIcon from 'common/icons/world_icon';

import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import './pagesExportToCsvPopup.scss';

const PagesExportToCsvPopupComponent = ({
  canExportCsvInSubscription,
  onCancel,
  onClickUpgradeSubscription,
  onSubmit,
  pageParentLink,
  pageParentId,
  pageParentType,
  pages,
}) => {
  const selectedRows = pages.filter((page) => page.get('selected', false));
  const hasSelectedRows = selectedRows.size > 0;
  const exportButtonIsDisabled = !hasSelectedRows || !canExportCsvInSubscription;

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>{translate('dlExportCsvPopup.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!canExportCsvInSubscription && (
          <SubscriptionLimitRibbonComponent>
            {translate('subscriptionLimitRibbon.message')(
              translate('subscriptionLimitRibbon.limited.exportCsv'),
            )}
            <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
          </SubscriptionLimitRibbonComponent>
        )}
        <div className="export-csv-popup-component__brand-name">
          <WorldIcon className="export-csv-popup-component__world-icon" />
          {pageParentLink}
          <a href={pageParentLink} className="export-csv-popup-component__external-link">
            <ExternalLinkIcon className="export-csv-popup-component__external-link-icon" />
          </a>
        </div>
        <PagesTable pageParentId={pageParentId} pageParentType={pageParentType} />
      </Modal.Body>
      <Modal.Footer>
        <div className="export-csv-popup-component__btn-group">
          <div className="export-csv-popup-component__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('dlExportCsvPopup.buttons.cancel')}
            </ButtonComponent>
          </div>
          <div className="export-csv-popup-component__btn">
            <ButtonComponent isDisabled={exportButtonIsDisabled} isGreen onClick={onSubmit}>
              {translate('dlExportCsvPopup.buttons.export')}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

PagesExportToCsvPopupComponent.propTypes = {
  canExportCsvInSubscription: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickUpgradeSubscription: PropTypes.func,
  onSubmit: PropTypes.func,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageParentLink: PropTypes.string,
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  pages: brandPagesArray,
};

export default PagesExportToCsvPopupComponent;
