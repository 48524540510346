import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CrawlPageDlsButtonComponent from './CrawlPageDlsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { canUseCrawlersSelector } from 'selectors/railsContextSelectors';
import { enqueuePageDlsCrawling } from 'api/crawler';
import { trackHelpcrunchEvent } from 'common/utils';
import { visiblePageIdByParentSelector } from 'selectors';

class CrawlPageDlsButtonContainer extends React.PureComponent {
  static propTypes = {
    canUseCrawlers: PropTypes.bool,
    dispatch: PropTypes.func,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // eslint-disable-line react/no-unused-prop-types
    pageParentType: PropTypes.oneOf(['brand_page', 'brand']).isRequired, // eslint-disable-line react/no-unused-prop-types
    visiblePageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  handlePageCrawl = async () => {
    const { dispatch, pageChildType, visiblePageId } = this.props;

    await enqueuePageDlsCrawling(dispatch, visiblePageId);

    trackHelpcrunchEvent(`crawl.${pageChildType}`);
  };

  render() {
    const { canUseCrawlers } = this.props;

    return (
      <CrawlPageDlsButtonComponent
        canUseCrawlers={canUseCrawlers}
        onPageCrawl={this.handlePageCrawl}
      />
    );
  }
}

function select(state, ownProps) {
  const canUseCrawlers = canUseCrawlersSelector(state, ownProps);
  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);

  return {
    canUseCrawlers,
    visiblePageId,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(CrawlPageDlsButtonContainer)));
