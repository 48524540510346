const FETCH_AHREFS_ABILITIES__FAILURE = 'FETCH_AHREFS_ABILITIES__FAILURE';
const FETCH_AHREFS_ABILITIES__START = 'FETCH_AHREFS_ABILITIES__START';
const FETCH_AHREFS_ABILITIES__SUCCESS = 'FETCH_AHREFS_ABILITIES__SUCCESS';
const FETCH_AHREFS_CONNECTION__FAILURE = 'FETCH_AHREFS_CONNECTION__FAILURE';
const FETCH_AHREFS_CONNECTION__START = 'FETCH_AHREFS_CONNECTION__START';
const FETCH_AHREFS_CONNECTION__SUCCESS = 'FETCH_AHREFS_CONNECTION__SUCCESS';
const REVOKE_AHREFS_ACCESS__FAILURE = 'REVOKE_AHREFS_ACCESS__FAILURE';
const REVOKE_AHREFS_ACCESS__START = 'REVOKE_AHREFS_ACCESS__START';
const REVOKE_AHREFS_ACCESS__SUCCESS = 'REVOKE_AHREFS_ACCESS__SUCCESS';
const UPDATE_CONNECTION_SETTINGS__FAILURE = 'UPDATE_CONNECTION_SETTINGS__FAILURE';
const UPDATE_CONNECTION_SETTINGS__START = 'UPDATE_CONNECTION_SETTINGS__START';
const UPDATE_CONNECTION_SETTINGS__SUCCESS = 'UPDATE_CONNECTION_SETTINGS__SUCCESS';


function fetchAhrefsAbilitiesFailure(errors) {
  return { type: FETCH_AHREFS_ABILITIES__FAILURE, errors };
}

function fetchAhrefsAbilitiesStart() {
  return { type: FETCH_AHREFS_ABILITIES__START };
}

function fetchAhrefsAbilitiesSuccess(abilities) {
  return { type: FETCH_AHREFS_ABILITIES__SUCCESS, abilities };
}

function fetchAhrefsConnectionFailure(errors) {
  return { type: FETCH_AHREFS_CONNECTION__FAILURE, errors };
}

function fetchAhrefsConnectionStart() {
  return { type: FETCH_AHREFS_CONNECTION__START };
}

function fetchAhrefsConnectionSuccess(connection) {
  return { type: FETCH_AHREFS_CONNECTION__SUCCESS, connection };
}

function revokeAhrefsAccessFailure(errors) {
  return { type: REVOKE_AHREFS_ACCESS__FAILURE, errors };
}

function revokeAhrefsAccessStart() {
  return { type: REVOKE_AHREFS_ACCESS__START };
}

function revokeAhrefsAccessSuccess(connection) {
  return { type: REVOKE_AHREFS_ACCESS__SUCCESS, connection };
}

function updateConnectionSettingsFailure() {
  return { type: UPDATE_CONNECTION_SETTINGS__FAILURE };
}

function updateConnectionSettingsStart() {
  return { type: UPDATE_CONNECTION_SETTINGS__START };
}

function updateConnectionSettingsSuccess(marketer) {
  return { type: UPDATE_CONNECTION_SETTINGS__SUCCESS, marketer };
}


export {
  FETCH_AHREFS_ABILITIES__FAILURE,
  FETCH_AHREFS_ABILITIES__START,
  FETCH_AHREFS_ABILITIES__SUCCESS,
  FETCH_AHREFS_CONNECTION__FAILURE,
  FETCH_AHREFS_CONNECTION__START,
  FETCH_AHREFS_CONNECTION__SUCCESS,
  REVOKE_AHREFS_ACCESS__FAILURE,
  REVOKE_AHREFS_ACCESS__START,
  REVOKE_AHREFS_ACCESS__SUCCESS,
  UPDATE_CONNECTION_SETTINGS__FAILURE,
  UPDATE_CONNECTION_SETTINGS__START,
  UPDATE_CONNECTION_SETTINGS__SUCCESS,
  fetchAhrefsAbilitiesFailure,
  fetchAhrefsAbilitiesStart,
  fetchAhrefsAbilitiesSuccess,
  fetchAhrefsConnectionFailure,
  fetchAhrefsConnectionStart,
  fetchAhrefsConnectionSuccess,
  revokeAhrefsAccessFailure,
  revokeAhrefsAccessStart,
  revokeAhrefsAccessSuccess,
  updateConnectionSettingsFailure,
  updateConnectionSettingsStart,
  updateConnectionSettingsSuccess,
};
