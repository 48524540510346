import React, { Fragment } from 'react';
import classnames from 'classnames';

import { crawlingInProgress } from '../utils';

import {
  AHREFS_PROVIDED_METRICS,
  CRAWLER_PROVIDED_METRICS,
  TOO_LITTLE_RDS_LIMIT,
} from './constants';

import { translate } from 'common/i18n';
import InfoIcon from 'common/icons/info';
import SwitchComponent from 'components_linkio/switch_component';
import SpinnerComponent from 'components_linkio/Spinner';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';
import TutorialPopup from 'components_linkio/TutorialPopup';
import Link from 'components_linkio/link';

import {
  insertNotAvailableIfNull,
  insertZeroIfValueIsNegative,
  processAhrefsOrMozMetricValue,
} from 'common/utils';

const buildLinkText = ({ page }) => {
  const link = page.get('link');

  return (
    <Link
      className="text_two-lines-with-ellipsis-break-all competitors-table__cell_link"
      href={link}
      target="_blank"
      title={link}
      rel="nofollow noopener noreferrer"
    >
      {link}
    </Link>
  );
};

const buildDataWithConditionalSpinner = (value, needToShowSpinner) => {
  return (
    <span className="competitors-table__cell_data-with-spinner">
      {value}
      {needToShowSpinner && <SpinnerComponent isBlack />}
    </span>
  );
};

const buildEmptyCell = (row, percentageGroupsStatus, onAddCompetitorsClick) => {
  // empty cells for the percentages table
  if (percentageGroupsStatus) {
    const showHideCellClasses = classnames({
      'competitors-table__cell_show': row.subTypes && percentageGroupsStatus[row.key].isOpen,
      'competitors-table__cell_hide': row.subTypes && !percentageGroupsStatus[row.key].isOpen,
    });

    return (
      <Fragment key={row.key}>
        {row.key === 'brandName' ? (
          <div className="competitors-table__cell">
            <span className="text text_link" onClick={onAddCompetitorsClick}>
              {translate('percentagesAnalysis.addCompetitors')}
            </span>
          </div>
        ) : (
          <div className="competitors-table__cell" />
        )}
        {row.subTypes &&
          row.subTypes.map((type) => (
            <div key={type.key} className={`${showHideCellClasses} competitors-table__cell`} />
          ))}
      </Fragment>
    );
  }

  // empty cells for the backlinks and metrics tables
  const cellClasses = classnames({
    'competitors-table__cell': true,
    'competitors-table__cell_header': row.isHeadLike,
  });

  return !row.isSummary ? <div key={row.key} className={cellClasses} /> : void 0;
};

const getCellValue = (row, page, tableSection) => {
  const immutableFriendlyRowKey = row.key.split('.');
  const cellCouldDisplayNA = !row.isHeadLike && !(row.isSummary && tableSection === 'averages');

  const ahrefsMetricsUploadingInProgress = page.get('ahrefsMetricsUploadingInProgress');
  const metricIsFromAhrefs = AHREFS_PROVIDED_METRICS.includes(immutableFriendlyRowKey[1]);

  const crawlingStatus = page.get('crawlingStatus');
  const metricProvidedByCrawler = CRAWLER_PROVIDED_METRICS.includes(immutableFriendlyRowKey[1]);

  const isBrandNameKey = row.key === 'brandName';

  const needToShowSpinner =
    (ahrefsMetricsUploadingInProgress && metricIsFromAhrefs) ||
    (crawlingInProgress(crawlingStatus) && (metricProvidedByCrawler || isBrandNameKey));

  if (row.cellValueFormatter) {
    return row.cellValueFormatter({ page, row, tableSection, needToShowSpinner });
  }

  const value = page.getIn(immutableFriendlyRowKey);

  if (value || value === 0) {
    if (metricIsFromAhrefs) {
      const processedValue = processAhrefsOrMozMetricValue(value);
      return buildDataWithConditionalSpinner(processedValue, needToShowSpinner);
    }

    return buildDataWithConditionalSpinner(value, needToShowSpinner);
  }

  if (!cellCouldDisplayNA) {
    return void 0;
  }

  return buildDataWithConditionalSpinner(translate('notAvailable'), needToShowSpinner);
};

const buildCountTowardAverages = ({ page, row, tableSection }) => {
  const isPercentagesAnalysisTable = tableSection === 'percentagesAnalysisTableCompetitors';
  const isVisible = tableSection === 'backlinksTableCompetitors' || isPercentagesAnalysisTable;

  if (!isVisible) {
    return void 0;
  }

  const onChangeCountTowardAverages = row.onClick;
  let initialValue = 'right';
  if (tableSection === 'backlinksTableCompetitors') {
    initialValue = page.get('countTowardAverages', true) ? 'right' : 'left';
  }

  if (isPercentagesAnalysisTable) {
    initialValue = page.get('countTowardCompetitorPercentages', true) ? 'right' : 'left';
  }

  const countReferringDomains = page.getIn(['metrics', 'dfAndIndexedReferringDomains']);

  const competitorHasFinalData =
    !crawlingInProgress(page.get('crawlingStatus')) && !page.get('importingInProgress');

  const showRdsNotification =
    isPercentagesAnalysisTable &&
    competitorHasFinalData &&
    countReferringDomains > 0 &&
    countReferringDomains < TOO_LITTLE_RDS_LIMIT;

  return (
    <>
      <SwitchComponent
        onClick={onChangeCountTowardAverages(page)}
        isBoolean
        initialValue={initialValue}
      />
      {showRdsNotification && (
        <span>
          <InfoIcon />
          {translate('percentagesAnalysis.tooLittleRds.title')}
          <ToggleShowPopup
            className="text_link"
            Popup={<TutorialPopup videoSrc="https://www.youtube.com/embed/iX4gb3QSo2Y" />}
          >
            {translate('percentagesAnalysis.tooLittleRds.linkText')}
          </ToggleShowPopup>
        </span>
      )}
    </>
  );
};

function processCompetitorsBasedEstimationsValue(metricName, metricValue) {
  if (metricName === 'pendingBacklinksToBuild') {
    return insertZeroIfValueIsNegative(insertNotAvailableIfNull(metricValue));
  }

  return metricValue;
}

export {
  buildCountTowardAverages,
  buildDataWithConditionalSpinner,
  buildEmptyCell,
  buildLinkText,
  getCellValue,
  processCompetitorsBasedEstimationsValue,
};
