import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';

import './manuallyAddCompetitorsPopup.scss';

const translationPrefix = 'bulkCompetitorsSetup.addCompetitorsManuallyPopup';

const ManuallyAddCompetitorsPopupComponent = ({ onCancel, onSave }) => {
  const [competitorUris, setCompetitorsUris] = React.useState('');

  function handleSaveCompetitors() {
    onSave(competitorUris);
  }

  function handleChangeCompetitorUris(event) {
    setCompetitorsUris(event.target.value);
  }

  return (
    <Modal show className="manually-add-competitors-popup__modal">
      <Modal.Header>
        <Modal.Title>{translate(`${translationPrefix}.title`)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{translate(`${translationPrefix}.subtitle`)}</p>

        <InputComponent
          className="manually-add-competitors-popup__textarea"
          onInputChange={handleChangeCompetitorUris}
          isMultiline
          message={{ text: translate(`${translationPrefix}.hint`), type: 'hint' }}
          value={competitorUris}
        />
      </Modal.Body>

      <Modal.Footer>
        <div className="manually-add-competitors-popup__btn-group">
          <div className="manually-add-competitors-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate(`${translationPrefix}.buttons.cancel`)}
            </ButtonComponent>
          </div>

          <div className="manually-add-competitors-popup__btn">
            <ButtonComponent isGreen onClick={handleSaveCompetitors} isDisabled={!competitorUris}>
              {translate(`${translationPrefix}.buttons.save`)}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ManuallyAddCompetitorsPopupComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ManuallyAddCompetitorsPopupComponent;
