import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BreadcrumbsEoCampaignsSelectComponent from './BreadcrumbsEoCampaignsSelectComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  activeEoCampaignTabString,
  eoCampaignsList,
  eoCampaignShape,
} from 'common/propTypesShapes/eoCampaigns';

import { breadcrumbsBrandsSelectToggle } from 'actions/ui_actions';
import { eoCampaignsSelector, eoCampaignSelector } from 'selectors/eoCampaignsSelector';
import { fetchEoCampaigns } from 'api/eoCampaign';

const BreadcrumbsEoCampaignsSelectContainer = ({
  activeEoCampaignTab,
  dispatch,
  history,
  eoCampaign,
  eoCampaigns,
}) => {
  React.useEffect(() => {
    fetchEoCampaigns(dispatch, {});
  }, [dispatch]);

  function handleCloseMenu() {
    dispatch(breadcrumbsBrandsSelectToggle());
  }

  function handleSelectChange(option) {
    const eoCampaignId = option && option.value;

    if (!eoCampaignId) {
      return;
    }

    handleCloseMenu();

    switch (activeEoCampaignTab) {
      case 'overview':
        history.push(Routes.overview_eo_campaign_path(eoCampaignId, { format: null }));
        break;
      case 'prospects':
        history.push(Routes.prospects_eo_campaign_path(eoCampaignId, { format: null }));
        break;
      case 'eo_progress':
        history.push(Routes.eo_progressions_path(eoCampaignId, { format: null }));
        break;
      case 'eo_sequences':
        history.push(Routes.eo_campaign_steps_path(eoCampaignId, { format: null }));
        break;
      case 'eo_inboxes':
        history.push(Routes.eo_campaigns_eo_inboxes_path(eoCampaignId, { format: null }));
        break;
      case 'eo_scheduler':
        history.push(Routes.eo_schedulers_path(eoCampaignId, { format: null }));
        break;
      case 'eo_messages':
        history.push(Routes.eo_messages_path(eoCampaignId, { format: null }));
        break;
      case 'options':
        history.push(Routes.options_eo_campaign_path(eoCampaignId, { format: null }));
        break;
      case 'eo_campaign_preview':
        history.push(Routes.eo_campaign_preview_index_path(eoCampaignId, { format: null }));
        break;
    }
  }

  const eoCampaignTitle = eoCampaign.get('title');

  const eoCampaignOptions = eoCampaigns
    .map((eoCampaign) => ({
      label: eoCampaign.get('title'),
      value: eoCampaign.get('id'),
    }))
    .toJS();

  return (
    <BreadcrumbsEoCampaignsSelectComponent
      eoCampaignTitle={eoCampaignTitle}
      eoCampaignOptions={eoCampaignOptions}
      onCloseMenu={handleCloseMenu}
      onSelectChange={handleSelectChange}
    />
  );
};

BreadcrumbsEoCampaignsSelectContainer.propTypes = {
  activeEoCampaignTab: activeEoCampaignTabString,
  dispatch: PropTypes.func.isRequired,
  eoCampaign: eoCampaignShape,
  eoCampaigns: eoCampaignsList,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

function select(state, ownProps) {
  const eoCampaign = eoCampaignSelector(state, ownProps);
  const eoCampaigns = eoCampaignsSelector(state);

  return {
    eoCampaign,
    eoCampaigns,
  };
}

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(BreadcrumbsEoCampaignsSelectContainer);
