import React from 'react';

const BellIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M19.341 15.473l-1.666-2a3 3 0 0 1-.695-1.92v-1.552c0-2.817-1.614-5.454-3.998-6.652V3.1A3.082 3.082 0 0 0 10.484.042a2.997 2.997 0 0 0-3.498 2.96v.34c-2.354 1.16-3.998 3.852-3.998 6.66v1.552a3 3 0 0 1-.695 1.92l-1.659 2A2.766 2.766 0 0 0 2.756 20h3.23a3.999 3.999 0 1 0 7.996 0h3.248a2.748 2.748 0 0 0 2.119-4.527h-.008zM9.984 22a2 2 0 0 1-1.999-2h3.998a2 2 0 0 1-2 2zm7.925-4.437a.75.75 0 0 1-.68.437H2.757a.765.765 0 0 1-.588-1.25l1.66-2a5.001 5.001 0 0 0 1.159-3.196V10c0-2.21 1.431-4.384 3.333-5.057a1 1 0 0 0 .664-.942v-1a1 1 0 0 1 1.175-.985c.499.123.843.579.824 1.092v.893a1 1 0 0 0 .652.937c1.97.733 3.346 2.812 3.346 5.062v1.553a5.021 5.021 0 0 0 1.157 3.197l1.666 2a.75.75 0 0 1 .105.812z" fillRule="nonzero" />
    </svg>
  );
};

BellIcon.defaultProps = {
  fill: '#ADC0D0',
  height: '24px',
  width: '20px',
};

export default BellIcon;
