import {
  fetchEoDashboardMetricsStart,
  fetchEoDashboardMetricsSuccess,
  fetchEoDashboardMetricsFailure,
} from 'actions/eoDashboardActions';

import { translate } from 'common/i18n';

import { getFromAPI, handleAPIError } from 'api/utils';

export async function fetchEoDashboardMetrics(dispatch) {
  dispatch(fetchEoDashboardMetricsStart());

  try {
    const eoDashboardMetrics = await getFromAPI(Routes.eo_dashboard_index_path());

    dispatch(fetchEoDashboardMetricsSuccess(eoDashboardMetrics));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoDashboardMetricsFailure,
      translate('eoDashboard.messages.cantFetchEoDashboardMetrics'),
    );
  }
}
