import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ShowTrustSuggestionsPopupLinkComponent from './ShowTrustSuggestionsPopupLinkComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { openTrustSuggestionsPopup } from 'actions/popup_actions';
import { trackHelpcrunchEvent } from 'common/utils';

class ShowTrustSuggestionsPopupLinkContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };

  handleOpenPopupClick = (event) => {
    event && event.preventDefault();

    const { dispatch } = this.props;

    trackHelpcrunchEvent('view.popup.trustSuggestions');
    dispatch(openTrustSuggestionsPopup());
  };

  render() {
    return <ShowTrustSuggestionsPopupLinkComponent onOpenPopupClick={this.handleOpenPopupClick} />;
  }
}

export default ConnectStoreHOC(connect()(ShowTrustSuggestionsPopupLinkContainer));
