const AHREFS_PROVIDED_METRICS = [
  'domainAhrefsRating',
  'domainRefdomainsCount',
  'homepageRefdomainsCount',
  'uriAhrefsRating',
];

const MOZ_PROVIDED_METRICS = ['domainRefdomainsCount', 'homepageRefdomainsCount'];

const CRAWLER_PROVIDED_METRICS = [
  'liveReferringDomains',
  'dfAndIndexedReferringDomains',
  'nfReferringDomains',
  'nfAndIndexedReferringDomains',
  'notIndexedReferringDomains',
];

const TOO_LITTLE_RDS_LIMIT = 10;

export {
  AHREFS_PROVIDED_METRICS,
  MOZ_PROVIDED_METRICS,
  CRAWLER_PROVIDED_METRICS,
  TOO_LITTLE_RDS_LIMIT,
};
