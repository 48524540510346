import React from 'react';
import PropTypes from 'prop-types';

import DeleteEoSchedulersButton from './DeleteEoSchedulersButton';
import AddEoSchedulerButton from './AddEoSchedulerButton';

import './eoSchedulersBulkActions.scss';

const EoSchedulersBulkActionsComponent = ({ hasEoSchedulers }) => {
  return (
    <div className="eo-schedulers-bulk-actions">
      <div className="eo-schedulers-bulk-actions__row">
        <div className="eo-schedulers-bulk-actions__row-item">
          {hasEoSchedulers && <DeleteEoSchedulersButton />}
        </div>

        <div className="eo-schedulers-bulk-actions__row-item">
          <AddEoSchedulerButton />
        </div>
      </div>
    </div>
  );
};

EoSchedulersBulkActionsComponent.propTypes = {
  hasEoSchedulers: PropTypes.bool,
};

export default EoSchedulersBulkActionsComponent;
