import React from 'react';
import PropTypes from 'prop-types';
import { format, parse, isValid, toDate, isBefore, isAfter } from 'date-fns';

import DateInputComponent from './DateInputComponent';

const DATE_FORMAT = 'MMM dd, yyyy';

const formatDate = (date) => format(date, DATE_FORMAT);

const DateInputContainer = ({ value, onChange, minValue, maxValue, ...props }) => {
  const [inputValue, setInputValue] = React.useState(formatDate(value));
  React.useEffect(() => setInputValue(formatDate(value)), [value]);

  const handleInputChange = ({ target: { value } }) => setInputValue(value);

  const handleDateChange = () => {
    const date = parse(inputValue, DATE_FORMAT, new Date());

    if (
      !isValid(date) ||
      (minValue && isBefore(date, minValue)) ||
      (maxValue && isAfter(date, maxValue))
    ) {
      onChange(toDate(value));
      return;
    }

    onChange(date);
  };

  return (
    <DateInputComponent
      onDateChange={handleDateChange}
      onChange={handleInputChange}
      value={inputValue}
      {...props}
    />
  );
};

DateInputContainer.propTypes = {
  maxValue: PropTypes.instanceOf(Date),
  minValue: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
};

export default DateInputContainer;
