import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { translate } from '../../common/i18n';

import ButtonComponent from '../button_component';
import MessageComponent from '../message_component';

import './importCsv.scss';


export default class ImportCsvComponent extends Component {
  static propTypes = {
    hintMessage: PropTypes.element,
    isDisabled: PropTypes.bool,
    onFileUpload: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      file: void 0,
    };
  }

  input = {};

  handleButtonClick = () => {
    this.input.click();
  }

  handleOnImport = async (event) => {
    const file = event.target.files[0];

    this.setState({ file });

    this.props.onFileUpload(file);
  }

  fileName = () => {
    const { file } = this.state;
    return file ? file.name : translate('importCsv.defaultFileName');
  }

  render() {
    const { hintMessage, isDisabled } = this.props;
    const fileName = this.fileName();
    const wrapperClasses = classnames({
      'import-csv-component': true,
      'import-csv-component_is-disabled': isDisabled,
    });

    return (
      <div className={wrapperClasses}>
        <div className="import-csv-component__file-info">
          {fileName}
        </div>
        <div className="import-csv-component__choose-file-btn">
          <ButtonComponent
            isBlue
            onClick={this.handleButtonClick}
            isDisabled={isDisabled}
          >
            {translate('importCsv.buttons.chooseFile')}
          </ButtonComponent>
        </div>
        <input
          accept=".csv,.tsv"
          className="import-csv-component__input"
          name="csv-file-input"
          onChange={this.handleOnImport}
          ref={(input) => this.input = input}
          type="file"
        />
        {hintMessage &&
          <MessageComponent message={{ type: 'hint' }}>
            {hintMessage}
          </MessageComponent>
        }
      </div>
    );
  }
}
