import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BulkDlsActionsComponent from './BulkDlsActionsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { ATP_TABLE_TABS_BULK_ACTIONS } from 'components/atp/AtpTableTabs/constants';
import { activeAtpTableTabSelector, optionsSelector } from 'selectors';
import { optionShape } from 'common/prop_types_shapes';

const BulkDlsActionsContainer = ({
  activeAtpTableTab,
  anchorTypeOptions,
  pageChildType,
  pageParentId,
  pageParentType,
  positionBottom,
}) => {
  const actions = ATP_TABLE_TABS_BULK_ACTIONS[activeAtpTableTab];

  return (
    <BulkDlsActionsComponent
      actions={actions}
      anchorTypeOptions={anchorTypeOptions.toList().toJS()}
      pageChildType={pageChildType}
      pageParentId={pageParentId}
      pageParentType={pageParentType}
      positionBottom={positionBottom}
    />
  );
};

BulkDlsActionsContainer.propTypes = {
  activeAtpTableTab: PropTypes.string,
  anchorTypeOptions: optionShape,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageParentType: PropTypes.oneOf(['brand_page', 'brand']).isRequired,
  positionBottom: PropTypes.bool,
};

function select(state, ownProps) {
  const activeAtpTableTab = activeAtpTableTabSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const anchorTypeOptions = options.get('anchorTypeOptions');

  return {
    activeAtpTableTab,
    anchorTypeOptions,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(BulkDlsActionsContainer)));
