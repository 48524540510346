import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LinkioRobo from 'common/icons/linkio_robo';
import SpinnerComponent from 'components_linkio/Spinner';

import './blank_state_cta_component.scss';

const BlankStateCtaComponent = ({ children, inModal, customIcon, isLoading }) => {
  const wrapperClasses = classnames({
    'blank-state-cta-component': true,
    'blank-state-cta-component_in-modal': inModal,
  });

  const DefaultBody = (
    <>
      {customIcon ? customIcon : <LinkioRobo className="blank-state-cta-component__head-img" />}
      {children}
    </>
  );

  return (
    <div className={wrapperClasses}>
      {isLoading && <SpinnerComponent isBlack />}
      {!isLoading && DefaultBody}
    </div>
  );
};

BlankStateCtaComponent.propTypes = {
  children: PropTypes.node.isRequired,
  customIcon: PropTypes.node,
  inModal: PropTypes.bool,
  isLoading: PropTypes.bool,
};

BlankStateCtaComponent.defaultProps = {
  inModal: false,
};

const TitleText = ({ children }) => (
  <h4 className="blank-state-cta-component__head-msg">{children}</h4>
);

TitleText.propTypes = {
  children: PropTypes.node.isRequired,
};

const BodyText = ({ children }) => <p className="blank-state-cta-component__cta-msg">{children}</p>;

BodyText.propTypes = {
  children: PropTypes.node.isRequired,
};

export { TitleText, BodyText };

export default BlankStateCtaComponent;
