import { updateEoInboxSuccess } from 'actions/eoInboxesSettingsActions';
import { showErrorMessage, showSuccessMessage } from 'common/utils';
import { translate } from 'common/i18n';

const handleVerifyAvailabilityEoInboxStatus = (dispatch, data) => {
  const { verifyAvailabilityEoInboxStatus, currentError } = data;

  if (!verifyAvailabilityEoInboxStatus) {
    return;
  }

  dispatch(updateEoInboxSuccess(data));

  if (currentError) {
    showErrorMessage(
      dispatch,
      translate('eoInboxesSettings.messages.failureVerifyAvailability')(currentError),
      true
    );
  } else {
    showSuccessMessage(dispatch, translate('eoInboxesSettings.messages.finishVerifyAvailability'));
  }
};

export default handleVerifyAvailabilityEoInboxStatus;
