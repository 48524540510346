import { updateBrandSuccess } from 'actions/brand_actions';
import { updatePageSuccess } from 'actions/brand_page_actions';
import { createDestinationLinksSuccess } from 'actions/destination_link_actions';
import {
  importDestinationLinksManuallyFailure,
  importDestinationLinksManuallySuccess,
} from 'actions/destination_links_import_actions';

import { translate } from 'common/i18n';

import { formattedErrorsMessages, showErrorMessage, showSuccessMessage } from 'common/utils';

const handleImportManuallyStatus = (dispatch, data) => {
  const {
    brand = {},
    brandPage = {},
    destinationLinks,
    dupeEntriesCount,
    importManuallyStatus,
    uniqEntriesCount,
  } = data;

  switch (importManuallyStatus) {
    case 'finished':
      dispatch(updateBrandSuccess(brand));
      dispatch(updatePageSuccess(brandPage));
      dispatch(createDestinationLinksSuccess(destinationLinks));
      dispatch(importDestinationLinksManuallySuccess(brandPage.id));
      handleManualImportMessages(dispatch, brandPage, dupeEntriesCount, uniqEntriesCount);

      break;
    case 'failure':
      dispatch(importDestinationLinksManuallyFailure(brandPage.id));
      handleManualImportMessages(dispatch, brandPage);
      break;
  }
};

export default handleImportManuallyStatus;

const handleManualImportMessages = (dispatch, brandPage, dupeEntriesCount, uniqEntriesCount) => {
  const errors = (brandPage.errors || {}).base;
  const isCompetitorsPage = !!brandPage.brand_page_id;

  if (errors && errors.length > 0) {
    showErrorMessage(
      dispatch,
      formattedErrorsMessages(errors, 'destinationLink.messages.manualImportFailure', {
        dupeEntriesCount,
        uniqEntriesCount,
      }),
      true,
    ); // show permanent error
  } else {
    showSuccessMessage(
      dispatch,
      translate('destinationLink.messages.manualImportSuccess', {
        dupeEntriesCount,
        uniqEntriesCount,
        isCompetitorsPage,
      }),
      false,
    );
  }
};
