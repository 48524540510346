const FETCH_DL_DUPLICATES__FAILURE = 'FETCH_DL_DUPLICATES__FAILURE';
const FETCH_DL_DUPLICATES__START = 'FETCH_DL_DUPLICATES__START';
const FETCH_DL_DUPLICATES__SUCCESS = 'FETCH_DL_DUPLICATES__SUCCESS';

function fetchDlDuplicatesStart() {
  return { type: FETCH_DL_DUPLICATES__START };
}

function fetchDlDuplicatesSuccess(dls) {
  return { type: FETCH_DL_DUPLICATES__SUCCESS, dls };
}

function fetchDlDuplicatesFailure(errors) {
  return { type: FETCH_DL_DUPLICATES__FAILURE, errors };
}


export {
  FETCH_DL_DUPLICATES__FAILURE,
  FETCH_DL_DUPLICATES__START,
  FETCH_DL_DUPLICATES__SUCCESS,
  fetchDlDuplicatesFailure,
  fetchDlDuplicatesStart,
  fetchDlDuplicatesSuccess,
};
