import { fromJS, List as iList } from 'immutable';

import { camelizeObjectDeep, updateBrandPageDls } from './utils';

import {
  FETCH_DL_DUPLICATES__FAILURE,
  FETCH_DL_DUPLICATES__START,
  FETCH_DL_DUPLICATES__SUCCESS,
} from 'actions/dl_duplicate_actions';

import { UPDATE_DESTINATION_LINKS__SUCCESS } from 'actions/destination_link_actions';

const initialState = iList();

export default function dlDuplicateReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DL_DUPLICATES__FAILURE:
    case FETCH_DL_DUPLICATES__START: {
      break;
    }
    case FETCH_DL_DUPLICATES__SUCCESS: {
      state = fromJS(action.dls.map(camelizeObjectDeep));

      break;
    }

    case UPDATE_DESTINATION_LINKS__SUCCESS: {
      state = updateBrandPageDls(state, action.dls, camelizeObjectDeep);

      break;
    }
  }

  return state;
}
