import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';
import cx from 'classnames';
import { format } from 'date-fns';

import EoDripListFormPopup from '../EoDripListsBulkActions/EoDripListFormPopup';

import columnsGenerator from 'common/tables/columns_generator';
import { addCSSClassToRowInTable, TABLE_ROW_ACTIVE_CLASS } from 'common/tables/utils';

import { buildSelectedItems } from 'common/utils';
import { checkboxColumn, textColumn } from 'common/tables/columns';
import { eoDripListsList } from 'common/propTypesShapes/eoDripLists';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import CheckedIcon from 'common/icons/checked';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';

import './eoDripListsTable.scss';

const EoDripListsTableComponent = ({
  canUpdate,
  eoDripLists,
  isReadOnly,
  onRowClick,
  onTableHeaderClick,
  onToggleSelectedRows,
  selectedIds,
  sorting,
}) => {
  const processedEoDripLists = buildSelectedItems(eoDripLists.toJS(), selectedIds);

  const headerValueFormatter = (options = {}) => (_value, extra) => {
    const { sortBy, sortingOrder } = sorting;
    const { withExplanationMessage } = options;
    const { property } = extra;

    const label = translate(`eoDripList.table.headers.${property}`);

    if (isReadOnly) {
      return label;
    }

    return (
      <HeaderFieldWithSorting
        appModule="eoDripLists"
        label={label}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  };

  const handleOnToggleSelectRow = (eoDripListId) => (event) => {
    const isSelected = event.target.checked;

    onToggleSelectedRows([eoDripListId], isSelected);
  };

  function handleOnToggleSelectAll(event) {
    const isSelectedAll = event.target.checked;

    const eoDripListsIds = processedEoDripLists.map((eoDripList) => eoDripList.id);

    onToggleSelectedRows(eoDripListsIds, isSelectedAll);
  }

  function isAllSelected() {
    const selectedRowsCount = processedEoDripLists.filter((eoDripList) => eoDripList.selected)
      .length;
    const rowsCount = processedEoDripLists.length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  }

  function cellCheckboxFormatters(_value, extra) {
    const {
      rowData: { selected, id },
    } = extra;

    return (
      <Checkbox
        checked={selected}
        className="eo-drip-lists-table__checkbox"
        onChange={handleOnToggleSelectRow(id)}
      />
    );
  }

  function headerCheckboxFormatters() {
    return (
      <Checkbox
        checked={isAllSelected()}
        className="eo-drip-lists-table__checkbox"
        onChange={handleOnToggleSelectAll}
      />
    );
  }

  function dateValueFormatter(value) {
    return format(new Date(value), 'MMM d h:mm a');
  }

  // function booleanCellValueFormatter(isTrue) {
  //   return isTrue ? <CheckedIcon /> : '';
  // }

  function brandValueFormatter(value) {
    return value || translate('uiComponents.brandAssign.noBrandAssigned');
  }

  function involvedProspectsCellValueFormatter(_value, extra) {
    const {
      rowData: { declineProspectsBrandlyInvolved, declineProspectsAlreadyInvolved },
    } = extra;

    if (declineProspectsBrandlyInvolved) {
      return translate('uiComponents.brandAssign.brandCampaign');
    }

    if (declineProspectsAlreadyInvolved) {
      return translate('uiComponents.brandAssign.anyCampaign');
    }

    return translate('notAvailable');
  }

  function generateColumns() {
    return columnsGenerator(
      [
        !isReadOnly &&
          checkboxColumn({
            headerFormatters: [headerCheckboxFormatters],
            cellFormatters: [cellCheckboxFormatters],
            className: 'eo-drip-lists-table__checkbox-column',
          }),
        textColumn({
          name: 'title',
          headerValueFormatter: headerValueFormatter(),
          Popup: canUpdate && !isReadOnly && EoDripListFormPopup,
          className: 'eo-drip-lists-table__title-column',
          isEditable: canUpdate && !isReadOnly,
        }),
        textColumn({
          name: 'brandName',
          headerLabel: translate('eoDripList.table.headers.brandName'),
          cellValueFormatter: brandValueFormatter,
          // headerValueFormatter: headerValueFormatter(),
          className: 'eo-drip-lists-table__brand-column-column',
        }),
        textColumn({
          name: 'prospectsCount',
          headerValueFormatter: headerValueFormatter(),
          className: 'eo-drip-lists-table__prospects-count-column',
        }),
        // textColumn({
        //   name: 'validateEmailAddresses',
        //   headerValueFormatter: headerValueFormatter(),
        //   cellValueFormatter: booleanCellValueFormatter,
        //   className: 'eo-drip-lists-table__validate-email-addresses-column',
        // }),
        // textColumn({
        //   name: 'declineCatchAllEmails',
        //   headerValueFormatter: headerValueFormatter(),
        //   cellValueFormatter: booleanCellValueFormatter,
        //   className: 'eo-drip-lists-table__decline-catch-all-emails-column',
        // }),
        // textColumn({
        //   name: 'declineUndeliverableEmails',
        //   headerValueFormatter: headerValueFormatter(),
        //   cellValueFormatter: booleanCellValueFormatter,
        //   className: 'eo-drip-lists-table__decline-undeliverable-emails-column',
        // }),
        // textColumn({
        //   name: 'declineUnknownEmails',
        //   headerValueFormatter: headerValueFormatter(),
        //   cellValueFormatter: booleanCellValueFormatter,
        //   className: 'eo-drip-lists-table__decline-unknown-emails-column',
        // }),
        textColumn({
          name: 'declineProspectsAlreadyInvolved',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: involvedProspectsCellValueFormatter,
          className: 'eo-drip-lists-table__decline-prospects-already-involved-column',
        }),
        textColumn({
          name: 'createdAt',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: dateValueFormatter,
          className: 'eo-drip-lists-table__created-at-column',
        }),
        textColumn({
          name: 'owner',
          headerValueFormatter: headerValueFormatter(),
          className: 'eo-drip-lists-table__owner-column',
        }),
      ].filter(Boolean),
    );
  }

  function handleRowClick(row, rowIndex) {
    const { id } = row;

    const tableNode = document.querySelector('.eo-drip-lists-table_read-only');
    const activeRow = tableNode.querySelector(`.${TABLE_ROW_ACTIVE_CLASS}`);

    activeRow && activeRow.classList.remove(TABLE_ROW_ACTIVE_CLASS);

    addCSSClassToRowInTable(tableNode, rowIndex, TABLE_ROW_ACTIVE_CLASS);

    onRowClick(id);
  }

  function onBodyRow(row, { rowIndex }) {
    if (!isReadOnly) {
      return void 0;
    }

    return {
      onClick: () => handleRowClick(row, rowIndex),
    };
  }

  const columns = generateColumns();

  const tableClassNames = cx('eo-drip-lists-table', {
    'eo-drip-lists-table_read-only': isReadOnly,
    'table-with-row-selection': isReadOnly,
  });

  return (
    <Table.Provider className={tableClassNames} columns={columns}>
      <Table.Header />
      <Table.Body rowKey="id" rows={processedEoDripLists} onRow={onBodyRow} />
    </Table.Provider>
  );
};

EoDripListsTableComponent.propTypes = {
  canUpdate: PropTypes.bool,
  eoDripLists: eoDripListsList,
  isReadOnly: PropTypes.bool,
  onRowClick: PropTypes.func,
  onTableHeaderClick: PropTypes.func,
  onToggleSelectedRows: PropTypes.func,
  selectedIds: selectedIdsList,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default EoDripListsTableComponent;
