import React from 'react';

import EoCampaignFormPopup from '../EoCampaignFormPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const AddEoCampaignButtonComponent = () => {
  return (
    <ToggleShowPopup Popup={<EoCampaignFormPopup />}>
      <ButtonComponent className="eo-campaigns-bulk-actions__add-eo_campaign" isInitial isGreen>
        {translate('eoCampaign.bulkActions.addEoCampaign')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

export default AddEoCampaignButtonComponent;
