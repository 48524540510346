import pluralize from 'pluralize';

// eslint-disable-next-line (import/order);
import { buildWordInPastTenseWithPluralization } from 'common/utils';

import accountDashboard from './accountDashboard';
import atpTableTabsComponent from './atp_table_tabs_component';
import authForms from './auth_forms';
import backlinks from './backlinks';
import backlinksBulkActions from './backlinksBulkActions';
import backlinksSetup from './backlinksSetup';
import billing from './billing';
import brandDashboard from './brandDashboard';
import brandPage from './brand_page';
import brandPageMetricsComponent from './brand_page_metrics_component';
import brandPagePercentage from './brand_page_percentage';
import brands from './brands';
import deleteAccountPopup from './deleteAccountPopup';
import bulkCompetitorsSetup from './bulkCompetitorsSetup';
import bulkPagesSetup from './bulkPagesSetup';
import categorizerPopup from './categorizerPopup';
import changePercentagesPopup from './changePercentagesPopup';
import changesHistory from './changesHistory';
import chart from './chart';
import competitorsPage from './competitors_page';
import confirmations from './confirmations';
import crawlerStatusBar from './crawlerStatusBar';
import destinationLink from './destination_link';
import disavow from './disavow';
import dlDetailsPopup from './dl_details_popup';
import dlExportCsvPopup from './dl_export_csv_popup';
import dlsFilter from './dls_filter';
import dlsRedistributingStatusNotifierComponent from './dls_redistributing_status_notifier_component';
import dlsStatusBarComponent from './dls_status_bar_component';
import eoBlacklistedDomain from './eoBlacklistedDomain';
import eoDashboard from './eoDashboard';
import eoDripList from './eoDripList';
import eoCampaign from './eoCampaign';
import eoCampaignOptions from './eoCampaignOptions';
import eoCampaignProspects from './eoCampaignProspects';
import eoCampaignPreview from './eoCampaignPreview';
import eoCampaignStep from './eoCampaignStep';
import eoDomain from './eoDomain';
import eoEmailVerification from './eoEmailVerification';
import eoInboxes from './eoInboxes';
import eoInboxesSettings from './eoInboxesSettings';
import eoInboxForm from './eoInboxForm';
import eoProgression from './eoProgression';
import eoScheduler from './eoScheduler';
import eoToDo from './eoToDo';
import errors from './errors';
import explanationMessages from './explanation_messages';
import feedbackPopup from './feedback_popup';
import getBacklinksSuggestionPopup from './get_backlinks_suggestion_popup';
import impersonates from './impersonates';
import importCsv from './importCsv';
import importToBrandPage from './import_to_brand_page';
import helpcrunchEvents from './helpcrunchEvents';
import intersectAnalysis from './intersectAnalysis';
import keywordsManager from './keywordsManager';
import metricsAnalysis from './metrics_analysis';
import newsletterSettings from './newsletterSettings';
import notifications from './notifications';
import numberOfBacklinksAnalysis from './number_of_backlinks_analysis';
import options from './options';
import pageDashboard from './pageDashboard';
import pager from './pager';
import payBtnComponent from './pay_btn_component';
import percentagesAnalysis from './percentages_analysis';
import prompts from './prompts';
import prospects from './prospects';
import proxies from './proxies';
import rankTracker from './rankTracker';
import rankTrackerKeywords from './rankTrackerKeywords';
import rankTrackerSetup from './rankTrackerSetup';
import roboSubscriptionStatusComponent from './robo_subscription_status_component';
import sidebar from './sidebar';
import stepsComponent from './steps_component';
import subscriptionLimitReachedPopup from './subscription_limit_reached_popup';
import subscriptionLimitRibbon from './subscription_limit_ribbon';
import subscriptionPlanUsageComponent from './subscriptionPlanUsageComponent';
import switchSubscriptionPlanPopup from './switchSubscriptionPlanPopup';
import trustSuggestionsPopup from './trustSuggestionsPopup';
import uiComponents from './uiComponents';
import eoMessage from './eoMessage';
import eoCustomField from './eoCustomField';
import eoActiveHour from './eoActiveHour';
import eoEmailTemplate from './eoEmailTemplate';

const translationDictionary = (args) => {
  return {
    ...eoActiveHour,
    ...eoEmailTemplate,
    ...eoCustomField,
    ...eoMessage,
    ...accountDashboard,
    ...atpTableTabsComponent,
    ...authForms,
    ...backlinks,
    ...backlinksBulkActions,
    ...backlinksSetup,
    ...billing,
    ...brandDashboard,
    ...brandPage,
    ...brandPageMetricsComponent,
    ...brandPagePercentage,
    ...brands,
    ...bulkCompetitorsSetup,
    ...bulkPagesSetup,
    ...categorizerPopup(args),
    ...changePercentagesPopup,
    ...changesHistory,
    ...chart,
    ...competitorsPage,
    ...confirmations(args),
    ...crawlerStatusBar,
    ...deleteAccountPopup,
    ...destinationLink(args),
    ...disavow,
    ...dlDetailsPopup,
    ...dlExportCsvPopup,
    ...dlsFilter,
    ...dlsRedistributingStatusNotifierComponent,
    ...dlsStatusBarComponent,
    ...eoBlacklistedDomain,
    ...eoDashboard,
    ...eoDripList,
    ...eoCampaign,
    ...eoCampaignOptions,
    ...eoCampaignPreview,
    ...eoCampaignProspects,
    ...eoCampaignStep,
    ...eoDomain,
    ...eoEmailVerification,
    ...eoInboxes,
    ...eoInboxesSettings,
    ...eoInboxForm,
    ...eoProgression,
    ...eoScheduler,
    ...eoToDo,
    ...errors,
    ...explanationMessages,
    ...feedbackPopup,
    ...getBacklinksSuggestionPopup,
    ...impersonates,
    ...importCsv,
    ...importToBrandPage,
    ...helpcrunchEvents,
    ...intersectAnalysis(),
    ...keywordsManager,
    ...metricsAnalysis(args),
    ...newsletterSettings,
    ...notifications,
    ...numberOfBacklinksAnalysis(args),
    ...options,
    ...pageDashboard,
    ...pager,
    ...payBtnComponent(args),
    ...percentagesAnalysis(args),
    ...prompts,
    ...prospects,
    ...proxies,
    ...rankTracker,
    ...rankTrackerKeywords,
    ...rankTrackerSetup,
    ...roboSubscriptionStatusComponent,
    ...sidebar,
    ...stepsComponent,
    ...subscriptionLimitReachedPopup,
    ...subscriptionLimitRibbon,
    ...subscriptionPlanUsageComponent,
    ...switchSubscriptionPlanPopup,
    ...trustSuggestionsPopup,
    ...uiComponents,

    projectTitle: 'Linkio',
    projectHomeURL: 'https://www.linkio.com/',
    copyright: `Copyright © ${args.value}. All rights reserved`,
    yes: 'Yes',
    no: 'No',
    notAvailable: 'N/A',
    noData: 'No data',
    more: 'More',

    validations: {
      email: {
        invalidFormat: 'Please fill in a valid email',
        cantBeBlank: 'Please fill in the email',
      },
      password: {
        confirmationMismatch: 'Your passwords do not match',
        cantBeBlank: 'Please fill in the password',
        isTooShort: 'Password is too short (minimum is 8 characters)',
      },

      marketer: {
        title: {
          cantBeBlank: 'Please fill in the marketer name',
        },
      },
    },

    warnings: {
      addNumberOfDlsToAdd: 'Add number of anchor links that you want to add.',
      changesAutosave: 'All your changes are automatically saved',
      csvImportTooltip:
        "Import published links. Your .csv file must contain 'Link Anchor' and 'Referring Page URL' columns.",
      fieldIsRequired: 'This field is required',
      numberOfSelectedShouldMatch:
        'The number of selected checkboxes must be the same in both tables',
      selectAtLeastOneAnchor: 'Select at least one anchor',
      selectAtLeastOneDl: 'Select at least one destination link',
      selectColumnFirst: 'Select a column first',
      setNewValue: 'Set the new value',
      setTargetsToProceed: 'Please set the targets to proceed',
    },

    ahrefsConnections: {
      description:
        'We integrate with the Ahrefs API to import your existing backlinks and find new backlinks automatically.',
      title: 'Ahrefs API',
      connectNew: 'Connect Your Ahrefs Account',
      pleaseUpgrade: 'Please upgrade to use AHREFS API',
      rowsRequest: {
        title: 'Rows Request (remaining/limit)',
      },
      subscription: {
        title: 'Subscription Type',
      },
      syncInterval: {
        title: 'Sync Interval',
      },
      editSettings: {
        title: 'Edit',
      },
      revokeAccess: {
        title: 'Delete',
      },
    },

    crawlerSettings: {
      description:
        'The crawler periodically validates all of the links in your backlink profile including a Google index check.',
      title: 'Crawler Setup',
      pleaseUpgrade: 'Please upgrade to use Crawler',
      account: {
        title: 'Account',
      },
      crawlerStatus: {
        title: 'Crawler Status',
      },
      subscriptionType: {
        title: 'SubscriptionType',
      },
      backlinksAnalyzed: {
        title: 'Backlinks Analyzed (used/limit)',
      },
      scheduledCrawlingInterval: {
        title: 'Crawling Interval',
      },
      editSettings: {
        title: 'Edit',
      },
      crawlingEnabled: {
        title: 'Crawling On/Off',
      },
    },

    editCrawlerSettings: {
      title: 'Edit Crawling Interval',
      buttons: {
        cancel: 'Cancel',
        submit: 'Update',
      },
    },

    editAhrefsConnectionSettings: {
      title: 'Edit Ahrefs Sync Interval',
      buttons: {
        cancel: 'Cancel',
        submit: 'Update',
      },
    },

    getBacklinksSuggestionOpener: {
      title: "Don't have Ahrefs?",
    },

    crawlingApiCalls: {
      messages: {
        brandPageDlsCrawlingEnqueued: 'Successfully scheduled crawling of your backlinks.',
        destinationLinkCrawlingEnqueued: 'Successfully scheduled crawling of your backlink.',
      },
    },
    brandOverviewApiCalls: {
      messages: {
        brandOverviewMetricsEnque: 'Successfully scheduled metrics generation of your brand',
        findBackLinksFirst: 'No backlinks available to calculate metrics, generate backlinks',
      }
    },
    brandPageCalculateMetricsEnque: {
      messages: {
        brandPageMetric: 'Successfully scheduled page metrics calculation'
      }
    },
    employee: {
      messages: {
        deleteFailure: `Team ${buildWordInPastTenseWithPluralization(
          'Member',
          args.count,
        )} not deleted.`,
        deleteSuccess: `Team ${buildWordInPastTenseWithPluralization(
          'Member',
          args.count,
        )} deleted successfully.`,
        inviteFailure: `Team ${buildWordInPastTenseWithPluralization(
          'Member',
          args.count,
        )} not invited.`,
        inviteSuccess: `Team ${buildWordInPastTenseWithPluralization(
          'Member',
          args.count,
        )} invited successfully.`,
      },
      email: {
        placeholder: 'Email...',
        title: 'Email',
      },
      name: {
        placeholder: 'Name...',
        title: 'Name',
      },
      profileType: {
        placeholder: 'Profile type...',
        title: 'Profile Type',
      },
      status: {
        title: 'Status',
      },
      buttons: {
        deleteTeamMember: {
          body: `Delete ${args.count === 0 ? '' : args.count} Team ${pluralize(
            'Member',
            args.count,
          )}`,
        },
        inviteTeamMember: {
          body: `Send invitation to ${args.count === 0 ? '' : args.count} Team ${pluralize(
            'Member',
            args.count,
          )}`,
        },
      },
    },

    percentageSettings: {
      pageTypeDefaults: {
        title: 'Set Page Type Defaults',
        messages: {
          bulkUpdateSuccessfully: 'The defaults were set successfully',
        },
        pageType: {
          title: 'Page Type',
        },
        defaultPercentageSchemeId: {
          title: 'Default %',
        },
        buttons: {
          cancel: 'Cancel',
          save: 'Save',
        },
      },
      savedPercentages: {
        title: 'Saved Percentages',
        buttons: {
          cancel: 'Cancel',
          save: 'Save',
          addPercentage: 'Add Percentage',
        },
        anchorType: {
          title: 'Anchor Type',
        },
        table: {
          total: 'TOTAL %',
        },
        modal: {
          title: 'Anchor Text Custom Percentage',
          titleInput: 'Custom Percentage Title',
          errors: {
            emptyTitle: "Title can't be blank.",
            notValidVIdealPercentage:
              'All the percentages must be numbers and must be equal or greater than zero.',
            percentageNot100: 'Total must equal to 100%.',
          },
        },
      },
    },

    pricing: {
      name: {
        title: 'Name',
      },
      description: {
        title: 'Description',
      },
      link: {
        title: 'Link',
      },
    },

    roadmap: {
      buttons: {
        generateRoadmap: 'Generate Roadmap',
      },
      dlCount: {
        placeholder: '# links',
      },
      keywords: {
        placeholder: 'Keyword, keyword',
      },
      link: {
        placeholder: 'Page URL...',
      },
      pageTypeId: {
        placeholder: 'Type',
      },
    },

    anchorTextPlanner: {
      titleLong: 'Anchor Text Planner',
      selectedLinksCounter: `Links selected ${args.count}`,
    },

    breadcrumbsMenu: {
      searchBrandPlaceholder: 'Search brand by name or URL',
      brand: 'Brand',
      brandWebsite: 'Brand Website',
    },

    topNavbar: {
      accountTitle: `Account: ${args.title}`,
      affiliate: 'Affiliate Program',
      viewAccountDetails: 'View Account',
      impersonate: 'Impersonate',
      feedback: 'Feedback',
      logout: 'Logout',
      switchAccount: 'Switch Account',
    },

    showHideButtons: {
      hideAll: 'Collapse All',
      showAll: 'Expand All',
    },

    searchPlaceholder: 'Search',
  };
};

const translate = (key, args = {}) => {
  if (!key) {
    return '';
  }

  const dictionary = translationDictionary(args);
  const translation = key.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : undefined;
  }, dictionary);

  return translation;
};

export { translate };
