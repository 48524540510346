import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { translate } from '../../common/i18n';

import CrossIcon from '../../common/icons/cross';

import './steps_component.scss';

const StepsComponent = ({ items, onClose }) => {
  const stepsCount = items.length;

  const stepsComponentClasses = classnames({
    'steps-component': true,
    'steps-component__1-step': stepsCount === 1,
    'steps-component__4-steps': stepsCount === 4,
  });

  return (
    <div className={stepsComponentClasses}>
      {items.map((item) => {
        return (
          <StepItemComponent
            body={item.body}
            key={item.number || 1}
            number={item.number}
            optional={item.optional}
            title={item.title}
          />
        );
      })}
      <span className="steps-component__close-icon" onClick={onClose}>{CrossIcon()}</span>
    </div>
  );
};

StepsComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    optional: PropTypes.bool,
    title: PropTypes.string.isRequired,
    number: PropTypes.number,
  })).isRequired,
  onClose: PropTypes.func.isRequired,
};

const StepItemComponent = ({ number, title, body, optional }) => {
  return (
    <div className="step-item-component">
      {number &&
        <div className="step-item-component__number">{number}</div>
      }
      <div className="step-item-component__title">
        {title}
        {optional &&
          <span className="step-item-component__optional">
            {translate('stepsComponent.optional')}
          </span>
        }
      </div>
      <div className="step-item-component__body">{body}</div>
    </div>
  );
};

StepItemComponent.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  number: PropTypes.number,
  optional: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default StepsComponent;
