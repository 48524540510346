import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import SubmitButtonComponent from 'components/auth/submit_button_component';

import { createEoEmailTemplateFolder } from 'api/eoEmailTemplateFolder';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import './eoEmailTemplateAddFolderPopup.scss';

const EoEmailTemplateAddFolderPopupComponent = ({ dispatch, onClose }) => {
  const [folderName, setFolderName] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const handleFolderNameInputChange = (event) => setFolderName(event.target.value);

  async function handleSubmit(event) {
    event.preventDefault();

    const resp = await createEoEmailTemplateFolder(dispatch, { title: folderName });

    const { errors } = resp;

    if (!isEmpty(errors)) {
      setFormErrors(errors);
      return resp;
    }

    onClose();

    return resp;
  }

  return (
    <Modal show className="eo-email-template-add-folder-popup">
      <Modal.Header className="eo-email-template-add-folder-popup__header">
        <Modal.Title>{translate('eoEmailTemplate.sidebar.addFolder.popup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="eo-email-template-add-folder-popup__body">
        <form id="eo-email-template-add-folder-form" noValidate>
          <InputComponent
            autoFocus
            className="eo-email-template-add-folder-popup__input"
            isMandatory
            label={translate(`eoEmailTemplate.sidebar.addFolder.popup.inputLabel`)}
            message={{ type: 'error', text: formErrors.title }}
            onInputChange={handleFolderNameInputChange}
            value={folderName}
          />
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="eo-email-template-add-folder-popup__btn-group">
          <div className="eo-email-template-add-folder-popup__btn">
            <ButtonComponent isWhite onClick={onClose}>
              {translate('eoEmailTemplate.sidebar.addFolder.popup.cancel')}
            </ButtonComponent>
          </div>

          <div className="eo-email-template-add-folder-popup__btn">
            <SubmitButtonComponent
              form="eo-email-template-add-folder-form"
              isGreen
              onClick={handleSubmit}
              text={translate('eoEmailTemplate.sidebar.addFolder.popup.submit')}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EoEmailTemplateAddFolderPopupComponent.propTypes = {
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConnectStoreHOC(connect()(EoEmailTemplateAddFolderPopupComponent));
