import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import BrandCrawlingStatus from './BrandCrawlingStatus';
import BulkSetValueInputs from './BulkSetValueInputs';
import CrawlBrandBacklinksBtn from './CrawlBrandBacklinksBtn';
import ExportToCsvButton from './ExportToCsvButton';

import { backlinksFilterSpec, defaultBacklinksFilter } from 'utils/dlsFilterSpec';

import DataFilter from 'components_linkio/DataFilter';
import DateRangeInput from 'components_linkio/DateRangeInput';
import GroupDlsButtons from 'pages/Pages/PagesList/PageItem/PageDls/BulkDlsActions/GroupDlsButtons';
import SearchInput from 'components/SearchInput';

import './backlinksBulkActions.scss';

const BacklinksBulkActionsComponent = ({
  backlinksPageType,
  brandPagesOptions,
  changeDateRange,
  dateEnd,
  dateStart,
  isFiltersLoading,
  onSubmitSearch,
}) => {
  const backlinksBulkActionsClasses = cx({
    'backlinks-bulk-actions': true,
    'backlinks-bulk-actions_all-backlinks': !backlinksPageType,
  });

  return (
    <div className={backlinksBulkActionsClasses}>
      <div className="backlinks-bulk-actions__left-block">
        {/* Show search input and group dls button only for "All Backlinks" page type */}
        {!backlinksPageType && (
          <div className="backlinks-bulk-actions__search-and-grouping">
            <SearchInput onSubmit={onSubmitSearch} />
            <div className="backlinks-bulk-actions__separator" />
            <GroupDlsButtons />
          </div>
        )}

        {dateStart && dateEnd && (
          <DateRangeInput
            dateStart={dateStart}
            dateEnd={dateEnd}
            changeDateRange={changeDateRange}
            align={{ bottom: 10, left: 0 }}
          />
        )}

        <DataFilter
          className="backlinks-bulk-actions__filter capybara--backlinks-filter"
          defaultFilters={defaultBacklinksFilter}
          filtersSpec={backlinksFilterSpec({ brandPagesOptions })}
          isLoading={isFiltersLoading}
        />
        <BulkSetValueInputs />
      </div>

      <span className="backlinks-bulk-actions__right-block">
        <ExportToCsvButton />
        <div className="backlinks-bulk-actions__crawling-status-and-btn">
          <BrandCrawlingStatus />
          <CrawlBrandBacklinksBtn />
        </div>
      </span>
    </div>
  );
};

BacklinksBulkActionsComponent.propTypes = {
  backlinksPageType: PropTypes.oneOf(['new_links', 'lost_links', 're_discovered_links']),
  brandPagesOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  changeDateRange: PropTypes.func,
  dateEnd: PropTypes.instanceOf(Date),
  dateStart: PropTypes.instanceOf(Date),
  isFiltersLoading: PropTypes.bool,
  onSubmitSearch: PropTypes.func,
};

export default BacklinksBulkActionsComponent;
