import handleAhrefsMetricsUploadingStatus from './handleAhrefsMetricsUploadingStatus';
import handleArbitraryMessage from './handleArbitraryMessage';
import handleAutodiscoverCompetitorsStatus from './handleAutodiscoverCompetitorsStatus';
import handleAutodiscoverViaSitemapStatus from './handleAutodiscoverViaSitemapStatus';
import handleBacklinkCrawlingStatus from './handleBacklinkCrawlingStatus';
import handleBacklinksAhrefsMetricsUploadingStatus from './handleBacklinksAhrefsMetricsUploadingStatus';
import handleBacklinksMozMetricsUploadingStatus from './handleBacklinksMozMetricsUploadingStatus';
import handleBrandCrawlingStatus from './handleBrandCrawlingStatus';
import handleBrandPageDlsRedistributingStatus from './handle_brand_page_dls_redistributing_status';
import handleCheckAllProxies from './handle_check_all_proxies';
import handleCheckEoInboxRepliesStatus from './handleCheckEoInboxRepliesStatus';
import handleCheckSingleProxy from './handleCheckSingleProxy';
import handleCompetitorsSchemesReloadingStatus from './handleCompetitorsSchemesReloadingStatus';
import handleCrawlingStatus from './handleCrawlingStatus';
import handleDataforseoUploadingStatus from './handleDataforseoUploadingStatus';
import handleDisavowStatus from './handle_disavow_status';
import handleEoProgressionStage from './handleEoProgressionStage';
import handleGoogleIndexedCrawlingStatus from './handleGoogleIndexedCrawlingStatus';
import handleImportBacklinksFromApiStatus from './handleImportBacklinksFromApiStatus';
import handleImportBacklinksManuallyStatus from './handleImportBacklinksManuallyStatus';
import handleImportFromAhrefsStatus from './handle_import_from_ahrefs_status';
import handleImportFromCsvStatus from './handle_import_from_csv_status';
import handleImportFromGscStatus from './handleImportFromGscStatus';
import handleImportFromMozStatus from './handleImportFromMozStatus';
import handleImportManuallyStatus from './handleImportManuallyStatus';
import handleImportProspectsFromCsvStatus from './handleImportProspectsFromCsvStatus';
import handleImportingStatus from './handleImportingStatus';
import handleMozMetricsUploadingStatus from './handleMozMetricsUploadingStatus';
import handleNotificationStatus from './handleNotificationStatus';
import handlePageAddHeadData from './handlePageAddHeadData';
import handlePageTitleFetching from './handle_page_title_fetching';
import handleProspectsEmailValidity from './handleProspectsEmailValidity';
import handleSubscriptionChangeStatus from './handleSubscriptionChangeStatus';
import handleVerifyAvailabilityEoInboxStatus from './handleVerifyAvailabilityEoInboxStatus';

import { setReceivedCallback } from 'api/actionCable/usersChannelInitializer';
import { processObjToCamelCase } from 'api/utils';

export function setupUsersChannelReceivedCallback(dispatch) {
  setReceivedCallback((data) => {
    const camelCasedData = processObjToCamelCase(data);

    handleAhrefsMetricsUploadingStatus(dispatch, camelCasedData);
    handleArbitraryMessage(dispatch, camelCasedData);
    handleAutodiscoverCompetitorsStatus(dispatch, camelCasedData);
    handleAutodiscoverViaSitemapStatus(dispatch, camelCasedData);
    handleBacklinkCrawlingStatus(dispatch, camelCasedData);
    handleBacklinksAhrefsMetricsUploadingStatus(dispatch, camelCasedData);
    handleBacklinksMozMetricsUploadingStatus(dispatch, camelCasedData);
    handleBrandCrawlingStatus(dispatch, camelCasedData);
    handleBrandPageDlsRedistributingStatus(dispatch, camelCasedData);
    handleCheckAllProxies(dispatch, camelCasedData);
    handleCheckEoInboxRepliesStatus(dispatch, camelCasedData);
    handleCheckSingleProxy(dispatch, camelCasedData);
    handleCompetitorsSchemesReloadingStatus(dispatch, data);
    handleCrawlingStatus(dispatch, camelCasedData);
    handleDataforseoUploadingStatus(dispatch, camelCasedData);
    handleDisavowStatus(dispatch, camelCasedData);
    handleEoProgressionStage(dispatch, camelCasedData);
    handleGoogleIndexedCrawlingStatus(dispatch, camelCasedData);
    handleImportBacklinksFromApiStatus(dispatch, camelCasedData);
    handleImportBacklinksManuallyStatus(dispatch, camelCasedData);
    handleImportFromAhrefsStatus(dispatch, camelCasedData);
    handleImportFromCsvStatus(dispatch, camelCasedData);
    handleImportFromGscStatus(dispatch, camelCasedData);
    handleImportFromMozStatus(dispatch, camelCasedData);
    handleImportManuallyStatus(dispatch, camelCasedData);
    handleImportProspectsFromCsvStatus(dispatch, camelCasedData);
    handleImportingStatus(dispatch, camelCasedData);
    handleMozMetricsUploadingStatus(dispatch, camelCasedData);
    handleNotificationStatus(dispatch, camelCasedData);
    handlePageAddHeadData(dispatch, camelCasedData);
    handlePageTitleFetching(dispatch, camelCasedData);
    handleProspectsEmailValidity(dispatch, camelCasedData);
    handleSubscriptionChangeStatus(dispatch, camelCasedData);
    handleVerifyAvailabilityEoInboxStatus(dispatch, camelCasedData);
  });
}
