import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import BulkSetValueInputsComponent from './BulkSetValueInputsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams from 'decorators/withQueryParams';

import { fetchDlSources } from 'api/dl_source';
import { updateBacklinks } from 'api/backlink';
import { currentIdSelector, optionsSelector, uiSelector } from 'selectors';
import { resetSelectedIds } from 'actions/ui_actions';
import { optionsMap, selectedIdsList } from 'common/prop_types_shapes';
import dlSourceChangeService from 'pages/Pages/services/dlSourceChangeService';

const BulkSetValueInputsContainer = ({
  brandId,
  dispatch,
  dlSourcesOptions,
  selectedIds,
}) => {
  React.useEffect(() => {
    fetchDlSources(dispatch);
  }, []);

  async function handleSetProperties(selectedProperty, selectedValue) {
    await updateBacklinks(dispatch, brandId, selectedIds.toJS(), selectedProperty, selectedValue);
    dispatch(resetSelectedIds()); // reset selected backlinks after the update operation
  }

  async function handleChangeSource(sourceName) {
    await dlSourceChangeService({ dispatch, dlSourcesOptions }, sourceName);
  }

  const isSelected = selectedIds.size > 0;

  return (
    <BulkSetValueInputsComponent
      dlSourcesOptions={dlSourcesOptions.toList().toJS()}
      onChangeSource={handleChangeSource}
      onSetProperties={handleSetProperties}
      isSelected={isSelected}
    />
  );
};

BulkSetValueInputsContainer.propTypes = {
  brandId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  dlSourcesOptions: optionsMap,
  selectedIds: selectedIdsList,
};

const selector = (state, ownProps) => {
  const brandId = currentIdSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);
  const dlSourcesOptions = options.get('dlSourcesOptions');

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    brandId,
    dlSourcesOptions,
    selectedIds,
  };
};

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(selector),
)(BulkSetValueInputsContainer);
