import { translate } from '../common/i18n';

import {
  createMarketerStart,
  createMarketerSuccess,
  createMarketerFailure,
  fetchMarketersStart,
  fetchMarketersSuccess,
  fetchMarketersFailure,
} from '../actions/marketer_actions';

import {
  handleAPIError,
  postToAPI,
  getFromAPI,
  processObjToSnakeCase,
} from './utils';

async function createMarketer(dispatch, data) {
  dispatch(createMarketerStart());

  try {
    const resp = await postToAPI(
      Routes.marketers_path(),
      marketerObject(data)
    );
    dispatch(createMarketerSuccess());
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, createMarketerFailure, translate('errors.cantCreateMarketer'));
  }
}

async function fetchMarketers(dispatch) {
  dispatch(fetchMarketersStart());

  try {
    const resp = await getFromAPI(
      Routes.marketers_path()
    );
    dispatch(fetchMarketersSuccess(resp));
  } catch (err) {
    handleAPIError(dispatch, err, fetchMarketersFailure, translate('errors.cantFetchMarketers'));
  }
}

const marketerObject = (data) => ({ marketer: processObjToSnakeCase(data) });

export {
  createMarketer,
  fetchMarketers,
};
