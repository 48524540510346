import {
  camelCase,
  isArray,
  isEmpty,
  isFunction,
  isObject,
  mapKeys,
  mapValues,
} from 'lodash';

import { fromJS } from 'immutable';

const camelizeObject = (object) => {
  return mapKeys(object, (v, k) => camelCase(k));
};

const camelizeObjectDeep = (object) => {
  const camelCasedObject = camelizeObject(object);

  return mapValues(camelCasedObject, (v) => {
    if (isArray(v)) {
      return v.map((element) => {
        if (isObject(element) && !isFunction(element)) {
          return camelizeObjectDeep(element);
        }
        return element;
      });
    }

    if (isObject(v) && !isFunction(v)) {
      return camelizeObjectDeep(v);
    }

    return v;
  });
};

// think twice before use, experimental feature with List instead of Map, since we need sorting here
const mergeArrayToList = (list, array, processFn) => {
  array.forEach((arItem) => list = mergeItemToList(list, arItem, processFn));
  return list;
};

const itemIndex = (item, list) => {
  return list.findIndex((listItem) => {
    return listItem.get('id') === item.id;
  });
};

const mergeItemToList = (list, item, processFn) => {
  const index = itemIndex(item, list);

  if (index > -1) {
    return list.set(index, fromJS(processFn ? processFn(item) : item));
  }

  return list.push(fromJS(processFn ? processFn(item) : item));
};

const updateBrandPageDls = (list, dls, processDlFn) => {
  // update must not increase initial collection size
  const updatedDls = dls
    .filter((dl) => itemIndex(dl, list) > -1);

  if (isEmpty(updatedDls)) {
    return list;
  }

  return mergeArrayToList(list, updatedDls, processDlFn);
};

export {
  camelizeObject,
  camelizeObjectDeep,
  itemIndex,
  mergeArrayToList,
  mergeItemToList,
  updateBrandPageDls,
};
