import React from 'react';
import PropTypes from 'prop-types';

import { eoCampaignShape } from 'common/propTypesShapes/eoCampaigns';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

import './eoCampaignStatusButton.scss';

function buildTooltip(hasEmailSteps, hasInbox) {
  return {
    text: (
      <p>
        {!hasEmailSteps && translate('eoCampaign.buttons.status.hasNoEmailStep')}
        {!hasInbox && !hasEmailSteps && <br />}
        {!hasInbox && translate('eoCampaign.buttons.status.hasNoInbox')}
      </p>
    ),
    placement: 'top',
  };
}

const EoCampaignStatusButtonComponent = ({ immutableEoCampaign, onUpdateCampaignStatus }) => {
  const { hasInbox, hasEmailSteps, status } = immutableEoCampaign.toJS();

  const isDisabled = !hasInbox || !hasEmailSteps;

  const tooltip = isDisabled ? buildTooltip(hasEmailSteps, hasInbox) : {};

  return (
    <ButtonComponent
      className="eo-campaign-status-btn"
      isBlue
      isDisabled={isDisabled}
      onClick={onUpdateCampaignStatus}
      tooltip={tooltip}
    >
      {translate('eoCampaign.buttons.status.label')(status)}
    </ButtonComponent>
  );
};

EoCampaignStatusButtonComponent.propTypes = {
  immutableEoCampaign: eoCampaignShape,
  onUpdateCampaignStatus: PropTypes.func,
};

export default EoCampaignStatusButtonComponent;
