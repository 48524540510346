import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';

import EoSchedulerFormPopup from '../EoSchedulersBulkActions/EoSchedulerFormPopup';

import columnsGenerator from 'common/tables/columns_generator';

import { buildSelectedItems } from 'common/utils';
import { checkboxColumn, textColumn } from 'common/tables/columns';
import { eoSchedulersList } from 'common/propTypesShapes/eoSchedulers';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';

import convertTimeFrom24To12Format from 'utils/convertTimeFrom24To12Format';

import './eoSchedulersTable.scss';

const EoSchedulersTableComponent = ({
  canUpdate,
  eoSchedulers,
  eoSchedulersCount,
  onTableHeaderClick,
  onToggleSelectedRows,
  selectedIds,
  sorting,
}) => {
  const processedEoSchedulers = buildSelectedItems(eoSchedulers.toJS(), selectedIds);

  const headerValueFormatter = (options = {}) => (_value, extra) => {
    const { sortBy, sortingOrder } = sorting;
    const { withExplanationMessage } = options;
    const { property } = extra;

    const label = translate(`eoScheduler.table.headers.${property}`);

    return (
      <HeaderFieldWithSorting
        appModule="eoSchedulers"
        label={label}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  };

  const handleOnToggleSelectRow = (eoSchedulerId) => (event) => {
    const isSelected = event.target.checked;

    onToggleSelectedRows([eoSchedulerId], isSelected);
  };

  function handleOnToggleSelectAll(event) {
    const isSelectedAll = event.target.checked;

    const eoSchedulersIds = processedEoSchedulers.map((eoScheduler) => eoScheduler.id);

    onToggleSelectedRows(eoSchedulersIds, isSelectedAll);
  }

  function isAllSelected() {
    const selectedRowsCount = processedEoSchedulers.filter((eoScheduler) => eoScheduler.selected)
      .length;
    const rowsCount = processedEoSchedulers.length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  }

  function cellCheckboxFormatters(_value, extra) {
    const {
      rowData: { selected, id },
    } = extra;

    return (
      <Checkbox
        checked={selected}
        className="eo-schedulers-table__checkbox"
        onChange={handleOnToggleSelectRow(id)}
      />
    );
  }

  function headerCheckboxFormatters() {
    return (
      <Checkbox
        checked={isAllSelected()}
        className="eo-schedulers-table__checkbox"
        onChange={handleOnToggleSelectAll}
      />
    );
  }

  function weekdaysValueFormatter(weekdays) {
    return weekdays.join(', ');
  }

  function generateColumns() {
    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [headerCheckboxFormatters],
        cellFormatters: [cellCheckboxFormatters],
        className: 'eo-schedulers-table__checkbox-column',
      }),
      textColumn({
        name: 'weekdays',
        headerLabel: translate('eoScheduler.table.headers.weekdays'),
        // headerValueFormatter: headerValueFormatter(), // enable when 'weekday' sorting is fixed
        cellValueFormatter: weekdaysValueFormatter,
        className: 'eo-schedulers-table__weekdays-column',
        Popup: canUpdate && EoSchedulerFormPopup,
        isEditable: canUpdate,
      }),
      textColumn({
        name: 'daytime',
        // headerValueFormatter: headerValueFormatter(), // enable when 'daytime' sorting is fixed
        headerLabel: translate('eoScheduler.table.headers.daytime'),
        className: 'eo-schedulers-table__daytime-column',
        cellValueFormatter: convertTimeFrom24To12Format,
      }),
      textColumn({
        name: 'prospectsCount',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-schedulers-table__prospects_count-column',
      }),
      textColumn({
        name: 'eoDripListTitle',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-schedulers-table__eo-drip-list-column',
      }),
      textColumn({
        name: 'timezone',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-schedulers-table__timezone-column',
      }),
    ]);
  }

  const columns = generateColumns();

  return (
    <>
      {eoSchedulersCount > 0 && (
        <p>{translate('eoScheduler.table.eoSchedulersCount')(eoSchedulersCount)}</p>
      )}

      <Table.Provider className="eo-schedulers-table" columns={columns}>
        <Table.Header />
        <Table.Body rowKey="id" rows={processedEoSchedulers} />
      </Table.Provider>
    </>
  );
};

EoSchedulersTableComponent.propTypes = {
  canUpdate: PropTypes.bool,
  eoSchedulers: eoSchedulersList,
  eoSchedulersCount: PropTypes.number,
  onTableHeaderClick: PropTypes.func,
  onToggleSelectedRows: PropTypes.func,
  selectedIds: selectedIdsList,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default EoSchedulersTableComponent;
