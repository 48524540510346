import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ProspectsTable from 'pages/ProspectsPage/ProspectsTable';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import MessageComponent from 'components_linkio/message_component';
import SearchInput from 'components/SearchInput';

import './selectProspectPopup.scss';

const SelectProspectPopupComponent = ({ onCancel, onSubmit, onChangeSearch }) => {
  const [selectedProspect, setSelectedProspect] = React.useState(null);

  function handleSubmit() {
    onSubmit(selectedProspect);
  }

  return (
    <Modal show className="select-prospect-popup">
      <Modal.Header>
        <Modal.Title>{translate('eoCampaignPreview.selectProspectPopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <SearchInput onSubmit={onChangeSearch} />

        <ProspectsTable isReadOnly onRowClick={setSelectedProspect} />

        <MessageComponent
          message={{
            type: 'hint',
            text: translate('eoCampaignPreview.selectProspectPopup.hint'),
          }}
        />
      </Modal.Body>

      <Modal.Footer>
        <div className="select-prospect-popup__btn-group">
          <div className="select-prospect-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('uiComponents.button.cancel')}
            </ButtonComponent>
          </div>

          <div className="select-prospect-popup__btn">
            <ButtonComponent isGreen onClick={handleSubmit} isDisabled={!selectedProspect}>
              {translate('eoCampaignPreview.selectProspectPopup.submit')}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

SelectProspectPopupComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SelectProspectPopupComponent;
