import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import MetricsPanelItem from './MetricsPanelItem';

import generateMetricsOverTime from 'utils/generateMetricsOverTime';

import { metricsShape, metricsOverTimeShape } from 'common/propTypesShapes/metrics';
import { translate } from 'common/i18n';
import { insertNotAvailableIfNull } from 'common/utils';

import Select from 'components_linkio/Select';
import BadgeComponent from 'components_linkio/badge_component';

import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import './metricsPanelContainer.scss';

const MetricsPanelComponent = ({
  activeMetric,
  canUseMetrics,
  importedBacklinksOnce,
  isBrand,
  isCompetitorsPage,
  isFetching,
  metrics,
  metricsOverTime,
  onMetricItemClick,
  onClickUpgradeSubscription,
}) => {
  const [selectedTimePeriod, setSelectedTimePeriod] = React.useState('week');

  const handleTimePeriodChange = (option) => setSelectedTimePeriod(option.value);

  const isActiveMetric = (item) =>
    item.title === translate(`brandPageMetricsComponent.${activeMetric}.title`);

  function buildMetricsPanelItems() {
    const countReferringDomains = metrics.countReferringDomains || 0;
    const liveReferringDomains = metrics.liveReferringDomains || 0;
    const nfReferringDomains = metrics.nfReferringDomains || 0;
    const nfAndIndexedReferringDomains = metrics.nfAndIndexedReferringDomains || 0;
    const notIndexedReferringDomains = metrics.notIndexedReferringDomains || 0;
    const overallBacklinks = metrics.overallBacklinks || 0;
    const linkioScore = insertNotAvailableIfNull(metrics.linkioScore);

    const countReferringDomainsOverTime = metricsOverTime.countReferringDomains;
    const liveReferringDomainsOverTime = metricsOverTime.liveReferringDomains;
    const nfReferringDomainsOverTime = metricsOverTime.nfReferringDomains;
    const nfAndNotIndexedReferringDomainsOverTime = metricsOverTime.nfAndIndexedReferringDomains;
    const notIndexedReferringDomainsOverTime = metricsOverTime.notIndexedReferringDomains;
    const overallBacklinksOverTime = metricsOverTime.overallBacklinks;
    const linkioScoreOverTime = metricsOverTime.linkioScore;

    const metricsPanelItems = [
      {
        title: translate('brandPageMetricsComponent.countReferringDomains.title'),
        value: countReferringDomains,
        tooltip: translate('brandPageMetricsComponent.countReferringDomains.tooltip'),
        ...generateMetricsOverTime(countReferringDomainsOverTime, {
          timePrefix: selectedTimePeriod,
          isVisible: !isCompetitorsPage,
        }),
        onClick: onMetricItemClick('countReferringDomains'),
        isVisible: true,
      },
      {
        title: translate('brandPageMetricsComponent.liveReferringDomains.title'),
        value: liveReferringDomains,
        tooltip: translate('brandPageMetricsComponent.liveReferringDomains.tooltip'),
        ...generateMetricsOverTime(liveReferringDomainsOverTime, {
          timePrefix: selectedTimePeriod,
          isVisible: !isCompetitorsPage,
        }),
        onClick: onMetricItemClick('liveReferringDomains'),
        isVisible: true,
      },
      {
        title: translate('brandPageMetricsComponent.nfReferringDomains.title'),
        value: nfReferringDomains,
        tooltip: translate('brandPageMetricsComponent.nfReferringDomains.tooltip'),
        ...generateMetricsOverTime(nfReferringDomainsOverTime, {
          timePrefix: selectedTimePeriod,
          isVisible: !isCompetitorsPage,
        }),
        onClick: onMetricItemClick('nfReferringDomains'),
        isVisible: true,
      },
      {
        title: translate('brandPageMetricsComponent.nfAndIndexedReferringDomains.title'),
        value: nfAndIndexedReferringDomains,
        tooltip: translate('brandPageMetricsComponent.nfAndIndexedReferringDomains.tooltip'),
        ...generateMetricsOverTime(nfAndNotIndexedReferringDomainsOverTime, {
          timePrefix: selectedTimePeriod,
          isVisible: !isCompetitorsPage,
        }),
        onClick: onMetricItemClick('nfAndIndexedReferringDomains'),
        isVisible: true,
      },
      {
        title: translate('brandPageMetricsComponent.notIndexedReferringDomains.title'),
        value: notIndexedReferringDomains,
        tooltip: translate('brandPageMetricsComponent.notIndexedReferringDomains.tooltip'),
        ...generateMetricsOverTime(notIndexedReferringDomainsOverTime, {
          timePrefix: selectedTimePeriod,
          isVisible: !isCompetitorsPage,
        }),
        onClick: onMetricItemClick('notIndexedReferringDomains'),
        isVisible: true,
      },
      {
        title: translate('brandPageMetricsComponent.overallBacklinks.title'),
        value: overallBacklinks,
        ...generateMetricsOverTime(overallBacklinksOverTime, {
          timePrefix: selectedTimePeriod,
          isVisible: !isCompetitorsPage,
        }),
        onClick: onMetricItemClick('overallBacklinks'),
        isVisible: true,
      },
      {
        title: translate('brandPageMetricsComponent.linkioScore.title'),
        value: linkioScore,
        tooltip: translate('brandPageMetricsComponent.linkioScore.tooltip'),
        ...generateMetricsOverTime(linkioScoreOverTime, {
          timePrefix: selectedTimePeriod,
          digits: 2,
        }),
        isVisible: !isCompetitorsPage,
      },
    ];

    return metricsPanelItems.filter((item) => item.isVisible);
  }

  const selectOptions = [
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
  ];

  const metricsPanelClasses = cx({
    'metrics-panel__wrapper': true,
    'metrics-panel__wrapper_blur': !canUseMetrics,
    'metrics-panel__wrapper_competitors': isCompetitorsPage,
  });

  const metricsPanelItems = buildMetricsPanelItems();
  const color = {
    green: importedBacklinksOnce,
    warning: !importedBacklinksOnce,
  };
  const profile = importedBacklinksOnce ? 'fullProfile' : 'partialProfile';
  const overallBacklinks = metrics.overallBacklinks || 0;

  return (
    <div className="metrics-panel">
      <div className={metricsPanelClasses}>
        {metricsPanelItems.map((item) => (
          <MetricsPanelItem
            isActive={isActiveMetric(item)}
            isFetching={isFetching}
            item={item}
            key={item.title}
          />
        ))}
        {!isCompetitorsPage && (
          <div className="metrics-panel__select">
            {isBrand && overallBacklinks > 0 && (
              <BadgeComponent {...color} text={translate(`brandPageMetricsComponent.${profile}`)} />
            )}
            <Select
              blurInputOnSelect
              isClearable={false}
              onChange={handleTimePeriodChange}
              options={selectOptions}
              triggerChangeOnBlur={false}
              value={selectOptions.find(
                (option) => String(option.value) === String(selectedTimePeriod),
              )}
            />
          </div>
        )}
      </div>
      {!canUseMetrics && (
        <div className="metrics-panel__subscription-ribbon">
          <SubscriptionLimitRibbonComponent>
            {translate('subscriptionLimitRibbon.message')(
              translate('subscriptionLimitRibbon.limited.metrics'),
              true,
            )}
            <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
          </SubscriptionLimitRibbonComponent>
        </div>
      )}
    </div>
  );
};

MetricsPanelComponent.propTypes = {
  activeMetric: PropTypes.string,
  canUseMetrics: PropTypes.bool,
  importedBacklinksOnce: PropTypes.bool,
  isBrand: PropTypes.bool,
  isCompetitorsPage: PropTypes.bool,
  isFetching: PropTypes.bool,
  metrics: metricsShape,
  metricsOverTime: metricsOverTimeShape,
  onClickUpgradeSubscription: PropTypes.func,
  onMetricItemClick: PropTypes.func,
};

export default MetricsPanelComponent;
export { generateMetricsOverTime };
