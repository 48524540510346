import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash';

import { eoActiveHourShape } from 'common/propTypesShapes/eoActiveHours';
import { optionsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import Checkbox from 'components_linkio/Checkbox';
import InputComponent from 'components_linkio/input_component';
import MessageComponent from 'components_linkio/message_component';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import TimezoneSelect from 'components_linkio/TimezoneSelectComponent';
import TimePicker from 'components_linkio/TimePicker';

import { WEEKDAYS } from 'utils/constants';

import './eoActiveHourFormPopup.scss';

function convertMinutesToTime(minutesCount) {
  return moment().startOf('day').add(minutesCount, 'minutes');
}

function convertTimeToMinutes(time) {
  return time.diff(moment().startOf('day'), 'minutes');
}

const initialEoActiveHoursSettings = WEEKDAYS.map((weekday) => ({
  enabled: weekday !== 'Saturday' && weekday !== 'Sunday',
  fromMinute: 540,
  toMinute: 1080,
  weekday,
}));

const buildInitialState = (accountTimezone) => ({
  name: '',
  timezone: accountTimezone,
  eoActiveHoursSettings: initialEoActiveHoursSettings,
  errors: {},
});

const weekdaysWithStartTimeError = (eoActiveHoursSettings) =>
  eoActiveHoursSettings
    .filter(({ fromMinute, toMinute }) => fromMinute >= toMinute)
    .map(({ weekday }) => weekday) || [];

function reducer(state, action) {
  const { payload, type } = action;

  const newState = cloneDeep(state);

  switch (type) {
    case 'clickActiveHoursWeekday': {
      const updatedEoActiveHoursSettings = newState.eoActiveHoursSettings;

      const index = updatedEoActiveHoursSettings.findIndex(({ weekday }) => weekday === payload);
      const selectedState = (updatedEoActiveHoursSettings[index] || {}).enabled;

      updatedEoActiveHoursSettings[index].enabled = !selectedState;

      newState.eoActiveHoursSettings = updatedEoActiveHoursSettings;
      break;
    }
    case 'changeEoActiveHoursTime': {
      const { minutesType, minutes, weekday: weekdayFromPayload } = payload;

      const updatedEoActiveHoursSettings = newState.eoActiveHoursSettings;
      const index = updatedEoActiveHoursSettings.findIndex(
        ({ weekday }) => weekday === weekdayFromPayload,
      );

      updatedEoActiveHoursSettings[index][minutesType] = minutes;

      newState.eoActiveHoursSettings = updatedEoActiveHoursSettings;

      const dropStartTimeError =
        newState.errors.eoActiveHoursSettingsFromMinute &&
        weekdaysWithStartTimeError(updatedEoActiveHoursSettings).length === 0;

      dropStartTimeError && (newState.errors.eoActiveHoursSettingsFromMinute = '');

      break;
    }
    case 'dropError': {
      newState.errors[payload] = '';
      break;
    }
    default: {
      newState[type] = payload;

      break;
    }
  }

  return newState;
}

const formInputs = [
  {
    name: 'name',
    type: 'text',
    isRequired: true,
  },
];

const EoActiveHourFormPopupComponent = ({
  accountTimezone,
  onCancel,
  onSave,
  eoActiveHour,
  immutableTimezonesOptions,
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    eoActiveHour || buildInitialState(accountTimezone),
  );

  const timezonesOptions = React.useMemo(() => immutableTimezonesOptions.toJS(), [
    immutableTimezonesOptions.size,
  ]);

  const { eoActiveHoursSettings, errors } = state;

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, payload: event.target.value });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const resp = await onSave(state);
    const { errors } = resp;

    dispatch({ type: 'errors', payload: errors });

    if (isEmpty(resp.errors)) {
      onCancel();
    }
  }

  function handleChangeTimezoneName(timezone) {
    dispatch({ type: 'timezone', payload: timezone });
  }

  const handleChangeEoActiveHoursTime = (weekday, minutesType) => (time) => {
    dispatch({
      type: 'changeEoActiveHoursTime',
      payload: { weekday, minutesType, minutes: convertTimeToMinutes(time) },
    });
  };

  const handleClickActiveHoursWeekday = (weekday) => () => {
    dispatch({ type: 'clickActiveHoursWeekday', payload: weekday });
  };

  const defaultTimezoneName = eoActiveHour
    ? (timezonesOptions.find(({ value }) => value === eoActiveHour.timezone) || {}).value
    : accountTimezone;

  return (
    <Modal show className="eo-active-hours-form-popup">
      <Modal.Header>
        <Modal.Title>
          {translate('eoActiveHour.eoActiveHourFormPopup.title')(!!eoActiveHour)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form id="eo-active-hours-form" className="eo-active-hours-form-popup__form" noValidate>
          {formInputs.map((input) => {
            const { name, isRequired, type } = input;
            const value = state[name] || '';

            return (
              <InputComponent
                isMandatory={isRequired}
                key={name}
                label={translate(`eoActiveHour.eoActiveHourFormPopup.inputs.${name}.label`)}
                message={{ type: 'error', text: errors[name] }}
                onInputChange={setInputValue(name)}
                type={type}
                value={value}
              />
            );
          })}

          <TimezoneSelect
            showAccountTimezoneOption
            defaultTimezoneName={defaultTimezoneName}
            onChangeTimezoneName={handleChangeTimezoneName}
            errors={errors}
          />

          <h4>{translate('eoActiveHour.eoActiveHourFormPopup.sendingTimes')}</h4>

          <ul className="eo-active-hours-form-popup__week-list">
            {eoActiveHoursSettings.map(({ enabled, weekday, fromMinute, toMinute }) => {
              return (
                <li key={weekday}>
                  <Checkbox checked={enabled} onChange={handleClickActiveHoursWeekday(weekday)}>
                    {translate(`uiComponents.weekdays.${weekday}`)}
                  </Checkbox>

                  <div className="eo-active-hours-form-popup__day-time">
                    <TimePicker
                      isDisabled={!enabled}
                      value={convertMinutesToTime(fromMinute)}
                      onChange={handleChangeEoActiveHoursTime(weekday, 'fromMinute')}
                      timeConstraints={{ minutes: { step: 10 } }}
                    />

                    <TimePicker
                      key={fromMinute} // keeps 'timeConstraints' in touch with the 'fromMinute' value
                      isDisabled={!enabled}
                      value={convertMinutesToTime(toMinute)}
                      onChange={handleChangeEoActiveHoursTime(weekday, 'toMinute')}
                      timeConstraints={{
                        minutes: { step: 10 },
                        hours: { min: Math.floor(fromMinute / 60) + 1 },
                      }}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          <MessageComponent
            message={{
              type: 'error',
              text:
                errors.eoActiveHoursSettingsFromMinute &&
                `${errors.eoActiveHoursSettingsFromMinute}: ${weekdaysWithStartTimeError(
                  eoActiveHoursSettings,
                ).join(', ')}`,
            }}
          />
          <MessageComponent
            message={{
              type: 'error',
              text: errors.eoActiveHoursSettings,
            }}
          />
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="eo-active-hours-form-popup__btn-group">
          <div className="eo-active-hours-form-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('eoActiveHour.eoActiveHourFormPopup.buttons.cancel')}
            </ButtonComponent>
          </div>

          <div className="eo-active-hours-form-popup__btn">
            <SubmitButtonComponent
              form="eo-active-hours-form"
              isGreen
              onClick={handleSubmit}
              text={translate('eoActiveHour.eoActiveHourFormPopup.buttons.submit')}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EoActiveHourFormPopupComponent.propTypes = {
  accountTimezone: PropTypes.string,
  eoActiveHour: eoActiveHourShape,
  immutableTimezonesOptions: optionsList,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EoActiveHourFormPopupComponent;
