import { updateBrandSuccess } from 'actions/brand_actions';
import { updatePageFailure, updatePageSuccess } from 'actions/brand_page_actions';

import { createDestinationLinksSuccess } from 'actions/destination_link_actions';

import {
  importDestinationLinksFromMozFailure,
  importDestinationLinksFromMozSuccess,
} from 'actions/destination_links_import_actions';

import { fetchPagePercentage } from 'api/brand_page';

import { translate } from 'common/i18n';
import { showSuccessMessage, showSuccessMessageFromTranslation } from 'common/utils';

import { handleAPIError } from 'api/utils';

const handleImportFromMozStatus = (dispatch, data) => {
  const {
    brand = {},
    brandPage = {},
    categorized,
    destinationLinks,
    dupeEntriesCount,
    importFromMozStatus,
    uniqEntriesCount,
  } = data;

  const pageChildType = brandPage.brand_page_id ? 'competitorsPage' : 'brandPage';

  switch (importFromMozStatus) {
    case 'finished':
      dispatch(updateBrandSuccess(brand));
      dispatch(updatePageSuccess(brandPage));
      dispatch(createDestinationLinksSuccess(destinationLinks));
      dispatch(importDestinationLinksFromMozSuccess(brandPage.id));
      (dupeEntriesCount || uniqEntriesCount) &&
        showSuccessImportFromMozMessage(
          dispatch,
          dupeEntriesCount,
          uniqEntriesCount,
          pageChildType,
        );
      if (categorized && destinationLinks.length > 0) {
        fetchPagePercentage(dispatch, brandPage.id);
        showSuccessCategorizeMessage(dispatch, destinationLinks.length);
      }
      break;
    case 'failure':
      handleImportFromMozFailure(dispatch, brandPage);
      break;
  }
};

const showSuccessImportFromMozMessage = (
  dispatch,
  dupeEntriesCount,
  uniqEntriesCount,
  pageChildType,
) => {
  const args = { dupeEntriesCount, uniqEntriesCount };
  showSuccessMessage(
    dispatch,
    translate(`${pageChildType}.messages.linksImportedFromMozSuccessfully`)(args),
  );
};

const showSuccessCategorizeMessage = (dispatch, destinationLinksCount) => {
  showSuccessMessageFromTranslation(
    dispatch,
    'destinationLink.messages.linksCategorizedSuccessfully',
    {
      count: destinationLinksCount,
    },
  );
};

const handleImportFromMozFailure = (dispatch, brandPage) => {
  const error = (brandPage.errors || {}).base;
  handleAPIError(
    dispatch,
    { message: error },
    updatePageFailure,
    translate('errors.cantUpdatePage'),
  );
  dispatch(importDestinationLinksFromMozFailure(error, brandPage.id));
};

export default handleImportFromMozStatus;
