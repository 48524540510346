export const CANCEL_EO_PROGRESSIONS__FAILURE = 'CANCEL_EO_PROGRESSIONS__FAILURE';
export const CANCEL_EO_PROGRESSIONS__SUCCESS = 'CANCEL_EO_PROGRESSIONS__SUCCESS';
export const DELETE_EO_PROGRESSIONS__FAILURE = 'DELETE_EO_PROGRESSIONS__FAILURE';
export const DELETE_EO_PROGRESSIONS__START = 'DELETE_EO_PROGRESSIONS__START';
export const DELETE_EO_PROGRESSIONS__SUCCESS = 'DELETE_EO_PROGRESSIONS__SUCCESS';
export const FETCH_EO_PROGRESSIONS__FAILURE = 'FETCH_EO_PROGRESSIONS__FAILURE';
export const FETCH_EO_PROGRESSIONS__START = 'FETCH_EO_PROGRESSIONS__START';
export const FETCH_EO_PROGRESSIONS__SUCCESS = 'FETCH_EO_PROGRESSIONS__SUCCESS';
export const RESUME_EO_PROGRESSIONS__FAILURE = 'RESUME_EO_PROGRESSIONS__FAILURE';
export const RESUME_EO_PROGRESSIONS__SUCCESS = 'RESUME_EO_PROGRESSIONS__SUCCESS';
export const UPDATE_EO_PROGRESSION__FAILURE = 'UPDATE_EO_PROGRESSION__FAILURE';
export const UPDATE_EO_PROGRESSION__START = 'UPDATE_EO_PROGRESSION__START';
export const UPDATE_EO_PROGRESSION__SUCCESS = 'UPDATE_EO_PROGRESSION__SUCCESS';

export function cancelEoProgressionsSuccess(eoProgressions, eoCampaignId) {
  return { type: CANCEL_EO_PROGRESSIONS__SUCCESS, eoProgressions, eoCampaignId };
}

export function cancelEoProgressionsFailure(errors) {
  return { type: CANCEL_EO_PROGRESSIONS__FAILURE, errors };
}

export function deleteEoProgressionsStart() {
  return { type: DELETE_EO_PROGRESSIONS__START };
}

export function deleteEoProgressionsSuccess(ids, eoCampaignId) {
  return { type: DELETE_EO_PROGRESSIONS__SUCCESS, ids, eoCampaignId };
}

export function deleteEoProgressionsFailure(errors) {
  return { type: DELETE_EO_PROGRESSIONS__FAILURE, errors };
}

export function fetchEoProgressionsStart() {
  return { type: FETCH_EO_PROGRESSIONS__START };
}

export function fetchEoProgressionsSuccess(eoProgressions, eoCampaignId) {
  return { type: FETCH_EO_PROGRESSIONS__SUCCESS, eoProgressions, eoCampaignId };
}

export function fetchEoProgressionsFailure(errors) {
  return { type: FETCH_EO_PROGRESSIONS__FAILURE, errors };
}

export function resumeEoProgressionsSuccess(eoProgressions, eoCampaignId) {
  return { type: RESUME_EO_PROGRESSIONS__SUCCESS, eoProgressions, eoCampaignId };
}

export function resumeEoProgressionsFailure(errors) {
  return { type: RESUME_EO_PROGRESSIONS__FAILURE, errors };
}

export function updateEoProgressionStart() {
  return { type: UPDATE_EO_PROGRESSION__START };
}

export function updateEoProgressionSuccess(eoProgression, eoCampaignId) {
  return { type: UPDATE_EO_PROGRESSION__SUCCESS, eoProgression, eoCampaignId };
}

export function updateEoProgressionFailure(errors) {
  return { type: UPDATE_EO_PROGRESSION__FAILURE, errors };
}
