import React from 'react';

const EditIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="M12.473 0c-.37-.002-.732.128-1.014.41l-.625.643 4.116 4.135.625-.644c.563-.563.57-1.478 0-2.048L13.507.43C13.223.143 12.845 0 12.473 0zm-2.34 1.62l-.976.935 4.292 4.29.994-.916-4.312-4.31zm-1.6 1.618l-6.75 6.69c-.155.078-.27.22-.312.39L.028 15.21c-.066.216-.008.45.15.61.16.16.395.217.61.15l4.897-1.442c.227-.034.416-.192.488-.41l6.653-6.592-.917-.916-6.828 6.845-2.75.8-.586-.585.84-2.867L9.35 4.057l-.82-.82zm1.482 1.5l-6.847 6.866.995.215.136.916 6.866-6.846-1.15-1.15z" />
    </svg>
  );
};

EditIcon.defaultProps = {
  fill: '#FFF',
  width: '14px',
  height: '14px',
};

export default EditIcon;
