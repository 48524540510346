export default {
  brandPageMetricsComponent: {
    countReferringDomains: {
      title: 'Referring Domains That Count',
      tooltip:
        'Number of unique referring domains pointing to your page URL that count towards your anchor \
        text percentages because they are dofollow and indexed.',
    },
    liveReferringDomains: {
      title: 'Live Referring Domains',
      tooltip:
        'Number of unique referring domains linking to your page URL. Includes dofollow, nofollow, \
        indexed, and not indexed domains.',
    },
    nfReferringDomains: {
      title: 'NF Referring Domains',
      tooltip:
        'Number of unique referring domains linking to your page URL that are nofollow. These do not \
        count towards your anchor text percentages.',
    },
    nfAndIndexedReferringDomains: {
      title: 'NF and Indexed Referring Domains',
      tooltip:
        'Number of unique referring domains linking to your page URL that are nofollow and indexed by \
        Google. These do not count towards your anchor text percentages.',
    },
    notIndexedReferringDomains: {
      title: 'Not Indexed Referring Domains',
      tooltip:
        'Number of unique referring domains linking to your page URL that are not indexed by Google. \
        These do not count towards your anchor text percentages.',
    },
    overallBacklinks: {
      title: 'Overall Backlinks Monitored',
    },
    linkioScore: {
      title: 'Anchor Text Score',
      tooltip:
        'Score from 0 to 100 that shows how close your anchor text percentages are to the ideal target \
        percentages. The closer you are to 100, the better.',
    },
    fullProfile: 'Full profile',
    partialProfile: 'Partial profile',
  },
};
