import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, isEqual, upperFirst } from 'lodash';

import { eoInboxShape } from 'common/propTypesShapes/eoInboxesSettings';
import { translate } from 'common/i18n';

import BackButton from 'components_linkio/BackButton';
import ButtonComponent from 'components_linkio/button_component';
import Checkbox from 'components_linkio/Checkbox';
import GoogleIcon from 'common/icons/GoogleIcon';
import InputComponent from 'components_linkio/input_component';
import Link from 'components_linkio/link';
import QuillTextEditor from 'components_linkio/QuillTextEditor';
import SubmitButtonComponent from 'components/auth/submit_button_component';

import './eoInboxFormPage.scss';

const initialState = {
  email: '',
  fromName: '',
  sendHost: '',
  sendPort: '',
  sendUsername: '',
  sendPassword: '',
  receiveHost: '',
  receivePort: '',
  receiveUsername: '',
  receivePassword: '',
  errors: {},
};

function reducer(state, action) {
  const { value, type } = action;

  const newState = cloneDeep(state);

  newState[type] = value;

  return newState;
}

function buildFormCommonInputs(isExistingEoInbox, isCustomEoInbox) {
  const formCommonInputs = [
    {
      name: 'email',
      type: 'email',
      isRequired: true,
      isVisible: isCustomEoInbox || !isExistingEoInbox,
    },
    {
      name: 'fromName',
      type: 'text',
      isRequired: true,
      isVisible: true,
    },
    {
      name: 'nickname',
      type: 'text',
      isVisible: isExistingEoInbox,
    },
    {
      name: 'replyTo',
      type: 'email',
      isVisible: isExistingEoInbox,
    },
    {
      name: 'sendingDelay',
      type: 'number',
      isVisible: isExistingEoInbox,
    },
    {
      name: 'sendingDelayVariation',
      type: 'number',
      isVisible: isExistingEoInbox,
    }
  ];

  return formCommonInputs.filter((input) => input.isVisible);
}

const formMailingInputs = [
  {
    name: 'host',
    type: 'text',
  },
  {
    name: 'port',
    type: 'number',
  },
  {
    name: 'username',
    type: 'text',
  },
  {
    name: 'password',
    type: 'password',
  },
];

const EoInboxFormPageComponent = ({
  eoInboxImmutable,
  onDelete,
  onSave,
  onVerifyAvailability,
  onCheckReplies,
}) => {
  const eoInbox = eoInboxImmutable.toJS();
  const isExistingEoInbox = !!eoInbox.id;

  const [state, dispatch] = React.useReducer(reducer, isExistingEoInbox ? eoInbox : initialState);
  const [initialEoInbox, setInitialEoInbox] = React.useState({});

  React.useEffect(() => {
    isExistingEoInbox && setInitialEoInbox(eoInbox);
  }, []);

  const isCustomEoInbox = isExistingEoInbox && eoInbox.providerType === 'custom';
  const isGmailEoInbox = isExistingEoInbox && eoInbox.providerType === 'gmail';

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  const setCheckboxValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.checked });
  };

  function setSignatureValue(value) {
    dispatch({ type: 'inboxSignature', value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const resp = await onSave(state);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      dispatch({ type: 'errors', value: errors });
    }
  }

  function buildMailingInput(input, mailingType) {
    const { name, type } = input;
    const inputValueName = `${mailingType}${upperFirst(name)}`;
    const value = state[inputValueName] || '';

    return (
      <InputComponent
        key={name}
        isMandatory
        autoComplete={type === 'password' ? 'new-password' : 'off'}
        label={translate(`eoInboxForm.inputs.${name}.label`)}
        message={{ type: 'error', text: state.errors[inputValueName] }}
        name={inputValueName}
        onInputChange={setInputValue(inputValueName)}
        placeholder={translate(`eoInboxForm.inputs.${name}.placeholder.${mailingType}`)}
        type={type}
        value={value}
      />
    );
  }

  const { saveReplies, inboxSignature, reAuthUri, errors = {} } = state;

  const formCommonInputs = buildFormCommonInputs(isExistingEoInbox, isCustomEoInbox);

  const formHasUnsavedChanges = isExistingEoInbox && !isEqual(initialEoInbox, state);

  const verifyEmailBtnTooltip =
    formHasUnsavedChanges || !isExistingEoInbox
      ? {
          text: translate('eoInboxForm.buttons.verify.tooltip'),
          placement: 'top',
          color: 'black',
        }
      : {};

  return (
    <div className="eo-inbox-form">
      <h2>{translate('eoInboxForm.title')(isExistingEoInbox)}</h2>

      <form id="eo-inbox-form" className="eo-inbox-form__form" noValidate>
        {isGmailEoInbox && (
          <h4>
            {translate('eoInboxForm.inputs.email.label')}: {eoInbox.email}
          </h4>
        )}

        {formCommonInputs.map((input) => {
          const { name, type, isRequired } = input;
          const value = state[name] || '';
          const message = errors[name]
            ? { type: 'error', text: errors[name] }
            : {
                type: 'hint',
                text: translate(`eoInboxForm.inputs.${name}.hint`),
              };

          return (
            <InputComponent
              key={name}
              isMandatory={isRequired}
              autoComplete="off"
              label={translate(`eoInboxForm.inputs.${name}.label`)}
              placeholder={translate(`eoInboxForm.inputs.${name}.placeholder`)}
              message={message}
              name={name}
              onInputChange={setInputValue(name)}
              type={type}
              value={value}
            />
          );
        })}

        {isExistingEoInbox && (
          <>
            <Checkbox
              checked={saveReplies}
              onChange={setCheckboxValue('saveReplies')}
              message={{
                type: 'hint',
                text: translate('eoInboxForm.inputs.saveReplies.hint'),
              }}
            >
              {translate('eoInboxForm.inputs.saveReplies.label')}
            </Checkbox>

            <QuillTextEditor
              label={translate('eoInboxForm.inputs.inboxSignature.label')}
              value={inboxSignature}
              onChange={setSignatureValue}
              placeholder={translate('eoInboxForm.inputs.inboxSignature.placeholder')}
              message={{
                type: 'hint',
                text: translate('eoInboxForm.inputs.inboxSignature.hint'),
              }}
            />
          </>
        )}

        {(isCustomEoInbox || !isExistingEoInbox) && (
          <div className="eo-inbox-form__sending-and-receiving-block">
            <ul>
              <h3>{translate('eoInboxForm.sending.title')}</h3>

              {formMailingInputs.map((input) => {
                return buildMailingInput(input, 'send');
              })}
            </ul>

            <ul>
              <h3>{translate('eoInboxForm.receiving.title')}</h3>

              {formMailingInputs.map((input) => {
                return buildMailingInput(input, 'receive');
              })}
            </ul>
          </div>
        )}
      </form>

      <hr />

      <div className="eo-inbox-form__btn-group">
        <div className="eo-inbox-form__btn-group-item">
          {isExistingEoInbox && (
            <>
              <div className="eo-inbox-form__btn">
                <ButtonComponent isRed onClick={onDelete}>
                  {translate('eoInboxForm.buttons.delete')}
                </ButtonComponent>
              </div>
              <div className="eo-inbox-form__btn">
                <ButtonComponent
                  isBlue
                  isLoading={eoInbox.repliesCheckingInProgress}
                  onClick={onCheckReplies}
                  tooltip={{
                    text: translate('eoInboxForm.buttons.checkReplies.tooltip'),
                    placement: 'top',
                    color: 'black',
                  }}
                >
                  {translate('eoInboxForm.buttons.checkReplies.label')}
                </ButtonComponent>
              </div>
            </>
          )}

          <div className="eo-inbox-form__btn">
            <ButtonComponent
              isBlue
              isDisabled={!isExistingEoInbox || formHasUnsavedChanges}
              isLoading={eoInbox.verificationAvailabilityInProgress}
              onClick={onVerifyAvailability}
              tooltip={verifyEmailBtnTooltip}
            >
              {translate('eoInboxForm.buttons.verify.label')}
            </ButtonComponent>
          </div>
        </div>

        <div className="eo-inbox-form__btn-group-item">
          <div className="eo-inbox-form__btn">
            <BackButton isWhite isInitial>
              {translate('uiComponents.button.cancel')}
            </BackButton>
          </div>

          <div className="eo-inbox-form__btn">
            <SubmitButtonComponent
              form="eo-inbox-form"
              isGreen
              onClick={handleSubmit}
              text={translate('eoInboxForm.buttons.submit')(isExistingEoInbox)}
            />
          </div>
        </div>
      </div>

      {isGmailEoInbox && (
        <Link href={reAuthUri} rel="noopener noreferrer" target="_blank">
          <ButtonComponent isInitial className="connect-gmail-button">
            <GoogleIcon />
            {translate('eoInboxForm.buttons.reAuth')}
          </ButtonComponent>
        </Link>
      )}
    </div>
  );
};

EoInboxFormPageComponent.propTypes = {
  eoInboxImmutable: eoInboxShape,
  onCheckReplies: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onVerifyAvailability: PropTypes.func,
};

export default EoInboxFormPageComponent;
