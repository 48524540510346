import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import BacklinksSetupComponent from './BacklinksSetupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { ahrefsAbilitiesShape, subscriptionShape } from 'common/prop_types_shapes';

import { fetchAhrefsAbilities } from 'api/ahrefs_connection';
import { importFromAPI, importFromCsv, manualImport } from 'api/backlink';
import { fetchCurrentSubscription } from 'api/subscription';

import { currentIdSelector } from 'selectors';
import {
  currentMarketerSelector,
  currentSubscriptionSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import { translate } from 'common/i18n';
import defaultMetricsParams from 'utils/routeUtils';

import clickUpgradeSubscriptionService from 'pages/Pages/services/clickUpgradeSubscriptionService';

import Breadcrumbs from 'components/NewBreadcrumbs';
import BreadcrumbsBrandsSelect from 'components/NewBreadcrumbs/BreadcrumbsBrandsSelect';

class BacklinksSetupContainer extends Component {
  static propTypes = {
    ahrefsAbilities: ahrefsAbilitiesShape,
    brandId: PropTypes.string,
    canUseAhrefsInSubscription: PropTypes.bool,
    canUseMetrics: PropTypes.bool,
    canUseMozInSubscription: PropTypes.bool,
    currentSubscription: subscriptionShape,
    dispatch: PropTypes.func,
    hasAhrefsConnection: PropTypes.bool,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchAhrefsAbilities(dispatch);
    fetchCurrentSubscription(dispatch);
  }

  handleImportBacklinks = (data) => {
    const { canUseMetrics, dispatch, brandId, history } = this.props;
    const { csvFile, importMode, importValue } = data;
    let path = Routes.backlink_path(brandId, {
      ...defaultMetricsParams(canUseMetrics),
    });

    switch (importMode) {
      case 'fromCsv':
        importFromCsv(dispatch, brandId, csvFile, importValue);
        break;
      case 'manually': {
        const backlinksArray = importValue.split('\n').map((str) => str.trim());
        path = Routes.new_links_backlink_path(brandId, {
          format: null,
          filters: {
            published_date_beginning_of_day_gteq: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            published_date_end_of_day_lteq: moment().format('YYYY-MM-DD'),
            not_a_double: true,
          },
          sorting: { sortBy: 'firstSeen', sortingOrder: 'desc' },
        });

        manualImport(dispatch, brandId, backlinksArray);
        break;
      }
      case 'viaApi': {
        importFromAPI(dispatch, brandId, importValue);
        break;
      }
    }

    history.push(path);
  };

  handleClickUpgradeSubscription = (event) => {
    const { currentSubscription, dispatch } = this.props;
    clickUpgradeSubscriptionService({ currentSubscription, dispatch }, event);
  };

  render() {
    const {
      ahrefsAbilities,
      canUseMozInSubscription,
      canUseAhrefsInSubscription,
      hasAhrefsConnection,
      //added to check subscription type
      currentSubscription,
    } = this.props;

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.TextItem>
            {translate('backlinksSetup.breadcrumbsTitle')}
          </Breadcrumbs.TextItem>
          <BreadcrumbsBrandsSelect targetAppModule="backlinksSetup" />
        </Breadcrumbs>

        <div className="page__wrapper">
          <BacklinksSetupComponent
            ahrefsAbilities={ahrefsAbilities}
            canImportFromMoz={canUseMozInSubscription}
            canUseAhrefsInSubscription={canUseAhrefsInSubscription}
            hasAhrefsConnection={hasAhrefsConnection}
            onClickUpgradeSubscription={this.handleClickUpgradeSubscription}
            onImportBacklinks={this.handleImportBacklinks}
            subscription={currentSubscription}
          />
        </div>
      </>
    );
  }
}

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);

  const currentMarketer = currentMarketerSelector(state);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  const ahrefsAbilities = state.getIn(['ahrefsConnection', 'abilities']).toJS();
  const canUseAhrefsInSubscription = subscriptionPolicies.getIn(['ahrefsIntegration', 'canUse']);
  const canUseMozInSubscription = subscriptionPolicies.getIn(['mozIntegration', 'canUse']);
  const hasAhrefsConnection = currentMarketer.get('has_ahrefs_connection');
  const canUseMetrics = subscriptionPolicies.getIn(['metrics', 'canUse']);

  return {
    ahrefsAbilities,
    brandId,
    canUseMozInSubscription,
    canUseAhrefsInSubscription,
    currentSubscription,
    hasAhrefsConnection,
    canUseMetrics,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(BacklinksSetupContainer)));
