import React from 'react';

const FolderIcon = (props) => {
  return (
    <svg {...props} width="14" height="12" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.508 8.308c.137 0 .253.05.348.151a.515.515 0 0 1 .144.368.515.515 0 0 1-.144.368.462.462 0 0 1-.348.151h-2.133v2.135a.515.515 0 0 1-.144.368.462.462 0 0 1-.348.151.462.462 0 0 1-.349-.151.515.515 0 0 1-.143-.368V9.346H8.367a.462.462 0 0 1-.348-.151.515.515 0 0 1-.144-.368c0-.144.048-.267.144-.368a.462.462 0 0 1 .348-.151h2.024v-2.25c0-.145.047-.267.143-.368a.462.462 0 0 1 .349-.152c.137 0 .253.05.348.152a.515.515 0 0 1 .144.368v2.25h2.133zm-.492-6.462c.273 0 .505.101.697.303.191.202.287.447.287.736v2.134a.515.515 0 0 1-.144.368.462.462 0 0 1-.348.151.462.462 0 0 1-.349-.151.515.515 0 0 1-.143-.368V2.885H.984v8.077h6.399c.137 0 .253.05.348.151a.515.515 0 0 1 .144.368.515.515 0 0 1-.144.368.462.462 0 0 1-.348.151H.984a.925.925 0 0 1-.697-.303A1.03 1.03 0 0 1 0 10.962V1.038C0 .75.096.505.287.303A.925.925 0 0 1 .984 0H5.4c.22 0 .415.067.588.202s.292.308.356.52l.342 1.124h6.33zm-12.032 0h4.662L5.4 1.038H.984v.808z" fillRule="evenodd" />
    </svg>
  );
};

FolderIcon.defaultProps = {
  fill: '#fff',
  width: '14px',
  height: '12px',
};

export default FolderIcon;
