import React from 'react';
import PropTypes from 'prop-types';

import { cloneDeep } from 'lodash';

import { getPredicates, isNotBlankOrPresentFilter } from '../utils';

import FiltersComponent from './FiltersComponent';

import useIsEqualPreviousValue from 'hooks/useIsEqualPreviousValue';

const initialFilterRow = {
  property: null,
  predicate: null,
  value: null,
};

function reducer(prevState, { type, payload: { filterIdx, value } = {} }) {
  let state = cloneDeep(prevState);

  switch (type) {
    case 'addFilterRow':
      state.push(initialFilterRow);
      break;
    case 'removeAllFilters':
      state = [];
      break;
    case 'removeFilterRow':
      state.splice(filterIdx, 1);
      break;
    case 'propertyNameChange': {
      const updatedFilterRow = {
        property: value,
        value: null,
        predicate: null,
      };

      state[filterIdx] = updatedFilterRow;
      break;
    }
    case 'predicateChange':
      state[filterIdx].value = null;
      state[filterIdx].predicate = value;
      break;
    case 'propertyValueChange':
      state[filterIdx].value = value;
      break;
  }

  return state;
}

function getQuery(filtersSpec, filters) {
  return filters.reduce((resultQueryObject, filter) => {
    const isBlankFilter = !filter.property || !filter.predicate || !filter.value;

    if (isNotBlankOrPresentFilter(filter) && isBlankFilter) {
      return resultQueryObject;
    }

    const {
      property: { value: property },
      predicate: { value: predicate },
      value,
    } = filter;

    const queryForFilter = filtersSpec[filter.property.value].mapToQuery(
      property,
      predicate,
      value,
    );

    return { ...resultQueryObject, ...queryForFilter };
  }, {});
}

function hasSinglePredicate(filtersSpec, property) {
  return getPredicates(filtersSpec, { property: { value: property } }).length === 1;
}

function getInitialFilters(filtersSpec, initialFilters) {
  function getFilter(entity) {
    for (const filter in filtersSpec) {
      const result = filtersSpec[filter].mapToFilter(...entity);
      if (result) {
        return result;
      }
    }

    return null;
  }

  return Object.entries(initialFilters).reduce((acc, entity) => {
    const filter = getFilter(entity);

    if (filter) {
      return [...acc, filter];
    }

    return acc;
  }, []);
}

const handleAddFilter = (dispatch) => () => dispatch({ type: 'addFilterRow' });

const handleRemoveAllFilter = (dispatch) => () => dispatch({ type: 'removeAllFilters' });

const handleRemoveFilter = (dispatch) => (filterIdx) => () =>
  dispatch({ type: 'removeFilterRow', payload: { filterIdx } });

const handleChangeProperty =
  (dispatch, filtersSpec, eqIsDefaultPredicate) => (filterIdx) => (data) => {
    const isValueBlank = data === null || !data.value;
    if (isValueBlank) {
      return;
    }

    dispatch({
      type: 'propertyNameChange',
      payload: { filterIdx, value: data },
    });
    if (hasSinglePredicate(filtersSpec, data.value)) {
      const predicate = getPredicates(filtersSpec, {
        property: { value: data.value },
      })[0];
      dispatch({
        type: 'predicateChange',
        payload: { filterIdx, value: predicate },
      });
    }
    if (eqIsDefaultPredicate) {
      const predicate = getPredicates(filtersSpec, {
        property: { value: data.value },
      }).find((predicate) => predicate.value === 'eq');
      dispatch({
        type: 'predicateChange',
        payload: { filterIdx, value: predicate },
      });
    }
  };

const handleChange = (dispatch, actionType) => (filterIdx) => (data) => {
  const isValueBlank = data === null || !(data.value || Array.isArray(data));
  if (isValueBlank) {
    return;
  }

  dispatch({ type: actionType, payload: { filterIdx, value: data } });
};

const handleChangePredicate = (dispatch) => handleChange(dispatch, 'predicateChange');
const handleChangeValue = (dispatch) => handleChange(dispatch, 'propertyValueChange');

const FiltersContainer = ({
  defaultFilters,
  eqIsDefaultPredicate,
  filtersSpec,
  onClickOutside,
  onFilterQueryChange,
  queryStringFilters,
}) => {
  const processInitialFilters = React.useMemo(() => {
    const initialFilters = getInitialFilters(filtersSpec, queryStringFilters);
    return initialFilters.length !== 0 ? initialFilters : defaultFilters;
  }, [filtersSpec, queryStringFilters]);
  const [filters, dispatch] = React.useReducer(reducer, processInitialFilters);
  const initialQuery = getQuery(filtersSpec, processInitialFilters);
  const query = getQuery(filtersSpec, filters);
  const isQueryChanged = !useIsEqualPreviousValue(query, initialQuery);

  React.useEffect(() => {
    if (isQueryChanged) {
      onFilterQueryChange(query);
    }
  }, [isQueryChanged, query]);

  return (
    <FiltersComponent
      filters={filters}
      filtersSpec={filtersSpec}
      onClickOutside={onClickOutside}
      handleAddFilter={handleAddFilter(dispatch)}
      handleChangeProperty={handleChangeProperty(dispatch, filtersSpec, eqIsDefaultPredicate)}
      handleChangePredicate={handleChangePredicate(dispatch)}
      handleChangeValue={handleChangeValue(dispatch)}
      handleRemoveFilter={handleRemoveFilter(dispatch)}
      handleRemoveAllFilter={handleRemoveAllFilter(dispatch)}
    />
  );
};

FiltersContainer.defaultProps = {
  defaultFilters: [{}],
};

FiltersContainer.propTypes = {
  defaultFilters: PropTypes.arrayOf(PropTypes.shape()),
  eqIsDefaultPredicate: PropTypes.bool,
  filtersSpec: PropTypes.shape().isRequired,
  onClickOutside: PropTypes.func.isRequired,
  onFilterQueryChange: PropTypes.func.isRequired,
  queryStringFilters: PropTypes.shape(),
};

export default FiltersContainer;
