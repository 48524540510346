import React from 'react';

const RightArrowIcon = (props) => {
  return (
    <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.037 12.866a.48.48 0 00.666 0l4.256-4.149.004-.003.4-.39a.45.45 0 000-.648l-4.66-4.542a.48.48 0 00-.666 0l-.399.39a.45.45 0 000 .648L9.565 8l-3.927 3.828a.45.45 0 000 .648l.4.39z"
        fill="#474a4d"
      />
    </svg>
  );
};

RightArrowIcon.defaultProps = {
  width: '14px',
  height: '14px',
  cursor: 'pointer',
};

export default RightArrowIcon;
