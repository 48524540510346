import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ArrowIcon from 'common/icons/ArrowIcon';
import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';

import './headerFieldWithSorting.scss';

const HeaderFieldWithSortingComponent = ({
  appModule,
  label,
  onClick,
  property,
  sortBy,
  sortingOrder,
  withExplanationMessage,
}) => {
  const isSameSortByProperty = sortBy === property;

  const showUpArrow = (isSameSortByProperty && sortingOrder === 'asc') || !isSameSortByProperty;
  const showDownArrow = (isSameSortByProperty && sortingOrder === 'desc') || !isSameSortByProperty;

  const explanationMessage = translate(`explanationMessages.${appModule}Table.${property}`);

  return (
    <div className="header-field_with-sorting" onClick={onClick(property)}>
      <span>
        {label}
        {withExplanationMessage && <ExplanatoryTooltipComponent text={explanationMessage} />}
      </span>
      {showUpArrow && (
        <ArrowIcon
          className="header-field__arrow-icon header-field__arrow-icon_up"
          orientation="up"
        />
      )}
      {showDownArrow && (
        <ArrowIcon
          className="header-field__arrow-icon header-field__arrow-icon_down"
          orientation="down"
        />
      )}
    </div>
  );
};

HeaderFieldWithSortingComponent.propTypes = {
  appModule: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  property: PropTypes.string,
  sortBy: PropTypes.string,
  sortingOrder: PropTypes.string,
  withExplanationMessage: PropTypes.bool,
};

export default HeaderFieldWithSortingComponent;
