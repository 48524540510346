const subscriptionLimitReachedPopup = {
  subscriptionLimitReachedPopup: {
    changeSubscriptionPlan: {
      title: 'Change Your Subscription Plan',
    },
    buttons: {
      cancel: 'No Thanks',
      downgradeToFree: 'Downgrade to Free',
    },
    switch: {
      left: 'Monthly Billing',
      right: 'Annual Billing',
      monthly: '/ mo.',
    },
    subscriptionPlan: {
      backlinksCrawlingsCount: 'Backlinks Analyzed',
      brandsCount: 'Websites',
      checkedKeywordsCount: 'Requests to rankings API',
      eoInboxesCount: 'Inbox',
      trackedKeywordsCount: 'Keywords Tracked',
      unlimited: 'Unlimited',
      users: 'Users',
    },
    featureList: {
      roboBalloon: {
        title: 'I’m here to help.',
        text: 'Automation is included in all subscription plans.',
      },
      feature: {
        ahrefsIntegration: 'Ahrefs Integration',
        automatedLinkBuildingPlanner: 'Automated Link Building Planner',
        backlinksCrawler: 'Backlinks Checker',
        categorizer: 'Automated Anchor Text Categorizer',
        emailOutreach: 'Email Outreach',
        googleIndexCrawler: 'Indexed Backlink Checker',
      },
    },
    messages: {
      changeSubscriptionPlanSuccess: 'Successfully changed subscription plan',
    },
    billedAnnuallyHint: 'Billed Annually',
  },
};

export default subscriptionLimitReachedPopup;
