import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';
import { newsletterSettingsShape, optionsArray } from 'common/prop_types_shapes';

import Checkbox from 'components_linkio/Checkbox';
import InputComponent from 'components_linkio/input_component';
import RadioButton from 'components_linkio/RadioButton';
import Select from 'components_linkio/Select';
import SubmitButtonComponent from 'components/auth/submit_button_component';

import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import DisabledSetting from 'common/img/disabled_newsletter_settings.png';

import './newsletterSettings.scss';

const NewsletterSettingsComponent = ({
  brandsOptions,
  canUseSettings,
  handleChangeEmailClick,
  handleClickUpgradeSubscription,
  handleUpdateSettings,
  newsletterSettings,
  userEmail,
}) => {
  const { receiveBrandIds = [] } = newsletterSettings;

  const defaultSelectedBrands = brandsOptions.filter((option) =>
    receiveBrandIds.includes(option.value),
  );

  const [selectedBrands, setSelectedBrands] = React.useState(defaultSelectedBrands);
  const [receivePeriod, setReceivePeriod] = React.useState(newsletterSettings.receivePeriod);

  const isAllBrandsSelected = brandsOptions.length === (selectedBrands || []).length;

  function onSelectBrand(value) {
    setSelectedBrands(value || []);
  }

  function onSelectAllBrandsClick(event) {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedBrands(brandsOptions);
    } else {
      setSelectedBrands([]);
    }
  }

  function onReceivePeriodChange(event) {
    setReceivePeriod(event.target.value);
  }

  function onSubmit(event) {
    event.preventDefault();

    const receiveBrandIds = selectedBrands.map((option) => option.value);

    handleUpdateSettings({ receiveBrandIds, receivePeriod });
  }

  const changeEmailHintText = (
    <span>
      {translate('newsletterSettings.emailsSentTo.hint.mainText')}
      <span className="text_link" onClick={handleChangeEmailClick}>
        {translate('newsletterSettings.emailsSentTo.hint.linkText')}
      </span>
    </span>
  );

  if (!canUseSettings) {
    return (
      <div className="newsletter-settings-wrapper__disabled-wrapper">
        <img className="newsletter-settings-wrapper__disabled-img" src={DisabledSetting} />
        <div className="newsletter-settings-wrapper__subscription-ribbon">
          <SubscriptionLimitRibbonComponent>
            {translate('subscriptionLimitRibbon.message')(
              translate('subscriptionLimitRibbon.limited.newsletterSettings'),
              true,
            )}
            <UpgradeSubscriptionLink onClick={handleClickUpgradeSubscription} />
          </SubscriptionLimitRibbonComponent>
        </div>
      </div>
    );
  }

  return (
    <form className="newsletter-settings-form">
      <InputComponent
        value={userEmail}
        isDisabled
        label={translate('newsletterSettings.emailsSentTo.title')}
        message={{ type: 'hint', text: changeEmailHintText }}
      />

      <div className="newsletter-settings-form__brands-select">
        <p className="text_grey">{translate('newsletterSettings.brandsSelector.title')}</p>

        <Checkbox checked={isAllBrandsSelected} onChange={onSelectAllBrandsClick}>
          {translate('newsletterSettings.brandsSelector.allBrands')}
        </Checkbox>

        <Select
          blurInputOnSelect={false}
          placeholder={translate('newsletterSettings.brandsSelector.placeholder')}
          triggerChangeOnBlur={false}
          closeMenuOnSelect={false}
          isClearable={false}
          isMulti
          onChange={onSelectBrand}
          options={brandsOptions}
          value={selectedBrands}
        />
      </div>

      <div className="newsletter-settings-form__emails-receive-period">
        <p className="text_grey">{translate('newsletterSettings.receivePeriod.title')}</p>

        <RadioButton
          checked={receivePeriod === 'daily'}
          onChange={onReceivePeriodChange}
          value="daily"
        >
          {translate('newsletterSettings.receivePeriod.daily')}
        </RadioButton>

        <RadioButton
          checked={receivePeriod === 'never'}
          onChange={onReceivePeriodChange}
          value="never"
        >
          {translate('newsletterSettings.receivePeriod.never')}
        </RadioButton>
      </div>

      <SubmitButtonComponent
        isBlue
        onClick={onSubmit}
        text={translate('newsletterSettings.buttons.submit')}
      />
    </form>
  );
};

NewsletterSettingsComponent.propTypes = {
  brandsOptions: optionsArray,
  canUseSettings: PropTypes.bool,
  handleChangeEmailClick: PropTypes.func,
  handleClickUpgradeSubscription: PropTypes.func,
  handleUpdateSettings: PropTypes.func,
  newsletterSettings: newsletterSettingsShape,
  userEmail: PropTypes.string,
};

export default NewsletterSettingsComponent;
