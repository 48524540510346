import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';

import { openDeleteAccountPopup } from 'actions/popup_actions';

import CancelFormSubmitDecorator from 'components/auth/cancel_form_submit_decorator';

import InputComponent from 'components_linkio/input_component';
import TimezoneSelect from 'components_linkio/TimezoneSelectComponent';

import AuthFormGlobalErrorComponent from 'components/auth/auth_form_global_error_component';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import TabsNavigationComponent from 'components/auth/tabs_navigation_component';

import { userShape } from 'common/prop_types_shapes';

import Link from 'components_linkio/link';

import './edit_profile_form_component.scss';

class EditProfileFormComponent extends Component {
  static propTypes = {
    currentMarketerTitle: PropTypes.string,
    currentUser: userShape,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    errors: PropTypes.shape({
      name: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.arrayOf(PropTypes.string),
    }),
    isOwner: PropTypes.bool,
    largeAvatarSrc: PropTypes.string,
    onHandleSubmitClickAction: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { currentUser } = props;

    this.state = {
      timezoneName: currentUser.get('timezoneName', null),
    };
  }

  inputs = {};

  handleSubmitClick = async () => {
    const name = (this.inputs.name || {}).value;
    const title = (this.inputs.title || {}).value;
    const { timezoneName } = this.state;

    await this.props.onHandleSubmitClickAction(name, title, timezoneName);
  };

  nameRef = (input) => (this.inputs.name = input);
  titleRef = (input) => (this.inputs.title = input);

  handleClickDeleteAccount = () => this.props.dispatch(openDeleteAccountPopup());

  changeTimezoneName = (timezoneName) => {
    this.setState({ timezoneName });
  };

  render() {
    const {
      currentUser,
      errors,
      error,
      isOwner,
      largeAvatarSrc,
      currentMarketerTitle,
    } = this.props;

    return (
      <div className="page__wrapper">
        <div className="edit-profile-component">
          <div className="edit-profile-component__tabs">
            <TabsNavigationComponent />
          </div>
          <div className="strip" />
          <form className="edit-profile-component__form" noValidate>
            {error && <AuthFormGlobalErrorComponent message={error} />}
            <div>
              <div className="edit-profile-component__avatar">
                <img
                  className="edit-profile-component__avatar-img"
                  src={largeAvatarSrc}
                  alt="avatar"
                />
                <p>
                  <em>
                    {translate('authForms.editProfile.avatarImageProvidedBy')}{' '}
                    <Link href="https://gravatar.com" rel="noopener noreferrer" target="_blank">
                      Gravatar
                    </Link>
                    .
                  </em>
                </p>
              </div>
              <div className="edit-profile-component__input">
                <InputComponent
                  defaultValue={currentUser.get('name')}
                  inputRef={this.nameRef}
                  label={translate('authForms.editProfile.inputs.userName.label')}
                  message={{ type: 'error', text: errors.name }}
                  onSend={this.handleSubmitClick}
                />
              </div>
              <TimezoneSelect
                defaultTimezoneName={currentUser.get('timezoneName', null)}
                onChangeTimezoneName={this.changeTimezoneName}
                errors={errors}
              />
              {isOwner && (
                <div className="edit-profile-component__input">
                  <InputComponent
                    defaultValue={currentMarketerTitle}
                    inputRef={this.titleRef}
                    isMandatory
                    label={translate('authForms.editProfile.inputs.businessName.label')}
                    message={{ type: 'error', text: errors.title }}
                    onSend={this.handleSubmitClick}
                  />
                </div>
              )}
              <SubmitButtonComponent
                isBlue
                onClick={this.handleSubmitClick}
                text={translate('authForms.editProfile.buttons.submit')}
              />
              <p className="edit-profile-component__text edit-profile-component__text_with-margin">
                {translate('authForms.cancelAccountPrompt')}&nbsp;
                <span className="text text_link" onClick={this.handleClickDeleteAccount}>
                  {translate('authForms.cancelAccountLinkText')}
                </span>
              </p>
              <p className="edit-profile-component__text edit-profile-component__text_with-margin">
                {translate('authForms.cancelSubscriptionPrompt')}&nbsp;
                <a
                  className="text text_link"
                  href={Routes.subscription_plans_path({ format: null })}
                >
                  {translate('authForms.cancelSubscriptionLinkText')}
                </a>
              </p>
              <p className="edit-profile-component__text edit-profile-component__text_with-margin">
                {translate('authForms.editProfile.contactUs')}&nbsp;
                <Link
                  className="text text_link"
                  href={`mailto:${translate('authForms.editProfile.privacyEmail')}`}
                >
                  {translate('authForms.editProfile.privacyEmail')}
                </Link>
                &nbsp;{translate('authForms.editProfile.contactUsCondition')}
              </p>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ConnectStoreHOC(connect()(CancelFormSubmitDecorator(EditProfileFormComponent)));
