import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoSchedulersStart,
  fetchEoSchedulersSuccess,
  fetchEoSchedulersFailure,
  fetchEoSchedulerStart,
  fetchEoSchedulerSuccess,
  fetchEoSchedulerFailure,
  fetchTimezonesAsOptionsSuccess,
  fetchTimezonesAsOptionsFailure,
  createEoSchedulerStart,
  createEoSchedulerSuccess,
  createEoSchedulerFailure,
  updateEoSchedulerStart,
  updateEoSchedulerSuccess,
  updateEoSchedulerFailure,
  deleteEoSchedulersStart,
  deleteEoSchedulersSuccess,
  deleteEoSchedulersFailure,
} from 'actions/eoSchedulerActions';

import { resetSelectedIds } from 'actions/ui_actions';
import { fetchEoSchedulersPagySuccess } from 'actions/pagy_actions';

import { translate } from 'common/i18n';

import {
  showErrorMessageFromTranslation,
  showSuccessMessage,
  showSuccessMessageFromTranslation,
} from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToCamelCase,
  processObjToSnakeCase,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoSchedulers(dispatch, params) {
  dispatch(fetchEoSchedulersStart());
  const { eoCampaignId } = params;

  try {
    const resp = await getFromAPI(Routes.eo_schedulers_path(eoCampaignId), {
      ...processObjToSnakeCaseDeep(params),
    });
    dispatch(fetchEoSchedulersSuccess(resp.eo_schedulers));
    dispatch(fetchEoSchedulersPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoSchedulersFailure,
      translate('eoScheduler.messages.cantFetchEoSchedulers'),
    );
  }
}

export async function fetchEoScheduler(dispatch, eoCampaignId, eoSchedulerId) {
  dispatch(fetchEoSchedulerStart());

  try {
    const eoScheduler = await getFromAPI(Routes.eo_scheduler_path(eoCampaignId, eoSchedulerId));
    dispatch(fetchEoSchedulerSuccess(eoScheduler));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoSchedulerFailure,
      translate('eoScheduler.messages.cantFetchEoScheduler'),
    );
  }
}

export async function createEoScheduler(dispatch, eoCampaignId, data) {
  dispatch(createEoSchedulerStart());

  try {
    const resp = await postToAPI(Routes.eo_schedulers_path(eoCampaignId), eoSchedulerObject(data));

    if (isEmpty((resp || {}).errors)) {
      dispatch(createEoSchedulerSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoScheduler.messages.createSuccess');
    } else {
      dispatch(createEoSchedulerFailure(resp));
    }
    resp.errors = processObjToCamelCase(resp.errors);

    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createEoSchedulerFailure,
      translate('eoScheduler.messages.cantCreateEoScheduler'),
    );
  }
}

export async function updateEoScheduler(dispatch, eoCampaignId, data) {
  dispatch(updateEoSchedulerStart());

  try {
    const resp = await patchToAPI(
      Routes.eo_scheduler_path(eoCampaignId, data.id),
      eoSchedulerObject(data),
    );

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(updateEoSchedulerSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoScheduler.messages.updateSuccess');
    } else {
      dispatch(updateEoSchedulerFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoSchedulerFailure,
      translate('eoScheduler.messages.cantUpdateEoScheduler'),
    );
  }
}

export async function deleteEoSchedulers(dispatch, eoCampaignId, ids) {
  dispatch(deleteEoSchedulersStart());

  try {
    const resp = await deleteToAPI(Routes.eo_schedulers_path(eoCampaignId, { ids }));
    if (isEmpty((resp || {}).errors)) {
      dispatch(resetSelectedIds());
      dispatch(deleteEoSchedulersSuccess(ids));
      showSuccessMessage(dispatch, translate('eoScheduler.messages.deleteSuccess')(ids.length));
    } else {
      dispatch(deleteEoSchedulersFailure(ids));
      showErrorMessageFromTranslation(dispatch, 'eoScheduler.messages.cantDeleteEoSchedulers');
    }
    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      deleteEoSchedulersFailure,
      translate('eoScheduler.messages.cantDeleteEoSchedulers'),
    );
  }
}

export async function fetchTimezonesAsOptions(dispatch) {
  try {
    const resp = await getFromAPI(Routes.time_zones_as_options_path());
    dispatch(fetchTimezonesAsOptionsSuccess(resp.time_zones));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchTimezonesAsOptionsFailure,
      translate('eoScheduler.messages.cantFetchTimezones'),
    );
  }
}

const eoSchedulerObject = (data) => ({ eo_scheduler: processObjToSnakeCase(data) });
