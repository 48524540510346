import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import TabsComponent, { TabItemComponent } from 'components_linkio/tabs_component';

import './EoCampaignEmailTemplatesTabs.scss';

const eoCampaignStepEmailTabs = ['all', 'private', 'shared', 'default'];

const EoCampaignEmailTemplatesTabsComponent = ({
  selectedEmailTemplateScope,
  setSelectedEmailTemplateId,
  setSelectedEmailTemplateScope,
}) => {
  const activeTab = selectedEmailTemplateScope ?? 'all';

  const handleTabClick = (tab) => () => {
    if (tab === selectedEmailTemplateScope) {
      return;
    }

    setSelectedEmailTemplateId(null);
    setSelectedEmailTemplateScope(tab);
  };

  return (
    <TabsComponent className="eo-campaign-email-templates-tabs">
      {eoCampaignStepEmailTabs.map((tab) => (
        <TabItemComponent
          key={tab}
          index={tab}
          label={translate(
            `eoCampaignStep.eoCampaignStepEmail.eoCampaignEmailTemplatesPopup.tabs.${tab}`,
          )}
          onClick={handleTabClick(tab)}
          options={{ active: tab === activeTab }}
        />
      ))}
    </TabsComponent>
  );
};

EoCampaignEmailTemplatesTabsComponent.propTypes = {
  selectedEmailTemplateScope: PropTypes.string,
  setSelectedEmailTemplateId: PropTypes.func,
  setSelectedEmailTemplateScope: PropTypes.func,
};

export default EoCampaignEmailTemplatesTabsComponent;
