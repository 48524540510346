import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import GoogleIcon from 'common/icons/GoogleIcon';

import './connectGmailEoInboxButton.scss';

const ConnectGmailEoInboxButtonComponent = ({
  allowedEoInboxesCount,
  canAddEoInboxes,
  onClick,
}) => {
  const explanationTooltip = {
    text: translate('eoInboxesSettings.inboxesLimitation')(allowedEoInboxesCount),
    placement: 'top',
    color: 'black',
  };

  const googleIntegrationIsTemporaryDisabledDueToVerificationProcess = true;

  return (
    <ButtonComponent
      className="connect-gmail-button"
      isDisabled={googleIntegrationIsTemporaryDisabledDueToVerificationProcess || !canAddEoInboxes}
      tooltip={
        googleIntegrationIsTemporaryDisabledDueToVerificationProcess && {
          text: 'Gmail OAuth Coming Soon',
          placement: 'top',
          color: 'black',
        }
      }
      isInitial
      onClick={onClick}
      // Enable when gmail integration is ready
      // tooltip={!canAddEoInboxes ? explanationTooltip : {}}
    >
      <GoogleIcon />
      {translate('eoInboxesSettings.buttons.connectGmailEoInbox')}
    </ButtonComponent>
  );
};

ConnectGmailEoInboxButtonComponent.propTypes = {
  allowedEoInboxesCount: PropTypes.number,
  canAddEoInboxes: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ConnectGmailEoInboxButtonComponent;
