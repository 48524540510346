export const FETCH_RAILS_CONTEXT__START = 'FETCH_RAILS_CONTEXT__START';
export const FETCH_RAILS_CONTEXT__SUCCESS = 'FETCH_RAILS_CONTEXT__SUCCESS';
export const FETCH_RAILS_CONTEXT__FAILURE = 'FETCH_RAILS_CONTEXT__FAILURE';

export function fetchRailsContextStart() {
  return { type: FETCH_RAILS_CONTEXT__START };
}

export function fetchRailsContextSuccess(railsContext) {
  return { type: FETCH_RAILS_CONTEXT__SUCCESS, payload: railsContext };
}

export function fetchRailsContextFailure(errors) {
  return { type: FETCH_RAILS_CONTEXT__FAILURE, errors };
}
