import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { ahrefsAbilitiesShape, subscriptionShape } from 'common/prop_types_shapes';

import BackButton from 'components_linkio/BackButton';
import ImportCsvComponent from 'components_linkio/ImportCsv';
import InputComponent from 'components_linkio/input_component';
import MessageComponent from 'components_linkio/message_component';
import RadioButton from 'components_linkio/RadioButton';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import './backlinksSetup.scss';

class BacklinksSetupComponent extends React.Component {
  static propTypes = {
    ahrefsAbilities: ahrefsAbilitiesShape,
    canImportFromMoz: PropTypes.bool,
    canUseAhrefsInSubscription: PropTypes.bool,
    hasAhrefsConnection: PropTypes.bool,
    onClickUpgradeSubscription: PropTypes.func,
    onImportBacklinks: PropTypes.func,
    subscription: subscriptionShape,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      ahrefsAbilities: { isAble: isAbleToImportFromAhrefs },
    } = nextProps;

    if (prevState.importValue === undefined && isAbleToImportFromAhrefs !== undefined) {
      const value = isAbleToImportFromAhrefs ? 'ahrefs' : '';
      return { importValue: value };
    }

    return null;
  }
  constructor(props) {
    super(props);
    const { subscription } = props;

    this.state = {
      csvFile: void 0,
      importMode: 'viaApi',
      importValue: void 0,
      //added to check subscription status
      subscriptionStatus: subscription.get('status'),
      subscriptionType: subscription.get('subscriptionPlanTitle'),
      subscriptionIsPaid: subscription.get('subscriptionPlanIsPaid'),
    };
  }

  handleImportBacklinks = (event) => {
    event.preventDefault();

    this.props.onImportBacklinks(this.state);
  };

  handleImportModeChange = (event) =>
    this.setState({ importMode: event.target.value, importValue: '' });

  handleImportValueChange = (event) => {
    const value = event.target.value;

    this.setState({ importValue: value });

    if (['ahrefs', 'moz'].includes(value)) {
      this.setState({ importMode: 'viaApi' });
    }

    if (value === 'gsc') {
      this.setState({ importMode: 'fromCsv' });
    }
  };

  handleCsvFileUpload = (csvFile) => this.setState({ csvFile });

  render() {
    const {
      csvFile,
      importMode,
      importValue,
      subscriptionStatus,
      subscriptionType,
      subscriptionIsPaid,
    } = this.state;

    const {
      ahrefsAbilities: {
        freeAhrefsSubscription: hasFreeAhrefsSubscription,
        isAble: isAbleToImportFromAhrefs,
        limitReached: isAhrefsLimitReached,
      },
      canImportFromMoz,
      canUseAhrefsInSubscription,
      hasAhrefsConnection,
      onClickUpgradeSubscription,
      subscription,
    } = this.props;
    const isCsvImportType = importMode === 'fromCsv';
    const isManuallyImportType = importMode === 'manually';
    const showAhrefsLimitRibbon = hasAhrefsConnection && !canUseAhrefsInSubscription;
    //make variable to check if trail
    const isTrial = subscriptionStatus === 'trialing';
    const isFree = subscriptionType === 'Free';
    return (
      <div className="backlinks-setup">
        <h1>{translate('backlinksSetup.title')}</h1>
        <form className="backlinks-setup__form">
          <h3>{translate('backlinksSetup.importViaApi.title')}</h3>

          <div className="backlinks-setup__import-type-item">
            {showAhrefsLimitRibbon && (
              <SubscriptionLimitRibbonComponent>
                {translate('subscriptionLimitRibbon.message')(
                  translate('subscriptionLimitRibbon.limited.ahrefs'),
                  true,
                )}
                <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
              </SubscriptionLimitRibbonComponent>
            )}
            <div className="backlinks-setup__ahrefs-import">
              <RadioButton
                checked={importValue === 'ahrefs' && !isFree}
                disabled={!isAbleToImportFromAhrefs || isFree}
                onChange={this.handleImportValueChange}
                value="ahrefs"
              >
                {translate('backlinksSetup.importViaApi.ahrefs.label')}
              </RadioButton>

              {(!hasAhrefsConnection || isTrial || !isFree) && (
                <a href={Routes.automation_settings_path({ format: null })}>
                  {translate('brand.form.connectAhrefsAccountLinkText')}
                </a>
              )}
            </div>
            {hasFreeAhrefsSubscription && (
              <MessageComponent
                message={{
                  type: 'hint',
                  text: translate('backlinksSetup.importViaApi.ahrefs.warningFree'),
                }}
              />
            )}
            {isAhrefsLimitReached && (
              <MessageComponent
                message={{
                  type: 'hint',
                  text: translate('backlinksSetup.importViaApi.ahrefs.warningLimit'),
                }}
              />
            )}
            <MessageComponent
              message={{ type: 'hint', text: translate('backlinksSetup.importViaApi.ahrefs.hint') }}
            />
          </div>

          {/* <div className="backlinks-setup__import-type-item">
            <div className="backlinks-setup__moz-import">
              <RadioButton
                checked={importValue === 'moz'}
                disabled={!canImportFromMoz}
                onChange={this.handleImportValueChange}
                value="moz"
              >
                {translate('backlinksSetup.importViaApi.moz.label')}
              </RadioButton>
            </div>
            <MessageComponent
              message={{ type: 'hint', text: translate('backlinksSetup.importViaApi.moz.hint') }}
            />
          </div> */}

          <h3>{translate('backlinksSetup.importManually.title')}</h3>

          <div className="backlinks-setup__import-type-item">
            <div className="backlinks-setup__csv-import-list">
              <RadioButton
                checked={importValue === 'gsc'}
                onChange={this.handleImportValueChange}
                value="gsc"
              >
                {translate('backlinksSetup.importManually.fromCsv.gsc')}
              </RadioButton>
            </div>

            <ImportCsvComponent
              isDisabled={importValue !== 'gsc'}
              onFileUpload={this.handleCsvFileUpload}
            />
          </div>

          <div className="backlinks-setup__import-type-item">
            <RadioButton
              className="backlinks-setup__import-type-picker"
              checked={isManuallyImportType}
              onChange={this.handleImportModeChange}
              value="manually"
            >
              {translate('backlinksSetup.importManually.manually.label')}
            </RadioButton>

            {importMode === 'manually' && importValue && (
              <span className="backlinks-setup__backlinks-count">
                {translate('backlinksSetup.importManually.manually.textarea.label.backlinksCount')(
                  importValue.split('\n').length,
                )}
              </span>
            )}
            <InputComponent
              className="manually-import-pages__textarea"
              isDisabled={!isManuallyImportType}
              onInputChange={this.handleImportValueChange}
              isMultiline
              label={translate('backlinksSetup.importManually.manually.textarea.label.title')}
              message={{
                type: 'hint',
                text: translate('backlinksSetup.importManually.manually.textarea.hint'),
              }}
              value={importMode === 'manually' ? importValue : ''}
            />
          </div>

          <div className="backlinks-setup__form-buttons-wrapper">
            <BackButton isWhite isInitial>
              {translate('backlinksSetup.buttons.cancel')}
            </BackButton>

            <SubmitButtonComponent
              isDisabled={!importValue || (isCsvImportType && !csvFile)}
              onClick={this.handleImportBacklinks}
              text={translate('backlinksSetup.buttons.submit')}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default BacklinksSetupComponent;
