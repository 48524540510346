const ADD_PAGES_TO_ATP__FAILURE = 'ADD_PAGES_TO_ATP__FAILURE';
const ADD_PAGES_TO_ATP__START = 'ADD_PAGES_TO_ATP__START';
const ADD_PAGES_TO_ATP__SUCCESS = 'ADD_PAGES_TO_ATP__SUCCESS';
const AUTODISCOVER_PAGES_VIA_SITEMAP__FAILURE = 'AUTODISCOVER_PAGES_VIA_SITEMAP__FAILURE';
const AUTODISCOVER_PAGES_VIA_SITEMAP__STARTED = 'AUTODISCOVER_PAGES_VIA_SITEMAP__STARTED';
const AUTODISCOVER_PAGES_VIA_SITEMAP__SUCCESS = 'AUTODISCOVER_PAGES_VIA_SITEMAP__SUCCESS';
const FETCH_BULK_SETUP_BRAND_PAGES__FAILURE = 'FETCH_BULK_SETUP_BRAND_PAGES__FAILURE';
const FETCH_BULK_SETUP_BRAND_PAGES__START = 'FETCH_BULK_SETUP_BRAND_PAGES__START';
const FETCH_BULK_SETUP_BRAND_PAGES__SUCCESS = 'FETCH_BULK_SETUP_BRAND_PAGES__SUCCESS';
const GET_TITLE_TAGS__START = 'GET_TITLE_TAGS__START';
const GET_TITLE_TAGS__SUCCESS = 'GET_TITLE_TAGS__SUCCESS';
const GET_TITLE_TAGS__FAILURE = 'GET_TITLE_TAGS__FAILURE';
const IMPORT_FROM_GSC__FAILURE = 'IMPORT_FROM_GSC__FAILURE';
const IMPORT_FROM_GSC__START = 'IMPORT_FROM_GSC__START';
const IMPORT_FROM_GSC__SUCCESS = 'IMPORT_FROM_GSC__SUCCESS';
const IMPORT_PAGES_MANUALLY__FAILURE = 'IMPORT_PAGES_MANUALLY__FAILURE';
const IMPORT_PAGES_MANUALLY__START = 'IMPORT_PAGES_MANUALLY__START';
const IMPORT_PAGES_MANUALLY__SUCCESS = 'IMPORT_PAGES_MANUALLY__SUCCESS';
const PAGE_ADD_HEAD_DATA__FAILURE = 'PAGE_ADD_HEAD_DATA__FAILURE';
const PAGE_ADD_HEAD_DATA__SUCCESS = 'PAGE_ADD_HEAD_DATA__SUCCESS';
const UPDATE_DRAFT_BRAND_PAGES_ROWS = 'UPDATE_DRAFT_BRAND_PAGES_ROWS';
const UPDATE_DRAFT_BRAND_PAGE__FAILURE = 'UPDATE_DRAFT_BRAND_PAGE__FAILURE';
const UPDATE_DRAFT_BRAND_PAGE__START = 'UPDATE_DRAFT_BRAND_PAGE__START';
const UPDATE_DRAFT_BRAND_PAGE__SUCCESS = 'UPDATE_DRAFT_BRAND_PAGE__SUCCESS';

function addPagesToAtpFailure(pages) {
  return { type: ADD_PAGES_TO_ATP__FAILURE, pages };
}

function addPagesToAtpStart() {
  return { type: ADD_PAGES_TO_ATP__START };
}

function addPagesToAtpSuccess(pages) {
  return { type: ADD_PAGES_TO_ATP__SUCCESS, pages };
}

function autodiscoverPagesViaSitemapFailure(payload) {
  return { type: AUTODISCOVER_PAGES_VIA_SITEMAP__FAILURE, payload };
}

function autodiscoverPagesViaSitemapStarted(payload) {
  return { type: AUTODISCOVER_PAGES_VIA_SITEMAP__STARTED, payload };
}

function fetchBulkSetupBrandPagesFailure(errors) {
  return { type: FETCH_BULK_SETUP_BRAND_PAGES__FAILURE, errors };
}

function autodiscoverPagesViaSitemapSuccess(payload) {
  return { type: AUTODISCOVER_PAGES_VIA_SITEMAP__SUCCESS, payload };
}

function fetchBulkSetupBrandPagesStart() {
  return { type: FETCH_BULK_SETUP_BRAND_PAGES__START };
}

function fetchBulkSetupBrandPagesSuccess(pages) {
  return { type: FETCH_BULK_SETUP_BRAND_PAGES__SUCCESS, pages };
}

function getTitleTagsByUrlStart() {
  return { type: GET_TITLE_TAGS__START };
}

function getTitleTagsByUrlSuccess(title) {
  return { type: GET_TITLE_TAGS__SUCCESS, title };
}

function getTitleTagsByUrlFailure(errors) {
  return { type: GET_TITLE_TAGS__FAILURE, errors };
}

function importPagesManuallyStart() {
  return { type: IMPORT_PAGES_MANUALLY__START };
}

function importFromGSCFailure(payload) {
  return { type: IMPORT_FROM_GSC__FAILURE, payload };
}

function importFromGSCStart(payload) {
  return { type: IMPORT_FROM_GSC__START, payload };
}

function importFromGSCSuccess(payload) {
  return { type: IMPORT_FROM_GSC__SUCCESS, payload };
}

function importPagesManuallyFailure(errors) {
  return { type: IMPORT_PAGES_MANUALLY__FAILURE, errors };
}

function importPagesManuallySuccess(pages) {
  return { type: IMPORT_PAGES_MANUALLY__SUCCESS, pages };
}

function pageAddHeadDataFailure(errors, pageId) {
  return { type: PAGE_ADD_HEAD_DATA__FAILURE, errors, pageId };
}

function pageAddHeadDataSuccess(pageId) {
  return { type: PAGE_ADD_HEAD_DATA__SUCCESS, pageId };
}

function updateDraftBrandPageFailure(errors) {
  return { type: UPDATE_DRAFT_BRAND_PAGE__FAILURE, errors };
}

function updateDraftBrandPageStart() {
  return { type: UPDATE_DRAFT_BRAND_PAGE__START };
}

function updateDraftBrandPageSuccess(page) {
  return { type: UPDATE_DRAFT_BRAND_PAGE__SUCCESS, page };
}

function updateDraftBrandPagesRows(pages) {
  return { type: UPDATE_DRAFT_BRAND_PAGES_ROWS, pages };
}

export {
  ADD_PAGES_TO_ATP__FAILURE,
  ADD_PAGES_TO_ATP__START,
  ADD_PAGES_TO_ATP__SUCCESS,
  AUTODISCOVER_PAGES_VIA_SITEMAP__FAILURE,
  AUTODISCOVER_PAGES_VIA_SITEMAP__STARTED,
  AUTODISCOVER_PAGES_VIA_SITEMAP__SUCCESS,
  FETCH_BULK_SETUP_BRAND_PAGES__FAILURE,
  FETCH_BULK_SETUP_BRAND_PAGES__START,
  FETCH_BULK_SETUP_BRAND_PAGES__SUCCESS,
  IMPORT_FROM_GSC__FAILURE,
  IMPORT_FROM_GSC__START,
  IMPORT_FROM_GSC__SUCCESS,
  IMPORT_PAGES_MANUALLY__FAILURE,
  IMPORT_PAGES_MANUALLY__START,
  IMPORT_PAGES_MANUALLY__SUCCESS,
  UPDATE_DRAFT_BRAND_PAGES_ROWS,
  UPDATE_DRAFT_BRAND_PAGE__FAILURE,
  UPDATE_DRAFT_BRAND_PAGE__START,
  UPDATE_DRAFT_BRAND_PAGE__SUCCESS,
  addPagesToAtpFailure,
  addPagesToAtpStart,
  addPagesToAtpSuccess,
  autodiscoverPagesViaSitemapFailure,
  autodiscoverPagesViaSitemapSuccess,
  autodiscoverPagesViaSitemapStarted,
  fetchBulkSetupBrandPagesFailure,
  fetchBulkSetupBrandPagesStart,
  fetchBulkSetupBrandPagesSuccess,
  getTitleTagsByUrlStart,
  getTitleTagsByUrlSuccess,
  getTitleTagsByUrlFailure,
  importFromGSCFailure,
  importFromGSCStart,
  importFromGSCSuccess,
  importPagesManuallyFailure,
  importPagesManuallyStart,
  importPagesManuallySuccess,
  pageAddHeadDataFailure,
  pageAddHeadDataSuccess,
  updateDraftBrandPageFailure,
  updateDraftBrandPageStart,
  updateDraftBrandPageSuccess,
  updateDraftBrandPagesRows,
};
