import pluralize from 'pluralize';

export default {
  changesHistory: {
    breadcrumbsTitle: 'Backlinks',
    buttons: {
      addBacklinks: 'Add Backlinks',
      exportToCsv: 'Export to CSV',
    },
    table: {
      changesHistoryCount: (changesHistoryCount) => {
        return `${pluralize('Backlink', changesHistoryCount, true)}`;
      },
      headers: {
        anchorText: 'Anchor Text',
        datesColumn: {
          firstSeen: 'First Seen',
          lastChecked: 'Last Checked',
        },
        publishedLink: 'Backlink',
        changes: 'Changes',
      },
      blankState: {
        noChangesBackLinks: {
          title: 'No Changes',
          body: `No recent backlink changes found`,
        },
      },
      typeOfChanges: {
        follow: {
          true: 'Nofollow',
          false: 'Follow',
        },
        indexed: {
          true: 'Indexed',
          false: 'Not Indexed',
        },
        crawled: {
          true: 'Found',
          false: 'Not Found',
        },
      },
    },
    title: 'Backlink Module',
  },
};
