import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'lodash';

import EoCampaignOptionsComponent from './EoCampaignOptionsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector, optionsSelector } from 'selectors';
import { deleteEoCampaigns } from 'api/eoCampaign';
import { eoCampaignOptionsSelector } from 'selectors/eoCampaignOptionsSelector';
import { eoCampaignSelector } from 'selectors/eoCampaignsSelector';
import { eoCampaignShape } from 'common/propTypesShapes/eoCampaigns';
import { fetchEoActiveHoursAsOptions } from 'api/eoActiveHour';
import { fetchSimpleBrands } from 'api/simpleBrand';
import { fetchUsersAsOptions } from 'api/user';
import { optionsList } from 'common/prop_types_shapes';
import { policiesSelector } from 'selectors/railsContextSelectors';
import { updateEoCampaignOptions } from 'api/eoCampaignOptions';

import { eoCampaignOptionsShape } from 'common/propTypesShapes/eoCampaignOptions';
import { translate } from 'common/i18n';

import confirmationDialogue from 'components/confirmation_dialogue';

const EoCampaignOptionsContainer = ({
  brandsOptions,
  canCreate,
  canUpdate,
  dispatch,
  history,
  eoActiveHourOptions,
  eoCampaignId,
  eoCampaign,
  eoCampaignOptions,
  usersOptions,
}) => {
  React.useEffect(() => {
    fetchEoActiveHoursAsOptions(dispatch);
    fetchSimpleBrands(dispatch);
    fetchUsersAsOptions(dispatch);
  }, []);

  async function handleUpdateSettings(updatedSettings) {
    const isTitleChanged = eoCampaignOptions.get('title') !== updatedSettings.title;

    let updatedEoCampaign = {};
    isTitleChanged && (updatedEoCampaign = eoCampaign.set('title', updatedSettings.title).toJS());

    const resp = await updateEoCampaignOptions(
      dispatch,
      eoCampaignId,
      updatedSettings,
      isTitleChanged,
      updatedEoCampaign,
    );

    return resp;
  }

  async function handleDeleteCampaign() {
    confirmationDialogue({
      body: translate('confirmations.default.title'),
      onConfirm: async () =>
        await deleteEoCampaigns(dispatch, [eoCampaignId]).then(() => {
          history.push(Routes.eo_campaigns_path({ format: null }));
        }),
    });
  }

  return (
    <>
      <EoCampaignOptionsComponent
        immutableBrandsOptions={brandsOptions}
        canCreate={canCreate}
        canUpdate={canUpdate}
        key={eoCampaignOptions.get('id')}
        immutableEoActiveHourOptions={eoActiveHourOptions}
        immutableUsersOptions={usersOptions}
        eoCampaignOptions={eoCampaignOptions}
        onDeleteCampaign={handleDeleteCampaign}
        onUpdateSettings={handleUpdateSettings}
      />
    </>
  );
};

EoCampaignOptionsContainer.propTypes = {
  brandsOptions: optionsList,
  canCreate: PropTypes.bool,
  canUpdate: PropTypes.bool,
  dispatch: PropTypes.func,
  eoActiveHourOptions: optionsList,
  eoCampaign: eoCampaignShape,
  eoCampaignId: PropTypes.string,
  eoCampaignOptions: eoCampaignOptionsShape,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  usersOptions: optionsList,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoCampaign = eoCampaignSelector(state, ownProps);
  const eoCampaignOptions = eoCampaignOptionsSelector(state);

  const options = optionsSelector(state);
  const brandsOptions = options.get('brandsOptions');
  const eoActiveHourOptions = options.get('eoActiveHourOptions');
  const usersOptions = options.get('usersOptions');

  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);
  const canUpdate = policies.getIn(['emailOutreach', 'canUpdate']);

  return {
    brandsOptions,
    canCreate,
    canUpdate,
    eoActiveHourOptions,
    eoCampaign,
    eoCampaignId,
    eoCampaignOptions,
    usersOptions,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(EoCampaignOptionsContainer);
