const prompts = {
  prompts: {
    oops: "Oops.",
    hey: 'Hey!',
    eoOnboarding: {
      title: 'Email Outreach',
      bodyText: {
        title: 'Run Automated Email Outreach Campaigns',
        tips: {
          addInbox: 'Add an Inbox',
          createDripList: 'Create a Drip List',
          addProspectsToDripList: 'Add Prospects to Drip List',
          launchCampaign: 'Launch a Campaign',
        },
      },
      sidebar: {
        prospects: 'Prospects',
        eoCampaigns: 'Campaigns',
        eoSettings: 'Settings',
      },
    },
    noProxies: {
      titleText: "No proxies in system.",
      bodyText: "Please, add new proxies.",
    },
    chooseExistingMarketer: {
      linkText: "Click here",
      text: "to choose an existing account.",
    },
    noAhrefsConnection: {
      titleText: " You are not connected to Ahrefs.",
      bodyText: "To use data from Ahrefs please connect your Linkio account to your Ahrefs account.",
    },
    noBrands: {
      title: "SEO Monitoring And Analysis",
      bodyText: {
        title: 'Analyze and Monitor Your SEO',
        subtitle: 'Helpful modules to plan and monitor your campaigns:',
        modulesList: {
          rankTracker: 'Rank Tracker',
          backlinks: 'Backlink Monitoring',
          atp: 'Anchor Text Planner',
        },
      },
      buttons: {
        addBrand: 'Add your first brand',
      },
    },
    noTargetPage: {
      text: "You haven't added any target pages for this brand yet. Click here to ",
      linkText: "add more pages",
    },
    targetDifferentPage: {
      text: "Want to target a different page? Click here to ",
      linkText: "add more pages",
    },
    messages: {
      cantFetchOnboardingStatus: "Can't load onboarding status",
    },
  },
};

export default prompts;
