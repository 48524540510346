import { fetchPages } from 'api/brand_page';

const DEFAULT_PAGY_ITEMS_COUNT = 10;

async function smartFetchPagesService(dispatch, pageParentType, pageParentId, fetchingParams = {}) {
  const { pageNumber, pagyItemsCount, search = '' } = fetchingParams;

  const options = {
    items: pagyItemsCount || DEFAULT_PAGY_ITEMS_COUNT,
    page: pageNumber || 1,
    search,
  };

  return await fetchPages(dispatch, pageParentType, pageParentId, options);
}

export default smartFetchPagesService;
