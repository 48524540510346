import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Map as iMap } from 'immutable';

import StatusTable from './StatusTable';
import DuplicatesTable from './DuplicatesTable';

import { translate } from 'common/i18n';

import { generateDestinationLinkStatusRows } from 'common/tables/utils';

import crawlersStatusService from 'pages/Pages/PagesList/PageItem/PageDls/services/crawlersStatusService';

import { destinationLinkShape, subscriptionPoliciesShape } from 'common/prop_types_shapes';

import ExternalLinkIcon from 'common/icons/external_link';
import WorldIcon from 'common/icons/world_icon';
import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';

import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import { crawlingInProgress } from 'pages/Pages/utils.js';
import Link from 'components_linkio/link';

import './dlDetailsPopup.scss';

const DlDetailsPopupComponent = ({
  destinationLink,
  onClickUpgradeSubscription,
  onCloseClick,
  onCrawlNowClick,
  onPublishedLinkChange,
  onSaveClick,
  onShowDuplicateLinksClick,
  publishedLink,
  showDuplicatesTable,
  subscriptionPolicies,
}) => {
  const crawlersStatus = crawlersStatusService(destinationLink);
  const needToShowSpinner = crawlingInProgress(crawlersStatus.status);
  const lastCheck = crawlersStatus.lastCheck;

  const canUseCrawlers = subscriptionPolicies.getIn(['crawlers', 'canUse'], false);
  const dlStatusRows = generateDestinationLinkStatusRows(destinationLink, canUseCrawlers);
  const publishedLinkMessage =
    destinationLink.get('errors', iMap()).size > 0
      ? {
          text: destinationLink.getIn(['errors', 'publishedLink']),
          type: 'error',
        }
      : void 0;
  const hasPublishedLink = !!publishedLink;
  const destinationLinkHasErrors = destinationLink.get('errors', iMap()).size > 0;
  const crawlNowBtnIsDisabled = !canUseCrawlers || destinationLinkHasErrors || !hasPublishedLink;

  const publishedLinkRowClasses = classnames({
    'dl-details-popup-component__published-link': true,
    'dl-details-popup-component__published-link_with-error': publishedLinkMessage,
  });

  const externalLinkIconClasses = classnames({
    'dl-details-popup-component__external-link-icon': true,
    'dl-details-popup-component__external-link-icon_with-error': publishedLinkMessage,
  });

  const worldIconClasses = classnames({
    'dl-details-popup-component__world-icon': true,
    'dl-details-popup-component__world-icon_with-error': publishedLinkMessage,
  });

  const subscriptionLimitRibbonMessage = translate('subscriptionLimitRibbon.message')(
    translate('subscriptionLimitRibbon.limited.crawling'),
  );

  return (
    <Modal show className="dl-details-popup-component__modal">
      <Modal.Header>
        <Modal.Title>{translate('dlDetailsPopup.title')}</Modal.Title>
        <div className={publishedLinkRowClasses}>
          <span className={worldIconClasses}>
            <WorldIcon />
          </span>
          <InputComponent
            message={publishedLinkMessage}
            onInputChange={onPublishedLinkChange}
            value={publishedLink}
          />
          <Link
            className={externalLinkIconClasses}
            href={destinationLink.get('publishedLink')}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <ExternalLinkIcon />
          </Link>
          <ButtonComponent
            isBlue
            onClick={onCrawlNowClick}
            isDisabled={crawlNowBtnIsDisabled}
            isLoading={needToShowSpinner}
          >
            {translate('dlDetailsPopup.buttons.crawlNow')}
          </ButtonComponent>
        </div>
        <div className="dl-details-popup-component__status-report">
          {translate('dlDetailsPopup.statusReport')}
          <div className="dl-details-popup-component__last-check-text">
            {translate('dlDetailsPopup.lastCheck')}
            &nbsp;&nbsp;
            {lastCheck ? new Date(lastCheck).toLocaleString() : '-'}
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        {!canUseCrawlers && (
          <SubscriptionLimitRibbonComponent>
            {subscriptionLimitRibbonMessage}
            <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
          </SubscriptionLimitRibbonComponent>
        )}
        <StatusTable
          rows={dlStatusRows}
          onShowDuplicateLinksClick={onShowDuplicateLinksClick}
          onClickUpgradeSubscription={onClickUpgradeSubscription}
        />
        {showDuplicatesTable && (
          <div>
            <div className="dl-details-popup-component__duplicate-table-title">
              {translate('dlDetailsPopup.tables.duplicates.title')}
            </div>
            <DuplicatesTable />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="dl-details-popup-component__button">
          <ButtonComponent isWhite onClick={onCloseClick}>
            {translate('dlDetailsPopup.buttons.close')}
          </ButtonComponent>
        </div>
        <div className="dl-details-popup-component__button">
          <ButtonComponent isGreen onClick={onSaveClick}>
            {translate('dlDetailsPopup.buttons.save')}
          </ButtonComponent>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

DlDetailsPopupComponent.propTypes = {
  destinationLink: destinationLinkShape,
  onClickUpgradeSubscription: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onCrawlNowClick: PropTypes.func.isRequired,
  onPublishedLinkChange: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onShowDuplicateLinksClick: PropTypes.func.isRequired,
  publishedLink: PropTypes.string,
  showDuplicatesTable: PropTypes.bool,
  subscriptionPolicies: subscriptionPoliciesShape,
};

export default DlDetailsPopupComponent;
