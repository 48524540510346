import React from 'react';
import PropTypes from 'prop-types';
import { isEqual, subWeeks, subMonths, subYears } from 'date-fns';
import { reduce } from 'lodash';

import CalendarPresetsPopupComponent from './CalendarPresetsPopupComponent';

import Portal from 'components_linkio/Portal';

import useAlign from 'hooks/useAlign';
import useClickOutsideCb from 'hooks/useClickOutsideCb';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

const START_DATE_FOR_PRESETS = {
  Default: (dateEnd) => subWeeks(dateEnd, 1),
  'Default Month': (dateEnd) => subMonths(dateEnd, 1),
  '3 Months': (dateEnd) => subMonths(dateEnd, 3),
  '6 Months': (dateEnd) => subMonths(dateEnd, 6),
  Year: (dateEnd) => subYears(dateEnd, 1),
};

const takePresetName = (dateStart, dateEnd) => {
  return (
    reduce(
      START_DATE_FOR_PRESETS,
      (presetTitle, getStartDate, title) => {
        if (presetTitle) {
          return presetTitle;
        }

        if (isEqual(getStartDate(dateEnd), dateStart)) {
          return title;
        }

        return presetTitle;
      },
      null,
    ) || 'Custom'
  );
};

const CalendarPresetsPopupContainer = ({
  anchorComponentRef,
  align,
  hide,
  changeDateRange,
  ...props
}) => {
  const ref = React.useRef(null);
  useClickOutsideCb(ref, hide);

  const [activePreset, setActivePreset] = React.useState(
    takePresetName(props.dateStart, props.dateEnd),
  );
  const positionStyle = useAlign(anchorComponentRef, ref, align, activePreset);

  const [calendarRange, setCalendarRange] = React.useState([props.dateStart, props.dateEnd]);
  const [dateStart, dateEnd] = calendarRange;
  React.useEffect(() => setActivePreset(takePresetName(dateStart, dateEnd)), [dateStart, dateEnd]);

  const onClickApply = () => {
    changeDateRange(...calendarRange);
  };

  const onClickClear = () => {
    setCalendarRange([props.dateStart, props.dateEnd]);
  };

  const onClickCancel = () => {
    hide();
  };

  const applyPreset = (presetName) => () => {
    setCalendarRange([START_DATE_FOR_PRESETS[presetName](new Date()), new Date()]);
  };
  const setActivePresetCustom = () => setActivePreset('Custom');

  return (
    <Portal>
      <CalendarPresetsPopupComponent
        align={align}
        componentRef={ref}
        activePreset={activePreset}
        positionStyle={positionStyle}
        applyPreset={applyPreset}
        setActivePresetCustom={setActivePresetCustom}
        setCalendarRange={setCalendarRange}
        onClickApply={onClickApply}
        onClickClear={onClickClear}
        onClickCancel={onClickCancel}
        dateStart={dateStart}
        dateEnd={dateEnd}
        defaultPeriod={props.defaultPeriod || 'Default'}
      />
    </Portal>
  );
};

CalendarPresetsPopupContainer.propTypes = {
  ...withQueryParamsPropTypes,
  align: PropTypes.shape(),
  anchorComponentRef: PropTypes.shape({ current: PropTypes.any }),
  hide: PropTypes.func,
};

export default withQueryParams(CalendarPresetsPopupContainer);
