import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EoCampaignTestComponent from './EoCampaignTestComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector } from 'selectors';
import {
  eoCampaignStepsList,
  eoCampaignStepsOptions,
} from 'common/propTypesShapes/eoCampaignSteps';
import {
  eoCampaignStepsPreviewSelector,
  eoCampaignStepsPreviewOptionsSelector,
} from 'selectors/eoCampaignStepsPreviewSelector';
import { eoInboxesList } from 'common/propTypesShapes/eoInboxesSettings';
import { eoInboxesSelector } from 'selectors/eoInboxesSettingsSelector';
import { fetchEoCampaignInboxes } from 'api/eoInbox';
import { fetchEoCampaignStepsPreview, sendTestEmail } from 'api/eoCampaignPreview';
import {
  changeEoCampaignStepsPreviewOptions,
  resetEoCampaignStepsPreviewOptions,
} from 'actions/eoCampaignPreviewActions';

const EoCampaignTestContainer = ({
  dispatch,
  eoCampaignId,
  eoCampaignStepsPreview,
  eoInboxes,
  eoCampaignStepsPreviewOptions,
}) => {
  const { eoInboxId, prospect } = eoCampaignStepsPreviewOptions.toJS();

  const firstUpdate = React.useRef(true);

  React.useEffect(() => {
    fetchEoCampaignInboxes(dispatch, eoCampaignId).then((eoInboxes) => {
      const eoInboxesBelongToCurrentCampaign = eoInboxes.filter(
        (eoInbox) => eoInbox.belongs_to_current_eo_campaign,
      );
      const hasSingleEoInbox =
        eoInboxesBelongToCurrentCampaign.length === 1 &&
        eoInboxId !== eoInboxesBelongToCurrentCampaign[0].id;

      hasSingleEoInbox &&
        dispatch(
          changeEoCampaignStepsPreviewOptions('eoInboxId', eoInboxesBelongToCurrentCampaign[0].id),
        );
    });
  }, [dispatch, eoCampaignId]);

  React.useEffect(() => {
    if (eoInboxId && prospect) {
      fetchEoCampaignStepsPreview(dispatch, eoCampaignId, { eoInboxId, prospectId: prospect.id });
    }
  }, [dispatch, eoInboxId, prospect.id]);

  // We don't need resetting at the moment of component mounting because switching
  // between tabs should keep the inbox and prospect
  React.useEffect(() => {
    if (!firstUpdate.current) {
      dispatch(resetEoCampaignStepsPreviewOptions());
      fetchEoCampaignStepsPreview(dispatch, eoCampaignId, { eoInboxId: null, prospectId: null });
    }
  }, [dispatch, eoCampaignId]);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  }, []);

  function onSendEmails(eoInboxId, prospectId) {
    sendTestEmail(dispatch, eoCampaignId, eoInboxId, prospectId);
  }

  function handleChangePreviewOptions(eoInboxId, prospectId) {
    fetchEoCampaignStepsPreview(dispatch, eoCampaignId, { eoInboxId, prospectId });
  }

  function handleChangePreviewOptionsGlobal(entityName, entity) {
    dispatch(changeEoCampaignStepsPreviewOptions(entityName, entity));
  }

  const hasTransformationError = eoCampaignStepsPreview.some((step) =>
    step.get('transformationError'),
  );

  return (
    <EoCampaignTestComponent
      selectedEoInboxId={eoInboxId}
      selectedProspect={prospect}
      eoCampaignId={eoCampaignId}
      hasTransformationError={hasTransformationError}
      immutableEoInboxes={eoInboxes}
      onChangePreviewOptions={handleChangePreviewOptions}
      onSendEmails={onSendEmails}
      handleChangePreviewOptionsGlobal={handleChangePreviewOptionsGlobal}
      dispatch={dispatch}
    />
  );
};

EoCampaignTestContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoCampaignStepsPreview: eoCampaignStepsList,
  eoCampaignStepsPreviewOptions: eoCampaignStepsOptions,
  eoInboxes: eoInboxesList,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoCampaignStepsPreview = eoCampaignStepsPreviewSelector(state);
  const eoInboxes = eoInboxesSelector(state);
  const eoCampaignStepsPreviewOptions = eoCampaignStepsPreviewOptionsSelector(state);

  return {
    eoCampaignId,
    eoCampaignStepsPreview,
    eoInboxes,
    eoCampaignStepsPreviewOptions,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(EoCampaignTestContainer)));
