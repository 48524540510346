const FETCH_BRAND_DASHBOARD_DATA__START = 'FETCH_BRAND_DASHBOARD_DATA__START';
const FETCH_BRAND_DASHBOARD_DATA__SUCCESS = 'FETCH_BRAND_DASHBOARD_DATA__SUCCESS';
const FETCH_BRAND_DASHBOARD_DATA__FAILURE = 'FETCH_BRAND_DASHBOARD_DATA__FAILURE';
const ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_FAILURE= 'ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_FAILURE';
const ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_START = 'ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_START';
const ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_SUCCESS = 'ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_SUCCESS';


function fetchBrandDashboardDataStart() {
  return { type: FETCH_BRAND_DASHBOARD_DATA__START };
}

function fetchBrandDashboardDataSuccess(data) {
  return { type: FETCH_BRAND_DASHBOARD_DATA__SUCCESS, data };
}

function fetchBrandDashboardDataFailure(errors) {
  return { type: FETCH_BRAND_DASHBOARD_DATA__FAILURE, errors };
}
function enqueProcessGenerateBrandMetricsStart(brandId){
  return { type: ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_START, brandId};
}
function enqueProcessGenerateBrandMetricsFailure(brandId){
  return { type: ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_FAILURE, brandId};
}
function enqueProcessGenerateBrandMetricsSuccess(brandId){
  return { type: ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_SUCCESS, brandId};
}
export {
  FETCH_BRAND_DASHBOARD_DATA__START,
  FETCH_BRAND_DASHBOARD_DATA__SUCCESS,
  FETCH_BRAND_DASHBOARD_DATA__FAILURE,
  ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_FAILURE,
  ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_START,
  ENQUEUE_PROCESS_GENERATE_BRAND_METRICS_SUCCESS,
  fetchBrandDashboardDataStart,
  fetchBrandDashboardDataSuccess,
  fetchBrandDashboardDataFailure,
  enqueProcessGenerateBrandMetricsStart,
  enqueProcessGenerateBrandMetricsFailure,
  enqueProcessGenerateBrandMetricsSuccess
};
