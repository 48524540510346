// Need to use snake_case because we use these constants in URL path
const ATP_TABLE_TABS = [
  'backlinks',
  'suggestions',
  'in_progress',
  'competitors',
  'bulk_competitors_setup',
];

const ATP_TABLE_TABS_FILTERS = {
  backlinks: { statusEq: 'published' },
  suggestions: { statusEq: 'not_started' },
  in_progress: { statusEq: 'started' },
};

const ATP_TABLE_TABS_COLUMNS = {
  backlinks: [
    'selected',
    'publishedLink',
    'anchorType',
    'anchorText',
    'publishedDate',
  ],
  suggestions: [
    'selected',
    'priority',
    'anchorType',
    'anchorText',
    'status',
    'targetWebsite',
    'publishedLink',
    'publishedDate',
    'source',
  ],
  in_progress: [
    'selected',
    'priority',
    'anchorType',
    'anchorText',
    'status',
    'targetWebsite',
    'publishedLink',
    'publishedDate',
    'source',
  ],
  pageDashboard: [
    'priority',
    'anchorType',
    'anchorText',
    'status',
  ],
};

const ATP_TABLE_TABS_BULK_ACTIONS = {
  backlinks: [
    'filterDls',
    'setValue',
    'groupDls',
    'destroy',
    'categorize',
    'crawlPage',
    'import',
    'progressPane',
  ],
  suggestions: [
    'filterDls',
    'setValue',
    'addSuggestions',
    'progressPane',
  ],
  in_progress: [
    'filterDls',
    'setValue',
    'destroy',
    'categorize',
    'progressPane',
  ],
  competitors: [
    'setValue',
    'groupDls',
    'destroy',
    'categorize',
    'crawlPage',
    'import',
    'progressPane',
  ],
};

export {
  ATP_TABLE_TABS,
  ATP_TABLE_TABS_BULK_ACTIONS,
  ATP_TABLE_TABS_COLUMNS,
  ATP_TABLE_TABS_FILTERS,
};
