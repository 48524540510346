import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import { translate } from 'common/i18n';

import Link from 'components_linkio/link';

import './importFromAhrefs.scss';

const ImportFromAhrefsComponent = ({ latestAhrefsImportAt, isDisabled, hasAhrefsConnection }) => {
  const latestImportInfo = () => {
    if (latestAhrefsImportAt) {
      return (
        <span>
          {translate('importToBrandPage.latestImport')}
          {moment(latestAhrefsImportAt).format('MM/DD/YYYY')}
        </span>
      );
    }

    return <span>{translate('importToBrandPage.dateOfLatestImportIsUnknown')}</span>;
  };

  const connectAhrefsAccountLink = () => {
    return (
      <Link href={Routes.automation_settings_path({ format: null })}>
        {translate('importToBrandPage.connectAhrefsAccountLinkText')}
      </Link>
    );
  };

  const wrapperClasses = classnames({
    'import-from-ahrefs-component': true,
    'import-from-ahrefs-component_is-disabled': isDisabled,
  });

  return (
    <div className={wrapperClasses}>
      {hasAhrefsConnection ? latestImportInfo() : connectAhrefsAccountLink()}
    </div>
  );
};

ImportFromAhrefsComponent.propTypes = {
  hasAhrefsConnection: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  latestAhrefsImportAt: PropTypes.string,
};

export default ImportFromAhrefsComponent;
