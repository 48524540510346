import {
  fetchRailsContextStart,
  fetchRailsContextSuccess,
  fetchRailsContextFailure,
} from '../actions/rails_context_actions';

import { translate } from '../common/i18n';

import {
  handleAPIError,
  getFromAPI,
} from './utils';

export async function fetchRailsContext(dispatch) {
  dispatch(fetchRailsContextStart());

  try {
    const resp = await getFromAPI(
      Routes.front_end_context_path()
    );
    dispatch(fetchRailsContextSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, fetchRailsContextFailure, translate('errors.cantFetchRailsContext'));
  }
}
