import React from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import NewProxiesInputComponent from './NewProxiesInputComponent';
import ProxiesQuickStatusComponent from './ProxiesQuickStatusComponent';
import ProxiesTableComponent from './ProxiesTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchProxies, deleteProxy, checkAllProxies, createProxies } from 'api/proxy';
import { policiesShape, proxiesMap } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

class ProxiesPageContainer extends React.PureComponent {
  static propTypes = {
    checkAllProxiesInProgress: PropTypes.bool,
    dispatch: PropTypes.func,
    policies: policiesShape.isRequired,
    proxies: proxiesMap,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchProxies(dispatch);
  }

  handleDeleteProxy = (ids) => deleteProxy(this.props.dispatch, ids);

  handleCheckNowClick = () => checkAllProxies(this.props.dispatch);

  handleNewProxiesClick = (proxies) => createProxies(this.props.dispatch, proxies);

  render() {
    const { proxies, policies, checkAllProxiesInProgress } = this.props;

    const haveProxies = proxies.size > 0;
    const canCreate = policies.getIn(['proxy', 'canCreate'], false);

    return (
      <div className="page__wrapper">
        {!haveProxies && (
          <BlankStateCtaComponent>
            <TitleText>
              <strong>{translate('prompts.oops')} </strong>
              {translate('prompts.noProxies.titleText')}
            </TitleText>
            <BodyText>{translate('prompts.noProxies.bodyText')}</BodyText>
          </BlankStateCtaComponent>
        )}
        {haveProxies && (
          <>
            <ProxiesQuickStatusComponent proxies={proxies} />
            <ProxiesTableComponent
              checkAllProxiesInProgress={checkAllProxiesInProgress}
              onCheckNowClick={this.handleCheckNowClick}
              onDeleteProxy={this.handleDeleteProxy}
              policies={policies}
              proxies={proxies}
            />
          </>
        )}
        {canCreate && <NewProxiesInputComponent onNewProxiesClick={this.handleNewProxiesClick} />}
      </div>
    );
  }
}

function select(state) {
  const policies = state.getIn(['railsContext', 'policies']);
  const proxies = state.get('proxies', iMap());
  const checkAllProxiesInProgress = state.getIn(['ui', 'checkAllProxiesInProgress'], false);

  return { policies, proxies, checkAllProxiesInProgress };
}

export default compose(ConnectStoreHOC, connect(select))(ProxiesPageContainer);
