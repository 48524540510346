import pluralize from 'pluralize';

import { buildWordInPastTenseWithPluralization } from 'common/utils';

export default {
  eoScheduler: {
    breadcrumbsTitle: 'Scheduler',
    bulkActions: {
      addEoScheduler: 'Add Trigger',
    },
    messages: {
      cantFetchEoSchedulers: "Can't load triggers",
      cantFetchEoScheduler: "Can't load trigger",
      cantFetchTimezones: "Can't load timezones",
      cantCreateEoScheduler: "Can't create trigger",
      cantUpdateEoScheduler: "Can't update trigger",
      cantDeleteEoSchedulers: "Can't delete trigger(s)",
      createSuccess: 'Trigger was created successfully.',
      deleteSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization('Trigger', count)} deleted successfully.`;
      },
      updateSuccess: 'Trigger was updated successfully.',
    },
    eoSchedulerFormPopup: {
      buttons: {
        cancel: 'Cancel',
        submit: 'Save',
      },
      inputs: {
        prospectsCount: {
          label: 'How many prospects?',
        },
        eoDripList: {
          label: '...from which Drip List?',
          placeholder: 'Select Drip List...',
        },
        weekdays: {
          label: 'Which day(s)?',
        },
        daytime: {
          label: 'What time?',
        },
      },
      title: (isExistingEoScheduler) => (isExistingEoScheduler ? 'Update Trigger' : 'Add Trigger'),
    },
    table: {
      blankState: {
        body: "You can add Trigger by clicking on 'Add Trigger' button",
        title: "You don't have any Trigger yet",
      },
      headers: {
        prospectsCount: 'Number of prospects',
        eoDripListTitle: 'From drip list',
        timezone: 'Timezone',
        weekdays: 'Days',
        daytime: 'Time',
      },
      eoSchedulersCount: (count) => `${pluralize('Trigger', count, true)}`,
    },
  },
};
