import React from 'react';

import calculateAveragesService from '../services/calculateAveragesService';

import MetricsAnalysisTableComponent from './MetricsAnalysisTableComponent';

import { brandPageShape, brandPagesMap } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

const MetricsAnalysisContainer = ({ competitorPages, myBrandPage }) => {
  function buildAverages(competitorPages) {
    const metricKeys = [
      'liveReferringDomains',
      'dfAndIndexedReferringDomains',
      'nfReferringDomains',
      'nfAndIndexedReferringDomains',
      'notIndexedReferringDomains',
      'uriAhrefsRating',
      'linkioScore',
      'domainRefdomainsCount',
      'homepageRefdomainsCount',
      'domainAhrefsRating',
    ];

    return calculateAveragesService(competitorPages, metricKeys, 'metricsAnalysis');
  }

  return (
    <>
      <div className="competitors-table__title competitors-table__title_standalone">
        {translate('metricsAnalysis.table.title')}
      </div>
      <MetricsAnalysisTableComponent
        averages={buildAverages(competitorPages)}
        brandPage={myBrandPage}
        competitors={competitorPages}
      />
    </>
  );
};

MetricsAnalysisContainer.propTypes = {
  competitorPages: brandPagesMap,
  myBrandPage: brandPageShape,
};

export default MetricsAnalysisContainer;
