export const FETCH_BRANDS_CHART_METRICS__START = 'FETCH_BRANDS_CHART_METRICS__START';
export const FETCH_BRANDS_CHART_METRICS__SUCCESS = 'FETCH_BRANDS_CHART_METRICS__SUCCESS';
export const FETCH_BRANDS_CHART_METRICS__FAILURE = 'FETCH_BRANDS_CHART_METRICS__FAILURE';

export function fetchBrandsChartMetricsStart() {
  return { type: FETCH_BRANDS_CHART_METRICS__START };
}

export function fetchBrandsChartMetricsSuccess(data) {
  return { type: FETCH_BRANDS_CHART_METRICS__SUCCESS, data };
}

export function fetchBrandsChartMetricsFailure(errors) {
  return { type: FETCH_BRANDS_CHART_METRICS__FAILURE, errors };
}
