import Rails from '@rails/ujs';
import ReactOnRails from 'react-on-rails';

import LeftColumnSignInUpComponent from '../components/auth/left_column_sign_in_up_component';

import App from '../startup/app';
import ReduxStore from '../startup/redux_store';

ReactOnRails.register({
  LeftColumnSignInUpComponent,
  App,
});

Rails.start();

ReactOnRails.registerStore({
  ReduxStore,
});
