import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames'
import AddSuggestionsInput from './AddSuggestionsInput';
import AnchorProgressPaneButton from './AnchorProgressPaneButton';
import CategorizeDlsButton from './CategorizeDlsButton';
import CrawlPageDlsButton from './CrawlPageDlsButton';
import DeleteDlsButton from './DeleteDlsButton';
import GroupDlsButtons from './GroupDlsButtons';
import ImportDlsButton from './ImportDlsButton';
import SetValueInputs from './SetValueInputs';

import { atpDlsFilterSpec, defaultAtpDlsFilter } from 'utils/dlsFilterSpec';
import { optionsArray } from 'common/prop_types_shapes';

import DataFilter from 'components_linkio/DataFilter';

import './bulkDlsActions.scss';

const BulkDlsActionsComponent = ({
  actions,
  anchorTypeOptions,
  pageChildType,
  pageParentId,
  pageParentType,
  positionBottom,
}) => {
  function brandPageActionsPresent() {
    return actions.some((action) => ['categorize', 'crawlPage', 'import'].includes(action));
  }

  const canDestroyLink = true;

  const componentClasses = classnames({
    'bulk-atp-actions-component': true,
    'capybara--bulk-atp-actions-component-top': !positionBottom,
    'bulk-atp-actions-component_position-bottom': positionBottom,
  });

  return (
    <div className={componentClasses}>
      {!positionBottom && (
        <div className="bulk-atp-actions-component__column">
          {actions.includes('filterDls') && (
            <>
              <DataFilter
                defaultFilters={defaultAtpDlsFilter({ anchorTypeOptions })}
                filtersSpec={atpDlsFilterSpec({ anchorTypeOptions })}
              />
              <div className="bulk-atp-actions-component__separator" />
            </>
          )}
          {actions.includes('groupDls') && (
            <>
              <GroupDlsButtons />
              <div className="bulk-atp-actions-component__separator" />
            </>
          )}
          {canDestroyLink && actions.includes('destroy') && (
            <DeleteDlsButton pageParentId={pageParentId} pageParentType={pageParentType} />
          )}
          {actions.includes('addSuggestions') && (
            <AddSuggestionsInput pageParentId={pageParentId} pageParentType={pageParentType} />
          )}
        </div>
      )}
      {positionBottom && (
        <div className="bulk-atp-actions-component__column">
          {actions.includes('setValue') && (
            <SetValueInputs
              pageChildType={pageChildType}
              pageParentId={pageParentId}
              pageParentType={pageParentType}
            />
          )}
        </div>
      )}
      {!positionBottom && (
        <div className="bulk-atp-actions-component__column bulk-atp-actions-component__column_justify-to-end">
          <div className="bulk-atp-actions-component__action">
            {actions.includes('categorize') && (
              <CategorizeDlsButton
                pageChildType={pageChildType}
                pageParentId={pageParentId}
                pageParentType={pageParentType}
              />
            )}
            {actions.includes('crawlPage') && (
              <div className="bulk-atp-actions-component__atp-button">
                <CrawlPageDlsButton
                  pageChildType={pageChildType}
                  pageParentId={pageParentId}
                  pageParentType={pageParentType}
                />
              </div>
            )}
            {actions.includes('import') && (
              <div className="bulk-atp-actions-component__atp-button">
                <ImportDlsButton
                  pageChildType={pageChildType}
                  pageParentId={pageParentId}
                  pageParentType={pageParentType}
                />
              </div>
            )}
          </div>
          {brandPageActionsPresent() && (
            <div className="bulk-atp-actions-component__separator" />
          )}
          {actions.includes('progressPane') && (
            <div className="bulk-atp-actions-component__action">
              <div className="bulk-atp-actions-component__button bulk-atp-actions-component__progress-button">
                <AnchorProgressPaneButton pageParentType={pageParentType} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

BulkDlsActionsComponent.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
  anchorTypeOptions: optionsArray,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageParentType: PropTypes.oneOf(['brand_page', 'brand']).isRequired,
  positionBottom: PropTypes.bool,
};

export default BulkDlsActionsComponent;
