import React from 'react';
import PropTypes from 'prop-types';

import EoEmailAttributesPopup from '../EoEmailAttributesPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import './eoEmailAttributesButton.scss';

const EoEmailAttributesButtonComponent = ({ onInsertAttribute }) => {
  return (
    <div className="eo-email-attributes-button__wrapper">
      <ToggleShowPopup Popup={<EoEmailAttributesPopup onInsertAttribute={onInsertAttribute} />}>
        <ButtonComponent className="eo-email-attributes-button" isInitial isBlue>
          {translate('eoCampaignStep.eoCampaignStepEmail.attrs.buttonTitle')}
        </ButtonComponent>
      </ToggleShowPopup>
    </div>
  );
};

EoEmailAttributesButtonComponent.propTypes = {
  onInsertAttribute: PropTypes.func.isRequired,
};

export default EoEmailAttributesButtonComponent;
