import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import RefreshIcon from 'common/icons/refresh';

import './recheckRankingsButton.scss';

const disabledBySubscriptionTooltip = {
  text: translate('rankTracker.buttons.recheckKeywordsRankings.tooltip'),
  placement: 'top',
};

const RecheckRankingsButtonComponent = ({
  isDisabledByKeywords,
  isDisabledBySubscription,
  isLoading,
  onClick,
}) => {
  const buttonTooltip = isDisabledBySubscription ? disabledBySubscriptionTooltip : void 0;

  const isDisabled = isDisabledByKeywords || isDisabledBySubscription;

  return (
    <ButtonComponent
      className="recheck-rankings-button"
      isDisabled={isDisabled}
      isLoading={isLoading}
      isWhite
      isInitial
      onClick={onClick}
      tooltip={buttonTooltip}
    >
      <RefreshIcon />
      {translate('rankTracker.buttons.recheckKeywordsRankings.title')}
    </ButtonComponent>
  );
};

RecheckRankingsButtonComponent.propTypes = {
  isDisabledByKeywords: PropTypes.bool,
  isDisabledBySubscription: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default RecheckRankingsButtonComponent;
