import React from 'react';

const EmailSettingsIcon = (props) => (
  <svg {...props} className="email-settings-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.667 4L8 8.667 1.333 4"
      strokeWidth=".8"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth="0"
      d="M1.733 4c0-.512.421-.933.934-.933h10.666c.513 0 .934.42.934.933v5.307l.8.32V4c0-.954-.78-1.733-1.734-1.733H2.667C1.712 2.267.933 3.046.933 4v8c0 .954.78 1.733 1.734 1.733h6.9l.2-.8h-7.1A.937.937 0 011.733 12V4z"
    />
    <g fill="none" clipPath="clip0" strokeLinecap="round" strokeLinejoin="round">
      <path d="M12 13a1 1 0 100-2 1 1 0 000 2z" strokeWidth=".6" />
      <path
        d="M14.467 13a.55.55 0 00.11.607l.02.02a.669.669 0 01-.217 1.088.668.668 0 01-.727-.145l-.02-.02a.55.55 0 00-.606-.11.55.55 0 00-.334.503V15a.667.667 0 11-1.333 0v-.03a.55.55 0 00-.36-.503.55.55 0 00-.607.11l-.02.02a.667.667 0 11-.943-.944l.02-.02a.55.55 0 00.11-.606.55.55 0 00-.503-.334H9a.667.667 0 110-1.333h.03a.55.55 0 00.503-.36.55.55 0 00-.11-.607l-.02-.02a.666.666 0 11.944-.943l.02.02a.55.55 0 00.606.11H11a.55.55 0 00.333-.503V9a.667.667 0 011.334 0v.03a.55.55 0 00.333.503.55.55 0 00.607-.11l.02-.02a.668.668 0 11.943.944l-.02.02a.55.55 0 00-.11.606V11a.55.55 0 00.503.333H15a.667.667 0 010 1.334h-.03a.55.55 0 00-.503.333v0z"
        strokeWidth=".7"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path transform="translate(8 8)" d="M0 0h8v8H0z" />
      </clipPath>
    </defs>
  </svg>
);

EmailSettingsIcon.defaultProps = {
  height: '16px',
  width: '16px',
  stroke: "#474A4D",
};

export default EmailSettingsIcon;
