import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as edit from 'react-edit';
import * as Table from 'reactabular-table';
import classnames from 'classnames';
import { cloneDeep, isEqual } from 'lodash';
import { Map as iMap } from 'immutable';

import columnsGenerator, { columnTextArea } from '../../common/tables/columns_generator';
import EditIcon from '../../common/icons/edit';
import {
  addCSSClassToCellInTable,
  removeCSSClassFromCellInTable,
  TABLE_CELL_ACTIVE_CLASS,
  TABLE_CELL_ERROR_CLASS,
} from '../../common/tables/utils';
import { policiesShape, optionsMap, percentageSchemesMap } from '../../common/prop_types_shapes';
import { textColumn } from '../../common/tables/columns';
import { translate } from '../../common/i18n';
import { textTwoLinesWithEllipsis } from '../../common/utils';
import { calculatePercentagesSum } from './utils';

import './saved_percentages_table_component.scss';


class SavedPercentagesTableComponent extends Component {
  static propTypes = {
    anchorTypeOptions: optionsMap.isRequired,
    editMode: PropTypes.bool,
    onEditPercentageSchemeClick: PropTypes.func,
    onPercentageChange: PropTypes.func,
    percentageSchemes: percentageSchemesMap.isRequired,
    policies: policiesShape.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      props,
      columns: this.generateColumns(props),
      rows: this.generateRows(props),
      generateColumns: this.generateColumns,
      generateRows: this.generateRows,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const needToUpdState = !isEqual(nextProps, prevState.props);

    if (needToUpdState) {
      return {
        columns: prevState.generateColumns(nextProps),
        rows: prevState.generateRows(nextProps),
        props: nextProps,
      };
    }

    return null;
  }

  componentDidMount() {
    this.setTablesRef();
  }

  tableRef = {}

  setTablesRef = () => {
    const tables = ReactDOM.findDOMNode(this).getElementsByTagName('table');
    this.tableRef = tables[0];
  }

  generateRows = (props) => {
    const { anchorTypeOptions, percentageSchemes } = props;

    const rows = anchorTypeOptions.toList().map((option) => {
      return this.getRowForAnchorType(option.get('value'), percentageSchemes);
    }).toJS();
    rows.push({
      anchorType: translate('percentageSettings.savedPercentages.table.total'),
      ...this.percentageSummary(props),
    });

    return rows;
  }

  percentageSummary = (props) => {
    return props.percentageSchemes.mapEntries(([_, scheme]) => { //eslint-disable-line no-unused-vars
      const percentageSummaryTotal = calculatePercentagesSum(scheme.get('percentageSchemeItems'));
      return [scheme.get('id'), percentageSummaryTotal];
    }).toJS();
  }

  getRowForAnchorType = (anchorType, percentageSchemes) => {
    const percentages = percentageSchemes.mapEntries(([_, scheme]) => { //eslint-disable-line no-unused-vars
      const idealPercentage = scheme
        .get('percentageSchemeItems', iMap())
        .filter((item) => item.get('anchorType') === anchorType)
        .first()
        .get('idealPercentage');
      return [scheme.get('id'), idealPercentage];
    }).toJS();
    return {
      anchorType,
      ...percentages,
    };
  }

  generateColumns = (props) => {
    const { editMode, percentageSchemes } = props;

    const columns = [
      textColumn({
        className: 'saved-percentages-table-component__column',
        headerLabel: translate('percentageSettings.savedPercentages.anchorType.title'),
        name: 'anchorType',
        cellValueFormatter: this.anchorTypeFormatter,
      }),
      ...percentageSchemes.toList().map((scheme) => {
        return textColumn({
          className: 'saved-percentages-table-component__column \
          saved-percentages-table-component__column_align-center',
          headerLabel: scheme.get('title'),
          name: scheme.get('id'),
          cellValueFormatter: this.percentageFormatter,
          headerFormatters: [this.headerSchemePercentageFormatters],
          cellTransforms: [columnTextArea(this.editable)],
          isEditable: editMode,
        });
      }),
    ];

    return columnsGenerator(columns);
  }

  editable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowIndex }) => {
      const { editMode } = this.props;
      const { rows } = this.state;
      const isLastRow = (rows.length - 1) === rowIndex;

      if (!editMode || isLastRow) {
        return;
      }

      const clonedRows = cloneDeep(rows);
      clonedRows[rowIndex].editing = columnIndex;

      removeCSSClassFromCellInTable(this.tableRef, rowIndex, columnIndex, TABLE_CELL_ERROR_CLASS);
      addCSSClassToCellInTable(this.tableRef, rowIndex, columnIndex, TABLE_CELL_ACTIVE_CLASS);

      this.setState({ rows: clonedRows });
    },

    onValue: async ({ columnIndex, value, rowIndex, property }) => {
      const { onPercentageChange } = this.props;
      const { rows } = this.state;

      const clonedRows = cloneDeep(rows);
      Reflect.deleteProperty(clonedRows[rowIndex], 'editing');
      removeCSSClassFromCellInTable(this.tableRef, rowIndex, columnIndex, TABLE_CELL_ACTIVE_CLASS);

      const valueAsNumber = Number(value);

      const isValueValid = !Number.isNaN(valueAsNumber) &&
        (valueAsNumber >= 0 && valueAsNumber <= 100);

      if (!isValueValid) { // highlight cell
        addCSSClassToCellInTable(this.tableRef, rowIndex, columnIndex, TABLE_CELL_ERROR_CLASS);
      }

      clonedRows[rowIndex][property] = isValueValid ? valueAsNumber.toFixed(2).toString() : value;
      onPercentageChange(clonedRows);
    },
  });

  headerSchemePercentageFormatters = (value, extra) => {
    const { policies, percentageSchemes, editMode } = this.props;
    const { property } = extra;

    const percentageSchemesId = percentageSchemes
      .filter((scheme) => scheme.get('id') === property)
      .first()
      .get('id');
    const canUpdate = policies.getIn(['percentageScheme', 'canUpdate']);
    const isEditableScheme = percentageSchemes
      .get(String(percentageSchemesId))
      .get('editable');
    return (
      <div className="saved-percentages-table-component__header-title">
        {textTwoLinesWithEllipsis(value, { breakAll: true })}
        {canUpdate && !editMode && isEditableScheme &&
          <EditIcon
            className="saved-percentages-table-component__icon"
            onClick={this.handleEditPercentageSchemeClick(percentageSchemesId)}
          />
        }
      </div>
    );
  }

  handleEditPercentageSchemeClick = (percentageSchemeId) => () => this.props.onEditPercentageSchemeClick(percentageSchemeId);

  anchorTypeFormatter = (value) => this.props.anchorTypeOptions.get(value, iMap()).get('label', value);
  percentageFormatter = (value) => `${value}%`;

  render() {
    const { editMode } = this.props;
    const { columns, rows } = this.state;

    const tableClasses = classnames({
      'saved-percentages-table-component__table': true,
      'saved-percentages-table-component__table_compact': editMode,
    });

    return (
      <div className="saved-percentages-table-component">
        <Table.Provider columns={columns} className={tableClasses}>
          <Table.Header />
          <Table.Body rowKey="anchorType" rows={rows} />
        </Table.Provider>
      </div>
    );
  }
}

export default SavedPercentagesTableComponent;
