import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { translate } from 'common/i18n';

import { keywordsArray } from 'common/prop_types_shapes';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import Checkbox from 'components_linkio/Checkbox';
import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';
import KeywordsManager from 'components_linkio/KeywordsManager';
import RadioButton from 'components_linkio/RadioButton';
import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';
import TextAreaComponent from 'components_linkio/textarea_component';
import CategorizationSamples from 'pages/Pages/PagesList/PageItem/PageDls/BulkDlsActions/CategorizeDlsButton/CategorizeDlsPopup/CategorizationSamples';

import './categorizeDlsPopup.scss';

const CategorizeDlsPopupComponent = ({
  alternativeLinks,
  brandIsFocused,
  brandIsHovered,
  brandName,
  brandOtherNames,
  canUseCategorizer,
  keywords,
  metaKeywords,
  onCancel,
  onCategorize,
  onChangeKeywords,
  onChangeState,
  isAnyChanges,
  isUpdating,
  onClickUpgradeSubscription,
  pageErrors,
  pageId,
  pageParentId,
  pageParentType,
  saveAndCategorize,
  selectedCheckbox,
  title,
}) => {
  function handleAlternativeLinksChange(event) {
    onChangeState({ alternativeLinks: event.target.value.split(/\r?\n/), isAnyChanges: true });
  }

  function handleCategorizeCheckboxChange(event) {
    const isChecked = !!(event.target || {}).checked;
    onChangeState({ saveAndCategorize: isChecked, isAnyChanges: true });
  }

  function handleTitleChange(event) {
    onChangeState({ title: event.target.value.split(/\r?\n/), isAnyChanges: true });
  }

  function handleBrandTextAreaBlur() {
    onChangeState({ brandIsFocused: false, brandIsHovered: false, isAnyChanges: true });
  }

  function handleBrandTextAreaFocus() {
    onChangeState({ brandIsFocused: true, brandIsHovered: false });
  }

  function handleBrandTextAreaMouseEnter() {
    !brandIsFocused && onChangeState({ brandIsHovered: true });
  }

  function handleBrandTextAreaMouseLeave() {
    onChangeState({ brandIsHovered: false });
  }

  function handleBrandOtherNamesChange(event) {
    onChangeState({ brandOtherNames: event.target.value, isAnyChanges: true });
  }

  function handleCheckboxChange(event) {
    onChangeState({ selectedCheckbox: event.target.value, isAnyChanges: true });
  }

  const brandNameSelects = classnames({
    'categorize-popup-component__main-brand': true,
    'categorize-popup-component__main-brand_focus': brandIsFocused,
    'categorize-popup-component__main-brand_hover': brandIsHovered,
  });

  return (
    <Modal
      show
      className="categorize-popup-component__modal"
      dialogClassName="categorize-popup-component__custom-modal"
    >
      <Modal.Header>
        <Modal.Title>{translate('categorizerPopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="categorize-popup-component__modal-body">
        {!canUseCategorizer && (
          <SubscriptionLimitRibbonComponent>
            {translate('subscriptionLimitRibbon.message')(
              translate('subscriptionLimitRibbon.limited.categorization'),
            )}
            <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
          </SubscriptionLimitRibbonComponent>
        )}

        <CategorizationSamples pageId={pageId} />

        <div className="categorize-popup-component__main-body">
          <KeywordsManager
            key={keywords}
            keywords={keywords}
            metaKeywords={metaKeywords}
            onChangeKeywords={onChangeKeywords}
            pageId={pageId}
            pageParentId={pageParentId}
            pageParentType={pageParentType}
            targetAppModule="atp"
            title={title}
          />

          <p className="categorize-popup-component__label">
            {translate('categorizerPopup.labels.brandName')}
            <ExplanatoryTooltipComponent text={translate('explanationMessages.brand.otherNames')} />
          </p>
          <div className="categorize-popup-component__input ">
            <div className={brandNameSelects}>{brandName}</div>
            <textarea
              className="categorize-popup-component__textarea categorize-popup-component__textarea_without-top-border"
              defaultValue={brandOtherNames}
              name="brandOtherNames"
              onBlur={handleBrandTextAreaBlur}
              onFocus={handleBrandTextAreaFocus}
              onMouseEnter={handleBrandTextAreaMouseEnter}
              onMouseLeave={handleBrandTextAreaMouseLeave}
              onChange={handleBrandOtherNamesChange}
            />
          </div>
          <TextAreaComponent
            explanationMessage={translate('explanationMessages.categorizePopup.alternativePageUrl')}
            label={translate('categorizerPopup.labels.alternativePageUrl')}
            onChange={handleAlternativeLinksChange}
            value={alternativeLinks.join('\n')}
          />
          {pageErrors && <p className="categorize-popup-component__error">{pageErrors}</p>}
          <TextAreaComponent
            explanationMessage={translate('explanationMessages.categorizePopup.title')}
            label={translate('categorizerPopup.labels.titleTag')}
            message={{ type: 'hint', text: translate('categorizerPopup.hints.title') }}
            onChange={handleTitleChange}
            value={title.join('\n')}
          />
          <div className="categorize-popup-component__selects">
            <div className="categorize-popup-component__checkbox">
              <Checkbox
                checked={saveAndCategorize}
                name="categorize"
                onChange={handleCategorizeCheckboxChange}
              >
                {translate('categorizerPopup.labels.categorize')}
                <ExplanatoryTooltipComponent
                  text={translate('explanationMessages.categorizePopup.categorize')}
                />
              </Checkbox>
            </div>
            <RadioButton
              checked={selectedCheckbox === 'all'}
              disabled={!saveAndCategorize}
              name="all"
              onChange={handleCheckboxChange}
              value="all"
            >
              {translate('categorizerPopup.labels.all')}
            </RadioButton>
            <RadioButton
              checked={selectedCheckbox === 'selected'}
              disabled={!saveAndCategorize}
              name="selected"
              onChange={handleCheckboxChange}
              value="selected"
            >
              {translate('categorizerPopup.labels.selected')}
            </RadioButton>
            <RadioButton
              checked={selectedCheckbox === 'uncategorized'}
              disabled={!saveAndCategorize}
              name="uncategorized"
              onChange={handleCheckboxChange}
              value="uncategorized"
            >
              {translate('categorizerPopup.labels.uncategorized')}
            </RadioButton>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="categorize-popup-component__btn-group">
          <div className="categorize-popup-component__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('categorizerPopup.buttons.cancel')}
            </ButtonComponent>
          </div>
          <div className="categorize-popup-component__btn">
            <ButtonComponent
              isLoading={isUpdating}
              isDisabled={!canUseCategorizer}
              isGreen
              onClick={onCategorize}
            >
              {translate('categorizerPopup.buttons.saveAndCategorize', {
                onlySave: !saveAndCategorize,
                anyChanges: isAnyChanges,
              })}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

CategorizeDlsPopupComponent.propTypes = {
  alternativeLinks: PropTypes.arrayOf(PropTypes.string),
  brandIsFocused: PropTypes.bool.isRequired,
  brandIsHovered: PropTypes.bool.isRequired,
  brandName: PropTypes.string,
  brandOtherNames: PropTypes.string,
  canUseCategorizer: PropTypes.bool.isRequired,
  isAnyChanges: PropTypes.bool,
  isUpdating: PropTypes.bool,
  keywords: keywordsArray,
  metaKeywords: PropTypes.arrayOf(PropTypes.string),
  onCancel: PropTypes.func.isRequired,
  onCategorize: PropTypes.func.isRequired,
  onChangeKeywords: PropTypes.func.isRequired,
  onChangeState: PropTypes.func.isRequired,
  onClickUpgradeSubscription: PropTypes.func.isRequired,
  pageErrors: PropTypes.string,
  pageId: PropTypes.string.isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageParentType: PropTypes.oneOf(['brand_page', 'brand']),
  saveAndCategorize: PropTypes.bool,
  selectedCheckbox: PropTypes.string,
  title: PropTypes.arrayOf(PropTypes.string),
};

export default CategorizeDlsPopupComponent;
