import React from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import MetricsPanelComponent from './MetricsPanelComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { currentBrandSelector, uiSelector, visiblePageIdByParentSelector } from 'selectors';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';
import { metricsShape, metricsOverTimeShape } from 'common/propTypesShapes/metrics';
import { openSubscriptionLimitReachedPopup } from 'actions/popup_actions';
import { subscriptionPoliciesShape } from 'common/prop_types_shapes';

const MetricsPanelContainer = ({
  appModule,
  changeQueryParams,
  dispatch,
  importedBacklinksOnce,
  isFetching,
  metrics,
  metricsOverTime,
  pageParentType,
  subscriptionPolicies,
  queryParams,
}) => {
  const activeMetric = queryParams.metricsFilter;

  const handleMetricItemClick = (metricName) => () => {
    const updatedActiveMetric = metricName === activeMetric ? '' : metricName;

    const newQueryParams = {
      ...queryParams,
      metricsFilter: updatedActiveMetric,
      pageNumber: 1,
    };

    changeQueryParams(newQueryParams);
  };

  function handleClickUpgradeSubscription(event) {
    event && event.preventDefault();

    dispatch(openSubscriptionLimitReachedPopup({}));
  }

  const canUseMetrics = subscriptionPolicies.getIn(['metrics', 'canUse']);
  const isBrand = appModule === 'backlinks';
  const isCompetitorsPage = pageParentType === 'brand_page';

  return (
    <MetricsPanelComponent
      activeMetric={activeMetric}
      canUseMetrics={canUseMetrics}
      importedBacklinksOnce={importedBacklinksOnce}
      isBrand={isBrand}
      isCompetitorsPage={isCompetitorsPage}
      isFetching={isFetching}
      metrics={metrics.toJS()}
      metricsOverTime={metricsOverTime.toJS()}
      onClickUpgradeSubscription={handleClickUpgradeSubscription}
      onMetricItemClick={handleMetricItemClick}
    />
  );
};

MetricsPanelContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  metrics: metricsShape,
  metricsOverTime: metricsOverTimeShape,
  subscriptionPolicies: subscriptionPoliciesShape,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const { appModule } = ownProps;
  const brand = currentBrandSelector(state, ownProps);

  let metricsEntity = iMap();

  switch (appModule) {
    case 'atp': {
      const visiblePageId = visiblePageIdByParentSelector(state, ownProps);

      metricsEntity = state.getIn(['brandPages', String(visiblePageId)], iMap());
      break;
    }
    case 'backlinks':
      metricsEntity = brand;
      break;
  }

  const metrics = metricsEntity.get('metrics', iMap());
  const metricsOverTime = metricsEntity.get('metricsOverTime', iMap());
  const importedBacklinksOnce = metricsEntity.get('importedBacklinksOnce', false);

  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  const uiStates = uiSelector(state);
  const fetchingBacklinks = uiStates.getIn(['loading', 'fetchBacklinks'], false);
  const fetchingDls = uiStates.getIn(['loading', 'fetchDLs'], false);
  const isFetching = fetchingBacklinks || fetchingDls;

  return {
    importedBacklinksOnce,
    isFetching,
    metrics,
    metricsOverTime,
    subscriptionPolicies,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(MetricsPanelContainer);
