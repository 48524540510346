import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import BrandOverviewComponent from './BrandOverviewComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandDashboardDataMap } from 'common/prop_types_shapes';
import { brandDashboardDataSelector } from 'selectors/brandDashboardSelector';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';
import { CHARTS_DEFAULT_DATE_RANGE } from 'utils/constants';
import { currentIdSelector } from 'selectors';
import { fetchBrandDashboardData } from 'api/brandDashboard';

const BrandOverviewContainer = ({
  brandDashboardData,
  brandId,
  canUseMetrics,
  dispatch,
  history,
  queryParams: { filters = {} },
}) => {
  const dateStart = filters.dateStart || CHARTS_DEFAULT_DATE_RANGE.dateStart;
  const dateEnd = filters.dateEnd || CHARTS_DEFAULT_DATE_RANGE.dateEnd;

  React.useEffect(() => {
    fetchBrandDashboardData(dispatch, brandId, dateStart, dateEnd);
  }, [brandId, dateStart, dateEnd]);

  const handleVisitLink = (link) => () => history.push(link);
  return (
    <>
      {brandDashboardData.get('id') && (
        <BrandOverviewComponent
          brandDashboardData={brandDashboardData}
          brandId={brandId}
          canUseMetrics={canUseMetrics}
          onVisitLink={handleVisitLink}
        />
      )}
    </>
  );
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const brandDashboardData = brandDashboardDataSelector(state);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);
  const canUseMetrics = subscriptionPolicies.getIn(['metrics', 'canUse']);

  return {
    brandId,
    brandDashboardData,
    canUseMetrics,
  };
}

BrandOverviewContainer.propTypes = {
  brandDashboardData: brandDashboardDataMap,
  brandId: PropTypes.string,
  canUseMetrics: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  ...withQueryParamsPropTypes,
};

export default compose(
  withQueryParams,
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(BrandOverviewContainer);
