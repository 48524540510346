import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutablejs-proptypes';
import { Map as iMap } from 'immutable';
import classnames from 'classnames';

import buildBrandName from '../utils/buildBrandName';

import {
  buildCountTowardAverages,
  buildDataWithConditionalSpinner,
  buildEmptyCell,
  buildLinkText,
  getCellValue,
} from '../utils';

import { crawlingInProgress } from '../../../Pages/utils';
import { TOO_LITTLE_RDS_LIMIT } from '../constants';
import IdealSchemeSelect from './../../PagesList/PageItem/PageDls/DlsPercentage/IdealSchemeSelect';

import { brandPageShape, percentageShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import EditIcon from 'common/icons/edit';
import MinusInRoundIcon from 'common/icons/minus_in_round';
import PlusInRoundIcon from 'common/icons/plus_in_round';

const anchorToggleIcon = (isOpen) => {
  return isOpen ? (
    <MinusInRoundIcon className="percentages__icon_grey" />
  ) : (
    <PlusInRoundIcon className="percentages__icon_grey" />
  );
};

const toFixedPercent = (num) => (!isNaN(num) ? `${Number(num).toFixed(2)}%` : '0%');

const PercentagesAnalysisTableComponent = ({
  averages,
  competitors,
  myBrandPage,
  onAddCompetitorsClick,
  onChangeCountTowardAverages,
  onEditClick,
  onTogglePercentageClick,
  percentageGroupsStatus,
  percentageSelectRef,
}) => {
  const rows = [
    {
      key: 'brandName',
      title: void 0,
      cellValueFormatter: buildBrandName,
    },
    {
      key: 'link',
      title: void 0,
      cellValueFormatter: buildLinkText,
    },
    {
      key: 'metrics.countReferringDomains',
      title: translate('numberOfBacklinksAnalysis.table.rows.countReferringDomains.title'),
    },
    {
      key: 'emptyCell',
      title: void 0,
    },
    {
      key: 'branded',
      title: translate('percentagesAnalysis.table.rows.branded.title'),
      subTypes: [
        {
          key: 'branded',
          title: translate('options.anchorType.branded.label'),
        },
        {
          key: 'websiteNameCom',
          title: translate('options.anchorType.websiteNameCom.label'),
        },
      ],
    },
    {
      key: 'keyword',
      title: translate('percentagesAnalysis.table.rows.keyword.title'),
      subTypes: [
        {
          key: 'exactKeyword',
          title: translate('options.anchorType.exactKeyword.label'),
        },
        {
          key: 'onlyPartOfKeyword',
          title: translate('options.anchorType.onlyPartOfKeyword.label'),
        },
        {
          key: 'keywordPlusWord',
          title: translate('options.anchorType.keywordPlusWord.label'),
        },
      ],
    },
    {
      key: 'hybrid',
      title: translate('percentagesAnalysis.table.rows.hybrid.title'),
      subTypes: [
        {
          key: 'titleTag',
          title: translate('options.anchorType.titleTag.label'),
        },
        {
          key: 'brandAndKeywordTogether',
          title: translate('options.anchorType.brandAndKeywordTogether.label'),
        },
      ],
    },
    {
      key: 'url',
      title: translate('percentagesAnalysis.table.rows.url.title'),
      subTypes: [
        {
          key: 'nakedUri',
          title: translate('options.anchorType.nakedUri.label'),
        },
        {
          key: 'nakedUriWoHttp',
          title: translate('options.anchorType.nakedUriWoHttp.label'),
        },
        {
          key: 'homepageUri',
          title: translate('options.anchorType.homepageUri.label'),
        },
      ],
    },
    {
      key: 'natural',
      title: translate('percentagesAnalysis.table.rows.natural.title'),
      subTypes: [
        {
          key: 'justNatural',
          title: translate('options.anchorType.justNatural.label'),
        },
        {
          key: 'noText',
          title: translate('options.anchorType.noText.label'),
        },
        {
          key: 'totallyRandom',
          title: translate('options.anchorType.totallyRandom.label'),
        },
      ],
    },
    {
      key: 'countTowardAverages',
      title: translate('percentagesAnalysis.table.rows.countTowardAverages.title'),
      isAction: true,
      cellValueFormatter: buildCountTowardAverages,
      onClick: onChangeCountTowardAverages,
    },
  ];

  function buildPageCells({ page = iMap(), tableSection }) {
    const isMyBrandPage = tableSection.includes('myBrandPage');
    const isAveragesSection = tableSection === 'averages';
    const countReferringDomains = page.getIn(['metrics', 'dfAndIndexedReferringDomains']);
    const competitorHasFinalData =
      !crawlingInProgress(page.get('crawlingStatus')) && !page.get('importingInProgress');

    const showRdsNotification =
      competitorHasFinalData &&
      countReferringDomains > 0 &&
      countReferringDomains < TOO_LITTLE_RDS_LIMIT;

    function handleRow(row) {
      const cellClasses = classnames({
        'competitors-table__cell': true,
        'competitors-table__cell_highlighted': (row.subTypes && isAveragesSection) || isMyBrandPage,
        'competitors-table__cell_text-bold-blue': row.key === 'brandName',
        'competitors-table__cell_with-notification':
          row.key === 'countTowardAverages' && showRdsNotification,
      });

      const showHideCellClasses = classnames({
        'competitors-table__cell_show': row.subTypes && percentageGroupsStatus[row.key].isOpen,
        'competitors-table__cell_hide': row.subTypes && !percentageGroupsStatus[row.key].isOpen,
      });

      const groupedPercentageClass = classnames({
        'competitors-table__cell_text-bold-dark': isMyBrandPage,
      });

      let overallType = 'overall';
      const percentageType = tableSection === 'myBrandPageIdeal' ? 'ideal' : 'published';

      if (isMyBrandPage) {
        overallType = tableSection === 'myBrandPageActual' ? 'publishedOverall' : 'idealOverall';
      }

      const needToShowSpinner = !isAveragesSection && !isMyBrandPage && !competitorHasFinalData;

      function getValue() {
        const rowIsEmpty = ['emptyCell'].includes(row.key);
        if (rowIsEmpty) {
          return void 0;
        }

        if (row.key.includes('metrics')) {
          // Don't show averages for metrics
          if (tableSection === 'averages') {
            return void 0;
          }

          return getCellValue(row, page, tableSection);
        }

        if (row.cellValueFormatter) {
          return row.cellValueFormatter({ page, row, tableSection, needToShowSpinner });
        }

        const fixedPercent = toFixedPercent(
          Number(page.getIn(['percentages', row.key, overallType])),
        );

        return (
          <span className="competitors-table__cell_data-with-spinner">
            {buildDataWithConditionalSpinner(fixedPercent, needToShowSpinner)}
          </span>
        );
      }

      function getPercentages(type) {
        const fixedPercent = toFixedPercent(
          Number(page.getIn(['percentages', row.key, 'percentage', type, percentageType])),
        );

        return (
          <span className="competitors-table__cell_data-with-spinner">
            {buildDataWithConditionalSpinner(fixedPercent, needToShowSpinner)}
          </span>
        );
      }

      return (
        <Fragment key={row.key}>
          <div className={`${cellClasses} ${groupedPercentageClass}`}>{getValue()}</div>
          {row.subTypes &&
            row.subTypes.map((type) => (
              <div key={type.key} className={`${cellClasses} ${showHideCellClasses}`}>
                {getPercentages(type.key)}
              </div>
            ))}
        </Fragment>
      );
    }

    return rows.reduce((result, row) => {
      const showEmptyCell = tableSection === 'emptyCells';

      if (showEmptyCell) {
        result.push(buildEmptyCell(row, percentageGroupsStatus, onAddCompetitorsClick));
        return result;
      }

      const hideSwitchBtn = row.isAction && tableSection !== 'percentagesAnalysisTableCompetitors';
      const hideUnnecessaryDataRelatedToMyBrandPage =
        isMyBrandPage && (row.title === void 0 || row.key.includes('metrics'));

      const needToSkipRow = hideSwitchBtn || hideUnnecessaryDataRelatedToMyBrandPage;

      if (!needToSkipRow) {
        result.push(handleRow(row));
      }

      return result;
    }, []);
  }

  function buildAnchorTypeCells() {
    function handleRow(row) {
      const titleClasses = classnames({
        'competitors-table__cell': true,
        'competitors-table__cell_text-bold-blue':
          Boolean(row.subTypes) || row.key === 'metrics.dfAndIndexedReferringDomains',
        'competitors-table__cell_text-charcoal-grey-two': row.isAction,
      });

      const subTitleClasses = classnames({
        'competitors-table__cell': true,
        'competitors-table__cell_text-charcoal-grey-two': true,
        'competitors-table__anchor-subtitle': true,
      });

      const showHideCellClasses = classnames({
        'competitors-table__cell_show': row.subTypes && percentageGroupsStatus[row.key].isOpen,
        'competitors-table__cell_hide': row.subTypes && !percentageGroupsStatus[row.key].isOpen,
      });

      return (
        <Fragment key={row.key}>
          <div className={titleClasses}>
            {row.subTypes && (
              <div onClick={onTogglePercentageClick(row.key)} className="percentages__icon">
                {anchorToggleIcon(percentageGroupsStatus[row.key].isOpen)}
              </div>
            )}
            <span className="competitors-table__anchor-title">{row.title}</span>
          </div>
          {row.subTypes &&
            row.subTypes.map((type) => (
              <div key={type.key} className={`${subTitleClasses} ${showHideCellClasses}`}>
                {type.title}
              </div>
            ))}
        </Fragment>
      );
    }

    return rows.map(handleRow);
  }

  const enoughCompetitorsToScroll = competitors.size >= 4;
  const hasCompetitors = competitors.size > 0;

  const headCellClasses = classnames({
    'competitors-table__cell': true,
    'competitors-table__cell_header': true,
  });

  const scrollableBoxClasses = classnames({
    'competitors-table__scrollable-box_item': true,
    'competitors-table__scrollable-item': enoughCompetitorsToScroll,
    'competitors-table__not-scrollable-item': !enoughCompetitorsToScroll,
  });

  const myBrandCellClasses = classnames({
    'competitors-table__cell': true,
    'competitors-table__cell_highlighted': true,
    'competitors-table__cell_text-bold-dark': true,
  });

  return (
    <div className="competitors-table">
      <div className="competitors-table__row-headers-wrapper">
        <div className={headCellClasses}>
          {translate('percentagesAnalysis.table.columns.anchorType.title')}
        </div>
        {buildAnchorTypeCells()}
      </div>

      <div className="competitors-table__competitors-wrapper">
        <div className={headCellClasses}>
          {translate('percentagesAnalysis.table.columns.competitors.title')}
        </div>
        <div className="competitors-table__scrollable-box">
          {hasCompetitors &&
            competitors
              .map((competitor) => (
                <div key={competitor.get('id')} className={scrollableBoxClasses}>
                  {buildPageCells({
                    page: competitor,
                    tableSection: 'percentagesAnalysisTableCompetitors',
                  })}
                </div>
              ))
              .valueSeq()
              .toArray()}
          {!hasCompetitors && (
            <div className={scrollableBoxClasses}>
              {buildPageCells({ page: void 0, tableSection: 'emptyCells' })}
            </div>
          )}
        </div>
      </div>

      <div className="competitors-table__average-wrapper competitors-table__column-with-border-bottom">
        <div className={headCellClasses}>
          {translate('percentagesAnalysis.table.columns.average.title')}
        </div>
        {buildPageCells({ page: averages, tableSection: 'averages' })}
      </div>

      <div className="competitors-table__my-brand-wrapper">
        <div className={headCellClasses}>
          {translate('percentagesAnalysis.table.columns.myBrand.title')}
        </div>
        <div className="competitors-table__cell competitors-table__cell_text-bold-blue">
          {buildBrandName({ page: myBrandPage })}
        </div>
        <div className="competitors-table__cell">{buildLinkText({ page: myBrandPage })}</div>
        <div className="competitors-table__cell">
          <div className="competitors-table__ideal-scheme-select-wrapper">
            <IdealSchemeSelect
              competitorsGroupInvisible={false}
              percentageSelectRef={percentageSelectRef}
              pageChildType="competitorsPage"
              pageId={myBrandPage.get('id')}
            />
          </div>
        </div>
        <div className="competitors-table__my-brand-percentages-wrapper">
          <div className="competitors-table__my-brand-actual-percentages-wrapper competitors-table__column-with-border-bottom">
            <div className={myBrandCellClasses}>
              {translate('percentagesAnalysis.table.columns.myBrand.actualTitle')}
            </div>
            {buildPageCells({ page: myBrandPage, tableSection: 'myBrandPageActual' })}
          </div>
          <div className="competitors-table__my-brand-ideal-percentages-wrapper competitors-table__column-with-border-bottom">
            <div className={myBrandCellClasses}>
              {translate('percentagesAnalysis.table.columns.myBrand.idealTitle')}
              <EditIcon className="competitors-table__edit-icon" onClick={onEditClick} />
            </div>
            {buildPageCells({ page: myBrandPage, tableSection: 'myBrandPageIdeal' })}
          </div>
        </div>
      </div>
    </div>
  );
};

PercentagesAnalysisTableComponent.propTypes = {
  averages: ImmutablePropTypes.contains({
    percentages: ImmutablePropTypes.mapOf(
      ImmutablePropTypes.contains({
        overall: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        percentage: ImmutablePropTypes.mapOf(percentageShape),
      }), //value
      PropTypes.string.isRequired, //key
    ),
  }).isRequired,
  competitors: ImmutablePropTypes.listOf(brandPageShape).isRequired,
  myBrandPage: ImmutablePropTypes.contains({
    brandName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
    percentages: ImmutablePropTypes.mapOf(
      ImmutablePropTypes.contains({
        idealOverall: PropTypes.number.isRequired,
        publishedOverall: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        percentage: ImmutablePropTypes.mapOf(percentageShape).isRequired,
      }), //value
      PropTypes.string.isRequired, //key
    ),
  }).isRequired,
  onAddCompetitorsClick: PropTypes.func,
  onChangeCountTowardAverages: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onTogglePercentageClick: PropTypes.func.isRequired,
  percentageGroupsStatus: PropTypes.objectOf(
    PropTypes.shape({
      isOpen: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
  percentageSelectRef: PropTypes.shape({ current: PropTypes.stateManager }).isRequired,
};

export default PercentagesAnalysisTableComponent;
