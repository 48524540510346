import React from 'react';
import cx from 'classnames';

import { translate } from 'common/i18n';

import OverlayWithTooltipComponent from 'components_linkio/overlay_with_tooltip_component';

export function buildEmailWithValidityStatus(prospect) {
  const { bounced, email, emailValidity } = prospect;

  const processedEmailValidity = emailValidity ?? 'unchecked';

  const emailValidityStyles = cx('email-validity', {
    'email-validity_green': processedEmailValidity === 'ok',
    'email-validity_orange': processedEmailValidity === 'risky',
    'email-validity_red': processedEmailValidity === 'invalid',
    'email-validity_grey': processedEmailValidity === 'unknown',
    'email-validity_blue': processedEmailValidity === 'in_progress',
    'email-validity_black': processedEmailValidity === 'error',
    'email-validity_white': processedEmailValidity === 'unchecked',
  });

  return (
    <OverlayWithTooltipComponent
      tooltip={{
        text: `${translate(`prospects.table.tooltips.${processedEmailValidity}`)}. ${
          bounced ? translate('prospects.table.tooltips.bounced') : ''
        }`,
        placement: 'top',
      }}
    >
      <span>
        <span className={emailValidityStyles} />
        <span className={bounced ? 'text_red' : ''}>{email}</span>
      </span>
    </OverlayWithTooltipComponent>
  );
}
