import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import buildBrandName from '../utils/buildBrandName';

import {
  buildCountTowardAverages,
  buildEmptyCell,
  buildLinkText,
  getCellValue,
  processCompetitorsBasedEstimationsValue,
} from '../utils';

import { brandPageShape, brandPagesMap } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

const NumberOfBacklinksAnalysisTableComponent = ({
  averages,
  brandPage,
  competitors,
  onChangeCountTowardAverages,
}) => {
  const rows = [
    {
      key: 'brandName',
      title: void 0,
      cellValueFormatter: buildBrandName,
    },
    {
      key: 'link',
      title: void 0,
      cellValueFormatter: buildLinkText,
    },
    {
      key: 'pageMetrics',
      title: translate('numberOfBacklinksAnalysis.table.rows.pageMetrics.title'),
      isHeadLike: true,
    },
    {
      key: 'metrics.dfAndIndexedReferringDomains',
      title: translate('numberOfBacklinksAnalysis.table.rows.dfAndIndexedReferringDomains.title'),
    },
    {
      key: 'domainMetrics',
      title: translate('numberOfBacklinksAnalysis.table.rows.domainMetrics.title'),
      isHeadLike: true,
    },
    {
      key: 'metrics.domainRefdomainsCount',
      title: translate('numberOfBacklinksAnalysis.table.rows.domainRefdomainsCount.title'),
    },
    {
      key: 'metrics.homepageRefdomainsCount',
      title: translate('numberOfBacklinksAnalysis.table.rows.homepageRefdomainsCount.title'),
    },
    {
      key: 'countTowardAverages',
      title: translate('numberOfBacklinksAnalysis.table.rows.countTowardAverages.title'),
      isAction: true,
      cellValueFormatter: buildCountTowardAverages,
      onClick: onChangeCountTowardAverages,
    },
  ];

  const enoughCompetitorsToScroll = competitors.size >= 4;
  const hasCompetitors = competitors.size > 0;

  function buildPageCells({ page, tableSection }) {
    function handleRow(row) {
      const cellClasses = classnames({
        'competitors-table__cell_header': row.isHeadLike,
        'competitors-table__cell_highlighted':
          !row.isAction && !row.isHeadLike && tableSection === 'averages' && !row.isSummary,
        'competitors-table__cell_text-bold-blue':
          (row.key === 'brandName' || row.isSummary) && !row.isHeadLike,
        'competitors-table__cell': true,
      });

      return (
        <div key={row.key} className={cellClasses}>
          {getCellValue(row, page, tableSection)}
        </div>
      );
    }

    return rows.reduce((result, row) => {
      if (tableSection === 'emptyCells') {
        result.push(buildEmptyCell(row));
        return result;
      }

      const needToSkipRow = tableSection === 'backlinksTableCompetitors' && row.isSummary;
      if (!needToSkipRow) {
        result.push(handleRow(row));
      }

      return result;
    }, []);
  }

  const headCellClasses = classnames({
    'competitors-table__cell': true,
    'competitors-table__cell_header': true,
  });

  const scrollableBoxClasses = classnames({
    'competitors-table__scrollable-box_item': true,
    'competitors-table__scrollable-item': enoughCompetitorsToScroll,
    'competitors-table__not-scrollable-item': !enoughCompetitorsToScroll,
  });

  const competitorsBasedEstimations = brandPage.get('competitorsBasedEstimations');

  return (
    <>
      <div className="competitors-table competitors-table__number-of-backlinks-analysis">
        <div className="competitors-table__row-headers-wrapper">
          <div className={headCellClasses} />
          {rows.map((row) => {
            const cellClasses = classnames({
              'competitors-table__cell': true,
              'competitors-table__cell_header': row.isHeadLike,
              'competitors-table__cell_text-bold-blue': !row.isAction && !row.isHeadLike,
              'competitors-table__cell_text-charcoal-grey-two': row.isAction,
            });

            return (
              <div key={row.key} className={cellClasses}>
                {row.title}
              </div>
            );
          })}
        </div>

        <div className="competitors-table__competitors-wrapper">
          <div className={headCellClasses}>
            {translate('numberOfBacklinksAnalysis.table.columns.competitors.title')}
          </div>
          {hasCompetitors && (
            <>
              <div className="competitors-table__scrollable-box">
                {competitors
                  .map((competitor) => (
                    <div key={competitor.get('id')} className={scrollableBoxClasses}>
                      {buildPageCells({
                        page: competitor,
                        tableSection: 'backlinksTableCompetitors',
                      })}
                    </div>
                  ))
                  .valueSeq()
                  .toArray()}
              </div>
            </>
          )}
          {!hasCompetitors && (
            <div className={scrollableBoxClasses}>
              {buildPageCells({ page: void 0, tableSection: 'emptyCells' })}
            </div>
          )}
        </div>

        <div className="competitors-table__average-wrapper">
          <div className={headCellClasses}>
            {translate('numberOfBacklinksAnalysis.table.columns.average.title')}
          </div>
          {buildPageCells({ page: averages, tableSection: 'averages' })}
        </div>
        <div className="competitors-table__my-brand-wrapper">
          <div className={headCellClasses}>
            {translate('numberOfBacklinksAnalysis.table.columns.myBrand.title')}
          </div>
          {buildPageCells({ page: brandPage, tableSection: 'myPage' })}
        </div>
      </div>

      <div className="competitors-based-estimations">
        {competitorsBasedEstimations
          .map((metricValue, metricName) => {
            return (
              <Fragment key={metricName}>
                <div className="competitors-based-estimations__metric competitors-based-estimations__metric_name">
                  {translate(`numberOfBacklinksAnalysis.table.rows.${metricName}.title`)}
                </div>
                <div className="competitors-based-estimations__metric competitors-based-estimations__metric_value">
                  {processCompetitorsBasedEstimationsValue(metricName, metricValue)}
                </div>
              </Fragment>
            );
          })
          .valueSeq()
          .toArray()}
      </div>
    </>
  );
};

NumberOfBacklinksAnalysisTableComponent.propTypes = {
  averages: PropTypes.shape({
    dfAndIndexedReferringDomains: PropTypes.number,
    domainRefdomainsCount: PropTypes.number,
    homepageRefdomainsCount: PropTypes.number,
  }),
  brandPage: brandPageShape,
  competitors: brandPagesMap,
  onChangeCountTowardAverages: PropTypes.func.isRequired,
};

export default NumberOfBacklinksAnalysisTableComponent;
