import React from 'react';

const BinIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
      <path d="M6.525 12.947c0 .29.266.527.594.527.326 0 .592-.236.592-.527V5.79c0-.29-.266-.527-.593-.527-.33 0-.595.236-.595.526v7.157zm4.905 1.446c-.003.07-.08.133-.16.133H2.968c-.08 0-.158-.066-.16-.133L2.37 5.127c-.02-.406-.406-.722-.864-.705-.458.017-.815.36-.795.767l.435 9.264c.04.858.85 1.546 1.82 1.546h8.302c.97 0 1.78-.687 1.82-1.546l.435-9.265c.02-.408-.337-.75-.796-.768-.46-.017-.846.3-.865.705l-.435 9.266zm-7.396-1.43c.01.29.283.52.61.51.328-.008.586-.25.576-.54l-.237-7.16c-.01-.29-.283-.518-.61-.51-.328.01-.586.25-.576.542l.237 7.158zm4.983-.03c-.01.29.248.532.576.54.327.01.6-.22.61-.51l.237-7.158c.01-.29-.248-.533-.575-.542-.328-.008-.6.22-.61.51l-.238 7.16zM13.17 2.21c.458 0 .83.33.83.737s-.372.737-.83.737H.83c-.458 0-.83-.33-.83-.737s.372-.736.83-.736h3l.236-.834C4.286.596 5.146 0 6.05 0h1.9c.905 0 1.765.596 1.984 1.376l.236.835h3zm-4.713 0l-.134-.477c-.035-.124-.23-.26-.373-.26h-1.9c-.143 0-.338.136-.373.26l-.134.478h2.914z" />
    </svg>
  );
};

BinIcon.defaultProps = {
  fill: '#FFF',
  width: '14px',
  height: '14px',
};

export default BinIcon;
