import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchEoCampaign } from 'api/eoCampaign';
import { fetchProspect } from 'api/prospects';

import EoToDoDetailsComponent from './EoToDoDetailsComponent';

import { eoCampaignsSelector } from 'selectors/eoCampaignsSelector';
import { eoCampaignsList } from 'common/propTypesShapes/eoCampaigns';

import { fetchEoToDos } from 'api/eoToDo';

const EoToDoDetailsContainer = ({
  currentEoCampaignStepPosition,
  currentEoToDoEoCampaignId,
  currentEoToDoProspectId,
  dispatch,
  eoCampaigns,
  selectedEoToDoId,
}) => {
  const [currentEoToDoProspect, setCurrentEoToDoProspect] = React.useState({});

  React.useEffect(() => {
    currentEoToDoProspectId
      ? fetchProspect(dispatch, currentEoToDoProspectId).then((resp) =>
          setCurrentEoToDoProspect(resp),
        )
      : setCurrentEoToDoProspect({});
    currentEoToDoEoCampaignId && fetchEoCampaign(dispatch, currentEoToDoEoCampaignId);
  }, [dispatch, currentEoToDoEoCampaignId, currentEoToDoProspectId]);

  const currentEoToDoEoCampaign = eoCampaigns.find(
    (eoCampaign) => eoCampaign.get('id') === currentEoToDoEoCampaignId,
  );

  function handleUpdateProspectInfo() {
    fetchEoToDos(dispatch, selectedEoToDoId);
    fetchProspect(dispatch, currentEoToDoProspectId).then((resp) => setCurrentEoToDoProspect(resp));
  }

  return (
    <EoToDoDetailsComponent
      currentEoCampaignStepPosition={currentEoCampaignStepPosition}
      currentEoToDoEoCampaign={currentEoToDoEoCampaign}
      currentEoToDoProspect={currentEoToDoProspect}
      handleUpdateProspectInfo={handleUpdateProspectInfo}
    />
  );
};

EoToDoDetailsContainer.propTypes = {
  currentEoCampaignStepPosition: PropTypes.number,
  currentEoToDoEoCampaignId: PropTypes.number,
  currentEoToDoProspectId: PropTypes.number,
  dispatch: PropTypes.func,
  eoCampaigns: eoCampaignsList,
  selectedEoToDoId: PropTypes.number,
};

function select(state) {
  const eoCampaigns = eoCampaignsSelector(state);

  return {
    eoCampaigns,
  };
}

export default ConnectStoreHOC(connect(select)(EoToDoDetailsContainer));
