import {
  fetchPercentageSchemesSuccess,
} from '../../actions/percentage_scheme_actions';

import {
  fetchPagePercentageSuccess,
} from '../../actions/brand_page_actions';


const handleCompetitorsSchemesReloadingStatus = (dispatch, data) => {
  const {
    brand_page,
    competitors_schemes_reloading_status,
    percentage_schemes,
  } = data;

  switch (competitors_schemes_reloading_status) {
    case 'finished':
      dispatch(fetchPercentageSchemesSuccess(percentage_schemes));
      brand_page && dispatch(fetchPagePercentageSuccess(brand_page.id, brand_page));

      break;
  }
};


export default handleCompetitorsSchemesReloadingStatus;
