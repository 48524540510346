import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';
import cx from 'classnames';
import { format, formatDistanceToNowStrict } from 'date-fns';

import EoCampaignActions from '../EoCampaignActions';

import columnsGenerator from 'common/tables/columns_generator';
import { addCSSClassToRowInTable, TABLE_ROW_ACTIVE_CLASS } from 'common/tables/utils';

import { eoCampaignsList } from 'common/propTypesShapes/eoCampaigns';
import { textColumn } from 'common/tables/columns';
import { translate } from 'common/i18n';

import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';

import buildCampaignStatusBadge from 'pages/EoCampaignPage/utils/buildCampaignStatusBadge';

import './eoCampaignsTable.scss';

const EoCampaignsTableComponent = ({
  eoCampaigns,
  isReadOnly,
  onTableHeaderClick,
  onRedirectToCampaign,
  onRowClick,
  sorting,
}) => {
  const eoCampaignsArray = eoCampaigns.toJS();

  const handleRedirectToCampaign =
    (_value, { rowData }) =>
    () => {
      const { id } = rowData;
      onRedirectToCampaign(id);
    };

  const headerValueFormatter =
    (options = {}) =>
    (_value, extra) => {
      const { sortBy, sortingOrder } = sorting;
      const { propertyFromOptions, withExplanationMessage } = options;
      const { property } = extra;

      const label = translate(`eoCampaign.table.headers.${property}`);

      if (isReadOnly) {
        return label;
      }

      return (
        <HeaderFieldWithSorting
          appModule="eoCampaigns"
          label={label}
          onClick={onTableHeaderClick}
          property={propertyFromOptions || property}
          sortBy={sortBy}
          sortingOrder={sortingOrder}
          withExplanationMessage={withExplanationMessage}
        />
      );
    };

  function statusCellValueFormatter(value) {
    return buildCampaignStatusBadge(value);
  }

  function titleCellValueFormatter(_value, { rowData }) {
    const { title, ownerEmail, createdAt } = rowData;

    return (
      <div className="eo-campaigns-table__campaign-info">
        <div className="eo-campaigns-table__campaign-title">{title}</div>
        <div className="eo-campaigns-table__campaign-owner">
          <span className="text_grey">{translate('eoCampaign.table.campaignInfo.ownerEmail')}</span>{' '}
          {ownerEmail}
        </div>
        <div className="eo-campaigns-table__campaign-created-at">
          <span className="text_grey">{translate('eoCampaign.table.campaignInfo.createdAt')}</span>{' '}
          {format(new Date(createdAt), 'MM/dd/y')}
        </div>
      </div>
    );
  }

  function percentageMetricCellValueFormatter(value, { rowData }) {
    const { uniqSentCount } = rowData;

    if (!value || !uniqSentCount) {
      return <div className="eo-campaigns-table__metric-main">&minus;</div>;
    }

    return (
      <div>
        <div className="eo-campaigns-table__metric-main">
          {((value / uniqSentCount) * 100).toFixed()}%
        </div>
        <div className="eo-campaigns-table__metric-minor">{value}</div>
      </div>
    );
  }

  function metricCellValueFormatter(value) {
    const processedValue = value || <>&minus;</>;

    return <div className="eo-campaigns-table__metric-main">{processedValue}</div>;
  }

  function lastEmailSentCellValueFormatter(value) {
    if (!value) {
      return <div className="text_grey">{translate('eoCampaign.table.noEmailsSent')}</div>;
    }

    return `${formatDistanceToNowStrict(new Date(value))} ago`;
  }

  function eoCampaignActionsCellValueFormatter(_value, { rowData }) {
    return <EoCampaignActions eoCampaignId={rowData.id} />;
  }

  function brandValueFormatter(value) {
    return value || translate('uiComponents.brandAssign.noBrandAssigned');
  }

  function generateColumns() {
    return columnsGenerator(
      [
        textColumn({
          name: 'title',
          headerValueFormatter: headerValueFormatter({ propertyFromOptions: 'createdAt' }),
          cellValueFormatter: titleCellValueFormatter,
          className: 'eo-campaigns-table__title-column',
          onClick: handleRedirectToCampaign,
        }),
        textColumn({
          name: 'status',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: statusCellValueFormatter,
          className: 'eo-campaigns-table__status-column',
          onClick: handleRedirectToCampaign,
        }),
        textColumn({
          name: 'brandName',
          // headerValueFormatter: headerValueFormatter(),
          headerLabel: translate('eoCampaign.table.headers.brandName'),
          cellValueFormatter: brandValueFormatter,
          className: 'eo-campaigns-table__brand-name-column',
        }),
        textColumn({
          name: 'uniqSentCount',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: metricCellValueFormatter,
          className: 'eo-campaigns-table__sent-column',
          onClick: handleRedirectToCampaign,
        }),
        // textColumn({
        //   name: 'uniqOpensCount',
        //   headerValueFormatter: headerValueFormatter(),
        //   cellValueFormatter: percentageMetricCellValueFormatter,
        //   className: 'eo-campaigns-table__opens-column',
        //   onClick: handleRedirectToCampaign,
        // }),
        // textColumn({
        //   name: 'uniqClicksCount',
        //   headerValueFormatter: headerValueFormatter(),
        //   cellValueFormatter: percentageMetricCellValueFormatter,
        //   className: 'eo-campaigns-table__clicks-column',
        //   onClick: handleRedirectToCampaign,
        // }),
        textColumn({
          name: 'uniqRepliesCount',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: percentageMetricCellValueFormatter,
          className: 'eo-campaigns-table__replies-column',
          onClick: handleRedirectToCampaign,
        }),
        textColumn({
          name: 'uniqBouncesCount',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: percentageMetricCellValueFormatter,
          className: 'eo-campaigns-table__bounces-column',
          onClick: handleRedirectToCampaign,
        }),
        textColumn({
          name: 'uniqPendingCount',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: metricCellValueFormatter,
          className: 'eo-campaigns-table__pending-column',
          onClick: handleRedirectToCampaign,
        }),
        textColumn({
          name: 'lastEmailSent',
          headerValueFormatter: headerValueFormatter(),
          cellValueFormatter: lastEmailSentCellValueFormatter,
          className: 'eo-campaigns-table__last-email-sent-column',
          onClick: handleRedirectToCampaign,
        }),
        !isReadOnly &&
          textColumn({
            headerLabel: translate('eoCampaign.table.headers.eoCampaignActions'),
            cellValueFormatter: eoCampaignActionsCellValueFormatter,
            className: 'eo-campaigns-table__eo-campaign-actions-column',
          }),
      ].filter(Boolean),
    );
  }

  function handleRowClick(row, rowIndex) {
    const tableNode = document.querySelector('.eo-campaigns-table');
    const activeRow = tableNode.querySelector(`.${TABLE_ROW_ACTIVE_CLASS}`);

    activeRow && activeRow.classList.remove(TABLE_ROW_ACTIVE_CLASS);

    addCSSClassToRowInTable(tableNode, rowIndex, TABLE_ROW_ACTIVE_CLASS);

    onRowClick(row.id);
  }

  function onBodyRow(row, { rowIndex }) {
    if (!isReadOnly) {
      return void 0;
    }

    return {
      onClick: () => handleRowClick(row, rowIndex),
    };
  }

  const columns = generateColumns();

  const tableClassNames = cx('eo-campaigns-table', {
    'table-with-row-selection': isReadOnly,
  });

  return (
    <Table.Provider className={tableClassNames} columns={columns}>
      <Table.Header />
      <Table.Body rowKey="id" rows={eoCampaignsArray} onRow={onBodyRow} />
    </Table.Provider>
  );
};

EoCampaignsTableComponent.propTypes = {
  eoCampaigns: eoCampaignsList,
  isReadOnly: PropTypes.bool,
  onRedirectToCampaign: PropTypes.func,
  onRowClick: PropTypes.func,
  onTableHeaderClick: PropTypes.func,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default EoCampaignsTableComponent;
