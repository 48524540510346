import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import Checkbox from 'components_linkio/Checkbox';
import InputComponent from 'components_linkio/input_component';
import Link from 'components_linkio/link';
import { Modal } from 'components_linkio/modal_component';
import SubmitButtonComponent from 'components/auth/submit_button_component';

import './confirmPasswordPopup.scss';

const initialState = {
  error: null,
  password: '',
  remember: false,
};

function reducer(state, action) {
  const { type, value } = action;

  const newState = cloneDeep(state);

  newState[type] = value;

  return newState;
}

const ConfirmPasswordPopupComponent = ({ onContinue }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  const setCheckboxValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.checked });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const error = await onContinue(state);

    if (!isEmpty(error)) {
      dispatch({ type: 'error', value: error });
    }
  }

  const { error, password, remember } = state;

  return (
    <Modal show className="confirm-password-popup">
      <Modal.Header>
        <Modal.Title>{translate('authForms.confirmPassword.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* hidden 'username' input is a workaround for password manager autofill getting the wrong input */}
        <input name="username" type="text" className="confirm-password-popup__hidden-username" />

        <InputComponent
          autoComplete="true"
          isMandatory
          label={translate('authForms.confirmPassword.confirmationRequest')}
          message={{ type: 'error', text: error }}
          name="password"
          onInputChange={setInputValue('password')}
          type="password"
          value={password}
        />

        <Checkbox checked={remember} onChange={setCheckboxValue('remember')}>
          {translate('authForms.signIn.inputs.rememberMe.label')}
        </Checkbox>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButtonComponent
          isBlue
          onClick={handleSubmit}
          text={translate('authForms.confirmPassword.buttons.continue')}
        />
        <Link to={Routes.destroy_user_session_path({ format: null })} data-method="delete">
          <ButtonComponent isWhite className="confirm-password-popup__logout_btn">
            {translate('authForms.confirmPassword.buttons.logout')}
          </ButtonComponent>
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmPasswordPopupComponent.propTypes = {
  onContinue: PropTypes.func,
};

export default ConfirmPasswordPopupComponent;
