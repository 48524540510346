import { getFromAPI, handleAPIError, patchToAPI } from './utils';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import { showSuccessMessage } from 'common/utils';
import { translate } from 'common/i18n';

import {
  fetchEoInboxesFailure,
  fetchEoInboxesStart,
  fetchEoInboxesSuccess,
} from 'actions/eoInboxesSettingsActions';

import {
  addOrRemoveEoCampaignInboxSuccess,
  addOrRemoveEoCampaignInboxFailure,
} from 'actions/eoInboxActions';

export async function fetchEoCampaignInboxes(dispatch, eoCampaignId) {
  dispatch(fetchEoInboxesStart());

  try {
    const resp = await getFromAPI(Routes.eo_campaigns_eo_inboxes_path(eoCampaignId));

    dispatch(fetchEoInboxesSuccess(resp.eo_inboxes));

    return resp.eo_inboxes;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchEoInboxesFailure,
      translate('eoInboxesSettings.messages.cantFetchEoInboxes'),
    );
  }
}

export async function addOrRemoveEoCampaignInbox(dispatch, params) {
  const { eoCampaignId, eoInboxId, eoInboxAction } = params;

  try {
    const resp = await patchToAPI(
      Routes.eo_campaigns_add_or_remove_eo_inbox_path(eoCampaignId, eoInboxId),
      {
        eo_inbox_action: eoInboxAction,
      },
    );

    showSuccessMessage(
      dispatch,
      translate('eoInboxes.messages.addOrRemoveEoCampaignInboxSuccess')(eoInboxAction),
    );

    dispatch(addOrRemoveEoCampaignInboxSuccess(resp));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      addOrRemoveEoCampaignInboxFailure,
      translate('eoInboxes.messages.addOrRemoveEoCampaignInboxFailure')(eoInboxAction),
    );
  }
}
