import { showSuccessMessage } from '../common/utils';
import { translate } from '../common/i18n';

import {
  enqueueBrandsDlsCrawlingFailure,
  enqueueBrandsDlsCrawlingStart,
  enqueueDestinationLinkCrawlingFailure,
  enqueueDestinationLinkCrawlingStart,
  enqueuePageDlsCrawlingFailure,
  enqueuePageDlsCrawlingStart,
  fetchCrawlersStatusFailure,
  fetchCrawlersStatusStart,
  fetchCrawlersStatusSuccess,
  fetchCrawlerStatusDataFailure,
  fetchCrawlerStatusDataStart,
  fetchCrawlerStatusDataSuccess,
} from '../actions/crawler_actions';

import {
  getFromAPI,
  handleAPIError,
  patchToAPI,
} from './utils';

async function fetchCrawlersStatus(dispatch) {
  dispatch(fetchCrawlersStatusStart());

  try {
    const resp = await getFromAPI(
      Routes.status_crawlers_path()
    );

    dispatch(fetchCrawlersStatusSuccess(resp.crawlers_status));

    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, fetchCrawlersStatusFailure, translate('errors.cantFetchCrawlersStatus'));
  }
}

async function enqueuePageDlsCrawling(dispatch, brandPageId) {
  dispatch(enqueuePageDlsCrawlingStart(brandPageId));

  try {
    await patchToAPI(
      Routes.crawl_brand_page_dls_crawlers_path(),
      { brand_page_id: brandPageId }
    );
    showSuccessMessage(dispatch, translate('crawlingApiCalls.messages.brandPageDlsCrawlingEnqueued'));
    return {};
  } catch (err) {
    return handleAPIError(dispatch, err, enqueuePageDlsCrawlingFailure, translate('errors.cantEnqueuePageDlsCrawling'));
  }
}

async function enqueueBrandsDlsCrawling(dispatch, brandIds) {
  dispatch(enqueueBrandsDlsCrawlingStart(brandIds));
  
  try {
    await patchToAPI(
      Routes.crawl_brands_dls_crawlers_path(),
      { brand_ids: brandIds }
    );
    showSuccessMessage(dispatch, translate('crawlingApiCalls.messages.brandPageDlsCrawlingEnqueued'));
    return {};
  } catch (err) {
    return handleAPIError(dispatch, err, enqueueBrandsDlsCrawlingFailure, translate('errors.cantEnqueuePageDlsCrawling'));
  }
}

async function enqueueDestinationLinkCrawling(dispatch, brandPageId, destinationLinkId) {
  dispatch(enqueueDestinationLinkCrawlingStart(destinationLinkId));
  showSuccessMessage(dispatch, translate('crawlingApiCalls.messages.destinationLinkCrawlingEnqueued'));

  try {
    await patchToAPI(
      Routes.crawl_dl_crawlers_path(),
      {
        brand_page_id: brandPageId,
        destination_link_id: destinationLinkId,
      }
    );

    return {};
  } catch (err) {
    return handleAPIError(dispatch, err, enqueueDestinationLinkCrawlingFailure, translate('errors.cantEnqueueDestinationLinkCrawling'));
  }
}

async function fetchCrawlerStatusData(dispatch) {
  dispatch(fetchCrawlerStatusDataStart());

  try {
    const resp = await getFromAPI(
      Routes.sidekiq_queues_size_path()
    );

    dispatch(fetchCrawlerStatusDataSuccess(resp));
  } catch (err) {
    handleAPIError(dispatch, err, fetchCrawlerStatusDataFailure, translate('errors.cantFetchCrawlerStatusData'));
  }
}

export {
  enqueueBrandsDlsCrawling,
  enqueueDestinationLinkCrawling,
  enqueuePageDlsCrawling,
  fetchCrawlersStatus,
  fetchCrawlerStatusData,
};
