const CREATE_PERCENTAGE_SCHEME__FAILURE = 'CREATE_PERCENTAGE_SCHEME__FAILURE';
const CREATE_PERCENTAGE_SCHEME__START = 'CREATE_PERCENTAGE_SCHEME__START';
const CREATE_PERCENTAGE_SCHEME__SUCCESS = 'CREATE_PERCENTAGE_SCHEME__SUCCESS';
const DELETE_PERCENTAGE_SCHEME__FAILURE = 'DELETE_PERCENTAGE_SCHEME__FAILURE';
const DELETE_PERCENTAGE_SCHEME__START = 'DELETE_PERCENTAGE_SCHEME__START';
const DELETE_PERCENTAGE_SCHEME__SUCCESS = 'DELETE_PERCENTAGE_SCHEME__SUCCESS';
const FETCH_PERCENTAGE_SCHEMES__FAILURE = 'FETCH_PERCENTAGE_SCHEMES__FAILURE';
const FETCH_PERCENTAGE_SCHEMES__START = 'FETCH_PERCENTAGE_SCHEMES__START';
const FETCH_PERCENTAGE_SCHEMES__SUCCESS = 'FETCH_PERCENTAGE_SCHEMES__SUCCESS';
const RELOAD_COMPETITORS_PERCENTAGE_SCHEMES__FAILURE = 'RELOAD_COMPETITORS_PERCENTAGE_SCHEMES__FAILURE';
const UPDATE_PERCENTAGE_SCHEME__FAILURE = 'UPDATE_PERCENTAGE_SCHEME__FAILURE';
const UPDATE_PERCENTAGE_SCHEME__START = 'UPDATE_PERCENTAGE_SCHEME__START';
const UPDATE_PERCENTAGE_SCHEME__SUCCESS = 'UPDATE_PERCENTAGE_SCHEME__SUCCESS';


function fetchPercentageSchemesFailure(errors) {
  return { type: FETCH_PERCENTAGE_SCHEMES__FAILURE, errors };
}

function fetchPercentageSchemesStart() {
  return { type: FETCH_PERCENTAGE_SCHEMES__START };
}

function fetchPercentageSchemesSuccess(percentageSchemes) {
  return { type: FETCH_PERCENTAGE_SCHEMES__SUCCESS, percentageSchemes };
}

function createPercentageSchemeFailure(errors) {
  return { type: CREATE_PERCENTAGE_SCHEME__FAILURE, errors };
}

function createPercentageSchemeStart() {
  return { type: CREATE_PERCENTAGE_SCHEME__START };
}

function createPercentageSchemeSuccess(percentageScheme) {
  return { type: CREATE_PERCENTAGE_SCHEME__SUCCESS, percentageScheme };
}

function reloadCompetitorsPercentageSchemesFailure(errors) {
  return { type: RELOAD_COMPETITORS_PERCENTAGE_SCHEMES__FAILURE, errors };
}

function updatePercentageSchemeFailure(errors) {
  return { type: UPDATE_PERCENTAGE_SCHEME__FAILURE, errors };
}

function updatePercentageSchemeStart() {
  return { type: UPDATE_PERCENTAGE_SCHEME__START };
}

function updatePercentageSchemeSuccess(percentageScheme) {
  return { type: UPDATE_PERCENTAGE_SCHEME__SUCCESS, percentageScheme };
}

function deletePercentageSchemeFailure(errors) {
  return { type: DELETE_PERCENTAGE_SCHEME__FAILURE, errors };
}

function deletePercentageSchemeStart() {
  return { type: DELETE_PERCENTAGE_SCHEME__START };
}

function deletePercentageSchemeSuccess(percentageSchemeId) {
  return { type: DELETE_PERCENTAGE_SCHEME__SUCCESS, percentageSchemeId };
}


export {
  CREATE_PERCENTAGE_SCHEME__FAILURE,
  CREATE_PERCENTAGE_SCHEME__START,
  CREATE_PERCENTAGE_SCHEME__SUCCESS,
  DELETE_PERCENTAGE_SCHEME__FAILURE,
  DELETE_PERCENTAGE_SCHEME__START,
  DELETE_PERCENTAGE_SCHEME__SUCCESS,
  FETCH_PERCENTAGE_SCHEMES__FAILURE,
  FETCH_PERCENTAGE_SCHEMES__START,
  FETCH_PERCENTAGE_SCHEMES__SUCCESS,
  RELOAD_COMPETITORS_PERCENTAGE_SCHEMES__FAILURE,
  UPDATE_PERCENTAGE_SCHEME__FAILURE,
  UPDATE_PERCENTAGE_SCHEME__START,
  UPDATE_PERCENTAGE_SCHEME__SUCCESS,
  createPercentageSchemeFailure,
  createPercentageSchemeStart,
  createPercentageSchemeSuccess,
  deletePercentageSchemeFailure,
  deletePercentageSchemeStart,
  deletePercentageSchemeSuccess,
  fetchPercentageSchemesFailure,
  fetchPercentageSchemesStart,
  fetchPercentageSchemesSuccess,
  reloadCompetitorsPercentageSchemesFailure,
  updatePercentageSchemeFailure,
  updatePercentageSchemeStart,
  updatePercentageSchemeSuccess,
};
