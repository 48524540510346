import React from 'react';
import PropTypes from 'prop-types';
import { map, cloneDeep, filter } from 'lodash';
import classnames from 'classnames';

import GroupedPercentage from './GroupedPercentage';
import ChangePercentagesButton from './ChangePercentagesButton';

import { translate } from 'common/i18n';
import { optionsMap, percentageShape, subscriptionPoliciesShape } from 'common/prop_types_shapes';

import ButtonComponent from 'components_linkio/button_component';
import MinusInRoundIcon from 'common/icons/minus_in_round';
import PlusInRoundIcon from 'common/icons/plus_in_round';

import './dlsPercentage.scss';

export default class DlsPercentageComponent extends React.PureComponent {
  static propTypes = {
    currentPercentagesSchemeId: PropTypes.number.isRequired,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']),
    pageId: PropTypes.number.isRequired,
    pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
    percentage: PropTypes.arrayOf(percentageShape).isRequired,
    percentageSchemeOptions: optionsMap,
    subscriptionPolicies: subscriptionPoliciesShape,
    uncategorizedAnchorsCount: PropTypes.number.isRequired,
  };

  state = {
    filterOptions: {
      includeStarted: false,
      includeNotStarted: false,
    },
    percentageGroupsStatus: {
      branded: { isOpen: false },
      keyword: { isOpen: false },
      hybrid: { isOpen: false },
      url: { isOpen: false },
      natural: { isOpen: false },
    },
  };

  handleToggleButtonClick = (event) => {
    const {
      target: { name, checked },
    } = event;
    const { filterOptions } = this.state;

    const clonedFilterOptions = cloneDeep(filterOptions);
    clonedFilterOptions[name] = checked;

    this.setState({ filterOptions: clonedFilterOptions });
  };

  currentPercentage = () => {
    //Get state values and parse current checkbox selects
    switch (Object.values(this.state.filterOptions).join('')) {
      case 'truetrue':
        return 'publishedStartedNotStarted';
      case 'truefalse':
        return 'publishedStarted';
      case 'falsetrue':
        return 'publishedNotStarted';
      default:
        return 'published';
    }
  };

  groupPercentagesByType = (percentage) => {
    return {
      branded: {
        title: translate('brandPagePercentage.percentageGroup.branded'),
        percentage: [...percentage.filter((item) => item.anchorGroup === 'branded')],
      },
      keyword: {
        title: translate('brandPagePercentage.percentageGroup.keyword'),
        percentage: [...percentage.filter((item) => item.anchorGroup === 'keyword')],
      },
      hybrid: {
        title: translate('brandPagePercentage.percentageGroup.hybrid'),
        percentage: [...percentage.filter((item) => item.anchorGroup === 'hybrid')],
      },
      url: {
        title: translate('brandPagePercentage.percentageGroup.url'),
        percentage: [...percentage.filter((item) => item.anchorGroup === 'url')],
      },
      natural: {
        title: translate('brandPagePercentage.percentageGroup.natural'),
        percentage: [...percentage.filter((item) => item.anchorGroup === 'natural')],
      },
    };
  };

  handleGroupPercentageClick = (title) => () => {
    const { percentageGroupsStatus } = this.state;
    const clonedPercentageGroupsStatus = cloneDeep(percentageGroupsStatus);
    clonedPercentageGroupsStatus[title].isOpen = !percentageGroupsStatus[title].isOpen;
    this.setState({ percentageGroupsStatus: clonedPercentageGroupsStatus });
  };

  handleAnchorsIconClick = () => {
    this.isAllPercentageOpen()
      ? this.changeAllPercentagesStatus({ isOpen: false })
      : this.changeAllPercentagesStatus({ isOpen: true });
  };

  changeAllPercentagesStatus = ({ isOpen }) => {
    const { percentageGroupsStatus } = this.state;
    const clonedPercentageGroupsStatus = cloneDeep(percentageGroupsStatus);

    Object.keys(percentageGroupsStatus).forEach(
      (key) => (clonedPercentageGroupsStatus[key].isOpen = isOpen),
    );

    this.setState({ percentageGroupsStatus: clonedPercentageGroupsStatus });
  };

  isAllPercentageOpen = () => {
    const { percentageGroupsStatus } = this.state;
    const percentageGroupsStatusLength = Object.keys(percentageGroupsStatus).length;
    return (
      filter(percentageGroupsStatus, (group) => group.isOpen).length ===
      percentageGroupsStatusLength
    );
  };

  uncategorizedAnchorsIsVisible = () => {
    const { subscriptionPolicies, uncategorizedAnchorsCount } = this.props;

    if (uncategorizedAnchorsCount <= 0) {
      return false;
    }

    const canUseCategorizerInSubscription = subscriptionPolicies.getIn(
      ['categorizer', 'canUse'],
      false,
    );
    const canUseCrawlersInSubscription = subscriptionPolicies.getIn(['crawlers', 'canUse'], false);
    if (canUseCategorizerInSubscription && canUseCrawlersInSubscription) {
      return false;
    }

    return true;
  };

  render() {
    const {
      currentPercentagesSchemeId,
      pageChildType,
      pageId,
      pageParentType,
      percentage,
      percentageSchemeOptions,
      uncategorizedAnchorsCount,
    } = this.props;

    const {
      filterOptions: { includeStarted, includeNotStarted },
      percentageGroupsStatus,
    } = this.state;

    const currentPercentage = this.currentPercentage();
    const groupedPercentages = this.groupPercentagesByType(percentage);

    const percentageIcon = this.isAllPercentageOpen() ? (
      <MinusInRoundIcon className="percentages__icon_red" />
    ) : (
      <PlusInRoundIcon className="percentages__icon_green" />
    );

    const isBrandPage = pageParentType === 'brand';

    const currentPercentagesSchemeLabel = percentageSchemeOptions.getIn([
      String(currentPercentagesSchemeId),
      'label',
    ]);

    const percentagesClasses = classnames({
      percentages: true,
      percentages_competitors: !isBrandPage,
    });

    return (
      <div className={percentagesClasses}>
        <div className="percentages__block-wrapper">
          <h3 className="percentages__title percentages__text percentages__text_title">
            {translate('brandPagePercentage.title')}
          </h3>
        </div>

        {isBrandPage && this.uncategorizedAnchorsIsVisible() && (
          <div className="percentages__block-wrapper ">
            <div className="percentages__uncategorised percentages__text">
              <span className="percentages__exclamation-mark">!</span>
              {translate('brandPagePercentage.uncategorizedAnchors')}
              <span className="percentages__uncategorised-count">{uncategorizedAnchorsCount}</span>
            </div>
          </div>
        )}

        <div className="percentages__block-wrapper ">
          <div className="percentages__change-percentages-wrapper">
            <h4>{translate('brandPagePercentage.changeIdealPercentages.title')}</h4>

            <span className="percentages__current-percentages-scheme">
              {currentPercentagesSchemeLabel}
            </span>

            <ChangePercentagesButton
              pageChildType={pageChildType}
              pageParentId={pageId}
              pageParentType={pageParentType}
            />
          </div>
        </div>

        <table className="percentages__anchors-table">
          <thead>
            <tr className="percentages__anchor-row percentages__anchor-row_head">
              <th className="percentages__icon" onClick={this.handleAnchorsIconClick}>
                {percentageIcon}
              </th>
              <th className="percentages__text_align-left">
                <span>{translate('brandPagePercentage.anchorType')}</span>
              </th>
              <th>
                <span>{translate('brandPagePercentage.actual')}</span>
              </th>
              <th>
                <div className="percentages__anchor-row_head-ideal">
                  <span>{translate('brandPagePercentage.ideal')}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {map(groupedPercentages, (groupedPercentage, key) => (
              <GroupedPercentage
                key={key}
                currentPercentage={currentPercentage}
                groupedPercentage={groupedPercentage}
                isOpen={percentageGroupsStatus[key].isOpen}
                onClick={this.handleGroupPercentageClick(key)}
              />
            ))}
          </tbody>
        </table>

        {isBrandPage && (
          <div className="percentages__block-wrapper">
            <p>{translate('brandPagePercentage.buttons.switchButtons.title')}</p>

            <div className="toggle-button-group">
              <ButtonComponent
                asToggle
                name={`includeStarted`}
                isActive={includeStarted}
                onClick={this.handleToggleButtonClick}
                isWhite
              >
                {translate('brandPagePercentage.includeStartedLinks')}
              </ButtonComponent>
              <ButtonComponent
                asToggle
                name={`includeNotStarted`}
                isActive={includeNotStarted}
                onClick={this.handleToggleButtonClick}
                isWhite
              >
                {translate('brandPagePercentage.includeNotStartedLinks')}
              </ButtonComponent>
            </div>
          </div>
        )}
      </div>
    );
  }
}
