export default (args) => {
  return {
    numberOfBacklinksAnalysis: {
      table: {
        title: 'Estimated Number of Links To Build',
        rows: {
          pageMetrics: {
            title: 'Page Related Metrics',
          },
          countReferringDomains: {
            title: 'Referring Domains That Count',
          },
          dfAndIndexedReferringDomains: {
            title: 'DF and Indexed Referring Domains',
          },
          domainMetrics: {
            title: 'Domain Related Metrics',
          },
          domainRefdomainsCount: {
            title: 'Overall Referring Domains',
          },
          homepageRefdomainsCount: {
            title: 'Homepage Referring Domains',
          },
          countTowardAverages: {
            title: 'Count Toward Averages',
          },
          estimatedOverallTargetRd: {
            title: 'Estimated Overall Target RD',
          },
          pendingBacklinksToBuild: {
            title: 'Pending Links to Build',
          },
        },
        columns: {
          competitors: {
            title: 'Competitors',
          },
          average: {
            title: 'Average',
          },
          myBrand: {
            title: 'My Brand',
          },
        },
      },
      editButtonTitle: 'Edit Links To Build',
    },
  };
};
