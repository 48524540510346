import { translate } from 'common/i18n';
import { showSuccessMessage } from 'common/utils';

import {
  deletePageFailure,
  deletePageStart,
  deletePageSuccess,
  fetchAhrefsLimitsFailure,
  fetchAhrefsLimitsStart,
  fetchAhrefsLimitsSuccess,
  fetchBrandPagesForFilterByBrandIdFailure,
  fetchBrandPagesForFilterByBrandIdSuccess,
  enqueueAhrefsMetricsUploadingStart,
  enqueueAhrefsMetricsUploadingSuccess,
  enqueueAhrefsMetricsUploadingFailure,
  enqueueMozMetricsUploadingStart,
  enqueueMozMetricsUploadingSuccess,
  enqueueMozMetricsUploadingFailure,
  fetchCompetitorPagesPercentagesFailure,
  fetchCompetitorPagesPercentagesStart,
  fetchCompetitorPagesPercentagesSuccess,
  fetchPagePercentageFailure,
  fetchPagePercentageStart,
  fetchPagePercentageSuccess,
  fetchPagesFailure,
  fetchPagesStart,
  fetchPagesSuccess,
  fetchSinglePageFailure,
  fetchSinglePageStart,
  fetchSinglePageSuccess,
  reDistributeDestinationLinksFailure,
  reDistributeDestinationLinksStart,
  updatePageFailure,
  updatePageStart,
  updatePageSuccess,
  enqueGenerateBrandPageMetricStart,
  enqueGenerateBrandPageMetricSuccess,
  enqueGenerateBrandPageMetricFailure
} from 'actions/brand_page_actions';

import { fetchBrandsContextSuccess } from 'actions/brandsContextActions';
import { fetchPagesPagySuccess } from 'actions/pagy_actions';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToCamelCase,
  processObjToSnakeCase,
} from 'api/utils';

export async function fetchPage(dispatch, brandPageId) {
  dispatch(fetchSinglePageStart());

  try {
    const resp = await getFromAPI(Routes.brand_page_path({ id: brandPageId }));
    dispatch(fetchSinglePageSuccess(resp));
    return processBrandPage(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchSinglePageFailure,
      translate('errors.cantFetchPages'),
    );
  }
}

export async function fetchPages(dispatch, pageParentType, pageParentId, { page, items, search }) {
  dispatch(fetchPagesStart());

  try {
    const params = {
      items,
      page,
      pageParentId,
      pageParentType,
      search,
    };

    const resp = await postToAPI(
      Routes.all_records_brand_pages_path(),
      processObjToSnakeCase(params),
    );
    dispatch(fetchPagesSuccess(resp.brand_pages, pageParentType));

    // Update pagy only for brand pages
    if (pageParentType === 'brand') {
      dispatch(fetchBrandsContextSuccess(pageParentId, resp.brand_context));
      dispatch(fetchPagesPagySuccess(pageParentId, resp.pagy));
    }
    return resp.brand_pages;
  } catch (err) {
    return handleAPIError(dispatch, err, fetchPagesFailure, translate('errors.cantFetchPages'));
  }
}

export async function fetchSinglePage(dispatch, pageId) {
  dispatch(fetchSinglePageStart());

  try {
    const resp = await getFromAPI(Routes.brand_page_path({ id: pageId }));
    dispatch(fetchSinglePageSuccess(resp));
    return processBrandPage(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchSinglePageFailure,
      translate('errors.cantFetchPages'),
    );
  }
}

export async function updatePage(dispatch, brandPageId, data) {
  dispatch(updatePageStart());

  try {
    const resp = await patchToAPI(
      Routes.brand_page_path({
        id: brandPageId,
        _options: true,
      }),
      brandObject(data),
    );
    dispatch(updatePageSuccess(resp));
    return processBrandPage(resp);
  } catch (err) {
    return handleAPIError(dispatch, err, updatePageFailure, translate('errors.cantUpdatePage'));
  }
}

export async function updatePagePriority(dispatch, brandPageId, value) {
  dispatch(updatePageStart());

  try {
    const resp = await patchToAPI(
      Routes.update_priority_brand_page_path({
        id: brandPageId,
        _options: true,
      }),
      { priority: value },
    );
    dispatch(updatePageSuccess(resp));
    return processBrandPage(resp);
  } catch (err) {
    return handleAPIError(dispatch, err, updatePageFailure, translate('errors.cantUpdatePage'));
  }
}

export async function deletePage(dispatch, id) {
  dispatch(deletePageStart());
  try {
    const resp = await deleteToAPI(Routes.brand_page_path({ id, _options: true }));
    dispatch(deletePageSuccess({ id }));
    showSuccessMessage(dispatch, translate('brandPage.messages.deleteSuccessfully'));
    return processBrandPage(resp);
  } catch (err) {
    return handleAPIError(dispatch, err, deletePageFailure, translate('errors.cantDeletePage'));
  }
}

export async function fetchAhrefsLimits(dispatch, brandPageId) {
  dispatch(fetchAhrefsLimitsStart());

  try {
    const resp = await getFromAPI(
      Routes.fetch_limits_ahrefs_requests_path({
        brand_page_id: brandPageId,
        _options: true,
      }),
    );
    dispatch(fetchAhrefsLimitsSuccess(resp));
    return processObjToCamelCase(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchAhrefsLimitsFailure,
      translate('errors.cantFetchAhrefsLimits'),
    );
  }
}

export async function enqueueAhrefsMetricsUploading(dispatch, pageIds) {
  dispatch(enqueueAhrefsMetricsUploadingStart());

  try {
    await postToAPI(Routes.fetch_pages_metrics_ahrefs_requests_path({ page_ids: pageIds }));
    dispatch(enqueueAhrefsMetricsUploadingSuccess());
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      enqueueAhrefsMetricsUploadingFailure,
      translate('errors.cantEnqueueAhrefsMetricsUploading'),
    );
  }
}

export async function enqueuePageGenerateMetric(dispatch, brandPageId){

  dispatch(enqueGenerateBrandPageMetricStart(brandPageId));

  try {
    const resp = await patchToAPI(
      Routes.generate_brand_page_metric_brand_page_path({ id:brandPageId }),
      { brand_page_id:brandPageId }
    );
    dispatch(enqueGenerateBrandPageMetricSuccess(brandPageId))
    showSuccessMessage(dispatch, translate('brandPageCalculateMetricsEnque.messages.brandPageMetric'));
    return {};
  } catch (err) {
    
    return handleAPIError(dispatch, err, enqueGenerateBrandPageMetricFailure, translate('errors.cantEnqueueBrandPageMetricsGeneration'));
  }
}

export async function enqueueMozMetricsUploading(dispatch, pageIds) {
  dispatch(enqueueMozMetricsUploadingStart());

  try {
    await postToAPI(Routes.fetch_pages_metrics_moz_requests_path({ page_ids: pageIds }));
    dispatch(enqueueMozMetricsUploadingSuccess());
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      enqueueMozMetricsUploadingFailure,
      translate('errors.cantEnqueueMozMetricsUploading'),
    );
  }
}

export async function fetchPagePercentage(dispatch, pageId) {
  dispatch(fetchPagePercentageStart());

  try {
    const resp = await getFromAPI(
      Routes.current_percentage_brand_page_path({
        id: pageId,
        _options: true,
      }),
    );
    dispatch(fetchSinglePageSuccess(resp));
    // TODO: don't store percentages in Redux apart from brand page
    dispatch(fetchPagePercentageSuccess(pageId, resp));
    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchPagePercentageFailure,
      translate('errors.cantFetchPagePercentage'),
    );
  }
}

export async function fetchCompetitorPagesPercentages(dispatch, pageId) {
  dispatch(fetchCompetitorPagesPercentagesStart());

  try {
    const resp = await getFromAPI(Routes.competitors_percentages_brand_page_path({ id: pageId }));
    dispatch(fetchCompetitorPagesPercentagesSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchCompetitorPagesPercentagesFailure,
      translate('errors.cantFetchCompetitorPagesPercentages'),
    );
  }
}

export async function reDistributeDls(dispatch, brandPageId) {
  dispatch(reDistributeDestinationLinksStart());

  try {
    const resp = await patchToAPI(Routes.redistribute_dls_brand_page_path({ id: brandPageId }));
    dispatch(fetchSinglePageSuccess(resp));
    return processBrandPage(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      reDistributeDestinationLinksFailure,
      translate('errors.cantRedistributeDestinationLinks'),
    );
  }
}

const brandObject = (data) => ({ brand_page: processObjToSnakeCase(data) });
const processBrandPage = (brandPage) => ({
  ...processObjToCamelCase(brandPage),
  errors: { ...processObjToCamelCase(brandPage.errors) },
});

export async function fetchBrandPagesForFilterByBrandId(dispatch, brandId) {
  try {
    const resp = await getFromAPI(Routes.brand_pages_as_options_path({ id: brandId }));
    const rawBrandPages = processObjToCamelCase(resp || {}).brandPages || [];
    const processedBrandPages = rawBrandPages.map(({ id, link }) => ({
      value: String(id),
      label: link,
    }));

    dispatch(fetchBrandPagesForFilterByBrandIdSuccess(processedBrandPages));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchBrandPagesForFilterByBrandIdFailure,
      translate('errors.cantFetchBrandPagesForFilterByBrandId'),
    );
  }
}
