import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { eoInboxesList } from 'common/propTypesShapes/eoInboxesSettings';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

import './eoInboxesPage.scss';

const EoInboxesPageComponent = ({ eoInboxes, onAddOrRemoveEoInbox }) => {
  const eoInboxesArray = eoInboxes.toJS();

  const handleClickAddOrRemoveInbox = (eoInboxId, eoInboxAction) => () => {
    onAddOrRemoveEoInbox(eoInboxId, eoInboxAction);
  };

  return (
    <>
      <h3>{translate('eoInboxes.title')}</h3>
      <p>{translate('eoInboxes.subtitle')}</p>

      <ul className="eo-inboxes-page__inboxes">
        {eoInboxesArray.map((inbox) => {
          const { id, belongsToCurrentEoCampaign, email, name } = inbox;

          const inboxesItemStyles = cx('eo-inboxes-page__inboxes-item', {
            'eo-inboxes-page__inboxes-item_active': belongsToCurrentEoCampaign,
          });

          const btnLabel = belongsToCurrentEoCampaign
            ? translate('eoInboxes.buttons.removeInbox')
            : translate('eoInboxes.buttons.useInbox');

          return (
            <li key={id} className={inboxesItemStyles}>
              <div>
                <p className="text_two-lines-with-ellipsis-break-word">{name}</p>
                <p className="text_two-lines-with-ellipsis-break-all">{email}</p>
              </div>

              <ButtonComponent
                isInitial
                isGreen={!belongsToCurrentEoCampaign}
                isRed={belongsToCurrentEoCampaign}
                onClick={handleClickAddOrRemoveInbox(
                  id,
                  belongsToCurrentEoCampaign ? 'remove' : 'add',
                )}
              >
                {btnLabel}
              </ButtonComponent>
            </li>
          );
        })}
      </ul>
    </>
  );
};

EoInboxesPageComponent.propTypes = {
  eoInboxes: eoInboxesList,
  onAddOrRemoveEoInbox: PropTypes.func,
};

export default EoInboxesPageComponent;
