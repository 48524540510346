import React from 'react';

const ImportIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="M7.44 9.36L5.04 6.8a.773.773 0 0 1 0-1.12c.32-.32.8-.32 1.12 0L7.2 6.8v-6c0-.48.32-.8.8-.8.48 0 .8.32.8.8v6l1.04-1.12c.32-.32.8-.32 1.12 0 .32.32.32.8 0 1.12l-2.4 2.56c-.24.32-.8.32-1.12 0zm8.48 2.32c.08.08.08.24.08.32v3.2c0 .48-.32.8-.8.8H.8c-.48 0-.8-.32-.8-.8V12c0-.08 0-.24.08-.32l2.4-5.6c.16-.4.64-.56 1.04-.4.4.16.56.64.4 1.04L2 11.2h12l-1.92-4.48c-.16-.4 0-.88.4-1.04.4-.16.88 0 1.04.4l2.4 5.6zM14.4 14.4v-1.6H1.6v1.6h12.8z" fillRule="evenodd" />
    </svg>
  );
};

ImportIcon.defaultProps = {
  fill: "#000",
  height: '16px',
  width: '16px',
};

export default ImportIcon;
