import pluralize from 'pluralize';

export default {
  brandPage: {
    messages: {
      ahrefsLimitReached:
        "Automatic backlink import from Ahrefs temporarily won't work \
        because your monthly Ahrefs API credits are spent.",
      ahrefsFreeAccount:
        'You have a FREE Ahrefs account so API importing will not work. \
        Upgrade to a paid Ahrefs account to enable this feature.',
      assignNewPercentageSchemeSuccessfully:
        'New ideal percentages set. Refresh your anchor text suggestions.',
      competitorsAveragePercentageChangedSuccessfully: 'Competitors average percentage is changed.',
      createSuccessfully: (pagesCount) => {
        if (pagesCount) {
          return `Successfully added ${pluralize('page', pagesCount, true)}.`;
        }
        return 'Successfully added a page.';
      },
      deleteSuccessfully: 'Page was deleted successfully.',
      linksImportedFromAhrefsSuccessfully: (args) =>
        `Successfully imported ${pluralize('anchor', args.uniqEntriesCount, true)},
        ${pluralize('duplicate', args.dupeEntriesCount, true)} skipped.`,
      linksImportedFromMozSuccessfully: (args) =>
        `Successfully imported ${pluralize('anchor', args.uniqEntriesCount, true)},
        ${pluralize('duplicate', args.dupeEntriesCount, true)} skipped.`,
    },
    destinationLink: {
      buttons: {
        exportCSV: 'Export to CSV',
      },
    },
    metrics: {
      competitorsCount: {
        title: 'Competitors',
      },
      countReferringDomains: {
        title: 'Referring Domains',
      },
      averageKeywordsRank: {
        title: 'Avg. Rank',
      },
      linkioScore: {
        title: 'Anchor Text Score',
      },
    },
    buttons: {
      actions: 'Actions',
      addPageAndGenerateRoadmap: 'Add Page & Generate Roadmap',
      addPage: 'Add New',
      categorize: 'Categorize',
      crawl: 'Crawl Now',
      delete: 'Delete',
      edit: 'Edit',
      import: 'Import',
      generateMetrics: 'Build Metrics'
    },
    keywords: {
      title: 'Keywords',
    },
    link: {
      title: 'Page URL',
    },
    pageTypeId: {
      title: 'Page Type',
    },
    priority: {
      title: 'Priority',
      placeholder: 'Priority...',
      high: {
        title: 'High',
      },
      medium: {
        title: 'Medium',
      },
      low: {
        title: 'Low',
      },
      lowest: {
        title: 'Lowest',
      },
      noMoreLinks: {
        title: 'No More Links',
      },
    },
    switchers: {
      showAtpSuggestions: 'Show anchor text suggestions',
    },
    addPageModal: {
      title: 'Add New Page',
      subtitle: 'Create a page for the selected brand',
      inputs: {
        city: {
          label: 'City',
        },
        keywords: {
          label: 'Keywords',
          hint: 'Please add one keyword per line',
        },
        pageUrl: {
          label: 'Page URL',
        },
        pageTypeId: {
          label: 'Page type',
        },
        product: {
          label: 'Product/Service',
        },
        state: {
          label: 'State',
        },
        stateAbbr: {
          label: 'State abbreviation',
        },
      },
      buttons: {
        cancel: 'Cancel',
        saveAndGenerate: 'Save & Generate',
      },
    },
    editPageModal: {
      title: 'Edit Page',
      subtitle: 'Edit the page for the selected brand',
      inputs: {
        city: {
          label: 'City',
        },
        keywords: {
          label: 'Page Keywords',
        },
        pageUrl: {
          label: 'Page URL',
        },
        pageTypeId: {
          label: 'Page type',
        },
        product: {
          label: 'Product/Service',
        },
        state: {
          label: 'State',
        },
        stateAbbr: {
          label: 'State abbreviation',
        },
      },
      buttons: {
        cancel: 'Close',
        save: 'Save',
      },
    },
    blankState: {
      titleText: "It looks like you don't have any pages.",
      bodyText: 'You can easily add new pages by clicking the button below.',
    },
    noDlsComponent: {
      tabs: {
        backlinks: {
          title: 'What Linkio Does',
          body: `Once you import your backlinks (if you have any), Linkio will crawl and check \
          their index status, calculate your anchor text percentages, and show you what anchors \
          to build next. Get started by clicking the import button.`,
        },
        suggestions: {
          title: 'No suggestions, eh?',
          body: 'Add some rows on the top of the table. Based on your current backlinks Linkio offers what to build next.',
        },
        in_progress: {
          title: 'Nothing is in progress',
          body: `Once you start working on the backlink, change its status to "Started" so that \
          you can easily access your work in progress from this tab.`,
        },
      },
    },
  },
};
