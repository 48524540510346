import { addBacklinksSuccess } from '../../actions/backlinkActions';

const handleBacklinksAhrefsMetricsUploadingStatus = (dispatch, data) => {
  const { backlinksAhrefsMetricsUploadingStatus } = data;

  switch (backlinksAhrefsMetricsUploadingStatus) {
    case 'started':
    case 'finished':
      dispatch(addBacklinksSuccess(data.brandId, [data]));

      break;
    case 'failure':
      dispatch(addBacklinksSuccess(data.brandId, [data]));

      // TODO: hanle possible error(s)
      break;
  }
};

export default handleBacklinksAhrefsMetricsUploadingStatus;
