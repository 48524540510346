import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';
import { optionsList } from 'common/prop_types_shapes';

import Select from 'components_linkio/Select';

import AddEoDripListButton from 'pages/EoDripListsPage/EoDripListsBulkActions/AddEoDripListButton';
import ImportProspectsButton from 'pages/ProspectsPage/ProspectsBulkActions/ImportProspectsButton';

import './eoCampaignProspects.scss';

const EoCampaignProspectsComponent = ({
  immutableEoDripListsOptions,
  onChangeEoDripList,
  selectedEoDripListId,
}) => {
  const eoDripListsOptions = immutableEoDripListsOptions.toJS();

  function handleChangeSelect(obj) {
    onChangeEoDripList(obj ? obj.value : null);
  }

  function handleCreateEoDripListCb(payload) {
    // Apply newly created Drip List automatically if no Drip List was selected before
    !selectedEoDripListId && onChangeEoDripList(payload.id);
  }

  return (
    <div className="eo-campaign-prospects">
      <h2>{translate('eoCampaignProspects.title')}</h2>
      <p>{translate('eoCampaignProspects.subtitle')}</p>

      <div className="eo-campaign-prospects__eo-drip-list">
        <Select
          isClearable
          label={translate('eoCampaignProspects.eoDripList.label')}
          blurInputOnSelect={false}
          triggerChangeOnBlur={false}
          onChange={handleChangeSelect}
          options={eoDripListsOptions}
          value={
            selectedEoDripListId &&
            eoDripListsOptions.find(({ value }) => value === selectedEoDripListId)
          }
        />

        <AddEoDripListButton isGreen onSubmitCb={handleCreateEoDripListCb} />
      </div>

      <ImportProspectsButton />
    </div>
  );
};

EoCampaignProspectsComponent.propTypes = {
  immutableEoDripListsOptions: optionsList,
  onChangeEoDripList: PropTypes.func,
  selectedEoDripListId: PropTypes.number,
};

export default EoCampaignProspectsComponent;
