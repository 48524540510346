import React from 'react';
import PropTypes from 'prop-types';

import DateRangeInput from 'components_linkio/DateRangeInput';


const ChartsDatapickerComponent = ({ dateStart, dateEnd, onChangeDateRange, popupAlign }) => {
  return (
    <DateRangeInput
      align={{ bottom: 10, [popupAlign]: 0 }}
      changeDateRange={onChangeDateRange}
      dateStart={new Date(dateStart)}
      dateEnd={new Date(dateEnd)}
    />
  );
};

ChartsDatapickerComponent.propTypes = {
  dateEnd: PropTypes.string,
  dateStart: PropTypes.string,
  onChangeDateRange: PropTypes.func.isRequired,
  popupAlign: PropTypes.oneOf(['left', 'right']),
};

export default ChartsDatapickerComponent;
