import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DeleteDlsButtonComponent from './DeleteDlsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  destinationLinksByBrandPageIdSelector,
  optionsSelector,
  visiblePageIdByParentSelector,
} from 'selectors';

import confirmationDialogue from 'components/confirmation_dialogue';
import { deleteDl } from 'api/destination_links';
import { destinationLinksMap } from 'common/prop_types_shapes';
import { fetchPagePercentage } from 'api/brand_page';
import { getSelected } from 'pages/Pages/utils';
import { translate } from 'common/i18n';

class DeleteDlsButtonContainer extends Component {
  static propTypes = {
    destinationLinks: destinationLinksMap,
    visiblePageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  handleDeleteDestinationLinks = () => {
    const linksCount = this.selectedRows().size;
    const itemTitle = linksCount > 1 ? 'links' : 'link';

    confirmationDialogue({
      body: translate('confirmations.deleteDestinationLink.body', {
        count: linksCount,
        title: itemTitle,
      }),
      onConfirm: this.onConfirmDeleteDestinationLinks,
    });
  };

  onConfirmDeleteDestinationLinks = async () => {
    const { dispatch, visiblePageId } = this.props;
    const ids = this.selectedRows()
      .map((row) => row.get('id'))
      .toArray();

    const data = await deleteDl(dispatch, visiblePageId, ids);

    const resultHasErrors = data.type && data.type === 'error';
    if (!resultHasErrors) {
      await fetchPagePercentage(dispatch, visiblePageId);
    }
  };

  selectedRows = () => {
    const { destinationLinks } = this.props;
    return getSelected(destinationLinks);
  };

  render() {
    const isSelected = this.selectedRows().size > 0;

    return (
      <DeleteDlsButtonComponent
        isSelected={isSelected}
        onDeleteDestinationLinks={this.handleDeleteDestinationLinks}
      />
    );
  }
}

function select(state, ownProps) {
  const destinationLinks = destinationLinksByBrandPageIdSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);
  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);

  return {
    destinationLinks,
    options,
    visiblePageId,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(DeleteDlsButtonContainer)));
