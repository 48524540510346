import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import DeleteEoMessagesButtonComponent from './DeleteEoMessagesButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { deleteEoMessages } from 'api/eoMessage';

import withQueryParams from 'decorators/withQueryParams';

import { currentIdSelector, uiSelector } from 'selectors';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import confirmationDialogue from 'components/confirmation_dialogue';

const DeleteEoMessagesButtonContainer = ({ dispatch, eoCampaignId, selectedIds }) => {
  function handleDelete() {
    confirmationDialogue({
      body: translate('confirmations.default.title'),
      onConfirm: async () => await deleteEoMessages(dispatch, eoCampaignId, selectedIds.toJS()),
    });
  }

  return <DeleteEoMessagesButtonComponent onDelete={handleDelete} selectedIds={selectedIds} />;
};

DeleteEoMessagesButtonContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  selectedIds: selectedIdsList,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    eoCampaignId,
    selectedIds,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(DeleteEoMessagesButtonContainer);
