import {
  fetchBrandDashboardDataStart,
  fetchBrandDashboardDataSuccess,
  fetchBrandDashboardDataFailure,
  enqueProcessGenerateBrandMetricsStart,
  enqueProcessGenerateBrandMetricsFailure,
  enqueProcessGenerateBrandMetricsSuccess
} from '../actions/brandDashboardActions';

import { translate } from '../common/i18n';
import { showSuccessMessage } from '../common/utils';
import {
  getFromAPI,
  handleAPIError,
  patchToAPI
} from './utils';

async function fetchBrandDashboardData(dispatch, brandId, dateStart, dateEnd) {
  dispatch(fetchBrandDashboardDataStart());

  try {
    const brand = await getFromAPI(
      Routes.dashboard_path({ brand_id: brandId }),
      { start_date: dateStart, end_date: dateEnd },
    );
    dispatch(fetchBrandDashboardDataSuccess(brand));
  } catch (err) {
    handleAPIError(dispatch, err, fetchBrandDashboardDataFailure, translate('errors.cantFetchBrandDashboardData'));
  }
}
async function enqueueBrandMetricsGenerate(dispatch, brandId){
  
  dispatch(enqueProcessGenerateBrandMetricsStart(brandId))
  try {
      const result = await patchToAPI(
        Routes.dashboard_generate_brand_metrics_path({dashboard_brand_id: brandId}),
        { dashboard_brand_id: brandId }
      );
      showSuccessMessage(dispatch, translate('brandOverviewApiCalls.messages.brandOverviewMetricsEnque'));
      return true;
  } catch (err) {
    handleAPIError(dispatch, err, enqueProcessGenerateBrandMetricsFailure, translate('errors.cantEnqueueBrandMetricsGeneration'))
    
  }
}

export { fetchBrandDashboardData, enqueueBrandMetricsGenerate};
