import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';

const eoCampaignStepsPreview = (state) => state.getIn(['eoCampaignStepsPreview', 'eoCampaignStepsPreviewList'], iList());
const eoCampaignStepsPreviewOptions = (state) => state.getIn(['eoCampaignStepsPreview', 'eoCampaignStepsPreviewOptions'], iMap());

export const eoCampaignStepsPreviewSelector = createSelector(
  [eoCampaignStepsPreview],
  (eoCampaignStepsPreview) => eoCampaignStepsPreview,
);

export const eoCampaignStepsPreviewOptionsSelector = createSelector(
  [eoCampaignStepsPreviewOptions],
  (eoCampaignStepsPreviewOptions) => eoCampaignStepsPreviewOptions,
);
