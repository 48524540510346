import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import AutodiscoverCompetitorsPopupComponent from './AutodiscoverCompetitorsPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { autodiscoverCompetitorsByKeyword } from 'api/bulkCompetitorsSetup';
import { brandPageMap, draftCompetitorPagesList } from 'common/prop_types_shapes';
import { bulkCompetitorPagesByBrandPageIdSelector } from 'selectors/bulkCompetitorPagesSelector';
import { openEditKeywordsPopup } from 'actions/popup_actions';
import { translate } from 'common/i18n';
import { visibleBrandPageSelector } from 'selectors';
import confirmationDialogue from 'components/confirmation_dialogue';

const AutodiscoverCompetitorsPopupContainer = ({
  brandPage,
  dispatch,
  draftCompetitorPages,
  onClose,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  async function onConfirmAutodiscoverCompetitors(keywordId) {
    setIsLoading(true);
    await autodiscoverCompetitorsByKeyword(dispatch, brandPage.get('id'), keywordId);
    setIsLoading(false);
    onClose();
  }

  async function handleSubmit(keywordId) {
    if (draftCompetitorPages.size === 0) {
      await onConfirmAutodiscoverCompetitors(keywordId);
      return;
    }

    confirmationDialogue({
      body: translate('confirmations.deleteDraftCompetitors.body'),
      onConfirm: async () => await onConfirmAutodiscoverCompetitors(keywordId),
    });
  }

  function handleOpenEditKeywordsPopup() {
    dispatch(
      openEditKeywordsPopup({
        pageId: brandPage.get('id'),
        targetAppModule: 'atp',
      }),
    );
  }

  const keywords = brandPage.get('keywords').toJS();

  return (
    <AutodiscoverCompetitorsPopupComponent
      isLoading={isLoading}
      keywords={keywords}
      onAddKeywordsClick={handleOpenEditKeywordsPopup}
      onCancelClick={onClose}
      onSubmit={handleSubmit}
    />
  );
};

function select(state, ownProps) {
  const brandPage = visibleBrandPageSelector(state, ownProps);
  const brandPageId = brandPage.get('id');

  const draftCompetitorPages = bulkCompetitorPagesByBrandPageIdSelector(state, brandPageId);

  return {
    brandPage,
    draftCompetitorPages,
  };
}

AutodiscoverCompetitorsPopupContainer.propTypes = {
  brandPage: brandPageMap,
  dispatch: PropTypes.func,
  draftCompetitorPages: draftCompetitorPagesList,
  onClose: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(AutodiscoverCompetitorsPopupContainer);
