import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './badge_component.scss';


const BadgeComponent = ({ text, red, green, small, warning, orange, blue }) => {
  const badgeClasses = classnames({
    'badge-component': true,
    'badge-component_blue': blue,
    'badge-component_green': green,
    'badge-component_orange': orange,
    'badge-component_red': red,
    'badge-component_small': small,
    'badge-component_warning': warning,
  });

  const badgeText = (warning && text.toLowerCase() === 'error') ? '!' : text;

  return (
    <div className={badgeClasses}>{badgeText}</div>
  );
};

BadgeComponent.propTypes = {
  blue: PropTypes.bool,
  green: PropTypes.bool,
  orange: PropTypes.bool,
  red: PropTypes.bool,
  small: PropTypes.bool,
  text: PropTypes.string,
  warning: PropTypes.bool,
};

export default BadgeComponent;
