import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';
import { format } from 'date-fns';

import EoMessagePopup from '../EoMessagePopup';

import columnsGenerator from 'common/tables/columns_generator';

import { buildSelectedItems } from 'common/utils';
import { checkboxColumn, textColumn } from 'common/tables/columns';
import { eoMessagesList } from 'common/propTypesShapes/eoMessages';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';
import ListIcon from 'common/icons/list';

import './eoMessagesTable.scss';

const EoMessagesTableComponent = ({
  eoMessages,
  eoMessagesCount,
  onTableHeaderClick,
  onToggleSelectedRows,
  selectedIds,
  sorting,
}) => {
  const processedEoMessages = buildSelectedItems(eoMessages.toJS(), selectedIds);

  const headerValueFormatter = (options = {}) => (_value, extra) => {
    const { sortBy, sortingOrder } = sorting;
    const { withExplanationMessage } = options;
    const { property } = extra;

    const label = translate(`eoMessage.table.headers.${property}`);

    return (
      <HeaderFieldWithSorting
        appModule="eoMessages"
        label={label}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  };

  const handleOnToggleSelectRow = (eoMessageId) => (event) => {
    const isSelected = event.target.checked;

    onToggleSelectedRows([eoMessageId], isSelected);
  };

  function handleOnToggleSelectAll(event) {
    const isSelectedAll = event.target.checked;

    const eoMessagesIds = processedEoMessages.map((eoMessage) => eoMessage.id);

    onToggleSelectedRows(eoMessagesIds, isSelectedAll);
  }

  function isAllSelected() {
    const selectedRowsCount = processedEoMessages.filter((eoMessage) => eoMessage.selected).length;
    const rowsCount = processedEoMessages.length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  }

  function cellCheckboxFormatters(_value, extra) {
    const {
      rowData: { selected, id },
    } = extra;

    return (
      <Checkbox
        checked={selected}
        className="eo-messages-table__checkbox"
        onChange={handleOnToggleSelectRow(id)}
      />
    );
  }

  function headerCheckboxFormatters() {
    return (
      <Checkbox
        checked={isAllSelected()}
        className="eo-messages-table__checkbox"
        onChange={handleOnToggleSelectAll}
      />
    );
  }

  function dateValueFormatter(value) {
    return format(new Date(value), 'MMM d h:mm a');
  }

  function generateColumns() {
    return columnsGenerator([
      // Uncomment when checkboxes are needed
      // checkboxColumn({
      //   headerFormatters: [headerCheckboxFormatters],
      //   cellFormatters: [cellCheckboxFormatters],
      //   className: 'eo-messages-table__checkbox-column',
      // }),
      textColumn({
        name: 'prospectEmail',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-messages-table__prospect_email-column',
        Popup: EoMessagePopup,
        Icon: ListIcon,
      }),
      textColumn({
        name: 'prospectPosition',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-messages-table__prospect_position-column',
      }),
      textColumn({
        name: 'eoCampaignStepPosition',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-messages-table__step-column',
      }),
      textColumn({
        name: 'createdAt',
        headerValueFormatter: headerValueFormatter(),
        className: 'eo-messages-table__created_at-column',
        cellValueFormatter: dateValueFormatter,
      }),
    ]);
  }

  const columns = generateColumns();

  return (
    <>
      {eoMessagesCount > 0 && (
        <p>{translate('eoMessage.table.eoMessagesCount')(eoMessagesCount)}</p>
      )}

      <Table.Provider className="eo-messages-table" columns={columns}>
        <Table.Header />
        <Table.Body rowKey="id" rows={processedEoMessages} />
      </Table.Provider>
    </>
  );
};

EoMessagesTableComponent.propTypes = {
  eoMessages: eoMessagesList,
  eoMessagesCount: PropTypes.number,
  onTableHeaderClick: PropTypes.func,
  onToggleSelectedRows: PropTypes.func,
  selectedIds: selectedIdsList,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default EoMessagesTableComponent;
