import React from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';

import { translate } from '../../../common/i18n';

import './devise_links_component.scss';

const DeviseLinksComponent = ({ linksToSkip = [] }) => {
  const ConfirmationInstructionsPath = Routes.new_user_confirmation_path({ format: null });
  const UnlockInstructions = Routes.new_user_unlock_path({ format: null });

  // By some reason we receive "Object doesn't support property or method 'includes'" error in production;
  // This code ensures that we always deal with array which, for sure, supports 'includes';
  const safeLinksToSkip = isArray(linksToSkip) ? linksToSkip : [];

  return (
    <div className="devise-links-component__links-container">
      {!safeLinksToSkip.includes('resendConfirmation') && (
        <a tabIndex="0" href={ConfirmationInstructionsPath}>
          {translate('authForms.deviseLinks.resendConfirmation')}
        </a>
      )}
      {!safeLinksToSkip.includes('resendUnlock') && (
        <a tabIndex="0" href={UnlockInstructions}>
          {translate('authForms.deviseLinks.resendUnlock')}
        </a>
      )}
    </div>
  );
};

DeviseLinksComponent.propTypes = {
  linksToSkip: PropTypes.arrayOf(PropTypes.oneOf(['resendConfirmation', 'resendUnlock'])),
};

export default DeviseLinksComponent;
