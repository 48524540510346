import React from 'react';
import PropTypes from 'prop-types';

import DeleteEoProgressionsButton from './DeleteEoProgressionsButton';
import EoProgressionActionsSelect from './EoProgressionActionsSelect';

import { filtersSpec, defaultFilters } from './filtersSpec';
import { optionsList } from 'common/prop_types_shapes';

import DataFilter from 'components_linkio/DataFilter';

import './eoProgressionsBulkActions.scss';

const EoProgressionsBulkActionsComponent = ({
  canCreate,
  hasEoProgressions,
  hideDataFilter,
  eoProgressionStageOptions,
}) => {
  const eoProgressionStageOptionsArray = eoProgressionStageOptions.toJS();

  return (
    <div className="eo-progressions-bulk-actions">
      <div className="eo-progressions-bulk-actions__row">
        <div className="eo-progressions-bulk-actions__row-item">
          {!hideDataFilter && (
            <DataFilter
              defaultFilters={defaultFilters({ eoProgressionStageOptionsArray })}
              filtersSpec={filtersSpec({ eoProgressionStageOptionsArray })}
            />
          )}

          {hasEoProgressions && canCreate && (
            <>
              <span className="vertical-separator" />
              <DeleteEoProgressionsButton />
            </>
          )}

          {hasEoProgressions && (
            <>
              <span className="vertical-separator" />
              <EoProgressionActionsSelect />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

EoProgressionsBulkActionsComponent.propTypes = {
  canCreate: PropTypes.bool,
  eoProgressionStageOptions: optionsList,
  hasEoProgressions: PropTypes.bool,
  hideDataFilter: PropTypes.bool,
};

export default EoProgressionsBulkActionsComponent;
