import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoEmailTemplatesByScopeStart,
  fetchEoEmailTemplatesByFolderStart,
  fetchEoEmailTemplatesByScopeSuccess,
  fetchEoEmailTemplatesByFolderSuccess,
  fetchEoEmailTemplatesByScopeFailure,
  fetchEoEmailTemplatesByFolderFailure,
  fetchEoEmailTemplatesCountStart,
  fetchEoEmailTemplatesCountSuccess,
  fetchEoEmailTemplatesCountFailure,
  fetchEoEmailTemplateStart,
  fetchEoEmailTemplateSuccess,
  fetchEoEmailTemplateFailure,
  createEoEmailTemplateStart,
  createEoEmailTemplateSuccess,
  createEoEmailTemplateFailure,
  updateEoEmailTemplateStart,
  updateEoEmailTemplateSuccess,
  updateEoEmailTemplateFailure,
  deleteEoEmailTemplatesStart,
  deleteEoEmailTemplatesSuccess,
  deleteEoEmailTemplatesFailure,
} from 'actions/eoEmailTemplateActions';

import { resetSelectedIds } from 'actions/ui_actions';
import { fetchEoEmailTemplatesPagySuccess } from 'actions/pagy_actions';

import { translate } from 'common/i18n';

import { showSuccessMessage, showSuccessMessageFromTranslation } from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCase,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoEmailTemplatesByScope(dispatch, params) {
  dispatch(fetchEoEmailTemplatesByScopeStart());

  try {
    const resp = await getFromAPI(
      Routes.eo_email_templates_all_path(),
      processObjToSnakeCaseDeep(params),
    );
    dispatch(fetchEoEmailTemplatesByScopeSuccess(resp.eo_email_templates));
    dispatch(fetchEoEmailTemplatesPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoEmailTemplatesByScopeFailure,
      translate('eoEmailTemplate.messages.cantFetchEoEmailTemplates'),
    );
  }
}

export async function fetchEoEmailTemplatesByFolder(dispatch, folderId, params) {
  dispatch(fetchEoEmailTemplatesByFolderStart());

  try {
    const resp = await getFromAPI(
      Routes.eo_email_templates_path(folderId),
      processObjToSnakeCaseDeep(params),
    );
    dispatch(fetchEoEmailTemplatesByFolderSuccess(resp.eo_email_templates));
    dispatch(fetchEoEmailTemplatesPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoEmailTemplatesByFolderFailure,
      translate('eoEmailTemplate.messages.cantFetchEoEmailTemplates'),
    );
  }
}

export async function fetchEoEmailTemplatesCount(dispatch) {
  dispatch(fetchEoEmailTemplatesCountStart());

  try {
    const resp = await getFromAPI(
      Routes.scope_template_count_path(),
      processObjToSnakeCaseDeep({}),
    );
    dispatch(fetchEoEmailTemplatesCountSuccess(resp));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoEmailTemplatesCountFailure,
      translate('eoEmailTemplate.messages.cantFetchEoEmailTemplatesCount'),
    );
  }
}

export async function fetchEoEmailTemplate(dispatch, eoEmailTemplateId) {
  dispatch(fetchEoEmailTemplateStart());

  try {
    const eoEmailTemplate = await getFromAPI(Routes.eo_email_template_path(eoEmailTemplateId));

    dispatch(fetchEoEmailTemplateSuccess(eoEmailTemplate));

    return processObjToCamelCaseDeep(eoEmailTemplate);
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoEmailTemplateFailure,
      translate('eoEmailTemplate.messages.cantFetchEoEmailTemplate'),
    );
  }
}

export async function createEoEmailTemplate(dispatch, data) {
  dispatch(createEoEmailTemplateStart());

  try {
    const resp = await postToAPI(
      Routes.eo_email_templates_path(data.eoEmailTemplateFolderId),
      eoEmailTemplateObject(data),
    );

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(createEoEmailTemplateSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoEmailTemplate.messages.createSuccess');
    } else {
      dispatch(createEoEmailTemplateFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createEoEmailTemplateFailure,
      translate('eoEmailTemplate.messages.cantCreateEoEmailTemplate'),
    );
  }
}

export async function updateEoEmailTemplate(dispatch, data) {
  dispatch(updateEoEmailTemplateStart());

  const { id } = data;
  try {
    const resp = await patchToAPI(Routes.eo_email_template_path(id), eoEmailTemplateObject(data));
    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(updateEoEmailTemplateSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoEmailTemplate.messages.updateSuccess');
    } else {
      dispatch(updateEoEmailTemplateFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoEmailTemplateFailure,
      translate('eoEmailTemplate.messages.cantUpdateEoEmailTemplate'),
    );
  }
}

export async function deleteEoEmailTemplates(dispatch, ids) {
  dispatch(deleteEoEmailTemplatesStart());

  try {
    await deleteToAPI(Routes.eo_email_templates_all_destroy_path({ ids }));

    dispatch(resetSelectedIds());
    dispatch(deleteEoEmailTemplatesSuccess(ids));

    showSuccessMessage(dispatch, translate('eoEmailTemplate.messages.deleteSuccess')(ids.length));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoEmailTemplatesFailure,
      translate('eoEmailTemplate.messages.cantDeleteEoEmailTemplates'),
    );
  }
}

const eoEmailTemplateObject = (data) => ({ eo_email_template: processObjToSnakeCase(data) });
