import { fetchSubscriptionSuccess } from 'actions/subscription_actions';

const handleSubscriptionChangeStatus = (dispatch, data) => {
  const { subscriptionChange } = data;

  switch (subscriptionChange) {
    case 'finished':
      dispatch(fetchSubscriptionSuccess(data));

      break;
  }
};

export default handleSubscriptionChangeStatus;
