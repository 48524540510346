import {
  createFilter,
  createYesOrNo,
  customValue,
  falseValue,
  trueValue,
} from 'components_linkio/DataFilter/utils';

import { translate } from 'common/i18n';

const brandPageFilterSpec = (options = []) => ({
  property: {
    value: 'brandPage',
    label: translate('uiComponents.dataFilters.filter.brandPage'),
  },
  predicate: {
    eq: options,
    eqAny: options,
    notEqAll: options,
    notEq: options,
  },
  mapToQuery(property, predicate, value) {
    switch (predicate) {
      case 'eqAny':
        return {
          brand_page_id_eq_any: value.map(({ value: itemValue }) => itemValue),
        };
      case 'eq':
        return {
          brand_page_id_eq: value.value,
        };
      case 'notEqAll':
        return {
          brand_page_id_not_eq_all: value.map(({ value: itemValue }) => itemValue),
        };
      case 'notEq':
        return {
          brand_page_id_not_eq_or_null: value.value,
        };
    }

    return {};
  },
  mapToFilter(queryProperty, queryValue = []) {
    switch (queryProperty) {
      case 'brand_page_id_eq_any': {
        const value = this.options.filter(({ value }) => queryValue.includes(value));
        return this.createFilter('eqAny', value);
      }
      case 'brand_page_id_eq': {
        const value = this.options.filter(({ value }) => value === queryValue)[0];
        return this.createFilter('eq', value);
      }
      case 'brand_page_id_not_eq_all': {
        const value = this.options.filter(({ value }) => queryValue.includes(value));
        return this.createFilter('notEqAll', value);
      }
      case 'brand_page_id_not_eq_or_null': {
        const value = this.options.filter(({ value }) => value === queryValue)[0];
        return this.createFilter('notEq', value);
      }
      default:
        return false;
    }
  },
  options,
  createFilter,
});

const anchorTypeFilterSpec = (options = []) => ({
  property: {
    value: 'anchorType',
    label: translate('uiComponents.dataFilters.filter.anchorType'),
  },
  predicate: {
    eq: options,
    eqAny: options,
    notEqAll: options,
    notEq: options,
    blank: [],
    present: [],
  },
  mapToQuery(property, predicate, value) {
    switch (predicate) {
      case 'eqAny':
        return {
          anchor_type_eq_any: value.map(({ value: itemValue }) => itemValue),
        };
      case 'eq':
        return {
          anchor_type_eq: value.value,
        };
      case 'notEqAll':
        return {
          anchor_type_not_eq_all: value.map(({ value: itemValue }) => itemValue),
        };
      case 'notEq':
        return {
          anchor_type_not_eq_or_null: value.value,
        };
      case 'blank':
        return {
          anchor_type_blank: 1,
        };
      case 'present':
        return {
          anchor_type_present: 1,
        };
    }

    return {};
  },
  mapToFilter(queryProperty, queryValue = []) {
    switch (queryProperty) {
      case 'anchor_type_eq_any': {
        const value = this.options.filter(({ value }) => queryValue.includes(value));
        return this.createFilter('eqAny', value);
      }
      case 'anchor_type_eq': {
        const value = this.options.filter(({ value }) => value === queryValue)[0];
        return this.createFilter('eq', value);
      }
      case 'anchor_type_not_eq_all': {
        const value = this.options.filter(({ value }) => queryValue.includes(value));
        return this.createFilter('notEqAll', value);
      }
      case 'anchor_type_not_eq_or_null': {
        const value = this.options.filter(({ value }) => value === queryValue)[0];
        return this.createFilter('notEq', value);
      }
      case 'anchor_type_blank':
        return this.createFilter('blank');
      case 'anchor_type_present':
        return this.createFilter('present');
      default:
        return false;
    }
  },
  options,
  createFilter,
});

const DFValue = customValue(translate('uiComponents.dataFilters.options.DF'), 'false');
const NFValue = customValue(translate('uiComponents.dataFilters.options.NF'), 'true');

const nofollowFilterSpec = {
  property: {
    value: 'nofollow',
    label: translate('uiComponents.dataFilters.filter.nofollow'),
  },
  predicate: {
    eq: [DFValue, NFValue],
    notEq: [DFValue, NFValue],
    blank: [],
    present: [],
  },
  mapToQuery(property, predicate, value) {
    switch (predicate) {
      case 'eq':
        return {
          nofollow_eq: value.value,
        };
      case 'notEq':
        return {
          nofollow_not_eq_or_null: value.value,
        };
      case 'blank':
        return {
          nofollow_null: 1,
        };
      case 'present':
        return {
          nofollow_not_null: 1,
        };
    }

    return {};
  },
  mapToFilter(queryProperty, queryValue) {
    switch (queryProperty) {
      case 'nofollow_eq': {
        const value = queryValue === 'true' ? NFValue : DFValue;
        return this.createFilter('eq', value);
      }
      case 'nofollow_not_eq_or_null': {
        const value = queryValue === 'true' ? NFValue : DFValue;
        return this.createFilter('notEq', value);
      }
      case 'nofollow_null':
        return this.createFilter('blank');
      case 'nofollow_not_null':
        return this.createFilter('present');
      default:
        return false;
    }
  },
  createFilter,
};

const includedFilterSpec = {
  property: {
    value: 'included',
    label: translate('uiComponents.dataFilters.filter.included'),
  },
  predicate: {
    eq: createYesOrNo(),
  },
  mapToQuery(property, predicate, { value }) {
    const key = `${property}_${value}`;

    return {
      [key]: 1,
    };
  },
  mapToFilter(queryProperty) {
    switch (queryProperty) {
      case 'included_true':
        return this.createFilter('eq', trueValue);
      case 'included_false':
        return this.createFilter('eq', falseValue);
      default:
        return false;
    }
  },
  createFilter,
};

const indexedFilterSpec = {
  property: {
    value: 'indexed',
    label: translate('uiComponents.dataFilters.filter.indexed'),
  },
  predicate: {
    eq: createYesOrNo(),
  },
  mapToQuery(property, predicate, { value }) {
    const prefix = value === 'true' ? '' : 'not_';
    const key = `${prefix}${property}`;

    return {
      [key]: true,
    };
  },
  mapToFilter(queryProperty) {
    switch (queryProperty) {
      case 'indexed':
        return this.createFilter('eq', trueValue);
      case 'not_indexed':
        return this.createFilter('eq', falseValue);
      default:
        return false;
    }
  },
  createFilter,
};

const isFoundFilterSpec = {
  property: {
    value: 'isFound',
    label: translate('uiComponents.dataFilters.filter.isFound'),
  },
  predicate: {
    eq: createYesOrNo(),
  },
  mapToQuery(property, predicate, { value }) {
    const key = value === 'true' ? 'is_found' : 'is_not_found';

    return {
      [key]: true,
    };
  },
  mapToFilter(queryProperty) {
    switch (queryProperty) {
      case 'is_found':
        return this.createFilter('eq', trueValue);
      case 'is_not_found':
        return this.createFilter('eq', falseValue);
      default:
        return false;
    }
  },
  createFilter,
};

const disavowFilterSpec = {
  property: {
    value: 'disavow',
    label: translate('uiComponents.dataFilters.filter.disavow'),
  },
  predicate: {
    eq: createYesOrNo(),
  },
  mapToQuery(property, predicate, { value }) {
    const key = `${property}_${value}`;

    return {
      [key]: 1,
    };
  },
  mapToFilter(queryProperty) {
    switch (queryProperty) {
      case 'disavow_true':
        return this.createFilter('eq', trueValue);
      case 'disavow_false':
        return this.createFilter('eq', falseValue);

      default:
        return false;
    }
  },
  createFilter,
};

const defaultFilters = {
  nofollow: nofollowFilterSpec,
  included: includedFilterSpec,
  indexed: indexedFilterSpec,
  isFound: isFoundFilterSpec,
  disavow: disavowFilterSpec,
};

export const backlinksFilterSpec = ({ brandPagesOptions } = {}) => ({
  brandPage: brandPageFilterSpec(brandPagesOptions),
  ...defaultFilters,
});

export const atpDlsFilterSpec = ({ anchorTypeOptions } = {}) => ({
  anchorType: anchorTypeFilterSpec(anchorTypeOptions),
  ...defaultFilters,
});

export const defaultBacklinksFilter = [isFoundFilterSpec.createFilter('eq', null)];

export const defaultAtpDlsFilter = ({ anchorTypeOptions } = {}) => [
  anchorTypeFilterSpec(anchorTypeOptions).createFilter('eq', null),
];
