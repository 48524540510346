import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import EoCampaignsTabsComponent from './EoCampaignsTabsComponent';

const EoCampaignsTabsContainer = ({ history, match }) => {
  const queryPath = match.path.substring(1);
  const isEmailTemplatesString = queryPath.includes('eo_email_template');
  const activeTab = isEmailTemplatesString ? 'eo_email_templates' : queryPath;

  const handleTabClick = (tab) => () => {
    if (tab === activeTab) {
      return;
    }

    let path = '';

    switch (tab) {
      case 'eo_campaigns':
        path = Routes.eo_campaigns_path({ format: null });
        break;
      case 'eo_drip_lists':
        path = Routes.eo_drip_lists_path({ format: null });
        break;
      case 'eo_active_hours_sets':
        path = Routes.eo_active_hours_sets_path({ format: null });
        break;
      case 'eo_email_templates':
        path = Routes.eo_email_templates_all_path({ format: null });
        break;
    }

    history.push(path);
  };

  return <EoCampaignsTabsComponent activeTab={activeTab} onTabClick={handleTabClick} />;
};

EoCampaignsTabsContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(EoCampaignsTabsContainer);
