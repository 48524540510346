export default {
  eoInboxes: {
    blankState: {
      title: "You don't have any inboxes yet.",
      body: {
        firstPart: 'You can add inboxes on ',
        secondPart: 'this page',
      },
    },
    buttons: {
      removeInbox: 'Remove from this campaign',
      useInbox: 'Use for this campaign',
    },
    messages: {
      addOrRemoveEoCampaignInboxSuccess: (addOrRemoveState) => {
        return addOrRemoveState === 'add'
          ? 'Successfully added inbox to the campaign'
          : 'Successfully removed inbox from the campaign';
      },
      addOrRemoveEoCampaignInboxFailure: (addOrRemoveState) => {
        return addOrRemoveState === 'add'
          ? "Can't add inbox to the campaign"
          : "Can't remove inbox from the campaign";
      },
    },
    title: 'Select inboxes to use for this campaign',
    subtitle:
      'We will send the emails from the selected inbox(es). \
      You can select multiple inboxes you have access to.',
  },
};
