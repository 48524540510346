import { fromJS, Map as iMap } from 'immutable';
import { keyBy } from 'lodash';

import {
  FETCH_SUBSCRIPTION_PLANS__FAILURE,
  FETCH_SUBSCRIPTION_PLANS__START,
  FETCH_SUBSCRIPTION_PLANS__SUCCESS,
} from '../actions/subscription_plan_actions';

import {
  camelizeObjectDeep,
} from './utils';

const initialState = iMap();


export default function subscriptionPlansReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_PLANS__FAILURE:
    case FETCH_SUBSCRIPTION_PLANS__START: {
      break;
    }
    case FETCH_SUBSCRIPTION_PLANS__SUCCESS: {
      state = state.merge(state, fromJS(keyBy(action.subscriptionPlans.map(camelizeObjectDeep), 'id')));

      break;
    }
  }

  return state;
}
