export const CHANGE_SUBSCRIPTION_PLAN__FAILURE = 'CHANGE_SUBSCRIPTION_PLAN__FAILURE';
export const CHANGE_SUBSCRIPTION_PLAN__START = 'CHANGE_SUBSCRIPTION_PLAN__START';
export const FETCH_SUBSCRIPTION_PLANS__FAILURE = 'FETCH_SUBSCRIPTION_PLANS__FAILURE';
export const FETCH_SUBSCRIPTION_PLANS__START = 'FETCH_SUBSCRIPTION_PLANS__START';
export const FETCH_SUBSCRIPTION_PLANS__SUCCESS = 'FETCH_SUBSCRIPTION_PLANS__SUCCESS';
export const LAUNCH_CHECKOUT_SESSION__FAILURE = 'LAUNCH_CHECKOUT_SESSION__FAILURE';
export const BUY_EMAIL_VERIFICATION_CREDITS_CHECKOUT_SESSION__FAILURE =
  'BUY_EMAIL_VERIFICATION_CREDITS_CHECKOUT_SESSION__FAILURE';

export function changeSubscriptionPlanStart() {
  return { type: CHANGE_SUBSCRIPTION_PLAN__START };
}

export function changeSubscriptionPlanFailure(errors) {
  return { type: CHANGE_SUBSCRIPTION_PLAN__FAILURE, errors };
}

export function fetchSubscriptionPlansStart() {
  return { type: FETCH_SUBSCRIPTION_PLANS__START };
}

export function fetchSubscriptionPlansSuccess(subscriptionPlans) {
  return { type: FETCH_SUBSCRIPTION_PLANS__SUCCESS, subscriptionPlans };
}

export function fetchSubscriptionPlansFailure(errors) {
  return { type: FETCH_SUBSCRIPTION_PLANS__FAILURE, errors };
}

export function launchCheckoutSessionFailure(errors) {
  return { type: LAUNCH_CHECKOUT_SESSION__FAILURE, errors };
}

export function buyEmailVerificationCreditsCheckoutSessionFailure(errors) {
  return { type: BUY_EMAIL_VERIFICATION_CREDITS_CHECKOUT_SESSION__FAILURE, errors };
}
