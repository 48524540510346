const FETCH_KEYWORDS_WITH_CHANGES__FAILURE = 'FETCH_KEYWORDS_WITH_CHANGES__FAILURE';
const FETCH_KEYWORDS_WITH_CHANGES__START = 'FETCH_KEYWORDS_WITH_CHANGES__START';
const FETCH_KEYWORDS_WITH_CHANGES__SUCCESS = 'FETCH_KEYWORDS_WITH_CHANGES__SUCCESS';
const FETCH_KEYWORDS_WITH_CHANGES_BY_URL__SUCCESS = 'FETCH_KEYWORDS_WITH_CHANGES_BY_URL__SUCCESS';
const RECHECK_KEYWORDS_RANKINGS_FAILURE = 'RECHECK_KEYWORDS_RANKINGS_FAILURE';
const RESET_RANK_TRACKER_FETCHING_STATES = 'RESET_RANK_TRACKER_FETCHING_STATES';
const SET_RANK_TRACKER_FILTERS = 'SET_RANK_TRACKER_FILTERS';

function fetchKeywordsWithChangesFailure() {
  return { type: FETCH_KEYWORDS_WITH_CHANGES__FAILURE };
}

function fetchKeywordsWithChangesStart() {
  return { type: FETCH_KEYWORDS_WITH_CHANGES__START };
}

function fetchKeywordsWithChangesSuccess(keywordsWithChanges, brandId) {
  return { type: FETCH_KEYWORDS_WITH_CHANGES__SUCCESS, keywordsWithChanges, brandId };
}

function fetchKeywordsWithChangesByUrlSuccess(keywordsWithChangesByUrl, brandId) {
  return { type: FETCH_KEYWORDS_WITH_CHANGES_BY_URL__SUCCESS, keywordsWithChangesByUrl, brandId };
}

function recheckKeywordsRankingsFailure() {
  return { type: RECHECK_KEYWORDS_RANKINGS_FAILURE };
}

function resetRankTrackerFetchingStates() {
  return { type: RESET_RANK_TRACKER_FETCHING_STATES };
}

function setRankTrackerFilters(params) {
  return { type: SET_RANK_TRACKER_FILTERS, params };
}

export {
  FETCH_KEYWORDS_WITH_CHANGES__FAILURE,
  FETCH_KEYWORDS_WITH_CHANGES__START,
  FETCH_KEYWORDS_WITH_CHANGES__SUCCESS,
  FETCH_KEYWORDS_WITH_CHANGES_BY_URL__SUCCESS,
  RECHECK_KEYWORDS_RANKINGS_FAILURE,
  RESET_RANK_TRACKER_FETCHING_STATES,
  SET_RANK_TRACKER_FILTERS,
  fetchKeywordsWithChangesFailure,
  fetchKeywordsWithChangesStart,
  fetchKeywordsWithChangesSuccess,
  fetchKeywordsWithChangesByUrlSuccess,
  recheckKeywordsRankingsFailure,
  resetRankTrackerFetchingStates,
  setRankTrackerFilters,
};
