import { buildWordInPastTenseWithPluralization } from 'common/utils';
import pluralize from 'pluralize';

export default {
  eoDripList: {
    breadcrumbsTitle: 'Drip Lists',
    bulkActions: {
      addEoDripList: 'Create New Drip List',
      selectBrand: 'Filter by brand',
    },
    messages: {
      cantAssignEoDripListToCampaign: "Can't assign drip list to the campaign",
      cantFetchEoDripLists: "Can't load drip lists",
      cantFetchEoDripList: "Can't load drip list",
      cantCreateEoDripList: "Can't create drip list",
      cantUpdateEoDripList: "Can't update drip list",
      cantDeleteEoDripLists: "Can't delete drip list(s)",
      createSuccess: 'Drip List was created successfully.',
      deleteSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization('Drip List', count)} deleted successfully.`;
      },
      updateSuccess: 'Drip List was updated successfully.',
    },
    eoDripListFormPopup: {
      buttons: {
        cancel: 'Cancel',
        submit: (isExistingEoDripList) =>
          isExistingEoDripList ? 'Update Drip List' : 'Create Drip List',
      },
      inputs: {
        title: {
          label: 'Title',
        },
      },
      checkboxes: {
        validateEmailAddresses: {
          label: 'Check email deliverability',
          hint: "Will automatically check an email's deliverability when adding prospect to this list. Click to learn more",
        },
        declineCatchAllEmails: {
          label: 'Catch-all',
          hint: 'Not-Recommended',
        },
        declineInvalidEmails: {
          label: 'Invalid',
          hint: 'Recommended',
        },
        declineUnknownEmails: {
          label: 'Unknown',
          hint: 'Deliverability check was inconclusive',
        },
      },
      rejectProspects: {
        rejectProspects: 'Reject prospects if they...',
        rejectProspectsBasedOnValidation: 'Reject prospect if email check shows the email is...',
        declineProspectsBrandlyInvolved: 'Already ran a campaign for this brand',
        declineProspectsAlreadyInvolved: 'Already ran any campaign',
      },
      title: (isExistingEoDripList) =>
        isExistingEoDripList ? 'Update Drip List' : 'Create Drip List',
      buyCredits: {
        emailsValidationCreditsCount: (emailsValidationCreditsCount) =>
          `You have ${pluralize('credit', emailsValidationCreditsCount, true)}`,
        buyMoreCredits: 'Buy more credits',
      },
    },
    table: {
      blankState: {
        body: 'You can manually add Drip Lists',
        title: "You don't have any Drip Lists yet",
      },
      headers: {
        brandName: 'Associated brand',
        createdAt: 'Created',
        owner: 'Owner',
        prospectsCount: 'Total number of prospects',
        declineProspectsAlreadyInvolved: 'Reject prospects if already ran...',
        title: 'Title',
        validateEmailAddresses: 'Validate email addresses',
      },
    },
  },
};
