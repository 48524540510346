import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';

import { currentIdSelector } from './index';

const bulkPagesSetupPagyByBrandId = (state, brandId) => {
  return state.getIn(['pagies', 'bulkPagesSetupPagy', String(brandId)], iMap());
};
const bulkSetupBrandPages = (state) => state.get('bulkSetupBrandPages', iMap());

const bulkPagesSetupPagyByBrandIdSelector = createSelector(
  [bulkPagesSetupPagyByBrandId],
  (pagy) => pagy || iMap(),
);

const bulkSetupBrandPagesSelector = createSelector(
  [bulkSetupBrandPages, currentIdSelector],
  (bulkSetupBrandPages, brandId) =>
    bulkSetupBrandPages.filter((page) => page.getIn(['brandId'], null) === parseInt(brandId, 10)) ||
    iList(),
);

export { bulkPagesSetupPagyByBrandIdSelector, bulkSetupBrandPagesSelector };
