import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PagesTableComponent from './PagesTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandPagesMap } from 'common/prop_types_shapes';
import { pagesByParentSelector } from 'selectors';
import refreshPagesService from 'pages/Pages/services/refreshPagesService';

const PagesTableContainer = ({ dispatch, pageParentType, pages }) => {
  // Need to update state after selecting brand pages
  function handleUpdatePagesRows(pages) {
    refreshPagesService({ dispatch, pageParentType }, pages);
  }

  const reactabularFriendlyPages = pages.toList().toJS();

  return (
    <PagesTableComponent onUpdateRows={handleUpdatePagesRows} pages={reactabularFriendlyPages} />
  );
};

PagesTableContainer.propTypes = {
  dispatch: PropTypes.func,
  pageParentType: PropTypes.oneOf(['brand_page', 'brand']).isRequired,
  pages: brandPagesMap,
};

function select(state, ownProps) {
  const pages = pagesByParentSelector(state, ownProps);

  return {
    pages,
  };
}

export default ConnectStoreHOC(connect(select)(PagesTableContainer));
