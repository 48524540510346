import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { isEmpty } from 'lodash';

import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import MessageComponent from 'components_linkio/message_component';
import RadioButton from 'components_linkio/RadioButton';

import './resumeEoProgressionsPopup.scss';

const yesterday = Datetime.moment().subtract(1, 'day');

const ResumeEoProgressionsPopupComponent = ({ onCancel, onResume }) => {
  const [resumeAt, setResumeAt] = React.useState(null);
  const [respErrors, setRespErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  function handleChangeRadioBtn(event) {
    const updatedResumeAt = event.target.value === 'resumeImmediately' ? null : Datetime.moment();
    setResumeAt(updatedResumeAt);
    !isEmpty(respErrors) && setRespErrors({});
  }

  function handleChangeDateTime(value) {
    setResumeAt(value);
    // Remove the error if the date is valid
    !isEmpty(respErrors) && Datetime.moment(value).isValid() && setRespErrors({});
  }

  function isValidDate(current) {
    // Disable all dates before today (prevents user to pick dates in the past)
    return current.isAfter(yesterday);
  }

  async function handleSubmit() {
    setIsLoading(true);

    if (!resumeAt) {
      await onResume(null);
      setIsLoading(false);
      onCancel();
      return;
    }

    const date = Datetime.moment(resumeAt).isValid()
      ? Datetime.moment(resumeAt).format('DD MMM YYYY HH:mm:ss ZZ')
      : resumeAt;

    const errors = await onResume(date);

    if (!isEmpty(errors)) {
      setRespErrors(errors);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    onCancel();
  }

  return (
    <Modal show className="resume-eo-progressions-popup">
      <Modal.Header>
        <Modal.Title>
          {translate('eoProgression.bulkActions.resumeEoProgressionsPopup.title')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4>{translate('eoProgression.bulkActions.resumeEoProgressionsPopup.subtitle')}</h4>

        <div className="resume-eo-progressions-popup__radio-btn-group">
          <RadioButton
            checked={!resumeAt}
            onChange={handleChangeRadioBtn}
            value="resumeImmediately"
          >
            {translate('eoProgression.bulkActions.resumeEoProgressionsPopup.resumeImmediately')}
          </RadioButton>

          <RadioButton checked={!!resumeAt} onChange={handleChangeRadioBtn} value="anotherTime">
            {translate('eoProgression.bulkActions.resumeEoProgressionsPopup.anotherTime')}
          </RadioButton>

          {resumeAt && (
            <>
              <Datetime
                closeOnSelect
                initialValue={Datetime.moment()}
                onChange={handleChangeDateTime}
                isValidDate={isValidDate}
              />
              <MessageComponent
                message={{
                  type: 'error',
                  text: respErrors.resumeAt,
                }}
              />

              <MessageComponent
                message={{
                  type: 'infoBlock',
                  text: translate(
                    'eoProgression.bulkActions.resumeEoProgressionsPopup.dateTimeHint',
                  ),
                }}
              />
            </>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="resume-eo-progressions-popup__btn-group">
          <div className="resume-eo-progressions-popup__btn">
            <ButtonComponent isDisabled={isLoading} isWhite onClick={onCancel}>
              {translate('uiComponents.button.cancel')}
            </ButtonComponent>
          </div>

          <div className="resume-eo-progressions-popup__btn">
            <ButtonComponent isLoading={isLoading} isGreen onClick={handleSubmit}>
              {translate('eoProgression.bulkActions.resumeEoProgressionsPopup.submit')}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ResumeEoProgressionsPopupComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onResume: PropTypes.func,
};

export default ResumeEoProgressionsPopupComponent;
