import React from 'react';
import PropTypes from 'prop-types';

import SearchInput from 'components/SearchInput';

const SearchProspectsInputComponent = ({ onSubmitSearch }) => {
  return <SearchInput onSubmit={onSubmitSearch} />;
};

SearchProspectsInputComponent.propTypes = {
  onSubmitSearch: PropTypes.func.isRequired,
};

export default SearchProspectsInputComponent;
