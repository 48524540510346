import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';

import BacklinksChangesHistoryPageComponent from './backlinksChangesHistoryPageComponent';
import { changesHistoryPagyByBrandIdSelector } from 'selectors/changesHistorySelector';

import { fetchBrandWithMetrics } from 'api/brand';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';

import { pagyShape, withRouterPropTypes } from 'common/prop_types_shapes';

import { currentIdSelector } from 'selectors';

import Breadcrumbs from 'components/NewBreadcrumbs';
import BreadcrumbsBrandsSelect from 'components/NewBreadcrumbs/BreadcrumbsBrandsSelect';
import ButtonComponent from 'components_linkio/button_component';

const BacklinksChangesHistoryPageContainer = ({
  brandId,
  changesHistoryPagy,
  changeQueryParams,
  dispatch,
  history,
  match,
  queryParams,
}) => {
  React.useEffect(() => {
    fetchBrandWithMetrics(dispatch, brandId);
  }, [brandId, dispatch]);


  const handlePagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  function handleSubmitSearch(value) {
    const newQueryParams = {
      ...queryParams,
      search: value,
      pageNumber: 1,
    };

    changeQueryParams(newQueryParams);
  }

  function handleAddBacklinksClick() {
    history.push(Routes.import_backlink_path(brandId, { format: null }));
  }

  function rightSideBtn() {
    return (
      <ButtonComponent isGreen onClick={handleAddBacklinksClick}>
        {translate('changesHistory.buttons.addBacklinks')}
      </ButtonComponent>
    );
  }

  function buildPageTitle() {
    switch (match.url) {
      case Routes.changes_backlink_path(brandId, { format: null }):
        return translate('sidebar.backlinks.changes.text');
      default:
        return void 0;
    }
  }

  const title = buildPageTitle();
  const searchTerm = queryParams.search || '';

  return (
    <>
      <Breadcrumbs rightSideBtn={rightSideBtn()}>
        <Breadcrumbs.LinkItem to={Routes.root_path({ format: null })}>
          {translate('brand.list.title')}
        </Breadcrumbs.LinkItem>
        <Breadcrumbs.TextItem>
          {translate('changesHistory.breadcrumbsTitle')}
        </Breadcrumbs.TextItem>
        <BreadcrumbsBrandsSelect targetAppModule={'changesBacklink'} />
      </Breadcrumbs>

      <div className="page__wrapper">
        <BacklinksChangesHistoryPageComponent
          brandId={brandId}
          changesHistoryPagy={changesHistoryPagy}
          onPagerClick={handlePagerClick}
          onItemsSelectChange={handleItemsSelectChange}
          onSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
          title={title}
          queryParams={queryParams}
        />
      </div>
    </>
  );
};

BacklinksChangesHistoryPageContainer.propTypes = {
  brandId: PropTypes.string,
  changesHistoryPagy: pagyShape,
  ...withRouterPropTypes,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const changesHistoryPagy = changesHistoryPagyByBrandIdSelector(state, brandId);

  return {
    brandId,
    changesHistoryPagy,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(BacklinksChangesHistoryPageContainer);
