import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EoCampaignActionsComponent from './EoCampaignActionsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { deleteEoCampaigns } from 'api/eoCampaign';
import { policiesSelector } from 'selectors/railsContextSelectors';
import { translate } from 'common/i18n';

import confirmationDialogue from 'components/confirmation_dialogue';

const EoCampaignActionsContainer = ({ dispatch, eoCampaignId, canCreate }) => {
  async function handleDeleteCampaign() {
    confirmationDialogue({
      body: translate('confirmations.default.title'),
      onConfirm: async () => await deleteEoCampaigns(dispatch, [eoCampaignId]),
    });
  }

  return (
    <EoCampaignActionsComponent
      onDeleteCampaignClick={handleDeleteCampaign}
      canCreate={canCreate}
    />
  );
};

EoCampaignActionsContainer.propTypes = {
  canCreate: PropTypes.bool,
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.number,
};

function select(state) {
  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);

  return {
    canCreate,
  };
}

export default ConnectStoreHOC(connect(select)(EoCampaignActionsContainer));
