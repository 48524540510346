import React from 'react';
import PropTypes from 'prop-types';

import DateRangeInputComponent from './DateRangeInputComponent';

import CalendarPresetsPopup from 'components_linkio/CalendarPresetsPopup';

const DateRangeInputContainer = ({
  changeDateRange: onChangeDateRange,
  ...props
}) => {
  if (!onChangeDateRange) {
    return <DateRangeInputComponent {...props} />;
  }

  const ref = React.useRef(null);
  const [isShow, setShowCalendarPresetsPopup] = React.useState(false);

  const hide = () => setShowCalendarPresetsPopup(false);
  const show = () => setShowCalendarPresetsPopup(true);

  const changeDateRange = (dateStart, dateEnd) => {
    onChangeDateRange(dateStart, dateEnd);
    hide();
  };

  return (
    <>
      <DateRangeInputComponent
        {...props}
        componentRef={ref}
        show={show}
        isShow={isShow}
      />
      {isShow && (
        <CalendarPresetsPopup
          anchorComponentRef={ref}
          hide={hide}
          changeDateRange={changeDateRange}
          {...props}
        />
      )}
    </>
  );
};

DateRangeInputContainer.propTypes = {
  changeDateRange: PropTypes.func,
};

export default DateRangeInputContainer;
