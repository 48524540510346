import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';
import { currentIdSelector } from './index';

const brandsContext = (state) => state.get('brandsContext', iMap());

export const currentBrandContextSelector = createSelector(
  [brandsContext, currentIdSelector],
  (brandsContext, currentBrandId) => brandsContext.get(currentBrandId, iMap()),
);
