import React from 'react';
import { connect } from 'react-redux';
import { compose, identity } from 'lodash/fp';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import ErrorBoundary from 'decorators/error-boundary';
import Link from 'components_linkio/link';
import { translate } from 'common/i18n';

import { policiesSelector, subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';

import DashboardIcon from 'common/icons/dashboard';
import EmailSettingsIcon from 'common/icons/EmailSettingsIcon';
import HelpImg from 'common/img/help.svg';
import SeoSettingsIcon from 'common/icons/SeoSettingsIcon';
import TeamIcon from 'common/icons/TeamIcon';

import { openSubscriptionLimitReachedPopup } from 'actions/popup_actions';

import './menu.scss';

class Menu extends React.PureComponent {
  handleClickOnDissallowedBySubscription = () => {
    this.props.dispatch(openSubscriptionLimitReachedPopup());
  };

  recurItemCheck = (predicateFn) => {
    const checkFn = (item) => {
      const { children } = item;
      if (children) {
        return children.map(checkFn).some(identity);
      }

      return predicateFn(item);
    };

    return checkFn;
  };

  hasAccessMenuItem = this.recurItemCheck(({ hasPermission }) => hasPermission);

  renderMenuItems = (
    { children, hasPermission = true, href, icon, isActiveCb, onClick, path, target, text },
    key,
  ) => {
    if (!this.hasAccessMenuItem({ hasPermission, children })) {
      return null;
    }

    const { match, location } = this.props;

    if (children) {
      const {
        location: { pathname },
      } = this.props;

      const classes = cx('menu__item', {
        menu__item_active: children
          .map(({ path, isActiveCb }) => ({ path, isActiveCb }))
          .some(({ path, isActiveCb }) =>
            isActiveCb ? isActiveCb(match, location) : path === pathname,
          ),
      });

      return (
        <div className={classes} key={key} title={text}>
          {icon}
          {text}
          <div className="menu__sub-menu">{children.map(this.renderMenuItems)}</div>
        </div>
      );
    }

    if (path || href) {
      const destination = href ? { href } : { to: path };
      return (
        <Link
          activeClassName="menu__item_active"
          as="nav"
          className={cx('menu__item', {
            menu__item_active: isActiveCb && isActiveCb(match, location),
          })}
          exact
          isActive={isActiveCb}
          key={key}
          target={target}
          title={text}
          {...destination}
        >
          {icon}
          {text}
        </Link>
      );
    }

    return (
      <div
        className="menu__item"
        key={key}
        onClick={onClick || this.handleClickOnDissallowedBySubscription}
        title={text}
      >
        {icon}
        {text}
      </div>
    );
  };

  render() {
    const { className, canViewProxies, policies } = this.props;

    const links = [
      {
        icon: <DashboardIcon height="18px" width="18px" />,
        text: translate('sidebar.dashboard'),
        path: Routes.root_path({ format: null }),
        hasPermission: policies.getIn(['brand', 'canView']),
      },
      {
        icon: <TeamIcon fill="currentColor" height="18px" />,
        text: translate('sidebar.team'),
        children: [
          {
            text: translate('sidebar.users'),
            path: Routes.employees_path({ format: null }),
            hasPermission: policies.getIn(['employee', 'canView']),
          },
          {
            text: translate('sidebar.permissions'),
            path: Routes.employees_permissions_path({ format: null }),
            hasPermission: policies.getIn(['permission', 'canView']),
          },
        ],
      },
      {
        icon: (
          <SeoSettingsIcon fill="currentColor" stroke="currentColor" height="18px" width="18px" />
        ),
        text: translate('sidebar.seoSettings'),
        children: [
          {
            text: translate('sidebar.percentageSettings'),
            path: Routes.percentage_settings_path({ format: null }),
            hasPermission: policies.getIn(['percentageSetting', 'canView']),
          },
          {
            text: translate('sidebar.automationSettings'),
            path: Routes.automation_settings_path({ format: null }),
            hasPermission:
              policies.getIn(['ahrefsConnection', 'canUse']) ||
              policies.getIn(['crawlingSettings', 'canView']),
          },
        ],
      },
      {
        icon: (
          <EmailSettingsIcon height="18px" width="18px" fill="currentColor" stroke="currentColor" />
        ),
        text: translate('sidebar.emailSettings'),
        path: Routes.eo_inboxes_settings_path({ format: null }),
        hasPermission: policies.getIn(['emailOutreach', 'canView']),
      },
      {
        icon: <img src={HelpImg} />,
        text: translate('sidebar.help'),
        href: Routes.help_path({ format: null }),
        target: '_blank',
      },
    ];

    if (canViewProxies) {
      links.push({
        text: translate('sidebar.proxies'),
        href: Routes.proxies_path({ format: null }),
        isActiveCb: (_match, location) =>
          location.pathname === Routes.proxies_path({ format: null }),
      });
    }

    return <nav className={cx('menu', className)}>{links.map(this.renderMenuItems)}</nav>;
  }
}

export default compose(
  ConnectStoreHOC,
  connect((state, ownProps) => ({
    policies: policiesSelector(state, ownProps),
    subscriptionPolicies: subscriptionPoliciesSelector(state, ownProps),
    canViewProxies: state.getIn(['railsContext', 'policies', 'proxy', 'canView'], false),
  })),
  withRouter,
  ErrorBoundary,
)(Menu);
