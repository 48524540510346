import React from 'react';

import { insertNotAvailableIfNull } from 'common/utils';
import Spinner from 'components_linkio/Spinner';

export function searchVolumeValueFormatter(value, dataforseoSearchVolumeInProgress) {
  if (dataforseoSearchVolumeInProgress) {
    return <Spinner isBlack />;
  }

  return insertNotAvailableIfNull(Math.round(value) || null);
}
