import React from 'react';
import { NavLink, Link as ReactRouterLink } from 'react-router-dom';

const Link = (props) => {
  const { href, children, className, as, target, rel } = props;

  // if this outside link or we need server-side render
  if (href) {
    const anchorProps = {
      href,
      className,
      target,
      rel,
    };

    return <a {...anchorProps}>{children}</a>;
  }

  // if this using in navigate components (has additional properties like active class)
  if (as === 'nav') {
    return <NavLink {...props} />;
  }

  // base client-side link
  return <ReactRouterLink {...props} />;
};

export default Link;
