import { translate } from '../../common/i18n';

import {
  checkProxiesFailure,
  checkProxiesFinished,
  checkProxiesStart,
} from '../../actions/proxy_actions';

import {
  handleAPIError,
} from './../utils';

const handleCheckAllProxies = (dispatch, data) => {
  const {
    checkAllProxies,
  } = data;

  switch (checkAllProxies) {
    case 'started':
      dispatch(checkProxiesStart());
      break;
    case 'finished':
      dispatch(checkProxiesFinished());
      break;
    case 'failure':
      dispatch(checkProxiesFailure());
      handleAPIError(dispatch, { message: void 0 }, checkProxiesFailure, translate('errors.cantCheckAllProxy'));
      break;
  }
};

export default handleCheckAllProxies;
