export const FETCH_EO_CAMPAIGN_STEPS_PREVIEW__FAILURE = 'FETCH_EO_CAMPAIGN_STEPS_PREVIEW__FAILURE';
export const FETCH_EO_CAMPAIGN_STEPS_PREVIEW__START = 'FETCH_EO_CAMPAIGN_STEPS_PREVIEW__START';
export const FETCH_EO_CAMPAIGN_STEPS_PREVIEW__SUCCESS = 'FETCH_EO_CAMPAIGN_STEPS_PREVIEW__SUCCESS';
export const SEND_TEST_EMAILS__FAILURE = 'SEND_TEST_EMAIL__FAILURE';
export const CHANGE_EO_CAMPAIGN_STEPS_PREVIEW_OPTIONS = 'CHANGE_EO_CAMPAIGN_STEPS_PREVIEW_OPTIONS';
export const RESET_EO_CAMPAIGN_STEPS_PREVIEW_OPTIONS = 'RESET_EO_CAMPAIGN_STEPS_PREVIEW_OPTIONS';

export function fetchEoCampaignStepsPreviewStart() {
  return { type: FETCH_EO_CAMPAIGN_STEPS_PREVIEW__START };
}

export function fetchEoCampaignStepsPreviewSuccess(eoCampaignStepsPreview) {
  return { type: FETCH_EO_CAMPAIGN_STEPS_PREVIEW__SUCCESS, eoCampaignStepsPreview };
}

export function fetchEoCampaignStepsPreviewFailure(errors) {
  return { type: FETCH_EO_CAMPAIGN_STEPS_PREVIEW__FAILURE, errors };
}

export function sendTestEmailsFailure(errors) {
  return { type: SEND_TEST_EMAILS__FAILURE, errors };
}

export function changeEoCampaignStepsPreviewOptions(entityName, entity) {
  return { type: CHANGE_EO_CAMPAIGN_STEPS_PREVIEW_OPTIONS, entityName, entity };
}

export function resetEoCampaignStepsPreviewOptions() {
  return { type: RESET_EO_CAMPAIGN_STEPS_PREVIEW_OPTIONS };
}
