export default function buildChartMetricsDataWith(brandChartMetrics, metricNameArray) {
  const buildChartDataItem = (brandChartMetric) => {
    const chartDataItem = {
      date: brandChartMetric.date,
    };

    metricNameArray.forEach((metricName) => {
      chartDataItem[metricName] = brandChartMetric[metricName];
    });

    return chartDataItem;
  };

  return brandChartMetrics.metrics.map(buildChartDataItem);
}
