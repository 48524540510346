import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  notificationShape,
} from '../../../common/prop_types_shapes';

import NotificationItemComponent from './NotificationItemComponent';


class NotificationItemContainer extends Component {
  static propTypes = {
    notification: notificationShape,
  };

  handleItemClick = () => {
    const { notification, history } = this.props;
    const link = notification.get('link');

    if (link) {
      history.push(link);
    }
  }

  render() {
    const { notification } = this.props;

    return (
      <NotificationItemComponent
        notification={notification}
        onItemClick={this.handleItemClick}
      />
    );
  }
}


export default withRouter(NotificationItemContainer);
