import React from 'react';
import PropTypes from 'prop-types';

import EoInboxTypeSelectPopup from '../EoInboxTypeSelectPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const AddEoInboxButtonComponent = ({ allowedEoInboxesCount, canAddEoInboxes }) => {
  const explanationTooltip = {
    text: translate('eoInboxesSettings.inboxesLimitation')(allowedEoInboxesCount),
    placement: 'top',
    color: 'black',
  };

  return (
    <ToggleShowPopup disabled={!canAddEoInboxes} Popup={<EoInboxTypeSelectPopup />}>
      <ButtonComponent
        isDisabled={!canAddEoInboxes}
        isInitial
        isGreen
        className="add-eo-inbox-button"
        tooltip={!canAddEoInboxes ? explanationTooltip : {}}
      >
        {translate('eoInboxesSettings.buttons.addEoInbox')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

AddEoInboxButtonComponent.propTypes = {
  allowedEoInboxesCount: PropTypes.number,
  canAddEoInboxes: PropTypes.bool,
};

export default AddEoInboxButtonComponent;
