import React from 'react';
import cx from 'classnames';

import { translate } from '../../../common/i18n';
import { insertNotAvailableIfNull } from '../../../common/utils';

import Spinner from '../../../components_linkio/Spinner';
import MinusInRoundIcon from '../../../common/icons/minus_in_round';
import PlusInRoundIcon from '../../../common/icons/plus_in_round';

import Link from 'components_linkio/link';

const buildUrlToggleIcon = (isOpen) => {
  const iconClasses = cx('rank-tracker-table__icon', {
    'rank-tracker-table__icon_green': !isOpen,
    'rank-tracker-table__icon_red': isOpen,
  });

  return isOpen ? (
    <MinusInRoundIcon className={iconClasses} />
  ) : (
    <PlusInRoundIcon className={iconClasses} />
  );
};

function buildRankChanges(ranks, todayRank, isYesterdayRank) {
  const rankValue = ranks.get(0);
  let rankChangeValue = ranks.get(1);

  if (!rankValue && !rankChangeValue) {
    return translate('notAvailable');
  }

  if (rankValue === 'NTH') {
    return rankValue;
  }

  const rankChangesClasses = cx('rank-tracker-table__rank-changes-value', {
    'rank-tracker-table__rank-changes-value_green': rankChangeValue > 0,
    'rank-tracker-table__rank-changes-value_red': rankChangeValue < 0,
  });

  if (isYesterdayRank) {
    rankChangeValue = todayRank ? rankChangeValue : 0;
  }

  const processedRankChangeValue =
    rankChangeValue === null ? translate('notAvailable') : Math.abs(rankChangeValue);

  return (
    <React.Fragment>
      <span>{insertNotAvailableIfNull(rankValue)}</span>
      <span className={rankChangesClasses}>{processedRankChangeValue}</span>
    </React.Fragment>
  );
}

function buildCellClasses(column, groupByUrl) {
  const isRankChangesCell = [
    'todayRank',
    'yesterdayRank',
    'weekAgoRank',
    'monthAgoRank',
    'endDateRank',
    'startDateRank',
  ].includes(column.key);

  const isChangesCountCell = column.key === 'changesCount';
  const isKeywordCell = column.key === 'label';
  const isLinkCell = column.key === 'link' && groupByUrl;

  return cx({
    'rank-tracker-table__rank-changes-cell': isRankChangesCell,
    'rank-tracker-table__changes-count-cell': isChangesCountCell,
    'rank-tracker-table__keyword-cell': isKeywordCell,
    'rank-tracker-table__link-cell': isLinkCell,
    'rank-tracker-table__keyword-cell_group-by-url': isKeywordCell && groupByUrl,
    'capybara--rank-tracker-table__today-rank': column.key === 'todayRank',
    'capybara--rank-tracker-table__yesterday-rank': column.key === 'yesterdayRank',
    'capybara--rank-tracker-table__week-ago-rank': column.key === 'weekAgoRank',
    'capybara--rank-tracker-table__month-ago-rank': column.key === 'monthAgoRank',
    'capybara--rank-tracker-table__start-date-rank': column.key === 'startDateRank',
    'capybara--rank-tracker-table__end-date-rank': column.key === 'endDateRank',
    'capybara--rank-tracker-table__volume-local': column.key === 'dfsSearchVolumeLocal',
    'capybara--rank-tracker-table__volume-global': column.key === 'dfsSearchVolumeGlobal',
  });
}

function getValue(column, object, brandPageId = null) {
  if (column.key === 'changesCount') {
    return column.cellValueFormatter(object, brandPageId, column.isActive);
  }

  if (column.key === 'todayRank') {
    return column.cellValueFormatter(
      object.get('todayRank'),
      object.get('yesterdayRank').first(),
      object.get('dataforseoRankInProgress', false),
    );
  }

  if (column.key === 'yesterdayRank') {
    return column.cellValueFormatter(object.get(column.key), object.get('todayRank'), true);
  }

  if (column.key === 'dfsSearchVolumeLocal') {
    return column.cellValueFormatter(
      object.get('dfsSearchVolumeLocal'),
      object.get('dataforseoSearchVolumeInProgress', false),
    );
  }

  if (column.key === 'dfsSearchVolumeGlobal') {
    return column.cellValueFormatter(
      object.get('dfsSearchVolumeGlobal'),
      object.get('dataforseoSearchVolumeInProgress', false),
    );
  }

  if (column.cellValueFormatter) {
    return column.cellValueFormatter(object.get(column.key));
  }

  return object.get(column.key);
}

function todayRankValueFormatter(value, yesterdayRank, rankInProgress) {
  if (rankInProgress) {
    return <Spinner isBlack />;
  }

  // Show 'yesterday rank' in case there is no 'today rank' yet
  const todayRank = value || yesterdayRank;

  return insertNotAvailableIfNull(todayRank);
}

function getLink(value) {
  return (
    <Link
      className="text_two-lines-with-ellipsis-break-all"
      href={value}
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      {value}
    </Link>
  );
}

export {
  buildCellClasses,
  buildRankChanges,
  buildUrlToggleIcon,
  getValue,
  getLink,
  todayRankValueFormatter,
};
