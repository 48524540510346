import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import EoCampaignStepEmailComponent from './EoCampaignStepEmailComponent';

import { eoCampaignStepShape } from 'common/propTypesShapes/eoCampaignSteps';

import { fetchUsersAsOptions } from 'api/user';

import { optionsSelector } from 'selectors';

import { optionsList, userShape } from 'common/prop_types_shapes';

const EoCampaignStepEmailContainer = ({
  currentUser,
  dispatch,
  immutableUsersOptions,
  onCancel,
  onSave,
  eoCampaignStep,
  eoCampaignStepPosition,
}) => {
  const { id: currentUserId } = currentUser.toJS();

  function handleFetchUsersOptions() {
    fetchUsersAsOptions(dispatch);
  }

  return (
    <EoCampaignStepEmailComponent
      currentUserId={currentUserId}
      handleFetchUsersOptions={handleFetchUsersOptions}
      immutableUsersOptions={immutableUsersOptions}
      onCancel={onCancel}
      onSave={onSave}
      eoCampaignStep={eoCampaignStep}
      eoCampaignStepPosition={eoCampaignStepPosition}
    />
  );
};

EoCampaignStepEmailContainer.propTypes = {
  currentUser: userShape,
  dispatch: PropTypes.func,
  eoCampaignStep: eoCampaignStepShape,
  eoCampaignStepPosition: PropTypes.number,
  immutableUsersOptions: optionsList,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

function select(state) {
  const options = optionsSelector(state);

  const currentUser = state.getIn(['railsContext', 'cuser']);

  return {
    currentUser,
    immutableUsersOptions: options.get('usersOptions'),
  };
}

export default ConnectStoreHOC(connect(select)(EoCampaignStepEmailContainer));
