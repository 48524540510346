import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import CancelFormSubmitDecorator from '../cancel_form_submit_decorator';

import ButtonComponent from '../../../components_linkio/button_component';
import InputComponent from '../../../components_linkio/input_component';

import AuthFormGlobalErrorComponent from '../auth_form_global_error_component';

import './new_marketer_form_component.scss';


class NewMarketerFormComponent extends Component {
  static propTypes = {
    error: PropTypes.string,
    errors: PropTypes.shape({
      title: PropTypes.string,
    }),
    handleCreateMarketerClickAction: PropTypes.func,
    history: PropTypes.shape({
      goBack: PropTypes.func,
    }),
    createMarketerIsLoading: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = { title: '' };
  }

  handleTitleChange = (input) => {
    const title = input.target.value;
    this.setState({ title });
  }

  handleSubmitClick = async () => {
    const title = this.state.title;
    await this.props.handleCreateMarketerClickAction(title);
  }

  handleCancelClick = () => {
    this.props.history.goBack();
  }

  render() {
    const { errors = {}, error, createMarketerIsLoading } = this.props;
    const { title } = this.state;


    return (
      <div className="page__wrapper">
        <div className="new-marketer-form-component">
          <h2 className="new-marketer-form-component__text new-marketer-form-component__text_title">
            {translate('authForms.newMarketer.title')}
          </h2>
          <form className="new-marketer-form-component__form" noValidate>
            <AuthFormGlobalErrorComponent message={error} />
            <div className="new-marketer-form-component__input">
              <InputComponent
                defaultValue={title}
                isMandatory
                label="Account Name"
                message={{ type: 'error', text: errors.title }}
                onInputChange={this.handleTitleChange}
              />
            </div>
            <div className="new-marketer-form-component__buttons">
              <ButtonComponent isWhite onClick={this.handleCancelClick}>
                {translate('authForms.newMarketer.buttons.cancel')}
              </ButtonComponent>
              <ButtonComponent
                isGreen
                isLoading={createMarketerIsLoading}
                onClick={this.handleSubmitClick}
              >
                {translate('authForms.newMarketer.buttons.submit')}
              </ButtonComponent>
            </div>
            <h4>
              <a href={Routes.users_choose_account_url({ format: null })}>
                {translate('prompts.chooseExistingMarketer.linkText')}
              </a>
              <span className="new-marketer-form-component__text new-marketer-form-component__text_subtitle">
                &nbsp;{translate('prompts.chooseExistingMarketer.text')}
              </span>
            </h4>
          </form>
        </div>
      </div>
    );
  }
}

export default CancelFormSubmitDecorator(NewMarketerFormComponent);
