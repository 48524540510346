import { fromJS, List as iList } from 'immutable';
import { camelizeObjectDeep, mergeItemToList } from './utils';

import {
  CREATE_EO_SCHEDULER__SUCCESS,
  DELETE_EO_SCHEDULERS__SUCCESS,
  FETCH_EO_SCHEDULERS__SUCCESS,
  FETCH_EO_SCHEDULER__SUCCESS,
  UPDATE_EO_SCHEDULER__SUCCESS,
} from 'actions/eoSchedulerActions';

const initialState = iList();

export default function eoSchedulersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_SCHEDULERS__SUCCESS: {
      const { eoSchedulers } = action;

      state = fromJS(eoSchedulers.map(camelizeObjectDeep));

      break;
    }
    case FETCH_EO_SCHEDULER__SUCCESS:
    case CREATE_EO_SCHEDULER__SUCCESS:
    case UPDATE_EO_SCHEDULER__SUCCESS: {
      const { eoScheduler } = action;
      state = mergeItemToList(state, eoScheduler, camelizeObjectDeep);

      break;
    }
    case DELETE_EO_SCHEDULERS__SUCCESS: {
      const { ids } = action;

      state = state.filter((eoScheduler) => !ids.includes(eoScheduler.get('id')));

      break;
    }
  }

  return state;
}
