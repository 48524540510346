import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoMessagesTableComponent from './EoMessagesTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { fetchEoMessages } from 'api/eoMessage';
import { eoMessagesList } from 'common/propTypesShapes/eoMessages';

import { eoMessagesSelector, eoMessagesPagySelector } from 'selectors/eoMessagesSelector';
import { currentIdSelector, uiSelector } from 'selectors';

import { resetSelectedIds, toggleSelectedIds } from 'actions/ui_actions';
import { pagyShape, selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const EoMessagesTableContainer = ({
  changeQueryParams,
  dispatch,
  eoCampaignId,
  eoMessages,
  eoMessagesPagy,
  queryParams,
  selectedIds,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const { pageNumber = 1, pagyItemsCount = 100, search = '', sorting = {} } = queryParams;

  React.useEffect(() => {
    async function fetchData() {
      const fetchingParams = {
        items: pagyItemsCount,
        page: pageNumber,
        eoCampaignId,
        search,
        sorting,
      };

      setIsFetching(true);
      await fetchEoMessages(dispatch, fetchingParams);
      setIsFetching(false);
    }

    fetchData();
  }, [
    dispatch,
    eoCampaignId,
    pageNumber,
    pagyItemsCount,
    search,
    sorting.sortBy,
    sorting.sortingOrder,
  ]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectedIds());
    };
  }, [dispatch, eoCampaignId]);

  function handleToggleSelectedRows(eoMessagesIds, isSelected) {
    dispatch(toggleSelectedIds(eoMessagesIds, isSelected));
  }

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  const eoMessagesCount = eoMessagesPagy.get('count');

  return (
    <>
      {eoMessages.size > 0 && (
        <EoMessagesTableComponent
          onTableHeaderClick={handleTableHeaderClick}
          onToggleSelectedRows={handleToggleSelectedRows}
          eoMessages={eoMessages}
          eoMessagesCount={eoMessagesCount}
          selectedIds={selectedIds}
          sorting={sorting}
        />
      )}
      {eoMessages.size === 0 && (
        <BlankStateCtaComponent isLoading={isFetching}>
          <TitleText>
            <strong>{translate('eoMessage.table.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('eoMessage.table.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

EoMessagesTableContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoMessages: eoMessagesList,
  eoMessagesPagy: pagyShape,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoMessages = eoMessagesSelector(state);
  const eoMessagesPagy = eoMessagesPagySelector(state);

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    eoCampaignId,
    eoMessages,
    eoMessagesPagy,
    selectedIds,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(EoMessagesTableContainer);
