import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoCampaignStepsStart,
  fetchEoCampaignStepsSuccess,
  fetchEoCampaignStepsFailure,
  fetchEoCampaignStepStart,
  fetchEoCampaignStepSuccess,
  fetchEoCampaignStepFailure,
  createEoCampaignStepStart,
  createEoCampaignStepSuccess,
  createEoCampaignStepFailure,
  updateEoCampaignStepStart,
  updateEoCampaignStepSuccess,
  updateEoCampaignStepFailure,
  deleteEoCampaignStepsStart,
  deleteEoCampaignStepsSuccess,
  deleteEoCampaignStepsFailure,
  moveEoCampaignStepStart,
  moveEoCampaignStepSuccess,
  moveEoCampaignStepFailure,
} from 'actions/eoCampaignStepActions';

import { resetSelectedIds } from 'actions/ui_actions';

import { translate } from 'common/i18n';

import {
  showErrorMessageFromTranslation,
  showSuccessMessage,
  showSuccessMessageFromTranslation,
} from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoCampaignSteps(dispatch, params) {
  dispatch(fetchEoCampaignStepsStart());
  const { eoCampaignId } = params;

  try {
    const resp = await getFromAPI(Routes.eo_campaign_steps_path(eoCampaignId), {
      ...processObjToSnakeCaseDeep(params),
    });
    dispatch(fetchEoCampaignStepsSuccess(resp.eo_campaign_steps));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoCampaignStepsFailure,
      translate('eoCampaignStep.messages.cantFetchEoCampaignSteps'),
    );
  }
}

export async function fetchEoCampaignStep(dispatch, params) {
  dispatch(fetchEoCampaignStepStart());

  const { eoCampaignId, eoCampaignStepId, eoCampaignStepActionType } = params;

  try {
    const eoCampaignStep = await getFromAPI(
      Routes.eo_campaign_step_path(eoCampaignId, eoCampaignStepId),
      {
        eo_campaign_step_action_type: eoCampaignStepActionType,
      },
    );
    dispatch(fetchEoCampaignStepSuccess(eoCampaignStep));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoCampaignStepFailure,
      translate('eoCampaignStep.messages.cantFetchEoCampaignStep'),
    );
  }
}

export async function createEoCampaignStep(dispatch, eoCampaignId, data) {
  dispatch(createEoCampaignStepStart());

  const { eoCampaignStepActionType } = data;

  try {
    const resp = await postToAPI(
      Routes.eo_campaign_steps_path(eoCampaignId),
      eoCampaignStepObject(data),
    );

    const createdStepFromResp = resp.created_steps.find(
      ({ eo_campaign_step_action_type }) =>
        eo_campaign_step_action_type === eoCampaignStepActionType,
    );

    const errors = (createdStepFromResp || {}).errors;

    if (isEmpty(errors)) {
      resp.created_steps.forEach((eoCampaignStep) => {
        eoCampaignStep && dispatch(createEoCampaignStepSuccess(eoCampaignStep));
      });
      showSuccessMessageFromTranslation(dispatch, 'eoCampaignStep.messages.createSuccess');
    } else {
      dispatch(createEoCampaignStepFailure(errors));
    }

    return processObjToCamelCaseDeep(createdStepFromResp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createEoCampaignStepFailure,
      translate('eoCampaignStep.messages.cantCreateEoCampaignStep'),
    );
  }
}

export async function updateEoCampaignStep(dispatch, eoCampaignId, data) {
  dispatch(updateEoCampaignStepStart());

  try {
    const resp = await patchToAPI(
      Routes.eo_campaign_step_path(eoCampaignId, data.id),
      eoCampaignStepObject(data),
    );

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(updateEoCampaignStepSuccess(eoCampaignId, resp));
      showSuccessMessageFromTranslation(dispatch, 'eoCampaignStep.messages.updateSuccess');
    } else {
      dispatch(updateEoCampaignStepFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoCampaignStepFailure,
      translate('eoCampaignStep.messages.cantUpdateEoCampaignStep'),
    );
  }
}

export async function deleteEoCampaignSteps(dispatch, eoCampaignId, ids) {
  dispatch(deleteEoCampaignStepsStart());

  try {
    const resp = await deleteToAPI(Routes.eo_campaign_steps_path(eoCampaignId), { ids });
    if (isEmpty((resp || {}).errors)) {
      dispatch(resetSelectedIds());
      dispatch(deleteEoCampaignStepsSuccess(eoCampaignId, ids));
      showSuccessMessage(dispatch, translate('eoCampaignStep.messages.deleteSuccess')(ids.length));
    } else {
      dispatch(deleteEoCampaignStepsFailure(ids));
      showErrorMessageFromTranslation(
        dispatch,
        'eoCampaignStep.messages.cantDeleteEoCampaignSteps',
      );
    }
    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      deleteEoCampaignStepsFailure,
      translate('eoCampaignStep.messages.cantDeleteEoCampaignSteps'),
    );
  }
}

export async function moveEoCampaignStep(dispatch, eoCampaignId, data) {
  dispatch(moveEoCampaignStepStart());

  try {
    const resp = await patchToAPI(
      Routes.move_eo_campaign_step_path(eoCampaignId, data.id),
      eoCampaignStepObject(data),
    );
    dispatch(moveEoCampaignStepSuccess(eoCampaignId, resp.eo_campaign_steps));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      moveEoCampaignStepFailure,
      translate('eoCampaignStep.messages.cantMoveEoCampaignStep'),
    );
  }
}

const eoCampaignStepObject = (data) => ({ eo_campaign_step: processObjToSnakeCaseDeep(data) });
