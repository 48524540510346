import { isEmpty } from 'lodash';

import { AHREFS_PROVIDED_METRICS } from '../constants';

import { enqueueAhrefsMetricsUploading } from 'api/brand_page';

function fetchAhrefsMetricsIfNeededService(options) {
  const { brandPage, competitorPages, dispatch, hasAhrefsConnection } = options;

  if (!hasAhrefsConnection) {
    return;
  }

  const hasMissingRequiredAhrefsProvidedMetrics = (page) => {
    const metrics = AHREFS_PROVIDED_METRICS.map((metric) => page.getIn(['metrics', metric]));

    // return false if any of metrics is skipped
    return metrics.includes(null);
  };

  const pageIds = [];
  if (hasMissingRequiredAhrefsProvidedMetrics(brandPage)) {
    pageIds.push(brandPage.get('id'));
  }

  competitorPages.forEach((page) => {
    if (hasMissingRequiredAhrefsProvidedMetrics(page)) {
      pageIds.push(page.get('id'));
    }
  });

  if (isEmpty(pageIds)) {
    return;
  }

  enqueueAhrefsMetricsUploading(dispatch, pageIds);
}

export default fetchAhrefsMetricsIfNeededService;
