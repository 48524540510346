import React from 'react';

const ArrowVectorIcon = ({ direction, ...props }) => {
  let rotateDegree = '';

  switch (direction) {
    case 'up':
      rotateDegree = 'rotate(0)';
      break;
    case 'right':
      rotateDegree = 'rotate(90)';
      break;
    case 'down':
      rotateDegree = 'rotate(180)';
      break;
    case 'left':
      rotateDegree = 'rotate(270)';
      break;
  }

  return (
    <svg {...props} transform={rotateDegree} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 15.833V4.167M4.167 10L10 4.167 15.833 10"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowVectorIcon.defaultProps = {
  height: '14px',
  stroke: '#474A4D',
  width: '14px',
};

export default ArrowVectorIcon;
