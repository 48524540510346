import { createSelector } from 'reselect';
import { Map as iMap, List as iList } from 'immutable';

const eoCampaignSteps = (state) => state.get('eoCampaignSteps', iList());
const eoCampaignStepById = (state, eoCampaignStepId) =>
  eoCampaignSteps(state).find((eoCampaignStep) => eoCampaignStep.get('id') === eoCampaignStepId);

export const eoCampaignStepsSelector = createSelector(
  [eoCampaignSteps],
  (eoCampaignSteps) => eoCampaignSteps,
);

export const eoCampaignStepByIdSelector = createSelector(
  [eoCampaignStepById],
  (eoCampaignStepById) => eoCampaignStepById || iMap,
);
