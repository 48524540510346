import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import NavbarComponent from './NavbarComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  currentUserSelector,
  currentMarketerSelector,
  policiesSelector,
} from 'selectors/railsContextSelectors';

function select(state, ownProps) {
  const currentPath = ownProps.location.pathname;
  const currentUser = currentUserSelector(state, ownProps);
  const currentMarketer = currentMarketerSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);
  const isUserTypeSelectionPage =
    currentPath === Routes.user_type_selection_index_path({ format: null });
  const isChooseAccountPage = currentPath === Routes.users_choose_account_path({ format: null });
  const isUnsubscribePage =
    currentPath === Routes.unsubscribe_newsletter_settings_path({ format: null });
  const isUnsubscribeProspectPage =
    currentPath === Routes.unsubscribe_prospects_path({ format: null });
  const hideAvatar = isUnsubscribePage || isUnsubscribeProspectPage || isUserTypeSelectionPage;
  const hideTopMenu = isUnsubscribePage || isUnsubscribeProspectPage || isUserTypeSelectionPage;
  const hideNotificationsWrapper =
    isChooseAccountPage ||
    isUnsubscribePage ||
    isUnsubscribeProspectPage ||
    isUserTypeSelectionPage;
  const hideRoboSubscriptionStatus =
    currentPath === Routes.new_marketer_path({ format: null }) ||
    isChooseAccountPage ||
    isUnsubscribePage ||
    isUnsubscribeProspectPage ||
    isUserTypeSelectionPage;

  return {
    currentUser,
    currentMarketer,
    policies,
    hideRoboSubscriptionStatus,
    hideNotificationsWrapper,
    hideAvatar,
    hideTopMenu,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(NavbarComponent);
