import { Map as iMap, fromJS } from 'immutable';
import { keyBy } from 'lodash';

import {
  ADD_MESSAGE,
  REMOVE_MESSAGE,
} from '../actions/messages_actions';

const initialState = iMap();

export default function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_MESSAGE: {
      state = state.merge(state, fromJS(keyBy([action.message], 'id')));

      break;
    }
    case REMOVE_MESSAGE: {
      state = state.delete(action.id);

      break;
    }
  }

  return state;
}
