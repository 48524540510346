import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoCampaignOverviewComponent from './EoCampaignOverviewPageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { fetchEoCampaignMetrics } from 'api/eoCampaign';
import { currentIdSelector } from 'selectors';
import { eoCampaignMetricsSelector } from 'selectors/eoCampaignMetricsSelector';
import { eoCampaignMetricsMap } from 'common/propTypesShapes/eoMetrics';

const EoCampaignOverviewPageContainer = ({ dispatch, eoCampaignId, eoCampaignMetrics }) => {
  React.useEffect(() => {
    fetchEoCampaignMetrics(dispatch, eoCampaignId);
  }, [dispatch, eoCampaignId]);

  return <EoCampaignOverviewComponent eoCampaignMetrics={eoCampaignMetrics} />;
};

EoCampaignOverviewPageContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoCampaignMetrics: eoCampaignMetricsMap,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoCampaignMetrics = eoCampaignMetricsSelector(state);

  return {
    eoCampaignId,
    eoCampaignMetrics,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoCampaignOverviewPageContainer);
