export default (args) => {
  return {
    percentagesAnalysis: {
      table: {
        title: 'Competitor Anchor Text Percentages Vs. Yours',
        rows: {
          branded: {
            title: 'Branded',
          },
          keyword: {
            title: 'Keyword',
          },
          hybrid: {
            title: 'Hybrid',
          },
          url: {
            title: 'URL',
          },
          natural: {
            title: 'Natural',
          },
          countTowardAverages: {
            title: 'Count Toward Averages',
          },
        },
        columns: {
          anchorType: {
            title: 'Anchor Type',
          },
          competitors: {
            title: 'Competitors',
          },
          average: {
            title: 'Average',
          },
          myBrand: {
            title: 'My Brand',
            idealTitle: 'Ideal',
            actualTitle: 'Actual',
          },
        },
      },
      editButtonTitle: 'Edit Ideal Percentages',
      addCompetitors: '+ Add Competitors',
      tooLittleRds: {
        linkText: 'Learn more.',
        title: 'Consider not counting if less than 10 RD. ',
      },
    },
  };
};
