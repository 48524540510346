import React from 'react';

const PersonIcon = (props) => {
  return (
    <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.167 17.5v-1.667a3.333 3.333 0 00-3.334-3.333H7.167a3.333 3.333 0 00-3.334 3.333V17.5h13.334zM10.5 9.167a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z"
        stroke="#333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

PersonIcon.defaultProps = {
  width: '20px',
  height: '20px',
  cursor: 'pointer',
};

export default PersonIcon;
