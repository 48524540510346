const IMPORT_DESTINATION_LINKS_FROM_CSV__FAILURE = 'IMPORT_DESTINATION_LINKS_FROM_CSV__FAILURE';
const IMPORT_DESTINATION_LINKS_FROM_CSV__START = 'IMPORT_DESTINATION_LINKS_FROM_CSV__START';
const IMPORT_DESTINATION_LINKS_FROM_CSV__SUCCESS = 'IMPORT_DESTINATION_LINKS_FROM_CSV__SUCCESS';
const IMPORT_DESTINATION_LINKS_MANUALLY__FAILURE = 'IMPORT_DESTINATION_LINKS_MANUALLY__FAILURE';
const IMPORT_DESTINATION_LINKS_MANUALLY__START = 'IMPORT_DESTINATION_LINKS_MANUALLY__START';
const IMPORT_DESTINATION_LINKS_MANUALLY__SUCCESS = 'IMPORT_DESTINATION_LINKS_MANUALLY__SUCCESS';
const IMPORT_DESTINATION_LINKS_FROM_AHREFS__FAILURE = 'IMPORT_DESTINATION_LINKS_FROM_AHREFS__FAILURE';
const IMPORT_DESTINATION_LINKS_FROM_AHREFS__START = 'IMPORT_DESTINATION_LINKS_FROM_AHREFS__START';
const IMPORT_DESTINATION_LINKS_FROM_AHREFS__SUCCESS = 'IMPORT_DESTINATION_LINKS_FROM_AHREFS__SUCCESS';
const IMPORT_DESTINATION_LINKS_FROM_MOZ__FAILURE = 'IMPORT_DESTINATION_LINKS_FROM_MOZ__FAILURE';
const IMPORT_DESTINATION_LINKS_FROM_MOZ__START = 'IMPORT_DESTINATION_LINKS_FROM_MOZ__START';
const IMPORT_DESTINATION_LINKS_FROM_MOZ__SUCCESS = 'IMPORT_DESTINATION_LINKS_FROM_MOZ__SUCCESS';

function importDestinationLinksFromCSVStart(brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_FROM_CSV__START, brandPageId };
}

function importDestinationLinksFromCSVSuccess(brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_FROM_CSV__SUCCESS, brandPageId };
}

function importDestinationLinksManuallyFailure(brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_MANUALLY__FAILURE, brandPageId };
}

function importDestinationLinksManuallyStart(brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_MANUALLY__START, brandPageId };
}

function importDestinationLinksManuallySuccess(brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_MANUALLY__SUCCESS, brandPageId };
}

function importDestinationLinksFromCSVFailure(brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_FROM_CSV__FAILURE, brandPageId };
}

function importDestinationLinksFromAhrefsFailure(errors, brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_FROM_AHREFS__FAILURE, errors, brandPageId };
}

function importDestinationLinksFromAhrefsStart(brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_FROM_AHREFS__START, brandPageId };
}

function importDestinationLinksFromAhrefsSuccess(brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_FROM_AHREFS__SUCCESS, brandPageId };
}

function importDestinationLinksFromMozFailure(errors, brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_FROM_MOZ__FAILURE, errors, brandPageId };
}

function importDestinationLinksFromMozStart(brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_FROM_MOZ__START, brandPageId };
}

function importDestinationLinksFromMozSuccess(brandPageId) {
  return { type: IMPORT_DESTINATION_LINKS_FROM_MOZ__SUCCESS, brandPageId };
}

export {
  IMPORT_DESTINATION_LINKS_FROM_AHREFS__FAILURE,
  IMPORT_DESTINATION_LINKS_FROM_AHREFS__START,
  IMPORT_DESTINATION_LINKS_FROM_AHREFS__SUCCESS,
  IMPORT_DESTINATION_LINKS_FROM_CSV__FAILURE,
  IMPORT_DESTINATION_LINKS_FROM_CSV__START,
  IMPORT_DESTINATION_LINKS_FROM_CSV__SUCCESS,
  IMPORT_DESTINATION_LINKS_FROM_MOZ__FAILURE,
  IMPORT_DESTINATION_LINKS_FROM_MOZ__START,
  IMPORT_DESTINATION_LINKS_FROM_MOZ__SUCCESS,
  IMPORT_DESTINATION_LINKS_MANUALLY__FAILURE,
  IMPORT_DESTINATION_LINKS_MANUALLY__START,
  IMPORT_DESTINATION_LINKS_MANUALLY__SUCCESS,
  importDestinationLinksFromAhrefsFailure,
  importDestinationLinksFromAhrefsStart,
  importDestinationLinksFromAhrefsSuccess,
  importDestinationLinksFromCSVFailure,
  importDestinationLinksFromCSVStart,
  importDestinationLinksFromCSVSuccess,
  importDestinationLinksFromMozFailure,
  importDestinationLinksFromMozStart,
  importDestinationLinksFromMozSuccess,
  importDestinationLinksManuallyFailure,
  importDestinationLinksManuallyStart,
  importDestinationLinksManuallySuccess,
};
