import React from 'react';
import PropTypes from 'prop-types';

import OverlayWithTooltipComponent from 'components_linkio/overlay_with_tooltip_component';

import { keywordsArray } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import EditIcon from 'common/icons/edit';

import './keywordsValueFormatter.scss';

const MAX_NUMBER_OF_KEYWORDS_TO_SHOW = 1;

function buildKeywordsCell(keywords) {
  const needToHideSomeKeywords = keywords.length > MAX_NUMBER_OF_KEYWORDS_TO_SHOW;

  const keywordsToShow = needToHideSomeKeywords
    ? keywords.slice(0, MAX_NUMBER_OF_KEYWORDS_TO_SHOW)
    : keywords;

  const hiddenKeywordsCount = needToHideSomeKeywords
    ? keywords.length - MAX_NUMBER_OF_KEYWORDS_TO_SHOW
    : 0;

  return (
    <>
      {keywordsToShow.map((keyword) => {
        return (
          <span className="keywords-value-formatter__keyword" key={keyword.id}>
            {keyword.label}
          </span>
        );
      })}
      {needToHideSomeKeywords && (
        <span className="keywords-value-formatter__more-keywords-badge">
          {`${hiddenKeywordsCount} ${translate('more')}`}
        </span>
      )}
    </>
  );
}

const KeywordsValueFormatterComponent = ({ keywords, onClick }) => {
  const tooltip =
    keywords.length > 0
      ? {
          text: keywords.map((kw) => kw.label).join(', '),
          placement: 'top',
        }
      : void 0;

  return (
    <div className="keywords-value-formatter" onClick={onClick}>
      <OverlayWithTooltipComponent isCopyable tooltip={tooltip}>
        <div className="keywords-value-formatter__keywords">{buildKeywordsCell(keywords)}</div>
      </OverlayWithTooltipComponent>
      <div className="keywords-value-formatter__edit-icon-wrapper">
        <EditIcon className="common-field__edit-icon" />
      </div>
    </div>
  );
};

KeywordsValueFormatterComponent.propTypes = {
  keywords: keywordsArray,
  onClick: PropTypes.func,
};

export default KeywordsValueFormatterComponent;
