import React from 'react';

const ListIcon = (props) => (
  <svg {...props} viewBox="0 0 16 16">
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M12.5 0h-9A3.5 3.5 0 0 0 0 3.5v9A3.5 3.5 0 0 0 3.5 16h9a3.5 3.5 0 0 0 3.5-3.5v-9A3.5 3.5 0 0 0 12.5 0zM15 12.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5v-9A2.5 2.5 0 0 1 3.5 1h9A2.5 2.5 0 0 1 15 3.5v9z"
        fillRule="nonzero"
      />
      <rect x={3.5} y={3.5} width={2} height={2} rx={1} />
      <rect x={3.5} y={7} width={2} height={2} rx={1} />
      <rect x={3.5} y={10.5} width={2} height={2} rx={1} />
      <path d="M6.5 4h6v1h-6zm0 3.5h6v1h-6zm0 3.5h6v1h-6z" />
    </g>
  </svg>
);

ListIcon.defaultProps = {
  width: '16px',
  height: '16px',
};

export default ListIcon;
