import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import BulkPagesSetupTableComponent from './BulkPagesSetupTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { currentIdSelector, optionsSelector, uiSelector } from 'selectors';
import { draftBrandPagesMap, optionsMap, selectedIdsList } from 'common/prop_types_shapes';
import { fetchBulkSetupBrandPages, updateDraftBrandPage } from 'api/bulkPagesSetup';
import { fetchPercentageSchemes } from 'api/percentage_scheme';
import { fetchPageTypes } from 'api/page_type';

import {
  bulkSetupBrandPagesSelector,
  bulkPagesSetupPagyByBrandIdSelector,
} from 'selectors/bulkPagesSetupSelector';

import { openEditKeywordsPopup } from 'actions/popup_actions';
import { toggleSelectedIds, resetSelectedIds } from 'actions/ui_actions';
import { updateDraftBrandPagesRows } from 'actions/bulkPagesSetupActions';

const BulkPagesSetupTableContainer = ({
  brandId,
  dispatch,
  draftBrandPages,
  isLoading,
  pageTypeOptions,
  percentageSchemeOptions,
  queryParams,
  selectedIds,
}) => {
  const { filters = {}, pageNumber = 1, pagyItemsCount = 100, search = '' } = queryParams;

  React.useEffect(() => {
    Promise.all([fetchPageTypes(dispatch), fetchPercentageSchemes(dispatch)]);
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(resetSelectedIds());

    return () => {
      // Drop selected ids on component unmount
      dispatch(resetSelectedIds());
    };
  }, [brandId, dispatch]);

  React.useEffect(() => {
    function fetchPages() {
      const fetchingParams = {
        filters,
        items: pagyItemsCount,
        page: pageNumber,
        search,
      };

      fetchBulkSetupBrandPages(dispatch, brandId, fetchingParams);
    }

    fetchPages();
  }, [brandId, dispatch, JSON.stringify(filters), pageNumber, pagyItemsCount, search]);

  // Need to update state after selecting links
  function handleUpdateRows(pages) {
    dispatch(updateDraftBrandPagesRows(pages));
  }

  function handleUpdateDraftBrandPage(page) {
    updateDraftBrandPage(dispatch, page);
  }

  function handleToggleSelectedRows(pagesIds, isSelected) {
    dispatch(toggleSelectedIds(pagesIds, isSelected));

    // Despite we keep selected ids separated in Redux store, we also need
    // to keep updated draft page's "select" state, cause backend relies on it in some operations
    const updatedRows = pagesIds.reduce((resultedRows, pageId) => {
      const updatedRow = draftBrandPages
        .find((page) => page.get('id') === pageId)
        .set('selected', isSelected)
        .toJS();

      resultedRows.push(updatedRow);

      return resultedRows;
    }, []);

    handleUpdateRows(updatedRows);
  }

  function handleClickKeywordsCell(pageId) {
    dispatch(openEditKeywordsPopup({ pageId, targetAppModule: 'bulkPagesSetup' }));
  }

  return (
    <>
      <BulkPagesSetupTableComponent
        immutableDraftBrandPages={draftBrandPages}
        isLoading={isLoading}
        onClickKeywordsCell={handleClickKeywordsCell}
        onToggleSelectedRows={handleToggleSelectedRows}
        onUpdateDraftBrandPage={handleUpdateDraftBrandPage}
        onUpdateRows={handleUpdateRows}
        pageTypeOptions={pageTypeOptions}
        percentageSchemeOptions={percentageSchemeOptions}
        selectedPagesIds={selectedIds}
      />
    </>
  );
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const bulkPagesSetupPagy = bulkPagesSetupPagyByBrandIdSelector(state, brandId);
  const draftBrandPages = bulkSetupBrandPagesSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const pageTypeOptions = options.get('pageTypeOptions');
  const percentageSchemeOptions = options.get('percentageSchemeOptions');

  const isLoading =
    state.getIn(['ui', 'loading', 'fetchAutoDiscoverPagesViaSitemap', brandId], false) ||
    state.getIn(['ui', 'loading', 'importFromGSC', brandId], false);
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    brandId,
    bulkPagesSetupPagy,
    draftBrandPages,
    isLoading,
    pageTypeOptions,
    percentageSchemeOptions,
    selectedIds,
  };
}

BulkPagesSetupTableContainer.propTypes = {
  brandId: PropTypes.string,
  dispatch: PropTypes.func,
  draftBrandPages: draftBrandPagesMap,
  history: PropTypes.shape({ push: PropTypes.func }),
  isLoading: PropTypes.bool,
  pageTypeOptions: optionsMap,
  percentageSchemeOptions: optionsMap,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(BulkPagesSetupTableContainer);
