import React from 'react';
import PropTypes from 'prop-types';

import EoCampaignsTabs from 'pages/EoCampaignsPage/EoCampaignsTabs';

import { pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';
import EoDripListsBulkActions from './EoDripListsBulkActions';
import EoDripListsTable from './EoDripListsTable';

const EoDripListsComponent = ({
  canCreate,
  onItemsSelectChange,
  onPagesPagerClick,
  eoDripListsPagy,
}) => {
  return (
    <div className="eo-drip-lists">
      <EoCampaignsTabs />

      <EoDripListsBulkActions canCreate={canCreate} />

      <EoDripListsTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="rowsPager"
        pagy={eoDripListsPagy}
      />
    </div>
  );
};

EoDripListsComponent.propTypes = {
  canCreate: PropTypes.bool,
  eoDripListsPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoDripListsComponent;
