import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, isEmpty } from 'lodash';

import EoDomainFormPopupComponent from './EoDomainFormPopupComponent';

import { eoDomainShape } from 'common/propTypesShapes/eoDomains';

import { createEoDomain, updateEoDomain } from 'api/eoDomain';

import ConnectStoreHOC from 'startup/connect_store_hoc';

const EoDomainFormPopupContainer = ({ dispatch, onClose, tableRowData: eoDomain }) => {
  async function handleSave(eoDomainFormData) {
    const resp = eoDomain
      ? await updateEoDomain(dispatch, eoDomainFormData)
      : await createEoDomain(dispatch, eoDomainFormData);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      return resp;
    }

    onClose();

    return resp;
  }

  return <EoDomainFormPopupComponent eoDomain={eoDomain} onCancel={onClose} onSave={handleSave} />;
};

EoDomainFormPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  tableRowData: eoDomainShape,
};

export default compose(ConnectStoreHOC, connect())(EoDomainFormPopupContainer);
