import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import OverlayWithTooltipComponent from 'components/overlay_with_tooltip_component';

function handleOnFocusAddLinksCount(event) {
  ((event || {}).target || {}).select();
}

function tooltip(text, placement = 'top') {
  return {
    text,
    placement,
    color: 'black',
  };
}

const AddSuggestionsInputComponent = ({
  addLinksCount,
  onAddSuggestions,
  onChangeAddLinksCount,
}) => {
  const addAnchorsTooltip = tooltip(translate('explanationMessages.atpBulkAction.addAnchors'));

  return (
    <div className="bulk-atp-actions-component__action">
      <div className="bulk-atp-actions-component__input">
        <InputComponent
          max="100"
          min="0"
          onFocus={handleOnFocusAddLinksCount}
          onInputChange={onChangeAddLinksCount}
          placeholder="0"
          type="number"
          value={addLinksCount}
        />
      </div>
      <OverlayWithTooltipComponent tooltip={addAnchorsTooltip}>
        <div className="bulk-atp-actions-component__button">
          <ButtonComponent isDisabled={!addLinksCount} isGreen onClick={onAddSuggestions}>
            {translate('destinationLink.buttons.addAnchors.text')}
          </ButtonComponent>
        </div>
      </OverlayWithTooltipComponent>
    </div>
  );
};

AddSuggestionsInputComponent.propTypes = {
  addLinksCount: PropTypes.string,
  onAddSuggestions: PropTypes.func.isRequired,
  onChangeAddLinksCount: PropTypes.func.isRequired,
};

export default AddSuggestionsInputComponent;
