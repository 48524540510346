import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import NewsletterSettingsUnsubscribeComponent from './NewsletterSettingsUnsubscribeComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { newsletterSettingsUnsubscribe, fetchNewsletterSettings } from 'api/newsletterSettings';
import { newsletterSettingsMap } from 'common/prop_types_shapes';

import { newsletterSettingsSelector } from 'selectors/newsletterSettingsSelector';

const NewsletterSettingsUnsubscribeContainer = ({
  dispatch,
  newsletterSettings,
  queryParams,
}) => {
  const { user_id, marketer_id } = queryParams;

  React.useEffect(() => {
    async function fetchData() {
      await fetchNewsletterSettings(dispatch, user_id, marketer_id);
    }

    fetchData();
  }, [dispatch]);

  function handleCancel() {
    window.location.replace(Routes.root_path);
  }

  async function handleUnsubscribe() {
    await newsletterSettingsUnsubscribe(dispatch, user_id, marketer_id);
  }

  function isSubscribed() {
    const receivePeriod = newsletterSettings.get('receivePeriod', '');
    const receiveBrandIds = newsletterSettings.get('receiveBrandIds', []);

    const unsubscribedByReceivePeriod = receivePeriod === 'never';
    const unsubscribedByReceiveBrandIds = receiveBrandIds.length === 0;

    return !(unsubscribedByReceivePeriod || unsubscribedByReceiveBrandIds);
  }

  return (
    <div className="newsletter-settings-page page__wrapper">
      <NewsletterSettingsUnsubscribeComponent
        onCancel={handleCancel}
        onUnsubscribe={handleUnsubscribe}
        isSubscribed={isSubscribed()}
      />
    </div>
  );
};

NewsletterSettingsUnsubscribeContainer.propTypes = {
  ...withQueryParamsPropTypes,
  dispatch: PropTypes.func,
  newsletterSettings: newsletterSettingsMap,
};

function select(state) {
  const newsletterSettings = newsletterSettingsSelector(state);
  return {
    newsletterSettings,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select)
)(NewsletterSettingsUnsubscribeContainer);
