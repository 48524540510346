import React from 'react';
import { range } from 'lodash';
import { format, addDays, startOfWeek } from 'date-fns';

import './daysOfWeek.scss';

const DaysOfWeekComponent = () => {
  const startDate = startOfWeek(new Date());

  return (
    <div className="days-of-week">
      {range(7).map((i) => (
        <div className="days-of-week__day" key={i}>
          {format(addDays(startDate, i), 'iii')}
        </div>
      ))}
    </div>
  );
};

export default DaysOfWeekComponent;
