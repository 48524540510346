import { translate } from '../common/i18n';

import {
  checkProxiesFailure,
  checkProxiesStart,
  createProxiesFailure,
  createProxiesStart,
  createProxiesSuccess,
  deleteProxyFailure,
  deleteProxyStart,
  deleteProxySuccess,
  fetchProxiesFailure,
  fetchProxiesStart,
  fetchProxiesSuccess,
} from '../actions/proxy_actions';

import { deleteToAPI, getFromAPI, postToAPI, handleAPIError } from './utils';

async function fetchProxies(dispatch) {
  dispatch(fetchProxiesStart());
  try {
    const resp = await getFromAPI(Routes.proxies_path());

    dispatch(fetchProxiesSuccess(resp.proxies));
    return resp.proxies;
  } catch (err) {
    return handleAPIError(dispatch, err, fetchProxiesFailure, translate('errors.cantFetchProxy'));
  }
}

async function createProxies(dispatch, data) {
  dispatch(createProxiesStart());
  try {
    const resp = await postToAPI(Routes.proxies_path(), { proxies: data });

    dispatch(createProxiesSuccess(resp.proxies));
    return resp.proxies;
  } catch (err) {
    return handleAPIError(dispatch, err, createProxiesFailure, translate('errors.cantCreateProxy'));
  }
}

async function checkAllProxies(dispatch) {
  dispatch(checkProxiesStart());
  try {
    await getFromAPI(Routes.check_all_proxies_path());
    return void 0;
  } catch (err) {
    return handleAPIError(dispatch, err, checkProxiesFailure, translate('errors.cantCheckAllProxy'));
  }
}

async function deleteProxy(dispatch, ids) {
  dispatch(deleteProxyStart());
  try {
    const resp = await deleteToAPI(Routes.bulk_destroy_proxies_path({ ids }));

    dispatch(deleteProxySuccess(ids));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, deleteProxyFailure, translate('errors.cantDeleteProxy'));
  }
}

export {
  checkAllProxies,
  createProxies,
  deleteProxy,
  fetchProxies,
};
