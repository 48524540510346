import { fromJS, Map as iMap } from 'immutable';

import {
  FETCH_ALL_NOTIFICATIONS__SUCCESS,
  FETCH_RECENT_NOTIFICATIONS__SUCCESS,
  FETCH_UNREAD_NOTIFICATIONS_COUNT__SUCCESS,
  MARK_ALL_NOTIFICATIONS_AS_READ__SUCCESS,
} from '../actions/notificationActions';

import {
  camelizeObjectDeep,
} from './utils';


const initialState = iMap();

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_NOTIFICATIONS__SUCCESS: {
      const { notifications, unreadCount } = action.resp;
      const allNotifications = notifications.map(camelizeObjectDeep);

      state = state.merge(
        state,
        fromJS({ all: allNotifications, unreadCount })
      );

      break;
    }
    case FETCH_RECENT_NOTIFICATIONS__SUCCESS: {
      const { notifications, unreadCount } = action.resp;
      const recentNotifications = notifications.map(camelizeObjectDeep);

      state = state.merge(
        state,
        fromJS({ recent: recentNotifications, unreadCount })
      );

      break;
    }
    case FETCH_UNREAD_NOTIFICATIONS_COUNT__SUCCESS:
    case MARK_ALL_NOTIFICATIONS_AS_READ__SUCCESS: {
      const { unreadCount } = action.resp;
      state = state.merge(state, fromJS({ unreadCount }));

      break;
    }
  }

  return state;
}
