import { each, compact } from 'lodash';
import { List as iList, Map as iMap } from 'immutable';
import moment from 'moment';

import crawlersStatusService from '../../pages/Pages/PagesList/PageItem/PageDls/services/crawlersStatusService';

import { translate } from '../i18n';

const TABLE_CELL_ACTIVE_CLASS = 'table__cell_active';
const TABLE_CELL_ERROR_CLASS = 'table__cell_error';
const TABLE_ROW_ACTIVE_CLASS = 'table__row-active';
const TABLE_ROW_CHANGED_CLASS = 'table__row-changed';
const TABLE_ROW_CURRENT_USER_CLASS = 'table__row-current-user';

/**
 * Assign css class to passed dom element
 * @param {HTMLElement} el - Dom element
 * @param {string} cssClass - CSS class
 */
const addCSSClassToElement = (el, cssClass) => el.classList.add(cssClass);
/**
 * Remove css class from passed dom element
 * @param {HTMLElement} el - Dom element
 * @param {string} cssClass - CSS class
 */
const removeCSSClassFromElement = (el, cssClass) => el.classList.remove(cssClass);
/**
 * Remove css class from all table cells
 * @param {HTMLElement} table - Table dom element
 * @param {string} cssClass - CSS class
 */
const removeCSSClassFromTableCells = (table, cssClass) => {
  if (!table) {
    return;
  }
  each(table.getElementsByTagName('tbody')[0].getElementsByTagName('tr'), (row) => {
    each(row.getElementsByTagName('td'), (cell) => cell.classList.remove(cssClass));
  });
};
/**
 * Higlight table row for 2 second
 * @param {HTMLElement} table - Table dom element
 * @param {number} row - Table row number
 */
const highlightTableRow = (table, row) => {
  addCSSClassToRowInTable(table, row, TABLE_ROW_CHANGED_CLASS);
  setTimeout(() => removeCSSClassFromRowInTable(table, row, TABLE_ROW_CHANGED_CLASS), 1000);
};
/**
 * Get table row (tr) element by number
 * @param {HTMLElement} table - Table dom element
 * @param {number} rowIndex - Table row index. Can be negative and in that case will return row from end of array
 * @return {Array} Array with columns size
 */
const getTableRowElement = (table, rowIndex) => {
  const rows =
    table &&
    table.getElementsByTagName('tbody')[0] &&
    table.getElementsByTagName('tbody')[0].getElementsByTagName('tr') &&
    table.getElementsByTagName('tbody')[0].getElementsByTagName('tr');

  return rowIndex < 0 ? rows[rows.length + rowIndex] : rows[rowIndex];
};
/**
 * Add CSS class to row in table
 * @param {HTMLElement} table - Table dom element
 * @param {number} row - Table row number
 * @param {string} cssClass - CSS class
 */
const addCSSClassToRowInTable = (table, row, cssClass) => {
  const rowEl = getTableRowElement(table, row);
  if (!rowEl) {
    return;
  }
  addCSSClassToElement(rowEl, cssClass);
};
/**
 * Remove CSS class from row in table
 * @param {HTMLElement} table - Table dom element
 * @param {number} row - Table row number
 * @param {string} cssClass - CSS class
 */
const removeCSSClassFromRowInTable = (table, row, cssClass) => {
  const rowEl = getTableRowElement(table, row);
  if (!rowEl) {
    return;
  }
  removeCSSClassFromElement(rowEl, cssClass);
};
/**
 * Add CSS class to cell in table
 * @param {HTMLElement} table - Table dom element
 * @param {number} row - Table row number
 * @param {number} cell - Table number of cell in row
 * @param {string} cssClass - CSS class
 */
const addCSSClassToCellInTable = (table, row, cell, cssClass) => {
  const rowEl = getTableRowElement(table, row);
  if (!rowEl) {
    return;
  }
  const cellEl = rowEl.getElementsByTagName('td')[cell];
  if (!cellEl) {
    return;
  }
  addCSSClassToElement(cellEl, cssClass);
};
/**
 * Remove CSS class from cell in table
 * @param {HTMLElement} table - Table dom element
 * @param {number} row - Table row number
 * @param {number} cell - Table number of cell in row
 * @param {string} cssClass - CSS class
 */
const removeCSSClassFromCellInTable = (table, row, cell, cssClass) => {
  const rowEl = getTableRowElement(table, row);
  if (!rowEl) {
    return;
  }
  const cellEl = rowEl.getElementsByTagName('td')[cell];
  if (!cellEl) {
    return;
  }
  removeCSSClassFromElement(cellEl, cssClass);
};
/**
 * Cut string if it longer then passed length and add ... to end
 * @param {string} value - input string
 * @param {number} length - cut string if string length more then passed value
 */
const cutLongString = (value, length) =>
  value && value.length > length ? `${value.substring(0, length)}...` : value;
/**
 * Generate destination link status rows for table or use
 * @param {dl} iMap - destination link
 * @param {crawlResult} iMap - crawler results for dl
 * @param {canUseCrawlers} boolean - if current user can use crawlers
 * @return {Array} Array with dl status
 */
const generateDestinationLinkStatusRows = (dl, canUseCrawlers) => {
  // FIXME: returning of `null` instead of `undefined` is unusual for our entities
  // TODO: ensure that `|| iMap()` is absolutelly necessary here
  const dlCrawlingResult = dl.get('dlCrawlingResult', iMap()) || iMap();

  const duplicatesCount = dl.get('duplicatesCount');
  const included = stringToBool(dl.get('included'));
  const publishedHostBlacklisted = dl.get('publishedHostBlacklisted');
  const includedStatus = included ? 'included' : 'excluded';
  const includeByUser = dl.get('includeByUser') !== 'auto';
  const nofollow = stringToBool(dl.get('nofollow'));
  const nofollowStatus = nofollow ? 'nf' : 'df';
  const indexed = dlCrawlingResult.getIn(['googleIndexedResult', 'indexedByGoogle']);
  const indexedStatus = indexed ? 'indexed' : 'notIndexed';
  const disavow = stringToBool(dl.get('disavow'));
  const disavowStatus = disavow ? 'disavow' : 'notDisavow';
  const foundPublished = stringToBool(dlCrawlingResult.getIn(['publishedUriResult', 'status']));
  const foundPublishedStatus = foundPublished ? 'ok' : 'notFound';
  const isCrawlingFinished = foundPublished !== void 0;
  const foundBacklink = stringToBool(dlCrawlingResult.getIn(['backlinkResult', 'status']));
  const foundBacklinkStatus = foundBacklink ? 'found' : 'notFound';

  const crawlersStatus = crawlersStatusService(dl);
  const lastCheck = crawlersStatus.lastCheck;

  const crawlerErrorPublished = dlCrawlingResult.getIn(['publishedUriResult', 'error']);
  const crawlerErrorBacklink = dlCrawlingResult.getIn(['backlinkResult', 'error']);
  const crawlerErrorGoogleIndexed = dlCrawlingResult.getIn(['googleIndexedResult', 'error']);

  const publishedUriRedirectChain = generateRedirectChain(
    dlCrawlingResult.getIn(['publishedUriResult', 'redirectPathways'], iList()),
  );
  const backlinkRedirectChain = generateRedirectChain(
    dlCrawlingResult.getIn(['backlinkResult', 'redirectPathways'], iList()),
  );

  const publishedUriResultResponseCode = getPublishedUriResultResponseCode(dlCrawlingResult);
  const backlinkResponseCode = getBacklinkResponseCode(dlCrawlingResult);

  const publishedUriCode =
    foundPublished && publishedUriResultResponseCode === ''
      ? '200'
      : publishedUriResultResponseCode;

  let publishedUriDescription = translate(
    `dlDetailsPopup.tables.status.${foundPublishedStatus}PublishedUri`,
  );
  if (crawlerErrorPublished) {
    publishedUriDescription = translate('dlDetailsPopup.tables.status.errorCrawler');
  } else if (!foundPublished && publishedUriResultResponseCode === '500') {
    publishedUriDescription = translate('dlDetailsPopup.tables.status.notFound500PublishedUri');
  }

  let backlinkDescription = translate(
    `dlDetailsPopup.tables.status.${foundBacklinkStatus}Backlink`,
  );
  if (crawlerErrorBacklink) {
    backlinkDescription = translate('dlDetailsPopup.tables.status.errorCrawler');
  } else if (foundBacklink && backlinkIsRedirect(dlCrawlingResult)) {
    backlinkDescription = translate('dlDetailsPopup.tables.status.foundBacklinkWithRedirect');
  }

  let includedDescription = translate(`dlDetailsPopup.tables.status.${includedStatus}`);
  includedDescription += includeByUser
    ? translate('dlDetailsPopup.tables.status.manual')
    : translate('dlDetailsPopup.tables.status.auto');

  const includedDetails = publishedHostBlacklisted
    ? translate('dlDetailsPopup.tables.status.details.publishedHostBlacklisted')
    : '';

  const buildNextTryString = (crawlingResultSource) => {
    return translate('dlDetailsPopup.tables.status.nextTryDescription')(
      crawlingResultSource.get('attemptNumber'),
      nextCrawlingDelayInWords(crawlingResultSource),
    );
  };

  const publishedUriDetails = crawlerErrorPublished
    ? buildNextTryString(dlCrawlingResult)
    : publishedUriRedirectChain;
  const backlinkDetails = crawlerErrorBacklink
    ? buildNextTryString(dlCrawlingResult)
    : backlinkRedirectChain;
  const googleIndexedErrorDetails = buildNextTryString(
    dlCrawlingResult.get('googleIndexedResult', iMap()),
  );

  let indexedDetails = '';
  const publishedDate = dl.get('publishedDate');
  const monthAgo = moment().subtract(1, 'month');
  const needToDisplayIndexedDetails =
    canUseCrawlers && indexed === false && publishedDate && moment(publishedDate) >= monthAgo;

  if (needToDisplayIndexedDetails) {
    const recheckEnd = moment(publishedDate)
      .add(1, 'month')
      .format('D MMM YYYY');
    indexedDetails = translate('dlDetailsPopup.tables.status.indexedDetails')(recheckEnd);
  }

  return compact([
    {
      id: 0,
      status: includedStatus,
      description: includedDescription,
      details: includedDetails,
    },
    foundPublished === void 0 &&
      !canUseCrawlers && {
        id: 1,
        status: translate('dlDetailsPopup.tables.status.unvalidated.title'),
      },
    !lastCheck &&
      canUseCrawlers && {
        id: 2,
        status: translate('dlDetailsPopup.tables.status.processing.title'),
        description: translate('dlDetailsPopup.tables.status.processing.description'),
      },
    foundPublished !== void 0 && {
      id: 3,
      status: (crawlerErrorPublished && 'error') || `${publishedUriCode}${foundPublishedStatus}`,
      description: publishedUriDescription,
      details: publishedUriDetails,
    },
    crawlerErrorGoogleIndexed && {
      id: 4,
      status: 'unknown',
      description: translate('dlDetailsPopup.tables.status.errorGoogleIndex'),
      details: googleIndexedErrorDetails,
    },
    (foundBacklink !== void 0 || isCrawlingFinished) && {
      id: 5,
      status: (crawlerErrorBacklink && 'error') || `${backlinkResponseCode}${foundBacklinkStatus}`,
      description: backlinkDescription,
      details: backlinkDetails,
    },
    indexed !== void 0 && {
      id: 6,
      status: indexedStatus,
      description: translate(`dlDetailsPopup.tables.status.${indexedStatus}`),
      details: indexedDetails,
    },
    nofollow !== void 0 && {
      id: 7,
      status: nofollowStatus,
      description: translate(`dlDetailsPopup.tables.status.${nofollowStatus}`),
    },
    {
      id: 8,
      status: disavowStatus,
      description: translate(`dlDetailsPopup.tables.status.${disavowStatus}`),
    },
    duplicatesCount && {
      id: 9,
      status: String(duplicatesCount),
      description: translate('dlDetailsPopup.tables.status.duplicates'),
    },
  ]);
};

/**
 * Returns time in words till the next crawling attempt
 * @param {crawlResult} iMap - crawler results for dl
 * @return {undefined} if crawlResult is empty
 * @return {String} Time in words till the next crawling attempt
 */
const nextCrawlingDelayInWords = (dlCrawlingResult) => {
  if (dlCrawlingResult.size === 0) {
    return void 0;
  }
  const delayedAt = dlCrawlingResult.get('delayedAt');
  const delayedForInSeconds = dlCrawlingResult.get('delayedForInSeconds');

  return delayInWords(delayedAt, delayedForInSeconds);
};

/**
 * Returns time in words till the next attempt
 * see https://momentjs.com/docs/#/displaying/fromnow/ for more details
 * @param {delayedAt} String - time, when the next attempt was delayed
 * @param {delayedForInSeconds} String - integer, delay timeframe
 * @return {undefined} if delayedAt or delayedForInSeconds is empty
 * @return {String} Time in words till the next try
 */
const delayInWords = (delayedAt, delayedForInSeconds) => {
  if (!delayedAt || !delayedForInSeconds) {
    return void 0;
  }

  const delayedAtTime = moment(delayedAt);
  const nextTryTimeInMilliseconds = delayedAtTime.valueOf() + Number(delayedForInSeconds) * 1000;

  const isNextTryTimeInPast = nextTryTimeInMilliseconds < moment().valueOf();

  if (isNextTryTimeInPast) {
    return translate('dlDetailsPopup.tables.status.enqueued');
  }

  return moment(nextTryTimeInMilliseconds).fromNow();
};

/**
 * Generate destination link status rows for atp table
 * return empty array if publishedLink empty
 * if dl included return only this status
 * if dl excluded return why it excluded
 * @param {dl} iMap - destination link
 * @param {canUseCrawlers} boolean - if current user can use crawlers
 * @param {crawlResult} iMap - crawler results for dl
 * @return {Array} Array with dl status
 */
const generateDestinationLinkStatusForAtp = (dl, canUseCrawlers) => {
  if (!dl.get('publishedLink')) {
    return [];
  }

  const dlCrawlingResult = iMap(dl.get('dlCrawlingResult', {}));
  const included = stringToBool(dl.get('included'));
  const includedStatus = included ? 'included' : 'excluded';
  const isIncludedByUser = dl.get('includeByUser') === 'yes';
  const duplicatesCount = dl.get('duplicatesCount');

  const crawlersStatus = crawlersStatusService(dl);
  const lastCheck = crawlersStatus.lastCheck;

  const foundBacklink = stringToBool(dlCrawlingResult.getIn(['backlinkResult', 'status']));
  const foundBacklinkStatus = foundBacklink ? 'found' : 'notFound';
  const crawlerErrorBacklink = dlCrawlingResult.getIn(['backlinkResult', 'error']);
  const backlinkResponseCode = getBacklinkResponseCode(dlCrawlingResult);

  const foundPublished = stringToBool(dlCrawlingResult.getIn(['publishedUriResult', 'status']));
  const foundPublishedStatus = foundPublished ? 'ok' : 'notFound';
  const crawlerErrorPublished = dlCrawlingResult.getIn(['publishedUriResult', 'error']);
  const publishedUriResultResponseCode = getPublishedUriResultResponseCode(dlCrawlingResult);

  const publishedUriCode =
    foundPublished && publishedUriResultResponseCode === ''
      ? '200'
      : publishedUriResultResponseCode;

  const includedStatusRow = {
    status: includedStatus,
    description: translate(`dlDetailsPopup.tables.status.${includedStatus}`),
  };

  const processingStatusRow = {
    status: translate('dlDetailsPopup.tables.status.processing.title'),
    description: translate('dlDetailsPopup.tables.status.processing.description'),
  };

  const backlinkStatusRow = {
    status: (crawlerErrorBacklink && 'error') || `${backlinkResponseCode}${foundBacklinkStatus}`,
    description: translate('dlDetailsPopup.tables.status.foundBacklink'),
  };

  const publishedStatusRow = {
    status: (crawlerErrorPublished && 'error') || `${publishedUriCode}${foundPublishedStatus}`,
    description: translate('dlDetailsPopup.tables.status.foundPublishedUri'),
  };

  const unvalidatedStatusRow = {
    status: translate('dlDetailsPopup.tables.status.unvalidated.title'),
  };

  const dupeNumberRow = {
    status: String(duplicatesCount),
  };
  const dupeLabelRow = {
    status: translate('dlDetailsPopup.tables.status.duplicatesShort'),
  };

  const dupeRows = duplicatesCount > 1 ? [dupeNumberRow, dupeLabelRow] : [];
  const isCrawlingFinished = foundPublished !== void 0;
  const showBacklinkStatusRow = isIncludedByUser || foundBacklink !== void 0;
  const showPublishedStatusRow = isCrawlingFinished && !showBacklinkStatusRow;

  if (included) {
    return compact([
      includedStatusRow,
      !lastCheck && canUseCrawlers && processingStatusRow,
      foundPublished === void 0 && !canUseCrawlers && unvalidatedStatusRow,
      showPublishedStatusRow && publishedStatusRow,
      showBacklinkStatusRow && backlinkStatusRow,
      ...dupeRows,
    ]);
  }

  const nofollow = stringToBool(dl.get('nofollow'));
  const nofollowStatus = nofollow ? 'nf' : void 0;
  const indexed = dlCrawlingResult.getIn(['googleIndexedResult', 'indexedByGoogle']);
  const indexedStatus = indexed ? void 0 : 'notIndexed';
  const disavow = stringToBool(dl.get('disavow'));
  const disavowStatus = disavow ? 'disavow' : void 0;

  return compact([
    // if excluded
    includedStatusRow,
    !lastCheck && canUseCrawlers && processingStatusRow,
    foundPublished === void 0 && !canUseCrawlers && unvalidatedStatusRow,
    (crawlerErrorPublished || (foundPublished !== void 0 && !foundPublished)) && publishedStatusRow,
    (crawlerErrorBacklink || (foundBacklink !== void 0 && !foundBacklink)) && backlinkStatusRow,
    indexed !== void 0 &&
      indexedStatus !== void 0 && {
        status: indexedStatus,
        description: translate(`dlDetailsPopup.tables.status.${indexedStatus}`),
      },
    nofollow !== void 0 &&
      nofollowStatus !== void 0 && {
        status: nofollowStatus,
        description: translate(`dlDetailsPopup.tables.status.${nofollowStatus}`),
      },
    disavow !== void 0 &&
      disavowStatus !== void 0 && {
        status: disavowStatus,
        description: translate(`dlDetailsPopup.tables.status.${disavowStatus}`),
      },
    ...dupeRows,
  ]);
};

/**
 * Extract latest response code from RedirectPathways.
 * @param {dlCrawlingResult} iMap - dlCrawlingResult
 * @return {String} Response Code
 */
const getPublishedUriResultResponseCode = (dlCrawlingResult) => {
  const pathways = publishedUriPathways(dlCrawlingResult);
  const lastPathway = pathways.last() || iMap();
  const responseCode = lastPathway.get('code', '');
  const isRedirect = publishedUriIsRedirect(dlCrawlingResult);

  if (isRedirect) {
    // The more 'honest' variant is something like `3xx` (since we do not know exact redirect code)
    return '301';
  }

  return String(responseCode);
};

const publishedUriIsRedirect = (dlCrawlingResult) => {
  const pathways = publishedUriPathways(dlCrawlingResult);
  const firstPathway = pathways.first() || iMap();
  const isRedirect = firstPathway.get('redirect', false);

  return isRedirect;
};

const publishedUriPathways = (dlCrawlingResult) => {
  return dlCrawlingResult.getIn(['publishedUriResult', 'redirectPathways'], iList());
};

/**
 * Extract latest response code from RedirectPathways, replace 200 code with empty string.
 * @param {dlCrawlingResult} iMap - dlCrawlingResult
 * @return {String} Response Code
 */
const getBacklinkResponseCode = (dlCrawlingResult) => {
  const pathways = backlinkPathways(dlCrawlingResult);
  const lastPathway = pathways.last() || iMap();
  const responseCode = lastPathway.get('code', '');
  const isRedirect = backlinkIsRedirect(dlCrawlingResult);

  if (isRedirect) {
    // The more 'honest' variant is something like `3xx` (since we do not know exact redirect code)
    return `301`;
  }

  if (responseCode === 200) {
    return '';
  }

  return String(responseCode);
};

const backlinkIsRedirect = (dlCrawlingResult) => {
  const pathways = backlinkPathways(dlCrawlingResult);
  const firstPathway = pathways.first() || iMap();
  const isRedirect = firstPathway.get('redirect', false);

  return isRedirect;
};

const backlinkPathways = (dlCrawlingResult) => {
  return dlCrawlingResult.getIn(['backlinkResult', 'redirectPathways'], iList());
};

/**
 * Process result redirects
 * @param {redirects} iList - crawler redirects
 * @return {String} Generated String with redirects uri
 */
const generateRedirectChain = (redirects) => {
  if (redirects.size < 2) {
    return '';
  }

  return redirects
    .map((redirect) => `${redirect.get('code', '')} ${redirect.get('uri')}`)
    .join(' => ');
};
/**
 * Covert string to bool
 * @param {string} string - string that need to convert
 * @return {boolean or undefined} return boolean value or undefined
 */
const stringToBool = (string) => {
  switch (string) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'success':
      return true;
    case 'failure':
      return false;
    default:
      return void 0;
  }
};

export {
  addCSSClassToCellInTable,
  addCSSClassToElement,
  addCSSClassToRowInTable,
  cutLongString,
  delayInWords,
  generateDestinationLinkStatusForAtp,
  generateDestinationLinkStatusRows,
  highlightTableRow,
  removeCSSClassFromCellInTable,
  removeCSSClassFromElement,
  removeCSSClassFromRowInTable,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
  TABLE_CELL_ERROR_CLASS,
  TABLE_ROW_ACTIVE_CLASS,
  TABLE_ROW_CHANGED_CLASS,
  TABLE_ROW_CURRENT_USER_CLASS,
};
