import { handleAPIError } from '../utils';

import { translate } from 'common/i18n';
import { updatePageFailure, updatePageSuccess } from 'actions/brand_page_actions';

import {
  updateDraftBrandPageFailure,
  updateDraftBrandPageSuccess,
} from 'actions/bulkPagesSetupActions';

import {
  updateDraftCompetitorPageFailure,
  updateDraftCompetitorPageSuccess,
} from 'actions/bulkCompetitorsSetupActions';

const handleAhrefsMetricsUploadingStatus = (dispatch, data) => {
  const { ahrefsMetricsUploadingStatus } = data;
  const isDraftPage = data.status === 'not_in_atp';

  if (isDraftPage) {
    switch (ahrefsMetricsUploadingStatus) {
      case 'started':
      case 'finished':
        handleUpdateDraftPage(dispatch, data);
        break;
      case 'failure':
        handleUpdateDraftPage(dispatch, data);
        handleUpdateDraftPageFailure(dispatch, data);
        break;
    }

    return;
  }

  switch (ahrefsMetricsUploadingStatus) {
    case 'started':
    case 'finished':
      dispatch(updatePageSuccess(data));
      break;
    case 'failure': {
      dispatch(updatePageSuccess(data));
      const error = ((data || {}).errors || {}).base;
      handleAPIError(
        dispatch,
        { message: error },
        updatePageFailure,
        translate('errors.cantUpdatePage'),
      );
      break;
    }
  }
};

const handleUpdateDraftPage = (dispatch, page) => {
  const isBrandPage = !page.brandPageId;

  if (isBrandPage) {
    dispatch(updateDraftBrandPageSuccess(page));
  } else {
    dispatch(updateDraftCompetitorPageSuccess(page));
  }
};

const handleUpdateDraftPageFailure = (dispatch, page) => {
  const error = ((page || {}).errors || {}).base;

  const isBrandPage = !page.brandPageId;

  if (isBrandPage) {
    handleAPIError(
      dispatch,
      { message: error },
      updateDraftBrandPageFailure,
      translate('errors.cantUpdateDraftBrandPage'),
    );
  } else {
    handleAPIError(
      dispatch,
      { message: error },
      updateDraftCompetitorPageFailure,
      translate('errors.cantUpdateDraftCompetitorPage'),
    );
  }
};

export default handleAhrefsMetricsUploadingStatus;
