import { map } from 'lodash';

import { translate } from 'common/i18n';
import { showSuccessMessage } from 'common/utils';

import {
  categorizeDestinationLinksFailure,
  categorizeDestinationLinksStart,
  categorizeDestinationLinksSuccess,
  createDestinationLinksFailure,
  createDestinationLinksStart,
  createDestinationLinksSuccess,
  deleteDestinationLinksFailure,
  deleteDestinationLinksStart,
  deleteDestinationLinksSuccess,
  fetchBrandPageDlsSuccess,
  fetchDestinationLinksFailure,
  fetchDestinationLinksStart,
  fetchPageDashboardDlsSuccess,
  updateDestinationLinksFailure,
  updateDestinationLinksStart,
  updateDestinationLinksSuccess,
  updatePageDashboardDlsSuccess,
} from 'actions/destination_link_actions';

import { fetchAtpPagySuccess } from 'actions/pagy_actions';

import {
  fetchAnchorTextsFailure,
  fetchAnchorTextsStart,
  fetchAnchorTextsSuccess,
} from 'actions/anchor_text_actions';

import { updateBacklinksSuccess } from 'actions/backlinkActions';

import {
  deleteToAPI,
  handleAPIError,
  getFromAPI,
  patchToAPI,
  postToAPI,
  processObjToCamelCase,
  processObjToSnakeCase,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchBrandPageDls(
  dispatch,
  brandPageId,
  { page, items, filters, dlsGroupMode, sorting },
) {
  dispatch(fetchDestinationLinksStart());

  try {
    const params = {
      brandPageId,
      page,
      items,
      filters,
      dlsGroupMode,
      sorting,
    };
    const resp = await postToAPI(
      Routes.brand_page_dls_destination_links_records_path(),
      processObjToSnakeCaseDeep(params),
    );

    dispatch(fetchBrandPageDlsSuccess(resp.destination_links));
    dispatch(fetchAtpPagySuccess(brandPageId, resp.pagy));
    return resp.destination_links;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchDestinationLinksFailure,
      translate('errors.cantFetchDestinationLinks'),
    );
  }
}

export async function fetchPageDashboardDls(dispatch, brandPageId, { page, items, filters }) {
  dispatch(fetchDestinationLinksStart());

  try {
    const params = {
      brandPageId,
      page,
      items,
      filters,
    };
    const resp = await postToAPI(
      Routes.brand_page_dls_destination_links_records_path(),
      processObjToSnakeCaseDeep(params),
    );

    dispatch(fetchPageDashboardDlsSuccess(resp.destination_links, brandPageId));

    return resp.destination_links;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchDestinationLinksFailure,
      translate('errors.cantFetchDestinationLinks'),
    );
  }
}

export async function createDl(dispatch, pageId, data) {
  dispatch(createDestinationLinksStart());

  try {
    const resp = await postToAPI(Routes.destination_links_records_path(), {
      brand_page_id: pageId,
      destination_link: processObjToSnakeCase(data),
    });
    const processedDestinationLinks = map(resp.destination_links, processDestinationLinks);

    dispatch(createDestinationLinksSuccess(processedDestinationLinks));

    return processedDestinationLinks;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createDestinationLinksFailure,
      translate('errors.cantCreateDestinationLinks'),
    );
  }
}

export async function updateDl(dispatch, pageId, data, brandId) {
  dispatch(updateDestinationLinksStart());

  try {
    const resp = await patchToAPI(Routes.destination_links_records_path(), {
      brand_page_id: pageId,
      destination_link: updateDlObjs(data),
    });
    const processedDestinationLinks = map(resp.destination_links, processDestinationLinks);

    dispatch(updateDestinationLinksSuccess(processedDestinationLinks));

    // If we deal with backlinks (a.k.a has parent brand),
    // then update the backlinks in a global state
    if (brandId) {
      dispatch(updateBacklinksSuccess(brandId, processedDestinationLinks));
    }

    return processedDestinationLinks;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateDestinationLinksFailure,
      translate('errors.cantUpdateDestinationLinks'),
    );
  }
}

export async function updatePageDashboardDl(dispatch, pageId, data) {
  dispatch(updateDestinationLinksStart());

  try {
    const resp = await patchToAPI(Routes.destination_links_records_path(), {
      brand_page_id: pageId,
      destination_link: updateDlObjs(data),
    });
    const processedDestinationLinks = map(resp.destination_links, processDestinationLinks);

    dispatch(updatePageDashboardDlsSuccess(processedDestinationLinks, pageId));

    return processedDestinationLinks;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateDestinationLinksFailure,
      translate('errors.cantUpdateDestinationLinks'),
    );
  }
}

export async function deleteDl(dispatch, pageId, ids) {
  dispatch(deleteDestinationLinksStart());

  try {
    const resp = await deleteToAPI(Routes.bulk_destroy_destination_links_records_path(), {
      brand_page_id: pageId,
      ids,
    });

    showSuccessMessage(
      dispatch,
      translate('destinationLink.messages.deleteSuccess', { count: ids.length }),
    );

    dispatch(deleteDestinationLinksSuccess(ids));

    return processObjToCamelCase(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      deleteDestinationLinksFailure,
      translate('errors.cantDeleteDestinationLinks'),
    );
  }
}

export async function categorizeDl(dispatch, brandPageId, categorizationParams) {
  dispatch(categorizeDestinationLinksStart());

  try {
    const resp = await patchToAPI(Routes.categorize_destination_links_records_path(), {
      brand_page_id: brandPageId,
      mode: categorizationParams.mode,
      ids: categorizationParams.ids,
      page: categorizationParams.page,
    });
    const { categorized_count, destination_links } = resp;
    const message = translate('destinationLink.messages.linksCategorizedSuccessfully', {
      count: categorized_count,
    });
    showSuccessMessage(dispatch, message);

    const processedDLs = map(destination_links, processDestinationLinks);

    dispatch(categorizeDestinationLinksSuccess(processedDLs));

    return processObjToCamelCase(processedDLs);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      categorizeDestinationLinksFailure,
      translate('errors.cantCategorizeDestinationLinks'),
    );
  }
}

export async function fetchDlAnchorTexts(dispatch, brandPageId, destinationLinkId) {
  dispatch(fetchAnchorTextsStart());

  try {
    const resp = await getFromAPI(
      Routes.anchor_texts_destination_links_record_path({ id: destinationLinkId }),
      { brand_page_id: brandPageId },
    );
    dispatch(fetchAnchorTextsSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchAnchorTextsFailure,
      translate('errors.cantFetchAnchorTexts'),
    );
  }
}

export async function updateDlAnchorText(dispatch, brandPageId, destinationLinkId) {
  dispatch(updateDestinationLinksStart());

  try {
    const resp = await patchToAPI(
      Routes.refresh_anchor_text_destination_links_record_path({ id: destinationLinkId }),
      { brand_page_id: brandPageId },
    );
    const processedDestinationLinks = map(resp.destination_links, processDestinationLinks);

    dispatch(updateDestinationLinksSuccess(processedDestinationLinks));

    return processedDestinationLinks;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateDestinationLinksFailure,
      translate('errors.cantUpdateDestinationLinkAnchorText'),
    );
  }
}

export async function updatePageDashboardDlAnchorText(dispatch, brandPageId, destinationLinkId) {
  dispatch(updateDestinationLinksStart());

  try {
    const resp = await patchToAPI(
      Routes.refresh_anchor_text_destination_links_record_path({ id: destinationLinkId }),
      { brand_page_id: brandPageId },
    );
    const processedDestinationLinks = map(resp.destination_links, processDestinationLinks);

    dispatch(updatePageDashboardDlsSuccess(processedDestinationLinks, brandPageId));

    return processedDestinationLinks;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateDestinationLinksFailure,
      translate('errors.cantUpdateDestinationLinkAnchorText'),
    );
  }
}

const processDestinationLinks = (link) => ({
  ...processObjToCamelCase(link),
  errors: { ...processObjToCamelCase(link.errors) },
  selected: false,
});

const updateDlObjs = (array) => map(array, (dl) => processObjToSnakeCaseDeep(dl));
