import pluralize from 'pluralize';

import { buildWordInPastTenseWithPluralization } from 'common/utils';

export default (args) => {
  return {
    destinationLink: {
      buttons: {
        addAnchors: {
          text: 'Save',
        },
        categorize: 'Categorization Settings',
        crawlNow: 'Verify Backlinks',
        importSmth: 'Import Backlinks',
        set: 'Set',
        ungroup: 'All',
        groupPerDomain: 'One link per domain',
      },
      messages: {
        linksCategorizedSuccessfully: `Successfully categorized ${pluralize(
          'anchor',
          args.count,
          true,
        )}.`,
        csvImportFailure: `Import was completed. ${pluralize(
          'link',
          args.uniqEntriesCount,
          true,
        )} added, ${pluralize(
          'duplicate',
          args.dupeEntriesCount,
          true,
        )} skipped. Review the errors below, please.`,
        csvImportSuccess: `Import was completed successfully. ${pluralize(
          'link',
          args.uniqEntriesCount,
          true,
        )} added, ${pluralize('duplicate', args.dupeEntriesCount, true)} skipped. ${
          args.isCompetitorsPage
            ? 'Linkio will now check backlinks and notify you once it calculates the percentages.'
            : ''
        }`,
        gscImportFailure: 'Import from GSC was not completed.',
        gscImportStart: 'Import from GSC is started.',
        gscImportSuccess: 'Import from GSC was completed successfully.',
        gscImportWarnings: 'Import from GSC. Warnings:',
        manualImportFailure: `Import was completed. ${pluralize(
          'link',
          args.uniqEntriesCount,
          true,
        )} added, ${pluralize(
          'duplicate',
          args.dupeEntriesCount,
          true,
        )} skipped. Review the errors below, please.`,
        manualImportSuccess: `Import was completed successfully. ${pluralize(
          'link',
          args.uniqEntriesCount,
          true,
        )} added, ${pluralize('duplicate', args.dupeEntriesCount, true)} skipped. ${
          args.isCompetitorsPage
            ? 'Linkio will now check backlinks and notify you once it calculates the percentages.'
            : ''
        }`,
        deleteFailure: `${buildWordInPastTenseWithPluralization('Link', args.count)} not deleted.`,
        deleteSuccess: `${buildWordInPastTenseWithPluralization(
          'Link',
          args.count,
        )} deleted successfully.`,
      },
      anchorText: {
        optionsHeader: {
          suggestions: 'Anchor Text Suggestions',
          repeated: 'Repeated',
        },
        placeholder: 'Anchor text...',
        title: 'Anchor Text',
      },
      anchorType: {
        title: 'Anchor Type',
        label: 'Anchor type',
        placeholder: 'Anchor type...',
        titleTag: `
          Title Tag or Blog Post Title means that the anchor should be whatever the title of the page is.
          This basically means that you can grab the meta title or the main H1 tag
          and create that the anchor.
          The most common scenario would be if you had a blog post you are linking to and make
          the anchor the title of the blog post.
        `,

        keywordPlusWord: `
          The anchor text will include the exact key phrase you are targeting
          but also have more in addition.
          For example, if your key phrase was "plumbing services" or "plumbing services available today".
          Keyword Plus Word anchor is basically your key phrase used in a sentence
          and just include part of the key phrase with it.
          NOTE: Each time you do a Keyword Plus Word anchor it needs to be unique from other
          Keyword Plus Word anchors you have done for this website. So, make sure you rotate which key
          phrase you use in the anchor.
        `,

        onlyPartOfKeyword: `
          If your main keyphrase is "plumbing service",
          Partial Match Keyword anchor would be something around "plumbing made easy".
          It contains just a part of the key phrase and not the entire phrase.
        `,

        homepageUri: `
          Home URL means even though you may be linking to an inner-page of your website
          you (still use the home url (http://wwww.yourwebsite.com) as the anchor text.
        `,

        nakedUri: `
          Naked URL anchor is just the full version of the URL you are linking to,
          like this: http://www.yoursite.com.
        `,

        justNatural: `
          Just Natural is an anchor naturally used in terms of not containing none of your key phrases.
          Something like "read more" as the anchor would be considered natural or it can be as simple as
          "here" (or "this website" etc.)
        `,

        noText: `No text anchor can usually be an image, or an empty tag in HTML. <a> </a>`,

        exactKeyword: `
          Exact keyword anchors are, as the name suggests, the exact match of your target keyword.
          For example if your target keyword is “plumbing services” the anchor is also "plumbing services".
        `,

        branded: `
          Brand Name anchors mean the anchor is the same or mentions a name of your brand or business.
          For example if you owned Facebook.com the brand would be "Facebook".
        `,

        brandAndKeywordTogether: `
          "Branded plus keyword" anchor means you include your brand name in the anchor with key phrase.
          For example "Plumbing services by Plumber".
        `,

        websiteNameCom: `
          WebsiteName.com is very similar to Home URL anchor except it is formatted different.
          For example, if a website wanted to link to us using this anchor they would use
          "plumber.com" as the anchor text.
        `,

        totallyRandom: `Anchor that cannot be categorized in any of the above.`,

        nakedUriWoHttp: `
          This is basically the naked URL of your website (can be a homepage or an inner page)
          without using http:// or https://.
          For example if your website is https://plumber.com/pricing, Naked URL without
          http:// would be "plumber/pricing.com"
        `,
      },
      publishedDate: {
        title: 'Date',
        placeholder: 'Date...',
      },
      id: {
        title: 'ID',
      },
      includeByUser: {
        placeholder: 'Yes',
        placeholderShort: '...',
        title: 'Include',
        postfix: {
          auto: ' (auto)',
          userDefined: '',
        },
      },
      link: {
        title: 'URL to Link to',
      },
      nofollow: {
        title: 'DF/NF',
        placeholderShort: '...',
      },
      priority: {
        title: 'Priority',
        placeholder: 'Priority',
        placeholderShort: '...',
      },
      publishedLink: {
        title: 'Published URL & Link Status',
        placeholder: 'Published link...',
      },
      targetWebsite: {
        title: 'Target Website',
        placeholder: 'Target website...',
      },
      source: {
        label: 'Source',
        placeholder: 'Source...',
        title: 'Source',
      },
      status: {
        label: 'Status',
        placeholder: 'Status...',
        title: 'Status',
      },

      bulkActions: {
        column: {
          placeholder: 'Select column',
        },
        value: {
          placeholder: 'Select value',
        },
        buttons: {
          set: 'Set',
        },
      },

      defaultPlaceholder: 'Select value...',
    },
  };
};
