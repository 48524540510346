import React from 'react';

const DashboardIcon = (props) => (
  <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" {...props}>
    <path
      d="M6.781.575h5.344v4H6.781v-4zm0 12V5.919h5.344v6.656H6.781zm-6.656 0v-4h5.344v4H.125zm0-5.344V.575h5.344v6.656H.125z"
      fillRule="evenodd"
    />
  </svg>
);

DashboardIcon.defaultProps = {
  width: "1em",
  height: "1em",
  fill: "currentColor",
};

export default DashboardIcon;
