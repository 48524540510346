import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import EditPageComponent from './EditPageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  brandPageShape,
  optionsMap,
  subscriptionShape,
  brandPagesMap,
} from 'common/prop_types_shapes';

import { closePageEditPopup } from 'actions/popup_actions';
import { fetchCurrentSubscription } from 'api/subscription';
import { optionsSelector, uiSelector } from 'selectors';
import { startMouseFlowPageView } from 'common/utils';
import clickUpgradeSubscriptionService from 'pages/Pages/services/clickUpgradeSubscriptionService';
import updatePageService from 'pages/Pages/services/updatePageService';

class EditPageContainer extends Component {
  static propTypes = {
    currentSubscription: subscriptionShape,
    dispatch: PropTypes.func,
    isUpdatingPage: PropTypes.bool,
    page: brandPageShape,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    pageTypeOptions: optionsMap,
    pages: brandPagesMap,
  };

  componentDidMount() {
    fetchCurrentSubscription(this.props.dispatch);
    startMouseFlowPageView(document.location.pathname);
  }

  handleCancel = () => {
    this.props.dispatch(closePageEditPopup());
  };

  handleSave = async (page) => {
    const { dispatch, pages } = this.props;

    const brandNames = (page.brandOtherNames || '').trim().split(/\r?\n/);

    const brandName = brandNames[0];
    const brandOtherNames = brandNames.slice(1);

    const pageData = {
      ...page,
      brandName,
      brandOtherNames,
    };

    const result = await updatePageService({ dispatch, pages }, pageData);
    const { type, errors } = result;

    if (type === 'error' || !isEmpty(errors)) {
      return result;
    }

    this.handleCancel();
    return result;
  };

  handleClickUpgradeSubscription = (event) => {
    const { currentSubscription, dispatch } = this.props;
    clickUpgradeSubscriptionService({ currentSubscription, dispatch }, event);
  };

  render() {
    const { isUpdatingPage, page, pageChildType, pageTypeOptions } = this.props;

    return (
      <EditPageComponent
        isUpdatingPage={isUpdatingPage}
        onCancel={this.handleCancel}
        onClickUpgradeSubscription={this.handleClickUpgradeSubscription}
        onSave={this.handleSave}
        page={page.toJS()}
        pageChildType={pageChildType}
        pageTypeOptions={pageTypeOptions}
      />
    );
  }
}

function select(state, ownProps) {
  const pages = state.get('brandPages', iMap());
  // Get page here, instead of receiving it from openPageEditPopup action,
  // in order to keep page updated
  const page = pages.get(String(ownProps.pageId), iMap());

  const options = optionsSelector(state, ownProps);
  const pageTypeOptions = options.get('pageTypeOptions');

  const uiStates = uiSelector(state);
  const isUpdatingPage = uiStates.getIn(['loading', 'changePage']);

  return {
    isUpdatingPage,
    page,
    pages,
    pageTypeOptions,
  };
}

export default ConnectStoreHOC(connect(select)(EditPageContainer));
