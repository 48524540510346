import React from 'react';

const CogIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M5 8c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3zm4.5 0c0 .836-.664 1.5-1.5 1.5A1.49 1.49 0 0 1 6.5 8c0-.836.664-1.5 1.5-1.5s1.5.664 1.5 1.5zm-.478-8H6.965c-.786 0-1.403.586-1.403 1.332v.71a6.683 6.683 0 0 0-1.796.977l-.635-.355a1.384 1.384 0 0 0-1.067-.125c-.355.09-.654.32-.841.622L.194 4.866c-.187.284-.243.639-.15.994.094.338.337.622.655.8l.636.354c-.056.32-.075.657-.075.977 0 .32.019.657.075.977l-.636.355a1.345 1.345 0 0 0-.655.8c-.093.354-.037.71.15 1.011l1.029 1.705c.187.302.486.533.841.622.356.088.73.053 1.067-.125l.635-.355c.543.409 1.141.746 1.796.977v.71c0 .728.617 1.332 1.403 1.332H9.04c.767 0 1.403-.586 1.403-1.332v-.71a6.683 6.683 0 0 0 1.795-.977l.636.355c.318.178.692.231 1.066.125.356-.09.655-.32.842-.622l1.028-1.705a1.2 1.2 0 0 0 .131-1.012 1.345 1.345 0 0 0-.654-.799l-.636-.355c.056-.32.075-.657.075-.977 0-.32-.019-.657-.075-.977l.636-.355c.318-.177.56-.461.654-.799a1.29 1.29 0 0 0-.13-1.012l-1.03-1.705a1.395 1.395 0 0 0-.84-.621 1.507 1.507 0 0 0-1.086.142l-.636.355a6.683 6.683 0 0 0-1.795-.977v-.71C10.425.586 9.808 0 9.022 0zm2.3 4.155a1.441 1.441 0 0 0 1.59.125l.618-.338.935 1.545-.617.338a1.306 1.306 0 0 0-.674 1.367c.038.266.075.533.075.8 0 .265-.019.532-.075.798-.093.55.169 1.101.674 1.368l.617.337-.935 1.545-.618-.337c-.505-.267-1.14-.231-1.59.124a5.635 5.635 0 0 1-1.477.817c-.542.195-.916.692-.916 1.243v.675h-1.87v-.675c0-.55-.375-1.048-.917-1.243a5.167 5.167 0 0 1-1.478-.817 1.441 1.441 0 0 0-1.59-.124l-.617.337-.935-1.545.617-.337c.505-.267.767-.835.674-1.368a5.74 5.74 0 0 1-.057-.799c0-.266.02-.533.075-.799a1.32 1.32 0 0 0-.673-1.367l-.617-.338.935-1.545.617.338c.505.266 1.141.23 1.59-.125A5.635 5.635 0 0 1 6.16 3.34c.543-.196.917-.693.917-1.244v-.674h1.87v.674c0 .55.374 1.048.917 1.244.505.195 1.01.461 1.459.816z" fillRule="nonzero" />
    </svg>
  );
};

CogIcon.defaultProps = {
  fill: '#919191',
  width: '16px',
  height: '16px',
};

export default CogIcon;
