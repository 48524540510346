import React from 'react';

import { translate } from 'common/i18n';

const buildGroupedPercentageSchemeOptionsService = ({
  competitorsGroupInvisible,
  hideActions,
  pageId,
  percentageSchemeOptions,
}) => {
  function isCustomScheme(scheme) {
    return scheme.get('isCustom');
  }

  function isCompetitorsScheme(scheme) {
    return scheme.get('isCompetitors');
  }

  function schemeBelongsToCurrentPage(scheme) {
    return Number(scheme.get('brandPageId')) === Number(pageId);
  }

  const competitorsSchemeOptions = () => {
    // We should skip competitors percentages on competitor pages
    if (competitorsGroupInvisible) {
      return null;
    }

    const realOptions = percentageSchemeOptions
      .filter((scheme) => {
        return (
          isCompetitorsScheme(scheme) && schemeBelongsToCurrentPage(scheme) && !scheme.get('isZero')
        );
      })
      .toList()
      .sortBy((option) => option.get('label'))
      .toJS();

    const defaultOptions = () => {
      const schemeKeys = ['average', 'reduceKwBy10', 'reduceKwBy25', 'reduceKwBy50'];
      return schemeKeys.map((schemeKey) => {
        return {
          isDisabled: true,
          label: translate(
            `brandPagePercentage.competitorsPercentageShemeOptionsLabels.${schemeKey}`,
          ),
          value: null,
        };
      });
    };

    const options = realOptions.length > 0 ? realOptions : defaultOptions();

    const optionCTA = () => {
      if (hideActions) {
        return null;
      }

      const label = (
        <div className="percentages__add-competitors">
          {translate('brandPagePercentage.newCompetitorsLabel')}
        </div>
      );

      return {
        isAction: true,
        label,
        value: 'newCompetitors',
      };
    };

    const notNullOptions = [...options, optionCTA()].filter((option) => option);

    return {
      label: translate('brandPagePercentage.competitorsPercentageSheme.title'),
      options: notNullOptions,
    };
  };

  const defaultSchemeOptions = () => {
    const options = percentageSchemeOptions
      .filter((scheme) => {
        return !isCustomScheme(scheme) && !isCompetitorsScheme(scheme);
      })
      .toList()
      .toJS();

    return {
      label: translate('brandPagePercentage.defaultPercentageSheme.title'),
      options,
    };
  };

  const customSchemeOptions = () => {
    const options = percentageSchemeOptions
      .filter((scheme) => {
        return isCustomScheme(scheme) && !isCompetitorsScheme(scheme);
      })
      .toList()
      .toJS();

    const optionCTA = () => {
      if (hideActions) {
        return null;
      }

      const label = (
        <div className="percentages__add-custom-percentage">
          {translate('brandPagePercentage.newPercentageLabel')}
        </div>
      );

      return {
        isAction: true,
        label,
        value: 'newCustomScheme',
      };
    };

    const notNullOptions = [...options, optionCTA()].filter((option) => option);

    return {
      label: translate('brandPagePercentage.customPercentageSheme.title'),
      options: notNullOptions,
    };
  };

  const groupedPercentageSchemeOptions = [
    competitorsSchemeOptions(),
    defaultSchemeOptions(),
    customSchemeOptions(),
  ].filter((option) => option); // reject 'null' options

  return groupedPercentageSchemeOptions;
};

export default buildGroupedPercentageSchemeOptionsService;
