const billing = {
  billing: {
    cardInfoSection: {
      title: 'Payment Method',
      inputs: {
        cardInfo: {
          label: 'Credit Card',
        },
      },
      buttons: {
        update: 'Update',
        checkout: 'Update Card',
      },
    },
    paymentsHistorySection: {
      title: 'Payments History',
    },
  },
};

export default billing;
