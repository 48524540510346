import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import EoCampaignStepComponent from './EoCampaignStepComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { createEoCampaignStep, updateEoCampaignStep } from 'api/eoCampaignStep';
import { currentIdSelector } from 'selectors';
import { eoCampaignStepShape, eoCampaignStepsList } from 'common/propTypesShapes/eoCampaignSteps';
import { eoCampaignStepsSelector } from 'selectors/eoCampaignStepsSelector';
import { fetchEoCampaign } from 'api/eoCampaign';
import { translate } from 'common/i18n';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const EoCampaignStepContainer = ({
  dispatch,
  eoCampaignId,
  eoCampaignStep = {},
  eoCampaignSteps,
  onSelectEoCampaignStep,
}) => {
  async function handleSave(eoCampaignStepAction) {
    const { eoCampaignStepActionType, id } = eoCampaignStep;

    const eoCampaignStepData = {
      id: id || '',
      eoCampaignStepActionType,
      eoCampaignStepAction,
    };

    const resp = id
      ? await updateEoCampaignStep(dispatch, eoCampaignId, eoCampaignStepData)
      : await createEoCampaignStep(dispatch, eoCampaignId, eoCampaignStepData);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      return resp;
    }

    // Apply newly created step as 'selected'
    onSelectEoCampaignStep(resp);

    const isFirstEmailStepCreated =
      !id &&
      !eoCampaignSteps.some(
        (eoCampaignStep) =>
          eoCampaignStep.get('eoCampaignStepActionType') === 'EoCampaignStepEmail',
      ) &&
      eoCampaignStepActionType === 'EoCampaignStepEmail';

    // If the user created first email Step, then re-fetch the campaign in order to update statuses
    isFirstEmailStepCreated && fetchEoCampaign(dispatch, eoCampaignId);

    return resp;
  }

  function handleCancel() {
    onSelectEoCampaignStep({});
  }

  // Always keep step's position up to date - get it straight from the Redux store
  const eoCampaignStepPosition =
    eoCampaignSteps.size > 0 && eoCampaignSteps.some((step) => step.get('id') === eoCampaignStep.id)
      ? eoCampaignSteps.find((step) => step.get('id') === eoCampaignStep.id).get('position')
      : eoCampaignStep.position;

  return (
    <>
      {(eoCampaignSteps.size > 0 || !isEmpty(eoCampaignStep)) && (
        <EoCampaignStepComponent
          eoCampaignStep={eoCampaignStep}
          eoCampaignStepPosition={eoCampaignStepPosition}
          onCancel={handleCancel}
          onSave={handleSave}
        />
      )}
      {eoCampaignSteps.size === 0 && isEmpty(eoCampaignStep) && (
        <BlankStateCtaComponent>
          <TitleText>
            <strong>{translate('eoCampaignStep.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('eoCampaignStep.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

EoCampaignStepContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoCampaignStep: eoCampaignStepShape,
  eoCampaignSteps: eoCampaignStepsList,
  onSelectEoCampaignStep: PropTypes.func,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoCampaignSteps = eoCampaignStepsSelector(state);

  return {
    eoCampaignId,
    eoCampaignSteps,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(EoCampaignStepContainer)));
