import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { buyEmailVerificationCredits } from 'api/subscription_plan';

import { stripeKeySelector } from 'selectors/railsContextSelectors';

import BuyVerificationCreditsPopupComponent from './BuyVerificationCreditsPopupComponent';

const BuyVerificationCreditsPopupContainer = ({ dispatch, onClose, stripeKey }) => {
  const handleBuyCredits = async (creditsVolumeCount) => {
    const checkoutSession = await buyEmailVerificationCredits(
      dispatch,
      location.pathname,
      creditsVolumeCount,
    );

    // eslint-disable-next-line no-undef
    const stripe = Stripe(stripeKey);
    stripe.redirectToCheckout({ sessionId: checkoutSession.id });
  };

  return (
    <BuyVerificationCreditsPopupComponent onCancel={onClose} onBuyCredits={handleBuyCredits} />
  );
};

BuyVerificationCreditsPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  stripeKey: PropTypes.string.isRequired,
};

function select(state, ownProps) {
  const stripeKey = stripeKeySelector(state, ownProps);

  return {
    stripeKey,
  };
}

export default compose(ConnectStoreHOC, connect(select))(BuyVerificationCreditsPopupContainer);
