import React from 'react';

import EoEmailTemplateFormPopup from '../EoEmailTemplateFormPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const AddEoEmailTemplateButtonComponent = () => {
  return (
    <ToggleShowPopup Popup={<EoEmailTemplateFormPopup />}>
      <ButtonComponent className="eo-email-templates-bulk-actions__add-eo_email_template" isInitial isGreen>
        {translate('eoEmailTemplate.bulkActions.addEoEmailTemplate')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

export default AddEoEmailTemplateButtonComponent;
