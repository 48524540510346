import React from 'react';
import { camelCase } from 'lodash';
import { anchorSamplesArray } from 'common/propTypesShapes/anchorSamples';
import { translate } from 'common/i18n';

import './categorizeAnchorSample.scss';

const CategorizationSamplesComponent = ({ anchorSamples }) => {
  function buildAnchorSamples() {
    if (anchorSamples.length === 0) {
      return (
        <li>
          <p className="categorize-anchor-sample-component__anchor-type">None anchors found</p>
        </li>
      );
    }
    return anchorSamples.map((anchorSample) => {
      const anchorType = camelCase(anchorSample.anchorType);
      return (
        <li key={anchorSample.id}>
          <p className="categorize-anchor-sample-component__anchor-text">{anchorSample.anchorText}</p>
          <p className="categorize-anchor-sample-component__anchor-type">
            {translate(`options.anchorType.${anchorType}.label`)}
          </p>
        </li>
      );
    });
  }

  return (
    <div className="categorize-anchor-sample-component__anchor-samples-body">
      <span className="categorize-anchor-sample-component__label">
        {translate('categorizerPopup.labels.anchorSamples')}
      </span>
      <div className="categorize-anchor-sample-component__anchor-samples">
        <ul className="categorize-anchor-sample-component__list">{buildAnchorSamples()}</ul>
      </div>
    </div>
  );
};

CategorizationSamplesComponent.propTypes = {
  anchorSamples: anchorSamplesArray,
};

export default CategorizationSamplesComponent;
