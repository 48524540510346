import { fromJS, Map as iMap, List as iList } from 'immutable';

import { camelizeObjectDeep, mergeArrayToList, mergeItemToList } from './utils';

import {
  ADD_COMPETITORS_MANUALLY__SUCCESS,
  ADD_COMPETITORS_TO_ATP__FAILURE,
  ADD_COMPETITORS_TO_ATP__SUCCESS,
  FETCH_BULK_COMPETITOR_PAGES__SUCCESS,
  UPDATE_DRAFT_COMPETITOR_PAGE__SUCCESS,
  UPDATE_DRAFT_COMPETITOR_PAGES_ROWS,
} from 'actions/bulkCompetitorsSetupActions';

const initialState = iMap();

export default function bulkCompetitorPagesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BULK_COMPETITOR_PAGES__SUCCESS: {
      const { pages, brandPageId } = action;

      state = state.set(String(brandPageId), fromJS(pages.map(camelizeObjectDeep)));

      break;
    }
    case UPDATE_DRAFT_COMPETITOR_PAGE__SUCCESS: {
      const { page } = action;
      const brandPageId = camelizeObjectDeep(page).brandPageId;

      state = state.set(
        String(brandPageId),
        mergeItemToList(state.get(String(brandPageId), iList()), page, camelizeObjectDeep),
      );

      break;
    }
    case ADD_COMPETITORS_MANUALLY__SUCCESS:
    case ADD_COMPETITORS_TO_ATP__FAILURE:
    case ADD_COMPETITORS_TO_ATP__SUCCESS:
    case UPDATE_DRAFT_COMPETITOR_PAGES_ROWS: {
      const { pages, brandPageId } = action;

      state = state.set(
        String(brandPageId),
        mergeArrayToList(state.get(String(brandPageId), iList()), pages, camelizeObjectDeep),
      );

      break;
    }
  }

  return state;
}
