import { Map as iMap } from 'immutable';
import moment from 'moment';

import { translate } from '../../../../../../../common/i18n';

const valueFormattersService = (options, placeholders) => {
  const anchorTypeOptions = options.get('anchorTypeOptions');
  const includedOptions = options.get('includedOptions');
  const nofollowOptions = options.get('nofollowOptions');
  const statusOptions = options.get('linkStatusOptions');
  const includeByUserPlaceholder = placeholders.includeByUser;
  const publishedDatePlaceholder = placeholders.publishedDate;

  function anchorTypeValueFormatter(value) {
    return labelByValue(anchorTypeOptions, value);
  }

  function dateValueFormatter(value) {
    if (value && value !== publishedDatePlaceholder) {
      const date = new Date(value).toISOString();
      return moment(date).format('MM/DD/YYYY');
    }

    return value;
  }

  function includeByUserValueFormatter(value, extra) {
    const { rowData: { included } } = extra;

    if (value === includeByUserPlaceholder) {
      return value;
    }

    // the code below aims to properly build 4 possible values of include status:
    // Yes (user), No (user), Yes (auto), No (auto)
    const includedLabel = labelByValue(includedOptions, included);
    const postfix = value === 'auto' ? 'auto' : 'userDefined';
    const translatedPostfix = translate(`destinationLink.includeByUser.postfix.${postfix}`);
    return `${includedLabel}${translatedPostfix}`;
  }

  function nofollowValueFormatter(value) {
    return labelByValue(nofollowOptions, value);
  }

  function sourceValueFormatter(value) {
    return value;
  }

  function statusValueFormatter(value) {
    return labelByValue(statusOptions, value);
  }

  return {
    anchorTypeValueFormatter,
    dateValueFormatter,
    includeByUserValueFormatter,
    nofollowValueFormatter,
    sourceValueFormatter,
    statusValueFormatter,
  };
};


export default valueFormattersService;

function labelByValue(options, value) {
  const option = (options || iMap()).get(String(value), iMap()).toJS();

  return (option || {}).label;
}
