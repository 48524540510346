import React from 'react';

import EoDomainFormPopup from '../EoDomainFormPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const AddEoDomainButtonComponent = () => {
  return (
    <ToggleShowPopup Popup={<EoDomainFormPopup />}>
      <ButtonComponent className="eo-domains-bulk-actions__add-eo_domain" isInitial isGreen>
        {translate('eoDomain.bulkActions.addEoDomain')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

export default AddEoDomainButtonComponent;
