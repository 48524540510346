import { openSubscriptionLimitReachedPopup } from 'actions/popup_actions';

const clickUpgradeSubscriptionService = (context, event = void 0) => {
  const { dispatch } = context;

  event && event.preventDefault();

  dispatch(openSubscriptionLimitReachedPopup({}));
};

export default clickUpgradeSubscriptionService;
