import { createSelector } from 'reselect';
import { List as iList } from 'immutable';

const bulkCompetitorPages = (state, pageId) =>
  state.getIn(['bulkCompetitorPages', String(pageId)], iList());

const bulkCompetitorPagesByBrandPageIdSelector = createSelector(
  [bulkCompetitorPages],
  (bulkCompetitorPages) => bulkCompetitorPages || iList(),
);

export { bulkCompetitorPagesByBrandPageIdSelector };
