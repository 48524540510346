import React from 'react';
import PropTypes from 'prop-types';

import './testimonials_item_component.scss';


const TestimonialsItemComponent = ({ photoUrl, name, position, text }) => {
  return (
    <div className="testimonials-item-component">
      <div className="testimonials-item-component__photo">
        <img src={photoUrl} alt={name} />
      </div>
      <div className="testimonials-item-component__name">
        {name}
      </div>
      <div className="testimonials-item-component__position">
        {position}
      </div>
      <div className="testimonials-item-component__text">
        {text}
      </div>
    </div>
  );
};

TestimonialsItemComponent.propTypes = {
  name: PropTypes.string,
  photoUrl: PropTypes.string,
  position: PropTypes.string,
  text: PropTypes.string
};

export default TestimonialsItemComponent;
