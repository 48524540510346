import { updateBrandSuccess } from 'actions/brand_actions';

const handleBrandCrawlingStatus = (dispatch, data) => {
  const { brandCrawlingStatus } = data;

  if (brandCrawlingStatus) {
    dispatch(updateBrandSuccess(data));
  }
};

export default handleBrandCrawlingStatus;
