import React from 'react';

import Link from 'components_linkio/link';
import Button from 'components_linkio/button_component';

import { translate } from 'common/i18n';

import DisappointedRobotImage from 'common/img/disappointed_robot.svg';

import './noAccess.scss';

const NoAccessComponent = () => (
  <div className="no-access">
    <div className="no-access__text">{translate('uiComponents.noAccess.title')}</div>
    <img className="no-access__pic" src={DisappointedRobotImage} />
    <Link className="no-access__btn-wrapper" to={Routes.root_path({ format: null })}>
      <Button className="no-access__btn">
        {translate('uiComponents.noAccess.button')}
      </Button>
    </Link>
  </div>
);

export default NoAccessComponent;
