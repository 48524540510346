import React from 'react';

const PlusInRoundIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 510">
      <path
        d="M280.5 127.5h-51v102h-102v51h102v102h51v-102h102v-51h-102v-102zM255 0C114.75 0 0 114.75 0
        255s114.75 255 255 255 255-114.75 255-255S395.25 0 255 0zm0 459c-112.2 0-204-91.8-204-204S142.8 51 255
        51s204 91.8 204 204-91.8 204-204 204z"
      />
    </svg>
  );
};

PlusInRoundIcon.defaultProps = {
  width: '14px',
  height: '14px',
};

export default PlusInRoundIcon;
