import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';

import './trustSuggestionsPopupComponent.scss';

const VIDEO_SRC = 'https://www.youtube.com/embed/pXYobYIy13Q';

const TrustSuggestionsPopupComponent = ({ onCancel }) => {
  return (
    <Modal show className="trust-suggestions-popup">
      <Modal.Header>
        <Modal.Title>{translate('trustSuggestionsPopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="trust-suggestions-popup__wrapper">
          <div className="trust-suggestions-popup__video">
            <iframe allowFullScreen frameBorder="0" src={VIDEO_SRC} title="main-video" />
          </div>

          <div className="trust-suggestions-popup__description-wrapper">
            <div className="trust-suggestions-popup__description-title">
              <span>
                <b>{translate('trustSuggestionsPopup.description.about')}</b>
              </span>
            </div>
            <div className="trust-suggestions-popup__description-text">
              <p>{translate('trustSuggestionsPopup.description.intro')}</p>
              <p>
                <b>{translate('trustSuggestionsPopup.description.section1.title')}</b>
              </p>
              <p>{translate('trustSuggestionsPopup.description.section1.paragraph1')}</p>
              <p>
                <u>{translate('trustSuggestionsPopup.description.whyIsThisImportant')}</u>
              </p>
              <p>{translate('trustSuggestionsPopup.description.section1.paragraph2')}</p>
              <p>
                <b>{translate('trustSuggestionsPopup.description.section2.title')}</b>
              </p>
              <p>{translate('trustSuggestionsPopup.description.section2.paragraph1')}</p>
              <p>
                <u>{translate('trustSuggestionsPopup.description.whyIsThisImportant')}</u>
              </p>
              <p>{translate('trustSuggestionsPopup.description.section2.paragraph2')}</p>
              <p>
                <b>{translate('trustSuggestionsPopup.description.section3.title')}</b>
              </p>
              <p>{translate('trustSuggestionsPopup.description.section3.paragraph1')}</p>
              <p>{translate('trustSuggestionsPopup.description.section3.paragraph2')}</p>
              <p>
                <u>{translate('trustSuggestionsPopup.description.whyIsThisImportant')}</u>
              </p>
              <p>{translate('trustSuggestionsPopup.description.section3.paragraph3')}</p>
              <p>{translate('trustSuggestionsPopup.description.section3.paragraph4')}</p>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="trust-suggestions-popup__btn-group">
          <div className="trust-suggestions-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('trustSuggestionsPopup.buttons.cancel')}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

TrustSuggestionsPopupComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default TrustSuggestionsPopupComponent;
