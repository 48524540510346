import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import TabsComponent, { TabItemComponent } from 'components_linkio/tabs_component';

const PROSPECT_TABS = [
  'prospects',
  'eo_domains',
  'eo_blacklisted_domains',
  'eo_custom_fields',
];

const ProspectTabsComponent = ({ onTabClick, activeTab }) => {
  return (
    <TabsComponent className="eo-module-tabs">
      {PROSPECT_TABS.map((tab) => (
        <TabItemComponent
          key={tab}
          index={tab}
          label={translate(`prospects.tabs.${tab}`)}
          onClick={onTabClick(tab)}
          options={{ active: tab === activeTab }}
        />
      ))}
    </TabsComponent>
  );
};

ProspectTabsComponent.propTypes = {
  activeTab: PropTypes.string,
  onTabClick: PropTypes.func,
};

export default ProspectTabsComponent;
