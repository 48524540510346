import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { translate } from '../../../../../common/i18n';

import {
  destinationLinksArray,
  destinationLinksMap,
  optionsMap,
  policiesShape,
} from '../../../../../common/prop_types_shapes';

import {
  ATP_TABLE_TABS_COLUMNS,
} from '../../../../../components/atp/AtpTableTabs/constants';

import Select from '../../../../../components_linkio/Select';

import AtpTable from '../../../../../components/atp/atp_table';

import './pageDashboard.scss';

const PageDashboardComponent = ({
  atpTableProps,
  destinationLinks,
  onChangeStatusSelect,
  onShowAllClick,
  pageId,
}) => {
  const componentStyles = {
    control: {
      height: '30px',
      minHeight: '30px',
    },
    indicatorSeparator: {
      width: '0',
    },
  };

  const statusSelectOptions = [
    { value: 'all', label: translate('pageDashboard.status.all') },
    { value: 'started', label: translate('options.linkStatus.started.label') },
    { value: 'not_started', label: translate('options.linkStatus.notStarted.label') },
  ];

  const atpTableColumns = ATP_TABLE_TABS_COLUMNS['pageDashboard'];

  const showAllLinkClasses = classnames({
    'text': true,
    'text_link': true,
    [`capybara--page-dashboard-show-all-link_${pageId}`]: true,
  });

  return (
    <>
      <div className="page-dashboard">
        <div className="page-dashboard__headline">
          <h4 className="page-dashboard__title">
            {translate('pageDashboard.title')}
            {translate('pageDashboard.showAll.prefix')}
            <span className={showAllLinkClasses} onClick={onShowAllClick}>
              {translate('pageDashboard.showAll.title')}
            </span>
            {translate('pageDashboard.showAll.postfix')}
          </h4>

          <div className="page-dashboard__select-status-wrapper">
            <span>{translate('pageDashboard.status.title')}</span>
            <Select
              blurInputOnSelect
              componentStyles={componentStyles}
              isClearable={false}
              onChange={onChangeStatusSelect}
              options={statusSelectOptions}
              defaultValue={statusSelectOptions.find((option) => option.value === 'all')}
              triggerChangeOnBlur={false}
            />
          </div>
        </div>

        <AtpTable
          {...atpTableProps}
          columns={atpTableColumns}
          destinationLinks={destinationLinks}
          tableType={'pageDashboard'}
        />
      </div>
    </>
  );
};

PageDashboardComponent.propTypes = {
  atpTableProps: PropTypes.shape({
    anchorTextOptions: optionsMap,
    anchorTypeOptions: optionsMap,
    dlSourcesOptions: optionsMap,
    includeByUserOptions: optionsMap,
    nofollowOptions: optionsMap,
    onFetchAnchorTextOptions: PropTypes.func,
    onUpdateDl: PropTypes.func,
    onUpdateDlAnchorText: PropTypes.func,
    onUpdateRows: PropTypes.func,
    policies: policiesShape,
    selectedRows: destinationLinksMap,
    statusOptions: optionsMap,
  }),
  destinationLinks: destinationLinksArray,
  onChangeStatusSelect: PropTypes.func,
  onShowAllClick: PropTypes.func,
  pageId: PropTypes.string.isRequired,
};

export default PageDashboardComponent;
