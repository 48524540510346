import { fromJS, List as iList } from 'immutable';

import { FETCH_IMPERSONATES__SUCCESS } from 'actions/impersonatesActions';

const initialState = iList();

export default function impersonatesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_IMPERSONATES__SUCCESS: {
      state = fromJS(action.impersonates);

      break;
    }
  }

  return state;
}
