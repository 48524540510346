import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import Select from 'components_linkio/Select';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} readOnly />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

Option.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string,
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

MultiValue.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
  }),
};

const propsForSingle = {
  componentStyles: {
    option: {
      'wordBreak': 'break-all',
    },
  },
  triggerChangeOnBlur: false,
  blurInputOnSelect: true,
  isClearable: false,
  isCreatable: false,
  skinOptions: {
    inTable: false,
  },
  components: {
    IndicatorSeparator: () => null,
  },
};

const propsForMulti = {
  componentStyles: {
    option: {
      'wordBreak': 'break-all',
      'display': 'flex',
      'gap': 8,
    },
  },
  triggerChangeOnBlur: false,
  closeMenuOnSelect: false,
  hideSelectedOptions: false,
  isClearable: false,
  isCreatable: false,
  isMulti: true,
  skinOptions: {
    inTable: false,
  },
  components: {
    IndicatorSeparator: () => null,
    MultiValue,
    Option,
  },
};

const FilterSelectComponent = ({ isMultiSelect = false, ...props }) => {
  const initialProps = isMultiSelect ? propsForMulti : propsForSingle;

  return <Select {...initialProps} {...props} />;
};

FilterSelectComponent.propTypes = {
  isMultiSelect: PropTypes.bool,
};

export default FilterSelectComponent;
