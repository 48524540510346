import React from 'react';

const CheckedIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 18 13.847000122070312" xmlns="http://www.w3.org/2000/svg">
      <path d="M 0.207 7.616 C 0.069 7.478 0 7.269 0 7.131 C 0 6.993 0.069 6.784 0.207 6.647 L 1.178 5.678 C 1.453 5.4 1.869 5.4 2.147 5.678 L 2.216 5.747 L 6.022 9.831 C 6.162 9.969 6.369 9.969 6.507 9.831 L 15.784 0.207 L 15.853 0.207 C 16.131 -0.069 16.547 -0.069 16.822 0.207 L 17.793 1.178 C 18.069 1.453 18.069 1.869 17.793 2.147 L 6.716 13.638 C 6.578 13.778 6.438 13.847 6.231 13.847 C 6.022 13.847 5.884 13.778 5.747 13.638 L 0.347 7.822 L 0.207 7.616 Z" />
    </svg>
  );
};

CheckedIcon.defaultProps = {
  fill: '#1abc9c',
  width: '14px',
  height: '14px',
  cursor: 'pointer',
};

export default CheckedIcon;
