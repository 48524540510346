import React from 'react';
import PropTypes from 'prop-types';
import { List as iList } from 'immutable';
import { isEmpty } from 'lodash';

import { translate } from 'common/i18n';

import {
  ahrefsAbilitiesShape,
  brandShape,
  optionsArray,
  subscriptionPoliciesShape,
  subscriptionShape,
} from 'common/prop_types_shapes';

import BackButton from 'components_linkio/BackButton';
import Checkbox from 'components_linkio/Checkbox';
import ImportCsvComponent from 'components_linkio/ImportCsv';
import InputComponent from 'components_linkio/input_component';
import MessageComponent from 'components_linkio/message_component';
import RadioButton from 'components_linkio/RadioButton';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import TextAreaComponent from 'components_linkio/textarea_component';

import SubscriptionLimitRibbonComponent, {
  MailToUsLink,
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import Link from 'components_linkio/link';

import './brandForm.scss';

class BrandFormComponent extends React.PureComponent {
  static propTypes = {
    ahrefsAbilities: ahrefsAbilitiesShape,
    brand: brandShape,
    canUseAhrefsInSubscription: PropTypes.bool,
    canUseMozInSubscription: PropTypes.bool,
    fromModuleSetupSelection: PropTypes.bool,
    hasAhrefsConnection: PropTypes.bool,
    isBrandFormSimpleMode: PropTypes.bool,
    isMozLimitReached: PropTypes.bool,
    kwDomainMatchOptions: optionsArray.isRequired,
    onBrandCreate: PropTypes.func.isRequired,
    onBrandUpdate: PropTypes.func.isRequired,
    onClickUpgradeSubscription: PropTypes.func,
    subscriptionPolicies: subscriptionPoliciesShape,
    subscription: subscriptionShape,
    websiteTypeOptions: optionsArray.isRequired,
  };
  
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      ahrefsAbilities: { isAble: isAbleToImportFromAhrefs },
    } = nextProps;

    if (
      prevState.importBacklinksFromAhrefs === undefined &&
      isAbleToImportFromAhrefs !== undefined
    ) {
      return {
        importBacklinksFromAhrefs: isAbleToImportFromAhrefs,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    const { brand } = props;
    const { subscription } = props;
    this.state = {
      csvFile: void 0,
      errors: {},
      id: brand.get('id'),
      // we should return undefined for new brands to check ahrefs ability in render
      importBacklinksFromAhrefs: brand.get('importBacklinksFromAhrefs'),
      importBacklinksFromMoz: brand.get('importBacklinksFromMoz', false),
      importDlsFromGsc: false,
      kwDomainMatch: brand.get('kwDomainMatch'),
      name: brand.get('name', ''),
      otherNames: [...brand.get('otherNames', iList()).toJS()].join('\n'),
      website: brand.get('website', ''),
      websiteType: brand.get('websiteType'),
      isLoading: false,
      //added to check subscription status
      subscriptionStatus: subscription.get('status'),
      subscriptionType: subscription.get('subscriptionPlanTitle'),
      subscriptionIsPaid: subscription.get('subscriptionPlanIsPaid'),
    };
  }
  handleInputChange = (inputName) => (event) => {
    const { target } = event;

    this.setState({ [inputName]: (target || {}).value });
  };

  handleCheckboxChange = (inputName) => (event) => {
    const { checked } = event.target;

    this.setState({ [inputName]: checked });
  };

  handleCsvFileUpload = (csvFile) => this.setState({ csvFile });

  handleSubmitClick = async (event) => {
    event.preventDefault();

    const { onBrandCreate, onBrandUpdate } = this.props;
    const brand = this.state;

    this.setState({ isLoading: true });

    const result = brand.id ? await onBrandUpdate(brand) : await onBrandCreate(brand);
    this.setState({ isLoading: false });

    const { type, errors = {}, message } = result;

    if (type === 'error' || !isEmpty(errors)) {
      this.setState({ errors: message || errors });
    }
  };

  kwDomainMatchHintText = (
    <span>
      {translate('brand.form.inputs.kwDomainMatch.hint.partOne')}
      <b>{translate('brand.form.inputs.kwDomainMatch.hint.partTwo')}</b>
      {translate('brand.form.inputs.kwDomainMatch.hint.partThree')}
      <b>{translate('brand.form.inputs.kwDomainMatch.hint.partFour')}</b>
      {translate('brand.form.inputs.kwDomainMatch.hint.partFive')}
    </span>
  );

  render() {
    const {
      csvFile,
      errors,
      importBacklinksFromAhrefs,
      importBacklinksFromMoz,
      importDlsFromGsc,
      kwDomainMatch,
      name,
      otherNames,
      website,
      websiteType,
      isLoading,
      subscriptionStatus,
      subscriptionType,
      subscriptionIsPaid,
    } = this.state;

    const {
      ahrefsAbilities: {
        freeAhrefsSubscription: hasFreeAhrefsSubscription,
        isAble: isAbleToImportFromAhrefs,
        limitReached: isAhrefsLimitReached,
      },
      brand,
      canUseAhrefsInSubscription,
      canUseMozInSubscription,
      fromModuleSetupSelection,
      hasAhrefsConnection,
      isMozLimitReached,
      isBrandFormSimpleMode,
      kwDomainMatchOptions,
      onClickUpgradeSubscription,
      subscriptionPolicies,
      subscription,
      websiteTypeOptions,
    } = this.props;

    const websiteMessage = errors.website
      ? { type: 'error', text: errors.website }
      : { type: 'hint', text: translate('brand.form.inputs.website.hint') };

    const importFromGscHintMessage = (
      <>
        {translate('brand.form.inputs.importDlsFromGscFileName.hint.firstPart')}
        <a
          href={translate('brand.form.inputs.importDlsFromGscFileName.hint.gscLink.href')(website)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {translate('brand.form.inputs.importDlsFromGscFileName.hint.gscLink.title')}
        </a>
        {translate('brand.form.inputs.importDlsFromGscFileName.hint.secondPart')}
      </>
    );

    const brandNameMessage = { type: 'error', text: errors.name };

    const brandId = brand.get('id');
    //make variable to check if trail
    const isTrial = subscriptionStatus === 'trialing';
    const isFree = subscriptionType === 'Free';
    const isMozCheckboxDisabled =
      !importBacklinksFromMoz && !canUseMozInSubscription && !isMozLimitReached;
    //disable for free users
    const isAhrefsCheckboxDisabled =
    (!importBacklinksFromAhrefs && !isAbleToImportFromAhrefs) || !subscriptionIsPaid;
    const showAhrefsLimitRibbon = hasAhrefsConnection && !canUseAhrefsInSubscription;

    const canAddBrand = subscriptionPolicies.getIn(['brand', 'canAdd'], false);
    const subscriptionLimitReached = !canAddBrand && !brandId;
    const brandsLimitReached = subscriptionPolicies.getIn(['brand', 'limitReached'], false);
    const showLimitReachedRibbon = subscriptionLimitReached || brandsLimitReached;
    const checked=(importBacklinksFromAhrefs && !isFree) || false
    return (
      <div className="brand">
        {!brandId && !showLimitReachedRibbon && (
          <div className="brand__add-brand-title">
            <h2>{translate('brand.add.title')}</h2>
            <h3>{translate('brand.add.subtitle')}</h3>
          </div>
        )}

        {showLimitReachedRibbon && (
          <SubscriptionLimitRibbonComponent>
            {translate('subscriptionLimitRibbon.maxBrandLimitReached')}
            {brandsLimitReached ? (
              <MailToUsLink />
            ) : (
              <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
            )}
            {brandsLimitReached && translate('subscriptionLimitRibbon.ifYouWantToAddMore')}
          </SubscriptionLimitRibbonComponent>
        )}

        <form className="brand__form" noValidate>
          {!fromModuleSetupSelection && (
            <InputComponent
              autoFocus
              isMandatory
              label={translate('brand.form.inputs.website.label')}
              message={websiteMessage}
              name="website"
              onInputChange={this.handleInputChange('website')}
              type="text"
              value={website}
            />
          )}

          {!isBrandFormSimpleMode && (
            <>
              <div className="brand__website-type">
                <p>{translate('brand.form.inputs.websiteType.label')}</p>
                <ul>
                  {websiteTypeOptions.map(({ label, value }) => (
                    <li key={value}>
                      <RadioButton
                        checked={websiteType === value}
                        onChange={this.handleInputChange('websiteType')}
                        value={value}
                      >
                        {label}
                      </RadioButton>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="brand__keyword-domain-match">
                <p>{translate('brand.form.inputs.kwDomainMatch.label')}</p>
                <ul>
                  {kwDomainMatchOptions.map(({ label, value }) => (
                    <li key={value}>
                      <RadioButton
                        checked={kwDomainMatch === value}
                        onChange={this.handleInputChange('kwDomainMatch')}
                        value={value}
                      >
                        {label}
                      </RadioButton>
                    </li>
                  ))}
                </ul>
                <MessageComponent message={{ type: 'hint' }}>
                  {this.kwDomainMatchHintText}
                </MessageComponent>
              </div>
            </>
          )}

          {!fromModuleSetupSelection && (
            <InputComponent
              isMandatory
              label={translate('brand.form.inputs.brandName.label')}
              message={brandNameMessage}
              name="brandName"
              onInputChange={this.handleInputChange('name')}
              type="text"
              value={name}
            />
          )}

          {!isBrandFormSimpleMode && (
            <TextAreaComponent
              explanationMessage={translate('explanationMessages.brand.otherNames')}
              label={translate('brand.form.inputs.otherNames.label')}
              message={{ type: 'hint', text: translate('brand.form.inputs.otherNames.hint') }}
              onChange={this.handleInputChange('otherNames')}
              value={otherNames}
            />
          )}

          {!isBrandFormSimpleMode && !fromModuleSetupSelection && (
            <>
              <div className="brand__gsc-import">
                <Checkbox
                  checked={importDlsFromGsc}
                  onChange={this.handleCheckboxChange('importDlsFromGsc')}
                >
                  {translate('brand.form.checkboxes.importDlsFromGsc.label')}
                </Checkbox>
                <div className="brand__csv-import">
                  <ImportCsvComponent
                    isDisabled={!importDlsFromGsc}
                    onFileUpload={this.handleCsvFileUpload}
                    hintMessage={importFromGscHintMessage}
                  />
                </div>
              </div>

              <div className="brand__ahrefs-integration">
                {showAhrefsLimitRibbon && (
                  <SubscriptionLimitRibbonComponent>
                    {translate('subscriptionLimitRibbon.message')(
                      translate('subscriptionLimitRibbon.limited.ahrefs'),
                      true,
                    )}
                    <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
                  </SubscriptionLimitRibbonComponent>
                )}
                <div className="brand__import-backlinks-from-ahrefs-checkbox-row">
                  <Checkbox
                    checked={(importBacklinksFromAhrefs && !isFree) || false}
                    disabled={isAhrefsCheckboxDisabled}
                    onChange={this.handleCheckboxChange('importBacklinksFromAhrefs')}
                  >
                    {translate('brand.form.inputs.importBacklinksFromAhrefsCheckbox.label')}
                  </Checkbox>
                  {((!hasAhrefsConnection && subscriptionIsPaid) || isTrial) && (
                    <Link href={Routes.automation_settings_path({ format: null })}>
                      {translate('brand.form.connectAhrefsAccountLinkText')}
                    </Link>
                  )}
                </div>
                {hasFreeAhrefsSubscription && (
                  <MessageComponent
                    message={{
                      type: 'hint',
                      text: translate(
                        'brand.form.inputs.importBacklinksFromAhrefsCheckbox.warningFree',
                      ),
                    }}
                  />
                )}
                {isAhrefsLimitReached && (
                  <MessageComponent
                    message={{
                      type: 'hint',
                      text: translate(
                        'brand.form.inputs.importBacklinksFromAhrefsCheckbox.warningLimit',
                      ),
                    }}
                  />
                )}
                {/* message for trail users and paid subscrbtions */}
                
                {(!isTrial && isFree) || !subscriptionIsPaid ? (
                  <MessageComponent
                    message={{
                      type: 'hint',
                      text: translate('brand.form.inputs.importBacklinksFromAhrefsCheckbox.hint'),
                    }}
                  />
                ) : (
                  <MessageComponent
                    message={{
                      type: 'hint',
                      text: translate(
                        'brand.form.inputs.importBacklinksFromAhrefsCheckbox.warningDefaultAhref',
                      ),
                    }}
                  />
                )}
              </div>
              {/* <div className="brand__moz-integration">
                <div className="brand__import-backlinks-from-moz-checkbox-row">
                  <Checkbox
                    checked={importBacklinksFromMoz}
                    disabled={isMozCheckboxDisabled}
                    onChange={this.handleCheckboxChange('importBacklinksFromMoz')}
                  >
                    {translate('brand.form.inputs.importBacklinksFromMozCheckbox.label')}
                  </Checkbox>
                </div>
                {isMozLimitReached && (
                  <MessageComponent
                    message={{
                      type: 'hint',
                      text: translate(
                        'brand.form.inputs.importBacklinksFromMozCheckbox.warningLimit',
                      ),
                    }}
                  />
                )}
                <MessageComponent
                  message={{
                    type: 'hint',
                    text: translate('brand.form.inputs.importBacklinksFromMozCheckbox.hint'),
                  }}
                />
              </div> */}
            </>
          )}

          <div className="brand__form-buttons-wrapper">
            <BackButton isWhite isInitial>
              {translate('brand.form.buttons.cancel')}
            </BackButton>

            <SubmitButtonComponent
              isDisabled={subscriptionLimitReached || (importDlsFromGsc && !csvFile)}
              isLoading={isLoading}
              onClick={this.handleSubmitClick}
              text={translate('brand.form.buttons.save')(brandId)}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default BrandFormComponent;
