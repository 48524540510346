import React from 'react';

const BacklinksRediscoveredIcon = (props) => (
  <svg {...props} viewBox="0 0 16 16">
    <path
      d="M2.333 16h9.334C12.955 16 14 14.995 14 13.754V4.211c0-1.24-1.045-2.246-2.333-2.246H10.48C10.333.843 9.342 0 8.167 0H5.833C4.658.001 3.667.843 3.52 1.965H2.333C1.045 1.965 0 2.97 0 4.21v9.543C0 14.994 1.045 16 2.333 16zm3.5-14.877h2.334c.644 0 1.166.503 1.166 1.123s-.522 1.122-1.166 1.122H5.833c-.644 0-1.166-.502-1.166-1.122 0-.62.522-1.123 1.166-1.123zM1.167 4.21c0-.62.522-1.123 1.166-1.123h1.34c.355.847 1.21 1.403 2.16 1.403h2.334c.95 0 1.805-.556 2.16-1.403h1.34c.644 0 1.166.502 1.166 1.123v9.543c0 .62-.522 1.123-1.166 1.123H2.333c-.644 0-1.166-.503-1.166-1.123V4.211zm3.91 2.743l-.477.478-1.13-1.06.547-.548 1.06 1.13zM6.98 5.537l-.446 1.49-.651-.175.354-1.519.743.204zm-2.306 3.35l-1.49.446-.204-.74 1.519-.357.175.652zm2.506 1.672l.599-.597a1.524 1.524 0 01-.299 1.73l-.863.863a1.526 1.526 0 01-2.154 0l-.017-.017a1.525 1.525 0 010-2.154l.863-.863a1.527 1.527 0 011.73-.298l-.598.598a.778.778 0 00-.606.226l-.863.862a.78.78 0 000 1.103l.017.017a.78.78 0 001.102 0l.864-.863a.779.779 0 00.225-.607zm.043-1.52a1.526 1.526 0 01.299-1.73l.296-.296.541.51-.312.311a.779.779 0 00-.225.607l-.599.598zm3.333-2.577c.594.594.594 1.56 0 2.153l-.864.863a1.527 1.527 0 01-1.73.299l.598-.599a.78.78 0 00.607-.225l.862-.863a.78.78 0 000-1.102l-.016-.017a.78.78 0 00-1.103 0l-.253.253-.51-.542.238-.237a1.526 1.526 0 012.154 0l.017.017zm-4.65 4.633a.371.371 0 010-.526L8.57 7.905a.371.371 0 11.525.525l-2.664 2.665a.371.371 0 01-.526 0z"
      fill="currentColor"
    />
  </svg>
);

BacklinksRediscoveredIcon.defaultProps = {
  height: '16px',
  width: '16px',
};

export default BacklinksRediscoveredIcon;
