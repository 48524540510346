import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';

import { eoSchedulerShape } from 'common/propTypesShapes/eoSchedulers';
import { optionsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import Checkbox from 'components_linkio/Checkbox';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import LabelComponent from 'components_linkio/label_component';
import MessageComponent from 'components_linkio/message_component';
import RadioButton from 'components_linkio/RadioButton';
import Select from 'components_linkio/Select';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import TimezoneSelect from 'components_linkio/TimezoneSelectComponent';

import AddEoDripListButton from 'pages/EoDripListsPage/EoDripListsBulkActions/AddEoDripListButton';

import convertTimeFrom24To12Format from 'utils/convertTimeFrom24To12Format';
import { WEEKDAYS } from 'utils/constants';

import './eoSchedulerFormPopup.scss';

function buildInitialState(accountTimezone) {
  return {
    daytimeHours: '08',
    daytimeMinutes: '00',
    daytimeMode: 'am',
    eoDripListTitle: '',
    prospectsCount: '',
    timezone: accountTimezone,
    weekdays: [],
    errors: {},
  };
}

function reducer(state, action) {
  const { value, type } = action;

  let newState = cloneDeep(state);

  switch (type) {
    case 'changeDaytimeHours': {
      if (Number(value) > 12 || value.length > 2) {
        break;
      }

      newState.daytimeHours = value;
      break;
    }
    case 'changeDaytimeMinutes': {
      if (Number(value) > 59 || value.length > 2) {
        break;
      }

      newState.daytimeMinutes = value;
      break;
    }
    case 'setDaytime': {
      const { daytimeHours, daytimeMinutes, daytimeMode = '' } = value;

      newState = {
        ...newState,
        daytimeHours,
        daytimeMinutes,
        daytimeMode: daytimeMode.toLowerCase(),
      };

      break;
    }
    case 'setWeekdays': {
      let updatedWeekdays = newState.weekdays;

      if (updatedWeekdays.includes(value)) {
        updatedWeekdays = updatedWeekdays.filter((weekday) => weekday !== value);
      } else {
        updatedWeekdays.push(value);
      }

      newState = {
        ...newState,
        weekdays: updatedWeekdays,
      };

      break;
    }
    default: {
      newState[type] = value;

      break;
    }
  }

  return newState;
}

const EoSchedulerFormPopupComponent = ({
  accountTimezone,
  immutableEoDripListsOptions,
  eoScheduler,
  onCancel,
  onSave,
  immutableTimezonesOptions,
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    eoScheduler || buildInitialState(accountTimezone),
  );

  const eoDripListsOptions = React.useMemo(() => immutableEoDripListsOptions.toJS(), [
    immutableEoDripListsOptions.size,
  ]);

  const timezonesOptions = React.useMemo(() => immutableTimezonesOptions.toJS(), [
    immutableTimezonesOptions.size,
  ]);

  React.useEffect(() => {
    if (eoScheduler) {
      const convertedDaytimeString = convertTimeFrom24To12Format(eoScheduler.daytime);

      const [daytimeHours, daytimeMinutes, daytimeMode] = convertedDaytimeString
        .replace(/ /g, ':')
        .split(':');

      dispatch({ type: 'setDaytime', value: { daytimeHours, daytimeMinutes, daytimeMode } });
    }
  }, []);

  const {
    daytimeMinutes,
    daytimeHours,
    daytimeMode,
    eoDripListTitle,
    prospectsCount,
    timezone,
    weekdays,
    errors,
  } = state;

  const setInputValue = (inputName) => (event) => {
    const value = event.target.value;
    dispatch({ type: inputName, value });
  };

  const changeDaytime = (actionType) => (event) => {
    const value = event.target.value;

    dispatch({ type: actionType, value });
  };

  const changeSelectOptionByLabel = (inputName) => (obj) => {
    dispatch({ type: inputName, value: obj.label });
  };

  function changeTimezoneName(timezone) {
    dispatch({ type: 'timezone', value: timezone });
  }

  const onChangeWeekdays = (weekday) => (_event) => {
    dispatch({ type: 'setWeekdays', value: weekday });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const daytime = daytimeHours ? `${daytimeHours}:${(daytimeMinutes || 0) + daytimeMode}` : null;

    const eoDripListId = (eoDripListsOptions.find(({ label }) => label === eoDripListTitle) || {})
      .value;

    const processedTimezone = timezone
      ? (timezonesOptions.find(({ label }) => label.includes(timezone)) || {}).value
      : null;

    const newEoScheduler = {
      id: eoScheduler ? eoScheduler.id : null,
      daytime,
      eoDripListId,
      prospectsCount: Number(prospectsCount),
      timezone: processedTimezone,
      weekdays,
    };

    const resp = await onSave(newEoScheduler);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      dispatch({ type: 'errors', value: errors });
    }
  }

  function handleCreateEoDripListCb(payload) {
    // Apply newly created Drip List automatically if no Drip List was selected before
    !eoDripListTitle && dispatch({ type: 'eoDripListTitle', value: payload.title });
  }

  const defaultTimezoneName = eoScheduler
    ? (timezonesOptions.find(({ label }) => label === eoScheduler.timezone) || {}).value
    : accountTimezone;

  return (
    <Modal show className="eo-schedulers-form-popup">
      <Modal.Header>
        <Modal.Title>
          {translate('eoScheduler.eoSchedulerFormPopup.title')(!!eoScheduler)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form id="eo-schedulers-form" className="eo-schedulers-form-popup__form" noValidate>
          <LabelComponent
            text={translate('eoScheduler.eoSchedulerFormPopup.inputs.weekdays.label')}
          />

          <div className="eo-schedulers-form-popup__weekdays">
            <MessageComponent message={{ type: 'error', text: errors.weekdays }} />

            {WEEKDAYS.map((weekday) => (
              <Checkbox
                key={weekday}
                checked={weekdays.includes(weekday)}
                onChange={onChangeWeekdays(weekday)}
                value={weekday}
              >
                {translate(`uiComponents.weekdays.${weekday}`)}
              </Checkbox>
            ))}
          </div>

          <LabelComponent
            isMandatory
            text={translate('eoScheduler.eoSchedulerFormPopup.inputs.daytime.label')}
          />
          <div className="eo-schedulers-form-popup__daytime">
            <InputComponent
              onInputChange={changeDaytime('changeDaytimeHours')}
              value={daytimeHours}
              message={{ type: 'error', text: errors.daytime }}
              type="number"
            />
            <span className="eo-schedulers-form-popup__daytime-divider">:</span>
            <InputComponent
              onInputChange={changeDaytime('changeDaytimeMinutes')}
              value={daytimeMinutes}
              type="number"
            />
            {['am', 'pm'].map((name) => (
              <RadioButton
                key={name}
                checked={daytimeMode === name}
                onChange={setInputValue('daytimeMode')}
                value={name}
              >
                {translate(`uiComponents.time.${name}`)}
              </RadioButton>
            ))}
          </div>

          <TimezoneSelect
            showAccountTimezoneOption
            defaultTimezoneName={defaultTimezoneName}
            onChangeTimezoneName={changeTimezoneName}
            errors={errors}
          />

          <InputComponent
            isMandatory
            className="eo-schedulers-form-popup__prospects-count"
            label={translate('eoScheduler.eoSchedulerFormPopup.inputs.prospectsCount.label')}
            onInputChange={setInputValue('prospectsCount')}
            value={prospectsCount}
            message={{ type: 'error', text: errors.prospectsCount }}
            type="number"
          />

          <div className="eo-schedulers-form-popup__eo-drip-list">
            <Select
              isMandatory
              label={translate('eoScheduler.eoSchedulerFormPopup.inputs.eoDripList.label')}
              blurInputOnSelect={false}
              placeholder={translate(
                'eoScheduler.eoSchedulerFormPopup.inputs.eoDripList.placeholder',
              )}
              triggerChangeOnBlur={false}
              onChange={changeSelectOptionByLabel('eoDripListTitle')}
              options={eoDripListsOptions}
              value={
                eoDripListTitle &&
                eoDripListsOptions.find(({ label }) => label.includes(eoDripListTitle))
              }
              message={{ type: 'error', text: errors.eoDripListId }}
            />

            <AddEoDripListButton isBlue onSubmitCb={handleCreateEoDripListCb} />
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="eo-schedulers-form-popup__btn-group">
          <div className="eo-schedulers-form-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('eoScheduler.eoSchedulerFormPopup.buttons.cancel')}
            </ButtonComponent>
          </div>

          <div className="eo-schedulers-form-popup__btn">
            <SubmitButtonComponent
              form="eo-schedulers-form"
              isGreen
              onClick={handleSubmit}
              text={translate('eoScheduler.eoSchedulerFormPopup.buttons.submit')}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EoSchedulerFormPopupComponent.propTypes = {
  accountTimezone: PropTypes.string,
  eoScheduler: eoSchedulerShape,
  immutableEoDripListsOptions: optionsList,
  immutableTimezonesOptions: optionsList,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EoSchedulerFormPopupComponent;
