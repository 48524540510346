import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import TabsComponent, { TabItemComponent } from 'components_linkio/tabs_component';

import './EoEmailTemplatesTabs.scss';

const eoEmailTemplatesTabs = ['all', 'private', 'shared', 'default'];

const EoEmailTemplatesTabsComponent = ({
  activeTab,
  handleDropSelectedFolder,
  handleTabSelect,
  templatesCount,
}) => {
  return (
    <>
      <TabsComponent className="eo-email-templates-tabs">
        {eoEmailTemplatesTabs.map((tab) => (
          <TabItemComponent
            key={tab}
            index={tab}
            label={translate(`eoEmailTemplate.sidebar.tabs.${tab}`)}
            onClick={handleTabSelect(tab)}
            options={{ active: tab === activeTab }}
          />
        ))}
      </TabsComponent>
      <div className="eo-email-templates-tabs__current-details" onClick={handleDropSelectedFolder}>
        <span>{activeTab}</span>
        <span>{templatesCount}</span>
      </div>
    </>
  );
};

EoEmailTemplatesTabsComponent.propTypes = {
  activeTab: PropTypes.string,
  handleDropSelectedFolder: PropTypes.func,
  handleTabSelect: PropTypes.func,
  templatesCount: PropTypes.number,
};

export default EoEmailTemplatesTabsComponent;
