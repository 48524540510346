import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';
import { currentIdSelector } from './index';

const rankTrackerFetchingStates = (state) => state.getIn(['rankTracker', 'fetchingStates']);
const rankTrackerFilters = (state) => state.getIn(['rankTracker', 'filters']);
const keywordsWithChanges = (state) => state.getIn(['rankTracker', 'keywordsWithChanges']);
const keywordsWithChangesByUrl = (state) => state.getIn(['rankTracker', 'keywordsWithChangesByUrl']);

const rankTrackerFetchingStatesSelector = createSelector(
  [rankTrackerFetchingStates],
  (rankTrackerFetchingStates) => rankTrackerFetchingStates || iMap()
);

const rankTrackerFiltersSelector = createSelector(
  [rankTrackerFilters],
  (rankTrackerFilters) => rankTrackerFilters || iMap()
);

const keywordsWithChangesSelector = createSelector(
  [keywordsWithChanges, currentIdSelector],
  (keywordsWithChanges, brandId) => keywordsWithChanges.get(brandId) || iList()
);

const keywordsWithChangesByUrlSelector = createSelector(
  [keywordsWithChangesByUrl, currentIdSelector],
  (keywordsWithChangesByUrl, brandId) => keywordsWithChangesByUrl.get(brandId) || iList()
);

export {
  keywordsWithChangesSelector,
  keywordsWithChangesByUrlSelector,
  rankTrackerFetchingStatesSelector,
  rankTrackerFiltersSelector,
};
