import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import withQueryParams from 'decorators/withQueryParams';

import { DEFAULT_SE_SETTINGS } from './utils';
import RankTrackerSetupComponent from './RankTrackerSetupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';
import { fetchDataIfBrandIdHasChanged } from 'common/utils';

import { optionsList, rankTrackerSettingsMap } from 'common/prop_types_shapes';

import {
  fetchRankTrackerCountries,
  fetchRankTrackerEngines,
  fetchRankTrackerLanguages,
  fetchRankTrackerLocations,
  fetchRankTrackerSettings,
  saveRankTrackerSettings,
} from 'api/rankTrackerSetup';

import { fetchSimpleBrands } from 'api/simpleBrand';

import { currentIdSelector, optionsSelector } from 'selectors';

import { rankTrackerSettingsSelector } from 'selectors/rankTrackerSettingsSelector';

import Breadcrumbs from 'components/NewBreadcrumbs';
import BreadcrumbsBrandsSelect from 'components/NewBreadcrumbs/BreadcrumbsBrandsSelect';

class RankTrackerSetupContainer extends React.Component {
  static propTypes = {
    brandId: PropTypes.string,
    dispatch: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    rankTrackerCountriesOptions: optionsList,
    rankTrackerLanguagesOptions: optionsList,
    rankTrackerLocationsOptions: optionsList,
    rankTrackerSettings: rankTrackerSettingsMap,
    searchEngineOptions: optionsList,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    fetchDataIfBrandIdHasChanged(prevProps.brandId, this.props.brandId, this.fetchData);
  }

  fetchData = () => {
    const { dispatch, brandId } = this.props;
    const { DEFAULT_COUNTRY, DEFAULT_ENGINE_NAME } = DEFAULT_SE_SETTINGS;

    Promise.all([
      fetchRankTrackerEngines(dispatch),
      fetchRankTrackerSettings(dispatch, brandId).then((rankTrackerSettings) => {
        const engineName = rankTrackerSettings.engineName || DEFAULT_ENGINE_NAME;
        fetchRankTrackerCountries(dispatch, { engineName }).then((rankTrackerCountries) => {
          // If we get only one country it means we already have languages in the same response
          // So fetch languages only if we get more than 1 country
          if (rankTrackerCountries.length > 1) {
            const country = rankTrackerSettings.country || DEFAULT_COUNTRY;
            fetchRankTrackerLanguages(dispatch, { engineName, country });
          }
        });
      }),
    ]);
  };

  handleClickCancel = () => {
    const { brandId, rankTrackerSettings, history } = this.props;

    const isUpdateSetting = Boolean(rankTrackerSettings.get('id'));

    isUpdateSetting
      ? history.push(Routes.rank_tracker_root_path(brandId, { format: null }))
      : history.push(Routes.root_path({ format: null }));
  };

  handleChangeOptions = (option, params) => {
    const { dispatch } = this.props;

    switch (option) {
      case 'country': {
        fetchRankTrackerLanguages(dispatch, params);

        break;
      }
      case 'engineName': {
        fetchRankTrackerCountries(dispatch, params);

        break;
      }
      case 'locationName': {
        fetchRankTrackerLocations(dispatch, params);

        break;
      }
    }
  };

  handleSaveRankTrackerSettings = async (settings) => {
    const { dispatch, brandId, rankTrackerSettings, history, queryParams } = this.props;

    const result = await saveRankTrackerSettings(dispatch, brandId, settings);

    const { errors } = result;

    if (!isEmpty(errors)) {
      return result;
    }

    const isUpdateSetting = Boolean(rankTrackerSettings.get('id'));

    if (isUpdateSetting) {
      this.handleClickCancel();
    } else {
      await fetchSimpleBrands(dispatch);

      const path =
        queryParams.fromModuleSetupSelection === 'true'
          ? Routes.edit_brand_path(brandId, { format: null, fromModuleSetupSelection: true })
          : Routes.dashboard_path(brandId, { format: null });

      history.push(path);
    }

    return {};
  };

  render() {
    const {
      rankTrackerCountriesOptions,
      rankTrackerLanguagesOptions,
      rankTrackerLocationsOptions,
      rankTrackerSettings,
      searchEngineOptions,
    } = this.props;

    const rankTrackerSettingsBrandId = rankTrackerSettings.get('brandId');

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.LinkItem to={Routes.root_path({ format: null })}>
            {translate('brand.list.title')}
          </Breadcrumbs.LinkItem>
          <BreadcrumbsBrandsSelect targetAppModule="rankTrackerSetup" />
        </Breadcrumbs>

        <div className="page__wrapper">
          {rankTrackerSettingsBrandId && (
            <RankTrackerSetupComponent
              key={rankTrackerSettingsBrandId}
              onChangeOptions={this.handleChangeOptions}
              onClickCancel={this.handleClickCancel}
              onSaveRankTrackerSettings={this.handleSaveRankTrackerSettings}
              rankTrackerCountriesOptions={rankTrackerCountriesOptions}
              rankTrackerLanguagesOptions={rankTrackerLanguagesOptions}
              rankTrackerLocationsOptions={rankTrackerLocationsOptions}
              rankTrackerSettings={rankTrackerSettings}
              searchEngineOptions={searchEngineOptions}
            />
          )}
        </div>
      </>
    );
  }
}

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);

  const rankTrackerSettings = rankTrackerSettingsSelector(state, ownProps);

  const options = optionsSelector(state);
  const rankTrackerCountriesOptions = options.get('rankTrackerCountriesOptions');
  const rankTrackerLanguagesOptions = options.get('rankTrackerLanguagesOptions');
  const rankTrackerLocationsOptions = options.get('rankTrackerLocationsOptions');
  const searchEngineOptions = options.get('searchEngineOptions');

  return {
    brandId,
    rankTrackerCountriesOptions,
    rankTrackerLanguagesOptions,
    rankTrackerLocationsOptions,
    rankTrackerSettings,
    searchEngineOptions,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(RankTrackerSetupContainer)));
