import { List as iList } from 'immutable';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, isEmpty } from 'lodash';

import EoEmailTemplateFormPopupComponent from './EoEmailTemplateFormPopupComponent';

import { eoEmailTemplateShape } from 'common/propTypesShapes/eoEmailTemplates';

import {
  createEoEmailTemplate,
  deleteEoEmailTemplates,
  fetchEoEmailTemplate,
  updateEoEmailTemplate,
} from 'api/eoEmailTemplate';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { fetchSimpleBrands } from 'api/simpleBrand';
import { fetchCmarketerEoEmailTemplateFoldersAsOptions } from 'api/eoEmailTemplateFolder';

import { optionsSelector } from 'selectors';
import { optionsList, userShape } from 'common/prop_types_shapes';

const EoEmailTemplateFormPopupContainer = ({
  brandsOptions,
  currentUser,
  dispatch,
  eoCmarketerEmailTemplateFoldersOptions,
  onClose,
  tableRowData,
}) => {
  const [eoEmailTemplate, setEoEmailTemplate] = useState(tableRowData);
  const [fetchingState, setFetchingState] = useState('');

  const canUserEditTemplate = eoEmailTemplate
    ? eoEmailTemplate.userId === currentUser.get('id')
    : true;

  React.useEffect(() => {
    fetchSimpleBrands(dispatch);

    // restrict folders with ones belong to current marketer
    fetchCmarketerEoEmailTemplateFoldersAsOptions(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    async function getTemplate() {
      const resp = await fetchEoEmailTemplate(dispatch, eoEmailTemplate.id);

      setEoEmailTemplate(resp);
      setFetchingState('finished');
    }

    tableRowData ? getTemplate() : setFetchingState('finished');
  }, [dispatch]);

  const onRemoveEmailTemplate = (id) => async () => {
    await deleteEoEmailTemplates(dispatch, [id]);

    onClose();
  };

  async function handleSave(eoEmailTemplateFormData) {
    const resp = eoEmailTemplate
      ? await updateEoEmailTemplate(dispatch, eoEmailTemplateFormData)
      : await createEoEmailTemplate(dispatch, eoEmailTemplateFormData);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      return resp;
    }

    return resp;
  }

  return (
    fetchingState === 'finished' && (
      <EoEmailTemplateFormPopupComponent
        currentUser={currentUser}
        eoEmailTemplate={eoEmailTemplate}
        immutableEoEmailTemplateFoldersOptions={eoCmarketerEmailTemplateFoldersOptions}
        immutableBrandsOptions={brandsOptions}
        isReadOnly={!canUserEditTemplate}
        onClose={onClose}
        onSave={handleSave}
        handleRemove={onRemoveEmailTemplate}
        dispatchGlobal={dispatch}
      />
    )
  );
};

EoEmailTemplateFormPopupContainer.propTypes = {
  brandsOptions: optionsList,
  currentUser: userShape,
  dispatch: PropTypes.func,
  eoCmarketerEmailTemplateFoldersOptions: optionsList,
  onClose: PropTypes.func,
  tableRowData: eoEmailTemplateShape,
};

const selector = (state) => {
  const options = optionsSelector(state);

  const currentUser = state.getIn(['railsContext', 'cuser']);

  // hotfix for broken specs
  const blankList = iList();

  return {
    currentUser,
    brandsOptions: options.get('brandsOptions'),
    eoCmarketerEmailTemplateFoldersOptions: options.get(
      'eoCmarketerEmailTemplateFolders',
      blankList,
    ),
  };
};

export default compose(ConnectStoreHOC, connect(selector))(EoEmailTemplateFormPopupContainer);
