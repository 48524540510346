import React from 'react';
import Datetime from 'react-datetime';
import cx from 'classnames';

import './timePicker.scss';

const TimePickerComponent = ({ inputProps, className, isDisabled, ...props }) => {
  function handleMouseDown(e) {
    const rdtBtn = e.target.parentElement;

    if (rdtBtn.classList.contains('rdtOpen')) {
      rdtBtn.classList.remove('rdtOpen');
    } else {
      rdtBtn.classList.add('rdtOpen');
    }
  }

  const classNames = cx('time-picker', className);

  return (
    <Datetime
      {...props}
      inputProps={{
        readOnly: true,
        disabled: isDisabled,
        onMouseDown: handleMouseDown,
        ...inputProps,
      }}
      className={classNames}
      dateFormat={false}
    />
  );
};

export default TimePickerComponent;
