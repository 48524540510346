import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';

import EoDomainsPageComponent from './EoDomainsPageComponent';

import Breadcrumbs from 'components/NewBreadcrumbs';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { translate } from 'common/i18n';

import { pagyShape } from 'common/prop_types_shapes';
import { eoDomainsPagySelector } from 'selectors/eoDomainsSelector';
import { policiesSelector } from 'selectors/railsContextSelectors';

const EoDomainsPageContainer = ({ canCreate, changeQueryParams, eoDomainsPagy, queryParams }) => {
  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('eoDomain.breadcrumbsTitle')}</Breadcrumbs.TextItem>
      </Breadcrumbs>
      <div className="page__wrapper">
        <EoDomainsPageComponent
          canCreate={canCreate}
          onItemsSelectChange={handleItemsSelectChange}
          onPagesPagerClick={handlePagesPagerClick}
          eoDomainsPagy={eoDomainsPagy}
        />
      </div>
    </>
  );
};

EoDomainsPageContainer.propTypes = {
  canCreate: PropTypes.bool,
  eoDomainsPagy: pagyShape,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoDomainsPagy = eoDomainsPagySelector(state);
  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);

  return {
    canCreate,
    eoDomainsPagy,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(EoDomainsPageContainer);
