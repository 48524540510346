import { updateProspectSuccess } from 'actions/prospectsActions';

const handleProspectsEmailValidity = (dispatch, data) => {
  const { prospectEmailValidity } = data;

  if (prospectEmailValidity) {
    dispatch(updateProspectSuccess(data));
  }
};

export default handleProspectsEmailValidity;
