import React from 'react';
import PropTypes from 'prop-types';

import PageCreateButton from '../PageCreateButton';

import { translate } from 'common/i18n';

import { policiesShape } from 'common/prop_types_shapes';

import ButtonComponent from 'components_linkio/button_component';
import DownloadIcon from 'common/icons/download';
import SearchInput from 'components/SearchInput';

import './pagesBulkActions.scss';

const PagesBulkActionsComponent = ({
  hasPages,
  onClickExport,
  onPageCreateButtonClick,
  onSubmitSearch,
  pageChildType,
  policies,
  searchTerm,
}) => {
  return (
    <>
      <div className="bulk-brand-pages-component">
        {pageChildType === 'competitorsPage' && (
          <span className="competitors-page-title">{translate(`competitorsPage.title`)}</span>
        )}
        <span className="bulk-brand-pages-component__actions">
          <SearchInput withDefaultValue defaultValue={searchTerm} onSubmit={onSubmitSearch} />
          <span className="bulk-brand-pages-component__actions_right-section">
            {hasPages && (
              <ButtonComponent
                className="bulk-brand-pages-component__csv-export-button"
                isBlue
                onClick={onClickExport}
              >
                <DownloadIcon className="bulk-brand-pages-component__csv-export-icon" />
                {translate(`${pageChildType}.destinationLink.buttons.exportCSV`)}
              </ButtonComponent>
            )}
            {pageChildType === 'competitorsPage' && (
              <div className="bulk-brand-pages-component__add-competitors-button">
                <PageCreateButton
                  onClick={onPageCreateButtonClick}
                  pageChildType={pageChildType}
                  policies={policies}
                />
              </div>
            )}
          </span>
        </span>
      </div>
    </>
  );
};

PagesBulkActionsComponent.propTypes = {
  hasPages: PropTypes.bool,
  onClickExport: PropTypes.func,
  onPageCreateButtonClick: PropTypes.func,
  onSubmitSearch: PropTypes.func.isRequired,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']),
  policies: policiesShape,
  searchTerm: PropTypes.string,
};

export default PagesBulkActionsComponent;
