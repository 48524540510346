import { createFilter } from 'components_linkio/DataFilter/utils';
import { translate } from 'common/i18n';

const eoProgressionStageFilterSpec = (options = []) => ({
  property: {
    value: 'stage',
    label: translate('uiComponents.dataFilters.filter.eoProgressionStage'),
  },
  predicate: {
    eq: options,
    eqAny: options,
    notEqAll: options,
    notEq: options,
  },
  mapToQuery(property, predicate, value) {
    switch (predicate) {
      case 'eqAny':
        return {
          stage_eq_any: value.map(({ value: itemValue }) => itemValue),
        };
      case 'eq':
        return {
          stage_eq: value.value,
        };
      case 'notEqAll':
        return {
          stage_not_eq_all: value.map(({ value: itemValue }) => itemValue),
        };
      case 'notEq':
        return {
          stage_not_eq_or_null: value.value,
        };
    }

    return {};
  },
  mapToFilter(queryProperty, queryValue = []) {
    switch (queryProperty) {
      case 'stage_eq_any': {
        const value = this.options.filter(({ value }) => queryValue.includes(value));
        return this.createFilter('eqAny', value);
      }
      case 'stage_eq': {
        const value = this.options.filter(({ value }) => value === queryValue)[0];
        return this.createFilter('eq', value);
      }
      case 'stage_not_eq_all': {
        const value = this.options.filter(({ value }) => queryValue.includes(value));
        return this.createFilter('notEqAll', value);
      }
      case 'stage_not_eq_or_null': {
        const value = this.options.filter(({ value }) => value === queryValue)[0];
        return this.createFilter('notEq', value);
      }
      default:
        return false;
    }
  },
  options,
  createFilter,
});

export const filtersSpec = ({ eoProgressionStageOptionsArray } = {}) => ({
  stage: eoProgressionStageFilterSpec(eoProgressionStageOptionsArray),
});

export const defaultFilters = ({ eoProgressionStageOptionsArray } = {}) => [
  eoProgressionStageFilterSpec(eoProgressionStageOptionsArray).createFilter('eq', null),
];
