import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'lodash/fp';

import BrandChartsComponent from './BrandChartsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { CHARTS_DEFAULT_DATE_RANGE } from 'utils/constants';
import { currentIdSelector } from 'selectors';
import { brandChartMetricsMap } from 'common/propTypesShapes/metrics';
import { brandChartMetricsByBrandIdSelector } from 'selectors/brandChartsSelector';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';
import { fetchBrandsChartMetrics } from 'api/brandsCharts';

const BrandChartsContainer = ({
  brandChartMetrics,
  brandId,
  canUseMetricsBySubscription,
  dispatch,
  queryParams: { filters = {} },
}) => {
  const dateStart = filters.dateStart || CHARTS_DEFAULT_DATE_RANGE.dateStart;
  const dateEnd = filters.dateEnd || CHARTS_DEFAULT_DATE_RANGE.dateEnd;

  function fetchData() {
    fetchBrandsChartMetrics(dispatch, [brandId], dateStart, dateEnd);
  }

  React.useEffect(() => {
    fetchData();
  }, [canUseMetricsBySubscription, brandId, dateStart, dateEnd]);

  return (
    <>
      {brandChartMetrics.get('brandId') && (
        <BrandChartsComponent brandChartMetrics={brandChartMetrics.toJS()} />
      )}
    </>
  );
};

function select(state, ownProps) {
  const brandId = Number(ownProps.brandId || currentIdSelector(state, ownProps));

  const brandChartMetrics = brandChartMetricsByBrandIdSelector(state, brandId);
  const canUseMetricsBySubscription = subscriptionPoliciesSelector(state).getIn([
    'metrics',
    'canUse',
  ]);
  return {
    brandChartMetrics,
    brandId,
    canUseMetricsBySubscription,
  };
}

BrandChartsContainer.propTypes = {
  brandChartMetrics: brandChartMetricsMap,
  brandId: PropTypes.number,
  canUseMetricsBySubscription: PropTypes.bool,
  dispatch: PropTypes.func,
  ...withQueryParamsPropTypes,
};

export default compose(
  withQueryParams,
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(BrandChartsContainer);
