import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';

import './manuallyImportPagesPopup.scss';

const ManuallyImportPagesPopupComponent = ({
  onAddPagesClick,
  onCancelClick,
  onPagesListChange,
  pagesList,
}) => {
  return (
    <Modal show className="manually-import-pages__modal">
      <Modal.Header>
        <Modal.Title>{translate('bulkPagesSetup.manuallyImportPagesPopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{translate('bulkPagesSetup.manuallyImportPagesPopup.subtitle')}</p>

        <InputComponent
          className="manually-import-pages__textarea"
          onInputChange={onPagesListChange}
          isMultiline
          message={{
            text: translate('bulkPagesSetup.manuallyImportPagesPopup.hint'),
            type: 'hint',
          }}
          value={pagesList}
        />
      </Modal.Body>

      <Modal.Footer>
        <div className="manually-import-pages__btn-group">
          <div className="manually-import-pages__btn">
            <ButtonComponent isWhite onClick={onCancelClick}>
              {translate('bulkPagesSetup.manuallyImportPagesPopup.buttons.cancel')}
            </ButtonComponent>
          </div>
          <div className="manually-import-pages__btn">
            <ButtonComponent isGreen onClick={onAddPagesClick} isDisabled={!pagesList}>
              {translate('bulkPagesSetup.manuallyImportPagesPopup.buttons.addPages')}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ManuallyImportPagesPopupComponent.propTypes = {
  onAddPagesClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onPagesListChange: PropTypes.func.isRequired,
  pagesList: PropTypes.string,
};

export default ManuallyImportPagesPopupComponent;
