import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ahrefsConsts } from 'common/constants';
import { marketerShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import RadioButton from 'components_linkio/RadioButton';

import './ahrefsSettingsEdit.scss';

class AhrefsSettingsEditComponent extends Component {
  static propTypes = {
    currentMarketer: marketerShape,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { currentMarketer } = this.props;
    const { defaultSyncInterval } = ahrefsConsts;

    this.state = {
      selectedOpt: currentMarketer.get('ahrefs_sync_interval') || defaultSyncInterval,
    };
  }

  handleCancel = () => this.props.onCancel();
  handleSubmit = () => this.props.onSubmit(this.state.selectedOpt);
  handleOptsChange = (event) => this.setState({ selectedOpt: event.target.value });

  radioBtn = (valueText) => {
    const { selectedOpt } = this.state;

    return (
      <RadioButton
        className="import-popup-component__radio-btn-item"
        checked={selectedOpt === valueText}
        onChange={this.handleOptsChange}
        value={valueText}
      >
        {translate(`options.ahrefsSyncInterval.${valueText}.label`)}
      </RadioButton>
    );
  };

  render() {
    return (
      <Modal show bsSize="small">
        <Modal.Header>
          <Modal.Title>{translate('editAhrefsConnectionSettings.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ahrefs-connections-edit-settings-component__form">
            <ul>
              <li>{this.radioBtn('daily')}</li>
              <li>{this.radioBtn('each3Days')}</li>
              <li>{this.radioBtn('weekly')}</li>
              <li>{this.radioBtn('monthly')}</li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="ahrefs-connections-edit-settings-component__btn-group">
            <div className="ahrefs-connections-edit-settings-component__btn">
              <ButtonComponent isWhite onClick={this.handleCancel}>
                {translate('editAhrefsConnectionSettings.buttons.cancel')}
              </ButtonComponent>
            </div>
            <div className="import-popup-component__btn">
              <ButtonComponent isGreen onClick={this.handleSubmit}>
                {translate('editAhrefsConnectionSettings.buttons.submit')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AhrefsSettingsEditComponent;
