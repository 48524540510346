import React from 'react';

const FilterIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M2.43 5.638V4.162h9.334v1.476H2.43zM.097.4h14v1.512h-14V.4zm5.432 9V7.888h3.136V9.4H5.53z" />
    </svg>
  );
};

FilterIcon.defaultProps = {
  fill: "#ffffff",
  height: '9px',
  width: '14px',
};

export default FilterIcon;
