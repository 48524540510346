import React from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';

import EoDomainFormPopup from '../EoDomainsBulkActions/EoDomainFormPopup';

import columnsGenerator from 'common/tables/columns_generator';

import { buildSelectedItems } from 'common/utils';
import { checkboxColumn, textColumn } from 'common/tables/columns';
import { eoDomainsList } from 'common/propTypesShapes/eoDomains';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import Checkbox from 'components_linkio/Checkbox';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';

import './eoDomainsTable.scss';

const EoDomainsTableComponent = ({
  canUpdate,
  onTableHeaderClick,
  onToggleSelectedRows,
  eoDomains,
  eoDomainsCount,
  selectedIds,
  sorting,
}) => {
  const processedEoDomains = buildSelectedItems(eoDomains.toJS(), selectedIds);

  const headerValueFormatter = (options = {}) => (_value, extra) => {
    const { sortBy, sortingOrder } = sorting;
    const { withExplanationMessage } = options;
    const { property } = extra;

    const label = translate(`eoDomain.table.headers.${property}`);

    return (
      <HeaderFieldWithSorting
        appModule="eoDomains"
        label={label}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  };

  const prospectsValueFormatter = (prospects) => {
    const title = prospects.length > 3 ? prospects.map(({ email }) => email).join(', ') : '';

    return (
      <div className="text_two-lines-with-ellipsis-break-word" title={title}>
        {prospects.map(({ bounced, email }, i) => {
          const needComma = i + 1 !== prospects.length;

          return (
            <React.Fragment key={email}>
              <span className={bounced ? 'text_red' : ''}>{email}</span>
              {needComma && ', '}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const handleOnToggleSelectRow = (eoDomainId) => (event) => {
    const isSelected = event.target.checked;

    onToggleSelectedRows([eoDomainId], isSelected);
  };

  function handleOnToggleSelectAll(event) {
    const isSelectedAll = event.target.checked;

    const eoDomainsIds = processedEoDomains.map((eoDomain) => eoDomain.id);

    onToggleSelectedRows(eoDomainsIds, isSelectedAll);
  }

  function isAllSelected() {
    const selectedRowsCount = processedEoDomains.filter((eoDomain) => eoDomain.selected).length;
    const rowsCount = processedEoDomains.length;

    return rowsCount > 0 ? rowsCount === selectedRowsCount : false;
  }

  function cellCheckboxFormatters(_value, extra) {
    const {
      rowData: { selected, id },
    } = extra;

    return (
      <Checkbox
        checked={selected}
        className="eo-domains-table__checkbox"
        onChange={handleOnToggleSelectRow(id)}
      />
    );
  }

  function headerCheckboxFormatters() {
    return (
      <Checkbox
        checked={isAllSelected()}
        className="eo-domains-table__checkbox"
        onChange={handleOnToggleSelectAll}
      />
    );
  }

  function generateColumns() {
    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [headerCheckboxFormatters],
        cellFormatters: [cellCheckboxFormatters],
        className: 'eo-domains-table__checkbox-column',
      }),
      textColumn({
        name: 'domain',
        headerValueFormatter: headerValueFormatter(),
        Popup: canUpdate && EoDomainFormPopup,
        className: 'eo-domains-table__domain-column',
        isEditable: canUpdate,
      }),
      textColumn({
        name: 'prospects',
        headerLabel: translate('eoDomain.table.headers.prospects'),
        cellValueFormatter: prospectsValueFormatter,
      }),
    ]);
  }

  const columns = generateColumns();

  return (
    <>
      {eoDomainsCount > 0 && <p>{translate('eoDomain.table.eoDomainsCount')(eoDomainsCount)}</p>}
      <Table.Provider className="eo-domains-table" columns={columns}>
        <Table.Header />
        <Table.Body rowKey="id" rows={processedEoDomains} />
      </Table.Provider>
    </>
  );
};

EoDomainsTableComponent.propTypes = {
  canUpdate: PropTypes.bool,
  eoDomains: eoDomainsList,
  eoDomainsCount: PropTypes.number,
  onTableHeaderClick: PropTypes.func,
  onToggleSelectedRows: PropTypes.func,
  selectedIds: selectedIdsList,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortingOrder: PropTypes.string,
  }),
};

export default EoDomainsTableComponent;
