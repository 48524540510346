import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ImportDlsButtonComponent from './ImportDlsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandPageShape } from 'common/prop_types_shapes';
import { openImportDlsPopup } from 'actions/popup_actions';
import { visiblePageIdByParentSelector } from 'selectors';

class ImportDlsButtonContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    page: brandPageShape,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // eslint-disable-line react/no-unused-prop-types
    pageParentType: PropTypes.oneOf(['brand_page', 'brand']).isRequired, // eslint-disable-line react/no-unused-prop-types
  };

  handleImportClick = () => {
    const { dispatch, pageChildType, page } = this.props;

    dispatch(openImportDlsPopup({ page, pageChildType }));
  };

  render() {
    return <ImportDlsButtonComponent onImportClick={this.handleImportClick} />;
  }
}

function select(state, ownProps) {
  const brandPages = state.get('brandPages', iMap());
  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);
  const page = brandPages.get(String(visiblePageId), iMap());

  return {
    page,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(ImportDlsButtonContainer)));
