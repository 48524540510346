import React from 'react';
import PropTypes from 'prop-types';

import './dlsRedistributingStatusNotifierComponent.scss';

import { translate } from 'common/i18n';

const DlsRedistributingStatusNotifierComponent = ({ status, onReloadDls }) => {
  const inProgress = status !== 'not_started';
  const notificationText =
    status === 'completed'
      ? translate('dlsRedistributingStatusNotifierComponent.newSuggestionsMessage')
      : translate('dlsRedistributingStatusNotifierComponent.suggestionsAreGenerated');

  return (
    <>
      {inProgress && (
        <div className="dls-redistributing-status-notifier-component">
          {notificationText}
          {translate(
            'dlsRedistributingStatusNotifierComponent.suggestionsUpdateInvitation.beginning',
          )}
          <a className="text_link" href="" onClick={onReloadDls}>
            {translate(
              'dlsRedistributingStatusNotifierComponent.suggestionsUpdateInvitation.linkText',
            )}
          </a>
          {translate('dlsRedistributingStatusNotifierComponent.suggestionsUpdateInvitation.ending')}
        </div>
      )}
    </>
  );
};

DlsRedistributingStatusNotifierComponent.propTypes = {
  onReloadDls: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default DlsRedistributingStatusNotifierComponent;
