import { handleAPIError, getFromAPI } from './utils';

import { translate } from 'common/i18n';

import {
  fetchAnchorSamplesFailure,
  fetchAnchorSamplesStart,
  fetchAnchorSamplesSuccess,
} from 'actions/anchorSamplesActions';

async function fetchAnchorSamples(dispatch, brandPageId) {
  dispatch(fetchAnchorSamplesStart());

  try {
    const resp = await getFromAPI(
      Routes.brand_page_anchor_samples_destination_links_records_path(),
      { brand_page_id: brandPageId },
    );
    dispatch(fetchAnchorSamplesSuccess(resp.anchor_samples));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchAnchorSamplesFailure,
      translate('errors.cantFetchAnchorSamples'),
    );
  }
}

export { fetchAnchorSamples };
