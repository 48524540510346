import React from 'react';
import PropTypes from 'prop-types';

import DataFilter from 'components_linkio/DataFilter';
import AddProspectButton from './AddProspectButton';
import DeleteProspectsButton from './DeleteProspectsButton';
import ImportProspectsButton from './ImportProspectsButton';
import ProspectActionsSelect from './ProspectActionsSelect';
import SearchProspectsInput from './SearchProspectsInput';

import { filtersSpec, defaultFilters } from './filtersSpec';

import './prospectsBulkActions.scss';

const ProspectsBulkActionsComponent = ({
  canCreate,
  eoCampaignsFilterOptions,
  eoDriplistsFilterOptions,
  hideDataFilter,
}) => {
  const eoProspectsFilterCampaignOptions = {
    field: 'campaign',
    options: eoCampaignsFilterOptions,
  };

  const eoProspectsFilterDripListOptions = {
    field: 'dripLists',
    options: eoDriplistsFilterOptions,
  };

  return (
    <div className="prospects-bulk-actions">
      <div className="prospects-bulk-actions__row">
        <div>
          <SearchProspectsInput />
          {!hideDataFilter && (
            <>
              <span className="vertical-separator" />
              <DataFilter
                defaultFilters={defaultFilters([
                  eoProspectsFilterCampaignOptions,
                  eoProspectsFilterDripListOptions,
                ])}
                filtersSpec={filtersSpec([
                  eoProspectsFilterCampaignOptions,
                  eoProspectsFilterDripListOptions,
                ])}
                eqIsDefaultPredicate
              />
            </>
          )}
          {canCreate && (
            <>
              <span className="vertical-separator" />
              <DeleteProspectsButton />
              <span className="vertical-separator" />
              <ProspectActionsSelect />
            </>
          )}
        </div>
        {canCreate && (
          <div className="prospects-bulk-actions__btn-group">
            <ImportProspectsButton />
            <AddProspectButton />
          </div>
        )}
      </div>
    </div>
  );
};

ProspectsBulkActionsComponent.propTypes = {
  canCreate: PropTypes.bool,
  eoCampaignsFilterOptions: PropTypes.arrayOf(PropTypes.shape),
  eoDriplistsFilterOptions: PropTypes.arrayOf(PropTypes.shape),
  hideDataFilter: PropTypes.bool,
};

export default ProspectsBulkActionsComponent;
