const filterSubscriptionPlans = (subscriptionPlans, selectedCostType, conditions = {}) => {
  const costTypeFilterValue = selectedCostType === 'monthly' ? 'monthly' : 'yearly';
  const billingIntervalFilter = (plan) => plan.get('billingInterval') === costTypeFilterValue;
  const paidAttrFilter = (plan) => (conditions.paidOnly ? plan.get('paid') : true);

  return subscriptionPlans
    .filter((plan) => paidAttrFilter(plan) && billingIntervalFilter(plan))
    .sortBy((plan) => Number(plan.get('price')));
};

const subscriptionPlanIsForbiddenToSwitchTo = (availability) => {
  const isAvailable = availability.get('isAvailable');
  const rejectionReason = availability.get('rejectionReason');

  return !isAvailable && rejectionReason === 'the_same_plan';
};

export { filterSubscriptionPlans, subscriptionPlanIsForbiddenToSwitchTo };
