export default {
  subscriptionLimitRibbon: {
    ifYouWantToAddMore: 'if you want to add more.',
    maxBrandLimitReached: 'The maximum number of brands was reached. ',
    maxCrawlsReached: 'The maximum number of crawls was reached. ',
    maxTrackedKeywordsCountLimitReached: 'The maximum number of tracked keywords was reached. ',
    message: (value, plural = false) =>
      `${value} ${plural ? 'are' : 'is'} not available for your subscription plan. `,
    contactEmailLink: {
      text: 'Write us at ',
      link: 'info@linkio.com',
    },
    upgradePopupLink: {
      link: 'upgrade to unlock.',
      text: 'Please, ',
    },
    limited: {
      ahrefs: 'Ahrefs Automation and Categorization',
      ahrefsImport: 'Import via Ahrefs',
      categorization: 'Categorization',
      charts: 'Charts',
      crawling: 'Crawling',
      eoInboxesSettings: 'Inboxes settings',
      exportCsv: 'Export CSV',
      metrics: 'Metrics',
      newsletterSettings: 'Notifications settings',
    },
  },
};
