import pluralize from 'pluralize';

export default {
  dlsStatusBarComponent: {
    linksCount: (args) => {
      const { dlsCount, tab } = args;

      switch (tab) {
        case 'backlinks':
          return `${pluralize('Backlink', dlsCount, true)}`;
        case 'suggestions':
          return `${dlsCount} Not Started ${pluralize('Link', dlsCount)} to Build`;
        case 'in_progress':
          return `${dlsCount} Started ${pluralize('Link', dlsCount)} to Build`;
        default:
          return '';
      }
    },
  },
};
