export default {
  dlExportCsvPopup: {
    title: 'Export Pages',
    tableColumns: {
      pages: 'Pages',
    },
    buttons: {
      cancel: 'Cancel',
      export: 'Export',
    },
  },
};
