import React from 'react';
import PropTypes from 'prop-types';
import { isSameDay, isSameMonth, isWithinInterval, format } from 'date-fns';
import cx from 'classnames';

import buildDaysOfMonthMatrix from 'utils/buildDaysOfMonthMatrix';

import './calendar.scss';

class CalendarComponent extends React.Component {
  handleDateClick = (day) => () => this.props.onDateClick(day);

  renderDay = (day) => {
    const {
      selectedDateRange: [startRange, endRange],
      month,
    } = this.props;

    const dayClasses = cx('calendar__day', {
      calendar__day_disabled: !isSameMonth(day, month),
      calendar__day_selected: isWithinInterval(day, {
        start: startRange,
        end: endRange,
      }),
      'calendar__day_start-range': isSameDay(day, startRange),
      'calendar__day_end-range': isSameDay(day, endRange),
    });

    return (
      <div className={dayClasses} key={day} onClick={this.handleDateClick(day)} aria-label={format(day, 'yyyy-MM-dd')}>
        {format(day, 'd')}
      </div>
    );
  };

  renderWeek = (days, idx) => {
    return (
      <div className="calendar__week" key={idx}>
        {days.map(this.renderDay)}
      </div>
    );
  };

  render() {
    const { month } = this.props;

    const monthMatrix = buildDaysOfMonthMatrix(month);

    return <div className="calendar">{monthMatrix.map(this.renderWeek)}</div>;
  }
}

CalendarComponent.propTypes = {
  month: PropTypes.instanceOf(Date).isRequired,
  onDateClick: PropTypes.func.isRequired,
  selectedDateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
};

export default CalendarComponent;
