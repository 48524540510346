import pluralize from 'pluralize';

export default {
  dlsFilter: {
    buttons: {
      filter: (numberOfDlsFilterQueries) => {
        if (numberOfDlsFilterQueries > 0) {
          return `${pluralize('filter', numberOfDlsFilterQueries)}`;
        }
        return 'Filter';
      },
      addFilter: '+ Add Filter',
    },
    emptyFilter: 'No filters applied to this view',
    predicates: {
      is: 'Is...',
      isNot: 'Is not...',
      isAnyOf: 'Is any of...',
      isNonOf: 'Is none of...',
      isEmpty: 'Is empty',
      isNotEmpty: 'Is not empty',
    },
    rowsGrouping: {
      and: 'And',
      or: 'Or',
      where: 'Where',
    },
    yesOrNoSelector: {
      yes: 'Yes',
      no: 'No',
    },
    indexed: 'Indexed',
    isFound: 'Found',
    disavow: 'Disavow',
  },
};
