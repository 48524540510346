import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import cx from 'classnames';
import EoEmailTemplateFormPopup from 'pages/EoEmailTemplatesPage/EoEmailTemplatesBulkActions/EoEmailTemplateFormPopup';

import { translate } from 'common/i18n';

import './EoCampaignEmailTemplatesList.scss';
import { eoEmailTemplateShape } from 'common/propTypesShapes/eoEmailTemplates';
import { fetchEoEmailTemplate } from 'api/eoEmailTemplate';

import { optionsList } from 'common/prop_types_shapes';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const EoCampaignEmailTemplatesListComponent = ({
  dispatch,
  eoEmailTemplates,
  immutableEoEmailTemplateFoldersOptions,
  selectedEmailTemplateId,
  setSelectedEmailTemplateId,
}) => {
  const eoEmailTemplateFoldersOptions = immutableEoEmailTemplateFoldersOptions.toJS();

  const selectTemplate = (id) => () => {
    setSelectedEmailTemplateId(id);

    fetchEoEmailTemplate(dispatch, id);
  };

  const buildEoEmailTemplatesFolderMap = (folderId, folderName) => {
    return (
      <>
        <div className="eo-campaign-email-templates-list__folder-title">{folderName}</div>
        <ul className="eo-campaign-email-templates-list__templates-list">
          {eoEmailTemplates
            .filter(({ eoEmailTemplateFolderId }) => eoEmailTemplateFolderId === folderId)
            .map((template) => {
              const { id: templateId, title: templateName } = template;
              const templateClassNames = cx('eo-campaign-email-templates-list__entries', {
                'eo-campaign-email-templates-list__selected':
                  templateId === selectedEmailTemplateId,
              });

              return (
                <li
                  key={templateId}
                  className={templateClassNames}
                  onClick={selectTemplate(templateId)}
                >
                  {templateName}
                </li>
              );
            })}
        </ul>
      </>
    );
  };

  return (
    <div className="eo-campaign-email-templates-list">
      <ToggleShowPopup Popup={<EoEmailTemplateFormPopup />}>
        <div className="eo-campaign-email-templates-list__create-template">
          {translate(
            'eoCampaignStep.eoCampaignStepEmail.eoCampaignEmailTemplatesPopup.createButton',
          )}
        </div>
      </ToggleShowPopup>

      <ul className="eo-campaign-email-templates-list__folders-list">
        {eoEmailTemplateFoldersOptions.map(({ value: folderId, label: folderName }) => {
          return (
            <li key={folderId} className="eo-campaign-email-templates-list__folder">
              {buildEoEmailTemplatesFolderMap(folderId, folderName)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

EoCampaignEmailTemplatesListComponent.propTypes = {
  dispatch: PropTypes.func,
  eoEmailTemplates: PropTypes.arrayOf(eoEmailTemplateShape),
  immutableEoEmailTemplateFoldersOptions: optionsList,
  selectedEmailTemplateId: PropTypes.number,
  setSelectedEmailTemplateId: PropTypes.func,
};

export default ConnectStoreHOC(connect()(EoCampaignEmailTemplatesListComponent));
