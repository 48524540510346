const FETCH_SUBSCRIPTION__FAILURE = 'FETCH_SUBSCRIPTION__FAILURE';
const FETCH_SUBSCRIPTION__START = 'FETCH_SUBSCRIPTION__START';
const FETCH_SUBSCRIPTION__SUCCESS = 'FETCH_SUBSCRIPTION__SUCCESS';

function fetchSubscriptionStart() {
  return { type: FETCH_SUBSCRIPTION__START };
}

function fetchSubscriptionSuccess(currentSubscription) {
  return { type: FETCH_SUBSCRIPTION__SUCCESS, currentSubscription };
}

function fetchSubscriptionFailure(errors) {
  return { type: FETCH_SUBSCRIPTION__FAILURE, errors };
}

export {
  FETCH_SUBSCRIPTION__FAILURE,
  FETCH_SUBSCRIPTION__START,
  FETCH_SUBSCRIPTION__SUCCESS,
  fetchSubscriptionFailure,
  fetchSubscriptionStart,
  fetchSubscriptionSuccess,
};
