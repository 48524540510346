import React from 'react';
import PropTypes from 'prop-types';

import { pagerTypeShape, pagyShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import Select from 'components_linkio/Select';

const PagerItemsSelectComponent = ({ handleItemsSelectChange, pagerType, pagy }) => {
  const options = [
    { value: 10, label: translate(`pager.${pagerType}.items10`) },
    { value: 50, label: translate(`pager.${pagerType}.items50`) },
    { value: 100, label: translate(`pager.${pagerType}.items100`) },
  ];

  const items = pagy.get('items');

  return (
    <Select
      className="pager-items-select"
      blurInputOnSelect
      isClearable={false}
      menuPlacement="top"
      onChange={handleItemsSelectChange}
      options={options}
      triggerChangeOnBlur={false}
      value={options.find((option) => Number(items) === Number(option.value))}
    />
  );
};

PagerItemsSelectComponent.propTypes = {
  handleItemsSelectChange: PropTypes.func,
  pagerType: pagerTypeShape.isRequired,
  pagy: pagyShape,
};

export default PagerItemsSelectComponent;
