import React from 'react';
import PropTypes from 'prop-types';

import ProspectsTable from './ProspectsTable';
import ProspectsBulkActions from './ProspectsBulkActions';
import ProspectTabs from './ProspectTabs';

import { pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';

const ProspectsPageComponent = ({
  canCreate,
  onItemsSelectChange,
  onPagesPagerClick,
  prospectsPagy,
}) => {
  return (
    <div className="prospects">
      <ProspectTabs />

      <ProspectsBulkActions canCreate={canCreate} />

      <ProspectsTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="rowsPager"
        pagy={prospectsPagy}
      />
    </div>
  );
};

ProspectsPageComponent.propTypes = {
  canCreate: PropTypes.bool,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
  prospectsPagy: pagyShape,
};

export default ProspectsPageComponent;
