import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoProgressionsBulkActionsComponent from './EoProgressionsBulkActionsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector, optionsSelector } from 'selectors';
import { eoProgressionsList } from 'common/propTypesShapes/eoProgressions';
import { eoProgressionsByCampaignIdSelector } from 'selectors/eoProgressionsSelector';
import { optionsList } from 'common/prop_types_shapes';
import { policiesSelector } from 'selectors/railsContextSelectors';

import './eoProgressionsBulkActions.scss';

const EoProgressionsBulkActionsContainer = ({
  canCreate,
  eoProgressions,
  eoProgressionStageOptions,
  queryParams,
}) => {
  const hasEoProgressions = eoProgressions.size > 0;
  const hideDataFilter = !queryParams.filters && !hasEoProgressions;

  return (
    <EoProgressionsBulkActionsComponent
      canCreate={canCreate}
      hasEoProgressions={hasEoProgressions}
      hideDataFilter={hideDataFilter}
      eoProgressionStageOptions={eoProgressionStageOptions}
    />
  );
};

EoProgressionsBulkActionsContainer.propTypes = {
  canCreate: PropTypes.bool,
  eoProgressionStageOptions: optionsList,
  eoProgressions: eoProgressionsList,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoProgressions = eoProgressionsByCampaignIdSelector(state, eoCampaignId);

  const options = optionsSelector(state);
  const eoProgressionStageOptions = options.get('eoProgressionStageOptions');

  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);

  return {
    canCreate,
    eoProgressions,
    eoProgressionStageOptions,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoProgressionsBulkActionsContainer);
