const disavow = {
  disavowPopup: {
    title: "Disavow File",
    uploadHintMessage: "This will overwrite any existing file",
    fileMetadata: {
      firstLine: (value) => `Result from the submission on ${value.date}.`,
      secondLine: (value) => {
        return (
          `You successfully uploaded a disavow links file (${value.name})
          containing ${value.domainsCount} domains ${value.linksCount} URLs.`
        );
      }
    },
    new: {
      subtitle:
        `If you have disavowed links through Google, upload the same file here.
        This will keep your percentages accurate. `,
    },
    edit: {
      subtitle: "The file below contains the list of disavow links. To edit the list, first dowload the file",
    },
    googleToolsLink: {
      href: "https://www.google.com/webmasters/tools/disavow-links-main",
      text: "Click here to access Google's tool",
    },
    inputs: {
      file: "Upload new disavow file",
    },
    buttons: {
      cancel: "Cancel",
      chooseFile: "Choose File",
      download: "Download",
      submit: "Submit",
    },
    errors: {
      fileIsNotChosen: "File is not chosen"
    }
  },
};

export default disavow;
