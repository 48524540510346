const FETCH_USERS_AS_OPTIONS__FAILURE = 'FETCH_USERS_AS_OPTIONS__FAILURE';
const FETCH_USERS_AS_OPTIONS__START = 'FETCH_USERS_AS_OPTIONS__START';
const FETCH_USERS_AS_OPTIONS__SUCCESS = 'FETCH_USERS_AS_OPTIONS__SUCCESS';
const UPDATE_CURRENT_USER__FAILURE = 'UPDATE_CURRENT_USER__FAILURE';
const UPDATE_CURRENT_USER__START = 'UPDATE_CURRENT_USER__START';
const UPDATE_CURRENT_USER__SUCCESS = 'UPDATE_CURRENT_USER__SUCCESS';

function fetchUsersAsOptionsStart() {
  return { type: FETCH_USERS_AS_OPTIONS__START };
}

function fetchUsersAsOptionsSuccess(users) {
  return { type: FETCH_USERS_AS_OPTIONS__SUCCESS, users };
}

function fetchUsersAsOptionsFailure(errors) {
  return { type: FETCH_USERS_AS_OPTIONS__FAILURE, errors };
}

function updateCurrentUserStart() {
  return { type: UPDATE_CURRENT_USER__START };
}

function updateCurrentUserSuccess(currentUser) {
  return { type: UPDATE_CURRENT_USER__SUCCESS, currentUser };
}

function updateCurrentUserFailure(errors) {
  return { type: UPDATE_CURRENT_USER__FAILURE, errors };
}

export {
  FETCH_USERS_AS_OPTIONS__FAILURE,
  FETCH_USERS_AS_OPTIONS__START,
  FETCH_USERS_AS_OPTIONS__SUCCESS,
  UPDATE_CURRENT_USER__FAILURE,
  UPDATE_CURRENT_USER__START,
  UPDATE_CURRENT_USER__SUCCESS,
  fetchUsersAsOptionsStart,
  fetchUsersAsOptionsSuccess,
  fetchUsersAsOptionsFailure,
  updateCurrentUserFailure,
  updateCurrentUserStart,
  updateCurrentUserSuccess,
};
