import React from 'react';
import PropTypes from 'prop-types';

import EoCustomFieldsTable from './EoCustomFieldsTable';
import EoCustomFieldsBulkActions from './EoCustomFieldsBulkActions';
import ProspectTabs from 'pages/ProspectsPage/ProspectTabs';

import { pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';

const EoCustomFieldsComponent = ({ onItemsSelectChange, onPagesPagerClick, eoCustomFieldsPagy }) => {
  return (
    <div className="eo-merge-tags">
      <ProspectTabs />

      <EoCustomFieldsBulkActions />

      <EoCustomFieldsTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="rowsPager"
        pagy={eoCustomFieldsPagy}
      />
    </div>
  );
};

EoCustomFieldsComponent.propTypes = {
  eoCustomFieldsPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoCustomFieldsComponent;
