import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConnectStoreHOC from '../../../startup/connect_store_hoc';

import { translate } from '../../../common/i18n';

import {
  markAllNotificationsAsRead,
} from '../../../api/notification';


class NotificationsMarkAllAsReadContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  handleClick = (event) => {
    event.preventDefault();

    const { dispatch } = this.props;
    markAllNotificationsAsRead(dispatch);
  }

  render() {
    return (
      <a href="#" onClick={this.handleClick} className="notifications-mark-all-as-read">
        {translate('notifications.actions.markAllAsRead')}
      </a>
    );
  }
}


export default ConnectStoreHOC(connect()(NotificationsMarkAllAsReadContainer));
