export default {
  explanationMessages: {
    addPageModal: {
      city: 'City is used in anchor text generator in case your page is local',
      keywords: `Add your exact keywords for this page. These keywords are used to automatically \
      categorize your anchor text. Stick to just the exact keywords.`,
      pageUrl: `The page URL must match your brand. E.g. if your brand is https://example.com, the \
      following won't work: http instead of https, using "www" if the original URL doesn't have it \
      or using any other subdomain.`,
      pageType: `Select one of our predefined page types based on the page content and its function. \
      The page type impacts the anchor text percentages that will be assigned to this page.`,
      product: 'Product/Service is used in anchor text generator in case your page is local',
      state: 'State is used in anchor text generator in case your page is local',
      stateAbbr: 'State abbreviation is used in anchor text generator in case your page is local',
    },
    backlinksBulkActions: {
      crawlBacklinksBtn:
        'Crawl a brand existing backlink data to check if the links are live and indexed.',
    },
    backlinksTable: {
      ahrefsRank:
        'A metric from Ahrefs that shows how strong a backlink profile of a target URL is on a \
        scale from 1 to 100. Generally speaking, each page passes its rating score to all the pages that it links to.',
      ahrefsDomainRating:
        'A metric from Ahrefs that shows the strength of a given website’s overall backlink \
        profile. Generally speaking, each domain splits its rating equally amongst the domains it links to.',
      mozDomainAuthority:
        'DA or Domain Authority is a metric from Moz that ranges from 1 to 100, with higher \
        scores corresponding to a greater ability to rank. The more quality links you have, the higher the DA.',
      mozPageAuthority:
        'PA or Page Authority is a metric from Moz that ranges from 1 to 100, with higher \
        scores corresponding to a greater ability to rank. The more links a page has, the higher the PA.',
      mozSubdomainSpamScore:
        "Spam Score is a metric from Moz that represents the percentage of sites with \
        similar features they've found to be penalized or banned by Google. A high Spam Score for your site, \
        or a site you're looking at, doesn't mean this site is spammy. It's a sign that you should do some \
        more investigation into the quality and relevance of this site.",
    },
    brand: {
      otherNames: `A brand may have different spelling variations, e.g. 'Guns N' Roses',\
        'Guns and Roses' or 'Guns&Roses'.`,
    },
    brandsTable: {
      atpLink: 'Add pages, import backlinks, analyze the data and get anchor text suggestions.',
      crawlButton:
        'Crawl a brand(s) existing backlink data to check if the links are live and indexed.',
      disavowLinks: 'Upload your disavow file so we know what links to ignore during analysis.',
    },
    brandPagesList: {
      averageKeywordsRank: 'The arithmetic mean of ranking positions of brand page keywords.',
      competitorsCount: 'Number of competitors included into Anchor Text Planner.',
      keywords:
        'These keywords are used to automatically categorize your anchor text. Click edit \
        to add/edit keywords. These should only be exact keywords.',
      pageType:
        'The page type impacts the anchor text percentage targets that will be assigned \
        to this page. Click ‘Edit Page’ to change it. In the menu, click "Percentage" to review and \
        edit system settings.',
      priority:
        'Is one page more important than another? Use the Page Priority to help your team \
        stay organized (optional).',
      countReferringDomains:
        'Number of unique referring domains pointing to your page URL that count towards your \
        anchor text percentages because they are dofollow and indexed.',
      linkioScore:
        'Score from 0 to 100 that shows how close your anchor text percentages are to the ideal \
        target percentages. The closer you are to 100, the better.',
    },
    bulkPagesSetupTable: {
      uriAhrefsRating:
        'A metric from Ahrefs that shows how strong a backlink profile of a target \
        URL is on a scale from 1 to 100. Generally speaking, each page passes its rating score to all the pages that it links to.',
      uriRefdomainsCount:
        'The number of unique domains with a hyperlink pointing to the URL. Source: Ahrefs',
    },
    bulkCompetitorsSetupTable: {
      domainAhrefsRating:
        'A metric from Ahrefs that shows the strength of a given website’s overall backlink \
        profile. Generally speaking, each domain splits its rating equally amongst the domains it links to.',
      mozDomainAuthority:
        'DA or Domain Authority is a metric from Moz that ranges from 1 to 100, with higher \
        scores corresponding to a greater ability to rank. The more quality links you have, the higher the DA.',
      uriRefdomainsCount:
        'The number of unique domains with a hyperlink pointing to the URL. Source: Ahrefs',
      brandName: 'Enter comma separated list of brand names',
    },
    atpTable: {
      anchorType: "The category of the link's anchor. Can be 1 of 13 categories.",
      anchorText: 'Anchor text is the clickable text in your backlink.',
      targetWebsite: `You can use this column to plan which site you'll try to get a link from (optional).`,
      status: `Helpful for managing your workflow. Our crawler periodically checks links with \
      the "Published" status to keep your anchor text suggestions accurate.`,
      source: `Customizable list of sources for your links or data to help stay organized (optional).`,
      includeByUser: `Shows whether the backlink counts to your anchor text percentages. Nofollow, \
      no indexed, or not found links will automatically be excluded.`,
      publishedDate: `The date when the link was first seen. Automatically set via Ahrefs \
      import or by our crawler. You can set it manually as well.`,
      nofollow: `Dofollow/Nofollow. Automatically set via Ahrefs import or by our crawler. \
      You can set it manually as well.`,
      priority: 'Shows the order in which the anchor text should be built.',
      publishedLink: `URL which contains your backlink. Click the tags to see more details.`,
      crawlButton:
        'Crawl a brand page existing backlink data to check if the links are live and indexed.',
    },
    atpBulkAction: {
      selector: `Bulk actions for various columns. Select rows to activate the options.`,
      categorize: `Open this pop-up to automatically categorize your anchor text or make adjustments.`,
      import: `Import backlinks for this page through Ahrefs API, .csv, or via copy paste.`,
      anchorProgress: `Show/Hide the anchor text percentages panel.`,
      addAnchors: `Adds rows that tell you which anchors to build next based on existing \
      backlink data. The number you specify will be generated.`,
    },
    categorizePopup: {
      alternativePageUrl:
        'Specify this if you changed domain name previously and now there are \
        redirects from the old domain to the new one.',
      categorize: `If checked, this will re-categorize anchor text based on your changes above.`,
      city: 'City is used in anchor text generator in case your page is local',
      product: 'Product/Service is used in anchor text generator in case your page is local',
      state: 'State is used in anchor text generator in case your page is local',
      stateAbbr: 'State abbreviation is used in anchor text generator in case your page is local',
      title: `This is what's usually displayed in the top bar of internet browsers and in the \
      Google SERPs. You can add historical titles as well.`,
    },
    crawlerStatusBar: {
      waitTime: `If you imported backlinks, added Google search console file, crawled backlinks or \
        generated the suggestions, that's the approximate ETA as all these processes are in the same queue.`,
    },
    importPopup: {
      importCSV: `Import your existing backlinks from a .csv file. We automatically filter out \
      duplicates. Moz.com, Moz.com, Ahrefs.com are popular paid sources of backlink data. \
      Google search "free backlink checker" for free options. Also check Google Search Console \
      for a free list of backlinks.`,
      importFromAhrefs: `Will import one backlink from domain. If the links were imported \
      previously via Ahrefs, we will import only the new links. We automatically \
      remove duplicates.`,
      importFromMoz: `Will import one backlink from domain. We automatically \
      remove duplicates.`,
      addPublishedLinks: `Copy and paste a list of your existing backlinks. Our crawler \
      will automatically find the anchor text. We automatically remove duplicates.`,
      categorize: `We'll use our categorization algorithm to determine the anchor type of \
      each anchor text.`,
    },
    percentageSettings: {
      pageTypeDefaults: `Each page type can be assigned its own percentages. Add percentages \
      that work best for you and choose them as Default % for your page type. Scroll down to \
      add new percentage schemes.`,
      savedPercentages: `Add, edit and delete the target percentages you want to use for your \
      link building. You can set whatever you create as defaults in the table above. You also \
      can choose these in your anchor text percentage sidebar.`,
    },
    keywordsManager: {
      keywords: (isBrandLevel) => `Add your exact keywords for this ${
        isBrandLevel ? 'brand' : 'page'
      }. \
        These keywords are used to automatically categorize your anchor text. Stick to just the exact keywords.`,
    },
    myBrandsDashboardTable: {
      atp: 'Number of pages included in Anchor Text Planner',
      avgRank: 'The arithmetic mean of ranking positions of all keywords',
      campaigns: 'Number of campaigns, associated with the brand',
      dripLists: 'Number of drip lists, associated with the brand',
      refDomains: 'How many unique domains link to your brand in total',
    },
    rankTrackerTable: {
      averageTodayRank: 'Average rank per brand page',
      todayRank: "Today's Google SERP position for your keyword",
    },
    brandDashboardMetricsGenerate:{
      generateMetricsExplanation: 'Generates metrics for brand',
    },
    onlyForPaidUsers: 'Available only for paid users.',
    emailsSentToday: 'Your daily limit is 100 emails.',
  },
};
