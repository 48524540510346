import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';
import { withRouter } from 'react-router-dom';

import EoCampaignProspectsComponent from './EoCampaignProspectsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector } from 'selectors';
import { eoCampaignOptionsSelector } from 'selectors/eoCampaignOptionsSelector';
import { fetchEoCampaignOptions } from 'api/eoCampaignOptions';
import { optionsSelector } from 'selectors';
import { optionsList } from 'common/prop_types_shapes';
import { assignEoDripListToCampaign, fetchEoDripListsAsOptions } from 'api/eoDripList';

const EoCampaignProspectsContainer = ({
  eoCampaignId,
  eoCampaignOptions,
  eoDripListsOptions,
  dispatch,
}) => {
  React.useEffect(() => {
    fetchEoCampaignOptions(dispatch, eoCampaignId);
    fetchEoDripListsAsOptions(dispatch);
  }, [dispatch]);

  function handleChangeEoDripList(eoDripListId) {
    assignEoDripListToCampaign(dispatch, eoDripListId, eoCampaignId);
  }

  const selectedEoDripListId = eoCampaignOptions.get('eoDripListId', null);

  return (
    <EoCampaignProspectsComponent
      onChangeEoDripList={handleChangeEoDripList}
      immutableEoDripListsOptions={eoDripListsOptions}
      selectedEoDripListId={selectedEoDripListId}
    />
  );
};

EoCampaignProspectsContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoDripListsOptions: optionsList,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoCampaignOptions = eoCampaignOptionsSelector(state);

  const options = optionsSelector(state);
  const eoDripListsOptions = options.get('eoDripListsOptions');

  return {
    eoCampaignId,
    eoCampaignOptions,
    eoDripListsOptions,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(EoCampaignProspectsContainer);
