import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SubscriptionPlansListComponent from './subscription_plans_list_component';
import SubscriptionPlanUsageComponent from './SubscriptionPlanUsageComponent';

import { fetchRailsContext } from 'api/rails_context';
import { policiesShape, subscriptionPlansMap, subscriptionShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import SwitchComponent from 'components_linkio/switch_component';
import TabsNavigationComponent from 'components/auth/tabs_navigation_component';
import { filterSubscriptionPlans } from 'components/subscriptions/utils';

export default class SubscriptionPlansComponent extends Component {
  static propTypes = {
    currentSubscription: subscriptionShape.isRequired,
    dispatch: PropTypes.func,
    policies: policiesShape,
    somethingIsLoading: PropTypes.bool,
    subscriptionPlans: subscriptionPlansMap,
  };

  state = {
    selectedCostType: this.props.currentSubscription.get('billingInterval', 'monthly'),
    selectedPlanId: String(this.props.currentSubscription.get('subscriptionPlanId')),
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchRailsContext(dispatch);
  }

  handlePlanSelect = (planId) => this.setState({ selectedPlanId: planId });

  handleSwitchClick = (item) => {
    this.setState({ selectedCostType: item === 'left' ? 'monthly' : 'yearly' });
  };

  render() {
    const { currentSubscription, policies, somethingIsLoading, subscriptionPlans } = this.props;
    const { selectedPlanId, selectedCostType } = this.state;
    const canManageSubscriptions = policies.getIn(['subscription', 'canManage'], false);
    const filteredSubscriptionPlans = filterSubscriptionPlans(subscriptionPlans, selectedCostType, {
      paidOnly: false,
    });
    const switchInitialValue = selectedCostType === 'monthly' ? 'left' : 'right';

    const switchComponentClasses = classnames({
      'subscription-plan-list-component__switch': true,
      'subscription-plan-list-component__switch_billed-annually': selectedCostType === 'yearly',
    });

    return (
      <div className="page__wrapper">
        <div className="edit-email-or-password-component__tabs">
          <TabsNavigationComponent />
        </div>
        <div className="strip" />
        <SubscriptionPlanUsageComponent
          currentSubscription={currentSubscription}
          subscriptionPlans={subscriptionPlans}
        />
        <div>
          <div className="strip" />
          <br />
          <SwitchComponent
            className={switchComponentClasses}
            left={translate('subscriptionLimitReachedPopup.switch.left')}
            right={translate('subscriptionLimitReachedPopup.switch.right')}
            initialValue={switchInitialValue}
            onClick={this.handleSwitchClick}
          />
          {!somethingIsLoading && (
            <SubscriptionPlansListComponent
              currentSubscription={currentSubscription}
              onPlanSelect={this.handlePlanSelect}
              selectedCostType={selectedCostType}
              selectedPlanId={selectedPlanId}
              subscriptionPlans={filteredSubscriptionPlans}
              withFeatureList
              withPayButton={canManageSubscriptions}
            />
          )}
        </div>
      </div>
    );
  }
}
