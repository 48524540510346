import React from 'react';

import BrandsBlankStateComponent from './BrandsBlankStateComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

const BrandsBlankStateContainer = ({ queryParams, history }) => {
  const showTutorialPopup = queryParams.show_tutorial_popup === 'true';

  function handleClickAddBrand() {
    history.push(Routes.new_brand_path({ format: null }));
  }

  return (
    <BrandsBlankStateComponent
      showTutorialPopup={showTutorialPopup}
      onClickAddBrand={handleClickAddBrand}
    />
  );
};

BrandsBlankStateContainer.propTypes = {
  ...withQueryParamsPropTypes,
};

export default withQueryParams(BrandsBlankStateContainer);
