import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoMessagesBulkActionsComponent from './EoMessagesBulkActionsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { eoMessagesList } from 'common/propTypesShapes/eoMessages';
import { eoMessagesSelector } from 'selectors/eoMessagesSelector';
import { policiesSelector } from 'selectors/railsContextSelectors';

const EoMessagesBulkActionsContainer = ({ canCreate, eoMessages, queryParams }) => {
  const hideSearchInput = !queryParams.search && eoMessages.size === 0;
  const showDeleteButton = canCreate && eoMessages.size > 0;

  return (
    <EoMessagesBulkActionsComponent
      canCreate={canCreate}
      hideSearchInput={hideSearchInput}
      showDeleteButton={showDeleteButton}
    />
  );
};

EoMessagesBulkActionsContainer.propTypes = {
  canCreate: PropTypes.bool,
  eoMessages: eoMessagesList,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoMessages = eoMessagesSelector(state);

  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);

  return {
    canCreate,
    eoMessages,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoMessagesBulkActionsContainer);
