const CATEGORIZE_DLS_POPUP__CLOSE = 'CATEGORIZE_DLS_POPUP__CLOSE';
const CATEGORIZE_DLS_POPUP__OPEN = 'CATEGORIZE_DLS_POPUP__OPEN';
const CHANGE_PERCENTAGES_POPUP__CLOSE = 'CHANGE_PERCENTAGES_POPUP__CLOSE';
const CHANGE_PERCENTAGES_POPUP__OPEN = 'CHANGE_PERCENTAGES_POPUP__OPEN';
export const CONFIRM_PASSWORD_POPUP__CLOSE = 'CONFIRM_PASSWORD_POPUP__CLOSE';
export const CONFIRM_PASSWORD_POPUP__OPEN = 'CONFIRM_PASSWORD_POPUP__OPEN';
export const DELETE_ACCOUNT_POPUP__CLOSE = 'DELETE_ACCOUNT_POPUP__CLOSE';
export const DELETE_ACCOUNT_POPUP__OPEN = 'DELETE_ACCOUNT_POPUP__OPEN';
const DL_DETAILS_POPUP__CLOSE = 'DL_DETAILS_POPUP__CLOSE';
const DL_DETAILS_POPUP__OPEN = 'DL_DETAILS_POPUP__OPEN';
const EDIT_KEYWORDS_POPUP__CLOSE = 'EDIT_KEYWORDS_POPUP__CLOSE';
const EDIT_KEYWORDS_POPUP__OPEN = 'EDIT_KEYWORDS_POPUP__OPEN';
const FEEDBACK_POPUP__CLOSE = 'FEEDBACK_POPUP__CLOSE';
const FEEDBACK_POPUP__OPEN = 'FEEDBACK_POPUP__OPEN';
const GET_BACKLINKS_SUGGESTION_POPUP__CLOSE = 'GET_BACKLINKS_SUGGESTION_POPUP__CLOSE';
const GET_BACKLINKS_SUGGESTION_POPUP__OPEN = 'GET_BACKLINKS_SUGGESTION_POPUP__OPEN';
const IMPORT_DLS_POPUP__CLOSE = 'IMPORT_DLS_POPUP__CLOSE';
const IMPORT_DLS_POPUP__OPEN = 'IMPORT_DLS_POPUP__OPEN';
const IMPORT_FROM_GSC_POPUP__CLOSE = 'IMPORT_FROM_GSC_POPUP__CLOSE';
const IMPORT_FROM_GSC_POPUP__OPEN = 'IMPORT_FROM_GSC_POPUP__OPEN';
const MANUALLY_IMPORT_PAGES_POPUP__CLOSE = 'MANUALLY_IMPORT_PAGES_POPUP__CLOSE';
const MANUALLY_IMPORT_PAGES_POPUP__OPEN = 'MANUALLY_IMPORT_PAGES_POPUP__OPEN';
const PAGES_EXPORT_TO_CSV_POPUP__CLOSE = 'PAGES_EXPORT_TO_CSV_POPUP__CLOSE';
const PAGES_EXPORT_TO_CSV_POPUP__OPEN = 'PAGES_EXPORT_TO_CSV_POPUP__OPEN';
const PAGE_EDIT_POPUP__CLOSE = 'PAGE_EDIT_POPUP__CLOSE';
const PAGE_EDIT_POPUP__OPEN = 'PAGE_EDIT_POPUP__OPEN';
const PERCENTAGES_FORM_POPUP__CLOSE = 'PERCENTAGES_FORM_POPUP__CLOSE';
const PERCENTAGES_FORM_POPUP__OPEN = 'PERCENTAGES_FORM_POPUP__OPEN';
const SUBSCRIPTION_LIMIT_REACHED_POPUP__CLOSE = 'SUBSCRIPTION_LIMIT_REACHED_POPUP__CLOSE';
const SUBSCRIPTION_LIMIT_REACHED_POPUP__OPEN = 'SUBSCRIPTION_LIMIT_REACHED_POPUP__OPEN';
const SWITCH_SUBSCRIPTION_PLAN_POPUP__CLOSE = 'SWITCH_SUBSCRIPTION_PLAN_POPUP__CLOSE';
const SWITCH_SUBSCRIPTION_PLAN_POPUP__OPEN = 'SWITCH_SUBSCRIPTION_PLAN_POPUP__OPEN';
const TRUST_SUGGESTIONS_POPUP__CLOSE = 'TRUST_SUGGESTIONS_POPUP__CLOSE';
const TRUST_SUGGESTIONS_POPUP__OPEN = 'TRUST_SUGGESTIONS_POPUP__OPEN';
const URL_CHANGING_HISTORY_POPUP__CLOSE = 'URL_CHANGING_HISTORY_POPUP__CLOSE';
const URL_CHANGING_HISTORY_POPUP__OPEN = 'URL_CHANGING_HISTORY_POPUP__OPEN';

function closeCategorizeDlsPopup() {
  return { type: CATEGORIZE_DLS_POPUP__CLOSE };
}

function openCategorizeDlsPopup(options) {
  return { type: CATEGORIZE_DLS_POPUP__OPEN, options };
}

function closeChangePercentagesPopup() {
  return { type: CHANGE_PERCENTAGES_POPUP__CLOSE };
}

function openChangePercentagesPopup(options) {
  return { type: CHANGE_PERCENTAGES_POPUP__OPEN, options };
}

export function closeConfirmPasswordPopup() {
  return { type: CONFIRM_PASSWORD_POPUP__CLOSE };
}

export function openConfirmPasswordPopup() {
  return { type: CONFIRM_PASSWORD_POPUP__OPEN };
}

export function closeDeleteAccountPopup() {
  return { type: DELETE_ACCOUNT_POPUP__CLOSE };
}

export function openDeleteAccountPopup() {
  return { type: DELETE_ACCOUNT_POPUP__OPEN };
}

function closeDlDetailsPopup() {
  return { type: DL_DETAILS_POPUP__CLOSE };
}

function openDlDetailsPopup(options) {
  return { type: DL_DETAILS_POPUP__OPEN, options };
}

function closeEditKeywordsPopup() {
  return { type: EDIT_KEYWORDS_POPUP__CLOSE };
}

function openEditKeywordsPopup(options) {
  return { type: EDIT_KEYWORDS_POPUP__OPEN, options };
}

function closeFeedbackPopup() {
  return { type: FEEDBACK_POPUP__CLOSE };
}

function openFeedbackPopup(options) {
  return { type: FEEDBACK_POPUP__OPEN, options };
}

function closeGetBacklinksSuggestionPopup() {
  return { type: GET_BACKLINKS_SUGGESTION_POPUP__CLOSE };
}

function openGetBacklinksSuggestionPopup(options) {
  return { type: GET_BACKLINKS_SUGGESTION_POPUP__OPEN, options };
}

function closeImportDlsPopup() {
  return { type: IMPORT_DLS_POPUP__CLOSE };
}

function openImportDlsPopup(options) {
  return { type: IMPORT_DLS_POPUP__OPEN, options };
}

function closeImportFromGSCPopup() {
  return { type: IMPORT_FROM_GSC_POPUP__CLOSE };
}

function openImportFromGSCPopup() {
  return { type: IMPORT_FROM_GSC_POPUP__OPEN };
}

function closeManuallyImportPagesPopup() {
  return { type: MANUALLY_IMPORT_PAGES_POPUP__CLOSE };
}

function openManuallyImportPagesPopup() {
  return { type: MANUALLY_IMPORT_PAGES_POPUP__OPEN };
}

function closePageEditPopup() {
  return { type: PAGE_EDIT_POPUP__CLOSE };
}

function openPageEditPopup(options) {
  return { type: PAGE_EDIT_POPUP__OPEN, options };
}

function closePagesExportToCsvPopup() {
  return { type: PAGES_EXPORT_TO_CSV_POPUP__CLOSE };
}

function openPagesExportToCsvPopup(options) {
  return { type: PAGES_EXPORT_TO_CSV_POPUP__OPEN, options };
}

function closePercentagesFormPopup() {
  return { type: PERCENTAGES_FORM_POPUP__CLOSE };
}

function openPercentagesFormPopup(options) {
  return { type: PERCENTAGES_FORM_POPUP__OPEN, options };
}

function closeSubscriptionLimitReachedPopup() {
  return { type: SUBSCRIPTION_LIMIT_REACHED_POPUP__CLOSE };
}

function openSubscriptionLimitReachedPopup(options) {
  return { type: SUBSCRIPTION_LIMIT_REACHED_POPUP__OPEN, options };
}

function closeSwitchSubscriptionPlanPopup() {
  return { type: SWITCH_SUBSCRIPTION_PLAN_POPUP__CLOSE };
}

function openSwitchSubscriptionPlanPopup(options) {
  return { type: SWITCH_SUBSCRIPTION_PLAN_POPUP__OPEN, options };
}

function closeTrustSuggestionsPopup() {
  return { type: TRUST_SUGGESTIONS_POPUP__CLOSE };
}

function openTrustSuggestionsPopup() {
  return { type: TRUST_SUGGESTIONS_POPUP__OPEN };
}

function closeUrlChangingHistoryPopup() {
  return { type: URL_CHANGING_HISTORY_POPUP__CLOSE };
}

function openUrlChangingHistoryPopup(keywordId, brandPageId) {
  return { type: URL_CHANGING_HISTORY_POPUP__OPEN, keywordId, brandPageId };
}

export {
  CATEGORIZE_DLS_POPUP__CLOSE,
  CATEGORIZE_DLS_POPUP__OPEN,
  CHANGE_PERCENTAGES_POPUP__CLOSE,
  CHANGE_PERCENTAGES_POPUP__OPEN,
  DL_DETAILS_POPUP__CLOSE,
  DL_DETAILS_POPUP__OPEN,
  EDIT_KEYWORDS_POPUP__CLOSE,
  EDIT_KEYWORDS_POPUP__OPEN,
  FEEDBACK_POPUP__CLOSE,
  FEEDBACK_POPUP__OPEN,
  GET_BACKLINKS_SUGGESTION_POPUP__CLOSE,
  GET_BACKLINKS_SUGGESTION_POPUP__OPEN,
  IMPORT_DLS_POPUP__CLOSE,
  IMPORT_DLS_POPUP__OPEN,
  IMPORT_FROM_GSC_POPUP__CLOSE,
  IMPORT_FROM_GSC_POPUP__OPEN,
  MANUALLY_IMPORT_PAGES_POPUP__CLOSE,
  MANUALLY_IMPORT_PAGES_POPUP__OPEN,
  PAGES_EXPORT_TO_CSV_POPUP__CLOSE,
  PAGES_EXPORT_TO_CSV_POPUP__OPEN,
  PAGE_EDIT_POPUP__CLOSE,
  PAGE_EDIT_POPUP__OPEN,
  PERCENTAGES_FORM_POPUP__CLOSE,
  PERCENTAGES_FORM_POPUP__OPEN,
  SUBSCRIPTION_LIMIT_REACHED_POPUP__CLOSE,
  SUBSCRIPTION_LIMIT_REACHED_POPUP__OPEN,
  SWITCH_SUBSCRIPTION_PLAN_POPUP__CLOSE,
  SWITCH_SUBSCRIPTION_PLAN_POPUP__OPEN,
  TRUST_SUGGESTIONS_POPUP__CLOSE,
  TRUST_SUGGESTIONS_POPUP__OPEN,
  URL_CHANGING_HISTORY_POPUP__CLOSE,
  URL_CHANGING_HISTORY_POPUP__OPEN,
  closeCategorizeDlsPopup,
  openCategorizeDlsPopup,
  closeChangePercentagesPopup,
  openChangePercentagesPopup,
  closeDlDetailsPopup,
  openDlDetailsPopup,
  closeEditKeywordsPopup,
  openEditKeywordsPopup,
  closeFeedbackPopup,
  openFeedbackPopup,
  closeGetBacklinksSuggestionPopup,
  openGetBacklinksSuggestionPopup,
  closeImportDlsPopup,
  openImportDlsPopup,
  closeImportFromGSCPopup,
  openImportFromGSCPopup,
  closeManuallyImportPagesPopup,
  openManuallyImportPagesPopup,
  closePageEditPopup,
  openPageEditPopup,
  closePagesExportToCsvPopup,
  openPagesExportToCsvPopup,
  closePercentagesFormPopup,
  openPercentagesFormPopup,
  closeSubscriptionLimitReachedPopup,
  openSubscriptionLimitReachedPopup,
  closeSwitchSubscriptionPlanPopup,
  openSwitchSubscriptionPlanPopup,
  closeTrustSuggestionsPopup,
  openTrustSuggestionsPopup,
  closeUrlChangingHistoryPopup,
  openUrlChangingHistoryPopup,
};
