import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DuplicatesTableComponent from './DuplicatesTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { canUseCrawlersSelector, policiesSelector } from 'selectors/railsContextSelectors';
import { destinationLinksMap, optionsMap, policiesShape } from 'common/prop_types_shapes';
import { dlDuplicatesSelector, optionsSelector } from 'selectors';
import { updateDl } from 'api/destination_links';

class DuplicatesTableContainer extends React.Component {
  static propTypes = {
    anchorTypeOptions: optionsMap.isRequired,
    canUseCrawlers: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    dlDuplicates: destinationLinksMap.isRequired,
    includeByUserOptions: optionsMap.isRequired,
    includedOptions: optionsMap.isRequired,
    policies: policiesShape.isRequired,
  };

  shouldComponentUpdate() {
    return true;
  }

  handleUpdateDl = async (dl) => {
    const { dispatch } = this.props;
    const { brandPageId } = dl;

    await updateDl(dispatch, brandPageId, [dl]);
  };

  render() {
    const {
      anchorTypeOptions,
      dlDuplicates,
      includeByUserOptions,
      includedOptions,
      policies,
      canUseCrawlers,
    } = this.props;

    return (
      <DuplicatesTableComponent
        anchorTypeOptions={anchorTypeOptions}
        canUseCrawlers={canUseCrawlers}
        dlDuplicates={dlDuplicates}
        includeByUserOptions={includeByUserOptions}
        includedOptions={includedOptions}
        onUpdateDl={this.handleUpdateDl}
        policies={policies}
      />
    );
  }
}

function select(state, ownProps) {
  const canUseCrawlers = canUseCrawlersSelector(state, ownProps);
  const dlDuplicates = dlDuplicatesSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);

  const anchorTypeOptions = options.get('anchorTypeOptions');
  const includeByUserOptions = options.get('includeByUserOptions');
  const includedOptions = options.get('includedOptions');

  return {
    anchorTypeOptions,
    dlDuplicates,
    includeByUserOptions,
    includedOptions,
    policies,
    canUseCrawlers,
  };
}

export default ConnectStoreHOC(connect(select)(DuplicatesTableContainer));
