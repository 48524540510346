export default {
  eoCampaignPreview: {
    messages: {
      cantFetchEoCampaignStepsPreview: "Can't load campaign sequences",
      sendTestEmailsSuccess: 'Successfully sent test emails.',
      sendTestEmailsFailure: "Can't send test emails",
    },
    test: {
      title: 'Preview Mode',
      chooseInbox: {
        title: '1. Choose Inbox',
        blankState: {
          title: "You haven't added inbox to the current campaign yet.",
          subtitle: {
            text: 'You can add inbox',
            link: 'here',
          },
        },
      },
      selectProspect: {
        title: '2. Select Prospect',
        btnTitle: 'Select a Prospect',
        disabledTooltip: 'Please choose inbox',
      },
      sendTestEmails: {
        title: '3. Send Test Emails',
        btnTitle: 'Email me the campaign',
        disabledTooltip: {
          noProspectSelected: 'Please select prospect',
          hasTransformationError: 'Email body has transformation error(s)',
        },
      },
    },
    selectProspectPopup: {
      title: 'Select a Prospect',
      submit: 'Use Prospect',
      hint:
        "Actual prospect won't get emails. \
        We'll send the test emails to your personal email address. \
        CC features are disabled for tests.",
    },
    stepsPreview: {
      blankState: {
        text: "You don't have any Steps yet. Add your first step",
        link: 'here',
      },
      stepEnd: 'End',
      stepPosition: (position) => `Step #${position}`,
      waitStepTitle: ({ value, measure }) => `Wait ${value} ${measure}`,
    },
  },
};
