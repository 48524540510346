import React from 'react';
import { connect } from 'react-redux';

import AddEoInboxButtonComponent from './AddEoInboxButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  currentSubscriptionSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import { subscriptionPoliciesShape, subscriptionShape } from 'common/prop_types_shapes';

const AddEoInboxButtonContainer = ({ currentSubscription, subscriptionPolicies }) => {
  const allowedEoInboxesCount = currentSubscription.get('allowedEoInboxesCount');
  const canAddEoInboxes = subscriptionPolicies.getIn(['emailOutreach', 'canAddEoInboxes']);

  return (
    <AddEoInboxButtonComponent
      allowedEoInboxesCount={allowedEoInboxesCount}
      canAddEoInboxes={canAddEoInboxes}
    />
  );
};

AddEoInboxButtonContainer.propTypes = {
  currentSubscription: subscriptionShape,
  subscriptionPolicies: subscriptionPoliciesShape,
};

function select(state) {
  const currentSubscription = currentSubscriptionSelector(state);
  const subscriptionPolicies = subscriptionPoliciesSelector(state);

  return {
    currentSubscription,
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(AddEoInboxButtonContainer));
