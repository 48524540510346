export const SEND_EO_TO_DO_EMAIL__FAILURE = 'SEND_EO_TO_DO_EMAIL__FAILURE';
export const SEND_EO_TO_DO_EMAIL__START = 'SEND_EO_TO_DO_EMAIL__START';
export const SEND_EO_TO_DO_EMAIL__SUCCESS = 'SEND_EO_TO_DO_EMAIL__SUCCESS';
export const DELETE_EO_TO_DOS__FAILURE = 'DELETE_EO_TO_DOS__FAILURE';
export const DELETE_EO_TO_DOS__START = 'DELETE_EO_TO_DOS__START';
export const DELETE_EO_TO_DOS__SUCCESS = 'DELETE_EO_TO_DOS__SUCCESS';
export const FETCH_EO_TO_DOS__FAILURE = 'FETCH_EO_TO_DOS__FAILURE';
export const FETCH_EO_TO_DOS__START = 'FETCH_EO_TO_DOS__START';
export const FETCH_EO_TO_DOS__SUCCESS = 'FETCH_EO_TO_DOS__SUCCESS';
export const FETCH_EO_TO_DO__FAILURE = 'FETCH_EO_TO_DO__FAILURE';
export const FETCH_EO_TO_DO__START = 'FETCH_EO_TO_DO__START';
export const FETCH_EO_TO_DO__SUCCESS = 'FETCH_EO_TO_DO__SUCCESS';
export const UPDATE_EO_TO_DO__FAILURE = 'UPDATE_EO_TO_DO__FAILURE';
export const UPDATE_EO_TO_DO__START = 'UPDATE_EO_TO_DO__START';
export const UPDATE_EO_TO_DO__SUCCESS = 'UPDATE_EO_TO_DO__SUCCESS';

export function sendEoToDoEmailStart() {
  return { type: SEND_EO_TO_DO_EMAIL__START };
}

export function sendEoToDoEmailSuccess(ids) {
  return { type: SEND_EO_TO_DO_EMAIL__SUCCESS, ids };
}

export function sendEoToDoEmailFailure(errors) {
  return { type: SEND_EO_TO_DO_EMAIL__FAILURE, errors };
}

export function deleteEoToDosStart() {
  return { type: DELETE_EO_TO_DOS__START };
}

export function deleteEoToDosSuccess(ids) {
  return { type: DELETE_EO_TO_DOS__SUCCESS, ids };
}

export function deleteEoToDosFailure(errors) {
  return { type: DELETE_EO_TO_DOS__FAILURE, errors };
}

export function fetchEoToDosStart() {
  return { type: FETCH_EO_TO_DOS__START };
}

export function fetchEoToDosSuccess(eoToDos) {
  return { type: FETCH_EO_TO_DOS__SUCCESS, eoToDos };
}

export function fetchEoToDosFailure(errors) {
  return { type: FETCH_EO_TO_DOS__FAILURE, errors };
}

export function fetchEoToDoStart() {
  return { type: FETCH_EO_TO_DO__START };
}

export function fetchEoToDoSuccess(eoToDo) {
  return { type: FETCH_EO_TO_DO__SUCCESS, eoToDo };
}

export function fetchEoToDoFailure(errors) {
  return { type: FETCH_EO_TO_DO__FAILURE, errors };
}

export function updateEoToDoStart() {
  return { type: UPDATE_EO_TO_DO__START };
}

export function updateEoToDoSuccess(eoToDo) {
  return { type: UPDATE_EO_TO_DO__SUCCESS, eoToDo };
}

export function updateEoToDoFailure(errors) {
  return { type: UPDATE_EO_TO_DO__FAILURE, errors };
}
