import { fromJS, List as iList } from 'immutable';
import { camelizeObjectDeep } from './utils';

import { DELETE_EO_MESSAGES__SUCCESS, FETCH_EO_MESSAGES__SUCCESS } from 'actions/eoMessageActions';

const initialState = iList();

export default function eoMessagesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_MESSAGES__SUCCESS: {
      const { eoMessages } = action;

      state = fromJS(eoMessages.map(camelizeObjectDeep));

      break;
    }
    case DELETE_EO_MESSAGES__SUCCESS: {
      const { ids } = action;

      state = state.filter((eoMessage) => !ids.includes(eoMessage.get('id')));

      break;
    }
  }

  return state;
}
