import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';

import './buyVerificationCreditsPopup.scss';

const DEFAULT_CREDITS_COUNT = 2000;
const CREDIT_COST = 0.005;

const BuyVerificationCreditsPopupComponent = ({ onCancel, onBuyCredits }) => {
  const [creditsVolumeCount, setCreditsVolumeCount] = React.useState(DEFAULT_CREDITS_COUNT / 1000);

  function handleChangeInputValue(event) {
    setCreditsVolumeCount(event.target.value);
  }

  function handleSubmit() {
    onBuyCredits(creditsVolumeCount * 1000);
  }

  return (
    <Modal show className="buy-verification-credits-popup">
      <Modal.Header>
        <Modal.Title>
          {translate('eoEmailVerification.buyVerificationCreditsPopup.title')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{translate('eoEmailVerification.buyVerificationCreditsPopup.howManyCredits')}</p>

        <div className="buy-verification-credits-popup__input-wrapper">
          <InputComponent
            onInputChange={handleChangeInputValue}
            type="number"
            value={creditsVolumeCount}
          />
          000
          <h4>{translate('eoEmailVerification.buyVerificationCreditsPopup.credits')}</h4>
        </div>

        <h2>{`$${creditsVolumeCount * 1000 * CREDIT_COST}`}</h2>
      </Modal.Body>

      <Modal.Footer>
        <div className="buy-verification-credits-popup__btn-group">
          <ButtonComponent isWhite isInitial onClick={onCancel}>
            {translate('uiComponents.button.cancel')}
          </ButtonComponent>

          <ButtonComponent
            isBlue
            isInitial
            isDisabled={creditsVolumeCount < 1}
            onClick={handleSubmit}
          >
            {translate('eoEmailVerification.buyVerificationCreditsPopup.buttons.submit')}
          </ButtonComponent>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

BuyVerificationCreditsPopupComponent.propTypes = {
  onBuyCredits: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default BuyVerificationCreditsPopupComponent;
