import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ImportCsv from './ImportCsv';
import ImportFromAhrefs from './ImportFromAhrefs';

import { ahrefsAbilitiesShape, brandPageShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import Checkbox from 'components_linkio/Checkbox';
import ButtonComponent from 'components_linkio/button_component';
import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';
import InputComponent from 'components_linkio/input_component';
import MessageComponent from 'components_linkio/message_component';
import RadioButton from 'components_linkio/RadioButton';
import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import './importDlsPopup.scss';

class ImportDlsPopupComponent extends Component {
  static propTypes = {
    ahrefsAbilities: ahrefsAbilitiesShape,
    // canImportFromMoz: PropTypes.bool.isRequired,
    canUseAhrefs: PropTypes.bool.isRequired,
    canUseAhrefsInSubscription: PropTypes.bool.isRequired,
    canUseCategorizer: PropTypes.bool.isRequired,
    hasAhrefsConnection: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClickUpgradeSubscription: PropTypes.func.isRequired,
    onFetchCurrentSubscription: PropTypes.func.isRequired,
    onImportCsv: PropTypes.func.isRequired,
    onImportFromAhrefs: PropTypes.func.isRequired,
    // onImportFromMoz: PropTypes.func.isRequired,
    onImportPublishedLinks: PropTypes.func.isRequired,
    page: brandPageShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      categorizeBacklinks: false,
      csvFile: void 0,
      publishedLinks: '',
      selectedOpt: 'importCSV',
    };
  }

  componentDidMount() {
    this.props.onFetchCurrentSubscription();
  }

  canUseCategorizerMessage = {
    type: 'hint',
    text: translate('importToBrandPage.canUseCategorizerMessage'),
  };

  handleCancel = () => this.props.onCancel();
  handleImport = () => {
    const { categorizeBacklinks, csvFile, publishedLinks, selectedOpt } = this.state;

    const {
      onImportCsv,
      onImportFromAhrefs,
      // onImportFromMoz,
      onImportPublishedLinks,
      page,
    } = this.props;

    const pageId = page.get('id');

    switch (selectedOpt) {
      case 'importCSV':
        onImportCsv(csvFile, pageId, categorizeBacklinks);
        break;
      case 'importFromAhrefs':
        onImportFromAhrefs(pageId, categorizeBacklinks);
        break;
      // case 'importFromMoz':
      //   onImportFromMoz(pageId, categorizeBacklinks);
      //   break;
      case 'addPublishedLinks':
        onImportPublishedLinks(publishedLinks, pageId, categorizeBacklinks);
        break;
    }
  };
  handleCSVFileUpload = (csvFile) => this.setState({ csvFile });
  handleOptsChange = (event) =>
    this.setState({ selectedOpt: event.target.value, publishedLinks: '' });
  handleAddPublishedLinks = (event) => this.setState({ publishedLinks: event.target.value });

  radioBtn = (valueText, isDisabled = false) => {
    const { selectedOpt } = this.state;

    return (
      <RadioButton
        className="import-popup-component__inputs"
        value={valueText}
        checked={selectedOpt === valueText}
        disabled={isDisabled}
        onChange={this.handleOptsChange}
      >
        {translate(`importToBrandPage.opts.${valueText}.label`)}
        <ExplanatoryTooltipComponent
          text={translate(`explanationMessages.importPopup.${valueText}`)}
        />
      </RadioButton>
    );
  };

  handleCategorizeCheckboxChange = (event) => {
    const { target } = event;
    const isChecked = !!(target || {}).checked;
    this.setState({ categorizeBacklinks: isChecked });
  };

  render() {
    const {
      ahrefsAbilities: {
        freeAhrefsSubscription: hasFreeAhrefsSubscription,
        isAble: isAbleToImportFromAhrefs,
        limitReached: isAhrefsLimitReached,
      },
      // canImportFromMoz,
      canUseAhrefs,
      canUseAhrefsInSubscription,
      canUseCategorizer,
      hasAhrefsConnection,
      onClickUpgradeSubscription,
      page,
    } = this.props;
    const { selectedOpt, csvFile, publishedLinks } = this.state;

    const pageLink = page.get('link');
    const pageLatestAhrefsImportAt = page.get('latestAhrefsImportAt');

    const canImportCsv = true;
    const showAhrefsLimitRibbon = hasAhrefsConnection && !canUseAhrefsInSubscription;

    return (
      <Modal show>
        <Modal.Header>
          <Modal.Title>{translate('importToBrandPage.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pageLink}
          <div className="import-popup-component__form">
            <ul>
              {canImportCsv && (
                <li>
                  {this.radioBtn('importCSV')}
                  <div className="import-popup-component__csv-import">
                    <ImportCsv
                      isDisabled={selectedOpt !== 'importCSV'}
                      onFileUpload={this.handleCSVFileUpload}
                    />
                  </div>
                </li>
              )}
              {canUseAhrefs && (
                <li>
                  <hr />
                  {showAhrefsLimitRibbon && (
                    <SubscriptionLimitRibbonComponent>
                      {translate('subscriptionLimitRibbon.message')(
                        translate('subscriptionLimitRibbon.limited.ahrefsImport'),
                      )}
                      <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
                    </SubscriptionLimitRibbonComponent>
                  )}
                  {this.radioBtn('importFromAhrefs', !isAbleToImportFromAhrefs)}
                  <div className="import-popup-component__ahrefs-import">
                    <ImportFromAhrefs
                      isDisabled={selectedOpt !== 'importFromAhrefs' || !isAbleToImportFromAhrefs}
                      hasAhrefsConnection={hasAhrefsConnection}
                      latestAhrefsImportAt={pageLatestAhrefsImportAt}
                    />
                  </div>
                  {hasFreeAhrefsSubscription && (
                    <MessageComponent
                      message={{
                        type: 'hint',
                        text: translate('importToBrandPage.opts.importFromAhrefs.warningFree'),
                      }}
                    />
                  )}
                  {isAhrefsLimitReached && (
                    <MessageComponent
                      message={{
                        type: 'hint',
                        text: translate('importToBrandPage.opts.importFromAhrefs.warningLimit'),
                      }}
                    />
                  )}
                </li>
              )}
              
              {/* <li>
                <hr />
                {this.radioBtn('importFromMoz', !canImportFromMoz)}
                <MessageComponent
                  message={{
                    type: 'hint',
                    text: translate('brand.form.inputs.importBacklinksFromMozCheckbox.hint'),
                  }}
                />
              </li> */}
              <li>
                <hr />
                {this.radioBtn('addPublishedLinks')}
                <div className="import-popup-component__add-published-links">
                  <InputComponent
                    isDisabled={selectedOpt !== 'addPublishedLinks'}
                    isMultiline
                    label={translate('importToBrandPage.publishedLinks')}
                    message={{
                      type: 'hint',
                      text: translate('importToBrandPage.publishedLinksHint'),
                    }}
                    onInputChange={this.handleAddPublishedLinks}
                    value={publishedLinks}
                  />
                </div>
              </li>
              <li>
                {!canUseCategorizer && (
                  <SubscriptionLimitRibbonComponent>
                    {translate('subscriptionLimitRibbon.message')(
                      translate('subscriptionLimitRibbon.limited.categorization'),
                    )}
                    <UpgradeSubscriptionLink onClick={onClickUpgradeSubscription} />
                  </SubscriptionLimitRibbonComponent>
                )}
                {canUseCategorizer ? (
                  <MessageComponent message={this.canUseCategorizerMessage} />
                ) : (
                  <div className="import-popup-component__inputs">
                    <Checkbox
                      checked={false}
                      disabled
                      name="categorize"
                      onChange={this.handleCategorizeCheckboxChange}
                    >
                      {translate('importToBrandPage.categorize')}
                      <ExplanatoryTooltipComponent
                        text={translate('explanationMessages.importPopup.categorize')}
                      />
                    </Checkbox>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="import-popup-component__btn-group">
            <div className="import-popup-component__btn">
              <ButtonComponent isWhite onClick={this.handleCancel}>
                {translate('importToBrandPage.buttons.cancel')}
              </ButtonComponent>
            </div>
            <div className="import-popup-component__btn">
              <ButtonComponent
                isDisabled={
                  (selectedOpt === 'importCSV' && !csvFile) ||
                  (selectedOpt === 'addPublishedLinks' && !publishedLinks)
                }
                isGreen
                onClick={this.handleImport}
              >
                {translate('importToBrandPage.buttons.import')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ImportDlsPopupComponent;
