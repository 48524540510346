export default {
  newsletterSettings: {
    brandsSelector: {
      allBrands: 'All brands',
      placeholder: 'Select brand(s)...',
      title: 'Receive emails for',
    },
    buttons: {
      submit: 'Update',
    },
    receivePeriod: {
      daily: 'Daily',
      never: 'Never',
      title: 'Receive emails',
    },
    emailsSentTo: {
      hint: {
        linkText: 'here',
        mainText: 'To change the email, please update your account email ',
      },
      title: 'Email sent to',
    },
    messages: {
      updateSuccess: 'Successfully updated notifications settings.',
    },
    unsubscribe: {
      buttons: {
        cancel: 'Cancel',
        unsubscribe: 'Unsubscribe',
      },
      messages: {
        unsubscribeSuccess: 'You are successfully unsubscribed',
        unsubscribeFailure: 'Can not unsubscribe',
      },
      title: {
        clickToUnsubscribe: 'Click to unsubscribe.',
        noSubscriptions: 'You have no active subscriptions',
      },
    },
  },
};
