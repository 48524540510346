import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import LineChartComponent from './LineChartComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { chartMetricsArray } from 'common/propTypesShapes/metrics';
import { openSubscriptionLimitReachedPopup } from 'actions/popup_actions';
import { subscriptionPoliciesShape } from 'common/prop_types_shapes';

import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';

const LineChartContainer = ({
  appModule,
  chartData,
  className,
  dispatch,
  leftYAxisReversed,
  link,
  rightYAxisReversed,
  showRightYAxis,
  subscriptionPolicies,
  title,
}) => {
  function handleClickUpgradeSubscription(event) {
    event && event.preventDefault();

    dispatch(openSubscriptionLimitReachedPopup({}));
  }

  const canUseCharts = subscriptionPolicies.getIn(['metrics', 'canUse']);

  return (
    <LineChartComponent
      appModule={appModule}
      canUseCharts={canUseCharts}
      chartData={chartData}
      className={className}
      leftYAxisReversed={leftYAxisReversed}
      link={link}
      onClickUpgradeSubscription={handleClickUpgradeSubscription}
      rightYAxisReversed={rightYAxisReversed}
      showRightYAxis={showRightYAxis}
      title={title}
    />
  );
};

LineChartContainer.propTypes = {
  appModule: PropTypes.oneOf(['backlinks', 'emailOutreach']),
  chartData: chartMetricsArray,
  className: PropTypes.string,
  dispatch: PropTypes.func,
  leftYAxisReversed: PropTypes.bool,
  link: PropTypes.string,
  rightYAxisReversed: PropTypes.bool,
  showRightYAxis: PropTypes.bool,
  subscriptionPolicies: subscriptionPoliciesShape,
  title: PropTypes.string,
};

const selector = (state) => ({
  subscriptionPolicies: subscriptionPoliciesSelector(state),
});

export default compose(ConnectStoreHOC, connect(selector))(LineChartContainer);
