import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { uiSidebarToggle } from 'actions/ui_actions.js';

import { addCookie } from 'common/utils';

import Link from 'components_linkio/link';

import MenuIcon from 'common/icons/menu';
import './layout.scss';

export const SidebarTitle = ({ children, as }) => (
  <div
    className={cx('layout__sidebar-title', {
      'layout__sidebar-title_icon': as === 'icon',
    })}
  >
    {children}
  </div>
);

export const SidebarLink = ({
  children,
  to,
  exact,
  icon,
  as,
  styleAs,
  isActive,
  title,
}) => {
  const isTitleStyle = styleAs === 'title';

  const classes = cx('layout__sidebar-item', {
    'layout__sidebar-item_icon': as === 'icon',
    'layout__sidebar-item_active': isActive,
    'layout__sidebar-item_as-title': isTitleStyle,
  });

  return (
    <Link
      activeClassName="layout__sidebar-item_active"
      as="nav"
      className={classes}
      exact={exact}
      to={to}
      title={title}
    >
      {as === 'icon' ? icon : children}
    </Link>
  );
};

export const SidebarButton = ({ children, icon, as, onClick, isActive, title }) => {
  const classes = cx('layout__sidebar-item', {
    'layout__sidebar-item_icon': as === 'icon',
    'layout__sidebar-item_active': isActive,
  });

  return (
    <div className={classes} onClick={onClick} title={title}>
      {as === 'icon' ? icon : children}
    </div>
  );
};

const renderFoldedSidebarItems = (children = []) => {
  return children.filter(Boolean).map((i) => React.cloneElement(i, { as: 'icon' }));
};

export const Sidebar = ({ children, isSidebarOpen, onToggleSidebar }) => {
  const classes = cx('layout__sidebar', {
    layout__sidebar_open: isSidebarOpen,
  });
  return (
    <div className={classes}>
      <div
        className="layout__menu-button"
        onClick={onToggleSidebar}
        title="menu"
      >
        <span>menu</span>
        <MenuIcon />
      </div>
      <div className="layout__sidebar-menu-wrapper">
        {isSidebarOpen ? children : renderFoldedSidebarItems(children)}
      </div>
    </div>
  );
};

export const PageContent = ({
  children,
  isSidebarOpen,
  isSidebarWasClosed,
}) => {
  const classes = cx('layout__page-content', {
    'layout__page-content_wide': !isSidebarOpen,
    'layout__page-content_anim': isSidebarWasClosed,
  });

  return <div className={classes}>{children}</div>;
};

class Layout extends React.PureComponent {
  state = {
    isSidebarWasClosed: false,
  };

  onToggleSidebar = () => {
    const { isSidebarOpen } = this.props;

    addCookie('sidebarIsOpen', String(!isSidebarOpen));
    this.props.dispatch(uiSidebarToggle());
    this.setState({ isSidebarWasClosed: true });
  };

  render() {
    const { isSidebarWasClosed } = this.state;
    const { isSidebarOpen } = this.props;

    return (
      <div className="layout">
        {React.Children.map(this.props.children, (child) =>
          React.cloneElement(child, {
            isSidebarOpen,
            isSidebarWasClosed,
            onToggleSidebar: this.onToggleSidebar,
          }),
        )}
      </div>
    );
  }
}

const select = (state) => ({
  isSidebarOpen: state.getIn(['ui', 'openClose', 'sidebar', 'isOpen']),
});

export default ConnectStoreHOC(connect(select)(Layout));
