const FETCH_ALL_NOTIFICATIONS__FAILURE = 'FETCH_ALL_NOTIFICATIONS__FAILURE';
const FETCH_ALL_NOTIFICATIONS__STARTED = 'FETCH_ALL_NOTIFICATIONS__STARTED';
const FETCH_ALL_NOTIFICATIONS__SUCCESS = 'FETCH_ALL_NOTIFICATIONS__SUCCESS';
const FETCH_RECENT_NOTIFICATIONS__FAILURE = 'FETCH_RECENT_NOTIFICATIONS__FAILURE';
const FETCH_RECENT_NOTIFICATIONS__STARTED = 'FETCH_RECENT_NOTIFICATIONS__STARTED';
const FETCH_RECENT_NOTIFICATIONS__SUCCESS = 'FETCH_RECENT_NOTIFICATIONS__SUCCESS';
const FETCH_UNREAD_NOTIFICATIONS_COUNT__FAILURE = 'FETCH_UNREAD_NOTIFICATIONS_COUNT__FAILURE';
const FETCH_UNREAD_NOTIFICATIONS_COUNT__SUCCESS = 'FETCH_UNREAD_NOTIFICATIONS_COUNT__SUCCESS';
const MARK_ALL_NOTIFICATIONS_AS_READ__FAILURE = 'MARK_ALL_NOTIFICATIONS_AS_READ__FAILURE';
const MARK_ALL_NOTIFICATIONS_AS_READ__STARTED = 'MARK_ALL_NOTIFICATIONS_AS_READ__STARTED';
const MARK_ALL_NOTIFICATIONS_AS_READ__SUCCESS = 'MARK_ALL_NOTIFICATIONS_AS_READ__SUCCESS';
const TOGGLE_NOTIFICATIONS_POPUP = 'TOGGLE_NOTIFICATIONS_POPUP';


function fetchAllNotificationsFailure(error) {
  return { type: FETCH_ALL_NOTIFICATIONS__FAILURE, error };
}

function fetchAllNotificationsStarted() {
  return { type: FETCH_ALL_NOTIFICATIONS__STARTED };
}

function fetchAllNotificationsSuccess(resp) {
  return { type: FETCH_ALL_NOTIFICATIONS__SUCCESS, resp };
}

function fetchRecentNotificationsFailure(error) {
  return { type: FETCH_RECENT_NOTIFICATIONS__FAILURE, error };
}

function fetchRecentNotificationsStarted() {
  return { type: FETCH_RECENT_NOTIFICATIONS__STARTED };
}

function fetchRecentNotificationsSuccess(resp) {
  return { type: FETCH_RECENT_NOTIFICATIONS__SUCCESS, resp };
}

function fetchUnreadNotificationsCountFailure(error) {
  return { type: FETCH_UNREAD_NOTIFICATIONS_COUNT__FAILURE, error };
}

function fetchUnreadNotificationsCountSuccess(resp) {
  return { type: FETCH_UNREAD_NOTIFICATIONS_COUNT__SUCCESS, resp };
}

function markAllNotificationsAsReadFailure(error) {
  return { type: MARK_ALL_NOTIFICATIONS_AS_READ__FAILURE, error };
}

function markAllNotificationsAsReadStarted() {
  return { type: MARK_ALL_NOTIFICATIONS_AS_READ__STARTED };
}

function markAllNotificationsAsReadSuccess(resp) {
  return { type: MARK_ALL_NOTIFICATIONS_AS_READ__SUCCESS, resp };
}

function toggleNotificationsPopup() {
  return { type: TOGGLE_NOTIFICATIONS_POPUP };
}


export {
  FETCH_ALL_NOTIFICATIONS__FAILURE,
  FETCH_ALL_NOTIFICATIONS__STARTED,
  FETCH_ALL_NOTIFICATIONS__SUCCESS,
  FETCH_RECENT_NOTIFICATIONS__FAILURE,
  FETCH_RECENT_NOTIFICATIONS__STARTED,
  FETCH_RECENT_NOTIFICATIONS__SUCCESS,
  FETCH_UNREAD_NOTIFICATIONS_COUNT__FAILURE,
  FETCH_UNREAD_NOTIFICATIONS_COUNT__SUCCESS,
  MARK_ALL_NOTIFICATIONS_AS_READ__FAILURE,
  MARK_ALL_NOTIFICATIONS_AS_READ__STARTED,
  MARK_ALL_NOTIFICATIONS_AS_READ__SUCCESS,
  TOGGLE_NOTIFICATIONS_POPUP,
  fetchAllNotificationsFailure,
  fetchAllNotificationsStarted,
  fetchAllNotificationsSuccess,
  fetchRecentNotificationsFailure,
  fetchRecentNotificationsStarted,
  fetchRecentNotificationsSuccess,
  fetchUnreadNotificationsCountFailure,
  fetchUnreadNotificationsCountSuccess,
  markAllNotificationsAsReadFailure,
  markAllNotificationsAsReadStarted,
  markAllNotificationsAsReadSuccess,
  toggleNotificationsPopup,
};
