import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Provider, Header, Body } from 'reactabular-table';

import CrawlingSettingsEditBtnComponent from './CrawlingSettingsEditBtnComponent';
import CrawlingEnabledSwitchComponent from './CrawlingEnabledSwitchComponent';

import {
  crawlersShape,
  marketerShape,
  subscriptionPlanShape,
  subscriptionShape,
  optionsMap,
} from 'common/prop_types_shapes';

import { translate } from 'common/i18n';

import { textColumn } from 'common/tables/columns';
import columnsGenerator from 'common/tables/columns_generator';

import './crawlingSettingsTable.scss';

class CrawlerSettingsTable extends Component {
  static propTypes = {
    crawlers: crawlersShape,
    currentMarketer: marketerShape, // eslint-disable-line react/no-unused-prop-types
    currentPlan: subscriptionPlanShape, // eslint-disable-line react/no-unused-prop-types
    currentSubscription: subscriptionShape,
    onClickUpgradeSubscription: PropTypes.func,
    onCrawlingEnabledToggle: PropTypes.func,
    onEditSettingsClick: PropTypes.func,
    options: optionsMap,
  };

  constructor(props) {
    super(props);

    this.state = {
      props,
      columns: this.generateColumns(),
      rows: this.generateRows(props),
      generateColumns: this.generateColumns,
      generateRows: this.generateRows,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const needToUpdState = !isEqual(nextProps, prevState.props);

    if (needToUpdState) {
      return {
        columns: prevState.generateColumns(),
        rows: prevState.generateRows(nextProps),
        props: nextProps,
      };
    }

    return null;
  }

  generateRows = (props) => {
    const { currentMarketer, currentPlan, currentSubscription } = props;

    const account = currentMarketer.get('title');
    const subscriptionType = currentPlan.get('title');

    const backlinksAnalyzed = `${currentSubscription.get('crawlingsCount', 0)}\
      / ${currentSubscription.get('allowedCrawlingsCount', 0)}`;

    const scheduledCrawlingInterval = currentMarketer.get('scheduled_crawling_interval');
    const crawlingEnabled = currentMarketer.get('crawling_enabled');

    const row = {
      account,
      backlinksAnalyzed,
      crawlingEnabled,
      scheduledCrawlingInterval,
      subscriptionType,
    };

    return [row];
  };

  generateColumns = () => {
    return columnsGenerator([
      textColumn({
        name: 'account',
        headerLabel: translate('crawlerSettings.account.title'),
        className: 'crawler-settings-table__column',
        showTooltip: true,
      }),
      textColumn({
        name: 'crawlerStatus',
        headerLabel: translate('crawlerSettings.crawlerStatus.title'),
        cellValueFormatter: this.crawlerStatusValueFormatter,
        className: 'crawler-settings-table__column',
        showTooltip: true,
      }),
      textColumn({
        name: 'subscriptionType',
        headerLabel: translate('crawlerSettings.subscriptionType.title'),
        className: 'crawler-settings-table__column',
      }),
      textColumn({
        name: 'backlinksAnalyzed',
        headerLabel: translate('crawlerSettings.backlinksAnalyzed.title'),
        className: 'crawler-settings-table__column',
      }),
      textColumn({
        name: 'scheduledCrawlingInterval',
        headerLabel: translate('crawlerSettings.scheduledCrawlingInterval.title'),
        cellValueFormatter: this.scheduledCrawlingIntervalValueFormatter,
        className: 'crawler-settings-table__column',
      }),
      textColumn({
        name: 'editSettings',
        headerLabel: translate('crawlerSettings.editSettings.title'),
        cellValueFormatter: this.editBtn,
        className: 'crawler-settings-table__column-action',
      }),
      textColumn({
        name: 'crawlingEnabled',
        headerLabel: translate('crawlerSettings.crawlingEnabled.title'),
        cellValueFormatter: this.crawlingEnabledSwitcher,
        className: 'crawler-settings-table__column-action',
      }),
    ]);
  };

  crawlerStatusValueFormatter = () => {
    const { crawlers, currentSubscription, onClickUpgradeSubscription } = this.props;

    const canUseCrawlerInSubscription = currentSubscription.getIn([
      'subscriptionPolicies',
      'crawlers',
      'canUse',
    ]);
    const crawlersStatus = crawlers.get('status');

    const crawlerStatusText = canUseCrawlerInSubscription
      ? crawlersStatus
      : translate('crawlerSettings.pleaseUpgrade');

    return canUseCrawlerInSubscription ? (
      crawlerStatusText
    ) : (
      <a href="#" onClick={onClickUpgradeSubscription}>
        {crawlerStatusText}
      </a>
    );
  };

  scheduledCrawlingIntervalValueFormatter = (value) => {
    const { options } = this.props;

    return options.getIn(['crawlingIntervalOptions', value, 'label']);
  };

  editBtn = () => {
    const { currentSubscription, onEditSettingsClick } = this.props;
    const canUseCrawlerInSubscription = currentSubscription.getIn([
      'subscriptionPolicies',
      'crawlers',
      'canUse',
    ]);

    return (
      <CrawlingSettingsEditBtnComponent
        isDisabled={!canUseCrawlerInSubscription}
        onClick={onEditSettingsClick}
      />
    );
  };

  crawlingEnabledSwitcher = (value) => {
    const { currentSubscription } = this.props;
    const canUseCrawlerInSubscription = currentSubscription.getIn([
      'subscriptionPolicies',
      'crawlers',
      'canUse',
    ]);

    return (
      <CrawlingEnabledSwitchComponent
        isDisabled={!canUseCrawlerInSubscription}
        value={value}
        onClick={this.handleCrawlingEnabledClick}
      />
    );
  };

  handleCrawlingEnabledClick = (value) => {
    const enabled = value === 'right';

    this.props.onCrawlingEnabledToggle({ crawling_enabled: enabled });
  };

  render() {
    const { columns, rows } = this.state;

    return (
      <div>
        <div className="crawler-settings-table__wrapper">
          <Provider className="crawler-settings-table__all" columns={columns}>
            <Header />
            <Body rows={rows} rowKey="account" />
          </Provider>
        </div>
      </div>
    );
  }
}

export default CrawlerSettingsTable;
