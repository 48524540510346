export const DEFAULT_PROSPECT_ATTRIBUTES = [
  'email',
  'firstName',
  'lastName',
  'organization',
  'domain',
  'position',
  'role',
  'phone',
];
