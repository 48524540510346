import pluralize from 'pluralize';

export default {
  backlinks: {
    breadcrumbsTitle: 'Backlinks',
    buttons: {
      addBacklinks: 'Add Backlinks',
      exportToCsv: 'Export to CSV',
    },
    table: {
      backlinksCount: (backlinksCount) => {
        return `${pluralize('Backlink', backlinksCount, true)}`;
      },
      dataNotReadyYet: {
        importingInProgress: 'Importing the backlinks...',
        crawlingInProgress: 'Crawling the backlinks...',
      },
      headers: {
        anchorText: 'Anchor Text',
        ahrefsRank: 'UR',
        ahrefsDomainRating: 'DR',
        datesColumn: {
          firstSeen: 'First Seen',
          lastChecked: 'Last Checked',
        },
        // mozDomainAuthority: 'DA',
        // mozPageAuthority: 'PA',
        //mozSubdomainSpamScore: 'Spam Score',
        publishedLink: 'Backlink',
        source: 'Source',
      },
      blankState: {
        crawlingInProgress: {
          title: 'Backlinks Crawling In Progress',
          body: () =>
            `Linkio crawls your backlinks and checks their index status to provide you with freshest data, stay tuned!`,
        },
        importingInProgress: {
          title: 'Backlinks Import In Progress',
          body: () =>
            `Linkio crawls your backlinks and checks their index status to provide you with freshest data, stay tuned!`,
        },
        noBacklinks: {
          title: 'What Linkio Does',
          body: () => `Once you import your backlinks (if you have any), Linkio will crawl and check \
          their index status. Get started by clicking the 'Add Backlinks' button.`,
        },
        noFilteredBacklinks: {
          title: 'No links match the selected criteria',
          body: () => `Please try selecting different filters.`,
        },
        noReDiscoveredBacklinks: {
          title: 'No Re-discovered Backlinks',
          body: (dateStart, dateEnd) =>
            `Linkio didn't find any re-discovered backlinks for the period of ${dateStart} to ${dateEnd}`,
        },
        noLostBacklinks: {
          title: 'No Lost Backlinks',
          body: (dateStart, dateEnd) =>
            `Linkio didn't find any lost backlinks for the period of ${dateStart} to ${dateEnd}`,
        },
        noNewBacklinks: {
          title: 'No New Backlinks',
          body: (dateStart, dateEnd) =>
            `Linkio didn't find any new backlinks for the period of ${dateStart} to ${dateEnd}`,
        },
      },
    },
    title: 'Backlink Module',
  },
};
