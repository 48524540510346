import React from 'react';
import PropTypes from 'prop-types';

import { optionsArray } from 'common/prop_types_shapes';

import Select from 'components_linkio/Select';
import Breadcrumbs from 'components/NewBreadcrumbs';

const BreadcrumbsEoCampaignsSelectComponent = ({
  eoCampaignOptions,
  eoCampaignTitle,
  onCloseMenu,
  onSelectChange,
}) => {
  function breadcrumbsSelect() {
    return (
      <div className="breadcrumbs-menu">
        <Select
          autoFocus
          className="breadcrumbs-menu__selector"
          onBlur={onCloseMenu}
          onChange={onSelectChange}
          openMenuOnFocus
          options={eoCampaignOptions}
          skinOptions={{ asDropdown: true, inTable: false }}
        />
      </div>
    );
  }

  return (
    <Breadcrumbs.SelectItem select={breadcrumbsSelect()}>{eoCampaignTitle}</Breadcrumbs.SelectItem>
  );
};

BreadcrumbsEoCampaignsSelectComponent.propTypes = {
  eoCampaignOptions: optionsArray,
  eoCampaignTitle: PropTypes.string,
  onCloseMenu: PropTypes.func,
  onSelectChange: PropTypes.func,
};

export default BreadcrumbsEoCampaignsSelectComponent;
