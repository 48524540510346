import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoCustomFieldFormPopupComponent from './EoCustomFieldFormPopupComponent';

import { eoCustomFieldShape } from 'common/propTypesShapes/eoCustomFields';

import { createEoCustomField, updateEoCustomField } from 'api/eoCustomField';

import ConnectStoreHOC from 'startup/connect_store_hoc';

const EoCustomFieldFormPopupContainer = ({
  dispatch,
  onClose,
  onSubmitCb,
  tableRowData: eoCustomField,
  predefinedEoCustomFieldName,
}) => {
  async function handleSave(eoCustomFieldFormData) {
    const resp = eoCustomField
      ? await updateEoCustomField(dispatch, eoCustomFieldFormData)
      : await createEoCustomField(dispatch, eoCustomFieldFormData);

    return resp;
  }

  return (
    <EoCustomFieldFormPopupComponent
      predefinedEoCustomFieldName={predefinedEoCustomFieldName}
      eoCustomField={eoCustomField}
      onCancel={onClose}
      onSave={handleSave}
      onSubmitCb={onSubmitCb}
    />
  );
};

EoCustomFieldFormPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  onSubmitCb: PropTypes.func,
  predefinedEoCustomFieldName: PropTypes.string,
  tableRowData: eoCustomFieldShape,
};

export default compose(ConnectStoreHOC, connect())(EoCustomFieldFormPopupContainer);
