import React from 'react';
import PropTypes from 'prop-types';

import { cloneDeep, isEmpty, debounce } from 'lodash';

import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import Select from 'components_linkio/Select';
import KeywordsManager from 'components_linkio/KeywordsManager';
import InfoIcon from 'common/icons/info';
import MessageComponent from 'components_linkio/message_component';

import { draftBrandPageShape, optionsMap } from 'common/prop_types_shapes';

import './atpSimpleModePopup.scss';

const initialState = {
  errors: {},
  id: null,
  link: '',
  newKeywords: [],
  pageTypeId: null,
};

// Update the state depending on if another draftBrandPage is passed into the component (user
// entered another link and it matched another existing brand page) or brand page is the same
// but keywords changed.

function getStateIfLinkExists(previousState, draftBrandPage) {
  const { id: currentId } = previousState;
  const { id: newPageId, keywords } = draftBrandPage;

  if (currentId === newPageId) {
    return { ...previousState, keywords };
  }

  return { ...previousState, ...draftBrandPage };
}

function reducer(state, action) {
  const { value, type } = action;

  let newState = cloneDeep(state);

  switch (type) {
    case 'updateStateDependingOnPageExistance': {
      const { link, newKeywords } = newState;

      // Check if the user entered a link that matches one of existing brand pages (then the
      // value is that page) or doesn't match any of existing links (the value is an empty object)

      const updatedState = isEmpty(value)
        ? { ...initialState, link, newKeywords }
        : getStateIfLinkExists(newState, value);

      newState = updatedState;

      break;
    }

    default: {
      newState[type] = value;
      break;
    }
  }

  return newState;
}

const AtpSimpleModePopupComponent = ({
  draftBrandPage,
  handleCheckForExistingPageByLink,
  handleSubmit,
  handleUpdateExistingKeywords,
  immutablePageTypeOptions,
  isLinkFormatErrorExist,
  isLinkInputDisabled,
  metaKeywordsOfNonExistingPageUrl,
  onClose,
  showNewKeywordsAddedMessage,
  titleTagsOfNonExistingPageUrl,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [keywordsManagerKey, setKeywordsManagerKey] = React.useState(0);
  const [handleCheckForExistingPageByLinkDebounced] = React.useState(() =>
    debounce(handleCheckForExistingPageByLink, 1000),
  );

  React.useEffect(() => {
    dispatch({ type: 'updateStateDependingOnPageExistance', value: draftBrandPage });
  }, [dispatch, draftBrandPage]);

  const remountKeywordsManager = () => setKeywordsManagerKey(Math.random);

  React.useEffect(() => {
    // We need to remount the keywords manager when changes existing page matching the link to
    // update existing keywords in the keyword manager.

    remountKeywordsManager();
  }, [state.id]);

  const { errors, id, link, newKeywords, pageTypeId } = state;
  const { keywords = [], title = [], status } = draftBrandPage;
  const metaKeywords = metaKeywordsOfNonExistingPageUrl;

  const isInAtp = status === 'in_atp';

  const pageTypeOptions = immutablePageTypeOptions.toList().toJS();

  const isAddingPage = isEmpty(draftBrandPage);

  const titleTagsOfExistingDraftBrandPage = title.length ? title[0] : '';
  const titleTagsString = isAddingPage
    ? titleTagsOfNonExistingPageUrl
    : titleTagsOfExistingDraftBrandPage;

  // Check if the user added new keywords into the form. If the page is already in ATP - the user
  // can only manage keywords and nothing more. Thus button is disabled in this mode if no new
  // keywords are entered into the textarea. Deleting and editing existing keywords doesn't need
  // submitting and is saved by default.

  const isNewKeywordsExist = !!newKeywords.length;
  const isEnableSubmitButton = isInAtp ? isNewKeywordsExist : true;

  function setLinkValue(event) {
    const newValue = event.target.value;

    // If the entered link matches one of the existing brand pages this page should be set as
    // current, passed into this component and merged into it's state.

    if (newValue !== link) {
      dispatch({ type: 'link', value: newValue });
      handleCheckForExistingPageByLinkDebounced(newValue);
    }
  }

  function onSelectPageType(option) {
    const value = option ? option.value : null;

    if (pageTypeId !== value) {
      dispatch({ type: 'pageTypeId', value });
    }
  }

  function handleChangeKeywords(keywordsFromKeywordsManager) {
    // This function from keywords manager gets:
    //   1) all keywords entered into the textarea for adding (newKeywords),
    //   2) edited keyword if there's any (existingKeywords) - if not, the array is empty.

    const existingKeywords = keywordsFromKeywordsManager.filter(({ id }) => !!id);
    const newKeywordsFromKeywordsManager = keywordsFromKeywordsManager.filter(({ id }) => !id);
    const isExistingKeywordsChanged = !!existingKeywords.length;

    // If an existing keyword was set to empty string - it's updating shouldn't proceed

    const isUpdatedKeywordCorrect = isExistingKeywordsChanged && existingKeywords[0].label !== '';
    const isExistingKeywordsNeedUpdate = isExistingKeywordsChanged && isUpdatedKeywordCorrect;

    // Save the updated existing keyword to DB if everything is OK

    isExistingKeywordsNeedUpdate && handleUpdateExistingKeywords(existingKeywords);

    dispatch({ type: 'newKeywords', value: newKeywordsFromKeywordsManager });
  }

  function handleClickSubmitButton(event) {
    event.preventDefault();

    handleSubmit(state, isAddingPage, event);

    // As the in-atp-page can be done nothing with in the popup except keywords editing/adding/
    // /removing - the submit button only adds new keywords, doesn't redirect to ATP page, keeps
    // the user in Simple Mode, adds new keywords to existing keywords and clears the textarea for
    // adding keywords. The success message should inform about what happened to be clear.

    if (isInAtp) {
      showNewKeywordsAddedMessage(newKeywords.length);
      dispatch({ type: 'newKeywords', value: [] });
      remountKeywordsManager();
    }
  }

  return (
    <Modal show className="atp-simple-mode-popup">
      <Modal.Header>
        <Modal.Title>
          {translate('bulkPagesSetup.atpSimpleModePopup.title')(isAddingPage, isInAtp)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isInAtp && (
          <div className="atp-simple-mode-popup__in-atp_hint">
            <InfoIcon className="atp-simple-mode-popup__in-atp_info-icon" />
            <span>{translate('bulkPagesSetup.atpSimpleModePopup.hints.isInAtp')}</span>
          </div>
        )}

        <InputComponent
          autoFocus
          isMandatory
          isDisabled={isLinkInputDisabled}
          label={translate(`bulkPagesSetup.atpSimpleModePopup.inputs.linkInput.label`)}
          message={{ type: 'error', text: errors.link }}
          onInputChange={setLinkValue}
          type="text"
          value={link}
        />

        <div className="atp-simple-mode-popup__link-messages_wrapper">
          <MessageComponent
            message={{
              type: 'hint',
              text: translate(`bulkPagesSetup.atpSimpleModePopup.hints.linkInput`),
            }}
          />

          {titleTagsString && (
            <MessageComponent
              message={{
                type: 'hint',
                text: translate('bulkPagesSetup.atpSimpleModePopup.hints.title')(titleTagsString),
              }}
            />
          )}

          {isLinkFormatErrorExist && (
            <MessageComponent message={{ type: 'error', text: translate('errors.invalidUrl') }} />
          )}
        </div>

        <KeywordsManager
          key={keywordsManagerKey}
          keywords={keywords}
          newKeywords={newKeywords}
          metaKeywords={metaKeywords}
          onChangeKeywords={handleChangeKeywords}
          pageId={id}
          targetAppModule="bulkPagesSetup"
        />

        {!isInAtp && (
          <Select
            className="atp-simple-mode-popup__page-type-select"
            isClearable={false}
            label={translate(`bulkPagesSetup.atpSimpleModePopup.inputs.pageType.label`)}
            onChange={onSelectPageType}
            options={pageTypeOptions}
            value={
              pageTypeOptions.find(
                ({ value }) => value === (pageTypeId && pageTypeId.toString()),
              ) || null
            }
            wrapperClassName={'atp-simple-mode-popup__mb-1'}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="atp-simple-mode-popup__footer">
          <div className="atp-simple-mode-popup__btn-group">
            <div className="atp-simple-mode-popup__btn">
              <ButtonComponent isWhite onClick={onClose}>
                {translate('bulkPagesSetup.atpSimpleModePopup.buttons.cancel')(isInAtp)}
              </ButtonComponent>
            </div>

            <div className="atp-simple-mode-popup__btn">
              <SubmitButtonComponent
                isDisabled={!isEnableSubmitButton}
                isGreen
                onClick={handleClickSubmitButton}
                text={translate('bulkPagesSetup.atpSimpleModePopup.buttons.submit')(
                  isAddingPage,
                  isInAtp,
                  isNewKeywordsExist,
                )}
              />
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AtpSimpleModePopupComponent.propTypes = {
  draftBrandPage: draftBrandPageShape,
  handleCheckForExistingPageByLink: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  handleUpdateExistingKeywords: PropTypes.func,
  immutablePageTypeOptions: optionsMap,
  isLinkFormatErrorExist: PropTypes.bool,
  isLinkInputDisabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showNewKeywordsAddedMessage: PropTypes.func,
  titleTagsOfNonExistingPageUrl: PropTypes.string,
};

export default AtpSimpleModePopupComponent;
