import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { cloneDeep, isEmpty } from 'lodash';

import GoogleDriveFilesList from './GoogleDriveFilesList';
import ProspectsRemappingList from './ProspectsRemappingList';

import { saveAs } from 'common/utils';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import GoogleDriveIcon from 'common/icons/GoogleDriveIcon';
import ImportCsvComponent from 'components_linkio/ImportCsv';
import ImportIcon from 'common/icons/import';
import MessageComponent from 'components_linkio/message_component';
import OverlayWithTooltipComponent from 'components/overlay_with_tooltip_component';

import './importProspectsPopup.scss';

function reducer(state, action) {
  const { value, type } = action;

  const newState = cloneDeep(state);

  switch (type) {
    case 'setSpreadsheetAttrs': {
      newState.spreadsheetAttrs = value;
      break;
    }
    default:
      newState[type] = value;
      break;
  }

  return newState;
}

const initialState = {
  csvFile: '',
  csvImportMessageType: 'hint',
  currentImportStep: 'chooseImportOption',
  googleDriveFiles: [],
  isLoading: false,
  spreadsheetAttrs: [],
};

const CSV_FILE_EXAMPLE_ROWS =
  'Email address,First name,Last name,Organization,Domain name,Position,Role,Phone number';

function downloadCsvFileExample() {
  saveAs(
    CSV_FILE_EXAMPLE_ROWS,
    translate('prospects.importProspectsPopup.importCsv.exampleCsvFileName'),
    'text/csv;charset=utf-8',
  );
}

const ImportProspectsPopupComponent = ({
  googleDriveAccessedCb,
  isGoogleDriveAccessed,
  onCancel,
  onFetchGoogleDriveFiles,
  onImportCsv,
  onImportGoogleDriveFile,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const fileS3IdRef = React.useRef('');

  React.useEffect(() => {
    // If Google Drive is just accessed then start fetching Google Drive files immediately
    // and reset query params to get rid of the 'drive_access=true' parameter
    if (isGoogleDriveAccessed) {
      handleFetchGoogleDriveFiles();
      googleDriveAccessedCb();
    }
  });

  function handleCsvFileUpload(csvFile) {
    // restrict file size to 1.5 MB
    if (csvFile && csvFile.size > 1572864) {
      dispatch({ type: 'csvImportMessageType', value: 'error' });
      dispatch({ type: 'csvFile', value: '' });
      return;
    }

    dispatch({ type: 'csvImportMessageType', value: 'hint' });
    dispatch({ type: 'csvFile', value: csvFile });
  }

  const handleChangeCurrentImportStep = (importStep) => () => {
    dispatch({ type: 'currentImportStep', value: importStep });
  };

  const {
    csvFile,
    csvImportMessageType,
    currentImportStep,
    googleDriveFiles,
    isLoading,
    spreadsheetAttrs,
  } = state;

  const isChooseImportOptionStep = currentImportStep === 'chooseImportOption';
  const isGoogleDriveImportStep = currentImportStep === 'googleDriveImport';
  const isImportCsvStep = currentImportStep === 'importCsv';
  const isRemappingCsvStep = currentImportStep === 'remappingCsv';

  const processSpreadsheetFileResp = (resp) => {
    const { type, errors, fields = [] } = resp;

    if (type === 'error' || !isEmpty(errors)) {
      return;
    }

    dispatch({ type: 'setSpreadsheetAttrs', value: fields });
    dispatch({ type: 'currentImportStep', value: 'remappingCsv' });
  };

  async function handleFetchGoogleDriveFiles() {
    const googleIntegrationIsTemporaryDisabledDueToVerificationProcess = true;
    if (googleIntegrationIsTemporaryDisabledDueToVerificationProcess) {
      return;
    }

    handleChangeCurrentImportStep('googleDriveImport')();
    dispatch({ type: 'isLoading', value: true });

    const googleDriveFiles = await onFetchGoogleDriveFiles();
    dispatch({ type: 'isLoading', value: false });

    dispatch({ type: 'googleDriveFiles', value: googleDriveFiles });
    handleChangeCurrentImportStep('googleDriveImport')();
  }

  const handleSelectGoogleDriveFile = (fileId) => async () => {
    dispatch({ type: 'isLoading', value: true });

    const resp = await onImportGoogleDriveFile(fileId);
    fileS3IdRef.current = resp.file_s3_id;
    dispatch({ type: 'isLoading', value: false });

    processSpreadsheetFileResp(resp);
  };

  async function handleImportCsv() {
    dispatch({ type: 'isLoading', value: true });

    const resp = await onImportCsv(csvFile);
    fileS3IdRef.current = resp.file_s3_id;
    dispatch({ type: 'isLoading', value: false });

    processSpreadsheetFileResp(resp);
  }

  const popupClasses = cx('import-prospects-popup', {
    'import-prospects-popup_wide': isRemappingCsvStep || isGoogleDriveImportStep,
  });

  const fileS3Id = fileS3IdRef.current;

  return (
    <Modal show className={popupClasses}>
      <Modal.Header>
        <Modal.Title>{translate('prospects.importProspectsPopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="import-prospects-popup__body">
          {isChooseImportOptionStep && (
            <div className="import-prospects-popup__import-options">
              {/* remove Tooltip when google drive feature is ready */}
              <OverlayWithTooltipComponent
                tooltip={{
                  text: 'Google Drive import coming soon',
                  placement: 'top',
                  color: 'black',
                }}
              >
                <div
                  className="import-prospects-popup__import-options-item import-prospects-popup__import-options-item__gdrive"
                  onClick={handleFetchGoogleDriveFiles}
                >
                  <GoogleDriveIcon />
                  <p>{translate('prospects.importProspectsPopup.importFromDrive.title')}</p>
                </div>
              </OverlayWithTooltipComponent>

              <div
                className="import-prospects-popup__import-options-item"
                onClick={handleChangeCurrentImportStep('importCsv')}
              >
                <ImportIcon />
                <p>{translate('prospects.importProspectsPopup.importCsv.title')}</p>
              </div>
            </div>
          )}

          {isGoogleDriveImportStep && (
            <GoogleDriveFilesList
              googleDriveFiles={googleDriveFiles}
              isLoading={isLoading}
              onSelect={handleSelectGoogleDriveFile}
            />
          )}

          {isImportCsvStep && (
            <>
              <p>{translate('prospects.importProspectsPopup.importCsv.subtitle')}</p>

              <ImportCsvComponent onFileUpload={handleCsvFileUpload} />
              <MessageComponent message={{ type: csvImportMessageType }}>
                {translate('prospects.importProspectsPopup.importCsv.hint.firstPart')}
                <span
                  onClick={downloadCsvFileExample}
                  className="import-prospects-popup__example-csv-file text_link"
                >
                  {translate('prospects.importProspectsPopup.importCsv.hint.exampleCsvFilePart')}
                </span>
                {translate('prospects.importProspectsPopup.importCsv.hint.secondPart')}
              </MessageComponent>
            </>
          )}

          {isRemappingCsvStep && (
            <ProspectsRemappingList
              fileS3Id={fileS3Id}
              onCancel={onCancel}
              spreadsheetAttrs={spreadsheetAttrs}
            />
          )}
        </div>

        {!isRemappingCsvStep && (
          <>
            <hr className="import-prospects-popup__modal-divider" />

            <div className="import-prospects-popup__btn-group">
              <div className="import-prospects-popup__btn">
                <ButtonComponent isWhite onClick={onCancel}>
                  {translate('prospects.importProspectsPopup.buttons.cancel')}
                </ButtonComponent>
              </div>

              {isImportCsvStep && (
                <div className="import-prospects-popup__btn">
                  <ButtonComponent
                    isBlue
                    isDisabled={!csvFile}
                    isLoading={isLoading}
                    onClick={handleImportCsv}
                  >
                    {translate('prospects.importProspectsPopup.buttons.importCsv')}
                  </ButtonComponent>
                </div>
              )}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

ImportProspectsPopupComponent.propTypes = {
  googleDriveAccessedCb: PropTypes.func,
  isGoogleDriveAccessed: PropTypes.bool,
  onCancel: PropTypes.func,
  onFetchGoogleDriveFiles: PropTypes.func,
  onImportCsv: PropTypes.func,
  onImportGoogleDriveFile: PropTypes.func,
};

export default ImportProspectsPopupComponent;
