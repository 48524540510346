import pluralize from 'pluralize';

import { buildWordInPastTenseWithPluralization } from 'common/utils';

function buildNotAddedToDripListText(notAddedToDripListCount) {
  if (notAddedToDripListCount === 0 || !notAddedToDripListCount) {
    return '';
  }

  return `${pluralize(
    'prospect',
    notAddedToDripListCount,
    true,
  )} not added to the selected Drip List (already involved in a campaign or already included into this drip list).`;
}

function buildEoDripListsSkippedText(skippedEoDripListsCount) {
  if (skippedEoDripListsCount === 0 || !skippedEoDripListsCount) {
    return '';
  }

  return `Not added to ${pluralize(
    'Drip List',
    skippedEoDripListsCount,
    true,
  )} (already involved in a campaign or already included into this drip list).`;
}

function buildNotStartedCampaignText(skippedProspectsCount) {
  if (skippedProspectsCount === 0 || !skippedProspectsCount) {
    return '';
  }

  return `Campaign was not started for ${pluralize(
    'prospect',
    skippedProspectsCount,
    true,
  )} (already involved in a campaign).`;
}

function buildImportProspectsFromCsvText(uniqEntriesCount, dupeEntriesCount) {
  return `Successfully imported ${pluralize('prospect', uniqEntriesCount, true)},
  ${pluralize('duplicate', dupeEntriesCount, true)} skipped.`;
}

export default {
  prospects: {
    breadcrumbsTitle: 'Prospects List',
    bulkActions: {
      addProspect: 'Add Prospect',
      addToEoDripList: 'Add to Drip List',
      importProspects: 'Import Prospects',
      removeFromEoDripList: 'Remove from Drip List',
      startEoCampaign: 'Start Campaign',
      addToDripListPopup: {
        title: 'Pick a Drip List',
        addToDripList: 'Add to this Drip List',
      },
      removeFromEoDripListPopup: {
        title: 'Pick a Drip List',
        removeFromEoDripList: 'Remove from this Drip List',
        removeFromAllEoDripLists: 'Remove from all Drip Lists',
      },
      startEoCampaignPopup: {
        title: 'Pick a Campaign',
        startEoCampaign: 'Start campaign',
      },
      validateEmailAddresses: 'Verify email deliverability',
    },
    importProspectsPopup: {
      buttons: {
        cancel: 'Cancel',
        importCsv: 'Import file and preview',
        importProspects: 'Import Prospects',
      },
      googleDriveFile: {
        owner: 'Document owner:',
        lastChanged: 'Last changed:',
        select: 'Select',
        view: 'View...',
      },
      importCsv: {
        hint: {
          firstPart: 'Maximum file size is 1.5 MB. Download this sample ',
          exampleCsvFilePart: '.CSV template',
          secondPart: ' or map columns in next step.',
        },
        subtitle: 'Please choose CSV file',
        title: 'Import from CSV file',
        exampleCsvFileName: 'prospects_import_example.csv',
      },
      importFromDrive: {
        filesCount: (count) => {
          if (count === 0) {
            return "We didn't find any Spreadsheet in your Drive account";
          }

          return `We found ${pluralize(
            'Spreadsheet',
            count,
            true,
          )}, please select the one to use for import.`;
        },
        title: 'Import from Google Drive',
        loading: 'Processing Google Drive import, please wait...',
      },
      remappingCsv: {
        prospectAttrOptions: {
          default: 'Default attributes',
          customFields: 'Custom fields',
        },
        updateExisting: {
          title: 'Update prospect if exists (slows the import process)',
          hint: 'By default, prospect who already exist are ignored. If you want the prospects \
            to be updated at import, please tick this box',
        },
        table: {
          title: 'Prospect fields',
          headers: {
            spreadsheetColumn: 'Spreadsheet columns',
            prospectAttribute: 'Prospect info',
            spreadsheetPreviewColumn: 'Preview',
          },
          doNotImport: "Don't import",
          errors: {
            email: 'Email Address must be selected',
          },
          createEoCustomField: 'Custom field',
        },
        dripList: {
          label: 'Drip list',
          title: 'Add to Drip list (Optional)',
        },
        prospectAlreadyExists: {
          title: 'If prospect already exists in the system...',
          duplicateModes: {
            reject: 'Skip duplicates',
            upload: 'Upload duplicates with new data',
            update_existing: 'Update existing prospect with new data',
          },
        },
      },
      title: 'Import Prospects',
    },
    messages: {
      cantDeleteProspectsFromDripList: "Can't delete Prospects from Drip List",
      cantAddProspectsToDripList: "Can't add Prospects to Drip List",
      cantStartCampaignForProspects: "Can't start the Campaign for Prospects",
      cantParseProspectsCsv: ({ errors }) => `Can't import prospects: ${errors}`,
      cantUnsubscribeProspect: "Can't unsubscribe",
      prospectEmailsValidationStartSuccess: 'Successfully started deliverability verification',
      prospectEmailsValidationStartFailure: "Can't start deliverability verification",
      createSuccess: (skippedEoDripListsCount) => {
        const skippedEoDripListsText = buildEoDripListsSkippedText(skippedEoDripListsCount);

        return `Successfully added a prospect. ${skippedEoDripListsText}`;
      },
      deleteSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization('Prospect', count)} deleted successfully.`;
      },
      addProspectsToDripListSuccess: (addedProspectsCount, skippedProspectsCount) => {
        const notAddedToDripListText = buildNotAddedToDripListText(skippedProspectsCount);

        return `Successfully added ${pluralize(
          'prospect',
          addedProspectsCount,
          true,
        )} to drip list. ${notAddedToDripListText}`;
      },
      startCampaignForProspectsSuccess: (addedProspectsCount, skippedProspectsCount) => {
        const notStartedCampaignText = buildNotStartedCampaignText(skippedProspectsCount);

        return `Successfully started Campaign for ${pluralize(
          'prospect',
          addedProspectsCount,
          true,
        )}. ${notStartedCampaignText}`;
      },
      deleteProspectsFromDripListSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization(
          'Prospect',
          count,
        )} deleted from drip list(s) successfully.`;
      },
      updateSuccess: (skippedEoDripListsCount) => {
        const skippedEoDripListsText = buildEoDripListsSkippedText(skippedEoDripListsCount);

        return `Successfully updated a prospect. ${skippedEoDripListsText}`;
      },
      importStarted: 'Prospects import started',
      csvImportFailure: ({ uniqEntriesCount, dupeEntriesCount, notAddedToDripListCount }) => {
        const notAddedToDripListText = buildNotAddedToDripListText(notAddedToDripListCount);
        const importProspectsFromCsvText = buildImportProspectsFromCsvText(
          uniqEntriesCount,
          dupeEntriesCount,
        );

        return `${importProspectsFromCsvText} ${notAddedToDripListText} Review the errors below, please.`;
      },
      csvImportSuccess: ({ uniqEntriesCount, dupeEntriesCount, notAddedToDripListCount }) => {
        const notAddedToDripListText = buildNotAddedToDripListText(notAddedToDripListCount);
        const importProspectsFromCsvText = buildImportProspectsFromCsvText(
          uniqEntriesCount,
          dupeEntriesCount,
        );

        return `${importProspectsFromCsvText} ${notAddedToDripListText}`;
      },
      googleDriveAccessDenied: "Can't process import. Access to Google Drive was denied.",
    },
    prospectFormPopup: {
      buttons: {
        cancel: 'Cancel',
        submit: 'Save',
      },
      customFields: {
        title: 'Custom fields',
        blankState: "You don't have any custom fields yet.",
      },
      inputs: {
        organization: {
          label: 'Organization',
        },
        domain: {
          label: 'Domain Name',
        },
        email: {
          label: 'Email Address',
        },
        firstName: {
          label: 'First name',
        },
        lastName: {
          label: 'Last name',
        },
        phone: {
          label: 'Phone Number',
        },
        role: {
          label: 'Role',
        },
        position: {
          label: 'Position',
        },
        eoDripList: {
          label: (isExistingProspect) =>
            isExistingProspect ? 'Drip List(s)' : 'Add to Drip List(s)',
        },
      },
      title: (isExistingProspect) => (isExistingProspect ? 'Update Prospect' : 'Add Prospect'),
    },
    table: {
      blankState: {
        body: 'You can import or manually add prospects',
        title: "You don't have any prospects yet",
      },
      dataNotReadyYet: {
        importingInProgress: 'Importing the prospects...',
      },
      headers: {
        organization: 'Organization',
        domain: 'Domain Name',
        email: 'Email Address',
        eoDripListTitle: 'In Drip List',
        lastEoCampaignTitle: 'Last campaign',
        lastName: 'Name',
        position: 'Position',
        updatedAt: 'Last updated',
      },
      prospectsCount: (count) => `${pluralize('Prospect', count, true)}`,
      readOnlyHint: 'Only the first 5 found prospects are shown',
      tooltips: {
        bounced: 'This email is invalid (bounced)',
        ok: 'Email deliverability is valid',
        risky: 'Email deliverability is catch-all',
        invalid: 'Email deliverability is invalid',
        unknown: 'Email deliverability is unknown',
        in_progress: 'Email deliverability is in progress',
        error: "Can't check email deliverability (error)",
        unchecked: 'Email deliverability is unchecked',
      },
    },
    tabs: {
      prospects: 'Prospects',
      eo_blacklisted_domains: 'Blacklisted Domains',
      eo_domains: 'Domains',
      eo_custom_fields: 'Custom Fields',
    },
    unsubscribe: {
      cancel: {
        title: 'You have not been unsubscribed',
      },
      confirmationText: 'Confirm you want to stop receiving emails from this person.',
      confirmButton: 'Unsubscribe',
      success: {
        title: 'Unsubscribed',
        subtitle: 'You have been successfully unsubscribed',
      },
    },
  },
};
