import createChannel from './createChannel';

let callback;

createChannel("UsersChannel", {
  received(data) {
    callback && Reflect.apply(callback, null, [data]);
  },
});

// this callback will be invoked once we receive something over usersChannel
function setReceivedCallback(fn) {
  callback = fn;
}

export { setReceivedCallback };
