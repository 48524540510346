import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';

import { translate } from 'common/i18n';
import { brandPageShape, optionsMap } from 'common/prop_types_shapes';

import { optionsFromImmutable, valueFromImmutable } from 'components_linkio/Select/utils';
import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import Select from 'components_linkio/Select';
import TextAreaComponent from 'components_linkio/textarea_component';

import './editPageComponent.scss';

class EditPageComponent extends React.Component {
  static propTypes = {
    isUpdatingPage: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    page: brandPageShape,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    pageTypeOptions: optionsMap,
  };

  constructor(props) {
    super(props);

    const { page } = props;

    this.state = {
      ...page,
      brandOtherNames: [page.brandName, ...page.brandOtherNames].join('\n'),
      errors: {},
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  handleInputChange = (inputName) => (event) => {
    const { value } = event.target;

    this.setState({ [inputName]: value });
  };

  handleSelectChange = (selectName) => (selectedOption) =>
    this.setState({ [selectName]: selectedOption.value });

  handleCancel = () => this.props.onCancel();

  handleSave = async () => {
    const { errors, type, message } = await this.props.onSave(this.state);

    if (type === 'error' || !isEmpty(errors)) {
      this.setState({ errors: message || errors });
    }
  };

  isLocalPage = () => {
    const { pageTypeId } = this.state;
    const { pageTypeOptions } = this.props;

    const pageTypeLabel = pageTypeOptions.getIn([pageTypeId.toString(), 'label'], '');

    return pageTypeLabel.toLowerCase().includes('local');
  };

  render() {
    const {
      brandHomepage,
      brandOtherNames,
      city,
      errors,
      link,
      pageTypeId,
      product,
      state,
      stateAbbr,
    } = this.state;
    const { isUpdatingPage, pageTypeOptions, pageChildType } = this.props;

    const isBrandPage = pageChildType === 'brandPage';

    const localFieldsVisible = this.isLocalPage() && isBrandPage;

    return (
      <Modal show className="edit-brand-page-component__modal">
        <Modal.Header>
          <Modal.Title>{translate(`${pageChildType}.editPageModal.title`)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{translate(`${pageChildType}.editPageModal.subtitle`)}</p>
          <div className="edit-brand-page-component__input">
            <InputComponent
              autoFocus
              isMandatory
              label={translate(`${pageChildType}.editPageModal.inputs.pageUrl.label`)}
              message={{ type: 'error', text: errors.link }}
              name="link"
              onInputChange={this.handleInputChange('link')}
              onSend={this.handleSave}
              type="text"
              value={link}
            />
          </div>
          <div className="edit-brand-page-component__input">
            <Select
              isMandatory
              label={translate(`${pageChildType}.editPageModal.inputs.pageTypeId.label`)}
              message={{ type: 'error', text: errors.pageTypeId }}
              name="pageTypeId"
              onChange={this.handleSelectChange('pageTypeId')}
              options={optionsFromImmutable(pageTypeOptions)}
              value={valueFromImmutable(pageTypeOptions, pageTypeId)}
            />
          </div>
          {!isBrandPage && (
            <div className="edit-brand-page-component__input edit-brand-page-component__input_big">
              <TextAreaComponent
                explanationMessage={translate('explanationMessages.brand.otherNames')}
                label={translate(`${pageChildType}.editPageModal.inputs.brandOtherNames.label`)}
                message={
                  errors.brandOtherNames && {
                    type: 'error',
                    text: errors.brandOtherNames,
                  }
                }
                onChange={this.handleInputChange('brandOtherNames')}
                value={brandOtherNames}
              />
            </div>
          )}

          {pageChildType === 'competitorsPage' && (
            <div className="new-brand-page-component__input">
              <InputComponent
                isMandatory
                label={translate(`${pageChildType}.editPageModal.inputs.brandHomepage.label`)}
                message={{ type: 'error', text: errors.brandHomepage }}
                name="brandHomepage"
                onInputChange={this.handleInputChange('brandHomepage')}
                onSend={this.handleSave}
                type="text"
                defaultValue={brandHomepage}
              />
            </div>
          )}
          {localFieldsVisible && (
            <>
              <div className="edit-brand-page-component__input">
                <InputComponent
                  label={translate('brandPage.editPageModal.inputs.city.label')}
                  name="city"
                  onInputChange={this.handleInputChange('city')}
                  type="text"
                  value={city || ''}
                />
              </div>
              <div className="edit-brand-page-component__input">
                <InputComponent
                  label={translate('brandPage.editPageModal.inputs.state.label')}
                  name="state"
                  onInputChange={this.handleInputChange('state')}
                  type="text"
                  value={state || ''}
                />
              </div>
              <div className="edit-brand-page-component__input">
                <InputComponent
                  label={translate('brandPage.editPageModal.inputs.stateAbbr.label')}
                  name="stateAbbr"
                  onInputChange={this.handleInputChange('stateAbbr')}
                  type="text"
                  value={stateAbbr || ''}
                />
              </div>
              <div className="edit-brand-page-component__input">
                <InputComponent
                  label={translate('brandPage.editPageModal.inputs.product.label')}
                  name="product"
                  onInputChange={this.handleInputChange('product')}
                  type="text"
                  value={product || ''}
                />
              </div>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="edit-brand-page-component__btn-group">
            <div className="edit-brand-page-component__btn">
              <ButtonComponent isWhite onClick={this.handleCancel}>
                {translate(`${pageChildType}.editPageModal.buttons.cancel`)}
              </ButtonComponent>
            </div>
            <div className="edit-brand-page-component__btn">
              <ButtonComponent
                isGreen
                isInitial
                isLoading={isUpdatingPage}
                onClick={this.handleSave}
              >
                {translate(`${pageChildType}.editPageModal.buttons.save`)}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditPageComponent;
