import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEqual } from 'lodash';

import LabelComponent from '../label_component';
import MessageComponent from '../message_component';
import { hasErrorMessage, hasSuccessMessage, messageIsEmpty } from '../message_utils';

import './input_component.scss';

export default class InputComponent extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    explanationMessage: PropTypes.string,
    icon: PropTypes.func,
    inputRef: PropTypes.func,
    isDisabled: PropTypes.bool,
    isHaveRightIcon: PropTypes.bool,
    isMandatory: PropTypes.bool,
    isMultiline: PropTypes.bool,
    label: PropTypes.string,
    message: PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
      type: PropTypes.oneOf(['success', 'error', 'hint']),
    }),
    onBlur: PropTypes.func,
    onIconClick: PropTypes.func,
    onInputChange: PropTypes.func,
    onSend: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    inputRef: (fn) => fn,
    message: {},
    onIconClick: (fn) => fn,
    onInputChange: (fn) => fn,
  };

  constructor(props) {
    super(props);

    const { message } = props;

    this.state = {
      focused: false,
      message,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const needToUpdState = !isEqual(nextProps.message, prevState.message);

    if (needToUpdState) {
      if (!messageIsEmpty(nextProps.message)) {
        return {
          message: nextProps.message,
        };
      }

      return {
        message: {},
      };
    }

    return null;
  }

  componentDidMount() {
    // Workaround to autofocus input in pop-up.
    // HTML5 built-in 'autoFocus' property works only on page load and ignores pop-up.
    const needAutofocusInModal = this.props.autoFocus && !!document.querySelector('.modal-dialog');
    needAutofocusInModal && setTimeout(() => this.input.focus(), 10);
  }

  input = {};

  handleInputClick = () => this.input.focus();

  showMessage = (message) => this.setState({ message });
  clearMessage = () => this.showMessage({});

  handleKeyDown = (event) => {
    const { onSend } = this.props;

    if (onSend !== void 0 && event.key === 'Enter') {
      onSend(this.input.value);
      this.removeFocus();
    }
  };

  handleBlur = () => {
    const { onBlur } = this.props;

    this.removeFocus();

    if (onBlur !== void 0) {
      onBlur(this.input.value);
    }
  };

  handleInputFocus = (event) => {
    // Move cursor to end of text on focus
    const tempValue = event.target.value;
    event.target.value = '';
    event.target.value = tempValue;
    this.setState({ focused: true });
  };

  removeFocus = () => this.setState({ focused: false });

  handleInputChange = (input) => {
    this.props.onInputChange(input);
  };

  renderInput = (inputProps) =>
    this.props.isMultiline ? <textarea {...inputProps} /> : <input {...inputProps} />;

  render() {
    const {
      className,
      explanationMessage,
      icon,
      inputRef,
      isDisabled,
      isHaveRightIcon,
      isMandatory,
      label,
      onIconClick,
      ...inputProps
    } = this.props;
    const { message, focused } = this.state;

    const wrapperClasses = classnames({
      'input-component-linkio__wrapper': true,
      'input-component-linkio__wrapper_is-disabled': isDisabled,
      'input-component-linkio__wrapper_is-focused': focused,
      'input-component-linkio__wrapper_error': hasErrorMessage(message),
      'input-component-linkio__wrapper_success': hasSuccessMessage(message),
      [`${className}`]: className,
    });

    const inputClasses = classnames({
      'input-component-linkio__input': true,
      'input-component-linkio__input_is-disabled': isDisabled,
      'input-component-linkio__input_with-left-icon': icon,
      'input-component-linkio__input_with-right-icon': isHaveRightIcon,
    });

    const saveRef = (input) => {
      this.input = input;
      inputRef(input);
    };

    //clear unused props from input props
    Reflect.deleteProperty(inputProps, 'isMandatory');
    Reflect.deleteProperty(inputProps, 'isMultiline');
    Reflect.deleteProperty(inputProps, 'message');
    Reflect.deleteProperty(inputProps, 'onInputChange');
    Reflect.deleteProperty(inputProps, 'onSend');

    const aggregateInputProps = {
      ...inputProps,
      className: inputClasses,
      disabled: isDisabled,
      onBlur: this.handleBlur,
      onKeyDown: this.handleKeyDown,
      onChange: this.handleInputChange,
      onFocus: this.handleInputFocus,
      ref: saveRef,
      required: isMandatory,
    };

    return (
      <div className="input-component-linkio">
        <LabelComponent
          text={label}
          isMandatory={isMandatory}
          explanationMessage={explanationMessage}
        >
          <div className={wrapperClasses}>
            {icon && !isHaveRightIcon && (
              <span className="input-component-linkio__icon" onClick={onIconClick}>
                {icon()}
              </span>
            )}
            {this.renderInput(aggregateInputProps)}
            {icon && isHaveRightIcon && (
              <span
                className="input-component-linkio__icon input-component-linkio__icon_right"
                onClick={onIconClick}
              >
                {icon()}
              </span>
            )}
          </div>
        </LabelComponent>
        {!messageIsEmpty(message) && <MessageComponent message={message} />}
      </div>
    );
  }
}
