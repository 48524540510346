import { snakeCase } from 'lodash';

import {
  fetchAhrefsAbilitiesFailure,
  fetchAhrefsAbilitiesStart,
  fetchAhrefsAbilitiesSuccess,
  fetchAhrefsConnectionFailure,
  fetchAhrefsConnectionStart,
  fetchAhrefsConnectionSuccess,
  revokeAhrefsAccessFailure,
  revokeAhrefsAccessStart,
  revokeAhrefsAccessSuccess,
  updateConnectionSettingsFailure,
  updateConnectionSettingsStart,
  updateConnectionSettingsSuccess,
} from '../actions/ahrefs_connection_actions';
import { translate } from '../common/i18n';

import {
  getFromAPI,
  handleAPIError,
  patchToAPI,
} from './utils';


async function fetchConnectionInfo(dispatch) {
  dispatch(fetchAhrefsConnectionStart());

  try {
    const resp = await getFromAPI(
      Routes.fetch_subscription_info_ahrefs_requests_path()
    );
    dispatch(fetchAhrefsConnectionSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, fetchAhrefsConnectionFailure, translate('errors.cantFetchAhrefsConnection'));
  }
}

async function revokeAhrefsAccess(dispatch, ahrefsConnection) {
  dispatch(revokeAhrefsAccessStart());

  try {
    const resp = await patchToAPI(
      Routes.ahrefs_oauth_revoke_access_path()
    );
    dispatch(revokeAhrefsAccessSuccess(ahrefsConnection));
    dispatch(updateConnectionSettingsSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, revokeAhrefsAccessFailure, translate('errors.cantRevokeAhrefsAccess'));
  }
}

async function updateConnectionSettings(dispatch, ahrefsSyncInterval) {
  dispatch(updateConnectionSettingsStart());

  try {
    const resp = await patchToAPI(
      Routes.update_settings_ahrefs_connections_path(),
      { ahrefs_sync_interval: snakeCase(ahrefsSyncInterval) }
    );
    dispatch(updateConnectionSettingsSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, updateConnectionSettingsFailure, translate('errors.cantUpdateConnectionSettings'));
  }
}

async function fetchAhrefsAbilities(dispatch, useLimit = true) {
  dispatch(fetchAhrefsAbilitiesStart());

  try {
    const resp = await getFromAPI(
      Routes.check_abilities_ahrefs_requests_path(),
      { use_limit: useLimit }
    );
    dispatch(fetchAhrefsAbilitiesSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, fetchAhrefsAbilitiesFailure, translate('errors.cantFetchAhrefsConnection'));
  }
}

export {
  fetchConnectionInfo,
  revokeAhrefsAccess,
  updateConnectionSettings,
  fetchAhrefsAbilities,
};
