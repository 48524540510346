const CREATE_MARKETER__START = 'CREATE_MARKETER__START';
const CREATE_MARKETER__SUCCESS = 'CREATE_MARKETER__SUCCESS';
const CREATE_MARKETER__FAILURE = 'CREATE_MARKETER__FAILURE';
const FETCH_MARKETERS__START = 'FETCH_MARKETERS__START';
const FETCH_MARKETERS__SUCCESS = 'FETCH_MARKETERS__SUCCESS';
const FETCH_MARKETERS__FAILURE = 'FETCH_MARKETERS__FAILURE';

function createMarketerStart() {
  return { type: CREATE_MARKETER__START };
}

function createMarketerSuccess() {
  return { type: CREATE_MARKETER__SUCCESS };
}

function createMarketerFailure(errors) {
  return { type: CREATE_MARKETER__FAILURE, errors };
}

function fetchMarketersStart() {
  return { type: FETCH_MARKETERS__START };
}

function fetchMarketersSuccess(marketers) {
  return { type: FETCH_MARKETERS__SUCCESS, result: marketers };
}

function fetchMarketersFailure(errors) {
  return { type: FETCH_MARKETERS__FAILURE, errors };
}

export {
  CREATE_MARKETER__START,
  CREATE_MARKETER__SUCCESS,
  CREATE_MARKETER__FAILURE,
  createMarketerStart,
  createMarketerSuccess,
  createMarketerFailure,
  FETCH_MARKETERS__START,
  FETCH_MARKETERS__SUCCESS,
  FETCH_MARKETERS__FAILURE,
  fetchMarketersStart,
  fetchMarketersSuccess,
  fetchMarketersFailure,
};
