import { Map as iMap } from 'immutable';
import { isNumber, mean } from 'lodash';

function calculateAveragesService(competitorPages, metricKeys, competitorsTableType) {
  const competitorsCount = competitorPages.size;
  let averages = iMap();

  if (competitorsCount === 0) {
    return averages;
  }

  let competitorPagesThatCount = competitorPages;

  // Leave all competitors for metrics analysis table
  if (competitorsTableType !== 'metricsAnalysis') {
    competitorPagesThatCount = competitorPages.filter((page) =>
      page.get('countTowardAverages', true),
    );
  }

  metricKeys.forEach((key) => {
    const keyValues = competitorPagesThatCount.reduce((result, page) => {
      const value = page.getIn(['metrics', key]);
      if (isNumber(value)) {
        result.push(value);
      }
      return result;
    }, []);

    const average = Math.round(mean(keyValues)) || void 0;

    averages = averages.setIn(['metrics', key], average);
  });

  const hasAhrefsMetricsUploadingInProgressPage = competitorPagesThatCount.find((page) =>
    page.get('ahrefsMetricsUploadingInProgress'),
  );

  if (hasAhrefsMetricsUploadingInProgressPage) {
    averages = averages.set('ahrefsMetricsUploadingInProgress', true);
  }

  return averages;
}

export default calculateAveragesService;
