import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  cancelEoProgressionsSuccess,
  cancelEoProgressionsFailure,
  fetchEoProgressionsStart,
  fetchEoProgressionsSuccess,
  fetchEoProgressionsFailure,
  deleteEoProgressionsStart,
  deleteEoProgressionsSuccess,
  deleteEoProgressionsFailure,
  resumeEoProgressionsSuccess,
  resumeEoProgressionsFailure,
} from 'actions/eoProgressionActions';

import { resetSelectedIds } from 'actions/ui_actions';
import { fetchEoProgressionsPagySuccess } from 'actions/pagy_actions';

import { translate } from 'common/i18n';

import { showSuccessMessage } from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoProgressions(dispatch, params) {
  dispatch(fetchEoProgressionsStart());
  const { eoCampaignId } = params;

  try {
    const resp = await getFromAPI(Routes.eo_progressions_path({ id: eoCampaignId }), {
      ...processObjToSnakeCaseDeep(params),
    });

    dispatch(fetchEoProgressionsSuccess(resp.eo_progressions, eoCampaignId));
    dispatch(fetchEoProgressionsPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoProgressionsFailure,
      translate('eoProgression.messages.cantFetchEoProgressions'),
    );
  }
}

export async function deleteEoProgressions(dispatch, eoCampaignId, ids) {
  dispatch(deleteEoProgressionsStart());

  try {
    await deleteToAPI(Routes.eo_progressions_path({ id: eoCampaignId }), { ids });

    showSuccessMessage(dispatch, translate('eoProgression.messages.deleteSuccess')(ids.length));

    dispatch(resetSelectedIds());
    dispatch(deleteEoProgressionsSuccess(ids, eoCampaignId));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoProgressionsFailure,
      translate('eoProgression.messages.cantDeleteEoProgressions'),
    );
  }
}

export async function cancelEoProgressions(dispatch, eoCampaignId, eoProgressionsIds) {
  try {
    const resp = await patchToAPI(Routes.cancel_eo_progressions_path(eoCampaignId), {
      ids: eoProgressionsIds,
    });

    dispatch(resetSelectedIds());

    showSuccessMessage(dispatch, translate('eoProgression.messages.cancelEoProgressionsSuccess'));
    dispatch(cancelEoProgressionsSuccess(resp.eo_progressions, eoCampaignId));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      cancelEoProgressionsFailure,
      translate('eoProgression.messages.cancelEoProgressionsFailure'),
    );
  }
}

export async function resumeEoProgressions(
  dispatch,
  eoCampaignId,
  eoProgressionsIds,
  resumeAtDate,
) {
  try {
    const resp = await patchToAPI(Routes.resume_eo_progressions_path(eoCampaignId), {
      ids: eoProgressionsIds,
      resume_at: resumeAtDate,
    });

    const errors = (resp || {}).errors[0] || {};

    if (isEmpty(errors)) {
      dispatch(resetSelectedIds());

      showSuccessMessage(dispatch, translate('eoProgression.messages.resumeEoProgressionsSuccess'));
      dispatch(resumeEoProgressionsSuccess(resp.eo_progressions, eoCampaignId));
    } else {
      dispatch(resumeEoProgressionsFailure(errors));
    }

    return processObjToCamelCaseDeep(errors);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      resumeEoProgressionsFailure,
      translate('eoProgression.messages.resumeEoProgressionsFailure'),
    );
  }
}
