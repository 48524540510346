import { fromJS, Map as iMap } from 'immutable';
import { keyBy } from 'lodash';

import {
  CREATE_BRAND__FAILURE,
  CREATE_BRAND__START,
  CREATE_BRAND__SUCCESS,
  DELETE_BRANDS__FAILURE,
  DELETE_BRANDS__START,
  DELETE_BRANDS__SUCCESS,
  FETCH_BRANDS__FAILURE,
  FETCH_BRANDS__START,
  FETCH_BRANDS__SUCCESS,
  UPDATE_BRAND__FAILURE,
  UPDATE_BRAND__START,
  UPDATE_BRAND__SUCCESS,
} from 'actions/brand_actions';

import { camelizeObject } from 'reducers/utils';

const initialState = iMap();

const processBrand = (brand) => {
  if (!brand) {
    return void 0;
  }
  const camelizeBrand = camelizeObject(brand);
  camelizeBrand.selected = camelizeBrand.selected || false;

  return camelizeBrand;
};

export default function brandsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRANDS__START:
    case CREATE_BRAND__START:
    case UPDATE_BRAND__START:
    case DELETE_BRANDS__START:
    case FETCH_BRANDS__FAILURE:
    case CREATE_BRAND__FAILURE:
    case UPDATE_BRAND__FAILURE:
    case DELETE_BRANDS__FAILURE: {
      break;
    }
    case FETCH_BRANDS__SUCCESS: {
      const brands = action.brands || [];
      state = initialState.merge(fromJS(keyBy(brands.map(processBrand), 'id')));

      break;
    }
    case CREATE_BRAND__SUCCESS:
    case UPDATE_BRAND__SUCCESS: {
      state = state.merge(state, fromJS(keyBy([processBrand(action.brand)], 'id')));

      break;
    }
    case DELETE_BRANDS__SUCCESS: {
      state = state.deleteAll(action.ids.map(String));

      break;
    }
  }

  return state;
}
