import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EoOnboardingComponent from './EoOnboardingComponent';
import SpinnerComponent from 'components_linkio/Spinner';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchOnboardingStatus } from 'api/onboardingStatus';
import { onboardingStatusSelector } from 'selectors/onboardingStatusSelector';
import { onboardingStatusShape } from 'common/propTypesShapes/onboardingStatus';

const EoOnboardingContainer = ({ dispatch, onboardingStatus }) => {
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      await fetchOnboardingStatus(dispatch);
      setIsFetching(false);
    }

    fetchData();
  }, [dispatch]);

  return isFetching ? (
    <SpinnerComponent isBlack />
  ) : (
    <EoOnboardingComponent immutableOnboardingStatus={onboardingStatus} />
  );
};

EoOnboardingContainer.propTypes = {
  dispatch: PropTypes.func,
  onboardingStatus: onboardingStatusShape,
};

function select(state) {
  const onboardingStatus = onboardingStatusSelector(state);

  return {
    onboardingStatus,
  };
}

export default ConnectStoreHOC(connect(select)(EoOnboardingContainer));
