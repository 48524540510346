import React from 'react';

import {
  optionsMap,
  permissionsMap,
  policiesShape,
} from '../../common/prop_types_shapes';

import UserPermissions from './UserPermissions';


const PermissionsComponent = ({
  permissions,
  policies,
  profileTypeOptions,
}) => {
  function permissionsByProfileType(profileTypeValue) {
    return permissions.filter((userPermissions) => userPermissions.get('userProfileType') === profileTypeValue);
  }

  function renderUserPermissions(userPermissions) {
    const canUpdateEmployee = policies.getIn(['employee', 'canUpdate']);

    return (
      <tr key={userPermissions.get('userId')}>
        <td className="permissions-table__name-column">{userPermissions.get('userDisplayName')}</td>
        <td>
          {canUpdateEmployee && <UserPermissions userPermissions={userPermissions} />}
        </td>
      </tr >
    );
  }

  function renderProfileType(profileTypeOption) {
    const value = profileTypeOption.get('value');
    const label = profileTypeOption.get('label');
    const optionPermissions = permissionsByProfileType(value);

    if (optionPermissions.size === 0) {
      return void 0;
    }

    return (
      <li key={value}>
        <h5>{label}</h5>
        <table className="table permissions-table">
          <thead>
            <tr>
              <th className="permissions-table__name-column">Name</th>
              <th>Permissions</th>
            </tr>
          </thead>
          <tbody>
            {optionPermissions.valueSeq().map(renderUserPermissions)}
          </tbody>
        </table>
      </li>
    );
  }

  return (
    <div className="page__wrapper">
      <h4>Main permissions types:</h4>
      <ul>
        <li>_viewer (Read only)</li>
        <li>_manager (Read, Update)</li>
        <li>_admin (Create, Read, Update, Delete)</li>
      </ul>
      <hr />
      <br />
      <ul>
        {profileTypeOptions.valueSeq().map(renderProfileType)}
      </ul>
    </div>
  );
};

PermissionsComponent.propTypes = {
  permissions: permissionsMap,
  policies: policiesShape,
  profileTypeOptions: optionsMap,
};

export default PermissionsComponent;
