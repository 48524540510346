import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';
import { keywordsArray } from 'common/prop_types_shapes';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import MessageComponent from 'components_linkio/message_component';
import RadioButton from 'components_linkio/RadioButton';

import './autodiscoverCompetitorsPopup.scss';

const AutodiscoverCompetitorsPopupComponent = ({
  isLoading,
  keywords,
  onAddKeywordsClick,
  onCancelClick,
  onSubmit,
}) => {
  // Auto-select single keyword
  const defaultSelectedKeyword = keywords.length === 1 ? keywords[0] : {};

  const [selectedKeyword, setSelectedKeyword] = React.useState(defaultSelectedKeyword);

  const handleChangeSelectedKeyword = (keyword) => (_event) => {
    setSelectedKeyword(keyword);
  };

  function handleSubmit() {
    onSubmit(selectedKeyword.id);
  }

  return (
    <Modal show className="autodiscover-competitors-popup">
      <Modal.Header>
        <Modal.Title>
          {translate('bulkCompetitorsSetup.autodiscoverCompetitorsPopup.title')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {keywords.length === 0 && (
          <span>
            {translate('bulkCompetitorsSetup.autodiscoverCompetitorsPopup.emptyStateText.linkTextPre')}
            <span className="text text_link" onClick={onAddKeywordsClick}>
              {translate('bulkCompetitorsSetup.autodiscoverCompetitorsPopup.emptyStateText.linkText')}
            </span>
            {translate('bulkCompetitorsSetup.autodiscoverCompetitorsPopup.emptyStateText.linkTextAfter')}
          </span>
        )}
        {keywords.length > 0 && (
          <>
            <p className="autodiscover-competitors-popup__choose-keyword">
              {translate('bulkCompetitorsSetup.autodiscoverCompetitorsPopup.chooseKeyword')}
            </p>
            <ul className="autodiscover-competitors-popup__keywords-list">
              {keywords.map(({ id, label }) => (
                <li key={id}>
                  <RadioButton
                    name={label}
                    checked={selectedKeyword.label === label}
                    onChange={handleChangeSelectedKeyword({ id, label })}
                    value={label}
                  >
                    {label}
                  </RadioButton>
                </li>
              ))}
            </ul>
          </>
        )}
        <div className="autodiscover-competitors-popup__legend">
          <MessageComponent
            message={{
              type: 'hint',
              text: translate('bulkCompetitorsSetup.autodiscoverCompetitorsPopup.hint'),
            }}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="autodiscover-competitors-popup__btn-group">
          <ButtonComponent isWhite isInitial onClick={onCancelClick}>
            {translate('bulkCompetitorsSetup.autodiscoverCompetitorsPopup.cancel')}
          </ButtonComponent>

          <ButtonComponent
            isGreen
            isInitial
            onClick={handleSubmit}
            isDisabled={!selectedKeyword.id}
            isLoading={isLoading}
          >
            {translate('bulkCompetitorsSetup.autodiscoverCompetitorsPopup.submit')}
          </ButtonComponent>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AutodiscoverCompetitorsPopupComponent.propTypes = {
  isLoading: PropTypes.bool,
  keywords: keywordsArray,
  onAddKeywordsClick: PropTypes.func,
  onCancelClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AutodiscoverCompetitorsPopupComponent;
