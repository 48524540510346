import { translate } from '../common/i18n';

import {
  fetchRankTrackerCountriesFailure,
  fetchRankTrackerCountriesStart,
  fetchRankTrackerCountriesSuccess,
  fetchRankTrackerEnginesFailure,
  fetchRankTrackerEnginesStart,
  fetchRankTrackerEnginesSuccess,
  fetchRankTrackerLanguagesFailure,
  fetchRankTrackerLanguagesStart,
  fetchRankTrackerLanguagesSuccess,
  fetchRankTrackerLocationsFailure,
  fetchRankTrackerLocationsStart,
  fetchRankTrackerLocationsSuccess,
  fetchRankTrackerSettingsFailure,
  fetchRankTrackerSettingsStart,
  fetchRankTrackerSettingsSuccess,
  saveRankTrackerSettingsFailure,
} from '../actions/rankTrackerSetupActions';

import {
  getFromAPI,
  handleAPIError,
  postToAPI,
  processObjToCamelCase,
  processObjToSnakeCase,
} from './utils';

async function fetchRankTrackerCountries(dispatch, params) {
  dispatch(fetchRankTrackerCountriesStart());

  try {
    const resp = await getFromAPI(
      Routes.countries_rank_tracker_settings_path(),
      { engine_name: params.engineName },
    );

    dispatch(fetchRankTrackerCountriesSuccess(resp.countries));
    // If we get only one country in response it means we automatically get languages for this country
    if (resp.countries.length === 1) {
      dispatch(fetchRankTrackerLanguagesSuccess(resp.languages));
    }
    return resp.countries;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchRankTrackerCountriesFailure,
      translate('errors.cantFetchRankTrackerCountries'),
    );
  }
}

async function fetchRankTrackerEngines(dispatch) {
  dispatch(fetchRankTrackerEnginesStart());

  try {
    const resp = await getFromAPI(Routes.engines_rank_tracker_settings_path());

    dispatch(fetchRankTrackerEnginesSuccess(resp.engine_names));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchRankTrackerEnginesFailure,
      translate('errors.cantFetchRankTrackerEngines'),
    );
  }
}

async function fetchRankTrackerLanguages(dispatch, params) {
  dispatch(fetchRankTrackerLanguagesStart());

  try {
    const resp = await getFromAPI(
      Routes.languages_rank_tracker_settings_path(),
      {
        engine_name: params.engineName,
        country: params.country,
      },
    );

    dispatch(fetchRankTrackerLanguagesSuccess(resp.languages));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchRankTrackerLanguagesFailure,
      translate('errors.cantFetchRankTrackerLanguages'),
    );
  }
}

async function fetchRankTrackerLocations(dispatch, params) {
  dispatch(fetchRankTrackerLocationsStart());

  try {
    const resp = await getFromAPI(
      Routes.locations_rank_tracker_settings_path(),
      { location_name: params.locationName, country: params.country },
    );

    dispatch(fetchRankTrackerLocationsSuccess(resp.locations));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchRankTrackerLocationsFailure,
      translate('errors.cantFetchRankTrackerLocations'),
    );
  }
}

async function fetchRankTrackerSettings(dispatch, brandId) {
  dispatch(fetchRankTrackerSettingsStart());

  try {
    const resp = await getFromAPI(
      Routes.rank_tracker_settings_path({ brand_id: brandId }),
    );

    const rankTrackerSettings = processObjToCamelCase(resp);

    dispatch(fetchRankTrackerSettingsSuccess(rankTrackerSettings));
    return rankTrackerSettings;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchRankTrackerSettingsFailure,
      translate('errors.cantFetchRankTrackerSettings'),
    );
  }
}

async function saveRankTrackerSettings(dispatch, brandId, settings) {
  try {
    const resp = await postToAPI(
      Routes.save_rank_tracker_settings_path({ brand_id: brandId }),
      { rank_tracker_setting: processObjToSnakeCase(settings) },
    );

    const rankTrackerSettings = processObjToCamelCase(resp);

    dispatch(fetchRankTrackerSettingsSuccess(rankTrackerSettings));
    return rankTrackerSettings;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      saveRankTrackerSettingsFailure,
      translate('errors.cantSaveRankTrackerSettings'),
    );
  }
}

export {
  fetchRankTrackerCountries,
  fetchRankTrackerEngines,
  fetchRankTrackerLanguages,
  fetchRankTrackerLocations,
  fetchRankTrackerSettings,
  saveRankTrackerSettings,
};
