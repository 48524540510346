import { difference, get, pickBy, some } from 'lodash';

import { translate } from 'common/i18n';

export const MAX_ALLOWED_FILTER_ROWS = 6;

export const PREDICATES = {
  eq: {
    value: 'eq',
    label: translate('uiComponents.dataFilters.predicates.is'),
  },
  eqAny: {
    value: 'eqAny',
    label: translate('uiComponents.dataFilters.predicates.isAnyOf'),
  },
  notEqAll: {
    value: 'notEqAll',
    label: translate('uiComponents.dataFilters.predicates.isNonOf'),
  },
  notEq: {
    value: 'notEq',
    label: translate('uiComponents.dataFilters.predicates.isNot'),
  },
  blank: {
    value: 'blank',
    label: translate('uiComponents.dataFilters.predicates.isEmpty'),
  },
  present: {
    value: 'present',
    label: translate('uiComponents.dataFilters.predicates.isNotEmpty'),
  },
};

export const trueValue = {
  value: 'true',
  label: translate('dlsFilter.yesOrNoSelector.yes'),
};

export const customValue = (label, value) => ({
  value,
  label,
});

export const falseValue = {
  value: 'false',
  label: translate('dlsFilter.yesOrNoSelector.no'),
};

export function createYesOrNo() {
  return [trueValue, falseValue];
}

export function isMultiSelect({ predicate: { value: predicateKey } }) {
  return ['eqAny', 'notEqAll'].includes(predicateKey);
}

export function isNotBlankOrPresentFilter(filter) {
  const predicate = get(filter, 'predicate.value', null);
  return !['blank', 'present'].includes(predicate);
}

export function getProperties(filtersSpec, filters) {
  return difference(
    Object.keys(filtersSpec),
    filters.reduce((acc, filter) => {
      const property = get(filter, 'property.value', null);
      if (!property) {
        return acc;
      }
      return [...acc, property];
    }, []),
  ).map((propertyKey) => filtersSpec[propertyKey].property);
}

export function getPredicates(filtersSpec, filter) {
  const propertyKey = get(filter, 'property.value', null);

  if (propertyKey === null) {
    return [];
  }

  return Object.keys(filtersSpec[propertyKey].predicate).map(
    (predicateKey) => PREDICATES[predicateKey],
  );
}

export function getValues(
  filtersSpec,
  { property: { value: propertyKey }, predicate: { value: predicateKey } },
) {
  return filtersSpec[propertyKey].predicate[predicateKey];
}

export function createFilter(predicate, value = null) {
  return {
    property: this.property,
    predicate: PREDICATES[predicate],
    value,
  };
}

const isDataFilterQueryKey = (filtersSpec, key) => some(filtersSpec, (filterSpec) => filterSpec.mapToFilter(key));

export const getQueryFiltersWithoutDataFilterKeys = (filtersSpec, filters = {}) => pickBy(filters, (_value, key) => !isDataFilterQueryKey(filtersSpec, key));

export const getQueryDataFilterKeys = (filtersSpec, filters = {}) => pickBy(filters, (_value, key) => isDataFilterQueryKey(filtersSpec, key));
