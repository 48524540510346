// OPTIMIZE: colours should be synced across the app.
// Consider exporting from /stylesheets/variables.scss
const colors = {
  charcoalGreyTwo: '#474a4d',
  cloudyBlueTwo: '#adc0d0',
  coolGrey: '#a1a7af',
  coolGreyTwo: '#8f9295',
  cornflowerBlue: '#4ba0d2',
  darkSkyBlue: '#56acde',
  duckEggBlue: '#e2edf9',
  mainLipstick: '#e3204b',
  mainTopaz: '#1abc9c',
  paleGreySix: '#eff2f5',
  silverFive: '#e3e6e9',
  silverSix: '#d6dadc',
  sky: '#8ad3ff',
  white: '#fff',
};

import { has } from 'lodash';

const optionBackgroundColour = (isAction, isDisabled, isFocused, isSelected) => {
  if (isAction) {
    return colors.white;
  }

  if (isDisabled) {
    return colors.paleGreySix;
  }

  if (isFocused) {
    return colors.duckEggBlue;
  }

  if (isSelected) {
    return colors.white;
  }

  return colors.white;
};

const controlBorderColor = (state, isError, isSuccess) => {
  const { isFocused, isDisabled } = state;

  if (isError) {
    return colors.mainLipstick;
  }

  if (isSuccess) {
    return colors.mainTopaz;
  }

  if (isFocused) {
    return colors.cornflowerBlue;
  }

  if (isDisabled) {
    return colors.paleGreySix;
  }

  return colors.silverFive;
};

const controlInTableStyles = (skinOptions) => {
  const { inTable } = skinOptions;

  if (!inTable) {
    return {};
  }

  return {
    minWidth: '7.14rem',
    borderColor: colors.cornflowerBlue,
    borderRadius: '0',
  };
};

const menuInTableStyles = (skinOptions) => {
  const { inTable } = skinOptions;

  if (!inTable) {
    return {};
  }

  return {
    position: 'relative',
    top: '-5px',
  };
};

const controlAsDropdownStyles = (skinOptions) => {
  const { asDropdown } = skinOptions;

  if (!asDropdown) {
    return {};
  }

  return {
    visibility: 'hidden',
  };
};

const menuAsDropdownStyles = (skinOptions) => {
  const { asDropdown } = skinOptions;

  if (!asDropdown) {
    return {};
  }

  return {
    marginTop: '-5px',
    top: '-2.57rem',
  };
};

const customStyles = (isError, isSuccess, skinOptions, componentStyles = {}) => {
  const isMultiValueCbExist = has(componentStyles, 'multiValueCb');
  const getMultiValueCbObject = (state) =>
    isMultiValueCbExist ? componentStyles.multiValueCb(state) : {};

  const isMultiValueLabelCbExist = has(componentStyles, 'multiValueLabelCb');
  const getMultiValueLabelCbObject = (state) =>
    isMultiValueLabelCbExist ? componentStyles.multiValueCb(state) : {};

  return {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '.29rem',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: controlBorderColor(state, isError, isSuccess),
      boxShadow: null,
      minHeight: '2.57rem',
      '&:hover': { borderColor: state.isFocused ? colors.cornflowerBlue : colors.silverSix },
      ...controlInTableStyles(skinOptions),
      ...controlAsDropdownStyles(skinOptions),
      ...componentStyles.control,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...componentStyles.dropdownIndicator,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      ...componentStyles.indicatorSeparator,
    }),
    group: (provided) => ({
      ...provided,
      paddingBottom: '0',
      paddingTop: '0',
    }),
    groupHeading: (provided) => {
      return {
        ...provided,
        backgroundColor: colors.cloudyBlueTwo,
        border: `solid 1px ${colors.silverSix}`,
        ':first-child': {
          borderRadius: '.29rem .29rem 0 0',
        },
        ':last-child': {
          borderRadius: '0 0 .29rem .29rem',
        },
        color: colors.white,
        fontSize: 'inherit',
        fontWeight: 'inherit',
        marginBottom: '0',
        padding: '8px 12px',
        textTransform: 'none',
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: colors.white,
      border: `solid 1px ${colors.silverSix}`,
      borderRadius: '.29rem',
      boxShadow: '0 2px 6px 0 rgba(0, 0, 0, .3)',
      ...menuInTableStyles(skinOptions),
      ...menuAsDropdownStyles(skinOptions),
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: colors.white,
      borderRadius: '.29rem',
      boxShadow: `inset 0 -1px 0 0 ${colors.silverSix}`,
      paddingBottom: '0',
      paddingTop: '0',
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: colors.sky,
      color: 'red',
      fontSize: '.7143rem',
      lineHeight: '1.9',
      textAlign: 'center',
      width: 'auto',
      ...getMultiValueCbObject(state),
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      ...getMultiValueLabelCbObject(state),
    }),
    option: (provided, state) => {
      const {
        isDisabled,
        isFocused,
        isSelected,
        data: { isAction },
      } = state;
      return {
        ...provided,
        color: isFocused ? colors.darkSkyBlue : colors.charcoalGreyTwo,
        cursor: isDisabled ? 'not-allowed' : 'grab',
        backgroundColor: optionBackgroundColour(isAction, isDisabled, isFocused, isSelected),
        border: `solid 1px ${colors.silverSix}`,
        ':first-of-type': {
          borderRadius: '.29rem .29rem 0 0',
        },
        ':last-child': {
          borderRadius: '0 0 .29rem .29rem',
        },
        ...componentStyles.option,
      };
    },
    valueContainer: (provided) => ({
      ...provided,
      ...componentStyles.valueContainer,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.coolGrey,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: colors.coolGreyTwo,
      paddingRight: '5px',
      ...componentStyles.singleValue,
    }),
  };
};

export default customStyles;
