import React from 'react';
import PropTypes from 'prop-types';

import NavbarMenu from './NavbarMenu';

import RoboSubscriptionStatusComponent from 'components/robo_subscription_status_component';
import Menu from 'components_linkio/menu';
import Link from 'components_linkio/link';

import NotificationsButton from 'pages/NotificationsPage/NotificationsButton';
import NotificationsPopup from 'pages/NotificationsPage/NotificationsPopup';

import { userShape, marketerShape, withRouterPropTypes } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import { openFeedbackPopup } from 'actions/popup_actions';

import LinkioLogo from 'common/icons/linkio_logo.svg';
import './navbar.scss';

class NavbarComponent extends React.Component {
  static propTypes = {
    currentMarketer: marketerShape.isRequired,
    currentUser: userShape.isRequired,
    hideAvatar: PropTypes.bool,
    hideNotificationsWrapper: PropTypes.bool,
    hideRoboSubscriptionStatus: PropTypes.bool,
    hideTopMenu: PropTypes.bool,
    ...withRouterPropTypes,
  };

  state = {
    showMenu: false,
  };

  handleAvatarClick = () => this.setState({ showMenu: !this.state.showMenu });
  handleClickOutsideMenu = () => this.setState({ showMenu: false });

  handleClickFeedback = () => {
    this.props.dispatch(openFeedbackPopup());
    this.setState({ showMenu: false });
  };

  render() {
    const {
      currentUser,
      hideAvatar,
      hideNotificationsWrapper,
      hideRoboSubscriptionStatus,
      hideTopMenu,
      location,
    } = this.props;

    const isNotChooseAccountPage = location.pathname !== Routes.users_choose_account_path({ format: null });

    return (
      <>
        <nav className="navbar">
          <div className="navbar__logo">
            <Link to={Routes.root_path({ format: null })} title={translate('projectTitle')}>
              <img src={LinkioLogo} />
            </Link>
          </div>
          {!hideTopMenu && <Menu className="navbar__top-menu" />}
          {!hideRoboSubscriptionStatus && <RoboSubscriptionStatusComponent />}

          {!hideNotificationsWrapper && (
            <div className="navbar__notifications-wrapper">
              <NotificationsButton />
              <NotificationsPopup />
            </div>
          )}

          {!hideAvatar && (
            <div onClick={this.handleAvatarClick}>
              <img
                src={currentUser.get('avatar')}
                alt={currentUser.get('name')}
                className="navbar__avatar"
              />
            </div>
          )}

          {this.state.showMenu && (
            <NavbarMenu
              {...this.props}
              isOnMousedown
              onClickOutside={this.handleClickOutsideMenu}
              onClickFeedback={this.handleClickFeedback}
            />
          )}
        </nav>
      </>
    );
  }
}

export default NavbarComponent;
