export default {
  deleteAccountPopup: {
    buttons: {
      cancel: 'Cancel',
      delete: 'Delete',
    },
    confirmationText:
      "We're sorry to see you go! If you delete your account it will be closed \
      immediately, the subscription  will be cancelled and your data will be deleted \
      permanently. Do you want to proceed?",
    deletionReasons: {
      complexTool: "Didn't figure out how to use the tool.",
      needSomethingElse: 'I was looking for something else.',
      other: 'Other',
      tooExpensive: 'Too expensive.',
    },
    feedbackRequest:
      'Please let us know why you decided to delete your account.',
    messages: {
      thanksForFeedback: 'Thanks for sharing your feedback',
    },
    switchToFreeAccountLink: 'Switch to Free account',
    title: 'Are you sure?',
  },
};
