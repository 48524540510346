import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import MessageComponent from 'components_linkio/message_component';

import Link from 'components_linkio/link';

import './importCsv.scss';

export default class ImportCsvComponent extends Component {
  static propTypes = {
    isDisabled: PropTypes.bool,
    onFileUpload: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      csvFile: void 0,
    };
  }

  input = {};

  handleButtonClick = () => {
    this.input.click();
  };

  handleOnImport = async (event) => {
    const file = event.target.files[0];

    this.setState({ csvFile: file });

    this.props.onFileUpload(file);
  };

  csvFileName = () => {
    const { csvFile } = this.state;
    return csvFile ? csvFile.name : translate('importCsv.defaultFileName');
  };

  render() {
    const { isDisabled } = this.props;
    const csvFileName = this.csvFileName();
    const wrapperClasses = classnames({
      'import-csv-component': true,
      'import-csv-component_is-disabled': isDisabled,
    });

    return (
      <div className={wrapperClasses}>
        <div className="import-csv-component__file-info">{csvFileName}</div>
        <div className="import-csv-component__choose-file-btn">
          <ButtonComponent isBlue onClick={this.handleButtonClick} isDisabled={isDisabled}>
            {translate('importCsv.buttons.chooseFile')}
          </ButtonComponent>
        </div>
        <input
          accept=".csv,.tsv"
          className="import-csv-component__input"
          name="csv-file-input"
          onChange={this.handleOnImport}
          ref={(input) => (this.input = input)}
          type="file"
        />
        <MessageComponent message={{ type: 'hint' }}>
          {translate('importToBrandPage.csvHintMessage.firstPart')}
          <Link href="/linkio-import.csv">.CSV template</Link>
          {translate('importToBrandPage.csvHintMessage.secondPart')}
        </MessageComponent>
      </div>
    );
  }
}
