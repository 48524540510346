import pluralize from 'pluralize';

export default {
  bulkPagesSetup: {
    atpSimpleModePopup: {
      buttons: {
        cancel: (isInAtp) =>
          isInAtp ? 'Switch to Bulk Add Mode' : 'Discard and Switch to Bulk Add Mode',
        submit: (isAddingPage, isInAtp, isNewKeywordsExist) => {
          const addToAtpText = 'Add Page to ATP';

          if (isAddingPage) {
            return addToAtpText;
          }

          const notInAtpText = isNewKeywordsExist ? 'Update and Add to ATP' : addToAtpText;

          return isInAtp ? 'Add keywords' : notInAtpText;
        },
      },
      hints: {
        isInAtp: "This page is already in ATP. You can still manage it's keywords in this form.",
        linkInput: 'Enter full URL, e. g., https://www.your-url.com',
        title: (title) => `Title Tag: ${title}`,
      },
      inputs: {
        pageType: {
          label: 'Page type',
        },
        linkInput: {
          label: 'Page URL',
        },
      },
      title: (isAddingPage, isInAtp) => {
        if (isAddingPage) {
          return 'Add a Page to Anchor Text Planner';
        }

        return isInAtp ? 'No Actions Needed' : 'Update This Page and Add It to Anchor Text Planner';
      },
    },
    breadcrumbsTitle: 'Add New Pages',
    bulkActions: {
      buttons: {
        addToAtp: (pagesCount) => {
          if (pagesCount > 0) {
            return `Add ${pluralize('Page', pagesCount, true)} to Anchor Text Planner`;
          }
          return 'Return to Anchor Text Planner';
        },
        cancel: 'Cancel',
        manuallyImportPages: 'Manually import pages to table',
        autodiscoverPagesViaSitemap: 'Autodiscover pages via sitemap',
        importFromGSC: 'Import from Google Search Console',
      },
      subtitle:
        'Manually add the page URLs, bulk-import from your sitemap, or import from \
        Google Search Console.',
      title: 'What Page(s) Do You Want Anchor Text Suggestions For?',
    },
    editKeywordsPopup: {
      buttons: {
        cancel: 'Close',
        save: 'Save',
      },
      title: 'Edit Keywords',
    },
    manuallyImportPagesPopup: {
      buttons: {
        cancel: 'Cancel',
        addPages: 'Add Pages',
      },
      hint: 'Please add one URL per line',
      title: 'Add New Pages',
      subtitle: 'Add Multiple Pages',
    },
    importFromGSCPopup: {
      buttons: {
        cancel: 'Cancel',
        import: 'Import',
      },
      title: 'Import links from Google Search Console',
      subtitle:
        "Check here to get the list of your external backlinks. Please upload this file and we'll automatically add your brand pages and their backlinks. This may take a while.",
    },
    table: {
      dataNotReadyYet: 'Discovering pages...',
      columns: {
        brandPages: 'Brand Pages',
        metrics: {
          uriRefdomainsCount: 'Referring Domains',
          uriAhrefsRating: 'URL Rating',
        },
        pageType: 'Page Type',
        keywords: 'Keywords',
        idealPercentage: 'Ideal Percentage',
        status: {
          in_atp: 'In Anchor Text Planner',
          not_in_atp: 'Not In Anchor Text Planner',
          title: 'Status',
        },
      },
    },
    messages: {
      addNewKeywords: (newKeywordsCount) =>
        `Successfully added ${pluralize('keyword', newKeywordsCount, true)}.`,
      createPageSuccess: 'Brand Page was created successfully.',
      manualImportSuccess: (uniqAndValidEntriesCount, dupeOrInvalidEntriesCount) => {
        const successMessage = `Import was completed successfully. ${pluralize(
          'page',
          uniqAndValidEntriesCount,
          true,
        )} added`;
        const skippedMessage = `${dupeOrInvalidEntriesCount} invalid or dupe ${pluralize(
          'page',
          dupeOrInvalidEntriesCount,
        )} skipped.`;

        return dupeOrInvalidEntriesCount > 0
          ? `${successMessage}, ${skippedMessage}`
          : successMessage;
      },
      autodiscoverViaSitemapSuccess: (
        uniqAndValidEntriesCount,
        dupeOrInvalidEntriesCount,
        pagesCountLimit,
        pagesCountLimitReached,
      ) => {
        const titleMsg = pagesCountLimitReached
          ? `Linkio added first ${pluralize(
              'page',
              uniqAndValidEntriesCount,
              true,
            )} from your sitemap`
          : `Linkio added ${pluralize('page', uniqAndValidEntriesCount, true)} from your sitemap`;
        const smthSkippedMsg = `${dupeOrInvalidEntriesCount} duplicate or invalid URLs were ignored`;

        return dupeOrInvalidEntriesCount > 0 ? `${titleMsg}, ${smthSkippedMsg}.` : `${titleMsg}.`;
      },
    },
  },
};
