import { createFilter } from 'components_linkio/DataFilter/utils';
import { translate } from 'common/i18n';

const statusFilterSpec = (options = []) => ({
  property: {
    value: 'status',
    label: translate('uiComponents.dataFilters.filter.status'),
  },
  predicate: {
    eq: options,
  },
  mapToQuery(_property, _predicate, { value }) {
    return { visible_in_atp_eq: value === 'in_atp' };
  },
  mapToFilter(_queryProperty, queryValue) {
    const status = queryValue === 'true' ? 'in_atp' : 'not_in_atp';
    const value = this.options.filter(({ value }) => value === status)[0];

    return this.createFilter('eq', value);
  },
  createFilter,
  options,
});

export const filtersSpec = ({ pageStatusOptions } = {}) => ({
  status: statusFilterSpec(pageStatusOptions),
});

export const defaultFilters = ({ pageStatusOptions } = {}) => [
  statusFilterSpec(pageStatusOptions).createFilter('eq', null),
];
