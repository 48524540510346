import { translate } from '../common/i18n';

import {
  createPercentageSchemeFailure,
  createPercentageSchemeStart,
  createPercentageSchemeSuccess,
  deletePercentageSchemeFailure,
  deletePercentageSchemeStart,
  deletePercentageSchemeSuccess,
  fetchPercentageSchemesFailure,
  fetchPercentageSchemesStart,
  fetchPercentageSchemesSuccess,
  reloadCompetitorsPercentageSchemesFailure,
  updatePercentageSchemeFailure,
  updatePercentageSchemeStart,
  updatePercentageSchemeSuccess,
} from '../actions/percentage_scheme_actions';

import {
  getFromAPI,
  postToAPI,
  patchToAPI,
  deleteToAPI,
  handleAPIError,
  processObjToSnakeCase,
} from './utils';


async function fetchPercentageSchemes(dispatch) {
  dispatch(fetchPercentageSchemesStart());

  try {
    const resp = await getFromAPI(Routes.percentage_schemes_path());
    dispatch(fetchPercentageSchemesSuccess(resp.percentage_schemes));
    return resp.percentage_schemes;
  } catch (err) {
    return handleAPIError(dispatch, err, fetchPercentageSchemesFailure, translate('errors.cantFetchPercentageSchemes'));
  }
}

async function createPercentageScheme(dispatch, percentageScheme) {
  dispatch(createPercentageSchemeStart());

  try {
    const resp = await postToAPI(
      Routes.percentage_schemes_path(),
      prepareSendObject(percentageScheme),
    );
    dispatch(createPercentageSchemeSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, createPercentageSchemeFailure, translate('errors.cantCreatePercentageScheme'));
  }
}

async function updatePercentageScheme(dispatch, percentageScheme) {
  dispatch(updatePercentageSchemeStart());

  try {
    const resp = await patchToAPI(
      Routes.percentage_scheme_path({ id: percentageScheme.id }),
      prepareSendObject(percentageScheme),
    );
    dispatch(updatePercentageSchemeSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, updatePercentageSchemeFailure, translate('errors.cantUpdatePercentageScheme'));
  }
}

async function deletePercentageScheme(dispatch, percentageSchemeId) {
  dispatch(deletePercentageSchemeStart());

  try {
    const resp = await deleteToAPI(Routes.percentage_scheme_path({ id: percentageSchemeId }));
    dispatch(deletePercentageSchemeSuccess(percentageSchemeId));
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, deletePercentageSchemeFailure, translate('errors.cantDeletePercentageScheme'));
  }
}

async function reloadCompetitorsPercentageSchemes(dispatch, brandPageId) {
  try {
    const resp = await patchToAPI(
      Routes.reload_competitors_percentages_percentage_schemes_path({ brand_page_id: brandPageId })
    );
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, reloadCompetitorsPercentageSchemesFailure, translate('errors.cantReloadCompetitorsPercentageSchemes'));
  }
}

const snakeCasedArray = (array) => array.map((item) => processObjToSnakeCase(item));
const processPercentageScheme = (percentageScheme) => {
  const processedPercentageScheme = processObjToSnakeCase(percentageScheme);
  processedPercentageScheme.percentage_scheme_items = snakeCasedArray(processedPercentageScheme.percentage_scheme_items);
  processedPercentageScheme.percentage_scheme_items.map((item) => {
    // REFACTOR_ME: Add coercion feature in PercentageSchemeItemForm to convert value to double there
    item.ideal_percentage = Number(item.ideal_percentage);
    return item;
  });
  return processedPercentageScheme;
};

const prepareSendObject = (percentageScheme) => {
  const processedPercentageScheme = processPercentageScheme(percentageScheme);
  const schemeItems = processedPercentageScheme.percentage_scheme_items;
  Reflect.deleteProperty(processedPercentageScheme, 'percentage_scheme_items');

  return {
    ...processedPercentageScheme,
    percentage_scheme_items: schemeItems,
  };
};

export {
  createPercentageScheme,
  deletePercentageScheme,
  fetchPercentageSchemes,
  reloadCompetitorsPercentageSchemes,
  updatePercentageScheme,
};
