import React from 'react';
import PropTypes from 'prop-types';

import './anchorTextSelectItem.scss';

const AnchorTextSelectItemComponent = ({ label, count }) => {
  return (
    <div className="anchor-text-select-item">
      <div>{label}</div>
      <div className="anchor-text-select-item__count">{count}</div>
    </div>
  );
};

AnchorTextSelectItemComponent.propTypes = {
  count: PropTypes.number,
  label: PropTypes.string,
};

export default AnchorTextSelectItemComponent;
