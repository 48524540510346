import React from 'react';

// Getting coordinates in px relative top left page pixel.
function getPosition(el) {
  if (!el) {
    return { top: 0, left: 0 };
  }
  const rect = el.getBoundingClientRect();
  const doc = el.ownerDocument;
  if (!doc) {
    throw new Error('Unexpectedly missing <document>.');
  }
  const win = doc.defaultView || doc.parentWindow;

  const winX =
    win.pageXOffset !== undefined
      ? win.pageXOffset
      : (doc.documentElement || doc.body.parentNode || doc.body).scrollLeft;
  const winY =
    win.pageYOffset !== undefined
      ? win.pageYOffset
      : (doc.documentElement || doc.body.parentNode || doc.body).scrollTop;

  return {
    left: rect.left + winX,
    top: rect.top + winY,
    bottom: rect.bottom + winY,
    right: rect.right + winX,
  };
}

function getSizes(el) {
  if (!el) {
    return { width: 0, height: 0 };
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  };
}

// The hook which allowing align element by absolute position relative root element.
function useAlign(anchorRef, ref, align = {}, ...watchingVars) {
  const { top, left, bottom, right } = getPosition(anchorRef.current);
  const { width, height } = getSizes(ref.current);

  const [ElementPosition, setElementPosition] = React.useState({
    top,
    left,
    bottom,
    right,
  });
  const [ElementSizes, setElementSizes] = React.useState({
    width,
    height,
  });

  function handleChangePosition() {
    if (anchorRef && anchorRef.current) {
      setElementPosition(getPosition(anchorRef.current));
    }
    if (ref && ref.current) {
      setElementSizes(getSizes(ref.current));
    }
  }

  React.useLayoutEffect(() => {
    handleChangePosition();
    window.addEventListener('resize', handleChangePosition);

    return () => {
      window.removeEventListener('resize', handleChangePosition);
    };
  }, [anchorRef, ref, ...watchingVars]);

  return {
    top: Number.isFinite(align.top)
      ? ElementPosition.top - align.top - ElementSizes.height
      : ElementPosition.bottom + align.bottom,
    left: Number.isFinite(align.left)
      ? ElementPosition.left + align.left
      : ElementPosition.right - align.right - ElementSizes.width,
  };
}

export default useAlign;
