import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConfirmPasswordPopupComponent from './ConfirmPasswordPopupComponent';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  currentMarketerSelector,
  currentUserSelector,
} from 'selectors/railsContextSelectors';

import { autoSetCurrentAccount, signInAsUser } from 'api/user';
import { closeConfirmPasswordPopup } from 'actions/popup_actions';
import { marketerShape, userShape } from 'common/prop_types_shapes';
import { resetLoadingAndChangingStatuses } from 'actions/ui_actions';

const ConfirmPasswordPopupContainer = ({
  currentMarketer,
  currentUser,
  dispatch,
}) => {
  React.useEffect(() => {
    dispatch(resetLoadingAndChangingStatuses());
  }, [dispatch]);

  async function handleSignInAction(state) {
    const email = currentUser.get('email');
    const marketerSlug = currentMarketer.get('slug');
    const { password, remember } = state;
    const formData = new FormData();

    formData.append('user[email]', email);
    formData.append('user[password]', password);
    formData.append('user[remember_me]', remember);

    const res = await signInAsUser(dispatch, formData);

    if ((res || {}).type === 'error') {
      return res.message;
    }

    if (currentMarketer) {
      await autoSetCurrentAccount(dispatch, marketerSlug);
    }
    dispatch(closeConfirmPasswordPopup());

    return void 0;
  }

  return (
    <ConfirmPasswordPopupComponent onContinue={handleSignInAction} />
  );
};

ConfirmPasswordPopupContainer.propTypes = {
  currentMarketer: marketerShape,
  currentUser: userShape,
  dispatch: PropTypes.func,
};

function select(state, ownProps) {
  const currentMarketer = currentMarketerSelector(state, ownProps);
  const currentUser = currentUserSelector(state, ownProps);

  return { currentMarketer, currentUser };
}

export default ConnectStoreHOC(connect(select)(ConfirmPasswordPopupContainer));

