import React from 'react';

const BroadcastIcon = (props) => {
  return (
    <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.167 4.167L5 7.5H1.667v5H5l4.167 3.333V4.167zM15.892 4.108a8.333 8.333 0 010 11.784M12.95 7.05a4.167 4.167 0 010 5.892"
        stroke="#333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

BroadcastIcon.defaultProps = {
  width: '20px',
  height: '20px',
  cursor: 'pointer',
};

export default BroadcastIcon;
