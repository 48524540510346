import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import UnsubscribeProspectPageComponent from './UnsubscribeProspectPageComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';
import { unsubscribeProspect } from 'api/prospects';

import confirmationDialogue from 'components/confirmation_dialogue';

const UnsubscribeProspectPageContainer = ({
  dispatch,
  match,
  queryParams: { encoded_prospect_id },
}) => {
  const [unsubscribeStatus, setUnsubscribeStatus] = React.useState('');

  const isRealUnsubscribeCase = match.path === '/prospects/unsubscribe';

  React.useEffect(() => {
    confirmationDialogue({
      body: translate('prospects.unsubscribe.confirmationText'),
      confirmLabel: translate('prospects.unsubscribe.confirmButton'),
      onConfirm: async () => {
        // Handle fake unsubscribe scenario (for linkio user)
        if (!isRealUnsubscribeCase) {
          setUnsubscribeStatus('unsubscribed');
          return;
        }

        // Handle real unsubscribe scenario (for the real prospect came from the email link)
        await unsubscribeProspect(dispatch, encoded_prospect_id).then(
          ({ isSuccess }) => isSuccess && setUnsubscribeStatus('unsubscribed'),
        );
      },
      onCancel: () => setUnsubscribeStatus('cancel'),
    });
  }, []);

  return <UnsubscribeProspectPageComponent unsubscribeStatus={unsubscribeStatus} />;
};

UnsubscribeProspectPageContainer.propTypes = {
  dispatch: PropTypes.func,
  ...withQueryParamsPropTypes,
};

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(),
)(UnsubscribeProspectPageContainer);
