import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProspectActionsPopupWithTableComponent from './ProspectActionsPopupWithTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  addProspectsToDripList,
  deleteProspectsFromDripLists,
  startCampaignForProspects,
} from 'api/prospects';

import { fetchEoDripListsSuccess } from 'actions/eoDripListActions';
import { fetchEoCampaignsSuccess } from 'actions/eoCampaignActions';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import { uiSelector } from 'selectors';

import confirmationDialogue from 'components/confirmation_dialogue';

const ProspectActionsPopupWithTableContainer = ({ actionType, dispatch, onClose, selectedIds }) => {
  React.useEffect(() => {
    // Need to drop data before fetching them again. Fetching and merging over old ones
    // leads to UI bug, when user clicks on one row but a wrong one is selected
    switch (actionType) {
      case 'startEoCampaign':
        dispatch(fetchEoCampaignsSuccess([]));
        break;
      case 'addToEoDripList':
      case 'removeFromEoDripList':
        dispatch(fetchEoDripListsSuccess([]));
        break;
    }
  }, [actionType, dispatch]);

  function handleSubmit(entityId) {
    switch (actionType) {
      case 'startEoCampaign':
        startCampaignForProspects(dispatch, selectedIds.toJS(), entityId);
        onClose();
        break;
      case 'addToEoDripList':
        addProspectsToDripList(dispatch, selectedIds.toJS(), entityId);
        onClose();
        break;
      case 'removeFromEoDripList':
        confirmationDialogue({
          body: translate('confirmations.default.title'),
          onConfirm: () => {
            deleteProspectsFromDripLists(dispatch, selectedIds.toJS(), {
              eoDripListIds: [entityId],
            });
            onClose();
          },
        });
        break;
    }
  }

  function handleDeleteFromAllDripLists() {
    confirmationDialogue({
      body: translate('confirmations.default.title'),
      onConfirm: () => {
        deleteProspectsFromDripLists(dispatch, selectedIds.toJS(), {
          deleteFromAllDripLists: true,
        });
        onClose();
      },
    });
  }

  return (
    <ProspectActionsPopupWithTableComponent
      actionType={actionType}
      onCancel={onClose}
      onSubmit={handleSubmit}
      onDeleteFromAllDripLists={handleDeleteFromAllDripLists}
    />
  );
};

ProspectActionsPopupWithTableContainer.propTypes = {
  actionType: PropTypes.oneOf(['startEoCampaign', 'addToEoDripList', 'removeFromEoDripList']),
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  selectedIds: selectedIdsList,
};

function select(state) {
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    selectedIds,
  };
}

export default ConnectStoreHOC(connect(select)(ProspectActionsPopupWithTableContainer));
