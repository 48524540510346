import React from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';

import { translate } from 'common/i18n';

import { optionsMap } from 'common/prop_types_shapes';

import Select from 'components_linkio/Select';
import ButtonComponent from 'components_linkio/button_component';

const SetValueInputsComponent = ({
  isSelected,
  onPropertyInputChange,
  onSelectInputChange,
  onSetProperties,
  onSourceInputChange,
  options,
  selectedProperty,
  selectedValue,
  tableType,
}) => {
  function handleSetProperties() {
    onSetProperties();
  }

  function handleSelectInputChange(object) {
    onSelectInputChange(object);
  }

  function handlePropertyInputChange(object) {
    const selectedProperty = object;
    const selectedValue = void 0;

    object && onPropertyInputChange(selectedProperty, selectedValue);
  }

  function handleSourceInputChange(object) {
    onSourceInputChange(object);
  }

  const anchorTypeOptions = options
    .get('anchorTypeOptions', iMap())
    .toList()
    .toJS();
  const statusOptions = options
    .get('linkStatusOptions', iMap())
    .toList()
    .toJS();
  const dlSourcesOptions = options
    .get('dlSourcesOptions', iMap())
    .toList()
    .toJS();

  const selectorTooltip = {
    text: translate('explanationMessages.atpBulkAction.selector'),
    placement: 'bottom',
    color: 'black',
  };

  function generateSelector(props) {
    const defaultSelectorProps = {
      blurInputOnSelect: false,
      isClearable: false,
      isCreatable: false,
      menuPlacement: 'top',
      placeholder: translate('destinationLink.bulkActions.column.placeholder'),
      skinOptions: {
        inTable: false,
      },
    };

    return <Select {...defaultSelectorProps} {...props} />;
  }

  const anchorTypeInput = (disabled) => {
    return generateSelector({
      isDisabled: disabled,
      name: 'anchorType',
      onChange: handleSelectInputChange,
      options: anchorTypeOptions,
      placeholder: translate('destinationLink.anchorType.placeholder'),
      tooltip: selectorTooltip,
      value: disabled ? null : selectedValue,
    });
  };

  const statusInput = (disabled) => {
    return generateSelector({
      isDisabled: disabled,
      name: 'status',
      onChange: handleSelectInputChange,
      options: statusOptions,
      placeholder: translate('destinationLink.status.placeholder'),
      tooltip: selectorTooltip,
      value: disabled ? null : selectedValue,
    });
  };

  const sourceInput = (disabled) => {
    return generateSelector({
      isClearable: true,
      isCreatable: true,
      isDisabled: disabled,
      name: 'source',
      onChange: handleSourceInputChange,
      options: dlSourcesOptions,
      placeholder: translate('destinationLink.source.placeholder'),
      tooltip: selectorTooltip,
      value: disabled ? null : selectedValue,
    });
  };

  const propertySelectorInput = (disabled) => {
    const propertySelectorOptions = [];

    if (tableType !== 'suggestions') {
      propertySelectorOptions.push({
        value: 'anchorType',
        label: translate('destinationLink.anchorType.title'),
      });
    }

    if (tableType !== 'backlinks' && tableType !== 'competitors') {
      propertySelectorOptions.push(
        { value: 'status', label: translate('destinationLink.status.title') },
        { value: 'source', label: translate('destinationLink.source.title') },
      );
    }

    const value = disabled || selectedProperty.value === 'empty' ? null : selectedProperty;

    return generateSelector({
      isDisabled: disabled,
      name: 'propertySelector',
      onChange: handlePropertyInputChange,
      options: propertySelectorOptions,
      placeholder: translate('destinationLink.bulkActions.column.placeholder'),
      tooltip: selectorTooltip,
      value,
    });
  };

  const emptyInput = () => {
    return generateSelector({
      isDisabled: true,
      name: 'empty',
      onChange: (fn) => fn,
      options: [],
      placeholder: translate('destinationLink.bulkActions.value.placeholder'),
      tooltip: selectorTooltip,
      value: null,
    });
  };

  const inputs = {
    anchorType: anchorTypeInput,
    empty: emptyInput,
    source: sourceInput,
    status: statusInput,
    propertySelector: propertySelectorInput,
  };

  const setButtonIsEnabled = isSelected && selectedValue;

  return (
    <div className="bulk-atp-actions-component__action">
      <div className="bulk-atp-actions-component__select">
        {inputs['propertySelector'](!isSelected)}
      </div>
      <div className="bulk-atp-actions-component__select">
        {inputs[selectedProperty.value](!isSelected)}
      </div>
      <div className="bulk-atp-actions-component__button">
        <ButtonComponent
          isDisabled={!setButtonIsEnabled}
          isGreen
          onClick={handleSetProperties}
          tooltip={selectorTooltip}
        >
          {translate('destinationLink.buttons.set')}
        </ButtonComponent>
      </div>
    </div>
  );
};

SetValueInputsComponent.propTypes = {
  isSelected: PropTypes.bool,
  onPropertyInputChange: PropTypes.func,
  onSelectInputChange: PropTypes.func,
  onSetProperties: PropTypes.func,
  onSourceInputChange: PropTypes.func,
  options: optionsMap,
  selectedProperty: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  selectedValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  tableType: PropTypes.string,
};

export default SetValueInputsComponent;
