const FETCH_BRAND_KEYWORDS__FAILURE = 'FETCH_BRAND_KEYWORDS__FAILURE';
const FETCH_BRAND_KEYWORDS__START = 'FETCH_BRAND_KEYWORDS__START';
const FETCH_BRAND_KEYWORDS__SUCCESS = 'FETCH_BRAND_KEYWORDS__SUCCESS';
const UPDATE_BRAND_KEYWORDS__FAILURE = 'UPDATE_BRAND_KEYWORDS__FAILURE';
const UPDATE_BRAND_KEYWORDS__SUCCESS = 'UPDATE_BRAND_KEYWORDS__SUCCESS';

function fetchBrandKeywordsFailure() {
  return { type: FETCH_BRAND_KEYWORDS__FAILURE };
}

function fetchBrandKeywordsStart() {
  return { type: FETCH_BRAND_KEYWORDS__START };
}

function fetchBrandKeywordsSuccess(keywords) {
  return { type: FETCH_BRAND_KEYWORDS__SUCCESS, keywords };
}

function updateBrandKeywordsFailure() {
  return { type: UPDATE_BRAND_KEYWORDS__FAILURE };
}

function updateBrandKeywordsSuccess(keywords) {
  return { type: UPDATE_BRAND_KEYWORDS__SUCCESS, keywords };
}

export {
  FETCH_BRAND_KEYWORDS__FAILURE,
  FETCH_BRAND_KEYWORDS__START,
  FETCH_BRAND_KEYWORDS__SUCCESS,
  UPDATE_BRAND_KEYWORDS__FAILURE,
  UPDATE_BRAND_KEYWORDS__SUCCESS,
  fetchBrandKeywordsFailure,
  fetchBrandKeywordsStart,
  fetchBrandKeywordsSuccess,
  updateBrandKeywordsFailure,
  updateBrandKeywordsSuccess,
};
