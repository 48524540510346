import React from 'react';

import BulkCompetitorsSetupBulkActions from './BulkCompetitorsSetupBulkActions';
import BulkCompetitorsSetupTable from './BulkCompetitorsSetupTable';

import './bulkCompetitorsSetup.scss';

const BulkCompetitorsSetupComponent = () => {
  return (
    <div className="bulk-competitors-setup">
      <BulkCompetitorsSetupBulkActions />

      <BulkCompetitorsSetupTable />
    </div>
  );
};

export default BulkCompetitorsSetupComponent;
