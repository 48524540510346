import React from 'react';
import PropTypes from 'prop-types';
import { subMonths, compareAsc, addMonths } from 'date-fns';

import CalendarsSideBySideComponent from './CalendarsSideBySideComponent';

const CalendarsSideBySideContainer = ({
  dateEnd,
  dateStart,
  onChangeRange,
}) => {
  const [[leftMonth, rightMonth], setMonths] = React.useState([
    subMonths(dateEnd, 1),
    dateEnd,
  ]);
  const [currentSelect, setCurrentSelect] = React.useState('endRange');

  React.useEffect(() => {
    setMonths([subMonths(dateEnd, 1), dateEnd]);
  }, [dateEnd]);

  const onClickPrevMonth = () => {
    setMonths([subMonths(leftMonth, 1), subMonths(rightMonth, 1)]);
  };

  const onClickNextMonth = () => {
    setMonths([addMonths(leftMonth, 1), addMonths(rightMonth, 1)]);
  };

  const handleClickDate = (date) => {
    if (currentSelect === 'startRange') {
      const newRange = [dateStart, date].sort(compareAsc);
      setCurrentSelect('endRange');
      onChangeRange(newRange);
      return;
    }

    if (currentSelect === 'endRange') {
      const newRange = [dateEnd, date].sort(compareAsc);
      setCurrentSelect('startRange');
      onChangeRange(newRange);
      return;
    }
  };

  const handleChangeDateStart = (date) => onChangeRange([date, dateEnd]);
  const handleChangeDateEnd = (date) => onChangeRange([dateStart, date]);

  return (
    <CalendarsSideBySideComponent
      onClickDate={handleClickDate}
      dateStart={dateStart}
      dateEnd={dateEnd}
      leftMonth={leftMonth}
      rightMonth={rightMonth}
      onClickPrevMonth={onClickPrevMonth}
      onClickNextMonth={onClickNextMonth}
      onChangeDateStart={handleChangeDateStart}
      onChangeDateEnd={handleChangeDateEnd}
    />
  );
};

CalendarsSideBySideContainer.propTypes = {
  dateEnd: PropTypes.instanceOf(Date).isRequired,
  dateStart: PropTypes.instanceOf(Date).isRequired,
  onChangeRange: PropTypes.func.isRequired,
};

export default CalendarsSideBySideContainer;
