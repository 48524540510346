import React from 'react';
import { translate } from '../../../common/i18n';

import './copyright_component.scss';


const CopyrightComponent = () => {
  return (
    <footer className="copyright-component">
      <h5>
        {translate('copyright', { value: new Date().getFullYear() })}
      </h5>
    </footer>
  );
};

export default CopyrightComponent;
