import React from 'react';

const SeoSettingsIcon = (props) => (
  <svg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="clip0">
      <path
        d="M15.382.104A.426.426 0 0015.105 0h-2.634a.386.386 0 00-.385.385c0 .211.173.384.385.384l1.7-.004-4.158 4.162-1.458-1.465a.405.405 0 00-.273-.12.405.405 0 00-.273.12L.625 10.854a.388.388 0 00-.012.546.388.388 0 00.546.011c.004-.003.008-.007.012-.007l7.115-7.116 1.458 1.454a.4.4 0 00.546 0l4.435-4.434-.004 1.696c0 .211.169.38.38.38h.004a.38.38 0 00.385-.376v-.004l.004-2.63c0-.1-.042-.197-.112-.27z"
        strokeWidth="0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.655 7.846h.731V16h-.73V7.846zm-3.038 2.885h.73V16h-.73v-5.27zm-2.308 3.308H.617V16h.692v-1.961z"
        strokeWidth="0"
      />
      <g fill="none" clipPath="clip1" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 13a1 1 0 100-2 1 1 0 000 2z" strokeWidth=".6" />
        <path
          d="M14.467 13a.55.55 0 00.11.607l.02.02a.669.669 0 01-.217 1.088.668.668 0 01-.727-.145l-.02-.02a.55.55 0 00-.606-.11.55.55 0 00-.334.503V15a.667.667 0 11-1.333 0v-.03a.55.55 0 00-.36-.503.55.55 0 00-.607.11l-.02.02a.667.667 0 11-.943-.944l.02-.02a.55.55 0 00.11-.606.55.55 0 00-.503-.334H9a.667.667 0 110-1.333h.03a.55.55 0 00.503-.36.55.55 0 00-.11-.607l-.02-.02a.666.666 0 11.944-.943l.02.02a.55.55 0 00.606.11H11a.55.55 0 00.333-.503V9a.667.667 0 011.334 0v.03a.55.55 0 00.333.503.55.55 0 00.607-.11l.02-.02a.668.668 0 11.943.944l-.02.02a.55.55 0 00-.11.606V11a.55.55 0 00.503.333H15a.667.667 0 010 1.334h-.03a.55.55 0 00-.503.333v0z"
          strokeWidth=".7"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h16v16H0z" />
      </clipPath>
      <clipPath id="clip1">
        <path transform="translate(8 8)" d="M0 0h8v8H0z" />
      </clipPath>
    </defs>
  </svg>
);

SeoSettingsIcon.defaultProps = {
  height: '16px',
  width: '16px',
  stroke: "#474A4D",
};

export default SeoSettingsIcon;
