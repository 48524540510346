import { createSelector } from 'reselect';
import { Map as iMap, List as iList } from 'immutable';


const notifications = (state) => state.get('notifications', iMap());


const allNotificationsSelector = createSelector(
  [notifications],
  (notifications) => notifications.get('all', iList())
);

const recentNotificationsSelector = createSelector(
  [notifications],
  (notifications) => notifications.get('recent', iList())
);

const unreadNotificationsCountSelector = createSelector(
  [notifications],
  (notifications) => notifications.get('unreadCount', 0)
);


export {
  allNotificationsSelector,
  recentNotificationsSelector,
  unreadNotificationsCountSelector,
};
