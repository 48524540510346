import { fromJS } from 'immutable';

import { TOGGLE_ANCHOR_PROGRESS_PANE } from 'actions/brand_page_actions';
import { TOGGLE_NOTIFICATIONS_POPUP } from 'actions/notificationActions';
import { UI_SIDEBAR__TOGGLE, BREADCRUMBS_BRANDS_SELECT__TOGGLE } from 'actions/ui_actions';

import { getCookie } from 'common/utils';

const initialState = fromJS({
  brand: {
    anchorProgressPaneIsVisible: true,
  },
  brand_page: {
    anchorProgressPaneIsVisible: true,
  },
  notifications: {
    showNotificationsPopup: false,
  },
  sidebar: {
    isOpen: getCookie('sidebarIsOpen', 'true') === 'true',
  },
  breadcrumbsBrandsSelect: {
    isOpen: false,
  },
});

export default function openCloseReducer(state = initialState, action) {
  switch (action.type) {
    case BREADCRUMBS_BRANDS_SELECT__TOGGLE: {
      const isOpen = state.getIn(['breadcrumbsBrandsSelect', 'isOpen']);

      state = state.setIn(['breadcrumbsBrandsSelect', 'isOpen'], !isOpen);

      break;
    }
    case TOGGLE_ANCHOR_PROGRESS_PANE: {
      const { pageParentType } = action;
      const isVisible = state.getIn([pageParentType, 'anchorProgressPaneIsVisible']);

      state = state.setIn([pageParentType, 'anchorProgressPaneIsVisible'], !isVisible);

      break;
    }
    case TOGGLE_NOTIFICATIONS_POPUP: {
      const isVisible = state.getIn(['notifications', 'showNotificationsPopup']);

      state = state.setIn(['notifications', 'showNotificationsPopup'], !isVisible);

      break;
    }

    case UI_SIDEBAR__TOGGLE: {
      const isOpen = state.getIn(['sidebar', 'isOpen']);

      state = state.setIn(['sidebar', 'isOpen'], !isOpen);

      break;
    }
  }

  return state;
}
