import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import Link from 'components_linkio/link';
import LockIcon from 'common/icons/lock';

import './subscription_limit_ribbon_component.scss';

const SubscriptionLimitRibbonComponent = ({ children }) => {
  return (
    <div className="subscription-limit-ribbon-component">
      <div>
        <LockIcon />
      </div>
      <div className="subscription-limit-ribbon-component__message">{children}</div>
    </div>
  );
};

SubscriptionLimitRibbonComponent.propTypes = {
  children: PropTypes.node,
};

const MailToUsLink = () => {
  return (
    <span>
      {translate('subscriptionLimitRibbon.contactEmailLink.text')}
      <Link href={`mailto:${translate('subscriptionLimitRibbon.contactEmailLink.link')}`}>
        {translate('subscriptionLimitRibbon.contactEmailLink.link')}
      </Link>
    </span>
  );
};

const UpgradeSubscriptionLink = ({ onClick }) => {
  return (
    <span>
      {translate('subscriptionLimitRibbon.upgradePopupLink.text')}
      <a href="#" onClick={onClick}>
        {translate('subscriptionLimitRibbon.upgradePopupLink.link')}
      </a>
    </span>
  );
};

UpgradeSubscriptionLink.propTypes = {
  onClick: PropTypes.func,
};

export { MailToUsLink, UpgradeSubscriptionLink };
export default SubscriptionLimitRibbonComponent;
