import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';

const newsletterSettings = (state) => state.get('newsletterSettings');

const newsletterSettingsSelector = createSelector(
  [newsletterSettings],
  (newsletterSettings) => newsletterSettings || iMap()
);

export {
  newsletterSettingsSelector,
};
