export default {
  options: {
    ahrefsSyncInterval: {
      daily: { label: 'Daily' },
      each3Days: { label: 'Once in 3 days' },
      weekly: { label: 'Weekly' },
      monthly: { label: 'Monthly' },
    },
    anchorType: {
      titleTag: { label: 'Title Tag' },
      exactKeyword: { label: 'Exact Keyword' },
      justNatural: { label: 'Just Natural' },
      nakedUri: { label: 'Naked URL' },
      keywordPlusWord: { label: 'Keyword Plus Word' },
      onlyPartOfKeyword: { label: 'Partial Match Keyword' },
      brandAndKeywordTogether: { label: 'Brand & Keyword Together' },
      branded: { label: 'Brand Name' },
      websiteNameCom: { label: 'WebsiteName.com' },
      noText: { label: 'No Text' },
      homepageUri: { label: 'Homepage URL' },
      nakedUriWoHttp: { label: 'Naked URL without http://' },
      totallyRandom: { label: 'Totally Random' },
    },
    eoCampaignStepAction: {
      eoCampaignStepEmail: { label: 'Email' },
      eoCampaignStepWait: { label: 'Wait' },
    },
    includeByUser: {
      auto: { label: 'Auto' },
      no: { label: 'No' },
      yes: { label: 'Yes' },
    },
    included: {
      false: { label: 'No' },
      true: { label: 'Yes' },
    },
    kwDomainMatch: {
      exactMatch: { label: 'Yes' },
      noMatch: { label: 'No' },
      partialMatch: { label: 'Partially' },
    },
    linkStatus: {
      started: { label: 'Started' },
      notStarted: { label: 'Not Started' },
      published: { label: 'Published' },
    },
    nofollow: {
      true: { label: 'NF' },
      false: { label: 'DF' },
    },
    priority: {
      p1: { label: 'P1' },
      p2: { label: 'P2' },
      p3: { label: 'P3' },
      p4: { label: 'P4' },
      p5: { label: 'P5' },
    },
    profileType: {
      assistant: { label: 'Assistant' },
      pm: { label: 'Project Manager' },
      admin: { label: 'Admin' },
      guest: { label: 'Guest' },
      powerSme: { label: 'Power sme' },
    },
    permissions: {
      admin: { label: 'Admin' },
      ahrefsConnectionAdmin: { label: 'Ahrefs connection admin' },
      atpAdmin: { label: 'ATP admin' },
      atpManager: { label: 'ATP manager' },
      atpViewer: { label: 'ATP viewer' },
      brandsAdmin: { label: 'Brands admin' },
      brandsManager: { label: 'Brands manager' },
      brandsViewer: { label: 'Brands viewer' },
      crawlerSettingsAdmin: { label: 'Crawler settings admin' },
      emailOutreachAdmin: { label: 'Email Outreach admin' },
      emailOutreachManager: { label: 'Email Outreach manager' },
      emailOutreachViewer: { label: 'Email Outreach viewer' },
      employeeAdmin: { label: 'Team Members admin' },
      employeeManager: { label: 'Team Members manager' },
      employeeViewer: { label: 'Team Members viewer' },
      employeePermissionsAdmin: { label: 'Team Member permissions admin' },
      percentageSettingsAdmin: { label: 'Percentage settings admin' },
      percentageSettingsManager: { label: 'Percentage settings manager' },
      percentageSettingsViewer: { label: 'Percentage settings viewer' },
      subscriptionsAdmin: { label: 'Subscriptions admin' },
    },
    crawlingInterval: {
      weekly: { label: 'Weekly' },
      monthly: { label: 'Monthly' },
    },
    websiteType: {
      ecommerce: { label: 'E-Commerce' },
      localBusiness: { label: 'Local Business' },
      national: { label: 'National' },
      software: { label: 'Software' },
    },
  },
};
