import { Map as iMap } from 'immutable';

import {
  FETCH_CRAWLERS_STATUS__FAILURE,
  FETCH_CRAWLERS_STATUS__START,
  FETCH_CRAWLERS_STATUS__SUCCESS,
} from '../actions/crawler_actions';

const initialState = iMap();

export default function crawlersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CRAWLERS_STATUS__FAILURE:
    case FETCH_CRAWLERS_STATUS__START: {
      break;
    }

    case FETCH_CRAWLERS_STATUS__SUCCESS: {
      const { status } = action;
      state = state.merge(state, { status });

      break;
    }
  }

  return state;
}
