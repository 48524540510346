import { fromJS, Map as iMap } from 'immutable';
import { keyBy } from 'lodash';

import {
  FETCH_PERMISSIONS__SUCCESS,
  UPDATE_USER_PERMISSIONS__SUCCESS,
} from '../actions/permissionActions';

import { camelizeObject } from './utils';


const initialState = iMap();


export default function permissionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PERMISSIONS__SUCCESS: {
      state = state.merge(state, fromJS(keyBy(action.permissions.map(camelizeObject), 'userId')));

      break;
    }
    case UPDATE_USER_PERMISSIONS__SUCCESS: {
      state = state.merge(state, fromJS(keyBy([camelizeObject(action.userPermissions)], 'userId')));

      break;
    }
  }

  return state;
}
