import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map as iMap } from 'immutable';

import AddSuggestionsInputComponent from './AddSuggestionsInputComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { visiblePageIdByParentSelector } from 'selectors';

import { reDistributeDls, updatePage } from 'api/brand_page';

import { brandPageShape } from 'common/prop_types_shapes';

import { showErrorMessage } from 'common/utils';
import { translate } from 'common/i18n';

class AddSuggestionsInputContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    visiblePage: brandPageShape,
    visiblePageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);

    this.state = {
      addLinksCount: props.visiblePage.get('suggestionsCount', 20),
    };
  }

  handleShowErrorMessage = (message) => showErrorMessage(this.props.dispatch, message);

  handleChangeAddLinksCount = (event) => {
    const { target } = event;
    const value = (target || {}).value;

    if (value > 100) {
      showErrorMessage(this.props.dispatch, translate('errors.limitedLinks')({ value: 100 }), true);
    }

    if (value < 0) {
      showErrorMessage(this.props.dispatch, translate('errors.mustBePositive'));
    }

    let number = value > 100 ? 100 : value;
    number = value < 0 ? 0 : value;

    (target || {}).value = number;

    this.setState({ addLinksCount: number });
  };

  handleAddSuggestions = async () => {
    const { addLinksCount } = this.state;
    if (!addLinksCount) {
      return;
    }

    const { visiblePageId, dispatch } = this.props;

    await updatePage(dispatch, visiblePageId, { suggestionsCount: addLinksCount });
    await reDistributeDls(dispatch, visiblePageId);

    this.setState({ addLinksCount });
  };

  render() {
    const { addLinksCount } = this.state;

    return (
      <AddSuggestionsInputComponent
        addLinksCount={String(addLinksCount)}
        onAddSuggestions={this.handleAddSuggestions}
        onShowErrorMessage={this.handleShowErrorMessage}
        onChangeAddLinksCount={this.handleChangeAddLinksCount}
      />
    );
  }
}

function select(state, ownProps) {
  const brandPages = state.get('brandPages', iMap());
  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);
  const visiblePage = brandPages.get(String(visiblePageId), iMap());

  return {
    visiblePage,
    visiblePageId,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(AddSuggestionsInputContainer)));
