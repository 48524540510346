import { translate } from '../common/i18n';

import {
  handleAPIError,
  postToAPI,
} from './utils';


async function sendFeedback(dispatch, message) {
  try {
    const resp = await postToAPI(
      Routes.feedback_path(),
      message
    );
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, (fn) => fn, translate('errors.cantSendFeedback'));
  }
}

async function updateHelpcrunchCustomAttributes(dispatch, attributes) {
  try {
    const resp = await postToAPI(
      Routes.update_custom_attributes_helpcrunch_index_path(),
      { ...attributes },
    );
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, (fn) => fn, translate('errors.cantUpdateHelpcrunchCustomAttributes'));
  }
}

export { sendFeedback, updateHelpcrunchCustomAttributes };
