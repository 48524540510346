class Column {
  constructor(params) {
    this.type = (params && params.type) || '';
    this.options = (params && params.options) || {};
  }
}

const checkboxColumn = (options) => new Column({ type: 'checkbox', options });
const genericColumn = (options) => new Column({ options });
const reactSelectColumn = (options) => new Column({ type: 'reactSelect', options });
const textColumn = (options) => new Column({ type: 'text', options });
const nameColumn = (options) => new Column({ type: 'name', options });

export {
  checkboxColumn,
  genericColumn,
  nameColumn,
  reactSelectColumn,
  textColumn,
};
