import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';

import { translate } from 'common/i18n';

const COLORS = [
  '#005689',
  '#1ABC9C',
  '#EB7A26',
  '#E3204B',
  '#00A0FF',
  '#795548',
  '#FFD4B5',
  '#FFB0C2',
  '#8ad3ff',
  '#886ce6',
  '#a44500',
];

const buildLabel = (appModule) => ({ percent, name, value }) => {
  const formattedPercent = `${(percent * 100).toFixed(1)}%`;
  return `${translate(`chart.metrics.${appModule}.${name}`)}: ${formattedPercent} (${value})`;
};

const PieChartComponent = ({ appModule, chartData }) => {
  return (
    <PieChart width={800} height={400} className="pie-chart-component">
      <Pie
        data={chartData}
        dataKey="value"
        cx={400}
        cy={200}
        outerRadius="70%"
        label={buildLabel(appModule)}
        isAnimationActive={false}
      >
        {chartData.map(({ name }, i) => (
          <Cell key={name} fill={COLORS[i]} />
        ))}
      </Pie>
    </PieChart>
  );
};

PieChartComponent.propTypes = {
  appModule: PropTypes.oneOf(['backlinks', 'emailOutreach']),
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

export default PieChartComponent;
