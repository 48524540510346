import React from 'react';
import PropTypes from 'prop-types';

import AutodiscoverCompetitorsButton from './AutodiscoverCompetitorsButton';
import ManuallyAddCompetitorsPopup from './ManuallyAddCompetitorsPopup';

import { translate } from 'common/i18n';

import BackButton from 'components_linkio/BackButton';
import ButtonComponent from 'components_linkio/button_component';
import InfoCard from 'components_linkio/InfoCard';
import SearchInput from 'components/SearchInput';
import SpinnerComponent from 'components_linkio/Spinner';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import './bulkCompetitorsSetupBulkActions.scss';

const BulkCompetitorsSetupBulkActionsComponent = ({
  addingCompetitorsToAtp,
  handleSubmitSearch,
  onAddCompetitorsToAtpClick,
  selectedPagesCount,
}) => {
  return (
    <div className="bulk-competitors-setup-bulk-actions">
      <InfoCard>
        <InfoCard.Title>{translate('bulkCompetitorsSetup.bulkActions.title')}</InfoCard.Title>
        <InfoCard.Subtitle>
          {translate('bulkCompetitorsSetup.bulkActions.subtitle')}
        </InfoCard.Subtitle>
        <InfoCard.BtnGroup>
          <AutodiscoverCompetitorsButton />
          <ToggleShowPopup Popup={<ManuallyAddCompetitorsPopup />}>
            <ButtonComponent
              className="bulk-competitors-setup-bulk-actions__manually-add-competitors"
              isInitial
              isSecondary
              isBlue
            >
              {translate('bulkCompetitorsSetup.bulkActions.manuallyAddCompetitors')}
            </ButtonComponent>
          </ToggleShowPopup>
        </InfoCard.BtnGroup>
      </InfoCard>

      <div className="bulk-competitors-setup-bulk-actions__buttons-panel-item">
        <SearchInput onSubmit={handleSubmitSearch} />

        <div className="bulk-competitors-setup-bulk-actions__btn-group">
          <BackButton isWhite isInitial>
            {translate('bulkCompetitorsSetup.bulkActions.cancel')}
          </BackButton>

          <ButtonComponent onClick={onAddCompetitorsToAtpClick} isGreen isInitial>
            {addingCompetitorsToAtp ? (
              <SpinnerComponent />
            ) : (
              translate('bulkCompetitorsSetup.bulkActions.addToAtp')(selectedPagesCount)
            )}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

BulkCompetitorsSetupBulkActionsComponent.propTypes = {
  addingCompetitorsToAtp: PropTypes.bool.isRequired,
  handleSubmitSearch: PropTypes.func,
  onAddCompetitorsToAtpClick: PropTypes.func,
  selectedPagesCount: PropTypes.number,
};

export default BulkCompetitorsSetupBulkActionsComponent;
