import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ExternalLinkIcon from 'common/icons/external_link';
import SearchInput from 'components/SearchInput';
import SpinnerComponent from 'components_linkio/Spinner';

import './googleDriveFilesList.scss';

const buildOwnersString = (owners) =>
  owners
    .reduce((result, { displayName }) => {
      displayName && result.push(displayName);
      return result;
    }, [])
    .join(', ');

const buildFormattedDate = (date) => format(new Date(date), 'MMM, yyyy, d h:mm a');

const GoogleDriveFilesListComponent = ({ googleDriveFiles, isLoading, onSelect }) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  function onSubmitSearch(value) {
    setSearchTerm(value);
  }

  const filesFilteredByName = googleDriveFiles.filter(({ name }) =>
    name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="google-drive-import">
      {isLoading && (
        <div className="google-drive-import__processing">
          {translate('prospects.importProspectsPopup.importFromDrive.loading')}
          <SpinnerComponent isBlack />
        </div>
      )}

      {!isLoading && (
        <>
          <h4 className="google-drive-import__title">
            {translate('prospects.importProspectsPopup.importFromDrive.filesCount')(
              googleDriveFiles.length,
            )}
          </h4>

          <SearchInput withDefaultValue defaultValue={searchTerm} onSubmit={onSubmitSearch} />

          <ul>
            {filesFilteredByName.map((file) => {
              const { id, modifiedTime, name, owners, webViewLink } = file;
              const ownersString = buildOwnersString(owners);
              const formattedDate = buildFormattedDate(modifiedTime);

              return (
                <li key={file.id} className="google-drive-file-item-component">
                  <div>
                    <h4>{name}</h4>

                    {ownersString && (
                      <p>
                        {translate('prospects.importProspectsPopup.googleDriveFile.owner')}{' '}
                        {ownersString}
                      </p>
                    )}

                    <p>
                      {translate('prospects.importProspectsPopup.googleDriveFile.lastChanged')}{' '}
                      {formattedDate}
                    </p>

                    <a className="text_link" href={webViewLink} target="blank_">
                      <ExternalLinkIcon />
                      {translate('prospects.importProspectsPopup.googleDriveFile.view')}
                    </a>
                  </div>

                  <ButtonComponent isInitial isBlue onClick={onSelect(id)}>
                    {translate('prospects.importProspectsPopup.googleDriveFile.select')}
                  </ButtonComponent>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

GoogleDriveFilesListComponent.propTypes = {
  googleDriveFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      modifiedTime: PropTypes.string,
      name: PropTypes.string,
      owners: PropTypes.arrayOf(
        PropTypes.shape({
          displayName: PropTypes.string,
        }),
      ),
      webViewLink: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default GoogleDriveFilesListComponent;
