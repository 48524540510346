const proxies = {
  proxy: {
    boolToString: (bool) => {
      if (bool === undefined || bool === null) {
        return '--';
      }
      return bool ? 'Yes' : 'No';
    },
    alive: {
      title: "Alive",
    },
    bannedByGoogle: {
      title: "Banned by Goggle",
    },
    delayInfo: {
      title: "Delay Info",
      nextTryDescription: (attemptNumber, delayInWords) => {
        return `Attempt ${attemptNumber} of 5. Next try ${delayInWords}.`;
      },
    },
    canVisitGoogle: {
      title: "Can visit Google",
    },
    canVisitHttps: {
      title: "Can visit httpS",
    },
    fullProxy: {
      title: "Proxy",
    },
    delete: {
      title: "Delete",
    },
    id: {
      title: "ID",
    },
    useCounter: {
      title: "Usage Counter",
    },
    onlinePercentage: {
      title: "Availability",
      notCheckedYetStatus: 'Not checked yet',
    },
    speed: {
      title: "Speed",
      isOfflineStatus: "Offline",
    },
    buttons: {
      checkNow: "Check Now",
    },
    newProxies: {
      placeholder: 'You must follow (http|https|socks5)://username:password@ipaddress:port template.',
      button: 'Add new proxies',
    },
  },
};

export default proxies;
