import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './toggleShowPopup.scss';

const ToggleShowPopupComponent = ({
  children,
  className,
  disabled,
  onCloseCb,
  onSubmitCb,
  Popup,
  initialShowPopupState,
  show,
}) => {
  const [isShow, setShowPopup] = React.useState(initialShowPopupState);

  React.useEffect(() => {
    show && !disabled && setShowPopup(true);
  }, [show, disabled]);

  const setShow = () => !disabled && setShowPopup(true);
  const hide = () => {
    onCloseCb && onCloseCb();
    setShowPopup(false);
  };
  const onSubmit = (data) => {
    onSubmitCb && onSubmitCb(data);
  };

  const toggleShowPopupStyles = cx('toggle-show-popup', {
    [className]: !!className,
  });

  return (
    <>
      <div className={toggleShowPopupStyles} onMouseDown={setShow}>
        {children}
      </div>
      {isShow && React.cloneElement(Popup, { onClose: hide, onSubmit })}
    </>
  );
};

ToggleShowPopupComponent.propTypes = {
  Popup: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  initialShowPopupState: PropTypes.bool,
  onCloseCb: PropTypes.func,
  onSubmitCb: PropTypes.func,
  show: PropTypes.bool,
};

export default ToggleShowPopupComponent;
