import React from 'react';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import BuyVerificationCreditsPopup from '../BuyVerificationCreditsPopup';

const BuyVerificationCreditsButtonComponent = () => {
  return (
    <ToggleShowPopup Popup={<BuyVerificationCreditsPopup />}>
      <ButtonComponent isInitial isBlue className="buy-verification-credits-button">
        {translate('eoEmailVerification.buttons.buyVerificationCredits')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

export default BuyVerificationCreditsButtonComponent;
