import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS, Map as iMap } from 'immutable';
import { keyBy, find, isEmpty } from 'lodash';

import SavedPercentagesTableComponent from './saved_percentages_table_component';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  createPercentageScheme,
  updatePercentageScheme,
  deletePercentageScheme,
} from 'api/percentage_scheme';

import { fetchPagePercentage, updatePage } from 'api/brand_page';

import BinIcon from 'common/icons/bin';
import { translate } from 'common/i18n';
import { optionsMap, percentageSchemesMap, policiesShape } from 'common/prop_types_shapes';

import { optionsSelector } from 'selectors';

import { policiesSelector } from 'selectors/railsContextSelectors';

import { showSuccessMessage, trackHelpcrunchEvent } from 'common/utils';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import confirmationDialogue from 'components/confirmation_dialogue';

import { calculatePercentagesSum } from 'components/percentage_settings/utils';

import './saved_percentages_modal_component.scss';

class SavedPercentagesModalComponent extends React.Component {
  static propTypes = {
    anchorTypeOptions: optionsMap.isRequired,
    currentPercentageSchemesMap: percentageSchemesMap.isRequired,
    dispatch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    percentageSchemeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    policies: policiesShape.isRequired,
    setDefaultToPage: PropTypes.shape({
      brandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      brandPageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  };

  constructor(props) {
    super(props);
    const { currentPercentageSchemesMap, percentageSchemeId } = props;

    const currentPercentageScheme = currentPercentageSchemesMap.get(
      String(percentageSchemeId),
      iMap(),
    );

    this.state = {
      currentPercentageScheme,
      errors: {},
    };
  }

  handleCancelClick = () => this.props.onClose();

  onConfirmDeleteClick = async () => {
    const { dispatch, percentageSchemeId, onClose } = this.props;

    await deletePercentageScheme(dispatch, percentageSchemeId);
    onClose();
  };

  handleDeleteClick = () => {
    const { currentPercentageScheme } = this.state;

    if (!currentPercentageScheme.get('hasBrandPages')) {
      this.onConfirmDeleteClick();
      return;
    }

    confirmationDialogue({
      body: translate('confirmations.deletePercentageScheme.body'),
      onConfirm: this.onConfirmDeleteClick,
    });
  };

  handleSaveClick = async () => {
    const { dispatch, percentageSchemeId, onClose, setDefaultToPage } = this.props;
    const { currentPercentageScheme } = this.state;
    if (!isEmpty(this.validateData())) {
      return;
    }

    const currentPercentageSchemeItems = currentPercentageScheme.get('percentageSchemeItems');
    const preparedPercentageScheme = currentPercentageScheme
      .set('percentageSchemeItems', currentPercentageSchemeItems.toList())
      .toJS();

    let resp = {};
    if (percentageSchemeId === 'newCustomScheme') {
      resp = await createPercentageScheme(dispatch, preparedPercentageScheme);
    } else {
      resp = await updatePercentageScheme(dispatch, preparedPercentageScheme);
    }

    if (!isEmpty(resp.errors)) {
      this.setState({ errors: resp.errors });
      return;
    }

    if (percentageSchemeId === 'newCustomScheme') {
      trackHelpcrunchEvent('add.customPercentage');
    }

    if (setDefaultToPage) {
      const { brandPageId } = setDefaultToPage;
      await updatePage(dispatch, brandPageId, { percentageSchemeId: resp.id });
      await fetchPagePercentage(dispatch, brandPageId);
      showSuccessMessage(
        dispatch,
        translate('brandPage.messages.assignNewPercentageSchemeSuccessfully'),
      );
    }

    onClose();
  };

  validateData = () => {
    const { currentPercentageScheme } = this.state;

    const currentPercentageSchemeItems = currentPercentageScheme.get('percentageSchemeItems');
    const errors = {};

    if (!currentPercentageScheme.get('title')) {
      errors.title = translate('percentageSettings.savedPercentages.modal.errors.emptyTitle');
    }

    currentPercentageSchemeItems.forEach((item) => {
      const idealPercentageAsNumber = Number(item.get('idealPercentage'));

      const isIdealPercentageValid =
        !Number.isNaN(idealPercentageAsNumber) &&
        idealPercentageAsNumber >= 0 &&
        idealPercentageAsNumber <= 100;

      if (!isIdealPercentageValid) {
        errors.idealPercentage = translate(
          'percentageSettings.savedPercentages.modal.errors.notValidVIdealPercentage',
        );
      }
    });

    const percentageSummaryTotal = calculatePercentagesSum(currentPercentageSchemeItems);
    if (percentageSummaryTotal !== 100) {
      errors.percentageCounter = translate(
        'percentageSettings.savedPercentages.modal.errors.percentageNot100',
      );
    }

    this.setState({ errors });
    return errors;
  };

  handlePercentageSchemeChange = (changedData) => {
    const { percentageSchemeId } = this.props;
    const { currentPercentageScheme } = this.state;

    const currentPercentageSchemeItems = currentPercentageScheme.get('percentageSchemeItems');

    const changedPercentageSchemeItems = currentPercentageSchemeItems.map((item) => {
      const newIdealPercentage = find(changedData, (row) => {
        return row.anchorType === item.get('anchorType');
      })[percentageSchemeId];

      return item.set('idealPercentage', newIdealPercentage);
    });

    const changedPercentageScheme = currentPercentageScheme.set(
      'percentageSchemeItems',
      changedPercentageSchemeItems,
    );

    this.setState({ currentPercentageScheme: changedPercentageScheme });
  };

  handleTitleChange = (event) => {
    this.setState({
      currentPercentageScheme: this.state.currentPercentageScheme.set('title', event.target.value),
    });
  };

  deleteIconIsVisible = () => {
    const { policies, percentageSchemeId } = this.props;
    const { currentPercentageScheme } = this.state;

    const canDelete = policies.getIn(['percentageScheme', 'canDestroy']);
    const isDeletableScheme = currentPercentageScheme.get('deletable');
    const isExistingScheme = percentageSchemeId !== 'newCustomScheme';

    return canDelete && isDeletableScheme && isExistingScheme;
  };

  render() {
    const { anchorTypeOptions, policies } = this.props;
    const { currentPercentageScheme, errors } = this.state;

    const currentPercentageSchemesMap = iMap({
      [String(currentPercentageScheme.get('id'))]: currentPercentageScheme,
    });
    const currentPercentageSchemeTitle = currentPercentageScheme.get('title');

    return (
      <Modal show className="saved-percentages-modal-component">
        <Modal.Header>
          <Modal.Title>{translate('percentageSettings.savedPercentages.modal.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SavedPercentagesTableComponent
            anchorTypeOptions={anchorTypeOptions}
            editMode
            onPercentageChange={this.handlePercentageSchemeChange}
            percentageSchemes={currentPercentageSchemesMap}
            policies={policies}
          />
          <div className="saved-percentages-modal-component__input">
            <InputComponent
              label={translate('percentageSettings.savedPercentages.modal.titleInput')}
              message={{ text: Object.values(errors), type: 'error' }}
              onInputChange={this.handleTitleChange}
              value={currentPercentageSchemeTitle}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="saved-percentages-modal-component__btn-group">
            <div className="saved-percentages-modal-component__btn">
              <ButtonComponent isWhite onClick={this.handleCancelClick}>
                {translate('percentageSettings.savedPercentages.buttons.cancel')}
              </ButtonComponent>
            </div>
            {this.deleteIconIsVisible() && (
              <div className="saved-percentages-modal-component__btn">
                <ButtonComponent isRed onClick={this.handleDeleteClick}>
                  <BinIcon />
                </ButtonComponent>
              </div>
            )}
            <div className="saved-percentages-modal-component__btn">
              <ButtonComponent isGreen onClick={this.handleSaveClick}>
                {translate('percentageSettings.savedPercentages.buttons.save')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

function select(state, ownProps) {
  const percentageSchemes = state.get('percentageSchemes');
  const policies = policiesSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);
  const anchorTypeOptions = options.get('anchorTypeOptions');
  // TODO: Move this to reselect
  let currentPercentageSchemesMap = percentageSchemes.filter((scheme) => {
    return scheme.get('id') === ownProps.percentageSchemeId;
  });

  if (ownProps.percentageSchemeId === 'newCustomScheme') {
    currentPercentageSchemesMap = newPercentageSchemesMap;
  }

  return { currentPercentageSchemesMap, policies, anchorTypeOptions };
}

const newPercentageSchemesMap = fromJS(
  keyBy(
    [
      {
        id: 'newCustomScheme',
        title: 'New',
        deletable: true,
        editable: true,
        percentageSchemeItems: {
          new_title_tag: {
            id: 'new_title_tag',
            anchorType: 'title_tag',
            idealPercentage: '0.0',
          },
          new_exact_keyword: {
            id: 'new_exact_keyword',
            anchorType: 'exact_keyword',
            idealPercentage: '0.0',
          },
          new_just_natural: {
            id: 'new_just_natural',
            anchorType: 'just_natural',
            idealPercentage: '0.0',
          },
          new_naked_uri: {
            id: 'new_naked_uri',
            anchorType: 'naked_uri',
            idealPercentage: '0.0',
          },
          new_keyword_plus_word: {
            id: 'new_keyword_plus_word',
            anchorType: 'keyword_plus_word',
            idealPercentage: '0.0',
          },
          new_only_part_of_keyword: {
            id: 'new_only_part_of_keyword',
            anchorType: 'only_part_of_keyword',
            idealPercentage: '0.0',
          },
          new_brand_and_keyword_together: {
            id: 'new_brand_and_keyword_together',
            anchorType: 'brand_and_keyword_together',
            idealPercentage: '0.0',
          },
          new_branded: {
            id: 'new_branded',
            anchorType: 'branded',
            idealPercentage: '0.0',
          },
          new_website_name_com: {
            id: 'new_website_name_com',
            anchorType: 'website_name_com',
            idealPercentage: '0.0',
          },
          new_no_text: {
            id: 'new_no_text',
            anchorType: 'no_text',
            idealPercentage: '0.0',
          },
          new_homepage_uri: {
            id: 'new_homepage_uri',
            anchorType: 'homepage_uri',
            idealPercentage: '0.0',
          },
          new_naked_uri_wo_http: {
            id: 'new_naked_uri_wo_http',
            anchorType: 'naked_uri_wo_http',
            idealPercentage: '0.0',
          },
          new_totally_random: {
            id: 'new_totally_random',
            anchorType: 'totally_random',
            idealPercentage: '0.0',
          },
        },
      },
    ],
    'id',
  ),
);

export default ConnectStoreHOC(connect(select)(SavedPercentagesModalComponent));
