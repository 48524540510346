import refreshPagesService from '../services/refreshPagesService';

import {
  closePagesExportToCsvPopup,
  openSubscriptionLimitReachedPopup,
} from 'actions/popup_actions';

import { trackHelpcrunchEvent } from 'common/utils';

const exportPagesToCsvServise = async (context, pages) => {
  const { dispatch, pageChildType, pageParentType } = context;

  const pageIds = pages
    .filter((page) => page.get('selected', false))
    .keySeq()
    .toArray();

  if (isGoingToOpenSubscriptionLimitReachedPopup(context)) {
    return void 0;
  }

  trackHelpcrunchEvent(`export.${pageChildType}.csv`);

  dispatch(closePagesExportToCsvPopup());
  clearPagesCheckboxes({ dispatch, pageParentType }, pages);

  window.location.href = Routes.export_csv_destination_links_records_path({
    brand_page_ids: pageIds,
    format: 'csv',
  });
};

function isGoingToOpenSubscriptionLimitReachedPopup(context) {
  const { dispatch, subscriptionPolicies } = context;

  const canExportCsvInSubscription = subscriptionPolicies.getIn(['exportCsv', 'canUse']);
  if (!canExportCsvInSubscription) {
    dispatch(openSubscriptionLimitReachedPopup({}));
    return;
  }
}

function clearPagesCheckboxes(context, pages) {
  const rows = pages
    .map((page) => page.set('selected', false))
    .valueSeq()
    .toJS();
  refreshPagesService(context, rows);
}

export default exportPagesToCsvServise;
