import React from 'react';

const isDevelopment = process.env.NODE_ENV === 'development';


const withErrorBoundary = (WrappedComponent) => {
  return class WithErrorBoundary extends React.PureComponent {
    static getDerivedStateFromError() {
      // error
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    constructor(props) {
      super(props);
      this.state = { hasError: false, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
      if (isDevelopment) {
        console.error(error, errorInfo);
      }
    }

    render() {
      const {
        hasError,
      } = this.state;

      if (hasError && isDevelopment) {
        return <div style={{ backgroundColor: 'red', color: 'white' }}>Something went wrong.</div>;
      }
      if (hasError && !isDevelopment) {
        return null;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withErrorBoundary;
