import React from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../../common/i18n';

import ButtonComponent from '../../../../components_linkio/button_component';
import LensIcon from '../../../../common/icons/lens';

import './crawlBrandBacklinksBtn.scss';


const CrawlPageDlsBtnComponent = ({
  canUseCrawlers,
  onClick,
}) => {
  const crawlButtonTooltip = canUseCrawlers ? explanationTooltip() : subscriptionLimitTooltip();

  return (
    <span className="crawl-brand-backlinks-btn">
      <ButtonComponent
        isDisabled={!canUseCrawlers}
        isInitial
        isWhite
        onClick={onClick}
        tooltip={crawlButtonTooltip}
      >
        <span className="crawl-brand-backlinks-btn__icon-wrapper">
          <LensIcon />
        </span>
        {translate('backlinksBulkActions.buttons.crawlBacklinks')}
      </ButtonComponent>
    </span>
  );
};

CrawlPageDlsBtnComponent.propTypes = {
  canUseCrawlers: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default CrawlPageDlsBtnComponent;


function explanationTooltip() {
  return {
    text: translate('explanationMessages.backlinksBulkActions.crawlBacklinksBtn'),
    placement: 'top',
    color: 'black',
  };
}

function subscriptionLimitTooltip() {
  const subscriptionLimitMessageParts =
    `${translate('subscriptionLimitRibbon.message')(
      translate('subscriptionLimitRibbon.limited.crawling')
    )} \
      ${translate('subscriptionLimitRibbon.upgradePopupLink.text')} \
      ${translate('subscriptionLimitRibbon.upgradePopupLink.link')}`;

  return {
    text: subscriptionLimitMessageParts,
    placement: 'top',
    color: 'red',
  };
}
