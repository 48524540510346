const payBtnComponent = (args) => {
  return {
    payBtnComponent: {
      withUpgradeText: 'Upgrade to Paid',
      updateTrial: 'Update Trial',
      myPlan: 'My Plan',
      choosePlan: 'Select This Plan',
      // product description limited with 29 characters in total
      productDescription: `${args.title}, ${args.price}/${shortenBillingInterval(args.billingInterval)}`,
      payBtnLabel: 'Continue',
    },
  };
};

const shortenBillingInterval = (billingInterval) => {
  return billingInterval === 'monthly' ? 'mo' : 'yr';
};

export default payBtnComponent;
