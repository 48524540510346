import React from 'react';

import crawlersStatusService from '../../services/crawlersStatusService';

import SpinnerComponent from 'components_linkio/Spinner';

const DlCrawlingInProgressIndicatorComponent = ({ dl }) => {
  const crawlersStatus = crawlersStatusService(dl);
  const needToShowSpinner = ['enqueued', 'processing'].includes(crawlersStatus.status);

  return (
    needToShowSpinner && (
      <div className="atp-table__spinner_wrapper">
        <SpinnerComponent isBlack />
      </div>
    )
  );
};

export default DlCrawlingInProgressIndicatorComponent;
