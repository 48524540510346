import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { breadcrumbsBrandsSelectToggle } from 'actions/ui_actions';

import SelectItemComponent from 'components/NewBreadcrumbs/SelectItem/SelectItemComponent';

const SelectItemContainer = ({ children, dispatch, menuIsOpen, select }) => {
  function handleToggleMenu() {
    dispatch(breadcrumbsBrandsSelectToggle());
  }

  return (
    <SelectItemComponent menuIsOpen={menuIsOpen} select={select} onToggleMenu={handleToggleMenu}>
      {children}
    </SelectItemComponent>
  );
};

SelectItemContainer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  select: PropTypes.node,
};

const selector = (state) => ({
  menuIsOpen: state.getIn(['ui', 'openClose', 'breadcrumbsBrandsSelect', 'isOpen']),
});

export default compose(ConnectStoreHOC, connect(selector))(SelectItemContainer);
