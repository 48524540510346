import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';
import { showSuccessMessage } from 'common/utils';

import { userShape } from 'common/prop_types_shapes';
import { fetchRailsContext } from 'api/rails_context';
import { updateEmailOrPassword } from 'api/user';

import EditEmailOrPasswordFormComponent from 'components/auth/edit_email_or_password_form_component';

import Breadcrumbs from 'components/NewBreadcrumbs';

class EditEmailOrPasswordPage extends React.PureComponent {
  static propTypes = {
    currentUser: userShape,
    dispatch: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const processedProps = this.processProps();

    this.state = {
      errors: {},
      processedProps,
    };
  }

  handleSubmitAction = async (email, currentPassword, password, passwordConfirmation) => {
    const { currentUser, dispatch } = this.props;

    const isEmailChanged = currentUser.get('email') !== email;
    const formData = new FormData();

    formData.append('user[email]', email);
    formData.append('user[current_password]', currentPassword);
    formData.append('user[password]', password);
    formData.append('user[password_confirmation]', passwordConfirmation);

    const result = await updateEmailOrPassword(dispatch, formData);
    const { type, errors, message } = result;

    if (type === 'error' || !isEmpty(errors)) {
      this.setState({ errors: message || errors });
    } else {
      this.setState({ errors: {} });
      await fetchRailsContext(dispatch);

      const msg = isEmailChanged ? 'updatedEmail' : 'updated';
      showSuccessMessage(dispatch, translate(`authForms.editEmailOrPassword.${msg}`));
    }
  };

  processProps = () => {
    const processedProps = cloneDeep(this.props);

    processedProps.handleSubmitClickAction = this.handleSubmitAction;
    processedProps.currentUser = this.props.currentUser;

    return processedProps;
  };

  render() {
    const { processedProps, errors } = this.state;

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.TextItem>{translate('authForms.breadcrumbsTitle')}</Breadcrumbs.TextItem>
        </Breadcrumbs>
        <EditEmailOrPasswordFormComponent {...processedProps} errors={errors} />
      </>
    );
  }
}

function select(state) {
  const currentUser = state.getIn(['railsContext', 'cuser']);

  return { currentUser };
}

export default ConnectStoreHOC(connect(select)(EditEmailOrPasswordPage));
