import React from 'react';
import PropTypes from 'prop-types';

import RoundSpinnerIcon from 'common/icons/RoundSpinnerIcon';

import './spinner.scss';

const SpinnerComponent = ({ isBlack, isWhite }) => {
  return (
    <div className="spinner-component">
      <RoundSpinnerIcon isBlack={isBlack} isWhite={isWhite} />
    </div>
  );
};

SpinnerComponent.propTypes = {
  isBlack: PropTypes.bool,
  isWhite: PropTypes.bool,
};

export default SpinnerComponent;
