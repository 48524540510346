export default {
  roboSubscriptionStatusComponent: {
    ctaLinkText: {
      upgradeSubscriptionPlan: 'Upgrade now',
    },
    subscription: 'Subscription:',
    subscriptionTitle: (subscriptionPlanTitle, isTrial, isFree) => {
      if (isTrial && isFree) {
        return 'Trial';
      }

      return subscriptionPlanTitle;
    },
    timeLeft: (time) => `${time} left`,
  },
};
