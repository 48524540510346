import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import RankTrackerTableComponent from './RankTrackerTableComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  keywordsWithChangesByUrlList,
  keywordsWithChangesList,
  rankTrackerFiltersMap,
} from 'common/prop_types_shapes';

import { currentIdSelector } from 'selectors';
import { fetchKeywordsWithChanges } from 'api/rankTracker';
import { resetRankTrackerFetchingStates } from 'actions/rankTrackerActions';

import {
  rankTrackerFiltersSelector,
  keywordsWithChangesSelector,
  keywordsWithChangesByUrlSelector,
} from 'selectors/rankTrackerSelector';

const RankTrackerTableContainer = ({
  brandId,
  changeQueryParams,
  dispatch,
  keywordsWithChanges,
  keywordsWithChangesByUrl,
  queryParams = {},
  rankTrackerFilters,
}) => {
  const { sorting = {}, filters = {} } = queryParams;

  const dateStart = filters.dateStart;
  const dateEnd = filters.dateEnd;

  React.useEffect(() => {
    dispatch(resetRankTrackerFetchingStates());
  }, [brandId, dateStart, dateEnd]);

  React.useEffect(() => {
    const fetchingParams = {
      groupByUrl: rankTrackerFilters.get('groupByUrl'),
      sorting,
      dateStart,
      dateEnd,
    };

    fetchKeywordsWithChanges(dispatch, brandId, fetchingParams);
  }, [brandId, sorting.sortBy, sorting.sortingOrder, dateStart, dateEnd]);

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    const isSearchVolumeRelated = property.includes('dfsSearchVolume');

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: isSearchVolumeRelated ? 'desc' : 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  return (
    <RankTrackerTableComponent
      dateStart={dateStart}
      dateEnd={dateEnd}
      keywordsWithChanges={keywordsWithChanges}
      keywordsWithChangesByUrl={keywordsWithChangesByUrl}
      onTableHeaderClick={handleTableHeaderClick}
      rankTrackerFilters={rankTrackerFilters}
      sorting={sorting}
    />
  );
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);

  const keywordsWithChanges = keywordsWithChangesSelector(state, ownProps);
  const keywordsWithChangesByUrl = keywordsWithChangesByUrlSelector(state, ownProps);
  const rankTrackerFilters = rankTrackerFiltersSelector(state, ownProps);

  return {
    brandId,
    keywordsWithChanges,
    keywordsWithChangesByUrl,
    rankTrackerFilters,
  };
}

RankTrackerTableContainer.propTypes = {
  brandId: PropTypes.string,
  dispatch: PropTypes.func,
  keywordsWithChanges: keywordsWithChangesList,
  keywordsWithChangesByUrl: keywordsWithChangesByUrlList,
  rankTrackerFilters: rankTrackerFiltersMap,
  ...withQueryParamsPropTypes,
};

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(RankTrackerTableContainer);
