import { combineReducers } from 'redux-immutable';

import checkAllProxiesInProgressReducer from './check_all_proxies_in_progress_reducer';
import loadingReducer from './loading_reducer';
import PopupsReducer from './popups_reducer';
import openCloseReducer from './open_close_reducer';
import selectedIdsReducer from './selectedIdsReducer';

export default combineReducers({
  checkAllProxiesInProgress: checkAllProxiesInProgressReducer,
  loading: loadingReducer,
  popups: PopupsReducer,
  openClose: openCloseReducer,
  selectedIds: selectedIdsReducer,
});
