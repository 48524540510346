import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './switch_component.scss';


class SwitchComponent extends Component {
  static propTypes = {
    className: PropTypes.string,
    initialValue: PropTypes.oneOf(['left', 'right']),
    isBoolean: PropTypes.bool,
    isDisabled: PropTypes.bool,
    left: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    right: PropTypes.string,
    value: PropTypes.string,
  }

  state = {
    active: this.props.initialValue || 'left',
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.value) {
      return { active: nextProps.value };
    }
    return null;
  }

  handleLeftClick = () => {
    this.props.onClick('left');
    this.setState({ active: 'left' });
  }

  handleRightClick = () => {
    this.props.onClick('right');
    this.setState({ active: 'right' });
  }
  handleSwitcherClick = () => {
    const newActive = this.state.active === 'left' ? 'right' : 'left';

    if (!this.props.isDisabled) {
      this.setState({ active: newActive });
      this.props.onClick(newActive);
    }
  }

  render() {
    const { className, left, right, isBoolean, isDisabled } = this.props;
    const { active } = this.state;

    const switchClasses = classnames({
      'switch-component': true,
      [`${className}`]: className,
    });

    const leftTextClasses = classnames({
      'switch-component__text': true,
      'switch-component__text_to-right': true,
      'switch-component__text_active': active === 'left',
    });
    const rightTextClasses = classnames({
      'switch-component__text': true,
      'switch-component__text_active': active === 'right',
    });
    const switcherClasses = classnames({
      'switch-component__switcher': true,
      'switch-component__switcher_left': active === 'left',
      'switch-component__switcher_right': active === 'right',
      'switch-component__switcher_off': isBoolean && active === 'left',
      'switch-component__switcher_disabled': isDisabled,
    });

    return (
      <div className={switchClasses}>
        {left &&
          <div className={leftTextClasses} onClick={this.handleLeftClick}>{left}</div>
        }
        <div className={switcherClasses} onClick={this.handleSwitcherClick} />
        {right &&
          <div className={rightTextClasses} onClick={this.handleRightClick}>{right}</div>
        }
      </div>
    );
  }
}

export default SwitchComponent;
