import React from 'react';
import moment from 'moment';

import { translate } from 'common/i18n';

import { stripeChargesArray } from 'common/prop_types_shapes';

import './stripeCharges.scss';

const StripeChargesComponent = ({ stripeCharges }) => {
  const stripeChargeItems = () => {
    return stripeCharges.map((stripeCharge) => {
      const { id, created, amountCents, currency } = stripeCharge;

      const createdDate = moment(created).format('LL');
      const amountDollars = amountCents / 100;
      const uppercasedCurrency = currency.toUpperCase();

      return (
        <div className="stripe-changes-component__row" key={id}>
          <span>{createdDate}</span>
          <span>{`${amountDollars} ${uppercasedCurrency}`}</span>
        </div>
      );
    });
  };

  return (
    <div className="stripe-changes-component">
      <h4 className="stripe-changes-component__title">
        {translate('billing.paymentsHistorySection.title')}
      </h4>
      {stripeCharges.length > 0 && (
        <div className="stripe-changes-component__table">
          {stripeChargeItems()}
        </div>
      )}
    </div>
  );
};

StripeChargesComponent.propTypes = {
  stripeCharges: stripeChargesArray,
};

export default StripeChargesComponent;
