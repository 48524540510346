import React, { Component } from 'react';
import ReactDOM from 'react-dom';

/**
 * Decorator
 * Add and call React component "handleFormSubmit" method on form submit event and
 * cancel default behavier
 * @param ComposedComponent
 * @returns {*}
 */
export default (ComposedComponent) => {
  return class CancelFormSubmitDecorator extends Component {
    componentDidMount() {
      const el = ReactDOM.findDOMNode(this);
      this.form = el.querySelector('form');
      this.form.addEventListener("submit", this.handleFormSubmit);
    }

    componentWillUnmount() {
      this.form.removeEventListener("submit", this.handleFormSubmit);
    }

    form;

    handleFormSubmit = (event) => event.preventDefault(); // disable page reload

    render() {
      return <ComposedComponent {...this.props} />;
    }
  };
};
