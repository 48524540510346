import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentMarketerSelector, policiesSelector } from 'selectors/railsContextSelectors';

import { marketerShape, policiesShape } from 'common/prop_types_shapes';

import { translate } from 'common/i18n';

import TabsComponent, { TabItemComponent } from 'components_linkio/tabs_component';

const accountDetailsPath = Routes.users_edit_profile_path({ format: null });
const billingPath = Routes.billing_marketers_path({ format: null });
const emailPasswordPath = Routes.edit_user_registration_path({ format: null });
const newsletterSettingsPath = Routes.newsletter_settings_path({ format: null });
const subscriptionPlansPath = Routes.subscription_plans_path({ format: null });

const TabsNavigationComponent = ({ cmarketer, policies, history, match }) => {
  const goTo = (path) => () => history.push(path);

  const canManageSubscriptions = policies.getIn(['subscription', 'canManage'], false);
  const hasCard = cmarketer.get('has_card', false);

  const subscriptionUsageTabLabel = translate('authForms.authTabs.subscriptionPlans');

  return (
    <TabsComponent>
      <TabItemComponent
        index={0}
        label={translate('authForms.authTabs.accountDetails')}
        onClick={goTo(accountDetailsPath)}
        options={{ active: match.path === accountDetailsPath }}
      />
      <TabItemComponent
        index={1}
        label={translate('authForms.authTabs.emailPassword')}
        onClick={goTo(emailPasswordPath)}
        options={{ active: match.path === emailPasswordPath }}
      />
      <TabItemComponent
        index={2}
        label={subscriptionUsageTabLabel}
        onClick={goTo(subscriptionPlansPath)}
        options={{ active: match.path === subscriptionPlansPath }}
      />
      <TabItemComponent
        index={3}
        label={translate('authForms.authTabs.newsletterSettings')}
        onClick={goTo(newsletterSettingsPath)}
        options={{ active: match.path === newsletterSettingsPath }}
      />
      {canManageSubscriptions && hasCard && (
        <TabItemComponent
          index={5}
          label={translate('authForms.authTabs.billing')}
          onClick={goTo(billingPath)}
          options={{ active: match.path === billingPath }}
        />
      )}
    </TabsComponent>
  );
};

TabsNavigationComponent.propTypes = {
  cmarketer: marketerShape,
  policies: policiesShape,
};

function select(state, ownProps) {
  const cmarketer = currentMarketerSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);

  return {
    cmarketer,
    policies,
  };
}

export default compose(ConnectStoreHOC, connect(select), withRouter)(TabsNavigationComponent);
