import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Async from 'react-select/async';
import Creatable from 'react-select/creatable';
import classnames from 'classnames';

import LabelComponent from '../label_component';
import MessageComponent from '../message_component';
import OverlayWithTooltipComponent from '../../components/overlay_with_tooltip_component';
import { hasErrorMessage, hasSuccessMessage, messageIsEmpty } from '../message_utils';

import { tooltipShape } from '../../common/prop_types_shapes';
import customStyles from './customStyles';

import './select.scss';

class SelectComponent extends Component {
  static propTypes = {
    components: PropTypes.objectOf(PropTypes.func),
    explanationMessage: PropTypes.string,
    forwardedRef: PropTypes.shape({ current: PropTypes.instanceOf(Select) }),
    isAsync: PropTypes.bool,
    isCreatable: PropTypes.bool,
    isMandatory: PropTypes.bool,
    label: PropTypes.string,
    message: PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      type: PropTypes.oneOf(['success', 'error', 'hint', 'infoBlock']),
    }),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    skinOptions: PropTypes.shape({
      asDropdown: PropTypes.bool,
      inTable: PropTypes.bool,
    }),
    tooltip: tooltipShape,
    triggerChangeOnBlur: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })),
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ]),
    wrapperClassName: PropTypes.string,
  };

  static defaultProps = {
    components: {},
    message: {},
    skinOptions: {
      asDropdown: false,
      inTable: false,
    },
    triggerChangeOnBlur: true,
  }

  componentDidMount() {
    this.checkOffscreen();
  }

  value = this.props.value;

  checkOffscreen = () => {
    const { skinOptions: { inTable } } = this.props;
    const node = ReactDOM.findDOMNode(this);

    if (!node) {
      return;
    }

    const rect = node.getBoundingClientRect();
    if (rect.right > window.innerWidth) {
      inTable
        ? node.style.right = '-20%'
        : node.style.right = '0.5rem';
    }
  }

  // we need this to be able to close select dropdown on clickout automatically
  handleBlur = () => {
    const {
      onBlur,
      onChange,
      triggerChangeOnBlur,
      value,
    } = this.props;

    onBlur && onBlur();
    triggerChangeOnBlur && onChange(value);
  };

  customCompareOption(inputValue, option) {
    const candidate = String(inputValue).toLowerCase();
    return (
      String(option.value).toLowerCase() === candidate ||
      String(option.label).toLowerCase() === candidate
    );
  }

  // This allows to use numeric values by overriding default behaviour
  // FIXME: Make sure to remove this once https://github.com/JedWatson/react-select/pull/2956 merged
  customIsValidNewOption = (inputValue, selectValue, selectOptions) => {
    return !(
      !inputValue ||
      selectValue.some((option) => this.customCompareOption(inputValue, option)) ||
      selectOptions.some((option) => this.customCompareOption(inputValue, option))
    );
  }

  render() {
    const {
      components,
      componentStyles,
      explanationMessage,
      forwardedRef,
      isAsync,
      isCreatable,
      isMandatory,
      label,
      message,
      skinOptions,
      tooltip,
      wrapperClassName,
    } = this.props;

    const { inTable } = skinOptions;
    const isError = hasErrorMessage(message);
    const isSuccess = hasSuccessMessage(message);

    const selectorOptions = {
      classNamePrefix: "react-select",
      components,
      onBlur: this.handleBlur,
      ref: forwardedRef,
      styles: customStyles(isError, isSuccess, skinOptions, componentStyles),
    };

    const selectWrapperClasses = classnames({
      'select': true,
      'select__in-table': inTable,
      [wrapperClassName]: true,
    });

    return (
      <OverlayWithTooltipComponent tooltip={tooltip}>
        <div className={selectWrapperClasses}>
          <LabelComponent
            text={label}
            isMandatory={isMandatory}
            explanationMessage={explanationMessage}
          >
            {isCreatable &&
              <Creatable
                {...this.props}
                {...selectorOptions}
                isValidNewOption={this.customIsValidNewOption}
              />
            }
            {!isCreatable && !isAsync &&
              <Select
                {...this.props}
                {...selectorOptions}
              />
            }
            {!isCreatable && isAsync &&
              <Async
                {...this.props}
                {...selectorOptions}
              />
            }
          </LabelComponent>
          {!messageIsEmpty(message) && <MessageComponent message={message} />}
        </div>
      </OverlayWithTooltipComponent>
    );
  }
}

export default SelectComponent;
