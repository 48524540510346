import React from 'react';
import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';

import EoProgressionsPageComponent from './EoProgressionsPageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { pagyShape } from 'common/prop_types_shapes';

import { eoProgressionsPagySelector } from 'selectors/eoProgressionsSelector';
import { currentIdSelector } from 'selectors';

const EoProgressionsPageContainer = ({ changeQueryParams, eoProgressionsPagy, queryParams }) => {
  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <EoProgressionsPageComponent
        onItemsSelectChange={handleItemsSelectChange}
        onPagesPagerClick={handlePagesPagerClick}
        eoProgressionsPagy={eoProgressionsPagy}
      />
    </>
  );
};

EoProgressionsPageContainer.propTypes = {
  eoProgressionsPagy: pagyShape,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoProgressionsPagy = eoProgressionsPagySelector(state, eoCampaignId);

  return {
    eoProgressionsPagy,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoProgressionsPageContainer);
