import { translate } from '../common/i18n';

import {
  addCompetitorsManuallyStart,
  addCompetitorsManuallySuccess,
  addCompetitorsManuallyFailure,
  addCompetitorsToAtpSuccess,
  addCompetitorsToAtpFailure,
  autodiscoverCompetitorsByKeywordFailure,
  autodiscoverCompetitorsByKeywordStart,
  fetchBulkCompetitorPagesStart,
  fetchBulkCompetitorPagesSuccess,
  fetchBulkCompetitorPagesFailure,
  updateDraftCompetitorPageSuccess,
  updateDraftCompetitorPageFailure,
} from '../actions/bulkCompetitorsSetupActions';

import { showSuccessMessage, showErrorMessage } from '../common/utils';

import {
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCaseDeep,
} from './utils';

export async function addCompetitorsToAtp(dispatch, brandPageId, pageIdsArray) {
  try {
    const resp = await patchToAPI(
      Routes.add_to_atp_bulk_competitor_pages_setup_path({ brand_page_id: brandPageId }),
      { bulk_competitor_pages_setup: { competitor_page_ids: pageIdsArray } },
    );

    if (resp.success) {
      dispatch(addCompetitorsToAtpSuccess(resp.draft_competitor_pages, brandPageId));
    } else {
      dispatch(addCompetitorsToAtpFailure(resp.draft_competitor_pages, brandPageId));
    }
    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      addCompetitorsToAtpFailure,
      translate('errors.cantAddCompetitorsToAtp'),
    );
  }
}

export async function autodiscoverCompetitorsByKeyword(dispatch, brandPageId, keywordId) {
  dispatch(autodiscoverCompetitorsByKeywordStart(brandPageId));

  try {
    const resp = await postToAPI(
      Routes.autodiscover_via_dataforseo_bulk_competitor_pages_setup_path({
        brand_page_id: brandPageId,
      }),
      { bulk_competitor_pages_setup: { autodiscover_by_keyword_id: keywordId } },
    );

    if (!resp.success) {
      showErrorMessage(dispatch, translate('errors.cantGetResultFromDataforseo'), true);
    }
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      autodiscoverCompetitorsByKeywordFailure,
      translate('errors.cantAutodiscoverCompetitorsByKeyword'),
    );
  }
}

export async function addCompetitorsManually(dispatch, brandPageId, competitorUrisArray) {
  dispatch(addCompetitorsManuallyStart());

  try {
    const resp = await postToAPI(
      Routes.add_manually_bulk_competitor_pages_setup_path({ brand_page_id: brandPageId }),
      { competitor_page_uris: competitorUrisArray },
    );

    const {
      draft_competitor_pages,
      dupe_or_invalid_entries_count,
      uniq_and_valid_entries_count,
    } = resp;

    dispatch(addCompetitorsManuallySuccess(draft_competitor_pages, brandPageId));
    showSuccessMessage(
      dispatch,
      translate('bulkCompetitorsSetup.messages.addCompetitorsSuccess')(
        uniq_and_valid_entries_count,
        dupe_or_invalid_entries_count,
      ),
    );
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      addCompetitorsManuallyFailure,
      translate('errors.cantAddCompetitorsManually'),
    );
  }
}

export async function fetchBulkCompetitorPages(dispatch, brandPageId, searchTerm) {
  dispatch(fetchBulkCompetitorPagesStart());

  try {
    const resp = await getFromAPI(
      Routes.bulk_competitor_pages_setup_path({ brand_page_id: brandPageId }),
    );

    let draftCompetitorPages = resp.draft_competitor_pages;

    if (searchTerm) {
      draftCompetitorPages = draftCompetitorPages.filter((page) =>
        page.link.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    dispatch(fetchBulkCompetitorPagesSuccess(draftCompetitorPages, brandPageId));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchBulkCompetitorPagesFailure,
      translate('errors.cantFetchBulkCompetitorPages'),
    );
  }
}

export async function updateDraftCompetitorPage(dispatch, page) {
  try {
    const resp = await patchToAPI(
      Routes.update_competitor_page_bulk_competitor_pages_setup_path({
        competitor_page_id: page.id,
      }),
      {
        draft_competitor_page: processObjToSnakeCaseDeep(page),
      },
    );

    dispatch(updateDraftCompetitorPageSuccess(resp));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      updateDraftCompetitorPageFailure,
      translate('errors.cantUpdateDraftCompetitorPage'),
    );
  }
}
