import React from 'react';
import { connect } from 'react-redux';

import GroupDlsButtonsComponent from './GroupDlsButtonsComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

const GroupDlsButtonsContainer = ({ queryParams, changeQueryParams }) => {
  const handleChangeDlsGroupMode = (newDlsGroupMode) => () => {
    const newQueryParams = {
      ...queryParams,
      dlsGroupMode: newDlsGroupMode,
      pageNumber: 1,
    };
    changeQueryParams(newQueryParams);
  };

  const dlsGroupMode = queryParams.dlsGroupMode;

  return (
    <GroupDlsButtonsComponent
      dlsGroupMode={dlsGroupMode}
      onChangeDlsGroupMode={handleChangeDlsGroupMode}
    />
  );
};

GroupDlsButtonsContainer.propTypes = {
  ...withQueryParamsPropTypes,
};

export default withQueryParams(ConnectStoreHOC(connect()(GroupDlsButtonsContainer)));
