import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoProgressionActionsSelectComponent from './EoProgressionActionsSelectComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { cancelEoProgressions, resumeEoProgressions } from 'api/eoProgression';
import { currentIdSelector, uiSelector } from 'selectors';
import { selectedIdsList } from 'common/prop_types_shapes';

const EoProgressionActionsSelectContainer = ({ dispatch, eoCampaignId, selectedIds }) => {
  function handleSelectAction(action) {
    switch (action) {
      case 'cancelEoProgressions':
        cancelEoProgressions(dispatch, eoCampaignId, selectedIds.toJS());
        break;
      case 'resumeEoProgressions':
        resumeEoProgressions(dispatch, eoCampaignId, selectedIds.toJS());
        break;
    }
  }

  const isDisabled = selectedIds.size === 0;

  return (
    <EoProgressionActionsSelectComponent isDisabled={isDisabled} onSelect={handleSelectAction} />
  );
};

EoProgressionActionsSelectContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  selectedIds: selectedIdsList,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    eoCampaignId,
    selectedIds,
  };
}

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(EoProgressionActionsSelectContainer);
