import React from 'react';
import PropTypes from 'prop-types';

import { optionsArray } from 'common/prop_types_shapes';

import Select from 'components_linkio/Select';
import Breadcrumbs from 'components/NewBreadcrumbs';

const BreadcrumbsBrandsSelectComponent = ({
  brandWebsite,
  brandsOptions,
  onCloseMenu,
  onSelectChange,
}) => {
  function breadcrumbsSelect() {
    return (
      <div className="breadcrumbs-menu">
        <Select
          autoFocus
          className="breadcrumbs-menu__selector"
          onBlur={onCloseMenu}
          onChange={onSelectChange}
          openMenuOnFocus
          options={brandsOptions}
          skinOptions={{ asDropdown: true, inTable: false }}
        />
      </div>
    );
  }

  return (
    <Breadcrumbs.SelectItem select={breadcrumbsSelect()}>{brandWebsite}</Breadcrumbs.SelectItem>
  );
};

BreadcrumbsBrandsSelectComponent.propTypes = {
  brandWebsite: PropTypes.string,
  brandsOptions: optionsArray,
  onCloseMenu: PropTypes.func,
  onSelectChange: PropTypes.func,
};

export default BreadcrumbsBrandsSelectComponent;
