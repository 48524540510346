import {
  showErrorMessage,
  showSuccessMessage,
} from '../../common/utils';


const handleArbitraryMessage = (dispatch, data) => {
  const {
    arbitraryMessageStatus,
    message,
  } = data;

  switch (arbitraryMessageStatus) {
    case 'success':
      showSuccessMessage(dispatch, message);

      break;
    case 'failure':
      showErrorMessage(dispatch, message);

      break;
  }
};


export default handleArbitraryMessage;
