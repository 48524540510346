export default {
  eoInboxForm: {
    breadcrumbsTitle: {
      addInbox: 'Add Inbox',
      editInbox: 'Edit Inbox',
    },
    buttons: {
      cancel: 'Cancel',
      checkReplies: {
        label: 'Check Replies now',
        tooltip: 'Check all the replies for this inbox',
      },
      delete: 'Delete',
      verify: {
        label: 'Test Deliverability',
        tooltip: 'Save inbox to test deliverability',
      },
      reAuth: 'Re-Authorize Google email account',
      submit: (isExistingInbox) => (isExistingInbox ? 'Save' : 'Add Inbox'),
    },
    inputs: {
      email: {
        label: 'Email Address',
      },
      fromName: {
        label: 'From Name',
      },
      nickname: {
        label: 'Nickname',
        hint: 'Use your first name or its short form in follow-ups to sound more casual.',
      },
      replyTo: {
        label: 'Set a different reply-to email',
        hint: 'Make sure we monitor that email inbox too, otherwise we won’t track replies to it.',
      },
      sendingDelay: {
        label: 'Sending Delay',
        hint:
          "Minimum Time delay between sending emails (in sec) - no value or 0 \
            means that it’s using the default time: 30 seconds",
        placeholder: 'Using default time',
      },
      sendingDelayVariation: {
        label: 'Sending Delay Variation',
        hint:
          "Adding time variation, will add random number of seconds \
            on top of minimum time delay",
        placeholder: 'No delay variation',
      },
      saveReplies: {
        label: 'Import campaign replies into the Reply tab',
        hint:
          "If you're forwarding emails between 2 paired inboxes, you can disable this to \
            avoid duplicates",
      },
      inboxSignature: {
        label: 'Inbox Signature',
        hint: 'Add this to your emails using the {{inbox.inboxSignature}} custom field.',
        placeholder: 'Write your email signature here',
      },
      host: {
        label: 'Host',
        placeholder: {
          send: 'smtp.example.com',
          receive: 'imap.example.com',
        },
      },
      port: {
        label: 'Port',
        placeholder: {
          send: '587',
          receive: '993',
        },
      },
      username: {
        label: 'Username',
        placeholder: {
          send: 'you@example.com',
          receive: 'you@example.com',
        },
      },
      password: {
        label: 'Password',
        placeholder: {
          send: 'your password',
          receive: 'your password',
        },
      },
    },
    sending: {
      title: 'Outgoing (SMTP)',
    },
    receiving: {
      title: 'Incoming (IMAP)',
    },
    title: (isExistingInbox) => (isExistingInbox ? 'Edit Inbox' : 'Add Inbox'),
  },
};
