import React, { Component } from 'react';
import { isEmpty, cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { forgotPassword } from 'api/user';
import { clearErrors, validateEmail } from 'common/utils';

import ForgotPasswordFormComponent from 'components/auth/forgot_password_form_component';

class ForgotPasswordPage extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
      dispatch: PropTypes.func,
    }),
  };
  constructor(props) {
    super(props);

    const processedProps = this.processProps();

    this.state = {
      error: null,
      errors: {},
      processedProps,
      showMessage: false,
    };
  }

  handleSendResetPwdInstructionsAction = async (email) => {
    this.setState(clearErrors(this.state));
    const errors = validateEmail({ email });
    const { dispatch } = this.props;

    if (!isEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    const formData = new FormData();

    formData.append('user[email]', email);

    const res = await forgotPassword(dispatch, formData);

    if (res.type && res.type === 'error') {
      this.setState(res.message);
    } else {
      return (
        this.props.history.push(Routes.users_passwords_instructions_sent_path({ format: null }))
      );
    }
  }

  processProps = () => {
    const processedProps = cloneDeep(this.props);

    processedProps.handleSendResetPwdInstructionsClick = this.handleSendResetPwdInstructionsAction;

    return processedProps;
  }

  render() {
    const { processedProps, errors, error, showMessage } = this.state;

    return (
      <ForgotPasswordFormComponent
        {...processedProps}
        errors={errors}
        error={error}
        showMessage={showMessage}
      />
    );
  }
}

export default withRouter(ConnectStoreHOC(connect()(ForgotPasswordPage)));
