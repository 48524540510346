export default {
  subscriptionPlanUsageComponent: {
    backlinksChecked: 'Backlinks checked',
    backlinksImportedFromMoz: 'Backlinks imported from Moz',
    brandsWebsites: 'Brands/Websites',
    checkedKeywords: 'Requests to rankings API',
    emailsValidationCredits: 'Emails validation credits',
    eoInboxes: 'Inboxes',
    subscriptionDate: 'Subscription date - ',
    title: 'My Subscription Plan and Usage',
    trackedKeywords: 'Keywords tracked',
    usage: 'Usage:',
  },
};
