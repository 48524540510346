import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import EoEmailTemplatesListComponent from './EoEmailTemplatesListComponent';
import {
  eoEmailTemplatesList,
  eoEmailTemplateFoldersList,
} from 'common/propTypesShapes/eoEmailTemplates';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';
import ConnectStoreHOC from 'startup/connect_store_hoc';
import { eoEmailTemplateFoldersSelector } from 'selectors/eoEmailTemplateFoldersSelector';
import { eoEmailTemplatesSelector } from 'selectors/eoEmailTemplatesSelector';
import { fetchEoEmailTemplateFolders } from 'api/eoEmailTemplateFolder';

const EoEmailTemplatesListContainer = ({
  currentEmailTemplateFolderId,
  dispatch,
  eoEmailTemplates,
  immutableEoEmailTemplateFolders,
  queryParams,
  setCurrentEmailTemplateFolderId,
}) => {
  const [fetchingState, setFetchingState] = useState('');

  React.useEffect(() => {
    const fetchFolders = async () => {
      await fetchEoEmailTemplateFolders(dispatch, {});
    };

    fetchFolders();
    setFetchingState('finished');
  }, [dispatch, eoEmailTemplates]);

  const handleSelectDirectory = (id) => () => setCurrentEmailTemplateFolderId(id);

  return (
    fetchingState === 'finished' && (
      <EoEmailTemplatesListComponent
        currentEmailTemplateFolderId={currentEmailTemplateFolderId}
        handleSelectDirectory={handleSelectDirectory}
        immutableEoEmailTemplateFolders={immutableEoEmailTemplateFolders}
        queryParams={queryParams}
      />
    )
  );
};

EoEmailTemplatesListContainer.propTypes = {
  currentEmailTemplateFolderId: PropTypes.number,
  dispatch: PropTypes.func,
  eoEmailTemplates: eoEmailTemplatesList,
  immutableEoEmailTemplateFolders: eoEmailTemplateFoldersList,
  setCurrentEmailTemplateFolderId: PropTypes.func,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const immutableEoEmailTemplateFolders = eoEmailTemplateFoldersSelector(state);
  const eoEmailTemplates = eoEmailTemplatesSelector(state);

  return {
    eoEmailTemplates,
    immutableEoEmailTemplateFolders,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoEmailTemplatesListContainer);
