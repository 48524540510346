import { fromJS } from 'immutable';

import {
  FETCH_ATP_PAGY__SUCCESS,
  FETCH_BACKLINKS_PAGY__SUCCESS,
  FETCH_BULK_PAGES_SETUP_PAGY__SUCCESS,
  FETCH_IMPERSONATES_PAGY__SUCCESS,
  FETCH_NOTIFICATIONS_PAGY__SUCCESS,
  FETCH_CHANGES_HISTORY_PAGY__SUCCESS,
  FETCH_PAGES_PAGY__SUCCESS,
  FETCH_PROSPECTS_PAGY__SUCCESS,
  FETCH_EO_BLACKLISTED_DOMAINS_PAGY__SUCCESS,
  FETCH_EO_DOMAINS_PAGY__SUCCESS,
  FETCH_EO_CAMPAIGNS_PAGY__SUCCESS,
  FETCH_EO_PROGRESSIONS_PAGY__SUCCESS,
  FETCH_EO_DRIP_LISTS_PAGY__SUCCESS,
  FETCH_EO_SCHEDULERS_PAGY__SUCCESS,
  FETCH_EO_MESSAGES_PAGY__SUCCESS,
  FETCH_EO_CUSTOM_FIELDS_PAGY__SUCCESS,
  FETCH_EO_ACTIVE_HOURS_PAGY__SUCCESS,
  FETCH_EO_EMAIL_TEMPLATES_PAGY__SUCCESS,
  FETCH_EO_TO_DOS_PAGY__SUCCESS,
} from 'actions/pagy_actions';

const initialState = fromJS({
  eoToDosPagy: {},
  eoActiveHoursPagy: {},
  eoEmailTemplatesPagy: {},
  eoCustomFieldsPagy: {},
  eoMessagesPagy: {},
  eoSchedulersPagy: {},
  eoDripListsPagy: {},
  eoDomainsPagy: {},
  eoBlacklistedDomainsPagy: {},
  eoProgressionsPagy: {},
  eoCampaignsPagy: {},
  atpPagy: {},
  backlinksPagy: {},
  bulkPagesSetupPagy: {},
  notificationsPagy: {},
  changesHistoryPagy: {},
  pagesPagy: {},
  impersonatesPagy: {},
  prospectsPagy: {},
});

export default function pagyReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_TO_DOS_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('eoToDosPagy', fromJS(pagy));

      break;
    }

    case FETCH_EO_ACTIVE_HOURS_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('eoActiveHoursPagy', fromJS(pagy));

      break;
    }

    case FETCH_EO_EMAIL_TEMPLATES_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('eoEmailTemplatesPagy', fromJS(pagy));

      break;
    }

    case FETCH_EO_CUSTOM_FIELDS_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('eoCustomFieldsPagy', fromJS(pagy));

      break;
    }

    case FETCH_EO_MESSAGES_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('eoMessagesPagy', fromJS(pagy));

      break;
    }

    case FETCH_EO_SCHEDULERS_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('eoSchedulersPagy', fromJS(pagy));

      break;
    }

    case FETCH_EO_DRIP_LISTS_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('eoDripListsPagy', fromJS(pagy));

      break;
    }

    case FETCH_EO_DOMAINS_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('eoDomainsPagy', fromJS(pagy));

      break;
    }

    case FETCH_EO_BLACKLISTED_DOMAINS_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('eoBlacklistedDomainsPagy', fromJS(pagy));

      break;
    }

    case FETCH_EO_PROGRESSIONS_PAGY__SUCCESS: {
      const { pagy } = action;

      state = state.set('eoProgressionsPagy', fromJS(pagy));

      break;
    }

    case FETCH_EO_CAMPAIGNS_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('eoCampaignsPagy', fromJS(pagy));

      break;
    }

    case FETCH_ATP_PAGY__SUCCESS: {
      const { brandPageId, pagy } = action;
      state = state.set('atpPagy', fromJS({ [brandPageId]: pagy }));

      break;
    }
    case FETCH_BACKLINKS_PAGY__SUCCESS: {
      const { brandId, pagy } = action;
      state = state.set('backlinksPagy', fromJS({ [brandId]: pagy }));

      break;
    }
    case FETCH_BULK_PAGES_SETUP_PAGY__SUCCESS: {
      const { brandId, pagy } = action;
      state = state.set('bulkPagesSetupPagy', fromJS({ [brandId]: pagy }));

      break;
    }
    case FETCH_IMPERSONATES_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('impersonatesPagy', fromJS({ pagy }));

      break;
    }
    case FETCH_NOTIFICATIONS_PAGY__SUCCESS: {
      const { pagy } = action;
      state = state.set('notificationsPagy', fromJS(pagy));

      break;
    }
    case FETCH_CHANGES_HISTORY_PAGY__SUCCESS: {
      const { brandId, pagy } = action;
      state = state.set('changesHistoryPagy', fromJS({ [brandId]: pagy }));

      break;
    }
    case FETCH_PAGES_PAGY__SUCCESS: {
      const { brandId, pagy } = action;
      state = state.set('pagesPagy', fromJS({ [brandId]: pagy }));

      break;
    }
    case FETCH_PROSPECTS_PAGY__SUCCESS: {
      state = state.set('prospectsPagy', fromJS(action.pagy));

      break;
    }
  }

  return state;
}
