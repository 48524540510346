import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { cloneDeep, each, upperCase, isEqual } from 'lodash';
import * as Table from 'reactabular-table';
import * as selectabular from 'selectabular';
import * as edit from 'react-edit';
import { fromJS } from 'immutable';

import DlCrawlingInProgressIndicator from './DlCrawlingInProgressIndicator';

import inCellEditHandlerService from './services/inCellEditHandlerService';
import valueFormattersService from './services/valueFormattersService';
import { processValue } from './services/utils';

import { anchorTypeSelectProps, sourceSelectProps, statusSelectProps } from './utils';

import {
  addCSSClassToCellInTable,
  generateDestinationLinkStatusForAtp,
  highlightTableRow,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
} from 'common/tables/utils';

import correctAtpWrapperBottomSpaceIfNeeded from 'utils/correctAtpWrapperBottomSpaceIfNeeded';
import { translate } from 'common/i18n';

import columnsGenerator, {
  columnSelect,
  columnTextArea,
  columnDate,
} from 'common/tables/columns_generator';

import { checkboxColumn, reactSelectColumn, textColumn } from 'common/tables/columns';

import {
  destinationLinksArray,
  destinationLinksMap,
  optionsMap,
  policiesShape,
} from 'common/prop_types_shapes';

import BadgeComponent from 'components_linkio/badge_component';
import Checkbox from 'components_linkio/Checkbox';
import EditIcon from 'common/icons/edit';
import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';
import HeaderFieldWithSorting from 'common/tables/sharedComponents/HeaderFieldWithSorting';
import Link from 'components_linkio/link';

import './dlsTable.scss';

export default class DlsTableComponent extends React.PureComponent {
  static propTypes = {
    anchorTypeOptions: optionsMap.isRequired,
    canUseCrawlers: PropTypes.bool.isRequired,
    destinationLinks: destinationLinksArray.isRequired,
    dlSourcesOptions: optionsMap.isRequired,
    onCreateDl: PropTypes.func.isRequired,
    onDlDetailsClick: PropTypes.func.isRequired,
    onShowErrorMessage: PropTypes.func.isRequired,
    onTableHeaderClick: PropTypes.func.isRequired,
    onUpdateDl: PropTypes.func.isRequired,
    onUpdateRows: PropTypes.func.isRequired,
    options: optionsMap,
    policies: policiesShape.isRequired,
    selectedRows: destinationLinksMap.isRequired,
    statusOptions: optionsMap.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      props,
      columns: this.generateColumns(props),
      formColumns: this.generateFormColumns(props),
      formRow: this.formRow(),
      generateColumns: this.generateColumns,
      generateFormColumns: this.generateFormColumns,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const needToUpdState = !isEqual(nextProps, prevState.props);

    if (needToUpdState) {
      return {
        columns: prevState.generateColumns(nextProps),
        formColumns: prevState.generateFormColumns(nextProps),
        props: nextProps,
      };
    }

    return null;
  }

  componentDidMount() {
    this.setTablesRef();
  }

  componentDidUpdate() {
    correctAtpWrapperBottomSpaceIfNeeded({ tableType: 'competitors' });

    if (!this.tableFormRef) {
      const tables = ReactDOM.findDOMNode(this).getElementsByTagName('table');
      this.tableFormRef = tables[1];
    }
  }

  tableRef = {};
  tableFormRef = {};

  setTablesRef = () => {
    const tables = ReactDOM.findDOMNode(this).getElementsByTagName('table');
    this.tableRef = tables[0];
    this.tableFormRef = tables[1];
  };

  placeholders = {
    anchorText: translate('destinationLink.anchorText.placeholder'),
    anchorType: translate('destinationLink.anchorType.placeholder'),
    errors: {},
    failed: false,
    id: 'anything',
    publishedDate: translate('destinationLink.publishedDate.placeholder'),
    publishedLink: translate('destinationLink.publishedLink.placeholder'),
    source: translate('destinationLink.source.placeholder'),
    status: translate('destinationLink.status.placeholder'),
    targetWebsite: translate('destinationLink.targetWebsite.placeholder'),
  };

  valueFormatters = valueFormattersService(this.props.options, this.placeholders);

  editable = (props) => inCellEditHandlerService(props, this.tableRef);

  // We need to keep 'creatable' in this component since it uses 'setState()' method.
  // Mutating state from external file for the 'edit` leads to rare bugs.
  creatable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex }) => {
      const index = 0;
      const { formColumns, formRow } = this.state;

      const property = formColumns[columnIndex].property;
      const clonedFormRow = cloneDeep(formRow);

      clonedFormRow[index].editing = columnIndex;
      if (clonedFormRow[index][property] === this.placeholders[property]) {
        clonedFormRow[index][property] = '';
      }

      addCSSClassToCellInTable(this.tableFormRef, index, columnIndex, TABLE_CELL_ACTIVE_CLASS);

      this.setState({ formRow: clonedFormRow });
    },

    onValue: async ({ value, rowData, property }) => {
      const index = 0;
      const { formRow } = this.state;
      const { onCreateDl } = this.props;

      const clonedFormRow = cloneDeep(formRow);
      const clonedRowData = cloneDeep(rowData);

      const newValue = processValue(value);

      if (newValue === '') {
        clonedFormRow[index] = {
          ...clonedFormRow[index],
          [property]: this.placeholders[property],
        };

        Reflect.deleteProperty(clonedFormRow[index], 'editing');

        this.setState({ formRow: clonedFormRow });
      }

      const linkData = { ...clonedRowData, [property]: newValue };

      // we should not send placeholders values to the server
      each(this.placeholders, (newValue, key) => {
        if (linkData[key] === newValue) {
          linkData[key] = '';
        }
      });

      await onCreateDl(linkData, property, newValue);

      clonedFormRow[index] = { ...clonedFormRow[index], ...this.placeholders };
      Reflect.deleteProperty(clonedFormRow[index], 'editing');

      highlightTableRow(this.tableFormRef, index);
      highlightTableRow(this.tableRef, -1);

      removeCSSClassFromTableCells(this.tableRef, TABLE_CELL_ACTIVE_CLASS);
      removeCSSClassFromTableCells(this.tableFormRef, TABLE_CELL_ACTIVE_CLASS);

      this.setState({ formRow: clonedFormRow });
    },
  });

  generateColumns = (props) =>
    this.baseGenerateColumns(this.editable(props), props, { сheckbox: true });

  generateFormColumns = (props) => {
    const canUpdateAtp = props.policies.getIn(['atp', 'canUpdate']);

    if (!canUpdateAtp) {
      return [];
    }

    return this.baseGenerateColumns(this.creatable, props, { form: true });
  };

  baseGenerateColumns = (withAbility, props, options = {}) => {
    const { anchorTypeOptions, dlSourcesOptions, pageParentType, statusOptions } = props;

    const { сheckbox, form } = options;
    const cellFormattersCheckbox = [];
    const isBrandPage = pageParentType === 'brand';

    сheckbox && cellFormattersCheckbox.push(this.cellCheckboxFormatters);

    // If you need to hide any column, just use conditional statement
    // (e.g. isBrandPage && textColumn({ ... })) and then apply boolean filter.
    // Don't use `isVisible` property, it hides the column for the user
    // but it still exist for 'table' component and it leads to rare bugs
    // because `columnIndex` are messed inside the table component
    const columns = columnsGenerator(
      [
        checkboxColumn({
          headerFormatters: [this.headerCheckboxFormatters],
          cellFormatters: cellFormattersCheckbox,
          className: 'atp-table__checkbox-column',
        }),
        reactSelectColumn({
          name: 'anchorType',
          headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
          cellValueFormatter: this.valueFormatters.anchorTypeValueFormatter,
          className: 'atp-table__anchor-type-column',
          cellTransforms: [
            columnSelect(withAbility, anchorTypeOptions.toList().toJS(), anchorTypeSelectProps),
          ],
        }),
        textColumn({
          name: 'anchorText',
          headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
          cellTransforms: [columnTextArea(withAbility)],
          className: 'atp-table__anchor-text-column',
          showTooltip: true,
          isEditable: true,
        }),
        isBrandPage &&
          textColumn({
            name: 'targetWebsite',
            headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
            className: 'atp-table__target-website-column',
            cellTransforms: [columnTextArea(withAbility)],
            showTooltip: true,
            isEditable: true,
          }),
        isBrandPage &&
          reactSelectColumn({
            name: 'status',
            headerLabel: translate('destinationLink.status.title'),
            explanationMessage: translate('explanationMessages.atpTable.status'),
            cellValueFormatter: this.valueFormatters.statusValueFormatter,
            className: 'atp-table__status-column',
            cellTransforms: [
              columnSelect(withAbility, statusOptions.toList().toJS(), statusSelectProps),
            ],
          }),
        isBrandPage &&
          reactSelectColumn({
            name: 'source',
            headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
            className: 'atp-table__source-column',
            cellValueFormatter: this.valueFormatters.sourceValueFormatter,
            cellTransforms: [
              columnSelect(withAbility, dlSourcesOptions.toList().toJS(), sourceSelectProps),
            ],
          }),
        reactSelectColumn({
          name: 'publishedDate',
          headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
          cellValueFormatter: this.valueFormatters.dateValueFormatter,
          cellTransforms: [columnDate(withAbility)],
          className: 'atp-table__date-column',
        }),
        isBrandPage &&
          textColumn({
            name: 'priority',
            headerValueFormatter: this.headerValueFormatter({ withExplanationMessage: true }),
            className: 'atp-table__priority-column',
            showTooltip: true,
          }),
      ].filter(Boolean),
    );
    // insert new column with index 1 in array
    columns.splice(1, 0, this.publishedLinkColumn(withAbility, form));

    return columns;
  };

  headerValueFormatter = ({ withExplanationMessage }) => (_value, extra) => {
    const { tableType } = this.props;
    const { property } = extra;

    if (tableType === 'pageDashboard') {
      return (
        <div className="atp-table__header-cell">
          {translate(`destinationLink.${property}.title`)}
          {withExplanationMessage && (
            <ExplanatoryTooltipComponent
              text={translate(`explanationMessages.atpTable.${property}`)}
            />
          )}
        </div>
      );
    }

    const { onTableHeaderClick, sorting } = this.props;
    const { sortBy, sortingOrder } = sorting;

    return (
      <HeaderFieldWithSorting
        appModule="atp"
        label={translate(`destinationLink.${property}.title`)}
        onClick={onTableHeaderClick}
        property={property}
        sortBy={sortBy}
        sortingOrder={sortingOrder}
        withExplanationMessage={withExplanationMessage}
      />
    );
  };

  headerCheckboxFormatters = () => {
    const canUpdateAtp = this.props.policies.getIn(['atp', 'canUpdate']);

    return (
      <Checkbox
        checked={this.isAllSelected()}
        className="atp-table__checkbox"
        disabled={!canUpdateAtp}
        onChange={this.handleOnToggleSelectAll}
      />
    );
  };

  cellCheckboxFormatters = (value, extra) => {
    const canUpdateAtp = this.props.policies.getIn(['atp', 'canUpdate']);
    const { selected, id } = extra.rowData;

    return (
      <Checkbox
        checked={selected}
        className="atp-table__checkbox"
        disabled={!canUpdateAtp}
        onChange={this.handleOnToggleSelectRow(id)}
      />
    );
  };

  handleDlDetailsClick = (dlId) => () => this.props.onDlDetailsClick(dlId);

  publishedLinkColumn = (withAbility, form) => {
    const { canUseCrawlers } = this.props;

    const cellFormatters = [
      (value, extra) => {
        if (value === translate('destinationLink.publishedLink.placeholder')) {
          return (
            <div className="common-field">
              <span>{value}</span>
              <span>
                <EditIcon className="common-field__edit-icon" />
              </span>
            </div>
          );
        }

        const { property, rowData } = extra;
        const { errors, id } = rowData;

        const dlStatus = generateDestinationLinkStatusForAtp(fromJS(rowData), canUseCrawlers).map(
          (row) => {
            const { status } = row;
            const text = upperCase(status);
            const color = {
              blue: status === 'processing',
              green: status === 'included',
              orange: status === 'unvalidated',
              red: status === 'excluded',
              warning: status === 'error',
            };
            return <BadgeComponent small {...color} key={text} text={text} />;
          },
        );

        const commonFieldProps = {
          onClick: this.handleDlDetailsClick(id),
        };

        const validationError = (errors || {})[property];

        form && Reflect.deleteProperty(commonFieldProps, 'onClick');

        const cellProps = dlStatus.length > 0 ? {} : commonFieldProps;
        const badgesWrapperProps = dlStatus.length > 0 ? commonFieldProps : {};

        return (
          <div className="common-field" {...cellProps}>
            {value && (
              <Link
                className="text text_one-line atp-table__link"
                href={value}
                target="_blank"
                title={value}
              >
                {value}
              </Link>
            )}
            {!form && !validationError && (
              <span
                className="atp-table__link-status atp-table__link-status_no_stretch"
                {...badgesWrapperProps}
              >
                <DlCrawlingInProgressIndicator dl={rowData} />
                {dlStatus}
              </span>
            )}
            {validationError && <span className="cell_error small">{validationError}</span>}
          </div>
        );
      },
    ];

    const cellTransforms = form ? [columnTextArea(withAbility)] : [];

    return {
      cell: {
        formatters: cellFormatters,
        transforms: cellTransforms,
      },
      header: {
        formatters: [this.headerValueFormatter({ withExplanationMessage: true })],
      },
      property: 'publishedLink',
      props: {
        className: 'atp-table__published-link-column',
      },
    };
  };

  formRow = () => [this.placeholders];

  handleOnToggleSelectAll = (event) => {
    const { onUpdateRows, destinationLinks } = this.props;

    const rows = cloneDeep(destinationLinks);

    const newRows = event.target.checked ? selectabular.all(rows) : selectabular.none(rows);

    onUpdateRows(newRows);
  };

  isAllSelected = () => {
    const { selectedRows, destinationLinks } = this.props;

    const rowsCount = destinationLinks.length;

    return rowsCount > 0 ? rowsCount === selectedRows.size : false;
  };

  handleOnToggleSelectRow = (rowId) => () => {
    const { onUpdateRows, destinationLinks } = this.props;

    const selectedFilter = (row) => row.id === rowId;
    const rows = selectabular.toggle(selectedFilter)(destinationLinks);

    onUpdateRows(rows);
  };

  render() {
    const { columns, formColumns, formRow } = this.state;
    const { destinationLinks } = this.props;

    return (
      <div className="competitors-atp-table__wrapper">
        <Table.Provider className="atp-table__all" columns={columns}>
          <Table.Header />
          <Table.Body rowKey="id" rows={destinationLinks} />
        </Table.Provider>

        <Table.Provider className="atp-table__form" columns={formColumns}>
          <Table.Body rowKey="id" rows={formRow} />
        </Table.Provider>
      </div>
    );
  }
}
