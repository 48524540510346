export const ASSIGN_EO_DRIP_LIST_TO_CAMPAIGN__FAILURE = 'ASSIGN_EO_DRIP_LIST_TO_CAMPAIGN__FAILURE';
export const CREATE_EO_DRIP_LIST__FAILURE = 'CREATE_EO_DRIP_LIST__FAILURE';
export const CREATE_EO_DRIP_LIST__START = 'CREATE_EO_DRIP_LIST__START';
export const CREATE_EO_DRIP_LIST__SUCCESS = 'CREATE_EO_DRIP_LIST__SUCCESS';
export const DELETE_EO_DRIP_LISTS__FAILURE = 'DELETE_EO_DRIP_LISTS__FAILURE';
export const DELETE_EO_DRIP_LISTS__START = 'DELETE_EO_DRIP_LISTS__START';
export const DELETE_EO_DRIP_LISTS__SUCCESS = 'DELETE_EO_DRIP_LISTS__SUCCESS';
export const FETCH_EO_DRIP_LISTS__FAILURE = 'FETCH_EO_DRIP_LISTS__FAILURE';
export const FETCH_EO_DRIP_LISTS__START = 'FETCH_EO_DRIP_LISTS__START';
export const FETCH_EO_DRIP_LISTS__SUCCESS = 'FETCH_EO_DRIP_LISTS__SUCCESS';
export const FETCH_EO_DRIP_LIST__FAILURE = 'FETCH_EO_DRIP_LIST__FAILURE';
export const FETCH_EO_DRIP_LIST__START = 'FETCH_EO_DRIP_LIST__START';
export const FETCH_EO_DRIP_LIST__SUCCESS = 'FETCH_EO_DRIP_LIST__SUCCESS';
export const FETCH_EO_DRIP_LISTS_AS_OPTIONS__SUCCESS = 'FETCH_EO_DRIP_LISTS_AS_OPTIONS__SUCCESS';
export const UPDATE_EO_DRIP_LIST__FAILURE = 'UPDATE_EO_DRIP_LIST__FAILURE';
export const UPDATE_EO_DRIP_LIST__START = 'UPDATE_EO_DRIP_LIST__START';
export const UPDATE_EO_DRIP_LIST__SUCCESS = 'UPDATE_EO_DRIP_LIST__SUCCESS';

export function assignEoDripListToCampaignFailure(errors) {
  return { type: ASSIGN_EO_DRIP_LIST_TO_CAMPAIGN__FAILURE, errors };
}

export function createEoDripListStart() {
  return { type: CREATE_EO_DRIP_LIST__START };
}

export function createEoDripListSuccess(eoDripList) {
  return { type: CREATE_EO_DRIP_LIST__SUCCESS, eoDripList };
}

export function createEoDripListFailure(errors) {
  return { type: CREATE_EO_DRIP_LIST__FAILURE, errors };
}

export function deleteEoDripListsStart() {
  return { type: DELETE_EO_DRIP_LISTS__START };
}

export function deleteEoDripListsSuccess(ids) {
  return { type: DELETE_EO_DRIP_LISTS__SUCCESS, ids };
}

export function deleteEoDripListsFailure(errors) {
  return { type: DELETE_EO_DRIP_LISTS__FAILURE, errors };
}

export function fetchEoDripListsStart() {
  return { type: FETCH_EO_DRIP_LISTS__START };
}

export function fetchEoDripListsSuccess(eoDripLists) {
  return { type: FETCH_EO_DRIP_LISTS__SUCCESS, eoDripLists };
}

export function fetchEoDripListsFailure(errors) {
  return { type: FETCH_EO_DRIP_LISTS__FAILURE, errors };
}

export function fetchEoDripListStart() {
  return { type: FETCH_EO_DRIP_LIST__START };
}

export function fetchEoDripListSuccess(eoDripList) {
  return { type: FETCH_EO_DRIP_LIST__SUCCESS, eoDripList };
}

export function fetchEoDripListFailure(errors) {
  return { type: FETCH_EO_DRIP_LIST__FAILURE, errors };
}

export function fetchEoDripListsAsOptionsSuccess(eoDripLists) {
  return { type: FETCH_EO_DRIP_LISTS_AS_OPTIONS__SUCCESS, eoDripLists };
}

export function updateEoDripListStart() {
  return { type: UPDATE_EO_DRIP_LIST__START };
}

export function updateEoDripListSuccess(eoDripList) {
  return { type: UPDATE_EO_DRIP_LIST__SUCCESS, eoDripList };
}

export function updateEoDripListFailure(errors) {
  return { type: UPDATE_EO_DRIP_LIST__FAILURE, errors };
}
