import React from 'react';
import PropTypes from 'prop-types';

import SearchEoMessagesInput from './SearchEoMessagesInput';
import DeleteEoMessagesButton from './DeleteEoMessagesButton';

import './eoMessagesBulkActions.scss';
const EoMessagesBulkActionsComponent = ({ hideSearchInput, showDeleteButton }) => {
  return (
    <div className="eo-messages-bulk-actions">
      <div className="eo-messages-bulk-actions__row">
        <div className="eo-messages-bulk-actions__row-item">
          {!hideSearchInput && <SearchEoMessagesInput />}
          {/* Uncomment when the 'Delete Replies' feature needed */}
          {/* {showDeleteButton && (
            <>
              <span className="vertical-separator" />
              <DeleteEoMessagesButton />
            </>
          )} */}
        </div>
      </div>
    </div>
  );
};

EoMessagesBulkActionsComponent.propTypes = {
  hideSearchInput: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
};

export default EoMessagesBulkActionsComponent;
