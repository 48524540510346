import React from 'react';
import PropTypes from 'prop-types';

import BellIcon from '../../../common/icons/bell';

import './notificationsButton.scss';

const NotificationsButtonComponent = ({ onToggleNotificationsButtonClick, unreadCount }) => {
  return (
    <div onClick={onToggleNotificationsButtonClick} className="notifications-button">
      <BellIcon />

      {unreadCount > 0 &&
        <div className="notifications-button__unread-counter">{unreadCount}</div>
      }
    </div>
  );
};

NotificationsButtonComponent.propTypes = {
  onToggleNotificationsButtonClick: PropTypes.func,
  unreadCount: PropTypes.number.isRequired,
};

export default NotificationsButtonComponent;
