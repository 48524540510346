import {
  autodiscoverPagesViaSitemapFailure,
  autodiscoverPagesViaSitemapSuccess,
} from '../../actions/bulkPagesSetupActions';

import { translate } from '../../common/i18n';
import { showSuccessMessage } from '../../common/utils';


const handleAutodiscoverViaSitemapStatus = (dispatch, data) => {
  const {
    autodiscoverViaSitemapStatus,
    brandId,
    dupeOrInvalidEntriesCount,
    message,
    pagesCountLimit,
    pagesCountLimitReached,
    uniqAndValidEntriesCount,
  } = data;

  switch (autodiscoverViaSitemapStatus) {
    case 'failure':
      dispatch(autodiscoverPagesViaSitemapFailure({ brandId, message }));

      break;
    case 'finished':
      // Do not refresh draftBrandPages here. Otherwise results of
      // BrandPages::AddHeadDataWorker and/or AhrefsApi::BrandPagesMetricsUploaderWorker broadcasters
      // will be overwritten by outdated data

      dispatch(autodiscoverPagesViaSitemapSuccess(brandId));

      showSuccessMessage(
        dispatch,
        translate(
          'bulkPagesSetup.messages.autodiscoverViaSitemapSuccess'
        )(
          uniqAndValidEntriesCount,
          dupeOrInvalidEntriesCount,
          pagesCountLimit,
          pagesCountLimitReached
        )
      );

      break;
  }
};


export default handleAutodiscoverViaSitemapStatus;
