const FETCH_RANK_TRACKER_COUNTRIES__FAILURE = 'FETCH_RANK_TRACKER_COUNTRIES__FAILURE';
const FETCH_RANK_TRACKER_COUNTRIES__START = 'FETCH_RANK_TRACKER_COUNTRIES__START';
const FETCH_RANK_TRACKER_COUNTRIES__SUCCESS = 'FETCH_RANK_TRACKER_COUNTRIES__SUCCESS';
const FETCH_RANK_TRACKER_ENGINES__FAILURE = 'FETCH_RANK_TRACKER_ENGINES__FAILURE';
const FETCH_RANK_TRACKER_ENGINES__START = 'FETCH_RANK_TRACKER_ENGINES__START';
const FETCH_RANK_TRACKER_ENGINES__SUCCESS = 'FETCH_RANK_TRACKER_ENGINES__SUCCESS';
const FETCH_RANK_TRACKER_LANGUAGES__FAILURE = 'FETCH_RANK_TRACKER_LANGUAGES__FAILURE';
const FETCH_RANK_TRACKER_LANGUAGES__START = 'FETCH_RANK_TRACKER_LANGUAGES__START';
const FETCH_RANK_TRACKER_LANGUAGES__SUCCESS = 'FETCH_RANK_TRACKER_LANGUAGES__SUCCESS';
const FETCH_RANK_TRACKER_LOCATIONS__FAILURE = 'FETCH_RANK_TRACKER_LOCATIONS__FAILURE';
const FETCH_RANK_TRACKER_LOCATIONS__START = 'FETCH_RANK_TRACKER_LOCATIONS__START';
const FETCH_RANK_TRACKER_LOCATIONS__SUCCESS = 'FETCH_RANK_TRACKER_LOCATIONS__SUCCESS';
const FETCH_RANK_TRACKER_SETTINGS__FAILURE = 'FETCH_RANK_TRACKER_SETTINGS__FAILURE';
const FETCH_RANK_TRACKER_SETTINGS__START = 'FETCH_RANK_TRACKER_SETTINGS__START';
const FETCH_RANK_TRACKER_SETTINGS__SUCCESS = 'FETCH_RANK_TRACKER_SETTINGS__SUCCESS';
const SAVE_RANK_TRACKER_SETTINGS__FAILURE = 'SAVE_RANK_TRACKER_SETTINGS__FAILURE';

function fetchRankTrackerCountriesStart() {
  return { type: FETCH_RANK_TRACKER_COUNTRIES__START };
}

function fetchRankTrackerCountriesSuccess(countries) {
  return { type: FETCH_RANK_TRACKER_COUNTRIES__SUCCESS, countries };
}

function fetchRankTrackerCountriesFailure() {
  return { type: FETCH_RANK_TRACKER_COUNTRIES__FAILURE };
}

function fetchRankTrackerEnginesStart() {
  return { type: FETCH_RANK_TRACKER_ENGINES__START };
}

function fetchRankTrackerEnginesSuccess(engineNames) {
  return { type: FETCH_RANK_TRACKER_ENGINES__SUCCESS, engineNames };
}

function fetchRankTrackerEnginesFailure() {
  return { type: FETCH_RANK_TRACKER_ENGINES__FAILURE };
}

function fetchRankTrackerLanguagesStart() {
  return { type: FETCH_RANK_TRACKER_LANGUAGES__START };
}

function fetchRankTrackerLanguagesSuccess(languages) {
  return { type: FETCH_RANK_TRACKER_LANGUAGES__SUCCESS, languages };
}

function fetchRankTrackerLanguagesFailure() {
  return { type: FETCH_RANK_TRACKER_LANGUAGES__FAILURE };
}

function fetchRankTrackerLocationsStart() {
  return { type: FETCH_RANK_TRACKER_LOCATIONS__START };
}

function fetchRankTrackerLocationsSuccess(locations) {
  return { type: FETCH_RANK_TRACKER_LOCATIONS__SUCCESS, locations };
}

function fetchRankTrackerLocationsFailure() {
  return { type: FETCH_RANK_TRACKER_LOCATIONS__FAILURE };
}

function fetchRankTrackerSettingsStart() {
  return { type: FETCH_RANK_TRACKER_SETTINGS__START };
}

function fetchRankTrackerSettingsSuccess(settings) {
  return { type: FETCH_RANK_TRACKER_SETTINGS__SUCCESS, settings };
}

function fetchRankTrackerSettingsFailure() {
  return { type: FETCH_RANK_TRACKER_SETTINGS__FAILURE };
}

function saveRankTrackerSettingsFailure() {
  return { type: SAVE_RANK_TRACKER_SETTINGS__FAILURE };
}


export {
  FETCH_RANK_TRACKER_COUNTRIES__FAILURE,
  FETCH_RANK_TRACKER_COUNTRIES__START,
  FETCH_RANK_TRACKER_COUNTRIES__SUCCESS,
  FETCH_RANK_TRACKER_ENGINES__FAILURE,
  FETCH_RANK_TRACKER_ENGINES__START,
  FETCH_RANK_TRACKER_ENGINES__SUCCESS,
  FETCH_RANK_TRACKER_LANGUAGES__FAILURE,
  FETCH_RANK_TRACKER_LANGUAGES__START,
  FETCH_RANK_TRACKER_LANGUAGES__SUCCESS,
  FETCH_RANK_TRACKER_LOCATIONS__FAILURE,
  FETCH_RANK_TRACKER_LOCATIONS__START,
  FETCH_RANK_TRACKER_LOCATIONS__SUCCESS,
  FETCH_RANK_TRACKER_SETTINGS__FAILURE,
  FETCH_RANK_TRACKER_SETTINGS__START,
  FETCH_RANK_TRACKER_SETTINGS__SUCCESS,
  SAVE_RANK_TRACKER_SETTINGS__FAILURE,
  fetchRankTrackerCountriesFailure,
  fetchRankTrackerCountriesStart,
  fetchRankTrackerCountriesSuccess,
  fetchRankTrackerEnginesFailure,
  fetchRankTrackerEnginesStart,
  fetchRankTrackerEnginesSuccess,
  fetchRankTrackerLanguagesFailure,
  fetchRankTrackerLanguagesStart,
  fetchRankTrackerLanguagesSuccess,
  fetchRankTrackerLocationsFailure,
  fetchRankTrackerLocationsStart,
  fetchRankTrackerLocationsSuccess,
  fetchRankTrackerSettingsFailure,
  fetchRankTrackerSettingsStart,
  fetchRankTrackerSettingsSuccess,
  saveRankTrackerSettingsFailure,
};
