import { isEqual, isEmpty } from 'lodash';

import { updatePage, fetchPagePercentage } from 'api/brand_page';

const updatePageService = async (context, page, parentPage = null) => {
  const { dispatch, pages } = context;

  const initialPage = pages.get(String(page.id)).toJS();

  if (isEqual(page, initialPage)) {
    return {};
  }

  const result = await updatePage(dispatch, page.id, page);
  const { type, errors } = result;

  if (type === 'error' || !isEmpty(errors)) {
    return result;
  }

  const pageTypeIdChanged = page.pageTypeId !== initialPage.pageTypeId;
  pageTypeIdChanged && (await recalculatePercentage(dispatch, page.id));

  if (parentPage) {
    await updatePage(dispatch, parentPage.id, parentPage);
  }

  return result;
};

const recalculatePercentage = async (dispatch, pageId) => {
  await fetchPagePercentage(dispatch, pageId);
};

export default updatePageService;
