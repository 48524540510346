import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import DownloadIcon from 'common/icons/download';

const ExportToCsvButtonComponent = ({ isDisabled, onClick }) => {
  return (
    <ButtonComponent isBlue isInitial isDisabled={isDisabled} onClick={onClick}>
      <DownloadIcon />
      {translate('rankTracker.buttons.exportToCsv')}
    </ButtonComponent>
  );
};

ExportToCsvButtonComponent.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ExportToCsvButtonComponent;
