import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import InfoIcon from 'common/icons/info';
import TickInCircleIcon from 'common/icons/tick_in_circle';
import CrossInCircleIcon from 'common/icons/cross_in_circle';

import './message_component.scss';

const MessageComponent = ({ message, children }) => {
  if (!message.text && !children) {
    return '';
  }

  const messageClasses = classnames({
    'message-component': true,
    'message-component_error': message.type === 'error',
    'message-component_success': message.type === 'success',
    'message-component__info-block': message.type === 'infoBlock',
  });

  let icon = InfoIcon;

  switch (message.type) {
    case 'hint':
    case 'infoBlock':
      icon = InfoIcon;
      break;
    case 'error':
      icon = CrossInCircleIcon;
      break;
    case 'success':
      icon = TickInCircleIcon;
      break;
  }

  return (
    <div className={messageClasses}>
      <div className="message-component__icon">{icon()}</div>
      <div className="message-component__text">
        {message.text}
        {children}
      </div>
    </div>
  );
};

MessageComponent.propTypes = {
  children: PropTypes.node,
  message: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    type: PropTypes.oneOf(['success', 'error', 'hint', 'infoBlock']),
  }),
};

export default MessageComponent;
