import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import recalculatePercentageService from '../../services/recalculatePercentageService';
import dlSourceChangeService from '../../../../../services/dlSourceChangeService';

import SetValueInputsComponent from './SetValueInputsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  activeAtpTableTabSelector,
  destinationLinksByBrandPageIdSelector,
  optionsSelector,
  visiblePageIdByParentSelector,
} from 'selectors';

import { destinationLinksMap, optionsMap } from 'common/prop_types_shapes';
import { getSelected } from 'pages/Pages/utils';
import { isStatusStartedOrPublished } from 'components/atp/atp_table/services/atpTableTrackHelpcrunchEventsService';
import { trackHelpcrunchEvent } from 'common/utils';
import { updateDl } from 'api/destination_links';

class SetValueInputsContainer extends Component {
  static propTypes = {
    activeAtpTableTab: PropTypes.string,
    destinationLinks: destinationLinksMap,
    dispatch: PropTypes.func,
    options: optionsMap,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    positionBottom: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = this.initialState;
  }

  initialState = {
    selectedProperty: { value: 'empty' },
    selectedValue: null,
  };

  handleSelectInputChange = (selectedValue) => this.setState({ selectedValue });

  handlePropertyInputChange = (selectedProperty, selectedValue) => {
    this.setState({ selectedProperty, selectedValue });
  };

  handleSourceInputChange = async (object) => {
    const { dispatch, dlSourcesOptions } = this.props;

    await dlSourceChangeService({ dispatch, dlSourcesOptions }, (object || {}).value);
    this.setState({ selectedValue: object });
  };

  handleSetProperties = async () => {
    const { dispatch, visiblePageId, destinationLinks, dlSourcesOptions } = this.props;
    const {
      selectedProperty: { value: selectedPropertyOption },
      selectedValue: { value: selectedValueOption },
    } = this.state;

    const selectedRows = getSelected(destinationLinks);
    const updatedDls = selectedRows.map((dl) =>
      dl.set(selectedPropertyOption, selectedValueOption),
    );

    const isStartedOrPublishedSelected = isStatusStartedOrPublished(
      selectedPropertyOption,
      selectedValueOption,
    );
    isStartedOrPublishedSelected && trackHelpcrunchEvent(`change.linkStatus.${selectedValueOption}`);

    const data = await updateDl(dispatch, visiblePageId, updatedDls.valueSeq().toJS());

    const resultHasErrors = data.type && data.type === 'error';
    if (!resultHasErrors) {
      // TODO: get highlighting to work
      // this.handleHighlightRows(map(data, (row) => row.id));
      recalculatePercentageService(
        { dispatch, dlSourcesOptions },
        visiblePageId,
        selectedPropertyOption,
        selectedValueOption,
      );
    }

    this.setState(this.initialState);
  };

  render() {
    const {
      activeAtpTableTab,
      destinationLinks,
      pageChildType,
      positionBottom,
      options,
    } = this.props;

    const { selectedProperty, selectedValue } = this.state;

    const selectedRows = getSelected(destinationLinks);
    const isSelected = selectedRows.size > 0;

    return (
      <SetValueInputsComponent
        isSelected={isSelected}
        onPropertyInputChange={this.handlePropertyInputChange}
        onSelectInputChange={this.handleSelectInputChange}
        onSetProperties={this.handleSetProperties}
        onSourceInputChange={this.handleSourceInputChange}
        options={options}
        pageChildType={pageChildType}
        positionBottom={positionBottom}
        selectedProperty={selectedProperty}
        selectedValue={selectedValue}
        tableType={activeAtpTableTab}
      />
    );
  }
}

function select(state, ownProps) {
  const activeAtpTableTab = activeAtpTableTabSelector(state, ownProps);
  const destinationLinks = destinationLinksByBrandPageIdSelector(state, ownProps);
  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const dlSourcesOptions = options.get('dlSourcesOptions');

  return {
    activeAtpTableTab,
    destinationLinks,
    dlSourcesOptions,
    options,
    visiblePageId,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(SetValueInputsContainer)));
