import { createDlSource } from 'api/dl_source';

const dlSourceChangeService = async (context, newDlSource) => {
  const { dispatch, dlSourcesOptions } = context;

  if (!newDlSource) {
    return;
  }

  if (!dlSourcesOptions.get(newDlSource)) {
    await createDlSource(dispatch, { title: newDlSource });
  }
};

export default dlSourceChangeService;
