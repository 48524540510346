import { fromJS, List as iList } from 'immutable';
import { camelizeObjectDeep, mergeItemToList } from './utils';

import {
  CREATE_EO_ACTIVE_HOUR__SUCCESS,
  DELETE_EO_ACTIVE_HOURS__SUCCESS,
  FETCH_EO_ACTIVE_HOURS__SUCCESS,
  FETCH_EO_ACTIVE_HOUR__SUCCESS,
  UPDATE_EO_ACTIVE_HOUR__SUCCESS,
} from 'actions/eoActiveHourActions';

import { WEEKDAYS } from 'utils/constants';

const initialState = iList();

function sortByWeekdays(eoActiveHoursSettings) {
  return eoActiveHoursSettings.sort(
    (a, b) => WEEKDAYS.indexOf(a.weekday) - WEEKDAYS.indexOf(b.weekday),
  );
}

export default function eoActiveHoursReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_ACTIVE_HOURS__SUCCESS: {
      const { eoActiveHours } = action;

      state = fromJS(
        eoActiveHours.map(camelizeObjectDeep).map((eoActiveHour) => ({
          ...eoActiveHour,
          eoActiveHoursSettings: sortByWeekdays(eoActiveHour.eoActiveHoursSettings),
        })),
      );

      break;
    }
    case FETCH_EO_ACTIVE_HOUR__SUCCESS:
    case CREATE_EO_ACTIVE_HOUR__SUCCESS:
    case UPDATE_EO_ACTIVE_HOUR__SUCCESS: {
      const { eoActiveHour } = action;

      const camelizedEoActiveHour = camelizeObjectDeep(eoActiveHour);

      const sortedEoActiveHour = {
        ...camelizedEoActiveHour,
        eoActiveHoursSettings: sortByWeekdays(camelizedEoActiveHour.eoActiveHoursSettings),
      };
      state = mergeItemToList(state, sortedEoActiveHour);

      break;
    }
    case DELETE_EO_ACTIVE_HOURS__SUCCESS: {
      const { ids } = action;

      state = state.filter((eoActiveHour) => !ids.includes(eoActiveHour.get('id')));

      break;
    }
  }

  return state;
}
