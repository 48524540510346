import React from 'react';
import PropTypes from 'prop-types';

import BrandCharts from './BrandCharts';
import BrandOverview from './BrandOverview';

import { translate } from 'common/i18n';
import { simpleBrandShape } from 'common/propTypesShapes/simpleBrands';

import CogIcon from 'common/icons/cog';
import OverlayWithTooltipComponent from 'components_linkio/overlay_with_tooltip_component';
import PageLayoutHeader from 'components_linkio/PageLayoutHeader';
import TutorialPopup from 'components_linkio/TutorialPopup';
import BrandOverViewBtn from './BrandOverViewBtn'
import './brandDashboard.scss';

const BrandDashboardComponent = ({ brand, onVisitLink }) => {
  
  return (
    <div className="brand-dashboard">
      <PageLayoutHeader
        title={translate('brandDashboard.title')}
        Popup={
          <TutorialPopup
            title={translate('brandDashboard.title')}
            videoSrc="https://www.youtube.com/embed/DQzvwqx8-u4"
          />
        }
      />

      <div className="brand-dashboard__brand-name-and-website-wrapper">
        <span className="brand-dashboard__brand-name">{brand.get('name')}</span>

        <a
          className="text_link"
          href={brand.get('website')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {brand.get('website')}
        </a>

        <OverlayWithTooltipComponent
          tooltip={{ text: translate('brandDashboard.editBrandHint'), placement: 'top' }}
        >
          <span
            className="brand-dashboard__edit-brand"
            onClick={onVisitLink(Routes.edit_brand_path(brand.get('id'), { format: null }))}
          >
            <CogIcon />
          </span>
        </OverlayWithTooltipComponent>
      </div>

      <div className="brand-dashboard__overview-wrapper">
        <h3>{translate('brandDashboard.brandOverview.title')}</h3>

        <BrandOverview />
      </div>
      
      <div className="brand-dashboard__charts-wrapper">
        <h3>{translate('brandDashboard.brandCharts.title')}</h3>
        <div className='generate-metrics-button'>
          <div className={`brand-overview-item__generate-overview`}>
            <div className="brand-overview-item__header-wrapper">
              <span className="brand-overview-item__app-module-title">
                {translate(`brandDashboard.brandOverview.generate.title`)}
              </span>
                <BrandOverViewBtn />
            </div>
          </div>
        </div>
        <BrandCharts />
      </div>
    </div>
  );
};

BrandDashboardComponent.propTypes = {
  brand: simpleBrandShape,
  onVisitLink: PropTypes.func.isRequired,
};

export default BrandDashboardComponent;
