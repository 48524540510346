import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ChangePercentagesButtonComponent from './ChangePercentagesButtonComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { openChangePercentagesPopup } from 'actions/popup_actions';
import { trackHelpcrunchEvent } from 'common/utils';
import { activeAtpTableTabSelector } from 'selectors';

import { activeAtpTableTabString, historyShape, matchShape } from 'common/prop_types_shapes';

class ChangePercentagesButtonContainer extends React.PureComponent {
  static propTypes = {
    activeAtpTableTab: activeAtpTableTabString,
    dispatch: PropTypes.func,
    history: historyShape,
    match: matchShape,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
    ...withQueryParamsPropTypes,
  };

  handleChangePercentagesButtonClick = () => {
    const {
      activeAtpTableTab,
      dispatch,
      history,
      match,
      pageChildType,
      pageParentId,
      pageParentType,
      queryParams,
    } = this.props;

    trackHelpcrunchEvent('click.btn.changePercentages');
    dispatch(openChangePercentagesPopup({ pageChildType, pageParentId, pageParentType }));

    if (pageParentType === 'brand') {
      const { id, pageId } = match.params;
      const path = Routes.brand_page_percentages_select_popup_path(id, pageId, activeAtpTableTab, {
        ...queryParams,
        format: null,
      });

      history.push(path);
    }
  };

  render() {
    return (
      <ChangePercentagesButtonComponent
        onChangePercentagesButtonClick={this.handleChangePercentagesButtonClick}
      />
    );
  }
}

function select(state, ownProps) {
  const activeAtpTableTab = activeAtpTableTabSelector(state, ownProps);

  return {
    activeAtpTableTab,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(ChangePercentagesButtonContainer)));
