import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PlusInRoundIcon from 'common/icons/plus_in_round';
import MinusInRoundIcon from 'common/icons/minus_in_round';

import './openCloseIcon.scss';

const PLUS_ICON_COLOR = '#1abc9c';
const MINUS_ICON_COLOR = '#e3204b';

const OpenCloseIconComponent = ({ isOpen, onOpenCloseClick, pageId }) => {
  const wrapperClasses = classnames({
    'open-close-icon': true,
    [`capybara--page-toggler_${pageId}`]: true,
  });

  return (
    <span onClick={onOpenCloseClick} className={wrapperClasses}>
      {isOpen && <MinusInRoundIcon fill={MINUS_ICON_COLOR} height="16px" />}
      {!isOpen && <PlusInRoundIcon fill={PLUS_ICON_COLOR} height="16px" />}
    </span>
  );
};

OpenCloseIconComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpenCloseClick: PropTypes.func.isRequired,
  pageId: PropTypes.number,
};

export default OpenCloseIconComponent;
