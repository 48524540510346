import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';
import { Provider, Header, Body } from 'reactabular-table';
import { findIndex, each, isEmpty } from 'lodash';
import * as edit from 'react-edit';
import * as selectabular from 'selectabular';

import { translate } from '../../common/i18n';

import columnsGenerator, { columnSelect, columnTextArea } from '../../common/tables/columns_generator';

import {
  optionsMap,
  policiesShape,
  userShape,
} from '../../common/prop_types_shapes';

import {
  addCSSClassToCellInTable,
  addCSSClassToRowInTable,
  highlightTableRow,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
  TABLE_ROW_CURRENT_USER_CLASS,
} from '../../common/tables/utils';

import { trackHelpcrunchEvent } from '../../common/utils';

import {
  checkboxColumn,
  nameColumn,
  textColumn,
  reactSelectColumn,
} from '../../common/tables/columns';

import ButtonComponent from '../../components_linkio/button_component';
import Checkbox from '../../components_linkio/Checkbox';
import confirmationDialogue from '../../components/confirmation_dialogue';
import { optionValueOrValue } from '../../components_linkio/Select/utils';

import {
  processEmployee,
  isEditing,
  placeholderTextObject,
  profileSelectProps,
} from './utils';


class EmployeesComponent extends Component {
  static propTypes = {
    currentUser: userShape,
    dispatch: PropTypes.func,
    employeeFormRow: userShape,
    employees: PropTypes.arrayOf(userShape),
    onCreateEmployee: PropTypes.func,
    onDeleteEmployees: PropTypes.func,
    onInviteEmployees: PropTypes.func,
    onUpdateEmployee: PropTypes.func,
    onUpdateFormRow: PropTypes.func,
    onUpdateRows: PropTypes.func,
    policies: policiesShape.isRequired,
    profileTypeOptions: optionsMap,
    recaptcha: PropTypes.node,
  };

  componentDidMount() {
    this.setTablesRef();
  }

  componentDidUpdate() {
    this.setCurrentUserClassToRow();
  }


  tableRef = {};
  tableFormRef = {};

  setTablesRef = () => {
    const tables = ReactDOM.findDOMNode(this).getElementsByTagName('table');
    this.tableRef = tables[0];
    this.tableFormRef = tables[1];
  }

  setCurrentUserClassToRow = () => {
    const { currentUser, employees } = this.props;

    removeCSSClassFromTableCells(this.tableRef, TABLE_ROW_CURRENT_USER_CLASS);

    each(employees, (emp, index) => {
      const isCurrentUser = emp.id === currentUser.get('id');
      isCurrentUser && addCSSClassToRowInTable(this.tableRef, index, TABLE_ROW_CURRENT_USER_CLASS);
    });
  }

  removeCellActiveClassFromAllCells = () => {
    removeCSSClassFromTableCells(this.tableRef, TABLE_CELL_ACTIVE_CLASS);
    removeCSSClassFromTableCells(this.tableFormRef, TABLE_CELL_ACTIVE_CLASS);
  }

  generateColumns = (withAbility, options = {}) => {
    const cellFormattersCheckbox = [];

    options.сheckbox && cellFormattersCheckbox.push(this.cellCheckboxFormatters);

    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [this.headerCheckboxFormatters],
        cellFormatters: cellFormattersCheckbox,
        className: 'employees-table__checkbox',
      }),
      nameColumn({
        cellTransforms: [columnTextArea(withAbility)],
        className: 'employees-table__name-column',
      }),
      textColumn({
        name: 'email',
        headerLabel: translate('employee.email.title'),
        cellTransforms: [columnTextArea(withAbility)],
        className: 'employees-table__email-column',
        isEditable: true,
      }),
      reactSelectColumn({
        name: 'profileType',
        headerLabel: translate('employee.profileType.title'),
        cellValueFormatter: this.profileTypeNameBy,
        cellTransforms: [
          columnSelect(withAbility, this.props.profileTypeOptions.valueSeq().toJS(), profileSelectProps),
        ],
        className: 'employees-table__select-react',
      }),
      textColumn({
        name: 'status',
        headerLabel: translate('employee.status.title'),
        className: 'employees-table__status-column',
      }),
    ]);
  }

  profileTypeNameBy = (value) => this.props.profileTypeOptions.get(value, iMap()).get('label', '');

  checkboxesAreEnabled = () => {
    const { policies } = this.props;

    const canInviteEmployee = policies.getIn(['employee', 'canInvite']);
    const canDestroyEmployee = policies.getIn(['employee', 'canDestroy']);

    return canInviteEmployee || canDestroyEmployee;
  };

  headerCheckboxFormatters = () => {
    return (
      <Checkbox
        className="employees-table__checkbox"
        checked={this.isAllSelected()}
        disabled={!this.checkboxesAreEnabled()}
        onChange={this.handleOnToggleSelectAll}
      />
    );
  }

  cellCheckboxFormatters = (value, extra) => {
    const { currentUser } = this.props;
    const rowDataId = extra.rowData.id;
    const isCurrentUser = rowDataId === currentUser.get('id');

    return (
      <Checkbox
        className="employees-table__checkbox"
        checked={extra.rowData.selected}
        disabled={!this.checkboxesAreEnabled() || isCurrentUser}
        onChange={this.handleOnToggleSelectRow(rowDataId)}
      />
    );
  }

  editable = edit.edit({
    isEditing,

    onActivate: ({ columnIndex, rowData }) => {
      const { currentUser, employees, onUpdateRows, policies } = this.props;

      const canUpdateEmployee = policies.getIn(['employee', 'canUpdate']);
      const isCurrentUser = rowData.id === currentUser.get('id');
      if (!canUpdateEmployee || isCurrentUser) {
        return;
      }

      const index = findIndex(employees, { id: rowData.id });

      employees[index].editing = columnIndex;
      employees[index].errors[employees[index].property] = '';

      addCSSClassToCellInTable(this.tableRef, index, columnIndex, TABLE_CELL_ACTIVE_CLASS);

      onUpdateRows(employees);
    },

    onValue: async ({ value, rowData, property }) => {
      const { employees, onUpdateRows, onUpdateEmployee } = this.props;
      const index = findIndex(employees, { id: rowData.id });
      const processedValue = optionValueOrValue(value);

      this.removeCellActiveClassFromAllCells();

      if (rowData[property] !== processedValue) {
        const updatedEmployee = { ...rowData, [property]: processedValue };
        onUpdateEmployee(updatedEmployee);

        highlightTableRow(this.tableRef, index);
      }

      Reflect.deleteProperty(employees[index], 'editing');

      onUpdateRows(employees);
    },
  });

  creatable = edit.edit({
    isEditing,

    onActivate: ({ columnIndex, property }) => {
      const { employeeFormRow, onUpdateFormRow } = this.props;

      employeeFormRow.editing = columnIndex;
      employeeFormRow.errors[property] = '';
      if (employeeFormRow[property] === placeholderTextObject[property]) {
        employeeFormRow[property] = '_clear_';
      }

      const index = 0;
      addCSSClassToCellInTable(this.tableFormRef, index, columnIndex, TABLE_CELL_ACTIVE_CLASS);

      onUpdateFormRow(employeeFormRow);
    },

    onValue: async ({ value, rowData, property }) => {
      const { employeeFormRow, onCreateEmployee, onUpdateFormRow } = this.props;
      const index = 0;
      const processedValue = optionValueOrValue(value);

      this.removeCellActiveClassFromAllCells();
      Reflect.deleteProperty(employeeFormRow, 'editing');

      if (processedValue === '') {
        Object.assign(employeeFormRow, { [property]: placeholderTextObject[property] });
      }

      // we need both name and email to be sent to the server
      const employeeData = this.removePlaceholderTextFrom(
        Object.assign({}, rowData, { [property]: processedValue })
      );
      const employeeHasAllRequiredFields = !employeeData.name || !employeeData.email || !employeeData.profileType;
      if (employeeHasAllRequiredFields) {
        onUpdateFormRow({ ...employeeFormRow, [property]: processedValue });
        return;
      }

      const employee = await onCreateEmployee(employeeData);
      const { errors } = employee;

      if (isEmpty(errors)) {
        trackHelpcrunchEvent('inviteUsers');

        highlightTableRow(this.tableFormRef, index);
        highlightTableRow(this.tableRef, -1);
      } else {
        onUpdateFormRow({ ...employeeFormRow, errors, [property]: processedValue });
      }
    },
  });

  removePlaceholderTextFrom = (employee) => {
    each(placeholderTextObject, (value, key) => {
      if (employee[key] === value) {
        employee[key] = '';
      }
    });

    return employee;
  }

  handleOnToggleSelectAll = (event) => {
    const { employees, onUpdateRows } = this.props;

    const rows = event.target.checked ? selectabular.all(employees) : selectabular.none(employees);

    onUpdateRows(rows);
  }

  handleOnToggleSelectRow = (rowId) => () => {
    const { employees, onUpdateRows } = this.props;

    const selectedFilter = (row) => row.id === rowId;
    const rows = selectabular.toggle(selectedFilter)(employees);

    onUpdateRows(rows);
  }

  selectedRows = () => this.props.employees.filter((row) => row.selected);

  isAllSelected = () => {
    const { employees } = this.props;

    const rowsCount = employees.length;
    const selectedRowsCount = this.selectedRows().length;

    return rowsCount > 0 ? (rowsCount === selectedRowsCount) : false;
  }

  handleDelete = () => {
    const employeesCount = this.selectedRows().length;

    confirmationDialogue({
      body: translate('confirmations.deleteEmployee.body', { count: employeesCount }),
      onConfirm: this.onConfirmDelete,
    });
  }

  onConfirmDelete = async () => {
    const { employees, onDeleteEmployees, onUpdateRows } = this.props;

    const rowIds = this.selectedRows().map((row) => row.id);
    const rows = selectabular.none(employees.filter((row) => !row.selected));

    await onDeleteEmployees(rowIds);
    onUpdateRows(rows);
  }

  handleSendInvitation = () => {
    const employeesCount = this.selectedRows().length;

    confirmationDialogue({
      body: translate('confirmations.inviteEmployee.body', { count: employeesCount }),
      onConfirm: this.onConfirmSendInvitation,
    });
  }

  onConfirmSendInvitation = async () => {
    const { dispatch, employees, onInviteEmployees, onUpdateRows } = this.props;

    const rowIds = this.selectedRows().map((row) => row.id);

    const response = await onInviteEmployees(rowIds);
    if (response.type && response.type === 'error') {
      return;
    }

    trackHelpcrunchEvent('inviteUsers');

    const rows = selectabular.none(employees);
    onUpdateRows(rows);
  }

  render() {
    const {
      employeeFormRow,
      employees,
      policies,
      recaptcha,
    } = this.props;

    const canDestroyEmployee = policies.getIn(['employee', 'canDestroy']);
    const canInviteEmployee = policies.getIn(['employee', 'canInvite']);
    const canCreateEmployee = policies.getIn(['employee', 'canCreate']);

    const columns = this.generateColumns(this.editable, { сheckbox: true });
    const rows = employees.map(processEmployee);

    const formColumns = canCreateEmployee ? this.generateColumns(this.creatable) : [];
    const formRow = [employeeFormRow].map(processEmployee);

    const selectedCount = this.selectedRows().length;


    return (
      <>
        <div className="page__wrapper">
          <div className="employees-table__wrapper">
            <Provider
              className="employees-table__all"
              columns={columns}
            >
              <Header />
              <Body
                rows={rows}
                rowKey="id"

              />
            </Provider>
            <Provider
              className="employees-table__form"
              columns={formColumns}
            >
              <Body
                rowKey="id"
                rows={formRow}
              />
            </Provider>
          </div>
          <br />
          {recaptcha}
          <br />
          <div className="employees-table__button-group">
            {canDestroyEmployee &&
              <ButtonComponent
                isRed
                isDisabled={selectedCount === 0}
                onClick={this.handleDelete}
              >
                {translate('employee.buttons.deleteTeamMember.body', { count: selectedCount })}
              </ButtonComponent>
            }
            {canInviteEmployee &&
              <ButtonComponent
                isBlue
                isDisabled={selectedCount === 0}
                onClick={this.handleSendInvitation}
              >
                {translate('employee.buttons.inviteTeamMember.body', { count: selectedCount })}
              </ButtonComponent>
            }
          </div>
        </div>
      </>
    );
  }
}


export default EmployeesComponent;
