import React from 'react';

import { brandChartMetricsShape } from 'common/propTypesShapes/metrics';
import { translate } from 'common/i18n';

import LineChart from 'components_linkio/LineChart';

import buildChartDataWithMetrics from 'utils/buildChartDataWithMetrics';

import './rankTrackerCharts.scss';

const RankTrackerChartsComponent = ({ brandChartMetrics }) => {
  const averageKeywordsRankChartData = buildChartDataWithMetrics(brandChartMetrics, [
    'averageKeywordsRank',
  ]);

  return (
    <div className="rank-tracker-charts">
      <div className="rank-tracker-charts__charts-wrapper">
        <LineChart
          appModule="backlinks"
          chartData={averageKeywordsRankChartData}
          leftYAxisReversed
          link={brandChartMetrics.link}
          title={translate('chart.titles.averageKeywordsRank')}
        />
      </div>
    </div>
  );
};

RankTrackerChartsComponent.propTypes = {
  brandChartMetrics: brandChartMetricsShape,
};

export default RankTrackerChartsComponent;
