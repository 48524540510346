export const CREATE_EO_INBOX__FAILURE = 'CREATE_EO_INBOX__FAILURE';
export const CREATE_EO_INBOX__START = 'CREATE_EO_INBOX__START';
export const CREATE_EO_INBOX__SUCCESS = 'CREATE_EO_INBOX__SUCCESS';
export const CREATE_GMAIL_EO_INBOX__FAILURE = 'CREATE_GMAIL_EO_INBOX__FAILURE';
export const DELETE_EO_INBOX__FAILURE = 'DELETE_EO_INBOX__FAILURE';
export const DELETE_EO_INBOX__START = 'DELETE_EO_INBOX__START';
export const DELETE_EO_INBOX__SUCCESS = 'DELETE_EO_INBOX__SUCCESS';
export const FETCH_EO_INBOX__FAILURE = 'FETCH_EO_INBOX__FAILURE';
export const FETCH_EO_INBOX__START = 'FETCH_EO_INBOX__START';
export const FETCH_EO_INBOX__SUCCESS = 'FETCH_EO_INBOX__SUCCESS';
export const FETCH_EO_INBOXES__FAILURE = 'FETCH_EO_INBOXES__FAILURE';
export const FETCH_EO_INBOXES__START = 'FETCH_EO_INBOXES__START';
export const FETCH_EO_INBOXES__SUCCESS = 'FETCH_EO_INBOXES__SUCCESS';
export const FETCH_EO_INBOXES_SETTINGS__FAILURE = 'FETCH_EO_INBOXES_SETTINGS__FAILURE';
export const FETCH_EO_INBOXES_SETTINGS__START = 'FETCH_EO_INBOXES_SETTINGS__START';
export const FETCH_EO_INBOXES_SETTINGS__SUCCESS = 'FETCH_EO_INBOXES_SETTINGS__SUCCESS';
export const UPDATE_EO_INBOX__FAILURE = 'UPDATE_EO_INBOX__FAILURE';
export const UPDATE_EO_INBOX__START = 'UPDATE_EO_INBOX__START';
export const UPDATE_EO_INBOX__SUCCESS = 'UPDATE_EO_INBOX__SUCCESS';
export const UPDATE_EO_INBOXES_SETTINGS__FAILURE = 'UPDATE_EO_INBOXES_SETTINGS__FAILURE';

export function createEoInboxFailure(errors) {
  return { type: CREATE_EO_INBOX__FAILURE, errors };
}

export function createEoInboxStart() {
  return { type: CREATE_EO_INBOX__START };
}

export function createEoInboxSuccess(eoInbox) {
  return { type: CREATE_EO_INBOX__SUCCESS, eoInbox };
}

export function connectGmailEoInboxFailure(errors) {
  return { type: CREATE_GMAIL_EO_INBOX__FAILURE, errors };
}

export function deleteEoInboxFailure(errors) {
  return { type: DELETE_EO_INBOX__FAILURE, errors };
}

export function deleteEoInboxStart() {
  return { type: DELETE_EO_INBOX__START };
}

export function deleteEoInboxSuccess(eoInboxId) {
  return { type: DELETE_EO_INBOX__SUCCESS, eoInboxId };
}

export function fetchEoInboxFailure(errors) {
  return { type: FETCH_EO_INBOX__FAILURE, errors };
}

export function fetchEoInboxStart() {
  return { type: FETCH_EO_INBOX__START };
}

export function fetchEoInboxSuccess(eoInbox) {
  return { type: FETCH_EO_INBOX__SUCCESS, eoInbox };
}

export function fetchEoInboxesFailure(errors) {
  return { type: FETCH_EO_INBOXES__FAILURE, errors };
}

export function fetchEoInboxesStart() {
  return { type: FETCH_EO_INBOXES__START };
}

export function fetchEoInboxesSuccess(eoInboxes) {
  return { type: FETCH_EO_INBOXES__SUCCESS, eoInboxes };
}

export function fetchEoInboxesSettingsFailure(errors) {
  return { type: FETCH_EO_INBOXES_SETTINGS__FAILURE, errors };
}

export function fetchEoInboxesSettingsStart() {
  return { type: FETCH_EO_INBOXES_SETTINGS__START };
}

export function fetchEoInboxesSettingsSuccess(eoInboxesSettings) {
  return { type: FETCH_EO_INBOXES_SETTINGS__SUCCESS, eoInboxesSettings };
}

export function updateEoInboxFailure(errors) {
  return { type: UPDATE_EO_INBOX__FAILURE, errors };
}

export function updateEoInboxStart() {
  return { type: UPDATE_EO_INBOX__START };
}

export function updateEoInboxSuccess(eoInbox) {
  return { type: UPDATE_EO_INBOX__SUCCESS, eoInbox };
}

export function updateEoInboxesSettingsFailure(errors) {
  return { type: UPDATE_EO_INBOXES_SETTINGS__FAILURE, errors };
}
