import React from 'react';
import PropTypes from 'prop-types';
import { camelCase, snakeCase, isEmpty } from 'lodash';
import { Map as iMap } from 'immutable';

import { optionsMap, userShape, permissionShape } from '../../../common/prop_types_shapes';

import Select from '../../../components_linkio/Select';


const UserPermissionsComponent = ({
  currentUser,
  permissionOptions,
  somethingIsChanging,
  userPermissions,
  onChangePermissions,
}) => {
  function handleSelectChange(permissionsFromSelect) {
    function processPermission(permissionItem) {
      return snakeCase(permissionItem.value);
    }
    const processedPermissions = permissionsFromSelect.map(processPermission).join(',');

    onChangePermissions(processedPermissions);
  }

  function permissionsListToOptions(permissions) {
    const options = [];
    for (const permission of permissions) {
      const camelizedPermission = camelCase(permission);
      const option = permissionOptions.get(camelizedPermission, iMap()).toJS();

      !isEmpty(option) && options.push({ ...option });
    }
    return options;
  }

  function isDisabled() {
    const isOwner = userPermissions.get('isOwner');
    const userId = userPermissions.get('userId');
    const isCurrentUser = currentUser.get('id') === userId;

    return isOwner || isCurrentUser || somethingIsChanging;
  }

  const userPermissionsList = userPermissions.get('permissionsList');
  const value = permissionsListToOptions(userPermissionsList);

  return (
    <Select
      closeMenuOnSelect={false}
      isDisabled={isDisabled()}
      isMulti
      onChange={handleSelectChange}
      options={permissionOptions.valueSeq().toJS()}
      triggerChangeOnBlur={false}
      value={value}
    />
  );
};


UserPermissionsComponent.propTypes = {
  currentUser: userShape,
  onChangePermissions: PropTypes.func,
  permissionOptions: optionsMap,
  somethingIsChanging: PropTypes.bool,
  userPermissions: permissionShape,
};


export default UserPermissionsComponent;
