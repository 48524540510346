import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoDomainsStart,
  fetchEoDomainsSuccess,
  fetchEoDomainsFailure,
  fetchEoDomainStart,
  fetchEoDomainSuccess,
  fetchEoDomainFailure,
  createEoDomainStart,
  createEoDomainSuccess,
  createEoDomainFailure,
  updateEoDomainStart,
  updateEoDomainSuccess,
  updateEoDomainFailure,
  deleteEoDomainsStart,
  deleteEoDomainsSuccess,
  deleteEoDomainsFailure,
} from 'actions/eoDomainActions';

import { resetSelectedIds } from 'actions/ui_actions';
import { fetchEoDomainsPagySuccess } from 'actions/pagy_actions';

import { translate } from 'common/i18n';

import { showSuccessMessage, showSuccessMessageFromTranslation } from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCase,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoDomains(dispatch, params) {
  dispatch(fetchEoDomainsStart());

  try {
    const resp = await getFromAPI(
      Routes.eo_domains_path(),
      processObjToSnakeCaseDeep(params),
    );
    dispatch(fetchEoDomainsSuccess(resp.eo_domains));
    dispatch(fetchEoDomainsPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoDomainsFailure,
      translate('eoDomain.messages.cantFetchEoDomains'),
    );
  }
}

export async function fetchEoDomain(dispatch, eoDomainId) {
  dispatch(fetchEoDomainStart());

  try {
    const eoDomain = await getFromAPI(Routes.eo_domain_path({ id: eoDomainId }));
    dispatch(fetchEoDomainSuccess(eoDomain));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoDomainFailure,
      translate('eoDomain.messages.cantFetchEoDomain'),
    );
  }
}

export async function createEoDomain(dispatch, data) {
  dispatch(createEoDomainStart());

  try {
    const resp = await postToAPI(Routes.eo_domains_path(), eoDomainObject(data));

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(createEoDomainSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoDomain.messages.createSuccess');
    } else {
      dispatch(createEoDomainFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createEoDomainFailure,
      translate('eoDomain.messages.cantCreateEoDomain'),
    );
  }
}

export async function updateEoDomain(dispatch, data) {
  dispatch(updateEoDomainStart());

  try {
    const resp = await patchToAPI(Routes.eo_domain_path({ id: data.id }), eoDomainObject(data));

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(updateEoDomainSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoDomain.messages.updateSuccess');
    } else {
      dispatch(updateEoDomainFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoDomainFailure,
      translate('eoDomain.messages.cantUpdateEoDomain'),
    );
  }
}

export async function deleteEoDomains(dispatch, ids) {
  dispatch(deleteEoDomainsStart());

  try {
    await deleteToAPI(Routes.eo_domains_path({ ids }));

    dispatch(resetSelectedIds());
    dispatch(deleteEoDomainsSuccess(ids));

    showSuccessMessage(dispatch, translate('eoDomain.messages.deleteSuccess')(ids.length));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoDomainsFailure,
      translate('eoDomain.messages.cantDeleteEoDomains'),
    );
  }
}

const eoDomainObject = (data) => ({ eo_domain: processObjToSnakeCase(data) });
