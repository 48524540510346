import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PagesBulkActionsComponent from './PagesBulkActionsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { brandPagesMap, policiesShape } from 'common/prop_types_shapes';
import { currentIdSelector, pagesByParentSelector } from 'selectors';
import { openPagesExportToCsvPopup } from 'actions/popup_actions';
import { policiesSelector } from 'selectors/railsContextSelectors';

const PagesBulkActionsContainer = ({
  brandId,
  changeQueryParams,
  dispatch,
  history,
  pageChildType,
  pageParentId,
  pageParentType,
  pages,
  policies,
  queryParams,
}) => {
  function handleClickExport() {
    dispatch(
      openPagesExportToCsvPopup({
        pageChildType,
        pageParentId,
        pageParentType,
        pages,
      }),
    );
  }

  function handlePageCreateButtonClick() {
    const path = Routes.brand_page_tab_path(brandId, pageParentId, 'bulk_competitors_setup', {
      pagesFilter: queryParams.pagesFilter,
      format: null,
    });

    history.push(path);
  }

  function handleSubmitSearch(value) {
    const newSearchQueryParams = {
      pageNumber: 1,
      search: value,
    };

    if (pageChildType === 'competitorsPage') {
      changeQueryParams({
        ...queryParams,
        ...newSearchQueryParams,
      });

      return;
    }

    changeQueryParams({
      ...queryParams,
      pagesFilter: {
        ...queryParams.pagesFilter,
        ...newSearchQueryParams,
      },
    });
  }

  const hasPages = pages.size > 0;
  const searchTerm =
    pageChildType === 'competitorsPage'
      ? queryParams.search
      : (queryParams.pagesFilter || {}).search;

  return (
    <PagesBulkActionsComponent
      hasPages={hasPages}
      onClickExport={handleClickExport}
      onPageCreateButtonClick={handlePageCreateButtonClick}
      onSubmitSearch={handleSubmitSearch}
      pageChildType={pageChildType}
      policies={policies}
      searchTerm={searchTerm}
    />
  );
};

PagesBulkActionsContainer.propTypes = {
  brandId: PropTypes.string,
  dispatch: PropTypes.func,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  pages: brandPagesMap,
  policies: policiesShape,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const pages = pagesByParentSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);

  return {
    brandId,
    pages,
    policies,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(PagesBulkActionsContainer)));
