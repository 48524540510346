import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

const ChangePercentagesButtonComponent = ({ onChangePercentagesButtonClick }) => {
  return (
    <ButtonComponent isBlue onClick={onChangePercentagesButtonClick}>
      {translate('brandPagePercentage.buttons.changeIdealPercentages')}
    </ButtonComponent>
  );
};

ChangePercentagesButtonComponent.propTypes = {
  onChangePercentagesButtonClick: PropTypes.func.isRequired,
};

export default ChangePercentagesButtonComponent;
