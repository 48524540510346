import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { translate } from 'common/i18n';

import TabsComponent, { TabItemComponent } from 'components_linkio/tabs_component';

import { activeEoCampaignTabString } from 'common/propTypesShapes/eoCampaigns';

import './eoCampaignTabs.scss';

const EO_CAMPAIGN_TABS = [
  'overview',
  'eo_progress',
  'eo_sequences',
  'prospects',
  'eo_inboxes',
  'eo_scheduler',
  'eo_messages',
  'options',
  'eo_campaign_preview',
];

const EoCampaignTabsComponent = ({ activeEoCampaignTab, onTabClick }) => {
  const eoModuleTabsStyles = cx('eo-module-tabs', {
    'eo-module-tabs_no-bottom-margin': activeEoCampaignTab === 'eo_sequences',
  });

  return (
    <TabsComponent className={eoModuleTabsStyles}>
      {EO_CAMPAIGN_TABS.map((tab) => (
        <TabItemComponent
          key={tab}
          index={tab}
          label={translate(`eoCampaign.tabs.${tab}`)}
          onClick={onTabClick(tab)}
          options={{ active: tab === activeEoCampaignTab }}
        />
      ))}
    </TabsComponent>
  );
};

EoCampaignTabsComponent.propTypes = {
  activeEoCampaignTab: activeEoCampaignTabString,
  onTabClick: PropTypes.func,
};

export default EoCampaignTabsComponent;
