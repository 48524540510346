import React from 'react';
import PropTypes from 'prop-types';

import LabelComponent from '../label_component';
import MessageComponent from '../message_component';

import './textarea_component.scss';


const TextAreaComponent = ({
  label,
  isMandatory,
  message,
  explanationMessage,
  ...textareaProps
}) => {
  const textArea = (
    <textarea
      className="linkio-textarea-component__textarea"
      {...textareaProps}
    />
  );
  const textAreaWithLabel = (
    <LabelComponent text={label} isMandatory={isMandatory} explanationMessage={explanationMessage}>
      {textArea}
    </LabelComponent>
  );

  return (
    <div className="linkio-textarea-component">
      {label ? textAreaWithLabel : textArea}
      {message && (message.type || message.text) && <MessageComponent message={message} />}
    </div>
  );
};

TextAreaComponent.propTypes = {
  explanationMessage: PropTypes.string,
  isMandatory: PropTypes.bool,
  label: PropTypes.string,
  message: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    type: PropTypes.oneOf(['success', 'error', 'hint']),
  }),
};

export default TextAreaComponent;
