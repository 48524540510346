import React from 'react';

const PlayIcon = (props) => (
  <svg width={16} height={16} fill="none" {...props}>
    <circle cx={8} cy={8} r={7.5} stroke="currentColor" />
    <path d="M11 8l-4.5 3.464V4.536L11 8z" fill="currentColor" />
  </svg>
);

export default PlayIcon;
