import { isArray, isObject, mapValues } from 'lodash';

import { processObjToCamelCase } from '../utils';

export default function processObjToCamelCaseDeep(object) {
  if (!isObject(object)) {
    return object;
  }

  const camelCasedObject = processObjToCamelCase(object);

  return mapValues(camelCasedObject, (v) => {
    if (isArray(v)) {
      return v.map((element) => {
        return processObjToCamelCaseDeep(element);
      });
    }

    if (isObject(v)) {
      return processObjToCamelCaseDeep(v);
    }

    return v;
  });
}
