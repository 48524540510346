/* Action Types */
export const FETCH_EO_TO_DOS_PAGY__SUCCESS = 'FETCH_EO_TO_DOS_PAGY__SUCCESS';
export const FETCH_EO_ACTIVE_HOURS_PAGY__SUCCESS = 'FETCH_EO_ACTIVE_HOURS_PAGY__SUCCESS';
export const FETCH_EO_EMAIL_TEMPLATES_PAGY__SUCCESS = 'FETCH_EO_EMAIL_TEMPLATES_PAGY__SUCCESS';
export const FETCH_EO_CUSTOM_FIELDS_PAGY__SUCCESS = 'FETCH_EO_CUSTOM_FIELDS_PAGY__SUCCESS';
export const FETCH_EO_MESSAGES_PAGY__SUCCESS = 'FETCH_EO_MESSAGES_PAGY__SUCCESS';
export const FETCH_EO_SCHEDULERS_PAGY__SUCCESS = 'FETCH_EO_SCHEDULERS_PAGY__SUCCESS';
export const FETCH_EO_DRIP_LISTS_PAGY__SUCCESS = 'FETCH_EO_DRIP_LISTS_PAGY__SUCCESS';
export const FETCH_EO_DOMAINS_PAGY__SUCCESS = 'FETCH_EO_DOMAINS_PAGY__SUCCESS';
export const FETCH_EO_BLACKLISTED_DOMAINS_PAGY__SUCCESS =
  'FETCH_EO_BLACKLISTED_DOMAINS_PAGY__SUCCESS';
export const FETCH_EO_PROGRESSIONS_PAGY__SUCCESS = 'FETCH_EO_PROGRESSIONS_PAGY__SUCCESS';
export const FETCH_EO_CAMPAIGNS_PAGY__SUCCESS = 'FETCH_EO_CAMPAIGNS_PAGY__SUCCESS';
export const FETCH_ATP_PAGY__SUCCESS = 'FETCH_ATP_PAGY__SUCCESS';
export const FETCH_BACKLINKS_PAGY__SUCCESS = 'FETCH_BACKLINKS_PAGY__SUCCESS';
export const FETCH_BULK_PAGES_SETUP_PAGY__SUCCESS = 'FETCH_BULK_PAGES_SETUP_PAGY__SUCCESS';
export const FETCH_CHANGES_HISTORY_PAGY__SUCCESS = 'FETCH_CHANGES_HISTORY_PAGY__SUCCESS';
export const FETCH_IMPERSONATES_PAGY__SUCCESS = 'FETCH_IMPERSONATES_PAGY__SUCCESS';
export const FETCH_NOTIFICATIONS_PAGY__SUCCESS = 'FETCH_NOTIFICATIONS_PAGY__SUCCESS';
export const FETCH_PAGES_PAGY__SUCCESS = 'FETCH_PAGES_PAGY__SUCCESS';
export const FETCH_PROSPECTS_PAGY__SUCCESS = 'FETCH_PROSPECTS_PAGY__SUCCESS';

/* Actions */
export function fetchEoToDosPagySuccess(pagy) {
  return { type: FETCH_EO_TO_DOS_PAGY__SUCCESS, pagy };
}

export function fetchEoActiveHoursPagySuccess(pagy) {
  return { type: FETCH_EO_ACTIVE_HOURS_PAGY__SUCCESS, pagy };
}

export function fetchEoEmailTemplatesPagySuccess(pagy) {
  return { type: FETCH_EO_EMAIL_TEMPLATES_PAGY__SUCCESS, pagy };
}

export function fetchEoCustomFieldsPagySuccess(pagy) {
  return { type: FETCH_EO_CUSTOM_FIELDS_PAGY__SUCCESS, pagy };
}

export function fetchEoMessagesPagySuccess(pagy) {
  return { type: FETCH_EO_MESSAGES_PAGY__SUCCESS, pagy };
}

export function fetchEoSchedulersPagySuccess(pagy) {
  return { type: FETCH_EO_SCHEDULERS_PAGY__SUCCESS, pagy };
}

export function fetchEoDripListsPagySuccess(pagy) {
  return { type: FETCH_EO_DRIP_LISTS_PAGY__SUCCESS, pagy };
}

export function fetchEoDomainsPagySuccess(pagy) {
  return { type: FETCH_EO_DOMAINS_PAGY__SUCCESS, pagy };
}

export function fetchEoBlacklistedDomainsPagySuccess(pagy) {
  return { type: FETCH_EO_BLACKLISTED_DOMAINS_PAGY__SUCCESS, pagy };
}

export function fetchEoProgressionsPagySuccess(pagy) {
  return { type: FETCH_EO_PROGRESSIONS_PAGY__SUCCESS, pagy };
}

export function fetchEoCampaignsPagySuccess(pagy) {
  return { type: FETCH_EO_CAMPAIGNS_PAGY__SUCCESS, pagy };
}

export function fetchAtpPagySuccess(brandPageId, pagy) {
  return { type: FETCH_ATP_PAGY__SUCCESS, brandPageId, pagy };
}

export function fetchBacklinksPagySuccess(brandId, pagy) {
  return { type: FETCH_BACKLINKS_PAGY__SUCCESS, brandId, pagy };
}

export function fetchBulkPagesSetupPagySuccess(brandId, pagy) {
  return { type: FETCH_BULK_PAGES_SETUP_PAGY__SUCCESS, brandId, pagy };
}

export function fetchImpersonatesPagySuccess(pagy) {
  return { type: FETCH_IMPERSONATES_PAGY__SUCCESS, pagy };
}

export function fetchNotificationsPagySuccess(pagy) {
  return { type: FETCH_NOTIFICATIONS_PAGY__SUCCESS, pagy };
}

export function fetchPagesPagySuccess(brandId, pagy) {
  return { type: FETCH_PAGES_PAGY__SUCCESS, brandId, pagy };
}

export function fetchChangesHistoryPagySuccess(brandId, pagy) {
  return { type: FETCH_CHANGES_HISTORY_PAGY__SUCCESS, brandId, pagy };
}

export function fetchProspectsPagySuccess(pagy) {
  return { type: FETCH_PROSPECTS_PAGY__SUCCESS, pagy };
}
