import React, { Component } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConnectStoreHOC from '../../../startup/connect_store_hoc';

import { createMarketer } from '../../../api/marketer';
import { fetchRailsContext } from '../../../api/rails_context';
import { clearErrors, trackHelpcrunchEvent } from '../../../common/utils';
import NewMarketerFormComponent from '../../../components/auth/new_marketer_form_component';

class NewMarketerPage extends Component {
  static propTypes = {
    createMarketerIsLoading: PropTypes.bool,
  };
  constructor(props) {
    super(props);

    const processedProps = this.processProps();

    this.state = {
      error: null,
      errors: {},
      processedProps,
    };
  }

  handleCreateMarketerAction = async (title) => {
    const { dispatch, history } = this.props;

    this.setState(clearErrors(this.state));

    const resp = await createMarketer(dispatch, { title });
    const { type, errors, message } = resp;

    if (type === 'error' || !isEmpty(errors)) {
      this.setState({ errors, error: message });
    } else {
      trackHelpcrunchEvent('add.marketer');
      await fetchRailsContext(dispatch);
      history.push(Routes.root_path({ format: null }));
    }
  }

  processProps = () => {
    const processedProps = cloneDeep(this.props);

    processedProps.handleCreateMarketerClickAction = this.handleCreateMarketerAction;

    return processedProps;
  }

  render() {
    const { processedProps, errors, error } = this.state;
    const { createMarketerIsLoading } = this.props;

    return (
      <NewMarketerFormComponent {...processedProps} errors={errors} error={error} createMarketerIsLoading={createMarketerIsLoading} />
    );
  }
}

function select(state, _ownProps) {
  const createMarketerIsLoading = state.getIn(['ui', 'loading', 'createMarketer']);

  return { createMarketerIsLoading };
}

export default ConnectStoreHOC(connect(select)(NewMarketerPage));
