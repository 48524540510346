import { handleAPIError, getFromAPI, processObjToSnakeCaseDeep } from './utils';

import { translate } from 'common/i18n';

import {
  fetchChangesHistoryFailure,
  fetchChangesHistoryStart,
  fetchChangesHistorySuccess,
} from 'actions/changesHistoryActions';

import { fetchChangesHistoryPagySuccess } from 'actions/pagy_actions';

export async function fetchChangesHistory(dispatch, brandId, options = {}) {
  dispatch(fetchChangesHistoryStart());

  const { pagyItemsCount: items, pageNumber: page, sorting, search } = options;
  const params = { items, page, sorting, search };

  try {
    const resp = await getFromAPI(
      Routes.changes_backlink_path({ brand_id: brandId }),
      processObjToSnakeCaseDeep(params),
    );
    dispatch(fetchChangesHistorySuccess(resp.backlink_changes, brandId));
    dispatch(fetchChangesHistoryPagySuccess(brandId, resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchChangesHistoryFailure,
      translate('errors.cantFetchChangesHistory'),
    );
  }
}
