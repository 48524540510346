import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { messageIsEmpty } from '../message_utils';
import MessageComponent from '../message_component';

import './checkbox.scss';

const CheckboxComponent = React.forwardRef(
  ({ children, className, message, ...inputProps }, ref) => {
    const checkboxStyles = cx('custom-checkbox', className, {
      'custom-checkbox_with-message': !messageIsEmpty(message),
    });

    return (
      <>
        <label className={checkboxStyles}>
          <span className="custom-checkbox__label-content">{children}</span>

          <input className="custom-checkbox__input" type="checkbox" ref={ref} {...inputProps} />

          <span className="custom-checkbox__checkmark" />
          {!messageIsEmpty(message) && <MessageComponent message={message} />}
        </label>
      </>
    );
  },
);

CheckboxComponent.displayName = 'CheckboxComponent';

CheckboxComponent.propTypes = {
  checkboxProps: PropTypes.shape({
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
  ]),
  className: PropTypes.string,
  message: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    type: PropTypes.oneOf(['success', 'error', 'hint']),
  }),
};

export default CheckboxComponent;
