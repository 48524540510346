import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ManuallyImportPagesButtonComponent from './ManuallyImportPagesButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { openManuallyImportPagesPopup } from 'actions/popup_actions';

const ManuallyImportPagesButtonContainer = ({ dispatch }) => {
  function handleClick() {
    dispatch(openManuallyImportPagesPopup());
  }

  return <ManuallyImportPagesButtonComponent onClick={handleClick} />;
};

ManuallyImportPagesButtonContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default ConnectStoreHOC(connect()(ManuallyImportPagesButtonContainer));
