export default {
  importToBrandPage: {
    categorize: 'Categorize imported anchor text',
    csvHintMessage: {
      firstPart: `The .CSV file you upload needs to include "Link Anchor" and "Referring Page URL" columns.
      Download this sample `,
      secondPart: ' for reference.',
      csvTemplateLink: '.CSV template',
    },
    connectAhrefsAccountLinkText: "Connect your Ahrefs account",
    dateOfLatestImportIsUnknown: "We'll import all the links from Ahrefs for you",
    latestImport: "Last import: ",
    publishedLinks: "Published links",
    publishedLinksHint: "We automatically remove duplicates. Add one per line.",
    title: "Import",
    buttons: {
      cancel: "Cancel",
      import: "Import",
    },
    opts: {
      addPublishedLinks: { label: "Manually add new published backlinks" },
      importCSV: { label: "Import .csv File" },
      importFromAhrefs: {
        label: "Import backlinks via Ahrefs now",
        warningFree: "You have a FREE Ahrefs account so API importing will not work. \
          Upgrade to a paid Ahrefs account to enable this feature.",
        warningLimit: "Your Ahrefs monthly backlink import limit has been reached. \
          Try again after your monthly cycle renews or upgrade your plan",
      },
      importFromMoz: { label: "Import backlinks via Moz now" },
    },
    canUseCategorizerMessage: 'As a Premium user, all of your imported anchor text will be categorized and analyzed.',
  },
};
