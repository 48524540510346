export const CREATE_EO_DOMAIN__FAILURE = 'CREATE_EO_DOMAIN__FAILURE';
export const CREATE_EO_DOMAIN__START = 'CREATE_EO_DOMAIN__START';
export const CREATE_EO_DOMAIN__SUCCESS = 'CREATE_EO_DOMAIN__SUCCESS';
export const DELETE_EO_DOMAINS__FAILURE = 'DELETE_EO_DOMAINS__FAILURE';
export const DELETE_EO_DOMAINS__START = 'DELETE_EO_DOMAINS__START';
export const DELETE_EO_DOMAINS__SUCCESS = 'DELETE_EO_DOMAINS__SUCCESS';
export const FETCH_EO_DOMAINS__FAILURE = 'FETCH_EO_DOMAINS__FAILURE';
export const FETCH_EO_DOMAINS__START = 'FETCH_EO_DOMAINS__START';
export const FETCH_EO_DOMAINS__SUCCESS = 'FETCH_EO_DOMAINS__SUCCESS';
export const FETCH_EO_DOMAIN__FAILURE = 'FETCH_EO_DOMAIN__FAILURE';
export const FETCH_EO_DOMAIN__START = 'FETCH_EO_DOMAIN__START';
export const FETCH_EO_DOMAIN__SUCCESS = 'FETCH_EO_DOMAIN__SUCCESS';
export const UPDATE_EO_DOMAIN__FAILURE = 'UPDATE_EO_DOMAIN__FAILURE';
export const UPDATE_EO_DOMAIN__START = 'UPDATE_EO_DOMAIN__START';
export const UPDATE_EO_DOMAIN__SUCCESS = 'UPDATE_EO_DOMAIN__SUCCESS';

export function createEoDomainStart() {
  return { type: CREATE_EO_DOMAIN__START };
}

export function createEoDomainSuccess(eoDomain) {
  return { type: CREATE_EO_DOMAIN__SUCCESS, eoDomain };
}

export function createEoDomainFailure(errors) {
  return { type: CREATE_EO_DOMAIN__FAILURE, errors };
}

export function deleteEoDomainsStart() {
  return { type: DELETE_EO_DOMAINS__START };
}

export function deleteEoDomainsSuccess(ids) {
  return { type: DELETE_EO_DOMAINS__SUCCESS, ids };
}

export function deleteEoDomainsFailure(errors) {
  return { type: DELETE_EO_DOMAINS__FAILURE, errors };
}

export function fetchEoDomainsStart() {
  return { type: FETCH_EO_DOMAINS__START };
}

export function fetchEoDomainsSuccess(eoDomains) {
  return { type: FETCH_EO_DOMAINS__SUCCESS, eoDomains };
}

export function fetchEoDomainsFailure(errors) {
  return { type: FETCH_EO_DOMAINS__FAILURE, errors };
}

export function fetchEoDomainStart() {
  return { type: FETCH_EO_DOMAIN__START };
}

export function fetchEoDomainSuccess(eoDomain) {
  return { type: FETCH_EO_DOMAIN__SUCCESS, eoDomain };
}

export function fetchEoDomainFailure(errors) {
  return { type: FETCH_EO_DOMAIN__FAILURE, errors };
}

export function updateEoDomainStart() {
  return { type: UPDATE_EO_DOMAIN__START };
}

export function updateEoDomainSuccess(eoDomain) {
  return { type: UPDATE_EO_DOMAIN__SUCCESS, eoDomain };
}

export function updateEoDomainFailure(errors) {
  return { type: UPDATE_EO_DOMAIN__FAILURE, errors };
}
