import React from 'react';
import PropTypes from 'prop-types';

import { selectedIdsList } from 'common/prop_types_shapes';

import BinIcon from 'common/icons/bin';
import ButtonComponent from 'components_linkio/button_component';

const DeleteEoProgressionsButtonComponent = ({ onDelete, selectedIds }) => {
  return (
    <ButtonComponent isDisabled={selectedIds.size === 0} isInitial isRed onClick={onDelete}>
      <BinIcon />
    </ButtonComponent>
  );
};

DeleteEoProgressionsButtonComponent.propTypes = {
  onDelete: PropTypes.func.isRequired,
  selectedIds: selectedIdsList,
};

export default DeleteEoProgressionsButtonComponent;
