import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Map as iMap } from 'immutable';

import EoToDosTasks from './EoToDosTasks';
import EoToDoDetails from './EoToDoDetails';
import EoToDosEmailArea from './EoToDosEmailArea';
import EoToDosBulkActions from './EoToDosBulkActions';

import { eoToDosList } from 'common/propTypesShapes/eoToDos';
import { optionsList, pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';

import './eoToDosPage.scss';

const EoToDosPageComponent = ({
  eoToDos,
  immutableUsersOptions,
  onItemsSelectChange,
  onPagesPagerClick,
  eoToDosPagy,
}) => {
  const [selectedEoToDoId, setSelectedEoToDoId] = useState(null);

  React.useEffect(() => {
    function dropSelectedEoToDoIdIfNotInList() {
      const isSelectedEoToDoInCurrentList = eoToDos.some(
        (eoToDo) => eoToDo.get('id') === selectedEoToDoId,
      );

      !isSelectedEoToDoInCurrentList && setSelectedEoToDoId(null);
    }

    selectedEoToDoId && dropSelectedEoToDoIdIfNotInList();
  }, [JSON.stringify(eoToDos)]);

  const detailsData = eoToDos.find((eoToDo) => eoToDo.get('id') === selectedEoToDoId) || iMap();
  const currentEoToDoProspectId = detailsData.get('prospectId', null);
  const currentEoToDoEoCampaignId = detailsData.get('eoCampaignId', null);
  const currentEoCampaignStepPosition = detailsData.get('eoCampaignStepPosition', null);

  function handleSetSelectedEoToDoId(eoToDoId) {
    return () => setSelectedEoToDoId(eoToDoId);
  }

  return (
    <div className="eo-to-dos-page">
      <EoToDosBulkActions immutableUsersOptions={immutableUsersOptions} />

      <div className="eo-to-dos-page__columns-wrapper">
        <EoToDosTasks
          immutableEoToDos={eoToDos}
          onClickEoToDo={handleSetSelectedEoToDoId}
          onItemsSelectChange={onItemsSelectChange}
          onPagesPagerClick={onPagesPagerClick}
          eoToDosPagy={eoToDosPagy}
          selectedEoToDoId={selectedEoToDoId}
        />

        <EoToDosEmailArea
          immutableUsersOptions={immutableUsersOptions}
          setSelectedEoToDoId={setSelectedEoToDoId}
          selectedEoToDoId={selectedEoToDoId}
        />

        <EoToDoDetails
          currentEoToDoProspectId={currentEoToDoProspectId}
          currentEoToDoEoCampaignId={currentEoToDoEoCampaignId}
          currentEoCampaignStepPosition={currentEoCampaignStepPosition}
          selectedEoToDoId={selectedEoToDoId}
        />
      </div>

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="rowsPager"
        pagy={eoToDosPagy}
      />
    </div>
  );
};

EoToDosPageComponent.propTypes = {
  eoToDos: eoToDosList,
  eoToDosPagy: pagyShape,
  immutableUsersOptions: optionsList,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoToDosPageComponent;
