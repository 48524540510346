import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EoCampaignEmailTemplatesTabs from './EoCampaignEmailTemplatesTabs';
import EoCampaignEmailTemplatesList from './EoCampaignEmailTemplatesList';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import CrossIcon from 'common/icons/cross';
import { eoEmailTemplatesList } from 'common/propTypesShapes/eoEmailTemplates';

import { optionsList } from 'common/prop_types_shapes';

import './EoCampaignEmailTemplatesPopup.scss';

const getSelectedEmailTemplateText = (eoEmailTemplates, selectedEmailTemplateId) => {
  const selectedEmailTemplate =
    eoEmailTemplates.find((emailTemplate) => emailTemplate.id === selectedEmailTemplateId) || {};

  const { subject = '', body = '' } = selectedEmailTemplate;

  return { selectedEmailTemplateSubject: subject, selectedEmailTemplateBody: body };
};

const EoCampaignEmailTemplatesPopupComponent = ({
  immutableEoEmailTemplateFoldersOptions,
  immutableEoEmailTemplates,
  onClose,
  onSubmit,
  selectedEmailTemplateScope,
  setSelectedEmailTemplateScope,
}) => {
  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState(null);

  const eoEmailTemplates = immutableEoEmailTemplates.toJS();

  const { selectedEmailTemplateSubject, selectedEmailTemplateBody } = getSelectedEmailTemplateText(
    eoEmailTemplates,
    selectedEmailTemplateId,
  );

  function handleSubmit(event) {
    event.preventDefault();

    onSubmit({ subject: selectedEmailTemplateSubject, body: selectedEmailTemplateBody });

    onClose();
  }

  return (
    <Modal show className="eo-campaign-email-templates-popup">
      <Modal.Header>
        <div className="eo-campaign-email-templates-popup__header">
          <Modal.Title className="eo-campaign-email-templates-popup__title">
            {translate('eoCampaignStep.eoCampaignStepEmail.eoCampaignEmailTemplatesPopup.title')}
          </Modal.Title>
          <CrossIcon onClick={onClose} />
        </div>
      </Modal.Header>

      <Modal.Body className="eo-campaign-email-templates-popup__body">
        <div className="eo-campaign-email-templates-popup__templates-list-wrapper">
          <EoCampaignEmailTemplatesTabs
            selectedEmailTemplateScope={selectedEmailTemplateScope}
            setSelectedEmailTemplateId={setSelectedEmailTemplateId}
            setSelectedEmailTemplateScope={setSelectedEmailTemplateScope}
          />
          <EoCampaignEmailTemplatesList
            eoEmailTemplates={eoEmailTemplates}
            immutableEoEmailTemplateFoldersOptions={immutableEoEmailTemplateFoldersOptions}
            selectedEmailTemplateId={selectedEmailTemplateId}
            setSelectedEmailTemplateId={setSelectedEmailTemplateId}
          />
        </div>
        <div className="eo-campaign-email-templates-popup__textarea">
          <div className="eo-campaign-email-templates-popup__textarea-subject">
            {selectedEmailTemplateId && (
              <span className="eo-campaign-email-templates-popup__textarea-subject-title">
                {translate(
                  'eoCampaignStep.eoCampaignStepEmail.eoCampaignEmailTemplatesPopup.textarea.subject',
                )}
              </span>
            )}
            {selectedEmailTemplateSubject}
          </div>
          <div className="eo-campaign-email-templates-popup__textarea-body">
            <div dangerouslySetInnerHTML={{ __html: selectedEmailTemplateBody }} />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="eo-campaign-email-templates-popup__btn-group">
          <div className="eo-campaign-email-templates-popup__btn">
            <ButtonComponent isWhite onClick={onClose}>
              {translate(
                'eoCampaignStep.eoCampaignStepEmail.eoCampaignEmailTemplatesPopup.buttons.cancel',
              )}
            </ButtonComponent>
          </div>

          <div className="eo-campaign-email-templates-popup__btn">
            <SubmitButtonComponent
              isDisabled={!selectedEmailTemplateId}
              isGreen
              onClick={handleSubmit}
              text={translate(
                'eoCampaignStep.eoCampaignStepEmail.eoCampaignEmailTemplatesPopup.buttons.submit',
              )}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EoCampaignEmailTemplatesPopupComponent.propTypes = {
  immutableEoEmailTemplateFoldersOptions: optionsList,
  immutableEoEmailTemplates: eoEmailTemplatesList,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedEmailTemplateScope: PropTypes.string,
  setSelectedEmailTemplateScope: PropTypes.func,
};

export default EoCampaignEmailTemplatesPopupComponent;
