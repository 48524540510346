import React from 'react';

const MenuIcon = (props) => (
  <svg width={16} height={16} {...props}>
    <path
      d="M14.78 0c.675 0 1.198.541 1.22 1.19 0 .65-.545 1.192-1.199 1.192H1.2A1.205 1.205 0 0 1 0 1.19C0 .54.545 0 1.199 0h13.58zm0 13.618c.675 0 1.198.542 1.22 1.191 0 .65-.545 1.191-1.199 1.191H1.2A1.205 1.205 0 0 1 0 14.81c0-.65.545-1.192 1.199-1.192h13.58zM10.964 6.82c.675 0 1.198.52 1.22 1.19 0 .65-.545 1.192-1.199 1.192H1.2A1.205 1.205 0 0 1 0 8.01c0-.65.545-1.19 1.199-1.19h9.766z"
      fill="currentColor"
    />
  </svg>
);

export default MenuIcon;
