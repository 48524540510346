function optionValueOrValue(option) {
  if (!option) {
    return option;
  }

  return option.value || option;
}

function optionsFromImmutable(options) {
  return Object.values(options.toJS());
}

function valueFromImmutable(options, simpleValue) {
  if (!simpleValue || options.size === 0) {
    return void 0;
  }

  const filteredValue = options.find((option) => String(option.get('value')) === String(simpleValue));

  if (!filteredValue) {
    return void 0;
  }

  return filteredValue.toJS();
}


export {
  optionValueOrValue,
  optionsFromImmutable,
  valueFromImmutable,
};
