import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';

import './tutorialPopupComponent.scss';

const DescriptionTitle = ({ children }) => (
  <div className="tutorial-popup__description-title">{children}</div>
);
const DescriptionText = ({ children }) => (
  <div className="tutorial-popup__description-text">{children}</div>
);

const TutorialPopupComponent = ({ children, onClose, videoSrc, title }) => (
  <Modal show className="tutorial-popup">
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div className="tutorial-popup__wrapper">
        <div className="tutorial-popup__video">
          <iframe allowFullScreen frameBorder="0" src={videoSrc} title={title} />
        </div>

        {children && <div className="tutorial-popup__description-wrapper">{children}</div>}
      </div>
    </Modal.Body>

    <Modal.Footer>
      <div className="tutorial-popup__btn-group">
        <div className="tutorial-popup__btn">
          <ButtonComponent isWhite onClick={onClose}>
            {translate('uiComponents.button.cancel')}
          </ButtonComponent>
        </div>
      </div>
    </Modal.Footer>
  </Modal>
);

TutorialPopupComponent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string,
  videoSrc: PropTypes.string,
};

TutorialPopupComponent.DescriptionTitle = DescriptionTitle;
TutorialPopupComponent.DescriptionText = DescriptionText;

export default TutorialPopupComponent;
