import { fromJS, List as iList, Map as iMap } from 'immutable';
import { keyBy, sortBy } from 'lodash';

import { translate } from 'common/i18n';

import { FETCH_ANCHOR_TEXTS__SUCCESS } from 'actions/anchor_text_actions';
import { FETCH_DL_SOURCES__SUCCESS, CREATE_DL_SOURCE__SUCCESS } from 'actions/dl_source_actions';
import { FETCH_BRAND_PAGES_FOR_FILTER_BY_BRAND_ID__SUCCESS } from 'actions/brand_page_actions';
import { FETCH_SIMPLE_BRANDS__SUCCESS } from 'actions/simpleBrandActions';
import { FETCH_EO_CAMPAIGNS__SUCCESS } from 'actions/eoCampaignActions';
import {
  CREATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS,
  FETCH_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS,
  FETCH_EO_CMARKETER_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__SUCCESS,
} from 'actions/eoEmailTemplateFolderActions';

import {
  BULK_UPDATE_PAGE_TYPES__SUCCESS,
  FETCH_PAGE_TYPES__SUCCESS,
} from 'actions/page_type_actions';

import {
  CREATE_PERCENTAGE_SCHEME__SUCCESS,
  DELETE_PERCENTAGE_SCHEME__SUCCESS,
  FETCH_PERCENTAGE_SCHEMES__SUCCESS,
  UPDATE_PERCENTAGE_SCHEME__SUCCESS,
} from 'actions/percentage_scheme_actions';

import {
  FETCH_RANK_TRACKER_COUNTRIES__SUCCESS,
  FETCH_RANK_TRACKER_ENGINES__SUCCESS,
  FETCH_RANK_TRACKER_LANGUAGES__SUCCESS,
  FETCH_RANK_TRACKER_LOCATIONS__SUCCESS,
} from 'actions/rankTrackerSetupActions';

import {
  FETCH_EO_DRIP_LISTS_AS_OPTIONS__SUCCESS,
  CREATE_EO_DRIP_LIST__SUCCESS,
} from 'actions/eoDripListActions';

import { FETCH_USERS_AS_OPTIONS__SUCCESS } from 'actions/user_actions';

import { FETCH_EO_ACTIVE_HOURS_AS_OPTIONS__SUCCESS } from 'actions/eoActiveHourActions';
import { FETCH_TIMEZONES_AS_OPTIONS__SUCCESS } from 'actions/eoSchedulerActions';

const AHREFS_SYNC_INTERVAL_OPTIONS = [
  { label: translate('options.ahrefsSyncInterval.daily.label'), value: 'daily' },
  { label: translate('options.ahrefsSyncInterval.each3Days.label'), value: 'each3Days' },
  { label: translate('options.ahrefsSyncInterval.weekly.label'), value: 'weekly' },
  { label: translate('options.ahrefsSyncInterval.monthly.label'), value: 'monthly' },
];

const COLUMN_TYPE_OPTIONS = [
  { label: 'Choice', value: 'choice' },
  { label: 'Date', value: 'date' },
  { label: 'Number', value: 'number' },
  { label: 'Text', value: 'text' },
  { label: 'User', value: 'user' },
];

const CRAWLING_INTERVAL_OPTIONS = [
  { label: translate('options.crawlingInterval.weekly.label'), value: 'weekly' },
  { label: translate('options.crawlingInterval.monthly.label'), value: 'monthly' },
];

const INCLUDE_BY_USER_OPTIONS = [
  { value: 'yes', label: translate('options.includeByUser.yes.label') },
  { value: 'no', label: translate('options.includeByUser.no.label') },
  { value: 'auto', label: translate('options.includeByUser.auto.label') },
];

const INCLUDED_OPTIONS = [
  { value: 'true', label: translate('options.included.true.label') },
  { value: 'false', label: translate('options.included.false.label') },
];

const KW_DOMAIN_MATCH_OPTIONS = [
  { value: 'exact_match', label: translate('options.kwDomainMatch.exactMatch.label') },
  { value: 'no_match', label: translate('options.kwDomainMatch.noMatch.label') },
  { value: 'partial_match', label: translate('options.kwDomainMatch.partialMatch.label') },
];

const NOFOLLOW_OPTIONS = [
  { value: 'false', label: translate('options.nofollow.false.label') },
  { value: 'true', label: translate('options.nofollow.true.label') },
];

const LINK_STATUS_OPTIONS = [
  { value: 'started', label: translate('options.linkStatus.started.label') },
  { value: 'not_started', label: translate('options.linkStatus.notStarted.label') },
  { value: 'published', label: translate('options.linkStatus.published.label') },
];

const ANCHOR_TYPE_OPTIONS = [
  { value: 'title_tag', label: translate('options.anchorType.titleTag.label') },
  { value: 'exact_keyword', label: translate('options.anchorType.exactKeyword.label') },
  { value: 'just_natural', label: translate('options.anchorType.justNatural.label') },
  { value: 'naked_uri', label: translate('options.anchorType.nakedUri.label') },
  { value: 'keyword_plus_word', label: translate('options.anchorType.keywordPlusWord.label') },
  { value: 'only_part_of_keyword', label: translate('options.anchorType.onlyPartOfKeyword.label') },
  {
    value: 'brand_and_keyword_together',
    label: translate('options.anchorType.brandAndKeywordTogether.label'),
  },
  { value: 'branded', label: translate('options.anchorType.branded.label') },
  { value: 'website_name_com', label: translate('options.anchorType.websiteNameCom.label') },
  { value: 'no_text', label: translate('options.anchorType.noText.label') },
  { value: 'homepage_uri', label: translate('options.anchorType.homepageUri.label') },
  { value: 'naked_uri_wo_http', label: translate('options.anchorType.nakedUriWoHttp.label') },
  { value: 'totally_random', label: translate('options.anchorType.totallyRandom.label') },
];

const PROFIlE_TYPE_OPTIONS = [
  { value: 'admin', label: translate('options.profileType.admin.label') },
  { value: 'pm', label: translate('options.profileType.pm.label') },
  { value: 'assistant', label: translate('options.profileType.assistant.label') },
];

const PERMISSION_OPTIONS = [
  { value: 'admin', label: translate('options.permissions.admin.label') },
  {
    value: 'ahrefsConnectionAdmin',
    label: translate('options.permissions.ahrefsConnectionAdmin.label'),
  },
  { value: 'atpAdmin', label: translate('options.permissions.atpAdmin.label') },
  { value: 'atpManager', label: translate('options.permissions.atpManager.label') },
  { value: 'atpViewer', label: translate('options.permissions.atpViewer.label') },
  { value: 'brandsAdmin', label: translate('options.permissions.brandsAdmin.label') },
  { value: 'brandsManager', label: translate('options.permissions.brandsManager.label') },
  { value: 'brandsViewer', label: translate('options.permissions.brandsViewer.label') },
  {
    value: 'crawlerSettingsAdmin',
    label: translate('options.permissions.crawlerSettingsAdmin.label'),
  },
  { value: 'emailOutreachAdmin', label: translate('options.permissions.emailOutreachAdmin.label') },
  {
    value: 'emailOutreachManager',
    label: translate('options.permissions.emailOutreachManager.label'),
  },
  {
    value: 'emailOutreachViewer',
    label: translate('options.permissions.emailOutreachViewer.label'),
  },
  { value: 'employeeAdmin', label: translate('options.permissions.employeeAdmin.label') },
  { value: 'employeeManager', label: translate('options.permissions.employeeManager.label') },
  { value: 'employeeViewer', label: translate('options.permissions.employeeViewer.label') },
  {
    value: 'employeePermissionsAdmin',
    label: translate('options.permissions.employeePermissionsAdmin.label'),
  },
  {
    value: 'percentageSettingsAdmin',
    label: translate('options.permissions.percentageSettingsAdmin.label'),
  },
  {
    value: 'percentageSettingsManager',
    label: translate('options.permissions.percentageSettingsManager.label'),
  },
  {
    value: 'percentageSettingsViewer',
    label: translate('options.permissions.percentageSettingsViewer.label'),
  },
  { value: 'subscriptionsAdmin', label: translate('options.permissions.subscriptionsAdmin.label') },
];

const PAGE_STATUS_OPTIONS = [
  { value: 'in_atp', label: translate('bulkPagesSetup.table.columns.status.in_atp') },
  { value: 'not_in_atp', label: translate('bulkPagesSetup.table.columns.status.not_in_atp') },
];

const WEBSITE_TYPE_OPTIONS = [
  { value: 'national', label: translate('options.websiteType.national.label') },
  { value: 'local_business', label: translate('options.websiteType.localBusiness.label') },
  { value: 'ecommerce', label: translate('options.websiteType.ecommerce.label') },
  { value: 'software', label: translate('options.websiteType.software.label') },
];

const EO_PROGRESSION_STAGE_OPTIONS = [
  { value: 'rejected', label: translate('eoProgression.table.stage.rejected') },
  { value: 'waiting', label: translate('eoProgression.table.stage.waiting') },
  { value: 'starting', label: translate('eoProgression.table.stage.starting') },
  { value: 'running', label: translate('eoProgression.table.stage.running') },
  { value: 'bounced', label: translate('eoProgression.table.stage.bounced') },
  { value: 'unsubscribed', label: translate('eoProgression.table.stage.unsubscribed') },
  { value: 'canceled', label: translate('eoProgression.table.stage.canceled') },
  { value: 'out_of_office', label: translate('eoProgression.table.stage.out_of_office') },
  { value: 'replied', label: translate('eoProgression.table.stage.replied') },
  { value: 'completed', label: translate('eoProgression.table.stage.completed') },
  { value: 'has_error', label: translate('eoProgression.table.stage.has_error') },
];

const initialState = iMap({
  ahrefsSyncIntervalOptions: fromJS(keyBy(AHREFS_SYNC_INTERVAL_OPTIONS, 'value')),
  anchorTextOptions: iMap(),
  anchorTypeOptions: fromJS(keyBy(ANCHOR_TYPE_OPTIONS, 'value')),
  brandPagesOptions: iMap(),
  brandsOptions: iList(),
  columnTypeOptions: fromJS(keyBy(COLUMN_TYPE_OPTIONS, 'value')),
  crawlingIntervalOptions: fromJS(keyBy(CRAWLING_INTERVAL_OPTIONS, 'value')),
  dlSourcesOptions: iMap(),
  eoActiveHourOptions: iList(),
  eoCampaignsOptions: iList(),
  eoDripListsOptions: iList(),
  eoEmailTemplateFoldersOptions: iList(),
  eoCmarketerEmailTemplateFoldersOptions: iList(),
  eoProgressionStageOptions: fromJS(EO_PROGRESSION_STAGE_OPTIONS),
  includeByUserOptions: fromJS(keyBy(INCLUDE_BY_USER_OPTIONS, 'value')),
  includedOptions: fromJS(keyBy(INCLUDED_OPTIONS, 'value')),
  kwDomainMatchOptions: fromJS(keyBy(KW_DOMAIN_MATCH_OPTIONS, 'value')),
  linkStatusOptions: fromJS(keyBy(LINK_STATUS_OPTIONS, 'value')),
  nofollowOptions: fromJS(keyBy(NOFOLLOW_OPTIONS, 'value')),
  pageStatusOptions: fromJS(keyBy(PAGE_STATUS_OPTIONS, 'value')),
  pageTypeOptions: iMap(),
  percentageSchemeOptions: iMap(),
  permissionOptions: fromJS(keyBy(PERMISSION_OPTIONS, 'value')),
  profileTypeOptions: fromJS(keyBy(PROFIlE_TYPE_OPTIONS, 'value')),
  rankTrackerCountriesOptions: iList(),
  rankTrackerLanguagesOptions: iList(),
  rankTrackerLocationsOptions: iList(),
  searchEngineOptions: iList(),
  timezonesOptions: iList(),
  usersOptions: iList(),
  websiteTypeOptions: fromJS(keyBy(WEBSITE_TYPE_OPTIONS, 'value')),
});

const processAnchorText = (anchorText) => {
  return {
    value: anchorText.value,
    label: anchorText.value,
    count: anchorText.count,
  };
};

const processDlSource = (dlSource) => ({ value: dlSource.title, label: dlSource.title });

const processBrand = (brand) => {
  if (!brand) {
    return void 0;
  }
  return { value: brand.id, label: brand.name };
};

const processPageType = (pageType) => {
  return {
    value: String(pageType.id),
    label: pageType.title,
    defaultPercentageSchemeId: String(pageType.default_percentage_scheme_id),
  };
};

const processPercentageScheme = (percentageScheme) => ({
  brandPageId: percentageScheme.brand_page_id,
  isCompetitors: !!percentageScheme.brand_page_id,
  isCustom: percentageScheme.editable,
  isZero: percentageScheme.is_zero,
  label: percentageScheme.title,
  value: String(percentageScheme.id),
});

const processCommonOption = (option) => ({ value: option, label: option });

const processAsOptionsEntity = (option) => {
  if (!option) {
    return void 0;
  }
  return { value: option.id, label: option.title || option.name };
};

export default function optionsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ANCHOR_TEXTS__SUCCESS: {
      state = state.set(
        'anchorTextOptions',
        fromJS(keyBy(action.anchorTexts.map(processAnchorText), 'value')),
      );

      break;
    }
    case FETCH_DL_SOURCES__SUCCESS: {
      state = state.set(
        'dlSourcesOptions',
        fromJS(keyBy(action.dlSources.map(processDlSource), 'value')),
      );

      break;
    }
    case FETCH_EO_DRIP_LISTS_AS_OPTIONS__SUCCESS: {
      const sortedEoDripListsOptions = sortBy(
        action.eoDripLists.map(processAsOptionsEntity),
        'label',
      );
      state = state.set('eoDripListsOptions', fromJS(sortedEoDripListsOptions));

      break;
    }
    case FETCH_USERS_AS_OPTIONS__SUCCESS: {
      const sortedUsersOptions = sortBy(action.users.map(processAsOptionsEntity), 'label');
      state = state.set('usersOptions', fromJS(sortedUsersOptions));

      break;
    }
    case FETCH_EO_ACTIVE_HOURS_AS_OPTIONS__SUCCESS: {
      const sortedEoActiveHourOptions = sortBy(
        action.eoActiveHours.map(processAsOptionsEntity),
        'label',
      );
      state = state.set('eoActiveHourOptions', fromJS(sortedEoActiveHourOptions));

      break;
    }
    case CREATE_EO_DRIP_LIST__SUCCESS: {
      const newEoDripListOption = processAsOptionsEntity(action.eoDripList);

      const updatedEoDripListOptions = state
        .get('eoDripListsOptions')
        .push(fromJS(newEoDripListOption));

      state = state.set(
        'eoDripListsOptions',
        updatedEoDripListOptions.sortBy((option) => option.get('label')),
      );

      break;
    }
    case FETCH_TIMEZONES_AS_OPTIONS__SUCCESS: {
      const sortedTimezonesOptions = sortBy(action.timezones.map(processAsOptionsEntity), 'title');
      state = state.set('timezonesOptions', fromJS(sortedTimezonesOptions));

      break;
    }
    case CREATE_DL_SOURCE__SUCCESS: {
      const newSource = fromJS(keyBy([processDlSource(action.dlSource)], 'value'));
      const newDlSourcesOptions = iMap().merge(state.get('dlSourcesOptions', iMap()), newSource);

      state = state.set('dlSourcesOptions', newDlSourcesOptions);

      break;
    }
    case FETCH_SIMPLE_BRANDS__SUCCESS: {
      const brands = action.brands || [];
      const sortedBrandsOptions = sortBy(keyBy(brands.map(processBrand), 'value'), 'label');
      state = state.set('brandsOptions', fromJS(sortedBrandsOptions));

      break;
    }
    case FETCH_EO_CAMPAIGNS__SUCCESS: {
      const eoCampaigns = action.eoCampaigns || [];
      const sortedEoCampaignsOptions = sortBy(eoCampaigns.map(processAsOptionsEntity), 'title');
      state = state.set('eoCampaignsOptions', fromJS(sortedEoCampaignsOptions));

      break;
    }
    case FETCH_EO_EMAIL_TEMPLATE_FOLDERS__SUCCESS: {
      const eoEmailTemplateFolders = action.eoEmailTemplateFolders || [];
      const sortedEoEmailTemplateFoldersOptions = sortBy(
        keyBy(eoEmailTemplateFolders.map(processAsOptionsEntity), 'value'),
        'label',
      );
      state = state.set(
        'eoEmailTemplateFoldersOptions',
        fromJS(sortedEoEmailTemplateFoldersOptions),
      );

      break;
    }
    case FETCH_EO_CMARKETER_EMAIL_TEMPLATE_FOLDERS_AS_OPTIONS__SUCCESS: {
      const eoCmarketerEmailTemplateFolders = action.eoCmarketerEmailTemplateFolders || [];
      const sortedEoCmarketerEmailTemplateFoldersOptions = sortBy(
        keyBy(eoCmarketerEmailTemplateFolders.map(processAsOptionsEntity), 'value'),
        'label',
      );
      state = state.set(
        'eoCmarketerEmailTemplateFolders',
        fromJS(sortedEoCmarketerEmailTemplateFoldersOptions),
      );

      break;
    }
    case CREATE_EO_EMAIL_TEMPLATE_FOLDER__SUCCESS: {
      const newEoEmailTemplateFolderOption = processAsOptionsEntity(action.eoEmailTemplateFolder);

      const updatedEoEmailTemplateFoldersOptions = state
        .get('eoEmailTemplateFoldersOptions')
        .push(fromJS(newEoEmailTemplateFolderOption));

      state = state.set(
        'eoEmailTemplateFoldersOptions',
        updatedEoEmailTemplateFoldersOptions.sortBy((option) => option.get('label')),
      );

      break;
    }
    case FETCH_BRAND_PAGES_FOR_FILTER_BY_BRAND_ID__SUCCESS: {
      state = state.set('brandPagesOptions', fromJS(keyBy(action.brandPages, 'value')));

      break;
    }
    case BULK_UPDATE_PAGE_TYPES__SUCCESS:
    case FETCH_PAGE_TYPES__SUCCESS: {
      state = state.set(
        'pageTypeOptions',
        fromJS(keyBy(action.pageTypes.map(processPageType), 'value')).sortBy((pageType) =>
          parseInt(pageType.get('value')),
        ),
      );

      break;
    }
    case CREATE_PERCENTAGE_SCHEME__SUCCESS:
    case UPDATE_PERCENTAGE_SCHEME__SUCCESS: {
      const percentageScheme = fromJS(
        keyBy([action.percentageScheme].map(processPercentageScheme), 'value'),
      );
      state = state
        .mergeIn(
          ['percentageSchemeOptions'],
          state.get('percentageSchemeOptions'),
          percentageScheme,
        )
        .sortBy((option) => option.get('label'));
      break;
    }
    case DELETE_PERCENTAGE_SCHEME__SUCCESS: {
      state = state.set(
        'percentageSchemeOptions',
        state.get('percentageSchemeOptions').delete(String(action.percentageSchemeId)),
      );

      break;
    }
    case FETCH_PERCENTAGE_SCHEMES__SUCCESS: {
      state = state
        .mergeIn(
          ['percentageSchemeOptions'],
          fromJS(keyBy(action.percentageSchemes.map(processPercentageScheme), 'value')),
        )
        .sortBy((option) => option.get('label'));

      break;
    }
    case FETCH_RANK_TRACKER_COUNTRIES__SUCCESS: {
      const { countries } = action;

      state = state.set('rankTrackerCountriesOptions', fromJS(countries.map(processCommonOption)));

      break;
    }
    case FETCH_RANK_TRACKER_ENGINES__SUCCESS: {
      const { engineNames } = action;

      state = state.set('searchEngineOptions', fromJS(engineNames.map(processCommonOption)));

      break;
    }
    case FETCH_RANK_TRACKER_LANGUAGES__SUCCESS: {
      const { languages } = action;

      state = state.set('rankTrackerLanguagesOptions', fromJS(languages.map(processCommonOption)));

      break;
    }
    case FETCH_RANK_TRACKER_LOCATIONS__SUCCESS: {
      const { locations } = action;

      state = state.set('rankTrackerLocationsOptions', fromJS(locations.map(processCommonOption)));

      break;
    }
  }

  return state;
}
