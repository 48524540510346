import React from 'react';

import RonellStewartImg from '../../common/img/ronell_stewart.jpg';
import MichaelTesalonaImg from '../../common/img/michael_tesalona.jpg';
import BartZoniImg from '../../common/img/bart_zoni.jpg';

import TestimonialsSliderComponent from './testimonials_slider_component';

import './testimonials_component.scss';


const TestimonialsComponent = () => {
  const testimonials = [
    {
      photoUrl: BartZoniImg,
      name: 'Bart Zoni',
      position: 'Head of Marketing at Global Skincare Company',
      text: 'Our time for managing SEO is really limited. Linkio really made the difference \
      between winning and wasting time.'
    },
    {
      photoUrl: RonellStewartImg,
      name: 'Ronell Stewart',
      position: 'Co-Founder at Youth Noise Digital',
      text: 'Spreadsheets work, but Linkio works faster.'
    },
    {
      photoUrl: MichaelTesalonaImg,
      name: 'Michael Tesalona',
      position: 'Founder at Bradford Crabtree SEO',
      text: `I work hard for my leads, and Linkio has put more "wow factor" into our proposals.
Conversion is up. I'm happy.`
    }
  ];

  return (
    <div className="testimonials-component">
      <div className="testimonials-component__testimonials">
        <TestimonialsSliderComponent testimonials={testimonials} />
      </div>
    </div>
  );
};

export default TestimonialsComponent;
