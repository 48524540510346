import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, isEmpty } from 'lodash';

import EoCampaignFormPopupComponent from './EoCampaignFormPopupComponent';

import { createEoCampaign } from 'api/eoCampaign';
import { fetchSimpleBrands } from 'api/simpleBrand';
import { optionsList } from 'common/prop_types_shapes';
import { optionsSelector } from 'selectors';

import ConnectStoreHOC from 'startup/connect_store_hoc';

const EoCampaignFormPopupContainer = ({ dispatch, onClose, history, brandsOptions }) => {
  React.useEffect(() => {
    fetchSimpleBrands(dispatch);
  }, []);

  async function handleSave(eoCampaignFormData) {
    const resp = await createEoCampaign(dispatch, eoCampaignFormData);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      return resp;
    }

    onClose();
    history.push(Routes.overview_eo_campaign_path(resp.id, { format: null }));
    return void 0;
  }

  return (
    <EoCampaignFormPopupComponent
      onCancel={onClose}
      onSave={handleSave}
      immutableBrandsOptions={brandsOptions}
    />
  );
};

EoCampaignFormPopupContainer.propTypes = {
  brandsOptions: optionsList,
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
};

function select(state) {
  const options = optionsSelector(state);
  const brandsOptions = options.get('brandsOptions');

  return {
    brandsOptions,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(EoCampaignFormPopupContainer);
