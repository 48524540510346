import React from 'react';
import PropTypes from 'prop-types';
import { Provider, Header, Body } from 'reactabular-table';

import { translate } from '../../common/i18n';

import {
  textColumn
} from '../../common/tables/columns';
import columnsGenerator from '../../common/tables/columns_generator';

import Link from 'components_linkio/link';

import './pricing_table_component.scss';


const PricingTableComponent = ({ data }) => {
  const toLink = (value) => <Link href={value} target="_blank" rel="noopener noreferrer">{value}</Link>;

  const columns = columnsGenerator([
    textColumn({
      name: 'name',
      headerLabel: translate('pricing.name.title'),
      className: 'pricing-table-component__column'
    }),
    textColumn({
      name: 'description',
      headerLabel: translate('pricing.description.title'),
      className: 'pricing-table-component__column'
    }),
    textColumn({
      name: 'link',
      headerLabel: translate('pricing.link.title'),
      cellValueFormatter: toLink,
      className: 'pricing-table-component__column'
    })
  ]);

  return (
    <div className="pricing-table-component">
      <Provider
        className="pricing-table-component__table"
        columns={columns}
      >
        <Header />
        <Body
          rows={data}
          rowKey="id"
        />
      </Provider>
    </div>
  );
};

PricingTableComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    errors: PropTypes.shape({})
  }))
};

export default PricingTableComponent;
