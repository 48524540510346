import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';


const employees = (state) => state.get('employees', iMap());
const employeeFormRow = (state) => state.get('employeeFormRow', iMap());


export const employeesSelector = createSelector(
  [employees],
  (employees) => employees
);

export const employeeFormRowSelector = createSelector(
  [employeeFormRow],
  (employeeFormRow) => employeeFormRow
);
