import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import EoDripListsTable from 'pages/EoDripListsPage/EoDripListsTable';
import EoCampaignsTable from 'pages/EoCampaignsPage/EoCampaignsTable';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';

import './prospectActionsPopupWithTable.scss';

const ProspectActionsPopupWithTableComponent = ({
  actionType,
  onCancel,
  onSubmit,
  onDeleteFromAllDripLists,
}) => {
  const [selectedEntityId, setSelectedEntityId] = React.useState(null);

  function handleSubmit() {
    onSubmit(selectedEntityId);
  }

  let Table;
  let popupTitle;
  let submitBtnTitle;

  switch (actionType) {
    case 'startEoCampaign':
      Table = EoCampaignsTable;
      popupTitle = translate('prospects.bulkActions.startEoCampaignPopup.title');
      submitBtnTitle = translate('prospects.bulkActions.startEoCampaignPopup.startEoCampaign');
      break;
    case 'addToEoDripList':
      Table = EoDripListsTable;
      popupTitle = translate('prospects.bulkActions.addToDripListPopup.title');
      submitBtnTitle = translate('prospects.bulkActions.addToDripListPopup.addToDripList');
      break;
    case 'removeFromEoDripList':
      Table = EoDripListsTable;
      popupTitle = translate('prospects.bulkActions.removeFromEoDripListPopup.title');
      submitBtnTitle = translate(
        'prospects.bulkActions.removeFromEoDripListPopup.removeFromEoDripList',
      );
      break;
  }

  return (
    <Modal show className="prospect-actions-popup-with-table">
      <Modal.Header>
        <Modal.Title>{popupTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table isReadOnly onRowClick={setSelectedEntityId} />
      </Modal.Body>

      <Modal.Footer>
        <div className="prospect-actions-popup-with-table__btn-group">
          <div className="prospect-actions-popup-with-table__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('uiComponents.button.cancel')}
            </ButtonComponent>
          </div>

          {actionType === 'removeFromEoDripList' && (
            <div className="prospect-actions-popup-with-table__btn">
              <ButtonComponent isRed onClick={onDeleteFromAllDripLists}>
                {translate(
                  'prospects.bulkActions.removeFromEoDripListPopup.removeFromAllEoDripLists',
                )}
              </ButtonComponent>
            </div>
          )}

          <div className="prospect-actions-popup-with-table__btn">
            <ButtonComponent
              isGreen={['startEoCampaign', 'addToEoDripList'].includes(actionType)}
              isBlue={actionType === 'removeFromEoDripList'}
              onClick={handleSubmit}
              isDisabled={!selectedEntityId}
            >
              {submitBtnTitle}
            </ButtonComponent>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ProspectActionsPopupWithTableComponent.propTypes = {
  actionType: PropTypes.oneOf(['startEoCampaign', 'addToEoDripList', 'removeFromEoDripList']),
  onCancel: PropTypes.func.isRequired,
  onDeleteFromAllDripLists: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default ProspectActionsPopupWithTableComponent;
