import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ProspectTabsComponent from './ProspectTabsComponent';

const ProspectTabsContainer = ({ history, match }) => {
  const activeTab = match.path.substring(1);

  const handleTabClick = (tab) => () => {
    if (tab === activeTab) {
      return;
    }

    let path = '';

    switch (tab) {
      case 'eo_blacklisted_domains':
        path = Routes.eo_blacklisted_domains_path({ format: null });
        break;
      case 'eo_domains':
        path = Routes.eo_domains_path({ format: null });
        break;
      case 'eo_custom_fields':
        path = Routes.eo_custom_fields_path({ format: null });
        break;
      case 'prospects':
        path = Routes.prospects_path({ format: null });
        break;
    }

    history.push(path);
  };

  return <ProspectTabsComponent activeTab={activeTab} onTabClick={handleTabClick} />;
};

ProspectTabsContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(ProspectTabsContainer);
