import {
  updatePageSuccess,
} from '../../actions/brand_page_actions';

const handleImportingStatus = (dispatch, data) => {
  const {
    brandPage,
    importingStatus,
  } = data;

  switch (importingStatus) {
    case 'started':
    case 'finished':
      dispatch(updatePageSuccess(brandPage));
      break;
    case 'failure':
      dispatch(updatePageSuccess(brandPage));
      // TODO: hanle possible error(s)
      break;
  }
};


export default handleImportingStatus;
