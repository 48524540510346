export default {
  stepsComponent: {
    optional: '(Optional)',
    importStep: {
      title: 'Import Backlinks',
      body: `Allow Linkio to analyze your backlinks profile by importing your backlinks. Use "Import" \
        button for that.`,
    },
    categorizeStep: {
      title: 'Validate and Categorize',
      body: `The crawler automatically checks every backlink to see if it's alive, dofollow and indexed. \
        It also validates and automatically categorizes the anchor text. Click "Categorize" to refine the \
        results. Click the backlink to see the check details. Click "Crawl Page" to re-check the backlinks.`,
    },
    percentagesStep: {
      title: 'Review Percentages',
      body: `Using the sidebar, review your existing anchor text percentages and select the target \
        percentages you want.`,
    },
    getSuggestionsStep: {
      title: 'Get Anchor Text Suggestions',
      body: `The suggestions are generated based on your backlinks profile. Use the anchor type (and text) \
        from the table in the recommended priority to build your next backlinks.`,
    },
    ahrefsStep: {
      title: 'Pair Ahrefs Account',
      bodyFirstPart: 'If you have Ahrefs, ',
      bodySecondPart: ` to pair your API. We'll import your backlinks and suggest ideal anchor text profile \
        based on it. You can also manually import your backlinks or use suggestions that are not specific \
        to your backlinks profile.`,
      linkText: 'click here',
    },
  },
};
