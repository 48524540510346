import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';

const railsContext = (state) => state.get('railsContext', iMap());

export const policiesSelector = createSelector(
  [railsContext],
  (railsContext) => railsContext.get('policies', iMap()),
);

export const stripeKeySelector = createSelector(
  [railsContext],
  (railsContext) => railsContext.get('stripeKey', ''),
);

export const currentUserSelector = createSelector(
  [railsContext],
  (railsContext) => railsContext.get('cuser', iMap()),
);

export const currentMarketerSelector = createSelector(
  [railsContext],
  (railsContext) => railsContext.get('cmarketer', iMap()),
);

export const currentSubscriptionSelector = createSelector(
  [railsContext],
  (railsContext) => railsContext.get('currentSubscription', iMap()),
);

export const subscriptionPoliciesSelector = createSelector(
  [railsContext],
  (railsContext) =>
    railsContext.getIn(['currentSubscription', 'subscriptionPolicies'], iMap()),
);

export const canUseCrawlersSelector = createSelector(
  [subscriptionPoliciesSelector],
  (subscriptionPoliciesSelector) =>
    subscriptionPoliciesSelector.getIn(['crawlers', 'canUse'], false),
);

export const crawlerStatusDataSelector = createSelector(
  [railsContext],
  (railsContext) => railsContext.get('crawlerStatus', iMap()),
);
