import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectGmailEoInboxButtonComponent from './ConnectGmailEoInboxButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { connectGmailEoInbox } from 'api/eoInboxesSettings';

import {
  currentSubscriptionSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import { subscriptionPoliciesShape, subscriptionShape } from 'common/prop_types_shapes';

const ConnectGmailEoInboxButtonContainer = ({
  currentSubscription,
  dispatch,
  subscriptionPolicies,
}) => {
  const allowedEoInboxesCount = currentSubscription.get('allowedEoInboxesCount');
  const canAddEoInboxes = subscriptionPolicies.getIn(['emailOutreach', 'canAddEoInboxes']);

  function handleClick() {
    connectGmailEoInbox(dispatch);
  }

  return (
    <ConnectGmailEoInboxButtonComponent
      allowedEoInboxesCount={allowedEoInboxesCount}
      canAddEoInboxes={canAddEoInboxes}
      onClick={handleClick}
    />
  );
};

ConnectGmailEoInboxButtonContainer.propTypes = {
  currentSubscription: subscriptionShape,
  dispatch: PropTypes.func,
  subscriptionPolicies: subscriptionPoliciesShape,
};

function select(state) {
  const currentSubscription = currentSubscriptionSelector(state);
  const subscriptionPolicies = subscriptionPoliciesSelector(state);

  return {
    currentSubscription,
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(ConnectGmailEoInboxButtonContainer));
