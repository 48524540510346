import React from 'react';
import PropTypes from 'prop-types';

import EoSchedulersTable from './EoSchedulersTable';
import EoSchedulersBulkActions from './EoSchedulersBulkActions';

import { pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';
const EoSchedulersComponent = ({
  canCreate,
  onItemsSelectChange,
  onPagesPagerClick,
  eoSchedulersPagy,
}) => {
  return (
    <div className="eo-schedulers">
      {canCreate && <EoSchedulersBulkActions />}

      <EoSchedulersTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="rowsPager"
        pagy={eoSchedulersPagy}
      />
    </div>
  );
};

EoSchedulersComponent.propTypes = {
  canCreate: PropTypes.bool,
  eoSchedulersPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoSchedulersComponent;
