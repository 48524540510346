import React from "react";
import PropTypes from "prop-types";

import { translate } from "common/i18n";
import SwitchComponent from "components_linkio/switch_component";

import "./show_atp_suggestions_switcher.scss";

const ShowAtpSuggestionsSwitcher = ({ showAtpSuggestions, onClick }) => (
  <div className="show-atp-suggestions-switcher">
    <span className="show-atp-suggestions-switcher__label">
      {translate("brandPage.switchers.showAtpSuggestions")}
    </span>
    <SwitchComponent
      onClick={onClick}
      isBoolean
      initialValue={showAtpSuggestions ? "right" : "left"}
      value={showAtpSuggestions ? "right" : "left"}
    />
  </div>
);

ShowAtpSuggestionsSwitcher.propTypes = {
  onClick: PropTypes.func.isRequired,
  showAtpSuggestions: PropTypes.bool.isRequired,
};

export default ShowAtpSuggestionsSwitcher;
