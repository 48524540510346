import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import TimezoneSelectComponent from './TimezoneSelectComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentUserSelector } from 'selectors/railsContextSelectors';
import { fetchTimezonesAsOptions } from 'api/eoScheduler';
import { optionsList, userShape } from 'common/prop_types_shapes';
import { optionsSelector } from 'selectors';

import './timezoneSelect.scss';

const TimezoneSelectContainer = ({
  currentUser,
  dispatch,
  defaultTimezoneName,
  errors,
  onChangeTimezoneName,
  timezonesOptions,
  showAccountTimezoneOption,
}) => {
  React.useEffect(() => {
    timezonesOptions.size === 0 && fetchTimezonesAsOptions(dispatch);
  }, [dispatch, timezonesOptions.size]);

  const accountTimezone = currentUser.get('timezoneName', null);

  return (
    <TimezoneSelectComponent
      accountTimezone={accountTimezone}
      defaultTimezoneName={defaultTimezoneName}
      errors={errors}
      onChangeTimezoneName={onChangeTimezoneName}
      immutableTimezonesOptions={timezonesOptions}
      showAccountTimezoneOption={showAccountTimezoneOption}
    />
  );
};

TimezoneSelectContainer.propTypes = {
  currentUser: userShape,
  defaultTimezoneName: PropTypes.string,
  dispatch: PropTypes.func,
  errors: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    timezone: PropTypes.string,
  }),
  onChangeTimezoneName: PropTypes.func,
  showAccountTimezoneOption: PropTypes.bool,
  timezonesOptions: optionsList,
};

function select(state) {
  const currentUser = currentUserSelector(state);
  const options = optionsSelector(state);
  const timezonesOptions = options.get('timezonesOptions');

  return {
    currentUser,
    timezonesOptions,
  };
}

export default compose(ConnectStoreHOC, connect(select))(TimezoneSelectContainer);
