import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutablejs-proptypes';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import fetchAhrefsMetricsIfNeededService from './services/fetchAhrefsMetricsIfNeededService';
// import fetchMozMetricsIfNeededService from './services/fetchMozMetricsIfNeededService';

import MetricsAnalysis from './MetricsAnalysis';
import NumberOfBacklinksAnalysis from './NumberOfBacklinksAnalysis';
import PercentagesAnalysis from './PercentagesAnalysis';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandPageShape, brandPagesMap, percentageShape } from 'common/prop_types_shapes';
import { currentMarketerSelector } from 'selectors/railsContextSelectors';
import { fetchCompetitorPagesPercentages } from 'api/brand_page';

import {
  currentIdSelector,
  pagesByParentSelector,
  percentagesSelector,
  visibleBrandPageSelector,
} from 'selectors';

import './competitorAnalysis.scss';

const CompetitorAnalysisContainer = ({
  brandId,
  brandPage,
  competitorPages,
  dispatch,
  hasAhrefsConnection,
  history,
  pageParentId,
  percentages,
  queryParams,
}) => {
  React.useEffect(() => {
    fetchCompetitorPagesPercentages(dispatch, pageParentId);
  }, []);

  React.useEffect(() => {
    const options = { brandPage, competitorPages, dispatch, hasAhrefsConnection };

    Promise.all([
      fetchAhrefsMetricsIfNeededService(options),
      // fetchMozMetricsIfNeededService(options),
    ]);
  }, [competitorPages.size]);

  function handleAddCompetitorsClick() {
    const path = Routes.brand_page_tab_path(brandId, pageParentId, 'bulk_competitors_setup', {
      pagesFilter: queryParams.pagesFilter,
      format: null,
    });

    history.push(path);
  }

  return (
    <div className="competitors-tables-wrapper">
      <PercentagesAnalysis
        competitorPages={competitorPages}
        percentages={percentages}
        myBrandPage={brandPage}
        onAddCompetitorsClick={handleAddCompetitorsClick}
      />
      <NumberOfBacklinksAnalysis competitorPages={competitorPages} myBrandPage={brandPage} />
      <MetricsAnalysis competitorPages={competitorPages} myBrandPage={brandPage} />
    </div>
  );
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const brandPage = visibleBrandPageSelector(state, ownProps);
  const competitorPages = pagesByParentSelector(state, ownProps);
  const percentages = percentagesSelector(state, ownProps);

  const currentMarketer = currentMarketerSelector(state, ownProps);
  const hasAhrefsConnection = currentMarketer.get('has_ahrefs_connection');

  return {
    brandId,
    brandPage,
    competitorPages,
    hasAhrefsConnection,
    percentages,
  };
}

CompetitorAnalysisContainer.propTypes = {
  brandId: PropTypes.string,
  brandPage: brandPageShape,
  competitorPages: brandPagesMap,
  dispatch: PropTypes.func,
  hasAhrefsConnection: PropTypes.bool,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentages: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      percentage: ImmutablePropTypes.listOf(percentageShape).isRequired,
      uncategorizedAnchorsCount: PropTypes.number,
    }), //value
    PropTypes.string.isRequired, //key
  ),
  ...withQueryParamsPropTypes,
};

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(CompetitorAnalysisContainer);
