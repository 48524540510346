import React from 'react';

import PricingTableComponent from 'components/pricing_table_component';
import Breadcrumbs from 'components/NewBreadcrumbs';

const PricingForClientsPage = () => {
  const pricing = [
    {
      id: 0,
      name: 'Guest Posting and Top Tier Sites',
      description: 'Pricing and description for our guest posting service.',
      link: 'https://1drv.ms/x/s!Aqu244jvqKE-gXx9WcNbYwhwOO9s',
      errors: {},
    },
    {
      id: 1,
      name: 'Custom Blogger Outreach',
      description: 'Pricing and descriptions for our custom blogger outreach service.',
      link: 'https://www.outreachmama.com/blogger-outreach-pricing/',
      errors: {},
    },
    {
      id: 2,
      name: 'Hybrid Guest Posting and Blogger Outreach',
      description: 'Prefer a hybrid approach? We can create a custom proposal for you.',
      link: 'https://www.outreachmama.com/contact/',
      errors: {},
    },
    {
      id: 3,
      name: 'Full Service SEO',
      description: 'Looking for an end-to-end solution? We can create a custom proposal for you.',
      link: 'https://www.outreachmama.com/contact/',
      errors: {},
    },
  ];

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>OutreachMama Pricing</Breadcrumbs.TextItem>
      </Breadcrumbs>
      <PricingTableComponent data={pricing} />
    </>
  );
};

export default PricingForClientsPage;
