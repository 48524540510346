import React from 'react';
import PropTypes from 'prop-types';

import RecheckRankingsButton from './RecheckRankingsButton';
import ExportToCsvButton from './ExportToCsvButton';

import { rankTrackerFiltersMap } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import CogIcon from 'common/icons/cog';
import DateRangeInput from 'components_linkio/DateRangeInput';
import PageLayoutHeader from 'components_linkio/PageLayoutHeader';
import SearchInput from 'components/SearchInput';
import SwitchComponent from 'components_linkio/switch_component';
import TutorialPopup from 'components_linkio/TutorialPopup';

import './rankTrackerBulkActions.scss';

const RankTrackerBulkActionsComponent = ({
  dateEnd,
  dateStart,
  isDateFilterApplied,
  onChangeDateRange,
  onChangeGroupByUrl,
  onClickSettings,
  onResetClick,
  onSubmitSearch,
  rankTrackerFilters,
}) => {
  const groupByUrlInitialValue = rankTrackerFilters.get('groupByUrl') ? 'right' : 'left';

  return (
    <div className="rank-tracker-bulk-actions">
      <div className="rank-tracker-bulk-actions__row">
        <PageLayoutHeader
          title={translate('rankTracker.title')}
          Popup={
            <TutorialPopup
              title={translate('rankTracker.title')}
              videoSrc="https://www.youtube.com/embed/IYm27lg0OsQ"
            />
          }
        />

        <div className="rank-tracker-bulk-actions__buttons-wrapper">
          <ExportToCsvButton />

          <RecheckRankingsButton />

          <ButtonComponent isInitial isWhite onClick={onClickSettings}>
            <CogIcon /> {translate('rankTracker.buttons.seSettings')}
          </ButtonComponent>
        </div>
      </div>

      <div className="rank-tracker-bulk-actions__row">
        <SwitchComponent
          initialValue={groupByUrlInitialValue}
          isBoolean
          onClick={onChangeGroupByUrl}
          right={translate('rankTracker.bulkActions.groupByUrl')}
        />

        <div className="rank-tracker-bulk-actions__buttons-wrapper">
          <DateRangeInput
            align={{ bottom: 10, right: 0 }}
            changeDateRange={onChangeDateRange}
            dateStart={new Date(dateStart)}
            dateEnd={new Date(dateEnd)}
            defaultPeriod={'Default Month'}
          />

          <ButtonComponent
            isDisabled={!isDateFilterApplied}
            isInitial
            isWhite
            onClick={onResetClick}
          >
            {translate('rankTracker.buttons.reset')}
          </ButtonComponent>

          <SearchInput onSubmit={onSubmitSearch} />
        </div>
      </div>
    </div>
  );
};

RankTrackerBulkActionsComponent.propTypes = {
  dateEnd: PropTypes.string,
  dateStart: PropTypes.string,
  isDateFilterApplied: PropTypes.bool,
  onChangeDateRange: PropTypes.func,
  onChangeGroupByUrl: PropTypes.func.isRequired,
  onClickSettings: PropTypes.func.isRequired,
  onResetClick: PropTypes.func,
  onSubmitSearch: PropTypes.func.isRequired,
  rankTrackerFilters: rankTrackerFiltersMap,
};

export default RankTrackerBulkActionsComponent;
