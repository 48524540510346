import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { eoMessageShape } from 'common/propTypesShapes/eoMessages';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import SpinnerComponent from 'components_linkio/Spinner';

import './eoMessagePopup.scss';

function fromEmailFormatter(emailsArray) {
  if (!emailsArray) {
    return void 0;
  }

  return emailsArray.map((email) => `<${email}>`).join(', ');
}

function toEmailFormatter(emailsArray) {
  if (!emailsArray) {
    return void 0;
  }

  return emailsArray.join(', ');
}

const EoMessagePopupComponent = ({ eoMessage, isFetching, onCancel }) => {
  const {
    body,
    date,
    eoCampaignStepPosition,
    eoCampaignTitle,
    from,
    subject,
    to,
    prospectDisplayName,
    prospectEmail,
  } = eoMessage;

  return (
    <Modal show className="eo-message-popup">
      <Modal.Header>
        <Modal.Title>{translate('eoMessage.eoMessagePopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isFetching && <SpinnerComponent isBlack />}

        {!isFetching && (
          <>
            <div className="eo-message-popup__headline">
              <p>
                <b>{translate('eoMessage.eoMessagePopup.prospect')}: </b>
                {prospectEmail}
              </p>
              <p>
                <b>{translate('eoMessage.eoMessagePopup.campaign')}: </b>
                {eoCampaignTitle}
              </p>
              {eoCampaignStepPosition && (
                <p>
                  <b>{translate('eoMessage.eoMessagePopup.step')(eoCampaignStepPosition)}</b>
                </p>
              )}
            </div>

            <hr />

            <h3>{subject}</h3>
            <p>
              <b>{translate('eoMessage.eoMessagePopup.from')}:</b> {prospectDisplayName}{' '}
              {fromEmailFormatter(from)}
            </p>
            <p>
              <b>{translate('eoMessage.eoMessagePopup.to')}:</b> {toEmailFormatter(to)}
            </p>
            {date && <span>{format(new Date(date), 'MMM d h:mm a')}</span>}

            <hr />

            <div dangerouslySetInnerHTML={{ __html: body }} />
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="eo-message-popup__btn-group">
          <ButtonComponent isWhite isInitial onClick={onCancel}>
            {translate('uiComponents.button.cancel')}
          </ButtonComponent>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EoMessagePopupComponent.propTypes = {
  eoMessage: eoMessageShape,
  isFetching: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

export default EoMessagePopupComponent;
