import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { translate } from 'common/i18n';

import UpArrowIcon from 'common/icons/UpArrowIcon';
import DownArrowIconV2 from 'common/icons/DownArrowIconV2';

import { eoToDosList } from 'common/propTypesShapes/eoToDos';

import SearchEoToDosInput from '../EoToDosBulkActions/SearchEoToDosInput';

import moment from 'moment';

import './eoToDosTasks.scss';

function getShortMomentFromNowSignature(longMomentFromNowSignature) {
  function parsePluralPeriod() {
    const [timeCount, timeUnit] = longMomentFromNowSignature.split(' ');

    if (timeUnit.slice(0, 3) === 'min') {
      return `${timeCount}min`;
    }

    return `${timeCount}${timeUnit[0]}`;
  }

  switch (longMomentFromNowSignature) {
    case 'a few seconds':
    case 'a minute':
      return '1min';
    case 'an hour':
      return '1h';
    case 'a day':
      return '1d';
    case 'a month':
      return '1m';
    case 'a year':
      return '1y';
    default: {
      return parsePluralPeriod();
    }
  }
}

function getEoToDoAge(createdAt) {
  const eoToDoAgeLong = moment(createdAt).fromNow(true);

  return getShortMomentFromNowSignature(eoToDoAgeLong);
}

const EoToDosTasksComponent = ({
  handleSwitchSortingOrder,
  immutableEoToDos,
  isShowNewTasksFirst,
  onClickEoToDo,
  selectedEoToDoId,
}) => {
  const eoToDos = immutableEoToDos.toJS();

  function buildEoToDoCard(eoToDo) {
    const { id, brandName, createdAt, eoCampaignStepPosition, userName, prospectEmail, toDosType } =
      eoToDo;

    const eoToDoCardClassnames = cx('eo-to-dos-tasks__eo-to-do-card', {
      'eo-to-dos-tasks__eo-to-do-card_selected': id === selectedEoToDoId,
    });

    const eoToDoTypeClassnames = cx('eo-to-dos-tasks__eo-to-do-card-type', {
      'eo-to-dos-tasks__eo-to-do-card-type_draft': toDosType === 'draft',
      'eo-to-dos-tasks__eo-to-do-card-type_reply': toDosType === 'reply',
    });

    const stepPositionBadgeText = `${translate(
      'eoToDo.tasks.eoToDoCard.stepPosition',
    )}${eoCampaignStepPosition}`;

    const brandBadgeText = brandName || translate('eoToDo.tasks.eoToDoCard.noBrandAssigned');
    const assigneeBadgeText = userName || translate('eoToDo.tasks.eoToDoCard.noAssignee');

    return (
      <li className={eoToDoCardClassnames} onClick={onClickEoToDo(id)} key={id}>
        <div className="eo-to-dos-tasks__first-line-wrapper">
          <div className="eo-to-dos-tasks__eo-to-do-card-email">{prospectEmail}</div>
          <div className={eoToDoTypeClassnames}>{toDosType}</div>
        </div>
        <div className="eo-to-dos-tasks__eo-to-do-card-badges-wrapper">
          {eoCampaignStepPosition && (
            <div className="eo-to-dos-tasks__eo-to-do-card-step-badge">{stepPositionBadgeText}</div>
          )}
          <div className="eo-to-dos-tasks__eo-to-do-card-brand-badge">{brandBadgeText}</div>
          <div className="eo-to-dos-tasks__eo-to-do-card-assignee-time-exists-wrapper">
            <div className="eo-to-dos-tasks__eo-to-do-card-assignee-badge">{assigneeBadgeText}</div>
            <div className="eo-to-dos-tasks__eo-to-do-card-time-exists">
              {getEoToDoAge(createdAt)}
            </div>
          </div>
        </div>
      </li>
    );
  }

  const sortingValue = isShowNewTasksFirst
    ? translate('eoToDo.tasks.sorting.newest')
    : translate('eoToDo.tasks.sorting.oldest');

  return (
    <div className="eo-to-dos-tasks">
      <div className="eo-to-dos-tasks__header">
        <div className="eo-to-dos-tasks__title">{translate('eoToDo.tasks.header.title')}</div>
        <div className="eo-to-dos-tasks__sorting-button" onClick={handleSwitchSortingOrder}>
          <div className="eo-to-dos-tasks__sorting-value">{sortingValue}</div>
          {isShowNewTasksFirst ? <UpArrowIcon /> : <DownArrowIconV2 />}
        </div>
      </div>

      <SearchEoToDosInput />

      <ul className="eo-to-dos-tasks__eo-to-dos-list">{eoToDos.map(buildEoToDoCard)}</ul>
    </div>
  );
};

EoToDosTasksComponent.propTypes = {
  handleSwitchSortingOrder: PropTypes.func,
  immutableEoToDos: eoToDosList,
  isShowNewTasksFirst: PropTypes.bool,
  onClickEoToDo: PropTypes.func,
  selectedEoToDoId: PropTypes.number,
};

export default EoToDosTasksComponent;
