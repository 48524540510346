import { fetchBrandPageDls } from 'api/destination_links';
import { ATP_TABLE_TABS_FILTERS } from 'components/atp/AtpTableTabs/constants';
import { METRICS_FILTERS } from 'utils/constants';

const DEFAULT_PAGY_ITEMS_COUNT = 50;

function smartFetchPageDlsService(dispatch, pageId, dlsFetchingConfig) {
  const {
    activeAtpTableTab,
    activeMetric,
    filters,
    dlsGroupMode,
    pageNumber,
    pagyItemsCount,
    sorting,
  } = dlsFetchingConfig;

  const data = {
    filters: {
      ...ATP_TABLE_TABS_FILTERS[activeAtpTableTab],
      ...METRICS_FILTERS[activeMetric],
      ...filters,
    },
    dlsGroupMode,
    items: pagyItemsCount || DEFAULT_PAGY_ITEMS_COUNT,
    page: pageNumber || 1,
    sorting,
  };

  fetchBrandPageDls(dispatch, pageId, data);
}

export { smartFetchPageDlsService };
