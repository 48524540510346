const authForms = {
  authForms: {
    deviseLinks: {
      forgotPassword: 'Forgot password?',
      resendConfirmation: "Didn't receive confirmation instructions?",
      resendUnlock: "Didn't receive unlock instructions?",
    },

    authTabs: {
      accountDetails: 'Account Details',
      billing: 'Billing',
      emailPassword: 'Email/Password',
      newsletterSettings: 'My Notifications',
      subscriptionPlans: 'Subscription Plans',
      usage: 'Usage',
    },

    dontHaveAccount: {
      alreadyHave: 'Already have an account?',
      dontHave: "Don't have an account?",
      signIn: 'Sign in',
      signUp: 'Sign up free!',
    },

    breadcrumbsTitle: 'My account',
    cancelAccountPrompt: 'Unhappy?',
    cancelAccountLinkText: 'Delete my account',
    cancelAccountConfirmation: 'Are you sure?',
    cancelSubscriptionPrompt: 'To cancel your subscription and preserve your account data please',
    cancelSubscriptionLinkText: 'choose Free subscription.',

    leftColumnSlogan: 'An SEO Workflow Optimized to Help You Build More Links',

    byClickingIAgree: 'I agree to the ',
    termsAndConditionsLinkText: 'Terms and Conditions',
    privacyPolicyLinkText: 'Privacy Policy.',

    acceptInvitation: {
      title: 'Set your password.',
      inputs: {
        password: {
          label: 'Password',
        },
        passwordConfirmation: {
          label: 'Password confirmation',
        },
      },
      buttons: {
        submit: 'Set My Password',
      },
    },

    chooseMarketer: {
      title: 'Choose account',
      buttons: {
        createNewMarketer: '+ Add New Account',
      },
    },

    confirmPassword: {
      title: 'PASSWORD IS REQUIRED',
      confirmationRequest: 'Please confirm your password to continue',
      buttons: {
        logout: 'Logout',
        continue: 'Continue',
      },
    },

    editEmailOrPassword: {
      title: 'Set your password',
      inputs: {
        currentPassword: {
          label: 'Current password',
          hint: 'We need it to confirm your changes',
        },
        email: {
          label: 'Email address',
        },
        password: {
          label: 'Password',
          hint: "Leave blank if you don't want to change it",
        },
        passwordConfirmation: {
          label: 'Password confirmation',
        },
      },
      buttons: {
        submit: 'Update',
      },
      updated: 'Your account has been updated successfully.',
      updatedEmail:
        'You updated your account successfully, but we need to verify your new email address. \
        Please check your email and follow the confirmation link to confirm your new email address.',
    },

    editProfile: {
      avatarImageProvidedBy: 'Avatar image provided by',
      inputs: {
        userName: {
          label: 'Name',
        },
        businessName: {
          label: 'Business name',
        },
      },
      buttons: {
        submit: 'Update',
      },
      contactUs: 'Please contact us at',
      contactUsCondition: 'if you want to export your data or have any questions.',
      privacyEmail: 'privacy@linkio.com',
      updated: 'Your account has been updated successfully.',
    },

    forgotPassword: {
      title: 'Forgot your password?',
      subtitle: 'Reset password instructions will be sent to your email address.',
      instructionsSent: {
        title: 'Password reset sent',
        successMsg:
          'Reset password instructions have been sent to your email. \
          Please check your inbox and follow the instructions in the email.',
      },
      inputs: {
        email: {
          label: 'Email address',
        },
      },
      buttons: {
        submit: 'Send',
      },
    },

    newMarketer: {
      title: 'Create new marketer.',
      inputs: {
        businessName: {
          label: 'Business name',
        },
      },
      buttons: {
        cancel: 'Cancel',
        submit: 'Continue',
      },
    },

    resendConfirmation: {
      title: 'Resend confirmation instructions.',
      subtitle: 'Enter the email you used to create your account.',
      successMsg:
        'You will receive an email with instructions for how to confirm your email address in a few minutes.',
      inputs: {
        email: {
          label: 'Email address',
        },
      },
      buttons: {
        submit: 'Send',
      },
    },

    resendUnlock: {
      title: 'Resend unlock instructions.',
      subtitle: 'Enter your email to receive unlock instructions.',
      successMsg:
        'You will receive an email with instructions for how to unlock your account in a few minutes.',
      inputs: {
        email: {
          label: 'Email address',
        },
      },
      buttons: {
        submit: 'Send',
      },
    },

    resetPassword: {
      title: 'Change your password',
      subtitle: 'Remember, this is for all your Linkio accounts.',
      inputs: {
        password: {
          label: 'New password',
        },
        passwordConfirmation: {
          label: 'Confirm new password',
        },
      },
      buttons: {
        submit: 'Change My Password',
      },
    },

    signIn: {
      title: 'Sign in to Linkio.',
      inputs: {
        email: {
          label: 'Email address',
        },
        password: {
          label: 'Password',
        },
        rememberMe: {
          label: 'Remember me',
        },
      },
      buttons: {
        submit: 'Sign In',
      },
    },

    signUp: {
      title: 'Get started absolutely free.',
      subtitle: 'Free forever. No credit card needed.',
      successMsg:
        'A message with confirmation link has been sent to your email address. \n Please follow the link to activate your account.',
      inputs: {
        email: {
          label: 'Email address',
        },
        businessName: {
          label: 'Business name',
        },
        password: {
          label: 'Password',
        },
        passwordConfirmation: {
          label: 'Password confirmation',
        },
      },
      buttons: {
        submit: 'Sign Up',
      },
    },
  },
};

export default authForms;
