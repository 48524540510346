import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { capitalize, fromPairs, lowerCase, toPairs } from 'lodash';

import BroadcastIcon from 'common/icons/BroadcastIcon';
import DownArrowIconV2 from 'common/icons/DownArrowIconV2';
import PaperIcon from 'common/icons/PaperIcon';
import PenIcon from 'common/icons/PenIcon';
import PersonIcon from 'common/icons/PersonIcon';
import ProspectFormPopup from '../../ProspectsPage/ProspectsBulkActions/ProspectFormPopup';
import RightArrowIcon from 'common/icons/RightArrowIcon';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import { eoCampaignShape } from 'common/propTypesShapes/eoCampaigns';

import { prospectShape } from 'common/propTypesShapes/prospects';

import { Map as iMap } from 'immutable';

import moment from 'moment';

import { translate } from 'common/i18n';

import './eoToDoDetails.scss';

function buildEoToDoInfoBlock(items) {
  const itemsArray = toPairs(items).map(([name, value]) => [capitalize(lowerCase(name)), value]);

  return (
    <ul className="eo-to-dos-details__info-list">
      {itemsArray.map(([name, value]) => (
        <li key={name} className="eo-to-dos-details__info-item">
          <div className="eo-to-dos-details__info-item-name">{name}</div>
          <div className="eo-to-dos-details__info-item-value">{value ?? '\u2014'}</div>
        </li>
      ))}
    </ul>
  );
}

const EoToDoDetailsComponent = ({
  currentEoCampaignStepPosition,
  currentEoToDoEoCampaign = iMap(),
  currentEoToDoProspect,
  handleUpdateProspectInfo,
}) => {
  const [isShowProspectInfoBlock, setIsShowProspectInfoBlock] = useState(false);
  const [isShowCampaignInfoBlock, setIsShowCampaignInfoBlock] = useState(false);
  const [isShowEoCustomFieldsInfoBlock, setIsShowEoCustomFieldsInfoBlock] = useState(false);

  const {
    id: prospectId,
    email: prospectEmail,
    firstName,
    lastName,
    organization,
    domain,
    role,
    phone,
    eoDripListTitles,
    eoCustomFieldValues: eoCustomFields = [],
  } = currentEoToDoProspect;

  const prospectInfoItems = {
    firstName,
    lastName,
    organization,
    domainName: domain,
    role,
    phoneNumber: phone,
    dripList: eoDripListTitles,
  };

  const {
    title: eoCampaignTitle,
    createdAt: eoCampaignCreatedAt,
    brandName: eoCampaignAssociatedBrand,
  } = currentEoToDoEoCampaign.toJS();

  const eoCampaignCreatedAtValue = eoCampaignCreatedAt && moment().format('MMM D, h:mm A');

  const eoCampaignInfoItems = {
    campaignName: eoCampaignTitle,
    campaignStep: currentEoCampaignStepPosition,
    created: eoCampaignCreatedAtValue,
    associatedBrand: eoCampaignAssociatedBrand,
  };

  const eoCustomFieldsInfoItemsArray = eoCustomFields.map(({ defaultValue, name, value }) => [
    name,
    value ?? defaultValue,
  ]);

  const eoCustomFieldsInfoItems = fromPairs(eoCustomFieldsInfoItemsArray);

  function handleShowProspectInfoBlock() {
    setIsShowProspectInfoBlock(!isShowProspectInfoBlock);
  }

  function handleShowCampaignInfoBlock() {
    setIsShowCampaignInfoBlock(!isShowCampaignInfoBlock);
  }

  function handleShowEoCustomFieldsInfoBlock() {
    setIsShowEoCustomFieldsInfoBlock(!isShowEoCustomFieldsInfoBlock);
  }

  return (
    <div className="eo-to-dos-details">
      <>
        {prospectEmail && (
          <ToggleShowPopup
            onSubmitCb={handleUpdateProspectInfo}
            Popup={<ProspectFormPopup prospectId={prospectId} />}
          >
            <div className="eo-to-dos-details__header">
              <div className="eo-to-dos-details__email">{prospectEmail}</div>
              <PenIcon className="eo-to-dos-details__header-icon" />
            </div>
          </ToggleShowPopup>
        )}
        <div className="eo-to-dos-details__scroll-area">
          <div
            className="eo-to-dos-details__info-title-wrapper"
            onClick={handleShowProspectInfoBlock}
          >
            <div className="eo-to-dos-details__info-title">
              <PersonIcon className="eo-to-dos-details__info-icon" />
              <div>{translate('eoToDo.details.prospectInfoTitle')}</div>
            </div>
            {isShowProspectInfoBlock ? (
              <DownArrowIconV2 className="eo-to-dos-details__show-info-block-icon" />
            ) : (
              <RightArrowIcon className="eo-to-dos-details__show-info-block-icon" />
            )}
          </div>
          {isShowProspectInfoBlock && (
            <div className="eo-to-dos-details__info-wrapper">
              {buildEoToDoInfoBlock(prospectInfoItems)}
            </div>
          )}

          <div
            className="eo-to-dos-details__info-title-wrapper"
            onClick={handleShowCampaignInfoBlock}
          >
            <div className="eo-to-dos-details__info-title">
              <BroadcastIcon className="eo-to-dos-details__info-icon" />
              <div>{translate('eoToDo.details.campaignInfoTitle')}</div>
            </div>
            {isShowCampaignInfoBlock ? (
              <DownArrowIconV2 className="eo-to-dos-details__show-info-block-icon" />
            ) : (
              <RightArrowIcon className="eo-to-dos-details__show-info-block-icon" />
            )}
          </div>
          {isShowCampaignInfoBlock && (
            <div className="eo-to-dos-details__info-wrapper">
              {buildEoToDoInfoBlock(eoCampaignInfoItems)}
            </div>
          )}

          <div
            className="eo-to-dos-details__info-title-wrapper"
            onClick={handleShowEoCustomFieldsInfoBlock}
          >
            <div className="eo-to-dos-details__info-title">
              <PaperIcon className="eo-to-dos-details__info-icon" />
              <div>{translate('eoToDo.details.eoCustomFieldsInfoTitle')}</div>
            </div>
            {isShowEoCustomFieldsInfoBlock ? (
              <DownArrowIconV2 className="eo-to-dos-details__show-info-block-icon" />
            ) : (
              <RightArrowIcon className="eo-to-dos-details__show-info-block-icon" />
            )}
          </div>
          {isShowEoCustomFieldsInfoBlock && (
            <div className="eo-to-dos-details__info-wrapper eo-to-dos-details__info-wrapper_last">
              {buildEoToDoInfoBlock(eoCustomFieldsInfoItems)}
            </div>
          )}
        </div>
      </>
    </div>
  );
};

EoToDoDetailsComponent.propTypes = {
  currentEoCampaignStepPosition: PropTypes.number,
  currentEoToDoEoCampaign: eoCampaignShape,
  currentEoToDoProspect: prospectShape,
  handleUpdateProspectInfo: PropTypes.func,
};

export default EoToDoDetailsComponent;
