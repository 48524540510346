import {
  autodiscoverCompetitorsByKeywordFailure,
  fetchBulkCompetitorPagesSuccess,
} from 'actions/bulkCompetitorsSetupActions';

import { showSuccessMessage, showErrorMessage } from 'common/utils';
import { translate } from 'common/i18n';
import { updatePageSuccess } from 'actions/brand_page_actions';

const handleAutodiscoverCompetitorsStatus = (dispatch, data) => {
  const {
    autodiscoverCompetitorsInProgressStatus,
    brandPage,
    draftCompetitorPages = {},
    notFoundLabel,
  } = data;

  const {
    brand_page_id,
    draft_competitor_pages,
    dupe_or_invalid_entries_count,
    uniq_and_valid_entries_count,
  } = draftCompetitorPages;

  switch (autodiscoverCompetitorsInProgressStatus) {
    case 'started':
      dispatch(updatePageSuccess(brandPage));
      dispatch(fetchBulkCompetitorPagesSuccess(draft_competitor_pages, brand_page_id));
      break;
    case 'finished':
      dispatch(updatePageSuccess(brandPage));
      dispatch(fetchBulkCompetitorPagesSuccess(draft_competitor_pages, brand_page_id));
      showSuccessMessage(
        dispatch,
        translate('bulkCompetitorsSetup.messages.addCompetitorsSuccess')(
          uniq_and_valid_entries_count,
          dupe_or_invalid_entries_count,
        ),
      );
      break;
    case 'failure':
      dispatch(updatePageSuccess(brandPage));
      dispatch(fetchBulkCompetitorPagesSuccess(draft_competitor_pages, brand_page_id));
      dispatch(autodiscoverCompetitorsByKeywordFailure(brand_page_id));

      notFoundLabel &&
        showErrorMessage(
          dispatch,
          translate('bulkCompetitorsSetup.messages.addCompetitorsFailure')(notFoundLabel),
          true,
        );
      break;
  }
};

export default handleAutodiscoverCompetitorsStatus;
