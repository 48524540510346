export const FETCH_SIMPLE_BRANDS__FAILURE = 'FETCH_SIMPLE_BRANDS__FAILURE';
export const FETCH_SIMPLE_BRANDS__START = 'FETCH_SIMPLE_BRANDS__START';
export const FETCH_SIMPLE_BRANDS__SUCCESS = 'FETCH_SIMPLE_BRANDS__SUCCESS';

export function fetchSimpleBrandsStart() {
  return { type: FETCH_SIMPLE_BRANDS__START };
}

export function fetchSimpleBrandsSuccess(brands) {
  return { type: FETCH_SIMPLE_BRANDS__SUCCESS, brands };
}

export function fetchSimpleBrandsFailure(errors) {
  return { type: FETCH_SIMPLE_BRANDS__FAILURE, errors };
}
