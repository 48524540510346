import { translate } from '../../../../../common/i18n';


const BRAND_PAGE_PRIORITY_LIST = [
  {
    label: translate('brandPage.priority.high.title'),
    value: 5,
  },
  {
    label: translate('brandPage.priority.medium.title'),
    value: 4,
  },
  {
    label: translate('brandPage.priority.low.title'),
    value: 3,
  },
  {
    label: translate('brandPage.priority.lowest.title'),
    value: 2,
  },
  {
    label: translate('brandPage.priority.noMoreLinks.title'),
    value: 1,
  },
];


export {
  BRAND_PAGE_PRIORITY_LIST,
};
