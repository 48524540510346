import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';
import { connect } from 'react-redux';

import buildGroupedPercentageSchemeOptionsService from '../services/buildGroupedPercentageSchemeOptionsService';
import IdealSchemeSelectComponent from './IdealSchemeSelectComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { translate } from 'common/i18n';

import { brandPageShape, optionsMap } from 'common/prop_types_shapes';
import { currentIdSelector, optionsSelector } from 'selectors';
import { openPercentagesFormPopup } from 'actions/popup_actions';
import { showSuccessMessage, trackHelpcrunchEvent } from 'common/utils';
import { updatePage, fetchPagePercentage, reDistributeDls } from 'api/brand_page';

class IdealSchemeSelectContainer extends Component {
  static propTypes = {
    brandId: PropTypes.string,
    competitorsGroupInvisible: PropTypes.bool,
    dispatch: PropTypes.func,
    page: brandPageShape,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    pageId: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
    percentageSchemeOptions: optionsMap,
    percentageSelectRef: PropTypes.shape({ current: PropTypes.stateManager }).isRequired,
    ...withQueryParamsPropTypes,
  };

  shouldComponentUpdate() {
    return true;
  }

  handleChangePercentageScheme = async (selectedOption) => {
    const { dispatch, brandId, history, page, pageChildType } = this.props;

    const pageId = page.get('id');
    const pageParentId = page.get('brandPageId');

    const { value } = selectedOption;

    if (value === 'newCustomScheme') {
      dispatch(
        openPercentagesFormPopup({
          pageChildType,
          pageId,
          percentageSchemeId: value,
        }),
      );

      return;
    }

    if (value === 'newCompetitors') {
      const path = Routes.brand_page_tab_path(
        brandId,
        pageParentId || pageId,
        'bulk_competitors_setup',
        { pagesFilter: this.props.queryParams.pagesFilter, format: null },
      );

      history.push(path);

      return;
    }

    const initialValue = page.get('percentageSchemeId');
    if (Number(value) === Number(initialValue)) {
      return;
    }

    this.trackSelectedOptionTypeViaHelpcrunch(selectedOption);
    await updatePage(dispatch, pageId, { percentageSchemeId: value });
    pageParentId && (await updatePage(dispatch, pageParentId, { dlsRedistributionRequired: true }));
    await fetchPagePercentage(dispatch, pageId);
    await reDistributeDls(dispatch, pageId);
    showSuccessMessage(
      dispatch,
      translate('brandPage.messages.assignNewPercentageSchemeSuccessfully'),
    );
    return;
  };

  trackSelectedOptionTypeViaHelpcrunch = (selectedOption) => {
    if (selectedOption.isCompetitors) {
      trackHelpcrunchEvent('select.competitorPercentage');
    }

    if (selectedOption.isCustom) {
      trackHelpcrunchEvent('select.customPercentage');
    }

    trackHelpcrunchEvent('select.percentage');
  };

  groupedPercentageSchemeOptions = () => {
    const { competitorsGroupInvisible, page, percentageSchemeOptions } = this.props;

    return buildGroupedPercentageSchemeOptionsService({
      competitorsGroupInvisible,
      percentageSchemeOptions,
      pageId: page.get('id'),
    });
  };

  render() {
    const { percentageSelectRef, page } = this.props;

    return (
      <IdealSchemeSelectComponent
        percentageSelectRef={percentageSelectRef}
        onChange={this.handleChangePercentageScheme}
        percentageSchemeId={page.get('percentageSchemeId')}
        percentageSchemeOptions={this.groupedPercentageSchemeOptions()}
      />
    );
  }
}

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const pageId = ownProps.pageId;
  const page = state.getIn(['brandPages', String(pageId)], iMap());

  const options = optionsSelector(state, ownProps);
  const percentageSchemeOptions = options.get('percentageSchemeOptions');

  return {
    brandId,
    page,
    percentageSchemeOptions,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(IdealSchemeSelectContainer)));
