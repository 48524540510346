import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { keywordsList } from 'common/prop_types_shapes';

import BackButton from 'components_linkio/BackButton';
import ButtonComponent from 'components_linkio/button_component';
import KeywordsManager from 'components_linkio/KeywordsManager';

import './rankTrackerKeywords.scss';

const RankTrackerKeywordsComponent = ({
  brandKeywords,
  canSaveKeywords,
  error,
  onChangeKeywords,
  onSaveKeywords,
}) => {
  return (
    <div className="rank-tracker-keywords">
      <h1>{translate('rankTrackerKeywords.title')}</h1>

      <KeywordsManager
        keywords={brandKeywords.toJS()}
        keywordsError={error}
        onChangeKeywords={onChangeKeywords}
        targetAppModule="rankTracker"
      />

      <div className="rank-tracker-keywords__btn-group">
        <BackButton isWhite isInitial>
          {translate('rankTrackerKeywords.buttons.cancel')}
        </BackButton>

        <ButtonComponent isGreen isInitial onClick={onSaveKeywords} isDisabled={!canSaveKeywords}>
          {translate('rankTrackerKeywords.buttons.save')}
        </ButtonComponent>
      </div>
    </div>
  );
};

RankTrackerKeywordsComponent.propTypes = {
  brandKeywords: keywordsList,
  canSaveKeywords: PropTypes.bool,
  error: PropTypes.string,
  onChangeKeywords: PropTypes.func.isRequired,
  onSaveKeywords: PropTypes.func.isRequired,
};

export default RankTrackerKeywordsComponent;
