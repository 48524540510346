import React from 'react';

const DashboardSecondIcon = (props) => (
  <svg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.52 6H3.814C3.365 6 3 5.663 3 5.25v-1.5c0-.413.365-.75.813-.75H6.52c.448 0 .812.337.812.75v1.5c0 .413-.364.75-.812.75zM3.814 3.5c-.15 0-.271.112-.271.25v1.5c0 .138.122.25.27.25h2.709c.149 0 .27-.112.27-.25v-1.5c0-.138-.121-.25-.27-.25H3.813zM12.25 13h-2.5c-.414 0-.75-.409-.75-.91v-1.822c0-.502.336-.91.75-.91h2.5c.414 0 .75.408.75.91v1.821c0 .502-.336.911-.75.911zm-2.5-3.036c-.137 0-.25.136-.25.304v1.821c0 .168.113.304.25.304h2.5c.137 0 .25-.136.25-.304v-1.821c0-.168-.113-.304-.25-.304h-2.5zM12.25 8h-2.5A.75.75 0 019 7.25v-3.5A.75.75 0 019.75 3h2.5a.75.75 0 01.75.75v3.5a.75.75 0 01-.75.75zm-2.5-4.5a.25.25 0 00-.25.25v3.5c0 .138.113.25.25.25h2.5a.25.25 0 00.25-.25v-3.5a.25.25 0 00-.25-.25h-2.5zM6.52 13H3.814C3.365 13 3 12.619 3 12.15V8.183c0-.468.365-.85.813-.85H6.52c.448 0 .812.382.812.85v3.967c0 .469-.364.85-.812.85zM3.814 7.9a.278.278 0 00-.271.283v3.967c0 .156.122.283.27.283h2.709c.149 0 .27-.127.27-.283V8.183a.278.278 0 00-.27-.283H3.813z" />
    <path d="M13.542 15H2.458A1.46 1.46 0 011 13.542V2.458A1.46 1.46 0 012.458 1h11.084A1.46 1.46 0 0115 2.458v11.084A1.46 1.46 0 0113.542 15zM2.458 1.583a.876.876 0 00-.875.875v11.084c0 .482.393.875.875.875h11.084a.876.876 0 00.875-.875V2.458a.876.876 0 00-.875-.875H2.458z" />
  </svg>
);

DashboardSecondIcon.defaultProps = {
  height: '16px',
  width: '16px',
};

export default DashboardSecondIcon;
