import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AhrefsSettings from './AhrefsSettings';
import CrawlingSettings from './CrawlingSettings';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';

import { policiesShape } from 'common/prop_types_shapes';
import { fetchRailsContext } from 'api/rails_context';
import { policiesSelector } from 'selectors/railsContextSelectors';

import Breadcrumbs from 'components/NewBreadcrumbs';

class AutomationSettingsPageContainer extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    policies: policiesShape,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchRailsContext(dispatch);
  }

  render() {
    const { policies } = this.props;

    const canManageCrawlingSettings = policies.getIn(['crawlerSettings', 'canView']);
    const canManageAhrefsConnection = policies.getIn(['ahrefsConnection', 'canUse']);

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.TextItem>{translate('sidebar.automationSettings')}</Breadcrumbs.TextItem>
        </Breadcrumbs>
        <div className="page__wrapper">
          {canManageCrawlingSettings && <CrawlingSettings />}
          {canManageAhrefsConnection && <AhrefsSettings />}
        </div>
      </>
    );
  }
}

function select(state, ownProps) {
  const policies = policiesSelector(state, ownProps);

  return {
    policies,
  };
}

export default ConnectStoreHOC(connect(select)(AutomationSettingsPageContainer));
