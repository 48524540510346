import { translate } from 'common/i18n';
import { showSuccessMessage } from 'common/utils';

import {
  fetchKeywordsWithChangesByUrlSuccess,
  fetchKeywordsWithChangesFailure,
  fetchKeywordsWithChangesStart,
  fetchKeywordsWithChangesSuccess,
  recheckKeywordsRankingsFailure,
} from 'actions/rankTrackerActions';

import { fetchBrandsContextSuccess } from 'actions/brandsContextActions';

import { getFromAPI, handleAPIError, postToAPI, processObjToSnakeCase } from 'api/utils';

async function fetchKeywordsWithChanges(dispatch, brandId, params) {
  dispatch(fetchKeywordsWithChangesStart());

  const { groupByUrl, sorting, dateStart, dateEnd } = params;

  const processedParams = {
    group_by_url: groupByUrl ? 1 : 0,
    sorting: processObjToSnakeCase(sorting),
  };

  dateStart && (processedParams['start_date'] = dateStart);
  dateEnd && (processedParams['end_date'] = dateEnd);

  try {
    const resp = await getFromAPI(
      Routes.rank_tracker_root_path({ brand_id: brandId }),
      processedParams,
    );

    if (params.groupByUrl) {
      dispatch(fetchKeywordsWithChangesByUrlSuccess(resp.brand_pages, brandId));
    } else {
      dispatch(fetchKeywordsWithChangesSuccess(resp.keywords, brandId));
    }
    dispatch(fetchBrandsContextSuccess(brandId, resp.brand_context));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchKeywordsWithChangesFailure,
      translate('errors.cantFetchKeywordsWithChanges'),
    );
  }
}

async function recheckKeywordsRankings(dispatch, brandId) {
  try {
    await postToAPI(Routes.rank_tracker_recheck_path({ brand_id: brandId }));
    showSuccessMessage(dispatch, translate('rankTracker.messages.recheckKeywordsRankingsStarted'));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      recheckKeywordsRankingsFailure,
      translate('errors.cantRecheckKeywordsRankings'),
    );
  }
}

export { fetchKeywordsWithChanges, recheckKeywordsRankings };
