import { List as iList, Map as iMap, fromJS } from 'immutable';
import { camelizeObjectDeep, mergeItemToList } from './utils';

import {
  CREATE_EO_INBOX__SUCCESS,
  DELETE_EO_INBOX__SUCCESS,
  FETCH_EO_INBOXES__SUCCESS,
  FETCH_EO_INBOX__SUCCESS,
  FETCH_EO_INBOXES_SETTINGS__SUCCESS,
  UPDATE_EO_INBOX__SUCCESS,
} from 'actions/eoInboxesSettingsActions';

import { ADD_OR_REMOVE_EO_CAMPAIGN_INBOX__SUCCESS } from 'actions/eoInboxActions';

const initialState = fromJS({
  eoInboxes: iList(),
  eoInboxesSettings: iMap(),
});

export default function eoInboxesSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_OR_REMOVE_EO_CAMPAIGN_INBOX__SUCCESS:
    case CREATE_EO_INBOX__SUCCESS:
    case FETCH_EO_INBOX__SUCCESS:
    case UPDATE_EO_INBOX__SUCCESS: {
      const { eoInbox } = action;

      state = state.set(
        'eoInboxes',
        mergeItemToList(state.get('eoInboxes'), eoInbox, camelizeObjectDeep),
      );

      break;
    }
    case DELETE_EO_INBOX__SUCCESS: {
      const { eoInboxId } = action;

      state = state.set(
        'eoInboxes',
        state.get('eoInboxes').filter((inbox) => inbox.get('id') !== eoInboxId),
      );

      break;
    }
    case FETCH_EO_INBOXES__SUCCESS: {
      const { eoInboxes } = action;

      state = state.set('eoInboxes', fromJS(eoInboxes.map(camelizeObjectDeep)));

      break;
    }
    case FETCH_EO_INBOXES_SETTINGS__SUCCESS: {
      const { eoInboxesSettings } = action;

      state = state.set('eoInboxesSettings', fromJS(camelizeObjectDeep(eoInboxesSettings)));

      break;
    }
  }

  return state;
}
