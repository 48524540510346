import { translate } from 'common/i18n';

const selectProps = {
  blurInputOnSelect: false,
  autoFocus: true,
  isClearable: true,
  isCreatable: false,
  menuIsOpen: true, // in order to get correctAtpWrapperBottomSpaceIfNeeded working
  openMenuOnFocus: true,
  placeholder: translate('destinationLink.defaultPlaceholder'),
  skinOptions: {
    inTable: true,
  },
  triggerChangeOnBlur: true,
};

const anchorTypeSelectProps = {
  ...selectProps,
  placeholder: translate('destinationLink.anchorType.placeholder'),
};

const statusSelectProps = {
  ...selectProps,
  placeholder: translate('destinationLink.status.placeholder'),
};

const sourceSelectProps = {
  ...selectProps,
  isCreatable: true,
  placeholder: translate('destinationLink.source.placeholder'),
};

export { anchorTypeSelectProps, sourceSelectProps, statusSelectProps };
