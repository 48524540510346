import React from 'react';

import EoDashboardCharts from './EoDashboardCharts';

import { translate } from 'common/i18n';

import Breadcrumbs from 'components/NewBreadcrumbs';

const EoDashboardPageComponent = () => {
  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('eoDashboard.breadcrumbsTitle')}</Breadcrumbs.TextItem>
      </Breadcrumbs>

      <div className="eo-dashboard page__wrapper">
        <EoDashboardCharts />
      </div>
    </>
  );
};

export default EoDashboardPageComponent;
