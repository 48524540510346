import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { translate } from 'common/i18n';
import { brandMetricsShape } from 'common/propTypesShapes/metrics';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import LabelComponent from 'components_linkio/label_component';
import MessageComponent from 'components_linkio/message_component';
import BinIcon from 'common/icons/bin';

import Link from 'components_linkio/link';

import './disavowPopup.scss';

class DisavowPopupComponent extends Component {
  static propTypes = {
    brand: brandMetricsShape,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  state = { file: {} };

  file = {};

  handleChooseFileButtonClick = () => this.file.click();

  handleOnChooseFile = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  render() {
    const { brand, onClose, onDelete, onSubmit } = this.props;
    const { file } = this.state;
    const {
      id,
      disavowDomains,
      disavowFilename,
      disavowFileS3Id,
      disavowLinks,
      disavowUploadedAt,
    } = brand;

    const existingDisavow = !isEmpty(disavowFilename);

    const uploadHintMessage = { text: translate('disavowPopup.uploadHintMessage') };

    const uploadedFileMetadata = {
      firstLine: { date: moment(disavowUploadedAt).format('MMMM DD, YYYY') },
      secondLine: {
        name: disavowFilename,
        domainsCount: disavowDomains.length,
        linksCount: disavowLinks.length,
      },
    };

    const downloadDisavowLink = Routes.download_disavow_file_brand_path(
      { id },
      { file_s3_id: disavowFileS3Id, filename: disavowFilename },
    );

    return (
      <Modal show>
        <Modal.Header>
          <Modal.Title>{translate('disavowPopup.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {existingDisavow && (
            <div>
              <div className="disavow-popup-component__subtitle">
                {translate('disavowPopup.edit.subtitle')}
              </div>
              <div className="disavow-popup-component__section">
                <div className="disavow-popup-component__download-file">
                  <div className="disavow-popup-component__file-name">{disavowFilename}</div>
                  <div className="disavow-popup-component__btn-group">
                    <div className="disavow-popup-component__btn">
                      <Link
                        href={downloadDisavowLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button-linkio button-linkio_is-blue button-linkio_is-small disavow-popup-component__download-link"
                      >
                        {translate('disavowPopup.buttons.download')}
                      </Link>
                    </div>
                    <div className="disavow-popup-component__btn">
                      <ButtonComponent isSmall isWhite onClick={onDelete}>
                        <BinIcon />
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
              <div className="disavow-popup-component__section">
                <div className="disavow-popup-component__file-metadata">
                  {translate('disavowPopup.fileMetadata.firstLine')(uploadedFileMetadata.firstLine)}
                  <br />
                  {translate('disavowPopup.fileMetadata.secondLine')(
                    uploadedFileMetadata.secondLine,
                  )}
                </div>
              </div>
            </div>
          )}
          {!existingDisavow && (
            <div>
              {translate('disavowPopup.new.subtitle')}
              <Link
                href={translate('disavowPopup.googleToolsLink.href')}
                target="_blank"
                rel="noopener noreferrer"
                className="disavow-popup-component__subtitle-link"
              >
                {translate('disavowPopup.googleToolsLink.text')}
              </Link>
            </div>
          )}
          <div className="disavow-popup-component__section">
            <LabelComponent text={translate('disavowPopup.inputs.file')} />
            <div className="disavow-popup-component__choose-file">
              <div className="disavow-popup-component__file-name">{file.name}</div>
              <div className="disavow-popup-component__choose-file-btn">
                <ButtonComponent isSmall isBlue onClick={this.handleChooseFileButtonClick}>
                  {translate('disavowPopup.buttons.chooseFile')}
                </ButtonComponent>
              </div>
              <input
                accept=".txt"
                className="disavow-popup-component__file-input"
                name="disavow-file-input"
                onChange={this.handleOnChooseFile}
                ref={(input) => (this.file = input)}
                type="file"
              />
            </div>
            {existingDisavow && <MessageComponent message={uploadHintMessage} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="disavow-popup-component__btn-group">
            <div className="disavow-popup-component__btn">
              <ButtonComponent isWhite onClick={onClose}>
                {translate('disavowPopup.buttons.cancel')}
              </ButtonComponent>
            </div>
            <div className="disavow-popup-component__btn">
              <ButtonComponent isGreen onClick={onSubmit(this.state.file)}>
                {translate('disavowPopup.buttons.submit')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DisavowPopupComponent;
