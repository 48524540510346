import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as edit from 'react-edit';
import { upperCase, cloneDeep, isEqual } from 'lodash';
import * as Table from 'reactabular-table';
import { fromJS, Map as iMap } from 'immutable';

import { translate } from 'common/i18n';

import columnsGenerator, { columnSelect, columnTextArea } from 'common/tables/columns_generator';

import { reactSelectColumn, textColumn } from 'common/tables/columns';

import {
  addCSSClassToCellInTable,
  generateDestinationLinkStatusForAtp,
  highlightTableRow,
  removeCSSClassFromTableCells,
  TABLE_CELL_ACTIVE_CLASS,
} from 'common/tables/utils';

import { destinationLinksMap, optionsMap, policiesShape } from 'common/prop_types_shapes';

import { optionValueOrValue } from 'components_linkio/Select/utils';
import OverlayWithTooltipComponent from 'components/overlay_with_tooltip_component';
import BadgeComponent from 'components_linkio/badge_component';

import Link from 'components_linkio/link';

import './duplicatesTable.scss';

const selectProps = {
  blurInputOnSelect: true,
  autoFocus: true,
  isClearable: true,
  openMenuOnFocus: true,
  skinOptions: {
    inTable: true,
  },
};

const anchorTypeSelectProps = {
  ...selectProps,
  placeholder: translate('destinationLink.anchorType.placeholder'),
};

const includeByUserSelectProps = {
  ...selectProps,
  isClearable: false,
  placeholder: translate('destinationLink.includeByUser.placeholderShort'),
};

export default class DuplicatesTable extends React.Component {
  static propTypes = {
    anchorTypeOptions: optionsMap.isRequired,
    canUseCrawlers: PropTypes.bool.isRequired,
    dlDuplicates: destinationLinksMap.isRequired,
    includeByUserOptions: optionsMap.isRequired,
    includedOptions: optionsMap.isRequired,
    onUpdateDl: PropTypes.func,
    policies: policiesShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      props,
      rows: props.dlDuplicates.toList().toJS(),
      columns: this.generateColumns(props),
      generateColumns: this.generateColumns,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const needToUpdState = !isEqual(nextProps, prevState.props);

    if (needToUpdState) {
      return {
        rows: nextProps.dlDuplicates.toList().toJS(),
        columns: prevState.generateColumns(nextProps),
        props: nextProps,
      };
    }

    return null;
  }

  componentDidMount() {
    this.setTablesRef();
  }

  tableRef = {};

  setTablesRef = () => {
    const tables = ReactDOM.findDOMNode(this).getElementsByTagName('table');
    this.tableRef = tables[0];
  };

  removeCellActiveClassFromAllCells = () => {
    removeCSSClassFromTableCells(this.tableRef, TABLE_CELL_ACTIVE_CLASS);
  };

  generateColumns = (props) => {
    const { anchorTypeOptions, includeByUserOptions } = props;

    const columns = columnsGenerator([
      reactSelectColumn({
        name: 'anchorType',
        headerLabel: translate('destinationLink.anchorType.title'),
        cellValueFormatter: this.anchorTypeValueFormatter,
        className: 'duplicates-table-component__anchor-type-column',
        cellTransforms: [
          columnSelect(this.editable, anchorTypeOptions.toList().toJS(), anchorTypeSelectProps),
        ],
      }),
      textColumn({
        name: 'anchorText',
        headerLabel: translate('destinationLink.anchorText.title'),
        cellTransforms: [columnTextArea(this.editable)],
        className: 'duplicates-table-component__anchor-text-column',
        showTooltip: true,
        isEditable: true,
      }),
      reactSelectColumn({
        name: 'includeByUser',
        headerLabel: translate('destinationLink.includeByUser.title'),
        className: 'duplicates-table-component__include-by-user-column',
        cellValueFormatter: this.includeByUserValueFormatter,
        cellTransforms: [
          columnSelect(
            this.editable,
            includeByUserOptions.toList().toJS(),
            includeByUserSelectProps,
          ),
        ],
      }),
    ]);
    // insert new column with index 1 in array
    columns.splice(0, 0, this.publishedLinkColumn());

    return columns;
  };

  publishedLinkColumn = () => {
    const { canUseCrawlers } = this.props;

    const cellFormatters = [
      (value, extra) => {
        const { rowData } = extra;

        const dlStatus = generateDestinationLinkStatusForAtp(fromJS(rowData), canUseCrawlers).map(
          (row) => {
            const { status } = row;
            const text = upperCase(status);
            const color = {
              blue: status === 'processing',
              green: status === 'included',
              orange: status === 'unvalidated',
              red: status === 'excluded' || status === 'error',
            };
            return <BadgeComponent small {...color} key={text} text={text} />;
          },
        );

        return (
          <OverlayWithTooltipComponent tooltip={{ text: value, placement: 'top', color: 'white' }}>
            <div className="common-field">
              <Link
                href={value}
                target="_blank"
                className="text text_one-line"
                rel="noopener noreferrer"
              >
                {value}
              </Link>
              <div className="atp-table__link-status">{dlStatus}</div>
            </div>
          </OverlayWithTooltipComponent>
        );
      },
    ];

    return {
      cell: {
        formatters: cellFormatters,
      },
      header: {
        label: translate('destinationLink.publishedLink.title'),
      },
      property: 'publishedLink',
      props: {
        className: 'duplicates-table-component__published-link-column',
      },
    };
  };

  editable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowData }) => {
      const { dlDuplicates, policies } = this.props;
      const { rows } = this.state;
      const canUpdateAtp = policies.getIn(['atp', 'canUpdate']);

      if (!canUpdateAtp) {
        return;
      }

      const index = dlDuplicates.toList().findIndex((dl) => dl.get('id') === rowData.id);
      const clonedRows = cloneDeep(rows);
      clonedRows[index].editing = columnIndex;

      addCSSClassToCellInTable(this.tableRef, index, columnIndex, TABLE_CELL_ACTIVE_CLASS);

      this.setState({ rows: clonedRows });
    },

    onValue: async ({ value, rowData, property }) => {
      const { dlDuplicates, onUpdateDl } = this.props;
      const { rows } = this.state;

      const index = dlDuplicates.toList().findIndex((dl) => dl.get('id') === rowData.id);
      const currentValue = rowData[property] || '';
      const newValue = optionValueOrValue(value) || '';
      const clonedRow = cloneDeep(rowData);
      const clonedRows = cloneDeep(rows);

      this.removeCellActiveClassFromAllCells();

      if (currentValue !== newValue) {
        highlightTableRow(this.tableRef, index);

        clonedRow[property] = newValue;

        await onUpdateDl(clonedRow);
      } else {
        Reflect.deleteProperty(clonedRow, 'editing');
        clonedRows[index] = clonedRow;

        this.setState({ rows: clonedRows });
      }
    },
  });

  anchorTypeValueFormatter = (value) => {
    const { anchorTypeOptions } = this.props;
    const processedValue = optionValueOrValue(value);

    const option = (anchorTypeOptions || iMap()).get(String(processedValue), iMap()).toJS();

    return (option || {}).label;
  };

  includeByUserValueFormatter = (value, extra) => {
    const {
      rowData: { included },
    } = extra;
    const { includedOptions } = this.props;
    const processedValue = optionValueOrValue(value);

    // the code below aims to properly build 4 possible values of include status:
    // Yes (user), No (user), Yes (auto), No (auto)
    const includedOption = (includedOptions || iMap()).get(String(included), iMap()).toJS();
    const includedLabel = (includedOption || {}).label;
    const postfix = processedValue === 'auto' ? 'auto' : 'userDefined';
    const translatedPostfix = translate(`destinationLink.includeByUser.postfix.${postfix}`);
    return `${includedLabel}${translatedPostfix}`;
  };

  render() {
    const { columns, rows } = this.state;

    return (
      <div className="duplicates-table-component">
        <Table.Provider className="duplicates-table-component__table" columns={columns}>
          <Table.Header />
          <Table.Body rowKey="id" rows={rows} />
        </Table.Provider>
      </div>
    );
  }
}
