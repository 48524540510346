import React from 'react';
import PropTypes from 'prop-types';

import BacklinksTable from './BacklinksTable';
import BacklinksBulkActions from './BacklinksBulkActions';

import { pagyShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import MetricsPanel from 'components_linkio/MetricsPanel';
import PageLayoutHeader from 'components_linkio/PageLayoutHeader';
import TutorialPopup from 'components_linkio/TutorialPopup';

import Pager from 'components/Pager';

import './backlinksPage.scss';

const BacklinksPageComponent = ({
  backlinksPageType,
  backlinksPagy,
  changeDateRange,
  dateEnd,
  dateStart,
  hasDateRange,
  hasFilters,
  onItemsSelectChange,
  onPagerClick,
  title,
}) => {
  return (
    <div className="backlinks-page">
      <div className="backlinks-page__row">
        <PageLayoutHeader
          title={title}
          Popup={
            <TutorialPopup
              title={translate('backlinks.title')}
              videoSrc="https://www.youtube.com/embed/LDBKqOwpwpE"
            />
          }
        />
      </div>

      {/* Show metrics panel only for "All Backlinks" page type */}
      {!backlinksPageType && <MetricsPanel appModule="backlinks" />}

      <BacklinksBulkActions
        dateStart={dateStart}
        dateEnd={dateEnd}
        changeDateRange={changeDateRange}
      />

      <BacklinksTable
        hasDateRange={hasDateRange}
        hasFilters={hasFilters}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagerClick}
        pagerType="rowsPager"
        pagy={backlinksPagy}
      />
    </div>
  );
};

BacklinksPageComponent.propTypes = {
  backlinksPageType: PropTypes.oneOf(['new_links', 'lost_links', 're_discovered_links']),
  backlinksPagy: pagyShape,
  changeDateRange: PropTypes.func,
  dateEnd: PropTypes.instanceOf(Date),
  dateStart: PropTypes.instanceOf(Date),
  hasDateRange: PropTypes.bool.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  onItemsSelectChange: PropTypes.func,
  onPagerClick: PropTypes.func,
  title: PropTypes.string,
};

export default BacklinksPageComponent;
