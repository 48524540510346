import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import cx from 'classnames';

import './month.scss';

const Month = ({ month, onClickPrevMonth, onClickNextMonth }) => {
  const prevButtonClasses = cx('month__button', 'month__button_prev', {
    month__button_hide: !onClickPrevMonth,
  });

  const nextButtonClasses = cx('month__button', 'month__button_next', {
    month__button_hide: !onClickNextMonth,
  });

  return (
    <div className="month">
      <div
        className={prevButtonClasses}
        onClick={onClickPrevMonth}
      >
        <div className="month__icon month__icon_prev" />
      </div>

      <div>{format(month, 'MMMM yyyy')}</div>

      <div
        className={nextButtonClasses}
        onClick={onClickNextMonth}
      >
        <div className="month__icon month__icon_next" />
      </div>
    </div>
  );
};

Month.propTypes = {
  month: PropTypes.instanceOf(Date),
  onClickNextMonth: PropTypes.func,
  onClickPrevMonth: PropTypes.func,
};

export default Month;
