import React from 'react';
import PropTypes from 'prop-types';

import ResumeEoProgressionsPopup from './ResumeEoProgressionsPopup';

import { translate } from 'common/i18n';

import Select from 'components_linkio/Select';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import './eoProgressionActionsSelect.scss';

const options = [
  {
    value: 'resumeEoProgressions',
    label: translate('eoProgression.bulkActions.resumeEoProgressions'),
  },
  {
    value: 'cancelEoProgressions',
    label: translate('eoProgression.bulkActions.cancelEoProgressions'),
  },
];

const EoProgressionActionsSelectComponent = ({ isDisabled, onSelect }) => {
  const [showResumeProgressionsPopup, setShowResumeProgressionsPopup] = React.useState(false);

  function onChange(obj) {
    const { value } = obj;

    const needToShowPopup = ['resumeEoProgressions'].includes(value);

    if (needToShowPopup) {
      setShowResumeProgressionsPopup(true);
      return;
    }

    onSelect(value);
  }

  function onClosePopup() {
    setShowResumeProgressionsPopup(false);
  }

  return (
    <>
      <div className="eo-progression-actions-select">
        <Select
          blurInputOnSelect
          isClearable={false}
          isDisabled={isDisabled}
          onChange={onChange}
          options={options}
          triggerChangeOnBlur={false}
          value={{ label: translate('uiComponents.select.selectAction') }}
        />
      </div>

      <ToggleShowPopup
        Popup={<ResumeEoProgressionsPopup />}
        show={showResumeProgressionsPopup}
        onCloseCb={onClosePopup}
      />
    </>
  );
};

EoProgressionActionsSelectComponent.propTypes = {
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default EoProgressionActionsSelectComponent;
