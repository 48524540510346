import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  assignEoDripListToCampaignFailure,
  fetchEoDripListsStart,
  fetchEoDripListsSuccess,
  fetchEoDripListsFailure,
  fetchEoDripListStart,
  fetchEoDripListSuccess,
  fetchEoDripListFailure,
  fetchEoDripListsAsOptionsSuccess,
  createEoDripListStart,
  createEoDripListSuccess,
  createEoDripListFailure,
  updateEoDripListStart,
  updateEoDripListSuccess,
  updateEoDripListFailure,
  deleteEoDripListsStart,
  deleteEoDripListsSuccess,
  deleteEoDripListsFailure,
} from 'actions/eoDripListActions';

import { fetchEoCampaignOptionsSuccess } from 'actions/eoCampaignOptionsActions.js';
import { resetSelectedIds } from 'actions/ui_actions';
import { fetchEoDripListsPagySuccess } from 'actions/pagy_actions';

import { translate } from 'common/i18n';

import { showSuccessMessage, showSuccessMessageFromTranslation } from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCase,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoDripLists(dispatch, params) {
  dispatch(fetchEoDripListsStart());

  try {
    const resp = await getFromAPI(Routes.eo_drip_lists_path(), processObjToSnakeCaseDeep(params));
    dispatch(fetchEoDripListsSuccess(resp.eo_drip_lists));
    dispatch(fetchEoDripListsPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoDripListsFailure,
      translate('eoDripList.messages.cantFetchEoDripLists'),
    );
  }
}

export async function fetchEoDripListsAsOptions(dispatch) {
  try {
    const resp = await getFromAPI(Routes.eo_drip_lists_as_options_path());
    dispatch(fetchEoDripListsAsOptionsSuccess(resp.eo_drip_lists));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoDripListsFailure,
      translate('eoDripList.messages.cantFetchEoDripLists'),
    );
  }
}

export async function fetchEoDripList(dispatch, eoDripListId) {
  dispatch(fetchEoDripListStart());

  try {
    const eoDripList = await getFromAPI(Routes.eo_drip_list_path({ id: eoDripListId }));
    dispatch(fetchEoDripListSuccess(eoDripList));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoDripListFailure,
      translate('eoDripList.messages.cantFetchEoDripList'),
    );
  }
}

export async function createEoDripList(dispatch, data) {
  dispatch(createEoDripListStart());

  try {
    const resp = await postToAPI(Routes.eo_drip_lists_path(), eoDripListObject(data));

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(createEoDripListSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoDripList.messages.createSuccess');
    } else {
      dispatch(createEoDripListFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createEoDripListFailure,
      translate('eoDripList.messages.cantCreateEoDripList'),
    );
  }
}

export async function updateEoDripList(dispatch, data) {
  dispatch(updateEoDripListStart());

  try {
    const resp = await patchToAPI(
      Routes.eo_drip_list_path({ id: data.id }),
      eoDripListObject(data),
    );

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(updateEoDripListSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoDripList.messages.updateSuccess');
    } else {
      dispatch(updateEoDripListFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoDripListFailure,
      translate('eoDripList.messages.cantUpdateEoDripList'),
    );
  }
}

export async function deleteEoDripLists(dispatch, ids) {
  dispatch(deleteEoDripListsStart());

  try {
    await deleteToAPI(Routes.eo_drip_lists_path({ ids }));

    showSuccessMessage(dispatch, translate('eoDripList.messages.deleteSuccess')(ids.length));

    dispatch(resetSelectedIds());
    dispatch(deleteEoDripListsSuccess(ids));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoDripListsFailure,
      translate('eoDripList.messages.cantDeleteEoDripLists'),
    );
  }
}

export async function assignEoDripListToCampaign(dispatch, eoDripListId, eoCampaignId) {
  try {
    const updatedEoCampaignOptions = await patchToAPI(
      Routes.assign_eo_drip_list_eo_campaign_path({ id: eoCampaignId }),
      {
        eo_drip_list_id: eoDripListId,
      },
    );
    dispatch(fetchEoCampaignOptionsSuccess(updatedEoCampaignOptions));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      assignEoDripListToCampaignFailure,
      translate('eoDripList.messages.cantAssignEoDripListToCampaign'),
    );
  }
}

const eoDripListObject = (data) => ({ eo_drip_list: processObjToSnakeCase(data) });
