import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import ProspectsTableComponent from './ProspectsTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import useIsEqualPreviousValue from 'hooks/useIsEqualPreviousValue';

import { currentMarketerSelector, policiesSelector } from 'selectors/railsContextSelectors';
import { fetchProspects } from 'api/prospects';
import { prospectsList } from 'common/propTypesShapes/prospects';
import { prospectsPagySelector, prospectsSelector } from 'selectors/prospectsSelector';
import { resetSelectedIds, toggleSelectedIds } from 'actions/ui_actions';
import { pagyShape, selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import { uiSelector } from 'selectors';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';
import TableLoadingWrapper from 'components_linkio/TableLoadingWrapper';

const ProspectsTableContainer = ({
  canUpdate,
  changeQueryParams,
  dispatch,
  isReadOnly,
  onRowClick,
  prospects,
  prospectsImportInProgress,
  prospectsPagy,
  queryParams,
  selectedIds,
}) => {
  const [isFetchingProspects, setIsFetchingProspects] = React.useState(false);

  const {
    pageNumber = 1,
    pagyItemsCount = 50,
    search = '',
    filters = {},
    sorting = {},
  } = queryParams;

  const isImportingJustFinished =
    !useIsEqualPreviousValue(prospectsImportInProgress, false) && !prospectsImportInProgress;

  React.useEffect(() => {
    async function fetchData() {
      const fetchingParams = {
        items: pagyItemsCount,
        page: pageNumber,
        filters,
        search,
        sorting,
      };

      setIsFetchingProspects(true);
      await fetchProspects(dispatch, fetchingParams);
      setIsFetchingProspects(false);
    }

    fetchData();
  }, [
    dispatch,
    pageNumber,
    pagyItemsCount,
    JSON.stringify(filters),
    search,
    sorting.sortBy,
    sorting.sortingOrder,
  ]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectedIds());
    };
  }, [dispatch]);

  // If importing just finished, then drop pager to default values
  React.useEffect(() => {
    function dropPagerValuesToDefault() {
      const newQueryParams = {
        ...queryParams,
        pageNumber: 1,
        pagyItemsCount: 100,
      };

      changeQueryParams(newQueryParams);
    }

    isImportingJustFinished && dropPagerValuesToDefault();
  });

  function handleToggleSelectedRows(prospectsIds, isSelected) {
    dispatch(toggleSelectedIds(prospectsIds, isSelected));
  }

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  const prospectsCount = prospectsPagy.get('count');

  return (
    <>
      <TableLoadingWrapper
        isLoading={prospectsImportInProgress}
        loadingMessage={translate('prospects.table.dataNotReadyYet.importingInProgress')}
      >
        {prospects.size > 0 && (
          <ProspectsTableComponent
            canUpdate={canUpdate}
            isReadOnly={isReadOnly}
            onRowClick={onRowClick}
            onTableHeaderClick={handleTableHeaderClick}
            onToggleSelectedRows={handleToggleSelectedRows}
            prospects={prospects}
            prospectsCount={prospectsCount}
            selectedIds={selectedIds}
            sorting={sorting}
          />
        )}
      </TableLoadingWrapper>

      {prospects.size === 0 && (
        <BlankStateCtaComponent isLoading={isFetchingProspects}>
          <TitleText>
            <strong>{translate('prospects.table.blankState.title')}</strong>
          </TitleText>
          <BodyText>{translate('prospects.table.blankState.body')}</BodyText>
        </BlankStateCtaComponent>
      )}
    </>
  );
};

ProspectsTableContainer.propTypes = {
  canUpdate: PropTypes.bool,
  dispatch: PropTypes.func,
  isReadOnly: PropTypes.bool,
  onRowClick: PropTypes.func,
  prospects: prospectsList,
  prospectsImportInProgress: PropTypes.bool,
  prospectsPagy: pagyShape,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const prospects = prospectsSelector(state);
  const prospectsPagy = prospectsPagySelector(state);

  const policies = policiesSelector(state);
  const canUpdate = policies.getIn(['emailOutreach', 'canUpdate']);

  const currentMarketer = currentMarketerSelector(state);
  const prospectsImportInProgress = currentMarketer.get('prospects_import_in_progress');

  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    canUpdate,
    prospects,
    prospectsImportInProgress,
    prospectsPagy,
    selectedIds,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(ProspectsTableContainer);
