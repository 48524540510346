import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map as iMap } from 'immutable';

import { compose } from 'lodash/fp';
import { isEmpty } from 'lodash';

import { draftBrandPagesMap, optionsMap } from 'common/prop_types_shapes';
import {
  addPagesToAtp,
  getTitleTagsByUrl,
  importPageManually,
  updateDraftBrandPage,
} from 'api/bulkPagesSetup';
import { currentIdSelector, optionsSelector } from 'selectors';
import { bulkSetupBrandPagesSelector } from 'selectors/bulkPagesSetupSelector';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import defaultMetricsParams from 'utils/routeUtils';
import { showSuccessMessage } from 'common/utils';
import { translate } from 'common/i18n';

import AtpSimpleModePopupComponent from './AtpSimpleModePopupComponent';

function areLinksEqual(link1, link2) {
  const linksAreStrictlyEqual = link1 === link2;
  const linksDifferBySlash = `${link1}/` === link2 || link1 === `${link2}/`;

  return linksAreStrictlyEqual || linksDifferBySlash;
}

async function createDraftBrandPage(dispatch, brandId, link, setIsLinkFormatErrorExist) {
  const draftBrandPage = await importPageManually(dispatch, brandId, link);

  if (!draftBrandPage) {
    setIsLinkFormatErrorExist(true);

    return 'error';
  }

  setIsLinkFormatErrorExist(false);

  return draftBrandPage;
}

const AtpSimpleModePopupContainer = ({
  brandId,
  changeQueryParams,
  dispatch,
  draftBrandPages,
  history,
  pageTypeOptions,
  onClose,
  queryParams,
}) => {
  const [draftBrandPage, setDraftBrandPage] = useState({});
  const [isLinkFormatErrorExist, setIsLinkFormatErrorExist] = useState(false);
  const [bulkAddModeQueryParams] = useState(queryParams);
  const [titleTagsOfNonExistingPageUrl, setTitleTagsForNonExistingPageUrl] = useState('');
  const [metaKeywordsOfNonExistingPageUrl, setMetaKeywordsForNonExistingPageUrl] = useState([]);

  const isFetchingDraftBrandPages = draftBrandPages.size === 0;

  const currentDraftBrandPageFromGlobalState =
    draftBrandPages.find(
      (draftBrandPageFromGlobalState) =>
        draftBrandPageFromGlobalState.get('id') === draftBrandPage.id,
    ) || iMap();

  const keyToUpdateDraftBrandPage = JSON.stringify(
    currentDraftBrandPageFromGlobalState.get('keywords'),
  );

  // We need to store main mode query params in bulkAddModeQueryParams and drop them for simple
  // mode because links are always compared with existing pages' links when inserted in the popup.
  // If there're query params - not all pages are fetched to compare with.

  React.useEffect(() => {
    changeQueryParams({ simpleMode: true });
  }, []);

  React.useEffect(() => {
    currentDraftBrandPageFromGlobalState &&
      setDraftBrandPage(currentDraftBrandPageFromGlobalState.toJS());
    setIsLinkFormatErrorExist(false);
  }, [keyToUpdateDraftBrandPage]);

  async function fetchTitleTagsForNonExistingPageUrl(link) {
    const resp = await getTitleTagsByUrl(dispatch, link);

    const metaKeywords = resp.keywords;
    setMetaKeywordsForNonExistingPageUrl(metaKeywords);

    const titleTagsFromResponse = resp.title;
    const isTitleTagsExist =
      titleTagsFromResponse && !titleTagsFromResponse.includes('Nothing found for');
    const newTitleTags = isTitleTagsExist ? titleTagsFromResponse : '';

    setTitleTagsForNonExistingPageUrl(newTitleTags);
  }

  function handleCheckForExistingPageByLink(link) {
    // We need to compare links taking into account that a link with or without backslash
    // is the same link. So this should be done:
    // 1) to compare if the link has really changed when typing text in the input,
    // 2) to find existing link

    const isNewEnteredLinkReallyDifferent = !areLinksEqual(draftBrandPage.link, link);

    if (isNewEnteredLinkReallyDifferent) {
      const existingDraftBrandPage = draftBrandPages.find((draftBrandPage) =>
        areLinksEqual(draftBrandPage.get('link'), link),
      );

      existingDraftBrandPage &&
        setDraftBrandPage({ ...existingDraftBrandPage.toJS(), selected: true });
      !existingDraftBrandPage && fetchTitleTagsForNonExistingPageUrl(link);
      !existingDraftBrandPage && !isEmpty(draftBrandPage) && setDraftBrandPage({});
    }
  }

  // If an existing keyword has been edited - update the page with it's new value.

  async function handleUpdateExistingKeywords(existingKeywords) {
    await updateDraftBrandPage(dispatch, { ...draftBrandPage, keywords: existingKeywords });
  }

  async function handleSave(atpSimpleModeFormData, isAddingPage) {
    const { link, keywords, newKeywords, pageTypeId, status } = atpSimpleModeFormData;

    // Create the page if it doesn't exist

    const createdDraftBrandPage =
      isAddingPage &&
      (await createDraftBrandPage(dispatch, brandId, link, setIsLinkFormatErrorExist));

    if (createdDraftBrandPage === 'error') {
      return;
    }

    // Update the page with keywords no matter if it's a new page or existing page

    const pageForUpdate = isAddingPage
      ? { ...createdDraftBrandPage, keywords: newKeywords, pageTypeId: pageTypeId || '1' }
      : { ...atpSimpleModeFormData, keywords: [...newKeywords, ...keywords] };

    await updateDraftBrandPage(dispatch, pageForUpdate);

    if (status !== 'in_atp') {
      const { id: brandPageId } = pageForUpdate;

      // If the page is not in ATP, add it to ATP

      const resp = await addPagesToAtp(dispatch, brandId, [brandPageId]);

      if (!resp.success) {
        return;
      }

      // Redirect to ATP page and spread the added page

      history.push(
        Routes.brand_page_tab_path(brandId, brandPageId, 'backlinks', defaultMetricsParams()),
      );
    }
  }

  function handleClose() {
    changeQueryParams({ ...bulkAddModeQueryParams, simpleMode: false });

    onClose();
  }

  function showNewKeywordsAddedMessage(newKeywordsCount) {
    showSuccessMessage(
      dispatch,
      translate('bulkPagesSetup.messages.addNewKeywords')(newKeywordsCount),
    );
  }

  return (
    <AtpSimpleModePopupComponent
      draftBrandPage={draftBrandPage}
      handleCheckForExistingPageByLink={handleCheckForExistingPageByLink}
      handleSubmit={handleSave}
      handleUpdateExistingKeywords={handleUpdateExistingKeywords}
      immutablePageTypeOptions={pageTypeOptions}
      isLinkFormatErrorExist={isLinkFormatErrorExist}
      isLinkInputDisabled={isFetchingDraftBrandPages}
      metaKeywordsOfNonExistingPageUrl={metaKeywordsOfNonExistingPageUrl}
      onClose={handleClose}
      showNewKeywordsAddedMessage={showNewKeywordsAddedMessage}
      titleTagsOfNonExistingPageUrl={titleTagsOfNonExistingPageUrl}
    />
  );
};

AtpSimpleModePopupContainer.propTypes = {
  brandId: PropTypes.number,
  dispatch: PropTypes.func,
  draftBrandPages: draftBrandPagesMap,
  onClose: PropTypes.func,
  pageTypeOptions: optionsMap,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const options = optionsSelector(state, ownProps);
  const pageTypeOptions = options.get('pageTypeOptions');

  const brandId = currentIdSelector(state, ownProps);
  const draftBrandPages = bulkSetupBrandPagesSelector(state, ownProps);

  return {
    brandId: parseInt(brandId, 10),
    draftBrandPages,
    pageTypeOptions,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(AtpSimpleModePopupContainer);
