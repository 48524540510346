import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';
import MagnifierIcon from 'common/icons/magnifier';

import EoCampaignEmailTemplatesPopup from '../EoCampaignEmailTemplatesPopup';

const SelectEoEmailTemplateButtonComponent = ({ onSubmitCb }) => {
  return (
    <ToggleShowPopup onSubmitCb={onSubmitCb} Popup={<EoCampaignEmailTemplatesPopup />}>
      <ButtonComponent className="eo-campaign-step-email__email-template-button" isWhite isInitial>
        <MagnifierIcon />

        {translate('eoCampaignStep.eoCampaignStepPopup.buttons.chooseTemplate')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

SelectEoEmailTemplateButtonComponent.propTypes = {
  onSubmitCb: PropTypes.func.isRequired,
};

export default SelectEoEmailTemplateButtonComponent;
