import { handleAPIError } from './utils';

import {
  fetchEoCampaignStepsPreviewFailure,
  fetchEoCampaignStepsPreviewStart,
  fetchEoCampaignStepsPreviewSuccess,
  sendTestEmailsFailure,
} from 'actions/eoCampaignPreviewActions';

import { showSuccessMessage } from 'common/utils';
import { translate } from 'common/i18n';

import { getFromAPI, postToAPI, processObjToSnakeCaseDeep } from 'api/utils';

export async function fetchEoCampaignStepsPreview(dispatch, eoCampaignId, params) {
  dispatch(fetchEoCampaignStepsPreviewStart());

  try {
    const resp = await getFromAPI(
      Routes.steps_preview_eo_campaign_preview_index_path(eoCampaignId),
      {
        ...processObjToSnakeCaseDeep(params),
      },
    );

    dispatch(fetchEoCampaignStepsPreviewSuccess(resp));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoCampaignStepsPreviewFailure,
      translate('eoCampaignPreview.messages.cantFetchEoCampaignStepsPreview'),
    );
  }
}

export async function sendTestEmail(dispatch, eoCampaignId, eoInboxId, prospectId) {
  try {
    await postToAPI(Routes.send_test_emails_eo_campaign_preview_index_path(eoCampaignId), {
      eo_inbox_id: eoInboxId,
      prospect_id: prospectId,
    });

    showSuccessMessage(dispatch, translate('eoCampaignPreview.messages.sendTestEmailsSuccess'));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      sendTestEmailsFailure,
      translate('eoCampaignPreview.messages.sendTestEmailsFailure'),
    );
  }
}
