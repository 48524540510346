import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ImportIcon from 'common/icons/import';

const ImportDlsButtonComponent = ({ onImportClick }) => {
  const tooltip = {
    text: translate('explanationMessages.atpBulkAction.import'),
    placement: 'left',
    color: 'black',
  };

  return (
    <ButtonComponent isWhite onClick={onImportClick} tooltip={tooltip}>
      <span className="bulk-atp-actions-component__icon-wrapper">
        <ImportIcon />
      </span>
      {translate('destinationLink.buttons.importSmth')}
    </ButtonComponent>
  );
};

ImportDlsButtonComponent.propTypes = {
  onImportClick: PropTypes.func.isRequired,
};

export default ImportDlsButtonComponent;
