import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';

import { Dropdown } from 'react-bootstrap'; //TODO: Need to change to our component
import { translate } from 'common/i18n';

import { policiesShape } from 'common/prop_types_shapes';

import DotsIcon from 'common/icons/DotsIcon';
import ButtonComponent from 'components_linkio/button_component';

import './pageActions.scss';

const PageActionsComponent = ({
  onCategorizeClick,
  onDeletePageClick,
  onEditPageClick,
  onImportClick,
  onPageCrawlClick,
  policies,
  onPageMetricGenerateClick
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);

  function handleOnBlur() {
    setDropdownIsOpen(false);
  }

  function handleOnToggle(isOpen) {
    setDropdownIsOpen(isOpen);
  }

  function buildActions() {
    const canUpdateAtp = policies.getIn(['atp', 'canUpdate']);
    const canDestroyAtp = policies.getIn(['atp', 'canDestroy']);

    return fromJS([
      {
        title: translate('brandPage.buttons.edit'),
        handleClick: onEditPageClick,
        canUse: canUpdateAtp,
      },
      {
        title: translate('brandPage.buttons.import'),
        handleClick: onImportClick,
        canUse: true,
      },
      {
        title: translate('brandPage.buttons.crawl'),
        handleClick: onPageCrawlClick,
        canUse: true,
      },
      {
        title: translate('brandPage.buttons.categorize'),
        handleClick: onCategorizeClick,
        canUse: true,
      },
      {
        title: translate('brandPage.buttons.generateMetrics'),
        handleClick: onPageMetricGenerateClick,
        canUse: true
      },
      {
        title: translate('brandPage.buttons.delete'),
        handleClick: onDeletePageClick,
        canUse: canDestroyAtp,
      },
    ]);
  }

  const availableActions = buildActions().filter((action) => action.get('canUse'));

  return (
    <Dropdown
      className="brand-page-actions"
      id="brand-page-actions"
      onBlur={handleOnBlur}
      onToggle={handleOnToggle}
      open={dropdownIsOpen}
    >
      <CustomDropdownToggle bsRole="toggle">
        <ButtonComponent className="brand-page-actions__icon-wrapper" name="brandPageActionsToggle">
          <DotsIcon />
        </ButtonComponent>
      </CustomDropdownToggle>
      <CustomDropdownMenu bsRole="menu">
        <div
          className="
            dropdown-menu
            dropdown-menu-right
            brand-page-actions__dropdown-menu"
        >
          <ul className="brand-page-actions__dropdown-items-list">
            {availableActions.map((action) => {
              return (
                <li
                  key={action.get('title')}
                  className="brand-page-actions__item"
                  onMouseDown={action.get('handleClick')}
                >
                  <span>{action.get('title')}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </CustomDropdownMenu>
    </Dropdown>
  );
};

PageActionsComponent.propTypes = {
  onCategorizeClick: PropTypes.func.isRequired,
  onDeletePageClick: PropTypes.func.isRequired,
  onEditPageClick: PropTypes.func.isRequired,
  onImportClick: PropTypes.func.isRequired,
  onPageCrawlClick: PropTypes.func.isRequired,
  policies: policiesShape.isRequired,
};

export default PageActionsComponent;

// see https://react-bootstrap.github.io/components.html#btn-dropdowns-custom
// for more details about this crappy stuff
class CustomDropdownToggle extends Component {
  static propTypes = { children: PropTypes.node, onClick: PropTypes.func };
  shouldComponentUpdate() {
    return true;
  }
  render() {
    return <div onClick={this.props.onClick}>{this.props.children}</div>;
  }
}

// see https://react-bootstrap.github.io/components.html#btn-dropdowns-custom
// for more details about this crappy stuff
class CustomDropdownMenu extends Component {
  static propTypes = { children: PropTypes.node };
  shouldComponentUpdate() {
    return true;
  }
  render() {
    return this.props.children;
  }
}
