import React from 'react';

// The hook which allowing execute a callback function
// when a user clicks outside from component that used the hook.
const useClickOutsideCb = (ref, cb) => {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      cb();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export default useClickOutsideCb;
