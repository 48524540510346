import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import moment from 'moment';

import MyBrandsDashboardComponent from './MyBrandsDashboardComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import { brandsMetricsSelector } from 'selectors/accountDashboardSelector';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';
import { fetchBrandsMetrics } from 'api/accountDashboard';

const MyBrandsDashboardContainer = ({ dispatch, ...props }) => {
  // Use predefined date interval (last day), metrics don't depend on datepicker
  const dateStart = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const dateEnd = moment().format('YYYY-MM-DD');

  React.useEffect(() => {
    fetchBrandsMetrics(dispatch, dateStart, dateEnd);
  }, [dispatch, dateStart, dateEnd]);

  return <MyBrandsDashboardComponent {...props} />;
};

MyBrandsDashboardContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function select(state, ownProps) {
  const brandsMetrics = brandsMetricsSelector(state, ownProps).valueSeq().toJS();
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);
  const canUseMetrics = subscriptionPolicies.getIn(['metrics', 'canUse']);

  return {
    brandsMetrics,
    canUseMetrics,
  };
}
export default compose(ConnectStoreHOC, connect(select))(MyBrandsDashboardContainer);
