function processValue(value) {
  let newValue = value || '';

  if (typeof value === 'object') {
    newValue = (value || {}).value || '';
  }

  if ((value || {})._isAMomentObject) {
    newValue = value._d;
  }

  return String(newValue).replace("Create option ", '').replace(/"/g, '');
}

export {
  processValue,
};
