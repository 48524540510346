import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

const AutodiscoverPagesViaSitemapButtonComponent = ({ onClick, isLoading }) => {
  return (
    <ButtonComponent
      className="autodiscover-pages-via-sitemap-button"
      isInitial
      isSecondary
      onClick={onClick}
      isLoading={isLoading}
    >
      {translate('bulkPagesSetup.bulkActions.buttons.autodiscoverPagesViaSitemap')}
    </ButtonComponent>
  );
};

AutodiscoverPagesViaSitemapButtonComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AutodiscoverPagesViaSitemapButtonComponent;
