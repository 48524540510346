import { isEmpty } from 'lodash';
import { translate } from 'common/i18n';
import { showSuccessMessage } from 'common/utils';

import {
  buyEmailVerificationCreditsCheckoutSessionFailure,
  changeSubscriptionPlanFailure,
  changeSubscriptionPlanStart,
  fetchSubscriptionPlansFailure,
  fetchSubscriptionPlansStart,
  fetchSubscriptionPlansSuccess,
  launchCheckoutSessionFailure,
} from 'actions/subscription_plan_actions';

import { getFromAPI, patchToAPI, handleAPIError } from 'api/utils';

async function buyEmailVerificationCredits(dispatch, successPathname, creditsVolumeCount) {
  try {
    const resp = await patchToAPI(Routes.buy_email_verification_credits_path(), {
      success_pathname: successPathname,
      credits_volume_count: creditsVolumeCount,
    });
    if (isEmpty((resp || {}).error)) {
      return resp;
    }

    handleAPIError(
      dispatch,
      null,
      buyEmailVerificationCreditsCheckoutSessionFailure,
      translate('errors.cantLaunchCheckoutSession'),
    );

    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      buyEmailVerificationCreditsCheckoutSessionFailure,
      translate('errors.cantLaunchCheckoutSession'),
    );
  }
}

async function createCheckoutSession(dispatch, successPathname, selectedPlanId, startTrial) {
  dispatch(changeSubscriptionPlanStart());

  try {
    const resp = await patchToAPI(Routes.create_checkout_session_path(), {
      success_pathname: successPathname,
      subscription_plan_id: selectedPlanId,
      start_trial: startTrial,
    });
    if (isEmpty((resp || {}).error)) {
      return resp;
    }

    handleAPIError(
      dispatch,
      null,
      launchCheckoutSessionFailure,
      translate('errors.cantLaunchCheckoutSession'),
    );

    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      launchCheckoutSessionFailure,
      translate('errors.cantLaunchCheckoutSession'),
    );
  }
}

async function changeSubscriptionPlan(dispatch, selectedPlanId, isContinueTrial) {
  dispatch(changeSubscriptionPlanStart());

  try {
    const resp = await patchToAPI(Routes.change_subscription_plan_path(), {
      subscription_plan_id: selectedPlanId,
      continue_trial: isContinueTrial,
    });
    if (isEmpty((resp || {}).error)) {
      dataLayer && dataLayer.push({ event: 'SubscriptionBought' });
      showSuccessMessage(
        dispatch,
        translate('subscriptionLimitReachedPopup.messages.changeSubscriptionPlanSuccess'),
      );
    } else {
      dispatch(changeSubscriptionPlanFailure(resp));
      handleAPIError(
        dispatch,
        null,
        changeSubscriptionPlanFailure,
        translate('errors.cantChangeSubscriptionPlan'),
      );
    }
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      changeSubscriptionPlanFailure,
      translate('errors.cantChangeSubscriptionPlan'),
    );
  }
}

async function fetchSubscriptionPlans(dispatch) {
  dispatch(fetchSubscriptionPlansStart());

  try {
    const resp = await getFromAPI(Routes.subscription_plans_path());
    dispatch(fetchSubscriptionPlansSuccess(resp.subscription_plans));
    return resp.subscription_plans;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchSubscriptionPlansFailure,
      translate('errors.cantFetchSubscriptionPlans'),
    );
  }
}

export {
  buyEmailVerificationCredits,
  changeSubscriptionPlan,
  createCheckoutSession,
  fetchSubscriptionPlans,
};
