import React from 'react';
import PropTypes from 'prop-types';

import EoBlacklistedDomainsTable from './EoBlacklistedDomainsTable';
import EoBlacklistedDomainsBulkActions from './EoBlacklistedDomainsBulkActions';
import ProspectTabs from 'pages/ProspectsPage/ProspectTabs';

import { pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';

const EoBlacklistedDomainsComponent = ({
  canCreate,
  onItemsSelectChange,
  onPagesPagerClick,
  eoBlacklistedDomainsPagy,
}) => {
  return (
    <div className="eo-blacklisted-domains">
      <ProspectTabs />

      <EoBlacklistedDomainsBulkActions canCreate={canCreate} />

      <EoBlacklistedDomainsTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="rowsPager"
        pagy={eoBlacklistedDomainsPagy}
      />
    </div>
  );
};

EoBlacklistedDomainsComponent.propTypes = {
  canCreate: PropTypes.bool,
  eoBlacklistedDomainsPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoBlacklistedDomainsComponent;
