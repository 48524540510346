import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConnectStoreHOC from '../../../../startup/connect_store_hoc';

import { trackHelpcrunchEvent } from '../../../../common/utils';

import {
  brandShape,
} from '../../../../common/prop_types_shapes';

import {
  currentBrandSelector,
} from '../../../../selectors';

import {
  canUseCrawlersSelector,
} from '../../../../selectors/railsContextSelectors';

import {
  enqueueBrandsDlsCrawling,
} from '../../../../api/crawler';

import CrawlBrandBacklinksBtnComponent from './CrawlBrandBacklinksBtnComponent';


class CrawlBrandBacklinksBtnContainer extends React.PureComponent {
  static propTypes = {
    brand: brandShape,
    canUseCrawlers: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  handleClick = () => {
    const {
      brand,
      dispatch,
    } = this.props;

    enqueueBrandsDlsCrawling(dispatch, [brand.get('id')]);

    trackHelpcrunchEvent('click.crawl.brand');
  }

  render() {
    const {
      canUseCrawlers,
    } = this.props;

    return (
      <CrawlBrandBacklinksBtnComponent
        canUseCrawlers={canUseCrawlers}
        onClick={this.handleClick}
      />
    );
  }
}

function select(state, ownProps) {
  const canUseCrawlers = canUseCrawlersSelector(state, ownProps);
  const brand = currentBrandSelector(state, ownProps);

  return {
    brand,
    canUseCrawlers,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(CrawlBrandBacklinksBtnContainer)));
