import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoCampaignEmailTemplatesPopupComponent from './EoCampaignEmailTemplatesPopupComponent';

import { eoEmailTemplatesList } from 'common/propTypesShapes/eoEmailTemplates';

import { eoEmailTemplatesSelector } from 'selectors/eoEmailTemplatesSelector';

import { fetchEoEmailTemplatesByScope } from 'api/eoEmailTemplate';
import { fetchEoEmailTemplateFolders } from 'api/eoEmailTemplateFolder';
import { optionsSelector } from 'selectors';

import { optionsList } from 'common/prop_types_shapes';

import ConnectStoreHOC from 'startup/connect_store_hoc';

const EoCampaignEmailTemplatesPopupContainer = ({
  dispatch,
  eoEmailTemplateFoldersOptions,
  immutableEoEmailTemplates,
  onClose,
  onSubmit,
}) => {
  const [selectedEmailTemplateScope, setSelectedEmailTemplateScope] = useState(null);

  React.useEffect(() => {
    fetchEoEmailTemplateFolders(dispatch, {});

    const templateScope = selectedEmailTemplateScope === 'all' ? '' : selectedEmailTemplateScope;

    fetchEoEmailTemplatesByScope(dispatch, { templateScope });
  }, [dispatch, selectedEmailTemplateScope]);

  return (
    <EoCampaignEmailTemplatesPopupComponent
      onClose={onClose}
      onSubmit={onSubmit}
      immutableEoEmailTemplateFoldersOptions={eoEmailTemplateFoldersOptions}
      immutableEoEmailTemplates={immutableEoEmailTemplates}
      selectedEmailTemplateScope={selectedEmailTemplateScope}
      setSelectedEmailTemplateScope={setSelectedEmailTemplateScope}
    />
  );
};

EoCampaignEmailTemplatesPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  eoEmailTemplateFoldersOptions: optionsList,
  immutableEoEmailTemplates: eoEmailTemplatesList,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

function select(state) {
  const options = optionsSelector(state);

  const immutableEoEmailTemplates = eoEmailTemplatesSelector(state);

  return {
    eoEmailTemplateFoldersOptions: options.get('eoEmailTemplateFoldersOptions'),
    immutableEoEmailTemplates,
  };
}

export default compose(ConnectStoreHOC, connect(select))(EoCampaignEmailTemplatesPopupContainer);
