import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import ErrorBoundary from 'decorators/error-boundary';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { optionsSelector } from 'selectors';

import { policiesSelector } from 'selectors/railsContextSelectors';

import { permissionsSelector } from 'selectors/permissionSelectors';

import { optionsMap, permissionsMap, policiesShape } from 'common/prop_types_shapes';

import { fetchPermissions } from 'api/permissions';

import Breadcrumbs from 'components/NewBreadcrumbs';

import PermissionsComponent from './PermissionsComponent';

import './permissions.scss';

class PermissionsContainer extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    permissions: permissionsMap,
    policies: policiesShape.isRequired,
    profileTypeOptions: optionsMap,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    fetchPermissions(dispatch);
  }

  render() {
    const { permissions, policies, profileTypeOptions } = this.props;

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.TextItem>Permissions</Breadcrumbs.TextItem>
        </Breadcrumbs>
        <PermissionsComponent
          permissions={permissions}
          policies={policies}
          profileTypeOptions={profileTypeOptions}
        />
      </>
    );
  }
}

function select(state, ownProps) {
  const permissions = permissionsSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const profileTypeOptions = options.get('profileTypeOptions');

  return {
    permissions,
    policies,
    profileTypeOptions,
  };
}

export default compose(ConnectStoreHOC, connect(select), ErrorBoundary)(PermissionsContainer);
