import React from 'react';
import PropTypes from 'prop-types';

import ImportProspectsPopup from '../ImportProspectsPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const ImportProspectsButtonComponent = ({ isGoogleDriveAccessed }) => {
  return (
    <ToggleShowPopup
      className="import-prospects-button"
      Popup={<ImportProspectsPopup />}
      initialShowPopupState={isGoogleDriveAccessed}
    >
      <ButtonComponent isInitial isBlue>
        {translate('prospects.bulkActions.importProspects')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

ImportProspectsButtonComponent.propTypes = {
  isGoogleDriveAccessed: PropTypes.bool,
};

export default ImportProspectsButtonComponent;
