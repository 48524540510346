import React from 'react';
import PropTypes from 'prop-types';

import EoCampaignStepsList from './EoCampaignStepsList';
import EoCampaignStep from './EoCampaignStep';

import { eoCampaignStepsList } from 'common/propTypesShapes/eoCampaignSteps';

import './eoCampaignStepsPage.scss';

const EoCampaignStepsPageComponent = ({ immutableEoCampaignSteps, eoCampaignId, isFetching }) => {
  const preselectedEoCampaignStep = immutableEoCampaignSteps.toJS()[0] || {};

  const [selectedEoCampaignStep, setSelectedEoCampaignStep] = React.useState({});

  React.useEffect(() => {
    setSelectedEoCampaignStep(preselectedEoCampaignStep);
  }, [eoCampaignId, isFetching, immutableEoCampaignSteps.size]);

  function handleSelectEoCampaignStep(eoCampaignStep) {
    eoCampaignStep.id !== selectedEoCampaignStep.id && setSelectedEoCampaignStep(eoCampaignStep);
  }

  function handleAddEoCampaignStep(eoCampaignStepActionType) {
    const newEoCampaignStep = {
      id: null,
      position: immutableEoCampaignSteps.size + 1,
      eoCampaignStepActionType,
    };

    setSelectedEoCampaignStep(newEoCampaignStep);
  }

  return (
    <div className="eo-campaign-steps-page">
      <EoCampaignStepsList
        onSelectEoCampaignStep={handleSelectEoCampaignStep}
        selectedEoCampaignStep={selectedEoCampaignStep}
        onAddEoCampaignStep={handleAddEoCampaignStep}
      />

      <EoCampaignStep
        eoCampaignStep={selectedEoCampaignStep}
        onSelectEoCampaignStep={handleSelectEoCampaignStep}
      />
    </div>
  );
};

EoCampaignStepsPageComponent.propTypes = {
  eoCampaignId: PropTypes.string,
  immutableEoCampaignSteps: eoCampaignStepsList,
  isFetching: PropTypes.bool,
};

export default EoCampaignStepsPageComponent;
