import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';

import './creditCardRequirement.scss';

const CreditCardRequirementComponent = ({ onStripeBtnClick }) => (
  <div className="credit-card-requirement">
    <h1>{translate('uiComponents.userTypeSelection.creditCardRequirement.title')}</h1>
    <h3>{translate('uiComponents.userTypeSelection.creditCardRequirement.subtitle')}</h3>
    <h4>{translate('uiComponents.userTypeSelection.creditCardRequirement.hint')}</h4>

    <ButtonComponent
      className="credit-card-requirement__stripe-btn"
      isGreen
      onClick={onStripeBtnClick}
    >
      {translate('uiComponents.userTypeSelection.creditCardRequirement.stripeBtn')}
    </ButtonComponent>
  </div>
);

CreditCardRequirementComponent.propTypes = {
  onStripeBtnClick: PropTypes.func,
};

export default CreditCardRequirementComponent;
