import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import recalculatePercentageService from '../services/recalculatePercentageService';
import refreshDlsService from '../services/refreshDlsService';

import DlsTableComponent from './DlsTableComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  destinationLinksByBrandPageIdSelector,
  optionsSelector,
  visiblePageIdByParentSelector,
} from 'selectors';

import { createDl, updateDl } from 'api/destination_links';
import { destinationLinksMap, optionsMap, policiesShape } from 'common/prop_types_shapes';
import { getSelected } from 'pages/Pages/utils';
import { openDlDetailsPopup } from 'actions/popup_actions';
import { showErrorMessage } from 'common/utils';
import { policiesSelector, canUseCrawlersSelector } from 'selectors/railsContextSelectors';

const DlsTableContainer = ({
  anchorTypeOptions,
  canUseCrawlers,
  changeQueryParams,
  destinationLinks,
  dispatch,
  dlSourcesOptions,
  includeByUserOptions,
  includedOptions,
  nofollowOptions,
  options,
  pageChildType,
  pageParentType,
  pageTypeOptions,
  policies,
  queryParams,
  statusOptions,
  visiblePageId,
}) => {
  async function handleCreateDl(newDl, property, newValue) {
    const data = await createDl(dispatch, visiblePageId, newDl);

    const resultHasErrors = data.type && data.type === 'error';
    if (!resultHasErrors) {
      recalculatePercentageService(
        { dispatch, dlSourcesOptions },
        visiblePageId,
        property,
        newValue,
      );
    }
  }

  async function handleUpdateDl(links = void 0, property, newValue) {
    const linksToUpdate = links || destinationLinks.valueSeq().toJS();

    const data = await updateDl(dispatch, visiblePageId, linksToUpdate);

    const resultHasErrors = data.type && data.type === 'error';
    if (!resultHasErrors) {
      // TODO: get highlighting to work
      // handleHighlightRows(map(data, (row) => row.id));
      recalculatePercentageService(
        { dispatch, dlSourcesOptions },
        visiblePageId,
        property,
        newValue,
      );
    }
  }

  function handleDlDetailsClick(destinationLinkId) {
    dispatch(
      openDlDetailsPopup({
        destinationLinkId,
        pageChildType,
      }),
    );
  }

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = queryParams.sorting || {};

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  function handleUpdateRows(dls) {
    refreshDlsService({ dispatch }, dls);
  }

  function handleShowErrorMessage(message) {
    showErrorMessage(dispatch, message);
  }

  const selectedRows = getSelected(destinationLinks);
  const sorting = queryParams.sorting || {};

  return (
    <DlsTableComponent
      anchorTypeOptions={anchorTypeOptions}
      canUseCrawlers={canUseCrawlers}
      destinationLinks={destinationLinks.valueSeq().toJS()}
      dlSourcesOptions={dlSourcesOptions}
      includeByUserOptions={includeByUserOptions}
      includedOptions={includedOptions}
      nofollowOptions={nofollowOptions}
      onCreateDl={handleCreateDl}
      onDlDetailsClick={handleDlDetailsClick}
      onShowErrorMessage={handleShowErrorMessage}
      onTableHeaderClick={handleTableHeaderClick}
      onUpdateDl={handleUpdateDl}
      onUpdateRows={handleUpdateRows}
      options={options}
      pageParentType={pageParentType}
      pageTypeOptions={pageTypeOptions}
      policies={policies}
      statusOptions={statusOptions}
      selectedRows={selectedRows}
      sorting={sorting}
    />
  );
};

DlsTableContainer.propTypes = {
  anchorTypeOptions: optionsMap,
  canUseCrawlers: PropTypes.bool,
  destinationLinks: destinationLinksMap,
  dispatch: PropTypes.func,
  dlSourcesOptions: optionsMap,
  includeByUserOptions: optionsMap,
  includedOptions: optionsMap,
  nofollowOptions: optionsMap,
  options: optionsMap,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  pageTypeOptions: optionsMap,
  policies: policiesShape,
  statusOptions: optionsMap,
  visiblePageId: PropTypes.string,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const canUseCrawlers = canUseCrawlersSelector(state, ownProps);
  const destinationLinks = destinationLinksByBrandPageIdSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);
  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const anchorTypeOptions = options.get('anchorTypeOptions');
  const dlSourcesOptions = options.get('dlSourcesOptions');
  const includeByUserOptions = options.get('includeByUserOptions');
  const includedOptions = options.get('includedOptions');
  const nofollowOptions = options.get('nofollowOptions');
  const pageTypeOptions = options.get('pageTypeOptions');
  const statusOptions = options.get('linkStatusOptions');

  return {
    anchorTypeOptions,
    canUseCrawlers,
    destinationLinks,
    dlSourcesOptions,
    includeByUserOptions,
    includedOptions,
    nofollowOptions,
    options,
    pageTypeOptions,
    policies,
    statusOptions,
    visiblePageId,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(DlsTableContainer)));
