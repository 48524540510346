import React from 'react';

const PenIcon = (props) => {
  return (
    <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 16.667h7.5M13.75 2.917a1.768 1.768 0 012.5 2.5L5.833 15.833l-3.333.834.833-3.334L13.75 2.917z"
        stroke="#4F4F4F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

PenIcon.defaultProps = {
  width: '20px',
  height: '20px',
  cursor: 'pointer',
};

export default PenIcon;
