import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';
import * as selectabular from 'selectabular';

import { translate } from '../../../../../common/i18n';
import columnsGenerator from '../../../../../common/tables/columns_generator';
import { checkboxColumn, textColumn } from '../../../../../common/tables/columns';
import { brandPagesArray } from '../../../../../common/prop_types_shapes';
import { textTwoLinesWithEllipsis } from '../../../../../common/utils';

import Checkbox from '../../../../../components_linkio/Checkbox';

import './pagesTableComponent.scss';

class PagesTableComponent extends Component {
  static propTypes = {
    onUpdateRows: PropTypes.func,
    pages: brandPagesArray,
  };

  static defaultProps = {
    pages: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      columns: this.generateColumns(),
    };
  }

  headerCheckboxFormatters = () => {
    return (
      <Checkbox
        checked={this.isAllSelected()}
        className="brand-pages-table__checkbox"
        onChange={this.handleOnToggleSelectAll}
      />
    );
  }

  handleOnToggleSelectAll = (event) => {
    const { onUpdateRows, pages } = this.props;

    const rows = event.target.checked ? selectabular.all(pages) : selectabular.none(pages);

    onUpdateRows(rows);
  }

  handleOnToggleSelectRow = (rowId) => () => {
    const { onUpdateRows, pages } = this.props;

    const selectedFilter = (row) => row.id === rowId;
    const rows = selectabular.toggle(selectedFilter)(pages);

    onUpdateRows(rows);
  }

  cellCheckboxFormatters = (value, extra) => {
    const { rowData: { selected, id } } = extra;

    return (
      <Checkbox
        checked={selected}
        className="brand-pages-table__checkbox"
        onChange={this.handleOnToggleSelectRow(id)}
      />
    );
  }

  isAllSelected = () => {
    const { pages } = this.props;

    const selectedRowsCount = pages.filter((brand) => brand.selected).length;
    const rowsCount = pages.length;

    return rowsCount === selectedRowsCount;
  }

  generateColumns = () => {
    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [this.headerCheckboxFormatters],
        cellFormatters: [this.cellCheckboxFormatters],
        className: 'brand_pages-table__checkbox-column',
      }),
      textColumn({
        name: 'link',
        headerLabel: translate('dlExportCsvPopup.tableColumns.pages'),
        cellValueFormatter: this.longTextCellValueFormatter,
        className: 'brand_pages-table__link-column',
        showTooltip: true,
      }),
    ]);
  }

  longTextCellValueFormatter = (value) => {
    return <a href={value}>{textTwoLinesWithEllipsis(value, { breakAll: true })}</a>;
  }

  render() {
    const { columns } = this.state;
    const { pages: rows } = this.props;

    return (
      <div className="brand_pages-table__wrapper">
        <Table.Provider
          className="brand_pages-table__all"
          columns={columns}
        >
          <Table.Header />
          <Table.Body
            rowKey="id"
            rows={rows}
          />
        </Table.Provider>
      </div>
    );
  }
}

export default PagesTableComponent;
