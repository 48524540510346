import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutablejs-proptypes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DlsPercentageComponent from './DlsPercentageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  brandPageShape,
  optionsMap,
  percentageShape,
  subscriptionPoliciesShape,
} from 'common/prop_types_shapes';

import {
  optionsSelector,
  percentageByBrandPageIdSelector,
  somethingIsLoadingSelector,
  uncategorizedAnchorsCountByBrandPageIdSelector,
  visiblePageByParentSelector,
} from 'selectors';

import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';

const DlsPercentageContainer = ({
  page,
  pageChildType,
  pageParentType,
  pagePercentage,
  pageUncategorizedAnchorsCount,
  percentageSchemeOptions,
  somethingIsLoading,
  subscriptionPolicies,
}) => {
  return (
    <>
      {!somethingIsLoading && (
        <DlsPercentageComponent
          currentPercentagesSchemeId={page.get('percentageSchemeId')}
          pageChildType={pageChildType}
          pageId={page.get('id')}
          pageParentType={pageParentType}
          percentage={pagePercentage.toJS()}
          percentageSchemeOptions={percentageSchemeOptions}
          subscriptionPolicies={subscriptionPolicies}
          uncategorizedAnchorsCount={pageUncategorizedAnchorsCount}
        />
      )}
    </>
  );
};

DlsPercentageContainer.propTypes = {
  page: brandPageShape,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
  pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
  pagePercentage: ImmutablePropTypes.listOf(percentageShape),
  pageUncategorizedAnchorsCount: PropTypes.number,
  percentageSchemeOptions: optionsMap,
  somethingIsLoading: PropTypes.bool,
  subscriptionPolicies: subscriptionPoliciesShape,
};

function select(state, ownProps) {
  const page = visiblePageByParentSelector(state, ownProps);
  const pagePercentage = percentageByBrandPageIdSelector(state, ownProps);
  const pageUncategorizedAnchorsCount = uncategorizedAnchorsCountByBrandPageIdSelector(
    state,
    ownProps,
  );
  const somethingIsLoading = somethingIsLoadingSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  const options = optionsSelector(state, ownProps);
  const percentageSchemeOptions = options.get('percentageSchemeOptions');

  return {
    page,
    pagePercentage,
    pageUncategorizedAnchorsCount,
    percentageSchemeOptions,
    somethingIsLoading,
    subscriptionPolicies,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(DlsPercentageContainer)));
