import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  dlDuplicatesSelector,
  optionsSelector,
} from 'selectors';

import {
  currentSubscriptionSelector,
  policiesSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import { DlDetailsPopupComponent as DlDetailsPopup } from 'components/atp/dl_details_popup_component';

function select(state, ownProps) {
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const dlDuplicates = dlDuplicatesSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);
  const policies = policiesSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  const anchorTypeOptions = options.get('anchorTypeOptions');
  const includeByUserOptions = options.get('includeByUserOptions');
  const includedOptions = options.get('includedOptions');

  return {
    anchorTypeOptions,
    currentSubscription,
    dlDuplicates,
    includeByUserOptions,
    includedOptions,
    policies,
    subscriptionPolicies,
  };
}

export default ConnectStoreHOC(connect(select)(DlDetailsPopup));
