import React from 'react';

import AddEoBlacklistedDomainsPopup from '../AddEoBlacklistedDomainsPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const AddEoBlacklistedDomainButtonComponent = () => {
  return (
    <ToggleShowPopup Popup={<AddEoBlacklistedDomainsPopup />}>
      <ButtonComponent
        className="eo-blacklisted-domains-bulk-actions__add-eo_blacklisted_domain"
        isInitial
        isGreen
      >
        {translate('eoBlacklistedDomain.bulkActions.addEoBlacklistedDomain')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

export default AddEoBlacklistedDomainButtonComponent;
