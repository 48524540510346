import { isEmpty } from 'lodash';

import { translate } from 'common/i18n';
import { showSuccessMessageFromTranslation, showErrorMessageFromTranslation } from 'common/utils';

import {
  fetchBrandsStart,
  fetchBrandsSuccess,
  fetchBrandsFailure,
  createBrandStart,
  createBrandSuccess,
  createBrandFailure,
  updateBrandStart,
  updateBrandSuccess,
  updateBrandFailure,
  deleteBrandsStart,
  deleteBrandsSuccess,
  deleteBrandsFailure,
} from 'actions/brand_actions';

import { processDisavowStart, processDisavowFailureMessage } from 'actions/accountDashboardActions';

import { uploadTmpFileToS3 } from 'api/s3';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCase,
} from 'api/utils';

async function applyDisavowFile(dispatch, brandId, file) {
  dispatch(processDisavowStart({ id: brandId }));

  try {
    const s3response = await uploadTmpFileToS3(file);

    if (!s3response.ok) {
      return handleAPIError(
        dispatch,
        s3response.error,
        processDisavowFailureMessage,
        translate('errors.cantDisavowLinks'),
      );
    }

    const resp = await patchToAPI(Routes.apply_disavow_file_brand_path({ id: brandId }), {
      file_s3_id: s3response.fileS3Id,
      filename: file.name,
    });

    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      processDisavowFailureMessage,
      translate('errors.cantDisavowLinks'),
    );
  }
}

async function deleteDisavowFile(dispatch, brandId) {
  dispatch(processDisavowStart({ id: brandId }));

  try {
    const resp = await deleteToAPI(Routes.delete_disavow_file_brand_path({ id: brandId }));

    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      processDisavowFailureMessage,
      translate('errors.cantDisavowLinks'),
    );
  }
}

async function fetchBrand(dispatch, brandId) {
  dispatch(fetchBrandsStart());

  try {
    const brand = await getFromAPI(Routes.brand_path({ id: brandId }));
    dispatch(fetchBrandsSuccess([brand]));
  } catch (err) {
    handleAPIError(dispatch, err, fetchBrandsFailure, translate('errors.cantFetchBrands'));
  }
}

async function fetchBrandWithMetrics(dispatch, brandId) {
  dispatch(fetchBrandsStart());

  try {
    const brand = await getFromAPI(Routes.show_with_metrics_brand_path({ id: brandId }));
    dispatch(fetchBrandsSuccess([brand]));
  } catch (err) {
    handleAPIError(dispatch, err, fetchBrandsFailure, translate('errors.cantFetchBrands'));
  }
}

async function createBrand(dispatch, data) {
  dispatch(createBrandStart());

  try {
    const resp = await postToAPI(Routes.brands_path(), brandObject(data));
    if (isEmpty((resp || {}).errors)) {
      dispatch(createBrandSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'brand.messages.createSuccess');
    } else {
      dispatch(createBrandFailure(resp));
    }
    return resp;
  } catch (err) {
    handleAPIError(dispatch, err, createBrandFailure, translate('errors.cantCreateBrand'))
    return { type: 'error' };
  }
}

async function updateBrand(dispatch, data, showMessage = true) {
  dispatch(updateBrandStart());

  try {
    const resp = await patchToAPI(Routes.brand_path({ id: data.id }), brandObject(data));
    if (isEmpty((resp || {}).errors)) {
      dispatch(updateBrandSuccess(resp));
      if (showMessage) {
        showSuccessMessageFromTranslation(dispatch, 'brand.messages.updateSuccess');
      }
    } else {
      dispatch(updateBrandFailure(resp));
      showErrorMessageFromTranslation(dispatch, 'errors.cantUpdateBrand');
    }
    return resp;
  } catch (err) {
    handleAPIError(dispatch, err, updateBrandFailure, translate('errors.cantUpdateBrand'));
    return { type: 'error' };
  }
}

async function deleteBrands(dispatch, ids) {
  dispatch(deleteBrandsStart());

  try {
    const resp = await deleteToAPI(Routes.bulk_destroy_brands_path(), { ids });
    if (isEmpty((resp || {}).errors)) {
      dispatch(deleteBrandsSuccess(ids));
      showSuccessMessageFromTranslation(dispatch, 'brand.messages.deleteSuccess');
    } else {
      dispatch(deleteBrandsFailure(ids));
      showErrorMessageFromTranslation(dispatch, 'errors.cantDeleteBrands');
    }
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, deleteBrandsFailure, translate('errors.cantDeleteBrands'));
  }
}

const brandObject = (data) => ({ brand: processObjToSnakeCase(data) });

export {
  applyDisavowFile,
  deleteDisavowFile,
  fetchBrand,
  fetchBrandWithMetrics,
  createBrand,
  updateBrand,
  deleteBrands,
};
