const BACKLINK_CRAWLING__FAILURE = 'BACKLINK_CRAWLING__FAILURE';
const BACKLINK_CRAWLING__START = 'BACKLINK_CRAWLING__START';
const BACKLINK_CRAWLING__SUCCESS = 'BACKLINK_CRAWLING__SUCCESS';
const ENQUEUE_PAGE_DLS_CRAWLING__FAILURE = 'ENQUEUE_PAGE_DLS_CRAWLING__FAILURE';
const ENQUEUE_PAGE_DLS_CRAWLING__START = 'ENQUEUE_PAGE_DLS_CRAWLING__START';
const ENQUEUE_PAGE_DLS_CRAWLING__SUCCESS = 'ENQUEUE_PAGE_DLS_CRAWLING__SUCCESS';
const ENQUEUE_BRANDS_DLS_CRAWLING__FAILURE = 'ENQUEUE_BRANDS_DLS_CRAWLING__FAILURE';
const ENQUEUE_BRANDS_DLS_CRAWLING__START = 'ENQUEUE_BRANDS_DLS_CRAWLING__START';
const ENQUEUE_BRANDS_DLS_CRAWLING__SUCCESS = 'ENQUEUE_BRANDS_DLS_CRAWLING__SUCCESS';
const ENQUEUE_DESTINATION_LINK_CRAWLING__FAILURE = 'ENQUEUE_DESTINATION_LINK_CRAWLING__FAILURE';
const ENQUEUE_DESTINATION_LINK_CRAWLING__START = 'ENQUEUE_DESTINATION_LINK_CRAWLING__START';
const ENQUEUE_DESTINATION_LINK_CRAWLING__SUCCESS = 'ENQUEUE_DESTINATION_LINK_CRAWLING__SUCCESS';
const FETCH_CRAWLERS_STATUS__FAILURE = 'FETCH_CRAWLERS_STATUS__FAILURE';
const FETCH_CRAWLERS_STATUS__START = 'FETCH_CRAWLERS_STATUS__START';
const FETCH_CRAWLERS_STATUS__SUCCESS = 'FETCH_CRAWLERS_STATUS__SUCCESS';
const FETCH_CRAWLER_STATUS_DATA__FAILURE = 'FETCH_CRAWLER_STATUS_DATA__FAILURE';
const FETCH_CRAWLER_STATUS_DATA__START = 'FETCH_CRAWLER_STATUS_DATA__START';
const FETCH_CRAWLER_STATUS_DATA__SUCCESS = 'FETCH_CRAWLER_STATUS_DATA__SUCCESS';
const GOOGLE_INDEXED_CRAWLING__FAILURE = 'GOOGLE_INDEXED_CRAWLING__FAILURE';
const GOOGLE_INDEXED_CRAWLING__START = 'GOOGLE_INDEXED_CRAWLING__START';
const GOOGLE_INDEXED_CRAWLING__SUCCESS = 'GOOGLE_INDEXED_CRAWLING__SUCCESS';


function backlinkCrawlingFailure(dls) {
  return { type: BACKLINK_CRAWLING__FAILURE, dls };
}

function backlinkCrawlingStart(dls) {
  return { type: BACKLINK_CRAWLING__START, dls };
}

function backlinkCrawlingSuccess(dls) {
  return { type: BACKLINK_CRAWLING__SUCCESS, dls };
}

function googleIndexedCrawlingFailure(dls) {
  return { type: GOOGLE_INDEXED_CRAWLING__FAILURE, dls };
}

function googleIndexedCrawlingStart(dls) {
  return { type: GOOGLE_INDEXED_CRAWLING__START, dls };
}

function googleIndexedCrawlingSuccess(dls) {
  return { type: GOOGLE_INDEXED_CRAWLING__SUCCESS, dls };
}

function enqueuePageDlsCrawlingStart(brandPageId) {
  return { type: ENQUEUE_PAGE_DLS_CRAWLING__START, brandPageId };
}

function enqueuePageDlsCrawlingSuccess(brandPageId) {
  return { type: ENQUEUE_PAGE_DLS_CRAWLING__SUCCESS, brandPageId };
}

function enqueuePageDlsCrawlingFailure(errors, brandPageId) {
  return { type: ENQUEUE_PAGE_DLS_CRAWLING__FAILURE, errors, brandPageId };
}

function enqueueBrandsDlsCrawlingStart(brandIds) {
  return { type: ENQUEUE_BRANDS_DLS_CRAWLING__START, brandIds };
}

function enqueueBrandsDlsCrawlingSuccess(brandIds) {
  return { type: ENQUEUE_BRANDS_DLS_CRAWLING__SUCCESS, brandIds };
}

function enqueueBrandsDlsCrawlingFailure(errors, brandIds) {
  return { type: ENQUEUE_BRANDS_DLS_CRAWLING__FAILURE, errors, brandIds };
}

function enqueueDestinationLinkCrawlingStart(destinationLinkId) {
  return { type: ENQUEUE_DESTINATION_LINK_CRAWLING__START, destinationLinkId };
}

function enqueueDestinationLinkCrawlingSuccess(destinationLinkId) {
  return { type: ENQUEUE_DESTINATION_LINK_CRAWLING__SUCCESS, destinationLinkId };
}

function enqueueDestinationLinkCrawlingFailure(errors, destinationLinkId) {
  return { type: ENQUEUE_DESTINATION_LINK_CRAWLING__FAILURE, errors, destinationLinkId };
}

function fetchCrawlersStatusStart() {
  return { type: FETCH_CRAWLERS_STATUS__START };
}

function fetchCrawlersStatusSuccess(status) {
  return { type: FETCH_CRAWLERS_STATUS__SUCCESS, status };
}

function fetchCrawlersStatusFailure() {
  return { type: FETCH_CRAWLERS_STATUS__FAILURE };
}

function fetchCrawlerStatusDataStart() {
  return { type: FETCH_CRAWLER_STATUS_DATA__START };
}

function fetchCrawlerStatusDataSuccess(payload) {
  return { type: FETCH_CRAWLER_STATUS_DATA__SUCCESS, payload };
}

function fetchCrawlerStatusDataFailure() {
  return { type: FETCH_CRAWLER_STATUS_DATA__FAILURE };
}

export {
  BACKLINK_CRAWLING__FAILURE,
  BACKLINK_CRAWLING__START,
  BACKLINK_CRAWLING__SUCCESS,
  ENQUEUE_PAGE_DLS_CRAWLING__FAILURE,
  ENQUEUE_PAGE_DLS_CRAWLING__START,
  ENQUEUE_PAGE_DLS_CRAWLING__SUCCESS,
  ENQUEUE_BRANDS_DLS_CRAWLING__FAILURE,
  ENQUEUE_BRANDS_DLS_CRAWLING__START,
  ENQUEUE_BRANDS_DLS_CRAWLING__SUCCESS,
  ENQUEUE_DESTINATION_LINK_CRAWLING__FAILURE,
  ENQUEUE_DESTINATION_LINK_CRAWLING__START,
  ENQUEUE_DESTINATION_LINK_CRAWLING__SUCCESS,
  FETCH_CRAWLERS_STATUS__FAILURE,
  FETCH_CRAWLERS_STATUS__START,
  FETCH_CRAWLERS_STATUS__SUCCESS,
  FETCH_CRAWLER_STATUS_DATA__FAILURE,
  FETCH_CRAWLER_STATUS_DATA__START,
  FETCH_CRAWLER_STATUS_DATA__SUCCESS,
  GOOGLE_INDEXED_CRAWLING__FAILURE,
  GOOGLE_INDEXED_CRAWLING__START,
  GOOGLE_INDEXED_CRAWLING__SUCCESS,
  backlinkCrawlingFailure,
  backlinkCrawlingStart,
  backlinkCrawlingSuccess,
  enqueuePageDlsCrawlingFailure,
  enqueuePageDlsCrawlingStart,
  enqueuePageDlsCrawlingSuccess,
  enqueueBrandsDlsCrawlingFailure,
  enqueueBrandsDlsCrawlingStart,
  enqueueBrandsDlsCrawlingSuccess,
  enqueueDestinationLinkCrawlingFailure,
  enqueueDestinationLinkCrawlingStart,
  enqueueDestinationLinkCrawlingSuccess,
  fetchCrawlersStatusFailure,
  fetchCrawlersStatusStart,
  fetchCrawlersStatusSuccess,
  fetchCrawlerStatusDataFailure,
  fetchCrawlerStatusDataStart,
  fetchCrawlerStatusDataSuccess,
  googleIndexedCrawlingFailure,
  googleIndexedCrawlingStart,
  googleIndexedCrawlingSuccess,
};
