import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import buildBrandName from '../utils/buildBrandName';

import { buildEmptyCell, buildLinkText, getCellValue } from '../utils';

import { brandPageShape, brandPagesMap } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

const MetricsAnalysisTableComponent = ({ averages, brandPage, competitors }) => {
  const rows = [
    {
      key: 'brandName',
      title: void 0,
      cellValueFormatter: buildBrandName,
    },
    {
      key: 'link',
      title: void 0,
      cellValueFormatter: buildLinkText,
    },
    {
      key: 'pageMetrics',
      title: translate('metricsAnalysis.table.rows.pageMetrics.title'),
      isHeadLike: true,
    },
    {
      key: 'metrics.liveReferringDomains',
      title: translate('metricsAnalysis.table.rows.liveReferringDomains.title'),
    },
    {
      key: 'metrics.dfAndIndexedReferringDomains',
      title: translate('metricsAnalysis.table.rows.dfAndIndexedReferringDomains.title'),
    },
    {
      key: 'metrics.nfReferringDomains',
      title: translate('metricsAnalysis.table.rows.nfReferringDomains.title'),
    },
    {
      key: 'metrics.nfAndIndexedReferringDomains',
      title: translate('metricsAnalysis.table.rows.nfAndIndexedReferringDomains.title'),
    },
    {
      key: 'metrics.notIndexedReferringDomains',
      title: translate('metricsAnalysis.table.rows.notIndexedReferringDomains.title'),
    },
    {
      key: 'metrics.uriAhrefsRating',
      title: translate('metricsAnalysis.table.rows.uriAhrefsRating.title'),
    },
    {
      key: 'metrics.linkioScore',
      title: translate('metricsAnalysis.table.rows.linkioScore.title'),
    },
    {
      key: 'domainMetrics',
      title: translate('metricsAnalysis.table.rows.domainMetrics.title'),
      isHeadLike: true,
    },
    {
      key: 'metrics.domainRefdomainsCount',
      title: translate('metricsAnalysis.table.rows.domainRefdomainsCount.title'),
    },
    {
      key: 'metrics.homepageRefdomainsCount',
      title: translate('metricsAnalysis.table.rows.homepageRefdomainsCount.title'),
    },
    {
      key: 'metrics.domainAhrefsRating',
      title: translate('metricsAnalysis.table.rows.domainAhrefsRating.title'),
    },
  ];

  const enoughCompetitorsToScroll = competitors.size >= 4;
  const hasCompetitors = competitors.size > 0;

  function buildPageCells({ page, tableSection }) {
    function handleRow(row) {
      const hasBlueBoldText =
        ['brandName', 'metrics.linkioScore'].includes(row.key) && !row.isHeadLike;

      const cellClasses = classnames({
        'competitors-table__cell_header': row.isHeadLike,
        'competitors-table__cell_highlighted':
          !row.isAction && !row.isHeadLike && tableSection === 'averages',
        'competitors-table__cell_text-bold-blue': hasBlueBoldText,
        'competitors-table__cell': true,
      });

      return (
        <div key={row.key} className={cellClasses}>
          {getCellValue(row, page, tableSection)}
        </div>
      );
    }

    return rows.reduce((result, row) => {
      if (tableSection === 'emptyCells') {
        result.push(buildEmptyCell(row));
        return result;
      }

      result.push(handleRow(row));

      return result;
    }, []);
  }

  const headCellClasses = classnames({
    'competitors-table__cell': true,
    'competitors-table__cell_header': true,
  });

  const scrollableBoxClasses = classnames({
    'competitors-table__scrollable-box_item': true,
    'competitors-table__scrollable-item': enoughCompetitorsToScroll,
    'competitors-table__not-scrollable-item': !enoughCompetitorsToScroll,
  });

  return (
    <div className="competitors-table">
      <div className="competitors-table__row-headers-wrapper">
        <div className={headCellClasses} />
        {rows.map((row) => {
          const cellClasses = classnames({
            'competitors-table__cell': true,
            'competitors-table__cell_header': row.isHeadLike,
            'competitors-table__cell_text-bold-blue': !row.isHeadLike && !row.isAction,
            'competitors-table__cell_text-charcoal-grey-two': row.isAction,
            'competitors-table__cell_text-uppercased': row.key === 'metrics.linkioScore',
          });

          return (
            <div key={row.key} className={cellClasses}>
              {row.title}
            </div>
          );
        })}
      </div>

      <div className="competitors-table__competitors-wrapper">
        <div className={headCellClasses}>
          {translate('metricsAnalysis.table.columns.competitors.title')}
        </div>
        {hasCompetitors && (
          <div className="competitors-table__scrollable-box">
            {competitors
              .map((competitor) => (
                <div key={competitor.get('id')} className={scrollableBoxClasses}>
                  {buildPageCells({ page: competitor, tableSection: 'competitors' })}
                </div>
              ))
              .valueSeq()
              .toArray()}
          </div>
        )}
        {!hasCompetitors && (
          <div className={scrollableBoxClasses}>
            {buildPageCells({ page: void 0, tableSection: 'emptyCells' })}
          </div>
        )}
      </div>

      <div className="competitors-table__average-wrapper">
        <div className={headCellClasses}>
          {translate('metricsAnalysis.table.columns.average.title')}
        </div>
        {buildPageCells({ page: averages, tableSection: 'averages' })}
      </div>

      <div className="competitors-table__my-brand-wrapper">
        <div className={headCellClasses}>
          {translate('metricsAnalysis.table.columns.myBrand.title')}
        </div>
        {buildPageCells({ page: brandPage, tableSection: 'myPage' })}
      </div>
    </div>
  );
};

MetricsAnalysisTableComponent.propTypes = {
  averages: PropTypes.shape({
    liveReferringDomains: PropTypes.number,
    dfAndIndexedReferringDomains: PropTypes.number,
    nfReferringDomains: PropTypes.number,
    nfAndIndexedReferringDomains: PropTypes.number,
    notIndexedReferringDomains: PropTypes.number,
    uriAhrefsRating: PropTypes.number,
    domainRefdomainsCount: PropTypes.number,
    homepageRefdomainsCount: PropTypes.number,
    domainAhrefsRating: PropTypes.number,
  }),
  brandPage: brandPageShape,
  competitors: brandPagesMap,
};

export default MetricsAnalysisTableComponent;
