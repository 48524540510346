import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { translate } from 'common/i18n';
import { insertNotAvailableIfNull } from 'common/utils';

import ArrowIcon from 'common/icons/ArrowIcon';
import ButtonComponent from 'components_linkio/button_component';
import PlusInRoundIcon from 'common/icons/plus_in_round';

import './brandOverviewItem.scss';

const BrandOverviewItemComponent = ({ appModule, onVisitLink }) => {
  const { metrics, name, setupLink, CreateButton, viewMoreLink } = appModule;

  const appModuleHasMetrics = !metrics.isEmpty;

  const metricsWrapperClassNames = cx('brand-overview-item__metrics-wrapper', {
    'brand-overview-item__metrics-wrapper_with-metrics': appModuleHasMetrics,
  });

  return (
    <div className={`brand-overview-item brand-overview-item__${name}-overview`}>
      <div className="brand-overview-item__header-wrapper">
        <span className="brand-overview-item__app-module-title">
          {translate(`brandDashboard.brandOverview.${name}.title`)}
        </span>

        {appModuleHasMetrics && !CreateButton && (
          <ButtonComponent isGreen isInitial onClick={onVisitLink(setupLink)}>
            <PlusInRoundIcon className="brand-overview-item__add-icon" />
            {translate(`brandDashboard.brandOverview.${name}.buttons.add`)}
          </ButtonComponent>
        )}

        {appModuleHasMetrics && CreateButton && <CreateButton isGreen />}
      </div>

      <div className={metricsWrapperClassNames}>
        {appModuleHasMetrics ? (
          buildMetricsView(appModule, onVisitLink)
        ) : (
          <ButtonComponent isInitial onClick={onVisitLink(setupLink)}>
            {translate(`brandDashboard.brandOverview.${name}.buttons.setup`)}
          </ButtonComponent>
        )}
      </div>

      {appModuleHasMetrics && (
        <div className="brand-overview-item__view-more" onClick={onVisitLink(viewMoreLink)}>
          {translate('brandDashboard.brandOverview.viewMore')}
        </div>
      )}
    </div>
    
  );
};

BrandOverviewItemComponent.propTypes = {
  onVisitLink: PropTypes.func,
};

function buildMetricsView(appModule, onVisitLink) {
  switch (appModule.name) {
    case 'atp':
      return AtpMetrics(appModule, onVisitLink);
    case 'rankTracker':
      return RankTrackerMetrics(appModule, onVisitLink);
    case 'backlinks':
      return BacklinksMetrics(appModule, onVisitLink);
    case 'eoDripLists':
      return EoDripListMetrics(appModule, onVisitLink);
    case 'eoCampaigns':
      return EoCampaignsMetrics(appModule, onVisitLink);
  }
  return void 0;
}

const AtpMetrics = (appModule, onVisitLink) => {
  const metrics = appModule.metrics;
  return (
    <>
      <div className="brand-overview-item__metrics-item">
        {translate('brandDashboard.brandOverview.atp.metrics.pagesCount')}

        <div className="capybara--atp-pages-count">
          <span
            className="brand-overview-item__metrics-data-count"
            onClick={onVisitLink(appModule.viewMoreLink)}
          >
            {insertNotAvailableIfNull(metrics.pagesCount)}
          </span>
        </div>
      </div>

      <div className="brand-overview-item__metrics-item">
        {translate('brandDashboard.brandOverview.atp.metrics.anchorTextScore')}

        <div className="capybara--atp-anchor-text-score">
          <span
            className="brand-overview-item__metrics-data-count"
            onClick={onVisitLink(appModule.anchorTextScoreLink)}
          >
            {insertNotAvailableIfNull(metrics.anchorTextScore)}
          </span>
        </div>
      </div>
    </>
  );
};

const RankTrackerMetrics = (appModule, onVisitLink) => {
  const metrics = appModule.metrics;
  return (
    <>
      <div className="brand-overview-item__metrics-item">
        {translate('brandDashboard.brandOverview.rankTracker.metrics.keywordsCount')}

        <div className="capybara--rankTracker-keywords-count">
          <span
            className="brand-overview-item__metrics-data-count"
            onClick={onVisitLink(appModule.viewMoreLink)}
          >
            {insertNotAvailableIfNull(metrics.keywordsCount)}
          </span>
        </div>
      </div>

      <div className="brand-overview-item__metrics-item">
        {translate('brandDashboard.brandOverview.rankTracker.metrics.averageRanking')}

        <div className="brand-overview-item__average-ranking-count">
          <span
            className="brand-overview-item__metrics-data-count"
            onClick={onVisitLink(appModule.viewMoreLink)}
          >
            {insertNotAvailableIfNull(metrics.averageRanking.count)}
          </span>
          {buildMetricsDifference(metrics.averageRanking.difference)}
        </div>
      </div>

      <div className="brand-overview-item__metrics-item">
        {translate('brandDashboard.brandOverview.rankTracker.metrics.upOrDown')}

        <div className="brand-overview-item__up-down-metrics capybara--rankTracker-up-down-metrics">
          <span className="brand-overview-item__metrics-data-diff brand-overview-item__metrics-data-diff_up">
            {insertNotAvailableIfNull(metrics.positionDifference.up)}
            <ArrowIcon orientation="up" />
          </span>

          <span className="brand-overview-item__metrics-data-diff brand-overview-item__metrics-data-diff_down">
            {insertNotAvailableIfNull(metrics.positionDifference.down)}
            <ArrowIcon orientation="down" />
          </span>
        </div>
      </div>
    </>
  );
};

const BacklinksMetrics = (appModule, onVisitLink) => {
  const metrics = appModule.metrics;
  return (
    <>
      <div className="brand-overview-item__metrics-item">
        {translate('brandDashboard.brandOverview.backlinks.metrics.backlinksCount')}

        <div className="capybara--backlinks-count">
          <span
            className="brand-overview-item__metrics-data-count"
            onClick={onVisitLink(appModule.viewMoreLink)}
          >
            {insertNotAvailableIfNull(metrics.backlinks.count)}
          </span>
          {buildMetricsDifference(metrics.backlinks.difference)}
        </div>
      </div>
      <div className="brand-overview-item__metrics-item">
        {translate('brandDashboard.brandOverview.backlinks.metrics.refDomains')}

        <div className="capybara--backlinks-ref-domains-count">
          <span
            className="brand-overview-item__metrics-data-count"
            onClick={onVisitLink(appModule.viewMoreLink)}
          >
            {insertNotAvailableIfNull(metrics.refDomains.count)}
          </span>
          {buildMetricsDifference(metrics.refDomains.difference)}
        </div>
      </div>
    </>
  );
};

const EoDripListMetrics = (appModule, onVisitLink) => {
  const metrics = appModule.metrics;
  return (
    <>
      <div className="brand-overview-item__metrics-item">
        {translate('brandDashboard.brandOverview.eoDripLists.metrics.eoDripListsCount')}

        <div className="capybara--eo-drip-lists-count">
          <span
            className="brand-overview-item__metrics-data-count"
            onClick={onVisitLink(appModule.viewMoreLink)}
          >
            {insertNotAvailableIfNull(metrics.eoDripListsCount)}
          </span>
        </div>
      </div>
    </>
  );
};

const EoCampaignsMetrics = (appModule, onVisitLink) => {
  const metrics = appModule.metrics;
  return (
    <>
      <div className="brand-overview-item__metrics-item">
        {translate('brandDashboard.brandOverview.eoCampaigns.metrics.eoCampaignsCount')}

        <div className="capybara--eo-campaigns-count">
          <span
            className="brand-overview-item__metrics-data-count"
            onClick={onVisitLink(appModule.viewMoreLink)}
          >
            {insertNotAvailableIfNull(metrics.eoCampaignsCount)}
          </span>
        </div>
      </div>
    </>
  );
};

const buildMetricsDifference = (diff) => {
  const diffInt = Number(diff);

  const diffClassNames = cx('brand-overview-item__metrics-data-diff', {
    'brand-overview-item__metrics-data-diff_down': diffInt < 0,
    'brand-overview-item__metrics-data-diff_up': diffInt > 0,
  });

  return (
    <>
      <span className={diffClassNames}>
        {Math.abs(diffInt) || ''}
        {!!diffInt && <ArrowIcon orientation={diffInt < 0 ? 'down' : 'up'} />}
      </span>
    </>
  );
};

export default BrandOverviewItemComponent;
