import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import calculateAveragesService from '../services/calculateAveragesService';
import CompetitorAnalysisTableHeadlineComponent from '../CompetitorAnalysisTableHeadline';
import NumberOfBacklinksAnalysisTableComponent from './NumberOfBacklinksAnalysisTableComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { brandPageShape, brandPagesMap } from 'common/prop_types_shapes';
import { fetchSinglePage } from 'api/brand_page';
import { translate } from 'common/i18n';
import updatePageService from 'pages/Pages/services/updatePageService';

const NumberOfBacklinksAnalysisContainer = ({ competitorPages, dispatch, myBrandPage }) => {
  const handleChangeCountTowardAverages = (page) => async (value) => {
    const countTowardAverages = value === 'right';

    const pageData = {
      ...page.toJS(),
      countTowardAverages,
    };

    await updatePageService({ dispatch, pages: competitorPages }, pageData);

    // in order to update myBrandPage estimations
    fetchSinglePage(dispatch, myBrandPage.get('id'));
  };

  function buildAverages(competitorPages) {
    const metricKeys = [
      'dfAndIndexedReferringDomains',
      'domainRefdomainsCount',
      'homepageRefdomainsCount',
    ];

    return calculateAveragesService(competitorPages, metricKeys);
  }

  return (
    <>
      <CompetitorAnalysisTableHeadlineComponent
        title={translate('numberOfBacklinksAnalysis.table.title')}
      />
      <NumberOfBacklinksAnalysisTableComponent
        averages={buildAverages(competitorPages)}
        brandPage={myBrandPage}
        competitors={competitorPages}
        onChangeCountTowardAverages={handleChangeCountTowardAverages}
      />
    </>
  );
};

NumberOfBacklinksAnalysisContainer.propTypes = {
  competitorPages: brandPagesMap,
  dispatch: PropTypes.func,
  myBrandPage: brandPageShape,
};

export default ConnectStoreHOC(connect()(NumberOfBacklinksAnalysisContainer));
