import React from 'react';

const DisavowFileIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
      <path d="M7.337 12.444h.5828c.4515 0 .8188-.3558.8188-.7932 0-.4388-.3673-.796-.8188-.796-.9694-.1545-.5828.861-.5828 1.5892z" />
      <path d="M13.2867 8.023l-1.1311.0005-.0055-3.3433a.6737.6737 0 0 0-.197-.4755L8.0694.3208a.6725.6725 0 0 0-.4756-.197H1.1189a.6726.6726 0 0 0-.6725.6726v14.4072c0 .3715.3011.6726.6725.6726h10.3587a.6726.6726 0 0 0 .6725-.6726v-1.6552h1.1405a.2628.2628 0 0 0 .263-.2629l-.004-4.9996a.263.263 0 0 0-.2629-.263zm-3.4946-.0186l-5.0584.0038a.2634.2634 0 0 0-.2629.263l-.024 5.0275a.2624.2624 0 0 0 .2629.2629l6.5527.0136v1.5276l-9.8997-.0572-.0285-14.034 5.9552.0286.0572 3.5674c-.0572.4286.3733.384.7447.384l3.1997.0285.0054 3.0034z" />
      <g>
        <path d="M9.8009 11.3205l.6053-.0589q.0546.3048.2207.4477.1681.143.4518.143.3006 0 .452-.1262.1533-.1282.1533-.2984 0-.1093-.0651-.185-.063-.0777-.2228-.1345-.1093-.0378-.4981-.1345-.5002-.124-.702-.3048-.2838-.2543-.2838-.62 0-.2354.1325-.4393.1345-.206.3846-.3131.2522-.1072.6074-.1072.58 0 .8722.2543.2943.2543.309.6789l-.6221.0273q-.04-.2375-.1724-.3405-.1303-.105-.393-.105-.2711 0-.4246.1113-.0987.0715-.0987.1913 0 .1093.0924.187.1177.0988.5717.206.454.1072.6705.2228.2186.1135.3405.3132.124.1975.124.4897 0 .2648-.1471.496-.1472.2312-.4162.3447-.269.1114-.6705.1114-.5843 0-.8974-.269-.3132-.2712-.3741-.7882zM8.7458 12.323V9.2418h.622v3.0812zM5.6519 9.2418H6.789q.3846 0 .5864.0589.2711.0798.4645.2837.1934.2039.2942.5002.101.2943.101.7273 0 .3804-.0947.6557-.1156.3363-.33.5444-.1618.1576-.4371.2459-.206.0651-.5507.0651H5.652zm.6222.5213v2.0408h.4645q.2606 0 .3762-.0294.1513-.0379.25-.1282.101-.0904.164-.2964.063-.208.063-.5654 0-.3573-.063-.5485-.063-.1913-.1765-.2985-.1135-.1072-.288-.145-.1303-.0294-.5107-.0294z" aria-label="DIS" fill="#fff" />
      </g>
    </svg>
  );
};

DisavowFileIcon.defaultProps = {
  fill: '#E3E6E9',
  width: '16px',
  height: '16px',
};

export default DisavowFileIcon;
