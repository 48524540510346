export const NEWSLETTER_SETTINGS_UNSUBSCRIBE__FAILURE = 'NEWSLETTER_SETTINGS_UNSUBSCRIBE__FAILURE';
export const NEWSLETTER_SETTINGS_UNSUBSCRIBE__START = 'NEWSLETTER_SETTINGS_UNSUBSCRIBE__START';
export const NEWSLETTER_SETTINGS_UNSUBSCRIBE__SUCCESS = 'NEWSLETTER_SETTINGS_UNSUBSCRIBE__SUCCESS';

export function newsletterSettingsUnsubscribeFailure(errors) {
  return { type: NEWSLETTER_SETTINGS_UNSUBSCRIBE__FAILURE, errors };
}

export function newsletterSettingsUnsubscribeStart() {
  return { type: NEWSLETTER_SETTINGS_UNSUBSCRIBE__START };
}

export function newsletterSettingsUnsubscribeSuccess(newsletterSettings) {
  return { type: NEWSLETTER_SETTINGS_UNSUBSCRIBE__SUCCESS, newsletterSettings };
}
