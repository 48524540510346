import { fromJS, Map as iMap } from 'immutable';
import { mapKeys, camelCase } from 'lodash';

import {
  FETCH_CARD_INFO__FAILURE,
  FETCH_CARD_INFO__START,
  FETCH_CARD_INFO__SUCCESS,
  FETCH_CHARGES__FAILURE,
  FETCH_CHARGES__START,
  FETCH_CHARGES__SUCCESS,
} from 'actions/billing_actions';

const initialState = iMap();

const camelizeObject = (object) => {
  const camelizedObject = mapKeys(object, (v, k) => camelCase(k));

  return camelizedObject;
};

export default function billingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CARD_INFO__START:
    case FETCH_CARD_INFO__FAILURE:
    case FETCH_CHARGES__FAILURE:
    case FETCH_CHARGES__START: {
      break;
    }
    case FETCH_CARD_INFO__SUCCESS: {
      state = state.set('cardInfo', fromJS(action.cardInfo));

      break;
    }
    case FETCH_CHARGES__SUCCESS: {
      state = state.set(
        'stripeCharges',
        fromJS(action.charges.map(camelizeObject)),
      );

      break;
    }
  }

  return state;
}
