import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import OverlayWithTooltipComponent from '../overlay_with_tooltip_component';
import ExplanationIcon from '../../common/icons/explanation';

import './explanatory_tooltip_component.scss';

const explanatoryTooltipComponent = ({ text, isWhite, placement = 'top' }) => {
  const tooltip = {
    color: 'black',
    placement,
    text,
  };

  const iconClasses = classnames({
    'explanatory-tooltip-component__icon': true,
    'explanatory-tooltip-component__icon_white': isWhite,
  });

  return (
    <OverlayWithTooltipComponent tooltip={tooltip}>
      <span className="explanatory-tooltip-component">
        <ExplanationIcon className={iconClasses} />
      </span>
    </OverlayWithTooltipComponent>
  );
};

explanatoryTooltipComponent.propTypes = {
  isWhite: PropTypes.bool,
  placement: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default explanatoryTooltipComponent;
