export default {
  rankTracker: {
    bulkActions: {
      groupByUrl: 'Group by URL',
    },
    buttons: {
      addKeywords: 'Add Keywords',
      exportToCsv: 'Export to CSV',
      recheckKeywordsRankings: {
        title: 'Re-Check',
        tooltip: `"Requests to rankings API" limit is reached`,
      },
      reset: 'Reset',
      seSettings: 'Search Engine Settings',
    },
    changesHistoryPopup: {
      buttons: {
        ok: 'OK',
      },
      table: {
        date: 'Date',
        url: 'URL',
      },
      title: 'URL Changing history',
    },
    messages: {
      rankTrackingFailure: (label) => {
        return `No ranking results were found for the keyword "${label}", maybe you misspelled it?`;
      },
      recheckKeywordsRankingsStarted: 'Linkio will now get your latest rankings',
    },
    table: {
      headers: {
        dfsSearchVolumeGlobal: 'Global',
        dfsSearchVolumeLocal: 'Local',
        keywords: 'Keywords',
        keywordsCount: 'Keywords',
        months: (monthsCount) => {
          switch (monthsCount) {
            case 1:
              return 'Month';
            case 3:
              return '3 Months';
            case 6:
              return '6 Months';
            case 12:
              return 'Year';
            default:
              return '';
          }
        },
        todayRank: 'Rank',
        url: 'URL',
        week: 'Week',
        yesterday: 'Yesterday',
      },
    },
    title: 'Ranking sorted by Keywords',
  },
};
