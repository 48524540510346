const calculatePercentagesSum = (percentageSchemes) => {
  return percentageSchemes.reduce((sum, item) => {
    const increasedSum = sum + Number(item.get('idealPercentage'));
    return Number(increasedSum.toFixed(2));
  }, 0.00);
};

export {
  calculatePercentagesSum,
};
