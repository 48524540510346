import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CheckRedirect from './CheckRedirect';
import RouteAppLayoutComponent from './RouteAppLayoutComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { uiStatesShape } from 'common/prop_types_shapes';

import Layout, {
  PageContent,
  Sidebar,
  SidebarButton,
  SidebarLink,
  SidebarTitle,
} from 'components_linkio/layout';

import GlobalComponents from 'components/global_components_container';
import Navbar from 'components_linkio/Navbar';

import { uiSelector } from 'selectors';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';

class RouteAppLayoutContainer extends React.Component {
  static propTypes = {
    canUseMetrics: PropTypes.bool,
    checkRedirect: PropTypes.bool,
    component: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    sidebarItems: PropTypes.arrayOf(PropTypes.shape()),
    uiStates: uiStatesShape,
  };

  renderSidebarItems = (sidebarItems, matchProps, dispatch, uiStates, canUseMetrics) =>
    sidebarItems.map(({ styleAs, title, text, to, icon, type, onClick, isActive, exact }, idx) => {
      switch (type) {
        case 'title':
          return <SidebarTitle key={idx}>{title}</SidebarTitle>;
        case 'button':
          return (
            <SidebarButton
              icon={icon}
              isActive={isActive(matchProps, uiStates)}
              key={idx}
              onClick={onClick(matchProps, dispatch)}
              title={title}
            >
              {' '}
              {text}
            </SidebarButton>
          );
        case 'link':
          return (
            <SidebarLink
              exact={exact}
              icon={icon}
              isActive={typeof isActive === 'function' && isActive(matchProps)}
              key={idx}
              styleAs={styleAs}
              to={to(matchProps, canUseMetrics)}
              title={title}
            >
              {text}
            </SidebarLink>
          );
        case 'separator': {
          const sidebarIsOpen = uiStates.getIn(['openClose', 'sidebar', 'isOpen']);
          return sidebarIsOpen && <hr key={idx} />;
        }
        default:
          return null;
      }
    });

  renderLayout = (matchProps) => {
    const {
      canUseMetrics,
      checkRedirect,
      component: Component,
      dispatch,
      sidebarItems,
      uiStates,
    } = this.props;

    if (!sidebarItems) {
      return (
        <>
          <GlobalComponents />
          <Navbar />
          <Component {...matchProps} />
        </>
      );
    }

    return (
      <CheckRedirect {...matchProps} checkRedirect={checkRedirect}>
        <GlobalComponents />
        <Navbar />
        <Layout>
          <Sidebar>
            {this.renderSidebarItems(sidebarItems, matchProps, dispatch, uiStates, canUseMetrics)}
          </Sidebar>
          <PageContent>
            <Component {...matchProps} />
          </PageContent>
        </Layout>
      </CheckRedirect>
    );
  };

  render() {
    const { component, sidebarItems, ...props } = this.props;

    return <RouteAppLayoutComponent props={props} renderLayout={this.renderLayout} />;
  }
}

function select(state) {
  const uiStates = uiSelector(state);
  const subscriptionPolicies = subscriptionPoliciesSelector(state);
  const canUseMetrics = subscriptionPolicies.getIn(['metrics', 'canUse']);

  return {
    canUseMetrics,
    uiStates,
  };
}

export default ConnectStoreHOC(connect(select)(RouteAppLayoutContainer));
