import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';

const eoDashboardMetrics = (state) => state.getIn(['eoDashboard', 'eoDashboardMetrics'], iMap());

const eoDashboardMetricsSelector = createSelector(
  [eoDashboardMetrics],
  (eoDashboardMetrics) => eoDashboardMetrics || iMap(),
);

export { eoDashboardMetricsSelector };
