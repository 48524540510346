import { translate } from '../common/i18n';
import { showMessage, showErrorMessageFromTranslation } from '../common/utils';

import {
  createDestinationLinksFailure,
  createDestinationLinksStart,
} from '../actions/destination_link_actions';

import {
  importDestinationLinksFromCSVFailure,
  importDestinationLinksFromCSVStart,
  importDestinationLinksFromAhrefsFailure,
  importDestinationLinksFromAhrefsStart,
  importDestinationLinksFromMozFailure,
  importDestinationLinksFromMozStart,
} from '../actions/destination_links_import_actions';

import { updateBrandFailure } from '../actions/brand_actions';

import { uploadTmpFileToS3 } from './s3';

import { handleAPIError, postToAPI, processObjToSnakeCase } from './utils';

export async function importFromAhrefs(dispatch, brandPageId, categorizeBacklinks) {
  dispatch(importDestinationLinksFromAhrefsStart(brandPageId));

  try {
    await postToAPI(Routes.import_ahrefs_destination_links_imports_path(), {
      brand_page_id: brandPageId,
      categorize: categorizeBacklinks,
    });
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      importDestinationLinksFromAhrefsFailure,
      translate('errors.cantImportDestinationLinksFromAhrefs'),
    );
  }
}

export async function importFromMoz(dispatch, brandPageId, categorizeBacklinks) {
  dispatch(importDestinationLinksFromMozStart(brandPageId));

  try {
    await postToAPI(Routes.import_moz_destination_links_imports_path(), {
      brand_page_id: brandPageId,
      categorize: categorizeBacklinks,
    });
    return {};
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      importDestinationLinksFromMozFailure,
      translate('errors.cantImportDestinationLinksFromMoz'),
    );
  }
}

export async function importCSV(dispatch, brandPageId, file, categorizeBacklinks) {
  // pre-flight check
  if (!file) {
    const errorMsg = {
      type: 'error',
      message: translate('importCsv.messages.csvFileIsRequired'),
    };
    showMessage(dispatch, errorMsg, true);
    return { type: 'error' }; // keeps the popup opened
  }

  dispatch(importDestinationLinksFromCSVStart(brandPageId));

  try {
    const s3response = await uploadTmpFileToS3(file);

    if (!s3response.ok) {
      return handleAPIError(
        dispatch,
        s3response.error,
        importDestinationLinksFromCSVFailure,
        translate('errors.cantImportDestinationLinks'),
      );
    }

    await postToAPI(Routes.import_csv_destination_links_imports_path(), {
      brand_page_id: brandPageId,
      categorize: categorizeBacklinks,
      file_s3_id: s3response.fileS3Id,
    });

    return {};
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      importDestinationLinksFromCSVFailure,
      translate('errors.cantImportDestinationLinks'),
    );
  }
}

export async function manualImport(dispatch, { brandPageId, data }) {
  dispatch(createDestinationLinksStart());

  try {
    await postToAPI(Routes.manual_import_destination_links_imports_path(), {
      brand_page_id: brandPageId,
      destination_link: processObjToSnakeCase(data),
    });
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      createDestinationLinksFailure,
      translate('errors.cantCreateDestinationLinks'),
    );
  }
}

export async function importFromGsc(dispatch, brandId, file) {
  // pre-flight check
  if (!file) {
    showErrorMessageFromTranslation(dispatch, 'importCsv.messages.csvFileIsRequired');
    return { type: 'error' }; // keeps the popup opened
  }

  try {
    const s3response = await uploadTmpFileToS3(file);

    if (!s3response.ok) {
      return handleAPIError(
        dispatch,
        s3response.error,
        updateBrandFailure,
        translate('errors.cantImportDestinationLinksFromGsc'),
      );
    }

    await postToAPI(Routes.import_gsc_destination_links_imports_path(), {
      brand_id: brandId,
      file_s3_id: s3response.fileS3Id,
    });

    return {};
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateBrandFailure,
      translate('errors.cantImportDestinationLinksFromGsc'),
    );
  }
}
