import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';

// TODO: Implementation through currentIdSelector does not work here. Figure out why
const changesHistoryByBrandId = (state, brandId) =>
  state.getIn(['changesHistory', String(brandId)], iList());

const changesHistoryPagyByBrandId = (state, brandId) => {
  return state.getIn(['pagies', 'changesHistoryPagy', String(brandId)], iMap());
};

export const changesHistoryPagyByBrandIdSelector = createSelector(
  [changesHistoryPagyByBrandId],
  (pagy) => pagy || iMap(),
);

export const changesHistoryByBrandIdSelector = createSelector(
  [changesHistoryByBrandId],
  (changesHistory) => changesHistory || iList(),
);
