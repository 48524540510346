import React from 'react';

import ButtonComponent from 'components_linkio/button_component';
import ConfirmationDialogue from 'components/confirmation_dialogue';
import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';
import InputComponent from 'components_linkio/input_component';
import PageLayoutHeader from 'components_linkio/PageLayoutHeader';
import Select from 'components_linkio/Select';
import SwitchComponent from 'components_linkio/switch_component';
import TabComponent from 'components_linkio/tabs_component';
import TutorialPopup from 'components_linkio/TutorialPopup';

import AnchorIcon from 'common/icons/anchor';
import ArrowIcon from 'common/icons/ArrowIcon';
import BacklinkAddIcon from 'common/icons/backlink_add';
import BacklinkIcon from 'common/icons/backlink';
import BacklinkMamaHorizontalLogo from 'common/icons/backlink_mama_full_horizontal_logo';
import BacklinksChangesIcon from 'common/icons/BacklinksChangesIcon';
import BacklinksImportIcon from 'common/icons/BacklinksImportIcon';
import BacklinksLostIcon from 'common/icons/BacklinksLostIcon';
import BacklinksRediscoveredIcon from 'common/icons/BacklinksRediscoveredIcon';
import BellIcon from 'common/icons/bell';
import BinIcon from 'common/icons/bin';
import BroadcastIcon from 'common/icons/BroadcastIcon';
import BulbIcon from 'common/icons/bulb';
import BullseyeImage from 'common/img/bullseye.svg';
import CalendarIcon from 'common/icons/calendar';
import CheckedIcon from 'common/icons/checked';
import Chevron from 'common/icons/chevron';
import CloudIcon from 'common/icons/CloudIcon';
import CogIcon from 'common/icons/cog';
import CrossIcon from 'common/icons/cross';
import CrossInCircleIcon from 'common/icons/cross_in_circle';
import DashboardIcon from 'common/icons/dashboard';
import DashboardSecondIcon from 'common/icons/DashboardSecondIcon';
import DisavowFileIcon from 'common/icons/DisavowFileIcon';
import DownArrowIcon from 'common/icons/down_arrow';
import DownArrowIconV2 from 'common/icons/DownArrowIconV2';
import DownloadIcon from 'common/icons/download';
import EditIcon from 'common/icons/edit';
import EmailSettingsIcon from 'common/icons/EmailSettingsIcon';
import ExplanationIcon from 'common/icons/explanation';
import ExternalLinkIcon from 'common/icons/external_link';
import FeedbackImage from 'common/img/feedback.svg';
import FilterIcon from 'common/icons/filter_icon';
import FolderIcon from 'common/icons/folder';
import HumansIcon from 'common/icons/HumansIcon';
import InfoIcon from 'common/icons/info';
import KeywordIcon from 'common/icons/keyword';
import LinkIcon from 'common/icons/link';
import LinkioRobo from 'common/icons/linkio_robo';
import ListIcon from 'common/icons/list';
import LockIcon from 'common/icons/lock';
import MagnifierIcon from 'common/icons/magnifier';
import MailIcon from 'common/icons/MailIcon';
import MenuIcon from 'common/icons/menu';
import MinusInRoundIcon from 'common/icons/minus_in_round';
import PaperIcon from 'common/icons/PaperIcon';
import PenIcon from 'common/icons/PenIcon';
import PersonIcon from 'common/icons/PersonIcon';
import PlayIcon from 'common/icons/play';
import PlusInRoundIcon from 'common/icons/plus_in_round';
import RefreshIcon from 'common/icons/refresh';
import RightArrowIcon from 'common/icons/RightArrowIcon';
import RoundSpinnerIcon from 'common/icons/RoundSpinnerIcon';
import SeoSettingsIcon from 'common/icons/SeoSettingsIcon';
import SpeakerWithWorldIcon from 'common/icons/SpeakerWithWorldIcon';
import StackIcon from 'common/icons/StackIcon';
import TeamIcon from 'common/icons/TeamIcon';
import TickInCircleIcon from 'common/icons/tick_in_circle';
import UpArrowIcon from 'common/icons/UpArrowIcon';
import DotsIcon from 'common/icons/DotsIcon';
import WatchIcon from 'common/icons/WatchIcon';

import './style_guide_page.scss';

class StyleGuidePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      dataFromInput: void 0,
      isDisabled: false,
      isLoading: false,
      lastStep: 0,
      multiSelectValue: void 0,
      selectedId: 1,
      selectedValue: void 0,
      switchValue: 'right',
    };
  }

  handleClick = () => console.log('click');
  handleSelect = (value) => this.setState({ selectedValue: value });
  handleSelectWithIds = (value) => {
    ConfirmationDialogue({
      body: 'This action will so something horrible.',
      onConfirm: () => this.setState({ selectedId: value }),
    });
  };
  handleMultiSelect = (value) => {
    this.setState({ multiSelectValue: value });
  };
  handleClickToggleDisable = () => this.setState({ isDisabled: !this.state.isDisabled });
  handleClickToggleLoading = () => this.setState({ isLoading: !this.state.isLoading });
  handleTabClick = (index) => () => this.setState({ activeTab: index });
  handleCheckForString = (data) => data.includes('@');
  generateMessage = () => {
    const { dataFromInput } = this.state;
    if (dataFromInput !== void 0) {
      return this.handleCheckForString(dataFromInput)
        ? { text: 'This is email!', type: 'success' }
        : {
            text: 'This is not email! And this is a very long message about error. Why we need so long error message? I dont know...',
            type: 'error',
          };
    }
    return void 0;
  };
  generateSelectMessage = () =>
    this.state.selectedValue && { text: 'Good choose!', type: 'success' };
  selectOptions = [
    { label: 'option 1', value: 'value option 1' },
    { label: 'option 2', value: 'value option 2' },
    { label: 'option 3', value: 'value option 3' },
    { label: 'option 4', value: 'value option 4' },
    {
      label: 'option 5 very long very long very long value',
      value: 'value option 5',
    },
  ];

  selectOptionsWithIds = [
    { label: 'Label for id #1', value: 1 },
    { label: 'Label for id #2', value: 2 },
    { label: 'Label for id #3', value: 3 },
    { label: 'Label for id #4', value: 4 },
    { label: 'Label for id #5', value: 5 },
  ];

  handleSaveDataFromInput = (data) => this.setState({ dataFromInput: data });

  handleClickSwitch = () =>
    this.setState({
      ...this.state,
      switchValue: this.state.switchValue === 'right' ? 'left' : 'right',
    });

  render() {
    const { activeTab, isDisabled, isLoading, multiSelectValue, selectedId, selectedValue } =
      this.state;
    const tabOptions = [
      { index: 0, label: 'Tab 1', handleClick: this.handleTabClick(0) },
      {
        index: 1,
        label: 'Tab 2',
        counter: 5,
        handleClick: this.handleTabClick(1),
      },
      { index: 2, label: 'Tab 3', handleClick: this.handleTabClick(2) },
    ];
    const buttons = [
      <ButtonComponent
        key="1"
        isBlue
        onClick={this.handleClickToggleLoading}
        isDisabled={isDisabled}
      >
        Press to Toggle Loading
      </ButtonComponent>,
      <ButtonComponent key="2" isBlue onClick={this.handleClick} isDisabled={isDisabled}>
        <CalendarIcon />
        &nbsp;&nbsp;Primary Button With Very Long Text
      </ButtonComponent>,
      <ButtonComponent key="3" isBlue isSmall onClick={this.handleClickToggleDisable}>
        Disable/Enable all buttons
      </ButtonComponent>,
      <ButtonComponent
        key="4"
        isBlue
        onClick={this.handleClick}
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        <CalendarIcon />
        &nbsp;&nbsp;Loading
      </ButtonComponent>,
      <ButtonComponent key="5" isGreen isLarge onClick={this.handleClick} isDisabled={isDisabled}>
        Secondary
      </ButtonComponent>,
      <ButtonComponent key="6" isGreen isLarge onClick={this.handleClick} isDisabled={isDisabled}>
        <CalendarIcon />
        &nbsp;&nbsp;Secondary
      </ButtonComponent>,
      <ButtonComponent key="7" isGreen isSmall onClick={this.handleClick} isDisabled={isDisabled}>
        <CalendarIcon />
      </ButtonComponent>,
      <ButtonComponent
        key="8"
        isGreen
        onClick={this.handleClick}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        Loading
      </ButtonComponent>,
      <ButtonComponent key="9" isOrange isLarge onClick={this.handleClick} isDisabled={isDisabled}>
        Warning
      </ButtonComponent>,
      <ButtonComponent key="10" isOrange isSmall onClick={this.handleClick} isDisabled={isDisabled}>
        <CalendarIcon />
        &nbsp;&nbsp;Warning
      </ButtonComponent>,
      <ButtonComponent key="11" isOrange onClick={this.handleClick} isDisabled={isDisabled}>
        <CalendarIcon />
      </ButtonComponent>,
      <ButtonComponent
        key="12"
        isOrange
        isLarge
        onClick={this.handleClick}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        Loading
      </ButtonComponent>,
      <ButtonComponent key="13" isRed isLarge onClick={this.handleClick} isDisabled={isDisabled}>
        Warning
      </ButtonComponent>,
      <ButtonComponent key="14" isRed isSmall onClick={this.handleClick} isDisabled={isDisabled}>
        <BinIcon />
        &nbsp;&nbsp;Warning
      </ButtonComponent>,
      <ButtonComponent key="15" isRed onClick={this.handleClick} isDisabled={isDisabled}>
        <BinIcon />
      </ButtonComponent>,
      <ButtonComponent
        key="16"
        isRed
        isLarge
        onClick={this.handleClick}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        Loading
      </ButtonComponent>,
      <ButtonComponent key="17" isWhite isLarge onClick={this.handleClick} isDisabled={isDisabled}>
        Warning
      </ButtonComponent>,
      <ButtonComponent key="18" isWhite onClick={this.handleClick} isDisabled={isDisabled}>
        <BinIcon />
        &nbsp;&nbsp;Warning
      </ButtonComponent>,
      <ButtonComponent key="19" isWhite isLarge onClick={this.handleClick} isDisabled={isDisabled}>
        <BinIcon />
      </ButtonComponent>,
      <ButtonComponent
        key="20"
        isWhite
        isSmall
        onClick={this.handleClick}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        Loading
      </ButtonComponent>,
    ];

    const inputs = [
      <InputComponent key="1" />,
      <InputComponent key="2" isDisabled />,
      <InputComponent key="3" placeholder="text in placeholder" />,
      <InputComponent key="4" defaultValue="default text" />,
      <InputComponent key="6" icon={CalendarIcon} />,
      <InputComponent key="7" icon={MagnifierIcon} isHaveRightIcon />,
      <InputComponent key="8" label="label" icon={MagnifierIcon} isHaveRightIcon />,
      <InputComponent key="11" isDisabled label="label" icon={CalendarIcon} />,
      <InputComponent key="13" type="number" label="only numbers" />,
      <InputComponent
        key="5"
        isMandatory
        message={this.generateMessage()}
        onBlur={this.handleSaveDataFromInput}
        onSend={this.handleSaveDataFromInput}
      />,
      <InputComponent
        key="9"
        label="label"
        icon={CalendarIcon}
        message={this.generateMessage()}
        onBlur={this.handleSaveDataFromInput}
        onSend={this.handleSaveDataFromInput}
      />,
    ];

    const selects = [
      <Select
        key="0"
        onChange={this.handleSelect}
        options={this.selectOptions}
        value={selectedValue}
      />,
      <Select
        key="1"
        onChange={this.handleSelect}
        options={this.selectOptions}
        value={selectedValue}
        isDisabled
      />,
      <Select
        key="2"
        label="label"
        onChange={this.handleSelect}
        options={this.selectOptions}
        value={selectedValue}
        isDisabled
      />,
      <Select
        key="3"
        label="label"
        onChange={this.handleSelect}
        options={this.selectOptions}
        value={selectedValue}
      />,
      <Select
        key="4"
        isMandatory
        message={this.generateSelectMessage()}
        label="label"
        onChange={this.handleSelect}
        options={this.selectOptions}
        value={selectedValue}
      />,
      <Select
        key="5"
        label="label"
        message={this.generateSelectMessage()}
        onChange={this.handleSelect}
        options={this.selectOptions}
        value={selectedValue}
      />,
      <Select
        key="6"
        skinOptions={{ asDropdown: true }}
        openMenuOnFocus
        autoFocus
        onChange={this.handleSelect}
        options={this.selectOptions}
        value={selectedValue}
      />,
      <Select
        key="9"
        isCreatable
        label="label"
        message={this.generateSelectMessage()}
        onChange={this.handleSelect}
        options={this.selectOptions}
        value={selectedValue}
      />,
      <Select
        key="10"
        label="label"
        message={this.generateSelectMessage()}
        onChange={this.handleSelect}
        options={this.selectOptions}
      />,
      <Select
        key="11"
        isMulti
        label="label"
        blurInputOnSelect={false}
        triggerChangeOnBlur={false}
        closeMenuOnSelect={false}
        autoFocus
        openMenuOnFocus
        message={this.generateSelectMessage()}
        onChange={this.handleMultiSelect}
        options={this.selectOptions}
        value={multiSelectValue}
      />,
      <Select
        key="12"
        triggerChangeOnBlur={false}
        label="Select with IDs and confirmation dialogue"
        message={this.generateSelectMessage()}
        onChange={this.handleSelectWithIds}
        options={this.selectOptionsWithIds}
        value={selectedId}
      />,
    ];

    return (
      <div className="page__wrapper">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            padding: '1rem',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
          }}
        >
          {buttons.map((button, index) => {
            return (
              <div key={index} style={{ flex: '3 0 14rem', height: '100%', margin: '1rem' }}>
                {button}
              </div>
            );
          })}
          <TabComponent active={activeTab} options={tabOptions} />
          {inputs.map((input, index) => {
            return (
              <div key={index} style={{ flex: '3 0 14rem', height: '100%', margin: '1rem' }}>
                {input}
              </div>
            );
          })}
          {selects.map((select, index) => {
            return (
              <div key={index} style={{ flex: '3 0 14rem', height: '100%', margin: '1rem' }}>
                {select}
              </div>
            );
          })}
          <div style={{ flex: '3 0 14rem', height: '100%', margin: '1rem' }}>
            <ExplanatoryTooltipComponent text="Very long text for testing" />
          </div>
          <div style={{ flex: '3 0 14rem', height: '100%', margin: '1rem' }}>
            <SwitchComponent
              onClick={this.handleClickSwitch}
              isBoolean
              initialValue={this.state.switchValue}
            />
          </div>

          <hr />
          <div className="icons-and-images">
            <div className="icons-and-images__label">
              <AnchorIcon width="50px" />
              <br />
              anchor
              <br />
              AnchorIcon
            </div>
            <div className="icons-and-images__label">
              <BacklinkAddIcon />
              backlink_add
              <br />
              BacklinkAddIcon
            </div>
            <div className="icons-and-images__label">
              <BacklinkIcon />
              <br />
              backlink_icon
              <br />
              BacklinkIcon
            </div>
            <div className="icons-and-images__label">
              <BacklinkMamaHorizontalLogo width="50px" />
              <br />
              backlink_mama_full_horizontal_logo
              <br />
              BacklinkMamaHorizontalLogo
            </div>
            <div className="icons-and-images__label">
              <BacklinksImportIcon />
              backlinks_import
              <br />
              BacklinksImportIcon
            </div>
            <div className="icons-and-images__label">
              <BacklinksLostIcon />
              backlinks_lost
              <br />
              BacklinksLostIcon
            </div>
            <div className="icons-and-images__label">
              <BacklinksRediscoveredIcon />
              backlinks_rediscovered
              <br />
              BacklinksRediscoveredIcon
            </div>
            <div className="icons-and-images__label">
              <BacklinksChangesIcon />
              backlinks_shanges
              <br />
              BacklinksChangesIcon
            </div>
            <div className="icons-and-images__label">
              <BellIcon />
              <br />
              bell_icon
              <br />
              BellIcon
            </div>
            <div className="icons-and-images__label">
              <BinIcon width="50px" />
              <br />
              bin
              <br />
              BinIcon
            </div>
            <div className="icons-and-images__label">
              <BulbIcon width="50px" />
              <br />
              bulb
              <br />
              BulbIcon
            </div>
            <div className="icons-and-images__label">
              <CalendarIcon width="50px" />
              <br />
              calendar
              <br />
              CalendarIcon
            </div>
            <div className="icons-and-images__label">
              <CheckedIcon width="50px" />
              <br />
              checked.svg
              <br />
              CheckedIcon
            </div>
            <div className="icons-and-images__label">
              <Chevron width="50px" />
              <br />
              chevron
              <br />
              Chevron
            </div>
            <div className="icons-and-images__label">
              <CloudIcon width="50px" />
              <br />
              cloud
              <br />
              CloudIcon
            </div>
            <div className="icons-and-images__label">
              <CogIcon width="50px" />
              <br />
              cog_icon
              <br />
              CogIcon
            </div>
            <div className="icons-and-images__label">
              <CrossIcon width="50px" />
              <br />
              cross
              <br />
              CrossIcon
            </div>
            <div className="icons-and-images__label">
              <CrossInCircleIcon width="50px" />
              <br />
              cross_in_circle
              <br />
              CrossInCircleIcon
            </div>
            <div className="icons-and-images__label">
              <DashboardIcon />
              <br />
              dashboard
              <br />
              DashboardIcon
            </div>
            <div className="icons-and-images__label">
              <DisavowFileIcon width="50px" />
              <br />
              disavow_file
              <br />
              DisavowFileIcon
            </div>
            <div className="icons-and-images__label">
              <DownArrowIcon width="50px" />
              <br />
              down_arrow
              <br />
              DownArrowIcon
            </div>
            <div className="icons-and-images__label">
              <DownArrowIconV2 width="50px" />
              <br />
              DownArrowIconV2
              <br />
              DownArrowIconV2
            </div>
            <div className="icons-and-images__label">
              <UpArrowIcon width="50px" />
              <br />
              UpArrowIcon
              <br />
              UpArrowIcon
            </div>
            <div className="icons-and-images__label">
              <RightArrowIcon width="50px" />
              <br />
              RightArrowIcon
              <br />
              RightArrowIcon
            </div>
            <div className="icons-and-images__label">
              <DownloadIcon width="50px" />
              <br />
              download
              <br />
              DownloadIcon
            </div>
            <div className="icons-and-images__label">
              <EditIcon width="50px" />
              <br />
              edit
              <br />
              EditIcon
            </div>
            <div className="icons-and-images__label">
              <ExplanationIcon width="50px" />
              <br />
              info_icon.svg
              <br />
              InfoImage
            </div>
            <div className="icons-and-images__label">
              <ExternalLinkIcon width="50px" />
              <br />
              external_link
              <br />
              ExternalLinkIcon
            </div>
            <div className="icons-and-images__label">
              <FilterIcon width="50px" fill="#000000" />
              <br />
              filter_icon
              <br />
              FilterIcon
            </div>
            <div className="icons-and-images__label">
              <FolderIcon width="50px" fill="#000000" />
              <br />
              folder_icon
              <br />
              FolderIcon
            </div>
            <div className="icons-and-images__label">
              <InfoIcon width="50px" />
              <br />
              info
              <br />
              InfoIcon
            </div>
            <div className="icons-and-images__label">
              <KeywordIcon />
              <br />
              keyword_icon
              <br />
              KeywordIcon
            </div>
            <div className="icons-and-images__label">
              <ArrowIcon width="50px" orientation="left" />
              <br />
              arrow_icon.svg
              <br />
              ArrowIcon - left
            </div>
            <div className="icons-and-images__label">
              <LinkIcon />
              <br />
              link_icon
              <br />
              LinkIcon
            </div>
            <div className="icons-and-images__label">
              <LinkioRobo width="50px" />
              <br />
              linkio_robo
              <br />
              LinkioRobo
            </div>
            <div className="icons-and-images__label">
              <ListIcon width="50px" />
              <br />
              list
              <br />
              ListIcon
            </div>
            <div className="icons-and-images__label">
              <LockIcon width="50px" />
              <br />
              lock
              <br />
              LockIcon
            </div>
            <div className="icons-and-images__label">
              <MagnifierIcon width="50px" />
              <br />
              magnifier
              <br />
              MagnifierIcon
            </div>
            <div className="icons-and-images__label">
              <MenuIcon />
              <br />
              menu
              <br />
              MenuIcon
            </div>
            <div className="icons-and-images__label">
              <MinusInRoundIcon width="50px" />
              <br />
              minus_in_round
              <br />
              MinusInRoundIcon
            </div>
            <div className="icons-and-images__label">
              <PlayIcon width="50px" color="#000000" />
              <br />
              play
              <br />
              PlayIcon
            </div>
            <div className="icons-and-images__label">
              <PlusInRoundIcon width="50px" />
              <br />
              plus_in_round
              <br />
              PlusInRoundIcon
            </div>
            <div className="icons-and-images__label">
              <RefreshIcon width="50px" />
              <br />
              refresh
              <br />
              RefreshIcon
            </div>
            <div className="icons-and-images__label">
              <ArrowIcon width="50px" orientation="right" />
              <br />
              arrow_icon.svg
              <br />
              ArrowIcon - right
            </div>
            <div className="icons-and-images__label">
              <RoundSpinnerIcon isBlack />
              <br />
              RoundSpinnerIcon
            </div>
            <div className="icons-and-images__label">
              <TickInCircleIcon width="50px" />
              <br />
              tick_in_circle
              <br />
              TickInCircleIcon
            </div>
            <div className="icons-and-images__label">
              <DotsIcon width="50px" />
              <br />
              vertical_dots
              <br />
              DotsIcon
            </div>
            <div className="icons-and-images__label">
              <img src={BullseyeImage} width="50px" />
              <br />
              bullseye
              <br />
              BullseyeImage
            </div>
            <div className="icons-and-images__label">
              <img src={FeedbackImage} width="50px" />
              <br />
              feedback
              <br />
              Feedback
            </div>
            <div className="icons-and-images__label">
              <TeamIcon />
              <br />
              TeamIcon
              <br />
              TeamIcon
            </div>
            <div className="icons-and-images__label">
              <StackIcon />
              <br />
              StackIcon
              <br />
              StackIcon
            </div>
            <div className="icons-and-images__label">
              <SpeakerWithWorldIcon />
              <br />
              SpeakerWithWorldIcon
              <br />
              SpeakerWithWorldIcon
            </div>
            <div className="icons-and-images__label">
              <EmailSettingsIcon />
              <br />
              EmailSettingsIcon
              <br />
              EmailSettingsIcon
            </div>
            <div className="icons-and-images__label">
              <HumansIcon />
              <br />
              HumansIcon
              <br />
              HumansIcon
            </div>
            <div className="icons-and-images__label">
              <SeoSettingsIcon />
              <br />
              SeoSettingsIcon
              <br />
              SeoSettingsIcon
            </div>
            <div className="icons-and-images__label">
              <DashboardSecondIcon />
              <br />
              DashboardSecondIcon
              <br />
              DashboardSecondIcon
            </div>
            <div className="icons-and-images__label">
              <WatchIcon />
              <br />
              WatchIcon
              <br />
              WatchIcon
            </div>
            <div className="icons-and-images__label">
              <MailIcon />
              <br />
              MailIcon
              <br />
              MailIcon
            </div>
            <div className="icons-and-images__label">
              <PenIcon />
              <br />
              PenIcon
              <br />
              PenIcon
            </div>
            <div className="icons-and-images__label">
              <PersonIcon />
              <br />
              PersonIcon
              <br />
              PersonIcon
            </div>
            <div className="icons-and-images__label">
              <BroadcastIcon />
              <br />
              BroadcastIcon
              <br />
              BroadcastIcon
            </div>
            <div className="icons-and-images__label">
              <PaperIcon />
              <br />
              PaperIcon
              <br />
              PaperIcon
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <PageLayoutHeader
              title="Page title"
              Popup={
                <TutorialPopup
                  title="Modal title"
                  videoSrc="https://www.youtube.com/embed/pXYobYIy13Q"
                >
                  <TutorialPopup.DescriptionTitle>Some sub-title</TutorialPopup.DescriptionTitle>
                  <TutorialPopup.DescriptionText>Some description</TutorialPopup.DescriptionText>
                </TutorialPopup>
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default StyleGuidePage;