export default {
  changePercentagesPopup: {
    buttons: {
      addCompetitors: "Add Competitors",
      addCustomPercentages: "Add Custom Percentages",
      apply: "Apply Ideal Percentages",
      cancel: "Cancel",
    },
    percentageSchemeDescription: {
      about: "About: ",
      "LocalHomepage-PartialMatchDomain":
        "If you own a small local business, then the most common scenario in which you \
        will find yourself is operating a website where the root domain includes only part \
        of the target keyword for your the product or service you offer. Examples would be \
        Joesplumbing.com and Plasticsurgerycenter.com. What these page types have in common \
        is that they include all or part of the keyword PLUS additional words. With this page \
        type, the branded percentages are markedly lower compared to local businesses with no \
        keywords in their domain and the keyword anchor percentages are slightly higher.",
      "LocalHomepage-NoKeywordinDomain":
        "If a plumbers website is johncarters.com or a plastic surgeons website is dromarsantangelo.com \
        - we’d consider there to be no keyword in the domain. This page type has the highest percentage \
        of branded anchors and one of the lowest for keyword anchors.",
      "LocalHomepage-ExactMatchDomainwhereBrandNameisnotaKeyword":
        "Some local business websites have a root domain that doubles as one of the target keywords \
        for their business. For example, plumberinnj.com or miamibreastaugmentation.com both contain an \
        obvious keyword and nothing else. Even if the domain contained a filler word like “the”, I’d \
        consider it an exact match domain. With this in mind, the business owner usually has 2 options \
        when deciding on their brand name. The first, and simplest option is to use the root domain as \
        your brand name. For example, if the root domain is miamibreastaugmentation.com, and the brand \
        name is Miami Breast Augmentation, I would consider that the brand name is the keyword. However, \
        you can also opt to use an entirely different brand name than the root domain of your website. \
        For example, if the brand name of your company is “Dr. Richard Valtino”, the page would be \
        considered a “Local Homepage - Exact Match Domain Where Brand Name is Not Keyword”. The most \
        notable difference regarding the percentages for these pages is that they have the highest \
        percentage of “Natural” anchors (e.g. “click here”).",
      "LocalHomepage-ExactMatchDomainwhereBrandNameisaKeyword":
        "If you run a local business where the root domain is an exact match domain, and the brand \
        name is also the keyword (like the brand name for miamibreastaugmentation.com is Miami Breast \
        Augmentation), then this is the page type to choose. In this case, the anchor sub-type “Branded” \
        is dialed all the way down to zero. Instead, this one has the highest percentages associated \
        with the keyword anchor type and the lowest branded anchor type.",
      "LocalBusinessServicePage":
        "For a local business website, it’s common practice for internal pages to target additional keyword+location \
        combinations. For example, Joesplumbing.com / houston or drstevenropopo.com / miami - breast - augmentation. \
        This page type is characterized by a fairly even distribution of the various anchor types, including a near \
        even split between branded and keyword based anchors.",
      "NationalHomepage-ExactMatchDomain":
        "If my main keyword is “SEO tools”, and my root domain is seotools[dot]com or seotool[dot]com, then my \
        homepage is categorized as a “national homepage - exact match domain” because the keyword I’m targeting \
        matches my domain name exactly. The most glaring difference between the anchor text percentage required \
        to rank for these pages and the other pages we’ve addressed so far is that the brand anchor percentage \
        is zero! The reason for this is quite simple: The exact keyword is technically the brand name. \
        Thus, the keyword and websitename.com anchors have both increased substantially.",
      "NationalHomepage-PartialMatchDomain":
        "If my main keyword was “SEO tool” and my root domain was linkioSEO.com or Linkioseotool.com, then the \
        homepage of this website would be considered a “National Homepage - Partial Match Domain” page \
        type because the root domain contains all or part of my keyword plus additional words. If the additional \
        words are things like “the” or “a”, I’d still consider it an EMD and not a PMD. For this page type, \
        the branded anchor text percentages are still pretty high, however, you’ll also want to increase \
        the number of keyword specific anchors you build.",
      "NationalHomepage-NoKeywordinDomain":
        "Starting with an example, let's say my main keyword is “SEO tool” and my root domain is linkio.com. \
        My homepage is https://www.linkio.com/ Therefore I categorize this homepage as a “National Homepage - \
        No Keyword in Domain” because my root domain does not contain any part of my target keyword, “seo tools”. \
        Top ranking pages of this type typically have a backlink profile with the highest percentage of \
        branded anchor text of all page types. It’s also associated with the lowest keyword- based anchor text.",
      "EcommerceProductPage":
        "Most webmasters make a critical mistake of not building enough branded anchors to this page type. \
        Effective ecommerce product page link building requires a balanced link profile with a slight \
        weight towards branded and natural anchors. Avoid the traditional keyword focused anchor approach \
        and you’ll do just fine.",
      "FeaturesPage":
        "Features pages are typically reserved for websites that sell software solutions. This page type is \
        high in keyword and branded percentages.",
      "ContentPage":
        "Whether you’re trying to rank a badass ‘definitive guide’, a simple blog post, listicle, or long \
        form content, the anchor text profile you’ll want to shoot for will be focused on building \
        keyword, hybrid, and natural anchors. ",
      "ServicePage":
        "If you offer a specific service on your website, it’s more than likely that you have a prominent \
        page (or pages) describing that service. For OutreachMama, this would be our link building services \
        page: https://www.outreachmama.com/link-building-services/ In order to rank each of your service pages \
        as quickly and highly as possible, you’ll want to focus the majority of your link building efforts \
        on branded and keyword anchors with a smattering of natural, hybrid, and url anchors.",
    },
    schemeTypes: {
      defaultScheme: {
        hint: "We analyzed percentages for top performing sites and found patterns based \
          page type. Choose a ideal percentage default that best describes your page type.",
        label: "Default Percentages",
      },
      competitorsScheme: {
        hint: "Use the average anchor text percentages of what your competitors have. \
          Use the option with reduced keywords anchors if you want to be cautious.",
        label: "Use percentages of Your Competitors",
        recommended: "(Recomended)",
      },
      customScheme: {
        hint: "Once you apply you custom percentages your suggestions will be updated.",
        label: "Custom Percentages",
      },
    },
    subtitle: "Choose the ideal percentages for your page to see how your actual percentages \
      differ and to generate suggestions which anchor type to build next.",
    title: "Choose Ideal Anchor Text Ratio",
  },
};
