import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import DeleteEoCustomFieldsButtonComponent from './DeleteEoCustomFieldsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { deleteEoCustomFields } from 'api/eoCustomField';
import { selectedIdsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import { uiSelector } from 'selectors';

import confirmationDialogue from 'components/confirmation_dialogue';

const DeleteEoCustomFieldsButtonContainer = ({ dispatch, selectedIds }) => {
  function handleDelete() {
    confirmationDialogue({
      body: translate('eoCustomField.bulkActions.deleteConfirmation'),
      onConfirm: async () => await deleteEoCustomFields(dispatch, selectedIds.toJS()),
    });
  }

  return <DeleteEoCustomFieldsButtonComponent onDelete={handleDelete} selectedIds={selectedIds} />;
};

DeleteEoCustomFieldsButtonContainer.propTypes = {
  dispatch: PropTypes.func,
  selectedIds: selectedIdsList,
};

function select(state) {
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    selectedIds,
  };
}

export default compose(ConnectStoreHOC, connect(select))(DeleteEoCustomFieldsButtonContainer);
