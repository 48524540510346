import {
  camelCase,
  find,
  isArray,
  isEmpty,
  isObject,
  isPlainObject,
  mapKeys,
  mapValues,
  snakeCase,
} from 'lodash';

import { translate } from 'common/i18n';
import { showMessage } from 'common/utils';
import { openConfirmPasswordPopup } from 'actions/popup_actions';

import 'whatwg-fetch';

const incrementRequestCounter = () => {
  if (window === void 0) {
    return void 0;
  }
  return (window.ajaxRequestCounter = getRequestCounter() + 1);
};

const decrimentRequestCounter = () => {
  if (window === void 0) {
    return void 0;
  }
  return (window.ajaxRequestCounter = getRequestCounter() - 1);
};

export const getRequestCounter = () => {
  if (window === void 0) {
    return void 0;
  }

  return window.ajaxRequestCounter === void 0
    ? (window.ajaxRequestCounter = 0)
    : window.ajaxRequestCounter;
};

export const processObjToCamelCase = (obj) => mapKeys(obj, (v, k) => camelCase(k));
export const processObjToSnakeCase = (obj) => mapKeys(obj, (v, k) => snakeCase(k));
export const processObjToSnakeCaseDeep = (object) => {
  if (!isObject(object)) {
    return object;
  }

  const snakeCasedObject = processObjToSnakeCase(object);

  return mapValues(snakeCasedObject, (v) => {
    if (isArray(v)) {
      return v.map((element) => {
        return processObjToSnakeCaseDeep(element);
      });
    }

    if (isObject(v)) {
      return processObjToSnakeCaseDeep(v);
    }

    return v;
  });
};

export const handleAPIError = (dispatch, error, errorAction, fallbackMessage) => {
  const processedError = processError(error, fallbackMessage, dispatch);
  showMessage(dispatch, processedError);
  dispatch(errorAction());
  return processedError;
};

export const processError = (err, fallbackMessage, dispatch) => {
  if (
    err.message === 'You need to sign in or sign up before continuing.' ||
    err.message === 'Unauthorized access error.'
  ) {
    dispatch(openConfirmPasswordPopup());
    return;
  }
  if (!err) {
    return errorObject(fallbackMessage);
  }
  const message = isJsonString(err.message) ? JSON.parse(err.message) : err.message;
  if (/doctype|DOCTYPE/gm.test(message)) {
    return errorObject(fallbackMessage);
  }

  return errorObject(fallbackMessage ? `${fallbackMessage} - ${message}` : message);
};

const errorObject = (message) => {
  const obj = { type: 'error' };
  obj.message = typeof message === 'object' ? processObjToCamelCase(message) : message;

  return obj;
};

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const getCSRFToken = () => {
  return find(document.getElementsByTagName('meta'), (meta) => {
    return meta.name === 'csrf-token';
  }).content;
};

const headers = {
  'Content-Type': 'application/json',
  'X-CSRF-Token': getCSRFToken(),
};

export const setCSRFToken = (token) => {
  return document.querySelector("meta[name='csrf-token']").setAttribute('content', token);
};

const getResponseBody = (response) => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json();
  }
  if (contentType && contentType.indexOf('text/csv') !== -1) {
    return response.blob();
  }
  return response.text();
};

const handleResponse = (response) => {
  decrimentRequestCounter();
  const responseBody = getResponseBody(response);
  return responseBody.then((res) => {
    const pathname = new URL(response.url).pathname;
    switch (response.status) {
      case 200:
        if (response.redirected && pathname === '/users/sign_in') {
          throw new Error((res || {}).error || translate('errors.401'));
        }
        break;
      case 422:
        return res;

      case 401:
        throw new Error((res || {}).error || translate('errors.401'));
      case 403:
        throw new Error((res || {}).text || translate('errors.403'));
      case 404:
        if (response.redirected && pathname === '/users/sign_in') {
          throw new Error((res || {}).error || translate('errors.401'));
        }
        throw new Error(translate('errors.404'));
      case 500:
        throw new Error(translate('errors.500'));
      case 503:
        throw new Error(translate('errors.503'));
      case 504:
        throw new Error(translate('errors.504'));
    }
    if (response.status >= 300) {
      throw new Error(JSON.stringify(res));
    }
    return res;
  });
};

function serializePlain(obj, prefix) {
  const paramStrings = Object.keys(obj).reduce((a, p) => {
    const v = obj[p];
    const k = prefix ? `${prefix}[${p}]` : p;
    // If the value is an object then serialize it again
    // in order to handle multidimensional object
    a.push(
      v !== null && isPlainObject(v)
        ? serializePlain(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
    );
    return a;
  }, []);

  const paramsString = paramStrings.join('&');

  return paramsString ? `${paramsString}` : '';
}

export async function getFromAPI(path, query = {}) {
  incrementRequestCounter();
  const queryStr = `?${serializePlain(query)}`;

  // for the `root_path` `queryStr` might contain trailing `?`,
  // which leads to weird errors in filters
  const sanitizedQueryStr = queryStr.replace(/\?$/, '');

  const headers = {
    'Content-Type': 'application/json',
  };

  const response = await fetch(`${path}${sanitizedQueryStr || ''}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers,
  });

  return handleResponse(response);
}

export async function postToAPI(path, data = {}) {
  incrementRequestCounter();
  const response = await fetch(`${path}`, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers,
  });

  return handleResponse(response);
}

// token should be passed inside the FormData (to bypass Rails csrf protection)
const formDataWithHeaders = (formData) => {
  formData.append('authenticity_token', getCSRFToken());
  return formData;
};

export async function postFormDataToAPI(path, formData = new formData()) {
  incrementRequestCounter();
  const response = await fetch(`${path}`, {
    method: 'POST',
    body: formDataWithHeaders(formData),
    credentials: 'same-origin',
  });

  return handleResponse(response);
}

export async function putFormDataToAPI(path, formData = new formData()) {
  incrementRequestCounter();
  const response = await fetch(`${path}`, {
    method: 'PUT',
    body: formDataWithHeaders(formData),
    credentials: 'same-origin',
  });

  return handleResponse(response);
}

export async function putToAPI(path, data = {}) {
  incrementRequestCounter();
  const response = await fetch(`${path}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers,
  });

  return handleResponse(response);
}

export async function patchToAPI(path, data = {}) {
  incrementRequestCounter();
  const response = await fetch(`${path}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers,
  });

  return handleResponse(response);
}

export async function deleteToAPI(path, data = {}) {
  incrementRequestCounter();
  const options = {
    method: 'DELETE',
    credentials: 'same-origin',
    headers,
  };

  options.body = isEmpty(data) ? false : JSON.stringify(data);

  if (options.body === false) {
    Reflect.deleteProperty(options, 'body');
  }

  const response = await fetch(`${path}`, options);

  return handleResponse(response);
}
