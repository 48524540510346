export default {
  rankTrackerSetup: {
    buttons: {
      cancel: "Cancel",
      submit: (id) => id ? "Save" : "Start Tracking",
    },
    checkboxes: {
      domain: {
        hint: "All the subdomains and URL variations will be ignored.",
        label: "Search for the exact brand URL I specified.",
      },
    },
    switches: {
      domain: "Do you want to specify domain related tracking settings?",
      location: "Do you want us to search from a specific location?",
      trackDesktop: "Track Desktop",
      trackMobile: "Track Mobile",
    },
    selects: {
      country: {
        label: "And the country of the search engine is...",
      },
      location: {
        label: "Enter city or district",
        noOptionsMessage: "Type in a search term ...",
        placeholder: "City or district...",
      },
      searchEngine: {
        label: "Which search engine would you like to track it on?",
      },
      searchEngineLanguage: {
        label: "What language in Google should we use?",
      },
    },
    title: "Pick your search engine of choice, language and some additional location and domain settings",
  },
};
