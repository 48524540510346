export default {
  chart: {
    metrics: {
      backlinks: {
        backlinksCount: 'Overall # of Backlinks',
        liveReferringDomains: 'Live Referring Domains',
        nfReferringDomains: 'NF Referring Domains',
        notIndexedReferringDomains: 'Not Indexed Referring Domains',
        referringDomains: 'Referring Domains That Count',
        averageKeywordsRank: 'Average Keywords Rank',
      },
      emailOutreach: {
        bounced: 'Bounced',
        canceled: 'Canceled',
        completed: 'Completed',
        emailsSent: 'Emails Sent',
        has_error: 'Has Error',
        out_of_office: 'Out of Office',
        rejected: 'Rejected',
        replied: 'Replied',
        running: 'Running',
        draft: 'Draft',
        starting: 'Starting',
        unsubscribed: 'Unsubscribed',
        waiting: 'Waiting',
        eoProgressions: {
          started: 'progressions started',
          active: 'active progressions',
          pausedOrCompleted: 'paused or completed progressions',
        },
      },
    },
    noData: 'There is no data for the chart',
    titles: {
      backlinkMetrics: 'Backlink Metrics',
      overallNumberOfBacklinks: 'Overall Number of Backlinks',
      averageKeywordsRank: 'Average Keywords Rank',
      backlinksAndAverageRank: 'Overall Number of Backlinks and Average Keywords Rank',
      volumeStats: 'Volume Stats',
    },
  },
};
