import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { cloneDeep, has, isEmpty, max, set } from 'lodash';

import EoEmailTemplateAddFolderPopup from '../../EoEmailTemplatesSidebar/EoEmailTemplateAddFolderPopup';

import { eoEmailTemplateShape } from 'common/propTypesShapes/eoEmailTemplates';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import SubmitButtonComponent from 'components/auth/submit_button_component';
import EoEmailForm from 'components_linkio/EoEmailForm';
import InfoIcon from 'common/icons/info';
import ExternalLinkIcon from 'common/icons/external_link';
import Select from 'components_linkio/Select';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';
import Checkbox from 'components_linkio/Checkbox';

import { optionsList, userShape } from 'common/prop_types_shapes';

import './eoEmailTemplateFormPopup.scss';

const initialState = {
  body: '',
  brandId: null,
  default: false,
  errors: {},
  eoEmailTemplateFolderId: null,
  id: null,
  private: false,
  subject: '',
  title: '',
};

const getInitialStateWithTemplate = (eoEmailTemplate) => () => ({
  ...initialState,
  ...eoEmailTemplate,
});

function reducer(state, action) {
  const { value, type } = action;

  const newState = cloneDeep(state);

  switch (type) {
    case 'private':
      set(newState, 'private', value);

      if (value) {
        set(newState, 'default', false);
      }

      break;
    case 'default':
      set(newState, 'default', value);

      if (value) {
        set(newState, 'private', false);
      }

      break;
    default: {
      set(newState, type, value);
    }
  }

  return newState;
}

const formCheckboxes = [{ name: 'private' }, { name: 'default' }];

function getNewestFolderId(eoEmailTemplateFoldersOptions) {
  const eoEmailTemplateFoldersIds = eoEmailTemplateFoldersOptions.map(({ value }) => value);

  return max(eoEmailTemplateFoldersIds);
}

const EoEmailTemplateFormPopupComponent = ({
  currentUser,
  isReadOnly,
  handleRemove,
  onClose,
  onSave,
  eoEmailTemplate,
  immutableBrandsOptions,
  immutableEoEmailTemplateFoldersOptions,
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    initialState,
    getInitialStateWithTemplate(eoEmailTemplate),
  );

  const [isInitialFoldersOptionsUploaded, setIsInitialFoldersOptionsUploaded] = useState(false);

  const { canManageDefaultTemplates } = currentUser.toJS();
  const eoEmailTemplateFoldersOptions = immutableEoEmailTemplateFoldersOptions.toJS();
  const brandsOptions = immutableBrandsOptions.toJS();

  const isUpdatingTemplate = has(eoEmailTemplate, 'id');

  const { body, id, subject, errors, eoEmailTemplateFolderId, brandId, title } = state;

  React.useEffect(() => {
    !isInitialFoldersOptionsUploaded &&
      immutableEoEmailTemplateFoldersOptions.size &&
      setIsInitialFoldersOptionsUploaded(true);

    isInitialFoldersOptionsUploaded &&
      dispatch({
        type: 'eoEmailTemplateFolderId',
        value: getNewestFolderId(eoEmailTemplateFoldersOptions),
      });
  }, [immutableEoEmailTemplateFoldersOptions.size]);

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  const setEmailBodyValue = (value) => {
    dispatch({ type: 'body', value });
  };

  const setCheckboxValue = (checkboxName) => (event) => {
    dispatch({ type: checkboxName, value: event.target.checked });
  };

  function onSelectFolder({ value }) {
    if (eoEmailTemplateFolderId !== value) {
      dispatch({ type: 'eoEmailTemplateFolderId', value });
    }
  }

  function onSelectBrand(data) {
    dispatch({ type: 'brandId', value: data ? data.value : null });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const { eoEmailTemplateFolderId } = state;

    if (!eoEmailTemplateFolderId) {
      dispatch({ type: 'errors', value: { folder: 'Please, select a folder.' } });

      return;
    }

    const resp = await onSave(state);

    const { errors } = resp;

    dispatch({ type: 'errors', value: errors });

    isEmpty(errors) && onClose();
  }

  const popupHeader = isReadOnly
    ? title
    : translate('eoEmailTemplate.eoEmailTemplateFormPopup.title')(!!eoEmailTemplate);

  const submitButtonText = isReadOnly
    ? translate('eoEmailTemplate.eoEmailTemplateFormPopup.buttons.close')
    : translate('eoEmailTemplate.eoEmailTemplateFormPopup.buttons.submit')(isUpdatingTemplate);

  return (
    <Modal show className="eo-email-templates-form-popup">
      <Modal.Header>
        <Modal.Title>{popupHeader}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="eo-email-templates-form-popup__templates-hint">
          <InfoIcon className="eo-email-templates-form-popup__templates-info-icon" />
          <span>
            {translate('eoEmailTemplate.eoEmailTemplateFormPopup.eoTemplatesHint.mainText')}{' '}
            <a
              className="text_link"
              href="https://www.linkio.com/email-outreach-templates/"
              target="_blank"
              rel="noreferrer"
            >
              {translate('eoEmailTemplate.eoEmailTemplateFormPopup.eoTemplatesHint.linkText')}.
              <ExternalLinkIcon className="eo-email-templates-form-popup__link-icon" />
            </a>
          </span>
        </div>

        <form
          id="eo-email-templates-form"
          className="eo-email-templates-form-popup__form"
          noValidate
        >
          {!isReadOnly && (
            <InputComponent
              autoFocus
              className="eo-email-templates-form-popup__mt-0 eo-email-templates-form-popup__mb-1"
              isMandatory
              label={translate(`eoEmailTemplate.eoEmailTemplateFormPopup.inputs.title.label`)}
              message={{ type: 'error', text: errors.title }}
              onInputChange={setInputValue('title')}
              type="text"
              value={title}
            />
          )}

          <EoEmailForm
            className="eo-email-templates-form-popup__mb-1"
            emailBody={body}
            emailSubject={subject}
            errors={errors}
            isDisabled={isReadOnly}
            setEmailBodyValue={setEmailBodyValue}
            setEmailSubjectValue={setInputValue('subject')}
          />

          {!isReadOnly && (
            <>
              <div className="eo-email-templates-form-popup__checkboxes-wrapper eo-email-templates-form-popup__mb-1">
                {formCheckboxes.map((checkbox) => {
                  const { name } = checkbox;
                  const isChecked = state[name];
                  const isShown = name !== 'default' || canManageDefaultTemplates;
                  const checkboxMessage =
                    name === 'default' && errors.default
                      ? { type: 'error', text: errors.default }
                      : {
                          type: 'hint',
                          text: translate(
                            `eoEmailTemplate.eoEmailTemplateFormPopup.inputs.${name}.hint`,
                          ),
                        };

                  return (
                    isShown && (
                      <Checkbox
                        key={name}
                        checked={isChecked}
                        disabled={isReadOnly}
                        onChange={setCheckboxValue(name)}
                        message={checkboxMessage}
                      >
                        {translate(`eoEmailTemplate.eoEmailTemplateFormPopup.inputs.${name}.label`)}
                      </Checkbox>
                    )
                  );
                })}
              </div>

              <div
                id="eo-email-templates-form-popup__folder-section-wrapper"
                className="eo-email-templates-form-popup__mb-1"
              >
                <Select
                  blurInputOnSelect
                  className="eo-email-templates-form-popup__mt-0 eo-email-templates-form-popup__folder-select"
                  isClearable={false}
                  isMandatory
                  label={translate(`eoEmailTemplate.eoEmailTemplateFormPopup.inputs.folder.label`)}
                  message={{ type: 'error', text: errors.folder }}
                  onChange={onSelectFolder}
                  options={eoEmailTemplateFoldersOptions}
                  triggerChangeOnBlur={false}
                  value={
                    eoEmailTemplateFoldersOptions.find(
                      ({ value }) => value === eoEmailTemplateFolderId,
                    ) || null
                  }
                />

                <ToggleShowPopup
                  className="eo-email-templates-form-popup__add-folder-btn"
                  Popup={<EoEmailTemplateAddFolderPopup />}
                >
                  <ButtonComponent isBlue>
                    {translate('eoEmailTemplate.eoEmailTemplateFormPopup.buttons.addFolder')}
                  </ButtonComponent>
                </ToggleShowPopup>
              </div>

              <Select
                blurInputOnSelect
                className="eo-email-templates-form-popup__mt-0"
                isClearable
                label={translate(`eoEmailTemplate.eoEmailTemplateFormPopup.inputs.brand.label`)}
                onChange={onSelectBrand}
                options={brandsOptions}
                triggerChangeOnBlur={false}
                value={brandsOptions.find(({ value }) => value === brandId) || null}
              />
            </>
          )}
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="eo-email-templates-form-popup__footer">
          <div>
            {isUpdatingTemplate && !isReadOnly && (
              <ButtonComponent isRed isInitial onClick={handleRemove(id)}>
                {translate('eoEmailTemplate.eoEmailTemplateFormPopup.buttons.remove')}
              </ButtonComponent>
            )}
          </div>

          <div className="eo-email-templates-form-popup__btn-group">
            {!isReadOnly && (
              <div className="eo-email-templates-form-popup__btn">
                <ButtonComponent isWhite onClick={onClose}>
                  {translate('eoEmailTemplate.eoEmailTemplateFormPopup.buttons.cancel')}
                </ButtonComponent>
              </div>
            )}

            <div className="eo-email-templates-form-popup__btn">
              <SubmitButtonComponent
                form="eo-email-templates-form"
                isGreen
                onClick={isReadOnly ? onClose : handleSubmit}
                text={submitButtonText}
              />
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EoEmailTemplateFormPopupComponent.propTypes = {
  currentUser: userShape,
  eoEmailTemplate: eoEmailTemplateShape,
  handleRemove: PropTypes.func,
  immutableBrandsOptions: optionsList,
  immutableEoEmailTemplateFoldersOptions: optionsList,
  isReadOnly: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EoEmailTemplateFormPopupComponent;
