export function getCurrentTimezoneName() {
  // It returns time zone mostly in format 'Country/City', rarely 'Region/Country/City' or 'Region'
  // The list of time zones returned by this API: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  const timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone || '');

  // Doing such we handle all possible scenarios and always aim to 'City'

  // Multi-words cities appear in format "Los_Angeles", so we need to separate words with the space
  return timezone;
}

export function getCurrentTimezoneOffset() {
  const offset = new Date().getTimezoneOffset();
  const offsetAbs = Math.abs(offset);

  // Returns time zone offset in format "+04:00"
  return `${offset <= 0 ? '+' : '-'}${('00' + Math.floor(offsetAbs / 60)).slice(-2)}:${(
    '00' +
    (offsetAbs % 60)
  ).slice(-2)}`;
}

export function getCurrentTimezoneNameOrSimilarBySameOffset(timezonesOptions) {
  const currentTimezoneName = getCurrentTimezoneName();

  const existsInTimezoneOptions = timezonesOptions.some(
    (timezoneOption) => timezoneOption.value === currentTimezoneName,
  );

  // Use user's time zone name if we have the same one in our time zone options
  if (existsInTimezoneOptions) {
    return currentTimezoneName;
  }

  // Find similar time zone name that exists in our time zone options by the same offset
  const similarTimezoneNameBySameOffset = (
    timezonesOptions.find((timezoneOption) =>
      timezoneOption.label.includes(getCurrentTimezoneOffset()),
    ) || {}
  ).value;

  return similarTimezoneNameBySameOffset;
}
