import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AccountDashboardComponent from './AccountDashboardComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchOnboardingStatus } from 'api/onboardingStatus';
import { onboardingStatusSelector } from 'selectors/onboardingStatusSelector';
import { onboardingStatusShape } from 'common/propTypesShapes/onboardingStatus';
import { policiesShape, userShape, subscriptionShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import {
  currentSubscriptionSelector,
  currentUserSelector,
  policiesSelector,
} from 'selectors/railsContextSelectors';

import Breadcrumbs from 'components/NewBreadcrumbs';
import ButtonComponent from 'components_linkio/button_component';
import FolderIcon from 'common/icons/folder';
import Link from 'components_linkio/link';

const eoOnboardingRelatedStatuses = [
  'hasDripList',
  'hasEoInbox',
  'hasProspectInDripList',
  'hasStartedCampaign',
];

const AccountDashboardContainer = ({
  dispatch,
  currentSubscription,
  currentUser,
  onboardingStatus,
  policies,
}) => {
  React.useEffect(() => {
    fetchOnboardingStatus(dispatch);
  }, [dispatch]);

  function buildRightSideBtn() {
    const canCreate = policies.getIn(['brand', 'canCreate']);

    return (
      canCreate && (
        <Link to={Routes.new_brand_path({ format: null })} className="new-brands-link">
          <ButtonComponent className="add-brand-button" isGreen>
            <FolderIcon /> {translate('brand.list.buttons.add')}
          </ButtonComponent>
        </Link>
      )
    );
  }

  const brandsCount = currentSubscription.get('brandsCount', 0);

  const showBrandSteps = currentUser.getIn(['onboarding', 'brands'], true);
  const showAtpOnboarding = brandsCount === 0;
  const showEoOnboarding = eoOnboardingRelatedStatuses.some(
    (status) => !onboardingStatus.get(status),
  );

  return (
    <>
      <Breadcrumbs rightSideBtn={buildRightSideBtn()}>
        <Breadcrumbs.TextItem>{translate('brand.list.title')}</Breadcrumbs.TextItem>
      </Breadcrumbs>

      <div className="page__wrapper account-dashboard__page-wrapper">
        <AccountDashboardComponent
          showBrandSteps={showBrandSteps}
          showEoOnboarding={showEoOnboarding}
          showAtpOnboarding={showAtpOnboarding}
        />
      </div>
    </>
  );
};

AccountDashboardContainer.propTypes = {
  currentSubscription: subscriptionShape,
  currentUser: userShape,
  dispatch: PropTypes.func,
  onboardingStatus: onboardingStatusShape,
  policies: policiesShape,
};

function select(state, ownProps) {
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const currentUser = currentUserSelector(state, ownProps);
  const onboardingStatus = onboardingStatusSelector(state);
  const policies = policiesSelector(state, ownProps);

  return {
    currentSubscription,
    currentUser,
    onboardingStatus,
    policies,
  };
}

export default ConnectStoreHOC(connect(select)(AccountDashboardContainer));
