import React from 'react';

const LinkIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M19.188 12.001c0 1.1-.891 2.015-1.988 2.015l-4.195-.015c.538 1.088.963 1.999 1.997 1.999h3C19.658 16 21 13.657 21 12s-1.342-4-2.998-4h-3c-1.034 0-1.459.911-1.998 1.999l4.195-.015c1.098 0 1.989.917 1.989 2.017z" />
      <path d="M8 12c0 .535.42 1 .938 1h6.109c.518 0 .938-.465.938-1 0-.534-.42-1-.938-1H8.938C8.42 11 8 11.466 8 12z" />
      <path d="M4.816 11.999c0-1.1.891-2.015 1.988-2.015L11 9.999C10.461 8.911 10.036 8 9.002 8h-3c-1.656 0-2.998 2.343-2.998 4s1.342 4 2.998 4h3c1.034 0 1.459-.911 1.998-1.999l-4.195.015c-1.098 0-1.989-.917-1.989-2.017z" />
    </svg>
  );
};

LinkIcon.defaultProps = {
  fill: "#949494",
  height: '20px',
  width: '20px',
};

export default LinkIcon;
