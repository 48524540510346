import { fromJS, OrderedMap } from 'immutable';
import { keyBy } from 'lodash';

import {
  CREATE_PERCENTAGE_SCHEME__FAILURE,
  CREATE_PERCENTAGE_SCHEME__START,
  CREATE_PERCENTAGE_SCHEME__SUCCESS,
  DELETE_PERCENTAGE_SCHEME__FAILURE,
  DELETE_PERCENTAGE_SCHEME__START,
  DELETE_PERCENTAGE_SCHEME__SUCCESS,
  FETCH_PERCENTAGE_SCHEMES__FAILURE,
  FETCH_PERCENTAGE_SCHEMES__START,
  FETCH_PERCENTAGE_SCHEMES__SUCCESS,
  UPDATE_PERCENTAGE_SCHEME__FAILURE,
  UPDATE_PERCENTAGE_SCHEME__START,
  UPDATE_PERCENTAGE_SCHEME__SUCCESS,
} from '../actions/percentage_scheme_actions';

import {
  camelizeObjectDeep,
} from './utils';

const initialState = OrderedMap();

const processPercentageSchemes = (percentageScheme) => {
  const camelizedPercentageScheme = camelizeObjectDeep(percentageScheme);
  camelizedPercentageScheme.percentageSchemeItems = keyBy(camelizedPercentageScheme.percentageSchemeItems, 'id');

  return camelizedPercentageScheme;
};


export default function percentageSchemeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PERCENTAGE_SCHEMES__SUCCESS: {
      state = state
        .merge(state, fromJS(keyBy(action.percentageSchemes.map(processPercentageSchemes), 'id')))
        .sortBy((option) => option.get('label'));

      break;
    }
    case CREATE_PERCENTAGE_SCHEME__SUCCESS:
    case UPDATE_PERCENTAGE_SCHEME__SUCCESS: {
      state = state
        .merge(state, fromJS(keyBy([processPercentageSchemes(action.percentageScheme)], 'id')))
        .sortBy((option) => option.get('label'));

      break;
    }
    case DELETE_PERCENTAGE_SCHEME__SUCCESS: {
      state = state.delete(action.percentageSchemeId.toString());

      break;
    }
  }

  return state;
}
