import React from 'react';
import PropTypes from 'prop-types';

import AutodiscoverCompetitorsPopup from '../AutodiscoverCompetitorsPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import './autodiscoverCompetitorsButton.scss';

const explanationTooltip = {
  text: translate('explanationMessages.onlyForPaidUsers'),
  placement: 'top',
  color: 'black',
};

const AutodiscoverCompetitorsButtonComponent = ({ autodiscoverIsAvailable, isLoading }) => {
  return (
    <ToggleShowPopup
      disabled={!autodiscoverIsAvailable || isLoading}
      Popup={<AutodiscoverCompetitorsPopup />}
    >
      <ButtonComponent
        className="autodiscover-competitors-button"
        isDisabled={!autodiscoverIsAvailable}
        isInitial
        isBlue
        isLoading={isLoading}
        tooltip={!autodiscoverIsAvailable ? explanationTooltip : {}}
      >
        {translate('bulkCompetitorsSetup.bulkActions.autodiscoverCompetitors')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

AutodiscoverCompetitorsButtonComponent.propTypes = {
  autodiscoverIsAvailable: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default AutodiscoverCompetitorsButtonComponent;
