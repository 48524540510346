import { fromJS, List as iList } from 'immutable';
import { camelizeObjectDeep, mergeItemToList } from './utils';

import {
  CREATE_EO_CAMPAIGN__SUCCESS,
  DELETE_EO_CAMPAIGNS__SUCCESS,
  FETCH_EO_CAMPAIGNS__SUCCESS,
  FETCH_EO_CAMPAIGN__SUCCESS,
  UPDATE_EO_CAMPAIGN__SUCCESS,
} from 'actions/eoCampaignActions';

const initialState = iList();

export default function eoCampaignsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_CAMPAIGNS__SUCCESS: {
      const { eoCampaigns } = action;

      state = fromJS(eoCampaigns.map(camelizeObjectDeep));

      break;
    }
    case FETCH_EO_CAMPAIGN__SUCCESS:
    case CREATE_EO_CAMPAIGN__SUCCESS:
    case UPDATE_EO_CAMPAIGN__SUCCESS: {
      const { eoCampaign } = action;
      state = mergeItemToList(state, eoCampaign, camelizeObjectDeep);

      break;
    }
    case DELETE_EO_CAMPAIGNS__SUCCESS: {
      const { ids } = action;

      state = state.filter((eoCampaign) => !ids.includes(eoCampaign.get('id')));

      break;
    }
  }

  return state;
}
