export default {
  sidebar: {
    // FIXME: these texts are not for the sidebar, actually
    ahrefsConnections: 'Ahrefs API',
    atp: 'Anchor Text Planner',
    automationSettings: 'Backlink Import Options',
    backlinks: {
      title: 'Backlinks',
      all: {
        text: 'All',
        title: 'All Backlinks',
      },
      import: {
        text: 'Import',
        title: 'Import Backlinks',
      },
      lost: {
        text: 'Lost',
        title: 'Lost Backlinks',
      },
      new: {
        text: 'New',
        title: 'New Backlinks',
      },
      reDiscovered: {
        text: 'Re-discovered',
        title: 'Re-discovered Backlinks',
      },
      changes: {
        text: 'Link Changes',
        title: 'Link Changes',
      },
    },
    emailOutreach: {
      title: 'Email Outreach',
      eoDashboard: {
        text: 'Dashboard',
        title: 'Dashboard',
      },
      eoDripLists: {
        text: 'Drip Lists',
        title: 'Drip Lists',
      },
      prospects: {
        text: 'Prospects',
        title: 'Prospects',
      },
      eoCampaigns: {
        text: 'Campaigns',
        title: 'Campaigns',
      },
      eoToDos: {
        text: 'To Do List',
        title: 'To Do List',
      },
      eoInboxesSettings: {
        text: 'Settings',
        title: 'Settings',
      },
      seoTools: {
        title: 'Seo Tools',
      },
    },
    team: 'Team',
    brandDashboard: 'Brand Overview',
    brands: 'Brands',
    bulkPagesSetup: 'Add New Pages',
    dashboard: 'Dashboard',
    emailSettings: 'Email Settings',
    help: 'Help',
    notifications: 'Notifications',
    pageList: 'Pages List',
    percentageSettings: 'Edit Anchor Percentages',
    permissions: 'Permissions',
    proxies: 'Proxies',
    rankTracker: {
      overview: 'Overview',
      title: 'Keywords Rankings',
    },
    seoSettings: 'SEO Settings',
    suggestionsDashboard: 'Suggestions Dashboard',
    title: 'Menu',
    users: 'Invite Team Members',
  },
};
