import { applyMiddleware, createStore, compose } from 'redux';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import middleware from 'redux-thunk';

import reducers from '../reducers';


export default (props, railsContext) => {
  const browserHasDevTools = typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined';

  return createStore(
    combineReducers(reducers),
    fromJS({ railsContext }),
    compose(
      applyMiddleware(middleware),
      browserHasDevTools ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    )
  );
};
