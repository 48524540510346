import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BulkDlsActions from './BulkDlsActions';
import DlsTable from './DlsTable';
import DlsPercentage from './DlsPercentage';

import ContainerWithScrollComponent from 'components/container_with_scroll_component';
import MetricsPanel from 'components_linkio/MetricsPanel';
import NoDLsComponent from 'components/atp/no_dls_component';
import Pager from 'components/Pager';
import { destinationLinksMap, pagyShape, policiesShape } from 'common/prop_types_shapes';
import { PageParentContext } from 'pages/Pages/context';

import './pageDls.scss';

const PageDlsComponent = ({
  atpPagy,
  destinationLinks,
  onAtpPagerClick,
  onAtpPagerItemsSelectChange,
  policies,
  showAnchorProgressPane,
}) => {
  const canUpdateAtp = policies.getIn(['atp', 'canUpdate']);
  const dlsCount = destinationLinks.size;

  const wrapperClasses = classnames({
    'brand-page-container__wrapper': true,
  });

  return (
    <div className={wrapperClasses}>
      <div className="brand-page-container">
        <div className="brand-page-container__main-content">
          <PageParentContext.Consumer>
            {({ pageChildType, pageParentId, pageParentType }) => (
              <>
                <MetricsPanel appModule="atp" pageParentType="brand_page" />
                {canUpdateAtp && (
                  <BulkDlsActions
                    pageChildType={'competitorsPage'}
                    pageParentId={pageParentId}
                    pageParentType={pageParentType}
                  />
                )}
                <div className="container-with-scroll-component__wrapper">
                  <ContainerWithScrollComponent maxWidth={900}>
                    <DlsTable
                      pageChildType={pageChildType}
                      pageParentId={pageParentId}
                      pageParentType={pageParentType}
                    />
                    {dlsCount === 0 && (
                      <NoDLsComponent
                        activeAtpTableTab={'competitors'}
                        pageChildType={pageChildType}
                      />
                    )}
                    <Pager
                      handleItemsSelectChange={onAtpPagerItemsSelectChange}
                      handlePagesControlClick={onAtpPagerClick}
                      pagerType="rowsPager"
                      pagy={atpPagy}
                    />
                  </ContainerWithScrollComponent>
                  {showAnchorProgressPane && (
                    <DlsPercentage
                      pageChildType={pageChildType}
                      pageParentId={pageParentId}
                      pageParentType={pageParentType}
                    />
                  )}
                </div>
                {canUpdateAtp && (
                  <BulkDlsActions
                    pageChildType={'competitorsPage'}
                    positionBottom
                    pageParentId={pageParentId}
                    pageParentType={pageParentType}
                  />
                )}
              </>
            )}
          </PageParentContext.Consumer>
        </div>
      </div>
    </div>
  );
};

PageDlsComponent.propTypes = {
  atpPagy: pagyShape,
  destinationLinks: destinationLinksMap,
  onAtpPagerClick: PropTypes.func.isRequired,
  onAtpPagerItemsSelectChange: PropTypes.func.isRequired,
  policies: policiesShape,
  showAnchorProgressPane: PropTypes.bool,
};

export default PageDlsComponent;
