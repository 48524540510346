import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';
import { List as iList } from 'immutable';

import EditKeywordsPopupComponent from './EditKeywordsPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { bulkCompetitorPagesByBrandPageIdSelector } from 'selectors/bulkCompetitorPagesSelector';
import { bulkSetupBrandPagesSelector } from 'selectors/bulkPagesSetupSelector';
import { closeEditKeywordsPopup } from 'actions/popup_actions';
import { draftBrandPageMap } from 'common/prop_types_shapes';
import { pagesByParentSelector } from 'selectors';
import { updateDraftBrandPage } from 'api/bulkPagesSetup';
import { updateDraftCompetitorPage } from 'api/bulkCompetitorsSetup';
import { updatePage } from 'api/brand_page';

const EditKeywordsPopupContainer = ({
  dispatch,
  page,
  pageParentId,
  pageParentType,
  targetAppModule,
}) => {
  const [keywords, setKeywords] = React.useState([]);
  const [isUpdating, setIsUpdating] = React.useState(false);

  function handleChangeKeywords(keywords) {
    setKeywords(keywords);
  }

  function handleCancelClick() {
    dispatch(closeEditKeywordsPopup());
  }

  async function handleSave() {
    const pageId = page.get('id');
    const pageData = { id: pageId, keywords, selected: page.get('selected') };

    setIsUpdating(true);

    switch (targetAppModule) {
      case 'atp':
        await updatePage(dispatch, pageId, pageData);
        break;
      case 'bulkPagesSetup':
        await updateDraftBrandPage(dispatch, pageData);
        break;
      case 'bulkCompetitorsSetup':
        await updateDraftCompetitorPage(dispatch, pageData);
        break;
    }

    setIsUpdating(false);
    handleCancelClick();
  }

  return (
    <EditKeywordsPopupComponent
      canSave={keywords.length > 0}
      isUpdating={isUpdating}
      onCancelClick={handleCancelClick}
      onChangeKeywords={handleChangeKeywords}
      onSave={handleSave}
      page={page.toJS()}
      pageParentId={pageParentId}
      pageParentType={pageParentType}
      targetAppModule={targetAppModule}
    />
  );
};

function select(state, ownProps) {
  const { pageId, pageParentId, targetAppModule } = ownProps;

  let pages = iList();

  switch (targetAppModule) {
    case 'atp':
      pages = pagesByParentSelector(state, ownProps);
      break;
    case 'bulkPagesSetup':
      pages = bulkSetupBrandPagesSelector(state, ownProps);
      break;
    case 'bulkCompetitorsSetup':
      pages = bulkCompetitorPagesByBrandPageIdSelector(state, pageParentId);
      break;
  }

  const page = pages.find((page) => page.get('id') === Number(pageId));

  return {
    page,
    pageParentId,
  };
}

EditKeywordsPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  page: draftBrandPageMap,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageParentType: PropTypes.oneOf(['brand_page', 'brand']),
  targetAppModule: PropTypes.oneOf(['atp', 'bulkCompetitorsSetup', 'bulkPagesSetup']),
};

export default compose(withRouter, ConnectStoreHOC, connect(select))(EditKeywordsPopupContainer);
