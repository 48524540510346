import React from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import ButtonComponent from '../../../components_linkio/button_component';

import './GenerateMetricsBtn.scss';


const GenerateMetricsBtnComponent = ({
  canUseMetrics,
  onClick,
  isLoading
}) => {
    const generateMetricButtonTooltip = canUseMetrics ? explanationTooltip() : subscriptionLimitTooltip();
  return (
    <span className="crawl-brand-backlinks-btn">
      <ButtonComponent
        isDisabled={!canUseMetrics}
        isInitial
        onClick={onClick}
        tooltip={generateMetricButtonTooltip}
        isLoading={isLoading}
      >
        {translate('brandDashboard.generateMetrics.buttons.generate')}
      </ButtonComponent>
    </span>
  );
};

GenerateMetricsBtnComponent.propTypes = {
  canUseMetrics: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default GenerateMetricsBtnComponent;


function explanationTooltip() {
  return {
    text: translate('explanationMessages.brandDashboardMetricsGenerate.generateMetricsExplanation'),
    placement: 'top',
    color: 'black',
  };
}

function subscriptionLimitTooltip() {
  const subscriptionLimitMessageParts =
    `${translate('subscriptionLimitRibbon.message')(
        translate('subscriptionLimitRibbon.limited.metrics'),
        true,
      )}`;

  return {
    text: subscriptionLimitMessageParts,
    placement: 'top',
    color: 'red',
  };
}
