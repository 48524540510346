import React from 'react';

import moment from 'moment';
import { translate } from 'common/i18n';

import ArrowIcon from 'common/icons/ArrowIcon';

import 'components_linkio/badge_component/badge_component.scss';
import './backlinksChangesHistoryTable.scss';

const ChangesHistoryByDate = (el) => {
  const item = el.changesHistoryItem;
  const generateStatusText = (change, type) => {
    const text = translate(`changesHistory.table.typeOfChanges.${type}.${String(change)}`);
    return text;
  };

  const generateBadgeOfChanges = (previousValue, currentValue) => {
    return (
      <div className="changes-history-table__link-status">
        <div className="badge-component">{previousValue}</div>
        <ArrowIcon orientation="right" />
        <div className="badge-component">{currentValue}</div>
      </div>
    );
  };

  const isValidItem = (item) => {
    return Boolean(item && item[0] !== null && item[1] !== null);
  };

  const buildFollowBadges = () => {
    if (!isValidItem(item.nofollow)) {
      return '';
    }

    const typeChanges = 'follow';
    const previousValue = generateStatusText(item.nofollow[0], typeChanges);
    const currentValue = generateStatusText(item.nofollow[1], typeChanges);
    return generateBadgeOfChanges(previousValue, currentValue);
  };

  const buildIndexedBadges = () => {
    if (!isValidItem(item.indexedByGoogle)) {
      return '';
    }
    const typeChanges = 'indexed';
    const previousValue = generateStatusText(item.indexedByGoogle[0], typeChanges);
    const currentValue = generateStatusText(item.indexedByGoogle[1], typeChanges);
    return generateBadgeOfChanges(previousValue, currentValue);
  };

  const buildFoundBadges = () => {
    if (!isValidItem(item.foundByCrawler)) {
      return '';
    }
    const typeChanges = 'crawled';
    const previousValue = generateStatusText(item.foundByCrawler[0], typeChanges);
    const currentValue = generateStatusText(item.foundByCrawler[1], typeChanges);
    return generateBadgeOfChanges(previousValue, currentValue);
  };

  const buildDateOfChanges = () => {
    const dateNow = moment().format('YYYY-MM-DD');
    const dateOfChangeFromNow = (item.dateOfChange === dateNow) ? 'today' : moment(item.dateOfChange).from(dateNow);
    return (
      <div className="changes-history-table__changes-date">
        {dateOfChangeFromNow}
      </div>
    );
  };

  return (
    <>
      {buildDateOfChanges()}
      {buildFoundBadges()}
      {buildIndexedBadges()}
      {buildFollowBadges()}
    </>
  );
};

export default ChangesHistoryByDate;
