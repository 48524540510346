import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import { optionsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import Select from 'components_linkio/Select';
import SubmitButtonComponent from 'components/auth/submit_button_component';

import './eoCampaignFormPopup.scss';

const initialState = {
  title: '',
  brandId: null,
  errors: {},
};

function reducer(state, action) {
  const { value, type } = action;

  const newState = cloneDeep(state);

  newState[type] = value;

  return newState;
}

const formInputs = [
  {
    name: 'title',
    type: 'text',
    isRequired: true,
  },
];

const EoCampaignFormPopupComponent = ({ onCancel, onSave, immutableBrandsOptions }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const brandsOptions = immutableBrandsOptions.toJS();

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  const handleChangeSelect = (inputName) => (obj) => {
    dispatch({ type: inputName, value: obj ? obj.value : null });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const resp = await onSave(state);

    resp && dispatch({ type: 'errors', value: resp.errors });
  }

  const errors = state.errors || {};

  const { brandId } = state;

  return (
    <Modal show className="eo-campaign-form-popup">
      <Modal.Header>
        <Modal.Title>{translate('eoCampaign.eoCampaignFormPopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form id="eo-campaign-form" className="eo-campaign-form-popup__form" noValidate>
          {formInputs.map((input) => {
            const { name, isRequired, type } = input;
            const value = state[name] || '';

            return (
              <InputComponent
                autoComplete="off"
                autoFocus={name === 'title'}
                isMandatory={isRequired}
                key={name}
                label={translate(`eoCampaign.eoCampaignFormPopup.inputs.${name}.label`)}
                message={{ type: 'error', text: errors[name] }}
                name={name}
                onInputChange={setInputValue(name)}
                type={type}
                value={value}
              />
            );
          })}

          <Select
            isClearable
            label={translate('uiComponents.brandAssign.selectLabel')}
            blurInputOnSelect={false}
            placeholder={translate('uiComponents.brandAssign.noBrandAssigned')}
            triggerChangeOnBlur={false}
            onChange={handleChangeSelect('brandId')}
            options={brandsOptions}
            value={brandsOptions.find(({ value }) => value === brandId)}
          />
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="eo-campaign-form-popup__btn-group">
          <div className="eo-campaign-form-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('eoCampaign.eoCampaignFormPopup.buttons.cancel')}
            </ButtonComponent>
          </div>

          <div className="eo-campaign-form-popup__btn">
            <SubmitButtonComponent
              form="eo-campaign-form"
              isGreen
              onClick={handleSubmit}
              text={translate('eoCampaign.eoCampaignFormPopup.buttons.submit')}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EoCampaignFormPopupComponent.propTypes = {
  immutableBrandsOptions: optionsList,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EoCampaignFormPopupComponent;
