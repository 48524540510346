import { Map as iMap, fromJS } from 'immutable';
import { isEmpty } from 'lodash';

import { FETCH_RAILS_CONTEXT__SUCCESS } from 'actions/rails_context_actions';
import { UPDATE_CONNECTION_SETTINGS__SUCCESS } from 'actions/ahrefs_connection_actions';
import { UPDATE_CRAWLER_SETTINGS__SUCCESS } from 'actions/crawler_settings_actions';
import { FETCH_SUBSCRIPTION__SUCCESS } from 'actions/subscription_actions';
import { UPDATE_CURRENT_USER__SUCCESS } from 'actions/user_actions';
import { FETCH_CRAWLER_STATUS_DATA__SUCCESS } from 'actions/crawler_actions';
import { IMPORT_PROSPECTS__START, IMPORT_PROSPECTS__SUCCESS } from 'actions/prospectsActions';

import { camelizeObjectDeep } from 'reducers/utils';


const initialState = iMap();

export default function railsContextReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CONNECTION_SETTINGS__SUCCESS:
    case UPDATE_CRAWLER_SETTINGS__SUCCESS:
    case IMPORT_PROSPECTS__START:
    case IMPORT_PROSPECTS__SUCCESS: {
      state = state.set('cmarketer', fromJS(action.marketer));

      break;
    }
    case FETCH_SUBSCRIPTION__SUCCESS: {
      state = state.set(
        'currentSubscription',
        fromJS(camelizeObjectDeep(action.currentSubscription)),
      );

      break;
    }
    case UPDATE_CURRENT_USER__SUCCESS: {
      state = state.set('cuser', fromJS(camelizeObjectDeep(action.currentUser)));

      break;
    }

    case FETCH_RAILS_CONTEXT__SUCCESS: {
      if (isEmpty(action.payload)) {
        return;
      }
      return state.mergeDeep(iMap(action.payload));
    }

    case FETCH_CRAWLER_STATUS_DATA__SUCCESS: {
      state = state.set('crawlerStatus', fromJS(camelizeObjectDeep(action.payload)));

      break;
    }
  }

  return state;
}
