import { translate } from '../common/i18n';

import {
  fetchDlDuplicatesFailure,
  fetchDlDuplicatesStart,
  fetchDlDuplicatesSuccess,
} from '../actions/dl_duplicate_actions';

import {
  getFromAPI,
  handleAPIError,
} from './utils';

export async function fetchDlDuplicates(dispatch, brandPageId, destinationLinkId) {
  dispatch(fetchDlDuplicatesStart());

  try {
    const resp = await getFromAPI(Routes.duplicates_destination_links_records_path(), {
      brand_page_id: brandPageId,
      destination_link_id: destinationLinkId,
    });
    dispatch(fetchDlDuplicatesSuccess(resp.destination_links));
    return resp.destination_links;
  } catch (err) {
    return handleAPIError(dispatch, err, fetchDlDuplicatesFailure, translate('errors.cantFetchDlDuplicates'));
  }
}
