import ReactDOM from 'react-dom';

const MODAL_SELECTOR = '#app-modal';

// That component provides us simplify using React Portal feature
// for creating popups and same stuff.
const PortalComponent = ({ children }) => ReactDOM.createPortal(
  children,
  document.querySelector(MODAL_SELECTOR)
);

export default PortalComponent;
