import React from 'react';

const BulbIcon = (props) => (
  <svg {...props} viewBox="0 0 12 16">
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M8.567 4.233a3.791 3.791 0 0 0-3-.95c-1.734.2-3.167 1.6-3.384 3.334a3.845 3.845 0 0 0 .784 2.866c.466.617.75 1.384.783 2.217a.506.506 0 0 0-.133.35c0 .283.216.5.5.5h3.8c.283 0 .5-.217.5-.5 0-.133-.05-.25-.134-.333.034-.8.334-1.6.85-2.3.484-.667.75-1.45.75-2.284a3.917 3.917 0 0 0-1.316-2.9zM8.317 8.8c-.617.833-.967 1.767-1.034 2.733H6.5V8.967h.417c.283 0 .5-.217.5-.5a.49.49 0 0 0-.5-.5h-1.85a.49.49 0 0 0-.5.5c0 .283.216.5.5.5H5.5v2.566h-.767a4.885 4.885 0 0 0-1-2.666A2.832 2.832 0 0 1 3.15 6.75a2.883 2.883 0 0 1 2.517-2.467c.833-.1 1.616.15 2.233.7.6.534.95 1.317.95 2.134A2.74 2.74 0 0 1 8.317 8.8z"
        fillRule="nonzero"
      />
      <path d="M5.5.583v1.634c0 .283.217.5.5.5.283 0 .5-.217.5-.5V.583c0-.283-.217-.5-.5-.5-.283 0-.5.217-.5.5zm6.383 2.784a.49.49 0 0 0-.7-.134l-1.283.85a.49.49 0 0 0-.133.7c.1.15.25.217.416.217.1 0 .184-.033.284-.083l1.283-.85a.508.508 0 0 0 .133-.7zM1.533 4.9c.084.05.184.083.284.083a.521.521 0 0 0 .416-.216.524.524 0 0 0-.133-.7l-1.283-.85a.524.524 0 0 0-.7.133.524.524 0 0 0 .133.7l1.283.85zm6.534 8.8c0-.283-.217-.5-.5-.5H4.433c-.283 0-.5.217-.5.5 0 .283.217.5.5.5h3.134c.266 0 .5-.217.5-.5zm-.517 1.717a.49.49 0 0 0-.5-.5h-2.1a.49.49 0 0 0-.5.5c0 .283.217.5.5.5h2.1c.283 0 .5-.217.5-.5z" />
    </g>
  </svg>
);

BulbIcon.defaultProps = {
  width: '12px',
  height: '16px',
};

export default BulbIcon;
