import { fromJS, List as iList } from 'immutable';
import { camelizeObjectDeep, mergeItemToList } from './utils';

import {
  DELETE_EO_TO_DOS__SUCCESS,
  FETCH_EO_TO_DOS__SUCCESS,
  FETCH_EO_TO_DO__SUCCESS,
  SEND_EO_TO_DO_EMAIL__SUCCESS,
  UPDATE_EO_TO_DO__SUCCESS,
} from 'actions/eoToDoActions';

const initialState = iList();

export default function eoToDosReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_TO_DOS__SUCCESS: {
      const { eoToDos } = action;

      state = fromJS(eoToDos.map(camelizeObjectDeep));

      break;
    }
    case FETCH_EO_TO_DO__SUCCESS:
    case UPDATE_EO_TO_DO__SUCCESS: {
      const { eoToDo } = action;
      state = mergeItemToList(state, eoToDo, camelizeObjectDeep);

      break;
    }
    case SEND_EO_TO_DO_EMAIL__SUCCESS:
    case DELETE_EO_TO_DOS__SUCCESS: {
      const { ids } = action;

      state = state.filter((eoToDo) => !ids.includes(eoToDo.get('id')));

      break;
    }
  }

  return state;
}
