import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../common/i18n';

import RadioButton from 'components_linkio/RadioButton';

const ContactUserOnFeedbackComponent = ({ mayContactUser, onMayContactUserChange }) => {
  return (
    <div className="feedback-popup__selects">
      <div className="feedback-popup__title">{translate('feedbackPopup.contactMe')}</div>
      <RadioButton checked={mayContactUser === 'yes'} onChange={onMayContactUserChange} value="yes">
        {translate('yes')}
      </RadioButton>

      <RadioButton checked={mayContactUser === 'no'} onChange={onMayContactUserChange} value="no">
        {translate('no')}
      </RadioButton>
    </div>
  );
};

ContactUserOnFeedbackComponent.propTypes = {
  mayContactUser: PropTypes.string.isRequired,
  onMayContactUserChange: PropTypes.func.isRequired,
};

export default ContactUserOnFeedbackComponent;
