import { translate } from 'common/i18n';

import {
  fetchSubscriptionStart,
  fetchSubscriptionSuccess,
  fetchSubscriptionFailure,
} from 'actions/subscription_actions';

import { getFromAPI, handleAPIError } from 'api/utils';

async function fetchCurrentSubscription(dispatch) {
  dispatch(fetchSubscriptionStart());

  try {
    const resp = await getFromAPI(Routes.fetch_subscription_path());
    dispatch(fetchSubscriptionSuccess(resp));
    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchSubscriptionFailure,
      translate('errors.cantFetchCurrentSubscription'),
    );
  }
}

export { fetchCurrentSubscription };
