import React, { Component } from 'react';
import { isEmpty, isObject, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SignInFormComponent from './SignInFormComponent';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { signInAsUser } from 'api/user';
import { clearErrors, validateSignInForm, trackHelpcrunchEvent } from 'common/utils';

class SignInPageContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };
  constructor(props) {
    super(props);

    const processedProps = this.processProps();

    this.state = {
      error: null,
      errors: {},
      processedProps,
    };
  }

  handleValidationError = (res) => {
    const errorState = isObject(res.message) ? res.message : { error: res.message };
    this.setState(errorState);
  }

  handleSignInAction = async (email, password, rememberMe) => {
    this.setState(clearErrors(this.state));
    const errors = validateSignInForm({ email, password });

    if (!isEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    const formData = new FormData();
    const { dispatch } = this.props;

    formData.append('user[email]', email);
    formData.append('user[password]', password);
    formData.append('user[remember_me]', rememberMe);

    const res = await signInAsUser(dispatch, formData);

    if ((res || {}).type === 'error') {
      this.handleValidationError(res);
    } else {
      trackHelpcrunchEvent('signIn');
      ((window || {}).location || {}).href = Routes.users_choose_account_url({ format: null, skip_choose_account: true });
    }
  }

  processProps = () => {
    const processedProps = cloneDeep(this.props);

    processedProps.handleSignInClickAction = this.handleSignInAction;

    return processedProps;
  }

  render() {
    const { processedProps, errors, error } = this.state;

    return (
      <SignInFormComponent {...processedProps} errors={errors} error={error} />
    );
  }
}

export default ConnectStoreHOC(connect()(SignInPageContainer));
