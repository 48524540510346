import { translate } from 'common/i18n';

import {
  fetchSimpleBrandsStart,
  fetchSimpleBrandsSuccess,
  fetchSimpleBrandsFailure,
} from 'actions/simpleBrandActions';

import { getFromAPI, handleAPIError } from 'api/utils';

export async function fetchSimpleBrands(dispatch) {
  dispatch(fetchSimpleBrandsStart());

  try {
    const resp = await getFromAPI(Routes.simple_brands_path());

    dispatch(fetchSimpleBrandsSuccess(resp.simple_brands));
  } catch (err) {
    handleAPIError(dispatch, err, fetchSimpleBrandsFailure, translate('errors.cantFetchBrands'));
  }
}
