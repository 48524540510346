export const CREATE_BRAND__FAILURE = 'CREATE_BRAND__FAILURE';
export const CREATE_BRAND__START = 'CREATE_BRAND__START';
export const CREATE_BRAND__SUCCESS = 'CREATE_BRAND__SUCCESS';
export const DELETE_BRANDS__FAILURE = 'DELETE_BRANDS__FAILURE';
export const DELETE_BRANDS__START = 'DELETE_BRANDS__START';
export const DELETE_BRANDS__SUCCESS = 'DELETE_BRANDS__SUCCESS';
export const FETCH_BRANDS__FAILURE = 'FETCH_BRANDS__FAILURE';
export const FETCH_BRANDS__START = 'FETCH_BRANDS__START';
export const FETCH_BRANDS__SUCCESS = 'FETCH_BRANDS__SUCCESS';
export const UPDATE_BRAND__FAILURE = 'UPDATE_BRAND__FAILURE';
export const UPDATE_BRAND__START = 'UPDATE_BRAND__START';
export const UPDATE_BRAND__SUCCESS = 'UPDATE_BRAND__SUCCESS';

export function fetchBrandsStart() {
  return { type: FETCH_BRANDS__START };
}

export function fetchBrandsSuccess(brands) {
  return { type: FETCH_BRANDS__SUCCESS, brands };
}

export function fetchBrandsFailure(errors) {
  return { type: FETCH_BRANDS__FAILURE, errors };
}

export function createBrandStart() {
  return { type: CREATE_BRAND__START };
}

export function createBrandSuccess(brand) {
  return { type: CREATE_BRAND__SUCCESS, brand };
}

export function createBrandFailure(errors) {
  return { type: CREATE_BRAND__FAILURE, errors };
}

export function updateBrandStart() {
  return { type: UPDATE_BRAND__START };
}

export function updateBrandSuccess(brand) {
  return { type: UPDATE_BRAND__SUCCESS, brand };
}

export function updateBrandFailure(errors) {
  return { type: UPDATE_BRAND__FAILURE, errors };
}

export function deleteBrandsStart() {
  return { type: DELETE_BRANDS__START };
}

export function deleteBrandsSuccess(ids) {
  return { type: DELETE_BRANDS__SUCCESS, ids };
}

export function deleteBrandsFailure(errors) {
  return { type: DELETE_BRANDS__FAILURE, errors };
}
