import { isEmpty } from 'lodash';

import { handleAPIError, processObjToSnakeCaseDeep, patchToAPI, getFromAPI } from './utils';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoCampaignOptionsSuccess,
  fetchEoCampaignOptionsFailure,
  updateEoCampaignOptionsFailure,
} from 'actions/eoCampaignOptionsActions.js';

import { updateEoCampaignSuccess } from 'actions/eoCampaignActions';

import { showSuccessMessage } from 'common/utils';
import { translate } from 'common/i18n';

export async function fetchEoCampaignOptions(dispatch, eoCampaignId) {
  try {
    const resp = await getFromAPI(Routes.options_eo_campaign_path(eoCampaignId));

    dispatch(fetchEoCampaignOptionsSuccess(resp));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoCampaignOptionsFailure,
      translate('eoCampaignOptions.messages.cantFetchEoCampaignOptions'),
    );
  }
}

export async function updateEoCampaignOptions(
  dispatch,
  eoCampaignId,
  eoCampaignOptions,
  needToUpdateEoCampaign,
  updatedEoCampaign,
) {
  try {
    const resp = await patchToAPI(Routes.update_options_eo_campaign_path({ id: eoCampaignId }), {
      eo_campaign: {
        ...processObjToSnakeCaseDeep(eoCampaignOptions),
      },
    });

    const errors = (resp || {}).errors;

    if (isEmpty(errors)) {
      dispatch(fetchEoCampaignOptionsSuccess(resp));
      needToUpdateEoCampaign && dispatch(updateEoCampaignSuccess(updatedEoCampaign));
      showSuccessMessage(dispatch, translate('eoCampaignOptions.messages.updateSuccess'));
    } else {
      dispatch(updateEoCampaignOptionsFailure(errors));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoCampaignOptionsFailure,
      translate('eoCampaignOptions.messages.cantUpdateEoCampaignOptions'),
    );
  }
}

export async function updateEoCampaignStatus(
  dispatch,
  eoCampaignId,
  updatedStatus,
  updatedEoCampaign,
) {
  try {
    const resp = await patchToAPI(Routes.update_status_eo_campaign_path({ id: eoCampaignId }), {
      eo_campaign: {
        status: updatedStatus,
      },
    });

    dispatch(fetchEoCampaignOptionsSuccess(resp));
    dispatch(updateEoCampaignSuccess(updatedEoCampaign));
    showSuccessMessage(
      dispatch,
      translate('eoCampaignOptions.messages.updateEoCampaignStatusSuccess')(updatedStatus),
    );

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoCampaignOptionsFailure,
      translate('eoCampaignOptions.messages.cantUpdateEoCampaignStatus'),
    );
  }
}
