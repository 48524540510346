import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import OverlayWithTooltipComponent from '../../components/overlay_with_tooltip_component';
import SpinnerComponent from '../Spinner';
import { tooltipShape } from '../../common/prop_types_shapes';

import './button_component.scss';

class ButtonComponent extends React.PureComponent {
  static propTypes = {
    asToggle: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
    ]),
    className: PropTypes.string,
    isActive: PropTypes.bool,
    isBlue: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isGreen: PropTypes.bool,
    isInitial: PropTypes.bool,
    isLarge: PropTypes.bool,
    isLoading: PropTypes.bool,
    isOrange: PropTypes.bool,
    isRed: PropTypes.bool,
    isSecondary: PropTypes.bool,
    isSmall: PropTypes.bool,
    isWhite: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
    tooltip: tooltipShape,
  };
  handleOnClick = (event) => {
    const { isLoading, isDisabled, onClick } = this.props;
    onClick && !isDisabled && !isLoading && onClick(event);
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      this.handleOnClick(event);
    }
  };

  render() {
    const {
      asToggle,
      children,
      className,
      isBlue,
      isActive,
      isDisabled,
      isGreen,
      isInitial,
      isLarge,
      isLoading,
      isOrange,
      isRed,
      isSecondary,
      isSmall,
      isWhite: isWhiteButton,
      name,
      tooltip,
    } = this.props;
    const classesButton = cx({
      'button-linkio': true,
      'toggle-button-linkio': asToggle,
      'toggle-button-linkio_is-active': asToggle && isActive,
      'button-linkio_is-green': !isDisabled && isGreen,
      'button-linkio_is-red': !isDisabled && isRed,
      'button-linkio_is-blue': !isDisabled && isBlue,
      'button-linkio_is-orange': !isDisabled && isOrange,
      'button-linkio_is-white': !isDisabled && isWhiteButton,
      'button-linkio_is-secondary': !isDisabled && isSecondary,
      'button-linkio_is-disabled': isDisabled,
      'button-linkio_is-loading': isLoading,
      'button-linkio_is-normal': !isLarge && !isSmall,
      'button-linkio_is-small': isSmall,
      'button-linkio_is-initial-width': isInitial,
      [`${className}`]: className,
    });

    const buttonLabelWrapperClasses = cx('button-linkio__label-wrapper', {
      'button-linkio__label-wrapper_is-loading': isLoading,
    });

    if (asToggle) {
      return (
        <label className={classesButton}>
          <input
            defaultChecked={isActive}
            id={name}
            name={name}
            onClick={this.handleOnClick}
            onKeyDown={this.handleKeyDown}
            readOnly={isActive}
            type="checkbox"
          />
          {children}
        </label>
      );
    }

    return (
      <OverlayWithTooltipComponent tooltip={tooltip}>
        <button
          className={classesButton}
          onClick={this.handleOnClick}
          onKeyDown={this.handleKeyDown}
          type="button"
        >
          {
            <>
              {isLoading && <SpinnerComponent isBlack={isWhiteButton} isWhite={!isWhiteButton} />}
              <div className={buttonLabelWrapperClasses}>{children}</div>
            </>
          }
        </button>
      </OverlayWithTooltipComponent>
    );
  }
}

export default ButtonComponent;
