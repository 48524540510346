import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import EoCampaignStepActionsDropdown from './EoCampaignStepActionsDropdown';
import AddEoCampaignStepDropdown from './AddEoCampaignStepDropdown';

import { eoCampaignStepsList } from 'common/propTypesShapes/eoCampaignSteps';
import { translate } from 'common/i18n';

import ClockIcon from 'common/icons/ClockIcon';
import MailIcon from 'common/icons/MailIcon';

import './eoCampaignStepsList.scss';

function buildEmailStepView({ emailSubject }) {
  const emailSubjectText =
    emailSubject ??
    translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailSubject.noSubjectMessage');

  const subjectMessageClassname = cx({
    text_grey: !emailSubject,
  });

  return (
    <div className="eo-campaign-steps-list-item__subject">
      <span className="text_grey">
        {`${translate('eoCampaignStep.eoCampaignStepEmail.inputs.emailSubject.label')}: `}
      </span>
      <span className={subjectMessageClassname}>{emailSubjectText}</span>
    </div>
  );
}

function buildWaitStepView({ measure, value }) {
  return <span className="text_size16">{` ${value} ${measure}`}</span>;
}

const EoCampaignStepsListComponent = ({
  canCreate,
  immutableEoCampaignSteps,
  onAddEoCampaignStep,
  onSelectEoCampaignStep,
  selectedEoCampaignStep,
}) => {
  const eoCampaignSteps = immutableEoCampaignSteps.toJS();

  const handleEoCampaignStepClick = (eoCampaignStepId) => () => {
    onSelectEoCampaignStep(eoCampaignSteps.find(({ id }) => id === eoCampaignStepId));
  };

  return (
    <div className="eo-campaign-steps-list__wrapper">
      <h3>{translate('eoCampaignStep.title')}</h3>

      <ul className="eo-campaign-steps-list">
        {eoCampaignSteps.map((stepItem) => {
          const { id, eoCampaignStepActionType, position, eoCampaignStepAction } = stepItem;

          const isEmailStep = eoCampaignStepActionType === 'EoCampaignStepEmail';
          const isActive = id === selectedEoCampaignStep.id;

          const eoCampaignStepContentStyles = cx('eo-campaign-steps-list-item__content', {
            'eo-campaign-steps-list-item__content_active': isActive,
            'eo-campaign-steps-list-item__content_white-bg': isEmailStep,
          });

          return (
            <li key={id} className="eo-campaign-steps-list-item">
              <div className="eo-campaign-steps-list-item__icon-wrapper">
                {isEmailStep ? <MailIcon stroke="#777" /> : <ClockIcon />}
              </div>
              <div className={eoCampaignStepContentStyles} onClick={handleEoCampaignStepClick(id)}>
                {isActive && <div className="eo-campaign-steps-list-item__active-border-line" />}

                <span className="eo-campaign-steps-list-item__title">
                  {`${position}. ${translate(
                    `eoCampaignStep.actionTypes.${eoCampaignStepActionType}`,
                  )}`}
                </span>

                {isEmailStep && buildEmailStepView(eoCampaignStepAction)}
                {!isEmailStep && buildWaitStepView(eoCampaignStepAction)}

                {isActive && <EoCampaignStepActionsDropdown eoCampaignStepId={id} />}
              </div>
            </li>
          );
        })}

        {canCreate && (
          <li className="eo-campaign-steps-list-item">
            <AddEoCampaignStepDropdown onAddEoCampaignStep={onAddEoCampaignStep} />
          </li>
        )}
      </ul>
    </div>
  );
};

EoCampaignStepsListComponent.propTypes = {
  canCreate: PropTypes.bool,
  immutableEoCampaignSteps: eoCampaignStepsList,
  onAddEoCampaignStep: PropTypes.func,
  onSelectEoCampaignStep: PropTypes.func,
};

export default EoCampaignStepsListComponent;
