import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoMessagesStart,
  fetchEoMessagesSuccess,
  fetchEoMessagesFailure,
  fetchEoMessageStart,
  fetchEoMessageFailure,
  deleteEoMessagesStart,
  deleteEoMessagesSuccess,
  deleteEoMessagesFailure,
} from 'actions/eoMessageActions';

import { resetSelectedIds } from 'actions/ui_actions';
import { fetchEoMessagesPagySuccess } from 'actions/pagy_actions';

import { translate } from 'common/i18n';

import { showErrorMessageFromTranslation, showSuccessMessage } from 'common/utils';

import { deleteToAPI, getFromAPI, handleAPIError, processObjToSnakeCaseDeep } from 'api/utils';

export async function fetchEoMessages(dispatch, params) {
  dispatch(fetchEoMessagesStart());
  const { eoCampaignId } = params;

  try {
    const resp = await getFromAPI(Routes.eo_messages_path({ id: eoCampaignId }), {
      ...processObjToSnakeCaseDeep(params),
    });
    dispatch(fetchEoMessagesSuccess(resp.eo_messages));
    dispatch(fetchEoMessagesPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoMessagesFailure,
      translate('eoMessage.messages.cantFetchEoMessages'),
    );
  }
}

export async function fetchEoMessage(dispatch, eoCampaignId, eoMessageId) {
  dispatch(fetchEoMessageStart());

  try {
    const eoMessage = await getFromAPI(Routes.eo_message_path(eoCampaignId, eoMessageId));
    return processObjToCamelCaseDeep(eoMessage);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchEoMessageFailure,
      translate('eoMessage.messages.cantFetchEoMessage'),
    );
  }
}

export async function deleteEoMessages(dispatch, eoCampaignId, ids) {
  dispatch(deleteEoMessagesStart());

  try {
    const resp = await deleteToAPI(Routes.eo_messages_path(eoCampaignId, { ids }));
    if (isEmpty((resp || {}).errors)) {
      dispatch(resetSelectedIds());
      dispatch(deleteEoMessagesSuccess(ids));
      showSuccessMessage(dispatch, translate('eoMessage.messages.deleteSuccess')(ids.length));
    } else {
      dispatch(deleteEoMessagesFailure(ids));
      showErrorMessageFromTranslation(dispatch, 'eoMessage.messages.cantDeleteEoMessages');
    }
    return resp;
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      deleteEoMessagesFailure,
      translate('eoMessage.messages.cantDeleteEoMessages'),
    );
  }
}
