import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Table from 'reactabular-table';

import { urlChangingHistoryList } from '../../../common/prop_types_shapes';

import columnsGenerator from '../../../common/tables/columns_generator';
import { textColumn } from '../../../common/tables/columns';

import { translate } from '../../../common/i18n';

import { Modal } from '../../../components_linkio/modal_component';
import ButtonComponent from '../../../components_linkio/button_component';

import './urlChangingHistoryPopup.scss';

const UrlChangingHistoryPopupComponent = ({
  onCloseModal,
  changesHistory,
}) => {
  function dateValueFormatter(value) {
    const date = new Date(value).toISOString();

    return moment(date).format('MMM DD, YYYY');
  }

  const columns = columnsGenerator([
    textColumn({
      className: 'url-changing-history-table__date-column',
      name: 'date',
      headerLabel: translate('rankTracker.changesHistoryPopup.table.date'),
      cellValueFormatter: dateValueFormatter,
    }),
    textColumn({
      className: 'capybara--url-changing-history-table__link-column',
      name: 'link',
      headerLabel: translate('rankTracker.changesHistoryPopup.table.url'),
      isLink: true,
    }),
  ]);

  const rows = changesHistory.toJS();

  return (
    <Modal show className="url-changing-history__modal">
      <Modal.Header>
        <Modal.Title>{translate('rankTracker.changesHistoryPopup.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="url-changing-history-table__wrapper">
          <Table.Provider
            columns={columns}
            className="url-changing-history-table__table"
          >
            <Table.Header />
            <Table.Body rowKey="link" rows={rows} />
          </Table.Provider>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="url-changing-history-table__btn-group">
          <ButtonComponent isGreen isInitial onClick={onCloseModal}>
            {translate('rankTracker.changesHistoryPopup.buttons.ok')}
          </ButtonComponent>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

UrlChangingHistoryPopupComponent.propTypes = {
  changesHistory: urlChangingHistoryList,
  onCloseModal: PropTypes.func.isRequired,
};

export default UrlChangingHistoryPopupComponent;
