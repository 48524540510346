import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { cardInfoShape } from 'common/prop_types_shapes';

import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import CreditCardIcon from 'common/icons/credit_card.svg';

import './cardInfo.scss';

const CardInfoComponent = ({ cardInfo, onUpdateCardClick }) => {
  const { last4 } = cardInfo;
  const last4Stub = last4 ? `xxxx-xxxx-xxxx-${last4}` : '';
  const iconFunc = () => <img src={CreditCardIcon} />;
  const inputIcon = last4 ? iconFunc : void 0;

  return (
    <div className="card-info-component">
      <h4 className="card-info-component__title">
        {translate('billing.cardInfoSection.title')}
      </h4>
      <div className="card-info-component__input">
        <InputComponent
          label={translate('billing.cardInfoSection.inputs.cardInfo.label')}
          isDisabled
          value={last4Stub}
          icon={inputIcon}
        />
      </div>
      <ButtonComponent onClick={onUpdateCardClick}>
        {translate('billing.cardInfoSection.buttons.update')}
      </ButtonComponent>
    </div>
  );
};

CardInfoComponent.propTypes = {
  cardInfo: cardInfoShape,
  onUpdateCardClick: PropTypes.func.isRequired,
};

export default CardInfoComponent;
