import { translate } from 'common/i18n';

import {
  addPagesToAtpStart,
  addPagesToAtpFailure,
  addPagesToAtpSuccess,
  autodiscoverPagesViaSitemapStarted,
  autodiscoverPagesViaSitemapFailure,
  fetchBulkSetupBrandPagesStart,
  fetchBulkSetupBrandPagesSuccess,
  fetchBulkSetupBrandPagesFailure,
  getTitleTagsByUrlStart,
  getTitleTagsByUrlSuccess,
  getTitleTagsByUrlFailure,
  importPagesManuallyStart,
  importPagesManuallySuccess,
  importPagesManuallyFailure,
  updateDraftBrandPageStart,
  updateDraftBrandPageSuccess,
  updateDraftBrandPageFailure,
} from 'actions/bulkPagesSetupActions';

import { fetchBulkPagesSetupPagySuccess } from 'actions/pagy_actions';
import { fetchBrandsContextSuccess } from 'actions/brandsContextActions';

import { showSuccessMessage } from 'common/utils';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCase,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function autodiscoverPagesViaSitemap(dispatch, brandId) {
  dispatch(autodiscoverPagesViaSitemapStarted(brandId));

  try {
    await postToAPI(Routes.autodiscover_via_sitemap_bulk_pages_setup_path({ brand_id: brandId }));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      autodiscoverPagesViaSitemapFailure,
      translate('errors.cantAutodiscoverPagesViaSitemap'),
    );
  }
}

export async function fetchBulkSetupBrandPages(dispatch, brandId, params = {}) {
  dispatch(fetchBulkSetupBrandPagesStart());

  try {
    const resp = await getFromAPI(
      Routes.bulk_pages_setup_path({ brand_id: brandId }),
      processObjToSnakeCaseDeep(params),
    );
    dispatch(fetchBulkSetupBrandPagesSuccess(resp.draft_brand_pages));
    dispatch(fetchBulkPagesSetupPagySuccess(brandId, resp.pagy));
    dispatch(fetchBrandsContextSuccess(brandId, resp.brand_context));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchBulkSetupBrandPagesFailure,
      translate('errors.cantFetchBulkSetupBrandPages'),
    );
  }
}

export async function importPagesManually(dispatch, brandId, pagesListArray) {
  dispatch(importPagesManuallyStart());

  try {
    const resp = await postToAPI(
      Routes.import_manually_bulk_pages_setup_path({ brand_id: brandId }),
      { bulk_pages_setup: { brand_page_uris: pagesListArray } },
    );
    dispatch(importPagesManuallySuccess(resp.draft_brand_pages));
    showSuccessMessage(
      dispatch,
      translate('bulkPagesSetup.messages.manualImportSuccess')(
        resp.uniq_and_valid_entries_count,
        resp.dupe_or_invalid_entries_count,
      ),
    );
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      importPagesManuallyFailure,
      translate('errors.cantImportPagesManually'),
    );
  }
}

export async function importPageManually(dispatch, brandId, pageLink) {
  dispatch(importPagesManuallyStart());

  try {
    const resp = await postToAPI(
      Routes.import_manually_bulk_pages_setup_path({ brand_id: brandId }),
      { bulk_pages_setup: { brand_page_uris: [pageLink] } },
    );
    dispatch(importPagesManuallySuccess(resp.draft_brand_pages));

    !resp.dupe_or_invalid_entries_count &&
      showSuccessMessage(dispatch, translate('bulkPagesSetup.messages.createPageSuccess'));

    return processObjToCamelCaseDeep(resp.draft_brand_pages[0]);
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      importPagesManuallyFailure,
      translate('errors.cantCreateDraftBrandPage'),
    );
  }
}

export async function updateDraftBrandPage(dispatch, page) {
  dispatch(updateDraftBrandPageStart());

  try {
    const resp = await patchToAPI(
      Routes.update_brand_page_bulk_pages_setup_path({ brand_page_id: page.id }),
      {
        draft_brand_page: processObjToSnakeCase(page),
      },
    );
    dispatch(updateDraftBrandPageSuccess(resp));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      updateDraftBrandPageFailure,
      translate('errors.cantUpdateDraftBrandPage'),
    );
  }
}

export async function addPagesToAtp(dispatch, brandId, pageIdsArray) {
  dispatch(addPagesToAtpStart());

  try {
    const resp = await patchToAPI(Routes.add_to_atp_bulk_pages_setup_path({ brand_id: brandId }), {
      bulk_pages_setup: { brand_page_ids: pageIdsArray },
    });

    if (resp.success) {
      dispatch(addPagesToAtpSuccess(resp.draft_brand_pages));
    } else {
      dispatch(addPagesToAtpFailure(resp.draft_brand_pages));
    }
    return resp;
  } catch (err) {
    handleAPIError(dispatch, err, addPagesToAtpFailure, translate('errors.cantAddPagesToAtp'));

    return void 0;
  }
}

export async function getTitleTagsByUrl(dispatch, pageLink) {
  dispatch(getTitleTagsByUrlStart());
  try {
    const resp = await postToAPI(
      Routes.title_by_url_bulk_pages_setup_index_path({
        url: pageLink,
      }),
    );

    dispatch(getTitleTagsByUrlSuccess(resp.draft_brand_pages));

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    handleAPIError(dispatch, err, getTitleTagsByUrlFailure, translate('errors.cantFetchTitleTags'));
  }
}
