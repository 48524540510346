import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';

import { fetchSimpleBrands } from 'api/simpleBrand';
import { optionsSelector } from 'selectors';
import { optionsList } from 'common/prop_types_shapes';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import EoCampaignsBulkActionsComponent from './EoCampaignsBulkActionsComponent';

const EoCampaignsBulkActionsContainer = ({
  brandsOptions,
  canCreate,
  changeQueryParams,
  dispatch,
  queryParams,
}) => {
  React.useEffect(() => {
    fetchSimpleBrands(dispatch);
  }, [dispatch]);

  const brandIdFromQueryParams =
    !isEmpty(queryParams) && queryParams.filters && queryParams.filters.brand_id_eq;

  const currentBrandId = parseInt(brandIdFromQueryParams, 10) || null;

  function handleChangeCurrentBrand(option) {
    const newBrandId = option ? option.value : null;
    const newQueryParams = { ...queryParams, filters: { brand_id_eq: newBrandId } };

    changeQueryParams(newQueryParams);
  }

  return (
    <EoCampaignsBulkActionsComponent
      canCreate={canCreate}
      currentBrandId={currentBrandId}
      handleChangeCurrentBrand={handleChangeCurrentBrand}
      immutableBrandsOptions={brandsOptions}
    />
  );
};

EoCampaignsBulkActionsContainer.propTypes = {
  brandsOptions: optionsList,
  canCreate: PropTypes.bool,
  dispatch: PropTypes.func,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const options = optionsSelector(state);
  const brandsOptions = options.get('brandsOptions');

  return {
    brandsOptions,
  };
}

export default withQueryParams(ConnectStoreHOC(connect(select)(EoCampaignsBulkActionsContainer)));
