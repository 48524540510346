import { isEmpty } from 'lodash';

import processObjToCamelCaseDeep from './utils/processObjToCamelCaseDeep';

import {
  fetchEoCampaignsStart,
  fetchEoCampaignsSuccess,
  fetchEoCampaignsFailure,
  fetchEoCampaignStart,
  fetchEoCampaignSuccess,
  fetchEoCampaignFailure,
  fetchEoCampaignMetricsStart,
  fetchEoCampaignMetricsSuccess,
  fetchEoCampaignMetricsFailure,
  createEoCampaignStart,
  createEoCampaignSuccess,
  createEoCampaignFailure,
  updateEoCampaignStart,
  updateEoCampaignSuccess,
  updateEoCampaignFailure,
  deleteEoCampaignsStart,
  deleteEoCampaignsSuccess,
  deleteEoCampaignsFailure,
} from 'actions/eoCampaignActions';

import { fetchEoCampaignsPagySuccess } from 'actions/pagy_actions';

import { translate } from 'common/i18n';

import {
  showErrorMessageFromTranslation,
  showSuccessMessage,
  showSuccessMessageFromTranslation,
} from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  patchToAPI,
  postToAPI,
  processObjToSnakeCase,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoCampaigns(dispatch, params) {
  dispatch(fetchEoCampaignsStart());

  try {
    const resp = await getFromAPI(Routes.eo_campaigns_path(), processObjToSnakeCaseDeep(params));
    dispatch(fetchEoCampaignsSuccess(resp.eo_campaigns));
    dispatch(fetchEoCampaignsPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoCampaignsFailure,
      translate('eoCampaign.messages.cantFetchEoCampaigns'),
    );
  }
}

export async function fetchEoCampaign(dispatch, eoCampaignId) {
  dispatch(fetchEoCampaignStart());

  try {
    const eoCampaign = await getFromAPI(Routes.eo_campaign_path({ id: eoCampaignId }));
    dispatch(fetchEoCampaignSuccess(eoCampaign));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoCampaignFailure,
      translate('eoCampaign.messages.cantFetchEoCampaign'),
    );
  }
}

export async function createEoCampaign(dispatch, data) {
  dispatch(createEoCampaignStart());

  try {
    const resp = await postToAPI(Routes.eo_campaigns_path(), eoCampaignObject(data));

    if (isEmpty((resp || {}).errors)) {
      dispatch(createEoCampaignSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoCampaign.messages.createSuccess');
    } else {
      dispatch(createEoCampaignFailure(resp));
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      createEoCampaignFailure,
      translate('eoCampaign.messages.cantCreateEoCampaign'),
    );
  }
}

export async function updateEoCampaign(dispatch, data) {
  dispatch(updateEoCampaignStart());

  try {
    const resp = await patchToAPI(Routes.eo_campaign_path({ id: data.id }), eoCampaignObject(data));
    if (isEmpty((resp || {}).errors)) {
      dispatch(updateEoCampaignSuccess(resp));
      showSuccessMessageFromTranslation(dispatch, 'eoCampaign.messages.updateSuccess');
    } else {
      dispatch(updateEoCampaignFailure(resp));
      showErrorMessageFromTranslation(dispatch, 'eoCampaign.messages.cantUpdateEoCampaign');
    }

    return processObjToCamelCaseDeep(resp);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      updateEoCampaignFailure,
      translate('eoCampaign.messages.cantUpdateEoCampaign'),
    );
  }
}

export async function deleteEoCampaigns(dispatch, ids) {
  dispatch(deleteEoCampaignsStart());

  try {
    await deleteToAPI(Routes.eo_campaigns_path({ ids }));

    dispatch(deleteEoCampaignsSuccess(ids));

    showSuccessMessage(dispatch, translate('eoCampaign.messages.deleteSuccess'));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoCampaignsFailure,
      translate('eoCampaign.messages.cantDeleteEoCampaign'),
    );
  }
}

export async function fetchEoCampaignMetrics(dispatch, eoCampaignId) {
  dispatch(fetchEoCampaignMetricsStart());

  try {
    const eoCampaignMetrics = await getFromAPI(
      Routes.overview_eo_campaign_path({ id: eoCampaignId }),
    );

    dispatch(fetchEoCampaignMetricsSuccess(eoCampaignMetrics));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoCampaignMetricsFailure,
      translate('eoCampaign.messages.cantFetchEoCampaignMetrics'),
    );
  }
}

const eoCampaignObject = (data) => ({ eo_campaign: processObjToSnakeCase(data) });
