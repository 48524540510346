export const FETCH_CHANGES_HISTORY__FAILURE = 'FETCH_CHANGES_HISTORY__FAILURE';
export const FETCH_CHANGES_HISTORY__START = 'FETCH_CHANGES_HISTORY__START';
export const FETCH_CHANGES_HISTORY__SUCCESS = 'FETCH_CHANGES_HISTORY__SUCCESS';

export function fetchChangesHistoryFailure(errors) {
  return { type: FETCH_CHANGES_HISTORY__FAILURE, errors };
}

export function fetchChangesHistoryStart() {
  return { type: FETCH_CHANGES_HISTORY__START };
}

export function fetchChangesHistorySuccess(changesHistory, brandId) {
  return { type: FETCH_CHANGES_HISTORY__SUCCESS, changesHistory, brandId };
}
