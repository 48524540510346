import React from 'react';
import PropTypes from 'prop-types';

import { optionsList } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import {
  getCurrentTimezoneName,
  getCurrentTimezoneNameOrSimilarBySameOffset,
  getCurrentTimezoneOffset,
} from 'utils/timezoneHelpers';

import RadioButton from 'components_linkio/RadioButton';
import Select from 'components_linkio/Select';

import './timezoneSelect.scss';

const currentTimezoneName = getCurrentTimezoneName();
const currentTimezoneOffset = getCurrentTimezoneOffset();

function initTimezoneMode({ showAccountTimezoneOption, defaultTimezoneName, accountTimezone }) {
  const isAccountTimezoneMode =
    showAccountTimezoneOption && accountTimezone === defaultTimezoneName;

  if (isAccountTimezoneMode) {
    return 'accountTimezone';
  }

  if (currentTimezoneName === defaultTimezoneName) {
    return 'currentTimezone';
  }

  return 'customTimezone';
}

const TimezoneSelectComponent = ({
  accountTimezone,
  defaultTimezoneName,
  errors,
  onChangeTimezoneName,
  immutableTimezonesOptions,
  showAccountTimezoneOption,
}) => {
  const timezonesOptions = React.useMemo(() => immutableTimezonesOptions.toJS(), [
    immutableTimezonesOptions.size,
  ]);

  const [timezoneName, setTimezoneName] = React.useState(defaultTimezoneName);
  const [timezoneMode, setTimezoneMode] = React.useState(() =>
    initTimezoneMode({ showAccountTimezoneOption, defaultTimezoneName, accountTimezone }),
  );

  React.useEffect(() => {
    setTimezoneMode(
      initTimezoneMode({ showAccountTimezoneOption, defaultTimezoneName, accountTimezone }),
    );
    setTimezoneName(defaultTimezoneName);
  }, [defaultTimezoneName]);

  function handleChangeTimezoneMode(event) {
    const updatedTimezoneMode = event.target.value;

    let updatedTimezoneName = '';

    // User decided to use current time zone
    if (updatedTimezoneMode === 'currentTimezone') {
      // Use user's time zone name if we have the same one in our time zone options
      // OR find similar time zone name that exists in our time zone options by the same offset
      updatedTimezoneName = getCurrentTimezoneNameOrSimilarBySameOffset(timezonesOptions);
    }

    if (updatedTimezoneMode === 'accountTimezone') {
      updatedTimezoneName = accountTimezone;
    }

    setTimezoneMode(updatedTimezoneMode);
    setTimezoneName(updatedTimezoneName);
    onChangeTimezoneName(updatedTimezoneName);
  }

  function handleChangeSelectOption({ value }) {
    setTimezoneName(value);
    onChangeTimezoneName(value);
  }

  const currentTimezoneOffsetLabel = `(GMT${currentTimezoneOffset}) ${currentTimezoneName}`;

  const accountTimezoneOffsetLabel =
    showAccountTimezoneOption &&
    (timezonesOptions.find(({ value }) => value === accountTimezone) || {}).label;

  const isCurrentTimezoneMode = timezoneMode === 'currentTimezone';
  const isAccountTimezoneMode = timezoneMode === 'accountTimezone';
  const isCustomTimezoneMode = timezoneMode === 'customTimezone';

  const timezoneSelectValue = isCustomTimezoneMode
    ? timezonesOptions.find(({ value }) => value === timezoneName)
    : {};

  return (
    <div className="timezone-select-component">
      <h4>{translate('uiComponents.timezone.title')}</h4>

      <RadioButton
        checked={isCurrentTimezoneMode}
        value="currentTimezone"
        onChange={handleChangeTimezoneMode}
        message={{
          type: 'hint',
          text: currentTimezoneOffsetLabel,
        }}
      >
        {translate('uiComponents.timezone.currentTimezone')}
      </RadioButton>

      {showAccountTimezoneOption && (
        <RadioButton
          checked={isAccountTimezoneMode}
          value="accountTimezone"
          onChange={handleChangeTimezoneMode}
          message={{
            type: 'hint',
            text: accountTimezoneOffsetLabel,
          }}
        >
          {translate('uiComponents.timezone.accountTimezone')}
        </RadioButton>
      )}

      <RadioButton
        checked={isCustomTimezoneMode}
        value="customTimezone"
        onChange={handleChangeTimezoneMode}
      >
        {translate('uiComponents.timezone.customTimezone')}
      </RadioButton>

      <Select
        wrapperClassName="timezone-select-component__select"
        isDisabled={!isCustomTimezoneMode}
        blurInputOnSelect={false}
        placeholder={translate('uiComponents.timezone.timezoneSelect.placeholder')}
        triggerChangeOnBlur={false}
        onChange={handleChangeSelectOption}
        options={timezonesOptions}
        value={timezoneSelectValue || null}
        maxMenuHeight={500}
        message={errors && { type: 'error', text: errors.timezone }}
      />
    </div>
  );
};

TimezoneSelectComponent.propTypes = {
  accountTimezone: PropTypes.string,
  defaultTimezoneName: PropTypes.string,
  errors: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    timezone: PropTypes.string,
  }),
  immutableTimezonesOptions: optionsList,
  onChangeTimezoneName: PropTypes.func,
  showAccountTimezoneOption: PropTypes.bool,
};

export default TimezoneSelectComponent;
