export const FETCH_ANCHOR_SAMPLES__FAILURE = 'FETCH_ANCHOR_SAMPLES__FAILURE';
export const FETCH_ANCHOR_SAMPLES__START = 'FETCH_ANCHOR_SAMPLES__START';
export const FETCH_ANCHOR_SAMPLES__SUCCESS = 'FETCH_ANCHOR_SAMPLES__SUCCESS';

export function fetchAnchorSamplesFailure(errors) {
  return { type: FETCH_ANCHOR_SAMPLES__FAILURE, errors };
}

export function fetchAnchorSamplesStart() {
  return { type: FETCH_ANCHOR_SAMPLES__START };
}

export function fetchAnchorSamplesSuccess(anchorSamples) {
  return { type: FETCH_ANCHOR_SAMPLES__SUCCESS, anchorSamples };
}
