export default {
  eoEmailVerification: {
    title: 'Email Verification',
    buttons: {
      buyVerificationCredits: 'Buy Verification Credits',
    },
    buyVerificationCreditsPopup: {
      title: 'Buy Email Verification Credits',
      howManyCredits: 'How many credits do you want to buy?',
      credits: 'credits',
      buttons: {
        submit: 'Buy Now',
      },
    },
    inputs: {
      validDays: {
        label: 'How long should the email be considered deliverable?',
        hint: 'Empty means forever. Might be a good time to re-validate every 365 day for example.',
      },
    },
  },
};
