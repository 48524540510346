import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Table from 'reactabular-table';

import { marketerShape, userShape } from '../../../common/prop_types_shapes';
import {
  textColumn,
} from '../../../common/tables/columns';
import columnsGenerator from '../../../common/tables/columns_generator';

import './marketers_table_component.scss';


class OrdersTableComponent extends Component {
  static propTypes = {
    currentUser: userShape,
    marketers: PropTypes.arrayOf(marketerShape),
    onMarketerClickAction: PropTypes.func,
  };

  handleLinkClick = (marketer) => (event) => {
    event.preventDefault();
    this.props.onMarketerClickAction(marketer);
  };

  cellValueFormatterTextToLink = (value, extra) => {
    return (
      <a
        href="#"
        onClick={this.handleLinkClick(extra.rowData)}
      >
        {value}
      </a>
    );
  }

  cellValueFormatterCheckEmail = (value) => this.props.currentUser.get('email') === value ? 'You' : value;

  generateColumns = () => {
    return columnsGenerator([
      textColumn({
        name: 'title',
        headerLabel: 'Account Name',
        cellValueFormatter: this.cellValueFormatterTextToLink,
        className: 'marketers-table-component__column',
      }),
      textColumn({
        name: 'owner',
        headerLabel: 'Owner',
        cellValueFormatter: this.cellValueFormatterCheckEmail,
        className: 'marketers-table-component__column',
      }),
    ]);
  };

  render() {
    const { marketers } = this.props;

    return (
      <div className="marketers-table-component">
        <div className="marketers-table-component__table-header">
          <div className="marketers-table-component__header-text">Accounts</div>
        </div>
        <Table.Provider
          className="marketers-table-component__table"
          columns={this.generateColumns()}
        >
          <Table.Header />
          <Table.Body
            rowKey="id"
            rows={marketers}
          />
        </Table.Provider>
      </div>
    );
  }
}

export default OrdersTableComponent;
