import { translate } from '../common/i18n';
import { showSuccessMessage } from '../common/utils';

import {
  bulkUpdatePageTypesFailure,
  bulkUpdatePageTypesStart,
  bulkUpdatePageTypesSuccess,
  fetchPageTypesFailure,
  fetchPageTypesStart,
  fetchPageTypesSuccess,
} from '../actions/page_type_actions';

import {
  getFromAPI,
  handleAPIError,
  patchToAPI,
  processObjToCamelCase,
  processObjToSnakeCase,
} from './utils';

async function fetchPageTypes(dispatch) {
  dispatch(fetchPageTypesStart());

  try {
    const resp = await getFromAPI(Routes.page_types_path());
    dispatch(fetchPageTypesSuccess(resp.page_types));
    return camelCasedPageTypes(resp.page_types);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      fetchPageTypesFailure,
      translate('errors.cantFetchPageTypes'),
    );
  }
}

export async function bulkUpdatePageTypes(dispatch, pageTypes) {
  dispatch(bulkUpdatePageTypesStart());

  try {
    const resp = await patchToAPI(Routes.bulk_update_page_types_path(), {
      page_types: snakeCasedPageTypes(pageTypes),
    });
    dispatch(bulkUpdatePageTypesSuccess(resp.page_types));
    showSuccessMessage(
      dispatch,
      translate('percentageSettings.pageTypeDefaults.messages.bulkUpdateSuccessfully'),
    );
    return camelCasedPageTypes(resp.page_types);
  } catch (err) {
    return handleAPIError(
      dispatch,
      err,
      bulkUpdatePageTypesFailure,
      translate('errors.cantUpdatePageTypes'),
    );
  }
}

const snakeCasedPageTypes = (pageTypes) =>
  pageTypes.map((pageType) => processObjToSnakeCase(pageType));
const camelCasedPageTypes = (pageTypes) =>
  pageTypes.map((pageType) => processObjToCamelCase(pageType));

export { fetchPageTypes };
