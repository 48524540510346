import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CategorizationSamplesComponent from './CategorizationSamplesComponent';
import ConnectStoreHOC from 'startup/connect_store_hoc';
import { fetchAnchorSamples } from 'api/anchorSamples';
import { anchorSamplesSelector } from 'selectors/anchorSamplesSelector';
import { anchorSamplesList } from 'common/propTypesShapes/anchorSamples';

const CategorizationSamplesContainer = ({ dispatch, pageId, anchorSamples }) => {
  React.useEffect(() => {
    fetchAnchorSamples(dispatch, pageId);
  }, []);

  return <CategorizationSamplesComponent anchorSamples={anchorSamples.toJS()} />;
};

CategorizationSamplesContainer.propTypes = {
  anchorSamples: anchorSamplesList,
  dispatch: PropTypes.func,
  pageId: PropTypes.number,
};

function select(state) {
  const anchorSamples = anchorSamplesSelector(state);

  return {
    anchorSamples,
  };
}

export default ConnectStoreHOC(connect(select)(CategorizationSamplesContainer));
