import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import AutodiscoverPagesViaSitemapButtonComponent from './AutodiscoverPagesViaSitemapButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { autodiscoverPagesViaSitemap } from 'api/bulkPagesSetup';
import { currentIdSelector } from 'selectors';

const AutodiscoverPagesViaSitemapButtonContainer = ({ brandId, dispatch, isLoading }) => {
  function handleClick() {
    autodiscoverPagesViaSitemap(dispatch, brandId);
  }

  return <AutodiscoverPagesViaSitemapButtonComponent onClick={handleClick} isLoading={isLoading} />;
};

AutodiscoverPagesViaSitemapButtonContainer.propTypes = {
  brandId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function select(state, ownProps) {
  const brandId = Number(currentIdSelector(state, ownProps));
  const isLoading = state.getIn(
    ['ui', 'loading', 'fetchAutoDiscoverPagesViaSitemap', String(brandId)],
    false,
  );

  return {
    brandId,
    isLoading,
  };
}

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(AutodiscoverPagesViaSitemapButtonContainer);
