export default {
  brandPagePercentage: {
    actual: "Actual",
    anchorType: "Anchor Type",
    buttons: {
      changeIdealPercentages: "Change Ideal Percentages",
      switchButtons: {
        title: "See how your percentages will change after you publish the suggested anchors.",
      },
    },
    changeIdealPercentages: {
      title: "Ideal Percentages Calculated From:",
    },
    competitorsPercentageSheme: {
      title: 'Competitors',
    },
    competitorsPercentageShemeOptionsLabels: {
      average: 'Average',
      reduceKwBy10: "Reduce KW by 10",
      reduceKwBy25: "Reduce KW by 25",
      reduceKwBy50: "Reduce KW by 50",
    },
    customPercentageSheme: {
      title: 'Custom',
    },
    defaultPercentageSheme: {
      title: 'System Defaults',
    },
    ideal: "Ideal",
    includeNotStartedLinks: "Not Started",
    includeStartedLinks: "Started",
    newCompetitorsLabel: 'Add New Competitors',
    newPercentageLabel: 'Add Custom Percentages',
    projected: "Projected",
    title: "Anchor Progress",
    uncategorizedAnchors: "Uncategorized anchors",
    percentageGroup: {
      branded: 'Branded',
      hybrid: 'Hybrid',
      keyword: 'Keyword',
      natural: 'Natural',
      url: 'URL',
    },
    labelPrefix: "Ideal from:",
    anchorTextPercentage: "Anchor Text Percentage",
  },
};
