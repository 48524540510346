export const ADD_OR_REMOVE_EO_CAMPAIGN_INBOX__SUCCESS = 'ADD_OR_REMOVE_EO_CAMPAIGN_INBOX__SUCCESS';
export const ADD_OR_REMOVE_EO_CAMPAIGN_INBOX__FAILURE = 'ADD_OR_REMOVE_EO_CAMPAIGN_INBOX__FAILURE';

export function addOrRemoveEoCampaignInboxSuccess(eoInbox) {
  return { type: ADD_OR_REMOVE_EO_CAMPAIGN_INBOX__SUCCESS, eoInbox };
}

export function addOrRemoveEoCampaignInboxFailure(errors) {
  return { type: ADD_OR_REMOVE_EO_CAMPAIGN_INBOX__FAILURE, errors };
}
