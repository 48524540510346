import { fromJS, Map as iMap } from 'immutable';

import {
  FETCH_RANK_TRACKER_SETTINGS__SUCCESS,
} from '../actions/rankTrackerSetupActions';

const initialState = iMap();

export default function rankTrackerSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RANK_TRACKER_SETTINGS__SUCCESS: {
      const { settings } = action;

      state = fromJS(settings);

      break;
    }
  }

  return state;
}
