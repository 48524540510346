import React, { Component } from 'react';
import { isEmpty, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { acceptInvitation } from 'api/user';
import { validateAcceptInvitationFrom, clearErrors } from 'common/utils';
import AcceptInvitationFormComponent from 'components/auth/accept_invitation_form_component';


class AcceptInvitationPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };
  constructor(props) {
    super(props);

    const processedProps = this.processProps();

    this.state = {
      error: null,
      errors: {},
      processedProps,
    };
  }

  handleSetPasswordButtonAction = async (invitationToken, password, passwordConfirmation) => {
    this.setState(clearErrors(this.state));
    const errors = validateAcceptInvitationFrom({ password, passwordConfirmation });

    if (!isEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    const formData = new FormData();
    const { dispatch } = this.props;

    formData.append('user[password]', password);
    formData.append('user[password_confirmation]', passwordConfirmation);
    formData.append('user[invitation_token]', invitationToken);

    const res = await acceptInvitation(dispatch, formData);

    if (res.type && res.type === 'error') {
      this.setState(res.message);
    } else {
      ((window || {}).location || {}).href = Routes.root_url({ format: null });
    }
  }

  processProps = () => {
    const processedProps = cloneDeep(this.props);

    processedProps.handleSetPasswordButtonClick = this.handleSetPasswordButtonAction;

    return processedProps;
  }

  render() {
    const { processedProps, errors, error } = this.state;

    return (
      <AcceptInvitationFormComponent {...processedProps} errors={errors} error={error} />
    );
  }
}

export default ConnectStoreHOC(connect()(AcceptInvitationPage));
