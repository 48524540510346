import { Map as iMap } from 'immutable';

import {
  FETCH_AHREFS_CONNECTION__START,
  FETCH_AHREFS_CONNECTION__SUCCESS,
  FETCH_AHREFS_CONNECTION__FAILURE,
} from 'actions/ahrefs_connection_actions';

import {
  DELETE_PAGE__FAILURE,
  DELETE_PAGE__START,
  DELETE_PAGE__SUCCESS,
  FETCH_BACKLINKS_COUNT_FROM_AHREFS__FAILURE,
  FETCH_BACKLINKS_COUNT_FROM_AHREFS__START,
  FETCH_BACKLINKS_COUNT_FROM_AHREFS__SUCCESS,
  FETCH_PAGES__FAILURE,
  FETCH_PAGES__START,
  FETCH_PAGES__SUCCESS,
  UPDATE_PAGE__START,
  UPDATE_PAGE__SUCCESS,
} from 'actions/brand_page_actions';

import {
  CREATE_BRAND__FAILURE,
  CREATE_BRAND__START,
  CREATE_BRAND__SUCCESS,
  FETCH_BRANDS__FAILURE,
  FETCH_BRANDS__START,
  FETCH_BRANDS__SUCCESS,
  UPDATE_BRAND__FAILURE,
  UPDATE_BRAND__START,
  UPDATE_BRAND__SUCCESS,
} from 'actions/brand_actions';

import {
  CREATE_MARKETER__START,
  CREATE_MARKETER__SUCCESS,
  CREATE_MARKETER__FAILURE,
  FETCH_MARKETERS__START,
  FETCH_MARKETERS__SUCCESS,
  FETCH_MARKETERS__FAILURE,
} from 'actions/marketer_actions';

import {
  CATEGORIZE_DESTINATION_LINKS__FAILURE,
  CATEGORIZE_DESTINATION_LINKS__START,
  CATEGORIZE_DESTINATION_LINKS__SUCCESS,
  CREATE_DESTINATION_LINKS__FAILURE,
  CREATE_DESTINATION_LINKS__START,
  CREATE_DESTINATION_LINKS__SUCCESS,
  DELETE_DESTINATION_LINKS__FAILURE,
  DELETE_DESTINATION_LINKS__START,
  DELETE_DESTINATION_LINKS__SUCCESS,
  FETCH_DESTINATION_LINKS__FAILURE,
  FETCH_DESTINATION_LINKS__START,
  FETCH_BRAND_PAGE_DLS__SUCCESS,
  FETCH_PAGE_DASHBOARD_DLS__SUCCESS,
  UPDATE_DESTINATION_LINKS__FAILURE,
  UPDATE_DESTINATION_LINKS__START,
  UPDATE_DESTINATION_LINKS__SUCCESS,
  UPDATE_PAGE_DASHBOARD_DLS__SUCCESS,
} from 'actions/destination_link_actions';

import {
  FETCH_EMPLOYEES__START,
  FETCH_EMPLOYEES__FAILURE,
  FETCH_EMPLOYEES__SUCCESS,
  CREATE_EMPLOYEE__START,
  CREATE_EMPLOYEE__SUCCESS,
  CREATE_EMPLOYEE__FAILURE,
  UPDATE_EMPLOYEE__START,
  UPDATE_EMPLOYEE__SUCCESS,
  UPDATE_EMPLOYEE__FAILURE,
  DELETE_EMPLOYEES__START,
  DELETE_EMPLOYEES__SUCCESS,
  DELETE_EMPLOYEES__FAILURE,
  INVITE_EMPLOYEE__START,
  INVITE_EMPLOYEE__SUCCESS,
  INVITE_EMPLOYEE__FAILURE,
} from 'actions/employee_actions';

import {
  FETCH_PERMISSIONS__FAILURE,
  FETCH_PERMISSIONS__START,
  FETCH_PERMISSIONS__SUCCESS,
  UPDATE_USER_PERMISSIONS__FAILURE,
  UPDATE_USER_PERMISSIONS__START,
  UPDATE_USER_PERMISSIONS__SUCCESS,
} from 'actions/permissionActions';

import {
  BULK_UPDATE_PAGE_TYPES__FAILURE,
  BULK_UPDATE_PAGE_TYPES__START,
  BULK_UPDATE_PAGE_TYPES__SUCCESS,
  FETCH_PAGE_TYPES__FAILURE,
  FETCH_PAGE_TYPES__START,
  FETCH_PAGE_TYPES__SUCCESS,
} from 'actions/page_type_actions';

import {
  FETCH_PERCENTAGE_SCHEMES__FAILURE,
  FETCH_PERCENTAGE_SCHEMES__START,
  FETCH_PERCENTAGE_SCHEMES__SUCCESS,
} from 'actions/percentage_scheme_actions';

import {
  FETCH_SUBSCRIPTION_PLANS__FAILURE,
  FETCH_SUBSCRIPTION_PLANS__START,
  FETCH_SUBSCRIPTION_PLANS__SUCCESS,
} from 'actions/subscription_plan_actions';

import {
  FETCH_BULK_SETUP_BRAND_PAGES__FAILURE,
  FETCH_BULK_SETUP_BRAND_PAGES__START,
  FETCH_BULK_SETUP_BRAND_PAGES__SUCCESS,
  AUTODISCOVER_PAGES_VIA_SITEMAP__STARTED,
  AUTODISCOVER_PAGES_VIA_SITEMAP__FAILURE,
  AUTODISCOVER_PAGES_VIA_SITEMAP__SUCCESS,
  IMPORT_FROM_GSC__FAILURE,
  IMPORT_FROM_GSC__START,
  IMPORT_FROM_GSC__SUCCESS,
} from 'actions/bulkPagesSetupActions';

import {
  FETCH_BACKLINKS__FAILURE,
  FETCH_BACKLINKS__START,
  FETCH_BACKLINKS__SUCCESS,
  IMPORT_BACKLINKS_FROM_API__FAILURE,
  IMPORT_BACKLINKS_FROM_API__START,
  IMPORT_BACKLINKS_FROM_API__SUCCESS,
  IMPORT_BACKLINKS_MANUALLY__FAILURE,
  IMPORT_BACKLINKS_MANUALLY__START,
  IMPORT_BACKLINKS_MANUALLY__SUCCESS,
} from 'actions/backlinkActions';

import {
  FETCH_CHANGES_HISTORY__FAILURE,
  FETCH_CHANGES_HISTORY__START,
  FETCH_CHANGES_HISTORY__SUCCESS,
} from 'actions/changesHistoryActions';

import {
  FETCH_BRANDS_METRICS__START,
  FETCH_BRANDS_METRICS__SUCCESS,
  FETCH_BRANDS_METRICS__FAILURE,
} from 'actions/accountDashboardActions';

import { RESET_LOADING_AND_CHANGING_STATUSES } from 'actions/ui_actions';

const initialState = iMap({
  changeBrand: false,
  changeDl: false,
  changeEmployee: false,
  changePage: false,
  changePageTypes: false,
  changeUserPermissions: false,
  createMarketer: false,
  fetchAhrefsConnection: false,
  fetchPages: false,
  fetchBacklinks: false,
  fetchChangesHistory: false,
  fetchBrands: false,
  fetchBrandsMetrics: false,
  fetchBulkSetupBrandPages: false,
  fetchDLs: false,
  fetchEmployees: false,
  fetchMarketers: false,
  fetchPageTypes: false,
  fetchPercentageSchemes: false,
  fetchPermissions: false,
  fetchSubscriptionPlans: false,
  fetchAutoDiscoverPagesViaSitemap: iMap(),
  importFromGSC: iMap(),
  importBacklinksManuallyInProgress: iMap(),
  importBacklinksFromApiInProgress: iMap(),
});

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAGES__START: {
      state = state.set('fetchPages', true);

      break;
    }
    case FETCH_PAGES__FAILURE:
    case FETCH_PAGES__SUCCESS: {
      state = state.set('fetchPages', false);

      break;
    }
    case DELETE_PAGE__START:
    case FETCH_BACKLINKS_COUNT_FROM_AHREFS__START:
    case UPDATE_PAGE__START: {
      state = state.set('changePage', true);

      break;
    }
    case CREATE_EMPLOYEE__START:
    case UPDATE_EMPLOYEE__START:
    case DELETE_EMPLOYEES__START:
    case INVITE_EMPLOYEE__START: {
      state = state.set('changeEmployee', true);

      break;
    }
    case DELETE_PAGE__FAILURE:
    case DELETE_PAGE__SUCCESS:
    case FETCH_BACKLINKS_COUNT_FROM_AHREFS__FAILURE:
    case FETCH_BACKLINKS_COUNT_FROM_AHREFS__SUCCESS:
    case UPDATE_PAGE__SUCCESS: {
      state = state.set('changePage', false);

      break;
    }
    case UPDATE_USER_PERMISSIONS__START: {
      state = state.set('changeUserPermissions', true);

      break;
    }
    case UPDATE_USER_PERMISSIONS__FAILURE:
    case UPDATE_USER_PERMISSIONS__SUCCESS: {
      state = state.set('changeUserPermissions', false);

      break;
    }
    case FETCH_AHREFS_CONNECTION__START: {
      state = state.set('fetchAhrefsConnection', true);

      break;
    }
    case FETCH_BRANDS__START: {
      state = state.set('fetchBrands', true);

      break;
    }
    case FETCH_DESTINATION_LINKS__START: {
      state = state.set('fetchDLs', true);

      break;
    }
    case FETCH_EMPLOYEES__START: {
      state = state.set('fetchEmployees', true);

      break;
    }
    case FETCH_EMPLOYEES__FAILURE:
    case FETCH_EMPLOYEES__SUCCESS: {
      state = state.set('fetchEmployees', false);

      break;
    }
    case CREATE_DESTINATION_LINKS__START:
    case DELETE_DESTINATION_LINKS__START:
    case CATEGORIZE_DESTINATION_LINKS__START:
    case UPDATE_DESTINATION_LINKS__START: {
      state = state.set('changeDl', true);

      break;
    }
    case CREATE_BRAND__START:
    case UPDATE_BRAND__START: {
      state = state.set('changeBrand', true);

      break;
    }
    case CREATE_EMPLOYEE__SUCCESS:
    case CREATE_EMPLOYEE__FAILURE:
    case UPDATE_EMPLOYEE__SUCCESS:
    case UPDATE_EMPLOYEE__FAILURE:
    case DELETE_EMPLOYEES__SUCCESS:
    case DELETE_EMPLOYEES__FAILURE:
    case INVITE_EMPLOYEE__SUCCESS:
    case INVITE_EMPLOYEE__FAILURE: {
      state = state.set('changeEmployee', false);

      break;
    }
    case FETCH_AHREFS_CONNECTION__SUCCESS:
    case FETCH_AHREFS_CONNECTION__FAILURE: {
      state = state.set('fetchAhrefsConnection', false);

      break;
    }
    case FETCH_BRANDS__SUCCESS:
    case FETCH_BRANDS__FAILURE: {
      state = state.set('fetchBrands', false);

      break;
    }
    case FETCH_DESTINATION_LINKS__FAILURE:
    case FETCH_BRAND_PAGE_DLS__SUCCESS:
    case FETCH_PAGE_DASHBOARD_DLS__SUCCESS: {
      state = state.set('fetchDLs', false);

      break;
    }
    case CREATE_DESTINATION_LINKS__FAILURE:
    case CREATE_DESTINATION_LINKS__SUCCESS:
    case DELETE_DESTINATION_LINKS__FAILURE:
    case DELETE_DESTINATION_LINKS__SUCCESS:
    case CATEGORIZE_DESTINATION_LINKS__FAILURE:
    case CATEGORIZE_DESTINATION_LINKS__SUCCESS:
    case UPDATE_DESTINATION_LINKS__SUCCESS:
    case UPDATE_DESTINATION_LINKS__FAILURE:
    case UPDATE_PAGE_DASHBOARD_DLS__SUCCESS: {
      state = state.set('changeDl', false);

      break;
    }
    case CREATE_BRAND__SUCCESS:
    case CREATE_BRAND__FAILURE:
    case UPDATE_BRAND__SUCCESS:
    case UPDATE_BRAND__FAILURE: {
      state = state.set('changeBrand', false);

      break;
    }
    case BULK_UPDATE_PAGE_TYPES__START: {
      state = state.set('changePageTypes', true);

      break;
    }
    case BULK_UPDATE_PAGE_TYPES__FAILURE:
    case BULK_UPDATE_PAGE_TYPES__SUCCESS: {
      state = state.set('changePageTypes', false);

      break;
    }
    case FETCH_PAGE_TYPES__START: {
      state = state.set('fetchPageTypes', true);

      break;
    }
    case FETCH_PAGE_TYPES__FAILURE:
    case FETCH_PAGE_TYPES__SUCCESS: {
      state = state.set('fetchPageTypes', false);

      break;
    }
    case FETCH_PERCENTAGE_SCHEMES__START: {
      state = state.set('fetchPercentageSchemes', true);

      break;
    }
    case FETCH_PERCENTAGE_SCHEMES__FAILURE:
    case FETCH_PERCENTAGE_SCHEMES__SUCCESS: {
      state = state.set('fetchPercentageSchemes', false);

      break;
    }
    case FETCH_PERMISSIONS__START: {
      state = state.set('fetchPermissions', true);

      break;
    }
    case FETCH_PERMISSIONS__FAILURE:
    case FETCH_PERMISSIONS__SUCCESS: {
      state = state.set('fetchPermissions', false);

      break;
    }
    case FETCH_SUBSCRIPTION_PLANS__START: {
      state = state.set('fetchSubscriptionPlans', true);

      break;
    }
    case FETCH_SUBSCRIPTION_PLANS__FAILURE:
    case FETCH_SUBSCRIPTION_PLANS__SUCCESS: {
      state = state.set('fetchSubscriptionPlans', false);

      break;
    }

    case CREATE_MARKETER__START: {
      state = state.set('createMarketer', true);

      break;
    }

    case CREATE_MARKETER__SUCCESS:
    case CREATE_MARKETER__FAILURE: {
      state = state.set('createMarketer', false);

      break;
    }

    case FETCH_BULK_SETUP_BRAND_PAGES__START: {
      state = state.set('fetchBulkSetupBrandPages', true);

      break;
    }
    case FETCH_BULK_SETUP_BRAND_PAGES__FAILURE:
    case FETCH_BULK_SETUP_BRAND_PAGES__SUCCESS: {
      state = state.set('fetchBulkSetupBrandPages', false);

      break;
    }

    case AUTODISCOVER_PAGES_VIA_SITEMAP__STARTED: {
      const brandId = String(action.payload);

      return state.setIn(['fetchAutoDiscoverPagesViaSitemap', brandId], true);
    }

    case AUTODISCOVER_PAGES_VIA_SITEMAP__FAILURE: {
      const brandId = String(action.payload.brandId);

      return state.setIn(['fetchAutoDiscoverPagesViaSitemap', brandId], false);
    }

    case AUTODISCOVER_PAGES_VIA_SITEMAP__SUCCESS: {
      const brandId = String(action.payload);

      return state.setIn(['fetchAutoDiscoverPagesViaSitemap', brandId], false);
    }

    case IMPORT_FROM_GSC__START: {
      const brandId = String(action.payload);

      return state.setIn(['importFromGSC', brandId], true);
    }
    case IMPORT_FROM_GSC__FAILURE:
    case IMPORT_FROM_GSC__SUCCESS: {
      const brandId = String(action.payload);

      return state.setIn(['importFromGSC', brandId], false);
    }

    case FETCH_MARKETERS__START: {
      state = state.set('fetchMarketers', true);

      break;
    }

    case FETCH_MARKETERS__FAILURE:
    case FETCH_MARKETERS__SUCCESS: {
      state = state.set('fetchMarketers', false);

      break;
    }

    case FETCH_BACKLINKS__START: {
      state = state.set('fetchBacklinks', true);

      break;
    }

    case FETCH_BACKLINKS__FAILURE:
    case FETCH_BACKLINKS__SUCCESS: {
      state = state.set('fetchBacklinks', false);

      break;
    }

    case FETCH_CHANGES_HISTORY__START: {
      state = state.set('fetchChangesHistory', true);

      break;
    }

    case FETCH_CHANGES_HISTORY__FAILURE:
    case FETCH_CHANGES_HISTORY__SUCCESS: {
      state = state.set('fetchChangesHistory', false);

      break;
    }

    case IMPORT_BACKLINKS_FROM_API__START: {
      const brandId = String(action.payload);

      return state.setIn(['importBacklinksFromApiInProgress', brandId], true);
    }
    case IMPORT_BACKLINKS_FROM_API__FAILURE:
    case IMPORT_BACKLINKS_FROM_API__SUCCESS: {
      const brandId = String(action.payload);

      return state.setIn(['importBacklinksFromApiInProgress', brandId], false);
    }

    case IMPORT_BACKLINKS_MANUALLY__START: {
      const brandId = String(action.payload);

      return state.setIn(['importBacklinksManuallyInProgress', brandId], true);
    }

    case IMPORT_BACKLINKS_MANUALLY__FAILURE:
    case IMPORT_BACKLINKS_MANUALLY__SUCCESS: {
      const brandId = String(action.payload);

      return state.setIn(['importBacklinksManuallyInProgress', brandId], false);
    }

    case FETCH_BRANDS_METRICS__START: {
      state = state.set('fetchBrandsMetrics', true);

      break;
    }

    case FETCH_BRANDS_METRICS__FAILURE:
    case FETCH_BRANDS_METRICS__SUCCESS: {
      state = state.set('fetchBrandsMetrics', false);

      break;
    }
    case RESET_LOADING_AND_CHANGING_STATUSES: {
      state = initialState;

      break;
    }
  }

  return state;
}
