import moment from 'moment';

const CHARTS_DEFAULT_DATE_RANGE = {
  dateStart: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
  dateEnd: moment().format('YYYY-MM-DD'),
};

const RANK_TRACKER_DEFAULT_DATE_RANGE = {
  dateStart: moment().subtract(1, 'months').format('YYYY-MM-DD'),
  dateEnd: moment().format('YYYY-MM-DD'),
};

const METRICS_FOR_BACKLINK_CHART = [
  'liveReferringDomains',
  'nfReferringDomains',
  'notIndexedReferringDomains',
  'referringDomains',
];

const METRICS_FILTERS = {
  countReferringDomains: {
    isFound: true,
    includedEq: true,
  },
  liveReferringDomains: {
    isFound: true,
    includedNotNull: true,
  },
  nfReferringDomains: {
    isFound: true,
    nofollowEq: true,
  },
  nfAndIndexedReferringDomains: {
    isFound: true,
    nofollowEq: true,
    indexed: true,
  },
  notIndexedReferringDomains: {
    isFound: true,
    includedNotNull: true,
    notIndexed: true,
  },
  overallBacklinks: {
    isFound: true,
  },
};

export const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export {
  METRICS_FILTERS,
  CHARTS_DEFAULT_DATE_RANGE,
  METRICS_FOR_BACKLINK_CHART,
  RANK_TRACKER_DEFAULT_DATE_RANGE,
};
