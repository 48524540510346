import React from 'react';

const LensIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.81 4.612c.233 0 .44.1.57.25a.659.659 0 0 1-.077.776l-.052.05c-.416.401-.91.877-1.35 1.277-.52.476-.987.901-1.377 1.277-.156.125-.312.2-.493.2-.052 0-.13 0-.208-.025a.543.543 0 0 1-.26-.15c-.467-.476-.779-.776-1.246-1.227-.182-.175-.26-.425-.156-.676.104-.25.286-.4.571-.425.182-.025.364.05.52.2l.779.776c.337-.325.753-.7 1.116-1.026l.026-.025c.39-.35.831-.751 1.169-1.077.13-.1.286-.175.467-.175zm6.492 8.061c.83.676.935 1.878.233 2.654-.7.8-1.947.901-2.778.225-.13-.1-.234-.225-.338-.35l-2.077-3.105C7.64 13.725 4.03 13.3 1.798 10.971c-2.545-2.654-2.364-6.785.39-9.238C4.94-.72 9.25-.545 11.795 2.108c2.233 2.354 2.363 5.834.467 8.312l3.039 2.253zM11.095 9.47a4.926 4.926 0 0 0-.416-6.36C8.705 1.033 5.355.908 3.226 2.81 1.07 4.712.94 7.942 2.914 9.994c1.714 1.778 4.467 2.128 6.57.927.312-.2.598-.4.883-.651.26-.25.52-.526.728-.801z" fillRule="evenodd" />
    </svg>
  );
};

LensIcon.defaultProps = {
  fill: "#000",
  height: '16px',
  width: '16px',
};

export default LensIcon;
