import { buildWordInPastTenseWithPluralization } from 'common/utils';

export default {
  eoEmailTemplate: {
    breadcrumbsTitle: 'Email Templates',
    bulkActions: {
      addEoEmailTemplate: 'Add Email Template',
    },
    messages: {
      cantFetchEoEmailTemplates: "Can't load email templates",
      cantFetchEoEmailTemplatesCount: "Can't load total email templates amount",
      cantFetchEoEmailTemplate: "Can't load email template",
      cantCreateEoEmailTemplate: "Can't create email template",
      cantUpdateEoEmailTemplate: "Can't update email template",
      cantDeleteEoEmailTemplates: "Can't delete email template(s)",
      createSuccess: 'Email Template was created successfully.',
      deleteSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization(
          'Email Template',
          count,
        )} deleted successfully.`;
      },
      updateSuccess: 'Email Template was updated successfully.',
      cantFetchEoEmailTemplateFolders: "Can't load email template folders",
      cantFetchEoCmarketerEmailTemplateFoldersAsOptions:
        "Can't load email template folders as options",
      cantFetchEoEmailTemplateFolder: "Can't load email template folder",
      cantCreateEoEmailTemplateFolder: "Can't create email template folder",
      cantUpdateEoEmailTemplateFolder: "Can't update email template folder",
      cantDeleteEoEmailTemplateFolders: "Can't delete email template folder(s)",
      createFolderSuccess: 'Email Template Folder was created successfully.',
      deleteFoldersSuccess: (count) => {
        return `${buildWordInPastTenseWithPluralization(
          'Email Template Folder(s)',
          count,
        )} deleted successfully.`;
      },
      updateFolderSuccess: 'Email Template Folder was updated successfully.',
    },
    eoEmailTemplateFormPopup: {
      buttons: {
        addFolder: 'Add New Folder',
        cancel: 'Undo',
        close: 'Close',
        remove: 'Delete Email Template',
        submit: (isExistingEoEmailTemplate) =>
          isExistingEoEmailTemplate ? 'Update Template' : 'Add Template',
      },
      eoTemplatesHint: {
        mainText: 'Looking for inspiration? Have a look at our',
        linkText: 'collection of templates',
      },
      inputs: {
        title: {
          label: 'Template title',
        },
        subject: {
          label: 'Subject',
        },
        body: {
          label: 'Body',
        },
        folder: {
          label: 'Organise it into a folder',
        },
        brand: {
          label: 'Associate with a brand',
        },
        private: {
          label: 'Keep it private',
          hint: 'Check the box for not to share this Email Template with other team members.',
        },
        default: {
          label: 'Make it a default Email Template',
          hint: 'Check the box to make this template a preloaded Email Template.',
        },
      },
      title: (isExistingEoEmailTemplate) =>
        isExistingEoEmailTemplate ? 'Update Email Template' : 'Add Email Template',
    },
    sidebar: {
      tabs: {
        all: 'All',
        default: 'Defaults',
        private: 'Private',
        shared: 'Shared',
      },
      title: 'Folders',
      addFolder: {
        button: '+ Add folder',
        popup: {
          title: 'Create new folder',
          inputLabel: 'Title',
          cancel: 'Cancel',
          submit: 'Create folder',
        },
      },
    },
    table: {
      blankState: {
        body: 'You can manually add Email Templates',
        title: "You don't have any Email Templates yet",
      },
      headers: {
        folderTitle: 'Folder',
        brandName: 'Brand Association',
        title: 'Title',
        userName: 'Created by',
      },
    },
  },
};
