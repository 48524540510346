export default {
  keywordsManager: {
    hints: {
      metaKeywords: (keywords) => `Meta Keywords: ${keywords}`,
      keywords: 'Please add one keyword per line',
      title: (title) => `Title Tag: ${title}`,
    },
    labels: {
      addKeywords: (isBrandLevel) => `Add New Keywords for the ${isBrandLevel ? 'Brand' : 'Page'}`,
    },
    table: {
      keywords: 'Keywords',
    },
    title: (isBrandLevel) => (isBrandLevel ? 'Linkio tracks these keywords' : 'Page Keywords'),
  },
};
