import {
  addEoBlacklistedDomainsStart,
  addEoBlacklistedDomainsSuccess,
  addEoBlacklistedDomainsFailure,
  fetchEoBlacklistedDomainsStart,
  fetchEoBlacklistedDomainsSuccess,
  fetchEoBlacklistedDomainsFailure,
  deleteEoBlacklistedDomainsStart,
  deleteEoBlacklistedDomainsSuccess,
  deleteEoBlacklistedDomainsFailure,
} from 'actions/eoBlacklistedDomainActions';

import { resetSelectedIds } from 'actions/ui_actions';
import { fetchEoBlacklistedDomainsPagySuccess } from 'actions/pagy_actions';
import { translate } from 'common/i18n';
import { showSuccessMessage } from 'common/utils';

import {
  deleteToAPI,
  getFromAPI,
  handleAPIError,
  postToAPI,
  processObjToSnakeCaseDeep,
} from 'api/utils';

export async function fetchEoBlacklistedDomains(dispatch, params) {
  dispatch(fetchEoBlacklistedDomainsStart());

  try {
    const resp = await getFromAPI(
      Routes.eo_blacklisted_domains_path(),
      processObjToSnakeCaseDeep(params),
    );
    dispatch(fetchEoBlacklistedDomainsSuccess(resp.eo_blacklisted_domains));
    dispatch(fetchEoBlacklistedDomainsPagySuccess(resp.pagy));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      fetchEoBlacklistedDomainsFailure,
      translate('eoBlacklistedDomain.messages.cantFetchEoBlacklistedDomains'),
    );
  }
}

export async function addEoBlacklistedDomains(dispatch, domainsList, brandId) {
  dispatch(addEoBlacklistedDomainsStart());

  try {
    const resp = await postToAPI(Routes.eo_blacklisted_domains_path(), {
      eo_blacklisted_domains: domainsList,
      brand_id: brandId,
    });

    dispatch(addEoBlacklistedDomainsSuccess(resp.eo_blacklisted_domains));
    showSuccessMessage(
      dispatch,
      translate('eoBlacklistedDomain.messages.addEoBlacklistedDomainsSuccess')(
        resp.uniq_and_valid_entries,
        resp.dupe_or_invalid_entries,
      ),
    );
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      addEoBlacklistedDomainsFailure,
      translate('eoBlacklistedDomain.messages.cantAddEoBlacklistedDomains'),
    );
  }
}

export async function deleteEoBlacklistedDomains(dispatch, ids) {
  dispatch(deleteEoBlacklistedDomainsStart());

  try {
    await deleteToAPI(Routes.eo_blacklisted_domains_path({ ids }));

    showSuccessMessage(dispatch, translate('eoBlacklistedDomain.messages.deleteSuccess')(ids.length));

    dispatch(resetSelectedIds());
    dispatch(deleteEoBlacklistedDomainsSuccess(ids));
  } catch (err) {
    handleAPIError(
      dispatch,
      err,
      deleteEoBlacklistedDomainsFailure,
      translate('eoBlacklistedDomain.messages.cantDeleteEoBlacklistedDomains'),
    );
  }
}
