import React from 'react';
import PropTypes from 'prop-types';

import SearchEoDomainsInput from './SearchEoDomainsInput';
import DeleteEoDomainsButton from './DeleteEoDomainsButton';
import AddEoDomainButton from './AddEoDomainButton';

import './eoDomainsBulkActions.scss';
const EoDomainsBulkActionsComponent = ({ canCreate }) => {
  return (
    <div className="eo-domains-bulk-actions">
      <div className="eo-domains-bulk-actions__row">
        <div className="eo-domains-bulk-actions__row-item">
          <SearchEoDomainsInput />
          {canCreate && (
            <>
              <span className="vertical-separator" />
              <DeleteEoDomainsButton />
            </>
          )}
        </div>

        {canCreate && (
          <div className="eo-domains-bulk-actions__row-item">
            <AddEoDomainButton />
          </div>
        )}
      </div>
    </div>
  );
};

EoDomainsBulkActionsComponent.propTypes = {
  canCreate: PropTypes.bool,
};

export default EoDomainsBulkActionsComponent;
