import React from 'react';
import PropTypes from 'prop-types';

import { ATP_TABLE_TABS, ATP_TABLE_TABS_FILTERS } from './constants';

import { activeAtpTableTabString } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import TabsComponent, { TabItemComponent } from 'components_linkio/tabs_component';

import './atpTableTabs.scss';

const AtpTableTabsComponent = ({ activeAtpTableTab, destinationLinksCounts, onChangeTab }) => {
  const options = (tab) => {
    const tabFilter = ATP_TABLE_TABS_FILTERS[tab];

    return {
      active: tab === activeAtpTableTab,
      counter: tabFilter && destinationLinksCounts[tabFilter.statusEq],
    };
  };

  return (
    <div className="atp-table-tabs-component">
      <TabsComponent withRoundedTabs>
        {ATP_TABLE_TABS.map((tab) => (
          <TabItemComponent
            key={tab}
            index={tab}
            label={translate(`atpTableTabsComponent.${tab}.title`)}
            onClick={onChangeTab(tab)}
            options={options(tab)}
          />
        ))}
      </TabsComponent>
    </div>
  );
};

AtpTableTabsComponent.propTypes = {
  activeAtpTableTab: activeAtpTableTabString,
  destinationLinksCounts: PropTypes.objectOf(PropTypes.number),
  onChangeTab: PropTypes.func,
};

export default AtpTableTabsComponent;
