import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map as iMap } from 'immutable';

import CrawlingSettingsTable from './CrawlingSettingsTable';
import CrawlingSettingsEdit from './CrawlingSettingsEdit';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  crawlersSelector,
  optionsSelector,
  somethingIsLoadingSelector,
  subscriptionPlansSelector,
} from 'selectors';

import { updateCrawlerSettings } from 'api/crawler_settings';
import { fetchCrawlersStatus } from 'api/crawler';
import { fetchCurrentSubscription } from 'api/subscription';
import { fetchSubscriptionPlans } from 'api/subscription_plan';

import { openSubscriptionLimitReachedPopup } from 'actions/popup_actions';

import {
  crawlersShape,
  marketerShape,
  optionsMap,
  subscriptionShape,
  subscriptionPlanShape,
} from 'common/prop_types_shapes';

import {
  currentMarketerSelector,
  currentSubscriptionSelector,
} from 'selectors/railsContextSelectors';

import { translate } from 'common/i18n';

import './crawlingSettings.scss';

class CrawlerSettingsContainer extends Component {
  static propTypes = {
    crawlers: crawlersShape,
    currentMarketer: marketerShape,
    currentPlan: subscriptionPlanShape,
    currentSubscription: subscriptionShape,
    dispatch: PropTypes.func,
    options: optionsMap,
  };

  constructor(props) {
    super(props);

    this.state = {
      settingsIsVisible: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    Promise.all([
      fetchCurrentSubscription(dispatch),
      fetchSubscriptionPlans(dispatch),
      fetchCrawlersStatus(dispatch),
    ]);
  }

  handleSubmitSettings = async (value) => {
    const { dispatch } = this.props;

    await updateCrawlerSettings(dispatch, value);
    this.closeSettings();
  };

  handleClickUpgradeSubscription = (event) => {
    const { dispatch } = this.props;

    event && event.preventDefault();

    dispatch(openSubscriptionLimitReachedPopup({}));
  };

  handleCancelSettings = () => this.closeSettings();

  handleShowSettings = () => this.setState({ settingsIsVisible: true });

  closeSettings = () => this.setState({ settingsIsVisible: false });

  render() {
    const { settingsIsVisible } = this.state;
    const { crawlers, currentMarketer, currentPlan, currentSubscription, options } = this.props;

    return (
      <>
        <>
          <div className="crawler-settings-container__header">
            {translate('crawlerSettings.title')}
          </div>
          <div className="crawler-settings-container__description">
            {translate('crawlerSettings.description')}
          </div>
          <CrawlingSettingsTable
            crawlers={crawlers}
            currentMarketer={currentMarketer}
            currentPlan={currentPlan}
            currentSubscription={currentSubscription}
            onClickUpgradeSubscription={this.handleClickUpgradeSubscription}
            onCrawlingEnabledToggle={this.handleSubmitSettings}
            onEditSettingsClick={this.handleShowSettings}
            options={options}
          />
        </>
        {settingsIsVisible && (
          <CrawlingSettingsEdit
            currentMarketer={currentMarketer}
            onCancel={this.handleCancelSettings}
            onSubmit={this.handleSubmitSettings}
          />
        )}
      </>
    );
  }
}

function select(state, ownProps) {
  const crawlers = crawlersSelector(state, ownProps);
  const currentMarketer = currentMarketerSelector(state, ownProps);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const options = optionsSelector(state, ownProps);
  const somethingIsLoading = somethingIsLoadingSelector(state, ownProps);
  const subscriptionPlans = subscriptionPlansSelector(state, ownProps);

  const currentSubscriptionPlanId = currentSubscription.get('subscriptionPlanId');
  const currentPlan = subscriptionPlans.get(String(currentSubscriptionPlanId), iMap());

  return {
    crawlers,
    currentMarketer,
    currentPlan,
    currentSubscription,
    options,
    somethingIsLoading,
  };
}

export default ConnectStoreHOC(connect(select)(CrawlerSettingsContainer));
