import { fromJS } from 'immutable';

import {
  FETCH_AHREFS_ABILITIES__FAILURE,
  FETCH_AHREFS_ABILITIES__START,
  FETCH_AHREFS_ABILITIES__SUCCESS,
  FETCH_AHREFS_CONNECTION__FAILURE,
  FETCH_AHREFS_CONNECTION__START,
  FETCH_AHREFS_CONNECTION__SUCCESS,
  REVOKE_AHREFS_ACCESS__FAILURE,
  REVOKE_AHREFS_ACCESS__START,
  REVOKE_AHREFS_ACCESS__SUCCESS,
} from '../actions/ahrefs_connection_actions';

import {
  camelizeObjectDeep,
} from './utils';

const initialState = fromJS({
  abilities: {},
});


export default function ahrefsConnectionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AHREFS_ABILITIES__FAILURE:
    case FETCH_AHREFS_ABILITIES__START:
    case FETCH_AHREFS_CONNECTION__FAILURE:
    case FETCH_AHREFS_CONNECTION__START:
    case REVOKE_AHREFS_ACCESS__FAILURE:
    case REVOKE_AHREFS_ACCESS__START: {
      break;
    }
    case FETCH_AHREFS_CONNECTION__SUCCESS: {
      state = state.merge(state, fromJS(camelizeObjectDeep(action.connection)));

      break;
    }
    case FETCH_AHREFS_ABILITIES__SUCCESS: {
      state = state.mergeIn(['abilities'], fromJS(camelizeObjectDeep(action.abilities)));

      break;
    }
    case REVOKE_AHREFS_ACCESS__SUCCESS: {
      state = initialState;

      break;
    }
  }

  return state;
}
