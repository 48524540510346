import { fromJS, List as iList } from 'immutable';

import { camelizeObject } from './utils';

import { FETCH_ANCHOR_SAMPLES__SUCCESS } from 'actions/anchorSamplesActions';

const initialState = iList();

export default function anchorSamplesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ANCHOR_SAMPLES__SUCCESS: {
      state = fromJS(action.anchorSamples.map(camelizeObject));

      break;
    }
  }

  return state;
}
