import React, { useState } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';

import EoEmailTemplatesTabs from './EoEmailTemplatesTabs';
import EoEmailTemplatesList from './EoEmailTemplatesList';
import EoEmailTemplateAddFolderPopup from './EoEmailTemplateAddFolderPopup';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

import withQueryParams from 'decorators/withQueryParams';

import { currentIdSelector } from 'selectors';

import { translate } from 'common/i18n';

import './EoEmailTemplatesSidebar.scss';

const EoEmailTemplatesSidebarComponent = ({ folderId }) => {
  const [currentEmailTemplateFolderId, setCurrentEmailTemplateFolderId] = useState(
    parseInt(folderId, 10),
  );

  return (
    <div className="eo-email-templates-sidebar__wrapper">
      <div className="eo-email-templates-sidebar__header">
        <div className="eo-email-templates-sidebar__title">
          {translate(`eoEmailTemplate.sidebar.title`)}
        </div>

        <ToggleShowPopup Popup={<EoEmailTemplateAddFolderPopup />}>
          <div className="eo-email-templates-sidebar__add-folder">
            {translate('eoEmailTemplate.sidebar.addFolder.button')}
          </div>
        </ToggleShowPopup>
      </div>

      <EoEmailTemplatesTabs setCurrentEmailTemplateFolderId={setCurrentEmailTemplateFolderId} />
      <EoEmailTemplatesList
        currentEmailTemplateFolderId={currentEmailTemplateFolderId}
        setCurrentEmailTemplateFolderId={setCurrentEmailTemplateFolderId}
      />
    </div>
  );
};

EoEmailTemplatesSidebarComponent.propTypes = {
  folderId: PropTypes.string,
};

function select(state, ownProps) {
  const folderId = currentIdSelector(state, ownProps);

  return {
    folderId,
  };
}

export default compose(withQueryParams, connect(select))(EoEmailTemplatesSidebarComponent);
