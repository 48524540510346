import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { omitBy, isEmpty, compose } from 'lodash';

import ProspectsRemappingListComponent from './ProspectsRemappingListComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { importProspectsFromCsv } from 'api/prospects';

import { eoCustomFieldsSelector } from 'selectors/eoCustomFieldsSelector';
import { eoCampaignOptionsSelector } from 'selectors/eoCampaignOptionsSelector';
import { eoCustomFieldsList } from 'common/propTypesShapes/eoCustomFields';
import { fetchEoDripListsAsOptions } from 'api/eoDripList';
import { fetchEoCustomFields } from 'api/eoCustomField';
import { optionsList } from 'common/prop_types_shapes';
import { optionsSelector } from 'selectors';

import SpinnerComponent from 'components_linkio/Spinner';

const ProspectsRemappingListContainer = ({
  dispatch,
  eoCustomFields,
  eoCampaignOptions,
  eoDripListsOptions,
  fileS3Id,
  onCancel,
  spreadsheetAttrs,
}) => {
  const [fetchingStatus, setFetchingStatus] = React.useState('');

  React.useEffect(() => {
    async function fetchData() {
      setFetchingStatus('isFetching');
      Promise.all[
        (fetchEoDripListsAsOptions(dispatch), fetchEoCustomFields(dispatch, {})).then(() =>
          setFetchingStatus('finished'),
        )
      ];
    }

    fetchData();
  }, [dispatch]);

  async function handleImportProspects({
    fieldsToRemap,
    eoCustomFieldsToRemap,
    eoDripListId,
    duplicatesMode,
  }) {
    // Omit empty values
    const processedFieldsToRemap = omitBy(fieldsToRemap, isEmpty);

    await importProspectsFromCsv(
      dispatch,
      processedFieldsToRemap,
      eoCustomFieldsToRemap,
      fileS3Id,
      [eoDripListId],
      duplicatesMode,
    );
  }

  const defaultEoDripListId = eoCampaignOptions.get('eoDripListId', null);

  return (
    <>
      {fetchingStatus === 'finished' && (
        <ProspectsRemappingListComponent
          defaultEoDripListId={defaultEoDripListId}
          immutableEoCustomFields={eoCustomFields}
          immutableEoDripListsOptions={eoDripListsOptions}
          spreadsheetAttrs={spreadsheetAttrs}
          onCancel={onCancel}
          onImportProspects={handleImportProspects}
        />
      )}
      {fetchingStatus === 'isFetching' && <SpinnerComponent isBlack />}
    </>
  );
};

ProspectsRemappingListContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCustomFields: eoCustomFieldsList,
  eoDripListsOptions: optionsList,
  fileS3Id: PropTypes.string,
  onCancel: PropTypes.func,
  spreadsheetAttrs: PropTypes.object,
};

function select(state) {
  const eoCampaignOptions = eoCampaignOptionsSelector(state);
  const eoCustomFields = eoCustomFieldsSelector(state);

  const options = optionsSelector(state);
  const eoDripListsOptions = options.get('eoDripListsOptions');

  return {
    eoCampaignOptions,
    eoCustomFields,
    eoDripListsOptions,
  };
}

export default compose(ConnectStoreHOC, connect(select))(ProspectsRemappingListContainer);
