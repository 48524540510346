import React from 'react';
import PropTypes from 'prop-types';

import EoCampaignStepEmail from './EoCampaignStepEmail';
import EoCampaignStepWait from './EoCampaignStepWait';

import {
  eoCampaignStepShape,
  eoCampaignStepActionTypeShape,
} from 'common/propTypesShapes/eoCampaignSteps';

import './eoCampaignStep.scss';

const EoCampaignStepComponent = ({ onCancel, onSave, eoCampaignStep, eoCampaignStepPosition }) => {
  const { eoCampaignStepActionType } = eoCampaignStep;

  const isEoCampaignStepWait = eoCampaignStepActionType === 'EoCampaignStepWait';
  const isEoCampaignStepEmail = eoCampaignStepActionType === 'EoCampaignStepEmail';

  return (
    <div className="eo-campaign-step">
      {isEoCampaignStepWait && (
        <EoCampaignStepWait
          key={eoCampaignStep.id}
          eoCampaignStep={eoCampaignStep}
          eoCampaignStepPosition={eoCampaignStepPosition}
          onSave={onSave}
          onCancel={onCancel}
        />
      )}

      {isEoCampaignStepEmail && (
        <EoCampaignStepEmail
          key={eoCampaignStep.id}
          eoCampaignStep={eoCampaignStep}
          eoCampaignStepPosition={eoCampaignStepPosition}
          onSave={onSave}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

EoCampaignStepComponent.propTypes = {
  eoCampaignStep: eoCampaignStepShape,
  eoCampaignStepActionType: eoCampaignStepActionTypeShape,
  eoCampaignStepPosition: PropTypes.number,
  onCancel: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};

export default EoCampaignStepComponent;
