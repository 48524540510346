import React from 'react';
import { compose } from 'lodash/fp';

import withRorSsrContent from 'decorators/ror-ssr-content-boundary';

const AdminReportsPageComponent = () => {
  return <div> </div>;
};

export default compose(withRorSsrContent)(AdminReportsPageComponent);
