import React from 'react';

const ArrowIcon = ({ orientation, ...props }) => {
  let rotateDegree = '';

  switch (orientation) {
    case 'up':
      rotateDegree = 'rotate(90)';
      break;
    case 'right':
      rotateDegree = 'rotate(180)';
      break;
    case 'down':
      rotateDegree = 'rotate(270)';
      break;
    case 'left':
      rotateDegree = 'rotate(360)';
      break;
  }

  return (
    <svg {...props} transform={rotateDegree} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
      <path d="M6.015 0L0 5.013l6.015 5.012z" />
    </svg>
  );
};

ArrowIcon.defaultProps = {
  fill: '#D6DADC',
  width: '14px',
  height: '14px',
};

export default ArrowIcon;
