import { translate } from '../common/i18n';

import {
  handleAPIError,
  postToAPI,
} from './utils';


async function verifyRecaptcha(dispatch, token) {
  try {
    const resp = await postToAPI(
      Routes.verify_recaptcha_path(), { token }
    );
    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, (fn) => fn, translate('errors.cantVerifyRecaptcha'));
  }
}

export { verifyRecaptcha };
