import {
  fetchKeywordsWithChangesByUrlSuccess,
  fetchKeywordsWithChangesSuccess,
} from '../../actions/rankTrackerActions';

import { showErrorMessage } from 'common/utils';
import { translate } from 'common/i18n';

const handleDataforseoUploadingStatus = (dispatch, data) => {
  const { brandId, dataforseoUploadingStatus, groupByUrl, notFoundLabel } = data;

  switch (dataforseoUploadingStatus) {
    case 'started':
    case 'finished':
      if (groupByUrl) {
        dispatch(fetchKeywordsWithChangesByUrlSuccess(data.brandPages, brandId));
      } else {
        dispatch(fetchKeywordsWithChangesSuccess(data.keywords, brandId));
      }

      break;
    case 'failure':
      if (groupByUrl) {
        dispatch(fetchKeywordsWithChangesByUrlSuccess(data.brandPages, brandId));
      } else {
        dispatch(fetchKeywordsWithChangesSuccess(data.keywords, brandId));
      }

      notFoundLabel &&
        groupByUrl &&
        showErrorMessage(
          dispatch,
          translate('rankTracker.messages.rankTrackingFailure')(notFoundLabel),
          true,
        );
      break;
  }
};

export default handleDataforseoUploadingStatus;
