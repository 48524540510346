import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { visibleCompetitorPageIdSelector } from 'selectors';

import scrollToOpenedCompetitorPage from 'pages/Pages/utils/scrollToOpenedCompetitorPage';

import LinkIcon from 'common/icons/link';

import './scrollToCompetitorLink.scss';

const ScrollToCompetitorLinkComponent = ({
  history,
  match,
  competitorPageId,
  queryParams,
  visibleCompetitorsPageId,
}) => {
  function handleOpenCompetitorPage() {
    const competitorPageIsAlreadyOpened = Number(visibleCompetitorsPageId) === competitorPageId;

    if (competitorPageIsAlreadyOpened) {
      scrollToOpenedCompetitorPage();
      return;
    }

    const params = {
      ...queryParams,
      dlsGroupMode: 'all',
      format: null,
    };

    const { id, pageId: brandPageId, activeAtpTableTab } = match.params;

    const path = Routes.brand_page_tab_path(
      id,
      brandPageId,
      activeAtpTableTab,
      competitorPageId,
      params,
    );

    history.push(path);
  }

  return <LinkIcon className="auto-scroll-link" onClick={handleOpenCompetitorPage} />;
};

function select(state, ownProps) {
  const visibleCompetitorsPageId = visibleCompetitorPageIdSelector(state, ownProps);

  return {
    visibleCompetitorsPageId,
  };
}

ScrollToCompetitorLinkComponent.propTypes = {
  competitorPageId: PropTypes.string,
  pageId: PropTypes.number,
  visibleCompetitorsPageId: PropTypes.number,
  ...withQueryParamsPropTypes,
};

export default withQueryParams(ConnectStoreHOC(connect(select)(ScrollToCompetitorLinkComponent)));
