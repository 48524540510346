import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import { subscriptionShape } from 'common/prop_types_shapes';

import { Modal } from 'components_linkio/modal_component';
import TextAreaComponent from 'components_linkio/textarea_component';
import ButtonComponent from 'components_linkio/button_component';
import ContactUserOnFeedbackComponent from 'components/feedback_popup/ContactUserOnFeedbackComponent';
import RadioButton from 'components_linkio/RadioButton';

import './deleteAccountPopup.scss';

const deletionReasonsArray = [
  {
    key: 'tooExpensive',
  },
  {
    key: 'complexTool',
  },
  {
    key: 'needSomethingElse',
    needTextarea: true,
  },
  {
    key: 'other',
    needTextarea: true,
  },
];

export default class DeleteAccountPopupComponent extends React.Component {
  static propTypes = {
    currentSubscription: subscriptionShape,
    onClickCancel: PropTypes.func.isRequired,
    onClickSwitchToFreeAccount: PropTypes.func.isRequired,
    onDeleteAccount: PropTypes.func.isRequired,
  };

  state = {
    deletionReason: '',
    feedbackText: '',
    mayContactUser: 'yes',
  };

  handleInputChange = (inputName) => (event) => {
    const { feedbackText } = this.state;

    this.setState({ [inputName]: event.target.value });

    if (inputName === 'deletionReason' && feedbackText) {
      this.setState({ feedbackText: '' });
    }
  };

  handleDeleteAccount = () => this.props.onDeleteAccount(this.state);

  buildSwitchToFreeAccountLink = () => {
    return (
      <>
        {' ('}
        <span className="text_link" onClick={this.props.onClickSwitchToFreeAccount}>
          {translate('deleteAccountPopup.switchToFreeAccountLink')}
        </span>
        {')'}
      </>
    );
  };

  render() {
    const { currentSubscription, onClickCancel } = this.props;
    const { deletionReason, feedbackText, mayContactUser } = this.state;

    const currentSubscriptionPlanIsFree =
      currentSubscription.get('subscriptionPlanTitle') === 'Free';

    return (
      <Modal show className="delete-account-popup">
        <Modal.Header>
          <Modal.Title>{translate('deleteAccountPopup.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{translate('deleteAccountPopup.confirmationText')}</p>

          <p className="delete-account-popup__feedback-request">
            {translate('deleteAccountPopup.feedbackRequest')}
          </p>

          <ul>
            {deletionReasonsArray.map((reason) => {
              const reasonKey = reason.key;
              const isCurrentReason = deletionReason === reasonKey;

              return (
                <li key={reasonKey}>
                  <RadioButton
                    checked={isCurrentReason}
                    className="delete-account-popup__radio-btn-item"
                    onChange={this.handleInputChange('deletionReason')}
                    value={reasonKey}
                  >
                    {translate(`deleteAccountPopup.deletionReasons.${reasonKey}`)}
                    {reasonKey === 'tooExpensive' &&
                      !currentSubscriptionPlanIsFree &&
                      this.buildSwitchToFreeAccountLink()}
                  </RadioButton>
                  {reason.needTextarea && (
                    <TextAreaComponent
                      disabled={!isCurrentReason}
                      onChange={isCurrentReason ? this.handleInputChange('feedbackText') : void 0}
                      value={isCurrentReason ? feedbackText : ''}
                    />
                  )}
                </li>
              );
            })}
          </ul>

          <ContactUserOnFeedbackComponent
            mayContactUser={mayContactUser}
            onMayContactUserChange={this.handleInputChange('mayContactUser')}
          />
        </Modal.Body>

        <Modal.Footer>
          <div className="delete-account-popup__btn-group">
            <div className="delete-account-popup__btn">
              <ButtonComponent isWhite onClick={onClickCancel}>
                {translate('deleteAccountPopup.buttons.cancel')}
              </ButtonComponent>
            </div>

            <div className="delete-account-popup__btn">
              <ButtonComponent isRed onClick={this.handleDeleteAccount}>
                {translate('deleteAccountPopup.buttons.delete')}
              </ButtonComponent>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
