import React from 'react';
import PropType from 'prop-types';

import DontHaveAccountComponent from '../dont_have_account_component';
import RoboImage from '../../../common/icons/linkio_robo';

import './devise_header_component.scss';


const DeviseHeaderComponent = ({ logIn, signUp, skipHeader }) => {
  return (
    <div className="devise-header-component">
      {!skipHeader &&
        <div className="devise-header-component__header">
          <DontHaveAccountComponent logIn={logIn} signUp={signUp} />
        </div>
      }
      <div className="devise-header-component__robo">
        <RoboImage />
      </div>
    </div>
  );
};

DeviseHeaderComponent.propTypes = {
  logIn: PropType.bool,
  signUp: PropType.bool,
  skipHeader: PropType.bool,
};

export default DeviseHeaderComponent;
