import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import PageDashboardComponent from './PageDashboardComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  destinationLinksMap,
  historyShape,
  optionsMap,
  policiesShape,
} from 'common/prop_types_shapes';

import {
  currentIdSelector,
  optionsSelector,
  pageDashboardDlsByBrandPageIdSelector,
} from 'selectors';

import { updatePageDashboardDlsSuccess } from 'actions/destination_link_actions';

import {
  fetchDlAnchorTexts,
  fetchPageDashboardDls,
  updatePageDashboardDl,
  updatePageDashboardDlAnchorText,
} from 'api/destination_links';

import { reDistributeDls } from 'api/brand_page';

import { trackHelpcrunchEvent } from 'common/utils';
import { getSelected } from 'pages/Pages/utils';

const PageDashboardContainer = ({
  activeAtpTableTab,
  anchorTextOptions,
  anchorTypeOptions,
  brandId,
  brandPageId,
  dispatch,
  dlSourcesOptions,
  history,
  includeByUserOptions,
  nofollowOptions,
  pageDashboardDls,
  policies,
  queryParams,
  statusOptions,
}) => {
  React.useEffect(() => {
    const data = {
      filters: {
        statusEqAny: ['started', 'not_started'], // Status 'All' by default
      },
      items: 5,
      page: 1,
    };

    fetchPageDashboardDls(dispatch, brandPageId, data);
  }, []);

  function handleUpdateRows(dls) {
    dispatch(updatePageDashboardDlsSuccess(dls, brandPageId));
  }

  async function handleUpdateDl(links = null) {
    updatePageDashboardDl(dispatch, brandPageId, links || pageDashboardDls.valueSeq().toJS());
  }

  function handleFetchAnchorTextOptions(destinationLinkId) {
    return fetchDlAnchorTexts(dispatch, brandPageId, destinationLinkId);
  }

  async function handleUpdateDlAnchorText(destinationLinkId) {
    updatePageDashboardDlAnchorText(dispatch, brandPageId, destinationLinkId);
  }

  async function handleShowAllClick() {
    if (activeAtpTableTab !== 'suggestions') {
      history.push(
        Routes.brand_page_tab_path(brandId, brandPageId, 'suggestions', {
          pagesFilter: queryParams.pagesFilter,
          format: null,
        }),
      );
    }

    trackHelpcrunchEvent('click.btn.showAllFromDashboardPage');

    await reDistributeDls(dispatch, brandPageId);
  }

  function handleChangeStatusSelect(object) {
    const status = object.value === 'all' ? ['started', 'not_started'] : [object.value];

    const data = {
      filters: {
        statusEqAny: status,
      },
      items: 5,
      page: 1,
    };

    fetchPageDashboardDls(dispatch, brandPageId, data);
  }

  const selectedRows = getSelected(pageDashboardDls);

  const atpTableProps = {
    anchorTextOptions,
    anchorTypeOptions,
    dlSourcesOptions,
    includeByUserOptions,
    nofollowOptions,
    policies,
    selectedRows,
    statusOptions,

    onUpdateRows: handleUpdateRows,
    onUpdateDl: handleUpdateDl,
    onFetchAnchorTextOptions: handleFetchAnchorTextOptions,
    onUpdateDlAnchorText: handleUpdateDlAnchorText,
  };

  return (
    <PageDashboardComponent
      atpTableProps={atpTableProps}
      destinationLinks={pageDashboardDls.valueSeq().toJS()}
      onChangeStatusSelect={handleChangeStatusSelect}
      onShowAllClick={handleShowAllClick}
      pageId={brandPageId}
    />
  );
};

PageDashboardContainer.propTypes = {
  activeAtpTableTab: PropTypes.string,
  anchorTextOptions: optionsMap,
  anchorTypeOptions: optionsMap,
  brandId: PropTypes.string,
  brandPageId: PropTypes.string,
  dispatch: PropTypes.func,
  dlSourcesOptions: optionsMap,
  history: historyShape,
  includeByUserOptions: optionsMap,
  nofollowOptions: optionsMap,
  pageDashboardDls: destinationLinksMap,
  policies: policiesShape,
  statusOptions: optionsMap,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);

  const pageDashboardDls = pageDashboardDlsByBrandPageIdSelector(state, ownProps);
  const policies = state.getIn(['railsContext', 'policies']);

  const options = optionsSelector(state, ownProps);
  const anchorTextOptions = options.get('anchorTextOptions');
  const anchorTypeOptions = options.get('anchorTypeOptions');
  const dlSourcesOptions = options.get('dlSourcesOptions');
  const includeByUserOptions = options.get('includeByUserOptions');
  const nofollowOptions = options.get('nofollowOptions');
  const statusOptions = options.get('linkStatusOptions');

  return {
    anchorTextOptions,
    anchorTypeOptions,
    brandId,
    dlSourcesOptions,
    includeByUserOptions,
    nofollowOptions,
    pageDashboardDls,
    policies,
    statusOptions,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(PageDashboardContainer);
