const FETCH_DL_SOURCES__START = 'FETCH_DL_SOURCES__START';
const FETCH_DL_SOURCES__SUCCESS = 'FETCH_DL_SOURCES__SUCCESS';
const FETCH_DL_SOURCES__FAILURE = 'FETCH_DL_SOURCES__FAILURE';
const CREATE_DL_SOURCE__START = 'CREATE_DL_SOURCE__START';
const CREATE_DL_SOURCE__SUCCESS = 'CREATE_DL_SOURCE__SUCCESS';
const CREATE_DL_SOURCE__FAILURE = 'CREATE_DL_SOURCE__FAILURE';


function fetchDlSourcesStart() {
  return { type: FETCH_DL_SOURCES__START };
}

function fetchDlSourcesSuccess(dlSources) {
  return { type: FETCH_DL_SOURCES__SUCCESS, dlSources };
}

function fetchDlSourcesFailure(errors) {
  return { type: FETCH_DL_SOURCES__FAILURE, errors };
}

function createDlSourceStart() {
  return { type: CREATE_DL_SOURCE__START };
}

function createDlSourceSuccess(dlSource) {
  return { type: CREATE_DL_SOURCE__SUCCESS, dlSource };
}

function createDlSourceFailure(errors) {
  return { type: CREATE_DL_SOURCE__FAILURE, errors };
}

export {
  FETCH_DL_SOURCES__START,
  FETCH_DL_SOURCES__SUCCESS,
  FETCH_DL_SOURCES__FAILURE,
  CREATE_DL_SOURCE__START,
  CREATE_DL_SOURCE__SUCCESS,
  CREATE_DL_SOURCE__FAILURE,
  fetchDlSourcesStart,
  fetchDlSourcesSuccess,
  fetchDlSourcesFailure,
  createDlSourceStart,
  createDlSourceSuccess,
  createDlSourceFailure
};
