import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';
import { withRouter } from 'react-router-dom';

import EoCampaignStepsListComponent from './EoCampaignStepsListComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { currentIdSelector } from 'selectors';
import { eoCampaignStepsList } from 'common/propTypesShapes/eoCampaignSteps';
import { eoCampaignStepsSelector } from 'selectors/eoCampaignStepsSelector';
import { policiesSelector } from 'selectors/railsContextSelectors';

const EoCampaignStepsListContainer = ({
  canCreate,
  eoCampaignSteps,
  onAddEoCampaignStep,
  onSelectEoCampaignStep,
  selectedEoCampaignStep,
}) => {
  return (
    <EoCampaignStepsListComponent
      canCreate={canCreate}
      immutableEoCampaignSteps={eoCampaignSteps}
      onSelectEoCampaignStep={onSelectEoCampaignStep}
      selectedEoCampaignStep={selectedEoCampaignStep}
      onAddEoCampaignStep={onAddEoCampaignStep}
    />
  );
};

EoCampaignStepsListContainer.propTypes = {
  canCreate: PropTypes.bool,
  eoCampaignSteps: eoCampaignStepsList,
  onAddEoCampaignStep: PropTypes.func,
  onSelectEoCampaignStep: PropTypes.func,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoCampaignSteps = eoCampaignStepsSelector(state);

  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);

  return {
    canCreate,
    eoCampaignId,
    eoCampaignSteps,
  };
}

export default compose(withRouter, ConnectStoreHOC, connect(select))(EoCampaignStepsListContainer);
