import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'common/i18n';

import TabsComponent, { TabItemComponent } from 'components_linkio/tabs_component';

const EO_CAMPAIGNS_TABS = [
  'eo_campaigns',
  'eo_drip_lists',
  'eo_active_hours_sets',
  'eo_email_templates',
];

const EoCampaignsTabsComponent = ({ onTabClick, activeTab }) => {
  return (
    <TabsComponent className="eo-module-tabs">
      {EO_CAMPAIGNS_TABS.map((tab) => (
        <TabItemComponent
          key={tab}
          index={tab}
          label={translate(`eoCampaign.tabs.${tab}`)}
          onClick={onTabClick(tab)}
          options={{ active: tab === activeTab }}
        />
      ))}
    </TabsComponent>
  );
};

EoCampaignsTabsComponent.propTypes = {
  activeTab: PropTypes.string,
  onTabClick: PropTypes.func,
};

export default EoCampaignsTabsComponent;
