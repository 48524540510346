import { updateEoInboxSuccess } from 'actions/eoInboxesSettingsActions';
import { showErrorMessage, showSuccessMessage } from 'common/utils';
import { translate } from 'common/i18n';

const handleCheckEoInboxRepliesStatus = (dispatch, data) => {
  const { checkEoInboxRepliesStatus, currentError } = data;

  if (!checkEoInboxRepliesStatus) {
    return;
  }

  dispatch(updateEoInboxSuccess(data));

  if (currentError) {
    showErrorMessage(dispatch, currentError, true);
  } else {
    showSuccessMessage(dispatch, translate('eoInboxesSettings.messages.finishEoInboxCheckReplies'));
  }
};

export default handleCheckEoInboxRepliesStatus;
