import React from 'react';

import ProspectFormPopup from '../ProspectFormPopup';

import { translate } from 'common/i18n';

import ButtonComponent from 'components_linkio/button_component';
import ToggleShowPopup from 'components_linkio/ToggleShowPopup';

const AddProspectButtonComponent = () => {
  return (
    <ToggleShowPopup Popup={<ProspectFormPopup />}>
      <ButtonComponent isInitial isGreen>
        {translate('prospects.bulkActions.addProspect')}
      </ButtonComponent>
    </ToggleShowPopup>
  );
};

export default AddProspectButtonComponent;
