import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SubscriptionPlanListItemComponent from './subscription_plan_list_item_component';

import { subscriptionPlansMap, subscriptionShape } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import CrossIcon from 'common/icons/cross';
import CheckedIcon from 'common/icons/checked.svg';

import './subscription_plans_list_component.scss';

const SubscriptionPlansListComponent = ({
  currentSubscription,
  onPlanSelect,
  selectedCostType,
  selectedPlanId,
  subscriptionPlans,
  withFeatureList,
  withPayButton,
  isClickable,
}) => {
  const subscriptionPlanAvailability = (subscriptionPlan) => {
    return currentSubscription.getIn([
      'subscriptionPlansAvailability',
      String(subscriptionPlan.get('id')),
    ]);
  };

  const featureList = [
    translate('subscriptionLimitReachedPopup.featureList.feature.ahrefsIntegration'),
    translate('subscriptionLimitReachedPopup.featureList.feature.automatedLinkBuildingPlanner'),
    translate('subscriptionLimitReachedPopup.featureList.feature.backlinksCrawler'),
    translate('subscriptionLimitReachedPopup.featureList.feature.categorizer'),
    translate('subscriptionLimitReachedPopup.featureList.feature.googleIndexCrawler'),
    translate('subscriptionLimitReachedPopup.featureList.feature.emailOutreach'),
  ];

  const tableColumnsSize = [...Array(subscriptionPlans.size + 1)];

  const featuresTable = tableColumnsSize.map((_, index) => {
    const isFirst = index === 0;
    const isSecond = index === 1;

    const featuresColumn = featureList.map((feature) => {
      return (
        <div
          key={feature}
          className="subscription-plan-list-component__feature-table-item
            subscription-plan-list-component__feature-table-item_align-left"
        >
          {feature}
        </div>
      );
    });

    const markColumn = [...Array(6)].map((_, index) => {
      return (
        <div key={`${index}-item`} className="subscription-plan-list-component__feature-table-item">
          {isSecond ? (
            <CrossIcon className="subscription-plan-list-component__cross-icon" />
          ) : (
            <img src={CheckedIcon} />
          )}
        </div>
      );
    });

    return (
      <div key={index} className="subscription-plan-list-component__feature-table-column">
        {isFirst ? featuresColumn : markColumn}
      </div>
    );
  });

  const componentClasses = classnames({
    'subscription-plan-list-component': true,
    'subscription-plan-list-component__with-feature-table': withFeatureList,
  });

  return (
    <div className={componentClasses}>
      {withFeatureList && <div />}
      {subscriptionPlans
        .map((subscriptionPlan) => (
          <SubscriptionPlanListItemComponent
            availability={subscriptionPlanAvailability(subscriptionPlan)}
            key={subscriptionPlan.get('id')}
            onPlanSelect={onPlanSelect}
            selectedCostType={selectedCostType}
            selectedPlanId={selectedPlanId}
            subscriptionPlan={subscriptionPlan}
            withPayButton={withPayButton}
            isClickable={isClickable}
          />
        ))
        .valueSeq()}
      {withFeatureList && featuresTable}
    </div>
  );
};

SubscriptionPlansListComponent.propTypes = {
  currentSubscription: subscriptionShape.isRequired,
  isClickable: PropTypes.bool,
  onPlanSelect: PropTypes.func.isRequired,
  selectedCostType: PropTypes.string,
  selectedPlanId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subscriptionPlans: subscriptionPlansMap,
  withFeatureList: PropTypes.bool,
  withPayButton: PropTypes.bool,
};

export default SubscriptionPlansListComponent;
