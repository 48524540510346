import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import EoCampaignStepActionsDropdownComponent from './EoCampaignStepActionsDropdownComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  deleteEoCampaignSteps,
  fetchEoCampaignSteps,
  moveEoCampaignStep,
} from 'api/eoCampaignStep';

import { currentIdSelector } from 'selectors';
import { eoCampaignStepsList } from 'common/propTypesShapes/eoCampaignSteps';
import { eoCampaignStepsSelector } from 'selectors/eoCampaignStepsSelector';
import { fetchEoCampaign } from 'api/eoCampaign';
import { policiesSelector } from 'selectors/railsContextSelectors';
import { translate } from 'common/i18n';

import confirmationDialogue from 'components/confirmation_dialogue';

function getEmailStepType(eoCampaignStep) {
  return eoCampaignStep.get('eoCampaignStepActionType') === 'EoCampaignStepEmail';
}

const EoCampaignStepActionsDropdownContainer = ({
  canCreate,
  dispatch,
  eoCampaignId,
  eoCampaignStepId,
  eoCampaignSteps,
}) => {
  function handleDeleteEoCampaignStep() {
    const isDeletingLastEmailStep =
      eoCampaignSteps.filter(getEmailStepType).size === 1 &&
      eoCampaignSteps.find(getEmailStepType).get('id') === eoCampaignStepId;

    confirmationDialogue({
      body: translate('confirmations.default.title'),
      onConfirm: async () => {
        await deleteEoCampaignSteps(dispatch, eoCampaignId, [eoCampaignStepId]);
        // Get updated step positions after step(s) deletion
        fetchEoCampaignSteps(dispatch, { eoCampaignId });
        // Get updated campaign status if the user deleted all Email steps
        isDeletingLastEmailStep && fetchEoCampaign(dispatch, eoCampaignId);
      },
    });
  }

  function handleMoveEoCampaignStep(delta) {
    moveEoCampaignStep(dispatch, eoCampaignId, { id: eoCampaignStepId, delta });
  }

  return (
    <>
      {canCreate && (
        <EoCampaignStepActionsDropdownComponent
          onDeleteEoCampaignStep={handleDeleteEoCampaignStep}
          onMoveEoCampaignStep={handleMoveEoCampaignStep}
          canCreate={canCreate}
        />
      )}
    </>
  );
};

EoCampaignStepActionsDropdownContainer.propTypes = {
  canCreate: PropTypes.bool,
  dispatch: PropTypes.func,
  eoCampaignId: PropTypes.string,
  eoCampaignStepId: PropTypes.number,
  eoCampaignSteps: eoCampaignStepsList,
};

function select(state, ownProps) {
  const eoCampaignId = currentIdSelector(state, ownProps);
  const eoCampaignSteps = eoCampaignStepsSelector(state);
  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);

  return {
    canCreate,
    eoCampaignId,
    eoCampaignSteps,
  };
}

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(EoCampaignStepActionsDropdownContainer);
