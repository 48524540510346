import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import MyBrandsDashboardMenuComponent from './MyBrandsDashboardMenuComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchRailsContext } from 'api/rails_context';
import { withRouterPropTypes } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';
import { deleteBrands } from 'api/brand';
import { enqueueBrandsDlsCrawling } from 'api/crawler';
import { fetchCurrentSubscription } from 'api/subscription';
import { trackHelpcrunchEvent } from 'common/utils';
import { subscriptionPoliciesSelector } from 'selectors/railsContextSelectors';


import confirmationDialogue from 'components/confirmation_dialogue';

const MyBrandsDashboardMenuContainer = ({ brand, canUseMetrics, dispatch, history: { push } }) => {
  const handleDeleteBrand = () => {
    confirmationDialogue({
      body: translate('confirmations.deleteBrand.body'),
      onConfirm: async () => {
        await deleteBrands(dispatch, [brand.id]);
        await fetchRailsContext(dispatch);
      },
    });
  };

  const handleCrawlBrand = async () => {
   
    const result = await enqueueBrandsDlsCrawling(dispatch, [brand.id]);

    trackHelpcrunchEvent('crawl.brand');

    await fetchCurrentSubscription(dispatch);

    return result;
  };
  
  const goTo = (route, params = { format: null }) => () => {
    push(route(brand.id, params));
  };

  return (
    <MyBrandsDashboardMenuComponent
      brand={brand}
      canUseMetrics={canUseMetrics}
      goTo={goTo}
      onCrawlBrand={handleCrawlBrand}
      onDeleteBrand={handleDeleteBrand}
    />
  );
};

MyBrandsDashboardMenuContainer.propTypes = {
  ...withRouterPropTypes,
  canUseMetrics: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

function select(state, ownProps) {
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);
  const canUseMetrics = subscriptionPolicies.getIn(['metrics', 'canUse']);

  return {
    canUseMetrics,
  };
}

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(MyBrandsDashboardMenuContainer);
