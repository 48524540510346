import React from 'react';
import { map } from 'lodash';

import { CommonField, HeaderField, NameField, SelectorField } from './components';

import DatetimePickerComponent from 'components/datetime_picker_component';
import Select from 'components_linkio/Select';
import TextAreaComponent from 'components/textarea_component';

import buildGroupedPercentageSchemeOptionsService from 'pages/Pages/PagesList/PageItem/PageDls/DlsPercentage/services/buildGroupedPercentageSchemeOptionsService';

const buildHeaderFormatters = (props) => [
  (value, extra) => {
    //eslint-disable-line react/display-name
    /* eslint-disable react/prop-types */
    const text = (props.headerValueFormatter && props.headerValueFormatter(value, extra)) || value;

    return <HeaderField explanationMessage={props.explanationMessage} text={text} />;
    /* eslint-enable react/prop-types */
  },
];

const reactSelectColumn = (props) => {
  const headerFormatters = buildHeaderFormatters(props);

  const cellFormatters = [
    (value, extra) => {
      //eslint-disable-line react/display-name
      const { rowData, property } = extra;
      const { errors } = rowData;
      /* eslint-disable react/prop-types */
      const text = (props.cellValueFormatter && props.cellValueFormatter(value, extra)) || value;
      const textIsLong = String(text).length > 18;
      const tooltipText = (props.showTooltip && textIsLong && String(value)) || '';
      const { isEditable, isRefreshable, isClearable } = props;
      /* eslint-enable react/prop-types */

      // It's hard to hide icons for reactSelectColumn cell in source table component.
      // So feel free to add conditions when we don't need icons for reactSelectColumn cell.
      const needToHideArrow = false;
      const needToHideCrossIcon = isClearable && !value;

      return (
        <SelectorField
          errorText={(errors || {})[property]}
          hideArrow={needToHideArrow}
          hideCrossIcon={needToHideCrossIcon}
          isEditable={isEditable}
          isRefreshable={isRefreshable}
          isClearable={isClearable}
          text={text}
          tooltipText={tooltipText}
        />
      );
    },
  ];

  return genericColumn({ cellFormatters, headerFormatters, ...props });
};

const textColumn = (props) => {
  const headerFormatters = buildHeaderFormatters(props);

  const cellFormatters = [
    (value, extra) => {
      //eslint-disable-line react/display-name
      const { rowData, property } = extra;
      const { errors } = rowData;
      /* eslint-disable react/prop-types */
      const text = (props.cellValueFormatter && props.cellValueFormatter(value, extra)) || value;
      const onClick = (props.onClick && props.onClick(value, extra));
      const textIsLong = String(text).length > 18;
      const tooltipText = (props.showTooltip && textIsLong && String(value)) || '';
      const { Icon, isEditable, isLink, Popup } = props;
      /* eslint-enable react/prop-types */
      return (
        <CommonField
          Popup={Popup}
          errorText={(errors || {})[property]}
          Icon={Icon}
          isEditable={isEditable}
          isLink={isLink}
          rowData={rowData}
          text={text}
          textIsLong={textIsLong}
          tooltipText={tooltipText}
          onClick={onClick}
        />
      );
    },
  ];

  return genericColumn({
    cellFormatters,
    headerFormatters,
    ...props,
  });
};

const nameColumn = ({ cellTransforms, className }) => {
  const cellFormatters = [
    (value, extra) => {
      //eslint-disable-line react/display-name
      const { rowData, property } = extra;
      const { avatar, errors } = rowData;

      return <NameField text={value} avatarImgSrc={avatar} errorText={(errors || {})[property]} />;
    },
  ];

  return genericColumn({
    name: 'name',
    headerLabel: 'Name',
    className,
    cellFormatters,
    cellTransforms,
  });
};

const genericColumn = ({
  cellFormatters,
  cellTransforms,
  className,
  headerFormatters,
  headerLabel,
  headerTransforms,
  isVisible,
  name,
}) => {
  const column = {
    cell: {},
    header: { label: headerLabel || '' },
    property: name || '',
    props: { className: className || '' },
    isVisible,
  };

  headerFormatters && (column.header.formatters = headerFormatters);
  headerTransforms && (column.header.transforms = headerTransforms);
  cellFormatters && (column.cell.formatters = cellFormatters);
  cellTransforms && (column.cell.transforms = cellTransforms);

  return column;
};

const checkboxColumn = (props) => {
  const { cellFormatters, className, headerFormatters, isVisible } = props;

  return genericColumn({
    name: 'selected',
    cellFormatters,
    className,
    headerFormatters,
    isVisible,
  });
};

const columnTextArea = (reactEdit) => {
  return (value, extra) => reactEdit(TextAreaComponent)(value, extra);
};

const columnSelect = (reactEdit, options, props) => {
  return (value, extra) =>
    reactEdit(reactSelectFunc(options, { ...props }))(value, extra, {
      className: (((extra || {}).rowData || {}).errors || {})[extra.property] && 'failed',
    });
};

const columnSelectWithTextArea = (reactEdit, options, props) => {
  return (value, extra) => {
    const {
      rowData: { activeCell },
    } = extra;

    const editingType = activeCell && activeCell.editingType;
    const loadOptions = activeCell && activeCell.loadOptions;

    const selectOptions = props.isAsync ? loadOptions : options;

    return editingType === 'text'
      ? reactEdit(TextAreaComponent)(value, extra)
      : reactEdit(reactSelectFunc(selectOptions, { ...props }))(value, extra, {
          className: (((extra || {}).rowData || {}).errors || {})[extra.property] && 'failed',
        });
  };
};

const columnDate = (reactEdit) => {
  return (value, extra) => reactEdit(DatetimePickerComponent)(value, extra);
};

const filterReactSelectOptions = (options, extraParameters) => {
  const {
    property,
    rowData: { id: brandPageId },
  } = extraParameters;

  const isPercentagesOptionsSelected = property === 'percentageSchemeId';

  let filteredOptions = options;

  if (isPercentagesOptionsSelected) {
    // Group options and leave only those competitor percentages schemes that belong to exact brand page
    filteredOptions = buildGroupedPercentageSchemeOptionsService({
      competitorsGroupInvisible: false,
      hideActions: true,
      pageId: brandPageId,
      percentageSchemeOptions: filteredOptions,
    });
  }

  return filteredOptions;
};

const reactSelectFunc = (options, selectProps) => (data) => {
  //eslint-disable-line react/display-name
  const { value, onValue, extraParameters } = data;
  const { isAsync } = selectProps;

  const filteredOptions = filterReactSelectOptions(options, extraParameters);

  const optionsProps = isAsync ? { loadOptions: filteredOptions } : { options: filteredOptions };

  return <Select {...selectProps} {...optionsProps} onChange={onValue} value={value} />;
};

export default function columnsGenerator(columns = []) {
  return map(columns, (column) => {
    switch (column.type) {
      case 'checkbox': {
        return checkboxColumn(column.options);
      }
      case 'reactSelect': {
        return reactSelectColumn(column.options);
      }
      case 'text': {
        return textColumn(column.options);
      }
      case 'name': {
        return nameColumn(column.options);
      }
      default: {
        return genericColumn(column.options);
      }
    }
  });
}

export { columnSelect, columnSelectWithTextArea, columnTextArea, columnDate };
