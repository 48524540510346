import React from 'react';
import PropTypes from 'prop-types';
import { Map as iMap } from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';

import ChangePercentagesPopupComponent from './ChangePercentagesPopupComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { showSuccessMessage, startMouseFlowPageView, trackHelpcrunchEvent } from 'common/utils';
import { translate } from 'common/i18n';

import {
  activeAtpTableTabString,
  brandPageShape,
  historyShape,
  optionsMap,
  matchShape,
} from 'common/prop_types_shapes';

import { updatePage, fetchPagePercentage, reDistributeDls } from 'api/brand_page';
import { closeChangePercentagesPopup, openPercentagesFormPopup } from 'actions/popup_actions';

import {
  activeAtpTableTabSelector,
  currentIdSelector,
  optionsSelector,
  visiblePageIdByParentSelector,
} from 'selectors';

class ChangePercentagesPopupContainer extends React.PureComponent {
  static propTypes = {
    activeAtpTableTab: activeAtpTableTabString,
    brandId: PropTypes.string,
    dispatch: PropTypes.func,
    history: historyShape,
    match: matchShape,
    page: brandPageShape,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']).isRequired,
    pageParentType: PropTypes.oneOf(['brand', 'brand_page']).isRequired,
    percentageSchemeOptions: optionsMap,
    visiblePageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ...withQueryParamsPropTypes,
  };

  state = {
    isUpdating: false,
  };

  componentDidMount() {
    startMouseFlowPageView(document.location.pathname);
  }

  handleCancel = () => {
    const {
      activeAtpTableTab,
      dispatch,
      history,
      match,
      page,
      pageParentType,
      queryParams,
    } = this.props;

    if (pageParentType === 'brand') {
      const { id } = match.params;

      history.push(
        Routes.brand_page_tab_path(id, page.get('id'), activeAtpTableTab, {
          ...queryParams,
          format: null,
        }),
      );
    }

    dispatch(closeChangePercentagesPopup());
  };

  handleAddCompetitors = () => {
    const { dispatch, history, brandId, visiblePageId, queryParams } = this.props;

    const path = Routes.brand_page_tab_path(brandId, visiblePageId, 'bulk_competitors_setup', {
      pagesFilter: queryParams.pagesFilter,
      format: null,
    });

    history.push(path);
    dispatch(closeChangePercentagesPopup());
  };

  handleAddCustomPercentages = () => {
    const { dispatch, pageChildType, visiblePageId } = this.props;

    dispatch(
      openPercentagesFormPopup({
        pageChildType,
        visiblePageId,
        percentageSchemeId: 'newCustomScheme',
      }),
    );
  };

  handleApplyPercentages = async (selectedScheme) => {
    const { dispatch, visiblePageId } = this.props;
    const { value } = selectedScheme;

    this.trackSelectedOptionTypeViaHelpcrunch(selectedScheme);
    this.setState({ isUpdating: true });

    await updatePage(dispatch, visiblePageId, { percentageSchemeId: value });
    await fetchPagePercentage(dispatch, visiblePageId);
    await reDistributeDls(dispatch, visiblePageId);
    showSuccessMessage(
      dispatch,
      translate('brandPage.messages.assignNewPercentageSchemeSuccessfully'),
    );
    this.setState({ isUpdating: false });
    this.handleCancel();
  };

  trackSelectedOptionTypeViaHelpcrunch = (selectedOption) => {
    if (selectedOption.isCompetitors) {
      trackHelpcrunchEvent('select.competitorPercentage');
    }

    if (selectedOption.isCustom) {
      trackHelpcrunchEvent('select.customPercentage');
    }

    trackHelpcrunchEvent('select.percentage');
  };

  render() {
    const { page, pageParentType, percentageSchemeOptions, visiblePageId } = this.props;
    const { isUpdating } = this.state;

    return (
      <ChangePercentagesPopupComponent
        isUpdating={isUpdating}
        onAddCompetitors={this.handleAddCompetitors}
        onAddCustomPercentages={this.handleAddCustomPercentages}
        onApplyPercentages={this.handleApplyPercentages}
        onCancel={this.handleCancel}
        pageParentType={pageParentType}
        percentageSchemeId={page.get('percentageSchemeId')}
        percentageSchemeOptions={percentageSchemeOptions}
        visiblePageId={visiblePageId}
      />
    );
  }
}

function select(state, ownProps) {
  const activeAtpTableTab = activeAtpTableTabSelector(state, ownProps);
  const brandId = currentIdSelector(state, ownProps);
  const visiblePageId = visiblePageIdByParentSelector(state, ownProps);
  const page = state.getIn(['brandPages', String(visiblePageId)], iMap());

  const options = optionsSelector(state, ownProps);
  const percentageSchemeOptions = options.get('percentageSchemeOptions');

  return {
    activeAtpTableTab,
    brandId,
    page,
    percentageSchemeOptions,
    visiblePageId,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(ChangePercentagesPopupContainer);
