import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EoEmailAttributesPopupComponent from './EoEmailAttributesPopupComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { eoCustomFieldsList } from 'common/propTypesShapes/eoCustomFields';
import { eoCustomFieldsSelector } from 'selectors/eoCustomFieldsSelector';
import { fetchEoCustomFields } from 'api/eoCustomField';

const EoEmailAttributesPopupContainer = ({
  dispatch,
  eoCustomFields,
  onInsertAttribute,
  onClose,
}) => {
  React.useEffect(() => {
    fetchEoCustomFields(dispatch, {});
  }, []);

  return (
    <EoEmailAttributesPopupComponent
      immutableEoCustomFields={eoCustomFields}
      onClose={onClose}
      onInsertAttribute={onInsertAttribute}
    />
  );
};

EoEmailAttributesPopupContainer.propTypes = {
  dispatch: PropTypes.func,
  eoCustomFields: eoCustomFieldsList,
  onClose: PropTypes.func,
  onInsertAttribute: PropTypes.func,
};

function select(state) {
  const eoCustomFields = eoCustomFieldsSelector(state);

  return {
    eoCustomFields,
  };
}

export default ConnectStoreHOC(connect(select)(EoEmailAttributesPopupContainer));
