import React from 'react';

import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';

import EoMessagesPageComponent from './EoMessagesPageComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { pagyShape } from 'common/prop_types_shapes';
import { eoMessagesPagySelector } from 'selectors/eoMessagesSelector';

const EoMessagesPageContainer = ({ changeQueryParams, eoMessagesPagy, queryParams }) => {
  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <EoMessagesPageComponent
        onItemsSelectChange={handleItemsSelectChange}
        onPagesPagerClick={handlePagesPagerClick}
        eoMessagesPagy={eoMessagesPagy}
      />
    </>
  );
};

EoMessagesPageContainer.propTypes = {
  eoMessagesPagy: pagyShape,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoMessagesPagy = eoMessagesPagySelector(state);

  return {
    eoMessagesPagy,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(EoMessagesPageContainer);
