import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'common/i18n';

import cx from 'classnames';

import useClickOutsideCb from 'hooks/useClickOutsideCb';

import { eoCustomFieldsList } from 'common/propTypesShapes/eoCustomFields';

import './eoEmailAttributesPopup.scss';

const prospectAttrs = [
  'first_name',
  'last_name',
  'email',
  'organization',
  'domain',
  'position',
  'role',
  'phone',
];

const inboxAttrs = [
  'email',
  'from_name',
  'nickname',
  // 'reply_to', Uncomment when the feature is ready
  'inbox_signature',
];

const precomputedAttrs = ['unsubscribe_link', 'textVariations'];

const textVariationsSample = '((Hey|Hi|Hello))';

const getFallbackStatement = (customField) => {
  const fallbackStatement = 'FALLBACK';

  if (!customField) {
    return fallbackStatement;
  }

  return customField.defaultValue === null ? fallbackStatement : customField.defaultValue;
};

const EoEmailAttributesPopupComponent = ({
  immutableEoCustomFields,
  onInsertAttribute,
  onClose,
}) => {
  const ref = React.useRef(null);
  useClickOutsideCb(ref, onClose);

  const customFields = immutableEoCustomFields.toJS();
  const prospectAttrsWithCustomFields = [
    ...prospectAttrs,
    ...immutableEoCustomFields.map((eoCustomField) => eoCustomField.get('name')),
  ];

  const handleAttributeClick =
    ({ textToInsert, isHtml, customField }) =>
    () => {
      const needsFallback = !isHtml && textToInsert.slice(0, 2) === '{{';
      let processedTextToInsert = textToInsert;

      if (needsFallback) {
        const fallbackStatement = getFallbackStatement(customField);
        processedTextToInsert = `${textToInsert.slice(0, -2)}:${fallbackStatement}}}`;
      }

      onInsertAttribute({ textToInsert: processedTextToInsert, isHtml });
      onClose();
    };

  function buildAttrsList(entityName, attrs) {
    const prospectAttrsListClassnames = cx('eo-email-attributes-popup__attributes-list', {
      'eo-email-attributes-popup__attributes-list_for-prospect': entityName === 'prospect',
    });

    return (
      <ul className={prospectAttrsListClassnames}>
        {attrs.map((attrName) => {
          let textToInsert = '';

          switch (attrName) {
            case 'unsubscribe_link':
              textToInsert = buildUnsubscribeText();
              break;
            case 'textVariations':
              textToInsert = textVariationsSample;
              break;
            default:
              textToInsert = `{{${entityName}.${attrName}}}`;
          }

          const labelFromTranslation = translate(
            `eoCampaignStep.eoCampaignStepEmail.attrs.${entityName}.${attrName}`,
          );

          return (
            <li
              key={attrName}
              className="eo-email-attributes-popup__attribute-item"
              onClick={handleAttributeClick({
                textToInsert,
                isHtml: attrName === 'unsubscribe_link',
                customField: customFields.find((customField) => customField.name === attrName),
              })}
            >
              {labelFromTranslation || attrName}
            </li>
          );
        })}
      </ul>
    );
  }

  function buildUnsubscribeText() {
    return (
      <>
        {translate('eoCampaignStep.eoCampaignStepEmail.attrs.precomputed.unsubscribeText.mainText')}{' '}
        <a href="{{precomputed.unsubscribe_link}}" target="_blank" rel="noreferrer">
          {translate(
            'eoCampaignStep.eoCampaignStepEmail.attrs.precomputed.unsubscribeText.linkText',
          )}
        </a>
      </>
    );
  }

  return (
    <div ref={ref} className="eo-email-attributes-popup">
      <div className="eo-email-attributes-popup__attributes-wrapper">
        <h3>{translate(`eoCampaignStep.eoCampaignStepEmail.attrs.prospect.attrTitle`)}</h3>
        {buildAttrsList('prospect', prospectAttrsWithCustomFields)}
      </div>

      <div className="eo-email-attributes-popup__attributes-wrapper">
        <h3>{translate(`eoCampaignStep.eoCampaignStepEmail.attrs.inbox.attrTitle`)}</h3>
        {buildAttrsList('inbox', inboxAttrs)}
      </div>

      <div className="eo-email-attributes-popup__attributes-wrapper">
        <h3>{translate(`eoCampaignStep.eoCampaignStepEmail.attrs.precomputed.attrTitle`)}</h3>
        {buildAttrsList('precomputed', precomputedAttrs)}
      </div>
    </div>
  );
};

EoEmailAttributesPopupComponent.propTypes = {
  immutableEoCustomFields: eoCustomFieldsList,
  onClose: PropTypes.func,
  onInsertAttribute: PropTypes.func,
};

export default EoEmailAttributesPopupComponent;
