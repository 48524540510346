import React from 'react';
import PropTypes from 'prop-types';
import BlankStateCtaComponent, { TitleText, BodyText } from '../../blank_state_cta_component';

import { activeAtpTableTabString } from 'common/prop_types_shapes';
import { translate } from 'common/i18n';

import DisappointedRobotImage from 'common/img/disappointed_robot.svg';

import './no_dls_component.scss';

const NoDlsComponent = ({ activeAtpTableTab, pageChildType }) => {
  const isBrandPage = pageChildType === 'brandPage';

  return (
    <div className="no-dls-component">
      <BlankStateCtaComponent customIcon={<img src={DisappointedRobotImage} />}>
        <TitleText>
          <strong>{translate(`${pageChildType}.noDlsComponent.tabs.${activeAtpTableTab}.title`)}</strong>
        </TitleText>
        {isBrandPage &&
          <BodyText>
            {translate(`brandPage.noDlsComponent.tabs.${activeAtpTableTab}.body`)}
          </BodyText>
        }
      </BlankStateCtaComponent>
    </div>
  );
};

NoDlsComponent.propTypes = {
  activeAtpTableTab: activeAtpTableTabString,
  pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']),
};

export default NoDlsComponent;
