import { updateBrandSuccess } from 'actions/brand_actions';
import { updatePageFailure, updatePageSuccess } from 'actions/brand_page_actions';
import { createDestinationLinksSuccess } from 'actions/destination_link_actions';

import { fetchPagePercentage } from 'api/brand_page';

import { translate } from 'common/i18n';
import { showSuccessMessage, showSuccessMessageFromTranslation } from 'common/utils';

import { handleAPIError } from 'api/utils';

const handleImportFromAhrefsStatus = (dispatch, data) => {
  const {
    brand = {},
    brandPage = {},
    categorized,
    destinationLinks,
    dupeEntriesCount,
    importFromAhrefsStatus,
    uniqEntriesCount,
  } = data;

  const pageChildType = brandPage.brand_page_id ? 'competitorsPage' : 'brandPage';

  switch (importFromAhrefsStatus) {
    case 'finished':
      dispatch(updateBrandSuccess(brand));
      dispatch(updatePageSuccess(brandPage));
      dispatch(createDestinationLinksSuccess(destinationLinks));
      showSuccessImportFromAhrefsMessage(
        dispatch,
        dupeEntriesCount,
        uniqEntriesCount,
        pageChildType,
      );
      if (categorized) {
        fetchPagePercentage(dispatch, brandPage.id);
        showSuccessCategorizeMessage(dispatch, destinationLinks.length);
      }
      break;
    case 'failure':
      handleImportFromAhrefsFailure(dispatch, brandPage);
      break;
  }
};

const showSuccessImportFromAhrefsMessage = (
  dispatch,
  dupeEntriesCount,
  uniqEntriesCount,
  pageChildType,
) => {
  const args = { dupeEntriesCount, uniqEntriesCount };
  showSuccessMessage(
    dispatch,
    translate(`${pageChildType}.messages.linksImportedFromAhrefsSuccessfully`)(args),
  );
};

const showSuccessCategorizeMessage = (dispatch, destinationLinksCount) => {
  showSuccessMessageFromTranslation(
    dispatch,
    'destinationLink.messages.linksCategorizedSuccessfully',
    {
      count: destinationLinksCount,
    },
  );
};

const handleImportFromAhrefsFailure = (dispatch, brandPage) => {
  const error = ((brandPage || {}).errors || {}).base;
  handleAPIError(
    dispatch,
    { message: error },
    updatePageFailure,
    translate('errors.cantUpdatePage'),
  );
};

export default handleImportFromAhrefsStatus;
