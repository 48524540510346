import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';
import { format } from 'date-fns';

import BacklinksTableComponent from './BacklinksTableComponent';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import {
  backlinksByBrandIdSelector,
  backlinksPagyByBrandIdSelector,
} from 'selectors/backlinkSelector';

import { backlinksList, brandShape, selectedIdsList } from 'common/prop_types_shapes';
import { crawlingInProgress } from 'pages/Pages/utils';
import { createDlSource, fetchDlSources } from 'api/dl_source';
import { currentBrandSelector, currentIdSelector, optionsSelector, uiSelector } from 'selectors';
import { fetchBacklinks, updateBacklink } from 'api/backlink';
import { fetchBrandWithMetrics } from 'api/brand';
import { fetchCurrentSubscription } from 'api/subscription';
import { METRICS_FILTERS } from 'utils/constants';
import { resetSelectedIds, toggleSelectedIds } from 'actions/ui_actions';
import { translate } from 'common/i18n';
import { updateBacklinksRows } from 'actions/backlinkActions';

import {
  canUseCrawlersSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import DisappointedRobotImage from 'common/img/disappointed_robot.svg';
import HandOnHipRobotImage from 'common/img/hand_on_hip_robot.svg';
import CheeringRobotImage from 'common/img/cheering_robot.svg';
import TableLoadingWrapper from 'components_linkio/TableLoadingWrapper';

import BlankStateCtaComponent, { TitleText, BodyText } from 'components/blank_state_cta_component';

const formatDate = (date) => date && format(date, 'MMM-dd');

const BacklinksTableContainer = ({
  backlinks,
  backlinksPagy,
  brand,
  brandId,
  canUseCrawlers,
  canUseMetrics,
  changeQueryParams,
  dateEnd,
  dateStart,
  dispatch,
  dlSourcesOptions,
  hasDateRange,
  hasFilters,
  importingInProgress,
  isFetchingBacklinks,
  match: {
    params: { backlinksPageType },
  },
  queryParams,
  selectedIds,
}) => {
  const {
    dlsGroupMode = '',
    filters,
    metricsFilter,
    pageNumber = 1,
    pagyItemsCount = 50,
    search = '',
    sorting = {},
  } = queryParams;

  React.useEffect(() => {
    fetchDlSources(dispatch);
  }, []);

  // This hook is only to remove metrics filter if the user have no access to use metrics
  React.useEffect(() => {
    if (canUseMetrics) {
      return;
    }

    if (metricsFilter) {
      const newQueryParams = {
        ...queryParams,
        metricsFilter: '',
      };

      changeQueryParams(newQueryParams);
    }
  }, [metricsFilter]);

  React.useEffect(() => {
    function fetchBacklinksWithParams() {
      const fetchingParams = {
        pageNumber,
        pagyItemsCount,
        filters: {
          ...(filters || {}),
          ...(METRICS_FILTERS[metricsFilter] || {}),
        },
        sorting,
        search,
        dlsGroupMode,
      };

      fetchBacklinks(dispatch, brandId, fetchingParams);
    }

    fetchBacklinksWithParams();
  }, [
    brandId,
    JSON.stringify(filters),
    search,
    metricsFilter,
    pageNumber,
    pagyItemsCount,
    dlsGroupMode,
    sorting.sortBy,
    sorting.sortingOrder,
  ]);

  React.useEffect(() => {
    return () => {
      // Drop selected ids on component unmount
      dispatch(resetSelectedIds());
    };
  }, [brandId]);

  React.useEffect(() => {
    fetchBrandWithMetrics(dispatch, brandId);
  }, [brandId]);

  const handleTableHeaderClick = (property) => () => {
    const { sortBy, sortingOrder } = sorting;

    const newQueryParams = { ...queryParams };

    const isSameSortByProperty = property === sortBy;

    if (isSameSortByProperty) {
      const newSortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';

      newQueryParams.sorting = {
        sortBy: property,
        sortingOrder: newSortingOrder,
      };

      changeQueryParams(newQueryParams);

      return;
    }

    newQueryParams.sorting = {
      sortBy: property,
      sortingOrder: 'asc',
    };

    changeQueryParams(newQueryParams);
  };

  const handleFetchCurrentSubscription = async () => await fetchCurrentSubscription(dispatch);

  function buildIconForBlankState() {
    if (hasFilters) {
      return DisappointedRobotImage;
    }

    if (hasDateRange) {
      return backlinksPageType === 'lost_links' ? CheeringRobotImage : HandOnHipRobotImage;
    }

    return DisappointedRobotImage;
  }

  function buildStateTranslationKey() {
    if (isCrawlingInProgress()) {
      return 'crawlingInProgress';
    }

    if (importingInProgress) {
      return 'importingInProgress';
    }

    if (hasFilters) {
      return 'noFilteredBacklinks';
    }

    switch (backlinksPageType) {
      case 'new_links':
        return 'noNewBacklinks';
      case 'lost_links':
        return 'noLostBacklinks';
      case 're_discovered_links':
        return 'noReDiscoveredBacklinks';
      default:
        return 'noBacklinks';
    }
  }

  function isCrawlingInProgress() {
    const crawlingStatus = brand.get('crawlingStatus');

    return crawlingInProgress(crawlingStatus);
  }

  function renderBlankState() {
    const isLoading = importingInProgress || isCrawlingInProgress();

    const translationKey = buildStateTranslationKey();

    return (
      <TableLoadingWrapper
        isLoading={isLoading}
        loadingMessage={translate(`backlinks.table.dataNotReadyYet.${translationKey}`)}
      >
        <div className="no-dls-component">
          <BlankStateCtaComponent customIcon={<img src={buildIconForBlankState()} />}>
            <TitleText>
              <strong>{translate(`backlinks.table.blankState.${translationKey}.title`)}</strong>
            </TitleText>
            <BodyText>
              {translate(`backlinks.table.blankState.${translationKey}.body`)(
                formatDate(dateStart),
                formatDate(dateEnd),
              )}
            </BodyText>
          </BlankStateCtaComponent>
        </div>
      </TableLoadingWrapper>
    );
  }

  function handleUpdateRows(backlinks) {
    dispatch(updateBacklinksRows(brandId, backlinks));
  }

  async function handleUpdateBacklink(backlink, property, value) {
    const needToCreateSource = property === 'source' && value && !dlSourcesOptions.get(value);

    if (needToCreateSource) {
      await createDlSource(dispatch, { title: value });
    }

    updateBacklink(dispatch, backlink, brandId);
  }

  function handleToggleSelectedRows(backlinksIds, isSelected) {
    dispatch(toggleSelectedIds(backlinksIds, isSelected));
  }

  const isLoading = importingInProgress || isCrawlingInProgress();
  const translationKey = buildStateTranslationKey();
  const { sortBy, sortingOrder } = sorting;
  const backlinksCount = backlinksPagy.get('count');

  return (
    <>
      {backlinks.size > 0 && (
        <TableLoadingWrapper
          isLoading={isLoading}
          loadingMessage={translate(`backlinks.table.dataNotReadyYet.${translationKey}`)}
        >
          <BacklinksTableComponent
            backlinks={backlinks.toJS()}
            backlinksCount={backlinksCount}
            canUseCrawlers={canUseCrawlers}
            dlSourcesOptions={dlSourcesOptions.toList().toJS()}
            onFetchCurrentSubscription={handleFetchCurrentSubscription}
            onTableHeaderClick={handleTableHeaderClick}
            onToggleSelectedRows={handleToggleSelectedRows}
            onUpdateBacklink={handleUpdateBacklink}
            onUpdateRows={handleUpdateRows}
            selectedBacklinksIds={selectedIds.toJS()}
            sortBy={sortBy}
            sortingOrder={sortingOrder}
          />
        </TableLoadingWrapper>
      )}

      {!isFetchingBacklinks && backlinks.size === 0 && renderBlankState()}
    </>
  );
};

BacklinksTableContainer.propTypes = {
  backlinks: backlinksList,
  brand: brandShape,
  brandId: PropTypes.string,
  canUseCrawlers: PropTypes.bool,
  dateEnd: PropTypes.instanceOf(Date),
  dateStart: PropTypes.instanceOf(Date),
  dispatch: PropTypes.func,
  hasDateRange: PropTypes.bool.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  importingInProgress: PropTypes.bool,
  selectedIds: selectedIdsList,
  ...withQueryParamsPropTypes,
};

function select(state, ownProps) {
  const brand = currentBrandSelector(state, ownProps);
  const brandId = currentIdSelector(state, ownProps);
  const backlinks = backlinksByBrandIdSelector(state, brandId);
  const backlinksPagy = backlinksPagyByBrandIdSelector(state, brandId);
  const canUseCrawlers = canUseCrawlersSelector(state, ownProps);
  const importingInProgress =
    state.getIn(['ui', 'loading', 'importBacklinksManuallyInProgress', brandId], false) ||
    state.getIn(['ui', 'loading', 'importBacklinksFromApiInProgress', brandId], false) ||
    brand.get('importingInProgress', false);

  const options = optionsSelector(state, ownProps);
  const dlSourcesOptions = options.get('dlSourcesOptions');

  const uiStates = uiSelector(state);
  const isFetchingBacklinks = uiStates.getIn(['loading', 'fetchBacklinks'], false);
  const selectedIds = uiStates.get('selectedIds');

  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);
  const canUseMetrics = subscriptionPolicies.getIn(['metrics', 'canUse']);

  return {
    backlinks,
    backlinksPagy,
    brand,
    brandId,
    canUseCrawlers,
    canUseMetrics,
    dlSourcesOptions,
    isFetchingBacklinks,
    importingInProgress,
    selectedIds,
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(BacklinksTableContainer);
