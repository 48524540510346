import React from 'react';
import PropTypes from 'prop-types';

import { eoInboxesList } from 'common/propTypesShapes/eoInboxesSettings';
import { translate } from 'common/i18n';

import CheckedIcon from 'common/icons/checked';
import Link from 'components_linkio/link';
import ExplanatoryTooltipComponent from 'components/explanatory_tooltip_component';
import OverlayWithTooltipComponent from 'components_linkio/overlay_with_tooltip_component';

import './eoInboxesList.scss';

const EoInboxesListComponent = ({ canUpdate, eoInboxes }) => {
  const eoInboxesArray = eoInboxes.toJS();

  function availabilityStatus(inbox) {
    const { currentError, verifiedAvailability } = inbox;

    return (
      <OverlayWithTooltipComponent
        tooltip={currentError && { text: currentError, placement: 'top' }}
      >
        <span className="eo-inboxes-list__availability-status">
          {translate('eoInboxesSettings.availability')(verifiedAvailability)}
          {verifiedAvailability && <CheckedIcon />}
        </span>
      </OverlayWithTooltipComponent>
    );
  }

  return (
    <>
      {eoInboxesArray.length === 0 && (
        <p className="eo-inboxes-list__blank-state">{translate('eoInboxesSettings.blankState')}</p>
      )}

      {eoInboxesArray.length > 0 && (
        <ul className="eo-inboxes-list__inboxes">
          {eoInboxesArray.map((inbox) => {
            const { id, email, emailsSentToday, emailsDailyQuota, fromName } = inbox;

            return (
              <li key={id}>
                <Link
                  to={canUpdate && Routes.edit_eo_inbox_path(id, { format: null })}
                  className="eo-inboxes-list__inboxes-item"
                >
                  <div>
                    <p className="text_two-lines-with-ellipsis-break-word">{fromName}</p>
                    <p className="text_two-lines-with-ellipsis-break-all">{email}</p>
                    {availabilityStatus(inbox)}
                  </div>
                  <div>
                    {translate('eoInboxesSettings.emailsSentToday')(
                      emailsSentToday,
                      emailsDailyQuota,
                    )}
                    <ExplanatoryTooltipComponent
                      text={translate('explanationMessages.emailsSentToday')}
                    />
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

EoInboxesListComponent.propTypes = {
  canUpdate: PropTypes.bool,
  eoInboxes: eoInboxesList,
};

export default EoInboxesListComponent;
