import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'lodash/fp';
import ConnectStoreHOC from '../../../startup/connect_store_hoc';

import {
  keywordsWithChangesByUrlList,
  keywordsWithChangesList,
} from '../../../common/prop_types_shapes';

import { closeUrlChangingHistoryPopup } from '../../../actions/popup_actions';

import {
  keywordsWithChangesByUrlSelector,
  keywordsWithChangesSelector,
} from '../../../selectors/rankTrackerSelector';

import UrlChangingHistoryPopupComponent from './UrlChangingHistoryPopupComponent';

const UrlChangingHistoryPopupContainer = ({
  dispatch,
  brandPageId,
  keywordId,
  keywordsWithChanges,
  keywordsWithChangesByUrl,
}) => {
  function handleCloseModal() {
    dispatch(closeUrlChangingHistoryPopup());
  }

  let changesHistory;

  if (brandPageId) {
    // If we have brandPageId it means keyword was clicked during the group by url view
    // So first find the needed brandPage, then find the needed keyword
    const page = keywordsWithChangesByUrl.find((page) => page.get('id') === brandPageId);
    const keyword = page.get('keywordsObjects').find((keyword) => keyword.get('id') === keywordId);
    changesHistory = keyword.get('changesHistory');
  } else {
    const keyword = keywordsWithChanges.find((keyword) => keyword.get('id') === keywordId);
    changesHistory = keyword.get('changesHistory');
  }

  return (
    <UrlChangingHistoryPopupComponent
      changesHistory={changesHistory}
      onCloseModal={handleCloseModal}
    />
  );
};

UrlChangingHistoryPopupContainer.propTypes = {
  brandPageId: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  keywordId: PropTypes.number,
  keywordsWithChanges: keywordsWithChangesList,
  keywordsWithChangesByUrl: keywordsWithChangesByUrlList,
};

function select(state, ownProps) {
  const keywordsWithChanges = keywordsWithChangesSelector(state, ownProps);
  const keywordsWithChangesByUrl = keywordsWithChangesByUrlSelector(state, ownProps);

  return {
    keywordsWithChanges,
    keywordsWithChangesByUrl,
  };
}

export default compose(
  withRouter,
  ConnectStoreHOC,
  connect(select),
)(UrlChangingHistoryPopupContainer);
