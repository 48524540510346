import { createSelector } from 'reselect';
import { List as iList, Map as iMap } from 'immutable';

const eoActiveHours = (state) => state.get('eoActiveHours', iList());
const eoActiveHoursPagy = (state) => state.getIn(['pagies', 'eoActiveHoursPagy'], iMap());

export const eoActiveHoursSelector = createSelector(
  [eoActiveHours],
  (eoActiveHours) => eoActiveHours,
);

export const eoActiveHoursPagySelector = createSelector([eoActiveHoursPagy], (pagy) => pagy);
