import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { uiStatesShape } from '../../common/prop_types_shapes';

const RouteAppLayoutComponent = ({ props, renderLayout }) => {
  return (
    <Route {...props} render={renderLayout} />
  );
};

RouteAppLayoutComponent.propTypes = {
  props: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    uiStates: uiStatesShape,
  }),
  renderLayout: PropTypes.func.isRequired,
};


export default RouteAppLayoutComponent;
