import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';
import { showSuccessMessage } from 'common/utils';

import { userShape } from 'common/prop_types_shapes';
import { fetchRailsContext } from 'api/rails_context';
import { updateProfile } from 'api/user';

import Breadcrumbs from 'components/NewBreadcrumbs';

import EditProfileFormComponent from 'components/auth/edit_profile_form_component';

class EditProfilePage extends React.PureComponent {
  static propTypes = {
    currentMarketerTitle: PropTypes.string,
    currentUser: userShape,
    dispatch: PropTypes.func,
    isOwner: PropTypes.bool,
    largeAvatarSrc: PropTypes.string,
  };

  state = {
    error: null,
    errors: {},
  };

  handleSubmitAction = async (name, title, timezoneName) => {
    const { dispatch } = this.props;
    const formData = new FormData();

    formData.append('user[name]', name);
    formData.append('user[title]', title);
    formData.append('user[timezone]', timezoneName);

    const resp = await updateProfile(dispatch, formData);
    const { type, message } = resp;

    if (type === 'error') {
      this.setState({ errors: message });
    } else {
      this.state.errors && this.setState({ errors: {} });
      await fetchRailsContext(dispatch);
      showSuccessMessage(dispatch, translate('authForms.editProfile.updated'));
    }
  };

  render() {
    const { errors, error } = this.state;

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.TextItem>{translate('authForms.breadcrumbsTitle')}</Breadcrumbs.TextItem>
        </Breadcrumbs>
        <EditProfileFormComponent
          {...this.props}
          onHandleSubmitClickAction={this.handleSubmitAction}
          errors={errors}
          error={error}
        />
      </>
    );
  }
}

function select(state) {
  const currentUser = state.getIn(['railsContext', 'cuser']);
  const currentMarketerTitle = state.getIn(['railsContext', 'cmarketer', 'title']);
  const isOwner = state.getIn(['railsContext', 'cmarketer', 'is_owner'], false);
  const avatar = state.getIn(['railsContext', 'cuser', 'avatar']);
  const largeAvatarSrc = avatar.replace('s=25', 's=120');

  return {
    currentMarketerTitle,
    currentUser,
    isOwner,
    largeAvatarSrc,
  };
}

export default ConnectStoreHOC(connect(select)(EditProfilePage));
