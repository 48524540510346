import { fromJS } from 'immutable';
import { camelizeObjectDeep, mergeItemToList } from './utils';

import {
  CREATE_EO_EMAIL_TEMPLATE__SUCCESS,
  DELETE_EO_EMAIL_TEMPLATES__SUCCESS,
  FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__SUCCESS,
  FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__SUCCESS,
  FETCH_EO_EMAIL_TEMPLATES_COUNT__SUCCESS,
  FETCH_EO_EMAIL_TEMPLATE__SUCCESS,
  UPDATE_EO_EMAIL_TEMPLATE__SUCCESS,
} from 'actions/eoEmailTemplateActions';

const initialState = fromJS({
  eoEmailTemplates: [],
  templateCount: {},
});

export default function eoEmailTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EO_EMAIL_TEMPLATES_BY_SCOPE__SUCCESS:
    case FETCH_EO_EMAIL_TEMPLATES_BY_FOLDER__SUCCESS: {
      const { eoEmailTemplates } = action;

      state = state.merge(
        state,
        fromJS({ eoEmailTemplates: eoEmailTemplates.map(camelizeObjectDeep) }),
      );

      break;
    }
    case FETCH_EO_EMAIL_TEMPLATES_COUNT__SUCCESS: {
      const { templateCount } = action;

      state = state.merge(state, fromJS({ templateCount: camelizeObjectDeep(templateCount) }));

      break;
    }
    case CREATE_EO_EMAIL_TEMPLATE__SUCCESS:
    case FETCH_EO_EMAIL_TEMPLATE__SUCCESS:
    case UPDATE_EO_EMAIL_TEMPLATE__SUCCESS: {
      const { eoEmailTemplate } = action;
      const eoEmailTemplates = state.get('eoEmailTemplates');
      const newEoEmailTemplates = mergeItemToList(
        eoEmailTemplates,
        eoEmailTemplate,
        camelizeObjectDeep,
      );

      state = state.merge(state, fromJS({ eoEmailTemplates: newEoEmailTemplates }));

      break;
    }
    case DELETE_EO_EMAIL_TEMPLATES__SUCCESS: {
      const { ids } = action;
      const eoEmailTemplates = state.get('eoEmailTemplates');
      const newEoEmailTemplates = eoEmailTemplates.filter(
        (eoEmailTemplate) => !ids.includes(eoEmailTemplate.get('id')),
      );

      state = state.merge(state, fromJS({ eoEmailTemplates: newEoEmailTemplates }));

      break;
    }
  }

  return state;
}
