import { round } from 'lodash';

export default function generateMetricsOverTime(
  metricsOverTime = {},
  { timePrefix = 'week', postfix = '', digits = 0, isVisible },
) {
  if (!isVisible) {
    return {};
  }

  const keyMinus = `${timePrefix}Minus`;
  const keyPlus = `${timePrefix}Plus`;

  const minusValue = round(metricsOverTime[keyMinus], digits);
  const plusValue = round(metricsOverTime[keyPlus], digits);

  return {
    minus: minusValue > 0 ? `-${minusValue}${postfix}` : '',
    plus: plusValue > 0 ? `+${plusValue}${postfix}` : '',
  };
}
