import { isEmpty, compact } from 'lodash';

const messageIsEmpty = (message) => {
  if (!message) {
    return true;
  }

  const { text } = message;
  const hasMessageText = Array.isArray(text) ? !isEmpty(compact(text)) : text;

  return isEmpty(message) || !hasMessageText;
};

const hasErrorMessage = (message) => (message && (message.type === 'error')) && !messageIsEmpty(message);
const hasSuccessMessage = (message) => (message && (message.type === 'success')) && !messageIsEmpty(message);

export {
  hasErrorMessage,
  hasSuccessMessage,
  messageIsEmpty,
};
