import React from 'react';

const DownloadIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <g>
        <path d="m25,17h-2c-0.6,0-1,0.4-1,1v2.5c0,0.3-0.2,0.5-0.5,0.5h-17c-0.3,0-0.5-0.2-0.5-0.5v-2.5c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v6c0,0.6 0.4,1 1,1h24c0.6,0 1-0.4 1-1v-6c0-0.6-0.4-1-1-1z" />
        <path d="M7.7,10.1c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l1-1C10.4,8.8,11,9,11,9.5v6.6c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1   V9.5c0-0.4,0.5-0.7,0.9-0.4l1,1c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l1.4-1.4C19.9,8.5,20,8.3,20,8s-0.1-0.5-0.3-0.7   l-6-6C13.5,1.1,13.2,1,13,1s-0.5,0.1-0.7,0.3l-6,6C6.1,7.5,6,7.7,6,8s0.1,0.5,0.3,0.7L7.7,10.1z" />
      </g>
    </svg>
  );
};

DownloadIcon.defaultProps = {
  fill: '#E3E6E9',
  width: '14px',
  height: '14px',
};

export default DownloadIcon;
