import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';

import EoToDosPageComponent from './EoToDosPageComponent';

import Breadcrumbs from 'components/NewBreadcrumbs';

import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';
import { fetchEoToDos } from 'api/eoToDo';
import { fetchUsersAsOptions } from 'api/user';

import { eoToDosSelector, eoToDosPagySelector } from 'selectors/eoToDosSelector';
import { optionsSelector } from 'selectors';

import { optionsList, pagyShape } from 'common/prop_types_shapes';
import { eoToDosList } from 'common/propTypesShapes/eoToDos';

const EoToDosPageContainer = ({
  changeQueryParams,
  dispatch,
  eoToDos,
  eoToDosPagy,
  immutableUsersOptions,
  queryParams,
}) => {
  const {
    pageNumber = 1,
    pagyItemsCount = 10,
    search = '',
    sorting = {},
    filters = {},
  } = queryParams;

  React.useEffect(() => {
    const fetchingParams = {
      filters,
      items: pagyItemsCount,
      page: pageNumber,
      search,
      sorting,
    };

    fetchEoToDos(dispatch, fetchingParams);
  }, [
    dispatch,
    pageNumber,
    pagyItemsCount,
    search,
    sorting.sortBy,
    sorting.sortingOrder,
    JSON.stringify(filters),
  ]);

  React.useEffect(() => {
    fetchUsersAsOptions(dispatch);
  }, [dispatch]);

  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>{translate('eoToDo.breadcrumbsTitle')}</Breadcrumbs.TextItem>
      </Breadcrumbs>
      <EoToDosPageComponent
        eoToDos={eoToDos}
        eoToDosPagy={eoToDosPagy}
        immutableUsersOptions={immutableUsersOptions}
        onItemsSelectChange={handleItemsSelectChange}
        onPagesPagerClick={handlePagesPagerClick}
      />
    </>
  );
};

EoToDosPageContainer.propTypes = {
  dispatch: PropTypes.func,
  eoToDos: eoToDosList,
  eoToDosPagy: pagyShape,
  immutableUsersOptions: optionsList,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoToDos = eoToDosSelector(state);

  const eoToDosPagy = eoToDosPagySelector(state);

  const options = optionsSelector(state);

  return {
    eoToDos,
    eoToDosPagy,
    immutableUsersOptions: options.get('usersOptions'),
  };
}

export default compose(withQueryParams, ConnectStoreHOC, connect(select))(EoToDosPageContainer);
