import { translate } from '../common/i18n';

import {
  fetchBrandKeywordsFailure,
  fetchBrandKeywordsStart,
  fetchBrandKeywordsSuccess,
  updateBrandKeywordsFailure,
  updateBrandKeywordsSuccess,
} from '../actions/rankTrackerKeywordsActions';

import {
  getFromAPI,
  handleAPIError,
  postToAPI,
} from './utils';


async function fetchBrandKeywords(dispatch, brandId) {
  dispatch(fetchBrandKeywordsStart());

  try {
    const resp = await getFromAPI(Routes.rank_tracker_keywords_path({ brand_id: brandId }));

    dispatch(fetchBrandKeywordsSuccess(resp.keywords));
  } catch (err) {
    handleAPIError(dispatch, err, fetchBrandKeywordsFailure, translate('errors.cantFetchBrandKeywords'));
  }
}

async function updateBrandKeywords(dispatch, brandId, keywords) {
  try {
    const resp = await postToAPI(
      Routes.save_rank_tracker_keywords_path({ brand_id: brandId }),
      { rank_tracker_brand: { keywords } }
    );

    dispatch(updateBrandKeywordsSuccess(resp.keywords));

    return resp;
  } catch (err) {
    return handleAPIError(dispatch, err, updateBrandKeywordsFailure, translate('errors.cantUpdateBrandKeywords'));
  }
}

export {
  fetchBrandKeywords,
  updateBrandKeywords,
};
