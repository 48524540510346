import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';

import { eoDomainShape } from 'common/propTypesShapes/eoDomains';
import { translate } from 'common/i18n';

import { Modal } from 'components_linkio/modal_component';
import ButtonComponent from 'components_linkio/button_component';
import InputComponent from 'components_linkio/input_component';
import SubmitButtonComponent from 'components/auth/submit_button_component';

import './eoDomainFormPopup.scss';

const initialState = {
  domain: '',
  errors: {},
};

function reducer(state, action) {
  const { value, type } = action;

  const newState = cloneDeep(state);

  newState[type] = value;

  return newState;
}

const formInputs = [
  {
    name: 'domain',
    type: 'text',
    isRequired: true,
  },
];

const EoDomainFormPopupComponent = ({ onCancel, onSave, eoDomain }) => {
  const [state, dispatch] = React.useReducer(reducer, eoDomain || initialState);

  const setInputValue = (inputName) => (event) => {
    dispatch({ type: inputName, value: event.target.value });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const resp = await onSave(state);

    const { errors } = resp;

    if (!isEmpty(errors)) {
      dispatch({ type: 'errors', value: errors });
    }
  }

  const { errors } = state;
  return (
    <Modal show className="eo-domains-form-popup">
      <Modal.Header>
        <Modal.Title>{translate('eoDomain.eoDomainFormPopup.title')(!!eoDomain)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form id="eo-domains-form" className="eo-domains-form-popup__form" noValidate>
          {formInputs.map((input) => {
            const { name, isRequired, type } = input;
            const value = state[name] || '';

            return (
              <InputComponent
                isMandatory={isRequired}
                key={name}
                label={translate(`eoDomain.eoDomainFormPopup.inputs.${name}.label`)}
                message={{ type: 'error', text: errors[name] }}
                name={name}
                onInputChange={setInputValue(name)}
                type={type}
                value={value}
              />
            );
          })}
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="eo-domains-form-popup__btn-group">
          <div className="eo-domains-form-popup__btn">
            <ButtonComponent isWhite onClick={onCancel}>
              {translate('eoDomain.eoDomainFormPopup.buttons.cancel')}
            </ButtonComponent>
          </div>

          <div className="eo-domains-form-popup__btn">
            <SubmitButtonComponent
              form="eo-domains-form"
              isGreen
              onClick={handleSubmit}
              text={translate('eoDomain.eoDomainFormPopup.buttons.submit')}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EoDomainFormPopupComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  eoDomain: eoDomainShape,
};

export default EoDomainFormPopupComponent;
