import { fromJS, Map as iMap } from 'immutable';
import { keyBy } from 'lodash';

import {
  DELETE_PAGE__SUCCESS,
  FETCH_PAGES__SUCCESS,
  FETCH_SINGLE_PAGE__SUCCESS,
  UPDATE_PAGE__SUCCESS,
  REDISTRIBUTE_DESTINATION_LINKS__SUCCESS,
  ENQUE_GENERATE_BRAND_PAGE_METRIC_SUCCESS
} from 'actions/brand_page_actions';

import { camelizeObject } from 'reducers/utils';

const initialState = iMap();

const processPage = (page, options = {}) => {
  const camelizedPage = camelizeObject(page);

  const processedPage = {
    ...camelizedPage,
    ...options,
    priority: camelizedPage.priority && String(camelizedPage.priority),
    selected: camelizedPage.selected || false,
  };

  return processedPage;
};

export default function brandPageReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_PAGE__SUCCESS: {
      state = state.delete(String(action.page.id));

      break;
    }
    case FETCH_SINGLE_PAGE__SUCCESS:
    case UPDATE_PAGE__SUCCESS: {
      state = state.merge(state, fromJS(keyBy([processPage(action.page)], 'id')));

      break;
    }
    case REDISTRIBUTE_DESTINATION_LINKS__SUCCESS: {
      const processedPage = processPage(action.page, { redistributingDlsStatus: 'completed' });
      state = state.merge(state, fromJS(keyBy([processedPage], 'id')));

      break;
    }
    // BrandPages and CompetitorPages are coexisting inside of the same piece of state.
    // In order to get filters and search to work,
    // we need an ability to completely and separately override CompetitorPages and/or BrandPages parts of the state
    // with the newly arrived entries.
    case FETCH_PAGES__SUCCESS: {
      const pageParentType = action.pageParentType;

      if (pageParentType === 'brand') {
        // drop all the BrandPages from the state
        state = state.filter((page) => !page.get('brandId'));
      }

      if (pageParentType === 'brand_page') {
        // drop all the CompetitorPages from the state
        state = state.filter((page) => page.get('brandId'));
      }

      // Merge newly arrived records into the prepared (cleaned up) state
      state = state.merge(state, fromJS(keyBy(action.pages.map(processPage), 'id')));

      break;
    }
    case ENQUE_GENERATE_BRAND_PAGE_METRIC_SUCCESS:
      break
  }

  return state;
}
