import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RunIntersectAnalysButtonComponent from './RunIntersectAnalysButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchIntersectReport } from 'api/competitorsAnalysis';
import { saveAs, trackHelpcrunchEvent } from 'common/utils';
import { translate } from 'common/i18n';

const RunIntersectAnalysButtonContainer = ({ dispatch, pageParentId }) => {
  async function handleClick() {
    trackHelpcrunchEvent('intersectReport');

    const { data } = await fetchIntersectReport(dispatch, pageParentId);
    saveAs(data, translate('intersectAnalysis.csvFileName'), 'text/csv;charset=utf-8');
  }

  return <RunIntersectAnalysButtonComponent onClick={handleClick} />;
};

RunIntersectAnalysButtonContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  pageParentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ConnectStoreHOC(connect()(RunIntersectAnalysButtonContainer));
