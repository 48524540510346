import pluralize from 'pluralize';

export default {
  backlinksSetup: {
    breadcrumbsTitle: 'Import Backlinks',
    title: 'Time to import your backlinks! Choose any of the below to start.',
    buttons: {
      cancel: 'Cancel',
      submit: 'Import Backlinks',
    },
    importViaApi: {
      title: 'Automatic Backlink Import',
      ahrefs: {
        label: 'Import backlinks via Ahrefs API',
        hint: 'We’ll import all the links from Ahref’s for you.',
        warningFree:
          'You have a FREE Ahrefs account so API importing will not work. \
          Upgrade to a paid Ahrefs account to enable this feature.',
        warningLimit:
          'Your Ahrefs monthly backlink import limit has been reached. \
          Try again after your monthly cycle renews or upgrade your plan',
      },
      moz: {
        hint:
          'As an experimental feature Linkio provides you with 2,000 backlinks monthly. \
        Check the credits in your account settings.',
        label: 'Import backlinks via Moz API',
      },
    },
    importManually: {
      title: 'Manual Backlink Import',
      fromCsv: {
        gsc: 'Google Search Console',
      },
      manually: {
        label: 'Manually add new published backlinks',
        textarea: {
          label: {
            title: 'Published Links',
            backlinksCount: (backlinksCount) => {
              if (!backlinksCount) {
                return void 0;
              }
              return `${pluralize('Backlink', backlinksCount, true)}`;
            },
          },
          hint: 'We automatically remove duplicates. Add one per line.',
        },
      },
    },
    messages: {
      importBacklinksManuallyFailure: 'Manual import was not completed.',
      importBacklinksManuallyStarted: 'Manual import is started.',
      importBacklinksManuallySuccess: 'Manual import was completed successfully',
      importBacklinksFromAhrefsFailure: 'Import from Ahrefs was not completed.',
      importBacklinksFromAhrefsStarted: 'Import from Ahrefs is started.',
      importBacklinksFromAhrefsSuccess: 'Import from Ahrefs was completed successfully',
      importBacklinksFromMozFailure: 'Import from Moz was not completed.',
      importBacklinksFromMozStarted: 'Import from Moz is started.',
      importBacklinksFromMozSuccess: 'Import from Moz was completed successfully',
    },
  },
};
