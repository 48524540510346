import {
  fetchPagePercentage,
} from '../../../../../../api/brand_page';

import { createDlSource } from '../../../../../../api/dl_source';


const recalculatePercentageService = async (context, pageId, property, value = null) => {
  const {
    dispatch,
    dlSourcesOptions,
  } = context;

  if (property === 'source') {
    handleDlSourcesChange({ dispatch, dlSourcesOptions }, value);
  }

  switch (property) {
    case 'includeByUser':
    case 'nofollow':
    case 'status':
    case 'anchorType': {
      await fetchPagePercentage(dispatch, pageId);
      break;
    }
  }
};

const handleDlSourcesChange = async (context, newDlSource) => {
  const {
    dispatch,
    dlSourcesOptions,
  } = context;

  if (!newDlSource) {
    return;
  }

  if (!dlSourcesOptions.get(newDlSource)) {
    await createDlSource(dispatch, { title: newDlSource });
  }
};


export default recalculatePercentageService;
