import React from 'react';

const ClockIcon = ({ stroke, ...props }) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="1 1 18 18" fill="none">
      <path
        d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z"
        strokeWidth="1.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5v5l3.333 1.667"
        strokeWidth="1.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ClockIcon.defaultProps = {
  width: '16px',
  height: '16px',
  stroke: '#777',
};

export default ClockIcon;
