export default {
  atpTableTabsComponent: {
    backlinks: {
      title: 'Backlinks',
    },
    suggestions: {
      title: 'Anchor Text Suggestions',
    },
    in_progress: {
      title: 'In Progress',
    },
    competitors: {
      title: 'Analyze Competitors',
    },
    bulk_competitors_setup: {
      title: 'Add New Competitors',
    },
  },
};
