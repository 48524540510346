import { trackHelpcrunchEvent } from 'common/utils';

const isStatusStartedOrPublished = (property, value) => {
  return property === 'status' && ['started', 'published'].includes(value);
};

const atpTableTrackHelpcrunchEventsService = ({ property, editingType, status, newValue  }) => {
  const isStartedOrPublishedSelected = isStatusStartedOrPublished(property, newValue);
  const isSuggestionEdited = property === 'anchorText' && editingType === 'text' && status !== 'published';
  const isSuggestionSelected = property === 'anchorText' && editingType === 'dropdown';

  isStartedOrPublishedSelected && trackHelpcrunchEvent(`change.linkStatus.${newValue}`);
  isSuggestionEdited && trackHelpcrunchEvent('suggestions.edited');
  isSuggestionSelected && trackHelpcrunchEvent('suggestions.selected');
};

export {
  atpTableTrackHelpcrunchEventsService,
  isStatusStartedOrPublished,
};
