import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash';

import DeleteEoDomainsButtonComponent from './DeleteEoDomainsButtonComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { deleteEoDomains } from 'api/eoDomain';
import { selectedIdsList } from 'common/prop_types_shapes';
import { uiSelector } from 'selectors';

const DeleteEoDomainsButtonContainer = ({ dispatch, selectedIds }) => {
  function handleDelete() {
    deleteEoDomains(dispatch, selectedIds.toJS());
  }

  return <DeleteEoDomainsButtonComponent onDelete={handleDelete} selectedIds={selectedIds} />;
};

DeleteEoDomainsButtonContainer.propTypes = {
  dispatch: PropTypes.func,
  selectedIds: selectedIdsList,
};

function select(state) {
  const uiStates = uiSelector(state);
  const selectedIds = uiStates.get('selectedIds');

  return {
    selectedIds,
  };
}

export default compose(ConnectStoreHOC, connect(select))(DeleteEoDomainsButtonContainer);
