export default {
  feedbackPopup: {
    titles: {
      feedback: 'Got Any Feature Request?',
      upcomingFeature: 'Upcoming Feature',
    },
    contactMe: 'May we contact you to follow up on your feedback?',
    buttons: {
      cancel: 'Cancel',
      send: 'Send',
    },
    labels: {
      feedback: `Do you think that some functionality is missing? Please let us know what you are \
        looking for!`,
    },
    placeholders: {
      feedback: 'Share your thoughts...',
    },
    messages: {
      thanksForFeedback: 'Thanks for sharing your feedback',
      noText: 'Please tell us something',
    },
    survey: {
      request: `We understand that setup in Linkio is challenging and we’re looking
        for the ways to improve it. Here we’ve listed the problems reported by our customers.
        Please help us by letting us know if you experienced the same.`,
      linkText: 'Take a short survey',
    },
  },
};
