import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fromJS, Map as iMap, List as iList } from 'immutable';
import { cloneDeep, findIndex, keyBy } from 'lodash';

import cx from 'classnames';

import * as Table from 'reactabular-table';
import * as edit from 'react-edit';
import * as selectabular from 'selectabular';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { fetchCurrentSubscription } from 'api/subscription';
import { fetchRailsContext } from 'api/rails_context';
import { optionValueOrValue } from 'components_linkio/Select/utils';
import { translate } from 'common/i18n';
import { updateDraftBrandPage } from 'api/bulkPagesSetup';
import { updateDraftCompetitorPage } from 'api/bulkCompetitorsSetup';
import { updateBrandKeywords } from 'api/rankTrackerKeywords';
import { updatePage } from 'api/brand_page';

import clickUpgradeSubscriptionService from 'pages/Pages/services/clickUpgradeSubscriptionService';

import {
  currentSubscriptionSelector,
  subscriptionPoliciesSelector,
} from 'selectors/railsContextSelectors';

import {
  keywordsArray,
  subscriptionPoliciesShape,
  subscriptionShape,
} from 'common/prop_types_shapes';

import columnsGenerator, { columnTextArea } from 'common/tables/columns_generator';
import { checkboxColumn, textColumn } from 'common/tables/columns';
import { currentIdSelector, pagesByParentSelector } from 'selectors';
import { bulkSetupBrandPagesSelector } from 'selectors/bulkPagesSetupSelector';
import { bulkCompetitorPagesByBrandPageIdSelector } from 'selectors/bulkCompetitorPagesSelector';

import SubscriptionLimitRibbonComponent, {
  UpgradeSubscriptionLink,
} from 'components/subscriptions/limit_ribbon_component';

import BinIcon from 'common/icons/bin';
import ButtonComponent from 'components_linkio/button_component';
import confirmationDialogue from 'components/confirmation_dialogue';
import Checkbox from 'components_linkio/Checkbox';
import MessageComponent from 'components_linkio/message_component';
import TextAreaComponent from 'components_linkio/textarea_component';

import './keywordsManager.scss';

function getActualKeywordsView(editedKeywords, keywords) {
  return keywords.map((keyword) => {
    const matchingEditedKeyword = editedKeywords.find(
      (editedKeyword) => editedKeyword.get('id') === keyword.get('id'),
    );

    if (matchingEditedKeyword) {
      return keyword.set('label', matchingEditedKeyword.get('label'));
    }

    return keyword;
  });
}

function getNewAndEditedKeywordsAfterDeletion(newKeywords, editedKeywords, deletedKeywords) {
  const editedKeywordsArray = editedKeywords.valueSeq().toJS();

  const newAndEditedKeywords = [];

  if (newKeywords) {
    const newKeywordsArray = newKeywords
      .split('\n')
      .filter((keyword) => keyword.trim() !== '')
      .map((keyword) => ({ label: keyword }));

    newAndEditedKeywords.push(...newKeywordsArray);
  }

  if (editedKeywordsArray.length > 0) {
    newAndEditedKeywords.push(...editedKeywordsArray);
  }

  const deletedKeywordsIds = deletedKeywords.map(({ id }) => id);
  const newAndEditedKeywordsWithoutDeleted = newAndEditedKeywords.filter(
    ({ id }) => !deletedKeywordsIds.includes(id),
  );

  return newAndEditedKeywordsWithoutDeleted;
}

function getEditedKeywordsAfterDeletion(editedKeywords, deletedKeywords) {
  const deletedKeywordsIds = deletedKeywords.map(({ id }) => id);

  const editedKeywordsWithoutDeleted = editedKeywords.filterNot((editedKeyword) =>
    deletedKeywordsIds.includes(editedKeyword.get('id')),
  );

  return editedKeywordsWithoutDeleted;
}

class KeywordsManagerComponent extends React.PureComponent {
  static propTypes = {
    brandId: PropTypes.string,
    currentSubscription: subscriptionShape,
    dispatch: PropTypes.func,
    keywords: keywordsArray,
    keywordsError: PropTypes.string,
    metaKeywords: PropTypes.arrayOf(PropTypes.string),
    newKeywords: PropTypes.arrayOf(PropTypes.shape()),
    onChangeKeywords: PropTypes.func.isRequired,
    pageChildType: PropTypes.oneOf(['brandPage', 'competitorsPage']),
    pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subscriptionPolicies: subscriptionPoliciesShape,
    targetAppModule: PropTypes.oneOf([
      'atp',
      'bulkPagesSetup',
      'bulkCompetitorsSetup',
      'rankTracker',
    ]),
    title: PropTypes.arrayOf(PropTypes.string),
  };

  state = {
    editedKeywords: iMap(),
    keywords: fromJS(keyBy(this.props.keywords, 'id')),
    newKeywords:
      (this.props.newKeywords &&
        this.props.newKeywords.map((newKeyword) => newKeyword.label).join('\n')) ||
      '',
    isUpdating: false,
  };

  // In order to update the table after keywords deletion
  static getDerivedStateFromProps(props, state) {
    const { keywords } = props;

    const shouldUpdateKeywords = keywords.length !== state.keywords.size;

    if (shouldUpdateKeywords) {
      return {
        keywords: fromJS(keyBy(keywords, 'id')),
      };
    }

    return null;
  }

  componentDidMount() {
    const { dispatch } = this.props;

    fetchRailsContext(dispatch);
    fetchCurrentSubscription(dispatch);
  }

  headerCheckboxFormatters = () => {
    return (
      <Checkbox
        checked={this.isAllSelected()}
        disabled={this.props.keywords.length === 0}
        onChange={this.handleOnToggleSelectAll}
      />
    );
  };

  cellCheckboxFormatters = (value, extra) => {
    const {
      rowData: { selected = false, id },
    } = extra;

    return <Checkbox checked={selected} onChange={this.handleOnToggleSelectRow(id)} />;
  };

  selectedKeywordsArray = () => {
    const keywordsArray = this.state.keywords.valueSeq().toJS();
    return keywordsArray.filter((keyword) => keyword.selected);
  };

  isAllSelected = () => {
    const keywordsArray = this.state.keywords.valueSeq().toJS();

    const selectedRowsCount = this.selectedKeywordsArray().length;

    return keywordsArray.length > 0 ? keywordsArray.length === selectedRowsCount : false;
  };

  handleOnToggleSelectAll = (event) => {
    const keywordsArray = this.state.keywords.valueSeq().toJS();

    const rows = event.target.checked
      ? selectabular.all(keywordsArray)
      : selectabular.none(keywordsArray);

    this.handleUpdateRows(rows);
  };

  handleOnToggleSelectRow = (rowId) => () => {
    const keywordsArray = this.state.keywords.valueSeq().toJS();

    const selectedFilter = (row) => row.id === rowId;
    const rows = selectabular.toggle(selectedFilter)(keywordsArray);

    this.handleUpdateRows(rows);
  };

  handleUpdateRows = (updatedKeywords) => {
    const { keywords } = this.state;

    const mergedKeywords = keywords.merge(fromJS(keyBy(updatedKeywords, 'id')));

    this.setState({ keywords: mergedKeywords });
  };

  handleChangeNewKeywords = (event) => {
    const { value } = event.target;

    this.setState({ newKeywords: value }, () => this.handleUpdateKeywords());
  };

  handleEditKeyword = (editedKeyword) => {
    const { editedKeywords } = this.state;

    const mergedKeywords = editedKeywords.merge(fromJS(keyBy([editedKeyword], 'id')));

    this.setState({ editedKeywords: mergedKeywords }, () => this.handleUpdateKeywords());
  };

  handleUpdateKeywords = () => {
    const { newKeywords, editedKeywords } = this.state;

    const editedKeywordsArray = editedKeywords.valueSeq().toJS();

    // We need to send only newly created or edited keywords
    const keywords = [];

    if (newKeywords) {
      const newKeywordsArray = newKeywords
        .split('\n')
        .filter((keyword) => keyword.trim() !== '')
        .map((keyword) => ({ label: keyword }));

      keywords.push(...newKeywordsArray);
    }

    if (editedKeywordsArray.length > 0) {
      keywords.push(...editedKeywordsArray);
    }

    this.props.onChangeKeywords(keywords);
  };

  processKeywordsForDeletion = () => {
    return this.selectedKeywordsArray().map((keyword) => ({
      ...keyword,
      delete: '1',
    }));
  };

  handleClickDelete = () => {
    const { targetAppModule } = this.props;

    // Show confirmation only for rank tracker module
    if (targetAppModule !== 'rankTracker') {
      this.handleDeletePageKeywords();
      return;
    }

    confirmationDialogue({
      body: translate('confirmations.deleteKeywords.body'),
      onConfirm: this.handleDeleteBrandKeywords,
    });
  };

  handleDeletePageKeywords = async () => {
    const { dispatch, pageId, targetAppModule, page } = this.props;

    const processedKeywords = this.processKeywordsForDeletion();

    const updatedPage = {
      id: pageId,
      keywords: processedKeywords,
      selected: page.get('selected'),
    };

    this.setState({ isUpdating: true });

    switch (targetAppModule) {
      case 'atp':
        await updatePage(dispatch, pageId, updatedPage);
        break;
      case 'bulkPagesSetup':
        await updateDraftBrandPage(dispatch, updatedPage);
        break;
      case 'bulkCompetitorsSetup':
        await updateDraftCompetitorPage(dispatch, updatedPage);
        break;
    }

    const { editedKeywords, newKeywords } = this.state;

    const newEditedAndNewKeywords = getNewAndEditedKeywordsAfterDeletion(
      newKeywords,
      editedKeywords,
      processedKeywords,
    );

    this.props.onChangeKeywords(newEditedAndNewKeywords, true);

    const newEditedKeywords = getEditedKeywordsAfterDeletion(editedKeywords, processedKeywords);

    this.setState({ editedKeywords: newEditedKeywords, isUpdating: false });

    fetchCurrentSubscription(dispatch); // check if the textarea should be re-enabled
  };

  handleDeleteBrandKeywords = async () => {
    const { dispatch, brandId } = this.props;

    const processedKeywords = this.processKeywordsForDeletion();

    this.setState({ isUpdating: true });

    await updateBrandKeywords(dispatch, brandId, processedKeywords);

    const { editedKeywords, newKeywords } = this.state;

    const newEditedAndNewKeywords = getNewAndEditedKeywordsAfterDeletion(
      newKeywords,
      editedKeywords,
      processedKeywords,
    );

    this.props.onChangeKeywords(newEditedAndNewKeywords);

    const newEditedKeywords = getEditedKeywordsAfterDeletion(editedKeywords, processedKeywords);

    this.setState({ editedKeywords: newEditedKeywords, isUpdating: false });

    fetchCurrentSubscription(dispatch); // check if the textarea should be re-enabled
  };

  keywordValueFormatter = (value) => {
    if (!value) {
      return (
        <span className="keywords-table__empty-keyword-warning">
          {translate('errors.mustBeFilled')}
        </span>
      );
    }

    return value;
  };

  generateColumns = (withAbility) => {
    return columnsGenerator([
      checkboxColumn({
        headerFormatters: [this.headerCheckboxFormatters],
        cellFormatters: [this.cellCheckboxFormatters],
        className: 'keywords-table__checkbox-column',
      }),
      textColumn({
        name: 'label',
        headerLabel: translate('keywordsManager.table.keywords'),
        cellValueFormatter: this.keywordValueFormatter,
        className: 'keywords-table__keywords-column',
        cellTransforms: [columnTextArea(withAbility)],
        showTooltip: true,
        isEditable: true,
      }),
    ]);
  };

  editable = edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,

    onActivate: ({ columnIndex, rowData }) => {
      const keywordsArray = this.state.keywords.valueSeq().toJS();

      const index = findIndex(keywordsArray, { id: rowData.id });
      const clonedRows = cloneDeep(keywordsArray);
      clonedRows[index].editing = columnIndex;

      this.handleUpdateRows(clonedRows);
    },

    onValue: async ({ value, rowData, property }) => {
      const keywordsArray = this.state.keywords.valueSeq().toJS();

      const index = findIndex(keywordsArray, { id: rowData.id });
      const currentValue = rowData[property] || '';
      const newValue = optionValueOrValue(value) || '';
      const clonedRow = cloneDeep(rowData);
      const clonedRows = cloneDeep(keywordsArray);

      Reflect.deleteProperty(clonedRow, 'editing');
      clonedRows[index] = clonedRow;

      if (currentValue !== newValue) {
        clonedRow[property] = newValue;

        this.handleUpdateRows([clonedRow]);
        this.handleEditKeyword(clonedRow);
      } else {
        this.handleUpdateRows(clonedRows);
      }
    },
  });

  handleClickUpgradeSubscription = () => {
    const { currentSubscription, dispatch } = this.props;

    clickUpgradeSubscriptionService({ currentSubscription, dispatch }, event);
  };

  render() {
    const {
      keywordsError,
      metaKeywords = [],
      pageChildType,
      subscriptionPolicies,
      targetAppModule,
      title = [],
    } = this.props;

    const { editedKeywords, isUpdating, keywords, newKeywords } = this.state;

    const showKeywordsTable = !!keywords.size;

    const keywordsInputClassnames = cx('keywords-manager__input', {
      'keywords-manager__input_big': showKeywordsTable,
      'keywords-manager__input_without-keywords': !showKeywordsTable,
    });

    const canAddNewKeywordsBySubscription = subscriptionPolicies.getIn(
      ['trackedKeyword', 'canAdd'],
      false,
    );

    const columns = this.generateColumns(this.editable);

    const actualKeywords = getActualKeywordsView(editedKeywords, keywords);

    const rows = actualKeywords.valueSeq().toJS();

    const isBrandLevel = targetAppModule === 'rankTracker';
    const isCompetitorPage = pageChildType === 'competitorsPage';
    const canAddNewKeywords = !isBrandLevel || canAddNewKeywordsBySubscription || isCompetitorPage;

    return (
      <div className="keywords-manager">
        {!canAddNewKeywords && (
          <SubscriptionLimitRibbonComponent>
            {translate('subscriptionLimitRibbon.maxTrackedKeywordsCountLimitReached')}
            <UpgradeSubscriptionLink onClick={this.handleClickUpgradeSubscription} />
          </SubscriptionLimitRibbonComponent>
        )}
        <div className={keywordsInputClassnames}>
          <TextAreaComponent
            explanationMessage={translate('explanationMessages.keywordsManager.keywords')(
              isBrandLevel,
            )}
            disabled={!canAddNewKeywords}
            label={translate('keywordsManager.labels.addKeywords')(isBrandLevel)}
            message={{ type: 'hint', text: translate('keywordsManager.hints.keywords') }}
            onChange={this.handleChangeNewKeywords}
            value={newKeywords}
          />
          {title.length > 0 && (
            <MessageComponent
              message={{
                type: 'hint',
                text: translate('keywordsManager.hints.title')(title.join(', ')),
              }}
            />
          )}
          {metaKeywords.length > 0 && (
            <MessageComponent
              message={{
                type: 'hint',
                text: translate('keywordsManager.hints.metaKeywords')(metaKeywords.join(', ')),
              }}
            />
          )}
        </div>
        {showKeywordsTable && (
          <div className="keywords-manager__headline">
            <span>{translate('keywordsManager.title')(isBrandLevel)}</span>

            <ButtonComponent
              className="keywords-manager__delete-btn"
              isDisabled={this.selectedKeywordsArray().length === 0}
              isLoading={isUpdating}
              isRed
              onClick={this.handleClickDelete}
            >
              <BinIcon />
            </ButtonComponent>
          </div>
        )}

        {showKeywordsTable && (
          <Table.Provider className="keywords-table" columns={columns}>
            <Table.Header />
            <Table.Body rowKey="id" rows={rows} />
          </Table.Provider>
        )}

        {keywordsError && (
          <MessageComponent message={{ type: 'error' }}>{keywordsError}</MessageComponent>
        )}
      </div>
    );
  }
}

function select(state, ownProps) {
  const { pageId, pageParentId, targetAppModule } = ownProps;

  const brandId = currentIdSelector(state, ownProps);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);
  const subscriptionPolicies = subscriptionPoliciesSelector(state, ownProps);

  let pages = iList();

  switch (targetAppModule) {
    case 'atp':
      pages = pagesByParentSelector(state, ownProps);
      break;
    case 'bulkPagesSetup':
      pages = bulkSetupBrandPagesSelector(state, ownProps);
      break;
    case 'bulkCompetitorsSetup':
      pages = bulkCompetitorPagesByBrandPageIdSelector(state, pageParentId);
      break;
  }

  const page = pages.find((page) => page.get('id') === Number(pageId));

  return {
    brandId,
    currentSubscription,
    page,
    subscriptionPolicies,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(KeywordsManagerComponent)));
