import React from 'react';

const CloudIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="M4.6666 9.7403l.9334.8122 1.7333-1.5083v5.337h1.3334v-5.337L10.4 10.5525l.9334-.8122L8 6.8398z" />
      <path d="M13.3334 6.3177c0-2.6105-2.4-4.699-5.3334-4.699s-5.3334 2.0884-5.3334 4.641v.058c-.6667.116-1.2.406-1.6667.8121-.6667.5221-1 1.2763-1 2.0304 0 1.6244 1.4667 2.9006 3.3334 2.9006v-1.1602c-1.1334 0-2-.7542-2-1.7404 0-.464.2-.8701.6-1.2182.4-.348.8666-.5221 1.4667-.5221h.8l-.1334-.6961C4 6.6078 4 6.4337 4 6.2597 4 4.3453 5.8 2.779 8 2.779s4 1.5663 4 3.4807c0 .174 0 .348-.0666.464l-.0667.6962h.8c1.1333 0 2 .7541 2 1.7403s-.8667 1.7404-2 1.7404v1.1602c1.8667 0 3.3334-1.2762 3.3334-2.9006 0-1.3922-1.1334-2.5525-2.6667-2.8425z" />
    </svg>
  );
};

CloudIcon.defaultProps = {
  fill: '#E3E6E9',
  width: '16px',
  height: '16px',
};

export default CloudIcon;
