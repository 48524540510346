import React from 'react';
import cx from 'classnames';

import EoCampaignStepsPage from '../EoCampaignStepsPage';
import EoCampaignOptions from './EoCampaignOptions';
import EoCampaignProspects from './EoCampaignProspects';
import EoCampaignStatusButton from './EoCampaignStatusButton';
import EoCampaignTabs from './EoCampaignTabs';
import buildCampaignStatusBadge from './utils/buildCampaignStatusBadge';

import EoCampaignOverviewPage from 'pages/EoCampaignOverviewPage';
import EoCampaignPreviewPage from 'pages/EoCampaignPreviewPage';
import EoInboxesPage from 'pages/EoInboxesPage';
import EoProgressionsPage from 'pages/EoProgressionsPage';
import EoMessagesPage from 'pages/EoMessagesPage';
import EoSchedulersPage from 'pages/EoSchedulersPage';

import { activeEoCampaignTabString, eoCampaignShape } from 'common/propTypesShapes/eoCampaigns';

import './eoCampaignPage.scss';

const EoCampaignPageComponent = ({ activeEoCampaignTab, eoCampaign }) => {
  const { status = '', title } = eoCampaign.toJS();

  const isEoCampaignOverviewTabActive = activeEoCampaignTab === 'overview';
  const isEoCampaignProspectsTabActive = activeEoCampaignTab === 'prospects';
  const isEoProgressionsTabActive = activeEoCampaignTab === 'eo_progress';
  const isEoCampaignStepsTabActive = activeEoCampaignTab === 'eo_sequences';
  const isEoInboxesActive = activeEoCampaignTab === 'eo_inboxes';
  const isEoSchedulerTabActive = activeEoCampaignTab === 'eo_scheduler';
  const isEoMessagesTabActive = activeEoCampaignTab === 'eo_messages';
  const isOptionsTabActive = activeEoCampaignTab === 'options';
  const isEoCampaignPreviewTabActive = activeEoCampaignTab === 'eo_campaign_preview';

  const eoCampaignPageStyles = cx('eo-campaign-page', 'page__wrapper', {
    'page__wrapper_no-bottom-padding': activeEoCampaignTab === 'eo_sequences',
  });

  return (
    <div className={eoCampaignPageStyles}>
      <div className="eo-campaign-page__title">
        <div>
          {buildCampaignStatusBadge(status)}
          <h1>{title}</h1>
        </div>

        <EoCampaignStatusButton />
      </div>

      <EoCampaignTabs />

      {isEoCampaignOverviewTabActive && <EoCampaignOverviewPage />}

      {isEoCampaignProspectsTabActive && <EoCampaignProspects />}

      {isEoProgressionsTabActive && <EoProgressionsPage />}

      {isEoCampaignStepsTabActive && <EoCampaignStepsPage />}

      {isEoInboxesActive && <EoInboxesPage />}

      {isOptionsTabActive && <EoCampaignOptions />}

      {isEoSchedulerTabActive && <EoSchedulersPage />}

      {isEoMessagesTabActive && <EoMessagesPage />}

      {isEoCampaignPreviewTabActive && <EoCampaignPreviewPage />}
    </div>
  );
};

EoCampaignPageComponent.propTypes = {
  activeEoCampaignTab: activeEoCampaignTabString,
  eoCampaign: eoCampaignShape,
};

export default EoCampaignPageComponent;
