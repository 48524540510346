import React from 'react';

const MinusInRoundIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 510">
      <path
        d="M255 0C114.75 0 0 114.75 0 255s114.75 255 255 255 255-114.75 255-255S395.25 0 255 0zm0
        459c-112.2 0-204-91.8-204-204S142.8 51 255 51s204 91.8 204 204-91.8 204-204 204zM153 280.5h204v-51H153v51z"
      />
    </svg>
  );
};

export default MinusInRoundIcon;
