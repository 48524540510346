import React from 'react';
import PropTypes from 'prop-types';

const RoundSpinnerIcon = ({ isBlack, isWhite, ...svgProps }) => {
  let fillColor = 'currentColor';
  isBlack && (fillColor = '#000000');
  isWhite && (fillColor = '#ffffff');

  return (
    <svg
      className="round-spinner"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{
        background: '0 0',
      }}
      {...svgProps}
    >
      <rect x={45} y={5} rx={36} ry={4} width={10} height={32} fill={fillColor}>
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.875s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={45}
        y={5}
        rx={36}
        ry={4}
        width={10}
        height={32}
        fill={fillColor}
        transform="rotate(45 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.75s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={45}
        y={5}
        rx={36}
        ry={4}
        width={10}
        height={32}
        fill={fillColor}
        transform="rotate(90 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.625s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={45}
        y={5}
        rx={36}
        ry={4}
        width={10}
        height={32}
        fill={fillColor}
        transform="rotate(135 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={45}
        y={5}
        rx={36}
        ry={4}
        width={10}
        height={32}
        fill={fillColor}
        transform="rotate(180 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.375s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={45}
        y={5}
        rx={36}
        ry={4}
        width={10}
        height={32}
        fill={fillColor}
        transform="rotate(225 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.25s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={45}
        y={5}
        rx={36}
        ry={4}
        width={10}
        height={32}
        fill={fillColor}
        transform="rotate(270 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.125s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={45}
        y={5}
        rx={36}
        ry={4}
        width={10}
        height={32}
        fill={fillColor}
        transform="rotate(315 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  );
};

RoundSpinnerIcon.defaultProps = {
  height: '16px',
  width: '16px',
};

RoundSpinnerIcon.propTypes = {
  isBlack: PropTypes.bool,
  isWhite: PropTypes.bool,
};

export default RoundSpinnerIcon;
