export const CREATE_EO_SCHEDULER__FAILURE = 'CREATE_EO_SCHEDULER__FAILURE';
export const CREATE_EO_SCHEDULER__START = 'CREATE_EO_SCHEDULER__START';
export const CREATE_EO_SCHEDULER__SUCCESS = 'CREATE_EO_SCHEDULER__SUCCESS';
export const DELETE_EO_SCHEDULERS__FAILURE = 'DELETE_EO_SCHEDULERS__FAILURE';
export const DELETE_EO_SCHEDULERS__START = 'DELETE_EO_SCHEDULERS__START';
export const DELETE_EO_SCHEDULERS__SUCCESS = 'DELETE_EO_SCHEDULERS__SUCCESS';
export const FETCH_EO_SCHEDULERS__FAILURE = 'FETCH_EO_SCHEDULERS__FAILURE';
export const FETCH_EO_SCHEDULERS__START = 'FETCH_EO_SCHEDULERS__START';
export const FETCH_EO_SCHEDULERS__SUCCESS = 'FETCH_EO_SCHEDULERS__SUCCESS';
export const FETCH_EO_SCHEDULER__FAILURE = 'FETCH_EO_SCHEDULER__FAILURE';
export const FETCH_EO_SCHEDULER__START = 'FETCH_EO_SCHEDULER__START';
export const FETCH_EO_SCHEDULER__SUCCESS = 'FETCH_EO_SCHEDULER__SUCCESS';
export const FETCH_TIMEZONES_AS_OPTIONS__FAILURE = 'FETCH_TIMEZONES_AS_OPTIONS__FAILURE';
export const FETCH_TIMEZONES_AS_OPTIONS__SUCCESS = 'FETCH_TIMEZONES_AS_OPTIONS__SUCCESS';
export const UPDATE_EO_SCHEDULER__FAILURE = 'UPDATE_EO_SCHEDULER__FAILURE';
export const UPDATE_EO_SCHEDULER__START = 'UPDATE_EO_SCHEDULER__START';
export const UPDATE_EO_SCHEDULER__SUCCESS = 'UPDATE_EO_SCHEDULER__SUCCESS';

export function createEoSchedulerStart() {
  return { type: CREATE_EO_SCHEDULER__START };
}

export function createEoSchedulerSuccess(eoScheduler) {
  return { type: CREATE_EO_SCHEDULER__SUCCESS, eoScheduler };
}

export function createEoSchedulerFailure(errors) {
  return { type: CREATE_EO_SCHEDULER__FAILURE, errors };
}

export function deleteEoSchedulersStart() {
  return { type: DELETE_EO_SCHEDULERS__START };
}

export function deleteEoSchedulersSuccess(ids) {
  return { type: DELETE_EO_SCHEDULERS__SUCCESS, ids };
}

export function deleteEoSchedulersFailure(errors) {
  return { type: DELETE_EO_SCHEDULERS__FAILURE, errors };
}

export function fetchEoSchedulersStart() {
  return { type: FETCH_EO_SCHEDULERS__START };
}

export function fetchEoSchedulersSuccess(eoSchedulers) {
  return { type: FETCH_EO_SCHEDULERS__SUCCESS, eoSchedulers };
}

export function fetchEoSchedulersFailure(errors) {
  return { type: FETCH_EO_SCHEDULERS__FAILURE, errors };
}

export function fetchEoSchedulerStart() {
  return { type: FETCH_EO_SCHEDULER__START };
}

export function fetchEoSchedulerSuccess(eoScheduler) {
  return { type: FETCH_EO_SCHEDULER__SUCCESS, eoScheduler };
}

export function fetchEoSchedulerFailure(errors) {
  return { type: FETCH_EO_SCHEDULER__FAILURE, errors };
}

export function fetchTimezonesAsOptionsSuccess(timezones) {
  return { type: FETCH_TIMEZONES_AS_OPTIONS__SUCCESS, timezones };
}

export function fetchTimezonesAsOptionsFailure(errors) {
  return { type: FETCH_TIMEZONES_AS_OPTIONS__FAILURE, errors };
}

export function updateEoSchedulerStart() {
  return { type: UPDATE_EO_SCHEDULER__START };
}

export function updateEoSchedulerSuccess(eoScheduler) {
  return { type: UPDATE_EO_SCHEDULER__SUCCESS, eoScheduler };
}

export function updateEoSchedulerFailure(errors) {
  return { type: UPDATE_EO_SCHEDULER__FAILURE, errors };
}
