import React from 'react';

import { translate } from 'common/i18n';

import EoDashboardCharts from 'pages/EoDashboardPage/EoDashboardCharts';
import Link from 'components_linkio/link';

const sidebar = [
  {
    name: 'eoDashboard',
    link: Routes.eo_dashboard_index_path({ format: null }),
  },
  {
    name: 'prospects',
    link: Routes.prospects_path({ format: null }),
  },
  {
    name: 'eoCampaigns',
    link: Routes.eo_campaigns_path({ format: null }),
  },
  {
    name: 'eoInboxesSettings',
    link: Routes.eo_inboxes_settings_path({ format: null }),
  },
];

const EoDashboardComponent = () => {
  return (
    <div className="account-dashboard__eo-dashboard">
      <ul>
        {sidebar.map(({ name, link }) => (
          <li key={name}>
            <Link to={link}>{translate(`sidebar.emailOutreach.${name}.title`)}</Link>
          </li>
        ))}
      </ul>

      <EoDashboardCharts />
    </div>
  );
};

export default EoDashboardComponent;
