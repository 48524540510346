import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { somethingIsLoadingSelector, somethingIsChangingSelector, uiSelector } from 'selectors';
import { messagesMap } from 'common/prop_types_shapes';
import { setupUsersChannelReceivedCallback } from 'api/users_channel';

import CategorizeDlsPopup from 'pages/Pages/PagesList/PageItem/PageDls/BulkDlsActions/CategorizeDlsButton/CategorizeDlsPopup';
import ChangePercentagesPopup from 'pages/Pages/PagesList/PageItem/PageDls/DlsPercentage/ChangePercentagesPopup';
import ConfirmPasswordPopup from 'components/auth/ConfirmPasswordPopup';
import DeleteAccountPopup from 'components/auth/edit_profile_form_component/DeleteAccountPopup';
import DlDetailsPopup from 'pages/Pages/PagesList/PageItem/PageDls/DlsTable/DlDetailsPopup';
import EditKeywordsPopup from 'components_linkio/EditKeywordsPopup';
import EditPage from 'pages/Pages/PagesList/PageItem/PageItemPopups/EditPage';
import FeedbackPopup from 'components/feedback_popup';
import GetBacklinksSuggestionPopup from 'components/atp/get_backlinks_suggestion_popup';
import ImportDlsPopup from 'pages/Pages/PagesList/PageItem/PageDls/BulkDlsActions/ImportDlsButton/ImportDlsPopup';
import ImportFromGSCPopup from 'pages/BulkPagesSetup/BulkPagesSetupBulkActions/ImportFromGSCPopup';
import LoadingComponent from 'components/loading_component';
import ManuallyImportPagesPopup from 'pages/BulkPagesSetup/BulkPagesSetupBulkActions/ManuallyImportPagesPopup';
import NotificationComponent from 'components_linkio/notification_component';
import PagesExportToCsvPopup from 'pages/Pages/PagesBulkActions/PagesExportToCsvPopup';
import PercentagesFormPopup from 'pages/Pages/PagesList/PageItem/PageDls/DlsPercentage/PercentagesFormPopup';
import SubscriptionLimitReachedPopup from 'components/subscriptions/limit_reached_popup';
import SwitchSubscriptionPlanPopup from 'components/subscriptions/SwitchSubscriptionPlanPopup';
import TrustSuggestionsPopup from 'pages/Pages/PagesList/PageItem/PageDls/Suggestions/TrustSuggestionsPopup';
import UrlChangingHistoryPopup from 'pages/RankTracker/UrlChangingHistoryPopup';

class GlobalComponentsContainer extends React.PureComponent {
  static propTypes = {
    categorizeDlsPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    changePercentagesPopup: PropTypes.shape({ isOpen: PropTypes.bool }),
    confirmPasswordPopup: PropTypes.shape({ isOpen: PropTypes.bool }),
    deleteAccountPopup: PropTypes.shape({ isOpen: PropTypes.bool }),
    dispatch: PropTypes.func,
    dlDetailsPopup: PropTypes.shape({
      dlId: PropTypes.number,
      isOpen: PropTypes.bool,
    }),
    editKeywordsPopup: PropTypes.shape({
      pageId: PropTypes.number,
      isOpen: PropTypes.bool,
    }),
    feedbackPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
      placeholder: PropTypes.string,
      title: PropTypes.string,
      videoAnnotation: PropTypes.string,
      withVideo: PropTypes.string,
    }),
    getBacklinksSuggestionPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
      placeholder: PropTypes.string,
      title: PropTypes.string,
    }),
    importDlsPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    importFromGSCPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    manuallyImportPagesPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    messages: messagesMap,
    pageEditPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    pagesExportToCsvPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    percentagesFormPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    somethingIsChanging: PropTypes.bool,
    somethingIsLoading: PropTypes.bool,
    subscriptionLimitReachedPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    switchSubscriptionPlanPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    trustSuggestionsPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    urlChangingHistoryPopup: PropTypes.shape({
      isOpen: PropTypes.bool,
      brandPageId: PropTypes.number,
      keywordId: PropTypes.number,
    }),
  };

  componentDidMount() {
    setupUsersChannelReceivedCallback(this.props.dispatch);
  }

  render() {
    const {
      categorizeDlsPopup,
      changePercentagesPopup,
      confirmPasswordPopup,
      deleteAccountPopup,
      dlDetailsPopup,
      editKeywordsPopup,
      feedbackPopup,
      getBacklinksSuggestionPopup,
      importDlsPopup,
      importFromGSCPopup,
      manuallyImportPagesPopup,
      messages,
      pageEditPopup,
      pagesExportToCsvPopup,
      percentagesFormPopup,
      somethingIsChanging,
      somethingIsLoading,
      subscriptionLimitReachedPopup,
      switchSubscriptionPlanPopup,
      trustSuggestionsPopup,
      urlChangingHistoryPopup,
    } = this.props;
    const { isOpen: subscriptionLimitReachedIsOpen } = subscriptionLimitReachedPopup;
    const {
      isOpen: getBacklinksSuggestionIsOpen,
      placeholder: getBacklinksSuggestionPlaceholder,
      title: getBacklinksSuggestionTitle,
    } = getBacklinksSuggestionPopup;
    const {
      isOpen: feedbackIsOpen,
      placeholder: feedbackPlaceholder,
      title: feedbackTitle,
      videoAnnotation: feedbackVideoAnnotation,
      withVideo,
    } = feedbackPopup;
    const subscriptionLimitReachedPopupAction = 'changeSubscriptionPlan';

    return (
      <div>
        <NotificationComponent messages={messages} />
        {(somethingIsLoading || somethingIsChanging) && <LoadingComponent />}
        {categorizeDlsPopup.isOpen && <CategorizeDlsPopup {...categorizeDlsPopup} />}
        {changePercentagesPopup.isOpen && <ChangePercentagesPopup {...changePercentagesPopup} />}
        {confirmPasswordPopup.isOpen && <ConfirmPasswordPopup {...confirmPasswordPopup} />}
        {deleteAccountPopup.isOpen && <DeleteAccountPopup {...deleteAccountPopup} />}
        {dlDetailsPopup.isOpen && <DlDetailsPopup {...dlDetailsPopup} />}
        {importDlsPopup.isOpen && <ImportDlsPopup {...importDlsPopup} />}
        {importFromGSCPopup.isOpen && <ImportFromGSCPopup {...importFromGSCPopup} />}
        {manuallyImportPagesPopup.isOpen && <ManuallyImportPagesPopup />}
        {editKeywordsPopup.isOpen && <EditKeywordsPopup {...editKeywordsPopup} />}
        {pageEditPopup.isOpen && <EditPage {...pageEditPopup} />}
        {pagesExportToCsvPopup.isOpen && <PagesExportToCsvPopup {...pagesExportToCsvPopup} />}
        {percentagesFormPopup.isOpen && <PercentagesFormPopup {...percentagesFormPopup} />}
        {subscriptionLimitReachedIsOpen && (
          <SubscriptionLimitReachedPopup actionType={subscriptionLimitReachedPopupAction} />
        )}
        {switchSubscriptionPlanPopup.isOpen && (
          <SwitchSubscriptionPlanPopup {...switchSubscriptionPlanPopup} />
        )}
        {feedbackIsOpen && (
          <FeedbackPopup
            placeholder={feedbackPlaceholder}
            title={feedbackTitle}
            videoAnnotation={feedbackVideoAnnotation}
            withVideo={withVideo}
          />
        )}
        {getBacklinksSuggestionIsOpen && (
          <GetBacklinksSuggestionPopup
            placeholder={getBacklinksSuggestionPlaceholder}
            title={getBacklinksSuggestionTitle}
          />
        )}
        {trustSuggestionsPopup.isOpen && <TrustSuggestionsPopup />}
        {urlChangingHistoryPopup.isOpen && <UrlChangingHistoryPopup {...urlChangingHistoryPopup} />}
      </div>
    );
  }
}

function select(state, ownProps) {
  const messages = state.get('messages');
  const somethingIsChanging = somethingIsChangingSelector(state, ownProps);
  const somethingIsLoading = somethingIsLoadingSelector(state, ownProps);

  const popupsStates = uiSelector(state).get('popups');
  const categorizeDlsPopup = popupsStates.get('categorizeDlsPopup');
  const changePercentagesPopup = popupsStates.get('changePercentagesPopup');
  const confirmPasswordPopup = popupsStates.get('confirmPasswordPopup');
  const deleteAccountPopup = popupsStates.get('deleteAccountPopup');
  const dlDetailsPopup = popupsStates.get('dlDetailsPopup');
  const editKeywordsPopup = popupsStates.get('editKeywordsPopup');
  const feedbackPopup = popupsStates.get('feedbackPopup');
  const getBacklinksSuggestionPopup = popupsStates.get('getBacklinksSuggestionPopup');
  const importDlsPopup = popupsStates.get('importDlsPopup');
  const importFromGSCPopup = popupsStates.get('importFromGSCPopup');
  const manuallyImportPagesPopup = popupsStates.get('manuallyImportPagesPopup');
  const pageEditPopup = popupsStates.get('pageEditPopup');
  const pagesExportToCsvPopup = popupsStates.get('pagesExportToCsvPopup');
  const percentagesFormPopup = popupsStates.get('percentagesFormPopup');
  const subscriptionLimitReachedPopup = popupsStates.get('subscriptionLimitReachedPopup');
  const switchSubscriptionPlanPopup = popupsStates.get('switchSubscriptionPlanPopup');
  const trustSuggestionsPopup = popupsStates.get('trustSuggestionsPopup');
  const urlChangingHistoryPopup = popupsStates.get('urlChangingHistoryPopup');

  return {
    categorizeDlsPopup,
    changePercentagesPopup,
    confirmPasswordPopup,
    deleteAccountPopup,
    dlDetailsPopup,
    editKeywordsPopup,
    feedbackPopup,
    getBacklinksSuggestionPopup,
    importDlsPopup,
    importFromGSCPopup,
    manuallyImportPagesPopup,
    messages,
    pageEditPopup,
    pagesExportToCsvPopup,
    percentagesFormPopup,
    somethingIsChanging,
    somethingIsLoading,
    subscriptionLimitReachedPopup,
    switchSubscriptionPlanPopup,
    trustSuggestionsPopup,
    urlChangingHistoryPopup,
  };
}

export default ConnectStoreHOC(connect(select)(GlobalComponentsContainer));
