import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AddEoCampaignStepDropdownComponent from './AddEoCampaignStepDropdownComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { policiesSelector } from 'selectors/railsContextSelectors';

const AddEoCampaignStepDropdownContainer = ({ onAddEoCampaignStep, canCreate }) => {
  return (
    <>
      {canCreate && (
        <AddEoCampaignStepDropdownComponent
          onAddEoCampaignStep={onAddEoCampaignStep}
          canCreate={canCreate}
        />
      )}
    </>
  );
};

AddEoCampaignStepDropdownContainer.propTypes = {
  canCreate: PropTypes.bool,
  onAddEoCampaignStep: PropTypes.func,
};

function select(state) {
  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);

  return {
    canCreate,
  };
}

export default ConnectStoreHOC(connect(select)(AddEoCampaignStepDropdownContainer));
