import React from 'react';

import SearchEoCustomFieldsInput from './SearchEoCustomFieldsInput';
import DeleteEoCustomFieldsButton from './DeleteEoCustomFieldsButton';
import AddEoCustomFieldButton from './AddEoCustomFieldButton';

import './eoCustomFieldsBulkActions.scss';

const EoCustomFieldsBulkActionsComponent = () => {
  return (
    <div className="eo-merge-tags-bulk-actions">
      <div className="eo-merge-tags-bulk-actions__row">
        <div className="eo-merge-tags-bulk-actions__row-item">
          <SearchEoCustomFieldsInput />
          <span className="vertical-separator" />
          <DeleteEoCustomFieldsButton />
        </div>

        <div className="eo-merge-tags-bulk-actions__row-item">
          <AddEoCustomFieldButton />
        </div>
      </div>
    </div>
  );
};

export default EoCustomFieldsBulkActionsComponent;
