import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import RankTrackerKeywordsComponent from './RankTrackerKeywordsComponent';

import ConnectStoreHOC from 'startup/connect_store_hoc';

import { translate } from 'common/i18n';
import { fetchDataIfBrandIdHasChanged } from 'common/utils';
import { keywordsList, subscriptionShape } from 'common/prop_types_shapes';

import { fetchBrandKeywords, updateBrandKeywords } from 'api/rankTrackerKeywords';
import { fetchRailsContext } from 'api/rails_context';

import { currentIdSelector } from 'selectors';
import { brandKeywordsSelector } from 'selectors/rankTrackerKeywords';
import { currentSubscriptionSelector } from 'selectors/railsContextSelectors';

import Breadcrumbs from 'components/NewBreadcrumbs';
import BreadcrumbsBrandsSelect from 'components/NewBreadcrumbs/BreadcrumbsBrandsSelect';

class RankTrackerKeywordsContainer extends React.Component {
  static propTypes = {
    brandId: PropTypes.string,
    brandKeywords: keywordsList,
    currentSubscription: subscriptionShape,
    dispatch: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  state = {
    error: '',
    keywords: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    fetchDataIfBrandIdHasChanged(prevProps.brandId, this.props.brandId, this.fetchData);
  }

  fetchData = () => {
    const { dispatch, brandId } = this.props;

    Promise.all([fetchRailsContext(dispatch), fetchBrandKeywords(dispatch, brandId)]);
  };

  handleChangeKeywords = (keywords) => this.setState({ keywords });

  handleSaveKeywords = async () => {
    const { dispatch, brandId } = this.props;
    const { error, keywords } = this.state;

    // Validate resulting amount of tracked keywords;
    // Because of the way gem 'reform' implemented, we cannot implement this validation on backend.
    // (in case of nested form the Reform stores all the new records, event if they are not valid)
    const validateTrackedKeywordsCount = this.validateTrackedKeywordsCount(keywords);
    if (!validateTrackedKeywordsCount.isValid) {
      this.setState({ error: validateTrackedKeywordsCount.errorMessage });

      return;
    }

    const resp = await updateBrandKeywords(dispatch, brandId, keywords);

    if (!isEmpty(resp.errors)) {
      this.setState({ error: resp.errors['keywords.label'] });
    } else {
      error && this.setState({ error: '' });
      this.props.history.push(Routes.rank_tracker_root_path(brandId, { format: null }));
    }
  };

  validateTrackedKeywordsCount = (keywords) => {
    const { currentSubscription } = this.props;
    const trackedKeywordsCount = currentSubscription.get('trackedKeywordsCount', 0);
    const allowedTrackedKeywordsCount = currentSubscription.get('allowedTrackedKeywordsCount', 0);
    const newKwsCount = keywords.filter((kw) => !kw.id).length;
    const proposedKwsCount = trackedKeywordsCount + newKwsCount;

    const isValid = allowedTrackedKeywordsCount >= proposedKwsCount;
    const errorMessage = translate('errors.tooManyTrackedKeywords')(allowedTrackedKeywordsCount);

    return { isValid, errorMessage };
  };

  render() {
    const { brandKeywords } = this.props;

    const { error, keywords } = this.state;

    const canSaveKeywords = keywords.length > 0;

    return (
      <>
        <Breadcrumbs>
          <Breadcrumbs.LinkItem to={Routes.root_path({ format: null })}>
            {translate('brand.list.title')}
          </Breadcrumbs.LinkItem>
          <BreadcrumbsBrandsSelect targetAppModule="rankTrackerKeywords" />
        </Breadcrumbs>

        <div className="page__wrapper">
          <RankTrackerKeywordsComponent
            brandKeywords={brandKeywords}
            canSaveKeywords={canSaveKeywords}
            error={error}
            onChangeKeywords={this.handleChangeKeywords}
            onSaveKeywords={this.handleSaveKeywords}
          />
        </div>
      </>
    );
  }
}

function select(state, ownProps) {
  const brandId = currentIdSelector(state, ownProps);
  const brandKeywords = brandKeywordsSelector(state, ownProps);
  const currentSubscription = currentSubscriptionSelector(state, ownProps);

  return {
    brandId,
    brandKeywords,
    currentSubscription,
  };
}

export default withRouter(ConnectStoreHOC(connect(select)(RankTrackerKeywordsContainer)));
