import React from 'react';
import PropTypes from 'prop-types';

import EoProgressionsTable from './EoProgressionsTable';
import EoProgressionsBulkActions from './EoProgressionsBulkActions';

import { pagyShape } from 'common/prop_types_shapes';

import Pager from 'components/Pager';

const EoProgressionsComponent = ({
  onItemsSelectChange,
  onPagesPagerClick,
  eoProgressionsPagy,
}) => {
  return (
    <div className="eo-progressions">
      <EoProgressionsBulkActions />

      <EoProgressionsTable />

      <Pager
        handleItemsSelectChange={onItemsSelectChange}
        handlePagesControlClick={onPagesPagerClick}
        pagerType="rowsPager"
        pagy={eoProgressionsPagy}
      />
    </div>
  );
};

EoProgressionsComponent.propTypes = {
  eoProgressionsPagy: pagyShape,
  onItemsSelectChange: PropTypes.func,
  onPagesPagerClick: PropTypes.func,
};

export default EoProgressionsComponent;
