export default {
  eoCampaignOptions: {
    buttons: {
      delete: 'Delete Campaign',
    },
    checkboxes: {
      prospectCanEnter: {
        label: 'Prospect can enter campaign more than once',
        hint: 'Prospects are forbidden to re-enter the same campaign by default.',
      },
      unsubscribeLink: {
        label: 'Embed an email header link to unsubscribe',
        hint: 'Let recipients unsubscribe from you via a header link (may decrease spam reports).',
      },
      dontTriggerProspects: {
        label: "Upon reply, don't trigger prospects from same company",
        hint: 'When one of the prospects under the same domain replies, the rest will \
          stop their progress and/or be deleted from all the drip lists.',
      },
      keepCampaignGoingOnReply: {
        label: 'Keep campaign going on reply',
        hint: 'The campaign continues until the sequence is done, regardless of receiving a reply.',
      },
    },
    inputs: {
      title: {
        label: 'Campaign title',
      },
      summary: {
        label: 'Campaign Summary',
      },
      activeHours: {
        label: 'Set Active Hours',
        hint: (areThereAnyEoActiveHourOptions) =>
          areThereAnyEoActiveHourOptions
            ? 'If none selected, every progression will be executed at any time'
            : 'If none selected, every progression will be executed at any time. To create Active \
              Hours click the link below',
      },
      repliesAssigneeSelect: 'Assign replies to',
    },
    links: {
      createActiveHours: '+ Create Active Hours',
    },
    owner: 'Campaign owner:',
    messages: {
      cantFetchEoCampaignOptions: "Can't fetch campaign options",
      cantUpdateEoCampaignOptions: "Can't update campaign options",
      cantUpdateEoCampaignStatus: "Can't update campaign status",
      updateEoCampaignStatusSuccess: (status) => {
        if (status === 'live') {
          return 'Successfully started the Campaign.';
        }

        return 'Campaign was paused.';
      },
      updateSuccess: 'Successfully updated campaign options.',
    },
  },
};
