import { Map as iMap } from 'immutable';

import {
  CATEGORIZE_DLS_POPUP__CLOSE,
  CATEGORIZE_DLS_POPUP__OPEN,
  CHANGE_PERCENTAGES_POPUP__CLOSE,
  CHANGE_PERCENTAGES_POPUP__OPEN,
  CONFIRM_PASSWORD_POPUP__CLOSE,
  CONFIRM_PASSWORD_POPUP__OPEN,
  DELETE_ACCOUNT_POPUP__CLOSE,
  DELETE_ACCOUNT_POPUP__OPEN,
  DL_DETAILS_POPUP__CLOSE,
  DL_DETAILS_POPUP__OPEN,
  EDIT_KEYWORDS_POPUP__CLOSE,
  EDIT_KEYWORDS_POPUP__OPEN,
  FEEDBACK_POPUP__CLOSE,
  FEEDBACK_POPUP__OPEN,
  GET_BACKLINKS_SUGGESTION_POPUP__CLOSE,
  GET_BACKLINKS_SUGGESTION_POPUP__OPEN,
  IMPORT_DLS_POPUP__CLOSE,
  IMPORT_DLS_POPUP__OPEN,
  IMPORT_FROM_GSC_POPUP__CLOSE,
  IMPORT_FROM_GSC_POPUP__OPEN,
  MANUALLY_IMPORT_PAGES_POPUP__CLOSE,
  MANUALLY_IMPORT_PAGES_POPUP__OPEN,
  PAGE_EDIT_POPUP__CLOSE,
  PAGE_EDIT_POPUP__OPEN,
  PAGES_EXPORT_TO_CSV_POPUP__CLOSE,
  PAGES_EXPORT_TO_CSV_POPUP__OPEN,
  PERCENTAGES_FORM_POPUP__CLOSE,
  PERCENTAGES_FORM_POPUP__OPEN,
  SUBSCRIPTION_LIMIT_REACHED_POPUP__CLOSE,
  SUBSCRIPTION_LIMIT_REACHED_POPUP__OPEN,
  SWITCH_SUBSCRIPTION_PLAN_POPUP__CLOSE,
  SWITCH_SUBSCRIPTION_PLAN_POPUP__OPEN,
  TRUST_SUGGESTIONS_POPUP__CLOSE,
  TRUST_SUGGESTIONS_POPUP__OPEN,
  URL_CHANGING_HISTORY_POPUP__CLOSE,
  URL_CHANGING_HISTORY_POPUP__OPEN,
} from 'actions/popup_actions';

const categorizeDlsPopupDefaults = {
  isOpen: false,
};

const changePercentagesPopupDefaults = {
  isOpen: false,
};

const confirmPasswordPopupDefaults = { isOpen: false };

const deleteAccountPopupDefaults = { isOpen: false };

const dlDetailsPopupDefaults = {
  isOpen: false,
};

const editKeywordsPopupDefaults = {
  isOpen: false,
};

const subscriptionLimitReachedPopupDefaults = {
  isOpen: false,
};

const feedbackPopupDefaults = {
  isOpen: false,
  placeholder: void 0,
  title: void 0,
  videoAnnotation: void 0,
  withVideo: void 0,
};

const getBacklinksSuggestionPopupDefaults = {
  isOpen: false,
  placeholder: void 0,
  title: void 0,
};

const importDlsPopupDefaults = {
  isOpen: false,
};

const importFromGSCPopupDefaults = {
  isOpen: false,
};

const manuallyImportPagesPopupDefaults = {
  isOpen: false,
};

const pageEditPopupDefaults = {
  isOpen: false,
};

const pagesExportToCsvPopupDefaults = {
  isOpen: false,
};

const percentagesFormPopupDefaults = {
  isOpen: false,
};

const switchSubscriptionPlanPopupDefaults = {
  isOpen: false,
};

const trustSuggestionsPopupDefaults = {
  isOpen: false,
};

const urlChangingHistoryPopupDefaults = {
  isOpen: false,
  brandPageId: null,
  keywordId: null,
};

const initialState = iMap({
  categorizeDlsPopup: categorizeDlsPopupDefaults,
  changePercentagesPopup: changePercentagesPopupDefaults,
  confirmPasswordPopup: confirmPasswordPopupDefaults,
  deleteAccountPopup: deleteAccountPopupDefaults,
  dlDetailsPopup: dlDetailsPopupDefaults,
  editKeywordsPopup: editKeywordsPopupDefaults,
  feedbackPopup: feedbackPopupDefaults,
  getBacklinksSuggestionPopup: getBacklinksSuggestionPopupDefaults,
  importDlsPopup: importDlsPopupDefaults,
  importFromGSCPopup: importFromGSCPopupDefaults,
  manuallyImportPagesPopup: manuallyImportPagesPopupDefaults,
  pageEditPopup: pageEditPopupDefaults,
  pagesExportToCsvPopup: pagesExportToCsvPopupDefaults,
  percentagesFormPopup: percentagesFormPopupDefaults,
  subscriptionLimitReachedPopup: subscriptionLimitReachedPopupDefaults,
  switchSubscriptionPlanPopup: switchSubscriptionPlanPopupDefaults,
  trustSuggestionsPopup: trustSuggestionsPopupDefaults,
  urlChangingHistoryPopup: urlChangingHistoryPopupDefaults,
});

export default function PopupsReducer(state = initialState, action) {
  switch (action.type) {
    case CATEGORIZE_DLS_POPUP__CLOSE: {
      state = state.set('categorizeDlsPopup', {
        isOpen: false,
      });

      break;
    }
    case CATEGORIZE_DLS_POPUP__OPEN: {
      state = state.set('categorizeDlsPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case CHANGE_PERCENTAGES_POPUP__CLOSE: {
      state = state.set('changePercentagesPopup', {
        isOpen: false,
      });

      break;
    }
    case CHANGE_PERCENTAGES_POPUP__OPEN: {
      state = state.set('changePercentagesPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case CONFIRM_PASSWORD_POPUP__CLOSE: {
      state = state.set('confirmPasswordPopup', confirmPasswordPopupDefaults);

      break;
    }
    case CONFIRM_PASSWORD_POPUP__OPEN: {
      state = state.set('confirmPasswordPopup', {
        isOpen: true,
      });

      break;
    }
    case DELETE_ACCOUNT_POPUP__CLOSE: {
      state = state.set('deleteAccountPopup', deleteAccountPopupDefaults);

      break;
    }
    case DELETE_ACCOUNT_POPUP__OPEN: {
      state = state.set('deleteAccountPopup', {
        isOpen: true,
      });

      break;
    }
    case DL_DETAILS_POPUP__CLOSE: {
      state = state.set('dlDetailsPopup', {
        isOpen: false,
      });

      break;
    }
    case DL_DETAILS_POPUP__OPEN: {
      state = state.set('dlDetailsPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case EDIT_KEYWORDS_POPUP__CLOSE: {
      state = state.set('editKeywordsPopup', {
        isOpen: false,
      });

      break;
    }
    case EDIT_KEYWORDS_POPUP__OPEN: {
      state = state.set('editKeywordsPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case FEEDBACK_POPUP__OPEN: {
      state = state.set('feedbackPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case FEEDBACK_POPUP__CLOSE: {
      state = state.set('feedbackPopup', feedbackPopupDefaults);

      break;
    }
    case GET_BACKLINKS_SUGGESTION_POPUP__OPEN: {
      state = state.set('getBacklinksSuggestionPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case GET_BACKLINKS_SUGGESTION_POPUP__CLOSE: {
      state = state.set('getBacklinksSuggestionPopup', getBacklinksSuggestionPopupDefaults);

      break;
    }
    case IMPORT_DLS_POPUP__OPEN: {
      state = state.set('importDlsPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case IMPORT_DLS_POPUP__CLOSE: {
      state = state.set('importDlsPopup', importDlsPopupDefaults);

      break;
    }
    case IMPORT_FROM_GSC_POPUP__CLOSE: {
      state = state.set('importFromGSCPopup', importFromGSCPopupDefaults);

      break;
    }
    case IMPORT_FROM_GSC_POPUP__OPEN: {
      state = state.set('importFromGSCPopup', {
        isOpen: true,
      });

      break;
    }
    case MANUALLY_IMPORT_PAGES_POPUP__OPEN: {
      state = state.set('manuallyImportPagesPopup', {
        isOpen: true,
      });

      break;
    }
    case MANUALLY_IMPORT_PAGES_POPUP__CLOSE: {
      state = state.set('manuallyImportPagesPopup', manuallyImportPagesPopupDefaults);

      break;
    }
    case PAGE_EDIT_POPUP__CLOSE: {
      state = state.set('pageEditPopup', {
        isOpen: false,
      });

      break;
    }
    case PAGE_EDIT_POPUP__OPEN: {
      state = state.set('pageEditPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case PAGES_EXPORT_TO_CSV_POPUP__CLOSE: {
      state = state.set('pagesExportToCsvPopup', {
        isOpen: false,
      });

      break;
    }
    case PAGES_EXPORT_TO_CSV_POPUP__OPEN: {
      state = state.set('pagesExportToCsvPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case PERCENTAGES_FORM_POPUP__CLOSE: {
      state = state.set('percentagesFormPopup', {
        isOpen: false,
      });

      break;
    }
    case PERCENTAGES_FORM_POPUP__OPEN: {
      state = state.set('percentagesFormPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case SUBSCRIPTION_LIMIT_REACHED_POPUP__OPEN: {
      state = state.set('subscriptionLimitReachedPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case SUBSCRIPTION_LIMIT_REACHED_POPUP__CLOSE: {
      state = state.set('subscriptionLimitReachedPopup', subscriptionLimitReachedPopupDefaults);

      break;
    }
    case SWITCH_SUBSCRIPTION_PLAN_POPUP__OPEN: {
      state = state.set('switchSubscriptionPlanPopup', {
        isOpen: true,
        ...action.options,
      });

      break;
    }
    case SWITCH_SUBSCRIPTION_PLAN_POPUP__CLOSE: {
      state = state.set('switchSubscriptionPlanPopup', switchSubscriptionPlanPopupDefaults);

      break;
    }
    case TRUST_SUGGESTIONS_POPUP__CLOSE: {
      state = state.set('trustSuggestionsPopup', {
        isOpen: false,
      });

      break;
    }
    case TRUST_SUGGESTIONS_POPUP__OPEN: {
      state = state.set('trustSuggestionsPopup', {
        isOpen: true,
      });

      break;
    }
    case URL_CHANGING_HISTORY_POPUP__CLOSE: {
      state = state.set('urlChangingHistoryPopup', urlChangingHistoryPopupDefaults);

      break;
    }
    case URL_CHANGING_HISTORY_POPUP__OPEN: {
      state = state.set('urlChangingHistoryPopup', {
        isOpen: true,
        brandPageId: action.brandPageId,
        keywordId: action.keywordId,
      });

      break;
    }
  }

  return state;
}
