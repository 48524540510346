import React from 'react';

const GoogleDriveIcon = (props) => {
  return (
    <svg
      {...props}
      className="google-drive-icon"
      viewBox="4 4 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5 21l-3.17 5.549A3.07 3.07 0 0123.645 28H8.354a3.07 3.07 0 01-2.683-1.451L4.369 24.27 9.758 21z"
        fill="#4285f4"
      />
      <path
        d="M12.382 4.13a3.226 3.226 0 00-1.707 1.428L2.96 18.76a3.07 3.07 0 00-.101 3.049l1.53 2.466L9.758 21 16 10.32z"
        fill="#00ac47"
      />
      <path
        d="M9.758 21h-7.19a2.654 2.654 0 00.29.809l1.522 2.454-.011.007L5.67 26.55a2.827 2.827 0 001.337 1.148L9.758 21l-.008.005z"
        fill="#0066da"
      />
      <path
        d="M19.607 4.13a3.226 3.226 0 011.706 1.428L29.03 18.76a3.07 3.07 0 01.101 3.049l-1.53 2.466L22.232 21 15.99 10.32z"
        fill="#ffba00"
      />
      <path
        d="M22.231 21h7.19a2.654 2.654 0 01-.29.809l-1.522 2.454.011.007-1.302 2.279a2.827 2.827 0 01-1.337 1.148L22.231 21l.008.005z"
        fill="#ea4435"
      />
      <path
        d="M19.616 4.134l.002-.004a2.773 2.773 0 00-.361-.098L16 4l-3.257.032a2.773 2.773 0 00-.36.098 3.022 3.022 0 00-.368.167l3.974 6.023 3.988-6.02a3.03 3.03 0 00-.361-.166z"
        fill="#188038"
      />
    </svg>
  );
};

GoogleDriveIcon.defaultProps = {
  height: '28px',
  width: '32px',
};

export default GoogleDriveIcon;
