import { createSelector } from 'reselect';
import { Map as iMap } from 'immutable';


const permissions = (state) => state.get('permissions', iMap());


export const permissionsSelector = createSelector(
  [permissions],
  (permissions) => permissions
);
