import { Map as iMap } from 'immutable';

function getSelected(items) {
  if (items) {
    return items.filter((item) => item.get('selected'));
  }

  return iMap();
}

function crawlingInProgress(status) {
  return ['enqueued', 'processing'].includes(status);
}

export { crawlingInProgress, getSelected };
