import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../common/i18n';

import CancelFormSubmitDecorator from '../cancel_form_submit_decorator';

import InputComponent from '../../../components_linkio/input_component';

import AuthFormGlobalErrorComponent from '../auth_form_global_error_component';
import DeviseFooterComponent from '../devise_footer_component';
import DeviseHeaderComponent from '../devise_header_component';
import DeviseLinksComponent from '../devise_links_component';
import SubmitButtonComponent from '../submit_button_component';

import './resend_confirmation_form_component.scss';


class ResendConfirmationFormComponent extends Component {
  static propTypes = {
    error: PropTypes.string,
    errors: PropTypes.shape({
      email: PropTypes.arrayOf(PropTypes.string),
    }),
    handleResendConfirmationClick: PropTypes.func,
    showMessage: PropTypes.bool,
  }

  inputs = {};

  handleSendClick = async () => {
    const email = this.inputs.email.value;

    await this.props.handleResendConfirmationClick(email);
  }

  emailRef = (input) => this.inputs.email = input;

  render() {
    const { errors, error, showMessage } = this.props;

    return (
      <div className="resend-confirmation-component">
        <DeviseHeaderComponent signUp logIn />
        <div className="resend-confirmation-component__space" />
        <h2 className="resend-confirmation-component__text resend-confirmation-component__text_title">
          {translate('authForms.resendConfirmation.title')}
        </h2>
        <h4 className="resend-confirmation-component__text resend-confirmation-component__text_subtitle">
          {translate('authForms.resendConfirmation.subtitle')}
        </h4>
        <form className="resend-confirmation-component__form" noValidate>
          {showMessage &&
            <div className="resend-confirmation-component__text">
              {translate('authForms.resendConfirmation.successMsg')}
            </div>
          }
          <AuthFormGlobalErrorComponent message={error} />
          <div className="resend-confirmation-component__input">
            <InputComponent
              message={{ type: 'error', text: errors.email }}
              inputRef={this.emailRef}
              isMandatory
              onSend={this.handleSendClick}
              label={translate('authForms.resendConfirmation.inputs.email.label')}
              autoComplete="email"
              type="email"
              name="email"
            />
          </div>
          <SubmitButtonComponent
            isBlue
            onClick={this.handleSendClick}
            text={translate('authForms.resendConfirmation.buttons.submit')}
          />
          <DeviseLinksComponent linksToSkip={['resendConfirmation']} />
          <DeviseFooterComponent />
        </form>
      </div>
    );
  }
}

export default CancelFormSubmitDecorator(ResendConfirmationFormComponent);
