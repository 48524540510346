import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose, isInteger } from 'lodash';

import { eoBlacklistedDomainsPagySelector } from 'selectors/eoBlacklistedDomainsSelector';
import EoBlacklistedDomainsPageComponent from './EoBlacklistedDomainsPageComponent';

import Breadcrumbs from 'components/NewBreadcrumbs';

import ConnectStoreHOC from 'startup/connect_store_hoc';
import withQueryParams, { withQueryParamsPropTypes } from 'decorators/withQueryParams';

import { translate } from 'common/i18n';

import { pagyShape } from 'common/prop_types_shapes';
import { policiesSelector } from 'selectors/railsContextSelectors';

const EoBlacklistedDomainsPageContainer = ({
  canCreate,
  changeQueryParams,
  eoBlacklistedDomainsPagy,
  queryParams,
}) => {
  const handlePagesPagerClick = (value) => (event) => {
    event.preventDefault();

    if (isInteger(value)) {
      const newQueryParams = {
        ...queryParams,
        pageNumber: value,
      };

      changeQueryParams(newQueryParams);
    }
  };

  function handleItemsSelectChange({ value }) {
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pagyItemsCount: value,
    };

    changeQueryParams(newQueryParams);
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.TextItem>
          {translate('eoBlacklistedDomain.breadcrumbsTitle')}
        </Breadcrumbs.TextItem>
      </Breadcrumbs>
      <div className="page__wrapper">
        <EoBlacklistedDomainsPageComponent
          canCreate={canCreate}
          onItemsSelectChange={handleItemsSelectChange}
          onPagesPagerClick={handlePagesPagerClick}
          eoBlacklistedDomainsPagy={eoBlacklistedDomainsPagy}
        />
      </div>
    </>
  );
};

EoBlacklistedDomainsPageContainer.propTypes = {
  canCreate: PropTypes.bool,
  eoBlacklistedDomainsPagy: pagyShape,
  ...withQueryParamsPropTypes,
};

function select(state) {
  const eoBlacklistedDomainsPagy = eoBlacklistedDomainsPagySelector(state);
  const policies = policiesSelector(state);
  const canCreate = policies.getIn(['emailOutreach', 'canCreate']);

  return {
    canCreate,
    eoBlacklistedDomainsPagy,
  };
}

export default compose(
  withQueryParams,
  ConnectStoreHOC,
  connect(select),
)(EoBlacklistedDomainsPageContainer);
