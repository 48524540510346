import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { translate } from '../../../common/i18n';

import CancelFormSubmitDecorator from '../../../components/auth/cancel_form_submit_decorator';

import Checkbox from '../../../components_linkio/Checkbox';
import InputComponent from '../../../components_linkio/input_component';

import AuthFormGlobalErrorComponent from '../../../components/auth/auth_form_global_error_component';
import DeviseFooterComponent from '../../../components/auth/devise_footer_component';
import DeviseHeaderComponent from '../../../components/auth/devise_header_component';
import DeviseLinksComponent from '../../../components/auth/devise_links_component';
import SubmitButtonComponent from '../../../components/auth/submit_button_component';

import './signInForm.scss';


class SignInFormComponent extends Component {
  static propTypes = {
    error: PropTypes.string,
    errors: PropTypes.shape({
      email: PropTypes.arrayOf(PropTypes.string),
      password: PropTypes.arrayOf(PropTypes.string),
    }),
    handleSignInClickAction: PropTypes.func,
  }

  inputs = {};

  handleSignInClick = async () => {
    const { errors, handleSignInClickAction } = this.props;

    const email = (this.inputs.email.value || '').toLowerCase();
    const password = this.inputs.password.value;
    const rememberMe = this.inputs.remember.checked ? 1 : 0;

    await handleSignInClickAction(email, password, rememberMe);

    if (!isEmpty(errors) && (errors.password || '').length > 0) {
      this.inputs.password.value = '';
    }
  }

  emailRef = (input) => this.inputs.email = input;
  passwordRef = (input) => this.inputs.password = input;
  rememberRef = (input) => this.inputs.remember = input;

  render() {
    const { errors, error } = this.props;

    const forgotPasswordPath = Routes.new_user_password_path({ format: null });

    return (
      <div className="signin-component">
        <DeviseHeaderComponent signUp />
        <form className="signin-component__form" noValidate>
          <h2 className="signin-component__text signin-component__text_title">
            {translate('authForms.signIn.title')}
          </h2>
          <AuthFormGlobalErrorComponent message={error} />
          <div className="signin-component__input">
            <InputComponent
              inputRef={this.emailRef}
              isMandatory
              label={translate('authForms.signIn.inputs.email.label')}
              message={{ type: 'error', text: errors.email }}
              onSend={this.handleSignInClick}
              autoComplete="true"
              type="email"
              name="email"
            />
          </div>
          <div className="signin-component__input">
            <InputComponent
              inputRef={this.passwordRef}
              isMandatory
              label={translate('authForms.signIn.inputs.password.label')}
              message={{ type: 'error', text: errors.password }}
              onSend={this.handleSignInClick}
              autoComplete="true"
              type="password"
              name="password"
            />
          </div>
          <div className="signin-component__checkbox-container">
            <Checkbox
              ref={this.rememberRef}
            >
              <span className="signin-component__text">
                {translate('authForms.signIn.inputs.rememberMe.label')}
              </span>
            </Checkbox>
            <a
              className="signin-component__text signin-component__text_link"
              href={forgotPasswordPath}
            >
              {translate('authForms.deviseLinks.forgotPassword')}
            </a>
          </div>
          <SubmitButtonComponent
            isBlue
            onClick={this.handleSignInClick}
            text={translate('authForms.signIn.buttons.submit')}
          />
          <DeviseLinksComponent />
          <DeviseFooterComponent />
        </form>
      </div>
    );
  }
}

export default CancelFormSubmitDecorator(SignInFormComponent);
