import React from 'react';
import PropTypes from 'prop-types';

import SearchEoCampaignsInput from './SearchEoCampaignsInput';
import AddEoCampaignButton from './AddEoCampaignButton';

import { translate } from 'common/i18n';

import { optionsList } from 'common/prop_types_shapes';

import Select from 'components_linkio/Select';

import './eoCampaignsBulkActions.scss';

const EoCampaignsBulkActionsComponent = ({
  canCreate,
  currentBrandId,
  immutableBrandsOptions,
  handleChangeCurrentBrand,
}) => {
  const brandsOptions = immutableBrandsOptions.toJS();
  const componentStylesPropForSelect = { option: { wordBreak: 'break-all' } };

  return (
    <div className="eo-campaigns-bulk-actions">
      <div className="eo-campaigns-bulk-actions__row">
        <div className="eo-campaigns-bulk-actions__row-item">
          <SearchEoCampaignsInput />

          <Select
            blurInputOnSelect
            componentStyles={componentStylesPropForSelect}
            wrapperClassName="eo-campaigns-bulk-actions__filter_by-brand"
            isClearable
            placeholder={translate(`eoCampaign.bulkActions.selectBrand`)}
            onChange={handleChangeCurrentBrand}
            options={brandsOptions}
            triggerChangeOnBlur={false}
            value={brandsOptions.find(({ value }) => value === currentBrandId) ?? null}
          />
        </div>

        {canCreate && (
          <div className="eo-campaigns-bulk-actions__row-item">
            <AddEoCampaignButton />
          </div>
        )}
      </div>
    </div>
  );
};

EoCampaignsBulkActionsComponent.propTypes = {
  canCreate: PropTypes.bool,
  currentBrandId: PropTypes.number,
  handleChangeCurrentBrand: PropTypes.func,
  immutableBrandsOptions: optionsList,
};

export default EoCampaignsBulkActionsComponent;
